import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  List,
  Popup,
  Icon,
  Ref,
  TextArea,
  TextAreaProps,
  Dimmer,
  Loader,
  Form,
  Accordion,
  Divider,
} from "semantic-ui-react";
import styled from "styled-components";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
import {
  buildSentence,
  findLanguage,
  getCustomerEnglishLanguage,
  getSectionContext,
  preventOverflowOnTextarea,
  updateSentenceValueFactory,
} from "../utils";
import { explainInputText, explainInstruction } from "./SelectedPrompt";
import { Text } from "../../../components/Text";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { Prompt, SentenceSectionInfo } from "../types";
import { PromptSuggestionsComponent } from "../PromptSuggestionsComponent";
import { selectedPromptGroupPromptItemTestIds } from "../testUtils/testIdsSelectors";
import { FieldsetSelection } from "./FieldsetSelection";
import { ImageUrlInput } from "./ImageUrlInput";
import { Flag } from "../../../customers/customerlanguages";
import { GptGenerationTaskQueue, gptAddSentences } from "../../../api/gptApi";
import { reloadPreview } from "../../../legacy/t.product-detail";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import { useGetDocumentStructures } from "../../../planner/document-structure/manage/customhooks";
import {
  CopyButton,
  GroupedGenerationButtons,
  RevertButton,
  SettingsButton,
} from "./Components/Buttons";
import { LangStringObject } from "../../../vocabulary/LangString";
import { getFeatureFlag } from "../../../utils/featureFlags";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";

export const FlexDivTwoItems = styled.div<{ $marginTop?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  ${({ $marginTop }): string =>
    $marginTop ? "margin-top: 10px" : "margin-bottom: 10px"};
  & > :nth-child(1) {
    flex: 1;
  }
  & > :nth-child(2) {
    margin: 0;
    margin-left: auto;
  }
`;

type Props = {
  id: number;
  setOpenUpdatePromptModal: Dispatch<SetStateAction<boolean>>;
  setSelectedPromptToUpdate: Dispatch<SetStateAction<Prompt>>;
  handleChangeInstruction: (id: number, value: string) => void;
  handleChangeInputText: (id: number, value: string) => void;
  handleImageUrlChange: (id: number, value: string) => void;
  handleCopyData: (value: string) => void;
  handleCopyImageUrl: (value: string) => void;
  collectStrings: (str: string, id: number) => void;
  saveManualImageUrl: (value: string, id: number) => void;
  alteredInstruction: string | undefined;
  alteredInputText: string | undefined;
  imageUrl: string | undefined;
  inputData: string;
  loading: boolean;
  supportsImageProcessing: (id: number) => Promise<boolean>;
  useChannelAsDefaultFallback: boolean;
  gptGenerationTaskQueue: GptGenerationTaskQueue;
};

export const SelectedPromptGroupPromptItem: React.FC<Props> = ({
  id,
  setOpenUpdatePromptModal,
  setSelectedPromptToUpdate,
  handleChangeInputText,
  handleChangeInstruction,
  handleImageUrlChange,
  handleCopyData,
  handleCopyImageUrl,
  collectStrings,
  saveManualImageUrl,
  alteredInputText,
  alteredInstruction,
  imageUrl,
  inputData,
  loading,
  supportsImageProcessing,
  useChannelAsDefaultFallback,
  gptGenerationTaskQueue,
}) => {
  const testIds = selectedPromptGroupPromptItemTestIds(id);
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: structures } = useGetDocumentStructures();
  const dispatch = useContext(CopyAssistantDispatchContext);
  const {
    prompts: { list: prompts },
    productSpecificOverwrites: { isLoading: productSpecificOverwritesLoading },
    sentences: { list: allSentences, isLoading: sentenceLoading },
    productId,
  } = useContext(CopyAssistantContext);
  const { data: customer } = useGetCustomerQuery();
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const [inputDataFromFieldset, setInputDataFromFieldset] = useState<string>(
    ""
  );

  const [showImageUrlInput, setShowImageUrlInput] = useState<boolean>(false);
  const prompt = useMemo(() => prompts.find((p) => p.id === id), [prompts]);

  useEffect(() => {
    let mounted = true;
    (async (): Promise<void> => {
      const supportsImageProcessingResult = await supportsImageProcessing(id);
      if (mounted) setShowImageUrlInput(supportsImageProcessingResult);
    })();
    return (): void => {
      mounted = false;
    };
  }, []);

  const viewChannelTextarea = useMemo(() => {
    if (prompt?.channel_id) {
      return true;
    }
    if (prompt?.fieldset_id) {
      return false;
    }
    return useChannelAsDefaultFallback;
  }, [useChannelAsDefaultFallback, prompt]);

  const language = useMemo(() => findLanguage(prompt, customer), [
    customer,
    prompt,
  ]);
  const textareaRefs = useRef<HTMLTextAreaElement[]>([]);

  useLayoutEffect(() => {
    textareaRefs.current.forEach((textarea) => {
      preventOverflowOnTextarea(textarea);
    });
  });

  useEffect(() => {
    collectStrings(inputDataFromFieldset, id);
  }, [inputDataFromFieldset, id]);

  const onAddSuggestion = (text: string): void => {
    const instruction = alteredInstruction ?? prompt?.instruction;
    let suggestion = text;
    if (instruction) {
      suggestion = `\n${text}`;
    }
    handleChangeInstruction(id, (instruction ?? "") + suggestion);
  };

  const hasInputData = useMemo(() => {
    if (alteredInputText !== undefined) {
      return alteredInputText;
    }
    return !!inputData;
  }, [inputData, alteredInputText]);

  const collectString = (str: string): void => {
    setInputDataFromFieldset(str);
  };

  const useInputData = useMemo(() => {
    if (alteredInputText !== undefined) {
      return alteredInputText;
    }
    return inputData || "";
  }, [alteredInputText, inputData]);

  const disabledGenerateButton = useMemo((): boolean => {
    if (!prompt?.channel_id && inputDataFromFieldset) {
      return false;
    }
    if (inputData && alteredInputText === "") {
      return true;
    }

    if (prompt) {
      return (!inputData && !alteredInputText) || alteredInstruction === "";
    }

    return !alteredInstruction || (!inputData && !alteredInputText);
  }, [
    prompt,
    inputDataFromFieldset,
    inputData,
    alteredInputText,
    alteredInstruction,
  ]);

  const setLoadingState = (loading: boolean, id?: string): void => {
    if (id) {
      dispatch({
        type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
        payload: { id, isLoading: loading },
      });
    }
    dispatch({
      type: CopyAssistantActionType.SET_SENTENCE_LOADING,
      payload: { loading, promptIds: [prompt?.id] },
    });
  };

  const handleGenerateAndAddToProduct = async (
    preloadedSectionInfo?: SentenceSectionInfo,
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    const textToUse = viewChannelTextarea
      ? alteredInputText ?? inputData
      : inputDataFromFieldset;
    const newSentence = buildSentence(
      [],
      {
        inputText: textToUse,
        instruction: alteredInstruction,
        promptId: prompt?.id,
      },
      customer,
      structures,
      language.code
    );
    newSentence.isLoading = true;
    if (preloadedSectionInfo) {
      newSentence.sectionInfo = preloadedSectionInfo;
    }

    dispatch({
      type: CopyAssistantActionType.ADD_SENTENCE,
      payload: newSentence,
    });

    const updateSentenceCallback = (value: string): void => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_SENTENCE,
        payload: {
          id: newSentence.id,
          toUpdate: { value: { [language.code]: value }, isStreaming: true },
        },
      });
    };

    const { awaitableTimeouts, updateResult } = updateSentenceValueFactory(
      language.code,
      updateSentenceCallback
    );

    const {
      generated_texts: langStringValues,
      seconds_spent_generating,
    } = await gptGenerationTaskQueue
      .generateOne(
        productId,
        textToUse,
        prompt?.id,
        alteredInstruction,
        imageUrl,
        getFeatureFlag(customer, "enable_copy_assistant_stream_gpt_generation"),
        updateResult
      )
      .catch(() => {
        dispatch({
          type: CopyAssistantActionType.REMOVE_SENTENCE,
          payload: newSentence.id,
        });
        return {
          generated_texts: [] as LangStringObject[],
          seconds_spent_generating: 0,
        };
      });

    await Promise.all(awaitableTimeouts);

    if (!langStringValues.length) {
      dispatch({
        type: CopyAssistantActionType.REMOVE_SENTENCE,
        payload: newSentence.id,
      });
      return;
    }
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.INFO,
        content: `Generation completed in ${seconds_spent_generating} seconds`,
      })
    );
    const data = await gptAddSentences(
      productId,
      langStringValues[0],
      token,
      prompt?.id
    );
    const sectionContext = getSectionContext(data, customer, structures);

    dispatch({
      type: CopyAssistantActionType.UPDATE_SENTENCE,
      payload: {
        id: newSentence.id,
        toUpdate: {
          ...sectionContext,
          isStreaming: false,
          isLoading: false,
          value: langStringValues[0],
        },
      },
    });
    if (sectionContext.sectionInfo) {
      dispatch({
        type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
        payload: sectionContext.sectionInfo.documentSectionName,
      });
    }

    dispatch({
      type: CopyAssistantActionType.SET_SECTION_ACTIVE,
      payload: { id: newSentence.id },
    });
    if (autoTranslateAfterGeneration) {
      dispatch({
        type: CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST,
        payload: {
          id: newSentence.id,
          mtConfigGroupId,
        },
      });
    }
  };
  const onGenerate = async (
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    const foundSentence = allSentences.find(
      (sentence) =>
        sentence?.sectionInfo?.documentSectionId === prompt?.document_section_id
    );
    setLoadingState(true, foundSentence?.id);
    let preloadSectionInfo: SentenceSectionInfo;
    if (foundSentence?.sectionInfo) {
      preloadSectionInfo = foundSentence.sectionInfo;
      dispatch({
        type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
        payload: foundSentence.sectionInfo.documentSectionName,
      });
    }
    await handleGenerateAndAddToProduct(
      preloadSectionInfo,
      autoTranslateAfterGeneration,
      mtConfigGroupId
    ).finally(() => {
      setLoadingState(false, foundSentence?.id);
      if (!autoTranslateAfterGeneration) {
        reloadPreview(
          foundSentence?.sectionInfo?.connectedChannels.map(({ id }) => id),
          prompt?.language || getCustomerEnglishLanguage(customer)?.code
        );
      }
      dispatch({
        type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
        payload: true,
      });
    });
  };

  if (!prompt) {
    return <></>;
  }
  return (
    <List.Item key={prompt.id}>
      <FlexDivTwoItems>
        <div>
          <Text compact as="h4" testId={testIds.displayName}>
            <Flag content={findLanguage(prompt, customer)?.flag} />{" "}
            {prompt.name}
          </Text>
          {prompt.tags.map((tag) => (
            <TemplateLabelChip
              data-testid={testIds.tag(tag)}
              classNames="fitted-template-label"
              skipPredefinedClasses
              key={tag}
              content={tag}
              description={""}
              labelProps={{
                as: "span",
                size: "mini",
              }}
            />
          ))}
        </div>
        <div className="tw-flex tw-gap-2 tw-justify-center">
          <GroupedGenerationButtons
            variant="primary-alt"
            size="sm"
            compact
            loading={sentenceLoading}
            disabled={disabledGenerateButton}
            onGenerate={onGenerate}
            generateTestId={testIds.buttons.generate}
            generateAndTranslateTestId={testIds.buttons.generateAndTranslate}
          />

          <Divider vertical hidden />
          <SettingsButton
            data-testid={testIds.buttons.settings}
            onClick={(): void => {
              setOpenUpdatePromptModal(true);
              setSelectedPromptToUpdate(prompt);
            }}
            color={undefined}
          />
        </div>
      </FlexDivTwoItems>
      <Accordion styled as={List.Description} fluid>
        <Accordion.Title
          data-testid={testIds.accordion.title}
          active={accordionOpen}
          index={0}
          onClick={(): void => setAccordionOpen(!accordionOpen)}
        >
          <Icon name="dropdown" />
          Prompt Details{" "}
          {(!hasInputData || loading) && viewChannelTextarea && (
            <>
              <Popup
                size="tiny"
                disabled={loading}
                content="No data for prompt"
                trigger={
                  <Icon
                    data-testid={
                      loading
                        ? testIds.accordion.loading
                        : testIds.accordion.warning
                    }
                    loading={loading}
                    name={loading ? "circle notch" : "warning circle"}
                    color={loading ? "grey" : "red"}
                  />
                }
              />
              {loading && "Collecting data..."}
            </>
          )}
        </Accordion.Title>
        <Form
          size="tiny"
          as={Accordion.Content}
          active={accordionOpen}
          data-testid={testIds.accordion.content}
        >
          <Form.Field>
            <FlexDivTwoItems>
              <span>
                <Popup
                  wide="very"
                  header={explainInstruction.header}
                  content={explainInstruction.content}
                  trigger={
                    <label
                      style={{ display: "inline-block" }}
                      data-testid={testIds.accordion.instruction.label}
                    >
                      <Icon name="question circle" /> Instruction:
                    </label>
                  }
                  size="tiny"
                  hoverable
                />{" "}
                <Text
                  color="grey"
                  className="descriptive-helper-text"
                  compact
                  inline
                >
                  how to write
                </Text>
              </span>
              {!!alteredInstruction && (
                <RevertButton
                  data-testid={testIds.accordion.instruction.revert}
                  onRevert={(): void =>
                    handleChangeInstruction(id, prompt.instruction)
                  }
                />
              )}
            </FlexDivTwoItems>
            <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
              <TextArea
                data-testid={testIds.accordion.instruction.textarea}
                value={alteredInstruction ?? prompt?.instruction}
                onChange={(
                  event: React.FormEvent<HTMLTextAreaElement>,
                  { value }: TextAreaProps
                ): void => handleChangeInstruction(id, value as string)}
              />
            </Ref>
            <PromptSuggestionsComponent
              onAddSuggestion={onAddSuggestion}
              language={language?.code}
            />
          </Form.Field>
          <Form.Field>
            <FlexDivTwoItems>
              <div>
                <Popup
                  header={explainInputText.header}
                  content={explainInputText.content}
                  trigger={
                    <label
                      style={{ display: "inline-block" }}
                      data-testid={testIds.accordion.data.label}
                    >
                      <Icon name="question circle" /> Data:
                    </label>
                  }
                  size="tiny"
                  wide="very"
                  hoverable
                />{" "}
                <Text
                  color="grey"
                  className="descriptive-helper-text"
                  compact
                  inline
                >
                  what to write about
                </Text>
              </div>
              <div>
                {!!alteredInputText && (
                  <RevertButton
                    data-testid={testIds.accordion.data.revert}
                    onRevert={(): void => handleChangeInputText(id, inputData)}
                  />
                )}
                {alteredInputText && (
                  <CopyButton
                    data-testid={testIds.accordion.data.copy}
                    onCopy={(): void => handleCopyData(alteredInputText)}
                  />
                )}
              </div>
            </FlexDivTwoItems>
            {viewChannelTextarea && (
              <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
                <TextArea
                  data-testid={testIds.accordion.data.textarea}
                  value={useInputData}
                  onChange={(
                    event: React.FormEvent<HTMLTextAreaElement>,
                    { value }: TextAreaProps
                  ): void => handleChangeInputText(id, value as string)}
                />
              </Ref>
            )}
            {!viewChannelTextarea && (
              <FieldsetSelection
                key={prompt?.fieldset_id}
                activeFieldsetId={prompt?.fieldset_id}
                collectString={collectString}
                alteredInputText={alteredInputText}
                saveManualInputData={(str: string): void =>
                  handleChangeInputText(id, str)
                }
                language={prompt?.language}
              />
            )}
            <Dimmer
              active={loading && viewChannelTextarea}
              inverted
              data-testid={testIds.accordion.data.dimmer}
            >
              <Loader
                active={loading}
                size="tiny"
                data-testid={testIds.accordion.data.loading}
              >
                Collecting Data...
              </Loader>
            </Dimmer>
          </Form.Field>
          {showImageUrlInput && !productSpecificOverwritesLoading && (
            <ImageUrlInput
              imageUrl={imageUrl}
              setImageUrl={(value): void => handleImageUrlChange(id, value)}
              productId={productId}
              saveManualImageUrl={(value): void =>
                saveManualImageUrl(value, id)
              }
              handleCopyImageUrl={handleCopyImageUrl}
              skipProductImage={!prompt?.include_documents_image}
            />
          )}
        </Form>
      </Accordion>
    </List.Item>
  );
};
