import VocabularyLookupNew from "../vocabulary/VocabularyLookupNew";
import { VocabularyTreeData } from "./tagutils";
import BrandSelector from "../products/brand_selector";

export function openInOverlay(path: string, callback: () => void = null): void {
  (window as any).openSubpage(null, path, callback);
}

export function queuePreviewTextGeneration(): void {
  (window as any).queuePreviewTextGeneration();
}

declare global {
  interface Window {
    brandSelectorOnDataChanged: (
      element: JQuery<HTMLElement>,
      component: BrandSelector,
      data: any
    ) => void;
    loadPartial: (partial: JQuery<HTMLElement>, force: boolean) => void;
    multipleSelectorOnDataChanged: (element: HTMLInputElement) => void;
    productTagsEditorOnDataChanged: (
      element: HTMLTextAreaElement,
      delayedRefresh: boolean
    ) => void;
    submitAnnotations: (element: JQuery<HTMLElement>) => void;
    tagTreeEditorOnDataChanged: (element: HTMLTextAreaElement) => void;
    langStringOnDataChanged: (element: HTMLTextAreaElement) => void;
    templateSelectorOnDataChanged: (element: HTMLInputElement) => void;
    templateMultipleLabelSelectorOnDataChanged: (
      element: HTMLInputElement
    ) => void;
    textPatternOnDataChanged: (element: HTMLInputElement) => void;
    queuePreviewTextGeneration: () => void;
    vocabularyLookupOnDataChanged: (
      element: HTMLInputElement,
      component: VocabularyLookupNew,
      data: VocabularyTreeData | VocabularyTreeData[]
    ) => void;
    openSubpage: (openerElement: any, url: string, callback?: any) => void;
  }
}
