import React, { useState } from "react";
import { Button, Dropdown, Icon } from "../../components/tailwind";
import {
  TagCategoryTypeMap,
  TagCategoryTypeMapKeyType,
  TemplateField,
  TemplateFieldTypeMap,
  TemplateFieldTypeMapKeyType,
} from "../template";
import {
  getTemplatefieldTypeOptions,
  isKeyForbidden,
} from "./TemplateBuilderManageVariableInput";

type Props = {
  open: boolean;
  variables: TemplateField[];
  onCreateVariable: (variable: TemplateField) => void;
  onAddVariable: (variableKey: string) => void;
  dragHandlers: {
    onDragEnd: (event: React.DragEvent<HTMLButtonElement>) => void;
    onDragStart: (event: React.DragEvent<HTMLButtonElement>) => void;
  };
  disabledDrag: boolean;
};
export const TemplateBuilderContentVariableHandler: React.FC<Props> = ({
  open,
  variables,
  onCreateVariable,
  onAddVariable,
  dragHandlers,
  disabledDrag,
}) => {
  const [isCreatingNewVariable, setIsCreatingNewVariable] = useState(false);
  const [selectedVariableType, setSelectedVariableType] = useState<
    TemplateFieldTypeMapKeyType
  >();
  const [selectedTag, setSelectedTag] = useState<TagCategoryTypeMapKeyType>();
  const [variableKey, setVariableKey] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const showTagDropdown =
    TemplateFieldTypeMap?.[selectedVariableType] === "Tag";

  const validateInput = (input: string): void => {
    setValidationError(null);
    if (isKeyForbidden(input)) {
      setValidationError("Variable name blocked.");
      return;
    }
    if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/.test(input)) {
      setValidationError("Invalid variable name.");
    }
  };
  if (!open) return null;
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-border-l-[1px] tw-pl-2">
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {variables.map((variable, index) => (
          <Button
            draggable={!disabledDrag}
            onDragStart={dragHandlers.onDragStart}
            onDragEnd={dragHandlers.onDragEnd}
            data-testid={`template-builder-content-add-variable-action-${index}`}
            data-balloon={`Type: "${TemplateFieldTypeMap[variable.type]}"`}
            data-balloon-pos="up-left"
            data-add-variable={variable.key}
            variant="secondary-alt"
            size="sm"
            compact={disabledDrag ? true : "very"}
            icon
            key={variable.key}
            onClick={(): void => onAddVariable(variable.key)}
          >
            {!disabledDrag && (
              <Icon
                name="drag_indicator"
                className="tw-pointer-events-none !tw-text-lg"
              />
            )}
            {variable.key}
          </Button>
        ))}
        <span data-balloon="Create new Variable" data-balloon-pos="up-left">
          <Button
            data-testid="template-builder-content-quick-create-new-variable"
            variant={isCreatingNewVariable ? "secondary" : "secondary-alt"}
            size="sm"
            compact
            icon
            onClick={(): void => {
              setIsCreatingNewVariable((prev) => !prev);
            }}
          >
            <Icon name="add" />
          </Button>
        </span>
      </div>
      {isCreatingNewVariable && (
        <div className="tw-flex tw-gap-x-4">
          <input
            className="tw-form-input tw-block tw-rounded-md tw-border-0 tw-p-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset"
            placeholder="Name your variable..."
            value={variableKey}
            onChange={({ target }): void => {
              validateInput(target.value);
              setVariableKey(target.value);
            }}
            data-testid="template-builder-content-quick-add-variable-name-input"
          />
          <Dropdown<TemplateFieldTypeMapKeyType>
            testIdBase="template-builder-content-quick-add-variable-type-dropdown"
            options={getTemplatefieldTypeOptions()}
            placeholder="Select Type"
            value={selectedVariableType}
            onChange={(value): void => {
              setSelectedVariableType(value as TemplateFieldTypeMapKeyType);
            }}
            clearable
          />
          {showTagDropdown && (
            <Dropdown<TagCategoryTypeMapKeyType>
              testIdBase="template-builder-content-quick-add-variable-tag-dropdown"
              options={Object.entries(TagCategoryTypeMap).map(
                ([key, value]) => ({
                  key: key,
                  value: key as TagCategoryTypeMapKeyType,
                  text: value,
                })
              )}
              placeholder="Select Tag"
              value={selectedTag}
              onChange={(value): void => {
                setSelectedTag(value as TagCategoryTypeMapKeyType);
              }}
              clearable
            />
          )}
          <Button
            data-testid="template-builder-content-quick-add-variable-create-action"
            variant="secondary"
            size="sm"
            compact
            disabled={
              !variableKey || !selectedVariableType || !!validationError
            }
            onClick={(): void => {
              onCreateVariable({
                key: variableKey,
                type: selectedVariableType,
                tag: showTagDropdown ? selectedTag : undefined,
              });
              setIsCreatingNewVariable(false);
              setSelectedVariableType(undefined);
              setVariableKey("");
              setSelectedTag(undefined);
            }}
            content="Create"
          />
          {validationError && (
            <p className="tw-text-red-500 tw-text-sm">{validationError}</p>
          )}
        </div>
      )}
    </div>
  );
};
