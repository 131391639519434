import { ProductId } from "../product";
import {
  CopyAssistantLangStringTemplate,
  PromptGroup,
  OpenAIAssistant,
} from "./types";
import { UseGenericFetch, useGenericFetch } from "../../api/customFetchHook";
import {
  GetModelsResponse,
  getModels,
  GetPromptsResponse,
  getPrompts,
  getPromptGroups,
  getCustomerLangStringTemplates,
  getProductSpecificOverwrites,
  ProductSpecificOverwrite,
  getOpenAIAssistants,
} from "../../api/gptApi";
import { TextBlock, getTextBlocks } from "../../api/textBlockApi";

export function useGetModels(): UseGenericFetch<GetModelsResponse> {
  return useGenericFetch<GetModelsResponse>([], getModels);
}

export function useGetPrompts({
  productId,
  onlyTranslationPrompts,
}: {
  productId?: ProductId;
  includeTranslationPrompts?: boolean;
  onlyTranslationPrompts?: boolean;
} = {}): UseGenericFetch<GetPromptsResponse> {
  return useGenericFetch<GetPromptsResponse>({}, getPrompts, {
    productId,
    onlyTranslationPrompts,
  });
}

export function useGetPromptGroups(): UseGenericFetch<PromptGroup[]> {
  return useGenericFetch<PromptGroup[]>([], getPromptGroups);
}

export function useGetCustomerLangStringTemplates(): UseGenericFetch<
  CopyAssistantLangStringTemplate[]
> {
  return useGenericFetch<CopyAssistantLangStringTemplate[]>(
    [],
    getCustomerLangStringTemplates
  );
}

export function useGetTextBlocks(
  productId: ProductId
): UseGenericFetch<TextBlock[]> {
  return useGenericFetch<TextBlock[]>([], getTextBlocks, productId);
}

export function useGetProductSpecificOverwrites(
  productId: ProductId
): UseGenericFetch<ProductSpecificOverwrite[]> {
  return useGenericFetch<ProductSpecificOverwrite[]>(
    {},
    getProductSpecificOverwrites,
    productId
  );
}

export function useGetOpenAIAssistants(): UseGenericFetch<OpenAIAssistant[]> {
  return useGenericFetch<OpenAIAssistant[]>([], getOpenAIAssistants);
}
