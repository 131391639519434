import React, { useRef } from "react";
import range from "lodash/range";
import { Button, Icon } from "../../../components/tailwind";
import { MachineTranslationConfigGroup } from "./types";

const LoadingListItems: React.FC<{ amount: number }> = ({ amount }) => {
  return (
    <>
      {range(0, amount).map((item) => {
        return (
          <li
            key={item}
            className="tw-h-14 tw-animate-pulse tw-rounded-lg tw-bg-gray-200"
            data-testid="loading-list-item"
          ></li>
        );
      })}
    </>
  );
};

type Props = {
  configGroups: MachineTranslationConfigGroup[];
  selectedId: number;
  onSelect: (id: number) => void;
  onCopy: (id: number) => void;
  isCopying: boolean;
  isLoading: boolean;
};

export const MachineTranslationConfigList: React.FC<Props> = ({
  configGroups,
  selectedId,
  onSelect,
  onCopy,
  isCopying,
  isLoading,
}) => {
  const copyClicked = useRef(false);
  return (
    <ul className="tw-flex tw-max-h-screen tw-flex-col tw-gap-2 tw-divide-gray-200 tw-overflow-auto">
      {isLoading && <LoadingListItems amount={6} />}
      {configGroups.map(({ name, id }) => (
        <li
          data-testid={`machine-translation-config-list-item-${id}`}
          aria-selected={selectedId === id && !isCopying ? "true" : "false"}
          key={id}
          className="tw-flex tw-cursor-pointer tw-rounded-lg tw-px-3 tw-py-3.5 tw-font-semibold tw-text-blue-500 tw-transition-all hover:tw-bg-gray-100 aria-selected:tw-bg-gray-100 aria-selected:tw-font-bold"
          onClick={(): void => {
            if (copyClicked.current) {
              copyClicked.current = false;
              return;
            }
            onSelect(id);
          }}
        >
          {name} ({id})
          <span
            data-balloon="Copy"
            data-balloon-pos="left"
            className="tw-ml-auto"
          >
            <Button
              data-testid={`machine-translation-config-list-copy-from-item-${id}`}
              compact
              variant="primary-alt"
              icon
              size="sm"
              onClick={(): void => {
                copyClicked.current = true;
                onCopy(id);
              }}
            >
              <Icon name="content_copy" />
            </Button>
          </span>
        </li>
      ))}
    </ul>
  );
};
