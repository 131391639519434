import React from "react";
import { Icon, Radio, DropdownProps } from "semantic-ui-react";
import Styled from "styled-components";
import { ProductListAction } from "../ProductList";
import { StyledDivider } from "./ProductFilterAddFilter";
import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";
import { Filterkeys } from "../ProductListTypes";

export const FontSizeParagraph = Styled.p`
  font-size: 10pt;
`;
export const FontSizeHoverEffectIcon = Styled(Icon)`
  &&& {
        font-size: 10pt;
        opacity: 0.6;
        transition: transform 50ms ease-in-out;
        &:hover {
            opacity: 1;
            transform: scale(1.05)
  }
`;
export const FlexCenter = Styled.div`
  display: flex;
  padding: 0 1.2rem 0 0 !important;
  align-items: center;
  position: relative;
  .divider {
        display: none;
  }
  div + & {
        .divider {
            display: initial;
        }
  }
`;

type Props = {
  action: ProductListAction;
  defaultValue: string;
  toggleFilter?: (
    filterKey: Filterkeys,
    filterValue: boolean,
    action: ProductListAction,
    value: string
  ) => void;
  label: string;
  onAction: (action: ProductListAction, value: string) => void;
  options: {
    key: number;
    text: string;
    value: string;
    content?: React.ReactElement;
  }[];
};

export const ProductFilterChoice: React.FC<Props> = ({
  action,
  defaultValue,
  toggleFilter,
  label,
  onAction,
  options,
}) => {
  options.forEach(
    (el) =>
      (el.content = (
        <Radio
          checked={defaultValue === el.value}
          data-testid={`${label.replace(" ", "-").toLowerCase()}-option-${
            el.key
          }`}
          name={label.replace(" ", "-").toLowerCase()}
          label={el.text}
          value={el.value}
        />
      ))
  );

  return (
    <FlexCenter>
      <StyledDivider className={"divider"} />
      <FontSizeParagraph>{label}:</FontSizeParagraph>
      <StyledDropdownBase
        className={"tiny"}
        basic
        button
        data-testid={label.replace(" ", "-").toLowerCase()}
        trigger={
          <span>
            {options.find(({ value }) => value === defaultValue).text}
          </span>
        }
        onChange={(e: React.SyntheticEvent, { value }: DropdownProps): void =>
          onAction(action, value as string)
        }
        options={options}
        selected={defaultValue}
        value={defaultValue}
      />
      {label.includes("Parent-child") && (
        <FontSizeHoverEffectIcon
          className={"times circle link icon"}
          data-testid={"close-btn-choice"}
          onClick={(): void =>
            toggleFilter(Filterkeys.ParentChild, false, action, "all")
          }
          size={"small"}
        />
      )}
    </FlexCenter>
  );
};
