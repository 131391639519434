import React from "react";
import { Button, Modal } from "semantic-ui-react";

type Props = {
  headerDescription: string;
  contentDescription?: string;
  open: boolean;
  onConfirm?: (confirm: boolean) => void;
};

export const ConfirmationModal: React.FC<Props> = ({
  headerDescription,
  contentDescription,
  open,
  onConfirm,
}) => {
  return (
    <Modal open={open} data-testid={"confirmation-modal"}>
      <Modal.Header>{headerDescription}</Modal.Header>
      {contentDescription ? (
        <Modal.Content scrolling>
          <Modal.Description>{contentDescription}</Modal.Description>
        </Modal.Content>
      ) : null}
      <Modal.Actions className="modal-actions">
        <Button
          onClick={(): void => onConfirm(false)}
          data-testid={"confirmation-modal-no"}
        >
          No
        </Button>
        <Button
          color="red"
          onClick={(): void => onConfirm(true)}
          data-testid={"confirmation-modal-yes"}
        >
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
