import React from "react";
import { TreeIndex, TreeItem, TreeNode } from "react-sortable-tree";
import { AxiosResponse } from "axios";
import { SEOScore } from "../products/seo-tab/ProductDetailSEOTab";

export interface DisplayableTag {
  helper_display_name?: string;
  master_display_name: string;
  seo_scores_volume?: string;
  vocab_type?: string;
  comment?: string;
}

export interface FullTag {
  allow_children: boolean;
  category: string;
  count?: number;
  expanded?: boolean;
  headline: string;
  helper_display_name?: string;
  headline_pl: string;
  id: string;
  master_display_name: string;
  seo_scores_volume?: string;
  settings_schema?: any;
  type: string;
  value: string;
  vocab_type?: string;
  vocabulary_id: number;
  tags: FullTag[];
}

export type GetNodeKeyHandler = (data: TreeNode & TreeIndex) => string | number;
export type HandleRequestWordHandler = (
  value: string,
  selectType: string,
  callback: (response: AxiosResponse, selectType: string) => void
) => void;

export interface TagOption {
  key?: number;
  value: string;
  text: string | React.ReactElement;
}

// Used by TagTree and Advanced components.
// This is what's exported by generate_tags_to_tree_mapping
// FIXME: replaced with a normal tag data if possible.
export interface TreeData extends TreeItem {
  allow_children?: boolean;
  category: string;
  children?: TreeData[];
  helper_display_name?: string;
  id: string;
  master_display_name: string;
  preview_text?: string;
  seo_scores_volume?: string;
  settings?: any;
  settings_schema?: any;
  selectedTagSchema?: any[];
  selectedSubpartSchema?: VocabularyTreeData;
  subpartOptions?: TagOption[];
  tagOptions?: TagOption[];
  tags?: TreeData[] | string[];
  type?: string;
  value?: string;
  vocab_type?: string;
}

export interface VocabularyTreeData extends TreeData {
  default_number?: string;
  expanded?: boolean;
  hypernym_id?: number;
  hypernym_value?: string;
  placeholder?: string;
  prepopulateMenu?: boolean;
  seo_scores?: SEOScore;
  setting_schema?: any;
  tag_id?: string;

  // FullTag
  _id?: string;
  _subpart_id?: string;
  comment?: string;
  headline?: string;
  headline_pl?: string;
  swedish?: string;
  vocabulary_id?: number;
  vocabulary_request_status?: string;
}

export interface TagCategoryAndType {
  category: string;
  type?: string;
}

export function isKind(tag: TagCategoryAndType): boolean {
  return tag.category === "identifier" && tag.type == "kind";
}

export function isDummy(tag: TagCategoryAndType): boolean {
  return tag.category === "dummy" && tag.type == "dummy";
}

export function isSubpart(tag: TagCategoryAndType): boolean {
  return tag.category === "subpart" && tag.type == "subpart";
}

export function isMaterial(tag: TagCategoryAndType): boolean {
  return tag.category == "material";
}

export function isNumber(tag: TagCategoryAndType): boolean {
  return tag.category === "kind" && tag.type == "number";
}

export function isBrand(tag: TagCategoryAndType): boolean {
  return tag.category === "identifier" && tag.type == "brand";
}

export function isName(tag: TagCategoryAndType): boolean {
  return tag.category === "identifier" && tag.type == "name";
}

export function isTemplate(tag: TagCategoryAndType): boolean {
  return tag.category === "template" && tag.type == "template";
}

export function isTemplateSection(tag: TagCategoryAndType): boolean {
  return tag.category === "template" && tag.type == "section";
}

export type formatTagParams = {
  includeSubTagNames?: boolean;
  showTagTypeHints?: boolean;
  showVocabType?: boolean;
};

export const formatTag = (
  initialData: DisplayableTag | null,
  {
    includeSubTagNames = false,
    showTagTypeHints = false,
    showVocabType = false,
  }: formatTagParams
): string | null => {
  if (initialData == null) {
    return null;
  }

  const {
    master_display_name,
    helper_display_name,
    vocab_type,
    seo_scores_volume,
    comment,
  } = initialData;
  let displayName = master_display_name;
  if (includeSubTagNames) {
    if (helper_display_name) {
      displayName += ` (${helper_display_name})`;
    }
    if (vocab_type) {
      if (showVocabType) {
        displayName += ` (${vocab_type})`;
      }
      if (showTagTypeHints) {
        displayName += ` (${vocab_type === "kind" ? "attribute" : vocab_type})`;
      }
    }
    if (seo_scores_volume) {
      displayName += ` (Vol: ${seo_scores_volume}/mo)`;
    }
    if (comment) {
      displayName += ` (${comment})`;
    }
  }

  return displayName;
};

export const getTagTypeValue = (value: string): string => {
  if (value === "sg") {
    return "Singular";
  } else if (value === "pl") {
    return "Plural";
  } else if (value === "mass") {
    return "Singular, no article (a/an)";
  } else if (value === "quant") {
    return "Quantity";
  } else {
    return value;
  }
};
