// "Dress (Vol: 12000/mo)" -> "Dress"
export function maybeStripSearchVolume(name: string | null): string {
  if (name === null) {
    return null;
  }
  const result = /(.*)\(Vol: \d+\/mo\)/g.exec(name);
  if (result) {
    return result[1];
  }
  return name;
}
