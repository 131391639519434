import React from "react";
import { Text } from "../../components/Text";
import { Button, Grid } from "semantic-ui-react";
import { RequestWordTranslateType } from "./RequestWordModal";
type Props = {
  submitAction: (translateType: RequestWordTranslateType) => void;
  showTranslate: boolean;
  showMachineTranslate: boolean;
};
export const RequestWordModalPickTranslationType: React.FC<Props> = ({
  submitAction,
  showTranslate,
  showMachineTranslate,
}) => {
  return (
    <Grid divided>
      {showTranslate && (
        <Grid.Row centered>
          <Grid.Column textAlign="center" width={8}>
            <Button
              data-testid="request-word-modal-pick-translation-type-translate"
              color="red"
              content="Send to Textual for translation"
              fluid
              onClick={(): void => {
                submitAction(RequestWordTranslateType.TRANSLATE);
              }}
            />
            <Text color="grey" size="small" lessMargin>
              Allow for one week delivery.
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      {showMachineTranslate && (
        <Grid.Row centered>
          <Grid.Column textAlign="center" width={8}>
            <Button
              data-testid="request-word-modal-pick-translation-type-machine-translate"
              color="red"
              content="Use machine translation"
              fluid
              onClick={(): void => {
                submitAction(RequestWordTranslateType.MACHINE_TRANSLATE);
              }}
            />
            <Text color="grey" size="small" lessMargin>
              You should check the resulting translation manually.
            </Text>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row centered>
        <Grid.Column textAlign="center" width={8}>
          <Button
            data-testid="request-word-modal-pick-translation-type-customer-translate"
            color="red"
            content="I will add translations manually"
            fluid
            onClick={(): void => {
              submitAction(RequestWordTranslateType.CUSTOMER_TRANSLATE);
            }}
          />
          <Text color="grey" size="small" lessMargin>
            If you want to add translations yourself, press here.
          </Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
