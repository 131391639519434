import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { LabelSelectorPopupVariant } from "./LabelSelectorPopupVariant";
import {
  getAllLabelsForTemplate,
  updateActiveLabels,
} from "../../api/productTemplateVocabularyLabelsApi";
import { RootState } from "../../utils/store";
import { TemplateLabel } from "../template";
import { LabelSelectorAllVisibleVariant } from "./LabelSelectorAllVisibleVariant";

export const MAX_LABEL_AMOUNT_BEFORE_USING_POPUP = 15;

type Props = {
  productId: string;
  templateId: string;
  templateTagId: string;
  disableWidth?: boolean;
};

export const TemplatesTabLabelSelector: React.FC<Props> = ({
  productId,
  templateId,
  templateTagId,
  disableWidth,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(true);
  const [options, setOptions] = useState<TemplateLabel[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<TemplateLabel[]>([]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      (async (): Promise<void> => {
        await getLabels();
      })();
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const getLabels = async (): Promise<void> => {
    const { activeLabels, labels } = await getAllLabelsForTemplate(
      productId,
      Number(templateId),
      templateTagId,
      token
    );
    if (mounted.current) {
      setSelectedLabels(activeLabels);
      setOptions(labels);
    }
  };

  const handleUpdateActiveLabels = async (labelId: number): Promise<void> => {
    try {
      await updateActiveLabels(
        labelId,
        productId,
        Number(templateId),
        templateTagId,
        token
      );
      await getLabels();
    } finally {
      window.queuePreviewTextGeneration();
    }
  };
  if (
    selectedLabels.length + options.length >
    MAX_LABEL_AMOUNT_BEFORE_USING_POPUP
  ) {
    return (
      <>
        <LabelSelectorPopupVariant
          options={options}
          selectedLabels={selectedLabels}
          templateTagId={templateTagId}
          updateActiveLabels={handleUpdateActiveLabels}
          disableWidth={disableWidth}
        />
      </>
    );
  }
  return (
    <>
      <LabelSelectorAllVisibleVariant
        options={options}
        selectedLabels={selectedLabels}
        templateTagId={templateTagId}
        updateActiveLabels={handleUpdateActiveLabels}
        disableWidth={disableWidth}
      />
    </>
  );
};
