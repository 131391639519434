import React from "react";

export type Props = {
  name: string;
  className?: string;
  onClick?: () => void;
};

export const Icon: React.FC<Props> = ({ name, className, ...rest }) => {
  return (
    <i className={`material-icons ${className || ""}`} {...rest}>
      {name}
    </i>
  );
};
