import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, List, Popup } from "semantic-ui-react";
import { FlexDiv } from "./LabelSelectorAllVisibleVariant";
import { TemplateLabel } from "../template";
import { TemplateLabelChip } from "./TemplateLabelChip";

type Props = {
  selectedLabels: TemplateLabel[];
  options: TemplateLabel[];
  templateTagId: string;
  updateActiveLabels: (id: number) => Promise<void>;
  disableWidth?: boolean;
};

export const LabelSelectorPopupVariant: React.FC<Props> = ({
  selectedLabels,
  options,
  templateTagId,
  updateActiveLabels,
  disableWidth,
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const filteredOptions = useMemo(() => {
    return options.filter(({ name }) =>
      name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [searchInput]);

  useEffect(() => {
    if (!open) {
      setSearchInput("");
    }
  }, [open]);

  const renderLabels = (
    labels: TemplateLabel[],
    isOptions: boolean
  ): React.ReactElement[] => {
    return labels.map(({ name, description, id }) => {
      if (isOptions) {
        return (
          <List.Item key={`${id}-${templateTagId}`}>
            <TemplateLabelChip
              content={name}
              description={description}
              labelProps={{
                as: "a",
                size: "mini",
                onClick: async (): Promise<void> => {
                  await updateActiveLabels(id);
                  setOpen(false);
                },
              }}
            />
          </List.Item>
        );
      }
      return (
        <TemplateLabelChip
          content={name}
          description={description}
          isSelected
          key={`${id}-${templateTagId}`}
          labelProps={{
            size: "mini",
            onRemove: async (): Promise<void> => {
              await updateActiveLabels(id);
              setOpen(false);
            },
          }}
        />
      );
    });
  };
  return (
    <FlexDiv disableWidth={disableWidth}>
      {renderLabels(selectedLabels, false)}
      {!!options.length && (
        <Popup
          on="click"
          open={open}
          onClose={(): void => setOpen(false)}
          onOpen={(): void => setOpen(true)}
          position="left center"
          trigger={
            <Button
              basic
              color="red"
              compact
              data-testid="add-label-popup-btn"
              size="mini"
              // Can't use styled-components here as it breaks the Popup component
              style={{ margin: 0, marginTop: "4px", width: "max-content" }}
            >
              Add label
            </Button>
          }
        >
          <Input
            icon="search"
            onChange={(_, { value }): void => setSearchInput(value)}
            placeholder="Search..."
          />
          <List>
            {renderLabels(searchInput ? filteredOptions : options, true)}
          </List>
        </Popup>
      )}
    </FlexDiv>
  );
};
