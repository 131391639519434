export const selectedPromptTestIds = {
  buttons: {
    generate: "selected-prompt-generate-button",
    saveNew: "selected-prompt-save-new-button",
    generateAndTranslate: "selected-prompt-generate-and-translate-button",
  },
  tag: (tag: string) => `selected-prompt-tag-${tag}` as const,
  instruction: {
    label: "selected-prompt-instruction-label",
    textarea: "selected-prompt-instruction-textarea",
    revert: "selected-prompt-instruction-revert",
  },
  data: {
    label: "selected-prompt-data-label",
    textarea: "selected-prompt-data-textarea",
    revert: "selected-prompt-data-revert",
    loading: "selected-prompt-data-loading",
    dimmer: "selected-prompt-data-dimmer",
  },
  image: {
    label: "selected-prompt-image-label",
    input: "selected-prompt-image-input",
    error: "selected-prompt-image-error",
  },
} as const;

export const selectedPromptGroupTestIds = {
  buttons: {
    generate: "selected-prompt-group-generate-button",
    generateAndTranslate: "select-prompt-group-generate-and-translate-button",
  },
  displayName: "selected-prompt-group-display-name",
  tag: (tag: string) => `selected-prompt-group-tag-${tag}` as const,
} as const;

export const selectedPromptGroupPromptItemTestIds = (promptId: number) =>
  ({
    displayName: `selected-prompt-group-prompt-item-${promptId}-display-name`,
    tag: (tag: string) =>
      `selected-prompt-group-prompt-item-${promptId}-tag-${tag}` as const,
    buttons: {
      settings: `selected-prompt-group-prompt-item-${promptId}-settings-button`,
      generate: `selected-prompt-group-prompt-item-${promptId}-generate-button`,
      generateAndTranslate: `selected-prompt-group-prompt-item-${promptId}-generate-and-translate-button`,
    },
    accordion: {
      content: `selected-prompt-group-prompt-item-${promptId}-accordion-content`,
      title: `selected-prompt-group-prompt-item-${promptId}-accordion-title`,
      loading: `selected-prompt-group-prompt-item-${promptId}-accordion-loading`,
      warning: `selected-prompt-group-prompt-item-${promptId}-accordion-warning`,
      instruction: {
        label: `selected-prompt-group-prompt-item-${promptId}-instruction-label`,
        textarea: `selected-prompt-group-prompt-item-${promptId}-instruction-textarea`,
        revert: `selected-prompt-group-prompt-item-${promptId}-instruction-revert`,
      },
      data: {
        label: `selected-prompt-group-prompt-item-${promptId}-data-label`,
        textarea: `selected-prompt-group-prompt-item-${promptId}-data-textarea`,
        revert: `selected-prompt-group-prompt-item-${promptId}-data-revert`,
        loading: `selected-prompt-group-prompt-item-${promptId}-data-loading`,
        dimmer: `selected-prompt-group-prompt-item-${promptId}-data-dimmer`,
        copy: `selected-prompt-group-prompt-item-${promptId}-data-copy`,
      },
    },
  } as const);

export const leftFloatedMenuTestIds = {
  openPromptList: "left-floated-menu-open-prompt-list-button",
  openPromptGroupList: "left-floated-menu-open-prompt-group-list-button",
  createPrompt: "left-floated-menu-create-prompt-button",
  createPromptGroup: "left-floated-menu-create-prompt-group-button",
  buttonsWrapper: "left-floated-menu-button-wrapper",
} as const;

export const generatedResultsTestIds = {
  loadingComponents: {
    fullCardLoading: "generated-results-full-card-loading",
    partialCardLoading: "generated-results-partial-card-loading",
  },
  cardGroup: "generated-results-card-group",
} as const;

export const generatedSingleResultTestIds = (promptName: string) =>
  ({
    card: `generated-single-result-${promptName}-card`,
    loading: `generated-single-result-${promptName}-loading`,
    amountOfVariants: `generated-single-result-${promptName}-amount-of-variants`,
    buttons: {
      remove: `generated-single-result-${promptName}-remove-button`,
      pickVariantLeft: `generated-single-result-${promptName}-pick-variant-left-button`,
      pickVariantRight: `generated-single-result-${promptName}-pick-variant-right-button`,
      setActive: `generated-single-result-${promptName}-set-active`,
      cancelStream: `generated-single-result-${promptName}-cancel-stream`,
    },
    displayName: `generated-single-result-${promptName}-display-name`,
    isActive: `generated-single-result-${promptName}-is-active`,
    modal: {
      header: `generated-single-result-${promptName}-modal-header`,
      content: {
        form: `generated-single-result-${promptName}-modal-content-form`,
        to: `generated-single-result-${promptName}-modal-content-to`,
      },
      buttons: {
        cancel: `generated-single-result-${promptName}-modal-buttons-cancel`,
        update: `generated-single-result-${promptName}-modal-buttons-save`,
      },
    },
    selectedVariant: (index: number) =>
      ({
        accordion: {
          title: `generated-single-result-selected-variant-${promptName}-${index}-accordion-title`,
          content: `generated-single-result-selected-variant-${promptName}-${index}-accordion-content`,
          update: `generated-single-result-selected-variant-${promptName}-${index}-accordion-update`,
          usedData: `generated-single-result-selected-variant-${promptName}-${index}-accordion-used-data`,
          usedInstruction: `generated-single-result-selected-variant-${promptName}-${index}-accordion-used-instruction`,
        },
      } as const),
  } as const);

export const toolbarTopTestIds = {
  contextDropdown: "toolbar-top-context-dropdown",
  buttons: {
    settings: "toolbar-top-settings-button",
    copy: "toolbar-top-copy-button",
    create: "toolbar-top-create-button",
    translateAllActiveTextBlocks: "toolbar-top-translate-all",
  },
  selectPromptGroup: {
    dropdown: "toolbar-top-select-prompt-group-dropdown",
    filterTags: "toolbar-top-select-prompt-group-filter-tags",
  },
  selectPrompt: {
    dropdown: "toolbar-top-select-prompt-dropdown",
    filterTags: "toolbar-top-select-prompt-filter-tags",
  },
  advanceFilter: {
    searchInput: "toolbar-top-advance-filter-search-input",
    showRail: "toolbar-top-advance-filter-show-rail",
    rail: "toolbar-top-advance-filter-rail",
  },
} as const;
