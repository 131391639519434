const CATEGORIES = {
  IDENTIFIER: "identifier",
  QUALITY: "quality",
};

const TYPES = {
  KIND: "kind",
  PROPERTY: "property",
};

export { CATEGORIES, TYPES };
