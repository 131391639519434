import React from "react";
import styled from "styled-components";
import { SEORecommendation } from "./ProductRecommendations";

export function formatSeoCompetition(competition: string): string {
  switch (competition) {
    case "1":
      return "Low";
    case "2":
      return "Medium";
    case "3":
      return "High";
    default:
      return "Unknown";
  }
}

interface Props {
  horizontal?: boolean;
  onTagsAction?: (recommendation: SEORecommendation) => void | Promise<void>;
  seoRecommendations: SEORecommendation[];
}

const StyledA = styled.a`
  & {
    :hover {
      cursor: pointer;
    }
  }
`;

export const SEOFieldRecommendations: React.FC<Props> = ({
  horizontal = false,
  onTagsAction,
  seoRecommendations,
}) => {
  if (seoRecommendations.length == 0) {
    return null;
  }

  return (
    <div
      className={horizontal ? "recommend-box-horizontal" : "recommend-box"}
      data-testid={"recommend-seo"}
    >
      {seoRecommendations.map((recommendation) => (
        <StyledA
          className="recommend seo-recommend"
          data-seo-competition={formatSeoCompetition(
            recommendation.competition
          )}
          data-testid={`recommend-seo-${recommendation.id}`}
          key={recommendation.id}
          onClick={(): void => {
            onTagsAction?.(recommendation);
          }}
        >
          {recommendation.name} (Vol: {recommendation.search_volume}/mo)
        </StyledA>
      ))}
    </div>
  );
};
