import { LanguageCode } from "../customers/customerlanguages";

export const TagCategoryTypeMap = {
  "quality/color": "Color",
  "material/any": "Material",
  "identifier/kind": "Attribute",
  "quality/property": "Property",
  "phrase/phrase": "Phrase",
  "phrase/sentence": "Fixed phrase",
  "feature/designed_in": "Designed in",
  "phrase/position": "Position",
  "feature/perfect_for": "Occasion",
  "feature/manufactured_in": "Made in",
} as const;

export type TagCategoryTypeMapKeyType = keyof typeof TagCategoryTypeMap;

export const TemplateFieldTypeMap = {
  A: "Advanced",
  B: "Boolean",
  P: "Bullet List",
  F: "Float",
  R: "Free Text",
  I: "Integer",
  S: "String",
  T: "Tag",
  L: "Tag Tree",
  X: "Text",
  N: "LangString",
} as const;

export const ExplainTemplateField = {
  S:
    "An untranslated string of characters. Use for measurements, names, certifications etc",
  T:
    "A single translated tag. Can be attributes, properties, colors, sentences etc",
  L:
    "Build a translated sentence from 1 or multiple tags in a tree-like structure.",
  N: "Type text freely for every language. Supports machine translation.",
} as const;
export type ExplainTemplateFieldKeyType = keyof typeof ExplainTemplateField;
export type TemplateFieldTypeMapKeyType = keyof typeof TemplateFieldTypeMap;

export type TemplateLexicon = {
  id?: number;
  language_code: LanguageCode;
  template: string;
  template_variants?: string[];
  in_manual_translation: boolean;
  translated_by_machine_translate?: boolean;
  translator_approved?: string | null; // approved date
};

export type TemplateField = {
  id?: number;
  key: string;
  type: TemplateFieldTypeMapKeyType;
  tag?: TagCategoryTypeMapKeyType;
};
export interface TemplateLabel {
  default?: boolean;
  description: string;
  id: number;
  label_id?: number; // When TemplateLabel is used in Template or TemplateItem this will be present
  name: string;
  used_in_templates?: number; // Currently only used in ManageTemplateLabels.tsx
}

export type Template = {
  display_name: string;
  id: string;
  labels?: TemplateLabel[];
  name?: string;
  template_lexicons?: TemplateLexicon[];
  template_fields?: TemplateField[];
  description?: string;
  annotate_content?: boolean;
};

export interface TemplateItem extends Template {
  jinja?: string;
  items?: TemplateItem[];
}
