import { SortOption } from "../api/vocabularyApi";
import React from "react";
import { FilteredSearchModal } from "../products/sections-tab/templates/FilteredSearchModal";
import { TemplateItem } from "./template";

type Props = {
  name: string;
  onFetchItems: () => Promise<TemplateItem[]>;
  onRenderRow: (item: TemplateItem) => JSX.Element;
  onSortChange?: (order: SortOption) => Promise<TemplateItem[]>;
  onSubmit: (item: TemplateItem) => Promise<void> | void;
  title?: string;
};

export const TemplateSelector: React.FC<Props> = ({
  name,
  onFetchItems,
  onRenderRow,
  onSortChange,
  onSubmit,
  title,
}) => {
  return (
    <FilteredSearchModal<TemplateItem>
      smallButton={true}
      name={name}
      onFetchItems={onFetchItems}
      onRenderRow={onRenderRow}
      onSortChange={onSortChange}
      onSelectItem={onSubmit}
      title={title}
    />
  );
};
