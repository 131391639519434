import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class TextPatternSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      textPatternSuggestions: [],
    };
  }

  componentDidMount() {
    const { initialData, availableChoices, onLoaded } = this.props;
    onLoaded(this);
    let textPatternSuggestions = [];
    if (availableChoices && availableChoices.length > 0) {
      textPatternSuggestions = availableChoices.map((item) => ({
        key: item.id,
        text: item.display_name,
        value: item.id,
        channel: item.channel,
      }));
    }
    const value = initialData.map((item) => item.id);
    this.setState({ value, textPatternSuggestions });
  }

  handleTextPatternChange = (value) => {
    const { textPatternSuggestions } = this.state;
    const { onDataChanged } = this.props;
    this.setState({ value });
    const selectedValues = [];

    value.map((item) => {
      const matchedIndex = textPatternSuggestions.findIndex(
        (textPattern) => textPattern.value === item
      );

      if (matchedIndex > -1) {
        selectedValues.push({
          id: textPatternSuggestions[matchedIndex].key,
          display_name: textPatternSuggestions[matchedIndex].text,
          channel: textPatternSuggestions[matchedIndex].channel,
        });
      }
    });
    onDataChanged(this, selectedValues);
  };

  render() {
    const { componentId } = this.props;
    const { textPatternSuggestions } = this.state;
    return (
      <Dropdown
        clearable
        key={componentId}
        options={textPatternSuggestions}
        placeholder="Select Text Pattern"
        renderLabel={(item) => (
          <Label
            key={item.key}
            value={item.value}
            content={item.text}
            color="black"
            basic
            className="no-border"
          />
        )}
        search
        selection
        fluid
        multiple
        value={this.state.value}
        onChange={(e, { value }) => this.handleTextPatternChange(value)}
        selectOnBlur={false}
        closeOnBlur={true}
        closeOnChange
      />
    );
  }
}

TextPatternSelector.propTypes = {
  onDataChanged: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
  initialData: PropTypes.array,
  componentId: PropTypes.string,
};
