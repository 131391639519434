import React from "react";
import { BalloonDiv } from "../../components/BalloonDiv";
import { VocabularyLookupNewComp } from "../../vocabulary/VocabularyLookupNew";
import { NOOP } from "../../index";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { ProductTagAction } from "../../api/action";
import { CustomerSettings } from "../../customers/customersettings";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import { VocabularyTreeData } from "../../utils/tagutils";

interface Props {
  details: ProductDetailInfo;
  onTagsAction?: onTagsActionCallback;
  quickSearchMessage: string | null;
  customerSettings: CustomerSettings;
  selected: SidebarItem;
}

/**
 * Component that allows you to add any given tag
 * @param props
 * @constructor
 */
export const QuickSearchField: React.FC<Props> = ({
  customerSettings,
  details,
  onTagsAction,
  quickSearchMessage,
  selected,
}) => (
  <div className="global-search">
    <BalloonDiv
      className={"label"}
      value={details.microcopies["easy_view_global_search"]}
    >
      <label htmlFor="id_global_search">Quick Search</label>
    </BalloonDiv>
    <VocabularyLookupNewComp
      {...customerSettings}
      componentId={details.createComponentId()}
      disableAutoAdd={false}
      excludeFixedPhrases={!customerSettings.showFixedPhrases}
      isMultiple={true}
      language={details.getLanguage()}
      limit={20}
      onLoaded={NOOP}
      onVocabularyAdded={(value: VocabularyTreeData): void => {
        onTagsAction?.({
          action: ProductTagAction.ADD_QUICK_SEARCH,
          parent: selected.isSubpart ? selected.id : null,
          value,
        });
      }}
      placeholder={details.microcopies["easy_view_global_search"]}
      showTagTypeHints={false}
      showVocabType={true}
      textualAppName={details.textualAppName}
      vocabularyRequestProductId={details.productId}
      vocabularyRequestAllowed={false}
    />
    {quickSearchMessage && (
      <span className={"global-search-message"}>{quickSearchMessage}</span>
    )}
  </div>
);
