import React, { useRef, useState, useEffect, useCallback } from "react";
import { NoCodeNode, useNoCodeParser } from "./useNoCodeParser";
import debounce from "lodash/debounce";
import { store } from "../../../utils/store";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import { TemplateField } from "../../template";

import { HTMLTextNode } from "./HTMLTextNode";
import { HTMLVariableNode } from "./HTMLVariableNode";
import { HTMLHiddenChar } from "./HTMLHiddenChar";
import {
  DRAGOVER_PADDING_ELEMENT_SELECTOR,
  findClosestDroppableElement,
  prepareNewVariable,
  resetAllDragClasses,
  switchDragIndicatorToThisElement,
} from "./utils";

type NoCodeEditorReturn = {
  NoCodeEditor: React.FC;
  onAddVariable: (variableKey: string) => void;
  dragHandlers: {
    onDragEnd: (event: React.DragEvent<HTMLButtonElement>) => void;
    onDragStart: (event: React.DragEvent<HTMLButtonElement>) => void;
  };
  errorParsingNodes: string;
};

type NoCodeEditorProps = {
  input: string;
  updateInput: (input: string) => void;
  availableVariables: TemplateField[];
  placeholder: string;
  dependencies?: any[];
};

export const useNoCodeEditor = ({
  input,
  updateInput,
  availableVariables,
  placeholder,
  dependencies = [],
}: NoCodeEditorProps): NoCodeEditorReturn => {
  const allElementsBoundingClientRect = useRef<
    Record<string, ReturnType<Element["getBoundingClientRect"]>>
  >({});
  const mounted = useRef(false);
  const currentDraggingElement = useRef<Element | null>(null);
  const dropPosition = useRef<"left" | "right">();
  const noCodeEditorRef = useRef<HTMLDivElement>(null);
  const handleChangeInflight = useRef(false);
  const cancelDebounce = useRef(false);
  const [nodes, setNodes] = useState<NoCodeNode[]>([]);
  const [errorParsingNodes, setErrorParsingNodes] = useState<string>();
  const createNodes = (newInput: string = input): void => {
    if (errorParsingNodes) return;
    setNodes([]);
    const emptyInput = newInput
      .replaceAll("\n", "")
      .split("")
      .every((char) => !char);

    if (emptyInput) return;
    try {
      const nodes = useNoCodeParser(newInput, availableVariables);
      setNodes(nodes);
    } catch (error) {
      console.error(error);
      setErrorParsingNodes(error);
      store.dispatch(
        setDjangoToastOpen({
          content: "No code editor failed to render. Fallback to simple editor",
          appearance: NotificationAppearance.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    createNodes();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, dependencies);

  //! This function is destruktive and expects the caller to commit the new value (e.g create new nodes).
  const sanitizeInput = (element: HTMLDivElement): string => {
    if (errorParsingNodes) return;
    const children = element.children;
    let html = "";

    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);

      if (child.getAttribute("data-node-type") === "variable") {
        child.innerHTML =
          child.getAttribute("data-node-value") || child.innerHTML;
      }
      if (child.getAttribute("data-break-element")) {
        const insertedBreak = child.querySelectorAll("#inserted-break");
        insertedBreak.forEach((inserted) => {
          child.removeChild(inserted);
        });
      }
    }
    html += element.innerText
      .replaceAll("\n", "<addedbr>")
      .replace(/[\u200B-\u200D\uFEFF]/g, "");
    if (html.endsWith("<addedbr>")) {
      html = html.slice(0, -"<addedbr>".length);
    }
    return html.replaceAll("<addedbr>", "<br>");
  };

  const resetFocusAndSelection = (): void => {
    if (errorParsingNodes) return;
    noCodeEditorRef.current?.focus();
    const selection = window.getSelection();
    if (selection && noCodeEditorRef.current) {
      const range = document.createRange();
      range.setStart(
        noCodeEditorRef.current,
        noCodeEditorRef.current.children.length
      );
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleChange = (blur?: boolean): void => {
    if (errorParsingNodes) return;
    if (blur && handleChangeInflight.current) {
      cancelDebounce.current = true;
    }

    const newInput = sanitizeInput(noCodeEditorRef.current);
    if (mounted.current) {
      updateInput(newInput);
      createNodes(newInput);
      resetFocusAndSelection();
      handleChangeInflight.current = false;
    }
  };
  const handleChangeDebounce = useCallback(
    debounce((): void => {
      if (cancelDebounce.current) {
        cancelDebounce.current = false;
        return;
      }
      handleChange();
    }, 1000),
    [input, mounted, noCodeEditorRef.current]
  );

  const cleanUpDragState = (): void => {
    if (errorParsingNodes) return;
    const dragoverPaddingElements = noCodeEditorRef.current?.querySelectorAll(
      `#${DRAGOVER_PADDING_ELEMENT_SELECTOR}`
    );

    dragoverPaddingElements.forEach((element) => {
      noCodeEditorRef.current.removeChild(element);
    });
    const hiddenElements = noCodeEditorRef.current.querySelectorAll(
      "#hide-on-drag"
    );
    hiddenElements.forEach((element) => {
      element.removeAttribute("style");
    });
    currentDraggingElement.current = null;
    allElementsBoundingClientRect.current = {};
    noCodeEditorRef.current?.classList.remove(
      "!tw-ring-green-700",
      "!tw-ring-2"
    );
  };

  const onDrop = (event: React.DragEvent<Element>): void => {
    const target = event.target as HTMLElement;
    const toTheLeft = dropPosition.current === "left";
    let draggedElement: Element;
    const newVariable = event.dataTransfer.getData("newVariable");
    const draggedNodeId = event.dataTransfer.getData("nodeId");
    event.dataTransfer.clearData();

    if (newVariable) {
      const newVariableElement = prepareNewVariable(
        newVariable,
        availableVariables
      );
      if (!newVariableElement) return;
      draggedElement = newVariableElement;
    }

    if (draggedNodeId) {
      draggedElement = noCodeEditorRef.current.querySelector(
        `[data-node-id="${draggedNodeId}"]`
      );
    }
    if (!draggedElement) return;

    const droppableElement = findClosestDroppableElement(
      target,
      toTheLeft,
      noCodeEditorRef.current
    );
    if (droppableElement === currentDraggingElement.current) {
      return;
    }
    if (droppableElement === noCodeEditorRef.current) {
      noCodeEditorRef.current.appendChild(draggedElement);
      return handleChange(true);
    }
    if (toTheLeft) {
      droppableElement.before(draggedElement);
    } else {
      droppableElement.after(draggedElement);
    }
    handleChange(true);
  };

  const onDragEnd = (): void => {
    if (errorParsingNodes) return;
    return cleanUpDragState();
  };

  const onDragOver = (event: React.DragEvent<Element>): void => {
    if (errorParsingNodes) return;
    event.preventDefault();
    const target = event.target as Element;

    if (target === currentDraggingElement.current) {
      resetAllDragClasses(noCodeEditorRef.current);
      return;
    } else if (target === noCodeEditorRef.current) {
      switchDragIndicatorToThisElement(target, false, noCodeEditorRef.current);
      return;
    } else if (target?.id === DRAGOVER_PADDING_ELEMENT_SELECTOR) {
      return;
    } else {
      const nodeId = target.getAttribute("data-node-id");
      if (!nodeId) return;
      const elementRect = allElementsBoundingClientRect.current[nodeId];
      if (!elementRect) return;
      const elementCenter =
        elementRect.left + (elementRect.right - elementRect.left) / 2;
      const toTheLeft = event.clientX < elementCenter;
      dropPosition.current = toTheLeft ? "left" : "right";
      switchDragIndicatorToThisElement(
        target,
        toTheLeft,
        noCodeEditorRef.current
      );
    }
  };

  const onDragStart = (event: React.DragEvent<HTMLButtonElement>): void => {
    if (errorParsingNodes) return;
    event.persist();
    const target = event.target as HTMLButtonElement;
    currentDraggingElement.current = target;
    noCodeEditorRef.current?.classList.add("!tw-ring-green-700", "!tw-ring-2");
    const nodeId = target.getAttribute("data-node-id");
    const newVariable = target.getAttribute("data-add-variable");
    if (newVariable) {
      event.dataTransfer.setData("newVariable", newVariable);
    } else if (nodeId) {
      event.dataTransfer.setData("nodeId", nodeId);
    }
    let firstSpanCreated = false;
    const children = noCodeEditorRef.current?.children;
    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);
      if (child.id === "hide-on-drag") {
        child.setAttribute("style", "display: none;");
        continue;
      }
      if (child === target || child?.id === DRAGOVER_PADDING_ELEMENT_SELECTOR) {
        continue;
      }
      if (
        child.previousElementSibling?.id !== DRAGOVER_PADDING_ELEMENT_SELECTOR
      ) {
        const dragoverPaddingElement = document.createElement("span");
        dragoverPaddingElement.id = DRAGOVER_PADDING_ELEMENT_SELECTOR;
        if (!firstSpanCreated) {
          dragoverPaddingElement.classList.add("first-padding");
          firstSpanCreated = true;
        }
        noCodeEditorRef.current?.insertBefore(dragoverPaddingElement, child);
      }
      if (child.nextElementSibling?.id !== DRAGOVER_PADDING_ELEMENT_SELECTOR) {
        const dragoverPaddingElement = document.createElement("span");
        dragoverPaddingElement.id = DRAGOVER_PADDING_ELEMENT_SELECTOR;
        if (!firstSpanCreated) {
          dragoverPaddingElement.classList.add("first-padding");
          firstSpanCreated = true;
        }
        noCodeEditorRef.current?.insertBefore(
          dragoverPaddingElement,
          child.nextSibling
        );
      }
      const nodeId = child.getAttribute("data-node-id");
      if (!nodeId) continue;
      allElementsBoundingClientRect.current[
        nodeId
      ] = child.getBoundingClientRect();
    }
  };

  const onAddVariable = (variableKey: string): void => {
    if (errorParsingNodes) return;

    const newVariableElement = prepareNewVariable(
      variableKey,
      availableVariables
    );
    noCodeEditorRef.current.appendChild(newVariableElement);
    handleChange(true);
  };

  const updateModifiers = (
    add: string[],
    remove: string[],
    nodeId: string
  ): void => {
    if (errorParsingNodes) return;
    const node = nodes.find(({ id }) => nodeId === id);
    let newValue = node.value;
    if (add.includes("tag_tokind") && !newValue.includes("tag_tokind")) {
      newValue =
        newValue.substring(0, node.displayValue.length + 1) +
        " | tag_tokind " +
        newValue.substring(node.displayValue.length + 2);
    }
    remove.forEach((val) => {
      const regex = new RegExp(String.raw`\s*\|\s*${val}`, "g");
      newValue = newValue.replace(regex, "");
    });
    newValue = newValue.replace("}}", "");
    add.forEach((val) => {
      const regex = new RegExp(String.raw`\s*\|\s*${val}`, "g");
      if (newValue.search(regex) === -1) {
        newValue += `| ${val} `;
      }
    });
    newValue = newValue.trimEnd();
    newValue += " }}";

    const newInput = nodes
      .map((node) => {
        if (node.id == nodeId) {
          return newValue;
        }
        return node.value;
      })
      .join("");
    updateInput(newInput);
    createNodes(newInput);
    resetFocusAndSelection();
  };

  const NoCodeEditorComponent = (): JSX.Element => {
    if (errorParsingNodes) return;
    return (
      <div
        tabIndex={0}
        ref={noCodeEditorRef}
        placeholder={placeholder}
        className="txu-textarea txu-no-code-editor tw-inline-block tw-min-h-[100px] tw-w-full tw-cursor-text  tw-rounded-md tw-ring-1 tw-ring-gray-300 tw-duration-100 selection:tw-bg-gray-400/70 focus-within:tw-ring-2 focus-within:tw-ring-gray-400 tw-p-2"
        contentEditable
        suppressContentEditableWarning
        onDragOver={onDragOver}
        onBlur={(event): void => {
          const disableBlurEvent =
            !!event.target?.getAttribute("data-disable-blur-event") ||
            !!event.relatedTarget?.getAttribute("data-disable-blur-event");
          if (disableBlurEvent) return;
          handleChange(true);
        }}
        onInput={(): void => {
          handleChangeInflight.current = true;
          handleChangeDebounce();
        }}
        onDrop={onDrop}
        onKeyDown={(event): void => {
          if (
            ["ArrowRight", "ArrowLeft"].includes(event.key) &&
            handleChangeInflight.current
          ) {
            // If the user is navigating with the arrow keys
            // we want to debounce the change event as the user intent might be to type more at the navigated position
            // and we don't want to interfere with their typing
            handleChangeDebounce();
          }
          const target = event.target as Element;
          const isVariable =
            target.getAttribute("data-node-type") === "variable";
          if (
            (event.key === "Backspace" || event.key === "Delete") &&
            isVariable
          ) {
            event.currentTarget.removeChild(target);
            const newInput = sanitizeInput(event.currentTarget);
            updateInput(newInput);
            createNodes(newInput);
            resetFocusAndSelection();
            event.preventDefault();
          }
        }}
      >
        {nodes.map((node) => {
          return node.type === "text" ? (
            <HTMLTextNode node={node} onDragOver={onDragOver} key={node.id} />
          ) : (
            <React.Fragment key={node.id}>
              <HTMLHiddenChar />
              <HTMLVariableNode
                node={node}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                updateModifiers={updateModifiers}
              />
              <HTMLHiddenChar />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return {
    NoCodeEditor: NoCodeEditorComponent,
    onAddVariable,
    dragHandlers: {
      onDragEnd,
      onDragStart,
    },
    errorParsingNodes,
  };
};
