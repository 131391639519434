import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { AxiosResponse } from "axios";
import { Button, Popup } from "semantic-ui-react";
import {
  GetNodeKeyHandler,
  formatTag,
  TreeData,
  VocabularyTreeData,
  getTagTypeValue,
} from "../utils/tagutils";
import { ProductId } from "../products/product";
import { OptionsType } from "react-select/lib/types";
import {
  newRequestWord,
  searchAsyncVocabulary,
  SearchVocabularyParams,
} from "../api/vocabularyApi";
import { LanguageCode } from "../customers/customerlanguages";
import { DirectionProperty } from "csstype";
import { debounce } from "../utils/debounce";
import { NodePath, SortableTreeProps } from "./TagTreeEditor";
import { connect, ConnectedProps } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  AllowTagType,
  RequestWordModal,
  RequestWordTranslateType,
  RequestWordTranslateTypeToastMessage,
  WordType,
} from "./RequestWordModal/RequestWordModal";
import { setHighlightedTag } from "../api/productDetailEnrichTabSlice";
import { popupStyle } from "../producttext/ProductTextToolbar";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../api/djangoToastSlice";
import { getCustomer } from "../api/customerApi";
import { Customer } from "../customers/Customer";
import styled from "styled-components";
import { TreeItem } from "react-sortable-tree";

const vocabularyLookupPopupStyle = { ...popupStyle, marginTop: "12px" };

const AlignedDiv = styled.div`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const DropdownIndicator = (): null => {
  return null;
};

type OptionsCallback = (options: Option[]) => void;

type RequestWord = {
  newWord: string;
  openRequestModal: boolean;
};

export type Option = {
  label: string | React.ReactElement;
  tag?: any;
  tag_id?: string;
  type?: string;
  value: string;
};

type Props = {
  alwaysTranslate?: boolean;
  customerLanguage: string;
  disableAutoAdd?: boolean;
  getNodeKey?: GetNodeKeyHandler;
  headline?: string;
  showVocabType?: boolean;
  includeSeo?: boolean;
  initialData: VocabularyTreeData | VocabularyTreeData[] | null;
  isMultiple?: boolean;
  isUsedInTagTreeEditor?: boolean;
  language?: LanguageCode;
  lexiconEditorDirection?: DirectionProperty;
  limit?: number;
  mappingMode?: boolean;
  node?: TreeItem<SortableTreeProps>;
  onChangeVocabulary?: (
    tag: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ) => void;
  onDataChanged: (component: VocabularyLookup, data: any) => void;
  onLoaded: (component: VocabularyLookup) => void;
  path?: number[] | null;
  placeholder?: string;
  showMachineTranslate?: boolean;
  showHypernym?: boolean;
  manageWithClick?: boolean;
  tagCategory: string;
  tagType: string;
  textualAppName: string;
  treeData?: VocabularyTreeData | VocabularyTreeData[];
  useLexiconEditor?: boolean;
  vocabularyRequestAllowed?: boolean;
  vocabularyRequestProductId?: ProductId;
  vocabularyRequestProductDescription?: string;
  setIsSearching?: (searching: boolean) => void;
};

type State = {
  defaultOptions: OptionsType<any>;
  dropDownValue: Option | Option[];
  hypernymData?: VocabularyTreeData;
  initialWordType?: string;
  lexiconData?: any; // TODO type annotation
  modalEnglishInputValue: string;
  modalInputValue: string;
  productTag: VocabularyTreeData | VocabularyTreeData[];
  requestWord: RequestWord;
  shouldPopulate: boolean;
  value: string;
  vocabularies: VocabularyTreeData[];
  wordType: string;
};

const checkMultiple = (isMultiple: boolean): any => (isMultiple ? [] : "");

const mapStateToProps = (
  state: RootState
): {
  token: string | null;
  customer: Customer | null;
  highlightedTagId: string | null;
} => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
  /** Current customer */
  customer: getCustomer(store.getState()),
  highlightedTagId: state.productDetailEnrichTab.highlightedTagId,
});

const connector = connect(mapStateToProps, null);

type VocabularyLookupProps = ConnectedProps<typeof connector> & Props;

export default class VocabularyLookup extends React.Component<
  VocabularyLookupProps,
  State
> {
  private mounted = false;
  static defaultProps: Partial<VocabularyLookupProps> = {
    initialData: null,
    path: null,
    manageWithClick: false,
  };

  state: State = {
    defaultOptions: [],
    dropDownValue: null,
    modalEnglishInputValue: "",
    modalInputValue: "",
    productTag: [],
    requestWord: {
      openRequestModal: false,
      newWord: "",
    },
    shouldPopulate: false,
    value: "",
    vocabularies: [],
    wordType: "",
  };

  componentDidMount(): void {
    this.mounted = true;
    const {
      showVocabType,
      isUsedInTagTreeEditor,
      onLoaded,
      isMultiple,
      tagCategory,
      tagType,
    } = this.props;
    let { initialData } = this.props;

    // default wordType
    let { wordType } = this.state;
    const defaultWordType =
      tagCategory && tagType ? `${tagCategory}/${tagType}` : "";
    wordType = wordType || defaultWordType;
    this.setState({ wordType: wordType, initialWordType: wordType });

    if (!isUsedInTagTreeEditor) {
      onLoaded(this);
    } else {
      initialData = initialData as VocabularyTreeData;
      if (
        initialData.setting_schema &&
        initialData.setting_schema.properties &&
        initialData.setting_schema.properties.number &&
        initialData.setting_schema.properties.number.enum
      ) {
        initialData.setting_schema.properties.number.enumNames = initialData.setting_schema.properties.number.enum.map(
          (value: string): string => getTagTypeValue(value)
        );
      }
    }

    let productTag: VocabularyTreeData | VocabularyTreeData[] = [];
    let dropDownValue = checkMultiple(isMultiple);
    if (
      !isMultiple &&
      initialData &&
      (initialData as VocabularyTreeData).value
    ) {
      initialData = initialData as VocabularyTreeData;
      dropDownValue = {
        value: initialData.value,
        label: formatTag(initialData, {
          includeSubTagNames: true,
          showTagTypeHints: isUsedInTagTreeEditor,
          showVocabType: showVocabType,
        }),
        type: initialData.type,
      };
      productTag = initialData;
    } else {
      initialData = initialData as VocabularyTreeData[];
      if (isMultiple && initialData && initialData.length > 0) {
        dropDownValue = initialData.map((item) => {
          productTag = productTag as VocabularyTreeData[];
          productTag.push(item);
          return {
            value: item.value,
            label: formatTag(item, {
              includeSubTagNames: true,
              showTagTypeHints: isUsedInTagTreeEditor,
              showVocabType: showVocabType,
            }),
            type: item.type,
          };
        });
      }
    }

    if (
      initialData &&
      (initialData as VocabularyTreeData).placeholder &&
      (initialData as VocabularyTreeData).prepopulateMenu
    ) {
      initialData = initialData as VocabularyTreeData;
      this.handleLoadOption(
        initialData.placeholder,
        (suggestions: Option[]) => {
          this.setState({
            dropDownValue,
            productTag,
            defaultOptions: suggestions,
          });
        }
      );
    } else {
      this.setState({ dropDownValue, productTag });
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: Props): void {
    const { showVocabType, initialData, isUsedInTagTreeEditor } = this.props;
    // iff isUsedInTagTreeEditor changed from false -> true
    if (!prevProps.isUsedInTagTreeEditor && isUsedInTagTreeEditor) {
      const vocabTree = initialData as VocabularyTreeData;
      const dropDownValue = {
        value: vocabTree.value,
        label: formatTag(vocabTree, {
          includeSubTagNames: true,
          showTagTypeHints: isUsedInTagTreeEditor,
          showVocabType: showVocabType,
        }),
        type: vocabTree.type,
      };
      this.setState({ dropDownValue });
    }
  }

  handleRequestWord = (value: string): void => {
    const { isUsedInTagTreeEditor } = this.props;
    const { requestWord } = this.state;
    if (isUsedInTagTreeEditor) {
      requestWord.openRequestModal = true;
      requestWord.newWord = value;
      this.setState({
        requestWord,
      });
    } else {
      requestWord.openRequestModal = true;
      requestWord.newWord = value;
      this.setState({
        requestWord,
      });
    }
  };

  getValueWithTagId = (data: Option | VocabularyTreeData): string => {
    return data.tag_id ? `${data.value}-${data.tag_id}` : data.value;
  };

  // TODO type annotation
  onLexiconDataChange = (lexiconData: any): void => {
    this.setState({ lexiconData: lexiconData });
  };

  onHypernymChange = (hypernymData: VocabularyTreeData): void => {
    this.setState({ hypernymData: hypernymData });
  };

  onWordTypeChange = (data: WordType | null): void => {
    this.setState({ wordType: data?.value ?? "" });
  };

  onShouldPopulateChange = (value: boolean): void => {
    this.setState({ shouldPopulate: value });
  };

  addNewRequestWord = (data: VocabularyTreeData): void => {
    const { isMultiple, isUsedInTagTreeEditor, showVocabType } = this.props;
    const { vocabularies } = this.state;
    let { productTag } = this.state;
    let value: Option | Option[];

    if (data && data.value) {
      const tempVocabularies: VocabularyTreeData[] = [...vocabularies];
      tempVocabularies.push(data);
      this.setState(
        {
          vocabularies: tempVocabularies,
        },
        () => {
          if (!isMultiple) {
            value = {
              label: formatTag(data, {
                includeSubTagNames: true,
                showTagTypeHints: isUsedInTagTreeEditor,
                showVocabType: showVocabType,
              }),
              value: this.getValueWithTagId(data),
              type: data.type,
            };
            this.handleOnChange(value);
          } else if (isMultiple) {
            value = [];
            productTag = productTag as VocabularyTreeData[];
            productTag &&
              productTag.length > 0 &&
              productTag.map((tag) => {
                (value as Option[]).push({
                  label: formatTag(tag, {
                    includeSubTagNames: true,
                    showTagTypeHints: isUsedInTagTreeEditor,
                    showVocabType: showVocabType,
                  }),
                  type: tag.type,
                  value: this.getValueWithTagId(tag),
                });
              });
            value.push({
              label: formatTag(data, {
                includeSubTagNames: true,
                showTagTypeHints: isUsedInTagTreeEditor,
                showVocabType: showVocabType,
              }),
              value: this.getValueWithTagId(data),
              tag: data.type,
            });
            this.handleOnChange(value);
          }
        }
      );
    }
  };

  requestNewWord = (translateType: RequestWordTranslateType): void => {
    const {
      hypernymData,
      modalEnglishInputValue,
      modalInputValue,
      lexiconData,
      requestWord,
      shouldPopulate,
      wordType,
    } = this.state;
    const {
      alwaysTranslate,
      disableAutoAdd,
      language,
      textualAppName,
      token,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
    } = this.props;

    const [wordTagCategory, wordTagType] = wordType.split("/");

    let newWord;
    if (lexiconData) {
      newWord = {
        word: lexiconData.form_data.forms.lemma || requestWord.newWord,
        lexiconData: lexiconData,
      };
    } else {
      newWord = requestWord.newWord;
    }

    const shouldTranslate =
      alwaysTranslate || translateType === RequestWordTranslateType.TRANSLATE;
    const shouldMachineTranslate =
      !alwaysTranslate &&
      translateType === RequestWordTranslateType.MACHINE_TRANSLATE;
    newRequestWord(
      newWord,
      modalEnglishInputValue,
      wordTagCategory,
      wordTagType,
      shouldTranslate,
      shouldMachineTranslate,
      shouldPopulate,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      modalInputValue,
      language,
      token,
      textualAppName,
      (hypernymData || {}).vocabulary_id
    ).then((response) => {
      if (!disableAutoAdd) {
        this.addNewRequestWord(response.data);
      }
      store.dispatch(
        setDjangoToastOpen({
          content: RequestWordTranslateTypeToastMessage[translateType],
          appearance: NotificationAppearance.SUCCESS,
        })
      );
    });
    this.closeRequestModal();
  };

  closeRequestModal = (): void => {
    const { requestWord, initialWordType } = this.state;
    this.setState({
      requestWord: {
        ...requestWord,
        openRequestModal: false,
        newWord: "",
      },
      modalEnglishInputValue: "",
      modalInputValue: "",
      wordType: initialWordType,
      shouldPopulate: false,
    });
  };

  getMatchedVocabularyIndex = (
    vocabularies: VocabularyTreeData[],
    addedItem: string
  ): number => {
    return vocabularies && vocabularies.length > 0
      ? vocabularies.findIndex((vocabulary) => {
          const matchedVocabularyValue = this.getValueWithTagId(vocabulary);
          return matchedVocabularyValue === addedItem;
        })
      : -1;
  };

  changeWithMultipleValue = (
    value: Option[],
    onDataChanged: (component: VocabularyLookup, data: any) => void
  ): void => {
    const dropDownValue: Option[] = [];
    const addedProductTag: VocabularyTreeData[] = [];
    let tag: VocabularyTreeData | VocabularyTreeData[] = [];
    const { showVocabType, isUsedInTagTreeEditor } = this.props;
    const { vocabularies, productTag } = this.state;
    value &&
      value.length > 0 &&
      value.map((item) => {
        if (item.value !== "Not-found-value") {
          const addedItem = item.value;
          const matchedVocabularyIndex = this.getMatchedVocabularyIndex(
            vocabularies,
            addedItem
          );
          if (matchedVocabularyIndex > -1) {
            tag = { ...vocabularies[matchedVocabularyIndex] };
            addedProductTag.push(tag);
          } else {
            const alreadyAddedTag = productTag as VocabularyTreeData[];
            const matchedTagIndex =
              alreadyAddedTag && alreadyAddedTag.length > 0
                ? alreadyAddedTag?.findIndex(
                    (tagItem) =>
                      this.getValueWithTagId?.(tagItem) === addedItem ||
                      tagItem.value === addedItem
                  )
                : -1;
            if (matchedTagIndex > -1) {
              tag = { ...alreadyAddedTag[matchedTagIndex] };
              addedProductTag.push(tag);
            }
          }
        }
      });
    addedProductTag &&
      addedProductTag.length > 0 &&
      addedProductTag.map((tag) => {
        dropDownValue.push({
          value: tag.value,
          label: formatTag(tag, {
            includeSubTagNames: true,
            showTagTypeHints: isUsedInTagTreeEditor,
            showVocabType: showVocabType,
          }),
          type: tag.type,
        });
      });
    onDataChanged(this, addedProductTag);
    this.setState({ dropDownValue, productTag: addedProductTag });
  };

  changeWithSingleValue = (
    value: Option,
    onDataChanged: (component: VocabularyLookup, data: any) => void,
    isUsedInTagTreeEditor: boolean,
    onChangeVocabulary: Function,
    initialData: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    let dropDownValue: Option;
    const addedProductTag = [];
    const { vocabularies } = this.state;
    const { showVocabType } = this.props;
    const addedItem = value.value;
    let tag: VocabularyTreeData | VocabularyTreeData[] = [];
    if (
      (isUsedInTagTreeEditor && value.type === "brand") ||
      value.type === "name"
    ) {
      const tag: TreeData = {
        master_display_name: value.label as string,
        category: "identifier",
        type: value.type,
        value: value.value,
        id: value.value,
        allow_children: false,
        settings: {},
        settings_schema: null,
        children: [],
      };
      dropDownValue = {
        value: tag.value,
        label: formatTag(tag, {
          includeSubTagNames: true,
          showTagTypeHints: isUsedInTagTreeEditor,
          showVocabType: showVocabType,
        }),
        type: tag.type,
      };
      onChangeVocabulary?.(tag, path, getNodeKey);
    } else {
      const matchedVocabularyIndex = this.getMatchedVocabularyIndex(
        vocabularies,
        addedItem
      );
      if (matchedVocabularyIndex > -1) {
        tag = { ...vocabularies[matchedVocabularyIndex] };
        addedProductTag.push(tag);
        addedProductTag &&
          addedProductTag.length > 0 &&
          addedProductTag.map((tag) => {
            dropDownValue = {
              value: tag.value,
              label: formatTag(tag, {
                includeSubTagNames: true,
                showTagTypeHints: isUsedInTagTreeEditor,
                showVocabType: showVocabType,
              }),
              type: tag.type,
            };
          });
        if (isUsedInTagTreeEditor) {
          tag.children =
            initialData && tag.allow_children && initialData.children
              ? initialData.children
              : [];
          tag.expanded = true;
          if (
            tag.setting_schema &&
            tag.setting_schema.properties &&
            tag.setting_schema.properties.number &&
            tag.setting_schema.properties.number.enum
          ) {
            tag.setting_schema.properties.number.enumNames = tag.setting_schema.properties.number.enum.map(
              (value: string) => getTagTypeValue(value)
            );
          }
          onChangeVocabulary?.(tag, path, getNodeKey);
        } else {
          onDataChanged(this, tag);
        }
      }
    }
    this.setState({ dropDownValue, productTag: addedProductTag });
  };

  handleOnChange = (value: string | Option | Option[]): void => {
    const {
      getNodeKey,
      initialData,
      isMultiple,
      isUsedInTagTreeEditor,
      node,
      onChangeVocabulary,
      onDataChanged,
      path,
      setIsSearching,
    } = this.props;
    let tag: VocabularyTreeData | VocabularyTreeData[] = [];
    const dropDownValue = checkMultiple(isMultiple);
    if (
      value !== null &&
      value !== "Not-found-value" &&
      value !== "placeholder"
    ) {
      if (
        (value as Option).value &&
        (value as Option).value !== "Not-found-value" &&
        !isMultiple
      ) {
        this.changeWithSingleValue(
          value as Option,
          onDataChanged,
          isUsedInTagTreeEditor,
          onChangeVocabulary,
          initialData as VocabularyTreeData,
          path,
          getNodeKey
        );
      } else if (isMultiple) {
        this.changeWithMultipleValue(value as Option[], onDataChanged);
      }
    } else {
      tag = checkMultiple(isMultiple);
      if (isUsedInTagTreeEditor) {
        const emptyTag = node;
        emptyTag.value = "";
        onChangeVocabulary?.(emptyTag, path, getNodeKey);
      } else {
        onDataChanged(this, tag);
      }
      this.setState({ dropDownValue });
    }
    setIsSearching?.(false);
  };

  addVocabularies = (
    value: string,
    vocabularyRequestAllowed: boolean,
    isUsedInTagTreeEditor: boolean,
    response: AxiosResponse<VocabularyTreeData[]>,
    callback: OptionsCallback
  ): void => {
    const { showVocabType, path, treeData } = this.props;
    const data = response.data && response.data.length > 0 ? response.data : [];
    const suggestions: Option[] = data.map((vocabulary: VocabularyTreeData) => {
      return {
        label: formatTag(vocabulary, {
          includeSubTagNames: true,
          showTagTypeHints: isUsedInTagTreeEditor,
          showVocabType: showVocabType,
        }),
        value: this.getValueWithTagId(vocabulary),
        type: vocabulary.type,
      } as Option;
    });
    if (vocabularyRequestAllowed) {
      if (!isUsedInTagTreeEditor) {
        suggestions.push({
          label: (
            <Button
              className={"text_wrap"}
              style={{
                width: "100%",
              }}
              content="Use this word without translations, and request translation."
              name="requestWord"
              onClick={(): void => this.handleRequestWord(value)}
            />
          ),
          value: "Not-found-value",
        });
      } else {
        const isAddedBrand = this.checkAlreadyAdded(
          isUsedInTagTreeEditor,
          path,
          treeData,
          "brand"
        );
        const isAddedProductName = this.checkAlreadyAdded(
          isUsedInTagTreeEditor,
          path,
          treeData,
          "name"
        );
        const isShowAddBrandProduct = this.isShowAddBrandProduct(
          isUsedInTagTreeEditor,
          path,
          treeData,
          isAddedBrand,
          isAddedProductName
        );

        if (isShowAddBrandProduct) {
          const disablePopup = suggestions.length < 4; // Disable the popup if there is < 4 suggestions as semantic ui Popup component can't overflow its parent and behaves strange
          suggestions.unshift({
            label: (
              <Button.Group size="tiny" compact fluid>
                <Popup
                  basic
                  content="Use word without translation and request translation."
                  disabled={disablePopup}
                  trigger={
                    <Button
                      content="Add new word"
                      name="requestWord"
                      onClick={(): void => this.handleRequestWord(value)}
                    />
                  }
                  size="small"
                />
                {!isAddedBrand && (
                  <Popup
                    basic
                    content="Use word as the brand name. Will not be translated."
                    disabled={disablePopup}
                    trigger={
                      <Button
                        onClick={(): void => this.handleAddBrand(value)}
                        content="Use as brand"
                      />
                    }
                    size="small"
                  />
                )}
                {!isAddedProductName && (
                  <Popup
                    basic
                    content="Use word as the product name. Will not be translated."
                    disabled={disablePopup}
                    trigger={
                      <Button
                        onClick={(): void => this.handleAddProductName(value)}
                        content="Use as name"
                      />
                    }
                    size="small"
                  />
                )}
              </Button.Group>
            ),
            value: "Not-found-value",
          });
        }
      }
    }
    this.setState({ vocabularies: data });
    callback?.(suggestions);
  };

  handleLoadOption = (value: string, callback: OptionsCallback): void => {
    this.setState({ value });
    const {
      headline,
      includeSeo,
      isUsedInTagTreeEditor,
      language,
      limit,
      mappingMode,
      path,
      tagCategory,
      tagType,
      textualAppName,
      token,
      vocabularyRequestAllowed,
      setIsSearching,
    } = this.props;
    setIsSearching?.(true);
    const queryParam: SearchVocabularyParams = {
      q: value,
      limit,
      language,
    };

    if (value.length >= 1) {
      if (headline) {
        queryParam.headline = headline;
      } else if (tagType && tagCategory) {
        queryParam.type = tagType;
        queryParam.category = tagCategory;
      }
      if (includeSeo) {
        queryParam.include_seo = includeSeo;
      }
      if (isUsedInTagTreeEditor && path.length === 1 && !mappingMode) {
        queryParam.type = null;
        queryParam.category = null;
        // tagtree_root_search allow search for Attribute or Material in top node
        // instead providing type / category only for Attribute
        queryParam.tagtree_root_search = true;
      }
      queryParam.exclude_templates = true;
      searchAsyncVocabulary(queryParam, token, textualAppName).then(
        (response) => {
          if (!this.mounted) {
            return;
          }
          this.addVocabularies(
            value,
            vocabularyRequestAllowed,
            isUsedInTagTreeEditor,
            response,
            callback
          );
        }
      );
    } else {
      callback([]);
    }
  };

  handleAddProductName = (value: string): void => {
    this.handleOnChange({
      label: value,
      value,
      type: "name",
    });
  };

  handleAddBrand = (value: string): void => {
    this.handleOnChange({
      label: value,
      value,
      type: "brand",
    });
  };

  onAddCopiedVocabulary = (data: VocabularyTreeData): void => {
    this.addNewRequestWord(data);
  };

  checkAlreadyAdded = (
    isUsedInTagTreeEditor: boolean,
    path: number[],
    treeData: VocabularyTreeData | VocabularyTreeData[],
    type: string
  ): boolean => {
    let isAdded = false;

    if (isUsedInTagTreeEditor && path.length === 2) {
      treeData = treeData as VocabularyTreeData[];
      if (treeData[path[0]] && treeData[path[0]].children.length > 0) {
        isAdded = treeData[path[0]].children.some(function (el) {
          return el.type === type;
        });
      }
    }
    return isAdded;
  };

  isShowAddBrandProduct = (
    isUsedInTagTreeEditor: boolean,
    path: number[],
    treeData: VocabularyTreeData | VocabularyTreeData[],
    isAddedBrand: boolean,
    isAddedProductName: boolean
  ): boolean => {
    let isShowAddBrandProduct = false;
    if (
      isUsedInTagTreeEditor &&
      ((path.length === 1 && path[0] === 0) ||
        (path.length === 2 &&
          (treeData as VocabularyTreeData[])[path[0]] &&
          (treeData as VocabularyTreeData[])[path[0]].type === "kind"))
    ) {
      isShowAddBrandProduct = true;
    }
    if (isAddedProductName && isAddedBrand) {
      isShowAddBrandProduct = false;
    }
    return isShowAddBrandProduct;
  };

  getCollapsedDropdownHoverValue = (node: TreeData): string => {
    const { showVocabType, isUsedInTagTreeEditor } = this.props;
    let content = formatTag(node, {
      includeSubTagNames: true,
      showTagTypeHints: isUsedInTagTreeEditor,
    });
    for (const item of node.children) {
      if (item.children.length) {
        content += ` / (${this.getCollapsedDropdownHoverValue(item)})`;
      } else {
        content += ` / ${formatTag(item, {
          includeSubTagNames: true,
          showTagTypeHints: isUsedInTagTreeEditor,
          showVocabType: showVocabType,
        })}`;
      }
    }
    return content;
  };

  getCollapsedDropdownValue = (node: TreeData): JSX.Element => {
    const { showVocabType, isUsedInTagTreeEditor } = this.props;
    const collapsedValue = this.getCollapsedDropdownHoverValue(node);
    const childrenDisplayNames = node.children.map((item: TreeData) =>
      formatTag(item, {
        includeSubTagNames: false,
        showTagTypeHints: false,
        showVocabType: showVocabType,
      })
    );
    let returnDisplay;
    if (childrenDisplayNames.length) {
      returnDisplay = (
        <div>
          {formatTag(node, {
            includeSubTagNames: false,
            showTagTypeHints: false,
            showVocabType: showVocabType,
          })}{" "}
          / {childrenDisplayNames.join(" / ")}
        </div>
      );
    } else {
      returnDisplay = (
        <div>
          {formatTag(node, {
            includeSubTagNames: true,
            showTagTypeHints: isUsedInTagTreeEditor,
            showVocabType: showVocabType,
          })}
        </div>
      );
    }
    return (
      <Popup
        content={collapsedValue}
        inverted
        key={collapsedValue}
        mouseEnterDelay={100}
        on={["hover"]}
        position="bottom left"
        size="mini"
        style={vocabularyLookupPopupStyle}
        trigger={returnDisplay}
      />
    );
  };

  render(): React.ReactElement {
    const {
      modalInputValue,
      modalEnglishInputValue,
      requestWord,
      wordType,
      shouldPopulate,
      defaultOptions,
    } = this.state;
    let { dropDownValue } = this.state;
    const {
      alwaysTranslate,
      isMultiple,
      isUsedInTagTreeEditor,
      language,
      lexiconEditorDirection,
      manageWithClick,
      mappingMode,
      node,
      path,
      placeholder,
      showHypernym,
      showMachineTranslate,
      textualAppName,
      useLexiconEditor,
      setIsSearching,
    } = this.props;
    let { initialData } = this.props;
    initialData = initialData as VocabularyTreeData;
    const { customer } = this.props;

    let dropDownValueWithLabel = dropDownValue;
    let label;
    if (isUsedInTagTreeEditor) {
      if (!isMultiple && (dropDownValue as Option)?.value) {
        dropDownValue = dropDownValue as Option;
        if (
          !node.old_display_name ||
          node.old_display_name === dropDownValue.label
        ) {
          if (node.expanded) {
            label = dropDownValue.label;
          } else {
            label = this.getCollapsedDropdownValue(node);
          }
          dropDownValueWithLabel = {
            value: this.getValueWithTagId(dropDownValue),
            label: label,
          };
        }
      } else {
        dropDownValueWithLabel = null;
      }
    } else {
      dropDownValueWithLabel = dropDownValue;
    }
    const isOpenRequestModal = requestWord.openRequestModal;

    const getAllowTags = (): AllowTagType => {
      if (mappingMode) return AllowTagType.ALL;
      if (path?.length === 1) return AllowTagType.KIND_MATERIAL;
      if (path?.length > 1) return AllowTagType.NON_FIXED_PHRASES;
      return AllowTagType.NON_KIND_MATERIAL;
    };

    return (
      <div
        className="VocabularyLookup"
        data-testid={"vocabulary-lookup"}
        style={
          isUsedInTagTreeEditor
            ? { zIndex: 2 }
            : {
                width: "100%",
                height: "100%",
                marginLeft: "15px",
                position: "relative",
              }
        }
      >
        {manageWithClick ? (
          <AlignedDiv
            onClick={(): void => {
              store.dispatch(setHighlightedTag(node._id || node.id));
            }}
          >
            {label}
          </AlignedDiv>
        ) : (
          <AsyncSelect
            classNamePrefix="vocabulary-lookup"
            styles={
              isUsedInTagTreeEditor && {
                control: (baseStyles): React.CSSProperties => ({
                  ...baseStyles,
                  border: "none",
                  cursor: "text",
                  height: 35,
                  minHeight: "unset",
                }),
                indicatorSeparator: (baseStyles): React.CSSProperties => ({
                  ...baseStyles,
                  display: "none",
                }),
                menu: (baseStyles): React.CSSProperties => ({
                  ...baseStyles,
                  zIndex: 99,
                }),
                menuList: (baseStyles): React.CSSProperties => ({
                  ...baseStyles,
                  overflowX: "hidden",
                }),
              }
            }
            autoBlur={false}
            cacheOptions={false}
            components={{ DropdownIndicator }}
            defaultInputValue={
              initialData?.prepopulateMenu ? initialData.placeholder : ""
            }
            defaultMenuIsOpen={initialData?.prepopulateMenu}
            defaultOptions={initialData?.prepopulateMenu ? defaultOptions : []}
            data-testid={"react-async-select"}
            id={"react-async-select"}
            isClearable={true}
            isMulti={isMultiple}
            isLoading={false}
            isSearchable={true}
            loadOptions={debounce(
              (value: string, callback: OptionsCallback): void => {
                if (!this.mounted) {
                  return;
                }
                this.handleLoadOption(value, callback);
              },
              300
            )}
            onBlur={(): void => {
              this.setState({
                value: "",
              });
              setIsSearching?.(false);
            }}
            onChange={(value: Option): void => this.handleOnChange(value)}
            placeholder={placeholder || "Type to search"}
            value={dropDownValueWithLabel}
          />
        )}
        {isOpenRequestModal && (
          <RequestWordModal
            allowTags={getAllowTags()}
            alwaysTranslate={alwaysTranslate}
            closeModal={this.closeRequestModal}
            customerLanguage={language}
            description={modalInputValue}
            englishTranslation={modalEnglishInputValue}
            isOpenModal={isOpenRequestModal}
            isUsedInTagTreeEditor={isUsedInTagTreeEditor}
            lexiconEditorDirection={lexiconEditorDirection}
            onDescriptionInputChange={(value): void => {
              this.setState({ modalInputValue: value });
            }}
            onEnglishTranslationInputChange={(value): void => {
              this.setState({ modalEnglishInputValue: value });
            }}
            onHypernymChange={this.onHypernymChange}
            onLexiconDataChange={this.onLexiconDataChange}
            onShouldPopulateChange={this.onShouldPopulateChange}
            onWordTypeChange={this.onWordTypeChange}
            populateTranslations={true}
            providedNewWord={requestWord.newWord}
            providedWordType={wordType}
            shouldPopulate={shouldPopulate}
            showHypernym={showHypernym}
            showTranslate={customer.isPayingCustomer}
            showMachineTranslate={showMachineTranslate}
            submitAction={this.requestNewWord}
            textualAppName={textualAppName}
            useLexiconEditor={useLexiconEditor}
            onAddCopiedVocabulary={this.onAddCopiedVocabulary}
          />
        )}
      </div>
    );
  }
}

export const VocabularyLookupComp = connector(VocabularyLookup);
