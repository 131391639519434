import api from "./api";
import { CeleryTaskStatus } from "../utils/types";
import { TaskId } from "./types";

type FetchCustomerMetricsHtml = {
  data: {
    status: CeleryTaskStatus;
    content: string;
  };
};

export async function fetchCustomerMetricsHtml(
  url: string,
  token: string,
  taskId: TaskId,
  template: string,
  path: string
): Promise<FetchCustomerMetricsHtml> {
  const request = await api.post(
    url,
    { task_id: taskId, html_template: template, template_path: path },
    {
      headers: { token: token },
    }
  );

  return request;
}
