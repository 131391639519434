import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { useGetCustomerQuery } from "../api/customerApi";
import { Spinner } from "../utils/Spinner";
import { ChannelId, CustomerChannel } from "./Customer";
import { Language, LanguageCode } from "./customerlanguages";
import {
  FooterRows,
  ChannelLanguageSettingsFooter,
  FooterMatrixType,
} from "./ChannelLanguageSettingsFooter";
import {
  ChannelLanguageSettingsType,
  ChannelLanguageMatrixItems,
} from "../api/manageApi";
import { CustomerChannelLanguageMatrixTableHeader } from "./CustomerChannelLanguageMatrixTableHeader";
import { CustomerChannelLanguageMatrixTableBody } from "./CustomerChannelLanguageMatrixTableBody";
import { ChannelLanguageButtonsFooter } from "./ChannelLanguageButtonsFooter";

export type MatrixCellType = {
  channel_id: ChannelId;
  // null if corresponding value does not exists in backend for that checkbox
  // boolean for value fetched from backend for corresponding checkbox
  fetched_value: boolean | null;
  selected: boolean;
};

export type MatrixRowType = {
  channel_details: MatrixCellType[];
  language_code: LanguageCode;
};

export type MatrixCellsUpdateType = {
  channel_ids: number[];
  checked: boolean;
  language_codes: LanguageCode[];
};

type Props = {
  buttonRows?: React.FunctionComponent<{ channelId: ChannelId | string }>[];
  disabled?: boolean;
  fetchedChannelLanguageMatrix: ChannelLanguageSettingsType;
  footerRows?: FooterRows[];
  onChangeChannelLanguageMatrixRef?: (payload: MatrixRowType[]) => void;
  onChangeChannelSettingsFooterRef?: (payload: FooterMatrixType[]) => void;
};
export const CustomerChannelLanguageMatrix: React.FC<Props> = ({
  buttonRows,
  disabled = false,
  fetchedChannelLanguageMatrix,
  footerRows = null,
  onChangeChannelLanguageMatrixRef = null,
  onChangeChannelSettingsFooterRef = null,
}) => {
  const [customerChannels, setCustomerChannels] = useState<CustomerChannel[]>(
    []
  );
  const [customerLanguages, setCustomerLanguages] = useState<Language[]>([]);
  const [channelLanguageMatrix, setChannelLanguageMatrix] = useState<
    MatrixRowType[]
  >([]);

  const { data: customer, isLoading } = useGetCustomerQuery();

  useEffect(() => {
    if (customer) {
      setCustomerChannels(
        customer.channels?.sort((a, b) =>
          a.display_name.localeCompare(b.display_name)
        )
      );
      setCustomerLanguages(
        customer.languages?.sort((a, b) =>
          a.short_name.localeCompare(b.short_name)
        )
      );
    }
  }, [customer]);

  const getDefaultChannelLanguageMatrix = (): MatrixRowType[] => {
    const channelLanguageMatrixArray = [] as MatrixRowType[];
    customerLanguages.forEach((language) => {
      const channelDetailsArray = [] as MatrixCellType[];
      customerChannels.forEach((channel) => {
        channelDetailsArray.push({
          channel_id: channel.id,
          selected: false,
          fetched_value: null,
        });
      });
      channelLanguageMatrixArray.push({
        language_code: language.code,
        channel_details: channelDetailsArray,
      });
    });
    return channelLanguageMatrixArray;
  };

  const getChannelLanguageMatrix = ({
    channelLanguageMatrix,
  }: {
    channelLanguageMatrix: ChannelLanguageMatrixItems[];
  }): MatrixRowType[] => {
    const defaultMatrix = [...getDefaultChannelLanguageMatrix()];
    channelLanguageMatrix.forEach((element) => {
      const defaultMatrixElement = defaultMatrix
        .find(
          (defaultElementByLanguage) =>
            defaultElementByLanguage.language_code === element.language_code
        )
        ?.channel_details.find(
          (defaultElementByChannel) =>
            defaultElementByChannel.channel_id === element.channel_id
        );
      if (defaultMatrixElement) {
        defaultMatrixElement.fetched_value = element.default;
        defaultMatrixElement.selected = element.default;
      }
    });

    return defaultMatrix;
  };

  useEffect(() => {
    if (
      customerChannels.length &&
      customerLanguages.length &&
      fetchedChannelLanguageMatrix
    ) {
      const channelLanguageMatrix = getChannelLanguageMatrix({
        channelLanguageMatrix:
          fetchedChannelLanguageMatrix?.channel_language_matrix,
      });
      setChannelLanguageMatrix(channelLanguageMatrix);
    }
  }, [customerChannels, customerLanguages, fetchedChannelLanguageMatrix]);

  if (isLoading || !channelLanguageMatrix.length) {
    return <Spinner />;
  }

  const ShouldCheckOrNot = (channelId: ChannelId): boolean => {
    return !channelLanguageMatrix.every(
      (row) =>
        row.channel_details.find((detail) => detail.channel_id === channelId)
          ?.selected
    );
  };

  const updateChannelLanguageCells = ({
    channel_ids,
    checked,
    language_codes,
  }: MatrixCellsUpdateType): void => {
    const channelLanguageMatrixCopy = [...channelLanguageMatrix];
    language_codes.forEach((languageCode) => {
      const channelLanguage = channelLanguageMatrixCopy.find(
        (channelLanguage) => channelLanguage.language_code === languageCode
      );
      channel_ids.forEach((channelId) => {
        const channelDetail = channelLanguage.channel_details.find(
          (channelDetail) => channelDetail.channel_id === channelId
        );
        channelDetail.selected = checked;
      });
    });
    setChannelLanguageMatrix(channelLanguageMatrixCopy);
    onChangeChannelLanguageMatrixRef?.(channelLanguageMatrixCopy);
  };

  return (
    <Table striped singleLine data-testid={"customer-channel-language-matrix"}>
      <CustomerChannelLanguageMatrixTableHeader
        customerChannels={customerChannels}
        customerLanguages={customerLanguages}
        updateChannelLanguageCells={updateChannelLanguageCells}
        ShouldCheckOrNot={ShouldCheckOrNot}
      />
      <CustomerChannelLanguageMatrixTableBody
        channelLanguageMatrix={channelLanguageMatrix}
        customerChannels={customerChannels}
        customerLanguages={customerLanguages}
        disabled={disabled}
        updateChannelLanguageCells={updateChannelLanguageCells}
      />
      {buttonRows?.length && (
        <ChannelLanguageButtonsFooter
          buttonRows={buttonRows}
          customerChannels={customerChannels}
        />
      )}
      {footerRows?.length && (
        <ChannelLanguageSettingsFooter
          customerChannels={customerChannels}
          customerLanguages={customerLanguages}
          disabled={disabled}
          fetchedChannelLanguageMatrix={fetchedChannelLanguageMatrix}
          footerRows={footerRows}
          onChangeChannelSettingsFooterRef={onChangeChannelSettingsFooterRef}
        />
      )}
    </Table>
  );
};
