import React from "react";
import { generateId } from "../utils/uuidUtils";

export default class VariantsField extends React.Component {
  constructor(props) {
    super(props);

    const items = props.initialData.map((value) => {
      return {
        id: generateId(),
        value: value,
      };
    });

    this.state = { items };
  }

  callOnDataChanged() {
    const data = this.state.items.map((item) => item.value);
    this.props.onDataChanged(this, data);
  }

  onAddClick() {
    const items = this.state.items;
    items.push({
      id: generateId(),
      value: "",
    });

    this.setState({ items });
    this.callOnDataChanged();
  }

  onInputChange(item) {
    return (event) => {
      const newValue = event.target.value;
      item.value = newValue;

      this.setState({ items: this.state.items });
      this.callOnDataChanged();
    };
  }

  onRemoveClick(item) {
    return () => {
      const items = this.state.items;
      const index = items.indexOf(item);
      items.splice(index, 1);

      this.setState({ items });
      this.callOnDataChanged();
    };
  }

  onCopyClick(item) {
    return () => {
      const items = this.state.items;
      const index = items.indexOf(item);
      items.splice(index, 0, {
        id: generateId(),
        value: item.value,
      });

      this.setState({ items });
      this.callOnDataChanged();
    };
  }

  render() {
    return (
      <div className="VariantsField">
        <ol>
          {this.state.items.map((item) => (
            <li key={item.id}>
              <textarea
                onChange={this.onInputChange(item)}
                defaultValue={item.value}
              ></textarea>

              <button
                type="button"
                onClick={this.onRemoveClick(item)}
                className="remove"
              >
                <i className="material-icons">remove_circle_outline</i>
                <span>Remove</span>
              </button>

              <button
                type="button"
                onClick={this.onCopyClick(item)}
                className="copy"
              >
                <i className="material-icons">filter_none</i>
                <span>Copy</span>
              </button>
            </li>
          ))}
        </ol>
        <button type="button" onClick={() => this.onAddClick()} className="add">
          <i className="material-icons">add_circle_outline</i>
          <span>Add</span>
        </button>
      </div>
    );
  }
}
