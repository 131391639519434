import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Divider, Icon, Popup, Ref, Segment } from "semantic-ui-react";
import { Icon as TWIcon } from "../../../components/tailwind";

import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import { Text } from "../../../components/Text";
import { popupDelay } from "../../../customers/gpt/types";
import { illustrations } from "../illustrations";
import {
  IllustrationKeys,
  MoveItemDirection,
  Section,
  SectionFormMode,
} from "../types";
import { store } from "../../../utils/store";

import { EditSectionForm } from "./EditSectionForm";

type Props = {
  isLastItem: boolean;
  index: number;
  section: Section;
  sectionConnectedToStructuresInfo: string;
  moveSection: (index: number, direction: MoveItemDirection) => void;
  deleteSection: (index: number) => void;
  updateSection: (
    index: number,
    name: string,
    template_label_ids: number[],
    illustration: IllustrationKeys,
    closeEditPopup: () => void
  ) => Promise<void>;
  disableDeleteButton?: boolean;
};
export const SectionViewer: React.FC<Props> = ({
  section,
  sectionConnectedToStructuresInfo,
  isLastItem,
  index,
  moveSection,
  deleteSection,
  updateSection,
  disableDeleteButton,
}) => {
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();

  const isDefaultCustomerSection = useMemo(() => {
    if (!customer && !section) {
      return false;
    }
    return customer?.config?.default_document_section === section?.id;
  }, [customer, section]);

  const editButtonRef = useRef<HTMLButtonElement>(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [preventClickOutside, setPreventClickOutside] = useState(false);
  const editPopupHasBeenOpened = useRef(false);

  const [sectionName, setSectionName] = useState("");
  const [sectionTemplateLabels, setSectionTemplateLabels] = useState([]);
  const [illustration, setIllustration] = useState<IllustrationKeys>("body");

  useEffect(() => {
    if (section) {
      setSectionName(section.name);
      setSectionTemplateLabels(section.template_label_ids);
      setIllustration(section.illustration_type);
    }
  }, [section]);

  useEffect(() => {
    if (!openEditPopup && editPopupHasBeenOpened.current) {
      let hasRevertedChanges = false;
      if (sectionName !== section.name) {
        hasRevertedChanges = true;
        setSectionName(section.name);
      }
      if (sectionTemplateLabels !== section.template_label_ids) {
        hasRevertedChanges = true;
        setSectionTemplateLabels(section.template_label_ids);
      }
      if (illustration !== section.illustration_type) {
        hasRevertedChanges = true;
        setIllustration(section.illustration_type);
      }

      if (hasRevertedChanges) {
        store.dispatch(
          setDjangoToastOpen({
            content: "Changes reverted",
            appearance: NotificationAppearance.WARNING,
            additionalContent: "Did you forget to press 'Update' button?",
          })
        );
      }
    }
    editPopupHasBeenOpened.current = openEditPopup;
  }, [openEditPopup]);

  const onUpdateSection = async (): Promise<void> => {
    setPreventClickOutside(true);
    await updateSection(
      index,
      sectionName,
      sectionTemplateLabels,
      illustration,
      (): void => {
        setOpenEditPopup(false);
        setPreventClickOutside(false);
      }
    );
  };

  return (
    <div
      style={{ position: "relative" }}
      data-testid={`section-viewer-${section.name}`}
    >
      <i
        className="material-icons"
        style={{
          opacity: 0.3,
          cursor: "grab",
          position: "absolute",
          left: "-25px",
          top: "2px",
        }}
      >
        drag_indicator
      </i>
      <Popup
        content="Remove section"
        size="tiny"
        position="top right"
        mouseEnterDelay={popupDelay}
        trigger={
          <Button
            data-testid={`section-viewer-delete-section-${section.name}`}
            icon="close"
            basic
            disabled={disableDeleteButton}
            size="mini"
            floated="right"
            compact
            onClick={(): void => deleteSection(index)}
          />
        }
      />
      <div className="tw-mb-[2px] tw-flex tw-items-center tw-gap-3">
        <Popup
          disabled={!section?.locked}
          content={
            isDefaultCustomerSection
              ? "This Section is reserved for System Prompts and cannot be edited."
              : "This Section is not editable."
          }
          size="small"
          wide="very"
          mouseEnterDelay={popupDelay}
          trigger={
            <Segment
              compact
              color="blue"
              inverted
              className="!tw-m-0 !tw-flex tw-cursor-grab tw-items-center tw-gap-1 !tw-p-2"
            >
              {section?.locked && <Icon name="lock" size="small" />}
              <Text
                inline={section?.locked}
                compact
                size="small"
                testId={`section-viewer-name-section-${section.name}`}
              >
                {sectionName}
              </Text>
              {section.template_label_ids ? (
                <TWIcon
                  className="tw-text-lg tw-leading-[1]"
                  data-balloon="Rule-based Section"
                  data-balloon-pos="down-right"
                  name="call_split"
                />
              ) : (
                <TWIcon
                  className="tw-text-lg tw-leading-[1]"
                  data-balloon="AI Section"
                  data-balloon-pos="down-right"
                  name="auto_awesome"
                />
              )}
            </Segment>
          }
        />
        <div>
          <Button.Group basic size="mini" compact icon>
            <Popup
              content="Move Section up"
              size="tiny"
              mouseEnterDelay={popupDelay}
              trigger={
                <Button
                  data-testid={`section-viewer-move-section-up-${section.name}`}
                  icon="arrow up"
                  disabled={index === 0}
                  onClick={(): void => moveSection(index, MoveItemDirection.UP)}
                />
              }
            />
            <Popup
              content="Move Section down"
              size="tiny"
              mouseEnterDelay={popupDelay}
              trigger={
                <Button
                  data-testid={`section-viewer-move-section-down-${section.name}`}
                  icon="arrow down"
                  disabled={isLastItem}
                  onClick={(): void =>
                    moveSection(index, MoveItemDirection.DOWN)
                  }
                />
              }
            />
          </Button.Group>{" "}
          <Button.Group basic size="mini" compact icon>
            <Ref innerRef={editButtonRef}>
              <Popup
                disabled={openEditPopup}
                content={
                  section?.locked
                    ? "This Section is not editable."
                    : "Edit Section"
                }
                size="tiny"
                mouseEnterDelay={popupDelay}
                trigger={
                  <span>
                    <Button
                      disabled={section?.locked}
                      data-testid={`section-viewer-edit-section-${section.name}`}
                      icon="pencil alternate"
                      onClick={(): void => setOpenEditPopup(!openEditPopup)}
                    />
                  </span>
                }
              />
            </Ref>
          </Button.Group>
        </div>
        {sectionConnectedToStructuresInfo && (
          <p className="tw-m-0 tw-align-middle tw-text-sm tw-text-gray-600">
            {sectionConnectedToStructuresInfo}
          </p>
        )}
      </div>
      <Segment style={{ marginTop: "0px" }}>
        {illustrations[illustration] || illustrations.body}
      </Segment>
      <Divider hidden />
      <Popup
        context={editButtonRef}
        position="right center"
        wide="very"
        style={preventClickOutside ? { zIndex: 1 } : {}}
        open={openEditPopup}
        onClose={(): void => {
          if (!preventClickOutside) setOpenEditPopup(false);
        }}
        size="small"
      >
        <Popup.Content>
          <EditSectionForm
            loading={isCustomerLoading}
            sectionName={sectionName}
            setSectionName={setSectionName}
            availableTemplateLabels={customer?.template_labels}
            sectionTemplateLabels={sectionTemplateLabels}
            setSectionTemplateLabels={setSectionTemplateLabels}
            illustration={illustration}
            setIllustration={setIllustration}
            handleActionButtonClick={onUpdateSection}
            handleClose={(): void => setOpenEditPopup(false)}
            formMode={SectionFormMode.UPDATE}
          />
        </Popup.Content>
      </Popup>
    </div>
  );
};
