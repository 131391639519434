import api from "./api";
import { Customer } from "../customers/Customer";
import { ProductText } from "../producttext/ProductText";
import { ProductImage } from "../products/ProductImage";
import { AxiosResponse } from "axios";
import {
  ChannelLanguagePairData,
  Product,
  ProductId,
  ProductStatus,
} from "../products/product";
import { TextGenerationRequest } from "../utils/TaskDispatcher2";
import { ProductTextAction } from "../producttext/ProductTextAction";
import { SelectionAction } from "../products/product-list/ProductList";
import { ProductListParams } from "../products/product-list/ProductListURLConfig";
import {
  LanguageMapping,
  SEOScore,
} from "../products/seo-tab/ProductDetailSEOTab";
import { ProductDetailInfo } from "../products/edit-tab/ProductDetailInfo";
import { VocabularyTreeData } from "../utils/tagutils";
import { ProductRecommendations } from "../products/edit-tab/ProductRecommendations";
import { FieldOption, getURL } from "../utils/ApiUtils";
import { ProductSearchAction, ProductSearchResult, TaskId } from "./types";
import { TaskResult } from "../utils/types";
import { ProductTextActivity } from "../producttext/ProductTextActivityModal";
import { UserActionContext } from "../products/publish/actionTypes";
import { CopyData } from "../products/actions/types";
import { AlertMessageType } from "../alerts/Alerts";
import { LanguageCode } from "../customers/customerlanguages";
import { FieldSpecification } from "../../ts/predefined_title";
import { FindAndReplaceInputData } from "../machine-translation/FindAndReplaceModal";

export async function generateText(
  vocabularies: any,
  token: string,
  textualAppName: string
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };
  return api.post("product/generate2", vocabularies, config);
}

export async function addBrand(
  postData: any,
  token: string,
  textualAppName: string
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };
  return api.post("customer/brands/", postData, config);
}

export async function spanExtract(
  postData: any,
  token: string,
  textualAppName: string
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };
  return api.post(`product/span_extract`, postData, config);
}

/*
 * Fetch customer details for current users
 *
 * Based on a token fetch the user and return
 * customer, config, configured languages and channels information
 */
let _customer: Customer = null;

const customerlessPages = ["/d/auth/login", "/d/auth/not-verified"];

export async function customerDetails(token: string): Promise<Customer> {
  if (customerlessPages.some((url) => window.location.pathname.includes(url))) {
    return Promise.resolve(null);
  }
  if (_customer != null) {
    return Promise.resolve(_customer);
  }
  const response = await api.get("/api/_internal/customer/details", {
    headers: { token },
  });
  _customer = new Customer(response.data);
  return _customer;
}

/*
 * Search for a product
 * See products.views.api_internal.ProductSearchRequest for list of supported parameters.
 */
export async function productSearch(
  token: string,
  params: ProductListParams,
  action: ProductSearchAction.SELECT
): Promise<string[]>;
export async function productSearch(
  token: string,
  params: ProductListParams,
  action: ProductSearchAction.SEARCH
): Promise<ProductSearchResult>;

export async function productSearch(
  token: string,
  params: ProductListParams,
  action: ProductSearchAction
): Promise<any> {
  const url = new URLSearchParams();
  params.action = action.toString();
  if (params.page) {
    url.set("page", params.page);
  }
  url.set("page_size", params.pagination_size.toString());
  url.set("bucket", params.bucket);
  url.set("query", params.query);
  const postUrl = `/api/_internal/product/search?${url.toString()}`;
  // Using .post() so we can support large parameter sizes
  try {
    const response = await api.post(postUrl, params, { headers: { token } });
    return response.data;
  } catch (e) {
    return { error: true };
  }
}

/*
 * Get product by id
 */
export async function getProduct(
  token: string,
  productId: ProductId
): Promise<Product> {
  const getUrl = `/api/_internal/product/${productId}/list_one`;
  const response = await api.get(getUrl, { headers: { token } });
  return response.data;
}

/*
 * Get all images related to the product
 */
export async function getProductImages(
  token: string,
  productId: ProductId
): Promise<ProductImage[]> {
  const getUrl = `/api/_internal/product/${productId}/images`;
  const response = await api.get(getUrl, { headers: { token } });
  return response.data.map((imageData: any) => new ProductImage(imageData));
}

/*
 * Set parent product
 */

type updateProductParentRequestData = {
  parentProductEntity: string;
  productId: ProductId;
  token: string;
};

type updateProductParentResponseData = {
  result: boolean;
  message: string;
  product?: Product;
};

export async function updateProductParent({
  parentProductEntity,
  productId,
  token,
}: updateProductParentRequestData): Promise<updateProductParentResponseData> {
  const response = await api.post(
    "/api/_internal/product/product_parent_update",
    {
      product: productId,
      parent_entity: parentProductEntity || null,
    },
    { headers: { token } }
  );
  return response.data;
}

/*
 * Queue a list of generation requests
 *
 * Each request requires a channel/language and product.
 *
 * If there product text is already generated the text will already be
 * sent back, otherwise a task_id will provided.
 *
 */
export async function productTextQueueGeneration(
  token: string,
  requests: TextGenerationRequest[]
): Promise<TaskId[]> {
  const generationRequests = requests.map((request) => request.getApiParams());
  const response = await api.post(
    "/api/_internal/producttext/queuegeneration",
    {
      generation_requests: generationRequests,
    },
    { headers: { token } }
  );
  return response.data.task_ids;
}

/*
 * Fetch text generation requests made via productTextQueueGeneration
 *
 * Provides a set of task_ids and will return the generated texts for these.
 * This is expected to be called several times until all generated texts
 * are available.
 */

export async function fetchTaskResults(
  token: string,
  taskIds: TaskId[]
): Promise<Record<string, TaskResult>> {
  try {
    const { data } = await api.post(
      "/api/_internal/fetch_task_results",
      { task_ids: taskIds },
      { headers: { token } }
    );
    return data.task_ids;
  } catch (error) {
    return Promise.resolve({});
  }
}

export async function fetchTaskResults2(
  token: string,
  taskIds: TaskId[]
): Promise<Record<string, TaskResult>> {
  try {
    const { data } = await api.post(
      "/api/_internal/fetch_task_results2",
      { task_ids: taskIds },
      { headers: { token } }
    );
    return data.task_ids;
  } catch (error) {
    return Promise.resolve({});
  }
}

/*
 * Do an action on a product text.
 *
 * See products.views.api_internal.ProductTextActionRequest for list of supported
 * actions.
 */
export async function productTextAction(
  token: string,
  userActionContext: UserActionContext,
  productTextIds: number[],
  action: ProductTextAction,
  data: any
): Promise<ProductText[]> {
  if (productTextIds.includes(null) || productTextIds.length === 0) {
    throw `Action: ${action.toUpperCase()} failed. No product text`;
  } else {
    const response = await api.post(
      "/api/_internal/producttext/action",
      {
        product_text_ids: productTextIds,
        user_action_context: userActionContext,
        action,
        data,
      },
      { headers: { token } }
    );

    return response.data.product_texts.map(
      (productText: any) => new ProductText(productText)
    );
  }
}

/*
 * Do an action on the a selection bucket
 *
 * See products.views.api_internal.SelectionBucketRequest for list of supported
 * actions.
 */
export async function selectionBucketAction(
  token: string,
  bucket: string,
  action: SelectionAction,
  data: any
): Promise<ProductId[]> {
  const response = await api.post(
    "/api/_internal/selectionbucket/action",
    { action, bucket, data },
    { headers: { token } }
  );
  return response.data.selection_values;
}

export type SEOResult = {
  scores: LanguageMapping<SEOScore[]>;
};

/*
 * Fetch product SEO data
 */
export async function getProductSEO(
  token: string,
  productId: ProductId
): Promise<SEOResult> {
  const getUrl = `/api/_internal/product/${productId}/seo_scores`;
  const { data } = await api.get(getUrl, { headers: { token } });
  return data;
}

/*
 * Replace selected product tag with another selected one
 */
export async function replaceProductTag(
  token: string,
  productId: ProductId,
  relatedTreeId: string
): Promise<SEOResult> {
  const { data } = await api.post(
    `/api/_internal/product/${productId}/replace_tag`,
    {
      related_tree_id: relatedTreeId,
    },
    { headers: { token } }
  );
  return data;
}

export async function fetchProductDetail(
  token: string,
  customer: Customer,
  productId: ProductId
): Promise<ProductDetailInfo> {
  const response = await api.get(
    `/api/_internal/product/${productId}/details_info`,
    {
      headers: { token },
    }
  );
  return new ProductDetailInfo(customer, token, productId, response.data);
}

export async function fetchProductRecommendations({
  token,
  productId,
  vocabId,
  useVocabAsKind = false,
}: {
  token: string;
  productId: ProductId;
  vocabId: number;
  useVocabAsKind?: boolean;
}): Promise<ProductRecommendations> {
  const response = await api.get(`/api/_internal/product/recommendations`, {
    params: {
      product_id: productId,
      use_vocab_as_kind: useVocabAsKind,
      vocab_id: vocabId,
    },
    headers: { token },
  });
  const {
    field_recommendations: fieldRecommendations,
    seo_recommendations: seoRecommendations,
    subpart_recommendations: subpartRecommendations,
    subtree_recommendations: subtreeRecommendations,
  } = response.data;
  return {
    fieldRecommendations,
    seoRecommendations,
    subpartRecommendations,
    subtreeRecommendations,
  };
}

export enum ProductTagAction {
  ADD = "add",
  ADD_EXTRACT = "add-extract",
  ADD_NUMBER = "add-number",
  ADD_QUICK_SEARCH = "add-quick-search",
  ADD_RECOMMENDATION = "add-recommendation",
  ADD_SEO_RECOMMENDATION = "add-seo-recommendation",
  ADD_UNTRANSLATED_NAME = "add-untranslated-name",
  ADD_SUBPART = "add-subpart",
  CLEAR = "clear",
  REMOVE = "remove",
  REORDER = "reorder",
  REPLACE_EXTRACT = "replace-extract",
  REMOVE_FROM_FREE_TEMPLATE = "remove-from-free-template",
}

export type ProductTagAddRequest = {
  action: ProductTagAction.ADD;
  parent?: string;
  value: VocabularyTreeData;
};

export type ProductTagAddExtractRequest = {
  action: ProductTagAction.ADD_EXTRACT;
  text: string;
  value: VocabularyTreeData[];
};

export type ProductTagAddNumberRequest = {
  action: ProductTagAction.ADD_NUMBER;
  parent?: string;
  number: string;
  quantity: number | "";
  numeric_value: boolean;
};

type ProductTagAddQuickSearchRequest = {
  action: ProductTagAction.ADD_QUICK_SEARCH;
  parent?: string;
  value: VocabularyTreeData;
};

type ProductTagAddRecommendationRequest = {
  action: ProductTagAction.ADD_RECOMMENDATION;
  parent?: string;
  vocabulary: number;
  headline: string;
};

type ProductTagAddSeoRecommendation = {
  action: ProductTagAction.ADD_SEO_RECOMMENDATION;
  value: number;
};

type ProductTagAddUntranslatedNameRequest = {
  action: ProductTagAction.ADD_UNTRANSLATED_NAME;
  parent: string;
  value: string;
};

type ProductTagAddSubpart = {
  action: ProductTagAction.ADD_SUBPART;
  parent?: string;
  kind?: number;
  child?: number;
};

// We don't need parent here as tag_id's are assumed to be unique.
type ProductTagClearRequest = {
  action: ProductTagAction.CLEAR;
  parent?: string;
  tagids: string[];
};

// We don't need parent here as tag_id is assumed to be unique.
type ProductTagRemoveRequest = {
  action: ProductTagAction.REMOVE;
  value: string[];
};

type RemoveTagFromFreeTemplateRequest = {
  action: ProductTagAction.REMOVE_FROM_FREE_TEMPLATE;
  template_id: string;
  value: string[];
};

type ProductTagReorder = {
  action: ProductTagAction.REORDER;
  parent?: string;
  value: string[];
};

export type ProductTagReplaceExtractRequest = {
  action: ProductTagAction.REPLACE_EXTRACT;
  text: string;
  value: VocabularyTreeData[];
};

export type ProductTagRequest =
  | ProductTagAddRequest
  | ProductTagAddExtractRequest
  | ProductTagAddNumberRequest
  | ProductTagAddQuickSearchRequest
  | ProductTagAddRecommendationRequest
  | ProductTagAddUntranslatedNameRequest
  | ProductTagAddSeoRecommendation
  | ProductTagAddSubpart
  | ProductTagClearRequest
  | ProductTagRemoveRequest
  | ProductTagReorder
  | ProductTagReplaceExtractRequest
  | RemoveTagFromFreeTemplateRequest;

export interface ProductTagsResponse {
  details: ProductDetailInfo;
  message: string;
  error: string;
  result: VocabularyTreeData | null;
}

type ProductTagsTreeRequestData = {
  product_id: string;
};

export type ProductTagsTreeResponse = {
  tagsTree: string;
};

export async function productTagsAction(
  token: string,
  customer: Customer,
  productId: ProductId,
  request: ProductTagRequest
): Promise<ProductTagsResponse> {
  const { data } = await api.post(
    `/api/_internal/product/${productId}/tag_action`,
    request,
    {
      headers: { token: token },
    }
  );
  return {
    details: new ProductDetailInfo(customer, token, productId, data.details),
    message: data.message,
    result: data.result,
    error: data.error,
  };
}

export async function getProductTexts(request: {
  token: string;
  channelLanguagePairs?: { channel_id: number; language_code: string }[];
  productIds?: ProductId[];
  skipCreateExcludeList?: boolean;
}): Promise<ProductText[]> {
  const response = await api.post(
    `/api/_internal/producttext/many`,
    {
      channel_language_pairs: request.channelLanguagePairs ?? [],
      product_ids: request.productIds ?? [],
      skip_create_exclude_list: request.skipCreateExcludeList ?? false,
    },
    {
      headers: { token: request.token },
    }
  );
  return response.data.map((productText: any) => new ProductText(productText));
}

export async function getTemplateOptions(
  token: string
): Promise<FieldOption[]> {
  const { data } = await api.get(
    `/api/_internal/field-options/template-single-selector`,
    {
      headers: { token: token },
    }
  );
  return data.options;
}

export async function getLabelOptions(token: string): Promise<FieldOption[]> {
  const { data } = await api.get(
    `/api/_internal/field-options/label-single-selector`,
    {
      headers: { token: token },
    }
  );
  return data.options;
}

export async function getAllCustomerBrands(
  token: string
): Promise<FieldOption[]> {
  const { data } = await api.get(`/api/_internal/customer/brands/`, {
    headers: { token: token },
  });
  return data.options;
}

export enum RegenerateAction {
  REGENERATE_APPROVED = "approved",
  REGENERATE_EDITED = "edited",
  REGENERATE_PUBLISHED = "published",
}

export type RegenerateConfig = {
  regenerate: boolean;
  regenerateApprovedTexts?: boolean;
  regeneratePublishedTexts?: boolean;
  regenerateEditedTexts?: boolean;
};

export type RegenerateConfigRequest = {
  regenerate: boolean;
  regenerate_approved_texts?: boolean;
  regenerate_published_texts?: boolean;
  regenerate_edited_texts?: boolean;
};

export type SwitchProductStatusRequestData = {
  status: ProductStatus;
  user_action_context: UserActionContext;
  regenerate_config?: RegenerateConfigRequest;
};

export async function switchProductStatus(
  token: string,
  productId: ProductId,
  status: ProductStatus,
  userActionContext: UserActionContext,
  regenerateConfig?: RegenerateConfig
): Promise<any> {
  // null is not allowed in typescript enums
  if (status == ProductStatus.IN_PROGRESS) {
    status = null;
  }
  const postData: SwitchProductStatusRequestData = {
    status: status,
    user_action_context: userActionContext,
  };
  if (regenerateConfig) {
    postData.regenerate_config = {
      regenerate: regenerateConfig.regenerate,
      regenerate_approved_texts: regenerateConfig.regenerateApprovedTexts,
      regenerate_published_texts: regenerateConfig.regeneratePublishedTexts,
      regenerate_edited_texts: regenerateConfig.regenerateEditedTexts,
    };
  }

  const { data } = await api.post(
    `/api/_internal/product/${productId}/status`,
    postData,
    { headers: { token } }
  );
  return data;
}

type getProductTextCountRequest = {
  selection_bucket_name: string;
  channel_language_pairs: ChannelLanguagePairData[];
  regenerate_approved: boolean;
  regenerate_edited: boolean;
  regenerate_published: boolean;
};

export async function getProductTextCount(
  token: string,
  bucketName: string,
  channelLanguagePairs: ChannelLanguagePairData[] | null,
  overrideApproved: boolean,
  overrideEdited: boolean,
  overridePublished: boolean
): Promise<number> {
  const data: getProductTextCountRequest = {
    selection_bucket_name: bucketName,
    channel_language_pairs: channelLanguagePairs,
    regenerate_approved: overrideApproved,
    regenerate_edited: overrideEdited,
    regenerate_published: overridePublished,
  };

  const response = await api.post(
    `/api/_internal/producttext/product_text_count`,
    data,
    {
      headers: { token: token },
    }
  );
  return response.data.count;
}

type FetchDataTagInput = {
  token: string;
  productId: ProductId;
};

export async function fetchDataTagTab({
  token,
  productId,
}: FetchDataTagInput): Promise<ProductTagsTreeResponse> {
  const postData: ProductTagsTreeRequestData = {
    product_id: productId,
  };
  const { data } = await api.post(
    `/api/_internal/product/product_tags_html`,
    postData,
    {
      headers: { token },
    }
  );
  return {
    tagsTree: data.tags_tree,
  };
}

type UpdateProductChannelLanguagePairsInput = {
  token: string;
  productId: ProductId;
  channelLanguagePairs: ChannelLanguagePairData[];
};

type UpdateProductChannelLanguagePairsRequest = {
  pairs: ChannelLanguagePairData[];
  product_id: ProductId;
};

export async function updateProductChannelLanguagePairs({
  channelLanguagePairs,
  productId,
  token,
}: UpdateProductChannelLanguagePairsInput): Promise<boolean> {
  const postData: UpdateProductChannelLanguagePairsRequest = {
    pairs: channelLanguagePairs,
    product_id: productId,
  };

  try {
    await api.post(
      `/api/_internal/product/update_channel_language_pairs`,
      postData,
      {
        headers: { token },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
}

type ProductTextActivityRequestInput = {
  productTextId: number;
  token: string;
};

type ProductTextActivityRequestData = {
  product_text_id: number;
};

export async function fetchProductTextActivity({
  productTextId,
  token,
}: ProductTextActivityRequestInput): Promise<ProductTextActivity[]> {
  const postData: ProductTextActivityRequestData = {
    product_text_id: productTextId,
  };
  const { data } = await api.post(
    `/api/_internal/producttext/activity`,
    postData,
    {
      headers: { token },
    }
  );
  return data.items;
}

export async function copyProductTags(
  {
    productId,
    productEAN,
    productSKU,
    copyMode,
    shouldMergeData,
    copyBrand,
    copyName,
    copyLabels,
    copyChannelsConfig,
  }: CopyData,
  token: string
): Promise<any> {
  const response = await api.post(
    "/api/_internal/product_copy",
    {
      product_id: productId,
      product_EAN: productEAN,
      product_SKU: productSKU,
      copy_mode: copyMode,
      should_merge_data: shouldMergeData,
      copy_brand: copyBrand,
      copy_name: copyName,
      copy_labels: copyLabels,
      copy_channels_config: copyChannelsConfig,
    },
    { headers: { token } }
  );

  return response.data;
}

type storeBulkActionReturnUrlRequest = {
  token: string;
  url: string;
};

export async function storeBulkActionReturnUrl({
  token,
  url,
}: storeBulkActionReturnUrlRequest): Promise<string> {
  const response = await api.post(
    `/api/_internal/bulk-action/store-return-url`,
    {
      url: url,
    },
    {
      headers: { token },
    }
  );
  return response.data.return_url;
}

type fetchProductCountRequest = {
  token: string;
  searchParams: ProductListParams;
};

type fetchVocabularyProductCountRequest = {
  token: string;
  vocabularyId: number;
};

export type fetchVocabularyProductCountResponse = {
  product_count: number;
  products_url: string;
};

export async function fetchProductCount({
  token,
  searchParams,
}: fetchProductCountRequest): Promise<any> {
  const response = await api.post(
    `/api/_internal/product/product_count`,
    searchParams,
    {
      headers: { token },
    }
  );
  return response.data.count;
}

export async function fetchVocabularyProductCount({
  token,
  vocabularyId,
}: fetchVocabularyProductCountRequest): Promise<
  fetchVocabularyProductCountResponse
> {
  const response = await api.post(
    `/api/_internal/vocabulary/get_vocabulary_product_count`,
    { vocabulary_id: vocabularyId },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function getLangStringTemplate({
  token,
  templateId,
  tagId,
  text,
  languageCode,
  productId,
}: {
  token: string;
  templateId: string;
  tagId: string;
  text: string;
  languageCode: LanguageCode;
  productId: ProductId;
}): Promise<{
  langsting_dict: { [key: string]: string };
  template_vocab_content: string;
  exact_variable: string | null;
}> {
  const response = await api.post(
    "/api/_internal/vocabulary/langstring_template/get",
    {
      template_id: templateId,
      tag_id: tagId,
      text,
      language_code: languageCode,
      product_id: productId,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updateLangStringTemplate({
  token,
  templateId,
  tagId,
  currentLangstringData,
  languageCode,
  productId,
}: {
  token: string;
  templateId: string;
  tagId: string;
  currentLangstringData: { [key: string]: string };
  languageCode: LanguageCode;
  productId: ProductId;
}): Promise<{ updated: boolean }> {
  const response = await api.post(
    "/api/_internal/vocabulary/langstring_template/update",
    {
      template_id: templateId,
      tag_id: tagId,
      langstring_data: currentLangstringData,
      language_code: languageCode,
      product_id: productId,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function fetchAlerts(token: string): Promise<AlertMessageType[]> {
  const response = await api.get("/api/_internal/alert/all", {
    headers: { token },
  });
  return response.data;
}

export async function getFieldSpecification(
  token: string,
  formData: string
): Promise<FieldSpecification[]> {
  const response = await api.post<{ suggestions: FieldSpecification[] }>(
    "/api/_internal/product/get_field_specification",
    {
      field_id: formData,
    },
    {
      headers: { token },
    }
  );
  return response.data.suggestions;
}

export async function searchFieldSpecification(
  token: string,
  valueId: number,
  search: string
): Promise<FieldSpecification[]> {
  const response = await api.post<{ suggestions: FieldSpecification[] }>(
    "/api/_internal/product/search_field_specification",
    {
      value_id: valueId,
      search,
    },
    {
      headers: { token },
    }
  );
  return response.data.suggestions;
}

export async function updateFindAndReplaces(
  token: string,
  replacements: FindAndReplaceInputData[]
): Promise<unknown> {
  const response = api.post(
    "/api/_internal/customer/find-and-replace",
    { replacements },
    {
      headers: { token },
    }
  );

  return response;
}
