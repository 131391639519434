export function formatDate(
  input: string | Date,
  skipSeconds: boolean = false
): string {
  const amountToSlice = skipSeconds ? 16 : 19;
  return new Date(input)
    .toISOString()
    .slice(0, amountToSlice)
    .replace("T", " ");
}
