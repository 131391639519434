import React from "react";
import { Form } from "semantic-ui-react";

type Props = { isLoading: boolean; testId: string };
export const PromptFormPaneForm: React.FC<Props> = ({
  isLoading,
  children,
  testId,
}) => {
  return (
    <Form as="div" size="small" data-testid={testId} loading={isLoading}>
      {children}
    </Form>
  );
};
