import { ChannelLanguagePairData } from "../product";
import { TranslationModalFormData } from "./TranslationsModal";
import { defaultDict } from "../../utils/defaultdict";
import { ChannelId } from "../../customers/Customer";
import { LanguageCode } from "../../customers/customerlanguages";

export const mapTranslationModalFormData = (
  customerChannelLanguagePairs: ChannelLanguagePairData[]
): TranslationModalFormData[] => {
  const languagesByChannels = defaultDict<string[]>(() => []);
  customerChannelLanguagePairs.forEach((pair) => {
    const channelId = pair.channel_id;
    languagesByChannels[channelId].push(pair.language_code);
  });

  const channelsByLanguages = defaultDict<ChannelId[]>(() => []);
  for (const [channelId, languages] of Object.entries(languagesByChannels)) {
    const languagesString = JSON.stringify(languages);
    channelsByLanguages[languagesString].push(Number(channelId));
  }

  const translationModalFormData: TranslationModalFormData[] = [];
  for (const [languagesString, channelsIds] of Object.entries(
    channelsByLanguages
  )) {
    translationModalFormData.push({
      key: translationModalFormData.length,
      selectedLanguageCodes: JSON.parse(languagesString) as LanguageCode[],
      selectedCustomerChannelIds: channelsIds,
    });
  }
  return translationModalFormData;
};
