import React from "react";
import { ProductText } from "../producttext/ProductText";
import {
  getProductTextStatusIcon,
  missingTranslationIconWithTooltip,
  notMarkedForPublishIconWithTooltip,
} from "../producttext/ProductTextStatusIconHelper";
import styled from "styled-components";

type Props = {
  markedForPublish: boolean;
  productText: ProductText;
};

const StatusIconFFTextStatusIcons = styled.div`
  & {
    position: absolute;
    top: -0.43em;
    left: 0.54em;
    transform: scale(0.6);
    z-index: 3;
  }
`;

export const ProductTextStatusIcon: React.FC<Props> = ({
  markedForPublish,
  productText,
}) => {
  let icon;
  if (!markedForPublish) {
    icon = notMarkedForPublishIconWithTooltip();
  } else if (!productText) {
    return null;
  } else if (productText.missingTranslation || productText.failed) {
    icon = missingTranslationIconWithTooltip();
  } else {
    icon = getProductTextStatusIcon(productText);
  }
  return <StatusIconFFTextStatusIcons>{icon}</StatusIconFFTextStatusIcons>;
};
