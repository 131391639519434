import { createContext, Dispatch } from "react";
import { groupPromptGroupsByTags, groupPromptsByTags } from "../utils";
import {
  CopyAssistantContextState,
  CopyAssistantActions,
  CopyAssistantInitialPromptStatePayload,
  CopyAssistantContextPromptState,
  modules,
  CopyAssistantActionType,
} from "./types";

export const initialCopyAssistantState: CopyAssistantContextState = {
  productId: null,
  globalIsLoading: false,
  prompts: {
    isLoading: true,
    list: [],
    selected: null,
    availableTags: { customerOwned: [], systemOwned: [] },
    customerOwned: [],
    systemOwned: [],
  },
  groups: {
    isLoading: true,
    list: [],
    selected: null,
    availableTags: [],
  },
  sentences: {
    isLoading: false,
    promptLoadingIds: [],
    list: [],
    autoTranslateList: [],
    sectionNamesOrderedByLastUsed: [],
  },
  inputData: {
    fetchNew: true,
  },
  fieldsets: {
    list: [],
    availableMetadata: {},
    isLoading: true,
  },
  productSpecificOverwrites: {
    list: [],
    isLoading: false,
  },
};
export const CopyAssistantContext = createContext<CopyAssistantContextState>(
  initialCopyAssistantState
);

export const CopyAssistantDispatchContext = createContext<Dispatch<
  CopyAssistantActions
> | null>(null);

const getPromptInitState = (
  payload: Omit<CopyAssistantInitialPromptStatePayload, "selectedGroup">
): CopyAssistantContextPromptState => {
  const initState = payload as CopyAssistantContextPromptState;
  const customerPrompts = initState.list.filter(
    (prompt) => !!prompt.customer_id
  );
  const systemPrompts = initState.list.filter((prompt) => !prompt.customer_id);
  const orderedCustomerPrompts = groupPromptsByTags(customerPrompts);
  const orderedSystemPrompts = groupPromptsByTags(systemPrompts);
  initState.availableTags = {
    customerOwned: Object.keys(orderedCustomerPrompts),
    systemOwned: Object.keys(orderedSystemPrompts),
  };
  initState.customerOwned = customerPrompts;
  initState.systemOwned = systemPrompts;
  return initState;
};

const promptStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.SET_PROMPT_INIT_STATE: {
      const promptNewState = getPromptInitState(action.payload);
      let selectedPrompt = promptNewState.selected; // pre selection
      let selectedGroup = action.payload.selectedGroup; // pre selection
      if (state.prompts.selected) {
        selectedPrompt = promptNewState.list.find(
          (prompt) => prompt.id === state.prompts.selected.id
        );
        selectedGroup = null;
      }
      if (state.groups.selected) {
        selectedGroup = state.groups.selected;
        selectedPrompt = null;
      }

      return {
        ...state,
        prompts: {
          ...promptNewState,
          selected: selectedPrompt,
        },
        groups: {
          ...state.groups,
          selected: selectedGroup,
        },
      };
    }
    case CopyAssistantActionType.SET_PROMPT_LOADING:
      return {
        ...state,
        prompts: { ...state.prompts, isLoading: action.payload },
      };
    case CopyAssistantActionType.SELECT_PROMPT: {
      return {
        ...state,
        prompts: { ...state.prompts, selected: action.payload },
        groups: { ...state.groups, selected: null },
      };
    }
  }
};

const groupStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.SET_GROUP_INIT_STATE: {
      const ordered = groupPromptGroupsByTags(action.payload.list);
      const selectedGroup = action.payload.list.find(
        (group) => group.id === state.groups.selected?.id
      );
      return {
        ...state,
        groups: {
          ...state.groups,
          ...action.payload,
          availableTags: Object.keys(ordered),
          selected: selectedGroup,
        },
      };
    }
    case CopyAssistantActionType.SET_GROUP_LOADING:
      return {
        ...state,
        groups: { ...state.groups, isLoading: action.payload },
      };
    case CopyAssistantActionType.SELECT_GROUP:
      return {
        ...state,
        groups: { ...state.groups, selected: action.payload },
        prompts: { ...state.prompts, selected: null },
      };
  }
};

const sentenceStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.SET_SENTENCE_LOADING: {
      const { loading: isLoading, promptIds } = action.payload;
      return {
        ...state,
        sentences: {
          ...state.sentences,
          isLoading: isLoading,
          promptLoadingIds: isLoading ? promptIds : [],
        },
      };
    }
    case CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING: {
      const { list } = state.sentences;
      const newList = [
        ...list.map((sentence) =>
          sentence.id === action.payload.id
            ? {
                ...sentence,
                isLoading: action.payload.isLoading,
                loadingReason: action.payload?.reason,
              }
            : sentence
        ),
      ];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
        },
      };
    }
    case CopyAssistantActionType.RESET_ALL_SENTENCES_LOADING_STATES: {
      const { list } = state.sentences;
      const newList = [
        ...list.map((sentence) => ({
          ...sentence,
          isLoading: false,
          loadingReason: "",
        })),
      ];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          isLoading: false,
          promptLoadingIds: [],
          list: newList,
        },
      };
    }
    case CopyAssistantActionType.REMOVE_SENTENCE: {
      const { list } = state.sentences;
      const newList = [
        ...list.filter((sentence) => sentence.id !== action.payload),
      ];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
        },
      };
    }
    case CopyAssistantActionType.UPDATE_SENTENCE: {
      const { list } = state.sentences;
      const { id, toUpdate } = action.payload;
      const newList = [
        ...list.map((sentence) =>
          sentence.id === id
            ? {
                ...sentence,
                ...toUpdate,
                value: { ...sentence.value, ...(toUpdate?.value || {}) },
              }
            : sentence
        ),
      ];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
        },
      };
    }

    case CopyAssistantActionType.ADD_SENTENCE: {
      const { list } = state.sentences;

      const newList = [...list, action.payload];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
          isLoading: false,
        },
      };
    }
    case CopyAssistantActionType.ADD_SENTENCES: {
      const { list } = state.sentences;
      const newList = [...list, ...action.payload];
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
          isLoading: false,
        },
      };
    }
    case CopyAssistantActionType.SET_SECTION_ACTIVE: {
      const { list } = state.sentences;
      const { id } = action.payload;
      const sectionId = list.find((sentence) => sentence.id === id)?.sectionInfo
        ?.documentSectionId;
      if (!sectionId) return state;

      const newList = list.map((sentence) => {
        if (sentence?.sectionInfo?.documentSectionId === sectionId) {
          if (sentence.id === id) {
            return {
              ...sentence,
              sectionInfo: { ...sentence.sectionInfo, active: true },
            };
          }
          return {
            ...sentence,
            sectionInfo: { ...sentence.sectionInfo, active: false },
          };
        }
        return sentence;
      });
      return {
        ...state,
        sentences: {
          ...state.sentences,
          list: newList,
        },
      };
    }
    case CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST: {
      const { autoTranslateList } = state.sentences;
      return {
        ...state,
        sentences: {
          ...state.sentences,
          autoTranslateList: [...autoTranslateList, action.payload],
        },
      };
    }
    case CopyAssistantActionType.REMOVE_SENTENCE_AUTO_TRANSLATE_LIST: {
      const { autoTranslateList } = state.sentences;
      const id = action.payload.id;
      return {
        ...state,
        sentences: {
          ...state.sentences,
          autoTranslateList: [
            ...autoTranslateList.filter((obj) => obj.id !== id),
          ],
        },
      };
    }

    case CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST: {
      const { sectionNamesOrderedByLastUsed } = state.sentences;
      return {
        ...state,
        sentences: {
          ...state.sentences,
          sectionNamesOrderedByLastUsed: [
            action.payload,
            ...sectionNamesOrderedByLastUsed.filter(
              (sectionName) => sectionName !== action.payload
            ),
          ],
        },
      };
    }
    case CopyAssistantActionType.REMOVE_ITEM_ORDER_SENTENCE_LIST: {
      const { sectionNamesOrderedByLastUsed } = state.sentences;
      return {
        ...state,
        sentences: {
          ...state.sentences,
          sectionNamesOrderedByLastUsed: [
            ...sectionNamesOrderedByLastUsed.filter(
              (sectionName) => sectionName !== action.payload
            ),
          ],
        },
      };
    }
  }
};

const inputDataStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT:
      return {
        ...state,
        inputData: { fetchNew: action.payload },
      };
  }
};

const fieldsetsStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.SET_FIELDSETS: {
      const { fieldsets, metadata } = action.payload;
      return {
        ...state,
        fieldsets: {
          isLoading: false,
          list: fieldsets,
          availableMetadata: metadata,
        },
      };
    }
    case CopyAssistantActionType.SET_FIELDSETS_LOADING:
      return {
        ...state,
        fieldsets: {
          ...state.fieldsets,
          isLoading: action.payload,
        },
      };
  }
};

const productSpecificOverwriteStateHandler = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  switch (action.type) {
    case CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE:
      return {
        ...state,
        productSpecificOverwrites: {
          ...state.productSpecificOverwrites,
          list: [
            action.payload,
            ...state.productSpecificOverwrites.list.filter(
              ({ prompt_id }) => prompt_id !== action.payload.prompt_id
            ),
          ],
        },
      };
    case CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES:
      return {
        ...state,
        productSpecificOverwrites: {
          ...state.productSpecificOverwrites,
          list: action.payload,
        },
      };
    case CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING:
      return {
        ...state,
        productSpecificOverwrites: {
          ...state.productSpecificOverwrites,
          isLoading: action.payload,
        },
      };
  }
};

export const copyAssistantReducer = (
  state: CopyAssistantContextState,
  action: CopyAssistantActions
): CopyAssistantContextState => {
  if (modules.PROMPT.includes(action.type))
    return promptStateHandler(state, action);

  if (modules.GROUP.includes(action.type))
    return groupStateHandler(state, action);

  if (modules.SENTENCES.includes(action.type))
    return sentenceStateHandler(state, action);

  if (modules.INPUT_DATA.includes(action.type))
    return inputDataStateHandler(state, action);

  if (modules.FIELDSETS.includes(action.type))
    return fieldsetsStateHandler(state, action);

  if (modules.PRODUCT_SPECIFIC_OVERWRITE.includes(action.type))
    return productSpecificOverwriteStateHandler(state, action);
};
