import React, { useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button, Dimmer, Icon, Loader, Popup } from "semantic-ui-react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { PromptSelectionRulesUpdateOrCreate } from "./PromptSelectionRulesUpdateOrCreate";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import { Prompt, PromptGroup } from "../../products/copy-assistant/types";
import {
  getPrompts,
  getPromptGroups,
  getPromptSelectionRules,
  reorderPromptSelectionRules,
} from "../../api/gptApi";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import { Text } from "../../components/Text";
import { PromptSelectionRulesList } from "./PromptSelectionRulesList";
import { ExplainRules, ExplainSettings } from "./PromptSelectionRulesHelpTexts";
import { PromptSelectionRule, popupDelay } from "./types";
import { promptSelectionRulesTestSelectors } from "./testUtils/testSelectors";
import ManageGrid from "../../components/manage/ManageGrid";

const {
  container: containerTestId,
  createButton: createButtonTestId,
  dimmer: dimmerTestId,
} = promptSelectionRulesTestSelectors.promptSectionRules;

const {
  rules: { popupSelector: rulesPopupSelector },
  settings,
} = promptSelectionRulesTestSelectors.popups;

export const PromptSelectionRules: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [rules, setRules] = useState<PromptSelectionRule[]>([]);
  const [selectedRule, setSelectedRule] = useState<PromptSelectionRule>();
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [promptGroups, setPromptGroups] = useState<PromptGroup[]>([]);

  const selectedPrompt = useMemo(() => {
    if (!selectedRule || !prompts.length) {
      return;
    }
    return prompts.find((prompt) => prompt.id === selectedRule.prompt_id);
  }, [selectedRule, prompts]);

  const selectedPromptGroup = useMemo(() => {
    if (!selectedRule || !prompts.length) {
      return;
    }
    return promptGroups.find(
      (group) => group.id === selectedRule.prompt_group_id
    );
  }, [selectedRule, promptGroups]);

  const [isLoading, setIsLoading] = useState(false);
  const [reorderListData, setReorderListData] = useState<{
    id: number;
    order: number;
  }>();

  useEffect(() => {
    if (token) {
      (async (): Promise<void> => {
        setIsLoading(true);
        const { prompts } = await getPrompts(token);
        setPrompts(prompts.filter((prompt) => !!prompt.customer_id));
        const promptGroups = await getPromptGroups(token);
        setPromptGroups(promptGroups);
        setRules(await getPromptSelectionRules(token));
        setIsLoading(false);
      })();
    }
  }, [token]);

  const refreshSelectionRules = async (unselect: boolean): Promise<void> => {
    setIsLoading(true);
    setRules(await getPromptSelectionRules(token));
    setIsLoading(false);
    if (unselect) {
      setShowCreateNew(false);
      setSelectedRule(undefined);
    }
  };

  useEffect(() => {
    if (token && reorderListData) {
      (async (): Promise<void> => {
        await reorderPromptSelectionRules(
          token,
          reorderListData.id,
          reorderListData.order
        )
          .then(() => {
            setReorderListData(undefined);
          })
          .catch(async () => {
            store.dispatch(
              setDjangoToastOpen({
                appearance: NotificationAppearance.ERROR,
                content: "Something went wrong trying to reorder the rules",
              })
            );
            await refreshSelectionRules(false);
          });
      })();
    }
  }, [token, reorderListData]);

  const onDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    const dragIndex = source.index;
    const destinationIndex = destination.index;

    if (dragIndex === destinationIndex) {
      return;
    }

    const copyRules = cloneDeep(rules);

    const draggedItem = copyRules[dragIndex];
    copyRules.splice(dragIndex, 1);
    copyRules.splice(destinationIndex, 0, draggedItem);
    setReorderListData({
      id: parseInt(draggableId),
      order: destinationIndex + 1,
    });
    setRules(copyRules);
  };

  const onCreateNew = (): void => {
    setShowCreateNew(true);
    setSelectedRule(undefined);
  };

  if (isLoading) {
    return (
      <Dimmer active data-testid={dimmerTestId}>
        <Loader />
      </Dimmer>
    );
  }
  return (
    <div className="manage-content">
      <ManageGrid testId={containerTestId}>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                wide
                mouseEnterDelay={popupDelay}
                trigger={
                  <span data-testid={rulesPopupSelector}>
                    <Text as="h4" compact inline>
                      Rules
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              >
                <ExplainRules />
              </Popup>
            }
            right={
              <Popup
                size="tiny"
                content="Create new rule"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Button
                    data-testid={createButtonTestId}
                    size="tiny"
                    color="red"
                    icon="plus"
                    compact
                    basic
                    onClick={(): void => onCreateNew()}
                  />
                }
              />
            }
          />
          <ManageGrid.Header.RightColumn>
            {(showCreateNew || selectedRule) && (
              <Popup
                wide
                mouseEnterDelay={popupDelay}
                trigger={
                  <span data-testid={settings.popupSelector}>
                    <Text as="h4" compact inline>
                      Settings
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              >
                <ExplainSettings />
              </Popup>
            )}
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <DragDropContext onDragEnd={onDragEnd}>
              <div>
                <PromptSelectionRulesList
                  rules={rules}
                  selectedRule={selectedRule}
                  setSelectedRule={setSelectedRule}
                  prompts={prompts}
                  promptGroups={promptGroups}
                />
              </div>
            </DragDropContext>
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {showCreateNew || selectedRule ? (
              <PromptSelectionRulesUpdateOrCreate
                name={selectedRule?.name}
                conditions={selectedRule?.conditions}
                selectedPrompt={selectedPrompt}
                selectedPromptGroup={selectedPromptGroup}
                prompts={prompts}
                promptGroups={promptGroups}
                conditionOperator={selectedRule?.condition_operator}
                id={selectedRule?.id}
                refreshSelectionRules={refreshSelectionRules}
                index={rules?.length + 1}
              />
            ) : (
              <ManageGrid.Body.EmptyForm>
                {!!rules?.length && (
                  <>
                    <Text as="h2" compact>
                      Select a Rule from the list
                    </Text>
                    <Text as="h3" color="grey" compact>
                      Or
                    </Text>
                  </>
                )}
                <Button
                  content="Create new Rule"
                  icon="plus"
                  color="red"
                  size="large"
                  onClick={(): void => onCreateNew()}
                />
              </ManageGrid.Body.EmptyForm>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
    </div>
  );
};
