import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { useGetCustomerQuery } from "../api/customerApi";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../api/djangoToastSlice";
import {
  MachineTranslateTaskQueue,
  DoneTranslateFileResponse,
} from "../api/machineTranslationApi";
import { FileDrop } from "../components/FileDrop";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import {
  LanguageCode,
  SUICustomerLanguageDropdown,
  renderLanguageFromCode,
} from "../customers/customerlanguages";
import { ProofreadRequestModal } from "./ProofreadRequestModal";
import { validateFileExtension } from "../utils/files";
import { Spinner } from "../utils/Spinner";
import { RootState, store } from "../utils/store";
import { uuidv4 } from "../utils/uuidUtils";

function downloadFile(fileName: string, fileUrl: string): void {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = fileUrl;
  link.download = fileName; // suggests download (vs navigate) + file name for dialog; not very reliable
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// https://www.deepl.com/docs-api/documents
export const ALLOWED_EXTENSIONS = [
  "docx",
  "doc",
  "pptx",
  "xlsx",
  "pdf",
  "htm",
  "html",
  "txt",
  "xlf",
  "xliff",
  "srt",
];

export const MachineTranslateFile: React.FC = () => {
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  function renderLanguage(languageCode: string): string {
    return (
      renderLanguageFromCode(languageCode, customer?.languages ?? []) ?? ""
    );
  }

  const machineTranslateTaskQueue = useMemo(() => {
    if (token)
      return new MachineTranslateTaskQueue<DoneTranslateFileResponse>(
        token,
        3000
      );
  }, [token]);

  const [selectedFile, setSelectedFile] = useState<File>(null);

  const isSelectedFileInvalid = useMemo((): boolean => {
    if (isNull(selectedFile)) return false;
    return !validateFileExtension(selectedFile, ALLOWED_EXTENSIONS);
  }, [selectedFile]);

  const [pathToSourceFile, setPathToSourceFile] = useState<string>(null);

  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<string>(
    null
  );
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<
    string[]
  >([]);

  const [translateStatus, setTranslateStatus] = useState<{
    [language: string]: {
      loading: boolean;
      error: unknown;
      result: DoneTranslateFileResponse;
    };
  }>({});

  const [downloadAllLoading, setDownloadAllLoading] = useState<boolean>(false);

  const startTranslate = (): Promise<void> => {
    const uniqueFilename = `${uuidv4().split("-")[0]}-${selectedFile.name}`;
    return machineTranslateTaskQueue
      .translateFile(
        selectedFile,
        uniqueFilename,
        selectedSourceLanguage,
        selectedTargetLanguages
      )
      .then((pathToSourceFile) => {
        setPathToSourceFile(pathToSourceFile);
        // Wait for all languages to be translated.
        const translateStatusObj: {
          [language: string]: {
            loading: boolean;
            error: unknown;
            result: DoneTranslateFileResponse;
          };
        } = {};
        selectedTargetLanguages.forEach((language) => {
          translateStatusObj[language] = {
            loading: true,
            error: null,
            result: null,
          };
        });
        setTranslateStatus(translateStatusObj);

        selectedTargetLanguages.forEach((language) => {
          machineTranslateTaskQueue
            .waitUntilTranslateLanguageIsDone(language)
            .then((result) => {
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: null,
                  result: result,
                },
              }));
            })
            .catch(() => {
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: "Translation failed",
                  result: null,
                },
              }));
            });
        });
      });
  };

  const allTranslateJobsDone = useMemo((): boolean => {
    return Object.values(translateStatus).every((status) => !status.loading);
  }, [translateStatus]);

  return (
    <>
      {Object.keys(translateStatus).length > 0 ? (
        // We have active translation tasks
        <>
          <h3>Translating {selectedFile?.name}</h3>
          <div
            data-testid="machine-translate-file-tasks"
            className="tw-my-8 tw-flex tw-w-full tw-flex-col tw-gap-4"
          >
            {Object.entries(translateStatus).map(([language, status]) => (
              <div
                key={language}
                className="tw-flex tw-items-center tw-border-b tw-pb-4"
              >
                <div className="tw-w-16">
                  {status.error ? (
                    <Icon name="warning" className="tw-text-red-600" />
                  ) : status.result ? (
                    <Icon name="check_circle" className="tw-text-green-600" />
                  ) : (
                    <Spinner size="small" align="left" />
                  )}
                </div>
                <div className="tw-w-64">{renderLanguage(language)}</div>
                <div className="tw-w-full">
                  {status.error ? (
                    <>
                      <Text compact color="black">
                        An error occurred while translating your file.
                      </Text>
                      <Text compact color="grey" size="small">
                        {status.error}
                      </Text>
                    </>
                  ) : status.result ? (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        variant="primary"
                        content="Download"
                        data-testid="machine-translate-file-download-button"
                        onClick={(): void => {
                          const origName =
                            selectedFile?.name ?? pathToSourceFile;
                          const parts = origName.split(".");
                          const ext = parts.pop();
                          const newName =
                            parts.join(".") + "-" + language + "." + ext;
                          downloadFile(newName, status.result.url);
                        }}
                        className="tw-me-2"
                      />
                      {pathToSourceFile && (
                        <ProofreadRequestModal
                          requestFileName={
                            status.result.original_target_file_name
                          }
                          requestPathToFile={status.result.target_file_path}
                          requestLanguage={language as LanguageCode}
                          originalFileName={selectedFile?.name}
                          originalPathToFile={pathToSourceFile}
                          originalLanguage={
                            selectedSourceLanguage as LanguageCode
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <Text compact color="grey">
                      Translating...
                    </Text>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="tw-flex tw-w-full tw-justify-end tw-gap-2">
            {allTranslateJobsDone && (
              <Button
                variant="primary"
                content="Download all"
                data-testid="machine-translate-file-download-all-button"
                loading={downloadAllLoading}
                onClick={async (): Promise<void> => {
                  const targetFilePaths = Object.values(translateStatus)
                    .filter((ts) => isNil(ts.error))
                    .map((ts) => ts.result.target_file_path);

                  setDownloadAllLoading(true);
                  try {
                    const zipUrl = await machineTranslateTaskQueue.downloadFiles(
                      pathToSourceFile,
                      targetFilePaths
                    );
                    const origName = selectedFile?.name ?? pathToSourceFile;
                    const parts = origName.split(".");
                    parts.pop(); // lose extension
                    const newName = parts.join(".") + "-translations.zip";
                    downloadFile(newName, zipUrl);
                  } catch {
                    store.dispatch(
                      setDjangoToastOpen({
                        content: "Error downloading files",
                        appearance: NotificationAppearance.ERROR,
                        additionalContent: null,
                      })
                    );
                  } finally {
                    setDownloadAllLoading(false);
                  }
                }}
              />
            )}
            <Button
              variant="primary-alt"
              content={allTranslateJobsDone ? "Done" : "Cancel"}
              data-testid="machine-translate-file-done-button"
              onClick={(): void => {
                if (!allTranslateJobsDone) {
                  machineTranslateTaskQueue.cancelAll();
                }
                setTranslateStatus({});
              }}
            />
          </div>
        </>
      ) : (
        // File/language selection
        <div className="tw-grid tw-grid-cols-[1fr_auto_1fr] tw-gap-8">
          <div>
            <SUICustomerLanguageDropdown
              clearable
              data-testid="machine-translate-file-source-dropdown"
              fluid
              onChange={(_, { value }): void => {
                setSelectedSourceLanguage(value as string);
              }}
              placeholder="Detect language"
              selection
              value={selectedSourceLanguage}
            />
            {!selectedSourceLanguage && (
              <div className="tw-mx-4 tw-my-2 tw-text-sm tw-text-gray-400">
                Glossaries are not used when source language is automatically
                detected.
              </div>
            )}
          </div>
          <div className="tw-pt-2">
            <Icon name="arrow_forward" />
          </div>
          <div>
            <SUICustomerLanguageDropdown
              clearable
              data-testid="machine-translate-file-target-dropdown"
              error={!isNull(selectedFile) && isEmpty(selectedTargetLanguages)}
              fluid
              multiple
              onChange={(_, { value }): void => {
                setSelectedTargetLanguages(value as string[]);
              }}
              placeholder="Select language(s)"
              selection
              value={selectedTargetLanguages}
            />
          </div>
          <div className="tw-col-span-3">
            <FileDrop
              selectedFile={selectedFile}
              isInvalid={isSelectedFileInvalid}
              onChange={(file): void => {
                setSelectedFile(file);
              }}
              contentUnselected={
                <>
                  <Text>
                    Supported file types: PDF (.pdf), Word (.docx), Excel
                    (.xlsx), Powerpoint (.pptx)
                  </Text>
                  <Text color="grey">
                    Also supported: HTML (.html/.htm), Plain text (.txt), XLIFF
                    (.xliff/.xlf), SubRip Subtitle Document (.srt)
                  </Text>
                </>
              }
              contentSelected={
                <Button
                  variant="primary"
                  content="Translate"
                  data-testid="machine-translate-file-translate-button"
                  disabled={
                    isEmpty(selectedTargetLanguages) || isSelectedFileInvalid
                  }
                  onClick={startTranslate}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
