import React, { useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Label,
  Popup,
  Radio,
  Segment,
} from "semantic-ui-react";

import { TemplateLabel } from "../../../customers/Customer";
import { popupDelay } from "../../../customers/gpt/types";
import { SelectIllustrations } from "../illustrations";
import {
  IllustrationKeys,
  NON_TEMPLATE_TAGS_TEMPLATE_LABEL_ID,
  NON_TEMPLATE_TAGS_TEMPLATE_LABEL_NAME,
  SectionFormMode,
} from "../types";
import { Text } from "../../../components/Text";

type Props = {
  loading: boolean;
  sectionName: string;
  setSectionName: (name: string) => void;
  availableTemplateLabels?: TemplateLabel[];
  sectionTemplateLabels: number[];
  setSectionTemplateLabels: (label_ids: number[]) => void;
  illustration: IllustrationKeys;
  setIllustration: (illustration: IllustrationKeys) => void;
  handleActionButtonClick: () => void;
  handleDeleteSection?: () => void;
  handleClose?: () => void;
  formMode: SectionFormMode;
};
export const EditSectionForm: React.FC<Props> = ({
  loading,
  sectionName,
  setSectionName,
  availableTemplateLabels,
  sectionTemplateLabels,
  setSectionTemplateLabels,
  illustration,
  setIllustration,
  handleActionButtonClick,
  handleDeleteSection,
  handleClose,
  formMode,
}) => {
  const [sectionType, setSectionType] = useState(
    sectionTemplateLabels?.length > 0 ? "rule-based" : "gpt"
  );

  const templateLabelOptions = useMemo(() => {
    const opts = availableTemplateLabels.map((label: TemplateLabel) => {
      return {
        key: label.id,
        text: label.name,
        value: label.id,
      };
    });
    opts.push({
      key: NON_TEMPLATE_TAGS_TEMPLATE_LABEL_ID,
      text: NON_TEMPLATE_TAGS_TEMPLATE_LABEL_NAME,
      value: NON_TEMPLATE_TAGS_TEMPLATE_LABEL_ID,
    });
    return opts;
  }, [availableTemplateLabels]);

  return (
    <Form as="div" loading={loading} data-testid="edit-section-form">
      <Form.Group>
        <Form.Field width={14}>
          <label htmlFor="edit-section-name-input">Name</label>
          <Input
            autoFocus
            data-testid="edit-section-name-input"
            id="edit-section-name-input"
            illustration="Section name"
            onChange={(_, { value }): void => {
              setSectionName(value);
            }}
            value={sectionName}
          />
        </Form.Field>
        {handleClose && (
          <Form.Field width={2}>
            <Popup
              content="Close edit section"
              mouseEnterDelay={popupDelay}
              size="tiny"
              trigger={
                <Button
                  data-testid="edit-section-close-button"
                  icon="close"
                  floated="right"
                  size="mini"
                  compact
                  basic
                  onClick={(): void => {
                    handleClose();
                  }}
                />
              }
            />
          </Form.Field>
        )}
      </Form.Group>
      <Form.Field>
        <label>Section type</label>
      </Form.Field>
      <Form.Field>
        <Radio
          checked={sectionType === "gpt"}
          data-testid="edit-section-type-gpt-radio"
          label="AI generated text"
          name="type"
          onChange={(_: any, { value }: { value: any }): void => {
            setSectionTemplateLabels([]);
            setSectionType(value.toString());
          }}
          value="gpt"
        />
      </Form.Field>
      <Form.Field>
        <Radio
          checked={sectionType === "rule-based"}
          data-testid="edit-section-type-rule-based-radio"
          label="Rule-based"
          name="type"
          onChange={(_: any, { value }: { value: any }): void => {
            setSectionType(value.toString());
          }}
          value="rule-based"
        />
      </Form.Field>
      {sectionType === "rule-based" && (
        <Segment>
          <Form.Field>
            <label>Select Template settings</label>
            <Text color="grey" size="small">
              Select which Templates to include by specifiying their settings.
              Use the setting{" "}
              <Label
                as="span"
                className="color-primary"
                size="tiny"
                content={NON_TEMPLATE_TAGS_TEMPLATE_LABEL_NAME}
              />{" "}
              to use tags from the Edit tab. The order in which the settings
              appear here determines their order in the output text.
            </Text>
            <Dropdown
              closeOnBlur
              closeOnChange
              data-testid="edit-section-template-labels-dropdown"
              fluid
              multiple
              options={templateLabelOptions}
              onChange={(e, { value }): void => {
                setSectionTemplateLabels(value as number[]);
              }}
              placeholder="Type to search"
              renderLabel={(
                { text },
                _,
                defaultLabelProps
              ): React.ReactElement => (
                <Label
                  className="font-size-small color-primary"
                  content={text}
                  {...defaultLabelProps}
                />
              )}
              search
              selection
              selectOnBlur={false}
              value={sectionTemplateLabels ?? []}
            />
          </Form.Field>
        </Segment>
      )}
      <Form.Field>
        <label htmlFor="edit-section-illustration-input">Illustration</label>
        <SelectIllustrations
          selected={illustration}
          setSelected={setIllustration}
        />
      </Form.Field>
      <Form.Field>
        <Button
          data-testid="edit-section-action-button"
          content={formMode === SectionFormMode.CREATE ? "Create" : "Update"}
          disabled={!sectionName}
          color="red"
          size="small"
          compact
          onClick={handleActionButtonClick}
        />
        {formMode === SectionFormMode.UPDATE && handleDeleteSection && (
          <Button
            data-testid="edit-section-delete-button"
            content="Delete"
            floated="right"
            basic
            size="small"
            compact
            onClick={handleDeleteSection}
          />
        )}
      </Form.Field>
    </Form>
  );
};
