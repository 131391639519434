import React, { useState } from "react";
import { Form, TextArea, Button, Message } from "semantic-ui-react";

import { GridVertical, GridHorizontal } from "./RequestWordModal";

type Props = {
  englishTranslation: string;
  onEnglishTranslationInputChange: (value: string) => void;
  suggestedEnglishText: string;
};

export const RequestWordModalAutomaticEnglishTranslationSuggestion: React.FC<Props> = ({
  englishTranslation,
  onEnglishTranslationInputChange,
  suggestedEnglishText,
}) => {
  const [value, setValue] = useState(englishTranslation);

  const oneLineTranslation = suggestedEnglishText.length < 100;

  return (
    <GridVertical>
      <Form>
        <TextArea
          rows={oneLineTranslation ? 1 : 3}
          className={oneLineTranslation ? "skip-default-styling" : null}
          data-testid={"requestwordmodal-english-translation"}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setValue(event.target.value);
            onEnglishTranslationInputChange(event.target.value);
          }}
          placeholder="English translation"
          value={value}
        />
      </Form>
      <div data-testid={"requestwordmodal-automatic-translation-suggestion"}>
        {value === suggestedEnglishText ? (
          <Message info>
            Translation was automatic and needs to be checked by user.
          </Message>
        ) : (
          <GridHorizontal>
            <p>
              Suggested machine translation: <b>{suggestedEnglishText}</b>
            </p>
            <Button
              basic
              compact
              content="Use suggestion"
              data-testid={"requestwordmodal-button-use-english-suggestion"}
              onClick={(): void => {
                setValue(suggestedEnglishText);
                onEnglishTranslationInputChange(suggestedEnglishText);
              }}
              size="small"
              type="button"
            />
          </GridHorizontal>
        )}
      </div>
    </GridVertical>
  );
};
