import React from "react";
import { Checkbox, Table } from "semantic-ui-react";
import {
  MatrixCellsUpdateType,
  MatrixRowType,
} from "./CustomerChannelLanguageMatrix";
import { Language } from "./customerlanguages";

type Props = {
  currentLanguage: Language;
  channelLanguageMatrix: MatrixRowType[];
  disabled: boolean;
  updateChannelLanguageCells?: ({
    channel_ids,
    checked,
    language_codes,
  }: MatrixCellsUpdateType) => void;
};

export const CustomerChannelLanguageMatrixTableRow: React.FC<Props> = ({
  currentLanguage,
  channelLanguageMatrix,
  disabled,
  updateChannelLanguageCells,
}) => {
  const languageCode = currentLanguage.code;
  const currentChannelDetails = channelLanguageMatrix.find(
    (channelLanguage) => channelLanguage.language_code === currentLanguage.code
  );
  return (
    <>
      {currentChannelDetails.channel_details.map((channelDetail) => {
        const { channel_id } = channelDetail;
        return (
          <Table.Cell
            collapsing
            textAlign="center"
            key={`${languageCode}_${channel_id}`}
          >
            <Checkbox
              data-language-code={languageCode}
              data-channel-id={channel_id}
              data-testid={"body-cell"}
              checked={channelDetail.selected}
              disabled={disabled}
              onChange={(e, { checked }): void => {
                updateChannelLanguageCells({
                  channel_ids: [channel_id],
                  checked,
                  language_codes: [languageCode],
                });
              }}
            />
          </Table.Cell>
        );
      })}
    </>
  );
};
