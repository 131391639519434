import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, List } from "semantic-ui-react";

import { selectionBucketAction } from "../../api/action";
import { getPipelines } from "../../api/pipelineApi";
import { useGetProductQuery } from "../../api/productDetailSlice";
import { Icon } from "../../components/tailwind";
import { ProductId, ProductStatus } from "../product";
import {
  SelectionAction,
  generateBucketName,
} from "../product-list/ProductList";
import { Pipeline } from "../../products/manage/pipelines/types";
import { Spinner } from "../../utils/Spinner";
import { RootState } from "../../utils/store";

type Props = {
  productId: ProductId;
};

export const RunPipeline: React.FC<Props> = ({ productId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);

  const token = useSelector((state: RootState) => state.auth.token);

  const openModal = (): void => {
    setLoading(true);
    setOpen(true);
    getPipelines(token)
      .then(setPipelines)
      .finally((): void => {
        setLoading(false);
      });
  };

  const closeModal = (): void => {
    setOpen(false);
  };

  const runPipeline = async (pipeline: Pipeline): Promise<void> => {
    const bucket = generateBucketName();
    await selectionBucketAction(token, bucket, SelectionAction.ADD, [
      productId,
    ]);

    const data: { [key: string]: string } = {
      bucket,
      return_url: window.location.href,
      pipeline: pipeline.id.toString(),
    };
    const url = "../../products/bulk-actions/perform/RunPipelineAction";

    const form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = url;
    for (const key in data) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
    form.submit();
  };

  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );

  if (
    !product ||
    isProductLoading ||
    product?.status == ProductStatus.IMPORTING
  ) {
    return <></>;
  }

  return (
    <>
      <a
        className="item"
        onClick={openModal}
        data-testid="action-run-pipeline-open-button"
      >
        <Icon
          name="schema"
          className="tw-text-[1em] tw-w-[1.18em] tw-me-[0.75em]"
        />
        <span>Run pipeline</span>
      </a>

      <Modal
        data-testid={"action-run-pipeline-modal"}
        open={open}
        size={"small"}
      >
        <Modal.Header>
          <h5>Run pipeline on document</h5>
        </Modal.Header>
        <Modal.Content>
          {loading ? (
            <Spinner size="medium" />
          ) : pipelines.length > 0 ? (
            <>
              <p>Choose a pipeline below to run it on this document.</p>
              <List selection divided relaxed="very" className="!tw-mx-2">
                {pipelines.map((pipeline) => (
                  <List.Item
                    key={pipeline.id}
                    onClick={(): void => {
                      runPipeline(pipeline);
                    }}
                  >
                    <List.Content>
                      <List.Header as="a">{pipeline.name}</List.Header>
                      <List.Description as="a">
                        {pipeline.description}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </>
          ) : (
            <p>No pipelines found</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-testid={"action-run-pipeline-modal-close-button"}
            onClick={closeModal}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
