import React, { Dispatch, SetStateAction } from "react";
import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";
import { Text } from "../../../components/Text";

type Props = {
  caption: string;
  dropdownOptions: DropdownItemProps[];
  label: string;
  placeholder: string;
  testId: string;
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  clearable?: boolean;
  linkUrl?: string;
  linkText?: string;
};

export const PromptSelectField: React.FC<Props> = ({
  caption,
  dropdownOptions,
  label,
  placeholder,
  testId,
  value,
  setValue,
  clearable,
  linkUrl,
  linkText,
}) => (
  <Form.Field>
    <label {...(!!linkUrl && { style: { display: "flex" } })}>
      {label} {!clearable && "*"}
      {linkUrl && (
        <a href={linkUrl} data-subpage="" style={{ marginLeft: "auto" }}>
          {linkText || `Manage ${label}`}
        </a>
      )}
    </label>
    <Text color="grey" className="descriptive-helper-text">
      {caption}
    </Text>
    <Dropdown
      data-testid={testId}
      fluid
      clearable={clearable}
      selection
      placeholder={placeholder}
      options={dropdownOptions}
      value={value}
      onChange={(e, data): void => setValue(data.value)}
    />
  </Form.Field>
);
