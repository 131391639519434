import React, { Dispatch, SetStateAction } from "react";
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { CopyAssistantLangStringTemplateLabels } from "../types";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
type Props = {
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
  dropdownOptions: DropdownItemProps[];
  templateLabels: CopyAssistantLangStringTemplateLabels[];
  activeTemplateLabelIds: number[];
  handleClickLangStringTemplateLabel: (id: number) => void;
  loading: boolean;
};

export const PromptLangStringSelectorField: React.FC<Props> = ({
  selected,
  setSelected,
  dropdownOptions,
  templateLabels,
  activeTemplateLabelIds,
  handleClickLangStringTemplateLabel,
  loading,
}) => (
  <>
    <Form.Field>
      <label>Select a Template (Optional)</label>
      <Text color="grey" className="descriptive-helper-text">
        Attach generated texts to products using this template. If not
        specified, the default template for this customer account will be used.
      </Text>
      <Dropdown
        search
        clearable
        data-testid="copy-assistant-template-form-lang-string-selector-dropdown"
        options={dropdownOptions}
        value={selected}
        onChange={(
          event: React.SyntheticEvent<HTMLElement>,
          data: DropdownProps
        ): void => setSelected(data.value as number)}
        selection
        compact
        placeholder="Select a Template"
        selectOnBlur={false}
        loading={loading}
      />
    </Form.Field>
    <Form.Field>
      {selected && (
        <>
          <label>Template Settings</label>
          <Text color="grey" className="descriptive-helper-text">
            You can specify here which settings will be set on the template when
            it is added to a product.
          </Text>
          <>
            {templateLabels.length ? (
              templateLabels.map((label) => (
                <TemplateLabelChip
                  key={label.label_id}
                  content={label.name}
                  description={label.description}
                  isSelected={activeTemplateLabelIds.includes(label.label_id)}
                  labelProps={{
                    size: "tiny",
                    as: "a",
                    onClick: (): void =>
                      handleClickLangStringTemplateLabel(label.label_id),
                  }}
                />
              ))
            ) : (
              <Text className="light-warning">
                No Template Settings for selected Template
              </Text>
            )}
          </>
        </>
      )}
    </Form.Field>
  </>
);
