import { AxiosResponse } from "axios";
import api from "../api/api";
import { NotificationAppearance, setDjangoToastOpen } from "./djangoToastSlice";
import { ProductListParams } from "../products/product-list/ProductListURLConfig";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";
import { store } from "../utils/store";

type ParseQueryBodyType = {
  query: string;
  url_params?: ProductListParams;
};

type ParseQueryResultType = {
  error_message?: string;
  message?: string;
  redirect_url: string;
  tree?: string;
};

type updateActionSpecParams = {
  token: string;
  customer_slug: string;
  query: string;
  actionSpecId: string;
};

export const customQueryApi = createApi({
  reducerPath: "customQueryApi",
  tagTypes: ["CustomQuery"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/product",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    parseQuery: build.mutation<ParseQueryResultType, ParseQueryBodyType>({
      query: (body) => ({
        url: `/parse_query/`,
        method: "POST",
        body,
      }),
      transformResponse: (responseData: ParseQueryResultType) => {
        // errors by documentation should be handled by queryFn https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-queryfn
        // but here we want to still keep content in textarea
        if (responseData?.error_message) {
          store.dispatch(
            setDjangoToastOpen({
              appearance: NotificationAppearance.ERROR,
              content: responseData.error_message,
            })
          );
        }
        return responseData;
      },
    }),
  }),
});

export async function updateActionSpec({
  token,
  customer_slug,
  query,
  actionSpecId,
}: updateActionSpecParams): Promise<AxiosResponse> {
  return api.post(
    `/d/${customer_slug}/products/query`,
    new URLSearchParams({ query: query, action_spec_id: actionSpecId }),
    {
      headers: { token, "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
}

export const { useParseQueryMutation } = customQueryApi;
