import React, { useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { ProductListAction } from "../ProductList";
import { DropdownHideDisplayText } from "./ProductListSelectionBanner";
import { SemanticPopupWithReactRef } from "../../../ui/semantic-ui-overwrites";
import { PageSizeStatus } from "../ProductListTypes";

const options = [
  { text: "1", value: PageSizeStatus.One },
  { text: "5", value: PageSizeStatus.Five },
  { text: "10", value: PageSizeStatus.Ten },
  { text: "25", value: PageSizeStatus.TwentyFive },
  { text: "50", value: PageSizeStatus.Fifty },
];

export type Props = {
  pageSize: string;
  onProductListAction: (action: ProductListAction, value: string) => void;
};

export const RowSelectorMenuItem: React.FC<Props> = ({
  pageSize,
  onProductListAction,
}) => {
  const [disablePopup, setDisablePopup] = useState(false);
  return (
    <SemanticPopupWithReactRef
      popupProps={{
        content: "Select rows",
        position: "top right",
        disabled: disablePopup,
      }}
    >
      <DropdownHideDisplayText
        data-testid="row-selector-menu-item"
        onOpen={(): void => setDisablePopup(true)}
        onClose={(): void => setDisablePopup(false)}
        floating
        item
        upward={false}
        icon={
          <>
            <Icon name="list alternate outline" size="large" />
            <Icon name="dropdown" fitted />
          </>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Header content="Select rows" />
          {options.map(({ text, value }) => (
            <Dropdown.Item
              text={text}
              value={value}
              key={value}
              selected={pageSize === value}
              onClick={(): void =>
                onProductListAction(
                  ProductListAction.ChangePageSize,
                  value as string
                )
              }
            />
          ))}
        </Dropdown.Menu>
      </DropdownHideDisplayText>
    </SemanticPopupWithReactRef>
  );
};
