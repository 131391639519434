import React, { useEffect, useRef, useState } from "react";
import { TextBlock, updateTextBlock } from "../../api/textBlockApi";

import { preventOverflowOnTextarea } from "../copy-assistant/utils";
import { RootState } from "../../utils/store";
import { useSelector } from "react-redux";
import { OnActionCallback } from "../../producttext/ProductTextViewer";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { getDocumentSection } from "../../api/documentStructureApi";
import { Section } from "../../planner/document-structure/types";
import { Modal, Button } from "../../components/tailwind";

export type EditTextBlockInlineProps = {
  onClose?: () => void;
  textBlock: TextBlock;
  loading: boolean;
  onAction?: OnActionCallback;
  sectionSlug?: string;
  sectionId?: number;
};

export const EditTextBlockInline: React.FC<EditTextBlockInlineProps> = ({
  onClose,
  textBlock,
  loading,
  onAction,
  sectionSlug,
  sectionId,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const textareaRef = useRef<HTMLTextAreaElement>();
  const [text, setText] = useState<string>("");

  const [documentSection, setDocumentSection] = useState<Section>();

  useEffect(() => {
    if (textBlock?.text) {
      setText(textBlock.text);
    }
  }, [textBlock]);

  useEffect(() => {
    preventOverflowOnTextarea(textareaRef.current);
  }, [textareaRef, text]);

  useEffect(() => {
    let mounted = true;
    if (!sectionId || !token) return;
    (async (): Promise<void> => {
      const section = await getDocumentSection(token, sectionId);
      if (mounted) setDocumentSection(section);
    })();
    return (): void => {
      mounted = false;
    };
  }, [sectionId, token]);

  const handleUpdateTextBlock = async (): Promise<void> => {
    await updateTextBlock(token, { text }, textBlock.id).finally(() => {
      onAction?.(ProductTextAction.REGENERATE);
    });
  };

  const deSlugify = (slug: string): string => {
    const deSlugified = slug.replace("-", " ").replace("_", " ");
    return deSlugified[0].toUpperCase() + deSlugified.slice(1);
  };
  const dialogRef = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (!dialogRef.current) return;
    dialogRef.current.showModal();
  }, [dialogRef]);

  return (
    <Modal dialogRef={dialogRef}>
      <Modal.Header>
        {(!!sectionSlug || !!documentSection) && (
          <h5
            data-testid="edit-textblock-inline-section-name"
            className="tw-m-0"
          >
            <span className="tw-text-gray-500">Section:</span>{" "}
            {documentSection?.name || deSlugify(sectionSlug)}
          </h5>
        )}
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="tw-px-2 tw-pt-1"
          ref={textareaRef}
          data-testid="edit-textblock-inline-textarea"
          onChange={({ target }): void => {
            setText(target.value);
          }}
          value={text}
        />
      </Modal.Body>
      <Modal.Actions>
        <Button
          loading={loading}
          onClick={(): void => {
            dialogRef.current?.close();
            onClose();
          }}
          data-testid="cancel"
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={(): void => {
            dialogRef.current?.close();
            handleUpdateTextBlock();
          }}
          variant="primary"
          data-testid="save"
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
