import React from "react";
import { ProductText } from "../../producttext/ProductText";
import { Product } from "../product";
import { Channel } from "./ChannelLanguageSelectorMatrix";
import { ProductTextsChannelRow } from "../ProductTextsChannelRow";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";

type Props = {
  onFlagToggle?: (modalOpen: boolean) => void;
  product: Product;
  productTexts: ProductText[] | null;
};

export const ProductListTextStatusesCell: React.FC<Props> = ({
  onFlagToggle,
  product,
  productTexts,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div data-testid={"product-list-text-statuses-cell"}>
      {customer.getVisibleChannels().map((channel: Channel) => (
        <ProductTextsChannelRow
          channel={channel}
          key={channel.id}
          onFlagToggle={onFlagToggle}
          productTexts={productTexts}
          product={product}
        />
      ))}
    </div>
  );
};
