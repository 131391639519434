import React, { useContext, useMemo, useState } from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { Prompt } from "../types";
import { findLanguage, fixLongName } from "../utils";
import { FittedDropdownAsMenuItem } from "./ToolbarTop";
import { toolbarTopTestIds } from "../testUtils/testIdsSelectors";
import { StatelessSelectPromptDropdownMenu } from "./StatelessSelectPromptDropdownMenu";
import { Flag } from "../../../customers/customerlanguages";

export const SelectPromptDropdown: React.FC = () => {
  const { selectPrompt } = toolbarTopTestIds;

  const {
    prompts: {
      list: prompts,
      customerOwned: customerOwnedPrompts,
      systemOwned: systemOwnedPrompts,
      selected: selectedPrompt,
      isLoading: fetchingPrompts,
      availableTags: { customerOwned, systemOwned },
    },
  } = useContext(CopyAssistantContext);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const [noPromptsShowing, setNoPromptsShowing] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [filterTags, setFilterTags] = useState<string[]>([]);

  const filterPromptList = (list: Prompt[]): DropdownItemProps[] => {
    let searchResult = list.filter(
      ({ name }) => name.toLowerCase().search(search.toLowerCase().trim()) != -1
    );

    filterTags.forEach((tag) => {
      searchResult = searchResult.filter((prompt) => prompt.tags.includes(tag));
    });
    return searchResult.map((prompt) => ({
      key: prompt.id,
      value: prompt.id,
      text: (
        <>
          <Flag content={findLanguage(prompt, customer)?.flag} />{" "}
          {fixLongName(prompt.name)}
        </>
      ),
    }));
  };

  const promptOptions = useMemo(() => {
    if (isLoading) return { customerPrompts: [], systemPrompts: [] };
    const customerPrompts = filterPromptList(customerOwnedPrompts);
    const systemPrompts = filterPromptList(systemOwnedPrompts);
    if (!customerPrompts.length && !systemPrompts.length) {
      setNoPromptsShowing(true);
    } else {
      setNoPromptsShowing(false);
    }

    return {
      customerPrompts,
      systemPrompts,
    };
  }, [prompts, search, isLoading, filterTags]);

  const tagMapping = useMemo(() => {
    return prompts.map((item) => ({
      tags: item.tags || [],
      id: item.id,
    }));
  }, [prompts]);

  const filteredAvailableTags = useMemo(
    () =>
      [...new Set([...customerOwned, ...systemOwned])].filter(
        (tag) => tag !== "no tags"
      ),
    [customerOwned, systemOwned]
  );

  const handleSelectPrompt = (id: number): void => {
    const prompt = prompts.find((prompt) => prompt.id === id);
    if (prompt) {
      setSearch("");
      setOpen(false);
      dispatch({
        payload: prompt,
        type: CopyAssistantActionType.SELECT_PROMPT,
      });
    }
  };

  const hideButton = filteredAvailableTags.length <= 0;
  return (
    <FittedDropdownAsMenuItem
      data-testid={selectPrompt.dropdown}
      floating
      upward={false}
      loading={fetchingPrompts}
      disabled={fetchingPrompts}
      value={selectedPrompt?.id}
      text={
        selectedPrompt
          ? `${findLanguage(selectedPrompt, customer)?.flag} ${fixLongName(
              selectedPrompt.name
            )}`
          : "Select prompt"
      }
      onClick={(): void => setOpen(!open)}
      onBlur={(): void => setOpen(false)}
      open={open}
    >
      <StatelessSelectPromptDropdownMenu
        selectedPrompt={selectedPrompt}
        handleSelectPrompt={handleSelectPrompt}
        tagMapping={tagMapping}
        promptOptions={promptOptions}
        search={search}
        setSearch={setSearch}
        hideAdvanceFilterButton={hideButton}
        noPromptsShowing={noPromptsShowing}
        availableTags={filteredAvailableTags}
        selectedTags={filterTags}
        setSelectedTags={setFilterTags}
        testIds={{
          filterTags: selectPrompt.filterTags,
        }}
        open={open}
      />
    </FittedDropdownAsMenuItem>
  );
};
