import React from "react";
import { ProductText } from "../producttext/ProductText";
import styled from "styled-components";
import { Language } from "../customers/customerlanguages";
import { ProductTextStatusIcon } from "./ProductTextStatusIcon";
import { ProductTextsLanguageFlag } from "./ProductTextsLanguageFlag";

const StyledDivFFTextStatusIcons = styled.div`
  & {
    position: relative;
    margin: 5px;
    padding-right: 10px;
    transform: scale(1.5);
  }
`;

type Props = {
  onFlagToggle?: (open: boolean) => void;
  language: Language;
  markedForPublish: boolean;
  productText: ProductText;
  disabledLanguageFlag?: boolean;
};

export const ProductTextStatusAndFlag: React.FC<Props> = ({
  onFlagToggle,
  language,
  markedForPublish,
  productText,
  disabledLanguageFlag,
}) => (
  <StyledDivFFTextStatusIcons
    className={"flag"}
    key={language.code}
    data-testid={"product-text-status-and-flag"}
  >
    <ProductTextsLanguageFlag
      language={language}
      markedForPublish={markedForPublish}
      onToggle={onFlagToggle}
      disabled={disabledLanguageFlag}
    />
    <ProductTextStatusIcon
      markedForPublish={markedForPublish}
      productText={productText}
    />
  </StyledDivFFTextStatusIcons>
);
