import React, { useEffect, useState } from "react";
import {
  Icon,
  Segment,
  Divider,
  Ref,
  Loader,
  Popup,
  Dimmer,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import {
  DocumentStructure as DocumentStructureType,
  MoveItemDirection,
  IllustrationKeys,
  Section,
} from "../types";
import { SectionViewer } from "./SectionViewer";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { SectionFormModal } from "./SectionFormModal";
import { RootState, store } from "../../../utils/store";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import { popupDelay } from "../../../customers/gpt/types";
import { useGetDocumentSections } from "./customhooks";
import { updateDocumentSection } from "../../../api/documentStructureApi";
import { useSelector } from "react-redux";
import { ConfirmModal } from "./ConfirmModal";

type Props = {
  selectedDocumentStructure: DocumentStructureType | null;
  allDocumentStructures: DocumentStructureType[];
  collectSections: (sections: Section[]) => void;
  managePromptLink: string;
};

export const DocumentStructure: React.FC<Props> = ({
  selectedDocumentStructure,
  allDocumentStructures,
  collectSections,
  managePromptLink,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const {
    data: allSections,
    reFetch: refetchSections,
    isFetching: isFetchingSections,
  } = useGetDocumentSections();

  const [activeSections, setActiveSections] = useState<Section[]>([]);

  const [defaultModalOpen, setDefaultModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const [confirmActionModalProps, setConfirmActionModalProps] = useState<{
    header: string;
    content: React.ReactElement;
    confirmAction: () => void;
    setOpen: () => void;
  }>({
    header: "",
    content: <></>,
    confirmAction: (): void => console.error("Confirm action not set"),
    setOpen: (): void => console.error("Close action not set"),
  });

  useEffect(() => {
    if (!isFetchingSections) {
      const activeSectionCopy = [...activeSections];
      const deletedSections = activeSectionCopy.filter(
        (section) =>
          !allSections.some(
            (updatedSection) => updatedSection.id === section.id
          )
      );
      const filteredOutDeletedSections = activeSectionCopy.filter(
        (section) => !deletedSections.includes(section)
      );

      const updatedSections = filteredOutDeletedSections.map((section) => {
        const updatedSection = allSections.find(
          (updatedSection) => updatedSection.id === section.id
        );
        return updatedSection || section;
      });
      setActiveSections(updatedSections);
    }
  }, [isFetchingSections]);

  const refreshSections = async (): Promise<void> => {
    setLoading(true);
    await refetchSections().finally(() => setLoading(false));
  };

  useEffect(() => {
    if (activeSections.length) return;
    if (selectedDocumentStructure) {
      setActiveSections(selectedDocumentStructure.sections);
    } else {
      const defaultSelectedSections: Section[] = [];
      const headlineSection = allSections.find(
        (section) => section.illustration_type === "heading"
      );
      if (headlineSection) defaultSelectedSections.push(headlineSection);
      const bodySection = allSections.find(
        (section) => section.illustration_type === "body"
      );
      if (bodySection) defaultSelectedSections.push(bodySection);
      const bulletSection = allSections.find(
        (section) => section.illustration_type === "bullets"
      );
      if (bulletSection) defaultSelectedSections.push(bulletSection);
      setActiveSections(defaultSelectedSections);
    }
  }, [selectedDocumentStructure, allSections]);

  useEffect(() => {
    collectSections(activeSections);
  }, [activeSections]);

  const isLastItem = (index: number): boolean => {
    return index === activeSections.length - 1;
  };

  const moveSection = (
    index: number,
    direction: MoveItemDirection,
    destinationIndex?: number
  ): void => {
    const destination =
      destinationIndex || (direction === MoveItemDirection.UP ? -1 : 1);
    const sectionsCopy = [...activeSections];
    const section = sectionsCopy[index];
    sectionsCopy.splice(index, 1);
    sectionsCopy.splice(index + destination, 0, section);
    setActiveSections(sectionsCopy);
  };

  const onDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    let direction: MoveItemDirection = MoveItemDirection.UP;
    if (destination.index > source.index) {
      direction = MoveItemDirection.DOWN;
    }
    moveSection(
      result.source.index,
      direction,
      destination.index - source.index
    );
  };

  const deleteSection = (index: number): void => {
    const sectionsCopy = [...activeSections];
    sectionsCopy.splice(index, 1);
    setActiveSections(sectionsCopy);
    store.dispatch(
      setDjangoToastOpen({
        content: "Section Removed",
        appearance: NotificationAppearance.WARNING,
        additionalContent: "It will still be available in the Section list.",
      })
    );
  };

  const handleConfirmDeleteSection = (index: number): void => {
    const selectedSection = activeSections[index];
    setConfirmActionModalProps({
      header: `Confirm remove Section ${selectedSection?.name} from Structure ${
        selectedDocumentStructure?.name || ""
      }`,
      content: (
        <>
          <Text compact>
            Are you sure you want to remove Section {selectedSection?.name} from
            Structure {selectedDocumentStructure?.name}?
          </Text>
          <Text compact color="grey">
            This will make parts of product texts associated with this Section
            and Structure hidden.
          </Text>
        </>
      ),
      confirmAction: (): void => deleteSection(index),
      setOpen: (): void => setConfirmActionModalOpen(false),
    });
    setConfirmActionModalOpen(true);
  };

  const updateSection = async (
    index: number,
    name: string,
    template_label_ids: number[],
    illustrationType: IllustrationKeys,
    closeEditPopup: () => void,
    confirmed?: boolean
  ): Promise<void> => {
    if (index < 0 || index >= activeSections.length) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Could not update section",
          appearance: NotificationAppearance.ERROR,
          additionalContent: `Index out of range: ${index}`,
        })
      );
      return new Promise((_, reject) => reject(`Index out of range: ${index}`));
    }
    const update = activeSections[index];
    if (!confirmed) {
      if (!update?.template_label_ids && template_label_ids?.length > 0) {
        handleConfirmChangeSectionBetweenRuleAndGPT(
          "Rule-based",
          update.name,
          index,
          name,
          template_label_ids,
          illustrationType,
          closeEditPopup
        );
        return;
      }
      if (update?.template_label_ids && !template_label_ids.length) {
        handleConfirmChangeSectionBetweenRuleAndGPT(
          "AI generated text",
          update.name,
          index,
          name,
          template_label_ids,
          illustrationType,
          closeEditPopup
        );
        return;
      }
    }
    update.name = name;
    update.template_label_ids =
      template_label_ids?.length > 0 ? template_label_ids : null;
    update.illustration_type = illustrationType;

    setLoading(true);
    await updateDocumentSection(token, update)
      .then(async ({ name, illustration_type }) => {
        const sectionsCopy = [...activeSections];
        sectionsCopy[index].name = name;
        sectionsCopy[index].template_label_ids = template_label_ids;
        sectionsCopy[index].illustration_type = illustration_type;
        setActiveSections(sectionsCopy);
        store.dispatch(
          setDjangoToastOpen({
            content: "Section Updated",
            appearance: NotificationAppearance.SUCCESS,
            additionalContent: `${name} has been updated`,
          })
        );
        await refetchSections().finally(() => setLoading(false));
      })
      .catch(() => {
        setLoading(false);
      });

    closeEditPopup();
  };

  const handleConfirmChangeSectionBetweenRuleAndGPT = (
    type: "Rule-based" | "AI generated text",
    sectionName: string,
    index: number,
    name: string,
    template_label_ids: number[],
    illustrationType: IllustrationKeys,
    closeEditPopup: () => void
  ): void => {
    setConfirmActionModalProps({
      header: `Confirm Change Section ${sectionName} to ${type}`,
      content: (
        <>
          <Text compact>
            Are you sure you want to change Section {sectionName} to {type}?
          </Text>
          <Text compact color="grey">
            {type === "Rule-based"
              ? "Prompts using this Section will be redirected to the default Section."
              : "Templates using this Section will be ignored."}
          </Text>
        </>
      ),
      confirmAction: (): Promise<void> =>
        updateSection(
          index,
          name,
          template_label_ids,
          illustrationType,
          closeEditPopup,
          true
        ),
      setOpen: () => {
        closeEditPopup();
        setConfirmActionModalOpen(false);
      },
    });
    setConfirmActionModalOpen(true);
  };

  const addSection = (section: Section): void => {
    setActiveSections([...activeSections, section]);
    store.dispatch(
      setDjangoToastOpen({
        content: "Section Added",
        appearance: NotificationAppearance.SUCCESS,
        additionalContent: `${section.name} has been added to the Structure.`,
      })
    );
  };

  const sectionConnectedToStructuresInfo = (section: Section): string => {
    const otherStructures = allDocumentStructures.filter(
      ({ id }) => id !== selectedDocumentStructure?.id
    );

    const connectedStructures: string[] = [];
    otherStructures
      .filter(({ sections }) => {
        return !!sections.find(({ id }) => id === section.id);
      })
      .forEach((structure) => {
        connectedStructures.push(structure.name);
      });
    if (connectedStructures.length)
      return `Connected to: ${connectedStructures.join(", ")}`;
  };
  return (
    <Dimmer.Dimmable dimmed={loading || isFetchingSections}>
      <Popup
        mouseEnterDelay={popupDelay}
        content="You can drag and drop the sections to change the order."
        size="tiny"
        wide="very"
        trigger={
          <span>
            <Text as="h4" compact inline>
              Sections
            </Text>{" "}
            <Icon name="question circle" />
          </span>
        }
      />
      <Text compact color="grey" size="small" inline>
        A Document contains Sections (headline, body, bulletlist etc). Each
        Section is connected to{" "}
        <a href={managePromptLink} data-subpage>
          Prompts...
        </a>{" "}
        that generate the text for each Section.
      </Text>
      {activeSections.length === 0 ? (
        <Segment
          padded
          placeholder
          textAlign="center"
          data-testid="document-structure-no-sections"
        >
          {!loading && <Text color="grey">No sections yet</Text>}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              gap: "10px",
            }}
          >
            <SectionFormModal
              key="section-form-modal"
              excludeSections={activeSections}
              addSectionToStructure={addSection}
              allSections={allSections}
              loading={loading}
              setLoading={setLoading}
              refreshSections={refreshSections}
              defaultOpen={defaultModalOpen}
              setDefaultOpen={(open): void => setDefaultModalOpen(open)}
            />
          </div>
        </Segment>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable-1"
            renderClone={(provided, snapshot, rubric): any => {
              const section = activeSections[rubric.source.index];
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Segment
                    compact
                    color="blue"
                    inverted
                    style={{
                      margin: 0,
                      padding: "8px",
                      cursor: "grab",
                    }}
                  >
                    <Text compact size="small">
                      {section?.name}
                    </Text>
                  </Segment>
                </div>
              );
            }}
          >
            {(provided): React.ReactElement => (
              <Ref innerRef={provided.innerRef}>
                <Segment
                  padded
                  style={{
                    minHeight: "18rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  data-testid="document-structure-sections"
                >
                  {activeSections.map((section, index) => (
                    <Draggable
                      draggableId={`${section.id}`}
                      index={index}
                      key={section.id}
                    >
                      {(provided): React.ReactElement => (
                        <Ref innerRef={provided.innerRef}>
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SectionViewer
                              section={section}
                              sectionConnectedToStructuresInfo={sectionConnectedToStructuresInfo(
                                section
                              )}
                              isLastItem={isLastItem(index)}
                              index={index}
                              moveSection={moveSection}
                              deleteSection={handleConfirmDeleteSection}
                              updateSection={updateSection}
                              disableDeleteButton={
                                selectedDocumentStructure?.locked &&
                                section?.locked
                              }
                            />
                          </div>
                        </Ref>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div style={{ marginTop: "auto" }}>
                    <Divider />
                    <SectionFormModal
                      excludeSections={activeSections}
                      addSectionToStructure={addSection}
                      allSections={allSections}
                      loading={loading}
                      setLoading={setLoading}
                      refreshSections={refreshSections}
                      defaultOpen={defaultModalOpen}
                      setDefaultOpen={(open): void => setDefaultModalOpen(open)}
                    />
                  </div>
                </Segment>
              </Ref>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Dimmer
        active={loading || isFetchingSections}
        inverted
        data-testid="document-structure-sections-loader"
      >
        <Loader />
      </Dimmer>
      <ConfirmModal
        open={confirmActionModalOpen}
        {...confirmActionModalProps}
      />
    </Dimmer.Dimmable>
  );
};
