import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Popup,
} from "semantic-ui-react";

import { Prompt, PromptGroup } from "../../products/copy-assistant/types";

import { RootState } from "../../utils/store";
import {
  createPromptSelectionRules,
  deletePromptSelectionRules,
  updatePromptSelectionRules,
} from "../../api/gptApi";
import { useSelector } from "react-redux";
import { PromptSelectionRulesCondition } from "./PromptSelectionRulesCondition";
import { generateId } from "../../utils/uuidUtils";
import {
  ExplainOperator,
  ExplainConditions,
} from "./PromptSelectionRulesHelpTexts";
import {
  ConditionOperator,
  PromptSelectionCondition,
  PromptSelectionConditionWithId,
  PromptSelectionStringMatchType,
  popupDelay,
} from "./types";
import { promptSelectionRulesTestSelectors } from "./testUtils/testSelectors";

const {
  form: formTestId,
  inputs: {
    name: nameTestId,
    or: orTestId,
    and: andTestId,
    promptSelector: promptSelectorTestId,
    promptGroupSelector: promptGroupSelectorTestId,
  },
  buttons: {
    addCondition: addConditionTestId,
    removeRule: removeRuleTestId,
    saveRule: saveRuleTestId,
  },
} = promptSelectionRulesTestSelectors.promptSelectionRulesUpdateOrCreate;
const {
  conditions: { popupSelector: conditionsPopupSelector },
  operator,
} = promptSelectionRulesTestSelectors.popups;
export const generateDefaultPromptSelectionRuleName = (index: number): string =>
  `My rule No. ${index}`;

type Props = {
  name?: string;
  conditions?: PromptSelectionCondition[];
  selectedPrompt?: Prompt;
  selectedPromptGroup?: PromptGroup;
  conditionOperator: ConditionOperator;
  id?: number;
  index?: number;
  prompts: Prompt[];
  promptGroups: PromptGroup[];
  refreshSelectionRules: (unselect: boolean) => Promise<void>;
};

export const PromptSelectionRulesUpdateOrCreate: React.FC<Props> = ({
  name,
  conditions,
  selectedPrompt,
  selectedPromptGroup,
  conditionOperator,
  id,
  index,
  prompts,
  promptGroups,
  refreshSelectionRules,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [conditionsState, setConditions] = useState<
    PromptSelectionConditionWithId[]
  >([]);
  const [conditionOperatorState, setConditionOperator] = useState(
    ConditionOperator.OR
  );

  const [nameState, setName] = useState("");
  const [selectedPromptId, setSelectedPromptId] = useState<number>();
  const [selectedPromptGroupId, setSelectedPromptGroupId] = useState<number>();

  useEffect(() => {
    const defaultCondition = {
      key: "",
      value: "",
      string_match_type: PromptSelectionStringMatchType.EQUALS,
      id: generateId(),
    };
    setConditions(
      conditions?.map((condition) => ({ ...condition, id: generateId() })) || [
        defaultCondition,
      ]
    );
  }, [conditions]);

  useEffect(() => {
    setConditionOperator(conditionOperator || ConditionOperator.OR);
  }, [conditionOperator]);

  useEffect(() => {
    setName(name || generateDefaultPromptSelectionRuleName(index));
  }, [name]);

  useEffect(() => {
    setSelectedPromptId(selectedPrompt?.id ?? null);
  }, [selectedPrompt]);

  useEffect(() => {
    setSelectedPromptGroupId(selectedPromptGroup?.id ?? null);
  }, [selectedPromptGroup]);

  const promptsDropdownOptions = useMemo(
    () =>
      prompts.map((prompt) => ({
        key: prompt.id,
        value: prompt.id,
        text: prompt.name,
      })),
    prompts
  );

  const promptGroupsDropdownOptions = useMemo(
    () =>
      promptGroups.map((group) => ({
        key: group.id,
        value: group.id,
        text: group.name,
      })),
    promptGroups
  );
  const onAddCondition = (): void => {
    setConditions([
      ...conditionsState,
      {
        key: "",
        value: "",
        string_match_type: PromptSelectionStringMatchType.EQUALS,
        id: generateId(),
      },
    ]);
  };

  const onUpdateCondition = (
    key: keyof PromptSelectionCondition,
    value: string | PromptSelectionStringMatchType,
    id: string
  ): void => {
    const copyConditions = [...conditionsState];
    const index = copyConditions.findIndex((condition) => condition.id === id);
    if (key === "string_match_type") {
      copyConditions[index][key] = value as PromptSelectionStringMatchType;
      if (value === PromptSelectionStringMatchType.EXISTS) {
        copyConditions[index].value = "";
      }
    } else {
      copyConditions[index][key] = value;
    }
    setConditions(copyConditions);
  };

  const onRemoveCondition = (id: string): void => {
    const copyConditions = [...conditionsState];
    const index = copyConditions.findIndex((condition) => condition.id === id);
    copyConditions.splice(index, 1);
    setConditions(copyConditions);
  };

  const onSave = async (): Promise<void> => {
    const conditionNoIds = conditionsState.map((condition) => {
      delete condition.id;
      return condition;
    });
    if (!id) {
      await createPromptSelectionRules(
        token,
        nameState,
        selectedPromptId,
        selectedPromptGroupId,
        conditionNoIds,
        conditionOperatorState
      );
    } else {
      await updatePromptSelectionRules(
        token,
        nameState,
        selectedPromptId,
        selectedPromptGroupId,
        conditionNoIds,
        conditionOperatorState,
        id
      );
    }

    await refreshSelectionRules(true);
  };

  const onCancel = async (): Promise<void> => {
    await refreshSelectionRules(true);
  };

  const onRemove = async (): Promise<void> => {
    await deletePromptSelectionRules(token, id);
    await refreshSelectionRules(true);
  };

  const disableAddCondition = (): boolean => {
    return conditionsState.some(({ key, value, string_match_type }) =>
      string_match_type === PromptSelectionStringMatchType.EXISTS
        ? !key
        : !key || !value
    );
  };

  const disableSave = (): boolean => {
    return (
      disableAddCondition() ||
      !nameState ||
      (!selectedPromptId && !selectedPromptGroupId)
    );
  };
  return (
    <>
      <Form as="div" size="tiny" data-testid={formTestId}>
        <Form.Field>
          <Popup
            mouseEnterDelay={popupDelay}
            content="Name your rule for easier identification"
            size="small"
            wide
            trigger={
              <label>
                Name <Icon name="question circle" />
              </label>
            }
          />
          <Input
            data-testid={nameTestId}
            placeholder="Name"
            value={nameState || ""}
            onChange={(e, { value }): void => setName(value)}
          />
        </Form.Field>
        <Divider />

        <Form.Field>
          <Popup
            mouseEnterDelay={popupDelay}
            flowing
            hoverable
            wide="very"
            trigger={
              <label data-testid={conditionsPopupSelector}>
                Conditions <Icon name="question circle" />
              </label>
            }
          >
            <ExplainConditions />
          </Popup>
          {conditionsState?.map(
            ({ key, value, string_match_type, id }, index) => {
              return (
                <PromptSelectionRulesCondition
                  index={index}
                  key={id}
                  objectKey={key}
                  id={id}
                  value={value}
                  stringMatchType={string_match_type}
                  onUpdateCondition={onUpdateCondition}
                  onRemoveCondition={onRemoveCondition}
                  conditionOperator={conditionOperatorState}
                />
              );
            }
          )}
          <Divider hidden />
          <Popup
            mouseEnterDelay={popupDelay}
            wide
            content={
              disableAddCondition()
                ? "Make sure all conditions are populated before adding a new one"
                : "Add a new condition"
            }
            trigger={
              <span>
                <Button
                  color="red"
                  size="tiny"
                  icon="add"
                  basic
                  disabled={disableAddCondition()}
                  data-testid={addConditionTestId}
                  onClick={(): void => onAddCondition()}
                />
              </span>
            }
          />
          <Divider hidden />
          <Form.Field disabled={conditionsState?.length < 2}>
            <Form.Field>
              <Popup
                mouseEnterDelay={popupDelay}
                size="small"
                wide
                trigger={
                  <label data-testid={operator.popupSelector}>
                    Operator <Icon name="question circle" />
                  </label>
                }
              >
                <ExplainOperator />
              </Popup>
            </Form.Field>
            <Form.Field>
              <Checkbox
                data-testid={orTestId}
                radio
                checked={conditionOperatorState == ConditionOperator.OR}
                label={"Or"}
                onClick={(): void => {
                  setConditionOperator(ConditionOperator.OR);
                }}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                data-testid={andTestId}
                radio
                checked={conditionOperatorState == ConditionOperator.AND}
                label={"And"}
                onClick={(): void => {
                  setConditionOperator(ConditionOperator.AND);
                }}
              />
            </Form.Field>
          </Form.Field>
          <Divider />
        </Form.Field>
        <Form.Group>
          <Form.Field inline>
            <label>Use prompt:</label>
            <Dropdown
              placeholder="Select a prompt to use"
              data-testid={promptSelectorTestId}
              selection
              options={promptsDropdownOptions}
              value={selectedPromptId}
              onChange={(e, { value }): void => {
                setSelectedPromptId(value as number);
                setSelectedPromptGroupId(null);
              }}
            />
          </Form.Field>
          <Form.Field inline>
            <label>or use prompt group:</label>
            <Dropdown
              placeholder="Select a prompt group to use"
              data-testid={promptGroupSelectorTestId}
              selection
              options={promptGroupsDropdownOptions}
              value={selectedPromptGroupId}
              onChange={(e, { value }): void => {
                setSelectedPromptId(null);
                setSelectedPromptGroupId(value as number);
              }}
            />
          </Form.Field>
        </Form.Group>
        <Divider />
        <Form.Field>
          {!!id && (
            <Button
              data-testid={removeRuleTestId}
              color="red"
              floated="right"
              basic
              size="tiny"
              onClick={(): Promise<void> => onRemove()}
            >
              Remove
            </Button>
          )}
          <Button
            data-testid={saveRuleTestId}
            color="red"
            size="tiny"
            onClick={(): Promise<void> => onSave()}
            disabled={disableSave()}
          >
            {id ? "Save" : "Create"}
          </Button>
          <Button
            color="red"
            basic
            size="tiny"
            onClick={(): Promise<void> => onCancel()}
          >
            Cancel
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};
