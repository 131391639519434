import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
  Ref,
  useContext,
} from "react";
import { Spinner } from "../utils/Spinner";
import {
  Form,
  TextArea,
  Icon,
  Grid,
  Popup,
  DropdownItemProps,
  Dropdown,
  Transition,
  Divider,
  Ref as SUIRef,
} from "semantic-ui-react";
import { useGetCustomerQuery } from "../api/customerApi";
import { Flag, Language, LanguageCode } from "../customers/customerlanguages";
import styled from "styled-components";
import { generateId, uuidv4 } from "../utils/uuidUtils";
import { getMachineTranslatedText } from "../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../api/djangoToastSlice";
import { StatelessTranslateButton } from "./StatelessTranslateButton";
import { FlexDivTwoItems } from "../products/copy-assistant/Selected/SelectedPromptGroupPromptItem";
import { preventOverflowOnTextarea } from "../products/copy-assistant/utils";
import { Text } from "../components/Text";
import { AIRewriteTextButton } from "../components/AIRewriteTextButton";
import { ProductId } from "../products/product";
import {
  createTextBlock,
  CreateTextBlock,
  setActiveTextBlock,
} from "../api/textBlockApi";
import { Sentence } from "../products/copy-assistant/types";
import { reloadPreview } from "../legacy/t.product-detail";
import { CopyAssistantActionType } from "../products/copy-assistant/CopyAssistantContext/types";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../products/copy-assistant/CopyAssistantContext/CopyAssistantContext";
import { TextareaWithRewriteContextMenu } from "../components/TextareaWithRewriteContextMenu";

export enum ShowAllOrPreview {
  ALL = "all",
  PREVIEW = "preview",
  NONE = "",
}

const InlineLabelAndTextarea = styled(Form.Field)`
  display: grid;
  &&.visible.transition {
    display: grid !important;
  }
  grid-template-columns: 200px auto;
  align-items: center;
  padding-right: 0 !important;
`;

export type LangStringObject = { [key in LanguageCode]?: string };
type Props = {
  onDataChanged?: (
    container: typeof LangString,
    data: LangStringObject
  ) => void;
  initialData: LangStringObject;
  expanded?: ShowAllOrPreview;
  isMinified?: boolean;
  displayLanguage?: LanguageCode;
  shouldAutoTranslate?: boolean;
  mtConfigGroupId?: number;
  onDoneTranslating?: () => void;
  streamedPreviewString?: string;
  disableAllButtons?: boolean;
  isInsideCopyAssistantContext?: boolean;
  productId?: ProductId;
  sectionId?: number;
  sectionName?: string;
};

type InternalProps = {
  previewLang: Language;
  uuidForPreviewLang: string;
  langStringObject: LangStringObject;
  handleInputChange: (value: string, languageCode: LanguageCode) => void;
  handlePopulateMissingTranslations: (
    sourceLanguageCode: LanguageCode,
    configGroupId?: number
  ) => Promise<void>;
  translatePopupProps: (language: Language) => any;
  translateButtonProps: (languageCode: LanguageCode) => any;
  previewTextareaRef: Ref<HTMLTextAreaElement>;
  countWords: (text?: string) => number;
  countChars: (text?: string) => number;
  showAllOrPreview: ShowAllOrPreview;
  setShowAllOrPreview: (v: ShowAllOrPreview) => void;
  optionalPreviewLang: LanguageCode;
  setOptionalPreviewLang: (value: LanguageCode) => void;
  optionalPreviewLangOptions: DropdownItemProps[];
  uuidForOptionalPreviewLang: string;
} & Props;

export const LangString: React.FC<Props> = ({
  onDataChanged,
  initialData = {},
  expanded = ShowAllOrPreview.NONE,
  isMinified,
  displayLanguage,
  shouldAutoTranslate,
  mtConfigGroupId,
  onDoneTranslating,
  streamedPreviewString,
  disableAllButtons,
  isInsideCopyAssistantContext,
  productId,
  sectionId,
  sectionName,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [langStringObject, setLangStringObject] = useState<LangStringObject>(
    {}
  );

  const [previewLang, setPreviewLang] = useState<Language>(undefined);
  const [optionalPreviewLang, setOptionalPreviewLang] = useState<LanguageCode>(
    null
  );
  const [showAllOrPreview, setShowAllOrPreview] = useState<ShowAllOrPreview>(
    expanded
  );
  const [translateIsLoading, setTranslateIsLoading] = useState(false);

  const previewTextareaRef = useRef<HTMLTextAreaElement>();
  const hasAutoTranslated = useRef(false);
  const uuidForPreviewLang = uuidv4();
  const uuidForOptionalPreviewLang = uuidv4();

  const translationWillOverwriteLanguages = useCallback(
    (languageCode: LanguageCode) => {
      const copyLangStringObj = { ...langStringObject };
      delete copyLangStringObj[languageCode];
      return Object.values(copyLangStringObj).filter((v) => !!v).length;
    },
    [langStringObject]
  );

  const countWords = (text: string = ""): number => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };

  const countChars = (text: string = ""): number => {
    return text.length;
  };

  const optionalPreviewLangOptions = useMemo((): DropdownItemProps[] => {
    const options: DropdownItemProps[] = [
      {
        key: "null",
        value: null,
        text: "Blank",
      },
    ];
    customer?.languages.forEach((language) => {
      if (language.code !== previewLang?.code) {
        options.push({
          key: language.code,
          value: language.code,
          text: (
            <>
              <Flag content={language.flag} /> {language.short_name}
            </>
          ),
        });
      }
    });
    return options;
  }, [customer, previewLang]);

  useEffect(() => {
    let mounted = true;
    if (customer) {
      const emptyLanguages: LangStringObject = {};
      customer.languages.forEach((language: Language) => {
        if (displayLanguage) {
          if (language.code === displayLanguage) {
            if (mounted) setPreviewLang(language);
          }
        } else if (language.code == customer.config.tag_input_language) {
          if (mounted) setPreviewLang(language);
        }
        emptyLanguages[language.code] = "";
      });
      if (mounted) setLangStringObject({ ...emptyLanguages, ...initialData });
    }
    return (): void => {
      mounted = false;
    };
  }, [customer]);

  const handleInputChange = (value: string, code: LanguageCode): void => {
    const newLangStringObj = { ...langStringObject };

    newLangStringObj[code] = value;
    setLangStringObject(newLangStringObj);
    onDataChanged?.(LangString, newLangStringObj);
  };

  const handlePopulateMissingTranslations = async (
    sourceLanguageCode: LanguageCode,
    configGroupId?: number
  ): Promise<void> => {
    setTranslateIsLoading(true);
    let successes = 0;
    let failures = 0;
    const promises: Promise<unknown>[] = [];
    const newTranslations: LangStringObject = {};

    const sourceText = langStringObject[sourceLanguageCode];
    if (!sourceText) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: "Found no Source text",
        })
      );
      setTranslateIsLoading(false);
      return;
    }

    Object.keys(langStringObject).forEach(
      (targetLanguageCode: LanguageCode) => {
        if (targetLanguageCode === sourceLanguageCode) return;
        promises.push(
          getMachineTranslatedText({
            sourceLanguage: sourceLanguageCode,
            targetLanguage: targetLanguageCode,
            text: sourceText,
            context: isMinified
              ? "copy-assistant-translate-generated-text"
              : "translate-template",
            token,
            configGroupId,
          })
            .then((text) => {
              successes += 1;
              newTranslations[targetLanguageCode] = text;
            })
            .catch(() => {
              failures += 1;
            })
        );
      }
    );
    await Promise.all(promises);

    if (failures && !successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: `Failed to translate ${failures} Language(s).`,
        })
      );
    } else if (failures && successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: `Translated ${successes} Language(s). Failed to translate ${failures} Language(s).`,
        })
      );
    } else if (successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.SUCCESS,
          content: `Translated ${successes} Language(s).`,
        })
      );
    }
    setTranslateIsLoading(false);
    const updatedLangStringObject = {
      ...langStringObject,
      ...newTranslations,
    };
    setLangStringObject(updatedLangStringObject);
    onDataChanged?.(LangString, updatedLangStringObject);
  };

  const translatePopupProps = (language: Language) =>
    ({
      content: translationWillOverwriteLanguages(language?.code)
        ? `Translate from ${
            language?.short_name
          } to all languages and overwrite ${translationWillOverwriteLanguages(
            language?.code
          )} existing texts`
        : `Translate from ${language?.short_name} to all missing languages`,
      wide: true,
      size: "small",
    } as const);

  const translateButtonProps = (languageCode: LanguageCode) =>
    ({
      content: "Translate",
      disabled: !langStringObject[languageCode] || disableAllButtons,
      loading: translateIsLoading,
      "data-testid": `lang-string-translate-${languageCode}`,
      variant: "primary-alt",
      compact: true,
    } as const);

  useEffect(() => {
    let mounted = true;
    if (!langStringObject[displayLanguage]) return;
    if (shouldAutoTranslate && !hasAutoTranslated.current) {
      hasAutoTranslated.current = true;
      handlePopulateMissingTranslations(
        displayLanguage,
        mtConfigGroupId
      ).finally(() => {
        if (mounted) onDoneTranslating?.();
      });
    }
    return (): void => {
      mounted = false;
    };
  }, [shouldAutoTranslate, langStringObject, displayLanguage]);

  useLayoutEffect(() => {
    preventOverflowOnTextarea(previewTextareaRef.current);
  }, [previewTextareaRef, langStringObject]);

  if (isLoading) {
    return <Spinner />;
  }
  const internalProps: InternalProps = {
    initialData,
    previewLang,
    uuidForPreviewLang,
    langStringObject,
    handleInputChange,
    handlePopulateMissingTranslations,
    translateButtonProps,
    translatePopupProps,
    mtConfigGroupId,
    streamedPreviewString,
    previewTextareaRef,
    countWords,
    countChars,
    disableAllButtons,
    showAllOrPreview,
    setShowAllOrPreview,
    optionalPreviewLang,
    setOptionalPreviewLang,
    optionalPreviewLangOptions,
    uuidForOptionalPreviewLang,
    productId,
    sectionId,
    sectionName,
  };

  if (isInsideCopyAssistantContext) {
    return <LangStringCopyAssistant {...internalProps} />;
  }

  if (isMinified) {
    return <MinifiedLangString {...internalProps} />;
  }

  return <DefaultLangString {...internalProps} />;
};

const DefaultLangString: React.FC<InternalProps> = (props) => {
  const { data: customer } = useGetCustomerQuery();
  const {
    previewLang,
    uuidForPreviewLang,
    langStringObject,
    handleInputChange,
    handlePopulateMissingTranslations,
    translateButtonProps,
    translatePopupProps,
    mtConfigGroupId,
    showAllOrPreview,
    setShowAllOrPreview,
    optionalPreviewLang,
    setOptionalPreviewLang,
    optionalPreviewLangOptions,
    uuidForOptionalPreviewLang,
  } = props;
  return (
    <div id="lang-string-selector-for-form-submit">
      {previewLang && (
        <Form className="no-padding" as="div">
          <InlineLabelAndTextarea>
            <Grid>
              <Grid.Row columns={16}>
                <label htmlFor={`${previewLang.code}-${uuidForPreviewLang}`}>
                  <Flag content={previewLang.flag} /> {previewLang.name}
                </label>
              </Grid.Row>
              <Grid.Row>
                <StatelessTranslateButton
                  type="button"
                  size="sm"
                  onTranslate={handlePopulateMissingTranslations}
                  languageCode={previewLang.code}
                  {...translateButtonProps(previewLang.code)}
                  popupProps={translatePopupProps(previewLang)}
                  initialMTConfigGroupId={mtConfigGroupId}
                />
              </Grid.Row>
            </Grid>
            <TextArea
              data-testid="lang-string-preview-input"
              id={`${previewLang.code}-${uuidForPreviewLang}`}
              onChange={(e, { value }): void =>
                handleInputChange(value as string, previewLang.code)
              }
              placeholder={previewLang.name}
              value={langStringObject[previewLang.code] || ""}
            />
          </InlineLabelAndTextarea>
          <Form.Field>
            <div>
              <Popup
                content="Show all Languages"
                trigger={
                  <Icon
                    name="list"
                    data-testid="all-languages"
                    link
                    color={
                      showAllOrPreview === ShowAllOrPreview.ALL
                        ? "blue"
                        : "black"
                    }
                    onClick={(): void => {
                      if (showAllOrPreview !== ShowAllOrPreview.ALL) {
                        setShowAllOrPreview(ShowAllOrPreview.ALL);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
              <Popup
                content="Preview one language"
                trigger={
                  <Icon
                    data-testid="additional-preview"
                    name={"eye"}
                    color={
                      showAllOrPreview === ShowAllOrPreview.PREVIEW
                        ? "blue"
                        : "black"
                    }
                    link
                    onClick={(): void => {
                      if (showAllOrPreview !== ShowAllOrPreview.PREVIEW) {
                        setShowAllOrPreview(ShowAllOrPreview.PREVIEW);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
            </div>
            <Transition
              visible={showAllOrPreview === ShowAllOrPreview.PREVIEW}
              animation="fade down"
              duration={200}
              unmountOnHide
            >
              <InlineLabelAndTextarea>
                <Grid>
                  <Grid.Row columns={16}>
                    <Dropdown
                      data-testid="lang-string-optional-preview-dropdown"
                      value={optionalPreviewLang}
                      onChange={(_, { value }): void =>
                        setOptionalPreviewLang(value as LanguageCode)
                      }
                      clearable
                      compact
                      placeholder="Select preview"
                      options={optionalPreviewLangOptions}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <StatelessTranslateButton
                      type="button"
                      size="sm"
                      onTranslate={handlePopulateMissingTranslations}
                      languageCode={optionalPreviewLang}
                      {...translateButtonProps(optionalPreviewLang)}
                      popupProps={translatePopupProps(
                        customer.languages.find(
                          ({ code }) => code === optionalPreviewLang
                        )
                      )}
                      initialMTConfigGroupId={mtConfigGroupId}
                    />
                  </Grid.Row>
                </Grid>

                <TextArea
                  disabled={!optionalPreviewLang}
                  data-testid={`lang-string-optional-preview-input-${optionalPreviewLang}`}
                  id={`${optionalPreviewLang}-${uuidForOptionalPreviewLang}`}
                  onChange={(e, { value }): void =>
                    handleInputChange(value as string, optionalPreviewLang)
                  }
                  placeholder={
                    customer.languages.find(
                      (language) => language.code === optionalPreviewLang
                    )?.name
                  }
                  value={langStringObject[optionalPreviewLang] || ""}
                />
              </InlineLabelAndTextarea>
            </Transition>
            {customer.languages.map((language: Language) => {
              if (language.code !== previewLang?.code) {
                const htmlId = `${language.code}-${uuidv4()}`;

                return (
                  <Transition
                    key={language.code}
                    visible={showAllOrPreview === ShowAllOrPreview.ALL}
                    animation="fade down"
                    duration={200}
                    unmountOnHide
                  >
                    <InlineLabelAndTextarea>
                      <Grid>
                        <Grid.Row columns={16}>
                          <label htmlFor={htmlId}>
                            <Flag content={language.flag} /> {language.name}
                          </label>
                        </Grid.Row>
                        <Grid.Row>
                          <StatelessTranslateButton
                            type="button"
                            size="sm"
                            onTranslate={handlePopulateMissingTranslations}
                            languageCode={language.code}
                            {...translateButtonProps(language.code)}
                            popupProps={translatePopupProps(language)}
                            initialMTConfigGroupId={mtConfigGroupId}
                          />
                        </Grid.Row>
                      </Grid>

                      <TextArea
                        data-testid={`form-input-${language.code}`}
                        id={htmlId}
                        onChange={(e, { value }): void =>
                          handleInputChange(value as string, language.code)
                        }
                        placeholder={language.name}
                        value={langStringObject[language.code] || ""}
                      />
                    </InlineLabelAndTextarea>
                  </Transition>
                );
              }
            })}
          </Form.Field>
        </Form>
      )}
    </div>
  );
};

const MinifiedLangString: React.FC<InternalProps> = (props) => {
  const { data: customer } = useGetCustomerQuery();
  const {
    previewLang,
    uuidForPreviewLang,
    langStringObject,
    handleInputChange,
    handlePopulateMissingTranslations,
    translateButtonProps,
    translatePopupProps,
    mtConfigGroupId,
    streamedPreviewString,
    previewTextareaRef,
    countWords,
    countChars,
    disableAllButtons,
    showAllOrPreview,
    setShowAllOrPreview,
    optionalPreviewLang,
    setOptionalPreviewLang,
    optionalPreviewLangOptions,
    uuidForOptionalPreviewLang,
  } = props;
  return (
    <div id="lang-string-selector-for-form-submit">
      {previewLang && (
        <Form className="no-padding" as="div" size="small">
          <Form.Field>
            <FlexDivTwoItems>
              <div className="tw-flex tw-items-center tw-gap-2">
                <label htmlFor={`${previewLang.code}-${uuidForPreviewLang}`}>
                  <Flag content={previewLang.flag} /> {previewLang.short_name}
                </label>

                <AIRewriteTextButton
                  input={langStringObject[previewLang.code] || ""}
                  languageCode={previewLang.code}
                  buttonProps={{ compact: true, variant: "primary-alt" }}
                  onGenerationCompleteCallback={(text): void =>
                    handleInputChange(text, previewLang.code)
                  }
                />
              </div>
              <StatelessTranslateButton
                size="sm"
                onTranslate={handlePopulateMissingTranslations}
                languageCode={previewLang.code}
                {...translateButtonProps(previewLang.code)}
                popupProps={translatePopupProps(previewLang)}
                initialMTConfigGroupId={mtConfigGroupId}
              />
            </FlexDivTwoItems>
            {streamedPreviewString ? (
              <Text
                color="grey"
                style={{
                  whiteSpace: "pre-wrap",
                  padding: "1rem",
                  minHeight: "100px",
                }}
              >
                {streamedPreviewString}
              </Text>
            ) : (
              <>
                <SUIRef innerRef={previewTextareaRef}>
                  <TextArea
                    data-testid="lang-string-preview-input"
                    id={`${previewLang.code}-${uuidForPreviewLang}`}
                    onChange={(e, { value }): void =>
                      handleInputChange(value as string, previewLang.code)
                    }
                    placeholder={previewLang.name}
                    value={langStringObject[previewLang.code] || ""}
                  />
                </SUIRef>

                <Text size="small" color="grey">
                  {countWords(langStringObject[previewLang.code])} Words
                  {" | "}
                  {countChars(langStringObject[previewLang.code])} Chars
                </Text>
              </>
            )}
          </Form.Field>

          <Form.Field>
            <div>
              <Popup
                content="Show all Languages"
                trigger={
                  <Icon
                    name="list"
                    data-testid="all-languages"
                    link={!disableAllButtons}
                    color={
                      showAllOrPreview === ShowAllOrPreview.ALL
                        ? "blue"
                        : "black"
                    }
                    onClick={(): void => {
                      if (disableAllButtons) return;
                      if (showAllOrPreview !== ShowAllOrPreview.ALL) {
                        setShowAllOrPreview(ShowAllOrPreview.ALL);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
              <Popup
                content="Preview one language"
                trigger={
                  <Icon
                    data-testid="additional-preview"
                    name="eye"
                    color={
                      showAllOrPreview === ShowAllOrPreview.PREVIEW
                        ? "blue"
                        : "black"
                    }
                    link={!disableAllButtons}
                    onClick={(): void => {
                      if (disableAllButtons) return;
                      if (showAllOrPreview !== ShowAllOrPreview.PREVIEW) {
                        setShowAllOrPreview(ShowAllOrPreview.PREVIEW);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
            </div>
            <Transition
              visible={showAllOrPreview === ShowAllOrPreview.PREVIEW}
              animation="fade down"
              duration={200}
              unmountOnHide
            >
              <>
                <Divider hidden />
                <Form.Field>
                  <FlexDivTwoItems>
                    <label>
                      <Dropdown
                        upward={false}
                        data-testid="lang-string-optional-preview-dropdown"
                        value={optionalPreviewLang}
                        onChange={(_, { value }): void =>
                          setOptionalPreviewLang(value as LanguageCode)
                        }
                        clearable
                        compact
                        placeholder="Select preview"
                        options={optionalPreviewLangOptions}
                      />
                    </label>

                    <StatelessTranslateButton
                      size="sm"
                      onTranslate={handlePopulateMissingTranslations}
                      languageCode={optionalPreviewLang}
                      {...translateButtonProps(optionalPreviewLang)}
                      popupProps={translatePopupProps(
                        customer.languages.find(
                          (language) => language.code === optionalPreviewLang
                        )
                      )}
                      initialMTConfigGroupId={mtConfigGroupId}
                    />
                  </FlexDivTwoItems>
                  <TextArea
                    disabled={!optionalPreviewLang}
                    data-testid={`lang-string-optional-preview-input-${optionalPreviewLang}`}
                    id={`${optionalPreviewLang}-${uuidForOptionalPreviewLang}`}
                    onChange={(e, { value }): void =>
                      handleInputChange(value as string, optionalPreviewLang)
                    }
                    placeholder={
                      customer.languages.find(
                        (language) => language.code === optionalPreviewLang
                      )?.name
                    }
                    value={langStringObject[optionalPreviewLang] || ""}
                  />

                  <Text size="small" color="grey">
                    {countWords(langStringObject[optionalPreviewLang])} Words
                    {" | "}
                    {countChars(langStringObject[optionalPreviewLang])} Chars
                  </Text>
                </Form.Field>
              </>
            </Transition>
            {showAllOrPreview === ShowAllOrPreview.ALL && <Divider hidden />}
            {customer.languages.map((language: Language) => {
              if (language.code !== previewLang?.code) {
                const htmlId = `${language.code}-${uuidv4()}`;

                return (
                  <Transition
                    key={language.code}
                    visible={showAllOrPreview === ShowAllOrPreview.ALL}
                    animation="fade down"
                    duration={200}
                    unmountOnHide
                  >
                    <Form.Field>
                      <FlexDivTwoItems>
                        <label htmlFor={htmlId}>
                          <Flag content={language.flag} /> {language.short_name}
                        </label>

                        <StatelessTranslateButton
                          size="sm"
                          onTranslate={handlePopulateMissingTranslations}
                          languageCode={language.code}
                          {...translateButtonProps(language.code)}
                          popupProps={translatePopupProps(language)}
                          initialMTConfigGroupId={mtConfigGroupId}
                        />
                      </FlexDivTwoItems>
                      <TextArea
                        data-testid="lang-string-preview-input"
                        id={htmlId}
                        onChange={(e, { value }): void =>
                          handleInputChange(value as string, language.code)
                        }
                        placeholder={language.name}
                        value={langStringObject[language.code] || ""}
                      />
                      <Text size="small" color="grey">
                        {countWords(langStringObject[language.code])} Words
                        {" | "}
                        {countChars(langStringObject[language.code])} Chars
                      </Text>
                    </Form.Field>
                  </Transition>
                );
              }
            })}
          </Form.Field>
        </Form>
      )}
    </div>
  );
};
const LangStringCopyAssistant: React.FC<InternalProps> = (props) => {
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    previewLang,
    uuidForPreviewLang,
    langStringObject,
    handleInputChange,
    handlePopulateMissingTranslations,
    translateButtonProps,
    translatePopupProps,
    mtConfigGroupId,
    streamedPreviewString,
    countWords,
    countChars,
    disableAllButtons,
    showAllOrPreview,
    setShowAllOrPreview,
    optionalPreviewLang,
    setOptionalPreviewLang,
    optionalPreviewLangOptions,
    uuidForOptionalPreviewLang,
    productId,
    sectionId,
    sectionName,
  } = props;
  const previewTextareaRef = useRef<HTMLTextAreaElement>();
  const {
    sentences: { list: sentences },
  } = useContext(CopyAssistantContext);

  const dispatch = useContext(CopyAssistantDispatchContext);

  useLayoutEffect(() => {
    preventOverflowOnTextarea(previewTextareaRef.current);
  }, [previewTextareaRef, langStringObject]);

  const handleAddTextBlock = async (
    text: string,
    languageCode: LanguageCode
  ): Promise<void> => {
    const newTextBlock: CreateTextBlock = {
      product: productId,
      section: sectionId,
      language: languageCode,
      text: text,
      active: true,
      prompt_id: null,
      translated_from: null,
    };

    const connectedChannels = sentences.find(
      (sentence) =>
        sentence?.sectionInfo?.documentSectionId === sectionId &&
        sentence?.sectionInfo?.active
    )?.sectionInfo?.connectedChannels;

    const createdTextblock = await createTextBlock(token, newTextBlock);
    const langStringValues: LangStringObject = {};
    customer.languages.forEach((language) => {
      if (language.code == createdTextblock.language) {
        langStringValues[language.code] = createdTextblock.text;
      } else {
        langStringValues[language.code] = "";
      }
    });
    const sentence: Sentence = {
      id: generateId(),
      value: langStringValues,
      language: languageCode,
      generationInfo: {
        promptId: undefined,
      },
      sentenceGroupId: undefined,
      sectionInfo: {
        documentSectionId: sectionId,
        documentSectionName: sectionName,
        textBlockId: createdTextblock.id,
        langStringValues: langStringValues,
        connectedChannels: connectedChannels,
        active: createdTextblock.active,
      },
    };
    dispatch({
      type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
      payload: sentence.sectionInfo.documentSectionName,
    });
    dispatch({ type: CopyAssistantActionType.ADD_SENTENCE, payload: sentence });
    await setActiveTextBlock(token, createdTextblock.id);
    dispatch({
      type: CopyAssistantActionType.SET_SECTION_ACTIVE,
      payload: { id: sentence.id },
    });
    reloadPreview();
  };

  return (
    <div id="lang-string-selector-for-form-submit">
      {previewLang && (
        <Form className="no-padding" as="div" size="small">
          <Form.Field>
            <FlexDivTwoItems>
              <div className="tw-flex tw-items-center tw-gap-2">
                <label htmlFor={`${previewLang.code}-${uuidForPreviewLang}`}>
                  <Flag content={previewLang.flag} /> {previewLang.short_name}
                </label>
                <div className="tw-flex tw-items-center tw-gap-2">
                  <AIRewriteTextButton
                    input={langStringObject[previewLang.code] || ""}
                    languageCode={previewLang.code}
                    buttonProps={{ compact: true, variant: "primary-alt" }}
                    onGenerationCompleteCallback={(text): void =>
                      handleInputChange(text, previewLang.code)
                    }
                  />
                  <div className="tw-text-xs">
                    <p className="!tw-m-0">Rewrite the text with AI</p>
                    <p className="!tw-m-0">
                      You can also select part of the text and right click
                    </p>
                  </div>
                </div>
              </div>
              <StatelessTranslateButton
                size="sm"
                onTranslate={handlePopulateMissingTranslations}
                languageCode={previewLang.code}
                {...translateButtonProps(previewLang.code)}
                popupProps={translatePopupProps(previewLang)}
                initialMTConfigGroupId={mtConfigGroupId}
              />
            </FlexDivTwoItems>
            {streamedPreviewString ? (
              <Text
                color="grey"
                style={{
                  whiteSpace: "pre-wrap",
                  padding: "1rem",
                  minHeight: "100px",
                }}
              >
                {streamedPreviewString}
              </Text>
            ) : (
              <>
                <TextareaWithRewriteContextMenu
                  getRef={(ref): void => {
                    previewTextareaRef.current = ref;
                  }}
                  languageCode={previewLang.code}
                  data-testid="lang-string-preview-input"
                  id={`${previewLang.code}-${uuidForPreviewLang}`}
                  onChange={(value): void => {
                    handleInputChange(value, previewLang.code);
                  }}
                  value={langStringObject[previewLang.code] || ""}
                  placeholder={previewLang.name}
                  onGenerationCompleteCallback={(text: string): Promise<void> =>
                    handleAddTextBlock(text, previewLang.code)
                  }
                />
                <Text size="small" color="grey">
                  {countWords(langStringObject[previewLang.code])} Words
                  {" | "}
                  {countChars(langStringObject[previewLang.code])} Chars
                </Text>
              </>
            )}
          </Form.Field>

          <Form.Field>
            <div>
              <Popup
                content="Show all Languages"
                trigger={
                  <Icon
                    name="list"
                    data-testid="all-languages"
                    link={!disableAllButtons}
                    color={
                      showAllOrPreview === ShowAllOrPreview.ALL
                        ? "blue"
                        : "black"
                    }
                    onClick={(): void => {
                      if (disableAllButtons) return;
                      if (showAllOrPreview !== ShowAllOrPreview.ALL) {
                        setShowAllOrPreview(ShowAllOrPreview.ALL);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
              <Popup
                content="Preview one language"
                trigger={
                  <Icon
                    data-testid="additional-preview"
                    name="eye"
                    color={
                      showAllOrPreview === ShowAllOrPreview.PREVIEW
                        ? "blue"
                        : "black"
                    }
                    link={!disableAllButtons}
                    onClick={(): void => {
                      if (disableAllButtons) return;
                      if (showAllOrPreview !== ShowAllOrPreview.PREVIEW) {
                        setShowAllOrPreview(ShowAllOrPreview.PREVIEW);
                      } else {
                        setShowAllOrPreview(ShowAllOrPreview.NONE);
                      }
                    }}
                  />
                }
              />
            </div>
            <Transition
              visible={showAllOrPreview === ShowAllOrPreview.PREVIEW}
              animation="fade down"
              duration={200}
              unmountOnHide
            >
              <>
                <Divider hidden />
                <Form.Field>
                  <FlexDivTwoItems>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <label>
                        <Dropdown
                          upward={false}
                          data-testid="lang-string-optional-preview-dropdown"
                          value={optionalPreviewLang}
                          onChange={(_, { value }): void =>
                            setOptionalPreviewLang(value as LanguageCode)
                          }
                          clearable
                          compact
                          placeholder="Select preview"
                          options={optionalPreviewLangOptions}
                        />
                      </label>
                      {!!optionalPreviewLang && (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <AIRewriteTextButton
                            input={langStringObject[optionalPreviewLang] || ""}
                            languageCode={optionalPreviewLang}
                            buttonProps={{
                              compact: true,
                              disabled: disableAllButtons,
                              variant: "primary-alt",
                            }}
                            onGenerationCompleteCallback={(
                              text
                            ): Promise<void> =>
                              handleAddTextBlock(text, optionalPreviewLang)
                            }
                          />
                          <div className="tw-text-xs">
                            <p className="!tw-m-0">Rewrite the text with AI</p>
                            <p className="!tw-m-0">
                              You can also select part of the text and right
                              click
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <StatelessTranslateButton
                      size="sm"
                      onTranslate={handlePopulateMissingTranslations}
                      languageCode={optionalPreviewLang}
                      {...translateButtonProps(optionalPreviewLang)}
                      popupProps={translatePopupProps(
                        customer.languages.find(
                          (language) => language.code === optionalPreviewLang
                        )
                      )}
                      initialMTConfigGroupId={mtConfigGroupId}
                    />
                  </FlexDivTwoItems>
                  <TextareaWithRewriteContextMenu
                    value={langStringObject[optionalPreviewLang] || ""}
                    onChange={(value): void => {
                      handleInputChange(value, optionalPreviewLang);
                    }}
                    languageCode={optionalPreviewLang}
                    placeholder={
                      customer.languages.find(
                        (language) => language.code === optionalPreviewLang
                      )?.name
                    }
                    onGenerationCompleteCallback={(
                      text: string
                    ): Promise<void> =>
                      handleAddTextBlock(text, optionalPreviewLang)
                    }
                    data-testid={`lang-string-optional-preview-input-${optionalPreviewLang}`}
                    id={`${optionalPreviewLang}-${uuidForOptionalPreviewLang}`}
                  />

                  <Text size="small" color="grey">
                    {countWords(langStringObject[optionalPreviewLang])} Words
                    {" | "}
                    {countChars(langStringObject[optionalPreviewLang])} Chars
                  </Text>
                </Form.Field>
              </>
            </Transition>
            {showAllOrPreview === ShowAllOrPreview.ALL && <Divider hidden />}
            {customer.languages.map((language: Language) => {
              if (language.code !== previewLang?.code) {
                const htmlId = `${language.code}-${uuidv4()}`;

                return (
                  <Transition
                    key={language.code}
                    visible={showAllOrPreview === ShowAllOrPreview.ALL}
                    animation="fade down"
                    duration={200}
                    unmountOnHide
                  >
                    <Form.Field>
                      <FlexDivTwoItems>
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <label htmlFor={htmlId}>
                            <Flag content={language.flag} />{" "}
                            {language.short_name}
                          </label>
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <AIRewriteTextButton
                              input={langStringObject[language.code] || ""}
                              languageCode={language.code}
                              buttonProps={{
                                compact: true,
                                disabled: disableAllButtons,
                                variant: "primary-alt",
                              }}
                              onGenerationCompleteCallback={(
                                text
                              ): Promise<void> =>
                                handleAddTextBlock(text, language.code)
                              }
                            />
                            <div className="tw-text-xs">
                              <p className="!tw-m-0">
                                Rewrite the text with AI
                              </p>
                              <p className="!tw-m-0">
                                You can also select part of the text and right
                                click
                              </p>
                            </div>
                          </div>
                        </div>
                        <StatelessTranslateButton
                          size="sm"
                          onTranslate={handlePopulateMissingTranslations}
                          languageCode={language.code}
                          {...translateButtonProps(language.code)}
                          popupProps={translatePopupProps(language)}
                          initialMTConfigGroupId={mtConfigGroupId}
                        />
                      </FlexDivTwoItems>
                      <TextareaWithRewriteContextMenu
                        value={langStringObject[language.code] || ""}
                        onChange={(value): void => {
                          handleInputChange(value, language.code);
                        }}
                        languageCode={language.code}
                        placeholder={language.name}
                        id={htmlId}
                        data-testid={`lang-string-preview-input-${language.code}`}
                        onGenerationCompleteCallback={(
                          text: string
                        ): Promise<void> =>
                          handleAddTextBlock(text, language.code)
                        }
                      />

                      <Text size="small" color="grey">
                        {countWords(langStringObject[language.code])} Words
                        {" | "}
                        {countChars(langStringObject[language.code])} Chars
                      </Text>
                    </Form.Field>
                  </Transition>
                );
              }
            })}
          </Form.Field>
        </Form>
      )}
    </div>
  );
};
