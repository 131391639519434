import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  PIPELINE_ACTION_STEP_LIST_GAP,
  SIMPLE_ACTION_PREFIX,
  bulkActionsWithNoSettings,
  findFriendlyName,
} from "./PipelineForm";
import { Text } from "../../../components/Text";
import { PipelineStepSettings } from "./PipelineStepSettings";
import { Draggable } from "react-beautiful-dnd";
import { Step, UniqueIdentifier } from "./types";
import {
  PipelineStepSegment,
  PipelineArrowDownIcon,
  PipelineDragIndicatorIcon,
  PipelineRemoveStepButton,
} from "./styledComponents";
import { SemanticPopupWithReactRef } from "../../../ui/semantic-ui-overwrites";
import { Divider, Icon, Popup } from "semantic-ui-react";
import { popupDelay } from "../../../customers/gpt/types";
import { useGetCustomerQuery } from "../../../api/customerApi";
import api from "../../../api/api";

type Props = {
  showArrow: boolean;
  step: Step;
  hasNext: boolean;
  uniqueIdentifier: string;
  index: number;
  pipelineId: number;
  onRemoveStep: (
    uniqueIdentifier: UniqueIdentifier,
    isSavedInDB: boolean
  ) => void;
  stepIsComplete: boolean;
  setStepAsComplete: (uniqueIdentifier: UniqueIdentifier) => void;
  isDragEnabled: boolean;
};
export const PipelineStep: React.FC<Props> = ({
  showArrow,
  step,
  hasNext,
  uniqueIdentifier,
  index,
  pipelineId,
  onRemoveStep,
  stepIsComplete,
  setStepAsComplete,
  isDragEnabled,
}) => {
  const { data: customer } = useGetCustomerQuery();
  const [triesToGetDescription, setTriesToGetDescription] = useState(0);
  const descriptionWrapperDivRef = useRef<HTMLDivElement>();
  const [descriptionShowing, setDescriptionShowing] = useState(false);
  const preventOpenModal = useRef(false);
  const [showSettings, setShowSettings] = useState(!stepIsComplete);

  const actionDescriptionUrl = useMemo(() => {
    if (!customer) return "";
    const url = `/d/${
      customer.slug
    }/products/bulk-actions/description/${step.action.replace(
      SIMPLE_ACTION_PREFIX,
      ""
    )}/${pipelineId}/${index}`;

    return url;
  }, [customer, index, step]);

  const setDescriptionHTML = (wait: number = 100): number => {
    return (setTimeout(() => {
      api.get(actionDescriptionUrl).then((res) => {
        // If this endpoint sends back a 204 (No content) it implies that we have tried to get the action description to soon and the state_data of the action has not yet been saved. This is due to a race condition between saving action to a pipeline for the first time and getting the description of it.
        if (res.status === 204) {
          setTriesToGetDescription((prev) => prev + 1);
          if (triesToGetDescription > 3) {
            return;
          }
          return setDescriptionHTML(wait * 2);
        }
        if (!descriptionWrapperDivRef.current) return;
        descriptionWrapperDivRef.current.innerHTML = res.data;
        setDescriptionShowing(!bulkActionsWithNoSettings.includes(step.action));
        setTriesToGetDescription(0);
      });
    }, wait) as unknown) as number;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | number;
    if (
      actionDescriptionUrl &&
      stepIsComplete &&
      descriptionWrapperDivRef.current
    ) {
      timeout = setDescriptionHTML();
    }
    return () => {
      clearTimeout(timeout as NodeJS.Timeout);
    };
  }, [actionDescriptionUrl, descriptionWrapperDivRef, stepIsComplete]);

  return (
    <Draggable
      draggableId={uniqueIdentifier}
      index={index}
      isDragDisabled={showSettings || !isDragEnabled}
    >
      {(provided): JSX.Element => (
        <div
          tabIndex={index}
          data-index={index}
          data-testid={`manage-pipelines-dnd-handler-${step.action}`}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <Popup
            open={!stepIsComplete && !showSettings}
            wide
            position="top left"
            size="small"
            offset={10}
            mouseEnterDelay={popupDelay}
            content="This step requires you to make choices"
            trigger={
              <div style={{ display: "flex", position: "relative" }}>
                <PipelineDragIndicatorIcon
                  $showSettings={showSettings || !isDragEnabled}
                  className="material-icons ui text grey"
                >
                  drag_indicator
                </PipelineDragIndicatorIcon>
                <PipelineStepSegment
                  $notClickable={bulkActionsWithNoSettings.includes(
                    step.action
                  )}
                  onClick={(): void => {
                    if (
                      !preventOpenModal.current &&
                      !bulkActionsWithNoSettings.includes(step.action)
                    ) {
                      setShowSettings(!showSettings);
                    }
                    preventOpenModal.current = false;
                  }}
                >
                  <div className="pipeline-step-segment-texts">
                    <Text compact>
                      {!stepIsComplete && !showSettings && (
                        <Icon name="warning sign" />
                      )}{" "}
                      {findFriendlyName(step.action, step.state_data)}
                    </Text>
                    <Text
                      compact
                      size="small"
                      className="action-open-settings-nudge"
                      textDecoration="underline"
                    >
                      Configure
                    </Text>
                  </div>
                  {descriptionShowing && (
                    <>
                      <Divider style={{ marginBottom: 0 }} />
                      <Text size="small" color="grey">
                        Saved Settings
                      </Text>
                    </>
                  )}
                  <div
                    className="ui text small"
                    ref={descriptionWrapperDivRef}
                    id="step-description"
                    onClick={(e: any): void => {
                      // Try to prevent Modal open if we suspect the clicked element to be a <a> tag
                      if (
                        e.target.tagName == "A" ||
                        e.target.parentElement.tagName == "A"
                      )
                        preventOpenModal.current = true;
                    }}
                  ></div>
                  <SemanticPopupWithReactRef
                    popupProps={{
                      content: "Remove step",
                      size: "tiny",
                      offset: -7,
                    }}
                  >
                    <PipelineRemoveStepButton
                      className="remove-step-button"
                      data-testid={`manage-pipelines-action-step-remove-${step.action}`}
                      onClick={(): void => {
                        preventOpenModal.current = true;
                        onRemoveStep(uniqueIdentifier, stepIsComplete);
                      }}
                      link
                      circular
                      name="close"
                      size="small"
                    />
                  </SemanticPopupWithReactRef>
                  {hasNext && showArrow && (
                    <PipelineArrowDownIcon
                      name="long arrow alternate down"
                      color="grey"
                      $bottom={-(PIPELINE_ACTION_STEP_LIST_GAP / 2) - 10}
                    />
                  )}
                </PipelineStepSegment>
              </div>
            }
          />
          <PipelineStepSettings
            show={showSettings}
            onHide={(): void => setShowSettings(false)}
            action={step.action}
            stateData={step.state_data}
            uniqueIdentifier={uniqueIdentifier}
            index={index}
            pipelineId={pipelineId}
            setStepAsComplete={setStepAsComplete}
            stepIsComplete={stepIsComplete}
            updateDescription={setDescriptionHTML}
          />
        </div>
      )}
    </Draggable>
  );
};
