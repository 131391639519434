const BASE = "prompt-selection-rules";

type PromptSelectionRulesCondition = {
  container: string;
  conditionOperatorLabel: string;
  inputs: {
    key: string;
    matchingSchemeSelector: string;
    value: string;
  };
  removeButton: string;
};
export const promptSelectionRulesTestSelectors = {
  promptSectionRules: {
    container: `${BASE}-container`,
    createButton: `${BASE}-create-new`,
    dimmer: `${BASE}-loading-dimmer`,
  },
  promptSelectionRulesList: {
    list: `${BASE}-list`,
    listItem: (name: string): string => `${BASE}-list-item-${name}`,
    listItemWarningIcon: (name: string): string =>
      `${BASE}-list-item-warning-${name}`,
  },
  promptSelectionRulesUpdateOrCreate: {
    form: `${BASE}-update-or-create-form`,
    inputs: {
      name: `${BASE}-update-or-create-form-name-input`,
      or: `${BASE}-update-or-create-form-or-radio`,
      and: `${BASE}-update-or-create-form-and-radio`,
      promptSelector: `${BASE}-update-or-create-form-prompt-selector`,
      promptGroupSelector: `${BASE}-update-or-create-form-prompt-group-selector`,
    },
    buttons: {
      addCondition: `${BASE}-update-or-create-form-add-condition`,
      removeRule: `${BASE}-update-or-create-form-remove-rule`,
      saveRule: `${BASE}-update-or-create-form-save-rule`,
    },
  },
  promptSelectionRulesCondition: (
    index: number
  ): PromptSelectionRulesCondition => ({
    container: `${BASE}-condition-container-${index}`,
    conditionOperatorLabel: `${BASE}-condition-operator-label-${index}`,
    inputs: {
      key: `${BASE}-condition-input-key-${index}`,
      matchingSchemeSelector: `${BASE}-condition-matching-scheme-selector-${index}`,
      value: `${BASE}-condition-input-value-${index}`,
    },
    removeButton: `${BASE}-condition-remove-${index}`,
  }),
  popups: {
    rules: {
      popupSelector: `${BASE}-explain-rules`,
      content: `${BASE}-explain-rules-content`,
    },
    settings: {
      popupSelector: `${BASE}-explain-settings`,
      content: `${BASE}-explain-settings-content`,
    },
    operator: {
      popupSelector: `${BASE}-explain-operator`,
      content: `${BASE}-explain-operator-content`,
    },
    conditions: {
      popupSelector: `${BASE}-explain-conditions`,
      content: `${BASE}-explain-conditions-content`,
    },
  },
};
