type DefaultDictKey = string | number | symbol;
type DefaultDictInitial = string | number | boolean | [] | {};
export type DefaultDict<T> = Record<DefaultDictKey, T>;

export function defaultDict<V extends DefaultDictInitial>(
  defaultFactory: () => V
): DefaultDict<V> {
  return new Proxy({} as DefaultDict<V>, {
    get: (target, name: DefaultDictKey): V => {
      if (!Reflect.has(target, name)) {
        Reflect.set(target, name, defaultFactory());
      }
      return Reflect.get(target, name);
    },
  });
}
