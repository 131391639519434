import { Customer } from "./Customer";

export interface CustomerSettings {
  alwaysTranslate: boolean;
  askForOverwrite: boolean;
  generateOnPublish: boolean;
  includeSeo: boolean;
  showFixedPhrases: boolean;
  showTranslate: boolean;
  showMachineTranslate: boolean;
  showHypernym: boolean;
  showStatusSwitch: boolean;
  showUntranslatedName: boolean;
  useLexiconEditor: boolean;
  vocabularyRequestAllowed: boolean;
}

export function getCustomerSettings(customer: Customer): CustomerSettings {
  const {
    always_translate: alwaysTranslate,
    ask_for_overwrite_on_publish: askForOverwrite,
    product_detail_generate_on_publish: generateOnPublish,
    product_detail_show_untranslated_name: showUntranslatedName,
    product_detail_show_status_switch: showStatusSwitch,
    request_word_show_hypernym: showHypernym,
    show_fixed_phrases: showFixedPhrases,
    show_machine_translate_in_request_word: showMachineTranslate,
    show_request_words: vocabularyRequestAllowed,
    show_seo_score: includeSeo,
    use_lexicon_editor: useLexiconEditor,
  } = customer.config;
  return {
    alwaysTranslate,
    askForOverwrite,
    generateOnPublish,
    includeSeo,
    showFixedPhrases,
    showTranslate: customer.isPayingCustomer,
    showMachineTranslate,
    showHypernym,
    showStatusSwitch,
    showUntranslatedName,
    useLexiconEditor,
    vocabularyRequestAllowed,
  };
}
