import React, { useEffect, useState } from "react";
import { Tab, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

import { fetchProductDetail } from "../../api/action";
import { useGetCustomerQuery } from "../../api/customerApi";
import { RootState } from "../../utils/store";

type Props = {
  productId: string;
};

export const TabMetadata: React.FC<Props> = ({ productId }) => {
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();

  const token = useSelector((state: RootState) => state.auth.token);

  const [metadata, setMetadata] = useState({});
  const [isProductLoading, setIsProductLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    fetchProductDetail(token, customer, productId)
      .then((details) => {
        if (mounted) setMetadata(details.getMetadata());
      })
      .finally(() => {
        if (mounted) setIsProductLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Tab.Pane className="tab-metadata">
      <Segment
        size="tiny"
        basic
        loading={isCustomerLoading || isProductLoading}
        data-testid="segment-metadata"
      >
        <pre style={{ margin: 0 }}>
          <code>{JSON.stringify(metadata, null, 4)}</code>
        </pre>
      </Segment>
    </Tab.Pane>
  );
};
