import { ColumnLabel, ColumnValue } from "./Columns";
import { Product } from "../../product";

export const ProductListOriginalTextColumn = {
  name: ColumnValue.OriginalText,
  label: ColumnLabel[ColumnValue.OriginalText],
  className: "text-column",
  renderCell: (p: Product): string => p.original_text,
  default: false,
};
