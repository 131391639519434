import React from "react";
import styled from "styled-components";
import { Language } from "../customers/customerlanguages";

const StyledDiv = styled.label`
  position: relative;
  cursor: pointer;
  display: block;
  width: 160px;
  padding: 10px 30px;
  border-radius: 10px;
  border: solid 2px transparent;

  &.selected {
    border-color: #d74c50;
  }
`;

const StyledI = styled.i`
  position: absolute;
  right: 0;
  top: 50%;
  width: 24px;
  height: 24px;
  margin-right: -12px;
  margin-top: -12px;
  font-size: 14px;
  background: #d74c50;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
`;

export const FlagSpanShortName = styled.span`
  font-size: 13px;
  font-weight: 600;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Flag = styled(FlagSpanShortName)`
  float: left;
  font-weight: normal;
  font-size: 18px;
  margin: -2px 10px 0 -18px;
`;

const StyledLi = styled.li`
  display: inline-block;
  vertical-align: top;
  margin: 5px 10px;
`;

type Props = {
  language: Language;
  onSelect: (isSelected: boolean, languageCode: string) => void;
  selected: boolean;
};

export const LanguageSelectorItem: React.FC<Props> = ({
  language,
  onSelect,
  selected,
}) => {
  const elementId = `language_${language.code}`;
  return (
    <StyledLi key={elementId}>
      <StyledDiv
        className={selected ? "selected" : ""}
        htmlFor={elementId}
        onClick={(): void => onSelect(!selected, language.code)}
      >
        {selected && <StyledI className="material-icons">check</StyledI>}
        <Flag className="flag">{language.flag}</Flag>
        <FlagSpanShortName className="label">
          {language.short_name}
        </FlagSpanShortName>
      </StyledDiv>
    </StyledLi>
  );
};
