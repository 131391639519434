import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { ChannelId, CustomerChannel } from "./Customer";
import { StyledFooterNameCell } from "./ChannelLanguageSettingsFooter";

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
`;

type Props = {
  buttonRows: React.FunctionComponent<{ channelId: ChannelId | string }>[];
  customerChannels: CustomerChannel[];
};
export const ChannelLanguageButtonsFooter: React.FC<Props> = ({
  buttonRows,
  customerChannels,
}) => {
  return (
    <Table.Footer
      fullWidth
      data-testid={"channel-language-buttons-footer-matrix"}
    >
      <Table.Row>
        <StyledFooterNameCell>Settings</StyledFooterNameCell>
        {customerChannels.map((channelFooterRow) => {
          const channelId = channelFooterRow.id;
          return (
            <Table.HeaderCell
              collapsing
              textAlign="center"
              data-testid={"footer-checkbox-cell"}
              key={`${channelFooterRow.display_name}_${channelFooterRow.id}`}
            >
              <ButtonsWrapper>
                {buttonRows.map((ButtonRowComponent, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ButtonRowComponent key={i} channelId={channelId} />
                ))}
              </ButtonsWrapper>
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Footer>
  );
};
