import React, { useEffect, useRef, useState } from "react";
import sortBy from "lodash/sortBy";
import { ProductId } from "../product";
import {
  SEOLanguage,
  SEOLanguageSelector,
} from "../../components/SEOLanguageSelector";
import { getProductSEO, replaceProductTag } from "../../api/action";
import { Table, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { Vocabulary, VocabularyLink } from "../../components/VocabularyLink";
import { useGetCustomerQuery } from "../../api/customerApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { popupStyle } from "../../producttext/ProductTextToolbar";

const StyledTable = styled(Table)`
  & {
    td {
      padding: 0;
      margin: 0;
      vertical-align: top;
    }
  }
`;

export type SEOScore = {
  clicks: number;
  name: string;
  cost_per_click: number;
  difficulty: number;
  related: SEOScore[];
  related_tree_id?: string;
  volume: number;
  vocabulary: Vocabulary;
};

type Props = {
  productId: ProductId;
};

export interface LanguageMapping<T> {
  [key: string]: T;
}

interface Rows {
  parent?: Vocabulary;
  rowsData: SEOScore[];
  indented?: boolean;
}

interface Header {
  name: string;
  title: string;
  content: string | null;
}

export const ProductDetailSEOTab: React.FC<Props> = ({ productId }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();
  const [activeLanguage, setActiveLanguage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [seoData, setSeoData] = useState(null);
  const [sortingDirection, setSortingDirection] = useState(null);
  const [sortingColumn, setSortingColumn] = useState(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      fetchLanguages();
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);

  if (isLoading) {
    return null;
  }

  const getLanguages = (): SEOLanguage[] => {
    return customer.data.languages.map((language: any) => {
      return {
        text: language.name,
        value: language.code,
      };
    });
  };

  const fetchSEO = (): void => {
    getProductSEO(token, productId).then((result) => {
      const { scores: seoData } = result;
      setSeoData(seoData);
      setSortingDirection(null);
      setSortingColumn(null);
    });
  };

  const fetchLanguages = (): void => {
    const languages = getLanguages();
    const {
      config: { tag_input_language: activeLanguage },
    } = customer;

    setActiveLanguage(activeLanguage);
    setLanguages(languages);
    fetchSEO();
  };

  const replaceTags = (relatedTreeId: string): void => {
    replaceProductTag(token, productId, relatedTreeId).then((result) => {
      const { scores: seoData } = result;
      setSeoData(seoData);
    });
  };

  const switchLanguage = (
    e: React.SyntheticEvent<HTMLElement>,
    target: HTMLInputElement
  ): void => {
    const activeLanguage = target.value;
    setActiveLanguage(activeLanguage);
    changeSorting("RESET_SORT");
  };

  const renderRows = ({
    parent,
    rowsData,
    indented = false,
  }: Rows): React.ReactElement[] => {
    const rows: React.ReactElement[] = [];

    for (const item of rowsData) {
      const vocabulary: Vocabulary = item.vocabulary;
      const key = item.related_tree_id ? item.related_tree_id : vocabulary.id;
      let onClick = null;
      if (parent) {
        onClick = (): void => replaceTags(item.related_tree_id);
      }
      rows.push(
        <Table.Row key={key}>
          <Table.Cell>
            <VocabularyLink
              indented={indented}
              name={item.name}
              isMainCategory={vocabulary.main_category}
              onClick={onClick}
            />
          </Table.Cell>
          <Table.Cell>{item.difficulty}</Table.Cell>
          <Table.Cell>{item.volume}</Table.Cell>
          <Table.Cell>{item.cost_per_click}</Table.Cell>
          <Table.Cell>{item.clicks}</Table.Cell>
        </Table.Row>
      );
      if (item.related) {
        const recommendationRows = renderRows({
          parent: item.vocabulary,
          rowsData: item.related,
          indented: true,
        });
        rows.push(...recommendationRows);
      }
    }
    return rows;
  };

  const renderHeaders = (): React.ReactElement[] => {
    const columns: React.ReactElement[] = [];
    const headers: Header[] = [
      {
        name: "vocabulary",
        title: "Vocabulary",
        content: "Words used in this product.",
      },
      {
        name: "difficulty",
        title: "Difficulty",
        content:
          "The keyword difficulty index (from 1 to 100%) shows you how difficult it would be to outrank your competitors in the Google organic top 20 for a given keyword. The higher the percentage, the harder it will be to achieve high rankings for each targeted keyword.",
      },
      {
        name: "volume",
        title: "Volume",
        content:
          "The average number of monthly searches for a given keyword over a 12-month period.",
      },
      {
        name: "cost_per_click",
        title: "Cost per click",
        content:
          "Average price in USD advertisers pay for a user’s click on an ad triggered by a given keyword (Google Ads).",
      },
      {
        name: "clicks",
        title: "Clicks",
        content: null,
      },
    ];

    const productDetailSEOTabPopupStyle = { ...popupStyle, opacity: 0.9 };
    for (const header of headers) {
      const trigger = (
        <Table.HeaderCell
          sorted={sortingColumn === header.name ? sortingDirection : null}
          onClick={(): void => changeSorting("CHANGE_SORT", header.name)}
        >
          {header.title}
        </Table.HeaderCell>
      );

      columns.push(
        <Popup
          key={header.name}
          content={header.content}
          trigger={trigger}
          style={productDetailSEOTabPopupStyle}
          inverted
        />
      );
    }
    return columns;
  };

  const changeSorting = (action: string, column?: string): void => {
    switch (action) {
      case "CHANGE_SORT":
        if (sortingColumn === column) {
          seoData[activeLanguage] = seoData[activeLanguage].reverse();

          setSortingDirection(
            sortingDirection === "ascending" ? "descending" : "ascending"
          );
        } else {
          seoData[activeLanguage] = sortBy(seoData[activeLanguage], [column]);
          setSortingDirection("ascending");
          setSortingColumn(column);
        }
        setSeoData(seoData);
        break;
      case "RESET_SORT":
        fetchSEO();
        break;

      default:
        throw new Error();
    }
  };

  const languageSelector = (): JSX.Element => {
    if (activeLanguage !== null) {
      return (
        <SEOLanguageSelector
          switchLanguage={switchLanguage}
          activeLanguage={activeLanguage}
          languages={languages}
        />
      );
    }
    return null;
  };

  const seoTable = (): JSX.Element => {
    if (seoData && seoData[activeLanguage]) {
      return (
        <StyledTable basic="very" sortable celled collapsing>
          <Table.Header>
            <Table.Row>{renderHeaders()}</Table.Row>
          </Table.Header>
          <Table.Body>
            {renderRows({
              parent: null,
              rowsData: seoData[activeLanguage],
            })}
          </Table.Body>
        </StyledTable>
      );
    }
    return null;
  };

  return (
    <>
      {languageSelector()}
      {seoTable()}
    </>
  );
};
