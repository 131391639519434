import { ChannelId } from "../customers/Customer";
import { ProductTextRef } from "../producttext/ProductText";
import { LanguageCode } from "../customers/customerlanguages";

export type ProductId = string;

export interface ChannelLanguagePairData {
  channel_id: ChannelId;
  language_code: LanguageCode;
}

export class ChannelLanguagePair implements ChannelLanguagePairData {
  public channel_id: ChannelId;
  public language_code: LanguageCode;

  constructor(languageCode: LanguageCode, channelId: ChannelId) {
    this.language_code = languageCode;
    this.channel_id = channelId;
  }

  createProductTextRef = (productId: ProductId): ProductTextRef => {
    return new ProductTextRef(productId, this.channel_id, this.language_code);
  };
}

export type Product = {
  id: ProductId;
  children: Product[];
  external_id: string | null;
  status: string | null;
  tags_used?: string | null;
  updated_at: string | null;
  created_at: string | null;
  parent_id: ProductId | null;
  parent_external_id: string | null;
  original_text?: string | null;
  ean: string | null;
  image_url: string | null;
  display_name: string | null;
  channel_language_pairs: ChannelLanguagePair[];
  namespace: string | null;
  labels: string[];
};

export enum ProductStatus {
  IMPORTING = "I",
  // should be null but this is not allowed in typescript enums
  IN_PROGRESS = "",
  READY = "R",
}

export enum ProductStatusLabel {
  IN_PROGRESS = "In progress",
  IMPORTING = "Importing",
  READY = "Ready",
}

export function getProductStatusLabel(status: string): ProductStatusLabel {
  switch (status) {
    case ProductStatus.IN_PROGRESS:
    case null:
      return ProductStatusLabel.IN_PROGRESS;
    case ProductStatus.IMPORTING:
      return ProductStatusLabel.IMPORTING;
    case ProductStatus.READY:
      return ProductStatusLabel.READY;
    default:
      throw new Error(`Unknown product status: ${status}`);
  }
}

export function productSKU(product: Product): string {
  return product.external_id || product.id;
}

export function getProductParentAndChildrenIds(
  products: Product[] | null
): ProductId[] {
  const productIds: ProductId[] = [];
  if (products != null) {
    products.forEach((product: Product) => {
      productIds.push(product.id);
      product.children.forEach((childProduct: Product) => {
        productIds.push(childProduct.id);
      });
    });
  }
  return productIds;
}
