import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { TagOption, TreeData } from "../utils/tagutils";
import { AxiosResponse } from "axios";
import { ProductId } from "../products/product";
import { getDisplayText, getValueWithTagId } from "../utils/data";
import Styled from "styled-components";
import IndexedDropdown from "../ui/Dropdown";
import { TYPES } from "../utils/consts";
import {
  DropdownOnSearchChangeData,
  DropdownProps,
} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {
  searchAsyncVocabulary,
  SearchVocabularyParams,
} from "../api/vocabularyApi";
import { useGetCustomerQuery } from "../api/customerApi";
import { Spinner } from "../utils/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { Button, DropdownItemProps } from "semantic-ui-react";
import { debounce } from "../utils/debounce";
import { SettingsModal } from "./SettingsModal";
import {
  ProductSettingSchemaField,
  ProductSettingSchemaFieldDataType,
} from "./ProductSettingSchemaField";

const StyledDiv = Styled.div<{ hideDefaultOptionButton: boolean }>`
  ${({ hideDefaultOptionButton }): string =>
    hideDefaultOptionButton &&
    `
        .product_type {
          .text button.width_hundred {
            display: none;
          }
        }
  `}
`;

export const IndexedDropdownLessPadding = Styled(IndexedDropdown)`
  &&&& {
    padding: 0;
    & > input {
      margin: 0 !important;
    }
    & > .text {
      top: 4px;
    }
    & > a {
     margin-left: 0.4em;
    }
  }
`;

type FieldChange = {
  name: string;
  selectType: string;
  value: string;
};

type Props = {
  advancedTemplateSearch?: boolean;
  handleRequestWord: (
    value: string,
    selectType: string,
    callback: (response: AxiosResponse, selectType: string) => void
  ) => void;
  limit: number;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  mapEnumNumberNames: (node: TreeData) => TreeData;
  onProductDataChanged: (node: TreeData[], delayedRefresh: boolean) => void;
  productId?: ProductId;
  textualAppName: string;
  treeData: TreeData[];
};

export const ProductTagsType: React.FC<Props> = ({
  advancedTemplateSearch,
  handleRequestWord,
  limit,
  mapEnumNumberNames,
  onProductDataChanged,
  productId,
  textualAppName,
  treeData,
  onKeyDown,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [vocabularies, setVocabularies] = useState<TreeData[]>([]);
  // Auto-complete suggestions for product type
  const [productTypeOptions, setProductTypeOptions] = useState<TagOption[]>([]);
  // Auto-complete suggestions for attributes
  const [productTagOptions, setProductTagOptions] = useState<TagOption[]>([]);
  const [productTagSelectOptions, setProductTagSelectOptions] = useState<
    TagOption[]
  >([]);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [isFetchingSearchResults, setIsFetchingSearchResults] = useState(false);
  const [
    isFetchingSearchResultsTags,
    setIsFetchingSearchResultsTags,
  ] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [queryParam, setQueryParam] = useState<SearchVocabularyParams | null>(
    null
  );
  const [selectType, setSelectType] = useState("");

  const mounted = useRef(true);

  /**
   * Update product type options for dropdown.
   */
  const getUpdatedProductTypeOptions = (): TagOption[] => {
    const updatedProductTypeOptions: TagOption[] = [];

    if (productTypeOptions.length === 0 && treeData[0] && treeData[0].value) {
      updatedProductTypeOptions.push({
        key: 0,
        text: getDisplayText(treeData[0]),
        value: getValueWithTagId(treeData[0]),
      });
    }
    return updatedProductTypeOptions;
  };

  /**
   * Update product tags options for dropdown.
   */
  const getUpdatedProductTagOptions = (): TagOption[] => {
    let updatedProductTagOptions: TagOption[] = [];

    if (productTagOptions.length === 0) {
      const tagOptions =
        treeData[0] && treeData[0].children
          ? treeData[0].children.filter((child) => {
              return child.type !== "kind";
            })
          : [];
      if (tagOptions.length > 0) {
        updatedProductTagOptions = tagOptions.map((tag, index) => ({
          key: index,
          text: getDisplayText(tag),
          value: getValueWithTagId(tag),
        }));
      }
    }
    return updatedProductTagOptions;
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (productTypeOptions.length === 0 && productTagOptions.length === 0) {
        const updatedProductTypeOptions = getUpdatedProductTypeOptions();
        const updatedProductTagOptions = getUpdatedProductTagOptions();
        if (
          updatedProductTypeOptions.length > 0 ||
          updatedProductTagOptions.length > 0
        ) {
          setProductTypeOptions(updatedProductTypeOptions);
          setProductTagOptions(updatedProductTagOptions);
        }
      }
      const tags =
        treeData[0] && treeData[0].children
          ? treeData[0].children.filter((child) => !child.allow_children)
          : [];
      setProductTagSelectOptions(
        tags.map((tag, index) => ({
          key: index,
          text: getDisplayText(tag),
          value: getValueWithTagId(tag),
        }))
      );
    }
    return (): void => {
      mounted.current = false;
    };
  }, [treeData]);

  useEffect(() => {
    let cleanup = false;
    // TODO move this function to utilities
    if (queryParam) {
      (async (): Promise<void> => {
        const response = await searchAsyncVocabulary(
          queryParam,
          token,
          textualAppName
        );
        setIsFetchingSearchResults(false);
        setIsFetchingSearchResultsTags(false);
        let data;
        let suggestions: TagOption[] = [];
        if (isEditingTags) {
          data = response.data.filter(function (item: TreeData) {
            return !item.allow_children;
          });
          suggestions = data.map((vocabulary: TreeData, index: number) => ({
            key: index,
            text: getDisplayText(vocabulary),
            value: getValueWithTagId(vocabulary),
          }));
        } else {
          data = response.data;
          suggestions = response.data.map(
            (vocabulary: TreeData, index: number): TagOption => ({
              key: index,
              text: getDisplayText(vocabulary),
              value: getValueWithTagId(vocabulary),
            })
          );
        }

        if (isEditingTags) {
          treeData[0] &&
            treeData[0].children &&
            treeData[0].children.filter((child) => {
              if (child.type !== "kind") {
                if (
                  suggestions.findIndex(
                    (tagOption) => tagOption.value === child.value
                  ) === -1
                ) {
                  suggestions.push({
                    value: getValueWithTagId(child),
                    text: getDisplayText(child),
                  });
                }
              }
            });
          if (!cleanup) {
            setSuggestionsAndVocabularies(
              suggestions,
              data,
              queryParam.q,
              selectType,
              isEditingTags
            );
          }
        } else {
          if (!cleanup) {
            setSuggestionsAndVocabularies(
              suggestions,
              data,
              queryParam.q,
              selectType,
              isEditingTags
            );
          }
        }
      })();
    }
    return (): void => {
      cleanup = true;
    };
  }, [queryParam]);

  if (isLoading) {
    return <Spinner />;
  }

  /**
   * Update vocabulary with a children if node has:
   *  - allowed children
   *  - existing child nodes
   *  Also - update node data with number value (singular, plural etc.)
   */
  const getUpdatedNodeData = (
    vocabulary: TreeData,
    node: TreeData
  ): TreeData => {
    vocabulary.children =
      node && vocabulary.allow_children && node.children ? node.children : [];
    vocabulary = mapEnumNumberNames(vocabulary);
    return vocabulary;
  };

  const getVocabList = ({
    vocabularyList,
    tempVocabularyList,
  }: {
    vocabularyList: TreeData[];
    tempVocabularyList: TreeData[];
  }): TreeData[] => {
    if (tempVocabularyList.length) {
      return tempVocabularyList;
    }
    return vocabularyList;
  };

  /**
   * Search vocabularies in state for index of a certain vocabulary.
   */
  const findVocabularyIndex = ({
    value,
    tempVocabularies = [],
  }: {
    value: string;
    tempVocabularies?: TreeData[];
  }): boolean | number => {
    const vocabList = getVocabList({
      vocabularyList: vocabularies,
      tempVocabularyList: tempVocabularies,
    });
    return (
      vocabList &&
      vocabList.length > 0 &&
      vocabList.findIndex((vocabulary) => {
        const vocabularyValue = getValueWithTagId(vocabulary);
        return vocabularyValue === value;
      })
    );
  };

  /**
   * Replace root of the tree (product type) with selected vocabulary.
   */
  const handleChangeProductType = (
    value: FieldChange,
    tempVocabularies: any
  ): void => {
    const matchedVocabularyIndex = findVocabularyIndex({
      value: value.value,
      tempVocabularies,
    });
    if (
      typeof matchedVocabularyIndex == "number" &&
      matchedVocabularyIndex > -1
    ) {
      treeData[0] = getUpdatedNodeData(
        getVocabList({
          vocabularyList: vocabularies,
          tempVocabularyList: tempVocabularies,
        })[matchedVocabularyIndex],
        treeData[0]
      );
    }
  };

  /**
   * Update product tags with selected vocabulary.
   */
  const handleChangeProductTags = (
    value: FieldChange,
    tempVocabularies: TreeData[] = []
  ): void => {
    let tag: Partial<TreeData> = {};
    const addedItem =
      value.selectType && value.selectType === "requestWord"
        ? value.value
        : value.value[value.value.length - 1];
    const matchedVocabularyIndex = findVocabularyIndex({
      value: addedItem,
      tempVocabularies,
    });
    if (
      typeof matchedVocabularyIndex == "number" &&
      matchedVocabularyIndex > -1
    ) {
      if (tempVocabularies.length) {
        tag = { ...tempVocabularies[matchedVocabularyIndex] };
      } else {
        tag = { ...vocabularies[matchedVocabularyIndex] };
      }

      // Case when already has some selected tags
      if (
        treeData[0] &&
        treeData[0].children &&
        treeData[0].children.length > 0
      ) {
        if (
          treeData[0].children.findIndex((productTag) => {
            const vocabularyValue = getValueWithTagId(productTag);
            return vocabularyValue === addedItem;
          }) === -1
        ) {
          treeData[0].children.push(tag as TreeData); // Add tag to array
        }
      } else {
        treeData[0].children = [tag as TreeData]; // TODO remove it(?)
      }
    }

    /*
     * Set only:
     * - found values
     * - KIND tags (product type)
     * - already set tags
     */
    if ("requestWord" !== value.selectType) {
      if (treeData[0].children && treeData[0].children.length) {
        treeData[0].children = treeData[0].children.filter(function (tag) {
          if (tag.value !== "Not-found-value") {
            return (
              tag.type === TYPES.KIND ||
              (value.value && value.value.includes(getValueWithTagId(tag)))
            );
          }
        });
      }
    }
  };

  /**
   * Handle product type/product tags dropdown select.
   */
  const handleChangeField = (
    event: any,
    value: FieldChange,
    tempVocabularies: TreeData[] = []
  ): void => {
    if (value.name === TYPES.KIND) {
      handleChangeProductType(value, tempVocabularies);
    } else if (value.name === "tags") {
      handleChangeProductTags(value, tempVocabularies);
    }

    const productTagOptionsCurrent: TagOption[] = [];
    const productTypeOptionsCurrent: TagOption[] = [];
    treeData[0] &&
      treeData[0].children &&
      treeData[0].children.map((tag) => {
        if (
          tag &&
          tag.value &&
          tag.value !== "Not-found-value" &&
          tag.type !== "kind"
        ) {
          productTagOptionsCurrent.push({
            value: getValueWithTagId(tag),
            text: getDisplayText(tag),
          });
        }
      });
    if (treeData[0]?.value) {
      productTypeOptionsCurrent.push({
        value: getValueWithTagId(treeData[0]),
        text: getDisplayText(treeData[0]),
      });
    }
    setProductTagOptions(productTagOptionsCurrent);
    setProductTypeOptions(productTypeOptionsCurrent);
    const tags =
      treeData[0] && treeData[0].children
        ? treeData[0].children.filter((child) => !child.allow_children)
        : [];
    setProductTagSelectOptions(
      tags.map((tag, index) => ({
        key: index,
        text: getDisplayText(tag),
        value: getValueWithTagId(tag),
      }))
    );
    onProductDataChanged(treeData, !!tempVocabularies.length);
  };

  /**
   * Handle product type/product tags dropdown search for a vocabulary.
   */
  const handleSearchChange = (
    event: React.SyntheticEvent<HTMLElement>,
    obj: DropdownOnSearchChangeData
  ): void => {
    const searchQuery = obj.searchQuery;
    const language = customer?.config.language;
    const prepareQueryParam: SearchVocabularyParams = {
      q: searchQuery,
      product_id: productId,
      limit,
      language,
      advanced_template_search: advancedTemplateSearch,
    };

    setSelectType("");
    setIsEditingTags(obj.name === "tags");
    const includeSeo = customer.config.show_seo_scores;
    if (searchQuery.length >= 1) {
      if (includeSeo) {
        prepareQueryParam.include_seo = includeSeo;
      }
      if (obj.name === "tags") {
        setIsFetchingSearchResultsTags(true);
        setSelectType("productTagSelect");
        let parentId = parseInt(treeData[0]?.id);
        if (isNaN(parentId)) {
          parentId = null;
        }
        prepareQueryParam.parent_id = parentId;
      } else {
        setIsFetchingSearchResults(true);
        prepareQueryParam.allow_children = true;
        prepareQueryParam.parent_id = null;
        setSelectType("productCategorySelect");
      }
      prepareQueryParam.advanced_template_search = true;
      setQueryParam(prepareQueryParam);
    } else {
      setVocabularies([]);
      if (obj.name === "tags") {
        setIsFetchingSearchResultsTags(false);
        setProductTagOptions([]);
      } else {
        setIsFetchingSearchResults(false);
        setProductTypeOptions([]);
      }
      setQueryParam(null);
    }
  };

  const setSuggestionsAndVocabularies = (
    suggestions: TagOption[],
    vocabularies: TreeData[],
    searchQuery: string,
    selectType: string,
    isEditingTags: boolean
  ): void => {
    const vocabularyRequestAllowed = customer?.config.show_request_words;
    if (vocabularyRequestAllowed) {
      suggestions.unshift({
        key: suggestions.length,
        text: (
          <>
            <Button
              className="width_hundred"
              content=" Use this word without translations, and request translation."
              onClick={(): void =>
                handleRequestWord(searchQuery, selectType, requestWordUpdated)
              }
            />
            {suggestions.length < 1 && (
              <span className="ui text grey">No results found</span>
            )}
          </>
        ),
        value: "Not-found-value",
      });
    }
    if (isEditingTags) {
      setProductTagOptions(suggestions);
    } else {
      setProductTypeOptions(suggestions);
    }
    setVocabularies(vocabularies);
  };

  const requestWordUpdated = (
    response: AxiosResponse,
    selectType: string
  ): void => {
    const data = response.data;

    if (data && data.value) {
      const tempVocabularies = [...vocabularies];
      tempVocabularies.push(data);

      const value: Partial<FieldChange> = {};
      value.selectType = "requestWord";
      if (selectType === "productCategorySelect") {
        value.name = "kind";
      } else if (selectType === "productTagSelect") {
        value.name = "tags";
      }
      value.value = getValueWithTagId(data);

      setVocabularies(tempVocabularies);
      handleChangeField(null, value as FieldChange, tempVocabularies);
    }
  };

  // ----------- Settings Modal -------------- //

  const handleSchemaSubmit = (settingsFormData: any): void => {
    treeData[0].settings = settingsFormData;

    onProductDataChanged(treeData, true);
    setIsOpenSettingsModal(false);
  };

  const attribute =
    treeData[0] && treeData[0].value ? getValueWithTagId(treeData[0]) : "";
  const tags =
    treeData[0] && treeData[0].children
      ? treeData[0].children.filter((child) => !child.allow_children)
      : [];
  return (
    <StyledDiv
      className={"product_type_container"}
      hideDefaultOptionButton={true}
      onKeyDown={onKeyDown}
    >
      <IndexedDropdown
        className={"product_type"}
        data-testid="productCategorySelect"
        id="productCategorySelect"
        name="kind"
        closeOnBlur
        closeOnChange
        fluid
        selection
        disabled={false}
        loading={isFetchingSearchResults}
        selectOnBlur={!!treeData.length}
        selectOnNavigation={false}
        placeholder="Type to add..."
        noResultsMessage="Start typing to get suggestions"
        options={productTypeOptions}
        onChange={(event: React.SyntheticEvent<HTMLElement>, value): void =>
          handleChangeField(event, value as FieldChange)
        }
        onClose={(): void => {
          if (!treeData.length) setProductTypeOptions([]);
        }}
        search={(options): DropdownItemProps[] => options}
        onSearchChange={debounce(
          (
            event: React.SyntheticEvent<HTMLElement>,
            obj: DropdownOnSearchChangeData
          ): void => handleSearchChange(event, obj),
          300
        )}
        value={attribute}
      />

      {treeData[0] && (
        <ProductSettingSchemaField
          node={treeData[0]}
          onChange={(data: ProductSettingSchemaFieldDataType): void =>
            handleSchemaSubmit(data)
          }
        />
      )}

      <IndexedDropdownLessPadding
        className={"product_tags alt-style-labels"}
        id="productTagSelect"
        name="tags"
        closeOnBlur
        closeOnChange
        fluid
        multiple
        selection
        selectOnNavigation={false}
        disabled={false}
        loading={isFetchingSearchResultsTags}
        selectOnBlur={!!treeData.length}
        placeholder="Type to add..."
        noResultsMessage="Start typing to get suggestions"
        options={[...productTagOptions, ...productTagSelectOptions]}
        onChange={(
          event: React.SyntheticEvent<HTMLElement>,
          value: DropdownProps
        ): void => handleChangeField(event, value as FieldChange)}
        onClose={(): void => {
          if (!treeData.length) {
            setProductTagSelectOptions([]);
          }
          setProductTagOptions([]);
        }}
        search={(options: DropdownItemProps[]): DropdownItemProps[] => options}
        onSearchChange={debounce(
          (
            event: React.SyntheticEvent<HTMLElement>,
            obj: DropdownOnSearchChangeData
          ) => handleSearchChange(event, obj),
          300
        )}
        value={tags.map(getValueWithTagId)}
      />
      {isOpenSettingsModal && (
        <SettingsModal
          closeSettingModal={(): void => setIsOpenSettingsModal(false)}
          handleFormJsonSchemaSubmit={(settingsFormData: any): void =>
            handleSchemaSubmit(settingsFormData)
          }
          currentNode={treeData[0]}
          isOpenSettingsModal={isOpenSettingsModal}
        />
      )}
    </StyledDiv>
  );
};
