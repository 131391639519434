import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Loader,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { DocumentStructure } from "./DocumentStructure";
import {
  DocumentStructure as DocumentStructureType,
  DocumentFormMode,
  Section,
} from "../types";
import { DocumentStructuresList } from "./DocumentStructuresList";
import { RootState, store } from "../../../utils/store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import { popupDelay } from "../../../customers/gpt/types";
import { customerApi, useGetCustomerQuery } from "../../../api/customerApi";
import { useGetDocumentStructures } from "./customhooks";
import {
  connectDocumentStructureToChannel,
  createDocumentStructure,
  deleteDocumentStructure,
  updateDocumentStructure,
} from "../../../api/documentStructureApi";
import { useSelector } from "react-redux";
import ManageGrid from "../../../components/manage/ManageGrid";

type Props = {
  managePromptLink: string;
};

export const DocumentStructures: React.FC<Props> = ({ managePromptLink }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(false);
  const { data: customer, isLoading } = useGetCustomerQuery();
  const {
    data: documentStructures,
    reFetch: refetchDocumentStructures,
    isFetching: isFetchingDocumentStructures,
  } = useGetDocumentStructures();

  const [loading, setLoading] = useState(true);

  const [formMode, setFormMode] = useState<DocumentFormMode>(
    DocumentFormMode.BLANK
  );
  const [isCopying, setIsCopying] = useState(false);

  const [
    selectedDocumentStructure,
    setSelectedDocumentStructure,
  ] = useState<DocumentStructureType | null>();
  const [
    selectDocumentStructureName,
    setSelectDocumentStructureName,
  ] = useState("");
  const [
    selectDocumentStructureDescription,
    setSelectDocumentStructureDescription,
  ] = useState("");
  const [
    selectDocumentStructureChannelId,
    setSelectDocumentStructureChannelId,
  ] = useState<number>();

  const [activeSections, setActiveSections] = useState<Section[]>([]);

  const customerChannels = useMemo(() => {
    if (!customer || isFetchingDocumentStructures) return [];
    return customer?.channels
      .filter(({ locked }) => !locked)
      .map((channel) => {
        let text = channel.display_name;
        if (channel.document_structure_id) {
          const connectedDocumentStructure = documentStructures?.find(
            (ds) => ds.id === channel.document_structure_id
          );
          if (
            connectedDocumentStructure &&
            connectedDocumentStructure.id !== selectedDocumentStructure?.id
          ) {
            text += " (Connected to another Structure)";
          }
        }

        return {
          key: channel.id,
          text,
          value: channel.id,
        };
      });
  }, [customer, documentStructures, selectedDocumentStructure]);

  const originalSelectedChannelId = useMemo(() => {
    if (!selectedDocumentStructure) return null;
    const channel = customer?.channels.find(
      (channel) =>
        channel.document_structure_id === selectedDocumentStructure.id
    );
    setSelectDocumentStructureChannelId(channel?.id || null);
    return channel?.id;
  }, [customer, selectedDocumentStructure]);

  const actionButtonName = useMemo(() => {
    switch (formMode) {
      case DocumentFormMode.CREATE:
        return "Create";
      case DocumentFormMode.UPDATE:
        return "Update";
      case DocumentFormMode.COPY:
        return "Copy";
      default:
        return "Create";
    }
  }, [formMode]);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    setLoading(!token || isLoading || isFetchingDocumentStructures);
  }, [isLoading, isFetchingDocumentStructures, token]);

  useEffect(() => {
    if (selectedDocumentStructure) {
      setFormMode(isCopying ? DocumentFormMode.COPY : DocumentFormMode.UPDATE);
      setSelectDocumentStructureDescription(
        selectedDocumentStructure.description
      );
      if (!isCopying) {
        setSelectDocumentStructureName(selectedDocumentStructure.name);
      } else {
        setSelectDocumentStructureName(
          `${selectedDocumentStructure.name}-copy`
        );
        setSelectDocumentStructureChannelId(null);
      }
    } else {
      if (formMode !== DocumentFormMode.CREATE) {
        setFormMode(DocumentFormMode.BLANK);
      }
      setSelectDocumentStructureName("");
      setSelectDocumentStructureDescription("");
      setSelectDocumentStructureChannelId(null);
    }
  }, [selectedDocumentStructure, isCopying]);

  const onStartCreateDocumentStructure = (): void => {
    setFormMode(DocumentFormMode.CREATE);
    setSelectedDocumentStructure(null);
  };

  const handleConnectDocumentStructureToChannel = async (
    id: number
  ): Promise<void> => {
    if (!selectDocumentStructureChannelId && !originalSelectedChannelId) return;
    if (selectDocumentStructureChannelId !== originalSelectedChannelId) {
      await connectDocumentStructureToChannel(
        token,
        selectDocumentStructureChannelId || originalSelectedChannelId,
        selectDocumentStructureChannelId ? id : null
      );
      store.dispatch(customerApi.util.invalidateTags(["Customer"]));
      if (!selectDocumentStructureChannelId)
        store.dispatch(
          setDjangoToastOpen({
            content: "Channel disconnected",
            appearance: NotificationAppearance.WARNING,
            additionalContent:
              "The connected Channel has been disconnected. The Document Structure will be ignored.",
          })
        );
    }
  };

  const handleCreateDocumentStructure = async (
    copy: boolean
  ): Promise<void> => {
    setLoading(true);
    const createdStructure = await createDocumentStructure(token, {
      name: selectDocumentStructureName,
      sections: activeSections,
      description: selectDocumentStructureDescription,
    });
    if (mounted.current) {
      store.dispatch(
        setDjangoToastOpen({
          content: copy
            ? "Document Structure Copied"
            : "Document Structure Created",
          appearance: NotificationAppearance.SUCCESS,
          additionalContent: copy
            ? `${createdStructure.name} has been copied from ${selectedDocumentStructure?.name}.`
            : `${createdStructure.name} has been created.`,
        })
      );
      if (copy) {
        setIsCopying(false);
        setFormMode(DocumentFormMode.UPDATE);
      }
      await handleConnectDocumentStructureToChannel(createdStructure.id);
      await refetchDocumentStructures();
      setSelectedDocumentStructure(createdStructure);
      setLoading(false);
    }
  };

  const handleUpdateDocumentStructure = async (): Promise<void> => {
    setLoading(true);
    const updateStructure = { ...selectedDocumentStructure };
    delete updateStructure?.locked;
    const updatedStructure = await updateDocumentStructure(token, {
      ...updateStructure,
      name: selectDocumentStructureName,
      sections: activeSections,
      description: selectDocumentStructureDescription,
    });
    if (mounted.current) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Document Structure Updated",
          appearance: NotificationAppearance.SUCCESS,
          additionalContent: `${updatedStructure.name} has been updated.`,
        })
      );
      await handleConnectDocumentStructureToChannel(updatedStructure.id);
      await refetchDocumentStructures();
      setSelectedDocumentStructure(updatedStructure);
      setLoading(false);
    }
  };

  const onActionClick = (): void => {
    switch (formMode) {
      case DocumentFormMode.UPDATE:
        handleUpdateDocumentStructure();
        break;
      case DocumentFormMode.CREATE:
        handleCreateDocumentStructure(false);
        break;
      case DocumentFormMode.COPY:
        handleCreateDocumentStructure(true);
        break;
      default:
        break;
    }
  };

  const handleDeleteDocumentStructure = async (): Promise<void> => {
    setLoading(true);
    await deleteDocumentStructure(token, selectedDocumentStructure.id);
    if (mounted.current) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Document Structure Deleted",
          appearance: NotificationAppearance.WARNING,
          additionalContent: `${selectedDocumentStructure.name} has been deleted.`,
        })
      );
      setFormMode(DocumentFormMode.BLANK);
      setSelectedDocumentStructure(null);
      setSelectDocumentStructureName("");
      setSelectDocumentStructureDescription("");
      await refetchDocumentStructures();
      setLoading(false);
    }
  };
  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                content="A Document Structure is a collection of sections in a specific order, which define the components of a complete text."
                wide="very"
                size="tiny"
                mouseEnterDelay={popupDelay}
                trigger={
                  <span>
                    <Text as="h4" compact inline>
                      Structures
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              />
            }
            right={
              <Button
                data-testid="document-structure-create-button"
                size="tiny"
                color="red"
                icon="plus"
                compact
                basic
                onClick={onStartCreateDocumentStructure}
              />
            }
          />
          <ManageGrid.Header.RightColumn>
            <div style={{ display: "flex", alignItems: "center" }}>
              {formMode !== DocumentFormMode.BLANK && (
                <Text compact as="h4" testId="document-structure-form-mode">
                  {formMode}:{" "}
                  {selectedDocumentStructure?.locked && (
                    <Popup
                      size="small"
                      content="This Document Structure is reserved by the system and will have a few settings disabled."
                      wide
                      trigger={<Icon name="lock" size="small" />}
                    />
                  )}
                  {selectedDocumentStructure?.name}
                </Text>
              )}
            </div>
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <DocumentStructuresList
              documentStructures={documentStructures}
              selectDocumentStructure={setSelectedDocumentStructure}
              selectedDocumentStructure={selectedDocumentStructure}
              isCopying={isCopying}
              setIsCopying={setIsCopying}
            />
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {formMode !== DocumentFormMode.BLANK ? (
              <div>
                <Form as="div" size="small">
                  <Form.Field>
                    <label htmlFor="document-name-input">Name</label>
                    <Input
                      data-testid="document-structure-name-input"
                      autoFocus
                      id="document-name-input"
                      value={selectDocumentStructureName}
                      size="small"
                      placeholder="Structure name"
                      onChange={(_, { value }): void =>
                        setSelectDocumentStructureName(value)
                      }
                    />
                  </Form.Field>
                  <Form.Field disabled={selectedDocumentStructure?.locked}>
                    <label style={{ display: "inline-block" }}>
                      Connected Channel
                    </label>{" "}
                    <Text color="grey" compact size="small" inline>
                      Channels are where the texts are published or used
                      (website, newsletter etc).
                    </Text>
                    <Dropdown
                      data-testid="document-structure-channel-dropdown"
                      placeholder="Select connected Channel"
                      clearable
                      selection
                      options={customerChannels}
                      value={selectDocumentStructureChannelId}
                      onChange={(_, { value }): void =>
                        setSelectDocumentStructureChannelId(value as number)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label htmlFor="document-description">
                      Comments (Optional)
                    </label>
                    <TextArea
                      data-testid="document-structure-description-textarea"
                      id="document-description"
                      value={selectDocumentStructureDescription || ""}
                      size="small"
                      placeholder="Use case, description, etc."
                      onChange={(_, { value }): void =>
                        setSelectDocumentStructureDescription(value as string)
                      }
                    />
                  </Form.Field>
                </Form>
                <Divider hidden />
                <Button
                  data-testid="document-structure-action-button"
                  disabled={
                    activeSections.length === 0 || !selectDocumentStructureName
                  }
                  content={actionButtonName}
                  color="red"
                  onClick={onActionClick}
                />
                <Divider hidden />
                <DocumentStructure
                  key={selectedDocumentStructure?.id}
                  selectedDocumentStructure={selectedDocumentStructure}
                  allDocumentStructures={documentStructures}
                  collectSections={setActiveSections}
                  managePromptLink={managePromptLink}
                />
                <Divider hidden />

                {formMode === DocumentFormMode.UPDATE &&
                  !selectedDocumentStructure?.locked && (
                    <Button
                      data-testid="document-structure-delete-button"
                      content="Delete"
                      basic
                      floated="right"
                      size="small"
                      compact
                      onClick={handleDeleteDocumentStructure}
                    />
                  )}
              </div>
            ) : (
              <ManageGrid.Body.EmptyForm>
                {!!documentStructures?.length && (
                  <>
                    <Text as="h2" compact>
                      Select a Document Structure from the list
                    </Text>
                    <Text as="h3" color="grey" compact>
                      Or
                    </Text>
                  </>
                )}
                <Button
                  content="Create new Document Structure"
                  icon="plus"
                  color="red"
                  size="large"
                  onClick={onStartCreateDocumentStructure}
                />
              </ManageGrid.Body.EmptyForm>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
      <Dimmer active={loading} inverted page>
        <Loader data-testid="document-structure-page-loader" />
      </Dimmer>
    </div>
  );
};
