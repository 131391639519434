import * as React from "react";

import { ProductFilterOrderByDateCategory } from "./ProductFilterOrderByDateCategory";
import { ProductFilterDatePicker } from "./ProductFilterDatePicker";
import { ProductListAction } from "../ProductList";
import { FlexCenter, FontSizeParagraph } from "./ProductFilterChoice";
import { DateCategory } from "../ProductListTypes";

type Props = {
  dateCategory: DateCategory;
  dateEnd: Date | null;
  dateStart: Date | null;
  onAction?: (action: ProductListAction, value?: unknown) => void;
};

export const ProductFilterOrderByDate: React.FC<Props> = ({
  dateCategory,
  dateEnd,
  dateStart,
  onAction,
}) => (
  <FlexCenter>
    <FontSizeParagraph>By date:</FontSizeParagraph>
    <ProductFilterOrderByDateCategory
      dateCategory={dateCategory}
      onAction={onAction}
    />
    <ProductFilterDatePicker
      date={dateStart}
      dateEnd={dateEnd}
      text={"After"}
      onChange={(newDate: Date | null): void => {
        onAction?.(ProductListAction.ChangeDateStart, newDate);
        onAction?.(ProductListAction.ChangeDateCategory, dateCategory);
      }}
    />
    <ProductFilterDatePicker
      date={dateEnd}
      dateStart={dateStart}
      text={"Before"}
      onChange={(newDate: Date | null): void => {
        onAction?.(ProductListAction.ChangeDateEnd, newDate);
        onAction?.(ProductListAction.ChangeDateCategory, dateCategory);
      }}
    />
  </FlexCenter>
);
