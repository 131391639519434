import $ from "jquery";
import React from "react";
import Downshift from "downshift";
import throttle from "lodash/throttle";

interface Props {
  initialValue: Suggestion | null;
  onDataChanged: (component: object, data: Suggestion | {}) => void;
}

export interface Suggestion {
  id: number;
  name: string;
}

interface State {
  selectedItem: Suggestion;
  inputValue: string;
  suggestions: Array<Suggestion>;
}

export type FieldSpecification = {};

export const PredefinedTitle: React.FC<Props> = ({
  onDataChanged,
  initialValue,
}) => {
  const [state, setState] = React.useState<State>({
    selectedItem: initialValue,
    inputValue: initialValue ? initialValue.name : "",
    suggestions: [],
  });

  const refreshSuggestions = React.useRef(
    throttle((inputValue: string) => {
      if (!inputValue) {
        return;
      }
      const customerToken = document.documentElement.getAttribute(
        "data-customer-token"
      );
      $.ajax({
        method: "POST",
        url: "/api/_internal/product/predefined_titles/",
        data: { search: inputValue, customer_token: customerToken },
        dataType: "json",
        success: function (data) {
          setState((s) => ({ ...s, suggestions: data }));
        },
      });
    }, 200)
  );

  React.useEffect(() => {
    refreshSuggestions.current(state.inputValue);
  }, [state.inputValue, refreshSuggestions]);

  return (
    <Downshift
      selectedItem={state.selectedItem}
      inputValue={state.inputValue || ""}
      onInputValueChange={(inputValue): void => {
        setState((s) => ({ ...s, inputValue }));
      }}
      itemToString={(item: Suggestion | null): string =>
        item ? item.name : ""
      }
      onChange={(item: Suggestion | null): void => {
        setState((s) => ({ ...s, selectedItem: item }));
        onDataChanged(null, item || {});
      }}
    >
      {({
        isOpen,
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        clearSelection,
      }): React.ReactElement => (
        <div className={`predefined-title-component`}>
          <div className={`wrapper ${isOpen ? "open" : ""}`}>
            <input
              type="text"
              {...getInputProps({
                placeholder: "Choose title",
                onChange: (e) => {
                  if (e.target.value === "") {
                    clearSelection();
                  }
                },
              })}
            />

            <ul className="suggestions" {...getMenuProps()}>
              {state.suggestions.map((item, index) => (
                <li
                  {...getItemProps({ key: item.id, index, item })}
                  className={index == highlightedIndex ? "selected" : ""}
                  key={item.id}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  );
};
