import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import { HTML } from "../../components/HTML";
import { Product } from "../product";
import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  product: Product;
}

const StyledDiv = styled.div`
  & {
    font-size: x-small;
    img {
      border: 1px solid lightgray;
      display: inline;
      float: left;
      height: auto;
      margin-right: 1em;
      max-width: 3em;
      width: 3em;
    }
    img:hover {
      height: inherit;
      max-width: 600px;
      max-height: 600px;
      position: absolute;
      width: inherit;
      z-index: 9999;
    }
    .html {
      display: inline;
    }
  }
`;

export const ProductListImage: React.FC<Props> = ({ product }) => {
  const { image_url: imageUrl, display_name: displayName } = product;
  let image = null;
  if (imageUrl) {
    const [hover, setHover] = useState(false);
    image = (
      <>
        <Image src={imageUrl} data-testid={"product-list-image-src"} />
        <Image
          onMouseEnter={(): void => setHover(true)}
          onMouseLeave={(): void => setHover(false)}
          src={imageUrl}
          style={{ display: hover ? "inline" : "none" }}
        />
      </>
    );
  }
  return (
    <StyledDiv data-testid={"product-list-image"}>
      {image}
      {displayName && <HTML text={displayName} />}
    </StyledDiv>
  );
};
