import { BalloonDiv } from "../../components/BalloonDiv";
import React from "react";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { ProductTagAction } from "../../api/action";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";

interface Props {
  details: ProductDetailInfo;
  onTagsAction: onTagsActionCallback;
  item: SidebarItem | null;
  toplevel: boolean;
}

export const AddSubpartButton: React.FC<Props> = ({
  details,
  onTagsAction,
  item,
  toplevel,
}) => {
  const add = (
    <a
      className="add add-subpart"
      onClick={(): void => {
        onTagsAction({
          action: ProductTagAction.ADD_SUBPART,
          parent: item?.id,
        });
      }}
    >
      <i className="icon plus"></i>
      <span>Add</span>
    </a>
  );
  if (toplevel) {
    return (
      <BalloonDiv
        className={"div"}
        value={details.microcopies["add_new_attribute"]}
        pos={"right"}
        data-testid="ballon-div"
      >
        {add}
      </BalloonDiv>
    );
  } else {
    return add;
  }
};
