import React, { Component } from "react";
import { RequestWordModal } from "./RequestWordModal/RequestWordModal";
import { spanExtract } from "../api/action";
import { newRequestWord } from "../api/vocabularyApi";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
});

const connector = connect(mapStateToProps, null);

class Annotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spans: [],
      newSpan: {},
      // RequestModal
      requestedSpanIndex: -1,
      newWord: "",
      modalInputValue: "",
      modalEnglishInputValue: "",
      wordType: "",
      shouldPopulate: false,
      isOpenRequestModal: false,
      lexiconData: null,
      hypernymData: null,
      showEntityPopup: false,
      loadingSpanExtract: false,
    };
  }
  componentDidMount() {
    this.initAnnotationData();
    // Add keypress event handler for entity selection update
    window.addEventListener("keypress", this._onKeyPress);

    this.props.onLoaded(this);
  }
  componentWillUnmount() {
    window.removeEventListener("keypress", this._onKeyPress);
  }
  initAnnotationData() {
    const { spans, onDataChanged, entityTypesRequestConfig } = this.props;

    const tempSpans = [...spans];
    tempSpans.forEach((span) => {
      entityTypesRequestConfig.find((entityType) => {
        if (entityType.entity_type === span.entityType) {
          span.groupEntityType = entityType.group_entity_type;
          span.tagCategory = entityType.tag_category;
          span.tagType = entityType.tag_type;
        }
      });
    });

    const { newSpans, spanUpdated } = this.updateSpanBoundaries(tempSpans);

    this.setState({ spans: newSpans });
    if (spanUpdated) {
      onDataChanged(this, newSpans);
    }
  }
  getGroupEntityPrio(entityType) {
    const { entityTypesRequestConfig } = this.props;
    const i = entityTypesRequestConfig.findIndex((et) => {
      return et.entity_type == entityType;
    });
    return i;
  }
  callSpanExtractApi(spans, newSpanIndex) {
    const { text, token, textualAppName } = this.props;
    const existingSpans = [...spans];
    const newSpan = { ...spans[newSpanIndex] };
    existingSpans.splice(newSpanIndex, 1);
    this.setState({ loadingSpanExtract: true });

    spanExtract(
      {
        text: text,
        existing_spans: existingSpans,
        added_span: newSpan,
      },
      token,
      textualAppName
    )
      .then((response) => {
        this.setState({ loadingSpanExtract: false });
        const data = response.data;
        if (data && data.existing_spans) {
          this.handleSpanUpdate(data.existing_spans);
        }
      })
      .catch(() => {
        this.setState({ loadingSpanExtract: false });
      });
  }
  updateSpanBoundaries(spans, newSpanIndex = -1) {
    const { wordBoundaries } = this.props;

    const newSpans = [];
    let spanUpdated = false;
    // Update span start / end in wordBoundaries
    spans.forEach(
      ({
        start,
        end,
        entityType,
        requestable,
        groupEntityType,
        tags,
        tagCategory,
        tagType,
        frequency,
      }) => {
        wordBoundaries.forEach((el) => {
          if (el[0] < start && start <= el[1]) {
            start = el[0];
            spanUpdated = true;
          }
          if (el[0] < end && end <= el[1]) {
            end = el[1];
            spanUpdated = true;
            return;
          }
          if (el[0] > end) {
            return;
          }
        });
        const newSpan = {
          start,
          end,
          entityType,
          requestable,
          groupEntityType,
          tags,
          tagCategory,
          tagType,
          frequency,
        };
        if (
          !newSpans.some(
            (el) =>
              el.start === newSpan.start &&
              el.end === newSpan.end &&
              el.entityType === newSpan.entityType
          )
        ) {
          newSpans.push({
            start,
            end,
            entityType,
            requestable,
            groupEntityType,
            tagCategory,
            tagType,
            tags,
            frequency,
          });
        }
      }
    );

    if (newSpanIndex >= 0) {
      this.callSpanExtractApi(newSpans, newSpanIndex);
    }

    newSpans.sort((a, b) => {
      if (a.start === b.start) {
        if (a.end === b.end) {
          return (
            this.getGroupEntityPrio(b.entityType) -
            this.getGroupEntityPrio(a.entityType)
          );
        }
        return b.end - a.end;
      }
      return a.start - b.start;
    });

    newSpans.forEach((span, index) => {
      span.spanIndex = index;
    });

    return { newSpans, spanUpdated };
  }
  handleSpanUpdate(updatedSpans, newSpanIndex) {
    const { onDataChanged } = this.props;
    const { newSpans } = this.updateSpanBoundaries(updatedSpans, newSpanIndex);
    this.setState({ spans: newSpans });
    onDataChanged(this, newSpans);
  }
  addNewSpan = (currentEntity) => {
    this.setState({ showEntityPopup: false });
    const { entityTypesRequestConfig } = this.props;
    const { spans, newSpan } = this.state;

    const currentRequestConfig = entityTypesRequestConfig.find((entityType) => {
      return entityType.entity_type === currentEntity;
    });
    const newSpanData = {
      ...newSpan,
      entityType: currentEntity,
      requestable:
        currentRequestConfig &&
        currentRequestConfig.requestable &&
        !currentRequestConfig.group_entity_type,
      groupEntityType:
        currentRequestConfig && currentRequestConfig.group_entity_type,
      tagCategory: currentRequestConfig
        ? currentRequestConfig.tag_category
        : "",
      tagType: currentRequestConfig ? currentRequestConfig.tag_type : "",
    };

    let newSpanIndex = -1;
    const tempSpans = [];

    if (newSpanData.sameAnchor) {
      // If no spans yet, just add a new span
      if (spans.length === 0) {
        tempSpans.push(newSpanData);
        newSpanIndex = tempSpans.length - 1;
      } else {
        // Check span exist and add to correct index
        spans.forEach((span, index) => {
          if (newSpanIndex >= 0) {
            tempSpans.push(span);
          } else if (span.end < newSpanData.start) {
            tempSpans.push(span);
            if (index === spans.length - 1 && newSpanIndex < 0) {
              newSpanIndex = tempSpans.length;
              tempSpans.push(newSpanData);
            }
          } else if (span.start > newSpanData.end) {
            // Add a new span if not added yet
            if (newSpanIndex < 0) {
              newSpanIndex = tempSpans.length;
              tempSpans.push(newSpanData);
            }
            tempSpans.push(span);
          } else if (
            span.start <= newSpanData.start &&
            newSpanData.end <= span.end
          ) {
            tempSpans.push(span);
            if (newSpanIndex < 0) {
              newSpanIndex = tempSpans.length;
              tempSpans.push(newSpanData);
            }
          }
        });
      }
    } else {
      // Check span exist and add to correct index
      spans.forEach((span) => {
        // Check if span anchors not override with existing spans
        let updatedStart = newSpanData.start;
        let updatedEnd = newSpanData.end;
        if (span.start < newSpanData.start && newSpanData.start < span.end) {
          updatedStart = span.start;
        }

        spans.forEach((span) => {
          if (span.start < newSpanData.end && newSpanData.end < span.end) {
            updatedEnd = span.end;
          }
        });

        if (
          newSpanIndex < 0 &&
          !(
            updatedStart === span.start &&
            updatedEnd === span.end &&
            span.entityType === currentEntity
          )
        ) {
          newSpanIndex = tempSpans.length;
          tempSpans.push({
            ...newSpanData,
            start: updatedStart,
            end: updatedEnd,
          });
        }
        tempSpans.push(span);
      });
    }

    // If not exist add new span
    if (newSpanIndex >= 0) {
      this.handleSpanUpdate(tempSpans, newSpanIndex);
    }
  };

  _onMouseUp = () => {
    // Get selectedText
    const selection = window.getSelection();
    const anchorElement = selection.anchorNode.parentElement;
    const selectedText = selection.toString().trim();

    if (selectedText && selectedText.length > 0) {
      // if selected in 1 node and is string content
      if (selection.anchorNode === selection.focusNode) {
        const selectionOffset = parseInt(
          (selection.anchorNode.parentElement.attributes.offset &&
            selection.anchorNode.parentElement.attributes.offset.value) ||
            selection.anchorNode.parentElement.parentElement.attributes.offset
              .value
        );
        const maxAnchor = Math.max(
          selection.anchorOffset,
          selection.focusOffset
        );
        const minAnchor = Math.min(
          selection.anchorOffset,
          selection.focusOffset
        );

        const start = selectionOffset + minAnchor;
        const end = start + (maxAnchor - minAnchor);

        if (start >= 0) {
          this.setState({
            newSpan: {
              start,
              end,
              sameAnchor: true,
              x: anchorElement.offsetLeft + 10,
              y: anchorElement.offsetTop + 32,
            },
            showEntityPopup: true,
          });
        }
      } else {
        const focusTextOffset = parseInt(
          (selection.focusNode.parentElement.attributes.offset &&
            selection.focusNode.parentElement.attributes.offset.value) ||
            selection.focusNode.parentElement.parentElement.attributes.offset
              .value
        );
        const anchorTextOffset = parseInt(
          (selection.anchorNode.parentElement.attributes.offset &&
            selection.anchorNode.parentElement.attributes.offset.value) ||
            selection.anchorNode.parentElement.parentElement.attributes.offset
              .value
        );

        let selectionOffset = 0;
        let startAnchor = 0;

        if (focusTextOffset > anchorTextOffset) {
          selectionOffset = anchorTextOffset;
          startAnchor = selection.anchorOffset;
        } else {
          selectionOffset = focusTextOffset;
          startAnchor = selection.focusOffset;
        }

        const start = selectionOffset + startAnchor;
        const end = start + selectedText.length;

        if (start >= 0) {
          this.setState({
            newSpan: {
              start,
              end,
              sameAnchor: false,
              x: anchorElement.offsetLeft + 10,
              y: anchorElement.offsetTop + 32,
            },
            showEntityPopup: true,
          });
        }
      }
    }
  };
  removeSpanAt(event, spanIndex) {
    const { spans } = this.state;
    const tempSpans = [...spans];
    tempSpans.splice(spanIndex, 1);

    this.handleSpanUpdate(tempSpans);
    event.stopPropagation();
  }
  _handleSpanClick = (span) => {
    const { text, entityTypesRequestConfig } = this.props;
    if (span.requestable) {
      const newWord = text.slice(span.start, span.end);
      const selectedEntityType = entityTypesRequestConfig.find((type) => {
        return type.entity_type === span.entityType;
      });
      const wordType = `${selectedEntityType.tag_category}/${selectedEntityType.tag_type}`;

      this.setState({
        newWord,
        wordType,
        isOpenRequestModal: true,
        requestedSpanIndex: span.spanIndex,
      });
    }
  };

  renderEntityPicker() {
    const { entityTypes, entityTypesRequestConfig } = this.props;
    const { newSpan, loadingSpanExtract } = this.state;

    const tempEntityTypes = [];
    entityTypes.forEach((entityType) => {
      const requestConfig = entityTypesRequestConfig.find(
        (entityTypeRequestConfig) => {
          return entityTypeRequestConfig.entity_type === entityType;
        }
      );
      tempEntityTypes.push({
        entityType: entityType,
        groupEntityType: requestConfig
          ? requestConfig.group_entity_type
          : false,
      });
    });

    return (
      <div
        className="entity-content"
        style={{ top: newSpan.y, left: newSpan.x }}
      >
        {tempEntityTypes.map((item, index) => {
          let disabled = false;
          if (
            loadingSpanExtract ||
            (!newSpan.sameAnchor && !item.groupEntityType)
          ) {
            disabled = true;
          }
          return (
            <div
              key={index}
              className={`entity-type-selection ${disabled ? "disabled" : ""}`}
              onClick={() => {
                if (!disabled) {
                  this.addNewSpan(item.entityType);
                }
              }}
            >
              {item.entityType}
            </div>
          );
        })}
      </div>
    );
  }

  renderAnnotationView(text, spanStart, spanEnd, index, parentSpan = null) {
    const { spans } = this.state;

    const jsx = [];
    let elements = [];
    let offset = 0;
    const parentSpanIndex = parentSpan ? parentSpan.spanIndex : -1;

    const showSpans = spans.slice(parentSpan ? parentSpan.spanIndex : 0);
    showSpans.forEach(
      ({ entityType, start, end, requestable, spanIndex, frequency }) => {
        if (
          offset <= start - spanStart &&
          spanIndex !== parentSpanIndex &&
          start - spanStart >= 0 &&
          end - spanStart <= text.length
        ) {
          const fragment = text.slice(offset, start - spanStart);
          const entity = text.slice(start - spanStart, end + 1 - spanStart);
          const fragmentOffset = offset + spanStart;
          const entityOffset = start;
          elements.push([fragmentOffset, fragment]);
          elements.push([
            entityOffset,
            {
              token: entity,
              entityType: entityType.toLowerCase(),
              span: {
                entityType,
                start,
                end,
                requestable,
                spanIndex,
                frequency,
              },
            },
          ]);
          offset = end + 1 - spanStart;
        }
      }
    );
    elements.push([offset + spanStart, text.slice(offset, text.length)]);
    elements = elements.filter((val) => val !== " ");
    elements = elements.filter((val) => !!val);
    elements.forEach((t) => {
      if (typeof t[1] === "string") {
        jsx.push([t[0], t[1]]);
      } else if (t[1].token !== "") {
        const jsxElement = (
          <mark
            data-entity={t[1].entityType}
            className={t[1].span.requestable ? "requestable" : ""}
            onClick={() => this._handleSpanClick(t[1].span)}
            data-frequency={t[1].span.frequency}
          >
            <div
              className="cross-button"
              onClick={(e) => this.removeSpanAt(e, t[1].span.spanIndex)}
            />
            {t[1].token}
          </mark>
        );
        jsx.push([t[0], jsxElement]);
      }
    });

    return (
      <div
        key={index && index}
        className="taggy-content"
        onClick={this._onMouseUp}
        style={{
          marginLeft: parentSpan && parentSpan.tier * 30,
          zIndex: parentSpan ? 1000 - parentSpan.tier : 1000,
        }}
      >
        {parentSpan && <div className="tier-line" />}
        <div className="taggy-wrapper">
          {jsx.map((j, i) => (
            <span key={i} offset={j[0]}>
              {j[1]}
            </span>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const {
      text,
      language,
      token,
      textualAppName,
      useLexiconEditor,
      showHypernym,
      lexiconEditorDirection,
      alwaysTranslate,
      showMachineTranslate,
    } = this.props;
    const {
      spans,
      modalInputValue,
      modalEnglishInputValue,
      wordType,
      shouldPopulate,
      isOpenRequestModal,
      newWord,
      showEntityPopup,
    } = this.state;

    const groupSpans = spans.filter((span) => !!span.groupEntityType);
    groupSpans.forEach((groupSpan, i) => {
      let tier = 0;
      groupSpans.slice(0, i).forEach((checkSpan) => {
        if (
          checkSpan.start <= groupSpan.start &&
          groupSpan.end <= checkSpan.end
        ) {
          tier++;
        }
      });
      groupSpan.tier = tier;
    });

    return (
      <div
        className="annotation"
        onClick={() =>
          showEntityPopup && this.setState({ showEntityPopup: false })
        }
      >
        {showEntityPopup && this.renderEntityPicker()}
        <div className="taggy-container">
          {this.renderAnnotationView(text, 0, text.length)}
          {groupSpans.map((span, index) => {
            return this.renderAnnotationView(
              text.slice(span.start, span.end + 1),
              span.start,
              span.end,
              index,
              span
            );
          })}
        </div>
        {isOpenRequestModal && (
          <RequestWordModal
            alwaysTranslate={alwaysTranslate}
            closeModal={this.closeRequestModal}
            customerLanguage={language}
            description={modalInputValue}
            englishTranslation={modalEnglishInputValue}
            isOpenModal={isOpenRequestModal}
            lexiconEditorDirection={lexiconEditorDirection}
            newWord={newWord}
            onDescriptionInputChange={(value) => {
              this.setState({ modalInputValue: value });
            }}
            onEnglishTranslationInputChange={(value) => {
              this.setState({ modalEnglishInputValue: value });
            }}
            onHypernymChange={this.onHypernymChange}
            onLexiconDataChange={this.onLexiconDataChange}
            onShouldPopulateChange={this.onShouldPopulateChange}
            onWordTypeChange={this.onWordTypeChange}
            populateTranslations={true}
            shouldPopulate={shouldPopulate}
            showHypernym={showHypernym}
            showMachineTranslate={showMachineTranslate}
            submitAction={this.requestNewWord}
            textualAppName={textualAppName}
            token={token}
            useLexiconEditor={useLexiconEditor}
            wordType={wordType}
            onAddCopiedVocabulary={this.onAddCopiedVocabulary}
          />
        )}
      </div>
    );
  }

  // --  RequestModal methods  --

  handleRequestWord = (value) => {
    this.setState({ newWord: value, isOpenRequestModal: true });
  };

  closeRequestModal = () => {
    this.setState({
      newWord: "",
      modalInputValue: "",
      modalEnglishInputValue: "",
      wordType: "",
      shouldPopulate: false,
      isOpenRequestModal: false,
      lexiconData: null,
      hypernymData: null,
    });
  };

  onWordTypeChange = (data) => {
    this.setState({ wordType: data?.value ?? "" });
  };

  onShouldPopulateChange = (value) => {
    this.setState({ shouldPopulate: value.target.checked });
  };

  onLexiconDataChange = (lexiconData) => {
    this.setState({ lexiconData: lexiconData });
  };

  onHypernymChange = (hypernymData) => {
    this.setState({ hypernymData: hypernymData });
  };

  requestNewWord = (translateType) => {
    const {
      token,
      textualAppName,
      language,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      alwaysTranslate,
      onDataChanged,
    } = this.props;
    const {
      newWord,
      wordType,
      shouldPopulate,
      modalInputValue,
      modalEnglishInputValue,
      lexiconData,
      hypernymData,
      spans,
      requestedSpanIndex,
    } = this.state;

    const [wordTagCategory, wordTagType] = wordType.split("/");

    let paramNewWord;
    if (lexiconData) {
      paramNewWord = {
        word: lexiconData.form_data.forms.lemma || newWord,
        lexiconData: lexiconData,
      };
    } else {
      paramNewWord = newWord;
    }
    const shouldTranslate = alwaysTranslate || translateType === "translate";
    const shouldMachineTranslate =
      !alwaysTranslate && translateType === "machine_translate";
    newRequestWord(
      paramNewWord,
      modalEnglishInputValue,
      wordTagCategory,
      wordTagType,
      shouldTranslate,
      shouldMachineTranslate,
      shouldPopulate,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      modalInputValue,
      language,
      token,
      textualAppName,
      (hypernymData || {}).vocabulary_id
    ).then((response) => {
      const data = response.data;
      if (data && data.value) {
        const newSpans = [...spans];
        newSpans[requestedSpanIndex] = {
          ...newSpans[requestedSpanIndex],
          requestable: false,
        };
        this.setState({ spans: newSpans });
        onDataChanged(this, newSpans);
        this.closeRequestModal();
      }
    });
  };

  onAddCopiedVocabulary = (data) => {
    if (data && data.value) {
      const { onDataChanged } = this.props;
      const { spans, requestedSpanIndex } = this.state;

      const newSpans = [...spans];
      newSpans[requestedSpanIndex] = {
        ...newSpans[requestedSpanIndex],
        requestable: false,
      };
      this.setState({ spans: newSpans });
      onDataChanged(this, newSpans);
      this.closeRequestModal();
    }
  };
}

export const AnnotationComp = connector(Annotation);
