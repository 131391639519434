import React from "react";
import { Table } from "semantic-ui-react";

import { HTML } from "../components/HTML";
import { ProductTextActivity } from "./ProductTextActivityModal";
import { formatDate } from "../utils/dateUtils";

type Props = {
  activity: ProductTextActivity;
};

export const ProductTextActivityTableRow: React.FC<Props> = ({ activity }) => (
  <Table.Row key={activity.id} data-testid={"product-text-activity-table-row"}>
    <Table.Cell>{activity.action}</Table.Cell>
    <Table.Cell>{formatDate(activity.created)}</Table.Cell>
    <Table.Cell>
      <HTML text={activity.username} />
    </Table.Cell>
    <Table.Cell>{activity.user_action_context}</Table.Cell>
  </Table.Row>
);
