import { Product } from "../products/product";

export type TaskId = string;

export enum ProductSearchAction {
  SEARCH = "search",
  SELECT = "select",
}

export interface ProductSearchResult {
  count: number;
  results: Product[];
  error?: boolean;
}
