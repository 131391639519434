import React from "react";
import {
  SectionActionRequest,
  TemplateFormInfo,
} from "../../api/sectionActions";
import { TemplateRow } from "./TemplateRow";
import { Draggable } from "react-beautiful-dnd";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";

type Props = {
  data: TemplateFormInfo;
  details: ProductDetailInfo | null;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  index: number;
  textualAppName: string;
};

export const TemplateSectionItem: React.FC<Props> = ({
  data,
  details,
  onSectionItemAction,
  index,
  textualAppName,
}) => (
  <Draggable draggableId={data.id} index={index}>
    {(provided): React.ReactElement => (
      <div
        className="template-item"
        ref={provided.innerRef.bind(provided)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TemplateRow
          details={details}
          form={data}
          dragHandleProps={provided.dragHandleProps}
          onSectionItemAction={onSectionItemAction}
          textualAppName={textualAppName}
        />
      </div>
    )}
  </Draggable>
);
