import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { ProductId } from "../../product";
import { createOrReplaceProductCategory } from "../../../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import { ProductCategorySelector } from "../../ProductCategorySelector";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import { ConfirmationModal } from "../../../ui/ConfirmationModal";
import { showMessage } from "../../../utils/partialUtils";

type Props = {
  productId: ProductId;
};

export const SaveAsCategoryModal: React.FC<Props> = ({ productId }) => {
  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownItemProps>(null);
  const [newOptions, setNewOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState(false);
  const [contentModalDescription, setContentModalDescription] = useState("");
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    let name: string = null;
    let productCategoryId: string = null;
    if (postData) {
      (async (): Promise<void> => {
        let selectedOptionObj = selectedOption;
        // When new category product is added, deselected and selected again it is one element array.
        if (selectedOptionObj.length) {
          selectedOptionObj = selectedOptionObj[0];
        }
        if (newOptions.includes(selectedOptionObj.text)) {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          name = selectedOptionObj.text.toString();
        } else {
          productCategoryId = selectedOptionObj.value.toString();
        }

        try {
          const response = await createOrReplaceProductCategory({
            token,
            productId,
            name,
            productCategoryId,
          });
          showMessage(NotificationAppearance.SUCCESS, response.data.message);
          setOpen(false);
          reset(true);
        } catch (error) {
          setOpen(false);
          reset(false);
        }
      })();
    }
    return;
  }, [postData]);

  const reset = (success: boolean): void => {
    setPostData(false);
    if (success) {
      setIsLoading(true);
      setTimeout(() => {
        setOpen(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  const onClick = (): void => {
    if (!selectedOption) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: "Fill in the category name to create the category",
        })
      );
    } else {
      let selectedOptionValue = selectedOption.text;
      // When new category product is added, deselected and selected again it is one element array.
      if (selectedOption.length) {
        selectedOptionValue = selectedOption[0].text;
      }
      if (newOptions.includes(selectedOptionValue)) {
        setPostData(true);
      } else {
        setOpenConfirmationModal(true);
      }
    }
  };

  const handleSelectedOption = (
    selectedOptionItem: DropdownItemProps
  ): void => {
    setSelectedOption(selectedOptionItem);
    if (selectedOptionItem && selectedOptionItem.length) {
      setContentModalDescription(
        `Are you sure you want to override the category "${selectedOptionItem[0].text}"`
      );
    }
  };
  const handleNewOptions = (newOptionValue: string): void => {
    setNewOptions((newOptions) => [...newOptions, newOptionValue]);
  };
  const handleOnConfirm = (onConfirm: boolean): void => {
    setPostData(onConfirm);
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    if (!open) {
      setSelectedOption(null);
    }
  }, [open]);

  if (!open) {
    return (
      <Button
        data-testid={"save-as-category-modal-trigger"}
        className={"pbutton pbutton-primary pbutton-inverse"}
        content={"Save as category"}
        onClick={(): void => setOpen(true)}
      />
    );
  } else
    return (
      <>
        <Modal
          data-testid={"save-as-category-modal"}
          size={"small"}
          open={open}
          onClose={(): void => setOpen(false)}
        >
          <Modal.Header>Save as category</Modal.Header>
          <Modal.Content>
            <p>
              A new template category will be created based on the fields and
              attributes of the current product
            </p>

            <ProductCategorySelector
              selectedOptionItem={handleSelectedOption}
              newOptionValue={handleNewOptions}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={isLoading}
              data-testid={"save-as-category-modal-close"}
              content={"Close"}
              onClick={(): void => setOpen(false)}
            />
            <Button
              loading={isLoading}
              data-testid={"save-as-category-modal-create"}
              color="red"
              content={"Create category"}
              onClick={onClick}
            />
          </Modal.Actions>
        </Modal>
        <ConfirmationModal
          headerDescription={"Overwriting existing category"}
          contentDescription={contentModalDescription}
          open={openConfirmationModal}
          onConfirm={handleOnConfirm}
        />
      </>
    );
};
