import React, { useMemo } from "react";
import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  useGetPrompts,
  useGetPromptGroups,
} from "../../../products/copy-assistant/customHooks";
import { Menu, Tab } from "semantic-ui-react";
import { ManagePromptGroupList } from "./ManagePromptGroupList";
import { ManagePromptList } from "./ManagePromptList";
import { ActiveTab, ActiveTabValue } from "./ManagePromptTabs";
import { useGetAuthQuery } from "../../../api/authApi";

export type CustomPane = {
  menuItem: JSX.Element;
  render: () => JSX.Element;
  tabKey: ActiveTabValue;
};
export const myPromptsPanes = (): CustomPane[] => {
  const { data: auth } = useGetAuthQuery();

  const { isLoading } = useGetCustomerQuery();
  const {
    data: { prompts },
    isFetching: isFetchingPrompts,
    reFetch: reFetchPrompts,
  } = useGetPrompts();

  const {
    data: groups,
    isFetching: isFetchingGroups,
    reFetch: reFetchGroups,
  } = useGetPromptGroups();

  const userIsStaff = useMemo(() => {
    if (!auth) return false;
    return auth.user.is_staff;
  }, [auth]);

  return [
    {
      menuItem: (
        <Menu.Item
          data-testid="copy-assistant-single-prompts-tab"
          key={ActiveTab.SINGLE}
        >
          Single prompts
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane loading={isLoading || isFetchingPrompts}>
          <ManagePromptList
            prompts={prompts || []}
            reFetchPrompts={reFetchPrompts}
            userIsStaff={userIsStaff}
          />
        </Tab.Pane>
      ),
      tabKey: ActiveTab.SINGLE,
    },
    {
      menuItem: (
        <Menu.Item
          data-testid="copy-assistant-prompt-groups-tab"
          key={ActiveTab.GROUPED}
        >
          Prompt groups
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane loading={isLoading || isFetchingPrompts || isFetchingGroups}>
          <ManagePromptGroupList
            prompts={prompts || []}
            groups={groups || []}
            reFetchGroups={reFetchGroups}
            reFetchPrompts={reFetchPrompts}
          />
        </Tab.Pane>
      ),
      tabKey: ActiveTab.GROUPED,
    },
    {
      menuItem: (
        <Menu.Item
          data-testid="copy-assistant-single-prompts-tab"
          key={ActiveTab.SYSTEM}
        >
          System prompts
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane loading={isLoading || isFetchingPrompts}>
          <ManagePromptList
            prompts={prompts || []}
            reFetchPrompts={reFetchPrompts}
            system
            userIsStaff={userIsStaff}
          />
        </Tab.Pane>
      ),
      tabKey: ActiveTab.SYSTEM,
    },
  ];
};

export const translationPromptsPane = (): CustomPane[] => {
  const { data: auth } = useGetAuthQuery();
  const { isLoading } = useGetCustomerQuery();
  const {
    data: { prompts },
    isFetching: isFetchingPrompts,
    reFetch: reFetchPrompts,
  } = useGetPrompts({ onlyTranslationPrompts: true });

  const userIsStaff = useMemo(() => {
    if (!auth) return false;
    return auth.user.is_staff;
  }, [auth]);
  return [
    {
      menuItem: (
        <Menu.Item
          data-testid="copy-assistant-single-prompts-tab"
          key={ActiveTab.SINGLE}
        >
          My Translation Prompts
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane loading={isLoading || isFetchingPrompts}>
          <ManagePromptList
            isTranslation
            prompts={prompts || []}
            reFetchPrompts={reFetchPrompts}
            userIsStaff={userIsStaff}
          />
        </Tab.Pane>
      ),
      tabKey: ActiveTab.SINGLE,
    },
    {
      menuItem: (
        <Menu.Item
          data-testid="copy-assistant-single-prompts-tab"
          key={ActiveTab.SYSTEM}
        >
          System Translation Prompts
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane loading={isLoading || isFetchingPrompts}>
          <ManagePromptList
            isTranslation
            prompts={prompts || []}
            reFetchPrompts={reFetchPrompts}
            system
            userIsStaff={userIsStaff}
          />
        </Tab.Pane>
      ),
      tabKey: ActiveTab.SYSTEM,
    },
  ];
};
