import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";

import styled from "styled-components";
import { CustomerChannel } from "./Customer";
import { Language } from "./customerlanguages";
import { MatrixCellType } from "./CustomerChannelLanguageMatrix";
import { ChannelLanguageSettingsType, FooterRowName } from "../api/manageApi";
import { CustomerChannelLanguageFooterRow } from "./CustomerChannelLanguageFooterRow";

export const StyledFooterNameCell = styled(Table.HeaderCell)`
  cursor: pointer !important;
  font-weight: 600 !important;
  padding-left: 23px !important;
  width: 100px !important;
`;

export type FooterMatrixType = {
  channel_details: MatrixCellType[];
  display_name: string;
  name: FooterRowName;
  radio_button: boolean;
};

export type FooterRows = {
  display_name: string;
  name: FooterRowName;
  radio_button: boolean;
};

export type FooterRowCellsUpdateType = {
  channel_ids: number[];
  checked: boolean;
  radio_button: boolean;
  row_name: string;
};

type Props = {
  customerChannels: CustomerChannel[];
  customerLanguages: Language[];
  disabled: boolean;
  fetchedChannelLanguageMatrix: ChannelLanguageSettingsType;
  footerRows: FooterRows[];
  onChangeChannelSettingsFooterRef?: (payload: FooterMatrixType[]) => void;
};
export const ChannelLanguageSettingsFooter: React.FC<Props> = ({
  customerChannels,
  customerLanguages,
  disabled,
  fetchedChannelLanguageMatrix,
  footerRows,
  onChangeChannelSettingsFooterRef = null,
}) => {
  const [channelFooterMatrix, setChannelFooterMatrix] = useState<
    FooterMatrixType[]
  >([]);

  const getDefaultFooterMatrix = (): FooterMatrixType[] => {
    const channelFooterMatrixArray = [] as FooterMatrixType[];
    footerRows.forEach((footerRow) => {
      const channelDetailsArray = [] as MatrixCellType[];
      customerChannels.forEach((channel) => {
        channelDetailsArray.push({
          channel_id: channel.id,
          selected: false,
          fetched_value: null,
        });
      });
      channelFooterMatrixArray.push({
        channel_details: channelDetailsArray,
        display_name: footerRow.display_name,
        name: footerRow.name,
        radio_button: footerRow.radio_button,
      });
    });
    return channelFooterMatrixArray;
  };

  const getFooterMatrix = (): FooterMatrixType[] => {
    const footerMatrix = [...getDefaultFooterMatrix()];

    for (const [key, footerRow] of Object.entries(
      fetchedChannelLanguageMatrix.channel_settings_footer
    )) {
      const matrixRow = footerMatrix.find((row) => row.name === key);
      footerRow.forEach(({ channel_id, value }) => {
        const matrixCell = matrixRow.channel_details.find(
          (cell) => cell.channel_id === channel_id
        );
        matrixCell.fetched_value = value;
        matrixCell.selected = value;
      });
    }

    return footerMatrix;
  };

  useEffect(() => {
    if (customerChannels.length && customerLanguages.length) {
      setChannelFooterMatrix(getFooterMatrix());
    }
  }, [customerChannels, customerLanguages]);

  useEffect(() => {
    onChangeChannelSettingsFooterRef?.(channelFooterMatrix);
  }, [channelFooterMatrix]);

  const updateFooterCells = ({
    channel_ids,
    checked,
    radio_button = false,
    row_name,
  }: FooterRowCellsUpdateType): void => {
    const channelFooterMatrixCopy = [...channelFooterMatrix];

    const currentFooterRows = channelFooterMatrixCopy.find(
      (channelFooterRow) => channelFooterRow.name === row_name
    );
    channel_ids.forEach((channelId) => {
      if (radio_button) {
        currentFooterRows.channel_details.forEach(
          (channelDetail) => (channelDetail.selected = false)
        );
      }
      const channelDetail = currentFooterRows.channel_details.find(
        (channelDetail) => channelDetail.channel_id === channelId
      );
      channelDetail.selected = checked;
    });
    setChannelFooterMatrix(channelFooterMatrixCopy);
  };

  return (
    <Table.Footer
      fullWidth
      data-testid={"customer-channel-language-footer-matrix"}
    >
      {channelFooterMatrix.map((channelFooterRow) => {
        return (
          <Table.Row key={channelFooterRow.name}>
            <StyledFooterNameCell data-testid={"footer-row-name-cell"}>
              {channelFooterRow.display_name}
            </StyledFooterNameCell>
            <CustomerChannelLanguageFooterRow
              channelFooterRow={channelFooterRow}
              disabled={disabled}
              updateFooterCells={updateFooterCells}
            />
          </Table.Row>
        );
      })}
    </Table.Footer>
  );
};
