import React, { useState } from "react";
import { Loader, Table } from "semantic-ui-react";
import styled from "styled-components";
import sortBy from "lodash/sortBy";
import { Column } from "./columns/Column";
import { Product, ProductId } from "../product";
import { SemanticWIDTHSNUMBER } from "semantic-ui-react/dist/commonjs/generic";
import { ProductTextsMapping } from "./ProductList";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { URLConfigParser } from "./ProductListURLConfig";
import { SelectionBannerAction } from "./selection-banner/ProductListSelectionBanner";

// FIXME: Move to Product List
const StyledTable = styled(Table)`
  margin-block: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  .select {
    white-space: nowrap;
    width: 10%;
  }
  th.text-column {
    white-space: normal !important;
  }
  & {
    td {
      padding: 0;
      &:not(.text-column) {
        width: 4em;
      }
      margin: 0;
      vertical-align: top;
      &.text-column > div > .html {
        line-height: 1.4em;
      }
      &.text-column > div > .html,
      .generated-text {
        padding: 0;
        font-size: small;
        * {
          margin-top: 0 !important;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: medium;
        }
        h1 {
          font-size: large;
        }
      }
    }
  }
`;

type State = {
  column: string;
  data?: Product[];
  direction: "ascending" | "descending";
};

type Action = {
  type: "CHANGE_SORT" | "REFRESH" | "DONT_SORT";
  column?: string;
  objects?: Product[];
};

function sortedColumnReducer(state: State, action: Action): State {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    case "REFRESH":
      return { ...state, data: action.objects };
    case "DONT_SORT":
      return {
        ...state,
        data: state.data,
        direction: state.direction,
      };
    default:
      throw new Error();
  }
}

function arraysEqual(a: Product[], b: Product[]): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export interface ProductListRenderContext {
  loadingProductTexts: boolean;
  onLinkOverlaySetProducts: (products: Product[]) => void;
  onProductListTextStatusesCellFlagToggled: (open: boolean) => void;
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: any
  ) => Promise<ProductText>;
  onSelectAllOnCurrentPage: (allRowSelected: boolean) => void;
  onSelectRowChange: any;
  onSelectLabel: (label: string) => void;
  products: Product[] | null;
  productTexts: ProductTextsMapping;
  selectedProducts: Set<ProductId>;
  urlConfig: URLConfigParser;
  onObjectSelectionAction: (action: SelectionBannerAction) => void;
  totalProducts: number;
  pageSize?: string;
}

const defaultCellRenderer = (columnSpec: Column): string => columnSpec.label;

type Props = {
  className: string;
  columns: Column[];
  objects: Product[];
  context: ProductListRenderContext;
};

export const ObjectTable: React.FC<Props> = ({
  children,
  className,
  columns,
  objects,
  context,
}) => {
  const [state, dispatch] = React.useReducer(sortedColumnReducer, {
    column: null,
    data: objects,
    direction: null,
  });
  const { column, data, direction } = state;
  const [prevObjects, setPrevObjects] = useState(objects);
  if (!arraysEqual(objects, prevObjects)) {
    dispatch({ type: "REFRESH", objects });
    setPrevObjects(objects);
  }

  const renderHeader = (): React.ReactElement[] => {
    return columns.map((columnSpec) => {
      if (columnSpec.hidden) {
        return null;
      }
      const cellRenderer = columnSpec.renderHeaderCell || defaultCellRenderer;
      const actionType =
        "sortable" in columnSpec && !columnSpec.sortable
          ? "DONT_SORT"
          : "CHANGE_SORT";
      return (
        <Table.HeaderCell
          sorted={column === columnSpec.name ? direction : null}
          onClick={(): void =>
            dispatch({ type: actionType, column: columnSpec.name })
          }
          key={columnSpec.name}
          width={columnSpec.width as SemanticWIDTHSNUMBER}
          className={columnSpec.className || columnSpec.name}
        >
          {cellRenderer(columnSpec, context)}
        </Table.HeaderCell>
      );
    });
  };

  const renderCells = (
    obj: Product,
    columns: Column[],
    parentIndex: number
  ): React.ReactElement => {
    return (
      <>
        {columns.map((columnSpec, index) => (
          <Table.Cell
            collapsing={columnSpec.collapsing}
            key={columnSpec.name}
            className={columnSpec.className || columnSpec.name}
            data-table-cell={`${parentIndex + 1}-${index + 1}`}
          >
            {columnSpec.renderCell(obj, columnSpec, context)}
          </Table.Cell>
        ))}
      </>
    );
  };

  const renderRow = (
    obj: Product,
    columns: Column[],
    index: number
  ): React.ReactElement => {
    return (
      <Table.Row key={obj.id} data-table-row={index + 1}>
        {renderCells(obj, columns, index)}
      </Table.Row>
    );
  };

  let rows;
  if (data.length === 0) {
    rows = (
      <Table.Row>
        <Table.Cell>
          <Loader active />
        </Table.Cell>
      </Table.Row>
    );
  } else {
    rows = data.map((obj, index) => renderRow(obj, columns, index));
  }

  return (
    <StyledTable
      sortable
      compact
      striped
      className={className}
      data-testid={"products-table"}
    >
      <Table.Header>
        <Table.Row>{renderHeader()}</Table.Row>
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
      {children && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>{children}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </StyledTable>
  );
};
