import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageCode } from "../customers/customerlanguages";
import { CustomerChannel } from "../customers/Customer";

export type CustomerLanguages = {
  language: LanguageCode;
};

export type customQueryInitialState = {
  customerChannels: CustomerChannel[];
  customerLanguages: CustomerLanguages[];
  preserveParams: { [key: string]: any };
  query: string;
};

const customQuerySlice = createSlice({
  name: "customQuery",
  initialState: {
    customerChannels: [],
    customerLanguages: [],
    preserveParams: null,
    query: "",
  } as customQueryInitialState,
  reducers: {
    setCustomQueryInitial: (
      state,
      {
        payload: { customerChannels, customerLanguages, preserveParams, query },
      }: PayloadAction<customQueryInitialState>
    ) => {
      state.customerChannels = customerChannels;
      state.customerLanguages = customerLanguages;
      state.preserveParams = preserveParams;
      state.query = query;
    },
    setQuery: (state, { payload: query }: PayloadAction<string>) => {
      state.query = query;
    },
  },
});

export const { setCustomQueryInitial, setQuery } = customQuerySlice.actions;

export default customQuerySlice.reducer;
