import React from "react";
import { SectionDetails } from "../../api/sectionActions";
import { Dropdown, DropdownProps } from "semantic-ui-react";

type Props = {
  sections: SectionDetails[];
  onAddSection: (name: SectionDetails) => void;
};

export const AddSection: React.FC<Props> = ({ onAddSection, sections }) => {
  return (
    <Dropdown
      placeholder={"Add section"}
      className={"button"}
      onChange={(
        event: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
      ): void => {
        const name = data.value as string;
        const section = sections.find((section) => section.name == name);
        if (section) {
          onAddSection(section);
        }
      }}
      options={sections.map((section) => ({
        key: section.id,
        text: section.display_name,
        value: section.name,
      }))}
    />
  );
};
