import api from "./api";
import { LanguageCode } from "../customers/customerlanguages";
import { ChannelId } from "../customers/Customer";
import { MatrixRowType } from "../customers/CustomerChannelLanguageMatrix";
import { FooterMatrixType } from "../customers/ChannelLanguageSettingsFooter";

export type FooterRowName = "main_default" | "show_in_preview";

export type ChannelLanguageMatrixCellFooterResponse = {
  channel_id: number;
  value: boolean;
};

export type ChannelLanguageMatrixItems = {
  channel_id: ChannelId;
  language_code: LanguageCode;
  default: boolean;
};

export type ChannelLanguageSettingsType = {
  channel_language_matrix: ChannelLanguageMatrixItems[];
  channel_settings_footer?: {
    [key in FooterRowName]: ChannelLanguageMatrixCellFooterResponse[];
  };
};

export async function getChannelLanguageSettings({
  token,
  showFooter = false,
}: {
  token: string;
  showFooter: boolean;
}): Promise<ChannelLanguageSettingsType> {
  const response = await api.post(
    `/api/_internal/manage/channel_language_settings/all`,
    { show_footer: showFooter },
    { headers: { token } }
  );
  return response.data;
}

export async function updateChannelLanguageSettings({
  token,
  channelLanguageMatrix,
  channelSettingsFooter,
}: {
  token: string;
  channelLanguageMatrix: MatrixRowType[];
  channelSettingsFooter?: FooterMatrixType[];
}): Promise<unknown> {
  const response = await api.post(
    `/api/_internal/manage/channel_language_settings/update`,
    {
      channel_language_matrix: channelLanguageMatrix,
      channel_settings_footer: channelSettingsFooter,
    },
    { headers: { token } }
  );
  return response.data;
}

export async function removeChannelLanguageSettings({
  token,
  channelId,
}: {
  token: string;
  channelId: number;
}): Promise<unknown> {
  const response = await api.post(
    `/api/_internal/manage/channel_language_settings/remove`,
    {
      customer_channel: channelId,
    },
    { headers: { token } }
  );
  return response.data;
}
