import React, { useLayoutEffect, useRef } from "react";
import { Button, Form, Ref, Segment, TextArea } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { preventOverflowOnTextarea } from "../utils";
type Props = {
  multiShots: string[];
  updateMultiShot: (index: number, value: string) => void;
  addMultiShot: () => void;
  removeMultiShot: () => void;
};
export const PromptMultiShotsField: React.FC<Props> = ({
  multiShots,
  updateMultiShot,
  addMultiShot,
  removeMultiShot,
}) => {
  const textareaRefs = useRef<HTMLTextAreaElement[]>([]);
  useLayoutEffect(() => {
    textareaRefs.current.forEach((textarea) => {
      if (textarea) {
        preventOverflowOnTextarea(textarea);
      }
    });
  }, []);

  return (
    <Form.Field data-testid="copy-assistant-template-form-prompts-wrapper">
      <label>Multi-shot</label>
      <Text color="grey" className="descriptive-helper-text">
        Generate your text using the iterative chat style by entering each
        instruction as a separate &quot;shot&quot; below.
        <br />
        The instruction is sent first, followed by the multi-shots in the order
        below.
      </Text>
      {multiShots.map((shot, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Form.Field key={index} as={Segment}>
          <Form.Field>
            <label>Shot {index + 1}</label>
            <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
              <TextArea
                data-testid={`copy-assistant-multi-shot-textarea-input-${
                  index + 1
                }`}
                style={{ whiteSpace: "pre-wrap" }}
                placeholder={`Shot ${index + 1}`}
                value={shot}
                onChange={(_, { value }): void => {
                  updateMultiShot(index, value as string);
                }}
              />
            </Ref>
          </Form.Field>
        </Form.Field>
      ))}
      <Button
        data-testid="copy-assistant-add-multi-shot"
        color="red"
        basic
        size="mini"
        icon="add"
        onClick={(): void => addMultiShot()}
      />

      <Button
        data-testid="copy-assistant-remove-multi-shot"
        color="red"
        basic
        size="mini"
        icon="minus"
        onClick={(): void => removeMultiShot()}
        disabled={!multiShots.length}
      />
    </Form.Field>
  );
};
