import React, { useState } from "react";
import { ProductId } from "../product";
import { ActionDataDetailModal } from "./ActionDataDetailModal";

type Props = {
  productId: ProductId;
};

export const PublishDetailDataModal: React.FC<Props> = ({ productId }) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <a
        className="item"
        data-testid={"publish-detail-data-modal-set-open-button"}
        onClick={(): void => setOpened(true)}
      >
        <i className="sitemap icon"></i>
        <span>Show Data</span>
      </a>
      {opened && (
        <ActionDataDetailModal
          onClose={(): void => setOpened(false)}
          open={opened}
          productId={productId}
        />
      )}
    </>
  );
};
