import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { HTML } from "./HTML";
import { RootState, store } from "../utils/store";
import {
  setDjangoToastClose,
  setDjangoToastTimeoutId,
  DjangoToastData,
} from "../api/djangoToastSlice";
import { useSelector } from "react-redux";
import { Icon } from "./tailwind";

export const DjangoToast: React.FC = () => {
  const data: DjangoToastData[] = useSelector(
    (state: RootState) => state.djangoToast
  );

  const mounted = useRef(true);
  const handleHide = (uuid: string): void => {
    store.dispatch(setDjangoToastClose({ toastToClose: uuid }));
  };

  useEffect(() => {
    mounted.current = true;
    if (data.length) {
      const timeoutArray: {
        timeoutId: any; // when clearing the timeout the type needs to be Timeout but when setting the timeout it returns type number.
        componentId: string;
      }[] = [];
      data.forEach(({ uuid, timeoutId }) => {
        if (!timeoutId) {
          timeoutArray.push({
            timeoutId: setTimeout(() => {
              handleHide(uuid);
            }, 10000),
            componentId: uuid,
          });
        }
      });
      if (mounted.current)
        store.dispatch(setDjangoToastTimeoutId({ timeoutArray }));
    }
    return (): void => {
      mounted.current = false;
    };
  }, [data]);

  const dialog = document.querySelector("dialog[open]");
  if (dialog) {
    return ReactDOM.createPortal(
      <div
        data-testid="toast-wrapper"
        className={`txu-toast-wrapper${data.length ? " txu-open" : ""}`}
      >
        {data?.map(({ appearance, content, additionalContent, uuid }) => (
          <div
            key={uuid}
            className={`txu-toast ${appearance} tw-animate-slideleft`}
            role="alert"
            data-testid={`django-toast-${content.replaceAll(" ", "_")}`}
          >
            <div className="txu-toast-content">
              <HTML text={content} />
              {additionalContent && (
                <HTML
                  text={additionalContent}
                  style={{ fontSize: "80%", marginTop: "5px" }}
                />
              )}
            </div>
            <Icon
              className="txu-close"
              data-balloon="Close"
              data-balloon-pos="down-right"
              onClick={(): void => handleHide(uuid)}
              data-testid={`close-toast-${content.replaceAll(" ", "_")}`}
              name="close"
            />
          </div>
        ))}
      </div>,
      dialog
    );
  }

  return (
    <div
      data-testid="toast-wrapper"
      className={`txu-toast-wrapper${data.length ? " txu-open" : ""}`}
    >
      {data?.map(({ appearance, content, additionalContent, uuid }) => (
        <div
          key={uuid}
          className={`txu-toast ${appearance} tw-animate-slideleft`}
          role="alert"
          data-testid={`django-toast-${content.replaceAll(" ", "_")}`}
        >
          <div className="txu-toast-content">
            <HTML text={content} />
            {additionalContent && (
              <HTML
                text={additionalContent}
                style={{ fontSize: "80%", marginTop: "5px" }}
              />
            )}
          </div>
          <Icon
            className="txu-close"
            data-balloon="Close"
            data-balloon-pos="down-right"
            onClick={(): void => handleHide(uuid)}
            data-testid={`close-toast-${content.replaceAll(" ", "_")}`}
            name="close"
          />
        </div>
      ))}
    </div>
  );
};
