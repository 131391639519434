import React, { useState } from "react";
import { Button, Divider, Form } from "semantic-ui-react";

const FormInput: React.FC = () => (
  <Form.Group widths="equal">
    <Form.Field>
      <input placeholder="e.g. Hello!" name="sources" />
    </Form.Field>
    <Form.Field>
      <input placeholder="e.g. Guten Tag!" name="targets" />
    </Form.Field>
  </Form.Group>
);
/**
 * This component is only used in templates/manage/glossaries/detail.html
 *
 * It serves that view with empty form inputs.
 *
 * The form in the view is responsible for sending a form request.
 */
export const EmptyFormInputs: React.FC = () => {
  const [amountOfInputs, setAmountOfInputs] = useState(1);
  return (
    <>
      {Array.from({ length: amountOfInputs }, (_, i) => i).map((number) => (
        <FormInput key={number} />
      ))}
      <Divider />
      <Button
        size="small"
        type="button"
        content="Add"
        color="red"
        floated="right"
        basic
        onClick={(): void => setAmountOfInputs((prev) => prev + 1)}
      />
    </>
  );
};
