import React from "react";
import { Divider, Header, List } from "semantic-ui-react";
import styled from "styled-components";
import { SectionActionRequest } from "../../api/sectionActions";
import { SectionSearchItem } from "./ProductDetailEnrichTab";
import { EnrichTabSearchResultItems } from "./EnrichTabSearchResultItems";

const StyledList = styled(List)`
  & {
    background: #ffffff;
    border-radius: 4px;
    overflow-y: scroll;
    padding: 0;
  }
`;

type Props = {
  height: number;
  label: string;
  loading: boolean;
  onSectionItemAction?: (request: SectionActionRequest) => void;
  searchResults: SectionSearchItem[];
  showIcons: boolean;
};

export const EnrichTabSearchResultBox: React.FC<Props> = ({
  height,
  label,
  loading,
  onSectionItemAction,
  searchResults,
  showIcons,
}) => (
  <>
    <Divider horizontal>
      <Header as={"h5"}>{label}</Header>
    </Divider>
    <StyledList
      selection
      style={{ height: `${height}em` }}
      verticalAlign="middle"
    >
      <EnrichTabSearchResultItems
        loading={loading}
        onSectionItemAction={onSectionItemAction}
        searchResults={searchResults}
        showIcons={showIcons}
      />
    </StyledList>
  </>
);
