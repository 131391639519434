import React from "react";
import styled from "styled-components";

export type Vocabulary = {
  // 51
  id: number;
  // dress
  name: string;
  // dress_51
  tag_value: string;
  main_category?: boolean;
};

type Props = {
  indented: boolean;
  onClick?: () => void;
  name: string;
  isMainCategory?: boolean;
};

interface VocabularySpanProps {
  readonly indented: boolean;
  readonly isMainCategory: boolean;
}

// Make vocabulary links look like normal links
const VocabularySpan = styled.span<VocabularySpanProps>`
  ${({ indented }): string =>
    indented
      ? `
    &:hover {
      color: black;
      cursor: pointer;
      text-decoration: underline;
    }
  `
      : ""}
  & {
    padding-left: ${(props: VocabularySpanProps): string =>
      props.indented ? "32px !important" : ""};
  }
  & {
    font-weight: ${(props: VocabularySpanProps): string =>
      props.isMainCategory ? "bold" : "normal"};
  }
`;

export const VocabularyLink: React.FC<Props> = ({
  indented,
  onClick,
  name,
  isMainCategory,
}) => (
  <VocabularySpan
    onClick={onClick}
    indented={indented}
    isMainCategory={isMainCategory}
  >
    {name}
  </VocabularySpan>
);

VocabularyLink.defaultProps = {
  indented: false,
  isMainCategory: false,
};
