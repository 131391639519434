import React from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { ProductText } from "../../producttext/ProductText";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Text } from "../../components/Text";

const StyledButton = styled(Button)`
  width: auto;
`;

const StyledModal = styled(Modal)`
  && {
    width: 45% !important;

    & .scrolling.content {
      max-height: calc(65vh - 10em) !important;
      height: calc(65vh - 10em);
    }
    & .description {
      height: 100%;
    }
    & .ui.grid {
      height: 90%;
    }
  }
`;

type Props = {
  open: boolean;
  translatedTexts: ProductText[];
  untranslatedTexts: ProductText[];
  onClose: () => void;
  onPublish: () => void;
};

export const UntranslatedTextsModal: React.FC<Props> = ({
  open,
  onClose,
  onPublish,
  translatedTexts,
  untranslatedTexts,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return null;
  }
  const untranslatedTextsLength = untranslatedTexts.length;
  const translatedTextsLength = translatedTexts.length;
  let translatedTextsLabel, untranslatedTextsLabel;

  if (translatedTextsLength === 1) {
    translatedTextsLabel = (
      <p>
        This 1 text is fully translated and will be <strong>published</strong>
      </p>
    );
  } else if (translatedTextsLength > 1) {
    translatedTextsLabel = (
      <p>
        These {translatedTextsLength} texts are fully translated and will be{" "}
        <strong>published</strong>
      </p>
    );
  }

  if (untranslatedTextsLength === 1) {
    untranslatedTextsLabel = "There is 1 text containing untranslated words";
  } else if (untranslatedTextsLength > 1) {
    untranslatedTextsLabel = `There are ${untranslatedTextsLength} texts containing untranslated words`;
  }

  const renderChannelsAndLanguages = (
    texts: ProductText[]
  ): React.ReactElement => {
    const listItems = texts.map((text) => {
      const channelObject = customer.getChannelById(text.customerChannelId);
      const languageObject = customer.getLanguageByCode(text.languageCode);
      return (
        <li key={text.id || `${text.customerChannelId}-${text.languageCode}`}>
          {languageObject.short_name} - {channelObject.display_name}
        </li>
      );
    });
    return <ul>{listItems}</ul>;
  };

  return (
    <StyledModal open={open} data-testid={"untranslated-texts-modal"}>
      <Modal.Header>
        There are some texts containing untranslated words
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                {translatedTextsLabel && (
                  <>
                    <h3 data-testid={"translated-texts-label"}>
                      {translatedTextsLabel}
                    </h3>
                    {renderChannelsAndLanguages(translatedTexts)}
                    <hr />
                  </>
                )}
                {untranslatedTextsLabel && (
                  <>
                    <h3 data-testid={"untranslated-texts-label"}>
                      {untranslatedTextsLabel}
                    </h3>
                    {renderChannelsAndLanguages(untranslatedTexts)}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Text textAlignment="left" color="grey">
          If you press &quot;Publish completely translated texts&quot;, only the
          texts that are completely translated will be published, and we will
          mark the rest with &quot;waiting to be published&quot; They will get
          published once they are all translated.
        </Text>
        <Button onClick={(): void => onClose()}>Not now</Button>
        <StyledButton
          content="Publish completely translated texts"
          className="pbutton pbutton-primary"
          onClick={(): void => onPublish()}
        />
      </Modal.Actions>
    </StyledModal>
  );
};
