import React from "react";

const spaceBar = 32;

export const onKeyUp = (e: React.KeyboardEvent): void => {
  if (e.keyCode === spaceBar) {
    (e.target as HTMLInputElement).value =
      (e.target as HTMLInputElement).value + " ";
    e.stopPropagation();
  }
};
