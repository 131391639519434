import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { CustomerChannel } from "./Customer";
import { Language } from "./customerlanguages";
import {
  MatrixCellsUpdateType,
  MatrixRowType,
} from "./CustomerChannelLanguageMatrix";
import { Flag, FlagSpanShortName } from "../products/LanguageSelectorItem";
import { CustomerChannelLanguageMatrixTableRow } from "./CustomerChannelLanguageMatrixTableRow";

const StyledRowNameCell = styled(Table.Cell)`
  cursor: pointer !important;
  padding-left: 40px !important;
  width: 100px !important;
`;

type Props = {
  channelLanguageMatrix: MatrixRowType[];
  customerChannels: CustomerChannel[];
  customerLanguages: Language[];
  disabled: boolean;
  updateChannelLanguageCells?: ({
    channel_ids,
    checked,
    language_codes,
  }: MatrixCellsUpdateType) => void;
};

export const CustomerChannelLanguageMatrixTableBody: React.FC<Props> = ({
  channelLanguageMatrix,
  customerChannels,
  customerLanguages,
  disabled,
  updateChannelLanguageCells,
}) => {
  return (
    <Table.Body data-testid={"customer-channel-language-matrix-table-body"}>
      {customerLanguages.map((language: Language) => (
        <Table.Row key={language.code}>
          <StyledRowNameCell
            data-testid={"language-cell"}
            onClick={(): void =>
              updateChannelLanguageCells({
                channel_ids: customerChannels.map((channel) => channel.id),
                checked: true,
                language_codes: [language.code],
              })
            }
          >
            <Flag className="flag">{language.flag}</Flag>
            <FlagSpanShortName className="label">
              {language.short_name}
            </FlagSpanShortName>
          </StyledRowNameCell>
          <CustomerChannelLanguageMatrixTableRow
            channelLanguageMatrix={channelLanguageMatrix}
            currentLanguage={language}
            disabled={disabled}
            updateChannelLanguageCells={updateChannelLanguageCells}
          />
        </Table.Row>
      ))}
    </Table.Body>
  );
};
