import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { ProductText } from "./ProductText";
import { fetchProductTextActivity } from "../api/action";
import { ProductTextActivityTableRow } from "./ProductTextActivityTableRow";
import { UserActionContext } from "../products/publish/actionTypes";
import { ProductTextActivityTableHeader } from "./ProductTextActivityTableHeader";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";

const StyledModal = styled(Modal)`
  && {
    & .scrolling.content {
      max-height: calc(95vh - 10em) !important;
      height: calc(95vh - 10em);
    }
    & .description {
      height: 100%;
    }
  }
`;
// If changed, please remember to update the types on backend side in ProductTextActivity model.
export enum ProductTextActivityAction {
  APPROVE = "approve",
  COMMENT = "comment",
  GENERATE = "generate",
  NEEDS_REGENERATION = "needs-regeneration",
  MANUAL_EDIT = "manual-edit",
  PUBLISH = "publish",
  REVERT_MANUAL_EDIT = "revert-edited-data",
  UNAPPROVE = "unapprove",
  WAITING_TO_BE_PUBLISHED = "waiting-to-be-published",
}

export type ProductTextActivityData = {
  approved?: Date | null;
  comment?: string | null;
  data?: string | null;
  edited_data?: string | null;
  error?: string | null;
  failed?: boolean;
  first_published?: Date | null;
  generation_event_id?: string | null;
  git_revision?: string | null;
  missing_translation?: boolean;
  needs_review?: boolean;
  needs_regeneration?: boolean;
  published?: Date | null;
  waiting_to_be_published?: boolean;
};

export type ProductTextActivity = {
  action: ProductTextActivityAction;
  changes: ProductTextActivityData;
  created: Date;
  id: number;
  revert_changes: ProductTextActivityData;
  username: string;
  user_action_context: UserActionContext;
};

type Props = {
  onClose: () => void;
  open: boolean;
  productText: ProductText;
};

export const ProductTextActivityModal: React.FC<Props> = ({
  onClose,
  open,
  productText,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [productTextHistory, setProductTextHistory] = useState(
    [] as ProductTextActivity[]
  );
  const mounted = useRef(true);

  useEffect(() => {
    (async (): Promise<void> => {
      mounted.current = true;
      if (mounted.current && open) {
        setProductTextHistory(
          await fetchProductTextActivity({
            productTextId: productText.id,
            token: token,
          })
        );
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, [open]);

  return (
    <StyledModal open={open} data-testid={"product-text-activity-modal"}>
      <Modal.Header>Product Text Activity</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <table className="txu-table txu-compact">
            <ProductTextActivityTableHeader />
            <tbody>
              {productTextHistory.map((activity: ProductTextActivity) => (
                <ProductTextActivityTableRow
                  activity={activity}
                  key={activity.id}
                />
              ))}
            </tbody>
          </table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Close"
          onClick={onClose}
          data-testid={"product-text-activity-modal-close-button"}
        />
      </Modal.Actions>
    </StyledModal>
  );
};
