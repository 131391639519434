import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Divider,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";

import {
  PromptInputField,
  PromptSelectField,
  PromptTagsField,
  PromptTextareaField,
  PromptBooleanField,
  PromptLangStringSelectorField,
} from "./PromptFormInputs/InputFields";
import {
  CreatePrompt,
  FurtherInstructionsGpt3,
  PROMPT_WITH_NO_TAG_KEY,
  Prompt,
  PromptFormMode,
  PromptConfigOverwrite,
} from "./types";

import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { useGetCustomerQuery } from "../../api/customerApi";
import { LanguageCode } from "../../customers/customerlanguages";
import { getCustomerEnglishLanguage } from "./utils";
import { PromptSuggestionsComponent } from "./PromptSuggestionsComponent";
import {
  useGetModels,
  useGetCustomerLangStringTemplates,
  useGetOpenAIAssistants,
} from "./customHooks";
import { createPrompt, updatePrompt, deletePrompt } from "../../api/gptApi";
import { useGetFieldsets } from "../manage/fieldsets/customhooks";
import { Text } from "../../components/Text";
import { useGetDocumentSections } from "../../planner/document-structure/manage/customhooks";
import { getFeatureFlag } from "../../utils/featureFlags";
import { createHref } from "../../utils/hrefUtils";
import { PromptSelectFieldWithHeaders } from "./PromptFormInputs/PromptSelectFieldWithHeaders";
import { PromptFormPaneForm } from "./PromptFormPaneForm";
import { PromptFormAdvancedTab } from "./PromptFormAdvancedTab";
import { Tab } from "../../components/Tab";

export type CustomerModelOption = {
  value: string;
  text: string;
  key: string;
  description: string;
};

export type CustomerLanguageOption = {
  value: LanguageCode;
  text: string;
  key: LanguageCode;
};

export type Props = {
  mode: PromptFormMode;
  availableTags?: string[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  refreshPromptList: () => Promise<void>;
  selectPrompt?: (prompt: Prompt) => void;
  prompt?: Partial<Prompt>;
  userIsStaff?: boolean;
  systemTags?: string[];
};

export const PromptForm: React.FC<Props> = ({
  mode,
  availableTags,
  setOpenModal,
  refreshPromptList,
  selectPrompt,
  prompt,
  userIsStaff,
  systemTags,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const { data: models, isFetching: isFetchingModels } = useGetModels();
  const {
    data: sections,
    isFetching: isFetchingSections,
  } = useGetDocumentSections();

  const {
    data: langStringTemplates,
    isFetching: isFetchingLangStringTemplates,
  } = useGetCustomerLangStringTemplates();

  const {
    data: { fieldsets },
    isFetching: isFetchingFieldsets,
  } = useGetFieldsets();

  const {
    data: assistants,
    isFetching: isFetchingAssistants,
  } = useGetOpenAIAssistants();

  const [createSystemPrompt, setCreateSystemPrompt] = useState(false);

  useEffect(() => {
    if (
      userIsStaff &&
      Object.keys(prompt || {}).includes("customer_id") &&
      !prompt?.customer_id
    ) {
      setCreateSystemPrompt(true);
    }
  }, [prompt, userIsStaff]);

  const isFetching = useMemo(
    () => isFetchingModels || isFetchingLangStringTemplates,
    [isFetchingModels, isFetchingLangStringTemplates, isFetchingSections]
  );

  const FFHideSettingsPromptForm = useMemo(() => {
    return getFeatureFlag(customer, "hide_settings_prompt_form");
  }, [customer]);

  const defaultDocumentSection = useMemo(() => {
    if (!customer) return;
    return sections.find(
      (section) => section.id === customer.config.default_document_section
    );
  }, [customer, sections]);

  // LANGUAGE SELECTION
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
    prompt?.language
  );

  // CHANNEL SELECTION
  const [selectedChannel, setSelectedChannel] = useState<number>(
    prompt?.channel_id
  );

  // FIELDSET SELECTION
  const [selectedFieldset, setSelectedFieldset] = useState<number>(
    prompt?.fieldset_id
  );

  // DOCUMENT SECTION SELECTION
  const [selectedDocumentSection, setSelectedDocumentSection] = useState<
    number
  >(prompt?.document_section_id);

  const selectedSectionIsRuleBased = useMemo(() => {
    if (!sections) return false;
    const selectedSection = sections.find(
      ({ id }) => id === selectedDocumentSection
    );
    if (!selectedSection) return false;
    return selectedSection.template_label_ids?.length > 0;
  }, [selectedDocumentSection, sections]);

  // DROPDOWNS OPTIONS
  const sectionOptions = useMemo(() => {
    if (!sections) return [];
    return sections
      .sort(({ template_label_ids }) =>
        template_label_ids?.length > 0 ? 1 : -1
      )
      .map(({ id, name, template_label_ids }) => ({
        value: id,
        text: (
          <>
            {template_label_ids?.length > 0 && (
              <Popup
                trigger={<Icon name="info circle" />}
                content="This Section is rule-based."
                size="small"
                wide="very"
              />
            )}{" "}
            {name}
          </>
        ),
        key: id,
        disabled: template_label_ids?.length > 0,
      }));
  }, [sections]);

  const customerModelOptions = useMemo(() => {
    if (!models) return [];
    return models.map(({ display_name, model_name, supports_images }) => ({
      value: model_name,
      text: display_name,
      key: model_name,
      description: supports_images ? "Supports image processing" : undefined,
    }));
  }, [models]);

  const customerLanguageOptions = useMemo((): CustomerLanguageOption[] => {
    if (customer) {
      if (!selectedLanguage) {
        const language =
          getCustomerEnglishLanguage(customer)?.code ||
          customer.config.tag_input_language;
        setSelectedLanguage(language);
      }
      return customer.languages.map(({ code, short_name }) => ({
        value: code,
        text: short_name,
        key: code,
      }));
    }
    return [];
  }, [customer]);

  const customerChannelOptions = useMemo(() => {
    if (customer) {
      return customer.channels.map(({ id, display_name }) => ({
        value: id,
        text: display_name,
        key: id,
      }));
    }
  }, [customer]);

  const fieldsetOptions = useMemo(() => {
    if (isFetchingFieldsets) return [];
    const defaultFieldset = fieldsets.find(
      (fieldset) => fieldset.use_as_default
    );
    const options = fieldsets.map(({ id, name }) => ({
      value: id,
      text: name,
      key: id,
    }));
    if (!defaultFieldset) {
      options.unshift({
        value: 0,
        key: 0,
        text: "Default",
      });
    }
    return options;
  }, [isFetchingFieldsets]);

  const assistantOptions = useMemo(() => {
    if (isFetchingAssistants) return [];

    return assistants.map(({ id, name }) => ({
      value: id,
      text: name,
      key: id,
    }));
  }, [isFetchingAssistants, assistants]);

  const [loadingForm, setLoadingForm] = useState(false);

  // NAME
  const [promptName, setPromptName] = useState(prompt?.name || "");

  // INSTRUCTION
  const [instructionState, setInstruction] = useState(
    prompt?.instruction || ""
  );

  // MODEL
  const DEFAULT_MODEL_NAME = "gpt-4";

  const [selectedModelName, setSelectedModelName] = useState(
    prompt?.assistant_id ? undefined : prompt?.model_name ?? DEFAULT_MODEL_NAME
  );

  // ASSISTANT
  const [selectedAssistant, setSelectedAssistant] = useState<number>(
    prompt?.assistant_id
  );

  // HTML
  const [clearHtmlTagsState, setClearHtmlTags] = useState(
    !prompt?.keep_html || false
  );

  // DOCUMENT IMAGE
  const [includeDocumentsImage, setIncludeDocumentsImage] = useState(
    prompt?.include_documents_image || false
  );

  // SELECTED TAGS
  const [selectedTags, setSelectedTags] = useState<string[]>(
    prompt?.tags || []
  );
  const [allTags, setAllTags] = useState<DropdownItemProps[]>(
    (
      availableTags?.filter((tag) => tag !== PROMPT_WITH_NO_TAG_KEY) || []
    ).map((tag) => ({ text: tag, value: tag, key: tag }))
  );

  useEffect(() => {
    if (userIsStaff && createSystemPrompt) {
      const tags = (
        systemTags?.filter((tag) => tag !== PROMPT_WITH_NO_TAG_KEY) || []
      ).map((tag) => ({
        text: tag,
        value: tag,
        key: tag,
      }));
      setAllTags(tags);
    } else {
      const tags = (
        availableTags?.filter((tag) => tag !== PROMPT_WITH_NO_TAG_KEY) || []
      ).map((tag) => ({ text: tag, value: tag, key: tag }));

      setAllTags(tags);
    }
  }, [availableTags, systemTags, userIsStaff, createSystemPrompt]);
  const [
    selectedLangStingTemplateId,
    setSelectedLangStringTemplateId,
  ] = useState<number>(prompt?.template_vocabulary_id || undefined);

  const [
    activeLangStringTemplateLabelsState,
    setActiveLangStringTemplateLabels,
  ] = useState<number[]>(prompt?.template_labels || []);

  const selectedLangStringTemplate = useMemo(() => {
    if (isFetching) return;
    const template = langStringTemplates.find(
      ({ template_id }) => template_id === selectedLangStingTemplateId
    );
    if (template && !activeLangStringTemplateLabelsState.length) {
      const activeLabels = template.labels.filter((label) => label.default);
      setActiveLangStringTemplateLabels(
        activeLabels.map((label) => label.label_id)
      );
    }
    return template;
  }, [
    selectedLangStingTemplateId,
    prompt?.template_vocabulary_id,
    langStringTemplates,
    isFetching,
  ]);

  const langStringTemplateOptions = useMemo(() => {
    if (isFetching) return [];
    return langStringTemplates.map(({ template_id, name }) => ({
      value: template_id,
      text: name,
      key: template_id,
    }));
  }, [langStringTemplates, isFetching]);

  useEffect(() => {
    if (mode === PromptFormMode.COPY) {
      setPromptName(`${promptName ?? prompt?.name}-copy`);
    }
  }, [mode]);

  const handleClickLangStringTemplateLabel = (id: number): void => {
    let copyActiveLangStringTemplateLabels = [
      ...activeLangStringTemplateLabelsState,
    ];
    const index = copyActiveLangStringTemplateLabels.findIndex(
      (label_id) => label_id === id
    );
    if (index > -1) {
      copyActiveLangStringTemplateLabels = copyActiveLangStringTemplateLabels.filter(
        (label_id) => id !== label_id
      );
    } else {
      copyActiveLangStringTemplateLabels.push(id);
    }
    setActiveLangStringTemplateLabels(copyActiveLangStringTemplateLabels);
  };

  const isSaveEnable = (): boolean => {
    return !(promptName && instructionState);
  };

  const handleAddTag = (tag: string): void => {
    const selectedTagsCopy = new Set([...selectedTags]);
    const allTagsCopy = new Set([...allTags]);
    selectedTagsCopy.add(tag);
    allTagsCopy.add({ text: tag, value: tag, key: tag });
    setSelectedTags(Array.from(selectedTagsCopy));
    setAllTags(Array.from(allTagsCopy));
  };

  const [advancedParameters, setAdvancedParameters] = useState<{
    further_instructions: string[] | FurtherInstructionsGpt3[];
    config_overwrite: PromptConfigOverwrite;
  }>({
    further_instructions: prompt?.further_instructions || [],
    config_overwrite: prompt?.config_overwrite || {},
  });
  const collectAdvancedParameters = (
    parameters: Partial<typeof advancedParameters>
  ): void => {
    setAdvancedParameters((prev) => ({
      ...prev,
      ...parameters,
    }));
  };

  const collectPromptData = (): CreatePrompt => {
    const isCreatingSystemPrompt = createSystemPrompt && userIsStaff;
    const newPrompt: CreatePrompt = {
      instruction: instructionState,
      name: promptName,
      tags: selectedTags,
      keep_html: !clearHtmlTagsState, // This is flipped because the checkbox is "Clear HTML tags". So we want to keep_html to be false if the checkbox is checked.
      model_name: selectedModelName,
      language: selectedLanguage,
      template_vocabulary_id: isCreatingSystemPrompt
        ? undefined
        : selectedLangStingTemplateId || undefined,
      template_labels: isCreatingSystemPrompt
        ? []
        : activeLangStringTemplateLabelsState,
      channel_id: isCreatingSystemPrompt ? undefined : selectedChannel,
      fieldset_id: isCreatingSystemPrompt ? undefined : selectedFieldset,
      document_section_id: selectedDocumentSection,
      assistant_id: selectedAssistant,
      include_documents_image: includeDocumentsImage,
      ...advancedParameters,
    };

    return newPrompt;
  };
  const onCreatePrompt = async (): Promise<Prompt> => {
    const newPrompt = collectPromptData();
    return await createPrompt(
      token,
      newPrompt,
      createSystemPrompt && userIsStaff
    );
  };

  const onUpdatePrompt = async (): Promise<Prompt> => {
    const newPrompt = (collectPromptData() as unknown) as Prompt;
    newPrompt.id = prompt.id;
    return updatePrompt(token, newPrompt, createSystemPrompt && userIsStaff);
  };
  const onDeletePrompt = async (): Promise<void> => {
    setLoadingForm(true);
    await deletePrompt(token, prompt.id, createSystemPrompt && userIsStaff);
    setLoadingForm(false);
    setOpenModal(false);
    await refreshPromptList(); // causes component to unmount
  };
  const onSave = async (): Promise<void> => {
    setLoadingForm(true);
    let newPrompt: Prompt;
    if (mode === PromptFormMode.UPDATE) {
      newPrompt = await onUpdatePrompt();
    } else {
      newPrompt = await onCreatePrompt();
    }
    if (newPrompt) {
      selectPrompt?.(newPrompt);
    }
    setLoadingForm(false);
    setOpenModal(false);
    await refreshPromptList(); // causes component to unmount
  };

  const onAddSuggestion = (text: string): void => {
    let suggestion = text;
    if (instructionState) {
      suggestion = `\n${text}`;
    }
    setInstruction(instructionState + suggestion);
  };

  const collectModelsAndAssistantOptions = (): {
    [key: string]: DropdownItemProps[];
  } => {
    return {
      Models: customerModelOptions,
      "Assistants (Beta)": assistantOptions,
    };
  };

  const handleSelectFromDropdownWithHeader = (
    value: number | string,
    key: string
  ): void => {
    if (key === "Models") {
      setSelectedModelName(value as string);
      setSelectedAssistant(undefined);
    } else if (key === "Assistants (Beta)") {
      setSelectedAssistant(value as number);
      setSelectedModelName(undefined);
    }
  };

  const findSelectedText = (): string => {
    if (selectedModelName) {
      return customerModelOptions.find(
        ({ value }) => value === selectedModelName
      )?.text;
    } else if (selectedAssistant) {
      return assistantOptions.find(({ value }) => value === selectedAssistant)
        ?.text;
    }
    return undefined;
  };

  const showInputAndOutput = useMemo(() => {
    return !createSystemPrompt;
  }, [createSystemPrompt]);

  const loading =
    loadingForm || isLoading || isFetchingFieldsets || isFetchingAssistants;
  const panes = [
    {
      menuItem: {
        key: "settings",
        heading: "Settings",
      },
      content: (
        <>
          <PromptFormPaneForm
            isLoading={loading}
            testId="prompt-form-basic-settings"
          >
            {userIsStaff && (
              <Form.Field>
                <Text size="small" color="grey">
                  This setting is never shown to users that are not staff.
                </Text>
                <Checkbox
                  disabled={mode === PromptFormMode.UPDATE}
                  label="System Prompt"
                  checked={createSystemPrompt}
                  onChange={(_, { checked }): void =>
                    setCreateSystemPrompt(checked)
                  }
                />
                {mode === PromptFormMode.UPDATE && (
                  <Text size="small" color="grey">
                    <Icon name="warning circle" color="red" />
                    This Prompt already exists and can&apos;t be changed between
                    System Prompt and Customer Prompt. If you wish to convert
                    this Prompt to the other type consider copying it instead.
                    <br /> The checkbox shows if it&apos;s a System Prompt or
                    not.
                    <br />
                    If the checkbox is checked when it&apos;s not supposed to
                    please inform any developer about this.
                  </Text>
                )}
                <Divider />
              </Form.Field>
            )}
            <PromptInputField
              testId="copy-assistant-template-form-name-input"
              label={"Name"}
              placeholder={"Name your prompt..."}
              value={promptName}
              setValue={setPromptName}
            />
            <Divider />
            <PromptTextareaField
              testId="copy-assistant-template-form-instruction-input"
              caption="How do you want the text to be processed?"
              label={"Instruction"}
              placeholder={"Describe a product using the input text..."}
              value={instructionState}
              setValue={setInstruction}
            />
            <PromptSuggestionsComponent
              onAddSuggestion={onAddSuggestion}
              language={selectedLanguage}
            />
            <PromptSelectField
              caption="Refers to the language field where the text is saved in Textual."
              dropdownOptions={customerLanguageOptions}
              label={"Language"}
              placeholder={"Select what language to use"}
              testId="copy-assistant-template-form-select-language"
              value={selectedLanguage}
              setValue={setSelectedLanguage}
            />
            {assistantOptions.length > 0 ? (
              <PromptSelectFieldWithHeaders
                dropdownOptionsWithHeaders={collectModelsAndAssistantOptions()}
                caption="Choose AI model or Assistant (Beta). Note that different models vary in quality, speed, and cost."
                label="Model or Assistant"
                placeholder="Select AI Model or Assistant"
                testId="copy-assistant-template-form-select-gpt-model-or-assistant"
                value={selectedModelName || selectedAssistant}
                selectedText={findSelectedText()}
                setValue={handleSelectFromDropdownWithHeader}
                linkUrl={createHref("assistants", customer)}
                linkText="Manage Assistants…"
              />
            ) : (
              <PromptSelectField
                caption="Choose AI model. Note that different models vary in quality, speed, and cost."
                dropdownOptions={customerModelOptions}
                label={"Model"}
                placeholder={"Select AI Model"}
                testId="copy-assistant-template-form-select-gpt-model"
                value={selectedModelName}
                setValue={setSelectedModelName}
              />
            )}
            <Divider />
            <PromptTagsField
              dropdownOptions={allTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              handleAddTag={handleAddTag}
            />
            <Divider />
            <Form.Field>
              <label style={{ display: "flex" }}>
                Prompt Selection Rules
                <a
                  href={createHref("promptSelectionRules", customer)}
                  data-subpage=""
                  style={{ marginLeft: "auto" }}
                >
                  Prompt Selection Rules...
                </a>
              </label>
              <Text color="grey" className="descriptive-helper-text">
                Set conditions for when this prompt is automatically selected.
              </Text>
            </Form.Field>
          </PromptFormPaneForm>
        </>
      ),
    },
    {
      menuItem: {
        key: "input-and-output",
        heading: "Input / Output Settings",
      },
      content: (
        <>
          {!showInputAndOutput ? (
            <Text color="grey">
              Input and output settings disabled because this is a system
              prompt.
            </Text>
          ) : (
            <PromptFormPaneForm
              isLoading={loading}
              testId="prompt-form-input-and-output-settings"
            >
              <Header>Input Data</Header>
              <Text size="small" color="grey">
                Fetch data automatically from a preset Input Schema.
                <br /> The prompt data tells the AI what to write about. Here
                you can configure to fetch the data from data imported via file
                or API.
              </Text>
              <PromptSelectField
                caption="Select what input schema to use with this prompt."
                dropdownOptions={fieldsetOptions}
                label={"Input Schema"}
                placeholder={"Select what input schema to use"}
                testId="copy-assistant-template-form-select-schema"
                value={selectedFieldset}
                setValue={setSelectedFieldset}
                linkUrl={`${createHref(
                  "fieldsets",
                  customer
                )}?fieldset_id=${selectedFieldset}`}
                linkText="Edit Input Schemas…"
                clearable
              />
              {!FFHideSettingsPromptForm && (
                <PromptSelectField
                  caption="Select what channel to use with this prompt."
                  dropdownOptions={customerChannelOptions}
                  label={"Channel"}
                  placeholder={"Select what channel to use"}
                  testId="copy-assistant-template-form-select-channel"
                  value={selectedChannel}
                  setValue={setSelectedChannel}
                  clearable
                />
              )}
              <PromptBooleanField
                testId="copy-assistant-template-form-include-documents-image"
                caption="Include document image if available and selected model supports image processing."
                label={"Include Document Image"}
                value={includeDocumentsImage}
                setValue={setIncludeDocumentsImage}
              />
              <Divider />
              <Header>Output</Header>
              <PromptBooleanField
                testId="copy-assistant-template-form-keep-html-tags"
                caption="Whether to clear generated HTML tags."
                label={"Clear HTML"}
                value={clearHtmlTagsState}
                setValue={setClearHtmlTags}
              />
              {selectedSectionIsRuleBased && (
                <Popup
                  size="small"
                  wide="very"
                  content="This Section is rule-based. The prompt will use the Default Section instead. Change Section selected below."
                  trigger={<Icon name="warning sign" color="yellow" />}
                />
              )}
              <PromptSelectField
                caption="Generated texts are saved in a Section. The Sections are setup under Document Structure."
                dropdownOptions={sectionOptions}
                label={"Select a Section"}
                placeholder={
                  defaultDocumentSection
                    ? defaultDocumentSection.name
                    : "Select a Section"
                }
                testId="copy-assistant-template-form-select-document-section"
                value={selectedDocumentSection}
                setValue={setSelectedDocumentSection}
                linkUrl={createHref("documentStructures", customer)}
                linkText="Edit Document Structures…"
                clearable
              />
              {!FFHideSettingsPromptForm && (
                <PromptLangStringSelectorField
                  selected={selectedLangStingTemplateId}
                  setSelected={setSelectedLangStringTemplateId}
                  dropdownOptions={langStringTemplateOptions}
                  templateLabels={selectedLangStringTemplate?.labels ?? []}
                  activeTemplateLabelIds={activeLangStringTemplateLabelsState}
                  handleClickLangStringTemplateLabel={
                    handleClickLangStringTemplateLabel
                  }
                  loading={isFetching}
                />
              )}
            </PromptFormPaneForm>
          )}
        </>
      ),
    },
    {
      menuItem: {
        key: "advanced-settings",
        heading: "Advanced Settings",
      },
      content: (
        <PromptFormAdvancedTab
          loading={loading}
          selectedModelName={selectedModelName}
          advancedParameters={advancedParameters}
          collectAdvancedParameters={collectAdvancedParameters}
          modelsConfig={
            models.find((model) => model.model_name === selectedModelName)
              ?.config
          }
        />
      ),
    },
  ];

  return (
    <>
      <Modal.Header
        content={
          mode === PromptFormMode.COPY
            ? `Copy Prompt from "${prompt?.name}"`
            : mode === PromptFormMode.UPDATE
            ? `Update Prompt "${prompt?.name}"`
            : "Create new Prompt"
        }
      />
      <Modal.Content>
        <Tab panes={panes} stateHandler="react" />
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        {mode === PromptFormMode.UPDATE && (
          <Button
            data-testid="copy-assistant-template-form-delete-template"
            floated="left"
            onClick={async (): Promise<void> => await onDeletePrompt()}
            content="Delete prompt"
            size="small"
            basic
          />
        )}
        <Button
          color="red"
          basic
          size="small"
          onClick={(): void => setOpenModal(false)}
          data-testid="close-copy-assistant-template-form"
          loading={loadingForm || isLoading}
        >
          Cancel
        </Button>
        <Button
          color="red"
          size="small"
          disabled={isSaveEnable()}
          onClick={async (): Promise<void> => await onSave()}
          data-testid="save-copy-assistant-template-form"
          loading={loadingForm || isLoading}
        >
          {mode === PromptFormMode.COPY ? "Copy" : "Save"}
        </Button>
      </Modal.Actions>
    </>
  );
};
