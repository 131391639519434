import React, { KeyboardEvent } from "react";

import {
  FilteredSearchResultBox,
  FilteredSearchResultBoxType,
} from "../../../vocabulary/FilteredSearchResultBox";
import { BalloonDiv } from "../../../components/BalloonDiv";

import styled from "styled-components";
import { Modal, Icon } from "semantic-ui-react";
import { SortOption } from "../../../api/vocabularyApi";

const StyledModal = styled(Modal)`
  & {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35% !important;
  }
`;

export const LeftPaddingIcon = styled(Icon)`
  & {
    padding-left: 8px;
    &:hover {
      color: rgba(215, 76, 80, 0.8);
    }
  }
`;

type Props<T> = {
  description?: string;
  smallButton?: boolean;
  name: string;
  onFetchItems: () => Promise<T[]>;
  onRenderRow: (item: T) => JSX.Element;
  onSelectItem: (item: T) => void | Promise<void>;
  onSortChange?: (order: SortOption) => Promise<T[]>;
  spaciousLabel?: boolean;
  title: string;
};

export const FilteredSearchModal = <T extends FilteredSearchResultBoxType>({
  description,
  smallButton = false,
  name,
  onFetchItems,
  onRenderRow,
  onSelectItem,
  onSortChange,
  spaciousLabel,
  title,
}: Props<T>): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const onSubmitCallback = (item: T): void => {
    onSelectItem(item);
    setOpen(false);
  };

  const onCancel = (): void => {
    setOpen(false);
  };

  if (!open) {
    if (title == "Choose data template") {
      return (
        <div className={"template-selector"}>
          <BalloonDiv
            as={"span"}
            className={"balloon"}
            value={"Add template"}
            pos={"right"}
          >
            <LeftPaddingIcon
              tabIndex={0}
              data-testid={"filtered-search-modal-button"}
              link
              name="add circle"
              className={"new-template-add"}
              onClick={(): void => setOpen(true)}
              onKeyDown={(e: KeyboardEvent<Element>): void =>
                e.key === "Enter" && setOpen(true)
              }
              fitted
            />
          </BalloonDiv>
        </div>
      );
    } else {
      return (
        <button
          className={`pbutton pbutton-primary pbutton-inverse new-template-add ${
            smallButton ? "pbutton-small" : ""
          }`}
          data-action="new-template"
          data-testid="filtered-search-modal-button"
          onClick={(): void => setOpen(true)}
        >
          {name}
        </button>
      );
    }
  }

  return (
    <StyledModal open={open} data-testid={"filtered-search-modal-modal"}>
      <div className="template-selector">
        <div className="mask" />
        <div className="modal">
          <div className="inner">
            <FilteredSearchResultBox
              description={description}
              onCancel={onCancel}
              onFetchItems={onFetchItems}
              onRenderRow={onRenderRow}
              onSortChange={onSortChange}
              onSubmit={onSubmitCallback}
              spaciousLabel={spaciousLabel}
              title={title}
            />
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
