import { Customer } from "../../customers/Customer";
import { ProductId } from "../product";
import { buildSidebarItems, SidebarItem } from "./SidebarItem";
import {
  FullTag,
  isBrand,
  isKind,
  isName,
  isTemplate,
  isTemplateSection,
  formatTag,
} from "../../utils/tagutils";
import { uuidv4 } from "../../utils/uuidUtils";
import { LanguageCode } from "../../customers/customerlanguages";

/**
 * Wrapper around all the details needed to render the edit tab
 * This mostly mangles the backend data so it fits what better into the
 * other react components.
 */
export class ProductDetailInfo {
  private readonly data: any;
  private readonly tags: FullTag[];
  readonly customer: Customer;
  readonly productId: ProductId | null = null;
  readonly token: string;
  readonly microcopies: Record<string, string>;
  textualAppName: string;
  constructor(
    customer: Customer,
    token: string,
    productId: ProductId,
    data: any
  ) {
    this.customer = customer;
    this.token = token;
    this.productId = productId;

    this.data = data;
    this.tags = data.tags;
    this.microcopies = data.microcopies;
  }

  createComponentId(): string {
    const componentId = Math.floor(Math.random() * 16).toString(16);
    return `vocabulary_lookup_${componentId}`;
  }

  getMainCategory(): FullTag | null {
    return this.tags.find(isKind) || null;
  }

  getMainCategorySidebarItem(): SidebarItem {
    const kindTag = this.getMainCategory();
    const children = buildSidebarItems(this.tags, true);
    const tags = this.tags;
    if (kindTag) {
      const name = formatTag(kindTag, {
        includeSubTagNames: true,
      });
      const { id, vocabulary_id: vocabularyId } = kindTag;
      const vocabularyIds = [];
      if (vocabularyId != undefined) {
        vocabularyIds.push(vocabularyId.toString());
      }
      return {
        id,
        name,
        tags,
        kindTag,
        childItems: children,
        vocabularyIds,
        isSubpart: false,
      };
    } else {
      return {
        childItems: children,
        id: uuidv4(),
        kindTag: null,
        name: "",
        tags,
        vocabularyIds: [],
        isSubpart: false,
      };
    }
  }

  getBrand(): FullTag | null {
    return this.tags.find(isBrand) || null;
  }

  getName(): FullTag | null {
    return this.tags.find(isName) || null;
  }

  getLanguage(): LanguageCode {
    return this.customer?.config.tag_input_language || "en_US";
  }

  /*
   * Returns a list of section names used on this product
   */
  getUsedSectionNames(): string[] {
    const sections = new Set<string>();
    for (const tag of this.tags) {
      if (isTemplate(tag)) {
        for (const child of tag.tags) {
          if (isTemplateSection(child)) {
            sections.add(child.value);
          }
        }
      }
    }
    return Array.from(sections);
  }

  getMetadata(): any {
    return this.data.imported_metadata;
  }
}
