import React from "react";

import { GridVertical } from "./RequestWordModal";
import { RequestWordModalAction } from "./RequestWordModalAction";
import { RequestWordModalAutomaticEnglishTranslation } from "./RequestWordModalAutomaticEnglishTranslation";

type Props = {
  englishTranslation: string;
  onEnglishTranslationInputChange: (value: string) => void;
  onShouldPopulateChange: (value: boolean) => void;
  populateTranslations: boolean;
  providedWordType: string;
  shouldPopulate: boolean;
  showMachineTranslate?: boolean;
  suggestedEnglishText: string;
};

export const RequestWordModalTranslationContainer: React.FC<Props> = ({
  englishTranslation,
  onEnglishTranslationInputChange,
  onShouldPopulateChange,
  populateTranslations,
  providedWordType,
  shouldPopulate,
  suggestedEnglishText,
}) => (
  <>
    <RequestWordModalAutomaticEnglishTranslation
      englishTranslation={englishTranslation}
      onEnglishTranslationInputChange={onEnglishTranslationInputChange}
      suggestedEnglishText={suggestedEnglishText}
    />
    <GridVertical>
      {populateTranslations && providedWordType === "quality/color" && (
        <RequestWordModalAction
          checked={shouldPopulate}
          id="fill-all-langs"
          helpText="The English word provided will be filled in for all languages. The word will not be sent to translation."
          label="Fill this word in all languages"
          onCheckboxChanged={(checked): void => onShouldPopulateChange(checked)}
        />
      )}
    </GridVertical>
  </>
);
