import React from "react";

type Size = "lg" | "sm";
type Variant =
  | "primary"
  | "primary-alt"
  | "secondary"
  | "secondary-alt"
  | "textual-blue"
  | "textual-blue-alt";
export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  content?: React.ReactNode | never;
  icon?: boolean;
  size?: Size;
  variant?: Variant;
  loading?: boolean;
  compact?: boolean | "very";
  borderless?: boolean;
  innerRef?: React.Ref<HTMLButtonElement>;
};

export const Button: React.FC<Props> = ({
  content,
  children,
  icon,
  size,
  variant = "secondary-alt",
  className,
  loading,
  compact,
  borderless,
  innerRef,
  ...rest
}) => {
  let classNames = `${className || ""} txu-btn txu-${variant} `;
  if (icon) classNames += "txu-icon ";
  if (size) classNames += `txu-${size} `;
  if (compact) {
    if (compact === "very") {
      classNames += "txu-compact-very ";
    } else {
      classNames += "txu-compact ";
    }
  }
  if (loading) {
    classNames += "txu-loading ";
    rest.disabled = true;
  }

  if (borderless) {
    classNames += "txu-no-border";
  }

  return (
    <button className={classNames.trim()} {...rest} ref={innerRef}>
      {content || children}
    </button>
  );
};
