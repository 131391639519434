import $ from "jquery";
import { store } from "./store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../api/djangoToastSlice";
import ReactDOM from "react-dom";
import {
  getSkeletonComponentByName,
  renderReduxElementProvidedProps,
} from "./renderutils";

export const CLASS = {
  LOADING: "loading",
  LOADING_NO_SPINNER: "loading-no-spinner",
  LOADING_WITH_SKELETON: "loading-with-skeleton",
  EMPTY: "empty",
  DONE: "done",
  TIMEOUT: "timeout",
  NOSCROLL: "noscroll",
  SELECTED: "selected",
};
const LOADING_COMPONENT = "data-react-loading-component";
export const handle = function (data: { messages?: string }): void {
  if (data.messages && data.messages.trim()) {
    const $alertBox = $(".alert-box-container");
    $alertBox.append(data.messages);
    setTimeout(function () {
      $alertBox.empty();
    }, 12000);
  }
};

export const showMessage = function (
  level: NotificationAppearance,
  message: string
): void {
  store.dispatch(setDjangoToastOpen({ appearance: level, content: message }));
};

export function replaceContent(
  partial: JQuery<HTMLElement>,
  contentHtml: string | null = null
): JQuery | undefined {
  partial.removeClass(CLASS.LOADING);
  partial.removeClass(CLASS.EMPTY);
  partial.removeClass(CLASS.LOADING_WITH_SKELETON);
  partial.addClass(CLASS.DONE);

  if (!contentHtml) {
    return undefined;
  }
  const usingReactLoadingComponent = !!partial.attr(LOADING_COMPONENT);

  if (usingReactLoadingComponent) {
    ReactDOM.unmountComponentAtNode($(partial)[0]);
  }
  const content = $(contentHtml);
  partial.html(contentHtml);

  if (content.is(".partial")) {
    partial.replaceWith(content);
    partial = content;
  }

  return partial;
}

export const loadPartial = function (
  partial: JQuery<HTMLElement>,
  force = false,
  infinite = false
): void {
  if (!force) {
    if (partial.hasClass(CLASS.LOADING)) return;
    if (partial.hasClass(CLASS.DONE)) return;
    if (partial.hasClass(CLASS.LOADING_WITH_SKELETON)) return;
  } else if (force && infinite) {
    replaceContent(partial, "<div></div>");
  }
  const loadingComponentName = partial.attr(LOADING_COMPONENT);
  if (loadingComponentName) {
    ReactDOM.render(
      renderReduxElementProvidedProps(
        getSkeletonComponentByName(loadingComponentName),
        {}
      ),
      $(partial)[0]
    );
    partial.addClass(CLASS.LOADING_WITH_SKELETON);
  } else {
    if (!partial.hasClass(CLASS.LOADING_NO_SPINNER)) {
      partial.addClass(CLASS.LOADING);
      partial.addClass(CLASS.EMPTY);
    }
  }
  if (infinite) return;
  $.ajax({
    url: partial.data("url"),
    success: function (data: any) {
      handle(data);
      partial = replaceContent(partial, data.content);
      (window as any).rebindAll();
    },
    error: function () {
      showMessage(
        NotificationAppearance.ERROR,
        "An unexpected error occurred."
      );
      partial.removeClass(CLASS.LOADING);
    },
  });
};

window.loadPartial = loadPartial;
