import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProductId } from "../product";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import {
  fetchSections,
  productSectionAction,
  SectionActionRequest,
  SectionDetails,
  SectionItemType,
} from "../../api/sectionActions";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import {
  searchAsyncVocabulary,
  SearchVocabularyParams,
} from "../../api/vocabularyApi";
import { useGetCustomerQuery } from "../../api/customerApi";
import { getFeatureFlag } from "../../utils/featureFlags";
import { Accordion, Input } from "semantic-ui-react";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { EnrichTabSearchResultBox } from "./EnrichTabSearchResultBox";
import { EnrichTabSearchActions } from "./EnrichTabSearchActions";
import { EnrichTabSearchFilters } from "./EnrichTabSearchFilters";
import { Sections } from "../sections-tab/Sections";
import styled from "styled-components";
import { TemplateItem } from "../../vocabulary/template";
import { VocabularyTreeData } from "../../utils/tagutils";
import { debounce } from "../../utils/debounce";
import { fetchProductDetail } from "../../api/action";
import { setHighlightedTag } from "../../api/productDetailEnrichTabSlice";
import { NotificationsContent } from "../../utils/TabNotificationPopup";
import { setDjangoToastOpen } from "../../api/djangoToastSlice";
import { queuePreviewTextGeneration } from "../../utils/GlobalFunctions";
import { getTemplates } from "../../api/templateVocabularyApi";

const TabContainer = styled.div`
  & {
    display: flex;
    flex-direction: row;
    margin: -20px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);

    > div {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 5px;
      margin: 10px;
    }

    > div:last-child {
      background: #ffffff;
      border-radius: 4px;
      padding: 5px 20px;
    }
  }
`;

const ResultsContainer = styled.div`
  & {
    display: flex;
    flex-direction: row;

    > div:first-child {
      width: 70%;
      padding-right: 20px;
    }

    > div:last-child {
      width: 30%;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;

    > div:first-child {
      width: 100%;
      padding: 0;
    }

    > div:last-child {
      width: 100%;
    }
  }
`;

type BaseSectionSearchItem = {
  type: SectionItemType;
  visible: boolean;
};

type TemplateSectionSearchItem = BaseSectionSearchItem & {
  type: SectionItemType.TEMPLATE;
  data: TemplateItem;
};

type VocabularySectionSearchItem = BaseSectionSearchItem & {
  type: SectionItemType.VOCABULARY;
  data: VocabularyTreeData;
};

type FixedPhraseSectionSearchItem = BaseSectionSearchItem & {
  type: SectionItemType.FIXED_SENTENCE;
  data: VocabularyTreeData;
};

export type SectionSearchItem =
  | TemplateSectionSearchItem
  | VocabularySectionSearchItem
  | FixedPhraseSectionSearchItem;

type Props = {
  productId: ProductId;
  textualAppName: string;
};

export const ProductDetailEnrichTab: React.FC<Props> = ({
  productId,
  textualAppName,
}) => {
  const [activeSections, setActiveSections] = useState<Set<number>>(new Set());
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [productDetails, setProductDetails] = useState<ProductDetailInfo>(null);
  const [searchForVocabulary, setSearchForVocabulary] = useState(true);
  const [searchForFixedSentences, setSearchForFixedSentences] = useState(true);
  const [searchForTemplates, setSearchForTemplates] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchResults, setSearchResults] = useState<SectionSearchItem[]>([]);
  const [sections, setSections] = useState<SectionDetails[]>([]);

  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(true);
  const { data: customer, isLoading } = useGetCustomerQuery();
  const highlightedTagId = useSelector(
    (state: RootState) => state.productDetailEnrichTab.highlightedTagId
  );
  const loadSearchResultsItemsDebounce = useCallback(
    debounce(
      async (value: string): Promise<void> =>
        await onSearchInputValueChanged(value),
      300
    ),
    []
  );

  useEffect(() => {
    return (): void => {
      store.dispatch(setHighlightedTag(null));
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && customer) {
      fetchProductDetail(token, customer, productId).then(
        (currentProductDetails) => {
          setProductDetails(currentProductDetails);
        }
      );
      fetchAndRefreshAllSections(true);
      loadSearchResultsItemsDebounce(searchInputValue);
    }
    return (): void => {
      mounted.current = false;
    };
  }, [customer]);

  useEffect(() => {
    loadSearchResultsItemsDebounce(searchInputValue);
  }, [searchInputValue]);

  if (isLoading || !mounted.current || !customer) {
    return <>Loading...</>;
  }

  const fetchAndRefreshAllSections = (
    shouldSetActiveSections = false
  ): void => {
    fetchSections({ token, productId }).then((currentSections) => {
      if (!mounted.current) {
        return;
      }
      // react does not recognize deep change - need to force refresh
      setSections([]);
      setSections(currentSections);
      if (shouldSetActiveSections) {
        setActiveSections(new Set(sections.map((section) => section.id)));
      }
    });
  };

  const toggleSectionVisibility = (index: number): void => {
    if (!mounted.current) {
      return;
    }
    const newActiveSections = new Set(activeSections);
    if (newActiveSections.has(index)) {
      newActiveSections.delete(index);
    } else {
      newActiveSections.add(index);
    }
    setActiveSections(newActiveSections);
  };

  const switchSearchResultsItemsVisibility = (
    searchItems: SectionSearchItem[],
    itemType: SectionItemType,
    visible: boolean
  ): SectionSearchItem[] =>
    searchItems.map((item) => {
      if (item.type === itemType) {
        item.visible = visible;
      }
      return item;
    });

  const onFilterChanged = (filterType: SectionItemType): void => {
    if (!mounted.current) {
      return;
    }
    switch (filterType) {
      case SectionItemType.VOCABULARY:
        setSearchForVocabulary(!searchForVocabulary);
        setSearchResults(
          switchSearchResultsItemsVisibility(
            searchResults,
            SectionItemType.VOCABULARY,
            searchForVocabulary
          )
        );
        break;
      case SectionItemType.FIXED_SENTENCE:
        setSearchForFixedSentences(!searchForFixedSentences);
        setSearchResults(
          switchSearchResultsItemsVisibility(
            searchResults,
            SectionItemType.FIXED_SENTENCE,
            searchForFixedSentences
          )
        );
        break;
      case SectionItemType.TEMPLATE:
        setSearchForTemplates(!searchForTemplates);
        setSearchResults(
          switchSearchResultsItemsVisibility(
            searchResults,
            SectionItemType.TEMPLATE,
            searchForTemplates
          )
        );
        break;
      default:
        throw new Error("Unhandled action case");
    }
  };

  async function onSearchInputValueChanged(value: string): Promise<void> {
    if (!mounted.current) {
      return;
    }
    if (value.length === 0) {
      setLoadingSearchResults(false);
      setSearchResults([]);
      return null;
    }
    const language = customer?.config.tag_input_language || "en_US";
    const currentSearchResults: SectionSearchItem[] = [];

    const vocabularyQueryParam: SearchVocabularyParams = {
      language: language,
      limit: 20,
      q: value,
    };
    const vocabularyResponse = await searchAsyncVocabulary(
      vocabularyQueryParam,
      token,
      textualAppName
    );
    if (vocabularyResponse?.data?.length > 0) {
      vocabularyResponse.data.map((data) => {
        if (!(data.category === "phrase" && data.type === "sentence")) {
          currentSearchResults.push({
            type: SectionItemType.VOCABULARY,
            data: data,
            visible: searchForVocabulary,
          });
        }
      });
    }

    const fixedSentenceQueryParam: SearchVocabularyParams = {
      category: "phrase",
      language: language,
      limit: 20,
      q: value,
      type: "sentence",
    };
    const fixedSentenceResponse = await searchAsyncVocabulary(
      fixedSentenceQueryParam,
      token,
      textualAppName
    );
    if (fixedSentenceResponse?.data?.length > 0) {
      fixedSentenceResponse.data.map((data) =>
        currentSearchResults.push({
          type: SectionItemType.FIXED_SENTENCE,
          data: data,
          visible: searchForFixedSentences,
        })
      );
    }

    const allTemplates = await getTemplates(token, {
      fetch_lexicons: true,
    });
    allTemplates.map((data) => {
      const templateLexicon = data.template_lexicons.find(
        (lexicon) => lexicon.language_code === language
      );
      if (
        templateLexicon &&
        `${data.display_name} ${templateLexicon.template}`.search(value) >= 0
      ) {
        currentSearchResults.push({
          type: SectionItemType.TEMPLATE,
          data: data,
          visible: searchForTemplates,
        });
      }
    });

    setLoadingSearchResults(false);
    setSearchResults(currentSearchResults);
  }

  const onSectionItemAction = async (
    request: SectionActionRequest
  ): Promise<void> => {
    const data = await productSectionAction({
      token,
      productId,
      request,
      highlightedTagId,
    });
    // FIXME: Optimize this to
    // a) when removing a field, just remove that field
    // b) when adding a field, just add that field
    // c) when doing a field set, only update that field
    // etc,
    // instead of always refetching sections

    if (!mounted.current) {
      return;
    }
    if (data.message) {
      store.dispatch(
        setDjangoToastOpen({
          additionalContent: NotificationsContent[data.message].content,
          content: NotificationsContent[data.message].header,
          appearance: NotificationsContent[data.message].appearance,
        })
      );
    }
    fetchAndRefreshAllSections();
    queuePreviewTextGeneration();
  };

  const singleResultBoxFF = getFeatureFlag(
    customer,
    "enrich_tab_single_search_result_box"
  );

  return (
    <>
      <TabContainer data-testid={"product-detail-enrich-tab"}>
        <div>
          <Input
            data-testid={"search-input"}
            icon={"search"}
            placeholder={"Search..."}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              if (!mounted.current) {
                return;
              }
              setSearchInputValue(data.value);
              setLoadingSearchResults(true);
            }}
            value={searchInputValue}
          />
          <ResultsContainer>
            {singleResultBoxFF ? (
              <div>
                <EnrichTabSearchResultBox
                  height={25}
                  label={"Vocabulary"}
                  loading={loadingSearchResults}
                  onSectionItemAction={onSectionItemAction}
                  searchResults={searchResults}
                  showIcons={singleResultBoxFF}
                />
              </div>
            ) : (
              <div>
                {searchForVocabulary && (
                  <EnrichTabSearchResultBox
                    height={10}
                    label={"Vocabulary"}
                    loading={loadingSearchResults}
                    onSectionItemAction={onSectionItemAction}
                    searchResults={searchResults.filter(
                      (item) => item.type === SectionItemType.VOCABULARY
                    )}
                    showIcons={singleResultBoxFF}
                  />
                )}
                {searchForFixedSentences && (
                  <EnrichTabSearchResultBox
                    height={10}
                    label={"Fixed Sentences"}
                    loading={loadingSearchResults}
                    onSectionItemAction={onSectionItemAction}
                    searchResults={searchResults.filter(
                      (item) => item.type === SectionItemType.FIXED_SENTENCE
                    )}
                    showIcons={singleResultBoxFF}
                  />
                )}
                {searchForTemplates && (
                  <EnrichTabSearchResultBox
                    height={10}
                    label={"Templates"}
                    loading={loadingSearchResults}
                    onSectionItemAction={onSectionItemAction}
                    searchResults={searchResults.filter(
                      (item) => item.type === SectionItemType.TEMPLATE
                    )}
                    showIcons={singleResultBoxFF}
                  />
                )}
              </div>
            )}
            <div>
              <EnrichTabSearchActions />
              <EnrichTabSearchFilters
                onFilterChanged={onFilterChanged}
                searchForFixedSentences={searchForFixedSentences}
                searchForTemplates={searchForTemplates}
                searchForVocabulary={searchForVocabulary}
              />
            </div>
          </ResultsContainer>
        </div>

        <div>
          <Accordion exclusive={false} fluid data-testid={"sections-container"}>
            {productDetails && (
              <Sections
                activeSections={activeSections}
                details={productDetails}
                onSectionItemAction={onSectionItemAction}
                sections={sections}
                textualAppName={textualAppName}
                toggleSectionVisibility={toggleSectionVisibility}
              />
            )}
          </Accordion>
        </div>
      </TabContainer>
    </>
  );
};
