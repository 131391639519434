import React from "react";
import { Product } from "../product";
import { RegenerateAction, RegenerateConfig } from "../../api/action";
import { ProductTextCountData } from "./RegenerateTextsModalStateHandler";
import { Checkbox } from "semantic-ui-react";
import { CheckboxProps } from "semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox";
import styled from "styled-components";

const RegenerateTextsModalFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-left: 14px;
`;

type Props = {
  askForOverwrite: boolean;
  displayApprovedCheckbox: boolean;
  displayEditedCheckbox: boolean;
  displayPublishedCheckbox: boolean;
  onChangeOverwrite: (overwrite: boolean) => void;
  onRegenerateActionChange?: (action: RegenerateAction, value: boolean) => void;
  product: Product;
  regenerateConfig: RegenerateConfig;
  textsCountData: ProductTextCountData;
};

export const RegenerateTextsModalForm: React.FC<Props> = ({
  askForOverwrite,
  displayApprovedCheckbox,
  displayEditedCheckbox,
  displayPublishedCheckbox,
  onChangeOverwrite,
  onRegenerateActionChange,
  regenerateConfig,
  textsCountData,
}) => {
  return (
    <RegenerateTextsModalFormStyled>
      {displayApprovedCheckbox && (
        <Checkbox
          data-testid="regenerate-approved-checkbox"
          checked={regenerateConfig.regenerateApprovedTexts}
          label={
            <label>
              Also re-generate{" "}
              <strong>{textsCountData.approved} approved texts</strong>
            </label>
          }
          onChange={(): void =>
            onRegenerateActionChange?.(
              RegenerateAction.REGENERATE_APPROVED,
              !regenerateConfig.regenerateApprovedTexts
            )
          }
        />
      )}
      {displayPublishedCheckbox && (
        <Checkbox
          data-testid="regenerate-published-checkbox"
          checked={regenerateConfig.regeneratePublishedTexts}
          label={
            <label>
              Also re-generate{" "}
              <strong>{textsCountData.published} published texts</strong>
            </label>
          }
          onChange={(): void =>
            onRegenerateActionChange?.(
              RegenerateAction.REGENERATE_PUBLISHED,
              !regenerateConfig.regeneratePublishedTexts
            )
          }
        />
      )}
      {displayEditedCheckbox && (
        <Checkbox
          data-testid="regenerate-edited-checkbox"
          checked={regenerateConfig.regenerateEditedTexts}
          label={
            <label>
              Also re-generate{" "}
              <strong>{textsCountData.edited} edited texts</strong>
            </label>
          }
          onChange={(): void =>
            onRegenerateActionChange?.(
              RegenerateAction.REGENERATE_EDITED,
              !regenerateConfig.regenerateEditedTexts
            )
          }
        />
      )}
      {askForOverwrite && (
        <Checkbox
          data-testid="regenerate-overwrite-checkbox"
          label={"Overwrite already published texts"}
          onChange={(
            event: React.FormEvent<HTMLInputElement>,
            { checked }: CheckboxProps
          ): void => onChangeOverwrite(checked)}
        />
      )}
    </RegenerateTextsModalFormStyled>
  );
};
