import { LanguageCode } from "../customers/customerlanguages";
import {
  AppliedField,
  Fieldset,
  GetFieldsetResponse,
  GetFieldsetsResponse,
  ModifiedFieldset,
} from "../products/manage/fieldsets/types";
import { ProductId } from "../products/product";
import api from "./api";

export const FIELDSET_BASE_URL = "/api/_internal/product/fieldset/";

export async function getFieldset(
  token: string,
  id: number,
  query: {
    productId: ProductId;
    languageCode?: LanguageCode;
  }
): Promise<GetFieldsetResponse<AppliedField>>;
export async function getFieldset(
  token: string,
  id: number,
  query: {
    productId?: ProductId;
    languageCode?: LanguageCode;
  }
): Promise<GetFieldsetResponse> {
  const response = await api.get<GetFieldsetResponse>(FIELDSET_BASE_URL + id, {
    params: {
      product_id: query?.productId,
      language: query?.languageCode,
    },
    headers: { token },
  });
  return response.data;
}

export async function getFieldsets(
  token: string,
  query: {
    productId: ProductId;
    includeKnownKeys?: boolean;
    languageCode?: LanguageCode;
  }
): Promise<GetFieldsetsResponse<AppliedField>>;
export async function getFieldsets(
  token: string,
  query: {
    productId?: ProductId;
    includeKnownKeys?: boolean;
    languageCode?: LanguageCode;
  }
): Promise<GetFieldsetsResponse> {
  const response = await api.get<GetFieldsetsResponse>(FIELDSET_BASE_URL, {
    params: {
      product_id: query?.productId,
      include_known_keys: query?.includeKnownKeys,
      language: query?.languageCode,
    },
    headers: { token },
  });
  return response.data;
}

export async function createFieldset(
  token: string,
  modifiedFieldset: ModifiedFieldset
): Promise<Fieldset> {
  const response = await api.post<Fieldset>(
    FIELDSET_BASE_URL,
    modifiedFieldset,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updateFieldset(
  token: string,
  id: number,
  modifiedFieldset: ModifiedFieldset
): Promise<Fieldset> {
  const response = await api.put<Fieldset>(
    `${FIELDSET_BASE_URL}${id}`,
    modifiedFieldset,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function deleteFieldset(
  token: string,
  id: number
): Promise<unknown> {
  const response = await api.delete<unknown>(`${FIELDSET_BASE_URL}${id}`, {
    headers: { token },
  });
  return response.data;
}

const FIELDSET_MAPPING_BASE_URL = FIELDSET_BASE_URL + "mappings/";

export const FIELDSET_KEY_MAPPING_BASE_URL =
  FIELDSET_MAPPING_BASE_URL + "keys/";

export type FieldsetKeyMapping = {
  [key: string]: string;
};

/**
 *
 * @param key If specified only return mapping for that key (Optional)
 * @returns `{ [key: string]: string; }`
 */
export async function getFieldsetKeyMappings(
  token: string,
  key?: string
): Promise<FieldsetKeyMapping> {
  let url = FIELDSET_KEY_MAPPING_BASE_URL;
  if (key) {
    url += key;
  }
  const response = await api.get<FieldsetKeyMapping>(url, {
    headers: { token: token },
  });

  return response.data;
}

export async function createFieldsetKeyMapping(
  token: string,
  key: string,
  displayKey: string
): Promise<FieldsetKeyMapping> {
  const response = await api.post<FieldsetKeyMapping>(
    FIELDSET_KEY_MAPPING_BASE_URL,
    {
      key: key,
      display_key: displayKey,
    },
    { headers: { token: token } }
  );
  return response.data;
}

export async function updateFieldsetKeyMapping(
  token: string,
  key: string,
  displayKey: string
): Promise<FieldsetKeyMapping> {
  const response = await api.put<FieldsetKeyMapping>(
    FIELDSET_KEY_MAPPING_BASE_URL + key,
    {
      key: key,
      display_key: displayKey,
    },
    { headers: { token: token } }
  );
  return response.data;
}

export async function deleteFieldsetKeyMapping(
  token: string,
  key: string
): Promise<unknown> {
  const response = await api.delete(FIELDSET_KEY_MAPPING_BASE_URL + key, {
    headers: { token: token },
  });

  return response;
}
