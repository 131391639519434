import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { MappingsDownloadRequest, TaskDispatcher2 } from "./TaskDispatcher2";

type Props = {
  apiToken: string;
  keyFilter: string;
  mappedFilter: string;
  namespaceFilter: string;
  tagsFilter: string;
  valueFilter: string;
};
/**
 * Interface for creating and downloading mappings in csv file.
 *
 * This works in the following way:
 * 1) Go to mappings view
 * 2) Filter results if needed
 * 3) Click on the 'Download' button
 * 4) Wait for file to be downloaded
 *
 * This component is using TaskDispatcher(app/assets/js/utils/TaskDispatcher.ts) to avoid possibility
 * of getting a timeout error when trying to download a large file.
 * The component can be initialize with additional filters
 * that are retrieved from mappings view FilerForm (app/extract/views/mappings.py) and pass as component props.
 *
 * @param apiToken -> user token
 * @param keyFilter -> -> filter by mappings key
 * @param mappedFilter -> filter by existence of resulting tag. Y: show only mapped, N: show only unmapped, "": show all
 * @param namespaceFilter -> filter by namespace name
 * @param tagsFilter -> filter by resulting tag name
 * @param valueFilter -> filter by mappings value
 * **/
export const MappingsDownloadButton: React.FC<Props> = ({
  apiToken,
  keyFilter,
  mappedFilter,
  namespaceFilter,
  tagsFilter,
  valueFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const taskDispatcher = new TaskDispatcher2(apiToken);

  const onDownloadRequest = (): void => {
    setLoading(true);
    taskDispatcher
      .queue(
        new MappingsDownloadRequest(
          keyFilter ? keyFilter : null,
          mappedFilter ? mappedFilter === "Y" : null,
          namespaceFilter ? namespaceFilter : null,
          tagsFilter ? tagsFilter : null,
          valueFilter ? valueFilter : null
        )
      )
      .then((result) => {
        window.location.assign(result.presigned_url);
        setLoading(false);
      });
  };

  return (
    <Button
      type="button"
      size="small"
      style={{ fontWeight: "unset" }}
      onClick={onDownloadRequest}
      loading={loading}
    >
      Download
    </Button>
  );
};
