import React from "react";
import { Button, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { ProductText } from "./ProductText";
import { OnActionCallback } from "./ProductTextViewer";
import { ProductTextAction } from "./ProductTextAction";
import { useGetCustomerQuery } from "../api/customerApi";
import { Customer } from "../customers/Customer";

const ProductActionsWrapper = styled.div`
  && {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 3px;
    left: 0;
    transform: translateY(105%);
    height: 25px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.75);
  }
`;

export const popupStyle = {
  fontFamily: "sans-serif",
  fontSize: "12px",
  opacity: 1,
  textAlign: "right",
  whiteSpace: "normal",
};

const ToolbarActionButton = styled(Button)`
  &&& {
    padding: 0.5em;
    opacity: 1;
    margin-right: auto;
    font-size: 1.2em;
    height: 100%;
  }
  &&&:not(:hover) {
    border-image-width: 0;
    box-shadow: none;
  }

  i {
    transform: translateY(-30%);
  }
`;

type ButtonInfo = {
  icon: SemanticICONS;
  label: string;
  action: ProductTextAction;
};

export enum ConnectorType {
  INRIVER = "inriver",
  API_V2_CB = "api_v2_cb",
}

function getProductTextActions(
  customer: Customer,
  productText: ProductText
): ButtonInfo[] {
  const config = customer?.config;
  const buttons: ButtonInfo[] = [];
  const addButton = (
    icon: SemanticICONS,
    label: string,
    action: ProductTextAction
  ): number => buttons.push({ icon, label, action });

  if (!productText) {
    addButton("font", "Generate", ProductTextAction.GENERATE);
    return buttons;
  }

  addButton("edit", "Manual edit", ProductTextAction.OPEN_TEXT_EDITOR);
  if (productText.editedData) {
    addButton("undo", "Revert", ProductTextAction.REVERT);
  } else if (
    !productText.editedData &&
    (!productText.approved || !config.show_approve_texts_button)
  ) {
    addButton("sync", "Re-generate", ProductTextAction.REGENERATE);
  }
  addButton(
    "comment outline",
    "Comment",
    ProductTextAction.OPEN_COMMENT_EDITOR
  );
  if (config.show_approve_texts_button) {
    if (productText.approved) {
      addButton("close", "Unapprove", ProductTextAction.UNAPPROVE);
    } else {
      addButton("check", "Approve", ProductTextAction.APPROVE);
    }
  }
  if (
    config.connector_type !== null &&
    (productText.approved || !config.show_approve_texts_button)
  ) {
    addButton("paper plane", "Publish", ProductTextAction.PUBLISH);
  }
  addButton("archive", "Activity", ProductTextAction.OPEN_ACTIVITY_MODAL);
  return buttons;
}

type ToolbarButtonProps = {
  icon: SemanticICONS;
  label: string;
  onClick: () => void;
};

export const ProductTextToolbarButton: React.FC<ToolbarButtonProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <Popup
      trigger={
        <div>
          <ToolbarActionButton
            basic
            icon
            size="mini"
            onClick={onClick}
            data-connector-type={label}
            data-testid={label.toLowerCase().replace(/\s/g, "-")}
          >
            <Icon name={icon} size="small" />
          </ToolbarActionButton>
        </div>
      }
      content={label}
      key={label}
      size="mini"
      style={popupStyle}
      inverted
      mouseEnterDelay={100}
    />
  );
};

type Props = {
  productText: ProductText;
  onAction: OnActionCallback;
};

export const ProductTextToolbar: React.FC<Props> = ({
  productText,
  onAction,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }

  const productToolbar =
    productText &&
    getProductTextActions(
      customer,
      productText
    ).map(({ action, icon, label }) => (
      <ProductTextToolbarButton
        data-testid={action}
        onClick={(): void => onAction(action)}
        key={action}
        icon={icon}
        label={label}
      />
    ));
  return (
    <ProductActionsWrapper data-testid={"product-text-toolbar"}>
      {productToolbar}
    </ProductActionsWrapper>
  );
};
