//This function exists to be able to extend what icons are shown

import { SemanticICONS } from "semantic-ui-react";

export type IconsObject = {
  key: string;
  value: string;
  iconName: SemanticICONS;
};
export const createIcons = (isChannelLanguagePair: boolean): IconsObject[] => {
  const icons: IconsObject[] = [];
  if (isChannelLanguagePair) {
    icons.push({
      key: "is-selected-in-translation-positive",
      value: "This channel is selected in translations",
      iconName: "check",
    });
  }
  if (!isChannelLanguagePair) {
    icons.push({
      key: "is-selected-in-translation-negative",
      value: "This channel is not selected in translations",
      iconName: "ban",
    });
  }
  return icons;
};
