import { ProductListParams } from "../products/product-list/ProductListURLConfig";
import {
  View,
  ViewId,
  ViewSet,
  ViewSetId,
} from "../products/product-list/viewsets/ViewSetsFilterContainer";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type CreateViewSetRequestData = {
  display_name: string;
};
type CreateViewSetViewRequestData = {
  display_name: string;
  parameters: ProductListParams;
  viewset: ViewSetId;
};

type UpdateViewSetViewRequestData = {
  view: ViewId;
  parameters: ProductListParams;
};

export const viewSetApi = createApi({
  reducerPath: "viewSetApi",
  tagTypes: ["viewSet"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/viewset",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    getViewSets: build.query<ViewSet[], void>({
      query: () => "/fetch/",
      providesTags: (result) => {
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: "viewSet", id } as const)),
            { type: "viewSet", id: "LIST" },
          ];
        }
        return [{ type: "viewSet", id: "LIST" }];
      },
    }),
    createViewSet: build.mutation<ViewSet, CreateViewSetRequestData>({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "viewSet", id: "LIST" }],
    }),
    createView: build.mutation<View, CreateViewSetViewRequestData>({
      query: (body) => ({
        url: "/view/create",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { viewset }) => [
        { type: "viewSet", id: viewset },
      ],
    }),
    updateView: build.mutation<View, UpdateViewSetViewRequestData>({
      query: (body) => ({
        url: "/view/update",
        method: "POST",
        body,
      }),
      invalidatesTags: (result) => {
        if (result) return [{ type: "viewSet", id: result.pviewset }];
        return [{ type: "viewSet", id: "LIST" }];
      },
    }),
    favoriteView: build.mutation<View, { id: ViewId; set_favorite: boolean }>({
      query: ({ id, set_favorite }) => ({
        url: `/view/favorite/${id}`,
        method: "PUT",
        body: { set_favorite },
      }),
      invalidatesTags: (result) => {
        if (result) return [{ type: "viewSet", id: result.pviewset }];
        return [{ type: "viewSet", id: "LIST" }];
      },
    }),
  }),
});

export const {
  endpoints,
  useGetViewSetsQuery,
  useCreateViewSetMutation,
  useCreateViewMutation,
  useUpdateViewMutation,
  useFavoriteViewMutation,
} = viewSetApi;
