import React from "react";

import { Column } from "./Column";
import { ColumnLabel, ColumnValue } from "./Columns";
import { ProductListRenderContext } from "../ObjectTable";
import { Product } from "../../product";
import { ProductListLabels } from "../ProductListLabels";

export const ProductListLabelColumn = {
  name: ColumnValue.Labels,
  label: ColumnLabel[ColumnValue.Labels],
  default: true,
  renderCell: (
    p: Product,
    column: Column,
    context: ProductListRenderContext
  ): JSX.Element => <ProductListLabels product={p} context={context} />,
};
