import React from "react";
import { TemplateItem } from "./template";
import { getTemplateLexiconShortcut } from "../api/vocabularyApi";
import { TemplateLexiconShortcutTypes } from "./TemplateVariantsField";
import { TemplateSelector } from "./TemplateSelector";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";

type Props = {
  onGenerate: (data: string) => void;
  onRenderRow: (item: TemplateItem) => JSX.Element;
  templateVocabId: string;
};
export const JinjaGenerator: React.FC<Props> = ({
  onGenerate,
  onRenderRow,
  templateVocabId,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [condition, setCondition] = React.useState("");
  const [thenData, setThenData] = React.useState("");
  const [elseData, setElseData] = React.useState("");

  const handleSubmit = (e: React.MouseEvent): void => {
    e.preventDefault();
    const jinja =
      `\n{% if ${condition} %}\n` +
      thenData +
      "\n{% else %}\n" +
      elseData +
      "\n{% endif %}";
    onGenerate(jinja);
  };

  return (
    <>
      <label>If...</label>
      <div className="shortcuts" data-testid={"jinja-generator-shortcuts"}>
        <TemplateSelector
          data-testid={"template-selector2"}
          name="Variables"
          onFetchItems={(): Promise<TemplateItem[]> =>
            getTemplateLexiconShortcut({
              token: token,
              templateVocabId: templateVocabId,
              shortcutType: TemplateLexiconShortcutTypes.CONDITIONAL,
            })
          }
          onRenderRow={onRenderRow}
          onSubmit={(item: TemplateItem): void => {
            setCondition(item.jinja);
          }}
          title={"Add variable"}
        />
      </div>
      <textarea
        value={condition}
        onChange={(e): void => setCondition(e.target.value)}
        placeholder='product_type = "dog"'
      />
      <label>Then output...</label>
      <textarea
        value={thenData}
        onChange={(e): void => setThenData(e.target.value)}
        placeholder="I am a dog."
      />
      <label>Otherwise output...</label>
      <textarea
        value={elseData}
        onChange={(e): void => setElseData(e.target.value)}
        placeholder="I want to be a dog."
      />
      <button
        data-testid={"jinja-generator-button-insert"}
        type="button"
        onClick={(e: React.MouseEvent): void => handleSubmit(e)}
      >
        Insert
      </button>
    </>
  );
};
