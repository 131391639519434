import { BaseQueryApi } from "@reduxjs/toolkit/src/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/src/query/tsHelpers";
import { RootState } from "./store";

type BaseQueryApiPick = Pick<
  BaseQueryApi,
  "getState" | "extra" | "endpoint" | "type" | "forced"
>;

export const prepareCustomHeaders = (
  headers: Headers,
  { getState }: BaseQueryApiPick
): MaybePromise<Headers> => {
  const token = (getState() as RootState).auth.token;
  if (token) {
    headers.set("token", token);
  }
  return headers;
};
