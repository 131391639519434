import isEqual from "lodash/isEqual";
import { Customer } from "../../customers/Customer";
import { ChannelLanguagePair } from "../product";
import {
  PageSizeStatus,
  ParentChildStatus,
  ProductStatusFilter,
  TextStatus,
  TranslationStatus,
} from "./ProductListTypes";
import { ProductListParams } from "./ProductListURLConfig";
import { ColumnValue } from "./columns/Columns";

/**
 * This function is responsible for creating a ProductListParams object with default values set.
 * @param customer current customer
 * @param params Optional: pass this to populate keys in the default filter to create the compare object with other values than the defaults
 * @returns Partial<ProductListParams>
 */
export const createCompareFilter = (
  customer: Customer,
  params?: Partial<ProductListParams>
): Partial<ProductListParams> => {
  const compare: Partial<ProductListParams> = {
    brands: undefined,
    columns: [] as ColumnValue[],
    date_category: "created",
    date_end: undefined,
    date_start: undefined,
    label: undefined,
    channel_language_pairs: [
      {
        channel_id: customer?.data.default_channel,
        language_code: customer?.config?.tag_input_language || "en_US",
      },
    ] as ChannelLanguagePair[],
    pagination_size: parseInt(PageSizeStatus.Five),
    parent_child: ParentChildStatus.All,
    status: ProductStatusFilter.All,
    template: undefined,
    text_status: TextStatus.All,
    translation_status: TranslationStatus.All,
    query: undefined,
    vocabulary: undefined,
    search: "",
  };
  if (params) Object.assign(compare, params);
  return compare;
};

/**
 * This function compares two ProductListParams objects and see if they differ in anyway.
 * It is using function `createCompareFilter` to create a default ProductListParams object to compare against
 * @param customer current customer
 * @param currentParameters current ProductListParams
 * @param compareParameters Optional: Use this if you want to compare against other saved filters (Like a active view)
 * @returns boolean
 */
export const hasActiveFilters = (
  customer: Customer,
  currentParameters: ProductListParams,
  compareParameters?: Partial<ProductListParams>
): boolean => {
  const compareFilter = createCompareFilter(customer, compareParameters);
  const currentFilter: {
    [key: string]: unknown;
  } = {};
  Object.entries(compareFilter).forEach(([key, value]) => {
    const valueToUse =
      currentParameters[key as keyof ProductListParams] || value;

    currentFilter[key] = valueToUse;
  });
  return !isEqual(compareFilter, currentFilter);
};
