import React, { useRef } from "react";
import { FileDrop as ReactFileDrop } from "react-file-drop";
import styled from "styled-components";

import { Text } from "../components/Text";
import { Button } from "../components/tailwind";

export const StyledReactFileDrop = styled(ReactFileDrop)`
  .file-drop-target {
    border: 1px dashed lightgrey;
    border-radius: 10px;
    padding: 3em 1em;
    margin: 0 2em 1em;
  }
`;

type FileDropProps = {
  selectedFile: File | null;
  isInvalid: boolean;
  onChange: (file: File | null) => void;
  contentUnselected?: React.ReactNode;
  contentSelected?: React.ReactNode;
};

export const FileDrop: React.FC<FileDropProps> = ({
  selectedFile,
  isInvalid,
  onChange,
  contentUnselected,
  contentSelected,
}) => {
  const fileInputRef = useRef(null);

  return (
    <div className="tw-text-center">
      <input
        data-testid="file-drop-input"
        onChange={(event): void => {
          // Note: If file dialog is reopened but same file is chosen,
          // the input value does not change and this handler doesn't fire.
          const { files } = event.target;
          if (files.length > 0) {
            onChange(files.item(0));
          } else {
            onChange(null);
          }
        }}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
      />
      {selectedFile ? (
        <div>
          {/* A file is selected */}
          <Text>Selected file: {selectedFile.name}</Text>
          {isInvalid && <Text color="red">Invalid file type.</Text>}
          {contentSelected}
          <Button
            data-testid="file-drop-remove-button"
            variant="secondary-alt"
            content={"Remove"}
            className="tw-mt-2 tw-ms-2"
            onClick={(): void => {
              onChange(null);
            }}
          />
        </div>
      ) : (
        <div data-testid="file-drop-target-wrapper">
          <StyledReactFileDrop
            onDrop={(files): void => {
              if (files.length > 0) {
                setTimeout(() => {
                  onChange(files.item(0));
                }, 200);
              } else {
                onChange(null);
              }
            }}
            onTargetClick={(): void => {
              fileInputRef.current.click();
            }}
          >
            <Text as="h3">Drag your file to upload here.</Text>
            {contentUnselected}
          </StyledReactFileDrop>
          <Text color="grey" compact>
            or
          </Text>
          <Text>
            <Button
              variant="primary"
              content="Select file to upload"
              data-testid="file-drop-select-button"
              onClick={(): void => {
                fileInputRef.current.click();
              }}
            />
          </Text>
        </div>
      )}
    </div>
  );
};

type MultiFileDropProps = {
  onAddFiles: (files: File[]) => void;
  content?: React.ReactNode;
};

export const MultiFileDrop: React.FC<MultiFileDropProps> = ({
  onAddFiles,
  content,
}) => {
  const fileInputRef = useRef(null);

  return (
    <div className="tw-text-center" data-testid="file-drop-target-wrapper">
      <input
        data-testid="file-drop-input"
        onChange={(event): void => {
          // Note: If file dialog is reopened but same file is chosen,
          // the input value does not change and this handler doesn't fire.
          const filelist = event.target.files;
          const files: File[] = [];
          for (let x = 0; x < filelist.length; x++) {
            files.push(filelist.item(x));
          }
          onAddFiles(files);
        }}
        ref={fileInputRef}
        type="file"
        className="tw-hidden"
        multiple
      />
      <StyledReactFileDrop
        onDrop={(filelist): void => {
          const files: File[] = [];
          for (let x = 0; x < filelist.length; x++) {
            files.push(filelist.item(x));
          }
          onAddFiles(files);
        }}
        onTargetClick={(): void => {
          fileInputRef.current.click();
        }}
      >
        <Text as="h3">Drag your file(s) to upload here.</Text>
        {content}
      </StyledReactFileDrop>
      <Text color="grey" compact>
        or
      </Text>
      <Text>
        <Button
          variant="primary"
          content="Select file(s) to upload"
          data-testid="file-drop-select-button"
          onClick={(): void => {
            fileInputRef.current.click();
          }}
        />
      </Text>
    </div>
  );
};
