import { TranslationsModalChannelProductTextItem } from "./TranslationsModalChannelProductTextItem";
import React from "react";
import { Product } from "../product";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import styled from "styled-components";

export const NegativeMarginGrid = styled.div`
  margin-top: -25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

type Props = {
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
  product: Product;
  texts: ProductText[];
};

export const TranslationsModalChannelProductTextItems: React.FC<Props> = ({
  onRequestProductTextAction,
  product,
  texts,
}) => (
  <NegativeMarginGrid>
    {texts.map((text) => (
      <TranslationsModalChannelProductTextItem
        key={text.id}
        onRequestProductTextAction={onRequestProductTextAction}
        product={product}
        productTextRef={
          new ProductTextRef(
            text.productId,
            text.customerChannelId,
            text.languageCode
          )
        }
        text={text}
      />
    ))}
  </NegativeMarginGrid>
);
