import { Product, ProductId } from "../../product";
import { ProductListLinks } from "../ProductListLinks";
import React from "react";
import { getProduct } from "../../../api/action";
import { Column } from "./Column";
import { ProductListRenderContext } from "../ObjectTable";
import { store } from "../../../utils/store";

function onLinkOverlayClose(
  productId: ProductId,
  products: Product[],
  setProducts: (products: Product[]) => void
): void {
  const token = store.getState().auth.token;
  getProduct(token, productId).then((product) => {
    if (products === null) {
      return;
    }
    const n = products.findIndex((p: Product) => p.id == product.id);
    if (n !== -1) {
      setProducts(Object.assign([], products, { [n]: product }));
    }
  });
}

export const ProductListLinksColumn = {
  name: "links",
  visible: true,
  collapsing: true,
  // eslint-disable-next-line react/display-name
  renderCell: (
    p: Product,
    column: Column,
    context: ProductListRenderContext
  ): JSX.Element => {
    const { products, onLinkOverlaySetProducts } = context;
    return (
      <ProductListLinks
        product={p}
        products={products}
        setProducts={onLinkOverlaySetProducts}
        onOverlayClose={onLinkOverlayClose}
      />
    );
  },
};
