import React, { useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { LockScreen } from "../utils/LockScreen";
import { Product, ProductId, ProductStatus } from "./product";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  useGetProductQuery,
  useSetProductStatusMutation,
} from "../api/productDetailSlice";
import { UserActionContext } from "./publish/actionTypes";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { productDetailsRefreshCurrentTab } from "../legacy/t.product-detail";
import Styled from "styled-components";

const StyledButton = Styled(Button)`
  width: 200px;
  font-weight: 400 !important;
`;

const StyledDiv = Styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

const StyledHeadingTag = Styled.h3`
  margin-bottom: 10px;
  font-weight: 600;
`;

type ProductDetailStatusContent = {
  buttonInnerText: string;
  text: string;
};

export const ProductDetailStatusContent: Record<
  ProductStatus,
  ProductDetailStatusContent
> = {
  [ProductStatus.IMPORTING]: {
    buttonInnerText: "Unlock for editing",
    text: "This product is marked as importing",
  },
  [ProductStatus.READY]: {
    buttonInnerText: "Unlock for editing",
    text: "This product is marked as ready",
  },
  [ProductStatus.IN_PROGRESS]: {
    buttonInnerText: "",
    text: "",
  },
};

type Props = {
  productId: ProductId;
};

export const ProductDetailLockScreen: React.FC<Props> = ({ productId }) => {
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();
  const [
    updateProductStatus,
    { isLoading: isProductUpdating, data: productUpdatedData },
  ] = useSetProductStatusMutation();
  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    // should be ProductStatus.IN_PROGRESS, but "null" is not allowed in typescript enums
    if (
      mounted.current &&
      !isProductUpdating &&
      productUpdatedData?.status == null
    ) {
      // after auto-refresh on product, refresh other sections.
      productDetailsRefreshCurrentTab();
    }
  }, [isProductUpdating]);

  if (!product || isProductLoading || isCustomerLoading) {
    return <></>;
  }

  const showTrialMessage = customer.config.show_trial_expired_message;

  const displayLockScreenContent = (): JSX.Element => {
    if (showTrialMessage) {
      return (
        <StyledDiv>
          <StyledHeadingTag>
            Trial expired. Please contact Textual to select a plan.
          </StyledHeadingTag>
        </StyledDiv>
      );
    }
    const innerContent =
      ProductDetailStatusContent[product.status as ProductStatus];
    return (
      <StyledDiv>
        <StyledHeadingTag>{innerContent.text}</StyledHeadingTag>
        <StyledButton
          content={innerContent.buttonInnerText}
          className={"pbutton pbutton-primary"}
          data-testid={"lock-screen-button"}
          onClick={(): Promise<
            { data: Product } | { error: FetchBaseQueryError | SerializedError }
          > =>
            updateProductStatus({
              productId,
              status: ProductStatus.IN_PROGRESS,
              userActionContext: UserActionContext.PRODUCT_STATUS_BUTTON,
            })
          }
        />
      </StyledDiv>
    );
  };

  const show =
    [ProductStatus.READY, ProductStatus.IMPORTING].includes(
      product.status as ProductStatus
    ) || showTrialMessage;

  return show && <LockScreen innerContent={displayLockScreenContent()} />;
};
