import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { TagTreeEditorComp } from "./TagTreeEditor";
import { formatTag, TreeData } from "../utils/tagutils";
import Styled from "styled-components";
import { buttonTextColor } from "../products/product-list/viewsets/ViewSetsCreateNewFilterDropdown";
import { connect, ConnectedProps } from "react-redux";

const StyledGrayButton = Styled(Button)`
  &&& {
    box-shadow: none !important;
    height: unset !important;
    font-weight: 500;
    color: ${buttonTextColor} !important;
    &:hover {
      box-shadow: none !important;
      opacity: 0.8;
    }
    &:focus {
      box-shadow: initial;
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;

type Props = {
  alwaysTranslate: boolean;
  disableDrag?: boolean;
  showMachineTranslate: boolean;
  initialData: TreeData[];
  language: string;
  limit: number;
  includeSeo: boolean;
  mappingMode: boolean;
  onDataChanged: (
    component: TagTreeButton,
    data: { treeData: TreeData[] }
  ) => void;
  showHypernym: boolean;
  textualAppName: string;
  useLexiconEditor: boolean;
  vocabularyRequestAllowed: boolean;
};

type State = {
  isModalVisible: boolean;
  treeData: TreeData[];
  newTreeData: TreeData[];
};

const connector = connect(null, null);

type TagTreeButtonProps = ConnectedProps<typeof connector> & Props;

export default class TagTreeButton extends React.Component<
  TagTreeButtonProps,
  State
> {
  constructor(props: TagTreeButtonProps) {
    super(props);
    this.state = {
      isModalVisible: false,
      treeData: [],
      newTreeData: [],
    };
  }

  componentDidMount(): void {
    const { initialData } = this.props;
    this.setState({ treeData: initialData, newTreeData: initialData });
  }

  // FIXME: TagTreeEditor
  onDataChanged = (tagTree: any, treeData: TreeData[]): void => {
    this.setState({ newTreeData: treeData });
  };

  submitAction = (): void => {
    let treeDataToAdd: TreeData[];
    const { newTreeData, treeData } = this.state;
    const { initialData, onDataChanged } = this.props;
    if (newTreeData.length) {
      // user added new data
      treeDataToAdd = [...newTreeData];
    } else if (treeData.length && !newTreeData.length) {
      // user deleted root from tree
      treeDataToAdd = [];
    } else {
      treeDataToAdd = [...initialData];
    }
    onDataChanged(this, { treeData: treeDataToAdd });
    this.setState({ treeData: treeDataToAdd });
    this.closeModal();
  };

  closeModal = (): void => {
    this.setState({ isModalVisible: false, newTreeData: [] });
  };

  render(): React.ReactElement {
    const { treeData, isModalVisible } = this.state;

    let buttonTitle = "Select tags";
    if (treeData && treeData.length > 0) {
      buttonTitle = "";
      treeData.forEach((tag) => {
        const preview =
          tag.preview_text ||
          formatTag(tag, {
            includeSubTagNames: true,
            showTagTypeHints: true,
          });
        if (buttonTitle === "") {
          buttonTitle = preview;
        } else {
          buttonTitle = `${buttonTitle}, ${preview}`;
        }
      });
    }

    return (
      <div className="treeViewButtonContainer" data-testid={"tag-tree-button"}>
        <StyledGrayButton
          basic
          className="treeViewButton"
          compact
          content={buttonTitle}
          data-testid={"tag-tree-button-open-modal"}
          onClick={(): void => this.setState({ isModalVisible: true })}
          size={"mini"}
          type="button"
        />
        <Modal
          open={isModalVisible}
          onClose={this.closeModal}
          data-testid={"tag-tree-button-modal"}
        >
          <Modal.Header>Select Tags</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <TagTreeEditorComp
                {...this.props}
                initialData={treeData ? treeData : []}
                expandedWhenEmpty={true}
                onDataChanged={this.onDataChanged}
              />
              <button
                className="pbutton pbutton-primary"
                data-testid={"tag-tree-button-submit"}
                onClick={this.submitAction}
                style={{ marginRight: "5px" }}
                type="button"
              >
                Submit
              </button>
              <button
                className="pbutton pbutton-secondary"
                data-testid={"tag-tree-button-close"}
                onClick={this.closeModal}
                type="button"
              >
                Cancel
              </button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export const TagTreeButtonComp = connector(TagTreeButton);
