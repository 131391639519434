import React from "react";
import { Button, Modal, Tab } from "semantic-ui-react";
import styled from "styled-components";
import { TabDataTree } from "./TabDataTree";
import { TabTextBlocks } from "./TabTextBlocks";
import { TabMetadata } from "./TabMetadata";

const StyledModal = styled(Modal)`
  & {
    width: 70% !important;

    & .scrolling.content {
      max-height: calc(65vh - 10em) !important;
      height: calc(65vh - 10em);
    }
    & .data-tab-tree {
      overflow: overlay;
    }
    & .tags {
      line-height: 19px;
      font-family: monospace;
      font-size: 16px;
      white-space: pre;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
  productId: string;
};

export const ActionDataDetailModal: React.FC<Props> = ({
  open,
  onClose,
  productId,
}) => {
  const renderTabData = (): JSX.Element => (
    <TabDataTree productId={productId} />
  );
  const renderTabBlocks = (): JSX.Element => (
    <TabTextBlocks productId={productId} />
  );
  const renderTabMetadata = (): JSX.Element => (
    <TabMetadata productId={productId} />
  );
  const panes = [
    {
      menuItem: "Data Tree",
      render: renderTabData,
    },
    {
      menuItem: "Text Blocks",
      render: renderTabBlocks,
    },
    {
      menuItem: "Metadata",
      render: renderTabMetadata,
    },
  ];

  return (
    <StyledModal open={open} data-testid={"action-data-detail-modal"}>
      <Modal.Content scrolling>
        <Modal.Description>
          <Tab panes={panes} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid={"action-data-detail-modal-close-button"}
          onClick={(): void => onClose()}
        >
          Close
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
