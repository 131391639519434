import React from "react";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import { EnrichTabSearchResultItem } from "./EnrichTabSearchResultItem";
import { SectionActionRequest } from "../../api/sectionActions";
import { SectionSearchItem } from "./ProductDetailEnrichTab";

const StyledParagraph = styled.p`
  & {
    text-align: center;
    color: dimgrey;
    font-size: 14px;
  }
`;

const StyledLoader = styled(Loader)`
  &&& {
    margin-top: 30px;
  }
`;

type Props = {
  loading: boolean;
  onSectionItemAction?: (request: SectionActionRequest) => void;
  searchResults: SectionSearchItem[];
  showIcons: boolean;
};

export const EnrichTabSearchResultItems: React.FC<Props> = ({
  loading,
  onSectionItemAction,
  searchResults,
  showIcons,
}) => {
  if (loading) {
    return (
      <StyledLoader
        active
        data-testid={"test-loader"}
        inline={"centered"}
        size={"tiny"}
        content={"Loading"}
      />
    );
  }
  const items = searchResults.filter((item) => item.visible);
  if (!items.length) {
    return (
      <StyledParagraph data-testid={"test-no-results"}>
        No results
      </StyledParagraph>
    );
  }
  return (
    <>
      {items.map((item) => (
        <EnrichTabSearchResultItem
          key={item.data.id}
          onSectionItemAction={onSectionItemAction}
          sectionSearchItem={item}
          showIcons={showIcons}
        />
      ))}
    </>
  );
};
