import React from "react";
import ReactDOM from "react-dom";
import { Icon } from "../icon/Icon";
import { Spinner } from "../../../utils/Spinner";

export type PageDimmerProps = {
  header: string;
  icon?: string;
  loading?: boolean;
};

export const PageDimmer: React.FC<PageDimmerProps> = ({
  header,
  loading,
  icon,
}) => {
  return ReactDOM.createPortal(
    <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[999] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-bg-black/80 tw-text-white">
      {loading && <Spinner size="medium" align="center" />}
      <div className="tw-flex tw-items-center">
        {icon && <Icon name={icon} data-testid="page-dimmer-icon" />}
        <h2>{header}</h2>
      </div>
    </div>,
    document.body
  );
};
