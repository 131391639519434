import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
  englishLanguageCustomer: boolean;
  remindEnglish: boolean;
};

const requestWordModalSlice = createSlice({
  name: "requestWordModal",
  initialState: {
    englishLanguageCustomer: true,
    remindEnglish: false,
  } as initialState,
  reducers: {
    setEnglishLanguageCustomer: (
      state,
      { payload: englishLanguageCustomer }: PayloadAction<boolean>
    ) => {
      state.englishLanguageCustomer = englishLanguageCustomer;
    },
    setRemindEnglish: (
      state,
      { payload: remindEnglish }: PayloadAction<boolean>
    ) => {
      state.remindEnglish = remindEnglish;
    },
  },
});

export const {
  setEnglishLanguageCustomer,
  setRemindEnglish,
} = requestWordModalSlice.actions;

export default requestWordModalSlice.reducer;
