import React from "react";
import { Toolbar } from "./Toolbar";
import { Customer } from "../../customers/Customer";
import {
  fetchTemplateForms,
  fetchTemplateSectionTags,
  productSectionAction,
  SectionsWithTags,
  TemplateFormInfo,
  SectionActionRequest,
  SectionDetails,
  TemplateSectionWithForms,
} from "../../api/sectionActions";
import { Spinner } from "../../utils/Spinner";
import { fetchProductDetail } from "../../api/action";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import { Sections } from "./Sections";
import { AddSection } from "./AddSection";
import { connect, ConnectedProps } from "react-redux";
import { RootState, store } from "../../utils/store";
import { getCustomer } from "../../api/customerApi";

type State = {
  details: ProductDetailInfo | null;

  emptySections: SectionDetails[];

  // All template forms that is used;
  forms: TemplateFormInfo[];

  tagSections: SectionsWithTags;
};

type Props = {
  textualAppName: string;
  productId: string;
};

const mapStateToProps = (
  state: RootState
): { token: string | null; customer: Customer | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
  /** Current customer */
  customer: getCustomer(store.getState()),
});

const connector = connect(mapStateToProps, null);

type ProductSectionsTabProps = ConnectedProps<typeof connector> & Props;

export class ProductSectionsTab extends React.Component<
  ProductSectionsTabProps,
  State
> {
  state: State;
  constructor(props: ProductSectionsTabProps) {
    super(props);
    this.state = {
      details: null,
      emptySections: [],
      forms: [],
      tagSections: {},
    };
  }
  componentDidMount(): void {
    const { customer, productId, token } = this.props;
    fetchProductDetail(token, customer, productId).then((details) => {
      this.setState({ details });
    });
    this._fetchTemplateForms();
    fetchTemplateSectionTags(token, productId).then(({ tagSections }) => {
      this.setState({ tagSections });
    });
  }

  _fetchTemplateForms(): void {
    const { productId, token } = this.props;
    fetchTemplateForms(token, productId).then(({ forms }) => {
      this.setState({ forms });
    });
  }

  onTemplateAction(request: SectionActionRequest): void {
    const { productId, token } = this.props;
    productSectionAction({ token, productId, request });
    this._fetchTemplateForms();
  }

  buildSectionsWithForms(): {
    addableSections: SectionDetails[];
    visibleSections: TemplateSectionWithForms[];
  } {
    const { details, emptySections, forms, tagSections } = this.state;
    const formsBySection: Record<string, TemplateFormInfo[]> = {};
    // Match up forms with sections
    forms.map((form): void => {
      if (!(form.section in formsBySection)) {
        formsBySection[form.section] = [];
      }
      formsBySection[form.section].push(form);
    });

    const addableSections: SectionDetails[] = [];
    const visibleSections: TemplateSectionWithForms[] = [];
    const emptySectionNames: string[] = emptySections.map(
      (section) => section.name
    );
    const usedSectionNames = details.getUsedSectionNames();

    for (const [name, section] of Object.entries(tagSections)) {
      if (
        section.include_in_new_products ||
        usedSectionNames.includes(name) ||
        emptySectionNames.includes(name)
      ) {
        visibleSections.push({
          ...section,
          forms: formsBySection[name] || [],
        });
      } else {
        addableSections.push(section);
      }
    }
    return {
      visibleSections,
      addableSections,
    };
  }

  render(): React.ReactElement {
    const { details } = this.state;
    const { customer, productId, textualAppName } = this.props;

    if (!customer || !details) {
      return <Spinner />;
    }

    const onTemplateAction = this.onTemplateAction.bind(this);
    const { addableSections, visibleSections } = this.buildSectionsWithForms();
    return (
      <div className={"right"}>
        <Toolbar onTemplateAction={onTemplateAction} productId={productId} />
        <Sections
          activeSections={new Set()}
          details={details}
          onSectionItemAction={onTemplateAction}
          sections={visibleSections}
          textualAppName={textualAppName}
          toggleSectionVisibility={(): void => null}
        />
        {addableSections.length > 0 && (
          <AddSection
            onAddSection={(section): void =>
              this.setState((prevState) => ({
                emptySections: [...prevState.emptySections, section],
              }))
            }
            sections={addableSections}
          />
        )}
      </div>
    );
  }
}

export const ProductSectionsTabComp = connector(ProductSectionsTab);
