import { Icon, Segment } from "semantic-ui-react";
import styled from "styled-components";

const pipelineStepBorderColor = "rgb(248, 186, 36)";
const pipelineStepBackgroundColor = "rgba(248, 186, 36, 0.2)";

export const PipelineStepsWrapper = styled.div<{ $gap: number }>`
  & {
    display: flex;
    flex-direction: column;
    gap: ${({ $gap }): string => $gap + "px"};
  }
`;

export const PipelineStepSegment = styled(Segment)<{ $notClickable: boolean }>`
  &&&& {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    padding-block: 1.5rem;
    padding-inline: 1rem 2rem;
    margin: 0;
    background-color: ${pipelineStepBackgroundColor};
    border-color: ${pipelineStepBorderColor};
    position: relative;
    cursor: ${({ $notClickable }): string =>
      $notClickable ? "initial" : "pointer"};
    transition: background-color 200ms ease;

    > .pipeline-step-segment-texts {
      width: 100%;
      display: flex;
      align-items: center;
      .action-open-settings-nudge {
        opacity: 0.6;
        visibility: ${({ $notClickable }): string =>
          $notClickable ? "hidden" : "visible"};
        transition: opacity 250ms ease;
        margin-left: auto;
      }
    }
    &:hover {
      background-color: ${({ $notClickable }): string =>
        $notClickable ? pipelineStepBackgroundColor : pipelineStepBorderColor};

      > .remove-step-button {
        opacity: 1;
      }
      .action-open-settings-nudge {
        opacity: 1;
      }
    }
  }
`;

export const PipelineDragIndicatorIcon = styled.i<{ $showSettings: boolean }>`
  &&& {
    cursor: grab;
    padding-top: 3px;
    visibility: ${({ $showSettings }): string =>
      $showSettings ? "hidden" : "visible"};
  }
`;

export const PipelineArrowDownIcon = styled(Icon)<{ $bottom: number }>`
  &&& {
    position: absolute;
    bottom: ${({ $bottom }): string => $bottom + "px"};
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const PipelineRemoveStepButton = styled(Icon)`
  &&& {
    opacity: 0;
    background-color: white;
    position: absolute;
    top: 3px;
    right: 0px;
    transform: translate(0%, -50%);
    transition: opacity 300ms ease;
  }
`;
