import React, { useMemo } from "react";
import { HTMLTextElements } from "../components/Text";
import {
  Dropdown,
  DropdownItemProps,
  Label,
  LabelProps,
  StrictDropdownProps,
} from "semantic-ui-react";
import { useGetCustomerQuery } from "../api/customerApi";

export type Language = {
  code: LanguageCode;
  flag: string;
  iso?: string;
  name: string;
  short_name: string;
  translation_request_email_recipient?: string;
  translate?: boolean;
};

export type LanguageCode =
  | "en_US"
  | "en_GB"
  | "sv_SE"
  | "fr_FR"
  | "da_DK"
  | "nb_NO"
  | "fi_FI"
  | "de_DE"
  | "nl_NL"
  | "zh_CN"
  | "bg_BG"
  | "it_IT"
  | "pl_PL"
  | "pt_PT"
  | "ru_RU"
  | "es_ES"
  | "ar_001"
  | "ja_JP"
  | "ko_KR"
  | "hu_HU"
  | "tr_TR"
  | "ro_RO"
  | "cs_CZ"
  | "sk_SK"
  | "lv_LV"
  | "et_EE"
  | "is_IS"
  | "lt_LT";

type FlagProps = {
  className?: string;
  as?: "span" | "div" | HTMLTextElements;
  content?: string;
};

export const Flag: React.FC<FlagProps> = ({
  as = "span",
  children,
  className,
  content,
}) => {
  if (content && children) {
    throw new Error(
      "Flag Component: Both content and children can't be provided together"
    );
  }
  const As = (as as unknown) as React.JSXElementConstructor<
    React.PropsWithChildren<FlagProps>
  >;
  return (
    <As {...(className && { className })} data-testid="flag-component">
      {content || children}
    </As>
  );
};

export function makeLanguageDropdownOption(
  language: Language,
  includeFlag: boolean = true
): {
  key: string;
  value: LanguageCode;
  text: React.ReactNode;
} {
  return {
    key: language.short_name,
    value: language.code,
    text: includeFlag ? (
      <>
        <Flag content={language.flag} /> {language.short_name}{" "}
      </>
    ) : (
      language.short_name
    ),
  };
}

type CustomerLanguageDropdownProps = Omit<
  StrictDropdownProps,
  "options" | "search" | "onSearchChange" | "searchQuery" | "renderLabel"
>;

export const SUICustomerLanguageDropdown: React.FC<CustomerLanguageDropdownProps> = ({
  loading,
  ...props
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();

  const languageOptions = useMemo(() => {
    if (!customer) return [];
    const options = customer.languages.map((l) =>
      makeLanguageDropdownOption(l)
    );
    return options;
  }, [customer]);

  const isMultiple = useMemo(() => props.multiple, [props]);

  const renderLabel = (
    item: ReturnType<typeof makeLanguageDropdownOption>,
    _: number,
    defaultLabelProps: LabelProps
  ): React.ReactNode => {
    return <Label {...defaultLabelProps} content={item.text} />;
  };
  return (
    <Dropdown
      {...props}
      loading={isLoading || loading}
      options={languageOptions}
      search={(options, value): DropdownItemProps[] => {
        if (!value) return options;
        return options.filter(
          ({ key }) => key.toLowerCase().search(value.toLowerCase()) != -1
        );
      }}
      {...(isMultiple && { renderLabel })}
    />
  );
};

// Convert from "zh_CN" to "Chinese" or "🇨🇳 Chinese"
export function renderLanguageFromCode(
  code: string,
  languages: Language[],
  includeFlag: boolean = true,
  includeName: boolean = true
): string | null {
  const language = languages.find((lang) => lang.code === code);
  if (language) {
    let out = "";
    if (includeFlag) out += `${language.flag} `;
    if (includeName) out += language.short_name;
    return out;
  } else {
    return null;
  }
}
