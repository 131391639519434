import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import fromPairs from "lodash/fromPairs";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dimmer } from "semantic-ui-react";

import { useGetAuthQuery } from "../api/authApi";
import { useGetCustomerQuery } from "../api/customerApi";
import { requestManualTranslation } from "../api/requestTranslationApi";
import { FileDrop } from "../components/FileDrop";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import {
  Flag,
  LanguageCode,
  renderLanguageFromCode,
  SUICustomerLanguageDropdown,
} from "../customers/customerlanguages";
import { validateFileExtension } from "../utils/files";
import { RootState } from "../utils/store";
import { Spinner } from "../utils/Spinner";

const ALLOWED_EXTENSIONS = ["docx", "pptx", "xlsx", "pdf", "txt", "csv"];

export const ManualTranslationRequest: React.FC = () => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [isRequesting, setIsRequesting] = useState(false);
  const [jobIds, setJobIds] = useState<{ [lang: string]: string }>({});
  const [email, setEmail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [deliverInOneFile, setDeliverInOneFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>(null);

  const isSelectedFileInvalid = useMemo((): boolean => {
    if (isNull(selectedFile)) return false;
    return !validateFileExtension(selectedFile, ALLOWED_EXTENSIONS);
  }, [selectedFile]);

  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<
    LanguageCode
  >();
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>(
    []
  );

  const allLanguagesHasBeenSelected =
    selectedLanguages.length === customer?.languages.length - 1;

  useEffect(() => {
    if (selectedLanguages.includes(selectedSourceLanguage)) {
      setSelectedLanguages(
        [...selectedLanguages].filter((code) => code !== selectedSourceLanguage)
      );
    }
  }, [selectedSourceLanguage]);

  const handleSelectLanguage = (code: LanguageCode): void => {
    if (code === selectedSourceLanguage) {
      return;
    }
    if (selectedLanguages.includes(code)) {
      setSelectedLanguages([...selectedLanguages].filter((c) => c !== code));
      return;
    }
    const copy = [...selectedLanguages];
    copy.push(code);
    setSelectedLanguages(copy);
  };

  const handleSelectAll = (): void => {
    if (allLanguagesHasBeenSelected) {
      setSelectedLanguages([]);
      return;
    }
    const languageCodesToSelect = customer.languages
      .filter(({ code }) => code !== selectedSourceLanguage)
      .map(({ code }) => code);
    setSelectedLanguages(languageCodesToSelect);
  };

  const handleRequestManualTranslation = async (): Promise<void> => {
    setIsRequesting(true);
    try {
      if (deliverInOneFile) {
        const jobId = await requestManualTranslation(
          token,
          email,
          selectedFile,
          selectedSourceLanguage,
          selectedLanguages,
          isUrgent,
          additionalInstructions
        );
        setJobIds(
          fromPairs(selectedLanguages.map((language) => [language, jobId]))
        );
      } else {
        const results = await Promise.all(
          selectedLanguages.map((language) => {
            return requestManualTranslation(
              token,
              email,
              selectedFile,
              selectedSourceLanguage,
              [language],
              isUrgent,
              additionalInstructions
            ).then((jobId) => {
              return { language, jobId };
            });
          })
        );
        setJobIds((prevJobIds) => {
          const newJobIds = { ...prevJobIds };
          results.forEach(({ language, jobId }) => {
            newJobIds[language] = jobId;
          });
          return newJobIds;
        });
      }
    } finally {
      setIsRequesting(false);
    }
  };

  const resetAllStates = (): void => {
    setJobIds({});
    setIsUrgent(false);
    setAdditionalInstructions("");
    setDeliverInOneFile(false);
    setSelectedFile(null);
    setSelectedSourceLanguage(null);
    setSelectedLanguages([]);
    setIsRequesting(false);
  };

  useEffect(() => {
    if (isAuthLoading || !auth) return;
    setEmail(auth.user.email);
  }, [auth, isAuthLoading]);

  return (
    <>
      <div className="tw-grid tw-grid-cols-2 tw-gap-8">
        <div className="tw-mt-4">
          <SUICustomerLanguageDropdown
            loading={isLoading}
            clearable
            data-testid="manual-translation-request-select-source-language-dropdown"
            fluid
            placeholder="Select source file language"
            selection
            value={selectedSourceLanguage}
            onChange={(e, { value }): void =>
              setSelectedSourceLanguage(value as LanguageCode)
            }
          />
          <div className="tw-my-8">
            <FileDrop
              selectedFile={selectedFile}
              isInvalid={isSelectedFileInvalid}
              onChange={(file): void => {
                setSelectedFile(file);
              }}
              contentUnselected={
                <>
                  <Text>
                    Supported file types: PDF, Word (.docx), Excel (.xlsx), CSV
                  </Text>
                  <Text color="grey">
                    Also supported: Plain text (.txt), Powerpoint (.pptx)
                  </Text>
                </>
              }
            />
          </div>
          <form className="txu-form tw-mt-4 tw-flex tw-flex-col tw-gap-4">
            <div>
              <label>Email address(es) for returning the delivery *</label>
              <small>
                Separate multiple email addresses with commas <code>(,)</code>
              </small>
              <input
                data-testid="manual-translation-request-email"
                type="email"
                placeholder="Email..."
                value={email}
                onChange={(e): void => setEmail(e.target.value)}
                className="tw-w-full"
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="manual-translation-request-urgent"
                data-testid="manual-translation-request-urgent"
                checked={isUrgent}
                onChange={(e): void => setIsUrgent(e.target.checked)}
              />
              <label htmlFor="manual-translation-request-urgent">
                {customer?.config.translation_request_urgent_message}
              </label>
            </div>
            <div>
              <label>Additional instructions</label>
              <textarea
                data-testid="manual-translation-request-additional-instructions"
                value={additionalInstructions}
                onChange={(e): void =>
                  setAdditionalInstructions(e.target.value)
                }
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="manual-translation-request-deliver-in-one-file"
                data-testid="manual-translation-request-deliver-in-one-file"
                checked={deliverInOneFile}
                onChange={(e): void => setDeliverInOneFile(e.target.checked)}
              />
              <label htmlFor="manual-translation-request-deliver-in-one-file">
                Deliver in one file
              </label>
            </div>
          </form>
        </div>
        <div>
          <table className="txu-table txu-compact txu-clickable-rows tw-mb-4">
            <thead>
              <tr>
                <th>Language</th>
                <th>Manual Translation</th>
              </tr>
            </thead>
            <tbody>
              {customer?.languages.map((language) => (
                <tr
                  data-testid={`manual-translation-request-table-row-${language.code}`}
                  key={language.code}
                  onClick={(): void => handleSelectLanguage(language.code)}
                >
                  <td>
                    <Flag content={language.flag} /> {language.short_name}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="txu-input"
                      data-testid={`manual-translation-request-table-row-${language.code}-checkbox`}
                      checked={selectedLanguages.includes(language.code)}
                      disabled={language.code === selectedSourceLanguage}
                      onChange={(): void => handleSelectLanguage(language.code)}
                    />
                  </td>
                </tr>
              ))}
              <tr onClick={(): void => handleSelectAll()}>
                <td>Select All</td>
                <td>
                  <input
                    type="checkbox"
                    data-testid="manual-translation-request-table-row-select-all-checkbox"
                    checked={allLanguagesHasBeenSelected}
                    onChange={(): void => handleSelectAll()}
                    className="txu-input"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            data-testid="manual-translation-request-send-request-button"
            variant="primary"
            content="Send request"
            style={{ float: "right" }}
            onClick={(): Promise<void> => handleRequestManualTranslation()}
            disabled={
              !selectedSourceLanguage ||
              !selectedFile ||
              !email ||
              !selectedLanguages.length ||
              isSelectedFileInvalid
            }
          />
        </div>
      </div>

      <Dimmer active={isRequesting || !isEmpty(jobIds)} page>
        {isRequesting ? (
          <Spinner
            inverted
            content="Sending for Manual translation..."
            data-testid="manual-translation-request-loading"
            size="medium"
          />
        ) : (
          <>
            <Icon name="check" />
            <Text size="large">Sent for Manual translation!</Text>
            <Text size="small">
              You will receive an email at <b>{email}</b> when the manual
              translation is complete.
            </Text>
            <ul data-testid="manual-translation-request-job-ids">
              {Object.entries(jobIds).map(([languageCode, jobId]) => {
                return (
                  <li key={languageCode} className="tw-my-2">
                    <strong>
                      {renderLanguageFromCode(
                        languageCode,
                        customer?.languages ?? []
                      )}
                    </strong>
                    : {jobId}
                  </li>
                );
              })}
            </ul>
            <Text size="small">
              Save the job ID(s) for use in correspondance with us.
              <br />
              Any related questions can be sent to{" "}
              <a
                href="mailto:translations@textual.se"
                style={{ color: "white" }}
              >
                translations@textual.se
              </a>
            </Text>
            <hr className="tw-my-4 tw-border-gray-600" />
            <Button
              data-testid="manual-translation-request-done-button"
              content="Done"
              variant="primary"
              onClick={resetAllStates}
            />
          </>
        )}
      </Dimmer>
    </>
  );
};
