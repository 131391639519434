"use strict";
import $ from "jquery";
// @ts-ignore
import CodeMirror from "codemirror";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/mode/python/python";
import "codemirror/mode/jinja2/jinja2";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/yaml/yaml";
import { debounce } from "../utils/debounce";
import { showMessage } from "../utils/partialUtils";
import { NotificationAppearance } from "../api/djangoToastSlice";

$(document).on("click", ".tasks a", function (e) {
  const $a = $(this);
  const $tasks = $a.closest(".tasks");
  const objId = $tasks.data("obj_id");

  let href = $a.attr("href");

  if (href.includes("_id__exact")) {
    e.preventDefault();
    e.stopPropagation();

    if (!href.includes("_compact")) {
      href += "&_compact=1";
    }

    const win = window.open(href, "", "width=1030,height=600");
    const timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        $.ajax({
          method: "POST",
          url: "/api/_internal/admin_render",
          data: {
            path: window.location.pathname,
            obj_id: objId,
            field: "_actions",
          },
          dataType: "json",
          success: function (data) {
            $tasks.replaceWith(data.html);
          },
        });
      }
    }, 100);
  }
});

// Textpattern code syntax high-lightning
$(function (): void {
  const $textarea = $("#id_code")[0];
  // @ts-ignore
  if (!$textarea || $textarea.length === 0) return;
  const $sourceType = $("select#id_source_type");
  const $codeMirror = CodeMirror.fromTextArea($textarea, {
    lineNumbers: true,
    indentUnit: 4,
    extraKeys: {
      Tab: (cm: any) => cm.execCommand("indentMore"),
      "Shift-Tab": (cm: any) => cm.execCommand("indentLess"),
    },
  });

  function sourceTypeToLanguage(sourceType: string): string {
    switch (sourceType) {
      case "python-v1":
        return "python";
      case "jinja2-v1":
        return "jinja2";
      // exporter/importer
      case undefined:
        return "python";
      default:
        throw new Error("Not implemented: " + sourceType);
    }
  }

  function updateSourceLanguage(): void {
    $codeMirror.setOption(
      "mode",
      sourceTypeToLanguage($sourceType.val() as string)
    );
  }
  function changeHandler(): void {
    // /d/admin/planner/textpattern/47/change/ -> 47
    $(".field-sample_text dd").html(
      '<div class="ui active inline small loader"></div>'
    );
    const id = document.location.pathname.split("/")[5];
    const code = $codeMirror.getValue();
    $.ajax({
      method: "POST",
      url: "/api/_internal/planner/textpattern_generate",
      data: {
        textpattern_id: id,
        code: code,
      },
      dataType: "json",
      success: function (data) {
        $(".field-sample_text dd").html(data.text);
      },
      statusCode: {
        404: function () {
          const text = "TextPattern id=" + id + " does not exists.";
          $(".field-sample_text dd").html(text);
        },
        400: function (jqXHR) {
          $(".field-sample_text dd").html(jqXHR.responseText);
        },
      },
    });
  }
  $codeMirror.setSize(1024, 500);

  $("#preview-sample-text").on("click", changeHandler);

  updateSourceLanguage();
  $sourceType.change(updateSourceLanguage);
});

$(function (): void {
  const ta = $("textarea[data-use-code-editor]");
  if (ta.length > 0) {
    ta.each(function (i, obj) {
      const modeName = obj.getAttribute("data-code-editor-language");
      const height = obj.getAttribute("height");
      const width = obj.getAttribute("width");
      let mode = { name: modeName, json: false };
      if (modeName === "json") {
        mode = { name: "javascript", json: true };
      }
      const cm = CodeMirror.fromTextArea(obj, {
        lineNumbers: true,
        lineWrapping: true,
        mode: mode,
      });
      if (height && width) {
        cm.setSize(width, height);
      }
    });
  }
});
// Template field get tag id
$(function () {
  const $templateFieldForm = $("#templatefield_form");
  if ($templateFieldForm.length === 0) return;

  const $tagCategory = $templateFieldForm.find('[name="tag_category"]');
  const $tagType = $templateFieldForm.find('[name="tag_type"]');

  const lookup = $templateFieldForm.find("#lookup_id_sample_vocabulary");
  const lookupUrl = lookup.attr("href");

  const refresh = function (): void {
    const tagCategoryValue = $tagCategory.val() as string;
    const tagTypeValue = $tagType.val() as string;

    lookup.attr("href", lookupUrl);

    if (tagCategoryValue && tagTypeValue) {
      $.ajax({
        method: "POST",
        url: "/api/_internal/vocabulary/get_tag_id/",
        data: {
          tag_category_value: tagCategoryValue,
          tag_type_value: tagTypeValue,
        },
        dataType: "json",
        success: function (data) {
          if (data.tag_id) {
            const href = lookupUrl + "&tags__id__exact=" + data.tag_id;
            lookup.attr("href", href);
          }
        },
      });
    }
  };

  refresh();

  $tagCategory.change(refresh);
  $tagType.change(refresh);
});

// Customer update lookup href for id
$(function () {
  const $customerForm = $("#customer_form");
  if ($customerForm.length === 0) return;

  const lookup = $customerForm.find("#lookup_id_default_channel");
  const lookupUrl = lookup.attr("href");

  const refresh = function (): void {
    const customerId = $customerForm.find("[data-obj_id]").data("obj_id");

    const href = lookupUrl + "&customer_id__exact=" + customerId;
    lookup.attr("href", href);
  };

  refresh();
});

// Customer update lookup href for display name
$(function () {
  const $customerForm = $("#customer_form");
  if ($customerForm.length === 0) return;

  const lookup = $customerForm.find("#lookup_id_display_name_channel");
  const lookupUrl = lookup.attr("href");

  const refresh = function (): void {
    const customerId = $customerForm.find("[data-obj_id]").data("obj_id");

    const href = lookupUrl + "&customer_id__exact=" + customerId;
    lookup.attr("href", href);
  };

  refresh();
});

// Inriver update lookup href for subentity customer channel
$(function () {
  const $inriverConfigForm = $("#inriverconfig_form");
  if ($inriverConfigForm.length === 0) return;

  const lookup = $inriverConfigForm.find(".field-subentity_customer_channel a");
  const lookupUrl = lookup.attr("href");

  const refresh = function (): void {
    const customerId = $inriverConfigForm.find("[id=id_customer]").val();

    const href = lookupUrl + "&customer_id__exact=" + customerId;
    lookup.attr("href", href);
  };

  refresh();

  $(document).on("click", ".add-row a", function () {
    setTimeout(function () {
      refresh();
    });
  });
});

// Customer metrics texts view -> show only_first_publish by clicking on checkbox(form submit)
$(function () {
  $(".metrics-input").on("change", function () {
    $(".metrics-form").trigger("submit");
  });
});

const fetchCustomerMetricsHtml = (
  url: string,
  token: string,
  taskId: string,
  target: string,
  template?: string
): void => {
  debounce(() => {
    const request = $.ajax({
      url: url,
      type: "POST",
      data: { task_id: taskId, html_template: template },
      headers: {
        token: token,
      },
    });
    request.done((data) => {
      if (data.status === "SUCCESS") {
        $(target).html(data.content);
        // hide/show detailed machine translations table
        machineTranslationDetailedTableButtonClick($(target));
        // expand additional row info in detailed machine translations table
        machineTranslationMetricsOnActionClick($(target));
      } else {
        fetchCustomerMetricsHtml(url, token, taskId, target, template);
      }
    });
    request.fail(() => {
      showMessage(
        NotificationAppearance.ERROR,
        "An unexpected error occurred."
      );
    });
  }, 500)();
};

const machineTranslationMetricsOnActionClick = (
  element: JQuery<HTMLElement>
): void => {
  // expand rows with detailed infos
  const toggleSiblings = ({
    element,
    selector,
  }: {
    element: HTMLElement;
    selector: string;
  }): void => {
    $(element)
      .siblings(selector)
      .children()
      .each(function () {
        $(this).toggleClass("hide");
      });
  };
  $(element)
    .find(".action")
    .on("click", function () {
      toggleSiblings({ element: this, selector: ".source_text" });
      toggleSiblings({ element: this, selector: ".translated_text" });
      toggleSiblings({ element: this, selector: ".replacement_text_details" });
      $(this).find(".arrow").toggleClass("rotated");
    });
};

const machineTranslationDetailedTableButtonClick = (
  element: JQuery<HTMLElement>
): void => {
  const toggleElements = ({ selector }: { selector: string }): void => {
    $(element)
      .find(selector)
      .each(function () {
        $(this).toggle();
      });
  };
  const button = $(element).find("#detailed-table-button");
  if (button.length) {
    $(button).on("click", function () {
      $.each(["span", ".metrics-table.detailed-table"], function (i, val) {
        toggleElements({ selector: val });
      });
    });
  }
};

$(function () {
  const metricsForm = $(".metrics-form");
  if (metricsForm.length === 0) return;
  const url = metricsForm.data("url");
  const token = metricsForm.data("token");
  metricsForm.find('input[name="task_id"]').each(function () {
    fetchCustomerMetricsHtml(
      url,
      token,
      $(this).val() as string,
      $(this).data("target"),
      $(this).data("template")
    );
  });
});

$(document).on("click", "form#text-metrics-db > button", (e) => {
  const button = $(e.target);
  const textContent = button.text();
  button.text(`${textContent.slice(0, -1)}ing...`);
  button.addClass("disabled");
});

$(function () {
  const year = $("#id_master_metrics_year");
  const metric = $("#id_master_metrics");

  if (year && metric) {
    year.add(metric).on("change", () => {
      window.location.href = `/d/admin/master-metrics?year=${year.val()}&metric=${metric.val()}`;
    });
  }
});

// admin/customers/customer/quick-add
$(function () {
  const customerCopyFrom = $("#id_customer_copy_from");

  if (customerCopyFrom) {
    customerCopyFrom.on("change", function () {
      if (customerCopyFrom.val()) {
        $("#id_text_patterns").addClass("hidden");
      } else {
        $("#id_text_patterns").removeClass("hidden");
      }
    });
  }
});
