import React from "react";
import { Icon, Label, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { openInOverlay } from "../../utils/GlobalFunctions";
import { Product, ProductId } from "../product";
import { popupStyle } from "../../producttext/ProductTextToolbar";

const RightAligned = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  position: relative;
`;

const Button = styled(Label)`
  text-decoration: none;
`;

const ButtonIcon = styled(Icon)`
  margin: auto !important;
`;

const productListLinksPopup = { ...popupStyle, textAlign: "left" };

type Props = {
  product: Product;
  products: Product[];
  onOverlayClose?: (
    productId: ProductId,
    products: Product[],
    setProducts: (products: Product[]) => void
  ) => void;
  setProducts: (products: Product[]) => void;
};

export const ProductListLinks: React.FC<Props> = ({
  product,
  products,
  onOverlayClose,
  setProducts,
}) => {
  const productId = product.id;

  // relative to /d/<slug>/products/
  const productLink = `../product/${productId}/`;
  const publishLogsLink = `../manage/publish-logs/?product=${productId}`;

  const onClick = (link: string, e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    openInOverlay(link, () =>
      onOverlayClose?.(productId, products, setProducts)
    );
  };

  return (
    <div>
      <RightAligned>
        <Popup
          position={"top right"}
          content={"Edit the data and generation settings for this product"}
          inverted
          key={"Edit"}
          mouseEnterDelay={100}
          size="mini"
          style={productListLinksPopup}
          trigger={
            <div>
              <Button
                data-testid={"product-list-edit-button"}
                color="blue"
                size="medium"
                href={productLink}
                onClick={(e: React.MouseEvent<HTMLDivElement>): void =>
                  onClick(productLink, e)
                }
              >
                <Icon name="pencil" />
                Generation settings
              </Button>
            </div>
          }
        />
      </RightAligned>
      <RightAligned>
        <Popup
          position={"top right"}
          content={"Open the publish logs for this product"}
          inverted
          key={"History"}
          mouseEnterDelay={100}
          size="mini"
          style={productListLinksPopup}
          trigger={
            <div>
              <Button
                basic
                size="medium"
                href={publishLogsLink}
                onClick={(e: React.MouseEvent<HTMLDivElement>): void =>
                  onClick(publishLogsLink, e)
                }
              >
                <ButtonIcon name="history" />
              </Button>
            </div>
          }
        />
      </RightAligned>
    </div>
  );
};
