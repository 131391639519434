import { BalloonDiv } from "../../components/BalloonDiv";
import { VocabularyLookupNewComp } from "../../vocabulary/VocabularyLookupNew";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { ProductTagAction } from "../../api/action";
import { NOOP } from "../../index";
import React from "react";
import { getCustomerSettings } from "../../customers/customersettings";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import { formatTag, VocabularyTreeData } from "../../utils/tagutils";
import { uuidv4 } from "../../utils/uuidUtils";
import { FieldRecommendation } from "./ProductRecommendations";
import { FieldInfo } from "./TagFields";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Label } from "semantic-ui-react";

type Props = {
  details: ProductDetailInfo;
  field: FieldInfo;
  fieldRecommendations: FieldRecommendation[];
  onTagsAction: onTagsActionCallback;
  selected: SidebarItem;
  tags: VocabularyTreeData[];
};

/**
 * Field for a specific tag, one for each type See {@link FieldType}
 * @param props
 * @constructor
 */
export const TagField: React.FC<Props> = ({
  details,
  field,
  fieldRecommendations,
  onTagsAction,
  selected,
  tags,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }
  let recommendations = null;
  if (fieldRecommendations.length > 0) {
    recommendations = fieldRecommendations.map((recommendation) => {
      return (
        <Label
          as="a"
          color="black"
          basic
          size="tiny"
          className="small-border"
          key={recommendation.vocabulary_id}
          onClick={(): void => {
            onTagsAction({
              action: ProductTagAction.ADD_RECOMMENDATION,
              parent: selected.isSubpart ? selected.id : null,
              headline: field.label,
              vocabulary: recommendation.vocabulary_id,
            });
          }}
        >
          {formatTag(recommendation, {
            includeSubTagNames: true,
          })}
        </Label>
      );
    });
  }
  return (
    <>
      <BalloonDiv
        className={"label"}
        value={details.microcopies[field.fieldName]}
        data-testid={"tag-field"}
      >
        <label htmlFor={field.fieldName}>{field.label}</label>
      </BalloonDiv>
      <VocabularyLookupNewComp
        {...field}
        {...getCustomerSettings(customer)}
        componentId={details.createComponentId()}
        disableAutoAdd={false}
        headline={field.label}
        showVocabType={false}
        isMultiple={true}
        language={details.getLanguage()}
        limit={20}
        onVocabularyAdded={(value: VocabularyTreeData): void => {
          value.id = uuidv4();
          onTagsAction({
            action: ProductTagAction.ADD,
            parent: selected.isSubpart ? selected.id : null,
            value,
          });
        }}
        onVocabularyRemoved={(value: VocabularyTreeData): void => {
          onTagsAction({
            action: ProductTagAction.REMOVE,
            value: [value.id],
          });
        }}
        onVocabularyCleared={(): void => {
          onTagsAction({
            action: ProductTagAction.CLEAR,
            tagids: tags.map((tag) => tag.id),
          });
        }}
        onLoaded={NOOP}
        placeholder={
          details.microcopies[`searchbox_${field.fieldName}`] ||
          "Start typing to search"
        }
        selectedTags={tags}
        showTagTypeHints={false}
        tagCategory={field.category}
        tagType={field.type}
        textualAppName={details.textualAppName}
        vocabularyRequestProductDescription={""}
        vocabularyRequestProductId={details.productId}
      />
      <div className={"recommend-box"}>{recommendations}</div>
    </>
  );
};
