import {
  AnyAction,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareArray,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import authReducer from "../api/authSlice";
import customQueryReducer from "../api/customQuerySlice";
import djangoToastReducer from "../api/djangoToastSlice";
import productListReducer from "../api/productListSlice";
import productDetailEnrichTabReducer from "../api/productDetailEnrichTabSlice";
import requestWordModalReducer from "../api/requestWordModalSlice";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { ImmutableStateInvariantMiddlewareOptions } from "@reduxjs/toolkit/src/immutableStateInvariantMiddleware";
import { SerializableStateInvariantMiddlewareOptions } from "@reduxjs/toolkit/src/serializableStateInvariantMiddleware";
import { authApi } from "../api/authApi";
import { customerApi } from "../api/customerApi";
import { productDetailApi } from "../api/productDetailSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { customQueryApi } from "../api/customQueryApi";
import { APIError } from "../api/api";
import { viewSetApi } from "../api/viewSetApi";
import { machineTranslationConfigApi } from "../api/machineTranslationConfigSlice";
import { rewritePromptApi } from "../api/rewritePromptSlice";

export const storeReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [customQueryApi.reducerPath]: customQueryApi.reducer,
  [productDetailApi.reducerPath]: productDetailApi.reducer,
  [viewSetApi.reducerPath]: viewSetApi.reducer,
  [machineTranslationConfigApi.reducerPath]:
    machineTranslationConfigApi.reducer,
  [rewritePromptApi.reducerPath]: rewritePromptApi.reducer,
  auth: authReducer,
  customQuery: customQueryReducer,
  djangoToast: djangoToastReducer,
  productList: productListReducer,
  productDetailEnrichTab: productDetailEnrichTabReducer,
  requestWordModal: requestWordModalReducer,
};

interface ThunkOptions<E = any> {
  extraArgument: E;
}

interface GetDefaultMiddlewareOptions {
  thunk?: boolean | ThunkOptions;
  immutableCheck?: boolean | ImmutableStateInvariantMiddlewareOptions;
  serializableCheck?: boolean | SerializableStateInvariantMiddlewareOptions;
}

export const RTKQueryCustomErrorHandler: Middleware = () => (next) => (
  action: any
): any => {
  if (isRejectedWithValue(action)) {
    const { request, response } = action.meta.baseQueryMeta;
    let message;
    if (typeof action.payload.data == "object") {
      message =
        action.payload.data?.message ??
        `"${action.meta.arg.endpointName}": ${response.status} ${JSON.stringify(
          action.payload.data
        )}`;
    } else {
      message = `"${action.meta.arg.endpointName}": ${response.status} ${response.statusText}`;
    }
    return Promise.reject(
      new APIError(
        `ERROR in backend callback:
          responseURL=${response.url}
          method=${request.method}
          data=${action.type}
          message=${message}
          status=${response.status}
          errors=${action.error.message}`,
        {
          data: {
            message,
          },
          status: response.status,
          statusText: response.statusText,
          headers: request.headers,
          config: null,
        }
      )
    );
  }

  return next(action);
};

export const storeMiddleware = ({
  getDefaultMiddleware,
  options = null,
}: {
  getDefaultMiddleware: CurriedGetDefaultMiddleware;
  // set different options ie. for test storeMiddleware
  options?: GetDefaultMiddlewareOptions;
}): MiddlewareArray<any> => {
  let storeMiddlewareOptions: GetDefaultMiddlewareOptions;
  if (!options) {
    storeMiddlewareOptions = {
      serializableCheck: {
        // FIXME: Customer should not be a class
        ignoredActions: [
          "authApi/executeQuery/fulfilled",
          "customerApi/executeQuery/fulfilled",
          "customQueryApi/executeMutation/fulfilled",
          "productDetailApi/executeQuery/fulfilled",
          "productDetailApi/executeMutation/fulfilled",
          "viewSetApi/executeQuery/fulfilled",
          "viewSetApi/executeMutation/fulfilled",
          "machineTranslationConfigApi/executeQuery/fulfilled",
          "machineTranslationConfigApi/executeMutation/fulfilled",
          "rewritePromptApi/executeQuery/fulfilled",
          "rewritePromptApi/executeMutation/fulfilled",
        ],
        ignoredActionPaths: ["payload"],
        ignoredPaths: [
          "authApi.queries.getAuth(undefined).data",
          "customerApi.queries.getCustomer(undefined).data",
          "productDetailApi.queries.getProduct(undefined).data",
          "machineTranslationConfigApi.queries.getMachineTranslationConfigs(undefined).data",
          "rewritePromptApi.queries.getRewritePrompts(undefined).data",
        ],
      },
    };
  }
  return getDefaultMiddleware(options || storeMiddlewareOptions).concat(
    RTKQueryCustomErrorHandler,
    customQueryApi.middleware,
    authApi.middleware,
    customerApi.middleware,
    productDetailApi.middleware,
    viewSetApi.middleware,
    machineTranslationConfigApi.middleware,
    rewritePromptApi.middleware
  );
};

export const store = configureStore({
  reducer: storeReducer,
  middleware: (getDefaultMiddleware) =>
    storeMiddleware({ getDefaultMiddleware }),
});

export type RootState = ReturnType<typeof store.getState>;
export type ThunkDispatchType = ThunkDispatch<any, never, AnyAction>;
export type StoreDispatch = ReturnType<typeof store.dispatch>;

setupListeners(store.dispatch);
