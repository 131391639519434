import React from "react";
import { TemplateItem } from "./template";
import { TemplateSelector } from "./TemplateSelector";
import { TemplateLabelsCollection } from "../components/TemplateLabelsCollection";
import { getTemplates } from "../api/templateVocabularyApi";
import { SortOption } from "../api/vocabularyApi";
export type SubmitCallback = (item: TemplateItem) => Promise<void>;

type Props = {
  token: string;
  name: string;
  onSubmit: SubmitCallback;
};

export const TemplateSelectorContainer: React.FC<Props> = ({
  token,
  name,
  onSubmit,
}) => {
  const onRenderRow = (template: TemplateItem): JSX.Element => (
    <>
      <p className="template-display-name">{template.display_name}</p>
      {template && (
        <TemplateLabelsCollection
          colorPrimary
          labels={template.labels}
          size="mini"
        />
      )}
    </>
  );

  return (
    <TemplateSelector
      name={name}
      onFetchItems={async (): Promise<TemplateItem[]> =>
        await getTemplates(token)
      }
      onRenderRow={(template): JSX.Element => onRenderRow(template)}
      onSortChange={async (order: SortOption): Promise<TemplateItem[]> =>
        await getTemplates(token, { order })
      }
      onSubmit={onSubmit}
      title={"Choose data template"}
    />
  );
};
