import React, { useEffect, useRef, useState } from "react";
import {
  MatrixRowType,
  CustomerChannelLanguageMatrix,
} from "./CustomerChannelLanguageMatrix";
import styled from "styled-components";
import { FooterMatrixType } from "./ChannelLanguageSettingsFooter";
import { RootState } from "../utils/store";
import {
  ChannelLanguageSettingsType,
  getChannelLanguageSettings,
  updateChannelLanguageSettings,
} from "../api/manageApi";
import { useSelector } from "react-redux";
import { ChannelEditButton } from "../products/channel-tab/ChannelEditButton";
import { ChannelRemoveButton } from "../products/channel-tab/ChannelRemoveButton";
import { Button } from "semantic-ui-react";

export const StyledTableWrapper = styled.div`
  width: 1000px;
  margin: 0 auto 40px;
`;

export const ChannelLanguageSettings: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [
    fetchedChannelLanguageMatrix,
    setFetchedChannelLanguageMatrix,
  ] = useState<ChannelLanguageSettingsType>(null);
  // if immidiatelly request to backend is needed, check changes on Refs
  const channelLanguageMatrixRef = useRef<MatrixRowType[]>([]);
  const channelSettingsFooterRef = useRef<FooterMatrixType[]>([]);

  const onChangeChannelLanguageMatrixRef = (payload: MatrixRowType[]): void => {
    channelLanguageMatrixRef.current = payload;
  };

  const onChangeChannelSettingsFooterRef = (
    payload: FooterMatrixType[]
  ): void => {
    channelSettingsFooterRef.current = payload;
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getChannelLanguageSettings({
        token,
        showFooter: true,
      });
      setFetchedChannelLanguageMatrix(response);
    })();
  }, []);

  const onClick = (): void => {
    updateChannelLanguageSettings({
      token,
      channelLanguageMatrix: channelLanguageMatrixRef.current,
      channelSettingsFooter: channelSettingsFooterRef.current,
    }).then(() => {
      window.location.reload();
    });
  };
  return (
    <div>
      <StyledTableWrapper>
        <Button
          color={"red"}
          content={"Save"}
          onClick={onClick}
          size={"large"}
          className="tw-float-right !tw-my-4"
        />
        <CustomerChannelLanguageMatrix
          buttonRows={[ChannelEditButton, ChannelRemoveButton]}
          footerRows={[
            {
              name: "main_default",
              display_name: "Main default",
              radio_button: true,
            },
            {
              name: "show_in_preview",
              display_name: "Show in preview",
              radio_button: false,
            },
          ]}
          fetchedChannelLanguageMatrix={fetchedChannelLanguageMatrix}
          onChangeChannelLanguageMatrixRef={onChangeChannelLanguageMatrixRef}
          onChangeChannelSettingsFooterRef={onChangeChannelSettingsFooterRef}
        />
      </StyledTableWrapper>
    </div>
  );
};
