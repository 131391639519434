import React from "react";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  checkboxId?: string;
};

export const ToggleLabel: React.FC<Omit<Props, "setChecked">> = ({
  checkboxId,
  checked,
  label,
  disabled,
}) => {
  return (
    <label
      htmlFor={checkboxId}
      className={`${
        disabled
          ? "tw-text-gray-300"
          : checked
          ? "tw-text-primary"
          : "tw-text-secondary"
      } tw-text-sm`}
    >
      {label}
    </label>
  );
};

export const ToggleCheckbox: React.FC<Props> = ({
  disabled,
  checked,
  setChecked,
  checkboxId,
  ...rest
}) => {
  return (
    <div
      className="tw-relative tw-cursor-pointer"
      onClick={(): void => setChecked(!checked)}
    >
      <input
        id={checkboxId}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={(): void => setChecked(!checked)}
        className="tw-sr-only"
        {...rest}
      />
      <div
        className={`tw-block tw-h-6 tw-w-10 tw-rounded-full ${
          disabled
            ? "tw-bg-gray-300"
            : checked
            ? "tw-bg-primary"
            : "tw-bg-secondary"
        }`}
      ></div>
      <div
        className={`tw-absolute tw-left-1 tw-top-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full ${
          disabled ? "tw-bg-gray-100" : "tw-bg-white"
        } tw-transition ${checked ? "tw-translate-x-full" : ""}`}
      ></div>
    </div>
  );
};

export const Toggle: React.FC<Omit<Props, "checkboxId">> = ({
  checked,
  setChecked,
  label,
  disabled,
  ...rest
}) => {
  return (
    <label
      className={`tw-flex ${
        disabled ? "tw-cursor-default" : "tw-cursor-pointer"
      } tw-select-none tw-items-center tw-gap-2`}
    >
      <div className="tw-relative">
        <input
          disabled={disabled}
          type="checkbox"
          checked={checked}
          onChange={(): void => setChecked(!checked)}
          className="tw-sr-only"
          {...rest}
        />
        <div
          className={`tw-block tw-h-6 tw-w-10 tw-rounded-full ${
            disabled
              ? "tw-bg-gray-300"
              : checked
              ? "tw-bg-primary"
              : "tw-bg-secondary"
          }`}
        ></div>
        <div
          className={`tw-absolute tw-left-1 tw-top-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full ${
            disabled ? "tw-bg-gray-100" : "tw-bg-white"
          } tw-transition ${checked ? "tw-translate-x-full" : ""}`}
        ></div>
      </div>
      <span
        className={`${
          disabled
            ? "tw-text-gray-300"
            : checked
            ? "tw-text-primary"
            : "tw-text-secondary"
        } tw-text-sm`}
      >
        {label}
      </span>
    </label>
  );
};
