import api from "./api";
import { LanguageCode } from "../customers/customerlanguages";

export const REQUEST_PROOFREADING_URL =
  "/api/_internal/customer/request-proofreading";

/**
 * Request proofreading of a file that have already been uploaded (e.g. they were machine translated)
 */
export async function requestProofreading(
  token: string,
  emailDelivery: string,
  originalText: string,
  originalFile: string,
  originalLanguage: string,
  requestedText: string,
  requestedFile: string,
  requestedLanguages: string[],
  isUrgent: boolean,
  additionalInstructions: string
): Promise<string> {
  const data = {
    email_delivery: emailDelivery,
    original_text: originalText,
    original_file: originalFile,
    original_language: originalLanguage,
    requested_text: requestedText,
    requested_file: requestedFile,
    requested_languages: requestedLanguages,
    is_urgent: isUrgent,
    additional_instructions: additionalInstructions,
  };
  const response = await api.post(REQUEST_PROOFREADING_URL, data, {
    headers: { token: token },
  });
  return response.data;
}

export interface FileItem {
  file: File;
  isInvalid: boolean;
  isSource: boolean;
  language: LanguageCode;
}

export const REQUEST_PROOFREADING_FILES_URL =
  "/api/_internal/customer/request-proofreading-files";

/**
 * Upload multiple files for proofreading
 */
export async function requestProofreadingFiles(
  token: string,
  emailDelivery: string,
  original: FileItem | null,
  requested: FileItem[],
  isUrgent: boolean,
  additionalInstructions: string
): Promise<string> {
  const formData = new FormData();
  if (original) {
    formData.append("original_file", original.file, original.file.name);
    formData.append("original_language", original.language);
  }
  requested.forEach((fileItem) => {
    formData.append("requested_files", fileItem.file, fileItem.file.name);
    formData.append("requested_languages", fileItem.language);
  });
  formData.append("is_urgent", isUrgent ? "true" : "");
  formData.append("additional_instructions", additionalInstructions);
  formData.append("email_delivery", emailDelivery);
  const response = await api.post(REQUEST_PROOFREADING_FILES_URL, formData, {
    headers: { token: token, "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

/**
 * Request proofreading of Documents in the document list
 */
export async function requestProofreadingDocuments(
  token: string,
  emailDelivery: string,
  originalLanguage: string,
  requestedLanguages: string[],
  isUrgent: boolean,
  additionalInstructions: string
): Promise<string> {
  const data = {
    email_delivery: emailDelivery,
    original_language: originalLanguage,
    requested_languages: requestedLanguages,
    is_urgent: isUrgent,
    additional_instructions: additionalInstructions,
  };
  const response = await api.post(REQUEST_PROOFREADING_URL, data, {
    headers: { token: token },
  });
  return response.data;
}

export const REQUEST_MANUAL_TRANSLATION_URL =
  "/api/_internal/customer/request-manual-translation";

export async function requestManualTranslation(
  token: string,
  emailDelivery: string,
  originalFile: File,
  originalLanguage: string,
  requestedLanguages: string[],
  isUrgent: boolean,
  additionalInstructions: string = ""
): Promise<string> {
  const formData = new FormData();
  formData.append("original_file", originalFile, originalFile.name);
  formData.append("original_language", originalLanguage);
  formData.append("is_urgent", isUrgent ? "true" : "");
  formData.append("additional_instructions", additionalInstructions);
  formData.append("email_delivery", emailDelivery);
  requestedLanguages.forEach((lang) => {
    formData.append("requested_languages", lang);
  });
  const response = await api.post(REQUEST_MANUAL_TRANSLATION_URL, formData, {
    headers: { token: token, "Content-Type": "multipart/form-data" },
  });
  return response.data;
}
