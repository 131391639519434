import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { LanguageCode } from "../../../customers/customerlanguages";
import { MachineTranslationConfig } from "./MachineTranslationConfig";
import range from "lodash/range";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import { Button } from "../../../components/tailwind";
import {
  MachineTranslationConfigGroup,
  MachineTranslationConfigWithSelection,
} from "./types";
import {
  useCreateMachineTranslationConfigMutation,
  useDeleteMachineTranslationConfigMutation,
  useUpdateMachineTranslationConfigMutation,
} from "../../../api/machineTranslationConfigSlice";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import { store } from "../../../utils/store";
import { Prompt } from "../../../products/copy-assistant/types";

export const LoadingTableColContent: React.FC = () => {
  return (
    <div
      className="tw-h-6 tw-animate-pulse tw-rounded-md tw-bg-gray-200"
      data-testid="loading-table-column-content"
    ></div>
  );
};

const LoadingTableRows: React.FC<{ cols: number; rows: number }> = ({
  cols,
  rows,
}) => {
  return (
    <>
      {range(0, rows).map((row) => (
        <tr key={row} data-testid="loading-table-row">
          {range(0, cols).map((col) => (
            <td key={col}>
              <LoadingTableColContent />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const getDefaultConfigState = (
  language: LanguageCode,
  selected: boolean
): MachineTranslationConfigWithSelection => ({
  isSelected: selected,
  machine_translation_service: "DEEPL",
  language,
});

type Props = {
  selectedConfigGroup: MachineTranslationConfigGroup;
  isCopying: boolean;
  isDefaultConfig: boolean;
  onSelect: (id: number) => void;
  resetAllParams: () => void;
  translationPrompts: Prompt[];
  isFetchingPrompts: boolean;
};

export const MachineTranslationConfigGroupForm: React.FC<Props> = ({
  selectedConfigGroup,
  isCopying,
  isDefaultConfig,
  onSelect,
  resetAllParams,
  translationPrompts,
  isFetchingPrompts,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const [
    createConfig,
    { isLoading: isCreating },
  ] = useCreateMachineTranslationConfigMutation();
  const [
    updateConfig,
    { isLoading: isUpdating },
  ] = useUpdateMachineTranslationConfigMutation();
  const [
    deleteConfig,
    { isLoading: isDeleting },
  ] = useDeleteMachineTranslationConfigMutation();

  const customerLanguages = useMemo(() => {
    if (!customer) return [];
    return customer.languages;
  }, [customer]);

  const includeAllCheckboxRef = useRef<HTMLInputElement>();

  // Customer changed configs
  const [changedConfigs, setChangedConfigs] = useState<
    MachineTranslationConfigWithSelection[]
  >([]);

  // Default/selectedConfigGroups configs
  const initialConfigs = useMemo<
    MachineTranslationConfigWithSelection[]
  >(() => {
    const existingConfigs = selectedConfigGroup?.configs || [];
    return customerLanguages.map(({ code }) => {
      const existingConfig = existingConfigs.find(
        ({ language }) => language === code
      );
      if (existingConfig) return { ...existingConfig, isSelected: true };
      return getDefaultConfigState(code, selectedConfigGroup?.is_default);
    });
  }, [selectedConfigGroup, customerLanguages]);

  // Combined changedConfig and initialConfigs
  const combinedConfigs = useMemo<
    MachineTranslationConfigWithSelection[]
  >(() => {
    const changedConfigsLanguages = changedConfigs.map(
      ({ language }) => language
    );
    const filteredInitialConfigs = initialConfigs.filter(
      ({ language }) => !changedConfigsLanguages.includes(language)
    );
    return [...changedConfigs, ...filteredInitialConfigs];
  }, [changedConfigs, initialConfigs]);

  const [changedConfigGroupName, setChangedConfigGroupName] = useState<string>(
    null
  );

  const initialConfigGroupName = selectedConfigGroup
    ? isCopying
      ? `${selectedConfigGroup.name}-copy`
      : selectedConfigGroup.name
    : "";

  const configGroupName = isNull(changedConfigGroupName)
    ? initialConfigGroupName
    : changedConfigGroupName;

  const selectedLanguages = useMemo(() => {
    return combinedConfigs
      .filter(({ isSelected }) => isSelected)
      .map(({ language }) => language);
  }, [combinedConfigs]);

  const allLanguagesIncluded = useMemo(() => {
    return selectedLanguages.length === customerLanguages.length;
  }, [selectedLanguages, customerLanguages]);

  //? All languages selection checkbox control
  useEffect(() => {
    if (!includeAllCheckboxRef.current || !customerLanguages.length) return;

    includeAllCheckboxRef.current.indeterminate =
      !!selectedLanguages.length && !allLanguagesIncluded;

    includeAllCheckboxRef.current.checked = allLanguagesIncluded;
  }, [
    includeAllCheckboxRef,
    selectedLanguages,
    customerLanguages,
    allLanguagesIncluded,
  ]);

  const onSelectAllLanguages = (): void => {
    setChangedConfigs(
      combinedConfigs.map((config) => ({
        ...config,
        isSelected: !allLanguagesIncluded,
      }))
    );
    return;
  };

  const onChangeConfigState = (
    languageCode: LanguageCode,
    newPartialConfig: Partial<MachineTranslationConfigWithSelection>
  ): void => {
    const configToUpdate = {
      ...combinedConfigs.find(({ language }) => language === languageCode),
      ...newPartialConfig,
    };

    setChangedConfigs((prev) => [
      ...prev.filter(({ language }) => language !== languageCode),
      configToUpdate,
    ]);
  };

  const onSaveConfig = async (): Promise<void> => {
    const configGroup: Omit<MachineTranslationConfigGroup, "id"> = {
      name: configGroupName,
      is_default: false,
      configs: cloneDeep(combinedConfigs)
        .filter(({ isSelected }) => isSelected)
        .map((config) => {
          delete config.isSelected;
          return config;
        }),
    };
    //? The save action will create a new config group
    if (!selectedConfigGroup || isCopying) {
      const createdGroup = await createConfig(configGroup).unwrap();
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.SUCCESS,
          content: `Created Settings Group: ${configGroup.name}`,
        })
      );
      onSelect(createdGroup.id);
      return;
    }

    //? The save action will update a existing config group
    (configGroup as MachineTranslationConfigGroup).id = selectedConfigGroup.id;
    await updateConfig(configGroup as MachineTranslationConfigGroup);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: `Updated Settings Group: ${configGroup.name}`,
      })
    );
  };

  const onDeleteConfig = async (): Promise<void> => {
    await deleteConfig(selectedConfigGroup?.id);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: `Deleted Settings Group: ${configGroupName}`,
      })
    );
    resetAllParams();
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-12 tw-px-2 tw-py-4">
      <section className="tw-flex tw-w-full tw-flex-col tw-gap-1.5">
        <label
          htmlFor="machine-translation-config-name"
          {...(isDefaultConfig && {
            className: "tw-text-gray-400",
          })}
        >
          Name *
        </label>
        <input
          data-testid="machine-translation-config-group-name"
          type="text"
          id="machine-translation-config-name"
          className="tw-form-input tw-block tw-w-full tw-rounded-md tw-border-0 tw-p-2.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 disabled:tw-text-gray-400 disabled:tw-ring-gray-100"
          placeholder="My Machine Translation Settings"
          onChange={({ target }): void =>
            setChangedConfigGroupName(target.value)
          }
          value={configGroupName}
          disabled={isDefaultConfig}
        />
        <p
          className={`tw-text-gray-500 tw-flex tw-items-center tw-gap-1 ${
            isDefaultConfig ? "tw-visible" : "tw-invisible"
          }`}
          data-testid="machine-translation-config-group-is-default-info"
        >
          <i className="material-icons tw-text-lg tw-leading-[1]">info</i> This
          is the default setting, some fields are read-only
        </p>
      </section>
      <section>
        <table
          className="txu-table txu-compact"
          data-testid="machine-translation-config-group-table"
        >
          <thead>
            <tr>
              <th>
                <label
                  data-balloon="Include all Languages"
                  data-balloon-pos="up-left"
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  <input
                    data-testid="machine-translation-config-group-table-include-all-languages"
                    type="checkbox"
                    ref={includeAllCheckboxRef}
                    disabled={isLoading || isDefaultConfig}
                    className="tw-form-checkbox tw-h-4 tw-w-4 tw-rounded tw-border-gray-400 tw-text-textual-blue indeterminate:tw-text-gray-500 focus:tw-ring-gray-400 disabled:tw-border-gray-200 disabled:tw-text-gray-200"
                    onChange={onSelectAllLanguages}
                    onKeyDown={(event): void => {
                      if (event.key === "Enter") {
                        onSelectAllLanguages();
                      }
                    }}
                  />
                  Included Languages
                </label>
              </th>
              <th>Translation Service</th>
              <th>Additional settings</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <LoadingTableRows cols={4} rows={8} />
            ) : (
              <>
                {customerLanguages.map((language) => (
                  <MachineTranslationConfig
                    config={combinedConfigs?.find(
                      (config) => config.language === language.code
                    )}
                    disabled={isDefaultConfig}
                    key={language.code}
                    language={language}
                    onChangeConfigState={onChangeConfigState}
                    translationPrompts={translationPrompts.filter(
                      (prompt) => prompt.language === language.code
                    )}
                    isFetchingPrompts={isFetchingPrompts}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </section>
      <section className="tw-flex">
        <Button
          data-testid="machine-translation-config-group-save"
          variant="primary"
          disabled={!selectedLanguages.length || !configGroupName || isDeleting}
          onClick={onSaveConfig}
          loading={isCreating || isUpdating}
        >
          Save
        </Button>
        {!isDefaultConfig && !!selectedConfigGroup && !isCopying && (
          <Button
            data-testid="machine-translation-config-group-remove"
            className="tw-ml-auto"
            onClick={onDeleteConfig}
            loading={isDeleting}
            disabled={isUpdating}
          >
            Remove
          </Button>
        )}
      </section>
    </div>
  );
};
