import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dimmer, Header, Icon, Popup } from "semantic-ui-react";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import {
  createPipeline,
  deletePipeline,
  updatePipeline,
} from "../../../api/pipelineApi";
import { Text } from "../../../components/Text";
import ManageGrid from "../../../components/manage/ManageGrid";
import { popupDelay } from "../../../customers/gpt/types";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import { RootState, store } from "../../../utils/store";
import { PipelineForm } from "./PipelineForm";
import { useGetPipelines } from "./customHooks";
import { PartialPipeline, Pipeline, UpdatePipeline } from "./types";
import { ListPipelines } from "./ListPipelines";

enum FormMode {
  CREATE,
  UPDATE,
  COPY,
}
const headers = {
  [FormMode.CREATE]: (): JSX.Element => <>New Pipeline</>,
  [FormMode.UPDATE]: (
    pipelineName: string,
    pipelineId?: number
  ): JSX.Element => (
    <>
      Updating: {pipelineName} {!!pipelineId && `(${pipelineId})`}
    </>
  ),
  [FormMode.COPY]: (pipelineName: string, pipelineId?: number): JSX.Element => (
    <>
      Copying: {pipelineName} {!!pipelineId && `(${pipelineId})`}
    </>
  ),
} as const;

export const Pipelines: React.FC = () => {
  const { data: pipelines, isFetching, reFetch } = useGetPipelines();
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    setParam,
    removeParam,
    urlState: { pipeline_id },
  } = useUrlState<{ pipeline_id: number }>();
  const [header, setHeader] = useState(headers[FormMode.CREATE]());
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const hasCheckedOnMount = useRef(false);
  const selectedPipeline = useMemo(
    () => pipelines.find(({ id }) => id === pipeline_id),

    [pipeline_id, pipelines]
  );

  useEffect(() => {
    if (isFetching) return;
    if (pipelines.length && pipeline_id && !hasCheckedOnMount.current) {
      setFormMode(FormMode.UPDATE);
    }
    hasCheckedOnMount.current = true;
  }, [pipelines, pipeline_id, isFetching]);

  useEffect(() => {
    if (formMode !== FormMode.COPY) {
      setHeader(
        headers[formMode](selectedPipeline?.name, selectedPipeline?.id)
      );
    }
  }, [formMode, selectedPipeline]);

  const handleSelectPipeline = (pipelineId: number): void => {
    setFormMode(FormMode.UPDATE);
    setParam("pipeline_id", pipelineId);
  };

  const handleCreatePipeline = async (
    data?: PartialPipeline,
    isCopying?: boolean
  ): Promise<void> => {
    if (!isCopying) {
      setFormMode(FormMode.CREATE);
    }
    const createdPipeline = await createPipeline(token, data);
    setParam("pipeline_id", createdPipeline.id);
    await reFetch();
  };

  const handleCopyPipeline = async (data: Pipeline): Promise<void> => {
    const newName = data.name + " Copy";
    setFormMode(FormMode.COPY);
    setHeader(headers[FormMode.COPY](data.name, data.id));
    await handleCreatePipeline({ ...data, name: newName }, true);
  };

  const handleUpdatePipeline = async (
    data: UpdatePipeline,
    id: number
  ): Promise<void> => {
    setFormMode(FormMode.UPDATE);
    const updatedPipeline = await updatePipeline(token, data, id);
    setParam("pipeline_id", updatedPipeline.id);
    await reFetch();
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Pipeline saved",
      })
    );
  };

  const handleDeletePipeline = async (id: number): Promise<void> => {
    await deletePipeline(token, id);
    await reFetch();
    setFormMode(FormMode.CREATE);
    removeParam("pipeline_id");
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.WARNING,
        content: "Pipeline deleted",
      })
    );
  };

  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                wide
                size="tiny"
                mouseEnterDelay={popupDelay}
                content="Pipelines are used to automate workflows"
                trigger={
                  <span>
                    <Text as="h4" compact inline width="max">
                      Pipelines
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              />
            }
            right={
              <Popup
                size="tiny"
                content="Create Pipeline"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Button
                    size="tiny"
                    color="red"
                    icon="plus"
                    compact
                    basic
                    onClick={(): Promise<void> =>
                      handleCreatePipeline({
                        name: `New pipeline ${pipelines.length + 1}`,
                      })
                    }
                  />
                }
              />
            }
          />
          <ManageGrid.Header.RightColumn>
            <div>
              <Text compact as="h3" inline>
                {header}
              </Text>
            </div>
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <ListPipelines
              pipelines={pipelines}
              selectedPipeline={selectedPipeline}
              handleSelectPipeline={handleSelectPipeline}
              handleCopyPipeline={handleCopyPipeline}
            />
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {selectedPipeline ? (
              <PipelineForm
                selectedPipeline={selectedPipeline}
                handleUpdatePipeline={handleUpdatePipeline}
                handleDeletePipeline={handleDeletePipeline}
              />
            ) : (
              <ManageGrid.Body.EmptyForm>
                <Button
                  data-testid="manage-pipelines-create-new-big-button"
                  content="Create new Pipeline"
                  icon="plus"
                  color="red"
                  size="large"
                  onClick={(): Promise<void> =>
                    handleCreatePipeline({
                      name: `New pipeline ${pipelines.length + 1}`,
                    })
                  }
                />
              </ManageGrid.Body.EmptyForm>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
      <Dimmer active={isFetching} page>
        <Header as="h4" icon inverted>
          <Icon
            name="circle notch"
            loading
            data-testid="manage-pipelines-page-loader"
          />
        </Header>
      </Dimmer>
    </div>
  );
};
