import React, { useMemo, useState } from "react";
import { Checkbox, DropdownItemProps } from "semantic-ui-react";
import { StatelessSelectPromptDropdownMenu } from "../../copy-assistant/Toolbar/StatelessSelectPromptDropdownMenu";
import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  useGetPromptGroups,
  useGetPrompts,
} from "../../copy-assistant/customHooks";
import {
  findLanguage,
  groupPromptGroupsByTags,
  groupPromptsByTags,
} from "../../copy-assistant/utils";
import { Prompt, PromptGroup } from "../../copy-assistant/types";
import { generateQuickAction } from "../../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { URLConfigParser } from "../ProductListURLConfig";
import {
  RedBackgroundMenuItemDropdown,
  SemanticPopupWithReactRef,
} from "../../../ui/semantic-ui-overwrites";
import { AdvanceFilterWrapper } from "../../copy-assistant/Toolbar/AdvanceFilter";
import { Flag } from "../../../customers/customerlanguages";

type Props = {
  disabled: boolean;
};

export const GenerateTextDropdown: React.FC<Props> = ({ disabled }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const bucketName = new URLConfigParser().getBucket();
  const returnUrl = window.location.pathname + window.location.search;

  // Dropdown state
  const [open, setOpen] = useState(false);

  // Dropdown menu state
  const [noPromptsShowing, setNoPromptsShowing] = useState<boolean>(false);

  // Advance filter state
  const [search, setSearch] = useState("");
  const [filterTags, setFilterTags] = useState<string[]>([]);

  // Generation settings state
  const [translate, setTranslate] = useState(false);

  const {
    data: { prompts },
    isFetching: isFetchingPrompts,
  } = useGetPrompts();

  const {
    data: promptGroups,
    isFetching: isFetchingPromptGroups,
  } = useGetPromptGroups();

  // init ordering of prompts/tags
  const customerOwnedPrompts = useMemo(() => {
    if (!prompts) return [];
    return prompts.filter(({ customer_id }) => !!customer_id);
  }, [prompts]);

  const systemOwnedPrompts = useMemo(() => {
    if (!prompts) return [];
    return prompts.filter(({ customer_id }) => !customer_id);
  }, [prompts]);

  const promptGroupTags = Object.keys(groupPromptGroupsByTags(promptGroups));

  const customerPromptTags = Object.keys(
    groupPromptsByTags(customerOwnedPrompts)
  );
  const systemPromptTags = Object.keys(groupPromptsByTags(systemOwnedPrompts));

  // combine loading states
  const loading = isLoading || isFetchingPrompts || isFetchingPromptGroups;

  // Advance filter functions
  const filterPromptList = (list: Prompt[]): DropdownItemProps[] => {
    let searchResult = list.filter(
      ({ name }) => name.toLowerCase().search(search.toLowerCase().trim()) != -1
    );

    filterTags.forEach((tag) => {
      searchResult = searchResult.filter((prompt) => prompt.tags.includes(tag));
    });
    return searchResult.map((prompt) => ({
      key: prompt.id,
      value: prompt.id,
      text: (
        <>
          <Flag content={findLanguage(prompt, customer)?.flag} /> {prompt.name}
        </>
      ),
    }));
  };

  const filterPromptGroupList = (list: PromptGroup[]): DropdownItemProps[] => {
    let searchResult = list.filter(
      ({ name }) => name.toLowerCase().search(search.toLowerCase().trim()) != -1
    );

    filterTags.forEach((tag) => {
      searchResult = searchResult.filter((group) => group.tags.includes(tag));
    });
    return searchResult.map((group) => ({
      key: group.id,
      value: group.id,
      text: group.name,
    }));
  };
  const availableTags = useMemo(
    () =>
      [
        ...new Set([
          ...customerPromptTags,
          ...systemPromptTags,
          ...promptGroupTags,
        ]),
      ].filter((tag) => tag !== "no tags"),
    [customerPromptTags, systemPromptTags]
  );

  // Dropdown menu functions
  const promptOptions = useMemo(() => {
    if (loading) return { customerPrompts: [], systemPrompts: [] };
    const customerPrompts = filterPromptList(customerOwnedPrompts);
    const systemPrompts = filterPromptList(systemOwnedPrompts);
    const filteredPromptGroups = filterPromptGroupList(promptGroups);
    if (
      !customerPrompts.length &&
      !systemPrompts.length &&
      !filteredPromptGroups.length
    ) {
      setNoPromptsShowing(true);
    } else {
      setNoPromptsShowing(false);
    }

    return {
      customerPrompts,
      promptGroups: filteredPromptGroups,
      systemPrompts,
    };
  }, [prompts, search, loading, filterTags, promptGroups]);

  const tagMapping = useMemo(() => {
    if (!prompts) return [];
    return [...prompts, ...promptGroups].map((item) => ({
      tags: item.tags || [],
      id: item.id,
    }));
  }, [prompts, promptGroups]);

  const startSimpleGenerateAction = async (id: number): Promise<void> => {
    setSearch("");
    setOpen(false);
    const redirect_url = await generateQuickAction(
      token,
      id,
      translate,
      bucketName,
      returnUrl
    );
    window.location.replace(redirect_url);
  };

  return (
    <>
      <SemanticPopupWithReactRef
        popupProps={{
          content: "Generate texts for selection using AI",
          disabled: open,
        }}
      >
        <RedBackgroundMenuItemDropdown
          item
          data-testid="quick-action-generate-ai-text-dropdown"
          style={open ? { opacity: 1 } : {}}
          floating
          text="Generate"
          loading={loading}
          disabled={loading || disabled}
          open={open}
          onClick={(): void => setOpen(!open)}
          onBlur={(): void => setOpen(false)}
        >
          <StatelessSelectPromptDropdownMenu
            selectedPrompt={null}
            handleSelectPrompt={startSimpleGenerateAction}
            tagMapping={tagMapping}
            promptOptions={promptOptions}
            open={open}
            noPromptsShowing={noPromptsShowing}
            search={search}
            setSearch={setSearch}
            availableTags={availableTags}
            selectedTags={filterTags}
            setSelectedTags={setFilterTags}
            generationSettings={
              <AdvanceFilterWrapper>
                <Checkbox
                  checked={translate}
                  onChange={(): void => setTranslate(!translate)}
                  label="Translate to all languages"
                  style={{ fontSize: "unset" }}
                />
              </AdvanceFilterWrapper>
            }
          />
        </RedBackgroundMenuItemDropdown>
      </SemanticPopupWithReactRef>
    </>
  );
};
