import React from "react";
import { TranslationModalFormData } from "./TranslationsModal";
import {
  MatrixRowType,
  CustomerChannelLanguageMatrix,
} from "../../customers/CustomerChannelLanguageMatrix";
import { ChannelLanguageMatrixItems } from "../../api/manageApi";

type Props = {
  channelLanguageCheckboxDisabled: boolean;
  translationModalFormData: TranslationModalFormData[];
  onChangeChannelLanguageMatrixRef: (payload: MatrixRowType[]) => void;
};

export const TranslationsModalTextPatternsAndLanguages: React.FC<Props> = ({
  channelLanguageCheckboxDisabled,
  translationModalFormData,
  onChangeChannelLanguageMatrixRef,
}) => {
  const getChannelLanguageMatrix = (): ChannelLanguageMatrixItems[] => {
    const data: ChannelLanguageMatrixItems[] = [];

    translationModalFormData.forEach((item) => {
      item.selectedCustomerChannelIds.forEach((channelId) => {
        item.selectedLanguageCodes.forEach((languageCode) => {
          data.push({
            channel_id: channelId,
            default: true,
            language_code: languageCode,
          });
        });
      });
    });
    return data;
  };

  return (
    <>
      <CustomerChannelLanguageMatrix
        disabled={channelLanguageCheckboxDisabled}
        fetchedChannelLanguageMatrix={{
          channel_language_matrix: getChannelLanguageMatrix(),
        }}
        onChangeChannelLanguageMatrixRef={onChangeChannelLanguageMatrixRef}
      />
    </>
  );
};
