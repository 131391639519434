import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Customer } from "../customers/Customer";
import { createSelector } from "@reduxjs/toolkit";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/customer",
    prepareHeaders: prepareCustomHeaders,
  }),
  tagTypes: ["Customer"],
  endpoints: (build) => ({
    getCustomer: build.query<Customer, void>({
      query: () => `details/`,
      providesTags: ["Customer"],
      transformResponse: (response) => new Customer(response),
    }),
  }),
});

export const getCustomerResult = customerApi.endpoints.getCustomer.select();

export const getCustomer = createSelector(
  getCustomerResult,
  (customerResult) => {
    const result = customerResult?.data;
    if (result) {
      return result;
    }
    return null;
  }
);

export const { endpoints, useGetCustomerQuery } = customerApi;
