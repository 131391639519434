import React from "react";
import { ColumnLabel, ColumnValue } from "./Columns";
import { Product } from "../../product";

export const ProductListParentColumn = {
  name: ColumnValue.Parent,
  label: ColumnLabel[ColumnValue.Parent],
  default: true,
  // eslint-disable-next-line react/display-name
  renderCell: (p: Product): JSX.Element | null => {
    const { parent_external_id: parentExternalId, parent_id: parentId } = p;
    if (parentId) {
      return (
        <a href={`../product/${parentId}`}>{parentExternalId || parentId}</a>
      );
    }
    return null;
  },
};
