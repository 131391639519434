import { illustrations } from "./illustrations";

export type IllustrationKeys = keyof typeof illustrations;

export type Section = {
  id: number;
  customer_id: number | null;
  locked?: boolean;
  name: string;
  template_label_ids: number[] | null;
  illustration_type?: IllustrationKeys;
};

export type CreateSection = Omit<Section, "id" | "customer_id">;

export type DocumentStructure = {
  id: number;
  customer_id: number | null;
  name: string;
  description: string;
  sections: Section[];
  locked?: boolean;
};

export type CreateDocumentStructure = Omit<
  DocumentStructure,
  "id" | "customer_id"
>;

export enum MoveItemDirection {
  UP = "UP",
  DOWN = "DOWN",
}

export enum DocumentFormMode {
  CREATE = "New Structure",
  UPDATE = "Updating",
  COPY = "Copying",
  BLANK = "Blank",
}

export enum SectionFormMode {
  CREATE = "New Section",
  UPDATE = "Updating",
  COPY = "Copying",
}

// These are used to render "Edit tab tags" in the same place as template tags
export const NON_TEMPLATE_TAGS_TEMPLATE_LABEL_ID = -1;
export const NON_TEMPLATE_TAGS_TEMPLATE_LABEL_NAME = "<Edit tab tags>";
