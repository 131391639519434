import React, { useMemo, useState } from "react";
import { Product } from "../../product";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { Column } from "./Column";
import { ProductListRenderContext } from "../ObjectTable";
import styled from "styled-components";
import { SelectionBannerAction } from "../selection-banner/ProductListSelectionBanner";

const AlignCheckboxAndDropdown = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 10px;
  & > .ui.dropdown > .dropdown.icon {
    margin: 0 !important;
    margin-left: 0.5em !important;
  }
`;
export const ProductListSelectColumn = {
  name: "select",
  collapsing: true,
  visible: true,
  sortable: false,
  renderCell: (
    p: Product,
    column: Column,
    context: ProductListRenderContext
  ): JSX.Element => {
    const { selectedProducts, onSelectRowChange } = context;
    return (
      <Checkbox
        className="select"
        checked={selectedProducts?.has(p.id)}
        data-product-id={p.id}
        onChange={(e, { checked }): void => onSelectRowChange(checked, p.id)}
      />
    );
  },
  renderHeaderCell: (
    column: Column,
    context: ProductListRenderContext
  ): JSX.Element => {
    const {
      products,
      selectedProducts,
      onSelectAllOnCurrentPage,
      onObjectSelectionAction,
      totalProducts,
    } = context;
    const productsIds = products.map((product) => product.id);
    const allRowsAreSelected =
      productsIds.filter((productId) => selectedProducts.has(productId))
        .length === products.length;

    const [open, setOpen] = useState(false);

    const allProductsSelected = useMemo(
      () => selectedProducts.size >= totalProducts,
      [totalProducts, selectedProducts.size]
    );

    return (
      <AlignCheckboxAndDropdown
        onMouseEnter={(): void => setOpen(true)}
        onMouseLeave={(): void => setOpen(false)}
      >
        <Checkbox
          data-testid={"product-list-select-all-active-on-page"}
          className="select-all"
          checked={allRowsAreSelected}
          onChange={(e, { checked }): void => onSelectAllOnCurrentPage(checked)}
        />
        <Dropdown text="Select" open={open}>
          <Dropdown.Menu>
            <Dropdown.Header
              data-testid="product-list-selected-count"
              content={
                selectedProducts.size
                  ? `Selected ${selectedProducts.size} of ${totalProducts} items`
                  : `Total selectable items: ${totalProducts}`
              }
            />
            <Dropdown.Item
              data-testid="product-list-select-all-active-on-page-in-dropdown"
              text={`${allRowsAreSelected ? "Deselect" : "Select"} all ${
                products.length
              } item(s) on this page`}
              onClick={(): void => {
                onSelectAllOnCurrentPage(!allRowsAreSelected);
                setOpen(false);
              }}
            />
            <Dropdown.Item
              data-testid="product-list-select-all-products"
              disabled={allProductsSelected}
              text={allProductsSelected ? "All Selected" : "Select All"}
              onClick={(): void => {
                onObjectSelectionAction(
                  SelectionBannerAction.SELECT_EVERYTHING
                );
                setOpen(false);
              }}
            />
            <Dropdown.Item
              disabled={selectedProducts.size <= 0}
              data-testid="product-list-clear-all-selected-products"
              text="Clear Selection"
              onClick={(): void => {
                onObjectSelectionAction(SelectionBannerAction.CLEAR);
                setOpen(false);
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </AlignCheckboxAndDropdown>
    );
  },
};
