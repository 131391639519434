import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Segment,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { uuidv4 } from "../../../utils/uuidUtils";
import { PipelineStep } from "./PipelineStep";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { BulkAction, bulkActionLabels } from "../../../ui/BulkActionsDropdown";
import { Step, Pipeline, UniqueIdentifier, UpdatePipeline } from "./types";
import { deleteStep, reorderSteps } from "../../../api/pipelineApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { PipelineStepsWrapper } from "./styledComponents";

export const PIPELINE_ACTION_STEP_LIST_GAP = 30;

export const bulkActionsWithNoSettings = [
  BulkAction.APPROVE_SELECTED,
  BulkAction.DELETE,
  BulkAction.REFRESH_FROM_IMPORTED_DATA,
];

const notAllowedBulkActions = [
  BulkAction.COPY_TO_FROM,
  BulkAction.CREATE_PARENT_PRODUCT,
  BulkAction.DOWNLOAD,
  BulkAction.REMOVE_TAG,
  BulkAction.REMOVE_TEMPLATE,
  BulkAction.REPLACE_TAG,
  BulkAction.RUN_PIPELINE,
] as const;

export const SIMPLE_ACTION_PREFIX = "-simple";

const getPipelineAllowedBulkActions = (): typeof bulkActionLabels => {
  const copy = { ...bulkActionLabels };
  notAllowedBulkActions.forEach((action) => delete copy[action]);

  copy[`${BulkAction.GPT_GENERATE_TEXT}${SIMPLE_ACTION_PREFIX}`] =
    "Generate AI texts (Simple)";

  return Object.keys(copy)
    .sort()
    .reduce((result: typeof bulkActionLabels, key) => {
      result[key] = copy[key];
      return result;
    }, {});
};

const options: {
  text: string;
  key: BulkAction;
  value: BulkAction;
}[] = Object.entries(getPipelineAllowedBulkActions()).map(
  ([key, value]: [BulkAction, string]) => ({
    text: value,
    key: key,
    value: key,
  })
);

export const findFriendlyName = (
  raw: BulkAction,
  stateData?: { simplified?: boolean }
): string => {
  if (stateData.simplified) {
    return (
      options.find(({ value }) => value === `${raw}${SIMPLE_ACTION_PREFIX}`)
        ?.text || raw
    );
  }
  return options.find(({ value }) => value === raw)?.text || raw;
};

type Props = {
  selectedPipeline: Pipeline;
  handleUpdatePipeline: (data: Partial<Pipeline>, id: number) => Promise<void>;
  handleDeletePipeline: (id: number) => Promise<void>;
};

export const PipelineForm: React.FC<Props> = ({
  selectedPipeline,
  handleUpdatePipeline,
  handleDeletePipeline,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [pipelineName, setPipelineName] = useState("");

  // steps that ether has Object.keys(state_data).length > 0 or has submitted its form is considered done
  const [doneStepsById, setDoneStepsById] = useState<UniqueIdentifier[]>([]);

  const [selectedSteps, setSelectedSteps] = useState<{
    [key: UniqueIdentifier]: Step;
  }>({});

  // This handles the ordering of steps as well
  const [addedStepsById, setAddedStepsById] = useState<UniqueIdentifier[]>([]);

  // Drag states
  const [isDragging, setIsDragging] = useState(false);

  const allStepsIsSaved = useMemo(
    () => addedStepsById.length === doneStepsById.length,
    [addedStepsById.length, doneStepsById.length]
  );

  const collectPipelineData = (): UpdatePipeline => {
    const newPipeline = {
      ...selectedPipeline,
      name: pipelineName,
    };
    delete newPipeline.id;
    delete newPipeline.steps_config; // updating steps_config list is handled by the form submit in PipelineStepSettings.tsx
    return newPipeline;
  };

  const setStepAsComplete = (uniqueIdentifier: UniqueIdentifier): void => {
    if (!doneStepsById.some((id) => id === uniqueIdentifier))
      setDoneStepsById((prev) => [...prev, uniqueIdentifier]);
  };

  const onSelectStep = (
    stepRawName: BulkAction,
    stateData: unknown = {}
  ): void => {
    const identifier = uuidv4();
    setSelectedSteps((prev) => ({
      ...prev,
      [identifier]: {
        action: stepRawName,
        state_data: stateData,
      },
    }));
    if (Object.keys(stateData).length) {
      setStepAsComplete(identifier);
    }

    setAddedStepsById((prev) => [...prev, identifier]);
  };

  useEffect(() => {
    if (!selectedPipeline) return;
    setSelectedSteps({});
    setAddedStepsById([]);
    setDoneStepsById([]);
    setPipelineName(selectedPipeline.name);

    selectedPipeline.steps_config?.forEach((step) => {
      onSelectStep(step.action, step.state_data);
    });
  }, [selectedPipeline]);

  const onRemoveStep = (
    uniqueIdentifier: UniqueIdentifier,
    isSavedInDB: boolean
  ): void => {
    const deleteAtIndex = addedStepsById.findIndex(
      (id) => id === uniqueIdentifier
    );
    if (isSavedInDB) {
      deleteStep(token, selectedPipeline.id, deleteAtIndex).then(() => {
        setSelectedSteps((prev) => {
          delete prev[uniqueIdentifier];
          return prev;
        });
        setAddedStepsById((prev) =>
          prev.filter((id) => id !== uniqueIdentifier)
        );
        setDoneStepsById((prev) =>
          prev.filter((id) => id !== uniqueIdentifier)
        );
      });
    } else {
      setSelectedSteps((prev) => {
        delete prev[uniqueIdentifier];
        return prev;
      });
      setAddedStepsById((prev) => prev.filter((id) => id !== uniqueIdentifier));
      setDoneStepsById((prev) => prev.filter((id) => id !== uniqueIdentifier));
    }
  };

  const findStepByUniqueIdentifier = (
    uniqueIdentifier: UniqueIdentifier
  ): Step => {
    return selectedSteps[uniqueIdentifier];
  };

  const onDragEnd = (result: DropResult): void => {
    setIsDragging(false);
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const dragIndex = source.index;
    const destinationIndex = destination.index;

    if (dragIndex === destinationIndex) {
      return;
    }
    const ids = [...addedStepsById];
    const unchanged = [...ids];
    const draggedItem = ids[dragIndex];
    ids.splice(dragIndex, 1);
    ids.splice(destinationIndex, 0, draggedItem);
    setAddedStepsById(ids);
    reorderSteps(token, selectedPipeline.id, dragIndex, destinationIndex).catch(
      () => {
        setAddedStepsById(unchanged);
      }
    );
  };

  return (
    <div>
      <Form as="div" size="small" data-testid="manage-pipelines-form">
        <Form.Field>
          <label htmlFor="input-pipeline-name">Name *</label>
          <Input
            data-testid="manage-pipelines-form-pipeline-name-input"
            placeholder="Pipeline name"
            id="input-pipeline-name"
            value={pipelineName}
            onChange={(_, { value }): void => setPipelineName(value)}
          />
        </Form.Field>
        <Divider />
      </Form>
      <div>
        <Text>
          <b>Steps *</b>
        </Text>
        <Segment placeholder={!addedStepsById.length}>
          {!addedStepsById.length && (
            <Text textAlignment="center" color="grey" compact>
              Add Step to configure the pipeline
            </Text>
          )}
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeDragStart={(): void => {
              setIsDragging(true);
            }}
          >
            <Droppable droppableId="action-steps">
              {(provided): JSX.Element => (
                <PipelineStepsWrapper
                  $gap={PIPELINE_ACTION_STEP_LIST_GAP}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {addedStepsById.map((id, index) => (
                    <PipelineStep
                      showArrow={!isDragging}
                      hasNext={addedStepsById.length !== index + 1}
                      key={id}
                      step={findStepByUniqueIdentifier(id)}
                      uniqueIdentifier={id}
                      index={index}
                      pipelineId={selectedPipeline?.id}
                      onRemoveStep={onRemoveStep}
                      stepIsComplete={doneStepsById.some(
                        (doneId) => doneId === id
                      )}
                      setStepAsComplete={setStepAsComplete}
                      isDragEnabled={allStepsIsSaved}
                    />
                  ))}
                  {provided.placeholder}
                </PipelineStepsWrapper>
              )}
            </Droppable>
          </DragDropContext>
          <Divider hidden={!addedStepsById.length} />
          <Dropdown
            style={{ opacity: 1 }}
            button
            compact
            className="red small"
            text="Add Step"
            scrolling
          >
            <Dropdown.Menu>
              {options.map(({ text, key, value }) => (
                <Dropdown.Item
                  data-testid={`manage-pipelines-form-dropdown-item-${key}`}
                  text={text}
                  key={key}
                  value={value}
                  onClick={(): void => onSelectStep(value)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Segment>
        <Button
          data-testid="manage-pipelines-form-delete-pipeline"
          compact
          basic
          floated="left"
          size="small"
          content="Delete"
          onClick={(): Promise<void> =>
            handleDeletePipeline(selectedPipeline?.id)
          }
        />
        <Button
          disabled={!pipelineName || !addedStepsById.length || !allStepsIsSaved}
          data-testid="manage-pipelines-form-save-pipeline"
          content="Save"
          color="red"
          compact
          size="small"
          floated="right"
          onClick={(): Promise<void> =>
            handleUpdatePipeline(collectPipelineData(), selectedPipeline?.id)
          }
        />
      </div>
    </div>
  );
};
