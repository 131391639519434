import React from "react";
import { Menu, Popup, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import {
  BulkAction,
  BulkActionsDropdown,
  getBulkActionUrl,
  Props as BulkActionsDropdownProps,
} from "../../../ui/BulkActionsDropdown";
import { popupDelay } from "../../../customers/gpt/types";
import { GenerateTextDropdown } from "./GenerateTextDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { ProductListAction } from "../ProductList";
import {
  RowSelectorMenuItem,
  Props as RowSelectorMenuItemProps,
} from "./RowSelectorMenuItem";
import {
  ColumnSelectorMenuItem,
  Props as ColumnSelectorMenuItemProps,
} from "./ColumnSelectorMenuItem";
import { ColumnValue } from "../columns/Columns";
import {
  RedBackgroundMenuItem,
  SemanticPopupWithReactRef,
} from "../../../ui/semantic-ui-overwrites";
import {
  ChannelLanguagePair,
  ProductListParams,
} from "../ProductListURLConfig";
import {
  ProductListPaginator,
  Props as ProductListPaginatorProps,
} from "./ProductListPaginator";

const SelectionBannerMenu = styled(Menu)<{
  $asFooter?: boolean;
  $productListLoading: boolean;
}>`
  &&&&& {
    ${({ $asFooter, $productListLoading }): string =>
      $asFooter
        ? `border-color: transparent; ${
            !$productListLoading && " border-top: none;"
          }`
        : !$productListLoading && "border-bottom: none;"}
    max-width: unset;
    margin: 0px;
    justify-content: space-between;
    > *,
    .menu > .item {
      font-weight: 500 !important;
    }
    > .menu {
      width: 33.33%;
      &.right {
        margin-left: unset !important;
        justify-content: flex-end;
      }
      &.center {
        justify-content: center;
        flex-grow: 1;
        &.remove-border {
          > .item {
            position: static;
            &::before {
              height: unset;
            }
          }
        }
      }
    }
  }
`;

export const DropdownHideDisplayText = styled(Dropdown)`
  && {
    > .text {
      display: None;
    }
  }
`;

export enum SelectionBannerAction {
  APPROVE = "approve",
  CLEAR = "clear",
  SELECT_EVERYTHING = "select-everything",
}

type ChannelLanguageSelectorModalActionValue = {
  [key in
    | "channelLanguagePairs"
    | "referenceChannelLanguagePairs"]: ChannelLanguagePair[];
};
type ProductListActionValues =
  | string
  | ColumnValue[]
  | boolean
  | ChannelLanguageSelectorModalActionValue
  | ProductListParams;

type InitProps = {
  bulkActionProps: BulkActionsDropdownProps;
  paginatorProps: Omit<ProductListPaginatorProps, "onProductListAction">;
  columnSelectorMenuItemProps: Omit<
    ColumnSelectorMenuItemProps,
    "onProductListAction"
  >;
  rowSelectorMenuItemProps: Omit<
    RowSelectorMenuItemProps,
    "onProductListAction"
  >;
  onProductListAction: (
    action: ProductListAction,
    value: ProductListActionValues
  ) => void;
  productListLoading?: boolean;
  asFooter?: boolean;
};
export type Props = InitProps & {
  asFooter?: never;
};
export type PropsAsFooter = Partial<InitProps> & {
  asFooter: true;
  onProductListAction: (
    action: ProductListAction,
    value: ProductListActionValues
  ) => void;
  paginatorProps: Omit<ProductListPaginatorProps, "onProductListAction">;
};

export const ProductListSelectionBanner: React.FC<Props | PropsAsFooter> = ({
  bulkActionProps,
  paginatorProps,
  columnSelectorMenuItemProps,
  rowSelectorMenuItemProps,
  onProductListAction,
  productListLoading,
  asFooter,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  return (
    <SelectionBannerMenu
      $asFooter={asFooter}
      $productListLoading={productListLoading}
      attached={asFooter ? "bottom" : "top"}
      size="tiny"
      stackable
      fluid
      data-testid="product-list-selection-banner"
    >
      <Popup
        disabled={!bulkActionProps?.disabled || asFooter}
        content="Select at least one item to enable Bulk actions, Generate and Download"
        size="small"
        wide
        mouseEnterDelay={popupDelay}
        trigger={
          <Menu.Menu
            position="left"
            data-testid="product-list-selection-banner-left-menu"
          >
            {!asFooter && (
              <>
                <BulkActionsDropdown {...bulkActionProps} />
                <GenerateTextDropdown disabled={bulkActionProps.disabled} />
                <SemanticPopupWithReactRef
                  popupProps={{
                    content: "Download selection as file",
                    disabled: bulkActionProps.disabled,
                  }}
                >
                  <RedBackgroundMenuItem
                    data-testid="quick-action-download-selection"
                    icon="download"
                    disabled={bulkActionProps.disabled}
                    content="Download"
                    onClick={(): Promise<void> =>
                      getBulkActionUrl(token, BulkAction.DOWNLOAD).then(
                        (url: string) => {
                          window.location.href = url;
                        }
                      )
                    }
                  />
                </SemanticPopupWithReactRef>
              </>
            )}
          </Menu.Menu>
        }
      />
      <Menu.Menu
        className="center remove-border"
        data-testid="product-list-selection-banner-center-menu"
      >
        <Menu.Item style={{ gap: "0.5rem" }}>
          <ProductListPaginator
            {...paginatorProps}
            onProductListAction={onProductListAction}
          />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu
        position="right"
        data-testid="product-list-selection-banner-right-menu"
      >
        {!asFooter && (
          <>
            <SemanticPopupWithReactRef
              popupProps={{
                content: "Setup text column(s)...",
                position: "top right",
              }}
            >
              <Menu.Item
                onClick={(): void => {
                  columnSelectorMenuItemProps.openChannelLanguageSelectorModal(
                    true
                  );
                }}
              >
                <Icon name="globe" size="large" style={{ marginRight: "0" }} />
              </Menu.Item>
            </SemanticPopupWithReactRef>
            <ColumnSelectorMenuItem
              {...columnSelectorMenuItemProps}
              onProductListAction={onProductListAction}
            />
            <RowSelectorMenuItem
              {...rowSelectorMenuItemProps}
              onProductListAction={onProductListAction}
            />
          </>
        )}
      </Menu.Menu>
    </SelectionBannerMenu>
  );
};
