import React from "react";

type Props = {
  currentLangstringData: { [key: string]: string };
  onChange?: (data: { [key: string]: string }) => void;
  templateVocabContent: string;
};

export const EditTemplateInlineMultiVariable: React.FC<Props> = ({
  currentLangstringData,
  onChange,
  templateVocabContent,
}) => {
  return (
    <div data-testid="edit-template-inline-multi-variable">
      {Object.keys(currentLangstringData).map((key) => {
        return (
          <div key={key} className="tw-mb-2">
            <label
              htmlFor={`langstring-edit-textarea-${key}`}
              className="tw-text-gray-500"
            >
              {key}
            </label>
            <textarea
              data-testid="edit-template-inline-multi-variable-textareas"
              className="tw-mt-1 tw-px-2 tw-pt-1"
              id={`langstring-edit-textarea-${key}`}
              key={key}
              onChange={({ target }): void => {
                onChange?.({
                  ...currentLangstringData,
                  [key]: target.value,
                });
              }}
              value={currentLangstringData[key]}
            />
          </div>
        );
      })}
      <span>
        <i className="info circle icon"></i>
        <small>
          {" "}
          This template has multiple fields.{" "}
          <pre className="tw-inline tw-bg-gray-100">{templateVocabContent}</pre>
        </small>
      </span>
      <br />
      <span>
        <i className="info circle icon"></i>
        <small> If template content is not correct - try to regenerate</small>
      </span>
    </div>
  );
};
