import React from "react";

interface Props {
  className: string;
  value: string;
  pos?: string;
  as?: string;
  // ...rest parameters we use
  type?: string;
  onClick?: () => void;
}

export const BalloonDiv: React.FC<Props> = ({
  as = "div",
  children,
  className,
  pos = "up",
  ...rest
}) => {
  let { value } = rest;
  if (!value) {
    value = "";
  }
  const textLength = value.length;
  let balloonLength;
  if (textLength > 150) {
    balloonLength = "xlarge";
  } else if (textLength > 120) {
    balloonLength = "large";
  } else if (textLength > 30) {
    balloonLength = "medium";
  } else {
    balloonLength = "";
  }
  const As = (as as unknown) as React.JSXElementConstructor<
    React.PropsWithChildren<Props>
  >;
  return (
    <As
      data-testid={"ballon-div"}
      className={className}
      data-balloon={value}
      data-balloon-length={balloonLength}
      data-balloon-pos={pos}
      {...rest}
    >
      {children}
    </As>
  );
};
