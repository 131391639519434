import * as React from "react";
import DatePicker from "react-datepicker";
import { Dropdown } from "semantic-ui-react";

import { SyntheticEvent } from "react";
import { FontSizeHoverEffectIcon } from "./ProductFilterChoice";
import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  date: Date | null;
  dateEnd?: Date | null;
  dateStart?: Date | null;
  onChange?: (newDate: Date | null) => void;
  text: string;
};
export const ProductFilterDatePicker: React.FC<Props> = ({
  date,
  dateEnd,
  dateStart,
  onChange,
  text,
}) => (
  <>
    <StyledDropdownBase
      className={"tiny"}
      basic
      button
      trigger={<span>{date?.toLocaleDateString("sv-SV") || text}</span>}
    >
      <Dropdown.Menu
        onClick={(e: SyntheticEvent): void => {
          if ((e.target as HTMLButtonElement).getAttribute("type") === "button")
            e.stopPropagation();
        }}
      >
        <DatePicker
          endDate={dateEnd}
          inline
          maxDate={dateEnd}
          minDate={dateStart}
          onChange={(date: Date): void => {
            const offsetDate = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000
            );
            onChange?.(offsetDate);
          }}
          selected={date}
          selectsEnd={text.includes("Before")}
          selectsStart={text.includes("After")}
          startDate={dateStart}
          todayButton={"Today"}
        />
      </Dropdown.Menu>
    </StyledDropdownBase>
    {date && (
      <FontSizeHoverEffectIcon
        className={"times circle link icon"}
        data-testid={"close-btn"}
        onClick={(): void => onChange?.(null)}
        size={"small"}
      />
    )}
  </>
);
