import React from "react";

export enum keyCode {
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
}

export function useKeyDown(targetKey: keyCode, callback: () => void): void {
  const onDown = React.useCallback(
    (event: KeyboardEvent): void => {
      if (event.keyCode == targetKey) {
        callback();
      }
    },
    [targetKey, callback]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", onDown);
    return (): void => {
      window.removeEventListener("keydown", onDown);
    };
  }, [onDown]);
}
