import React, { Dispatch, SetStateAction } from "react";
import { Form, List, Ref, Segment } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { Prompt } from "../types";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import cloneDeep from "lodash/cloneDeep";

type Props = {
  prompts: Prompt[];
  selectedPromptsId: number[];
  setSelectedPromptsId: Dispatch<SetStateAction<number[]>>;
};

export const PromptOrderTemplateField: React.FC<Props> = ({
  prompts,
  selectedPromptsId,
  setSelectedPromptsId,
}) => {
  const onDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const dragIndex = source.index;
    const destinationIndex = destination.index;

    if (dragIndex === destinationIndex) {
      return;
    }

    const copySelectedPromptsId = cloneDeep(selectedPromptsId);

    const draggedItem = copySelectedPromptsId[dragIndex];
    copySelectedPromptsId.splice(dragIndex, 1);
    copySelectedPromptsId.splice(destinationIndex, 0, draggedItem);
    setSelectedPromptsId(copySelectedPromptsId);
  };
  return (
    <Form.Field>
      <label>Order prompt selection</label>
      <Text color="grey" className="descriptive-helper-text">
        Drag and drop the selected prompts to control what order they should be
        generated in.
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable-1"
          renderClone={(provided, snapshot, rubric): any => {
            const id = selectedPromptsId[rubric.source.index];
            const template = prompts.find((prompt) => prompt.id === id);
            return (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <List.Item>{template?.name ?? "(missing)"}</List.Item>
              </div>
            );
          }}
        >
          {(provided): React.ReactElement => (
            <Segment data-testid="copy-assistant-template-form-order-templates-draggable">
              <Ref innerRef={provided.innerRef}>
                <List divided ordered relaxed="very">
                  {selectedPromptsId.map((id, index) => {
                    const prompt = prompts.find((prompt) => prompt.id === id);
                    return (
                      <Draggable draggableId={`${id}`} index={index} key={id}>
                        {(provided): React.ReactElement => (
                          <Ref innerRef={provided.innerRef}>
                            <List.Item
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {prompt?.name ?? "(missing)"}
                            </List.Item>
                          </Ref>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              </Ref>
            </Segment>
          )}
        </Droppable>
      </DragDropContext>
    </Form.Field>
  );
};
