import React, { useEffect, useMemo, useState } from "react";
import { useGetFieldsetKeyMappings, useGetFieldsets } from "./customhooks";
import { Button, Dimmer, Header, Icon, Popup } from "semantic-ui-react";
import { popupDelay } from "../../../customers/gpt/types";
import { Text } from "../../../components/Text";
import { FieldsetForm } from "./FieldsetForm";
import { ListFieldsets } from "./ListFieldsets";
import { Fieldset } from "./types";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import ManageGrid from "../../../components/manage/ManageGrid";
import { FieldsetMappingsForm } from "./FieldsetMappingsForm";
import {
  useGetDocumentSections,
  useGetDocumentStructures,
} from "../../../planner/document-structure/manage/customhooks";

export type BooleanFilterType = "true" | "false";
const Views = {
  IS: "input-schema",
  MAPPINGS: "mappings",
} as const;

type ViewsValues = typeof Views[keyof typeof Views];

export const Fieldsets: React.FC = () => {
  const { setParam, urlState, removeParam } = useUrlState<{
    fieldset_id: number;
    view: ViewsValues;
    m_search: string;
    m_filter_mappings: BooleanFilterType;
  }>();
  const { fieldset_id, view, m_search, m_filter_mappings } = urlState;
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (fieldset_id && !showForm) {
      setShowForm(true);
    }
  }, [fieldset_id]);

  const setSelectedFieldset = (fieldset: Fieldset): void => {
    if (!fieldset) {
      removeParam("fieldset_id");
      setShowForm(false);
      return;
    }
    setShowForm(true);
    setParam("fieldset_id", fieldset.id);
  };

  const {
    data: { fieldsets, known_keys: knownKeys },
    isFetching: isFetchingFieldsets,
    reFetch: reFetchFieldsets,
  } = useGetFieldsets({ includeKnownKeys: true });

  const {
    data: sections,
    isFetching: isFetchingSections,
  } = useGetDocumentSections();

  const { data: structures } = useGetDocumentStructures();

  const {
    data: keyMappings,
    isFetching: isFetchingFieldsetKeyMappings,
    reFetch: reFetchFieldsetKeyMappings,
  } = useGetFieldsetKeyMappings();

  const [showDimmer, setShowDimmer] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const defaultFieldset = useMemo(() => {
    const defaultFieldset = fieldsets.find(
      ({ use_as_default }) => use_as_default
    );
    if (defaultFieldset) {
      return defaultFieldset;
    }
    const createdDefault: Fieldset = {
      id: 0,
      customer_id: 0,
      name: "Default",
      created: new Date(),
      fields: knownKeys.map((key) => ({ key })),
      use_as_default: true,
    };
    return createdDefault;
  }, [fieldsets, knownKeys]);

  const selectedFieldset = useMemo(() => {
    if (fieldset_id === 0) {
      return defaultFieldset;
    }
    return [...fieldsets, defaultFieldset].find(({ id }) => id === fieldset_id);
  }, [fieldset_id, fieldsets, defaultFieldset]);

  const displayName = useMemo(
    () =>
      selectedFieldset?.name.length > 60
        ? `${selectedFieldset?.name.substring(0, 60)}...`
        : selectedFieldset?.name,
    [selectedFieldset]
  );

  useEffect(() => {
    setShowDimmer(isFetchingFieldsets); // Global loading
  }, [isFetchingFieldsets]);

  const isViewingMappings = view == Views.MAPPINGS;

  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              !isViewingMappings && (
                <Popup
                  wide
                  size="tiny"
                  mouseEnterDelay={popupDelay}
                  content="Schemas determine which parts of your product's data are used when generating descriptions."
                  trigger={
                    <span>
                      <Text as="h4" compact inline width="max">
                        Schemas
                      </Text>{" "}
                      <Icon name="question circle" />
                    </span>
                  }
                />
              )
            }
            right={
              !isViewingMappings && (
                <Popup
                  size="tiny"
                  content="Create input schema"
                  mouseEnterDelay={popupDelay}
                  trigger={
                    <Button
                      data-testid={"create-fieldset"}
                      size="tiny"
                      color="red"
                      icon="plus"
                      compact
                      basic
                      onClick={(): void => {
                        setIsCopying(false);
                        setSelectedFieldset(undefined);
                        setShowForm(true);
                      }}
                    />
                  }
                />
              )
            }
          />
          <ManageGrid.Header.RightColumn>
            <div>
              {!isViewingMappings ? (
                <>
                  {isCopying && (
                    <Text
                      compact
                      as="h3"
                      testId={"fieldset-selected-action-copy"}
                      inline
                    >
                      Copying schema: {displayName}
                    </Text>
                  )}
                  {!!selectedFieldset && !isCopying && (
                    <Text
                      compact
                      as="h3"
                      testId={"fieldset-selected-action-update"}
                      inline
                    >
                      Update: {displayName}
                    </Text>
                  )}
                  {!selectedFieldset && showForm && (
                    <Text
                      compact
                      as="h3"
                      testId={"fieldset-selected-action-create"}
                      inline
                    >
                      New schema
                    </Text>
                  )}
                </>
              ) : (
                <Text compact as="h3" testId={"fieldset-mappings-view"} inline>
                  Modify field names ({Object.keys(keyMappings).length})
                </Text>
              )}
              <Button
                data-testid="fieldset-change-view"
                color="red"
                basic={!isViewingMappings}
                compact
                floated="right"
                content="Modify field names"
                onClick={(): void => {
                  setParam(
                    "view",
                    isViewingMappings ? Views.IS : Views.MAPPINGS
                  );
                }}
              />
            </div>
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            {!isViewingMappings && (
              <ListFieldsets
                defaultFieldset={defaultFieldset}
                fieldsets={fieldsets.filter(
                  ({ use_as_default }) => !use_as_default
                )}
                selectedFieldset={selectedFieldset}
                setSelectedFieldset={setSelectedFieldset}
                setIsCopying={(isCopying): void => {
                  setIsCopying(isCopying);
                  setShowForm(true);
                }}
                isCopying={isCopying}
              />
            )}
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {!isViewingMappings ? (
              <>
                {showForm ? (
                  <FieldsetForm
                    loading={showDimmer}
                    knownKeys={knownKeys}
                    refetchFieldsets={reFetchFieldsets}
                    selectedFieldset={selectedFieldset}
                    setSelectedFieldset={setSelectedFieldset}
                    setIsCopying={setIsCopying}
                    isCopying={isCopying}
                    sections={sections}
                    isFetchingSections={isFetchingSections}
                    structures={structures}
                  />
                ) : (
                  <ManageGrid.Body.EmptyForm>
                    {!!fieldsets?.length && (
                      <>
                        <Text as="h2" compact>
                          Select an Input Schema from the list
                        </Text>
                        <Text as="h3" color="grey" compact>
                          Or
                        </Text>
                      </>
                    )}
                    <Button
                      content="Create new Input Schema"
                      icon="plus"
                      color="red"
                      size="large"
                      onClick={(): void => setShowForm(true)}
                    />
                  </ManageGrid.Body.EmptyForm>
                )}
              </>
            ) : (
              <FieldsetMappingsForm
                keyMappings={keyMappings}
                knownKeys={knownKeys}
                loading={isFetchingFieldsetKeyMappings}
                reFetch={reFetchFieldsetKeyMappings}
                search={m_search}
                setSearch={(search?: string): void => {
                  if (!search) {
                    removeParam("m_search");
                    return;
                  }
                  setParam("m_search", search);
                }}
                filterMappings={m_filter_mappings}
                setFilterMappings={(filter?: BooleanFilterType): void => {
                  if (!filter || filter === m_filter_mappings) {
                    removeParam("m_filter_mappings");
                    return;
                  }
                  setParam("m_filter_mappings", filter);
                }}
              />
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
      <Dimmer active={showDimmer} page>
        <Header as="h4" icon inverted>
          <Icon name="circle notch" loading />
        </Header>
      </Dimmer>
    </div>
  );
};
