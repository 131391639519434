import React from "react";
import { ProductTextActivityTableHeaderCell } from "./ProductTextActivityTableHeaderCell";

export const ProductTextActivityTableHeader: React.FC = () => (
  <thead data-testid="product-text-activity-modal-header">
    <tr>
      <ProductTextActivityTableHeaderCell
        title="Text action"
        content={[
          {
            header: "generate",
            description: "The text was generated manually.",
          },
          {
            header: "regenerate",
            description: "The text was regenerated automatically.",
          },
          {
            header: "needs-regeneration",
            description: "The text was scheduled for regeneration.",
          },
          {
            header: "approve",
            description: "The text was approved.",
          },
          {
            header: "comment",
            description: "A comment was left on the text.",
          },
          {
            header: "manual-edit",
            description: "The text was manually edited.",
          },
          {
            header: "publish",
            description: "The text was published.",
          },
          {
            header: "revert-edited-data",
            description: "Manual edits on the text were reverted.",
          },
          {
            header: "unapprove",
            description: "The text was unapproved.",
          },
          {
            header: "waiting-to-be-published",
            description:
              "The text was marked as waiting to be published (because it contains missing translations).",
          },
        ]}
      />
      <ProductTextActivityTableHeaderCell title={"Created"} />
      <ProductTextActivityTableHeaderCell
        title={"User"}
        content={[
          {
            header: "info+celery-system-user",
            description:
              "The platform itself which invokes indirect or automatic actions.",
          },
        ]}
      />
      <ProductTextActivityTableHeaderCell
        title={"Context"}
        content={[
          {
            header: "system-periodic-task",
            description: "The action was performed on a schedule.",
          },
          {
            header: "product-tags-changed",
            description:
              "The action was performed as a result of the product tags changing.",
          },
          {
            header: "product-list-text-column-toolbar",
            description:
              "The action was performed from the toolbar which appears when you hover over a text in the product list.",
          },
          {
            header: "bulk-[action]-action",
            description: "The action was performed by a bulk action.",
          },
          {
            header: "product-status-button",
            description:
              "The action was performed by changing the product’s status.",
          },
          {
            header: "translations-modal-text-toolbar",
            description:
              "The action was performed from the Select translations dialog.",
          },
        ]}
      />
    </tr>
  </thead>
);
