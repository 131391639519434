import React, { useMemo } from "react";
import { ProductListAction } from "../ProductList";
import { ProductListParams } from "../ProductListURLConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { Button, Loader, Popup } from "semantic-ui-react";
import { Text } from "../../../components/Text";

export type Props = {
  currentPage: number;
  currentParameters: ProductListParams;
  errorSearchQuery: boolean;
  onPageChange: (page: number) => void;
  onProductListAction: (
    action: ProductListAction,
    value: ProductListParams
  ) => void;
  paginationSize: number;
};

export const ProductListPaginator: React.FC<Props> = ({
  currentPage,
  currentParameters,
  errorSearchQuery,
  onPageChange,
  onProductListAction,
  paginationSize = 0,
}) => {
  const { productCount } = useSelector(
    (state: RootState) => state.productList.paginationDetails
  );
  const totalPages = Math.ceil(productCount / paginationSize);
  const { firstOnPage, lastOnPage } = useMemo(() => {
    if (!productCount) return { firstOnPage: 0, lastOnPage: 0 };
    return {
      firstOnPage: (currentPage - 1) * paginationSize + 1,
      lastOnPage: Math.min(currentPage * paginationSize, productCount),
    };
  }, [productCount, currentPage, paginationSize]);
  const isLastPage = totalPages === currentPage;

  const errorMessage = useMemo(() => {
    if (errorSearchQuery) return "Something went wrong";
    if (firstOnPage > lastOnPage) return "Page out of bounds";
    if (productCount === 0) return "No Documents found";
  }, [errorSearchQuery, firstOnPage, lastOnPage, productCount]);

  const isLoading = !firstOnPage && !lastOnPage && !productCount;
  return (
    <>
      {errorMessage ? (
        <Text
          compact
          size="medium"
          color="grey"
          testId="product-list-paginator-error"
        >
          {errorMessage}
        </Text>
      ) : (
        <Text compact size="medium" testId="product-list-paginator-page-info">
          {isLoading ? (
            <Loader active size="tiny" inline as="span" />
          ) : (
            <>
              {firstOnPage} &ndash; {lastOnPage} of {productCount}
            </>
          )}
        </Text>
      )}
      <div>
        {errorMessage ? (
          <>
            <Popup
              content="Refresh Document list"
              size="small"
              wide
              trigger={
                <Button
                  data-testid="product-list-paginator-error-refresh"
                  compact
                  basic
                  size="tiny"
                  icon="refresh"
                  onClick={(): void => {
                    onPageChange(currentPage);
                  }}
                />
              }
            />
            <Popup
              content="Clear filters"
              size="small"
              wide
              trigger={
                <Button
                  data-testid="product-list-paginator-error-clear-filters"
                  compact
                  basic
                  size="tiny"
                  icon="eraser"
                  onClick={(): void => {
                    onProductListAction(
                      ProductListAction.Reset,
                      currentParameters
                    );
                  }}
                />
              }
            />
          </>
        ) : (
          <>
            <Button
              data-testid="product-list-paginator-prev-page"
              compact
              basic
              size="tiny"
              icon="chevron left"
              disabled={currentPage === 1}
              onClick={(): void => {
                if (currentPage !== 1) onPageChange(currentPage - 1);
              }}
            />
            <Button
              data-testid="product-list-paginator-next-page"
              compact
              basic
              size="tiny"
              icon="chevron right"
              disabled={isLastPage}
              onClick={(): void => {
                if (!isLastPage) onPageChange(currentPage + 1);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
