import React, { useState } from "react";
import { CopyToFromModal } from "./CopyToFromModal";
import { ProductId } from "../product";
import { CopyMode } from "./types";

type Props = {
  productId: ProductId;
  copyMode: CopyMode;
  label: string;
  token?: string;
};

export const CopyToFromButton: React.FC<Props> = ({
  productId,
  copyMode,
  label,
}) => {
  const [open, setOpen] = useState(false);
  const childClose = (): void => setOpen(false);

  return (
    <>
      <a
        className="item"
        onClick={(): void => setOpen(true)}
        data-testid={`copymode-${copyMode}`}
      >
        <i className="copy icon"></i>
        <span>{label}</span>
      </a>
      <CopyToFromModal
        label={label}
        productId={productId}
        childClose={childClose}
        open={open}
        copyMode={copyMode}
      />
    </>
  );
};
