import React, { useEffect, useMemo, useState } from "react";
import { SimilarVocabulary } from "../../api/vocabularyApi";
import { Button, Divider, Icon, List, Popup } from "semantic-ui-react";
import { useGetCustomerQuery } from "../../api/customerApi";
import {
  Flag,
  Language,
  LanguageCode,
} from "../../customers/customerlanguages";
import { getCustomerEnglishLanguage } from "../../products/copy-assistant/utils";
import styled from "styled-components";
import { Text } from "../../components/Text";
import { Spinner } from "../../utils/Spinner";

const PositionedSpinner: React.FC = () => (
  <div style={{ margin: "200px" }}>
    <Spinner size="large" />
  </div>
);

const findLanguageWithAllSimilarVocabulariesTranslated = (
  items: SimilarVocabulary[],
  allCustomerLanguages: Language[],
  customerMasterLanguageCode: LanguageCode,
  customerHelperLanguageCode: LanguageCode
): Language => {
  const filteredByMasterLanguage = items.filter(
    ({ translations }) => !!translations[customerMasterLanguageCode]
  );
  if (filteredByMasterLanguage.length == items.length) {
    return allCustomerLanguages.find(
      ({ code }) => code === customerMasterLanguageCode
    );
  }

  const filteredByHelperLanguage = items.filter(
    ({ translations }) => !!translations[customerHelperLanguageCode]
  );

  if (filteredByHelperLanguage.length == items.length) {
    return allCustomerLanguages.find(
      ({ code }) => code === customerHelperLanguageCode
    );
  }

  const language = allCustomerLanguages
    .filter(
      ({ code }) =>
        ![customerHelperLanguageCode, customerMasterLanguageCode].includes(code)
    )
    .find(({ code }) => {
      const filtered = items.filter(({ translations }) => !!translations[code]);
      if (filtered.length === items.length) return code;
    });

  return language;
};

const languageStatuses = (
  item: SimilarVocabulary,
  languages: Language[]
): { hasTranslations: JSX.Element[]; noTranslations: JSX.Element[] } => {
  const hasTranslations: JSX.Element[] = [];
  const noTranslations: JSX.Element[] = [];
  languages.forEach((language) => {
    const translation = item.translations[language.code];
    if (translation) {
      hasTranslations.push(
        <Popup
          key={language.code}
          content={`${language.short_name}: ${translation}`}
          wide
          trigger={<span>{language.flag}</span>}
        />
      );
    } else {
      noTranslations.push(
        <Popup
          key={language.code}
          content={`No translation for ${language.short_name}`}
          wide
          trigger={<span>{language.flag}</span>}
        />
      );
    }
  });

  return { hasTranslations, noTranslations };
};

const GridListItem = styled(List.Item)`
  &&&& {
    display: grid;
    grid-template-columns: repeat(2, 200px) repeat(2, 1fr) min-content;
    align-items: center;
  }
`;

const buildListItem = (
  item: SimilarVocabulary,
  displayLanguage: Language,
  customerEnLanguage: Language,
  allCustomerLanguages: Language[],
  handleAddVocabulary: (
    id: number,
    requestTranslation: boolean
  ) => Promise<unknown>
): JSX.Element => {
  const languagesToIterate = allCustomerLanguages.filter(
    ({ code }) =>
      ![customerEnLanguage?.code, displayLanguage?.code].includes(code)
  );
  const showEnTranslation = displayLanguage?.code !== customerEnLanguage?.code;

  const { hasTranslations, noTranslations } = languageStatuses(
    item,
    languagesToIterate
  );

  const buttonPopupContent =
    hasTranslations.length === languagesToIterate.length
      ? "Add this word to your vocabulary"
      : `Add this word to your vocabulary and request translation into ${noTranslations.length} language(s)`;

  return (
    <GridListItem>
      <div className="display-translation">
        <Flag content={displayLanguage?.flag} />{" "}
        {item.translations[displayLanguage?.code] || (
          <Text color="grey" size="small" compact inline>
            Missing Translation
          </Text>
        )}
      </div>
      <div className="en-translation">
        {showEnTranslation && (
          <>
            <Flag content={customerEnLanguage.flag} />{" "}
            {item.translations[customerEnLanguage?.code] || (
              <Text color="grey" size="small" compact inline>
                Missing Translation
              </Text>
            )}
          </>
        )}
      </div>
      <div className="translations">
        {hasTranslations.length ? (
          <>
            <Text color="grey" size="small" compact>
              Translations
            </Text>
            <span style={{ marginTop: "10px" }}>
              {hasTranslations.map((item) => item)}
            </span>
          </>
        ) : (
          <Popup
            content={`Missing translations for ${noTranslations.length} language(s)`}
            wide
            trigger={<Icon color="yellow" name="warning sign" />}
          />
        )}
      </div>
      <div className="no-translations">
        {noTranslations.length ? (
          <>
            <Text color="grey" size="small" compact>
              Missing translations
            </Text>
            <span style={{ marginTop: "10px" }}>
              {noTranslations.map((item) => item)}
            </span>
          </>
        ) : (
          <Popup
            content="The word has translations in all your languages and is ready to be used"
            wide
            trigger={<Icon color="green" name="check" />}
          />
        )}
      </div>
      <Popup
        position="left center"
        content={buttonPopupContent}
        wide="very"
        trigger={
          <Button
            data-testid={`request-word-modal-similar-vocabularies-add-${item.vocabulary_id}`}
            content="Add"
            color="red"
            size="small"
            compact
            style={{ justifySelf: "end" }}
            onClick={(): Promise<unknown> =>
              handleAddVocabulary(item.vocabulary_id, !!noTranslations.length)
            }
          />
        }
      />
    </GridListItem>
  );
};

type Props = {
  similarVocabularies: {
    exact: SimilarVocabulary[];
    contains: SimilarVocabulary[];
  };
  loading: boolean;
  handleAddVocabulary: (
    id: number,
    requestTranslation: boolean
  ) => Promise<unknown>;
};
export const RequestWordModalSimilarVocabularies: React.FC<Props> = ({
  similarVocabularies,
  loading,
  handleAddVocabulary,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();

  const [showContainsVocabularies, setShowContainsVocabularies] = useState(
    false
  );
  const customerEnLanguage = useMemo(() => {
    return customer ? getCustomerEnglishLanguage(customer) : null;
  }, [customer]);

  const displayLanguage = useMemo<Language>(() => {
    const combined = [
      ...similarVocabularies.exact,
      ...similarVocabularies.contains,
    ];
    if (!customer?.config) return customerEnLanguage;

    return findLanguageWithAllSimilarVocabulariesTranslated(
      combined,
      customer.languages,
      customer.config?.tag_input_language,
      customerEnLanguage?.code || "en_US"
    );
  }, [customer, customerEnLanguage, similarVocabularies]);

  useEffect(() => {
    if (!similarVocabularies.exact.length && !loading) {
      setShowContainsVocabularies(true);
    } else {
      setShowContainsVocabularies(false);
    }
  }, [similarVocabularies.exact, loading]);

  return (
    <div data-testid="request-word-modal-similar-vocabularies">
      <Text as="h2" compact>
        Ready to use word(s)
      </Text>
      <Divider hidden />
      <Text as="h4" compact>
        Exact matching word(s)
      </Text>{" "}
      {isLoading || loading ? (
        <PositionedSpinner />
      ) : (
        <List divided verticalAlign="middle" relaxed="very">
          {similarVocabularies.exact.length ? (
            <>
              {similarVocabularies.exact.map((item) => (
                <React.Fragment key={item.vocabulary_id}>
                  {buildListItem(
                    item,
                    displayLanguage,
                    customerEnLanguage,
                    customer?.languages,
                    handleAddVocabulary
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <List.Item disabled>Found no exact matches</List.Item>
          )}
        </List>
      )}
      {!!similarVocabularies.contains.length && !showContainsVocabularies && (
        <Button
          content={`Show more... (${similarVocabularies.contains.length}) `}
          size="small"
          color="red"
          basic
          compact
          floated="right"
          onClick={(): void => setShowContainsVocabularies(true)}
        />
      )}
      <Divider hidden={!showContainsVocabularies} />
      {showContainsVocabularies && (
        <>
          <Divider hidden />
          <Text as="h4" compact>
            Similar word(s)
          </Text>
          <List divided verticalAlign="middle" relaxed="very">
            {similarVocabularies.contains.map((item) => (
              <React.Fragment key={item.vocabulary_id}>
                {buildListItem(
                  item,
                  displayLanguage,
                  customerEnLanguage,
                  customer?.languages,
                  handleAddVocabulary
                )}
              </React.Fragment>
            ))}
          </List>
        </>
      )}
    </div>
  );
};
