import { ProductId } from "../products/product";
import api from "./api";
import { AxiosResponse } from "axios";
import { TemplateItem } from "../vocabulary/template";
import { getTagCatType, getURL } from "../utils/ApiUtils";
import { VocabularyTreeData } from "../utils/tagutils";
import { LanguageCode } from "../customers/customerlanguages";
import { TemplateLexiconShortcutTypes } from "../vocabulary/TemplateVariantsField";

export type VocabularyId = number;

export type SearchVocabularyParams = {
  advanced_template_search?: boolean;
  allow_children?: boolean;
  category?: string;
  detailed_vocab_suggestion?: boolean;
  exclude_fixed_phrases?: boolean;
  exclude_templates?: boolean;
  headline?: string;
  include_seo?: boolean;
  language?: string;
  limit?: number;
  parent_id?: number;
  product_id?: ProductId;
  q?: string;
  skip_customer_filter?: boolean;
  tagtree_root_search?: boolean;
  type?: string;
};

export async function searchAsyncVocabulary(
  params: SearchVocabularyParams,
  token: string,
  textualAppName: string
): Promise<AxiosResponse<VocabularyTreeData[]>> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
    params,
  };
  return api.get("vocabulary", config);
}

export type SortOption = "display_name" | "recently_used";

export async function newRequestWordSchema(
  tagCategory: string,
  tagType: string,
  language: string,
  token: string,
  textualAppName: string,
  disallowKind: boolean,
  allowMaterialKindTagsOnly: boolean
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };

  const urlParams = new URLSearchParams();
  urlParams.set("tag_category", tagCategory);
  urlParams.set("tag_type", tagType);
  urlParams.set("language", language);
  urlParams.set("disallow_kind", disallowKind ? "1" : "0");
  urlParams.set(
    "allowMaterialKindTagsOnly",
    allowMaterialKindTagsOnly ? "1" : "0"
  );
  return api.get(`vocabulary/request/schema?${urlParams.toString()}`, config);
}

export async function newRequestWordInfer(
  tagCategory: string,
  tagType: string,
  language: string,
  lemma: string,
  token: string,
  textualAppName: string
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };

  const urlParams = new URLSearchParams();
  urlParams.set("tag_category", tagCategory);
  urlParams.set("tag_type", tagType);
  urlParams.set("language", language);
  urlParams.set("lemma", lemma);
  return api.get(`vocabulary/request/infer?${urlParams.toString()}`, config);
}

interface NewWord {
  word: string;
  lexiconData: object;
}

export async function newRequestWord(
  newWord: NewWord | string,
  englishTranslation: NewWord | string,
  tagCategory: string,
  tagType: string,
  shouldTranslate: boolean,
  shouldMachineTranslate: boolean,
  shouldPopulate: boolean,
  productId: ProductId,
  sampleSentence: string,
  description: string,
  customerDefaultLang: string,
  token: string,
  textualAppName: string,
  hypernymId: number
): Promise<AxiosResponse<VocabularyTreeData>> {
  // word

  let newWordValue = null;
  let newWordLexiconData = null;

  if (typeof newWord === "string") {
    newWordValue = newWord;
    newWordLexiconData = undefined;
  } else {
    newWordValue = newWord.word;
    newWordLexiconData = newWord.lexiconData;
  }

  // english translation

  let englishTranslationValue = null;
  let englishTranslationLexiconData = null;

  if (typeof englishTranslation === "string") {
    englishTranslationValue = englishTranslation;
    englishTranslationLexiconData = undefined;
  } else {
    englishTranslationValue = englishTranslation.word;
    englishTranslationLexiconData = englishTranslation.lexiconData;
  }

  // request body

  const requestPayload = {
    tag_type: getTagCatType(tagType, "kind"),
    tag_category: getTagCatType(tagCategory, "identifier"),
    should_translate: shouldTranslate,
    should_machine_translate: shouldMachineTranslate,
    should_populate: shouldPopulate,
    product_id: productId,
    sample_sentence: sampleSentence,
    description: description,
    source: textualAppName,
    requested_forms: [
      {
        language: customerDefaultLang,
        word: newWordValue,
        lexicon_data: newWordLexiconData,
      },
    ],
    hypernym_id: hypernymId,
  };
  if (
    customerDefaultLang &&
    customerDefaultLang != "en_US" &&
    customerDefaultLang != "en_GB"
  ) {
    requestPayload.requested_forms.push({
      language: "en_US",
      word: englishTranslationValue,
      lexicon_data: englishTranslationLexiconData,
    });
  }
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };
  return api.post("vocabulary/request", requestPayload, config);
}

export async function getFluentInputSuggestions(
  queryParams: any,
  token: string,
  textualAppName: string
): Promise<AxiosResponse> {
  const config = {
    baseURL: getURL(textualAppName),
    headers: {
      token: token,
    },
  };
  return api.get(`vocabulary/completions${queryParams}`, config);
}

/**
 * These string literals comes from backend enum `MachineTranslationActionContext`
 * can be found in customers/constants.py
 */
export const machineTranslationActionContext = {
  COPY_ASSISTANT_TRANSLATE: "copy-assistant-translate-generated-text",
  TRANSLATE_TEMPLATE: "translate-template",
  MANUAL_EDIT_PRODUCT_TEXT_TRANSLATE: "manual-edit-product-text-translate",
  REQUEST_WORD_ENGLISH_TRANSLATION: "request-word-english-translation",
} as const;

type MachineTranslationActionContextKeys = keyof typeof machineTranslationActionContext;

type MachineTranslationActionContext = typeof machineTranslationActionContext[MachineTranslationActionContextKeys];

export async function getMachineTranslatedText(params: {
  sourceLanguage: string;
  targetLanguage: string;
  text: string;
  context?: MachineTranslationActionContext;
  token: string;
  configGroupId?: number;
}): Promise<string> {
  const {
    text: source_text,
    sourceLanguage: source_language,
    targetLanguage: target_language,
    context: action_context,
    token,
    configGroupId,
  } = params;
  const response = await api.post(
    `/api/_internal/mt/translate/text_sync`,
    {
      source_text,
      source_language,
      target_language,
      action_context,
      config_group_id: configGroupId,
    },
    {
      headers: { token: token },
    }
  );
  return response.data.translated_text;
}

type GuessFormsRequestInput = {
  gf_category: string;
  forms: Record<string, string>;
  languageCode: LanguageCode;
  token: string;
};

type GuessFormsResponseData = Record<string, string>;

export async function guessLexiconForms({
  gf_category,
  forms,
  languageCode,
  token,
}: GuessFormsRequestInput): Promise<GuessFormsResponseData> {
  return api
    .post(
      `/api/_internal/vocabulary/guess_lexicon_forms`,
      {
        gf_category,
        forms: forms,
        language: languageCode,
      },
      { headers: { token: token } }
    )
    .then((response) => response.data);
}

type GenerateRequestWordSamplesRequestInput = {
  base_form: string;
  languageCode: LanguageCode;
  token: string;
};

export type GenerateRequestWordSamplesResponseItem = {
  name: string;
  sample: string;
  type: string;
};

export async function generateRequestWordSamples({
  base_form,
  languageCode,
  token,
}: GenerateRequestWordSamplesRequestInput): Promise<
  GenerateRequestWordSamplesResponseItem[]
> {
  const response = await api.post(
    `/api/_internal/vocabulary/generate_request_word_samples`,
    {
      base_form: base_form,
      language: languageCode,
    },
    { headers: { token: token } }
  );
  return response.data;
}

type TemplateLexiconShortcutParams = {
  token: string;
  templateVocabId: string;
  shortcutType: TemplateLexiconShortcutTypes;
};

export async function getTemplateLexiconShortcut({
  token,
  templateVocabId,
  shortcutType,
}: TemplateLexiconShortcutParams): Promise<TemplateItem[]> {
  const response = await api.post(
    "/api/_internal/vocabulary/product_template_lexicon_shortcut/",
    { template_vocab_id: templateVocabId, shortcut_type: shortcutType },
    { headers: { token } }
  );
  return response.data;
}

export type PrefillProductCategoriesResponse = {
  name: string;
  id: string;
};

export type SortOptionsRequestParams = {
  order?: SortOption;
};

/*
 * Get prefill template categories
 */
export async function getPrefillProductCategories(
  token: string,
  order?: SortOption
): Promise<PrefillProductCategoriesResponse[]> {
  const params: SortOptionsRequestParams = {
    order: order || "recently_used",
  };
  const response = await api.get(
    "/api/_internal/product/get_template_categories/",
    { headers: { token }, params }
  );
  return response.data;
}

type PrefillFromTemplateCategoryParams = {
  token: string;
  categoryId: string;
  productId: ProductId;
};

export interface PrefillFromTemplateCategoryResponse {
  status: number;
}

export async function prefillFromTemplateCategory({
  token,
  categoryId,
  productId,
}: PrefillFromTemplateCategoryParams): Promise<
  PrefillFromTemplateCategoryResponse
> {
  return api.post(
    "/api/_internal/product/prefill_from_template_category/",
    { category_id: categoryId, product_id: productId },
    { headers: { token } }
  );
}

type CreateTemplateCategoryParams = {
  token: string;
  productId: ProductId;
  name: string;
};

type CreateOrOverwriteProductCategoryParams = {
  token: string;
  productId: ProductId;
  name?: string;
  productCategoryId?: string;
};

interface CreateTemplateCategoryResponse {
  data: {
    success: boolean;
    message: string;
  };
}

interface CreateOrOverwriteProductCategoryResponse {
  data?: {
    message: string;
  };
}

export async function createTemplateCategory({
  token,
  productId,
  name,
}: CreateTemplateCategoryParams): Promise<CreateTemplateCategoryResponse> {
  return api.post(
    "/api/_internal/product/save_template_category/",
    { name: name, product_id: productId },
    { headers: { token } }
  );
}

export async function createOrReplaceProductCategory({
  token,
  productId,
  name,
  productCategoryId,
}: CreateOrOverwriteProductCategoryParams): Promise<
  CreateOrOverwriteProductCategoryResponse
> {
  return api.post(
    "/api/_internal/product/create_or_replace_product_category/",
    {
      product_id: productId,
      name: name,
      product_category_id: productCategoryId,
    },
    { headers: { token } }
  );
}

interface ProductActionResult {
  message?: string;
}

export async function recommendationVocabularyAdd({
  token,
  productId,
  highlightedTagId = null,
  vocabularyId = null,
}: {
  token: string;
  productId: ProductId;
  highlightedTagId?: string | number | null;
  vocabularyId?: string | number | null;
}): Promise<ProductActionResult> {
  const response = await api.post(
    `/api/_internal/vocabulary/recommendations_vocabulary_add/`,
    {
      product: productId,
      selected_tag_id: highlightedTagId,
      vocabulary_id: vocabularyId,
    },
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export async function recommendationVocabularyAddSeo({
  token,
  productId,
  highlightedTagId = null,
  vocabularyId = null,
  replaceTag = false,
}: {
  token: string;
  productId: ProductId;
  highlightedTagId?: string | number | null;
  vocabularyId?: string | number | null;
  replaceTag?: boolean | null;
}): Promise<ProductActionResult> {
  const response = await api.post(
    `/api/_internal/vocabulary/recommendations_vocabulary_add_seo/`,
    {
      product: productId,
      selected_tag_id: highlightedTagId,
      vocabulary_id: vocabularyId,
      replace_root_tag: replaceTag,
    },
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export type SimilarVocabulary = {
  vocabulary_id: number;
  translations: Record<LanguageCode, string | undefined>;
};

export async function getSimilarVocabularies(
  token: string,
  searchTerm: string,
  tagType: string,
  category: string,
  limit: number = 10,
  contains: boolean = false,
  excludeVocabularyIds: number[] = []
): Promise<SimilarVocabulary[]> {
  const response = await api.post<SimilarVocabulary[]>(
    "/api/_internal/vocabulary/get-similar-vocabularies",
    {
      search_term: searchTerm,
      tag_type: tagType,
      category: category,
      limit: limit,
      contains: contains,
      exclude_vocabulary_ids: excludeVocabularyIds,
    },
    { headers: { token: token } }
  );

  return response.data;
}

export async function copyVocabularyToCustomer(
  token: string,
  vocabularyId: number,
  requestTranslation: boolean = false
): Promise<VocabularyTreeData> {
  const response = await api.post<VocabularyTreeData>(
    "/api/_internal/vocabulary/copy-vocabulary-to-customer",
    {
      vocabulary_id: vocabularyId,
      request_translation: requestTranslation,
    },
    { headers: { token: token } }
  );

  return response.data;
}
