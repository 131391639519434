import React, { useCallback, useState } from "react";
import {
  SectionAction,
  TemplateFieldInfoBase,
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import { debounce } from "../../utils/debounce";

interface Props<T> {
  field: T;
  form: TemplateFormInfo;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  placeholder: string;
  type: string;
}

export const InputFormField = <T extends TemplateFieldInfoBase>({
  field,
  form,
  placeholder,
  onSectionItemAction,
  type,
}: Props<T>): React.ReactElement => {
  const [value, setValue] = useState(field.value || "");
  const [isSaving, setIsSaving] = useState(false);
  const debounceCallback = useCallback(
    debounce((value: string): void => {
      setIsSaving(true);
      onSectionItemAction({
        action: SectionAction.TEMPLATE_FIELD_SET,
        tag_id: form.id,
        field: field.key,
        text_value: value,
      }).then(() => {
        setIsSaving(false);
      });
    }, 1000),
    []
  );

  const onInputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.currentTarget.value;
    setValue(value);
    debounceCallback(value);
  };

  return (
    <input
      disabled={isSaving}
      onChange={onInputChangeHandler}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
};
