import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
  highlightedTagId: string;
};

const productDetailEnrichTabSlice = createSlice({
  name: "productDetailEnrichTab",
  initialState: { highlightedTagId: null } as initialState,
  reducers: {
    setHighlightedTag: (
      state,
      { payload: highlightedTagId }: PayloadAction<string>
    ) => {
      if (highlightedTagId !== state.highlightedTagId) {
        state.highlightedTagId = highlightedTagId;
      } else {
        state.highlightedTagId = null;
      }
    },
  },
});

export const { setHighlightedTag } = productDetailEnrichTabSlice.actions;

export default productDetailEnrichTabSlice.reducer;
