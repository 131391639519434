import React from "react";
import { Product } from "../../product";
import { ProductTextRef } from "../../../producttext/ProductText";
import { ProductTextViewerComp } from "../../../producttext/ProductTextViewer";
import { Customer } from "../../../customers/Customer";
import { Column } from "./Column";
import { LanguageCode } from "../../../customers/customerlanguages";
import { ProductListRenderContext } from "../ObjectTable";

export const createProductTextColumn = (
  customer: Customer,
  channel: number,
  language: LanguageCode,
  showTextColumns: boolean
): Column => {
  const channelObject = customer.getChannelById(channel);
  const languageObject = customer.getLanguageByCode(language);
  return {
    name: `language_${channelObject.id}_${languageObject.code}`,
    label: `${channelObject.display_name} (${languageObject.short_name})`,
    visible: showTextColumns,
    className: "text-column",
    // eslint-disable-next-line react/display-name
    renderCell: (
      product: Product,
      column: Column,
      context: ProductListRenderContext
    ): JSX.Element => {
      const productTextRef = new ProductTextRef(
        product.id,
        channelObject.id,
        languageObject.code
      );
      const {
        onRequestProductTextAction,
        productTexts,
        loadingProductTexts,
      } = context;
      // FIXME: move productTexts to redux.
      const productText = productTexts[productTextRef.key];
      return (
        <ProductTextViewerComp
          markedForPublish={productTextRef.markedForPublish(
            customer,
            product.channel_language_pairs
          )}
          productText={productText}
          productTextRef={productTextRef}
          onRequestProductTextAction={onRequestProductTextAction}
          isLoading={loadingProductTexts}
        />
      );
    },
    width: 3,
    channelId: channel,
    language,
  };
};
