import React from "react";
import { Language, LanguageCode } from "../../../customers/customerlanguages";
import {
  MachineTranslationConfigWithSelection,
  MachineTranslationFormality,
  MachineTranslationFormalityType,
  MachineTranslationService,
  MachineTranslationServiceType,
} from "./types";
import { Dropdown } from "../../../components/tailwind";
import { LoadingTableColContent } from "./MachineTranslationConfigGroupForm";
import { Prompt } from "../../../products/copy-assistant/types";

type Props = {
  config: MachineTranslationConfigWithSelection;
  language: Language;
  onChangeConfigState: (
    languageCode: LanguageCode,
    newPartialConfig: Partial<MachineTranslationConfigWithSelection>
  ) => void;
  translationPrompts: Prompt[];
  isFetchingPrompts: boolean;
  disabled: boolean;
};

export const MachineTranslationConfig: React.FC<Props> = ({
  config,
  language,
  onChangeConfigState,
  translationPrompts,
  isFetchingPrompts,
  disabled,
}) => {
  return (
    <tr>
      <td>
        <label className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2">
          <input
            data-testid={`${language.code}-machine-translation-config-include-language`}
            className="tw-form-checkbox tw-h-4 tw-w-4 tw-rounded tw-border-gray-400 tw-text-textual-blue focus:tw-ring-gray-400 disabled:tw-border-gray-200 disabled:tw-text-gray-200"
            type="checkbox"
            checked={config?.isSelected || false}
            onChange={(): void =>
              onChangeConfigState(language.code, {
                isSelected: !config?.isSelected,
              })
            }
            onKeyDown={(event): void => {
              if (event.key === "Enter") {
                onChangeConfigState(language.code, {
                  isSelected: !config?.isSelected,
                });
              }
            }}
            disabled={disabled}
          />
          <span {...(!config?.isSelected && { className: "tw-text-gray-400" })}>
            <span>{language.flag}</span> {language.name}
          </span>
        </label>
      </td>

      <td>
        <div className="tw-max-w-[15rem]">
          <Dropdown<MachineTranslationServiceType>
            options={Object.entries(MachineTranslationService).map(
              ([value, text]) => ({
                key: value,
                value: value as MachineTranslationServiceType,
                text,
              })
            )}
            onChange={(value): void =>
              onChangeConfigState(language.code, {
                machine_translation_service: value as MachineTranslationServiceType,
              })
            }
            value={config?.machine_translation_service || "DEEPL"}
            placeholder="Service..."
            size="sm"
            testIdBase={`${language.code}-machine-translation-service`}
          />
        </div>
      </td>
      <td>
        {isFetchingPrompts ? (
          <LoadingTableColContent />
        ) : (
          <div
            className={
              config?.machine_translation_service === "GPT"
                ? "tw-flex tw-items-center tw-gap-2 tw-mb-2"
                : "tw-hidden"
            }
          >
            <label className="tw-w-[10rem] tw-font-semibold">
              Translation prompt:
            </label>
            <Dropdown<number>
              options={translationPrompts.map((prompt) => ({
                text: prompt.name,
                value: prompt.id,
                key: prompt.id.toString(),
              }))}
              onChange={(value): void =>
                onChangeConfigState(language.code, {
                  machine_translation_prompt: value as number,
                })
              }
              value={config?.machine_translation_prompt || 0}
              placeholder="Select GPT prompt..."
              size="sm"
              clearable
              testIdBase={`${language.code}-machine-translation-prompt`}
            />
          </div>
        )}
        <div
          className={
            config?.machine_translation_service !== "GPT"
              ? "tw-flex tw-items-center tw-gap-2 tw-mb-2"
              : "tw-hidden"
          }
        >
          <label className="tw-w-[10rem] tw-font-semibold">Formality:</label>
          <Dropdown<MachineTranslationFormalityType>
            options={Object.entries(MachineTranslationFormality).map(
              ([value, text]) => ({
                key: value,
                value: value as MachineTranslationFormalityType,
                text,
              })
            )}
            onChange={(value): void =>
              onChangeConfigState(language.code, {
                machine_translation_formality: value as MachineTranslationFormalityType,
              })
            }
            value={config?.machine_translation_formality}
            placeholder="Select formality..."
            size="sm"
            clearable
            testIdBase={`${language.code}-machine-translation-formality`}
          />
        </div>
      </td>
    </tr>
  );
};
