"use strict";
import $ from "jquery";

const { loadPartial } = window;
/*
 * Mapping editing modal
 */
const topActions = $("#actions");

const quietSubmit = function (form) {
  topActions.addClass("loading");

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: form.serializeArray(),
    success: function () {
      topActions.removeClass("loading");
      loadPartial($("#mapping_tree_recommendations"), true);
    },
  });
};

window.tagTreeEditorOnDataChanged = function (element) {
  const form = $(element).closest("form");
  setTimeout(function () {
    quietSubmit(form);
  });
};

$(document).on(
  "change",
  '#edit form input[type="checkbox"][name="is_product_type"]',
  function () {
    const $input = $(this);
    const form = $input.closest("form");
    quietSubmit(form);
  }
);

// Run callback when value changed with a wait of 300ms
const onTextChange = function (selector, callback) {
  $(document).on("keydown", selector, function () {
    const input = $(this);
    input.data("value", input.val());
  });

  $(document).on("keyup", selector, function () {
    const input = $(this);

    let _timeout = input.data("timeout");
    let _oldValue = input.data("value") + "";
    let _newValue = input.val() + "";

    _oldValue = _oldValue || "";
    _newValue = _newValue || "";

    if (_newValue.trim() !== _oldValue.trim()) {
      clearTimeout(_timeout);
      _timeout = setTimeout(function () {
        callback(input);
      }, 300);

      input.data("timeout", _timeout);
    }
  });
};

onTextChange("[data-auto-quiet] > textarea", function (input) {
  const form = $(input).closest("form");
  setTimeout(function () {
    quietSubmit(form);
  });
});

$(document).on("change", "[data-auto-quiet] > input", function () {
  const $input = $(this);
  const form = $input.closest("form");
  quietSubmit(form);
});

$(document).on(
  "change",
  'input[type="checkbox"].mappings-template-labels',
  function (input) {
    const label = $(input.currentTarget.nextElementSibling);
    const isActive = label[0].classList.value.includes("color-primary");
    if (isActive) {
      label.removeClass("color-primary");
    } else {
      label.addClass("color-primary");
    }
  }
);
