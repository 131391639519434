import React from "react";
import { Label } from "semantic-ui-react";
import Styled from "styled-components";

import { ProductListRenderContext } from "./ObjectTable";
import { Product } from "../product";

type Props = {
  product: Product;
  context: ProductListRenderContext;
};

const FlexWrapDiv = Styled.div`
  & {
    display: flex;
    flex-wrap: wrap;
    gap: 3px 0;
  }
`;
export const ProductListLabels: React.FC<Props> = ({ product, context }) => (
  <FlexWrapDiv>
    {product.labels.map((label: string) => (
      <Label
        as="a"
        key={label}
        size="mini"
        onClick={(e): void => {
          e.preventDefault();
          context.onSelectLabel(label);
        }}
      >
        {label}
      </Label>
    ))}
  </FlexWrapDiv>
);
