import React from "react";
import { BalloonDiv } from "../../components/BalloonDiv";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SEORecommendation } from "./ProductRecommendations";
import { SEOFieldRecommendations } from "./SEOFieldRecommendations";
import { ProductTagAction } from "../../api/action";

interface Props {
  details: ProductDetailInfo;
  onTagsAction?: onTagsActionCallback;
  seoRecommendations: SEORecommendation[];
}

export const SEORecommendations: React.FC<Props> = ({
  details,
  onTagsAction,
  seoRecommendations,
}) => {
  if (seoRecommendations.length == 0) {
    return null;
  }

  return (
    <div className="seo-recommendations">
      <BalloonDiv
        className={"label"}
        value={details.microcopies["seo_recommendations"]}
      >
        <label>Optimize for search term</label>
      </BalloonDiv>
      <SEOFieldRecommendations
        onTagsAction={(recommendation): void =>
          onTagsAction?.({
            action: ProductTagAction.ADD_SEO_RECOMMENDATION,
            value: recommendation.id,
          })
        }
        seoRecommendations={seoRecommendations}
      />
    </div>
  );
};
