import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Icon,
  Input,
  List,
  Loader,
  Modal,
  Popup,
  Segment,
  Transition,
} from "semantic-ui-react";
import { Icon as TWIcon } from "../../../components/tailwind";

import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import {
  createDocumentSection,
  deleteDocumentSection,
  updateDocumentSection,
} from "../../../api/documentStructureApi";
import { popupDelay } from "../../../customers/gpt/types";
import { Text } from "../../../components/Text";
import { IllustrationKeys, Section, SectionFormMode } from "../types";
import { RootState, store } from "../../../utils/store";

import { EditSectionForm } from "./EditSectionForm";
import { ConfirmModal } from "./ConfirmModal";

type Props = {
  excludeSections: Section[];
  addSectionToStructure: (section: Section) => void;
  allSections: Section[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  refreshSections: () => Promise<void>;
  defaultOpen: boolean;
  setDefaultOpen: (defaultOpen: boolean) => void;
};

export const SectionFormModal: React.FC<Props> = ({
  excludeSections,
  addSectionToStructure,
  allSections,
  loading,
  setLoading,
  refreshSections,
  defaultOpen,
  setDefaultOpen,
}) => {
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();

  const token = useSelector((state: RootState) => state.auth.token);

  const [open, setOpen] = useState(false);

  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);

  const [formMode, setFormMode] = useState<SectionFormMode>(
    SectionFormMode.UPDATE
  );

  const [isEditing, setIsEditing] = useState(false);

  const [selectedSection, setSelectedSection] = useState<Section>();
  const [sectionName, setSectionName] = useState("");
  const [sectionTemplateLabels, setSectionTemplateLabels] = useState([]);
  const [illustration, setIllustration] = useState<IllustrationKeys>();

  const [searchQuery, setSearchQuery] = useState("");

  const isDefaultCustomerSection = useCallback(
    (section: Section) => {
      if (!customer) {
        return false;
      }
      return customer?.config?.default_document_section === section?.id;
    },
    [customer]
  );

  const [confirmActionModalProps, setConfirmActionModalProps] = useState<{
    header: string;
    content: React.ReactElement;
    confirmAction: () => void;
  }>({
    header: "",
    content: <></>,
    confirmAction: (): void => console.error("Confirm action not set"),
  });

  useEffect(() => {
    setOpen(defaultOpen || open);
  }, [defaultOpen]);

  const excludeSectionIds = useMemo(() => {
    return excludeSections.map((section) => section.id);
  }, [excludeSections]);

  const filteredSections = useMemo(() => {
    if (!searchQuery) return allSections;
    return allSections.filter((section) =>
      section.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, allSections, loading, excludeSections]);

  const noSectionsFound = useMemo(() => {
    if (loading) {
      return "";
    }
    if (filteredSections.length === 0) {
      return "No sections found.";
    }
  }, [loading, filteredSections, searchQuery]);

  const handleDeselectSection = (): void => {
    setIsEditing(false);
    setFormMode(undefined);
    setSelectedSection(undefined);
    setSectionName("");
    setSectionTemplateLabels([]);
    setIllustration("body");
  };

  const handleSelectSection = (
    section: Section | undefined,
    formMode: SectionFormMode
  ): void => {
    setIsEditing(formMode === SectionFormMode.UPDATE);
    setFormMode(formMode);
    setSelectedSection(section);
    if (!section) {
      setSectionName("");
      setSectionTemplateLabels([]);
      setIllustration("body");
      return;
    }
    setSectionName(section.name);
    setSectionTemplateLabels(section.template_label_ids);
    setIllustration(section.illustration_type);
  };

  const handleCreateSection = async (): Promise<void> => {
    const savedName = sectionName;
    const savedTemplateLabels =
      sectionTemplateLabels?.length > 0 ? sectionTemplateLabels : null;
    const savedIllustration = illustration;
    setLoading(true);
    handleDeselectSection();
    await createDocumentSection(token, {
      name: savedName,
      template_label_ids: savedTemplateLabels,
      illustration_type: savedIllustration,
    }).then(({ name }) => {
      store.dispatch(
        setDjangoToastOpen({
          content: "Section Created",
          appearance: NotificationAppearance.SUCCESS,
          additionalContent: `${name} has been created`,
        })
      );
    });
    await refreshSections();
    setLoading(false);
  };
  const handleUpdateSection = async (confirmed?: boolean): Promise<void> => {
    if (!confirmed) {
      if (
        !selectedSection?.template_label_ids &&
        sectionTemplateLabels?.length > 0
      ) {
        handleConfirmChangeSectionBetweenRuleAndGPT("Rule-based");
        return;
      }
      if (
        selectedSection?.template_label_ids &&
        !sectionTemplateLabels.length
      ) {
        handleConfirmChangeSectionBetweenRuleAndGPT("AI generated text");
        return;
      }
    }
    setLoading(true);
    const updatedSection = {
      ...selectedSection,
      name: sectionName,
      template_label_ids:
        sectionTemplateLabels?.length > 0 ? sectionTemplateLabels : null,
      illustration_type: illustration,
    };

    await updateDocumentSection(token, updatedSection).then(({ name }) => {
      store.dispatch(
        setDjangoToastOpen({
          content: "Section Updated",
          appearance: NotificationAppearance.SUCCESS,
          additionalContent: `${name} has been updated`,
        })
      );
    });
    handleDeselectSection();
    await refreshSections();
    setLoading(false);
  };

  const handleActionButtonClick = async (): Promise<void> => {
    if (formMode === SectionFormMode.UPDATE) {
      await handleUpdateSection();
      return;
    }
    await handleCreateSection();
  };

  const handleDeleteSection = async (): Promise<void> => {
    setLoading(true);
    await deleteDocumentSection(token, selectedSection.id).then(() => {
      store.dispatch(
        setDjangoToastOpen({
          content: "Section Deleted",
          appearance: NotificationAppearance.WARNING,
          additionalContent: `${
            selectedSection?.name || "a Section"
          } has been deleted`,
        })
      );
    });
    handleDeselectSection();
    await refreshSections();
    setLoading(false);
  };

  const handleConfirmChangeSectionBetweenRuleAndGPT = (
    type: "Rule-based" | "AI generated text"
  ): void => {
    setConfirmActionModalProps({
      header: `Confirm Change Section ${selectedSection?.name} to ${type}`,
      content: (
        <>
          <Text compact>
            Are you sure you want to change Section {selectedSection?.name} to
            {""}
            {type}?
          </Text>
          <Text compact color="grey">
            {type === "Rule-based"
              ? "Prompts using this Section will be redirected to the default Section."
              : "Templates using this Section will be ignored."}
          </Text>
        </>
      ),
      confirmAction: (): Promise<void> => handleUpdateSection(true),
    });
    setConfirmActionModalOpen(true);
  };

  const handleConfirmDeleteSection = (): void => {
    setConfirmActionModalProps({
      header: `Confirm Delete Section ${selectedSection?.name}`,
      content: (
        <>
          <Text compact>
            Are you sure you want to delete Section {selectedSection?.name}?
          </Text>
          <Text compact color="grey">
            This will remove parts of product texts associated with this
            Section.
          </Text>
        </>
      ),
      confirmAction: handleDeleteSection,
    });
    setConfirmActionModalOpen(true);
  };

  const handleAddSectionToStructure = (section: Section): void => {
    addSectionToStructure(section);
    handleDeselectSection();
  };

  return (
    <Dimmer.Dimmable dimmed={loading}>
      <Modal
        size="small"
        onOpen={(): void => setOpen(true)}
        onClose={(): void => {
          handleDeselectSection();
          setOpen(false);
        }}
        onUnmount={(): void => {
          setDefaultOpen(open);
        }}
        open={open}
        trigger={
          <Button
            data-testid="section-form-modal-trigger"
            content="Manage/Add sections"
            color="red"
            size="small"
            icon="plus"
            disabled={loading}
            loading={loading}
          />
        }
      >
        <Modal.Header>Sections</Modal.Header>
        <Modal.Content>
          {allSections.length > 10 && (
            <>
              <Form as="div" size="tiny">
                <Form.Field>
                  <Input
                    data-testid="section-form-modal-search-input"
                    placeholder="Search"
                    icon="search"
                    value={searchQuery}
                    onChange={(_, { value }): void => setSearchQuery(value)}
                  />
                </Form.Field>
              </Form>
              <Divider hidden />
            </>
          )}
          {noSectionsFound && formMode !== SectionFormMode.CREATE ? (
            <>
              <Text
                size="small"
                compact
                color="grey"
                testId="section-form-modal-no-sections-found"
              >
                {noSectionsFound}
              </Text>
              <Divider />
            </>
          ) : (
            <List size="small" divided relaxed>
              {filteredSections.map((section) => (
                <List.Item
                  key={section.id}
                  disabled={loading}
                  data-testid={`section-form-modal-list-item-${section.name}`}
                >
                  {isEditing && selectedSection?.id === section.id ? (
                    <Transition transitionOnMount>
                      <List.Content
                        as={Segment}
                        data-testid={`section-form-modal-list-item-${section.name}-content-edit`}
                      >
                        <EditSectionForm
                          loading={loading || isCustomerLoading}
                          sectionName={sectionName}
                          setSectionName={setSectionName}
                          availableTemplateLabels={customer?.template_labels}
                          sectionTemplateLabels={sectionTemplateLabels}
                          setSectionTemplateLabels={setSectionTemplateLabels}
                          illustration={illustration}
                          setIllustration={setIllustration}
                          handleActionButtonClick={handleActionButtonClick}
                          handleDeleteSection={handleConfirmDeleteSection}
                          handleClose={handleDeselectSection}
                          formMode={SectionFormMode.UPDATE}
                        />
                      </List.Content>
                    </Transition>
                  ) : (
                    <List.Content
                      data-testid={`section-form-modal-list-item-${section.name}-content`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Popup
                          disabled={!section?.locked}
                          content={
                            isDefaultCustomerSection(section)
                              ? "This Section is reserved for System Prompts and cannot be edited."
                              : "This Section is not editable."
                          }
                          size="small"
                          wide="very"
                          mouseEnterDelay={popupDelay}
                          trigger={
                            <Segment
                              compact
                              color={
                                excludeSectionIds.includes(section.id)
                                  ? "grey"
                                  : "blue"
                              }
                              inverted
                              style={{
                                cursor: excludeSectionIds.includes(section.id)
                                  ? "default"
                                  : "pointer",
                              }}
                              className="!tw-m-0 !tw-flex tw-items-center tw-gap-1 !tw-p-2"
                              onClick={(): void => {
                                if (excludeSectionIds.includes(section.id))
                                  return;
                                handleAddSectionToStructure(section);
                              }}
                            >
                              {section?.locked && (
                                <Icon name="lock" size="small" />
                              )}
                              <Text
                                size="small"
                                compact
                                inline={section?.locked}
                              >
                                {section.name}
                              </Text>
                              {section.template_label_ids ? (
                                <TWIcon
                                  className="tw-text-lg tw-leading-[1]"
                                  data-balloon="Rule-based Section"
                                  data-balloon-pos="down-left"
                                  name="call_split"
                                />
                              ) : (
                                <TWIcon
                                  className="tw-text-lg tw-leading-[1]"
                                  data-balloon="AI Section"
                                  data-balloon-pos="down-left"
                                  name="auto_awesome"
                                />
                              )}
                            </Segment>
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "auto", marginRight: "6px" }}>
                        {!excludeSectionIds.includes(section.id) ? (
                          <Button
                            data-testid={`section-form-modal-list-item-${section.name}-add-to-document-button`}
                            size="mini"
                            basic
                            compact
                            color="red"
                            content="Add to structure"
                            icon="plus"
                            onClick={(): void =>
                              handleAddSectionToStructure(section)
                            }
                          />
                        ) : (
                          <div style={{ paddingRight: "14px" }}>
                            <Text
                              compact
                              size="small"
                              color="grey"
                              data-testid={`section-form-modal-list-item-${section.name}-added-to-document-text`}
                            >
                              Added to structure
                            </Text>
                          </div>
                        )}
                      </div>
                      <Popup
                        size="tiny"
                        content={
                          section?.locked
                            ? "This Section is not editable"
                            : "Edit section"
                        }
                        mouseEnterDelay={popupDelay}
                        trigger={
                          <span>
                            <Button
                              disabled={section?.locked}
                              data-testid={`section-form-modal-list-item-${section.name}-edit`}
                              size="mini"
                              basic
                              compact
                              color="red"
                              icon="pencil alternate"
                              onClick={(): void => {
                                handleSelectSection(
                                  section,
                                  SectionFormMode.UPDATE
                                );
                              }}
                            />
                          </span>
                        }
                      />
                    </List.Content>
                  )}
                </List.Item>
              ))}
              {formMode === SectionFormMode.CREATE && (
                <Transition transitionOnMount>
                  <List.Item data-testid="section-form-modal-create-section">
                    <List.Content as={Segment}>
                      <EditSectionForm
                        loading={loading || isCustomerLoading}
                        sectionName={sectionName}
                        setSectionName={setSectionName}
                        availableTemplateLabels={customer?.template_labels}
                        sectionTemplateLabels={sectionTemplateLabels}
                        setSectionTemplateLabels={setSectionTemplateLabels}
                        illustration={illustration}
                        setIllustration={setIllustration}
                        handleActionButtonClick={handleActionButtonClick}
                        handleDeleteSection={handleConfirmDeleteSection}
                        handleClose={handleDeselectSection}
                        formMode={SectionFormMode.CREATE}
                      />
                    </List.Content>
                  </List.Item>
                </Transition>
              )}
            </List>
          )}

          <Button
            data-testid="section-form-modal-create-section-button"
            content="New section"
            color="red"
            basic
            size="mini"
            compact
            floated="right"
            onClick={(): void => {
              handleSelectSection(undefined, SectionFormMode.CREATE);
            }}
          />
          <Divider hidden />
        </Modal.Content>
        <Modal.Actions className="modal-actions">
          <Button
            data-testid="section-form-modal-close-button"
            content="Close"
            size="tiny"
            basic
            onClick={(): void => setOpen(false)}
          />
        </Modal.Actions>

        <Dimmer active={loading} inverted>
          <Loader data-testid="section-form-modal-loader" />
        </Dimmer>
      </Modal>
      <ConfirmModal
        open={confirmActionModalOpen}
        setOpen={setConfirmActionModalOpen}
        {...confirmActionModalProps}
      />
    </Dimmer.Dimmable>
  );
};
