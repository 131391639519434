import React, { useState } from "react";
import {
  Flag,
  Language,
  LanguageCode,
} from "../../customers/customerlanguages";
import { Button, Dropdown, Icon } from "../../components/tailwind";
import { TemplateField } from "../template";
import { Loader } from "semantic-ui-react";
import {
  OnUpdateLexiconParams,
  TemplateLexiconWithUncommittedTranslationChanges,
} from "./TemplateBuilderModal";
import { TemplateBuilderContentLanguageInputToolbar } from "./TemplateBuilderContentLanguageInputToolbar";
import { AdvancedEditor, useNoCodeEditor } from "./no-code-editor";
import { TemplateBuilderContentVariableHandler } from "./TemplateBuilderContentVariableHandler";
import { TemplateBuilderContentLanguageInputVariant } from "./TemplateBuilderContentLanguageInputVariant";
import { uuidv4 } from "../../utils/uuidUtils";

type AllProps = {
  // Always required props
  language: Language;
  lexicon: TemplateLexiconWithUncommittedTranslationChanges;
  onUpdateLexicon: (
    languageCode: LanguageCode,
    params: OnUpdateLexiconParams
  ) => void;
  onUpdateLexiconVariant: (
    languageCode: LanguageCode,
    value: string,
    index: number,
    removeVariant?: boolean
  ) => void;
  variables: TemplateField[];
  onCreateVariable: (variable: TemplateField) => void;
  onCopyToAllLexicons: (languageCode: LanguageCode, value: string) => void;
  onTranslate: (
    targetLanguageCodes: LanguageCode[],
    action?: "single" | "all"
  ) => Promise<void>;
  canTranslate: boolean;
  isTranslating: boolean;
  // Sometimes required props
  isStatic?: boolean;
  onViewAll?: () => void;
  onViewPreview?: () => void;
  viewMode?: "all" | "preview" | null;
  asPreview?: boolean;
  languageOptions?: Language[];
  selectLanguage?: (language: Language) => void;
  onSendAllLexiconsToManualTranslation?: (languageCode: LanguageCode) => void;
};

type PreviewProps = AllProps & {
  asPreview: true;
  languageOptions: Language[];
  selectLanguage: (language: Language) => void;
  onSendAllLexiconsToManualTranslation?: never;
};

type StaticProps = AllProps & {
  isStatic: true;
  viewMode: "all" | "preview" | null;
  onViewAll: () => void;
  onViewPreview: () => void;
  selectLanguage: (language: Language) => void;
  onSendAllLexiconsToManualTranslation: (languageCode: LanguageCode) => void;
};

type RegularProps = AllProps & {
  isStatic?: false | never;
  asPreview?: false | never;
  languageOptions?: never;
  setSelectedPreviewLanguage?: never;
  viewMode?: never;
  onViewAll?: never;
  onViewPreview?: never;
  onSendAllLexiconsToManualTranslation?: never;
};

type CombinedProps = PreviewProps | StaticProps | RegularProps;

export const TemplateBuilderContentLanguageInput: React.FC<CombinedProps> = ({
  isStatic,
  language,
  lexicon,
  onUpdateLexicon,
  onUpdateLexiconVariant,
  variables,
  onCreateVariable,
  onCopyToAllLexicons,
  onViewAll,
  onViewPreview,
  viewMode,
  asPreview,
  languageOptions,
  selectLanguage,
  onTranslate,
  canTranslate,
  isTranslating,
  onSendAllLexiconsToManualTranslation,
}) => {
  const [viewVariables, setViewVariables] = useState(false);
  const [viewAdvanced, setViewAdvanced] = useState(false);
  const {
    onAddVariable,
    dragHandlers,
    errorParsingNodes,
    NoCodeEditor,
  } = useNoCodeEditor({
    input: lexicon?.template || "",
    updateInput: (input: string): void => {
      onUpdateLexicon(language?.code, { value: input });
    },
    availableVariables: variables,
    placeholder: `template content for ${language?.short_name}...`,
    dependencies: [viewAdvanced, variables, lexicon],
  });

  return (
    <div
      className="tw-flex tw-flex-col tw-gap-2"
      data-testid={`template-builder-content-language-input-${language?.code}${
        isStatic ? "-static" : ""
      }`}
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        {isStatic && (
          <>
            <p className="tw-m-0 tw-text-sm">
              Create a Template by adding text and variables.
              <br />
              Variables are placeholders in the text that automatically get
              replaced with a value when the Template is used. This value can be
              numbers, text or vocabularies.
            </p>
            <p className="tw-m-0 -tw-mb-1 tw-text-xs">
              Select Language for this template
            </p>
          </>
        )}
        <div className="tw-flex tw-items-center tw-gap-2">
          {asPreview || isStatic ? (
            <>
              <Flag
                content={language ? language.flag : languageOptions[0]?.flag}
                className={!language ? "tw-invisible" : ""}
              />
              <Dropdown
                testIdBase={`template-builder-content-language-selector${
                  isStatic ? "-static" : "-preview"
                }`}
                options={languageOptions.map((language) => ({
                  key: language.code,
                  value: language.code,
                  text: language.short_name,
                }))}
                value={language?.code}
                onChange={(value): void => {
                  selectLanguage(
                    languageOptions.find(({ code }) => code == value)
                  );
                }}
                placeholder="Select preview"
                clearable={!isStatic}
                size="sm"
              />
            </>
          ) : (
            <label htmlFor={`template-${language.code}-language-input`}>
              <Flag content={language.flag} /> {language.name}
            </label>
          )}
          {!!lexicon?.translator_approved && (
            <p className="tw-m-0 tw-text-sm tw-text-gray-400">
              This Template has been manually translated (
              {lexicon.translator_approved})
            </p>
          )}
        </div>
        <Button
          data-testid={`template-builder-content-language-${language?.code}-view-variables`}
          className="tw-mr-auto"
          icon
          variant="secondary-alt"
          size="sm"
          compact
          borderless
          disabled={
            !language ||
            (lexicon?.in_manual_translation &&
              (!lexicon?.notCommitted || lexicon?.canWithdrawWhenCommitted))
          }
          onClick={(): void => setViewVariables((prev) => !prev)}
        >
          Variables{" "}
          <Icon
            name={
              viewVariables && !lexicon?.in_manual_translation
                ? "expand_more"
                : "navigate_next"
            }
          />
        </Button>
      </div>
      <TemplateBuilderContentVariableHandler
        open={viewVariables && !lexicon?.in_manual_translation}
        variables={variables}
        onCreateVariable={onCreateVariable}
        onAddVariable={onAddVariable}
        dragHandlers={dragHandlers}
        disabledDrag={!!errorParsingNodes || viewAdvanced}
      />

      <div className="tw-grid tw-grid-cols-[1fr_20%] tw-gap-6">
        <div>
          <div className="tw-relative">
            {((lexicon?.in_manual_translation &&
              (!lexicon?.notCommitted || lexicon?.canWithdrawWhenCommitted)) ||
              isTranslating) && (
              <div
                className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-bg-white/80 tw-backdrop-blur-sm"
                data-testid={`template-builder-content-${language?.code}-textarea-dimmer`}
              >
                <h5>
                  {isTranslating
                    ? "Translating"
                    : "Sent for Manual Translation"}
                </h5>
                {isTranslating ? (
                  <Loader active inline />
                ) : (
                  <Button
                    content="Withdraw"
                    variant="primary"
                    size="sm"
                    compact
                    onClick={(): void =>
                      onUpdateLexicon(language?.code, {
                        inManualTranslation: false,
                      })
                    }
                  />
                )}
              </div>
            )}
            {errorParsingNodes || viewAdvanced ? (
              <AdvancedEditor
                input={lexicon?.template || ""}
                updateInput={(value: string) =>
                  onUpdateLexicon(language?.code, { value })
                }
                placeholder={`template content for ${language?.short_name}...`}
              />
            ) : (
              <NoCodeEditor />
            )}
          </div>
          <div className="tw-flex tw-gap-2">
            {isStatic && (
              <>
                <Icon
                  name="list"
                  className={`tw-cursor-pointer tw-leading-[1] ${
                    viewMode === "all" && "tw-text-primary"
                  }`}
                  data-balloon="View all Languages"
                  data-balloon-pos="up-left"
                  onClick={onViewAll}
                  data-testid="template-builder-content-view-all-action"
                />
                <Icon
                  data-balloon="Preview one Language"
                  data-balloon-pos="up-left"
                  onClick={onViewPreview}
                  name="visibility"
                  className={`tw-cursor-pointer tw-leading-[1]  ${
                    viewMode === "preview" && "tw-text-primary"
                  }`}
                  data-testid="template-builder-content-view-preview-action"
                />
                <div className="tw-border tw-border-secondary/30 tw-my-1"></div>
              </>
            )}
            <Icon
              data-balloon="Add variant"
              data-balloon-pos="up-left"
              onClick={() => {
                if (!lexicon?.template) return;
                onUpdateLexiconVariant(
                  lexicon?.language_code,
                  "",
                  lexicon?.template_variants?.length || 0
                );
              }}
              name="add"
              className={`tw-cursor-pointer tw-leading-[1] ${
                lexicon?.template
                  ? "tw-text-primary tw-cursor-pointer"
                  : "tw-text-gray-300"
              }`}
              data-testid="template-builder-content-add-variant-action"
            />
            <Icon
              data-balloon="Switch between Simple/Advance Editor"
              data-balloon-pos="up-right"
              onClick={(): void => {
                setViewAdvanced((prev) => !prev);
              }}
              name="code"
              className={`tw-cursor-pointer tw-leading-[1] tw-text-lg tw-ml-auto ${
                viewAdvanced && "tw-text-primary"
              }`}
              data-testid="template-builder-content-view-advanced-editor-action"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-2">
            {lexicon?.template_variants.map((variant, index) => (
              <TemplateBuilderContentLanguageInputVariant
                key={uuidv4()}
                variant={variant}
                index={index}
                language={language}
                onUpdateLexiconVariant={onUpdateLexiconVariant}
                variables={variables}
                onCreateVariable={onCreateVariable}
              />
            ))}
          </div>
        </div>
        <TemplateBuilderContentLanguageInputToolbar
          lexicon={lexicon}
          language={language}
          isStatic={isStatic}
          onUpdateLexicon={onUpdateLexicon}
          onCopyToAllLexicons={onCopyToAllLexicons}
          onSendAllLexiconsToManualTranslation={
            onSendAllLexiconsToManualTranslation
          }
          onTranslate={(): void => {
            let param = [language?.code];
            if (isStatic) {
              param = languageOptions
                .map(({ code }) => code)
                .filter((code) => code !== language?.code);
            }
            onTranslate(param, isStatic ? "all" : "single");
          }}
          isTranslating={isTranslating}
          canTranslate={canTranslate}
        />
      </div>
    </div>
  );
};
