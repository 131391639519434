import React from "react";
import { Checkbox } from "semantic-ui-react";
import { Text } from "../../components/Text";

type Props = {
  checked: boolean;
  id: string;
  helpText?: string;
  label: string;
  onCheckboxChanged: (checked: boolean) => void;
};

export const RequestWordModalAction: React.FC<Props> = ({
  checked,
  id,
  helpText,
  label,
  onCheckboxChanged,
}) => (
  <div>
    <Checkbox
      checked={checked}
      data-testid={"request-word-modal-action-checkbox"}
      id={id}
      label={label}
      onChange={(event, props): void => onCheckboxChanged(props.checked)}
    />
    {helpText && <Text content={helpText} color="grey" size="small" compact />}
  </div>
);
