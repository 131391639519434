import React from "react";
import { Spinner } from "../utils/Spinner";
import { ProductText, ProductTextRef } from "./ProductText";
import { ProductTextCommentEditor } from "./ProductTextCommentEditor";
import { ProductTextCommentViewer } from "./ProductTextCommentViewer";
import { ProductTextHTMLRenderer } from "./ProductTextHTMLRenderer";
import {
  getProductTextStatusIcon,
  missingTranslationIconWithTooltip,
  notMarkedForPublishIconWithTooltip,
  commentIconWithTooltip,
  editedIconWithTooltip,
} from "./ProductTextStatusIconHelper";
import { ProductTextErrorViewer } from "./ProductTextErrorViewer";
import { ProductTextAction } from "./ProductTextAction";
import styled from "styled-components";
import { ProductTextToolbar } from "./ProductTextToolbar";
import { ProductTextActivityModal } from "./ProductTextActivityModal";
import { ProductTextModalEditorComp } from "./ProductTextModalEditor";
import { connect, ConnectedProps } from "react-redux";
import { TextGeneratorError } from "../utils/types";

export interface OnActionCallback {
  (action: ProductTextAction, data?: string): void;
}

type Props = {
  markedForPublish: boolean;
  productTextRef: ProductTextRef;
  productText: ProductText | null;
  viewOnly?: boolean;
  isLoading?: boolean;
  onRequestProductTextAction?: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
};

type State = {
  error: TextGeneratorError | null;
  isCommenting: boolean;
  isActivityModalOpen: boolean;
  isEditing: boolean;
  isReloading: boolean;
};

const ActionButtonsHover = styled.div`
  & {
    position: relative;
    display: none;
    :hover {
      display: block;
      z-index: 2;
    }
  }
`;
export const PublishDateDiv = styled.div`
  font-size: 9pt;
  padding: 10px 0;
  font-weight: 600;
`;

const ProductTextWrapper = styled.div`
  &&:hover ${ActionButtonsHover} {
    display: block;
    z-index: 2;
  }
`;

const connector = connect(null, null);

type ProductTextViewerProps = ConnectedProps<typeof connector> & Props;

export class ProductTextViewer extends React.Component<
  ProductTextViewerProps,
  State
> {
  mounted = false;

  constructor(props: ProductTextViewerProps) {
    super(props);
    this.state = {
      isCommenting: false,
      isActivityModalOpen: false,
      isEditing: false,
      isReloading: false,
      error: null,
    };
    this.onAction = this.onAction.bind(this);
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  requestAction(action: ProductTextAction, data?: string): void {
    const {
      onRequestProductTextAction,
      productText,
      productTextRef,
    } = this.props;
    const isEditing = action === ProductTextAction.EDIT;
    this.setState({ isReloading: true, isEditing });
    onRequestProductTextAction(action, productTextRef, productText, data)
      .then(() => {
        if (this.mounted) {
          this.setState({ isReloading: false, isEditing: false, error: null });
        }
      })
      .catch((error) => {
        if (this.mounted) {
          this.setState({ error, isReloading: false });
        }
      });
  }

  onAction(action: ProductTextAction, data: string = null): void {
    switch (action) {
      case ProductTextAction.OPEN_COMMENT_EDITOR:
        this.setState({ isCommenting: true });
        break;
      case ProductTextAction.SAVE_AND_CLOSE_COMMENT_EDITOR:
        this.requestAction(ProductTextAction.COMMENT, data);
        this.setState({ isCommenting: false });
        break;
      case ProductTextAction.CLOSE_COMMENT_EDITOR:
        this.setState({ isCommenting: false });
        break;
      case ProductTextAction.OPEN_TEXT_EDITOR:
        this.setState({ isEditing: true });
        break;
      case ProductTextAction.SAVE_AND_CLOSE_TEXT_EDITOR:
        this.requestAction(ProductTextAction.EDIT, data);
        break;
      case ProductTextAction.CLOSE_TEXT_EDITOR:
        this.setState({ isEditing: false });
        break;
      case ProductTextAction.OPEN_ACTIVITY_MODAL:
        this.setState({ isActivityModalOpen: true });
        break;
      case ProductTextAction.CLOSE_ACTIVITY_MODAL:
        this.setState({ isActivityModalOpen: false });
        break;
      default:
        this.setState({ isReloading: true });
        this.requestAction(action, data);
        break;
    }
  }

  render(): React.ReactElement {
    const {
      error,
      isCommenting,
      isActivityModalOpen,
      isEditing,
      isReloading,
    } = this.state;
    const { markedForPublish, productText, viewOnly, isLoading } = this.props;
    if (!markedForPublish) {
      return <div>{notMarkedForPublishIconWithTooltip()}</div>;
    }

    if (isLoading || isReloading) {
      return <Spinner />;
    } else if (!productText) {
      return <></>;
    } else if (isCommenting) {
      return (
        <ProductTextCommentEditor
          initial={productText.comment}
          onAction={this.onAction.bind(this)}
        />
      );
    }

    let publishDateText = productText.published;
    if (publishDateText) {
      publishDateText = `Latest publication date: ${
        publishDateText.split("T")[0]
      }`;
    }
    return (
      <ProductTextWrapper>
        <ProductTextModalEditorComp
          open={isEditing}
          onAction={this.onAction.bind(this)}
          productText={productText}
        />
        {getProductTextStatusIcon(productText)}
        {productText.missingTranslation
          ? missingTranslationIconWithTooltip()
          : null}
        {productText.isEdited && editedIconWithTooltip()}
        {!!productText.comment && commentIconWithTooltip()}
        {error ? (
          <ProductTextErrorViewer error={error} />
        ) : (
          <ProductTextHTMLRenderer
            onAction={this.onAction.bind(this)}
            isEdited={productText.isEdited}
            productText={productText}
          />
        )}
        <ProductTextCommentViewer comment={productText.comment} />
        <PublishDateDiv>{publishDateText}</PublishDateDiv>
        {!viewOnly && (
          <>
            <ProductTextActivityModal
              key={productText.id}
              onClose={(): void =>
                this.onAction(ProductTextAction.CLOSE_ACTIVITY_MODAL)
              }
              open={isActivityModalOpen}
              productText={productText}
            />
            <ActionButtonsHover>
              <ProductTextToolbar
                onAction={this.onAction.bind(this)}
                productText={productText}
              />
            </ActionButtonsHover>
          </>
        )}
      </ProductTextWrapper>
    );
  }
}

export const ProductTextViewerComp = connector(ProductTextViewer);
