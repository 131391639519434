import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Message } from "semantic-ui-react";
import { RootState } from "../utils/store";
import { ProductListParams } from "../products/product-list/ProductListURLConfig";
import { setQuery } from "../api/customQuerySlice";
import { useDispatch, useSelector } from "react-redux";
import { updateActionSpec, useParseQueryMutation } from "../api/customQueryApi";
import { useGetCustomerQuery } from "../api/customerApi";

export const CustomQueryInputInterface: React.FC = () => {
  const [
    parseCurrentQuery,
    { isLoading: isQueryResponseLoading, data: queryResponseData },
  ] = useParseQueryMutation();
  const query = useSelector((state: RootState) => state.customQuery.query);
  const preserveParams = useSelector(
    (state: RootState) => state.customQuery.preserveParams
  ) as ProductListParams;
  const [disabledContinueButton, setDisabledContinueButton] = useState(true);
  const [autoRefetchQuery, setAutoRefetchQuery] = useState(true);
  const [message, setMessage] = useState<string>(null);
  const [tree, setTree] = useState<string>(null);
  const mounted = useRef(true);
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer } = useGetCustomerQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (query && !queryResponseData && autoRefetchQuery) {
        parseQuery(query);
        setAutoRefetchQuery(false);
      }
    }
    return (): void => {
      mounted.current = false;
    };
  }, [query]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      let disabled;
      if (isQueryResponseLoading) {
        disabled = true;
      } else {
        disabled = !queryResponseData?.message;
      }
      setDisabledContinueButton(disabled);
      setMessage(queryResponseData?.message);
      setTree(queryResponseData?.tree);
    }
    return (): void => {
      mounted.current = false;
    };
  }, [isQueryResponseLoading]);

  const parseQuery = (query: string): void => {
    parseCurrentQuery({ query, url_params: preserveParams });
  };
  const handleClickContinue = (query: string): void => {
    const customer_slug = customer.data.slug;
    const actionSpecId = new URLSearchParams(window.location.search).get(
      "action_spec_id"
    );
    if (actionSpecId) {
      updateActionSpec({
        token,
        customer_slug,
        query,
        actionSpecId,
      }).then(() => {
        // @ts-ignore
        window.parent.CURRENT_SUBPAGE_GO_BACK();
      });
    } else {
      window.location.href = queryResponseData.redirect_url;
    }
  };

  return (
    <div
      className={"input-container"}
      data-testid={"custom-query-input-interface"}
    >
      <textarea
        data-testid={"custom-query-textarea"}
        className={"monospace"}
        value={query}
        cols={10}
        rows={10}
        onChange={(e): void => {
          setAutoRefetchQuery(false);
          dispatch(setQuery(e.currentTarget.value));
          if (!disabledContinueButton) {
            setDisabledContinueButton(true);
          }
          setTree(null);
          setMessage(null);
        }}
      />
      {tree && (
        <p className={"tree monospace"} data-testid={"tree"}>
          {tree}
        </p>
      )}
      {message && (
        <Message size={"tiny"}>
          <p data-testid={"message"}>{message}</p>
        </Message>
      )}
      <div className={"buttons"}>
        <Button
          className={"basic"}
          color={"red"}
          content={"Run"}
          data-testid={"button-run"}
          disabled={isQueryResponseLoading}
          onClick={(): void => {
            parseQuery(query);
          }}
        />
        <Button
          color={"red"}
          content={"Continue"}
          data-testid={"button-continue"}
          disabled={disabledContinueButton || isQueryResponseLoading}
          onClick={(): void => handleClickContinue(query)}
        />
      </div>
      <Divider section />
    </div>
  );
};
