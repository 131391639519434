import React, {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
} from "react";
import { Form, Ref, TextArea } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { preventOverflowOnTextarea } from "../utils";
type Props = {
  testId?: string;
  caption?: string;
  label: string;
  placeholder: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  clearable?: boolean;
};
export const PromptTextareaField: React.FC<Props> = ({
  testId,
  caption,
  label,
  placeholder,
  value,
  setValue,
  clearable,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>();

  useLayoutEffect(() => {
    if (textareaRef.current) {
      preventOverflowOnTextarea(textareaRef.current);
    }
  });

  return (
    <Form.Field>
      <label>
        {label} {!clearable && "*"}
      </label>
      {caption && (
        <Text color="grey" className="descriptive-helper-text">
          {caption}
        </Text>
      )}
      <Ref innerRef={textareaRef}>
        <TextArea
          data-testid={testId}
          placeholder={placeholder}
          value={value}
          onChange={(_, { value }): void => setValue(value as string)}
        />
      </Ref>
    </Form.Field>
  );
};
