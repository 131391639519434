import React, { useEffect, useRef, useState } from "react";
import { ProductText } from "../../producttext/ProductText";
import { OnActionCallback } from "../../producttext/ProductTextViewer";
import { EditTemplateInlineSingleVariable } from "./EditTemplateInlineSingleVariable";
import { EditTemplateInlineMultiVariable } from "./EditTemplateInlineMultiVariable";
import { Text } from "../../components/Text";
import { Button, Modal } from "../../components/tailwind";
import { updateLangStringTemplate } from "../../api/action";
import { useSelector } from "react-redux";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { RootState } from "../../utils/store";
import { openInOverlay } from "../../utils/GlobalFunctions";
import { useGetCustomerQuery } from "../../api/customerApi";

type Props = {
  exactTemplateVariable: string | null;
  langstingData: { [key: string]: string };
  onAction?: OnActionCallback;
  onClose?: () => void;
  productText: ProductText;
  templateId: string;
  tagId: string;
  templateVocabContent: string;
  text: string;
};

export const EditTemplateInline: React.FC<Props> = ({
  exactTemplateVariable,
  langstingData,
  onAction,
  onClose,
  productText,
  templateId,
  tagId,
  templateVocabContent,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [currentLangstringData, setCurrentLangstringData] = useState<{
    [key: string]: string;
  }>(langstingData);
  const dialogRef = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (!dialogRef.current) return;
    dialogRef.current.showModal();
  }, [dialogRef]);

  const renderBody = (): React.ReactNode => {
    if (!tagId)
      return (
        <Text color="red" size="small" testId="edit-template-no-tag-id">
          Please regenerate the product text before trying to edit this template
        </Text>
      );

    if (exactTemplateVariable) {
      return (
        <EditTemplateInlineSingleVariable
          currentLangstringData={currentLangstringData}
          onChange={(data): void => {
            setCurrentLangstringData(data);
          }}
          exactTemplateVariable={exactTemplateVariable}
        />
      );
    }

    return (
      <EditTemplateInlineMultiVariable
        currentLangstringData={currentLangstringData}
        onChange={(data): void => {
          setCurrentLangstringData(data);
        }}
        templateVocabContent={templateVocabContent}
      />
    );
  };

  return (
    <Modal dialogRef={dialogRef}>
      <Modal.Header>
        <h5 className="tw-m-0 tw-flex tw-items-center tw-gap-2">
          Edit Template Content{" "}
          <span className="tw-text-gray-500">({templateId})</span>{" "}
          {!isLoading && (
            <i
              className="material-icons tw-cursor-pointer tw-rounded tw-text-lg tw-leading-[1] hover:tw-outline"
              data-testid="edit-template-inline-open-template-details"
              data-balloon="View template"
              data-balloon-pos="down"
              onClick={(): void => {
                openInOverlay(`/d/${customer.slug}/template/${templateId}`);
                dialogRef.current?.close();
                onClose?.();
              }}
            >
              open_in_new
            </i>
          )}
        </h5>
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Actions>
        <Button
          data-testid="cancel"
          onClick={(): void => {
            dialogRef.current?.close();
            onClose?.();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          data-testid="save"
          onClick={(): void => {
            dialogRef.current?.close();
            updateLangStringTemplate({
              token,
              templateId,
              tagId,
              currentLangstringData,
              languageCode: productText.languageCode,
              productId: productText.productId,
            }).then((result) => {
              if (result.updated) {
                onAction?.(ProductTextAction.REGENERATE);
              }
              dialogRef.current?.close();
              onClose?.();
            });
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
