import {
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import React from "react";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import { TemplateFormField } from "./TemplateFormField";

type Props = {
  details: ProductDetailInfo;
  form: TemplateFormInfo;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  textualAppName: string;
};

export const TemplateForm: React.FC<Props> = ({
  details,
  form,
  onSectionItemAction,
  textualAppName,
}) => (
  <>
    {form.instructions.map((instruction, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <TemplateFormField
          instruction={instruction}
          form={form}
          details={details}
          onSectionItemAction={onSectionItemAction}
          textualAppName={textualAppName}
        />
      </React.Fragment>
    ))}
  </>
);
