import React from "react";
import styled from "styled-components";
import { FieldRecommendation } from "./ProductRecommendations";
import { formatTag } from "../../utils/tagutils";

interface Props {
  onClick: (recommendation: FieldRecommendation) => void;
  recommendations: FieldRecommendation[];
  subtree?: boolean;
  horizontal?: boolean;
}

const StyledA = styled.a`
  & {
    :hover {
      cursor: pointer;
    }
  }
`;

/**
 * Shows recommendations for a tagfield.
 * @param props
 * @constructor
 */
export const FieldRecommendations: React.FC<Props> = ({
  onClick,
  subtree = false,
  recommendations,
  horizontal = false,
}) => {
  if (recommendations.length == 0) {
    return null;
  }

  return (
    <div
      className={horizontal ? "recommend-box-horizontal" : "recommend-box"}
      data-testid={"recommend"}
    >
      {recommendations.map((rec) => (
        <StyledA
          className="recommend"
          data-testid={`recommend-${rec.vocabulary_id}`}
          key={rec.vocabulary_id}
          onClick={(): void => onClick(rec)}
        >
          {formatTag(rec, {
            includeSubTagNames: true,
            showTagTypeHints: true,
          })}
          {subtree &&
            rec?.tags?.length &&
            ` + ${formatTag(rec.tags[0], {
              includeSubTagNames: true,
              showVocabType: true,
            })}`}
        </StyledA>
      ))}
    </div>
  );
};
