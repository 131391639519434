import React, { useEffect, useRef, useState } from "react";
import { CarouselProvider, Image, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

import { ProductImage } from "./ProductImage";
import { getProductImages } from "../api/action";
import CarouselImageSelector from "../components/CarouselImageSelector";
import { ProductId } from "./product";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";

type Props = {
  productId: ProductId;
};

const StyledSegment = styled(Segment)`
  border-radius: 9px !important;
`;

const StyledImage = styled(Image)`
  max-height: 420px;
  max-width: 100%;
  height: auto !important;
  width: auto !important;
  margin: auto;
`;

export const ProductDetailImages: React.FC<Props> = ({ productId }) => {
  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      getProductImages(token, productId).then(
        (productImages: ProductImage[]) => {
          setProductImages(productImages);
        }
      );
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const renderSlides = (): JSX.Element[][] => {
    return [
      productImages.map((image, index) => {
        return (
          <Slide tag="a" index={index} key={image.imageUrl}>
            <StyledImage src={image.imageUrl} hasMasterSpinner={true} />
          </Slide>
        );
      }),
    ];
  };

  return (
    <StyledSegment attached="bottom">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={3}
        isIntrinsicHeight={true}
      >
        <Slider>{renderSlides()}</Slider>
        <CarouselImageSelector images={productImages} />
      </CarouselProvider>
    </StyledSegment>
  );
};
