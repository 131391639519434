import React, { useCallback, useState } from "react";
import {
  Label,
  Input,
  Dropdown,
  Form,
  Button,
  Popup,
  DropdownProps,
} from "semantic-ui-react";

import {
  ConditionOperator,
  PromptSelectionCondition,
  PromptSelectionStringMatchType,
  popupDelay,
} from "./types";
import { promptSelectionRulesTestSelectors } from "./testUtils/testSelectors";
import { useSelector } from "react-redux";
import {
  addNewKnownKeyPromptSelectionRules,
  searchKnownKeysPromptSelectionRules,
} from "../../api/gptApi";
import { RootState } from "../../utils/store";
import { debounce } from "../../utils/debounce";

type Props = {
  conditionOperator: ConditionOperator;
  index: number;
  id: string;
  objectKey: string;
  value: string;
  stringMatchType: PromptSelectionStringMatchType;
  onUpdateCondition: (
    key: keyof PromptSelectionCondition,
    value: string | PromptSelectionStringMatchType,
    id: string
  ) => void;
  onRemoveCondition: (id: string) => void;
};
export const PromptSelectionRulesCondition: React.FC<Props> = ({
  conditionOperator,
  index,
  id,
  objectKey,
  value,
  stringMatchType,
  onUpdateCondition,
  onRemoveCondition,
}) => {
  const {
    container: containerTestId,
    conditionOperatorLabel: conditionOperatorLabelTestId,
    inputs: {
      key: keyTestId,
      matchingSchemeSelector: matchingSchemeSelectorTestId,
      value: valueTestId,
    },
    removeButton: removeButtonTestId,
  } = promptSelectionRulesTestSelectors.promptSelectionRulesCondition(index);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state: RootState) => state.auth.token);
  const stringMatchOptions = Object.values(
    PromptSelectionStringMatchType
  ).map((type) => ({ key: type, value: type, text: type }));

  const searchKnownKeys = useCallback(
    debounce(async (search: string): Promise<void> => {
      setLoading(true);
      if (search) {
        const knownKeys = await searchKnownKeysPromptSelectionRules(
          token,
          search
        ).finally(() => setLoading(false));
        setDropdownOptions(
          knownKeys.map((key) => ({
            text: key,
            key: key,
            value: key,
          }))
        );
      } else {
        setLoading(false);
        setDropdownOptions([]);
      }
    }, 500),
    []
  );

  const addNewKnownKey = async (key: string): Promise<void> => {
    await addNewKnownKeyPromptSelectionRules(token, key);
  };

  return (
    <Form.Group inline data-testid={containerTestId}>
      <Form.Field width={6}>
        <Label
          data-testid={conditionOperatorLabelTestId}
          content={index < 1 ? "If" : conditionOperator}
          color={index < 1 ? "blue" : "grey"}
          className="no-line-height"
        />
        <Dropdown
          style={{ flexGrow: 1 }}
          data-testid={keyTestId}
          allowAdditions
          additionLabel="Add key "
          options={dropdownOptions}
          value={objectKey}
          onChange={(
            event: React.SyntheticEvent<HTMLElement>,
            data: DropdownProps
          ): void => {
            onUpdateCondition("key", data.value as string, id);
          }}
          onSearchChange={(
            event: React.SyntheticEvent<HTMLElement>,
            data: DropdownProps
          ): void => {
            searchKnownKeys(data.searchQuery);
          }}
          onAddItem={(
            event: React.SyntheticEvent<HTMLElement>,
            data: DropdownProps
          ): void => {
            addNewKnownKey(data.value as string);
          }}
          text={objectKey}
          placeholder="Key"
          search
          selection
          loading={loading}
          noResultsMessage="Search known keys"
        />
      </Form.Field>
      <Form.Field>
        <Dropdown
          data-testid={matchingSchemeSelectorTestId}
          placeholder="Select matching scheme"
          selection
          options={stringMatchOptions}
          value={stringMatchType}
          onChange={(e, { value }): void =>
            onUpdateCondition(
              "string_match_type",
              value as PromptSelectionStringMatchType,
              id
            )
          }
        />
      </Form.Field>
      <Form.Field>
        {stringMatchType !== PromptSelectionStringMatchType.EXISTS && (
          <Input
            data-testid={valueTestId}
            placeholder="Value"
            value={value}
            onChange={(e, { value }): void => {
              onUpdateCondition("value", value, id);
            }}
          />
        )}
      </Form.Field>
      <Popup
        position="top right"
        content="Remove condition"
        size="small"
        mouseEnterDelay={popupDelay}
        trigger={
          <Button
            data-testid={removeButtonTestId}
            color="red"
            basic
            size="tiny"
            icon="close"
            onClick={(): void => onRemoveCondition(id)}
          />
        }
      />
    </Form.Group>
  );
};
