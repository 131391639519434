import React, { useEffect, useState } from "react";
import { PrefillFromCategoryModal } from "./PrefillFromCategoryModal";
import { PrefillCategory } from "./PrefillFromCategoryModalToolbar";
import { CategoryItem } from "./CategoryItem";

type Props = {
  onDataChanged?: (
    field: typeof PrefillFromCategoryModalWizardUpload,
    data: PrefillCategory | {}
  ) => void;
};

export const PrefillFromCategoryModalWizardUpload: React.FC<Props> = ({
  onDataChanged,
}) => {
  const [category, setCategory] = useState<PrefillCategory>(null);

  useEffect((): void => {
    onDataChanged?.(this, category);
  }, [category]);

  if (category?.id) {
    return (
      <CategoryItem
        category={category}
        onClick={(): void => setCategory(null)}
      />
    );
  }
  return (
    <PrefillFromCategoryModal
      name={"Select category"}
      onSelectItem={setCategory}
      spaciousLabel={true}
    />
  );
};
