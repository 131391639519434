import "./utils/bootstrap";

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "semantic-ui-react";
import { LexiconEditorComp } from "./vocabulary/LexiconEditor";
import { VocabularyLookupComp } from "./vocabulary/VocabularyLookup";
import { VocabularyLookupNewComp } from "./vocabulary/VocabularyLookupNew.tsx";
import { TagTreeEditorComp } from "./vocabulary/TagTreeEditor";
import { TagTreeButtonComp } from "./vocabulary/TagTreeButton";
import { AnnotationComp } from "./vocabulary/annotation";
import FluentInput from "./vocabulary/fluent_input";
import VariantsField from "./vocabulary/variants_field";
import { TemplateVariantsFieldComp } from "./vocabulary/TemplateVariantsField";
import BrandSelector from "./products/brand_selector";
import TextPatternSelector from "./products/text_pattern_selector";
import MultipleSelectorField from "./products/MultipleSelectorField";
import { TemplateMultipleLabelSelectorFieldComp } from "./products/TemplateMultipleLabelSelectorField";
import { PredefinedTitle } from "../ts/predefined_title";
import { Fluent } from "./vocabulary/Fluent";
import SingleSelectorField from "./products/SingleSelectorField";
import { TemplateSelectorContainer } from "./vocabulary/TemplateSelectorContainer";
import { formatTag } from "./utils/tagutils";
import { PrefillFromCategoryModalWizardUpload } from "./products/sections-tab/templates/PrefillFromCategoryModalWizardUpload";
import { renderReduxElementProvidedProps } from "./utils/renderutils";
import { AdvancedTemplateContainer } from "./vocabulary/AdvancedTemplateContainer";
import { LangString, ShowAllOrPreview } from "./vocabulary/LangString";
import loadEventHandlers from "./event-handlers";
export const NOOP = () => {};

window.components = {
  Fluent: (container, props) => {
    ReactDOM.render(<Fluent {...props} />, container);
  },
};

function VocabularyLookupNewStateWrapper({ dataset, initialData, options }) {
  let selectedValue = "";
  let selectedTags = [];
  const isMultiple = JSON.parse(dataset.isMultiple);
  function setInnerContent(data) {
    return (
      <div
        className={data.vocabulary_request_status === "Pending" && "pending"}
      >
        {formatTag(data, {
          includeSubTagNames: true,
        })}{" "}
        / {data.swedish} ({data.vocabulary_id})
        <div>
          {data.category} / {data.type}
        </div>
      </div>
    );
  }
  const detailedVocabSuggestion = JSON.parse(dataset.detailedVocabSuggestion);
  if (!isMultiple && initialData.value) {
    selectedValue = formatTag(initialData, {
      includeSubTagNames: true,
    });
  } else {
    if (isMultiple && initialData && initialData.length > 0) {
      selectedTags = initialData;
    }
  }
  if (detailedVocabSuggestion && Object.keys(initialData).length) {
    selectedValue = setInnerContent(initialData);
  }

  const [value, setValue] = useState(selectedValue);
  const [tags, setTags] = useState(selectedTags);
  function onDataChanged(component, value) {
    if (isMultiple) {
      setTags(value);
    } else {
      const displayName = formatTag(value, {
        includeSubTagNames: true,
      });
      if (detailedVocabSuggestion) {
        setValue(displayName ? setInnerContent(value) : null);
      } else setValue(displayName);
    }
    if (options.onDataChanged) {
      options.onDataChanged(component, value);
    }
  }
  return renderReduxElementProvidedProps(VocabularyLookupNewComp, {
    selectedTags: tags,
    selectedValue: value,
    token: dataset.token,
    textualAppName: dataset.textualAppName,
    componentId: dataset.componentId,
    isMultiple: isMultiple,
    limit: dataset.limit,
    language: dataset.language,
    includeSeo: JSON.parse(dataset.includeSeo),
    vocabularyRequestAllowed: JSON.parse(dataset.vocabularyRequestAllowed),
    vocabularyRequestProductId: dataset.vocabularyRequestProductId,
    vocabularyRequestProductDescription:
      dataset.vocabularyRequestProductDescription,
    detailedVocabSuggestion: detailedVocabSuggestion,
    tagCategory: dataset.tagCategory,
    tagType: dataset.tagType,
    headline: dataset.headline,
    showVocabType: false,
    placeholder: dataset.placeholder || "Start typing to search",
    showTagTypeHints: JSON.parse(dataset.showTagTypeHints),
    useLexiconEditor: JSON.parse(dataset.useLexiconEditor),
    lexiconEditorDirection: dataset.lexiconEditorDirection,
    showHypernym: JSON.parse(dataset.showHypernym),
    skipCustomerFilter: JSON.parse(dataset.skipCustomerFilter),
    alwaysTranslate: JSON.parse(dataset.alwaysTranslate),
    showMachineTranslate: dataset.showMachineTranslate
      ? JSON.parse(dataset.showMachineTranslate)
      : false,
    disableAutoAdd: JSON.parse(dataset.disableAutoAdd),
    onLoaded: options.onLoaded || NOOP,
    onDataChanged: onDataChanged,
  });
}

window.glue = {
  variantsField: {
    forTextarea: (textarea, options) => {
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);

        const event1 = new Event("keydown", { bubbles: true });
        textarea.dispatchEvent(event1);

        textarea.textContent = JSON.stringify(data);

        const event2 = new Event("keyup", { bubbles: true });
        textarea.dispatchEvent(event2);
      };

      const div = document.createElement("div");
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = (
        <VariantsField
          initialData={JSON.parse(textarea.textContent)}
          onDataChanged={onDataChanged}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  templateVariantsField: {
    forTextarea: (textarea, options) => {
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);

        const event1 = new Event("keydown", { bubbles: true });
        textarea.dispatchEvent(event1);

        textarea.textContent = JSON.stringify(data);

        const event2 = new Event("keyup", { bubbles: true });
        textarea.dispatchEvent(event2);
      };

      const div = document.createElement("div");
      const props = options.props || {};

      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = renderReduxElementProvidedProps(TemplateVariantsFieldComp, {
        ...props,
        initialData: JSON.parse(textarea.textContent),
        onDataChanged: onDataChanged,
      });

      ReactDOM.render(jsx, div);
    },
  },
  prefillFromCategory: {
    forTextarea: (textarea, options) => {
      const onDataChanged = (component, data) => {
        textarea.textContent = JSON.stringify(data);
      };
      const div = document.createElement("div");
      const props = options.props || {};

      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = renderReduxElementProvidedProps(
        PrefillFromCategoryModalWizardUpload,
        {
          ...props,
          onDataChanged: onDataChanged,
          token: textarea.dataset.token,
        }
      );
      ReactDOM.render(jsx, div);
    },
  },
  templateSelector: {
    forInput: (input, options) => {
      const onSubmit = (data) => {
        (options.onDataChanged || NOOP)(data);
        input.value = JSON.stringify(data.id);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const name = JSON.parse(input.dataset.name);
      const token = input.dataset.token;

      ReactDOM.render(
        <TemplateSelectorContainer
          token={token}
          name={name}
          onSubmit={onSubmit}
        />,
        div
      );
    },
  },
  dropdown: {
    forInput: (input, options) => {
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data.value);
        const event = new Event("change", {
          bubbles: true,
        });
        input.dispatchEvent(event);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const choices = JSON.parse(input.dataset.options);
      const placeholder = input.dataset.placeholder;
      const initialValue = JSON.parse(input.value);
      const isMultiple = "multiple" in input.dataset;
      const isDisabled = input.disabled;

      const jsx = (
        <Dropdown
          options={choices}
          multiple={isMultiple}
          fluid
          selection
          disabled={isDisabled}
          onChange={onDataChanged}
          defaultValue={initialValue}
          placeholder={placeholder}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  brandSelector: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const jsx = (
        <BrandSelector
          token={input.dataset.token}
          textualAppName={input.dataset.textualAppName}
          componentId={input.dataset.componentId}
          availableChoices={JSON.parse(input.dataset.availableChoices)}
          initialData={JSON.parse(input.value)}
          onLoaded={options.onLoaded || NOOP}
          onDataChanged={onDataChanged}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  textPatternSelector: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const jsx = (
        <TextPatternSelector
          componentId={input.dataset.componentId}
          availableChoices={JSON.parse(input.dataset.availableChoices)}
          initialData={JSON.parse(input.value)}
          onLoaded={options.onLoaded || NOOP}
          onDataChanged={onDataChanged}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  multipleSelector: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data);
        const event = new Event("change", {
          bubbles: true,
        });
        input.dispatchEvent(event);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const props = JSON.parse(input.dataset.props);

      const jsx = (
        <MultipleSelectorField
          componentId={input.dataset.componentId}
          initialData={JSON.parse(input.value) || []}
          onLoaded={options.onLoaded || NOOP}
          onDataChanged={onDataChanged}
          {...props}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  templateMultipleLabelSelector: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data);
        const event = new Event("change", {
          bubbles: true,
        });
        input.dispatchEvent(event);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const props = JSON.parse(input.dataset.props);

      const jsx = renderReduxElementProvidedProps(
        TemplateMultipleLabelSelectorFieldComp,
        {
          componentId: input.dataset.componentId,
          initialData: JSON.parse(input.value) || [],
          onLoaded: options.onLoaded || NOOP,
          onDataChanged: onDataChanged,
          ...props,
        }
      );

      ReactDOM.render(jsx, div);
    },
  },
  singleSelector: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = data;
        const event = new Event("change", {
          bubbles: true,
        });
        input.dispatchEvent(event);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const props = JSON.parse(input.dataset.props);

      const jsx = (
        <SingleSelectorField
          componentId={input.dataset.componentId}
          initialData={input.value || null}
          onLoaded={options.onLoaded || NOOP}
          onDataChanged={onDataChanged}
          {...props}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  vocabularyLookup: {
    forDiv: (div, options) => {
      const jsx = renderReduxElementProvidedProps(VocabularyLookupComp, {
        token: div.dataset.token,
        textualAppName: div.dataset.textualAppName,
        componentId: div.dataset.componentId,
        initialData: JSON.parse(div.dataset.initialData),
        isMultiple: JSON.parse(div.dataset.isMultiple),
        limit: div.dataset.limit,
        language: div.dataset.language,
        includeSeo: JSON.parse(div.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          div.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: div.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          div.dataset.vocabularyRequestProductDescription,
        tagCategory: div.dataset.tagCategory,
        tagType: div.dataset.tagType,
        headline: div.dataset.headline,
        placeholder: div.dataset.placeholder || "Start typing to search",
        useLexiconEditor: JSON.parse(div.dataset.useLexiconEditor),
        lexiconEditorDirection: div.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(div.dataset.showHypernym),
        alwaysTranslate: div.dataset.alwaysTranslate
          ? JSON.parse(div.dataset.alwaysTranslate)
          : false,
        showMachineTranslate: div.dataset.showMachineTranslate
          ? JSON.parse(div.dataset.showMachineTranslate)
          : false,
        disableAutoAdd: div.dataset.disableAutoAdd
          ? JSON.parse(div.dataset.disableAutoAdd)
          : false,
        onLoaded: options.onLoaded || NOOP,
        onDataChanged: options.onDataChanged || NOOP,
      });

      ReactDOM.render(jsx, div);
    },
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data);
      };

      const div = document.createElement("div");
      div.className = "vocabulary-lookup-wrapper";
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const jsx = renderReduxElementProvidedProps(VocabularyLookupComp, {
        token: input.dataset.token,
        textualAppName: input.dataset.textualAppName,
        componentId: input.dataset.componentId,
        initialData: JSON.parse(input.value),
        isMultiple: JSON.parse(input.dataset.isMultiple),
        limit: input.dataset.limit,
        language: input.dataset.language,
        includeSeo: JSON.parse(input.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          input.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: input.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          input.dataset.vocabularyRequestProductDescription,
        tagCategory: input.dataset.tagCategory,
        tagType: input.dataset.tagType,
        headline: input.dataset.headline,
        placeholder: input.dataset.placeholder || "Start typing to search",
        useLexiconEditor: JSON.parse(input.dataset.useLexiconEditor),
        lexiconEditorDirection: input.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(input.dataset.showHypernym),
        alwaysTranslate: input.dataset.alwaysTranslate
          ? JSON.parse(input.dataset.alwaysTranslate)
          : false,
        showMachineTranslate: input.dataset.showMachineTranslate
          ? JSON.parse(input.dataset.showMachineTranslate)
          : false,
        disableAutoAdd: input.dataset.disableAutoAdd
          ? JSON.parse(input.dataset.disableAutoAdd)
          : false,
        onLoaded: options.onLoaded || NOOP,
        onDataChanged: onDataChanged || NOOP,
      });

      ReactDOM.render(jsx, div);
    },
  },
  vocabularyLookupNew: {
    forInput: (input, options) => {
      // previously set onDataChanged function, delegate as we need some logic after
      // it runs.

      const prevOnDataChanged = options.onDataChanged || NOOP;
      options.onDataChanged = (component, data) => {
        input.value = JSON.stringify(data);
        !JSON.parse(input.dataset.disableAutoApplyChanges) &&
          prevOnDataChanged(component, data);
      };
      const div = document.createElement("div");
      div.className = "vocabulary-lookup-wrapper";
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";
      const dataset = {};
      Object.assign(dataset, input.dataset);
      ReactDOM.render(
        <VocabularyLookupNewStateWrapper
          dataset={dataset}
          initialData={JSON.parse(input.value)}
          options={options}
        />,
        div
      );
    },
  },
  tagTreeEditor: {
    forDiv: (div, options) => {
      const jsx = renderReduxElementProvidedProps(TagTreeEditorComp, {
        token: div.dataset.token,
        textualAppName: div.dataset.textualAppName,
        componentId: div.dataset.componentId,
        initialData: JSON.parse(div.dataset.initialData),
        limit: div.dataset.limit,
        language: div.dataset.language,
        includeSeo: JSON.parse(div.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          div.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: div.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          div.dataset.vocabularyRequestProductDescription,
        mappingMode: JSON.parse(div.dataset.mappingMode),
        useLexiconEditor: JSON.parse(div.dataset.useLexiconEditor),
        lexiconEditorDirection: div.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(div.dataset.showHypernym),
        alwaysTranslate: JSON.parse(div.dataset.alwaysTranslate),
        showMachineTranslate: div.dataset.showMachineTranslate
          ? JSON.parse(div.dataset.showMachineTranslate)
          : false,
        onDataChanged: options.onDataChanged || NOOP,
      });

      ReactDOM.render(jsx, div);
    },
    forTextarea: (textarea, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data, update) => {
        textarea.textContent = JSON.stringify(data);
        if (!JSON.parse(textarea.dataset.disableAutoApplyChanges) && update) {
          (options.onDataChanged || NOOP)(component, data);
        }
      };

      const div = document.createElement("div");
      div.className = "tag-tree-editor-wrapper";
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = renderReduxElementProvidedProps(TagTreeEditorComp, {
        token: textarea.dataset.token,
        textualAppName: textarea.dataset.textualAppName,
        componentId: textarea.dataset.componentId,
        initialData: JSON.parse(textarea.textContent),
        limit: textarea.dataset.limit,
        language: textarea.dataset.language,
        includeSeo: JSON.parse(textarea.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          textarea.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: textarea.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          textarea.dataset.vocabularyRequestProductDescription,
        mappingMode: JSON.parse(textarea.dataset.mappingMode),
        useLexiconEditor: JSON.parse(textarea.dataset.useLexiconEditor),
        lexiconEditorDirection: textarea.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(textarea.dataset.showHypernym),
        alwaysTranslate: JSON.parse(textarea.dataset.alwaysTranslate),
        showMachineTranslate: textarea.dataset.showMachineTranslate
          ? JSON.parse(textarea.dataset.showMachineTranslate)
          : false,
        updateAfterChange: JSON.parse(textarea.dataset.updateAfterChange),
        onDataChanged: onDataChanged || NOOP,
      });

      ReactDOM.render(jsx, div);
    },
  },
  langString: {
    forTextarea: (textarea) => {
      const onDataChanged = (component, data) => {
        // These fake events is used to trigger a event listener (setup in JQuery) that submits the closest form to this component.
        // The form submit is essential for the tag and the product to update itself.
        const event1 = new Event("keydown", { bubbles: true });
        textarea.dispatchEvent(event1);

        textarea.textContent = JSON.stringify(data);

        const event2 = new Event("keyup", { bubbles: true });
        textarea.dispatchEvent(event2);
      };

      const div = document.createElement("div");
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      let initData = undefined;
      if (textarea.textContent) {
        initData = JSON.parse(textarea.textContent);
      }

      const jsx = renderReduxElementProvidedProps(LangString, {
        token: textarea.dataset.token,
        onDataChanged: onDataChanged || NOOP,
        initialData: initData,
        expanded: ShowAllOrPreview.PREVIEW,
      });
      ReactDOM.render(jsx, div);
    },
  },
  tagTreeButton: {
    forDiv: (div, options) => {
      const jsx = renderReduxElementProvidedProps(TagTreeButtonComp, {
        token: div.dataset.token,
        textualAppName: div.dataset.textualAppName,
        componentId: div.dataset.componentId,
        initialData: JSON.parse(div.dataset.initialData),
        limit: div.dataset.limit,
        language: div.dataset.language,
        includeSeo: JSON.parse(div.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          div.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: div.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          div.dataset.vocabularyRequestProductDescription,
        mappingMode: JSON.parse(div.dataset.mappingMode),
        useLexiconEditor: JSON.parse(div.dataset.useLexiconEditor),
        lexiconEditorDirection: div.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(div.dataset.showHypernym),
        onDataChanged: options.onDataChanged || NOOP,
        alwaysTranslate: JSON.parse(div.dataset.alwaysTranslate),
        showMachineTranslate: div.dataset.showMachineTranslate
          ? JSON.parse(div.dataset.showMachineTranslate)
          : false,
      });

      ReactDOM.render(jsx, div);
    },
    forTextarea: (textarea, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        textarea.textContent = JSON.stringify(data.treeData);
        !JSON.parse(textarea.dataset.disableAutoApplyChanges) &&
          (options.onDataChanged || NOOP)(component, data);
      };

      const div = document.createElement("div");
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";
      div.className = "tag-tree-button-wrapper";

      const jsx = renderReduxElementProvidedProps(TagTreeButtonComp, {
        token: textarea.dataset.token,
        textualAppName: textarea.dataset.textualAppName,
        componentId: textarea.dataset.componentId,
        initialData: JSON.parse(textarea.textContent),
        limit: textarea.dataset.limit,
        language: textarea.dataset.language,
        includeSeo: JSON.parse(textarea.dataset.includeSeo),
        vocabularyRequestAllowed: JSON.parse(
          textarea.dataset.vocabularyRequestAllowed
        ),
        vocabularyRequestProductId: textarea.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          textarea.dataset.vocabularyRequestProductDescription,
        mappingMode: JSON.parse(textarea.dataset.mappingMode),
        useLexiconEditor: JSON.parse(textarea.dataset.useLexiconEditor),
        lexiconEditorDirection: textarea.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(textarea.dataset.showHypernym),
        onDataChanged: onDataChanged || NOOP,
        alwaysTranslate: JSON.parse(textarea.dataset.alwaysTranslate),
        showMachineTranslate: textarea.dataset.showMachineTranslate
          ? JSON.parse(textarea.dataset.showMachineTranslate)
          : false,
      });
      ReactDOM.render(jsx, div);
    },
  },
  lexiconEditor: {
    forDiv: (div, options) => {
      const jsx = renderReduxElementProvidedProps(LexiconEditorComp, {
        componentId: div.dataset.componentId,
        direction: div.dataset.direction,
        initialData: JSON.parse(div.dataset.initialData),
        jsonSchema: JSON.parse(div.dataset.jsonSchema),
        languageCode: div.dataset.languageCode,
        onDataChanged: options.onDataChanged || NOOP,
        onLoaded: options.onLoaded || NOOP,
        token: div.dataset.token,
        vocabularyId: div.dataset.vocabularyId,
      });

      ReactDOM.render(jsx, div);
    },
    forTextarea: (textarea, options) => {
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        textarea.textContent = JSON.stringify(data);
      };

      const div = document.createElement("div");
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = renderReduxElementProvidedProps(LexiconEditorComp, {
        componentId: textarea.dataset.componentId,
        direction: textarea.dataset.direction,
        initialData: JSON.parse(textarea.textContent),
        jsonSchema: JSON.parse(textarea.dataset.jsonSchema),
        uiSchema: JSON.parse(textarea.dataset.uiSchema),
        typeOfTranslation: textarea.dataset.typeOfTranslation,
        languageCode: textarea.dataset.languageCode,
        onDataChanged: onDataChanged,
        onLoaded: options.onLoaded || NOOP,
        token: textarea.dataset.token,
        vocabularyId: textarea.dataset.vocabularyId,
      });

      ReactDOM.render(jsx, div);
    },
  },
  annotation: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        input.value = JSON.stringify(data);
        const event = new Event("change", {
          bubbles: true,
        });
        input.dispatchEvent(event);
        (options.onDataChanged || NOOP)(component, data);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const jsx = renderReduxElementProvidedProps(AnnotationComp, {
        alwaysTranslate: JSON.parse(input.dataset.alwaysTranslate),
        token: input.dataset.token,
        textualAppName: input.dataset.textualAppName,
        language: input.dataset.language,
        vocabularyRequestProductId: input.dataset.vocabularyRequestProductId,
        vocabularyRequestProductDescription:
          input.dataset.vocabularyRequestProductDescription,
        text: input.dataset.text,
        wordBoundaries: JSON.parse(input.dataset.wordBoundaries),
        entityTypes: JSON.parse(input.dataset.entityTypes),
        entityTypesRequestConfig: JSON.parse(
          input.dataset.entityTypesRequestConfig
        ),
        spans: JSON.parse(input.value),
        useLexiconEditor: JSON.parse(input.dataset.useLexiconEditor),
        lexiconEditorDirection: input.dataset.lexiconEditorDirection,
        showHypernym: JSON.parse(input.dataset.showHypernym),
        showMachineTranslate: input.dataset.showMachineTranslate
          ? JSON.parse(input.dataset.showMachineTranslate)
          : false,
        onLoaded: options.onLoaded || NOOP,
        onDataChanged: onDataChanged,
      });

      ReactDOM.render(jsx, div);
    },
  },
  fluentInput: {
    forInput: (input, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);
        input.value = JSON.stringify(data.words || "");
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const jsx = (
        <FluentInput
          token={input.dataset.token}
          textualAppName={input.dataset.textualAppName}
          initialValue={JSON.parse(input.value)}
          onLoaded={options.onLoaded || NOOP}
          onDataChanged={onDataChanged}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
  productTagsEditor: {
    forDiv: (div, options) => {
      const jsx = renderReduxElementProvidedProps(AdvancedTemplateContainer, {
        allowMaterialKindTagsOnly: JSON.parse(
          div.dataset.allowMaterialKindTagsOnly
        ),
        componentId: div.dataset.componentId,
        initialData: JSON.parse(div.dataset.initialData),
        limit: div.dataset.limit,
        mappingMode: JSON.parse(div.dataset.mappingMode),
        onDataChanged: options.onDataChanged || NOOP,
        onLoaded: options.onLoaded || NOOP,
        textualAppName: div.dataset.textualAppName,
        token: div.dataset.token,
        vocabularyRequestProductDescription:
          div.dataset.vocabularyRequestProductDescription,
        vocabularyRequestProductId: div.dataset.vocabularyRequestProductId,
      });

      ReactDOM.render(jsx, div);
    },
    forTextarea: (textarea, options) => {
      // eslint-disable-next-line no-unused-vars
      const onDataChanged = (component, data, delayedRefresh) => {
        !JSON.parse(textarea.dataset.disableAutoApplyChanges) &&
          (options.onDataChanged || NOOP)(component, data, delayedRefresh);
        textarea.textContent = JSON.stringify(data);
      };

      const div = document.createElement("div");
      textarea.parentNode.insertBefore(div, textarea);
      textarea.style.display = "none";

      const jsx = renderReduxElementProvidedProps(AdvancedTemplateContainer, {
        allowMaterialKindTagsOnly: JSON.parse(
          textarea.dataset.allowMaterialKindTagsOnly
        ),
        componentId: textarea.dataset.componentId,
        initialData: JSON.parse(textarea.textContent),
        limit: textarea.dataset.limit,
        mappingMode: JSON.parse(textarea.dataset.mappingMode),
        onDataChanged: onDataChanged || NOOP,
        onLoaded: options.onLoaded || NOOP,
        textualAppName: textarea.dataset.textualAppName,
        token: textarea.dataset.token,
        vocabularyRequestProductDescription:
          textarea.dataset.vocabularyRequestProductDescription,
        vocabularyRequestProductId: textarea.dataset.vocabularyRequestProductId,
      });

      ReactDOM.render(jsx, div);
    },
  },
  predefinedTitle: {
    forInput: (input, options) => {
      const onDataChanged = (component, data) => {
        (options.onDataChanged || NOOP)(component, data);

        const event1 = new Event("keydown", { bubbles: true });
        input.dispatchEvent(event1);

        input.value = JSON.stringify(data);

        const event2 = new Event("keyup", { bubbles: true });
        input.dispatchEvent(event2);
      };

      const div = document.createElement("div");
      input.parentNode.insertBefore(div, input);
      input.style.display = "none";

      const props = JSON.parse(input.dataset.props);

      const jsx = (
        <PredefinedTitle
          initialValue={JSON.parse(input.value || "{}")}
          onDataChanged={onDataChanged}
          {...props}
        />
      );

      ReactDOM.render(jsx, div);
    },
  },
};

document.addEventListener("DOMContentLoaded", () => {
  if (document.body) {
    document.body.parentNode.style.display = "inline";
  }

  const observer = new MutationObserver((mutationRecords) => {
    let rebindForElements = new Set();
    for (let i = 0; i < mutationRecords.length; i++) {
      const record = mutationRecords[i];
      if (record.addedNodes) {
        if (record.target.nodeName == "BODY") {
          rebindForElements = new Set();
          rebindForElements.add(record.target);
          break;
        }
        rebindForElements.add(record.target);
      }
    }
    rebindForElements.forEach((element) => {
      loadEventHandlers(element);
    });
  });
  observer.observe(document.body, { childList: true, subtree: true });
  loadEventHandlers();
});
