import React from "react";
import { Checkbox, Form } from "semantic-ui-react";
import { SectionItemType } from "../../api/sectionActions";

interface Props {
  onFilterChanged?: (filter: SectionItemType) => void;
  searchForFixedSentences: boolean;
  searchForTemplates: boolean;
  searchForVocabulary: boolean;
}

export const EnrichTabSearchFilters: React.FC<Props> = ({
  onFilterChanged,
  searchForFixedSentences,
  searchForTemplates,
  searchForVocabulary,
}) => (
  <>
    <h4>Filter</h4>
    <Form>
      <Form.Field>
        <Checkbox
          checked={searchForVocabulary}
          data-testid={"vocabulary-filter"}
          label={"Vocabulary"}
          onChange={(): void => onFilterChanged?.(SectionItemType.VOCABULARY)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          checked={searchForFixedSentences}
          data-testid={"sentence-filter"}
          label={"Fixed sentences"}
          onChange={(): void =>
            onFilterChanged?.(SectionItemType.FIXED_SENTENCE)
          }
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          checked={searchForTemplates}
          data-testid={"template-filter"}
          label={"Templates"}
          onChange={(): void => onFilterChanged?.(SectionItemType.TEMPLATE)}
        />
      </Form.Field>
    </Form>
  </>
);
