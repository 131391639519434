import React, { useCallback, useMemo, useState } from "react";
import { Form } from "semantic-ui-react";
import { PromptFormPaneForm } from "./PromptFormPaneForm";
import { PromptMultiShotsField } from "./PromptFormInputs/PromptMultiShotsInputs";
import { Divider } from "semantic-ui-react";
import { FurtherInstructionsGpt3, PromptConfigOverwrite } from "./types";
import { isGoogle } from "./utils";
import { Text } from "../../components/Text";
import debounce from "lodash/debounce";
import isNull from "lodash/isNull";
import { Icon } from "../../components/tailwind";

export type Props = {
  loading: boolean;
  selectedModelName: string;
  modelsConfig?: { [key: string]: any };
  advancedParameters: {
    further_instructions: string[] | FurtherInstructionsGpt3[];
    config_overwrite: PromptConfigOverwrite;
  };
  collectAdvancedParameters: (
    parameters: Partial<{
      further_instructions: string[] | FurtherInstructionsGpt3[];
      config_overwrite: PromptConfigOverwrite;
    }>
  ) => void;
};

export const PromptFormAdvancedTab: React.FC<Props> = ({
  loading,
  selectedModelName,
  modelsConfig,
  advancedParameters,
  collectAdvancedParameters,
}) => {
  const [rawConfigOverwrite, setRawConfigOverwrite] = useState<string>(
    advancedParameters.config_overwrite?.[selectedModelName]
      ? JSON.stringify(
          advancedParameters.config_overwrite?.[selectedModelName],
          null,
          4
        )
      : null
  );

  const viewConfig = useMemo(() => {
    if (!modelsConfig) return rawConfigOverwrite;
    return isNull(rawConfigOverwrite)
      ? JSON.stringify(modelsConfig, null, 4)
      : rawConfigOverwrite;
  }, [modelsConfig, rawConfigOverwrite]);

  const [JSONParseError, setJSONParseError] = useState<string | null>(null);
  const renderBody = (modelName: string): React.ReactNode => {
    return (
      <span data-testid="prompt-form-advanced-tab-inputs">
        {!isGoogle(modelName) && (
          <PromptMultiShotsField
            multiShots={advancedParameters.further_instructions as string[]}
            updateMultiShot={updateMultiShot}
            addMultiShot={addMultiShot}
            removeMultiShot={removeMultiShot}
          />
        )}
        <Divider />
        <Form.Field>
          <p className="tw-mx-0 tw-flex tw-items-center tw-gap-1">
            <Icon
              name="warning"
              className="tw-text-lg tw-leading-[1] tw-text-yellow-500"
            />{" "}
            If this is not used correctly, it can break the prompt.
          </p>
          <label>Provider API Config</label>{" "}
          <Text color="grey" className="descriptive-helper-text">
            In a JSON format, you can override the default parameters for the
            provider API. Please refer to the provider API documentation for the
            available parameters.
          </Text>
          <textarea
            data-testid="prompt-form-advanced-tab-config-overwrite-input"
            value={viewConfig}
            onChange={({ target }): void => {
              setRawConfigOverwrite(target.value);
              updateConfigOverwrite(target.value);
            }}
          />
        </Form.Field>
        {JSONParseError && (
          <Text
            color="red"
            className="descriptive-helper-text"
            testId="prompt-form-advanced-tab-config-overwrite-error"
          >
            {JSONParseError}
          </Text>
        )}
      </span>
    );
  };

  // Multi-shot
  const updateMultiShot = (index: number, value: string): void => {
    const copyMultiShots = [...advancedParameters.further_instructions];
    copyMultiShots[index] = value;
    collectAdvancedParameters({
      further_instructions: copyMultiShots as string[],
    });
  };
  const addMultiShot = (): void => {
    collectAdvancedParameters({
      further_instructions: [
        ...advancedParameters.further_instructions,
        "",
      ] as string[],
    });
  };
  const removeMultiShot = (): void => {
    const copyMultiShots = [...advancedParameters.further_instructions];
    copyMultiShots.splice(-1, 1);
    collectAdvancedParameters({
      further_instructions: copyMultiShots as string[],
    });
  };

  const updateConfigOverwrite = useCallback(
    debounce((value: string): void => {
      setJSONParseError(null);
      if (!value) {
        const copy = { ...advancedParameters.config_overwrite };
        if (copy[selectedModelName]) {
          delete copy[selectedModelName];
        }
        collectAdvancedParameters({ config_overwrite: copy });
        return;
      }
      try {
        const parsedValue = JSON.parse(value);
        const copy = { ...advancedParameters.config_overwrite };
        copy[selectedModelName] = parsedValue;

        collectAdvancedParameters({ config_overwrite: copy });
      } catch (e) {
        setJSONParseError(e.message);
      }
    }, 1000),
    [advancedParameters.config_overwrite, selectedModelName]
  );

  return (
    <PromptFormPaneForm
      isLoading={loading}
      testId="prompt-form-advanced-settings"
    >
      {renderBody(selectedModelName)}
    </PromptFormPaneForm>
  );
};
