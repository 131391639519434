import {
  CreateDocumentStructure,
  CreateSection,
  DocumentStructure,
  Section,
} from "../planner/document-structure/types";
import api from "./api";

// STRUCTURES:
export const DOCUMENT_STRUCTURE_BASE_URL =
  "/api/_internal/planner/document-structure/";

export async function getDocumentStructures(
  token: string
): Promise<DocumentStructure[]> {
  const response = await api.get<DocumentStructure[]>(
    DOCUMENT_STRUCTURE_BASE_URL,
    {
      headers: { token },
    }
  );
  return response.data;
}
export async function getDocumentStructure(
  token: string,
  id: number
): Promise<DocumentStructure> {
  const response = await api.get<DocumentStructure>(
    `${DOCUMENT_STRUCTURE_BASE_URL}${id}`,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function createDocumentStructure(
  token: string,
  documentStructure: CreateDocumentStructure
): Promise<DocumentStructure> {
  const response = await api.post<DocumentStructure>(
    DOCUMENT_STRUCTURE_BASE_URL,
    documentStructure,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updateDocumentStructure(
  token: string,
  documentStructure: DocumentStructure
): Promise<DocumentStructure> {
  const response = await api.put<DocumentStructure>(
    `${DOCUMENT_STRUCTURE_BASE_URL}${documentStructure.id}`,
    documentStructure,
    {
      headers: { token },
    }
  );
  return response.data;
}
export async function deleteDocumentStructure(
  token: string,
  id: number
): Promise<void> {
  await api.delete(`${DOCUMENT_STRUCTURE_BASE_URL}${id}`, {
    headers: { token },
  });
}
export const CONNECT_DOCUMENT_STRUCTURE_TO_CHANNEL_BASE_URL =
  "/api/_internal/customer/channel/set-document-structure";

type ConnectDocumentStructureToChannelResponse = {
  channel_id: number;
  document_structure_id: number | null;
};

export async function connectDocumentStructureToChannel(
  token: string,
  channelId: number,
  documentStructureId: number | null // If null then disconnect from channel
): Promise<ConnectDocumentStructureToChannelResponse> {
  const response = await api.post<ConnectDocumentStructureToChannelResponse>(
    CONNECT_DOCUMENT_STRUCTURE_TO_CHANNEL_BASE_URL,
    {
      channel_id: channelId,
      document_structure_id: documentStructureId,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}

// SECTIONS:
export const DOCUMENT_SECTION_BASE_URL =
  "/api/_internal/planner/document-section/";

export async function getDocumentSections(token: string): Promise<Section[]> {
  const response = await api.get<Section[]>(DOCUMENT_SECTION_BASE_URL, {
    headers: { token },
  });
  return response.data;
}
export async function getDocumentSection(
  token: string,
  id: number
): Promise<Section> {
  const response = await api.get<Section>(`${DOCUMENT_SECTION_BASE_URL}${id}`, {
    headers: { token },
  });
  return response.data;
}
export async function createDocumentSection(
  token: string,
  section: CreateSection
): Promise<Section> {
  const response = await api.post<Section>(DOCUMENT_SECTION_BASE_URL, section, {
    headers: { token },
  });
  return response.data;
}

export async function updateDocumentSection(
  token: string,
  section: Section
): Promise<Section> {
  const response = await api.put<Section>(
    `${DOCUMENT_SECTION_BASE_URL}${section.id}`,
    section,
    {
      headers: { token },
    }
  );
  return response.data;
}
export async function deleteDocumentSection(
  token: string,
  id: number
): Promise<void> {
  await api.delete(`${DOCUMENT_SECTION_BASE_URL}${id}`, {
    headers: { token },
  });
}
