import { ColumnLabel, ColumnValue } from "./Columns";
import { Product } from "../../product";

export const ProductListCreatedColumn = {
  name: ColumnValue.Created,
  label: ColumnLabel[ColumnValue.Created],
  default: true,
  renderCell: (p: Product): string =>
    p.created_at && new Date(p.created_at).toISOString().split("T")[0],
};
