import React, { useState } from "react";
import { Dropdown, Accordion, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { DropdownOnSearchChangeData } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { SimpleModal } from "./SimpleModal";
import { buttonTextColor } from "../products/product-list/viewsets/ViewSetsCreateNewFilterDropdown";
import { storeBulkActionReturnUrl } from "../api/action";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { useGetCustomerQuery } from "../api/customerApi";

export enum BulkAction {
  ADD_DATA = "AddDataAction",
  ADD_LABEL = "AddLabelAction",
  ADD_TAG = "AddTagAction",
  ADD_TEMPLATE = "AddTemplateAction",
  APPROVE_SELECTED = "ApproveTextAction",
  CHANGE_STATUS = "ChangeStatusAction",
  COPY_TO_FROM = "CopyAction",
  CREATE_PARENT_PRODUCT = "CreateParentProductAction",
  DELETE = "DeleteAction",
  DOWNLOAD = "DownloadAction",
  EXPORT = "ExportAction",
  GENERATE_TEXTS = "GenerateAction",
  GPT_GENERATE_TEXT = "GptGenerateTextAction",
  MACHINE_TRANSLATE = "MachineTranslateAction",
  PUBLISH = "PublishAction",
  REFRESH_FROM_IMPORTED_DATA = "RefreshFromImportedDataAction",
  REMOVE_LABEL = "RemoveLabelAction",
  REMOVE_TAG = "RemoveTagAction",
  REMOVE_TEMPLATE = "RemoveTemplateAction",
  REPLACE_TAG = "ReplaceTagAction",
  RESET_PUBLISH_STATUS = "ResetPublishStatusAction",
  RUN_CUSTOM_SCRIPT = "RunCustomScriptAction",
  RUN_PIPELINE = "RunPipelineAction",
  UPDATE_CHANNEL_LANGUAGE = "UpdateChannelLanguageAction",
}

export const bulkActionLabels: Record<string, string> = {
  [BulkAction.ADD_DATA]: "Add data",
  [BulkAction.ADD_LABEL]: "Add label",
  [BulkAction.ADD_TAG]: "Add tag",
  [BulkAction.ADD_TEMPLATE]: "Add template",
  [BulkAction.APPROVE_SELECTED]: "Approve Selected",
  [BulkAction.CHANGE_STATUS]: "Change status",
  [BulkAction.COPY_TO_FROM]: "Copy from one to many",
  [BulkAction.CREATE_PARENT_PRODUCT]: "Create parent document",
  [BulkAction.DELETE]: "Delete documents",
  [BulkAction.DOWNLOAD]: "Download as file",
  [BulkAction.EXPORT]: "Export to file",
  [BulkAction.GENERATE_TEXTS]: "Generate rule-based texts",
  [BulkAction.GPT_GENERATE_TEXT]: "Generate AI texts (Advanced)",
  [BulkAction.MACHINE_TRANSLATE]: "Machine translate texts",
  [BulkAction.PUBLISH]: "Publish to Connector",
  [BulkAction.REFRESH_FROM_IMPORTED_DATA]: "Refresh from imported data",
  [BulkAction.REMOVE_LABEL]: "Remove label",
  [BulkAction.REMOVE_TAG]: "Remove tag",
  [BulkAction.REMOVE_TEMPLATE]: "Remove template",
  [BulkAction.REPLACE_TAG]: "Replace tag",
  [BulkAction.RESET_PUBLISH_STATUS]: "Reset publish status",
  [BulkAction.RUN_CUSTOM_SCRIPT]: "Run Custom Script",
  [BulkAction.RUN_PIPELINE]: "Run pipeline",
  [BulkAction.UPDATE_CHANNEL_LANGUAGE]: "Update language channel settings",
};

export const DropdownItemLikeButton = styled(Button)`
  &&&& {
    position: relative;
    color: ${buttonTextColor};
  }
  &&&.basic,
  &&&.basic:hover {
    box-shadow: none !important;
    opacity: 1 !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
`;

const DropdownItem = styled(Dropdown.Item)`
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px !important;
  font-size: 10pt !important;
`;

export const getBulkActionUrl = async (
  token: string,
  action: BulkAction
): Promise<string> => {
  const currentUrl = window.location.pathname?.replace(/\/+$/, ""); // remove trailing slashes
  const locationSearch = window.location.search;
  const queryParams = new URLSearchParams(locationSearch);
  const returnUrl = await storeBulkActionReturnUrl({
    token,
    url: `${currentUrl}${locationSearch}`,
  });
  queryParams.set("return_url", returnUrl);
  const qs = queryParams.toString();
  if (action == BulkAction.GENERATE_TEXTS) {
    const generateTextPath = `${currentUrl}/bulk-actions/perform/${action}`;
    const generateSearchParams = new URLSearchParams();
    generateSearchParams.set("_label", "__selection__");
    generateSearchParams.set("_bucket", queryParams.get("bucket"));
    generateSearchParams.set("_ordering", "-created");
    const qsGenerateTexts = generateSearchParams.toString();
    return `${generateTextPath}?${qs}&${qsGenerateTexts}`;
  } else {
    const bulkActionPath = "/bulk-actions/perform/";
    return `${currentUrl}${bulkActionPath}${action}?${qs}`;
  }
};

export type Props = {
  onApproveSelected?: () => void;
  onProductEditClick?: () => void;
  disabled: boolean;
};

export const BulkActionsDropdown: React.FC<Props> = ({
  onApproveSelected,
  onProductEditClick,
  disabled,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [
    showNoSelectedProductsNotification,
    setShowNoSelectedProductsNotification,
  ] = useState(false);
  const [activeIndex, setActiveIndex] = useState<undefined | number>();
  const [open, setOpen] = useState(false);
  const { data: customer } = useGetCustomerQuery();

  const handleClick = (
    e: React.SyntheticEvent,
    titleProps: { index: number }
  ): void => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setOpen(true);
    setActiveIndex(newIndex);
  };
  const onDropdownOptionClick = (
    e: React.MouseEvent<HTMLDivElement>,
    { value }: DropdownOnSearchChangeData
  ): void => {
    if (value) {
      if (value == BulkAction.APPROVE_SELECTED) {
        onApproveSelected?.();
      } else {
        getBulkActionUrl(token, value as BulkAction).then((url: string) => {
          window.location.href = url;
        });
      }
    }
  };
  const getDropdownAccordionOption = (
    bulkAction: BulkAction
  ): React.ReactElement => {
    return (
      <DropdownItemLikeButton
        basic
        content={bulkActionLabels[bulkAction]}
        data-testid={bulkAction}
        key={bulkAction}
        onClick={(
          e: React.MouseEvent<HTMLDivElement>,
          data: DropdownOnSearchChangeData
        ): void => onDropdownOptionClick(e, data)}
        value={bulkAction}
      />
    );
  };

  const getDropdownOption = (bulkAction: BulkAction): React.ReactElement => {
    return (
      <DropdownItem
        data-testid={bulkAction}
        key={bulkAction}
        onClick={(
          e: React.MouseEvent<HTMLDivElement>,
          data: DropdownOnSearchChangeData
        ): void => onDropdownOptionClick(e, data)}
        text={bulkActionLabels[bulkAction]}
        value={bulkAction}
      />
    );
  };

  const accordionProps = {
    icon: (
      <Icon name="dropdown" style={{ margin: "0 0.5em 0 0", float: "left" }} />
    ),
    as: DropdownItem,
    onClick: handleClick,
  };
  const productListTopBarFFMenu = (
    <Dropdown.Menu
      onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
    >
      {getDropdownOption(BulkAction.GENERATE_TEXTS)}
      {getDropdownOption(BulkAction.GPT_GENERATE_TEXT)}
      {getDropdownOption(BulkAction.MACHINE_TRANSLATE)}
      {getDropdownOption(BulkAction.DOWNLOAD)}
      {getDropdownOption(BulkAction.APPROVE_SELECTED)}
      {getDropdownOption(BulkAction.PUBLISH)}
      <DropdownItem
        onClick={onProductEditClick}
        text="Edit selected"
        value="edit-selected"
      />
      {getDropdownOption(BulkAction.RUN_PIPELINE)}
      <Dropdown.Divider />
      <Accordion.Title
        {...accordionProps}
        active={activeIndex === 0}
        content={"Document"}
        index={0}
      />
      <Accordion.Content active={activeIndex === 0}>
        <Button.Group size={"mini"} vertical fluid>
          {getDropdownAccordionOption(BulkAction.ADD_DATA)}
          {getDropdownAccordionOption(BulkAction.CHANGE_STATUS)}
          {getDropdownAccordionOption(BulkAction.UPDATE_CHANNEL_LANGUAGE)}
          {getDropdownAccordionOption(BulkAction.CREATE_PARENT_PRODUCT)}
          {getDropdownAccordionOption(BulkAction.COPY_TO_FROM)}
          {getDropdownAccordionOption(BulkAction.RESET_PUBLISH_STATUS)}
          {customer?.config.allow_refresh_from_imported_data &&
            getDropdownAccordionOption(BulkAction.REFRESH_FROM_IMPORTED_DATA)}
          {getDropdownAccordionOption(BulkAction.DELETE)}
        </Button.Group>
      </Accordion.Content>
      <Accordion.Title
        {...accordionProps}
        active={activeIndex === 1}
        content={"Tag"}
        index={1}
      />
      <Accordion.Content active={activeIndex === 1}>
        <Button.Group size={"mini"} vertical fluid>
          {getDropdownAccordionOption(BulkAction.ADD_TAG)}
          {getDropdownAccordionOption(BulkAction.REPLACE_TAG)}
          {getDropdownAccordionOption(BulkAction.REMOVE_TAG)}
        </Button.Group>
      </Accordion.Content>
      <Accordion.Title
        {...accordionProps}
        active={activeIndex === 2}
        content={"Template"}
        index={2}
      />
      <Accordion.Content active={activeIndex === 2}>
        <Button.Group size={"mini"} vertical fluid>
          {getDropdownAccordionOption(BulkAction.ADD_TEMPLATE)}
          {getDropdownAccordionOption(BulkAction.REMOVE_TEMPLATE)}
        </Button.Group>
      </Accordion.Content>
      <Accordion.Title
        {...accordionProps}
        active={activeIndex === 3}
        content={"Label"}
        index={3}
      />
      <Accordion.Content active={activeIndex === 3}>
        <Button.Group size={"mini"} vertical fluid>
          {getDropdownAccordionOption(BulkAction.ADD_LABEL)}
          {getDropdownAccordionOption(BulkAction.REMOVE_LABEL)}
        </Button.Group>
      </Accordion.Content>
    </Dropdown.Menu>
  );

  return (
    <>
      <Accordion
        data-testid="bulk-actions-dropdown"
        disabled={disabled}
        item
        as={Dropdown}
        open={open}
        onClick={(): void => setOpen(!open)}
        onBlur={(): void => setOpen(false)}
        floating
        name="bulk-actions"
        text="Bulk actions"
        upward={false}
      >
        {productListTopBarFFMenu}
      </Accordion>
      <SimpleModal
        content={"No documents have been marked for this bulk action."}
        onClose={(): void => setShowNoSelectedProductsNotification(false)}
        open={showNoSelectedProductsNotification}
      />
    </>
  );
};
