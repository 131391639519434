import React from "react";
import { FieldRecommendations } from "../products/edit-tab/FieldRecommendations";
import { TagTreeEditorRecommendations } from "./TagTreeEditor";
import { BalloonDiv } from "../components/BalloonDiv";
import { SEOFieldRecommendations } from "../products/edit-tab/SEOFieldRecommendations";
import {
  FieldRecommendation,
  SEORecommendation,
} from "../products/edit-tab/ProductRecommendations";
import {
  recommendationVocabularyAdd,
  recommendationVocabularyAddSeo,
} from "../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { ProductId } from "../products/product";
import { productDetailsRefreshCurrentTab } from "../legacy/t.product-detail";

type Props = {
  onRemove?: () => void;
  productId: ProductId;
  recommendations: TagTreeEditorRecommendations;
};

export const TagTreeEditorRecommendationContainer: React.FC<Props> = ({
  onRemove,
  productId,
  recommendations,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const {
    fieldRecommendations,
    seoRecommendations,
    subpartRecommendations,
  } = recommendations.tags;

  const vocabId = recommendations.root_tag._id;

  const addTag = (
    recommendation: FieldRecommendation | SEORecommendation
  ): void => {
    recommendationVocabularyAdd({
      token,
      productId,
      highlightedTagId: vocabId,
      vocabularyId: recommendation.vocabulary_id || recommendation.id,
    }).then(productDetailsRefreshCurrentTab);
  };

  const addSeoTag = async (
    recommendation: FieldRecommendation | SEORecommendation,
    replaceTagWithSeo = false
  ): Promise<void> => {
    await recommendationVocabularyAddSeo({
      token,
      productId,
      highlightedTagId: vocabId,
      vocabularyId: recommendation.vocabulary_id || recommendation.id,
      replaceTag: replaceTagWithSeo,
    }).then(productDetailsRefreshCurrentTab);
  };

  const getFieldRecommendations = (): JSX.Element => {
    const currentRecommendations = [];
    for (const [key, value] of Object.entries(fieldRecommendations)) {
      if (value.length) {
        currentRecommendations.push(
          <div
            className={"recommendations-wrapper"}
            key={key}
            data-testid={"field-recommendations"}
          >
            <h3 className={"name"} data-testid={key.replace(/\s/g, "")}>
              {key}
            </h3>
            <FieldRecommendations
              horizontal={true}
              onClick={(recommendation): void => {
                addTag(recommendation);
              }}
              recommendations={value}
            />
          </div>
        );
      }
    }
    return <>{currentRecommendations}</>;
  };

  const getSubpartRecommendations = subpartRecommendations.length ? (
    <div
      className={"recommendations-wrapper"}
      data-testid={"subpart-recommendations"}
    >
      <h3 className={"name"}>Subpart</h3>
      <FieldRecommendations
        horizontal={true}
        onClick={(recommendation): void => {
          addTag(recommendation);
        }}
        recommendations={subpartRecommendations}
      />
    </div>
  ) : null;

  const getSEORecommendations = seoRecommendations.length ? (
    <div
      className={"recommendations-wrapper"}
      data-testid={"seo-recommendations"}
    >
      <BalloonDiv
        as={"div"}
        className={"seo"}
        pos={"up"}
        value={"Optimize for search term"}
      >
        <h3>
          <i className={"question circle icon"} /> SEO
        </h3>
      </BalloonDiv>
      <SEOFieldRecommendations
        horizontal={true}
        onTagsAction={(recommendation): Promise<void> =>
          addSeoTag(recommendation, true)
        }
        seoRecommendations={seoRecommendations}
      />
    </div>
  ) : null;

  return (
    <div
      className={"tagtree-recommendations"}
      data-testid={"tag-tree-editor-recommendations"}
    >
      <div className={"title-wrapper"}>
        <h2 className={"title"}>Recommended</h2>
        <BalloonDiv
          as={"div"}
          className={"remove-button"}
          onClick={(): void => {
            onRemove?.();
          }}
          pos={"up"}
          type={"submit"}
          value={"Remove"}
        >
          <i className={"close icon"} />
        </BalloonDiv>
      </div>
      {getSEORecommendations}
      {getSubpartRecommendations}
      {getFieldRecommendations()}
    </div>
  );
};
