import React, {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
  KeyboardEvent,
} from "react";
import { getTagTypeValue, VocabularyTreeData } from "../utils/tagutils";
import { Checkbox, Dropdown } from "semantic-ui-react";
import styled from "styled-components";

interface StyledElements {
  readonly tagTreeComponent: boolean;
}

const EmptyDiv = styled.div`
  margin: 0 5px;
`;

const StyledFlexColumn = styled.div<StyledElements>`
  ${({ tagTreeComponent }): string =>
    tagTreeComponent
      ? `
    margin-bottom: 20px;
    width: 100% !important;
  `
      : ` 
      display: flex;
        width: 224px;
        flex-direction: column;
        margin: 0 10px;
        `}
`;

const StyledFlexRow = styled.div<StyledElements>`
  ${({ tagTreeComponent }): string =>
    tagTreeComponent
      ? `
        display: flex; 
        `
      : ` 
        display: flex; 
        min-width: 224px !important 
        `}
`;

const StyledQuantInput = styled.input`
  padding-left: 16px !important;
  width: 40% !important;
  height: inherit !important;
  margin: 0 0 0 10px !important;
`;

export type ProductSettingSchemaFieldDataType = {
  number: string;
  type: "number" | "numeric_number";
  value: string;
};

type Props = {
  tagTreeComponent?: boolean;
  node: VocabularyTreeData;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (data: ProductSettingSchemaFieldDataType) => void;
  schemaRef?: MutableRefObject<any>;
  onKeyDown?: (e: KeyboardEvent) => void;
};

const getQuantityValue = (num: string): string => {
  if (num && !isNaN(Number(num))) {
    return num;
  }
  return "1";
};

export const ProductSettingSchemaField: React.FC<Props> = ({
  tagTreeComponent = false,
  node,
  onFocus,
  onBlur,
  onChange,
  schemaRef,
  onKeyDown,
}) => {
  const [currentOption, setCurrentOption] = useState(
    node?.settings?.number || ""
  );
  const [quantity, setQuantity] = useState(
    getQuantityValue(node?.settings?.value)
  );
  const [numericValues, setNumericValues] = useState(
    node?.settings?.type === "numeric_number"
  );

  useEffect(() => {
    setCurrentOption(node?.settings?.number || "");
    setQuantity(getQuantityValue(node?.settings?.value));
    setNumericValues(node?.settings?.type === "numeric_number");
  }, [node]);

  const onDataChange = ({
    option = currentOption,
    isNumericValue = numericValues,
    quantityValue = quantity,
  }: {
    option?: string;
    isNumericValue?: boolean;
    quantityValue?: string;
  }): void => {
    onChange?.({
      number: option,
      type: isNumericValue ? "numeric_number" : "number",
      value: quantityValue,
    });
  };

  useImperativeHandle(
    schemaRef,
    () => ({
      getSchemaState: (): ProductSettingSchemaFieldDataType => {
        return {
          number: currentOption,
          type: numericValues ? "numeric_number" : "number",
          value: quantity,
        };
      },
    }),
    [currentOption, numericValues, quantity]
  );

  const dropdownOptions = node?.setting_schema?.properties?.number.enum;

  if (!dropdownOptions) {
    return <EmptyDiv />;
  }

  const isCurrentQuant = currentOption == "quant";

  return (
    <StyledFlexColumn
      tagTreeComponent={tagTreeComponent}
      data-testid={"product-setting-schema-field"}
    >
      <StyledFlexRow tagTreeComponent={tagTreeComponent}>
        {dropdownOptions && (
          <Dropdown
            fluid
            data-testid={"custom-widget-dropdown"}
            style={{ maxWidth: `${isCurrentQuant ? "60%" : "100%"}` }}
            selection
            selectOnNavigation={false}
            clearable
            name={"currentOption"}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            options={dropdownOptions.map((value: string): {
              [key: string]: string;
            } => {
              return {
                value: value,
                text: getTagTypeValue(value),
                key: value,
              };
            })}
            onChange={(e: any, { value }: { value: string }): void => {
              const checkQuant = value !== "quant";
              if (checkQuant) {
                setQuantity("1");
                setNumericValues(false);
              }
              setCurrentOption(value);
              onDataChange({
                option: value,
                quantityValue: checkQuant ? "1" : quantity,
                isNumericValue: checkQuant ? false : numericValues,
              });
            }}
            value={currentOption}
          />
        )}
        {isCurrentQuant && (
          <StyledQuantInput
            type={"number"}
            name={"quantity"}
            min={1}
            data-testid={"product-setting-schema-field-quantity-input"}
            value={quantity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value } = e.target;
              setQuantity(value);
              onDataChange({ quantityValue: value });
            }}
          />
        )}
      </StyledFlexRow>

      {isCurrentQuant && (
        <Checkbox
          data-testid={"product-setting-schema-field-numeric-values-checkbox"}
          label={"Numeric values"}
          name={"numericValues"}
          checked={numericValues}
          onChange={(): void => {
            const isNumericValue = !numericValues;
            setNumericValues(isNumericValue);
            onDataChange({ isNumericValue });
          }}
          style={{ marginTop: `${tagTreeComponent ? "20px" : "5px"}` }}
        />
      )}
    </StyledFlexColumn>
  );
};
