import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Popup,
} from "semantic-ui-react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { FieldsetFormList, FieldsetFormListItem } from "./FieldsetFormList";
import {
  createFieldset,
  deleteFieldset,
  updateFieldset,
} from "../../../api/fieldsetApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { Fieldset, ModifiedFieldset } from "./types";
import {
  DocumentStructure,
  Section,
} from "../../../planner/document-structure/types";

// Jest does not collect coverage from this file as we cant test DND because it has functionally to drag between two lists. (last time it run coverage it was 75% for this file) - Erik

function filterAllData(item: FieldsetFormListItem): boolean {
  return item.origin === "data";
}

function filterAllSections(item: FieldsetFormListItem): boolean {
  return item.origin === "section";
}

const OFFSET = 480;

const SELECTED_LIST_DROPPABLE_ID = "selected-keys-list";
const KNOWN_LIST_DROPPABLE_ID = "known-keys-list";
const SECTION_DROPPABLE_ID = "section-list";

const notAllowedToDragBetweenLists = [
  KNOWN_LIST_DROPPABLE_ID,
  SECTION_DROPPABLE_ID,
];

const SUBMIT_BUTTON_NAMES = {
  CREATE: "Create",
  UPDATE: "Update",
  COPY: "Copy",
} as const;

type Props = {
  knownKeys: string[];
  loading?: boolean;
  refetchFieldsets: () => Promise<void>;
  selectedFieldset: Fieldset | undefined;
  setSelectedFieldset: (fieldset: Fieldset) => void;
  isCopying?: boolean;
  setIsCopying?: Dispatch<SetStateAction<boolean>>;
  sections: Section[];
  isFetchingSections: boolean;
  structures: DocumentStructure[];
};

export const FieldsetForm: React.FC<Props> = ({
  knownKeys,
  loading,
  refetchFieldsets,
  selectedFieldset,
  setSelectedFieldset,
  isCopying,
  setIsCopying,
  sections,
  isFetchingSections,
  structures,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  // FIELDSET DATA
  const [selectedItems, setSelectedItems] = useState<FieldsetFormListItem[]>(
    []
  );
  const [fieldsetName, setFieldsetName] = useState<string>("");
  const [useAsDefault, setUseAsDefault] = useState<boolean>(
    selectedFieldset?.use_as_default || false
  );

  // SEARCH
  const [searchString, setSearchString] = useState<string>("");

  const allowedSections = useMemo(
    () => sections.filter(({ template_label_ids }) => !template_label_ids),
    [sections]
  );

  const searchItems = (
    search: string,
    items: FieldsetFormListItem[]
  ): FieldsetFormListItem[] => {
    if (!search) return items;
    return items.filter(
      ({ name }) => name.toLowerCase().search(search.toLowerCase()) != -1
    );
  };
  const allItemsSelected = useMemo(() => {
    return knownKeys.length + allowedSections.length === selectedItems.length;
  }, [knownKeys, allowedSections, selectedItems]);

  const filteredItems = useMemo(
    () => searchItems(searchString, selectedItems),
    [searchString, selectedItems]
  );

  const filteredKnownKeys = useMemo(
    () =>
      searchItems(
        searchString,
        knownKeys
          .filter(
            (key) =>
              !selectedItems
                .filter(filterAllData)
                .map(({ name }) => name)
                .includes(key)
          )
          .map(
            (key): FieldsetFormListItem => ({
              name: key,
              origin: "data",
            })
          )
      ),
    [knownKeys, selectedItems, searchString]
  );

  const filteredSections = useMemo(
    () =>
      searchItems(
        searchString,
        allowedSections
          .filter(
            ({ id }) =>
              !selectedItems
                .filter(filterAllSections)
                .map((selected) => selected?.id)
                .includes(id)
          )
          .map(
            ({ name, id }): FieldsetFormListItem => ({
              name,
              id,
              origin: "section",
            })
          )
      ),
    [sections, searchString, selectedItems]
  );

  const isUnsavedDefaultFieldset = useMemo(
    () => selectedFieldset?.use_as_default && selectedFieldset?.id === 0,
    [selectedFieldset]
  );

  const [submitButtonName, setSubmitButtonName] = useState<string>(
    SUBMIT_BUTTON_NAMES.CREATE
  );

  useEffect(() => {
    if (selectedFieldset) {
      setSubmitButtonName(
        isCopying ? SUBMIT_BUTTON_NAMES.COPY : SUBMIT_BUTTON_NAMES.UPDATE
      );
    } else {
      setSubmitButtonName(SUBMIT_BUTTON_NAMES.CREATE);
    }
  }, [selectedFieldset, isCopying, useAsDefault]);

  useEffect(() => {
    setSelectedItems([]);
    setFieldsetName("");
    setUseAsDefault(false);
    if (selectedFieldset) {
      setUseAsDefault(selectedFieldset.use_as_default);
      if (
        selectedFieldset.use_as_default &&
        selectedFieldset.fields.length < 1
      ) {
        setSelectedItems(
          knownKeys.map((key) => ({ name: key, origin: "data" }))
        );
      } else {
        if (isFetchingSections) return;
        selectedFieldset.fields.forEach((field) => {
          if (field?.section_id) {
            setSelectedItems((prev) => [
              ...prev,
              {
                name:
                  allowedSections.find(({ id }) => id === field.section_id)
                    ?.name || "Unknown section name",
                id: field.section_id,
                origin: "section",
              },
            ]);
          } else if (field?.key) {
            setSelectedItems((prev) => [
              ...prev,
              {
                name: field.key,
                origin: "data",
              },
            ]);
          }
        });
      }
      let name = selectedFieldset.name;
      if (isCopying) {
        setUseAsDefault(false);
        name += "-copy";
      }
      setFieldsetName(name);
    }
    setMissingName(false);
    setMissingKeys(false);
  }, [selectedFieldset, isCopying, isFetchingSections, allowedSections]);

  // RESIZE LISTS
  const [height, setHeight] = useState<number>(0);
  const handleWindowResize = useCallback(() => {
    const windowHeight = document.documentElement.clientHeight;
    const windowScrollHeight = document.documentElement.scrollHeight;
    const scrollOffset = windowScrollHeight - windowHeight;
    setHeight(windowHeight - scrollOffset - OFFSET);
  }, []);

  useLayoutEffect(() => {
    if (height === 0) {
      handleWindowResize();
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // VALIDATE FORM
  const [missingName, setMissingName] = useState<boolean>(false);
  const [missingKeys, setMissingKeys] = useState<boolean>(false);
  const validateForm = (): boolean => {
    setMissingName(!fieldsetName);
    setMissingKeys(selectedItems.length < 1);
    const valid = !!fieldsetName && selectedItems.length > 0;
    return valid;
  };

  // MANIPULATE LISTS
  const addKey = (item: FieldsetFormListItem): void => {
    setMissingKeys(false);
    setSelectedItems([...selectedItems, item]);
  };

  const addAllKeys = (): void => {
    const allSelectedDataKeyNames = selectedItems
      .filter(filterAllData)
      .map(({ name }) => name);

    const allNonSelectedDataKeys = filteredKnownKeys.filter(
      (key) => !allSelectedDataKeyNames.includes(key.name)
    );

    const allSelectedSectionIds = selectedItems
      .filter(filterAllSections)
      .map(({ id }) => id);
    const allNonSelectedSections = filteredSections.filter(
      ({ id }) => !allSelectedSectionIds.includes(id)
    );

    setSelectedItems([
      ...selectedItems,
      ...allNonSelectedDataKeys,
      ...allNonSelectedSections,
    ]);
  };

  const removeAllKeys = (): void => {
    if (searchString) {
      setSelectedItems(
        selectedItems.filter(
          (item) => !filteredItems.map(({ name }) => name).includes(item.name)
        )
      );
      return;
    }
    setSelectedItems([]);
  };
  const removeKey = (item: FieldsetFormListItem): void => {
    if (item?.id) {
      setSelectedItems(
        selectedItems.filter((selected) => selected?.id !== item.id)
      );
      return;
    }

    setSelectedItems(
      selectedItems.filter((selected) => {
        if (selected.origin === "data") {
          return selected.name !== item.name;
        }
        return true;
      })
    );
  };

  // DND FUNCTIONS
  const reorder = (
    list: FieldsetFormListItem[],
    startIndex: number,
    endIndex: number
  ): FieldsetFormListItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const moveTo = (
    toList: FieldsetFormListItem[],
    fromList: FieldsetFormListItem[],
    fromListIndex: number,
    toListIndex: number
  ): FieldsetFormListItem[] => {
    const fromListResult = Array.from(fromList);
    const [removed] = fromListResult.splice(fromListIndex, 1);
    const toListResult = Array.from(toList);
    toListResult.splice(toListIndex, 0, removed);
    return toListResult;
  };

  const removeItem = (
    fromList: FieldsetFormListItem[],
    fromListIndex: number
  ): FieldsetFormListItem[] => {
    const fromListResult = Array.from(fromList);
    fromListResult.splice(fromListIndex, 1);
    return fromListResult;
  };

  const onDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      notAllowedToDragBetweenLists.includes(source.droppableId) &&
      notAllowedToDragBetweenLists.includes(destination.droppableId)
    ) {
      return;
    }
    const dragIndex = source.index;
    const destinationIndex = destination.index;

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId !== SELECTED_LIST_DROPPABLE_ID) {
        const fromList =
          source.droppableId === SECTION_DROPPABLE_ID
            ? filteredSections
            : filteredKnownKeys;

        setMissingKeys(false);
        const newList = moveTo(
          selectedItems,
          fromList,
          dragIndex,
          destinationIndex
        );
        setSelectedItems(newList);
      }
      if (source.droppableId === SELECTED_LIST_DROPPABLE_ID) {
        const newList = removeItem(selectedItems, dragIndex);
        setSelectedItems(newList);
      }
    }
    if (dragIndex === destinationIndex) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === SELECTED_LIST_DROPPABLE_ID) {
        const newList = reorder(selectedItems, dragIndex, destinationIndex);
        setSelectedItems(newList);
      }
    }
  };

  // ACTIONS
  const collectData = (): ModifiedFieldset => ({
    name: fieldsetName,
    fields: selectedItems.map((item) => {
      if (item.origin === "section" && item.id) {
        return { section_id: item.id };
      }
      return { key: item.name };
    }),
    use_as_default: useAsDefault,
  });

  const onCreateFieldset = async (): Promise<void> => {
    if (!validateForm()) return;
    setIsCopying?.(false);
    await createFieldset(token, collectData())
      .then((fieldset) => setSelectedFieldset(fieldset))
      .finally(async () => {
        await refetchFieldsets();
      });
  };

  const onUpdateFieldset = async (): Promise<void> => {
    if (!validateForm()) return;
    await updateFieldset(token, selectedFieldset?.id, collectData())
      .then((fieldset) => setSelectedFieldset(fieldset))
      .finally(async () => {
        await refetchFieldsets();
      });
  };

  const onDeleteFieldset = async (): Promise<void> => {
    setMissingKeys(false);
    setMissingName(false);
    await deleteFieldset(token, selectedFieldset?.id)
      .then(() => setSelectedFieldset(undefined))
      .finally(async () => {
        await refetchFieldsets();
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Form as="div" size="small">
        <Popup
          open={missingName}
          content="Name is required"
          size="tiny"
          trigger={
            <Form.Field error={missingName}>
              <label htmlFor="fieldset-name-input">Name</label>
              <Input
                disabled={useAsDefault}
                data-testid="fieldset-name-input"
                id="fieldset-name-input"
                autoFocus
                placeholder="Input schema name"
                tabIndex={1}
                value={fieldsetName}
                onChange={(_, { value }): void => {
                  setMissingName(false);
                  setFieldsetName(value);
                }}
              />
            </Form.Field>
          }
        />
        <Form.Field>
          <Divider />
          <Input
            iconPosition="left"
            icon="search"
            placeholder="Search Fields & Sections"
            value={searchString}
            onChange={(_, { value }): void => setSearchString(value)}
          />
          <Grid>
            <Grid.Row divided>
              <Grid.Column size="tiny" width={6}>
                <Button
                  size="tiny"
                  basic
                  content="Add all"
                  color="red"
                  compact
                  onClick={addAllKeys}
                  disabled={
                    !filteredKnownKeys.length && !filteredSections.length
                  }
                  data-testid="fieldset-add-all-keys"
                />
                {!!filteredKnownKeys.length && (
                  <>
                    <Divider horizontal>
                      <Header as="h5">Available Fields</Header>
                    </Divider>
                    <FieldsetFormList
                      testIdPrefix="fieldset-available-fields"
                      listItems={filteredKnownKeys}
                      listItemAction={addKey}
                      listItemIcon="arrow right"
                      listHight={filteredSections.length ? height / 2 : height}
                      droppableId={KNOWN_LIST_DROPPABLE_ID}
                    />
                  </>
                )}
                {!!filteredSections.length && (
                  <>
                    <Divider horizontal>
                      <Header as="h5">Available Sections</Header>
                    </Divider>
                    <FieldsetFormList
                      testIdPrefix="fieldset-available-sections"
                      listItems={filteredSections}
                      listItemAction={addKey}
                      listItemIcon="arrow right"
                      listHight={filteredKnownKeys.length ? height / 2 : height}
                      droppableId={SECTION_DROPPABLE_ID}
                      structures={structures}
                    />
                  </>
                )}
                {!filteredSections.length && !filteredKnownKeys.length && (
                  <Divider horizontal>
                    <Header
                      as="h5"
                      color="grey"
                      data-testid="fieldset-form-no-available-items-message"
                    >
                      {allItemsSelected
                        ? "All Fields & Sections selected"
                        : "No Fields or Sections Found"}
                    </Header>
                  </Divider>
                )}
              </Grid.Column>

              <Grid.Column width={10}>
                <Button
                  data-testid="fieldset-submit-form"
                  content={submitButtonName}
                  size="tiny"
                  color="red"
                  compact
                  onClick={(): void => {
                    if (
                      !selectedFieldset ||
                      isCopying ||
                      isUnsavedDefaultFieldset
                    ) {
                      onCreateFieldset();
                    } else {
                      onUpdateFieldset();
                    }
                  }}
                  disabled={loading}
                />
                <Button
                  floated="right"
                  size="tiny"
                  basic
                  content="Remove all"
                  color="red"
                  compact
                  onClick={removeAllKeys}
                  data-testid="fieldset-remove-all-keys"
                />
                <Popup
                  content="At least one selected Field or Section is required"
                  size="tiny"
                  open={missingKeys}
                  position="bottom center"
                  trigger={
                    <Divider horizontal>
                      <Header as="h5" {...(missingKeys && { color: "red" })}>
                        Selected Fields & Sections
                      </Header>
                    </Divider>
                  }
                />
                <FieldsetFormList
                  testIdPrefix="fieldset-selected-fields"
                  listItems={filteredItems}
                  listItemAction={removeKey}
                  listItemIcon="arrow left"
                  listHight={height}
                  droppableId={SELECTED_LIST_DROPPABLE_ID}
                  structures={structures}
                />
                <Divider />

                {selectedFieldset && !isUnsavedDefaultFieldset && (
                  <Button
                    size="tiny"
                    floated="right"
                    content="Delete"
                    basic
                    compact
                    onClick={(): Promise<void> => onDeleteFieldset()}
                    disabled={loading}
                    data-testid="fieldset-delete"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
    </DragDropContext>
  );
};
