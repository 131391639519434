import React from "react";
import { SemanticSIZES } from "semantic-ui-react/dist/commonjs/generic";
import { TemplateLabel } from "../vocabulary/template";
import { TemplateLabelChip } from "../vocabulary/templatesTabLabelSelector/TemplateLabelChip";

type Props = {
  colorPrimary?: boolean;
  labels: TemplateLabel[];
  size?: SemanticSIZES;
};
export const TemplateLabelsCollection: React.FC<Props> = ({
  colorPrimary,
  labels,
  size,
}) => (
  <>
    {labels.map(({ description, name }) => (
      <TemplateLabelChip
        colorPrimary={colorPrimary}
        content={name}
        description={description}
        labelProps={{
          as: "span",
          size: size || "small",
        }}
        key={name}
      />
    ))}
  </>
);
