import React, { KeyboardEvent } from "react";
import uniqBy from "lodash/uniqBy";
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  FullTree,
  getNodeAtPath,
  NodeData,
  OnDragPreviousAndNextLocation,
  OnMovePreviousAndNextLocation,
  removeNodeAtPath,
  TreeItem,
} from "react-sortable-tree";
import { Button } from "semantic-ui-react";
import { SettingsModal } from "./SettingsModal";
import { getDisplayText } from "../utils/data";
import IndexedDropdown from "../ui/Dropdown";
import CustomTheme from "../reactSortableTreeTheme/index";

import {
  GetNodeKeyHandler,
  HandleRequestWordHandler,
  formatTag,
  TagOption,
  TreeData,
  VocabularyTreeData,
  getTagTypeValue,
} from "../utils/tagutils";
import {
  DropdownOnSearchChangeData,
  DropdownProps,
} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { AxiosResponse } from "axios";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {
  searchAsyncVocabulary,
  SearchVocabularyParams,
} from "../api/vocabularyApi";
import { Customer } from "../customers/Customer";
import { ProductId } from "../products/product";
import { debounce } from "../utils/debounce";
import { NodePath, SortableTreeProps } from "./TagTreeEditor";
import { IndexedDropdownLessPadding } from "./ProductTagsType";
import { getCustomer } from "../api/customerApi";
import { connect, ConnectedProps } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  ProductSettingSchemaField,
  ProductSettingSchemaFieldDataType,
} from "./ProductSettingSchemaField";
import Styled from "styled-components";

const StyledDiv = Styled.div<{ hideDefaultOptionButton: boolean }>`
  ${({ hideDefaultOptionButton }): string =>
    hideDefaultOptionButton &&
    `
      [name="subpart"] {
        .text button.width_hundred {
          display: none;
        }
      }
  `}
`;

type Props = {
  getNodeKey?: GetNodeKeyHandler;
  handleRequestWord: HandleRequestWordHandler;
  onAttributeChanged: (subparts: { treeData: TreeItem[] }) => void;
  limit?: number;
  textualAppName: string;
  token: string;
  treeData: TreeData[];
  productId?: ProductId;
  parentId?: number;
};

type State = {
  currentNode: VocabularyTreeData;
  currentNodeKey: GetNodeKeyHandler;
  currentNodePath: NodePath;
  isOpenSettingsModal: boolean;
  modifiedTreeData: VocabularyTreeData[];
  vocabularies: VocabularyTreeData[];
  isFetchingSearchResults: boolean;
  isFetchingSearchResultsTags: boolean;
  isFocused: boolean;
};

interface VocabularyDataDropdownProps extends Omit<DropdownProps, "value"> {
  value?: string[];
}

const mapStateToProps = (
  state: RootState
): { token: string | null; customer: Customer | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
  /** Current customer */
  customer: getCustomer(store.getState()),
});

const connector = connect(mapStateToProps, null);

type ProductTagsSortableTreeProps = ConnectedProps<typeof connector> & Props;

export default class ProductTagsSortableTree extends React.Component<
  ProductTagsSortableTreeProps,
  State
> {
  state: State = {
    currentNode: null,
    currentNodeKey: null,
    currentNodePath: [],
    isOpenSettingsModal: false,
    modifiedTreeData: [],
    vocabularies: [],
    isFetchingSearchResults: false,
    isFetchingSearchResultsTags: false,
    isFocused: false,
  };
  componentDidUpdate(prevProps: ProductTagsSortableTreeProps): void {
    const { treeData } = this.props;
    if (prevProps.treeData !== treeData) {
      const tempTreeData: VocabularyTreeData[] = JSON.parse(
        JSON.stringify(treeData)
      );
      tempTreeData.map((data) => {
        if (data && data.children) {
          data.children = data.children.filter((child) => {
            child.children = child.children
              ? child.children.filter((grandChild) => {
                  return grandChild.allow_children;
                })
              : [];
            return child.allow_children;
          });
          data.expanded = true;
        }
      });

      this.setState({ modifiedTreeData: tempTreeData });
    }
  }

  getMatchedVocabularyIndex = (
    vocabularies: VocabularyTreeData[],
    addedItem: string
  ): number | boolean => {
    return vocabularies && vocabularies.length > 0
      ? vocabularies.findIndex((vocabulary) => {
          const matchedVocabularyValue = vocabulary.value;
          return matchedVocabularyValue === addedItem;
        })
      : -1;
  };

  _onMoveNode = ({
    treeData,
    node,
    prevPath,
  }: NodeData & FullTree & OnMovePreviousAndNextLocation): void => {
    const { treeData: originalTreeData } = this.props;
    const { onAttributeChanged } = this.props;
    const { modifiedTreeData } = this.state;
    const getNodeKey: GetNodeKeyHandler = ({ treeIndex }) => treeIndex;

    let originalData: any = {};
    const updatedTreeData: TreeData[] = [];
    originalTreeData.forEach((child, index) => {
      if (JSON.stringify(modifiedTreeData[index]) === JSON.stringify(node)) {
        originalData = child; // removed from original treeData, but saved to originalData
      } else if (child.children.length > 0) {
        const addChild: TreeData[] = [];
        child.children.forEach((grandChild, grandIndex) => {
          if (
            grandChild.allow_children &&
            JSON.stringify(modifiedTreeData[index].children[grandIndex]) ===
              JSON.stringify(node)
          ) {
            originalData = grandChild; // removed from original treeData, but saved to originalData
          } else {
            addChild.push(grandChild);
          }
        });
        updatedTreeData.push({
          ...child,
          children: [...addChild],
        });
      } else {
        updatedTreeData.push({ ...child });
      }
    });

    const removedTreeData = removeNodeAtPath({
      treeData: [...modifiedTreeData] as TreeData[],
      path: prevPath,
      getNodeKey,
    }) as TreeData[];

    // second add removed node
    if (treeData.length > removedTreeData.length) {
      // moved to parent
      let addedIndex = -1;
      treeData.forEach((child: TreeData, index) => {
        if (!removedTreeData.includes(child)) {
          addedIndex = index;
        }
      });
      updatedTreeData.splice(addedIndex, 0, originalData);
    } else if (treeData.length === removedTreeData.length) {
      // moved to child
      let addedParentIndex = -1;
      let addedChildIndex = -1;
      treeData.forEach((child: TreeData, index) => {
        if (child.children.length > removedTreeData[index].children.length) {
          addedParentIndex = index;
          child.children.forEach((grandChild, grandIndex) => {
            if (!removedTreeData[index].children.includes(grandChild)) {
              addedChildIndex = grandIndex;
            }
          });
        }
      });
      updatedTreeData[addedParentIndex].children.splice(
        addedChildIndex,
        0,
        originalData
      );
    }

    onAttributeChanged({ treeData: updatedTreeData });
  };

  handleTreeInputChange = (
    obj: DropdownOnSearchChangeData,
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    const {
      customer,
      treeData,
      token,
      textualAppName,
      limit,
      productId,
      parentId,
    } = this.props;
    this.setState({ currentNodePath: path });
    const searchQuery = obj.searchQuery;
    const language = customer?.config.language;
    const queryParam: SearchVocabularyParams = {
      q: searchQuery,
      product_id: productId,
      limit,
      language,
    };
    let selectType = "";
    const includeSeo = customer?.config.show_seo_scores;
    if (includeSeo) {
      queryParam.include_seo = includeSeo;
    }
    if (obj.name !== "tags") {
      // In the dropdowns on the left side of the component you can only select tags that can have children.
      queryParam.allow_children = true;
      selectType = "productCategorySelect";
      // Select component on the top of the advanced template
      queryParam.parent_id = parentId;
      this.setState({ isFetchingSearchResults: true });
    } else {
      this.setState({
        isFetchingSearchResultsTags: true,
      });
      // In the second case - return all tags.
      selectType = "productTagSelect";
      // Select component on the left side as parent
      let newParentId = parseInt(treeData[0]?.id);
      if (isNaN(newParentId)) {
        newParentId = null;
      }
      queryParam.parent_id = newParentId;
    }

    queryParam.advanced_template_search = true;

    if (searchQuery.length >= 1) {
      searchAsyncVocabulary(queryParam, token, textualAppName).then(
        (response: AxiosResponse<VocabularyTreeData[]>) => {
          this.setState({
            isFetchingSearchResults: false,
            isFetchingSearchResultsTags: false,
          });
          let data = [];
          let suggestions: TagOption[] = [];
          if (obj.name === "tags") {
            data = response.data.filter(function (item) {
              return !item.allow_children;
            });
            suggestions = data.map((vocabulary, index) => ({
              key: index,
              text: getDisplayText(vocabulary),
              value: vocabulary.value,
            }));
          } else {
            data = response.data;

            suggestions = response.data.map((vocabulary, index) => ({
              key:
                node.master_display_name !== "" && node.value !== ""
                  ? index + 1
                  : index,
              text: getDisplayText(vocabulary),
              value: vocabulary.value,
            }));

            if (node.master_display_name !== "" && node.value !== "") {
              suggestions.splice(0, 0, {
                key: 0,
                text: getDisplayText(node),
                value: node.value,
              });
            }
          }
          if (obj.name === "subpart") {
            node.subpartOptions = searchQuery ? suggestions : [];
            this.setVocabulariesAndNodeData(
              data,
              searchQuery,
              selectType,
              node,
              path,
              getNodeKey,
              true
            );
          } else if (obj.name === "tags") {
            treeData[0] &&
              treeData[0].children &&
              treeData[0].children.filter((child) => {
                if (!child.allow_children) {
                  if (
                    suggestions.findIndex(
                      (tagOption) => tagOption.value === child.value
                    ) === -1
                  ) {
                    suggestions.push({
                      value: child.value,
                      text: getDisplayText(child),
                    });
                  }
                }
              });
            node.tagOptions = searchQuery ? suggestions : [];
            if (node.selectedTagSchema && node.selectedTagSchema.length > 0) {
              node.selectedTagSchema.map((tag) => {
                if (
                  node.tagOptions.findIndex(
                    (tagOption) => tagOption.value === tag.value
                  ) === -1
                ) {
                  node.tagOptions.push({
                    value: tag.value,
                    text: formatTag(tag, {
                      includeSubTagNames: true,
                    }),
                  });
                }
              });
            }
            this.setVocabulariesAndNodeData(
              data,
              searchQuery,
              selectType,
              node,
              path,
              getNodeKey,
              false
            );
          }
        }
      );
    } else {
      if (obj.name == "subpart")
        node.subpartOptions = node.value
          ? [{ text: node.helper_display_name, value: node.value }]
          : [];
      if (obj.name == "tags") node.tagOptions = [];
      this.setState({
        isFetchingSearchResults: false,
        isFetchingSearchResultsTags: false,
      });
      this.setVocabulariesAndNodeData(
        [],
        searchQuery,
        selectType,
        node,
        path,
        getNodeKey,
        obj.name === "subpart"
      );
    }
  };

  setVocabulariesAndNodeData = (
    vocabularies: VocabularyTreeData[],
    searchQuery: string,
    selectType: string,
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler,
    isSubpart: boolean
  ): void => {
    const { customer, handleRequestWord } = this.props;

    const vocabularyRequestAllowed = customer?.config.show_request_words;
    const key = isSubpart ? "subpartOptions" : "tagOptions";
    if (vocabularyRequestAllowed && searchQuery) {
      node[key].unshift({
        key: node[key].length,
        text: (
          <>
            <Button
              className="width_hundred"
              content=" Use this word without translations, and request translation."
              onClick={(): void =>
                handleRequestWord(
                  searchQuery,
                  selectType,
                  this.requestWordUpdated
                )
              }
            />
            {node[key].length < 1 && (
              <span className="ui text grey">No results found</span>
            )}
          </>
        ),
        value: "Not-found-value",
      });
    }
    this.setState({
      vocabularies: vocabularies,
      currentNode: node,
      currentNodePath: path,
      currentNodeKey: getNodeKey,
    });
  };

  updateParentTreeData = (
    updatedNode: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler,
    isRemoveNode = false
  ): void => {
    const { treeData, onAttributeChanged } = this.props;
    const { modifiedTreeData } = this.state;

    const updatedTreeData = changeNodeAtPath({
      treeData: [...modifiedTreeData],
      path,
      getNodeKey,
      newNode: updatedNode,
    }) as TreeData[];

    const callbackData: TreeData[] = [];
    treeData.forEach((child, index) => {
      if (
        JSON.stringify(updatedTreeData[index]) === JSON.stringify(updatedNode)
      ) {
        if (!isRemoveNode) {
          const updatedChildren: TreeItem[] = [];
          if (child.children.length > 0) {
            child.children.forEach((originalChild) => {
              if (
                !originalChild.allow_children &&
                (updatedNode.tags as string[]).includes(originalChild.value)
              ) {
                updatedChildren.push(originalChild);
              } else if (originalChild.allow_children) {
                updatedChildren.push(originalChild);
              }
            });
          }
          if (updatedNode.children.length > 0) {
            updatedNode.children.forEach((tagChild) => {
              if (
                !tagChild.allow_children &&
                (updatedNode.tags as string[]).includes(tagChild.value)
              ) {
                updatedChildren.push(tagChild);
              }
            });
          }
          callbackData.push({
            ...updatedNode,
            children: [...updatedChildren],
          } as TreeData);
        }
      } else if (child.children.length > 0) {
        const addChild: TreeItem[] = [];
        child.children.forEach((grandChild, grandIndex) => {
          if (
            grandChild.allow_children &&
            JSON.stringify(updatedTreeData[index].children[grandIndex]) ===
              JSON.stringify(updatedNode)
          ) {
            if (!isRemoveNode) {
              addChild.push(updatedNode);
            }
          } else {
            addChild.push(grandChild);
          }
        });
        callbackData.push({
          ...child,
          children: [...addChild],
        } as TreeData);
      } else {
        callbackData.push({ ...child });
      }
    });

    // Remove duplicated children
    if (callbackData[0]?.children) {
      callbackData[0].children = uniqBy(callbackData[0].children, "value");
    }
    onAttributeChanged({ treeData: callbackData });
  };

  requestWordUpdated = (response: AxiosResponse, selectType: string): void => {
    const {
      vocabularies,
      currentNode,
      currentNodePath,
      currentNodeKey,
    } = this.state;
    const data = response.data;

    if (data && data.value) {
      const tempVocabularies = [...vocabularies];
      tempVocabularies.push(data);

      this.setState(
        {
          vocabularies: tempVocabularies,
        },
        () => {
          if (selectType === "productCategorySelect") {
            this._onSubpartChange(
              data,
              currentNode,
              currentNodePath,
              currentNodeKey
            );
          } else if (selectType === "productTagSelect") {
            const tags = currentNode.tags;
            tags.push(data.value);
            this._onTagsChange(
              { value: tags } as VocabularyDataDropdownProps,
              currentNode,
              currentNodePath,
              currentNodeKey
            );
          }
        }
      );
    }
  };

  _onSubpartChange = (
    { value }: DropdownProps,
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    if (value !== "Not-found-value") {
      const { vocabularies } = this.state;
      const addedItem = value as string;
      let updatedNode = node;
      updatedNode.value = value as string;

      if (value) {
        const matchedVocabularyIndex = this.getMatchedVocabularyIndex(
          vocabularies,
          addedItem
        );
        if (matchedVocabularyIndex !== false && matchedVocabularyIndex > -1) {
          const matchedVocabulary = {
            ...vocabularies[matchedVocabularyIndex as number],
          };
          updatedNode = {
            ...updatedNode,
            id: matchedVocabulary.id,
            selectedSubpartSchema: matchedVocabulary,
            allow_children: matchedVocabulary.allow_children,
            category: matchedVocabulary.category,
            master_display_name: matchedVocabulary.master_display_name,
            helper_display_name: matchedVocabulary.helper_display_name,
            vocab_type: matchedVocabulary.vocab_type,
            seo_scores_volume: matchedVocabulary.seo_scores_volume,
            type: matchedVocabulary.type,
            setting_schema: matchedVocabulary.setting_schema,
            settings: matchedVocabulary.settings,
            tag_id: matchedVocabulary.tag_id,
          };

          if (
            updatedNode.setting_schema &&
            updatedNode.setting_schema.properties &&
            updatedNode.setting_schema.properties.number &&
            updatedNode.setting_schema.properties.number.enum
          ) {
            updatedNode.setting_schema.properties.number.enumNames = updatedNode.setting_schema.properties.number.enum.map(
              (value: string): string => getTagTypeValue(value)
            );
          }

          if (!matchedVocabulary.allow_children) {
            delete updatedNode.children;
          }
        }
      } else {
        updatedNode.value = "";
        updatedNode.master_display_name = "";
        updatedNode.helper_display_name = "";
        updatedNode.vocab_type = "";
        updatedNode.seo_scores_volume = "";
        updatedNode.type = "";
      }
      const subpartOptions = [];
      if (
        updatedNode.selectedSubpartSchema &&
        updatedNode.selectedSubpartSchema.value
      ) {
        const selectedSubpartSchemaValue =
          updatedNode.selectedSubpartSchema.value;
        if (selectedSubpartSchemaValue === updatedNode.value) {
          subpartOptions.push({
            value: updatedNode.value,
            text: getDisplayText(updatedNode),
          });
        }
      }
      updatedNode.subpartOptions = subpartOptions;
      this.updateParentTreeData(updatedNode, path, getNodeKey);
    }
  };

  _onTagsChange = (
    { value }: VocabularyDataDropdownProps,
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    const { vocabularies } = this.state;
    const addedItem = value[value.length - 1];
    const isRemovingTag =
      node.selectedTagSchema?.length >
      value.filter((el) => el != "Not-found-value").length;

    /* Hotfix: Specific case when:
     * - new tag is added to 2nd level of attributes
     * - there's already at least one tag in a subpart
     * - children field in node is empty, but there's selectedTagSchema
     */
    if (
      value.length > 1 &&
      value.length > node.selectedTagSchema?.length &&
      node.children?.length === 0 &&
      node.selectedTagSchema?.length !== 0 &&
      path.length > 1
    ) {
      node.children = [...node.selectedTagSchema];
    }

    node.tags = value;
    if (isRemovingTag) {
      node.children = node.selectedTagSchema.filter((child: TreeData) => {
        return value.includes(child.value);
      });
    } else {
      // Add new tag
      const matchedVocabularyIndex = this.getMatchedVocabularyIndex(
        vocabularies,
        addedItem
      );

      if (matchedVocabularyIndex !== false && matchedVocabularyIndex > -1) {
        const matchedVocabulary = {
          ...vocabularies[matchedVocabularyIndex as number],
        };
        if (node.selectedTagSchema) {
          node.selectedTagSchema.push({ ...matchedVocabulary });
        } else {
          node.selectedTagSchema = [{ ...matchedVocabulary }];
        }
        node.children.push({ ...matchedVocabulary });
      } else {
        // Don't continue if received vocabulary doesn't exist
        // This happens when a word is requested but the word request hasn't been sent yet to the backend
        return;
      }

      node.selectedTagSchema = node.selectedTagSchema.filter(function (
        tag: TreeData
      ) {
        if (tag.value !== "Not-found-value") {
          return node.tags && (node.tags as string[]).includes(tag.value);
        }
      });

      // Remove duplicates
      node.selectedTagSchema = uniqBy(node.selectedTagSchema, "value");

      const tagOptions: TagOption[] = [];
      node.selectedTagSchema.map((tag: TreeData) => {
        const selectedSubpartSchemaValue = tag.value;
        if (
          selectedSubpartSchemaValue !== "Not-found-value" &&
          value &&
          value.includes(selectedSubpartSchemaValue)
        ) {
          tagOptions.push({
            value: selectedSubpartSchemaValue,
            text: getDisplayText(tag),
          });
        }
      });
      node.tagOptions = tagOptions;
    }

    this.updateParentTreeData(node, path, getNodeKey);
  };

  _onAddPressed = (
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    if (node.allow_children) {
      const { treeData, onAttributeChanged } = this.props;
      const { modifiedTreeData } = this.state;
      const newChildNode: VocabularyTreeData = {
        master_display_name: "",
        helper_display_name: "",
        vocab_type: "",
        seo_scores_volume: "",
        category: "identifier",
        type: "kind",
        value: "",
        id: "",
        allow_children: true,
        settings: {},
        setting_schema: null,
        children: [],
        tags: [],
        tagOptions: [],
        selectedTagSchema: [],
        tag_id: null,
      };

      const updatedTreeData = addNodeUnderParent({
        treeData: [...modifiedTreeData],
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey,
        newNode: newChildNode,
      }).treeData;

      const callbackData: TreeData[] = [];
      treeData.forEach((child, index) => {
        const updatedNode = updatedTreeData[index] as VocabularyTreeData;
        if (
          child.children.length ===
          updatedNode.children.length +
            (updatedNode.tags ? updatedNode.tags.length : 0)
        ) {
          callbackData.push(child);
        } else if (
          child.children.length === 0 &&
          updatedNode.children.length > 0
        ) {
          callbackData.push({
            ...child,
            children: updatedTreeData[index].children as VocabularyTreeData[],
            expanded: true,
          });
        } else if (child.children.length > 0) {
          callbackData.push({
            ...child,
            children: [
              {
                ...newChildNode,
              },
              ...child.children,
            ],
            expanded: true,
          });
        }
      });

      onAttributeChanged({ treeData: callbackData });
    }
  };

  _onRemovePressed = (path: NodePath, getNodeKey: GetNodeKeyHandler): void => {
    const { modifiedTreeData } = this.state;
    const removedNode = getNodeAtPath({
      treeData: modifiedTreeData,
      path,
      getNodeKey,
    });

    this.updateParentTreeData(
      removedNode.node as VocabularyTreeData,
      path,
      getNodeKey,
      true
    );
  };

  _onKeydown = (
    e: KeyboardEvent,
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    const { isFocused } = this.state;
    if (isFocused) {
      if (e.shiftKey && e.key === "Enter") {
        this._onAddPressed(node, path, getNodeKey);
      }
      if (e.shiftKey && e.key === "Backspace") {
        this._onRemovePressed(path, getNodeKey);
      }
    }
  };

  // ----------- Settings Modal -------------- //

  openSettingModal = (
    node: VocabularyTreeData,
    path: NodePath,
    getNodeKey: GetNodeKeyHandler
  ): void => {
    this.setState({
      isOpenSettingsModal: true,
      currentNode: node,
      currentNodePath: path,
      currentNodeKey: getNodeKey,
    });
  };

  closeSettingModal = (): void => {
    this.setState({ isOpenSettingsModal: false });
  };

  // used for three dots button
  dotsHandleSchemaSubmit = (settingsFormData: any): void => {
    const { currentNode, currentNodePath, currentNodeKey } = this.state;
    this.handleSchemaSubmit(
      settingsFormData,
      currentNode,
      currentNodePath,
      currentNodeKey
    );
  };

  // used for dropdown menu
  dropdownHandleSchemaSubmit = (
    settingsFormData: any,
    currentNode: TreeItem,
    currentNodePath: NodePath,
    currentNodeKey: GetNodeKeyHandler
  ): void => {
    this.handleSchemaSubmit(
      settingsFormData,
      currentNode,
      currentNodePath,
      currentNodeKey
    );
  };

  handleSchemaSubmit = (
    settingsFormData: any,
    currentNode: TreeItem,
    currentNodePath: NodePath,
    currentNodeKey: GetNodeKeyHandler
  ): void => {
    const updatedNode = {
      ...currentNode,
      settings: settingsFormData,
    } as VocabularyTreeData;

    this.updateParentTreeData(updatedNode, currentNodePath, currentNodeKey);
    this.closeSettingModal();
  };

  getCountedNodeHeight = ({
    selectedTagSchema,
    rowHeight,
    tagHeight,
  }: {
    selectedTagSchema: TreeData[];
    rowHeight: number;
    tagHeight: number;
  }): number => {
    if (selectedTagSchema?.length) {
      let count = 0;
      for (const currentTag of selectedTagSchema) {
        const displayedName = getDisplayText(currentTag) || "";
        if (displayedName && displayedName.length > 100) {
          count += (displayedName.length / 60) * tagHeight;
        } else if (displayedName && displayedName.length > 30) {
          count += (displayedName.length / 30) * tagHeight * 1.3;
        } else {
          count += tagHeight;
        }
      }
      if (count > rowHeight) {
        return count;
      }
    }
    return rowHeight;
  };

  getVisibleNodeHeight = ({ treeData }: FullTree): number => {
    const nodeHeight = 70;
    const tagHeight = 50;
    const traverse = (node: VocabularyTreeData): number => {
      const nodeTags = node.tags?.length ?? 0;
      const rowHeight = nodeTags > 0 ? nodeTags * tagHeight : nodeHeight;
      if (
        !node.children ||
        node.expanded !== true ||
        typeof node.children === "function"
      ) {
        return this.getCountedNodeHeight({
          selectedTagSchema: node.selectedTagSchema,
          rowHeight,
          tagHeight,
        });
      }
      return (
        this.getCountedNodeHeight({
          selectedTagSchema: node.selectedTagSchema,
          rowHeight,
          tagHeight,
        }) +
        node.children.reduce(
          (total: number, currentNode: VocabularyTreeData) =>
            total + traverse(currentNode),
          0
        )
      );
    };

    return treeData.reduce(
      (total: number, currentNode: VocabularyTreeData) =>
        total + traverse(currentNode),
      0
    );
  };

  render(): React.ReactElement {
    const {
      modifiedTreeData,
      currentNode,
      currentNodePath,
      isOpenSettingsModal,
      isFetchingSearchResults,
      isFetchingSearchResultsTags,
    } = this.state;
    const getNodeKey: GetNodeKeyHandler = ({ treeIndex }) => treeIndex;
    const externalNodeType = "yourNodeType";
    const canDrop = ({
      nextPath,
    }: OnDragPreviousAndNextLocation & NodeData): boolean => {
      return nextPath.length <= 2;
    };
    return (
      <StyledDiv
        className={"tree_view_table hide-placeholder"}
        hideDefaultOptionButton={true}
      >
        <SortableTree<SortableTreeProps>
          theme={CustomTheme as any}
          className={"treeViewBox advanced-child-tree"}
          style={{
            height: this.getVisibleNodeHeight({ treeData: modifiedTreeData }),
          }}
          dndType={externalNodeType}
          maxDepth={50}
          shouldCopyOnOutsideDrop={false}
          treeData={modifiedTreeData}
          canDrop={canDrop}
          onChange={(): void => null}
          onMoveNode={this._onMoveNode}
          placeholderRenderer={null}
          generateNodeProps={({
            node,
            path,
          }: {
            node: VocabularyTreeData;
            path: NodePath;
          }): { [index: string]: any } => ({
            className: "custom_class",
            buttons: [
              <i
                key={7}
                className="material-icons sortable-handle-child"
                style={{
                  fontSize: "1.2rem",
                }}
              >
                drag_indicator
              </i>,
              <IndexedDropdown
                id="ProductSubpartSelect"
                className="treeview_dropdown"
                name="subpart"
                placeholder="Type to add..."
                noResultsMessage="Start typing to get suggestions"
                key={1}
                closeOnBlur={true}
                closeOnChange
                disabled={false}
                fluid
                loading={isFetchingSearchResults && path === currentNodePath}
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                onChange={(e, obj): void =>
                  this._onSubpartChange(obj, node, path, getNodeKey)
                }
                onFocus={(): void => this.setState({ isFocused: true })}
                onBlur={(): void => this.setState({ isFocused: false })}
                onKeyDown={(e: KeyboardEvent): void =>
                  this._onKeydown(e, node, path, getNodeKey)
                }
                options={
                  node.subpartOptions && node.subpartOptions[0]?.value
                    ? node.subpartOptions
                    : []
                }
                search={(options): DropdownItemProps[] => options}
                onSearchChange={debounce(
                  (
                    e: React.SyntheticEvent,
                    obj: DropdownOnSearchChangeData
                  ): void =>
                    this.handleTreeInputChange(obj, node, path, getNodeKey),
                  300
                )}
                style={{
                  maxHeight: "16px",
                }}
                value={node.value}
              />,
              node && (
                <ProductSettingSchemaField
                  key={2}
                  node={node}
                  onChange={(data: ProductSettingSchemaFieldDataType): void =>
                    this.dropdownHandleSchemaSubmit(
                      data,
                      node,
                      path,
                      getNodeKey
                    )
                  }
                  onFocus={(): void => this.setState({ isFocused: true })}
                  onBlur={(): void => this.setState({ isFocused: false })}
                  onKeyDown={(e: KeyboardEvent): void =>
                    this._onKeydown(e, node, path, getNodeKey)
                  }
                />
              ),
              <IndexedDropdownLessPadding
                id="ProductSubpartTagSelect"
                className={
                  "treeview_dropdown, multiple-treeview_dropdown alt-style-labels"
                }
                name="tags"
                key={3}
                fluid
                selection
                multiple
                disabled={false}
                loading={
                  isFetchingSearchResultsTags && path === currentNodePath
                }
                selectOnNavigation={false}
                selectOnBlur={false}
                closeOnBlur={true}
                closeOnChange
                options={node.tagOptions ? node.tagOptions : []}
                value={node.tags ? (node.tags as string[]) : []}
                placeholder="Type to add..."
                noResultsMessage="Start typing to get suggestions"
                onChange={(
                  e: React.SyntheticEvent<HTMLElement>,
                  obj: VocabularyDataDropdownProps
                ): void => this._onTagsChange(obj, node, path, getNodeKey)}
                onFocus={(): void => this.setState({ isFocused: true })}
                onBlur={(): void => this.setState({ isFocused: false })}
                onKeyDown={(e: KeyboardEvent): void =>
                  this._onKeydown(e, node, path, getNodeKey)
                }
                search={(options: DropdownItemProps[]): DropdownItemProps[] =>
                  options
                }
                onSearchChange={debounce(
                  (
                    e: React.SyntheticEvent<HTMLElement>,
                    obj: DropdownOnSearchChangeData
                  ) => this.handleTreeInputChange(obj, node, path, getNodeKey),
                  300
                )}
              />,
              path.length === 1 && node.allow_children ? (
                <i
                  tabIndex={0}
                  key={4}
                  className={"add circle icon"}
                  title="Add Child"
                  color={node.allow_children ? "primary" : "disabled"}
                  style={{
                    padding: "12px",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={(): void =>
                    this._onAddPressed(node, path, getNodeKey)
                  }
                  onKeyDown={(e: KeyboardEvent<Element>): void =>
                    e.key === "Enter" &&
                    this._onAddPressed(node, path, getNodeKey)
                  }
                >
                  {" "}
                </i>
              ) : null,
              <i
                tabIndex={0}
                key={5}
                className={"delete icon"}
                title="Remove Child"
                color="primary"
                style={{
                  paddingTop: "12px",
                  width: "25px",
                  height: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={(): void => this._onRemovePressed(path, getNodeKey)}
                onKeyDown={(e: KeyboardEvent<Element>): void =>
                  e.key === "Enter" && this._onRemovePressed(path, getNodeKey)
                }
              >
                {" "}
              </i>,
            ],
          })}
        />

        {isOpenSettingsModal && (
          <SettingsModal
            closeSettingModal={this.closeSettingModal}
            currentNode={currentNode}
            handleFormJsonSchemaSubmit={this.dotsHandleSchemaSubmit}
            isOpenSettingsModal={isOpenSettingsModal}
          />
        )}
      </StyledDiv>
    );
  }
}

export const ProductTagsSortableTreeComp = connector(ProductTagsSortableTree);
