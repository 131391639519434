import React from "react";
import { Loader } from "semantic-ui-react";
import {
  SemanticSIZES,
  SemanticTEXTALIGNMENTS,
} from "semantic-ui-react/dist/commonjs/generic";
import styled from "styled-components";

const StyledDiv = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    &:after {
      border-color: #767676 #ddd #ddd;
    }
  }
`;

type Props = {
  size?: SemanticSIZES;
  align?: SemanticTEXTALIGNMENTS;
  inverted?: Boolean;
  content?: React.ReactNode;
};

export const Spinner: React.FC<Props> = ({
  size,
  align,
  inverted,
  content,
}) => {
  let flexAlignment;
  switch (align) {
    case "left":
      flexAlignment = "flex-start";
      break;
    case "right":
      flexAlignment = "flex-end";
      break;
    default:
      flexAlignment = "center";
  }
  return (
    <StyledDiv data-testid="spinner" style={{ alignItems: flexAlignment }}>
      <StyledLoader
        active
        inline
        size={size}
        inverted={inverted}
        content={content}
      />
    </StyledDiv>
  );
};

Spinner.defaultProps = {
  size: "tiny",
  align: "center",
};
