import React, { Dispatch, SetStateAction } from "react";
import { Checkbox, CheckboxProps, Form } from "semantic-ui-react";
import { Text } from "../../../components/Text";
type Props = {
  testId?: string;
  caption?: string;
  label: string;
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
};
export const PromptBooleanField: React.FC<Props> = ({
  testId,
  caption,
  label,
  value,
  setValue,
}) => (
  <Form.Field>
    <label htmlFor={testId}>{label}</label>
    {caption && (
      <Text color="grey" className="descriptive-helper-text">
        {caption}
      </Text>
    )}
    <Checkbox
      id={testId}
      data-testid={testId}
      slider
      checked={value}
      onClick={(
        e: React.FormEvent<HTMLInputElement>,
        { checked }: CheckboxProps
      ): void => {
        setValue(checked);
      }}
    />
  </Form.Field>
);
