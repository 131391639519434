import { useEffect, useState } from "react";

export type UseUrlState<T> = {
  setParam: (key: keyof T, value: number | string) => void;
  removeParam: (key: keyof T) => void;
  paramExists: (key: keyof T) => boolean;
  urlState: Partial<T>;
};

type UseUrlStateDefaultType = { [index: string]: number | string };

export function useUrlState<T extends UseUrlStateDefaultType>(
  initialState: Partial<T> = {} as Partial<T>
): UseUrlState<T> {
  const [urlState, setUrlState] = useState<Partial<T>>(initialState);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const ignoreKeys = ["jwt", "autologin", "xid", "bucket"];

  useEffect(() => {
    if (Object.keys(urlState).length > 0) {
      Object.keys(urlState).forEach((key) => {
        if (paramExists(key)) return; // Don't overwrite existing params on initial render
        setParam(key, urlState[key]);
      });
    }
    const newUrlState: UseUrlStateDefaultType = {};

    urlParams.forEach((value, key) => {
      if (ignoreKeys.includes(key)) return;
      newUrlState[key] = parseValue(value);
    });
    setUrlState(newUrlState as T);
  }, []);

  function setParam(key: keyof T, value: number | string): void {
    if (value === undefined) return;
    setUrlState((prevState) => ({ ...prevState, [key]: value }));
    urlParams.set(key.toString(), value.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
  }

  function removeParam(key: keyof T): void {
    setUrlState((prevState) => {
      const newState = { ...prevState };
      delete newState[key];
      return newState;
    });
    urlParams.delete(key.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
  }

  function parseValue(value: string): number | string {
    const n = parseInt(value);
    return Number.isFinite(n) ? n : value;
  }

  function paramExists(key: keyof T): boolean {
    return urlParams.has(key.toString());
  }

  return { setParam, removeParam, paramExists, urlState };
}
