import React from "react";
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  defaultGetNodeKey,
  ExtendedNodeData,
  FullTree,
  getDepth,
  getNodeAtPath,
  GetNodeKeyFunction,
  getVisibleNodeCount,
  NodeData,
  OnDragPreviousAndNextLocation,
  OnMovePreviousAndNextLocation,
  OnVisibilityToggleData,
  removeNodeAtPath,
  TreeItem,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { SettingsModal } from "./SettingsModal";
import { VocabularyLookupComp } from "./VocabularyLookup";
import { Icon } from "semantic-ui-react";
import { ProductId } from "../products/product";
import {
  GetNodeKeyHandler,
  TreeData,
  VocabularyTreeData,
} from "../utils/tagutils";
import { LanguageCode } from "../customers/customerlanguages";
import { DirectionProperty } from "csstype";
import { connect, ConnectedProps } from "react-redux";
import { RootState, store } from "../utils/store";
import { Customer } from "../customers/Customer";
import { getCustomer } from "../api/customerApi";
import { SectionAction, SectionActionRequest } from "../api/sectionActions";
import { setHighlightedTag } from "../api/productDetailEnrichTabSlice";
import { TagTreeEditorRecommendationContainer } from "./TagTreeEditorRecommendationContainer";
import { fetchProductRecommendations } from "../api/action";
import { ProductRecommendations } from "../products/edit-tab/ProductRecommendations";
import CustomTheme from "../reactSortableTreeTheme/index";

const dummyVocabulary: VocabularyTreeData = {
  allow_children: true,
  category: "",
  children: [],
  helper_display_name: "",
  id: "",
  master_display_name: "",
  seo_scores_volume: "",
  settings: {},
  setting_schema: null,
  value: "",
  type: "",
  vocab_type: "",
};

const getVisibility = (isVisible: boolean): string =>
  isVisible ? "visible" : "hidden";

export type NodePath = Array<string | number>;

export interface SortableTreeProps extends VocabularyTreeData {
  id: string;
  _source?: string;
  master_display_name: string;
  old_display_name?: string;
}

type Props = {
  alwaysTranslate?: boolean;
  disableDrag?: boolean;
  initialData: VocabularyTreeData[];
  onDataChanged?: (
    component: TagTreeEditor,
    treeData: VocabularyTreeData[],
    updateAfterChange: boolean
  ) => void;
  includeSeo?: boolean;
  manageWithClick?: boolean;
  language?: string;
  lexiconEditorDirection?: DirectionProperty;
  limit?: number;
  mappingMode?: boolean;
  showMachineTranslate?: boolean;
  showHypernym?: boolean;
  small?: boolean;
  textualAppName: string;
  updateAfterChange?: boolean;
  useLexiconEditor?: boolean;
  vocabularyRequestAllowed?: boolean;
  vocabularyRequestProductId?: ProductId;
  vocabularyRequestProductDescription?: string;
  /** when set, expands all items when initially empty (default false)  */
  expandedWhenEmpty?: boolean;
  onSectionItemAction?: (request: SectionActionRequest) => Promise<void>;
};

export interface TagTreeEditorRecommendations {
  root_tag: VocabularyTreeData;
  root_tag_path: NodePath;
  tags: ProductRecommendations;
}

type State = {
  currentNode?: VocabularyTreeData;
  currentNodePath?: NodePath;
  currentNodeKey?: GetNodeKeyFunction;
  isOpenSettingsModal: boolean;
  isTreeDataValidate: boolean;
  treeData: VocabularyTreeData[];
  recommendations: TagTreeEditorRecommendations;
  isSearching: boolean;
};

const mapStateToProps = (
  state: RootState
): {
  token: string | null;
  customer: Customer | null;
  highlightedTagId: string | null;
} => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
  /** Current customer */
  customer: getCustomer(store.getState()),
  highlightedTagId: state.productDetailEnrichTab.highlightedTagId,
});

const connector = connect(mapStateToProps, null);

type TagTreeEditorProps = ConnectedProps<typeof connector> & Props;

export default class TagTreeEditor extends React.Component<
  TagTreeEditorProps,
  State
> {
  static defaultProps: Partial<TagTreeEditorProps> = {
    expandedWhenEmpty: false,
    updateAfterChange: true,
    manageWithClick: false,
    small: false,
  };

  constructor(props: TagTreeEditorProps) {
    super(props);
    const { initialData } = this.props;
    this.state = {
      isOpenSettingsModal: false,
      isTreeDataValidate: false,
      treeData: initialData,
      recommendations: null,
      isSearching: false,
    };
  }

  componentDidMount(): void {
    const { mappingMode, expandedWhenEmpty } = this.props;
    const { treeData } = this.state;
    // Initialize the component with dummy vocabulary to avoid clicking the add button unnecessarily
    if (
      expandedWhenEmpty &&
      (mappingMode || (!mappingMode && treeData.length === 0))
    ) {
      this.addFirstNode();
    }
  }

  generateVocabulary = (): void => {
    const { treeData } = this.state;
    const { onDataChanged, updateAfterChange } = this.props;

    const treeStateData = JSON.parse(JSON.stringify(treeData));
    const filterEmptyTreeData = treeStateData.filter(function f(
      o: VocabularyTreeData
    ): boolean | number {
      if (!o.value) return true;
      if (o.children) {
        return (o.children = o.children.filter(f)).length;
      }
    });
    const isTreeDataValidate = !filterEmptyTreeData.length;
    this.setState({ isTreeDataValidate }, () => {
      if (isTreeDataValidate) {
        onDataChanged?.(this, treeData, updateAfterChange);
      }
    });
  };

  addRecommendation = (node: VocabularyTreeData, path: NodePath): any => {
    const { customer } = this.props;

    if (customer?.config.show_tagtree_recommendations && node?.allow_children) {
      const { token, vocabularyRequestProductId } = this.props;
      fetchProductRecommendations({
        token,
        productId: vocabularyRequestProductId,
        vocabId: Number(node.id),
        useVocabAsKind: true,
      }).then((fetchedRecommendations) => {
        this.setState({
          recommendations: {
            root_tag: node,
            root_tag_path: path,
            tags: fetchedRecommendations,
          },
        });
      });
    }
  };

  addNode = (node: VocabularyTreeData, path: NodePath): void => {
    if (node.allow_children) {
      const { treeData } = this.state;

      const newChildNode = { ...dummyVocabulary };
      this.setState(
        {
          treeData: changeNodeAtPath({
            treeData,
            path: path,
            getNodeKey: defaultGetNodeKey,
            newNode: node,
          }) as VocabularyTreeData[],
        },
        () => {
          this.setState(
            {
              treeData: addNodeUnderParent({
                treeData: [...treeData],
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: defaultGetNodeKey,
                newNode: { ...newChildNode },
              }).treeData as VocabularyTreeData[],
            },
            () => {
              this.generateVocabulary();
            }
          );
          this.addRecommendation(node, path);
        }
      );
    }
  };

  removeNode = (path: NodePath): void => {
    const {
      highlightedTagId,
      manageWithClick,
      onSectionItemAction,
    } = this.props;
    const { treeData } = this.state;
    if (manageWithClick) {
      const tag_id = treeData[0]._id;
      const getNodeKey: GetNodeKeyHandler = ({ treeIndex }) => treeIndex;
      const removedNode = getNodeAtPath({
        treeData: treeData,
        path,
        getNodeKey,
      }).node as VocabularyTreeData;

      if (tag_id === highlightedTagId) {
        store.dispatch(setHighlightedTag(null));
      }

      onSectionItemAction?.({
        action: SectionAction.ITEM_REMOVE,
        tag_id: removedNode._subpart_id || removedNode._id,
      });
    } else {
      this.setState(
        {
          treeData: removeNodeAtPath({
            treeData,
            path,
            getNodeKey: defaultGetNodeKey,
          }) as VocabularyTreeData[],
          recommendations: null,
        },
        () => {
          this.generateVocabulary();
        }
      );
    }
  };

  openSettingModal = (node: TreeItem, path: NodePath): void => {
    this.setState({
      isOpenSettingsModal: true,
      currentNode: node as VocabularyTreeData,
      currentNodePath: path,
      currentNodeKey: defaultGetNodeKey,
    });
  };

  onDragStateChanged = ({ isDragging }: { isDragging: boolean }): void => {
    const { treeData } = this.state;
    const { onDataChanged, updateAfterChange } = this.props;
    if (!isDragging) {
      onDataChanged(this, treeData, updateAfterChange);
    }
  };

  closeSettingModal = (): void => {
    this.setState({ isOpenSettingsModal: false });
  };

  handleSchemaSubmit = (settingsFormData: any): void => {
    const { onSectionItemAction, vocabularyRequestProductId } = this.props;
    const {
      treeData,
      currentNode,
      currentNodePath,
      currentNodeKey,
    } = this.state;
    onSectionItemAction?.({
      action: SectionAction.ITEM_ADD_SETTINGS_NUMBER,
      product: vocabularyRequestProductId,
      settings_number_data: settingsFormData,
      subpart_tag_id: currentNode._subpart_id,
      tag_id: currentNode._id,
    });
    const updatedNode = {
      ...currentNode,
      settings: settingsFormData,
    };

    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData,
          path: currentNodePath,
          getNodeKey: currentNodeKey,
          newNode: updatedNode,
        }) as VocabularyTreeData[],
      },
      () => {
        this.generateVocabulary();
        this.closeSettingModal();
      }
    );
  };

  canDrop = ({
    node,
    nextParent,
    nextPath,
  }: OnDragPreviousAndNextLocation & NodeData): boolean => {
    const parentData = nextParent as TreeData;
    const nodeData = node as TreeData;
    if (parentData && !parentData.allow_children) {
      return false;
    }
    const disableDrop =
      nextPath &&
      nextPath.length === 1 &&
      nodeData.category !== "identifier" &&
      nodeData.type !== "kind";
    return !disableDrop;
  };

  canDrag = ({ parentNode }: ExtendedNodeData<{}>): boolean => {
    const { isSearching } = this.state;
    const { disableDrag } = this.props;
    if (disableDrag) {
      return false;
    }
    const canDrag = !isSearching && !!parentNode;
    return canDrag;
  };

  onChangeVocabulary = (
    newNode: TreeItem,
    path: NodePath,
    getNodeKey: GetNodeKeyFunction
  ): void => {
    const { treeData } = this.state;
    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData,
          path,
          getNodeKey,
          newNode,
        }) as VocabularyTreeData[],
      },
      () => {
        this.generateVocabulary();
      }
    );
  };

  onVisibilityToggle = (
    treeData: TreeItem[],
    node: TreeItem,
    expanded: boolean,
    path: NodePath
  ): void => {
    const updatedNode = { ...node, expanded };
    this.setState({
      treeData: changeNodeAtPath({
        treeData,
        path,
        getNodeKey: defaultGetNodeKey,
        newNode: updatedNode,
      }) as VocabularyTreeData[],
    });
  };

  addFirstNode = (): void => {
    const { treeData } = this.state;
    this.setState({ treeData: treeData.concat(dummyVocabulary) });
  };

  showBarsIcon = (node: TreeItem<SortableTreeProps>): boolean => {
    const { manageWithClick } = this.props;
    if (manageWithClick) {
      return getDepth(node) > 0;
    }
    return true;
  };

  getSortableTreeClassName = (node: TreeItem<SortableTreeProps>): string => {
    const { highlightedTagId, manageWithClick } = this.props;
    let sortableClassName = "";

    if (node["_source"]) {
      sortableClassName += `source-${node["_source"]} `;
    }

    if (manageWithClick && node["_id"] === highlightedTagId) {
      sortableClassName += "highlight_selected ";
    }
    return sortableClassName;
  };

  showRecommendation = (): boolean => {
    const { recommendations } = this.state;
    if (!recommendations) return false;
    const {
      fieldRecommendations,
      seoRecommendations,
      subpartRecommendations,
    } = recommendations.tags;
    for (const value of Object.values(fieldRecommendations)) {
      if (value.length) {
        return true;
      }
    }
    return !!(seoRecommendations.length || subpartRecommendations.length);
  };
  _onMoveNode = ({
    treeData,
  }: NodeData & FullTree & OnMovePreviousAndNextLocation): void => {
    this.setState({ treeData: treeData as VocabularyTreeData[] });
  };
  renderSortableTree(): React.ReactElement {
    const { treeData } = this.state;
    const {
      limit,
      language,
      includeSeo,
      vocabularyRequestAllowed,
      textualAppName,
      vocabularyRequestProductDescription,
      vocabularyRequestProductId,
      mappingMode,
      useLexiconEditor,
      lexiconEditorDirection,
      showHypernym,
      manageWithClick,
      alwaysTranslate,
      showMachineTranslate,
      small,
    } = this.props;
    const count = getVisibleNodeCount({ treeData });
    return (
      <div
        data-testid={"rendered-sortable-tree"}
        className="rendered-sortable-tree"
        style={
          small
            ? { height: count * 68 + 50, width: 500 }
            : { height: count * 68 + 50, width: 800 } // These widths are resized in the template tab. see function "resizeAllTagTrees"
        }
      >
        <SortableTree<SortableTreeProps>
          theme={CustomTheme as any}
          rowHeight={40}
          treeData={treeData}
          shouldCopyOnOutsideDrop={false}
          canDrop={this.canDrop}
          canDrag={this.canDrag}
          dndType={"yourNodeType"}
          onDragStateChanged={this.onDragStateChanged}
          onMoveNode={this._onMoveNode}
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={(): void => {}}
          generateNodeProps={({
            node,
            path,
            ...rest
          }): {
            buttons: JSX.Element[];
            className: string;
          } => ({
            className: this.getSortableTreeClassName(node),
            buttons: [
              this.canDrag({ node, path, ...rest }) && (
                <i
                  key={node.id}
                  className="material-icons sortable-handle-child"
                >
                  drag_indicator
                </i>
              ),
              <VocabularyLookupComp
                alwaysTranslate={alwaysTranslate}
                getNodeKey={defaultGetNodeKey}
                headline=""
                showVocabType={false}
                includeSeo={includeSeo}
                initialData={node}
                isMultiple={false}
                isUsedInTagTreeEditor={true}
                key={node.id}
                language={language as LanguageCode}
                lexiconEditorDirection={lexiconEditorDirection}
                limit={limit}
                manageWithClick={manageWithClick}
                mappingMode={mappingMode}
                node={node}
                onChangeVocabulary={this.onChangeVocabulary}
                path={path as number[]}
                showHypernym={showHypernym}
                showMachineTranslate={showMachineTranslate}
                tagCategory=""
                tagType=""
                textualAppName={textualAppName}
                treeData={treeData}
                useLexiconEditor={useLexiconEditor}
                vocabularyRequestAllowed={vocabularyRequestAllowed}
                vocabularyRequestProductDescription={
                  vocabularyRequestProductDescription
                }
                vocabularyRequestProductId={vocabularyRequestProductId}
                setIsSearching={(searching: boolean): void =>
                  this.setState({ isSearching: searching })
                }
              />,
              <Icon
                id="add_setting"
                key={node.id}
                disabled={
                  node.setting_schema === null || node.category === "template"
                }
                name={"ellipsis vertical"}
                color="black"
                title="Add setting details"
                style={{
                  cursor: "pointer",
                  visibility: getVisibility(
                    node.setting_schema !== null && node.category !== "template"
                  ),
                }}
                onClick={(): void => this.openSettingModal(node, path)}
              />,
              !manageWithClick && (
                <Icon
                  key={node.id}
                  data-testid={"tag-tree-editor-add-child"}
                  name={"plus circle"}
                  title="Add child"
                  disabled={!node.allow_children}
                  color={node.allow_children ? "black" : "grey"}
                  style={{
                    cursor: "pointer",
                    visibility: getVisibility(
                      node.allow_children && Object.keys(path).length < 4
                    ),
                  }}
                  onClick={(): void => this.addNode(node, path)}
                />
              ),
              <Icon
                key={node.id}
                name={"x"}
                title="Remove child"
                color="black"
                style={{
                  cursor: "pointer",
                }}
                onClick={(): void => this.removeNode(path)}
              />,
            ],
          })}
          onVisibilityToggle={({
            treeData,
            node,
            expanded,
            path,
          }: OnVisibilityToggleData & { path?: NodePath }): void =>
            this.onVisibilityToggle(treeData, node, expanded, path)
          }
        />
      </div>
    );
  }

  render(): React.ReactElement {
    const {
      treeData,
      currentNode,
      isOpenSettingsModal,
      recommendations,
    } = this.state;
    const { mappingMode, vocabularyRequestProductId } = this.props;
    return (
      <div className="TagTreeEditor" data-testid={"tag-tree-editor"}>
        {(mappingMode || (!mappingMode && treeData.length === 0)) && (
          <div>
            Attributes:
            <i
              className={"add circle icon addSubparts"}
              data-testid={"tag-tree-editor-addSubparts"}
              color="primary"
              title="Add Child"
              data-name="addChild"
              onClick={this.addFirstNode}
            />
          </div>
        )}
        {this.renderSortableTree()}
        {isOpenSettingsModal && (
          <SettingsModal
            closeSettingModal={this.closeSettingModal}
            handleFormJsonSchemaSubmit={this.handleSchemaSubmit}
            currentNode={currentNode}
            isOpenSettingsModal={isOpenSettingsModal}
          />
        )}
        {this.showRecommendation() && (
          <TagTreeEditorRecommendationContainer
            onRemove={(): void => {
              this.setState({ recommendations: null });
            }}
            productId={vocabularyRequestProductId}
            recommendations={recommendations}
          />
        )}
      </div>
    );
  }
}

export const TagTreeEditorComp = connector(TagTreeEditor);
