import React from "react";
import {
  SectionActionRequest,
  SectionDetails,
  SectionItemType,
} from "../../api/sectionActions";
import { Droppable } from "react-beautiful-dnd";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import { Accordion, Icon } from "semantic-ui-react";
import { TemplateSectionItem } from "./TemplateSectionItem";
import { VocabularySectionItem } from "./VocabularySectionItem";

type Props = {
  active: boolean;
  details: ProductDetailInfo | null;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  section: SectionDetails;
  textualAppName: string;
  toggleSectionVisibility: (sectionId: number) => void;
};

export const Section: React.FC<Props> = ({
  active,
  details,
  onSectionItemAction,
  section,
  textualAppName,
  toggleSectionVisibility,
}) => (
  <>
    <Accordion.Title
      active={active}
      data-testid={"section-title"}
      index={section.id}
      onClick={(): void => toggleSectionVisibility(section.id)}
    >
      <Icon name="dropdown" />
      {section.display_name}
    </Accordion.Title>
    <Accordion.Content active={active}>
      <Droppable key={section.id} droppableId={section.name} type="section">
        {(provided): React.ReactElement => (
          <div
            className={"template-list bound"}
            ref={provided.innerRef.bind(provided)}
            {...provided.droppableProps}
            style={{ minHeight: "2em" }}
          >
            {section.items.map((item, index) => {
              if (item.type === SectionItemType.VOCABULARY) {
                return (
                  <VocabularySectionItem
                    key={item.data[0]._id}
                    data={item.data}
                    index={index}
                    onSectionItemAction={onSectionItemAction}
                    section={section}
                  />
                );
              }
              if (item.type === SectionItemType.TEMPLATE) {
                return (
                  <TemplateSectionItem
                    key={item.data.id}
                    data={item.data}
                    details={details}
                    onSectionItemAction={onSectionItemAction}
                    index={index}
                    textualAppName={textualAppName}
                  />
                );
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Accordion.Content>
  </>
);
