import React, { useLayoutEffect, useRef, useState } from "react";
import { Popup, PopupProps, Ref } from "semantic-ui-react";
import { NOOP } from "../..";
import { popupDelay } from "../../customers/gpt/types";

type Props = {
  children: JSX.Element;
  popupProps: PopupProps;
};
/**
 * Use this component if a Popup is needed when using styled-components to style a React component.
 * It fixes the ref to be the actual HTML element and not a React component object.
 */
export const SemanticPopupWithReactRef: React.FC<Props> = ({
  children,
  popupProps,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<Element>();

  const defaultPopupProps: Partial<PopupProps> = {
    wide: true,
    size: "small",
    mouseEnterDelay: popupDelay,
    trigger: <></>,
  };

  const props = {
    ...defaultPopupProps,
    ...popupProps,
  };

  useLayoutEffect(() => {
    let mounted = true;
    let timer: number;
    if (ref.current) {
      ref.current.addEventListener("mouseenter", () => {
        if (timer) clearTimeout(timer);
        timer = (setTimeout(() => {
          if (mounted) setOpen(true);
        }, popupDelay) as unknown) as number;
      });
      ref.current.addEventListener("mouseleave", () => {
        if (timer) clearTimeout(timer);
        if (mounted) setOpen(false);
      });
    }
    return () => {
      mounted = false;
      if (timer) clearTimeout(timer);
    };
  }, [ref.current]);
  return (
    <>
      <Ref innerRef={ref}>
        <Ref innerRef={NOOP}>{children}</Ref>
      </Ref>
      <Popup
        {...props}
        context={ref}
        open={open && !props.disabled}
        data-testid="semantic-popup-with-react-ref"
      />
    </>
  );
};
