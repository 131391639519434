import { useState, useMemo, useEffect, RefObject } from "react";

export function useHandleClickOutside(
  callback: () => void,
  dependencies: React.DependencyList = [],
  ...refs: RefObject<HTMLElement>[]
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const isOutside = refs.every((ref) => {
        return ref.current && !ref.current.contains(event.target as Node);
      });

      if (isOutside) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, dependencies);
}

type Start = number;
type End = number;
type SelectionPosition = [Start, End];
/**
 * Custom hook to manage text selection in an input or textarea element.
 *
 * @param {string} inputValue - The current value of the input or textarea.
 * @returns {Object} An object containing:
 *   - `selectedText`: The currently selected text.
 *   - `selectionPosition`: An array with the start and end indices of the selection.
 *   - `setSelectionPosition`: A function to manually set the selection position.
 *   - `onSelect`: An event handler to update the selection position when text is selected.
 */

export function useSelectedText(
  inputValue: string
): {
  selectedText: string;
  selectionPosition: SelectionPosition;
  setSelectionPosition: (position: SelectionPosition) => void;
  onSelect: (
    event: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement, Event>
  ) => void;
} {
  const [selectionPosition, setSelectionPosition] = useState<SelectionPosition>(
    [0, 0]
  );
  const selectedText = useMemo(() => {
    const [start, end] = selectionPosition;
    if (start === end) return ""; // Nothing is selected
    return inputValue.substring(start, end);
  }, [selectionPosition, inputValue]);

  const onSelect = (
    event: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement, Event>
  ): void => {
    const el = event.target as HTMLTextAreaElement | HTMLInputElement;
    const start = el.selectionStart;
    const end = el.selectionEnd;
    setSelectionPosition([start, end]);
  };

  return {
    selectedText,
    selectionPosition,
    setSelectionPosition,
    onSelect,
  };
}
