import {
  PartialPipeline,
  Pipeline,
  UpdatePipeline,
} from "../products/manage/pipelines/types";
import api from "./api";

export const BASE_URL = "/api/_internal/product/pipeline/";

export async function getPipelines(token: string): Promise<Pipeline[]> {
  const response = await api.get<Pipeline[]>(BASE_URL, { headers: { token } });
  return response.data;
}

export async function createPipeline(
  token: string,
  data: PartialPipeline = {}
): Promise<Pipeline> {
  const response = await api.post<Pipeline>(BASE_URL, data, {
    headers: { token },
  });

  return response.data;
}

export async function updatePipeline(
  token: string,
  data: UpdatePipeline,
  id: number
): Promise<Pipeline> {
  const response = await api.put<Pipeline>(`${BASE_URL}${id}/`, data, {
    headers: { token },
  });

  return response.data;
}

export async function deletePipeline(
  token: string,
  id: number
): Promise<unknown> {
  const response = await api.delete(`${BASE_URL}${id}/`, {
    headers: { token },
  });
  return response.data;
}

export async function reorderSteps(
  token: string,
  pipelineId: number,
  oldIndex: number,
  newIndex: number
): Promise<unknown> {
  const response = await api.patch(
    `${BASE_URL}${pipelineId}/reorder-steps`,
    { old_index: oldIndex, new_index: newIndex },
    { headers: { token } }
  );

  return response.data;
}

export async function deleteStep(
  token: string,
  pipelineId: number,
  deleteAtIndex: number
): Promise<unknown> {
  const response = await api.delete(
    `${BASE_URL}${pipelineId}/delete-step/${deleteAtIndex}`,
    { headers: { token } }
  );
  return response.data;
}
