import React from "react";
import { PrefillCategory } from "./PrefillFromCategoryModalToolbar";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  & {
    font-weight: 600 !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: none !important;
  }
  &&& {
    color: rgba(0, 0, 0) !important;
    opacity: 1 !important;
  }
`;
const StyledIcon = styled(Icon)`
  & {
    font-size: 0.9em !important;
  }
`;

type Props = {
  category: PrefillCategory;
  onClick?: () => void;
};

export const CategoryItem: React.FC<Props> = ({ category, onClick }) => {
  return (
    <>
      <StyledButton data-testid={"category-item"} basic disabled size="small">
        {category.name}
      </StyledButton>
      <StyledIcon
        className={"times circle link icon"}
        data-testid={"close-button"}
        onClick={onClick}
        size={"small"}
      />
    </>
  );
};
