import React, { useMemo } from "react";
import { useGetViewSetsQuery } from "../../../api/viewSetApi";
import { Text } from "../../../components/Text";
import { StyledViewSetButton, ViewId } from "./ViewSetsFilterContainer";
import { ProductListAction } from "../ProductList";
import { ProductListParams } from "../ProductListURLConfig";

type Props = {
  viewId?: ViewId;
  onProductListAction: (
    action: ProductListAction,
    value: ProductListParams
  ) => void;
};
export const FavoriteViews: React.FC<Props> = ({
  viewId,
  onProductListAction,
}) => {
  const { data: viewSets, isLoading } = useGetViewSetsQuery();

  const favoriteViews = useMemo(() => {
    if (!viewSets || isLoading) return [];
    return viewSets
      .flatMap(({ views }) => views.filter(({ favorite }) => favorite))
      .sort((a, b) => {
        if (a.display_name < b.display_name) {
          return -1;
        }
        if (a.display_name > b.display_name) {
          return 1;
        }
        return 0;
      });
  }, [isLoading, viewSets]);

  if (!favoriteViews.length) return <></>;
  return (
    <>
      <Text size="small" testId="favorites-views-header">
        Favorite filters:
      </Text>
      {favoriteViews.map((view) => (
        <StyledViewSetButton
          className={viewId === view.id ? "active" : ""}
          data-testid={`activate-favorite-view-${view.id}`}
          compact
          size="tiny"
          basic
          key={view.id}
          content={view.display_name}
          onClick={(): void => {
            onProductListAction(ProductListAction.UseViewSet, view.parameters);
          }}
        />
      ))}
    </>
  );
};
