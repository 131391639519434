import { ViewSet, ViewSetId } from "./ViewSetsFilterContainer";
import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import {
  Dropdown,
  Input,
  Icon,
  Button,
  TextArea,
  InputOnChangeData,
} from "semantic-ui-react";
import { onKeyUp } from "../../../utils/semanticInputUtils";
import styled from "styled-components";

export const buttonTextColor = "#202020 !important;";

export const StyledDropdownBase = styled(Dropdown)`
  &&& {
    color: ${buttonTextColor}
    margin-left: 0.3rem;
    .menu {
      z-index: 3;
      padding-bottom: 5px;
    }
  }
  &:hover,
  &:focus {
    border: none !important;
    opacity: 1 !important;
  }
`;

const StyledDropdownBaseZIndex = Styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ nested, ...rest }) => <StyledDropdownBase {...rest} />
)`
&&& {
  display: ${(p): string => (p.nested ? "flex" : "inherit")} !important;
  margin: ${(p): string => (p.nested ? "0" : "0 5px")};
  z-index: 3;
  & .menu { 
    margin-left: 0 !important;
  }
}
`;

export const Overlay = Styled.div`
  &.show {
    position: fixed;
    top: 0;
    left 0;
    width: 100vw;
    height: 100vh;  
    z-index: 3;
  }
  &.hide {
    display:none;
  }
`;

const StyledDropdownItem = Styled(Dropdown.Item)`
&&&& {
  .menu {
  top: 100% !important;
  margin: 0 !important;
  }
}
`;

const FlexDropdown = Styled(Dropdown)`
  display: flex;
`;

const DropdownIcon = Styled(Icon)`
  position: absolute;
  top: 0 !important;
  right: 0 !important;
`;

type OptionState = {
  [index: number]: number;
  key: number;
  text: string;
  value: string;
};

type Props = {
  nestedDropdown?: boolean;
  onViewSetCreate: (displayName: string) => void;
  onViewCreate: (displayName: string, viewSetId: ViewSetId) => void;
  viewSets: ViewSet[];
};

export const ViewSetsCreateNewFilterDropdown: React.FC<Props> = ({
  nestedDropdown = false,
  viewSets,
  onViewSetCreate,
  onViewCreate,
}) => {
  const [filterName, setFilterName] = useState("");
  const [filterGroup, setFilterGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionState[]>([]);
  const mounted = useRef(true);
  const spaceBar = 32;

  useEffect(() => {
    mounted.current = true;
    const mapViewSetsToDropDownItems: any = [];
    viewSets.map((el) => {
      return mapViewSetsToDropDownItems.push({
        key: el.id,
        value: el.display_name,
        text: el.display_name,
      });
    });
    if (mounted.current) {
      setOptions(mapViewSetsToDropDownItems);
    }
    return (): void => {
      mounted.current = false;
    };
  }, [viewSets]);

  const onAddItem = (): void => {
    const groupExists = viewSets.find(
      ({ display_name }) => display_name === filterGroup.trim()
    );
    if (!groupExists && filterGroup) onViewSetCreate(filterGroup.trim());
  };

  const onClickCreate = (): void => {
    const selectedGroup = viewSets.find(
      ({ display_name }) => display_name === filterGroup.trim()
    );
    onViewCreate(filterName, selectedGroup.id);
    setFilterGroup("");
    setFilterName("");
    setOpen(false);
  };

  const onKeyUpFilterGroupVariant = (e: React.KeyboardEvent): void => {
    if (e.keyCode === spaceBar) {
      setFilterGroup(filterGroup + " ");
      e.stopPropagation();
    }
  };

  return (
    <>
      <Overlay
        onClick={(): void => setOpen(false)}
        className={open ? "show" : "hide"}
      />
      <StyledDropdownBaseZIndex
        className={"tiny"}
        basic
        button={!nestedDropdown}
        item={nestedDropdown}
        nested={nestedDropdown}
        data-testid={"product-filter-saveas-button"}
        text={"Save as"}
        onClick={(): void => setOpen(!open)}
        open={open}
        closeOnBlur={false}
      >
        <Dropdown.Menu
          onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
        >
          <Dropdown.Header>Filter Group</Dropdown.Header>
          <StyledDropdownItem>
            <FlexDropdown
              data-testid="viewsets-create-new-filter-dropdown-filter-group-name"
              allowAdditions
              direction={"left"}
              fluid
              icon={<DropdownIcon name={"dropdown"} rotated={"clockwise"} />}
              onAddItem={onAddItem}
              onChange={(
                e: React.ChangeEvent<TextArea>,
                { value }: InputOnChangeData
              ): void => {
                setFilterGroup(value);
              }}
              onKeyUp={onKeyUpFilterGroupVariant}
              onSearchChange={(
                e: React.ChangeEvent<TextArea>,
                { searchQuery }: InputOnChangeData
              ): void => {
                setFilterGroup(searchQuery);
              }}
              options={options}
              placeholder={"Choose Group"}
              search
              selection
              searchQuery={filterGroup}
              value={filterGroup}
            />
          </StyledDropdownItem>
          <Dropdown.Divider />
          <Dropdown.Header>Filter Name</Dropdown.Header>
          <Dropdown.Item>
            <Input
              data-testid="viewsets-create-new-filter-dropdown-filter-name-input"
              placeholder={"Filter name..."}
              onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
              onChange={(e, { value }): void => {
                setFilterName(value);
              }}
              onKeyUp={onKeyUp}
              value={filterName}
            />
          </Dropdown.Item>
          {filterName && filterGroup && (
            <Dropdown.Item>
              <Button
                data-testid="viewsets-create-new-filter-dropdown-create-button"
                compact
                className={"pbutton pbutton-primary"}
                content={"Create Filter"}
                fluid
                onClick={onClickCreate}
                size={"mini"}
              />
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </StyledDropdownBaseZIndex>
    </>
  );
};
