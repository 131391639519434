import {
  TagCategoryTypeMap,
  TemplateField,
  TemplateFieldTypeMap,
} from "../../template";

export const TagTypeModifiersTable: {
  [key: string]: string[];
} = {
  "material/any": ["tag_totext"],
  "material/kind": ["tag_tokind", "tag_totext"],
  "identifier/kind": ["tag_tokind", "tag_totext"],
  others: ["tag_totext"],
};

export const getTagTypeModifiers = (tagType: string): string => {
  if (Object.keys(TagTypeModifiersTable).includes(tagType)) {
    return `| ${TagTypeModifiersTable[tagType].join(" | ")}`;
  }
  return `| ${TagTypeModifiersTable.others.join(" | ")}`;
};

export const friendlyTogglableModifiers = {
  capitalize: "Capitalize",
  lower: "lowercase",
  upper: "UPPERCASE",
  title_html: "Title Case",
};
export const shortFriendlyTogglableModifiers: { [key: string]: string } = {
  capitalize: "T",
  lower: "tt",
  upper: "TT",
  title_html: "Tt",
};
export const getTogglableModifiersInitState = (
  modifiers: string[],
  tagType?: TemplateField["tag"]
): { [key: string]: boolean } => {
  const tagTypeIsMaterial = TagCategoryTypeMap?.[tagType] === "Material";
  const state: { [key: string]: boolean } = {
    capitalize: false,
    lower: false,
    upper: false,
    title_html: false,
  };
  if (tagTypeIsMaterial) {
    state["tag_tokind"] = false;
  }
  modifiers.forEach((modifier) => {
    const modifierState = state?.[modifier.trim()];
    if (modifierState !== undefined) {
      state[modifier.trim()] = true;
    }
  });

  return state;
};

export const translateModifierState = (state: {
  [key: string]: boolean;
}): { add: string[]; remove: string[] } => {
  const add: string[] = [];
  const remove: string[] = [];
  Object.entries(state).forEach(([key, value]) => {
    if (value) {
      add.push(key);
    } else {
      remove.push(key);
    }
  });
  return { add, remove };
};

export const disableModifierState = (
  variableType: TemplateField["type"]
): boolean => {
  const disableForTypes = ["Integer", "Float", "Boolean"];
  const type = TemplateFieldTypeMap?.[variableType];
  if (!type) return false;

  return disableForTypes.includes(type);
};

export const DRAGOVER_PADDING_ELEMENT_SELECTOR = "drag-padding";
export const DRAGOVER_CLASS_LIST_PADDING = ["txu-drag-over-padding"];

export const resetAllDragClasses = (element: Element): void => {
  const dragoverPaddingElement = element.querySelectorAll(
    `#${DRAGOVER_PADDING_ELEMENT_SELECTOR}`
  );
  dragoverPaddingElement.forEach((paddingElement) => {
    paddingElement.classList.remove(...DRAGOVER_CLASS_LIST_PADDING);
  });
};

export const switchDragIndicatorToThisElement = (
  dragOverElement: Element,
  toTheLeft: boolean,
  wrapperElement: Element
): void => {
  const droppableElement = findClosestDroppableElement(
    dragOverElement,
    toTheLeft,
    wrapperElement
  );
  const allActivePaddingElements = wrapperElement.querySelectorAll(
    `.${DRAGOVER_CLASS_LIST_PADDING[0]}`
  );
  allActivePaddingElements.forEach((paddingElement) => {
    paddingElement.classList.remove(...DRAGOVER_CLASS_LIST_PADDING);
  });
  if (droppableElement === wrapperElement) {
    const allPaddingElements = wrapperElement.querySelectorAll(
      `#${DRAGOVER_PADDING_ELEMENT_SELECTOR}`
    );
    const lastPaddingElement =
      allPaddingElements[allPaddingElements.length - 1];
    if (!lastPaddingElement) return;
    lastPaddingElement.classList.add(...DRAGOVER_CLASS_LIST_PADDING);
    return;
  }

  const newActivePaddingElement: Element = toTheLeft
    ? droppableElement?.previousElementSibling
    : droppableElement?.nextElementSibling;

  if (!newActivePaddingElement) return;
  newActivePaddingElement.classList.add(...DRAGOVER_CLASS_LIST_PADDING);
};

export const findClosestDroppableElement = (
  eventTarget: Element,
  toTheLeft: boolean,
  wrapperElement: Element
): Element => {
  const searchDepth = 5;
  let currentTestedDepth = 0;
  let current = eventTarget;
  if (current.classList.contains("first-padding")) {
    return current?.nextElementSibling;
  }
  while (currentTestedDepth < searchDepth) {
    if (current?.getAttribute("data-node-type") || !current) {
      break;
    }
    if (toTheLeft) {
      current = current?.nextElementSibling;
    } else {
      current = current?.previousElementSibling;
    }
    currentTestedDepth++;
  }
  return current || wrapperElement;
};

export const prepareNewVariable = (
  variableKey: string,
  availableVariables: TemplateField[]
): Element | undefined => {
  const variable = availableVariables.find(({ key }) => variableKey === key);
  if (!variable) return;
  const displayValue = variable.key;
  let value = `{{ ${variable.key} `;
  if (variable.tag) {
    value += getTagTypeModifiers(variable.tag);
  }
  value += " }}";
  const newVariable = document.createElement("button");
  newVariable.setAttribute("data-node-type", "variable");
  newVariable.setAttribute("data-node-value", value);
  newVariable.innerText = displayValue;
  return newVariable;
};
