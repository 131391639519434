"use strict";
import $ from "jquery";

// Highlight related `data-vocab-id` elements on hover
$(document).on(
  {
    mouseenter: function () {
      const el = $(this);
      const vocabIds = String(el.data("vocab-id")).split(" ");
      const tagValues = String(el.data("tag-value")).split(" ");
      el.addClass("highlight");
      vocabIds.forEach((id) => {
        $(`[data-vocab-id~="${id}"]`).addClass("highlight");
      });
      tagValues.forEach((value) => {
        $(`[data-tag-value~="${value}"]`).addClass("highlight");
      });
    },
    mouseleave: function () {
      $("[data-vocab-id], [data-tag-value]").removeClass("highlight");
    },
  },
  "[data-vocab-id], [data-tag-value]"
);

// Fade out tag mappings on hover
$(document).on(
  {
    mouseenter: function () {
      $(".mapped-mappings").addClass("fade");
    },
    mouseleave: function () {
      $(".mapped-mappings").removeClass("fade");
    },
  },
  ".text-preview, .mapped-mappings"
);

$("#id_prompt").on("change", function ({ currentTarget }) {
  const selectedValue = currentTarget.value;
  const promptGroup = $("#id_prompt_group");
  const promptGroupValue = promptGroup.val();
  if (selectedValue && promptGroupValue) {
    promptGroup.val("");
  }
});
$("#id_prompt_group").on("change", function ({ currentTarget }) {
  const selectedValue = currentTarget.value;
  const prompt = $("#id_prompt");
  const promptValue = prompt.val();
  if (selectedValue && promptValue) {
    prompt.val("");
  }
});

$("i.upload-instructions-help-text").on({
  mouseenter: function ({ currentTarget }) {
    const helpText = $(".help.show-on-hover");
    const elementWidth = $(currentTarget).outerWidth();
    const helpTextWidth = $(helpText).outerWidth();
    $(helpText).css({
      position: "absolute",
      right: "-" + (helpTextWidth - elementWidth) - 25 + "px",
      top: "15px",
    });

    helpText.addClass("show");
    helpText.removeClass("hidden");
  },
  mouseleave: function () {
    const helpText = $(".help.show-on-hover");
    helpText.addClass("hidden");
    helpText.removeClass("show");
  },
});

$("#id_type_of_upload").on("click", function ({ currentTarget }) {
  const children = $(currentTarget).children();
  const checked = children.find("input[type=radio]:checked");
  if (checked.length) {
    const value = checked.val();
    const generateAISettings = $(".generate-ai-settings");
    const runPipelineSettings = $(".run-pipeline-settings");

    if (value === "PIPELINE") {
      runPipelineSettings.removeClass("hidden");
      runPipelineSettings.addClass("show");
    } else {
      runPipelineSettings.removeClass("show");
      runPipelineSettings.addClass("hidden");
    }

    if (value === "AI") {
      generateAISettings.removeClass("hidden");
      generateAISettings.addClass("show");
    } else {
      generateAISettings.removeClass("show");
      generateAISettings.addClass("hidden");
    }
  }
});

$(function () {
  const reference = $(".generate-ai-settings");
  const referenceWidth = reference?.outerWidth();
  const form = $(".select-actions-after-file-upload");
  const currentWidth = form.width();
  if (referenceWidth > currentWidth) {
    form.css({
      width: referenceWidth + "px",
    });
  }
});
