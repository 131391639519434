import React, { useState } from "react";
import { Button, Message } from "semantic-ui-react";
import styled from "styled-components";

const SaveForAllLangBtn = styled(Button)`
  &&& {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: unset;
  }
`;

const AbsoluteMessage = styled(Message)`
  && {
    position: absolute;
    bottom: calc(100% - 15px);
    width: max-content;
  }
`;
type Props = {
  name: string;
  value: string;
  type: string;
  hasMultipleTranslations: string;
};
export const SaveForAllLanguagesButton: React.FC<Props> = ({
  name,
  value,
  type,
  hasMultipleTranslations,
}) => {
  const [hidden, setHidden] = useState(true);
  return (
    <>
      <AbsoluteMessage floating error hidden={hidden} size="mini" attached>
        <Message.Header>
          This will override the other translations
        </Message.Header>
        <p>Are you sure you want to do that?</p>
        <Button
          size="tiny"
          content="Yes"
          type={type}
          name={name}
          value={value}
          color="red"
        />
        <Button
          size="tiny"
          content="No"
          onClick={(): void => setHidden(true)}
          basic
          color="red"
          type="button"
        />
      </AbsoluteMessage>
      <SaveForAllLangBtn
        basic
        color="red"
        className="pbutton pbutton-inverse"
        content={"Save for all languages"}
        type={hasMultipleTranslations ? "button" : type}
        name={name}
        value={value}
        onClick={(): void => hasMultipleTranslations && setHidden(false)}
      />
    </>
  );
};
