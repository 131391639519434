import React from "react";
import { Form, Input } from "semantic-ui-react";
import { ProductListAction } from "./ProductList";
import { ProductListParams } from "./ProductListURLConfig";
import { useDispatch } from "react-redux";
import { setCustomQuery } from "../../api/productListSlice";
import { setCustomQueryContent } from "./CustomQueryHint";
import { useGetCustomerQuery } from "../../api/customerApi";
import { hasActiveFilters } from "./utils";
import { StyledViewSetButton } from "./viewsets/ViewSetsFilterContainer";

type Props = {
  searchQuery: string;
  onAction: (action: ProductListAction, value?: unknown) => void;
  currentParameters: ProductListParams;
};

export const ProductListSearchbar: React.FC<Props> = ({
  searchQuery,
  onAction,
  currentParameters,
}) => {
  const dispatch = useDispatch();
  const { data: customer, isLoading } = useGetCustomerQuery();

  return (
    <div data-testid="product-list-search-bar">
      <Form
        data-testid="product-list-search-bar-form"
        size="tiny"
        onSubmit={(e): void => {
          const target = e.target as typeof e.target & {
            search: { value: string };
          };
          onAction(
            ProductListAction.UpdateSearchBar,
            target.search.value || ""
          );
        }}
      >
        <Form.Group inline size="tiny">
          <Form.Field width={5}>
            <Input
              autoFocus
              key={searchQuery ? "active" : "inactive"}
              defaultValue={searchQuery}
              data-testid="searchQuery"
              placeholder="Search SKU, EAN or any word…"
              name="search"
              action={{
                size: "tiny",
                type: "submit",
                color: "red",
                icon: "search",
                content: "Search",
                ["data-testid"]: "searchButton",
              }}
            />
          </Form.Field>
          <Form.Field style={{ paddingLeft: "10px" }}>
            {hasActiveFilters(customer, currentParameters) && (
              <StyledViewSetButton
                loading={isLoading}
                type="button"
                size="tiny"
                basic
                icon="eraser"
                data-testid="product-list-search-bar-clear-filter-button"
                content="Clear filters"
                onClick={(): void => {
                  onAction(ProductListAction.Reset, currentParameters);
                  dispatch(
                    setCustomQuery(
                      setCustomQueryContent({ customer, query: "" })
                    )
                  );
                }}
              />
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};
