import React from "react";
import { List, Rail, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { ProductText } from "./ProductText";
import { CustomerChannel } from "../customers/Customer";

const StyledRail = styled(Rail)`
  &&&& {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }
`;

type Props = {
  channel: CustomerChannel;
  productText: ProductText;
};

export const ProductTextDetailsRail: React.FC<Props> = ({
  channel,
  productText,
}) => {
  return (
    <StyledRail internal position={"right"}>
      <Segment compact>
        <List size={"small"}>
          <List.Item>
            <b>Product</b>: {productText.productId}
          </List.Item>
          <List.Item>
            <b>Language</b>: {productText.languageCode}
          </List.Item>
          <List.Item>
            <b>Channel</b>: {channel.display_name}
          </List.Item>
        </List>
      </Segment>
    </StyledRail>
  );
};
