import React from "react";
import styled, { StyledComponent } from "styled-components";
import { LanguageCode } from "../customers/customerlanguages";

export const DefaultParagraph = styled.p``;
export const DefaultHeadingH5 = styled.h5``;

type TableRowContent = {
  as?: string & StyledComponent<React.ComponentType, any>;
  column1: string;
  column2?: LanguageCode | number | string;
};

type Props = {
  description: string;
  header: string;
  name: string;
  tableContent: TableRowContent[];
};

export const CustomQueryRenderTable: React.FC<Props> = ({
  description,
  header,
  name,
  tableContent,
}) => {
  const renderTable = (): JSX.Element => {
    const renderRows = tableContent.map(({ as, column1, column2 }) => {
      const ElementType = as;
      return (
        <div className={"detail-row"} key={column1}>
          <ElementType>{column1}</ElementType>
          <ElementType>{column2}</ElementType>
        </div>
      );
    });

    return (
      <div className={"table"} data-testid={`customer-${name}-table`}>
        {renderRows}
      </div>
    );
  };

  return (
    <div className={name}>
      <h2>{header}</h2>
      <p>{description}</p>
      {renderTable()}
    </div>
  );
};
