import React from "react";
import { CustomQueryLanguagesSection } from "./CustomQueryLanguagesSection";
import { CustomQueryChannelSection } from "./CustomQueryChannelSection";

export const CustomQueryChannelLanguage: React.FC = () => {
  return (
    <>
      <div className={"customer-details"}>
        <CustomQueryLanguagesSection />
        <CustomQueryChannelSection />
      </div>
    </>
  );
};
