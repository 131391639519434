import React, { useMemo } from "react";
import { Popup, PopupProps } from "semantic-ui-react";

import { useGetCustomerQuery } from "../api/customerApi";
import {
  Button,
  Props as ButtonProps,
} from "../components/tailwind/button/Button";
import { TranslateButton } from "../components/TranslateButton";
import { LanguageCode } from "../customers/customerlanguages";
import { popupDelay } from "../customers/gpt/types";
import { getFeatureFlag } from "../utils/featureFlags";

type Props = Omit<ButtonProps, "onClick"> & {
  onTranslate: (languageCode: LanguageCode, configGroupId?: number) => void;
  languageCode: LanguageCode;
  popupProps?: PopupProps;
  initialMTConfigGroupId?: number;
};

export const StatelessTranslateButton: React.FC<Props> = ({
  onTranslate,
  languageCode,
  popupProps = { disabled: true },
  initialMTConfigGroupId,
  ...rest
}) => {
  const { data: customer } = useGetCustomerQuery();
  const ffNewTranslateButton = useMemo(() => {
    return getFeatureFlag(customer, "machine_translation_settings");
  }, [customer]);

  return (
    <Popup
      mouseEnterDelay={popupDelay}
      {...popupProps}
      trigger={
        ffNewTranslateButton ? (
          <TranslateButton
            onClick={(event, configGroupId): void =>
              onTranslate(languageCode, configGroupId)
            }
            initialConfigGroupId={initialMTConfigGroupId}
            {...rest}
          />
        ) : (
          <Button onClick={(): void => onTranslate(languageCode)} {...rest} />
        )
      }
    />
  );
};
