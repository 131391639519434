import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";
import { RewritePrompt } from "../products/copy-assistant/types";

export const rewritePromptApi = createApi({
  reducerPath: "rewritePromptApi",
  tagTypes: ["rewritePrompt"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/gpt/",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    getRewritePrompts: build.query<RewritePrompt[], never>({
      query: () => "rewrite-prompt",
      providesTags: (result) => {
        if (!result) {
          return [{ type: "rewritePrompt", id: "LIST" }];
        }
        return [
          ...result.map(({ id }) => ({
            type: "rewritePrompt" as const,
            id,
          })),
          { type: "rewritePrompt", id: "LIST" },
        ];
      },
    }),
  }),
});

export const { useGetRewritePromptsQuery } = rewritePromptApi;
