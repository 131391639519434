import React from "react";
import { Icon } from "../icon/Icon";

export type Props = React.HTMLAttributes<HTMLElement> & {
  content: React.ReactNode;
  onClick?: () => void;
  clearable?: boolean;
  active?: boolean;
};
export const Badge: React.FC<Props> = ({
  content,
  clearable,
  active,
  onClick,
  ...rest
}) => {
  return (
    <span
      className={`tw-inline-flex tw-w-max tw-items-center tw-gap-2 tw-rounded-md tw-px-2 tw-py-1 tw-text-xs hover:tw-shadow-md tw-transition-all ${
        active
          ? "tw-bg-textual-blue tw-text-white"
          : "tw-bg-gray-300 tw-text-black"
      } `}
      {...rest}
    >
      <span className="tw-cursor-default">{content}</span>{" "}
      {clearable && (
        <Icon
          name="close"
          className="tw-cursor-pointer tw-rounded-md tw-text-base tw-leading-[1] tw-transition-all hover:tw-scale-105 hover:tw-outline"
          onClick={onClick}
        />
      )}
    </span>
  );
};
