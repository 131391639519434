import React, { useMemo, useState } from "react";
import { Button, Icon, List, Modal, Popup } from "semantic-ui-react";
import { ManageTable } from "../../../components/manage/ManageTable";
import {
  Prompt,
  PromptFormMode,
  PromptGroup,
} from "../../../products/copy-assistant/types";
import { groupPromptGroupsByTags } from "../../../products/copy-assistant/utils";
import { PromptGroupForm } from "../../../products/copy-assistant/PromptGroupForm";
import { Text } from "../../../components/Text";
import { PromptForm } from "../../../products/copy-assistant/PromptForm";

type Props = {
  prompts: Prompt[];
  groups: PromptGroup[];
  reFetchPrompts: () => Promise<void>;
  reFetchGroups: () => Promise<void>;
};
export const ManagePromptGroupList: React.FC<Props> = ({
  prompts,
  groups,
  reFetchPrompts,
  reFetchGroups,
}) => {
  const [selectedPromptGroup, setSelectedPromptGroup] = useState<PromptGroup>();
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt>();
  const [promptFormMode, setPromptFormMode] = useState<PromptFormMode>(
    PromptFormMode.CREATE
  );
  const [openModal, setOpenModal] = useState(false);
  const [openPromptModal, setOpenPromptModal] = useState(false);

  const customerPrompts = useMemo(() => {
    return prompts.filter(({ customer_id }) => customer_id);
  }, [prompts]);

  const promptGroupOrderedByTags = useMemo(() => {
    if (!groups) return {};
    const copy = [...groups];
    const ordered = groupPromptGroupsByTags(copy);
    return ordered;
  }, [groups]);

  const availableTags = useMemo(() => Object.keys(promptGroupOrderedByTags), [
    promptGroupOrderedByTags,
  ]);

  const refreshPromptGroupList = async (): Promise<void> => {
    setSelectedPromptGroup(undefined);
    await reFetchGroups();
    await reFetchPrompts();
    setOpenModal(false);
    setOpenPromptModal(false);
  };

  const tableHeaders = ["Prompt Group", "Prompts", "Actions"];
  const tableBody = (): { id: number; elements: JSX.Element }[] =>
    groups.map((group) => {
      const promptsInGroup = customerPrompts.length
        ? group.prompt_ids.map((id) =>
            customerPrompts.find((prompt) => prompt.id === id)
          )
        : [];
      return {
        id: group.id,
        elements: (
          <>
            {group.name.length >= 70 ? (
              <Popup
                flowing
                content={group.name}
                size="tiny"
                mouseEnterDelay={200}
                trigger={
                  <td>
                    <Text compact>{group.name.substring(0, 40)}...</Text>
                  </td>
                }
              />
            ) : (
              <td>
                <Text compact>{group.name}</Text>
              </td>
            )}
            <td>
              <List ordered divided>
                {promptsInGroup.map((prompt) => (
                  <List.Item key={prompt.id}>
                    {prompt.name.length >= 70 ? (
                      <Popup
                        flowing
                        content={prompt.name}
                        size="tiny"
                        mouseEnterDelay={200}
                        trigger={
                          <List.Content floated="left">
                            {prompt.name.substring(0, 40)}...
                          </List.Content>
                        }
                      />
                    ) : (
                      <List.Content floated="left">{prompt.name}</List.Content>
                    )}
                    <List.Content floated="right">
                      <Popup
                        content={`Prompt ${prompt.name} setting`}
                        size="tiny"
                        trigger={
                          <Icon
                            name="setting"
                            color="red"
                            link
                            onClick={(): void => {
                              setSelectedPrompt(prompt);
                              setOpenPromptModal(true);
                              setOpenModal(false);
                            }}
                          />
                        }
                      />
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </td>
            <td>
              <Button.Group size="mini">
                <Popup
                  content="Copy Group"
                  size="small"
                  trigger={
                    <Button
                      basic
                      size="mini"
                      color="red"
                      icon="copy"
                      content="Copy"
                      onClick={(): void => {
                        setSelectedPromptGroup(group);
                        setPromptFormMode(PromptFormMode.COPY);
                        setOpenModal(true);
                      }}
                    />
                  }
                />
                <Popup
                  content="Group settings"
                  size="small"
                  trigger={
                    <Button
                      basic
                      size="mini"
                      color="red"
                      icon="setting"
                      content="Edit"
                      onClick={(): void => {
                        setSelectedPromptGroup(group);
                        setPromptFormMode(PromptFormMode.UPDATE);
                        setOpenModal(true);
                      }}
                    />
                  }
                />
              </Button.Group>
            </td>
          </>
        ),
      };
    });

  return (
    <>
      <ManageTable
        hasData={!!groups.length}
        noDataMessage="No Prompt Groups found"
        headers={tableHeaders}
        body={tableBody()}
        additionButton={
          <Button
            color="red"
            basic
            size="small"
            onClick={(): void => {
              setOpenModal(true);
              setPromptFormMode(PromptFormMode.CREATE);
            }}
          >
            Add Prompt Group
          </Button>
        }
        showAdditionButtonWithNoData
      />
      <Modal open={openModal} onClose={(): void => setOpenModal(false)}>
        {[PromptFormMode.UPDATE, PromptFormMode.COPY].includes(
          promptFormMode
        ) ? (
          <PromptGroupForm
            prompts={customerPrompts}
            mode={promptFormMode}
            isLoading={false}
            selectedPromptGroup={selectedPromptGroup}
            availableTags={availableTags}
            setOpenModal={setOpenModal}
            reFetchGroups={refreshPromptGroupList}
            selectGroup={setSelectedPromptGroup}
          />
        ) : (
          <PromptGroupForm
            isLoading={false}
            prompts={customerPrompts}
            mode={PromptFormMode.CREATE}
            availableTags={availableTags}
            setOpenModal={setOpenModal}
            reFetchGroups={refreshPromptGroupList}
            selectGroup={setSelectedPromptGroup}
          />
        )}
      </Modal>
      <Modal
        open={openPromptModal}
        onClose={(): void => setOpenPromptModal(false)}
      >
        <PromptForm
          mode={PromptFormMode.UPDATE}
          prompt={selectedPrompt}
          availableTags={availableTags}
          setOpenModal={setOpenModal}
          refreshPromptList={refreshPromptGroupList}
        />
      </Modal>
    </>
  );
};
