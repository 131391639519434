/* eslint-disable react/no-array-index-key */
import React from "react";
import throttle from "lodash/throttle";
import { useKeyDown, keyCode } from "../../ts/hooks";

interface FluentProps {
  name?: string;
  initialValue?: string;
  token?: string;
  textualAppName?: string;
}

interface TextualApiConfig {
  token: string;
  appName: string;
}

interface Suggestion {
  search: string;
  completion: string;
  tags: string;
}

interface FluentState {
  open: boolean;
  value: string;
  previousValue: string;
  suggestions: Array<Suggestion>;
  selectedSuggestion: number;
  shouldUpdateSuggestions: boolean;
  tags: string;
}

type SuggestionResponse = {
  text: string;
  tags: string;
  updated: boolean;
};

const updateCompletions = async (
  value: string | null,
  config: TextualApiConfig
): Promise<Array<Suggestion>> => {
  if (!value || !value.trim()) {
    return [];
  }
  const params = new URLSearchParams({ q: value });
  const path = `/app/${
    config.appName
  }/1/vocabulary/completions?${params.toString()}`;
  const headers = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set("token", config.token);

  const results = await fetch(path, {
    method: "GET",
    headers: headers,
  });

  if (results.status == 200) {
    const json: SuggestionResponse[] = await results.json();
    return json.map((s) => ({
      search: value,
      completion: s.text.substr(value.length),
      tags: s.tags,
      updated: s.updated,
    }));
  }
  return [];
};

// Reducer for when the user selects a suggestion
const onSuggestionSelect = (state: FluentState): FluentState => {
  const s = state.suggestions[state.selectedSuggestion];
  const value = s ? s.search + s.completion : state.value;
  return {
    ...state,
    previousValue: value,
    value,
    tags: s ? s.tags : null,
  };
};

// Reducer for keyboard arrow press
const onArrowPress = (
  arrow: "up" | "down",
  state: FluentState
): FluentState => {
  if (!state.open) return state;

  const currentIndex = state.selectedSuggestion;
  const maxIndex = state.suggestions.length - 1;
  let newIndex = -1;
  switch (arrow) {
    case "up":
      newIndex = currentIndex <= -1 ? maxIndex : currentIndex - 1;
      break;
    case "down":
      newIndex = currentIndex >= maxIndex ? -1 : currentIndex + 1;
      break;
  }

  return onSuggestionSelect({ ...state, selectedSuggestion: newIndex });
};

export const Fluent: React.FC<FluentProps> = ({
  initialValue,
  name,
  textualAppName,
  token,
}) => {
  const [state, setState] = React.useState<FluentState>({
    open: false,
    value: initialValue || "",
    previousValue: "",
    suggestions: [],
    shouldUpdateSuggestions: false,
    selectedSuggestion: -1,
    tags: null,
  });
  const apiConfig: TextualApiConfig = {
    token: token || "",
    appName: textualAppName || "app",
  };

  const showSuggestions = state.open && state.suggestions.length;

  const throttled = React.useRef(throttle(updateCompletions, 100));

  React.useEffect(() => {
    if (state.shouldUpdateSuggestions) {
      let didCancel = false;
      const fetchData = async (): Promise<void> => {
        const suggestions = await throttled.current(state.value, apiConfig);
        if (!didCancel) {
          setState((s) => ({
            ...s,
            suggestions: suggestions,
            shouldUpdateSuggestions: false,
          }));
        }
      };
      fetchData();
      return (): void => {
        didCancel = true;
      }; // Cancel if component is unmounted
    }
  }, [apiConfig, state.value, state.shouldUpdateSuggestions]);

  useKeyDown(
    keyCode.UP_ARROW,
    React.useCallback(() => {
      setState((s) => onArrowPress("up", s));
    }, [])
  );

  useKeyDown(
    keyCode.DOWN_ARROW,
    React.useCallback(() => {
      setState((s) => onArrowPress("down", s));
    }, [])
  );

  return (
    <div
      className={`fluent-input-component ${showSuggestions ? "open" : ""}`}
      onFocus={(): void => setState({ ...state, open: true })}
      onBlur={(): void => setState({ ...state, open: false })}
    >
      <input type="hidden" name="tags" value={JSON.stringify(state.tags)} />
      <input
        type="text"
        value={state.value}
        name={name}
        autoComplete="off"
        onChange={(e): void =>
          setState({
            ...state,
            previousValue: state?.value,
            shouldUpdateSuggestions: true,
            value: e.target.value,
          })
        }
        onKeyDown={(e): void => {
          if (
            e.keyCode == keyCode.DOWN_ARROW ||
            e.keyCode == keyCode.UP_ARROW
          ) {
            e.preventDefault();
          }
        }}
      />

      <ul className="suggestions">
        {state.suggestions.map((suggestion, i) => (
          <li
            className={i == state.selectedSuggestion ? "selected" : ""}
            onMouseEnter={(): void =>
              setState((s) => ({ ...s, selectedSuggestion: i }))
            }
            onMouseDown={(): void => setState(onSuggestionSelect)}
            key={i}
          >
            {suggestion.search}
            <b>{suggestion.completion}</b>
          </li>
        ))}
      </ul>
      <input
        type="hidden"
        name="updated"
        value={(state.value != state.previousValue).toString()}
      />
    </div>
  );
};
