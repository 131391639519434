import React from "react";
import { Popup, Grid } from "semantic-ui-react";
import { Text } from "../../components/Text";
import { promptSelectionRulesTestSelectors } from "./testUtils/testSelectors";

const { popups } = promptSelectionRulesTestSelectors;

export const ExplainConditions: React.FC = () => (
  <Popup.Content data-testid={popups.conditions.content}>
    <Text size="medium" compact>
      <b>Conditions</b> are used to match Key and Value pairs.
    </Text>
    <Text size="small" color="grey">
      <em>Key</em> is case sensitive
      <br />
      <em>Value</em> is not case sensitive
    </Text>
    <Grid centered divided columns={3}>
      <Grid.Column>
        <Text size="small" compact>
          <b>contains:</b>
          <br />
          <code>
            If <em>Hello</em> contains <em>Wo</em>
          </code>
          <br />
          Would match: <br />
          <code>&#123; Hello: World &#125;</code> <br />
          <code>&#123; Hello: Wombat &#125;</code>
          <br />
          Would <b>Not</b> match: <br />
          <code>&#123; Brand: World &#125;</code> <br />
          <code>&#123; Hello: Alex &#125;</code>
        </Text>
      </Grid.Column>
      <Grid.Column>
        <Text size="small" compact>
          <b>equals:</b>
          <br />
          <code>
            If <em>Hello</em> equals <em>World</em>
          </code>
          <br />
          Would match: <br />
          <code>&#123; Hello: World &#125;</code> <br />
          Would <b>Not</b> match: <br />
          <code>&#123; Hello: Wombat &#125;</code>
          <br />
          <code>&#123; Brand: World &#125;</code> <br />
          <code>&#123; Hello: Alex &#125;</code>
        </Text>
      </Grid.Column>
      <Grid.Column>
        <Text size="small" compact>
          <b>exists:</b>
          <br />
          <code>
            If <em>Hello</em> exists
          </code>
          <br />
          Would match: <br />
          <code>&#123; Hello: World &#125;</code> <br />
          <code>&#123; Hello: Alex &#125;</code>
          <br />
          <code>&#123; Hello: Wombat &#125;</code> <br />
          Would <b>Not</b> match: <br />
          <code>&#123; Brand: World &#125;</code> <br />
        </Text>
      </Grid.Column>
    </Grid>
  </Popup.Content>
);

export const ExplainSettings: React.FC = () => (
  <Popup.Content data-testid={popups.settings.content}>
    <Text size="medium" compact>
      Rule settings
    </Text>
    <Text size="medium">
      We check the <b>conditions</b> you specify here against the{" "}
      <b>Key and Value</b> pairs in a product&apos;s metadata.
    </Text>
    <Text size="medium" compact>
      These <b>conditions</b> are used when picking which prompt should be
      selected when opening the Copy Assistant.
    </Text>
  </Popup.Content>
);

export const ExplainRules: React.FC = () => (
  <Popup.Content data-testid={popups.rules.content}>
    <Text size="medium" compact>
      These are your created <b>rules</b>.
    </Text>
    <Text size="medium">
      Rules are executed in this order, and prompt selection stops at the first
      rule that matches.
    </Text>
    <Text size="medium" compact>
      You can Drag and Drop the <b>rules</b> in the list below to change their
      order.
    </Text>
  </Popup.Content>
);

export const ExplainOperator: React.FC = () => (
  <Popup.Content data-testid={popups.operator.content}>
    <Text size="medium" compact>
      <b>Operator</b>
    </Text>
    <Text size="medium">
      Using <b>Or</b> means that one or more condition needs to be <em>true</em>{" "}
      in order for the rule to match.
    </Text>
    <Text size="medium" compact>
      Using <b>And</b> means that all conditions need to be <em>true</em> in
      order for the rule to match.
    </Text>
  </Popup.Content>
);
