import React, { useMemo, useState } from "react";
import { Divider, Segment } from "semantic-ui-react";
import { Text } from "../components/Text";
import { getDateRangeEnd } from "./PreviouslyMachineTranslatedTextRail";
import { PreviouslyMachineTranslatedTextRailListBundledDate } from "./PreviouslyMachineTranslatedTextRailListBundledDate";
import { TranslationInfo } from "./PreviouslyMachineTranslatedTextRailList";

type Props = {
  header?: string;
  lastDate: Date | undefined;
  dates: Date[];
  setNoReturnCount: () => void;
  incrementInFlightRequest: () => void;
  decrementInFlightRequest: () => void;
  observerLastElementInList: (element: Element) => void;
  setIsLoading: () => void;
  handleUpdateDates: (date: Date, success: boolean) => void;
  loadPreviousTranslation: (info: TranslationInfo) => void;
  searchPrevTranslations: string;
};

export const PreviouslyMachineTranslatedTextRailListBundledDates: React.FC<Props> = ({
  header,
  lastDate,
  dates,
  setNoReturnCount,
  incrementInFlightRequest,
  decrementInFlightRequest,
  observerLastElementInList,
  setIsLoading,
  handleUpdateDates,
  loadPreviousTranslation,
  searchPrevTranslations,
}) => {
  const [amountOfDatesShowing, setAmountOfDatesShowing] = useState<number>(0);
  const [hiddenDates, setHiddenDates] = useState<Set<Date>>(new Set());

  const handleIncrementHiddenDates = (date: Date): void => {
    setHiddenDates((prev) => {
      if (prev.has(date)) return prev;
      const copy = new Set(prev);
      copy.add(date);
      return copy;
    });
  };

  const handleDecrementHiddenDates = (date: Date): void => {
    setHiddenDates((prev) => {
      if (!prev.has(date)) return prev;
      const copy = new Set(prev);
      copy.delete(date);
      return copy;
    });
  };

  const allDatesAreHidden = useMemo(
    () => hiddenDates.size >= amountOfDatesShowing,
    [hiddenDates, amountOfDatesShowing]
  );

  const intersectHandleUpdateDates = (
    date: Date,
    success: boolean,
    doneFetching: boolean
  ): void => {
    if (success && doneFetching) {
      setAmountOfDatesShowing((prev) => prev + 1);
    }
    handleUpdateDates(date, success);
  };
  return (
    <>
      {!!header && !allDatesAreHidden && (
        <>
          <Text lessMargin color="grey">
            {header}
          </Text>
          <Divider fitted hidden />
        </>
      )}
      <Segment.Group
        style={{
          marginBlock: header ? "4px" : "unset",
          visibility: !allDatesAreHidden ? "visible" : "hidden",
        }}
      >
        {dates.map((start, index) => {
          return (
            <PreviouslyMachineTranslatedTextRailListBundledDate
              key={start.toDateString()}
              start={start}
              end={getDateRangeEnd(lastDate)}
              setNoReturnCount={setNoReturnCount}
              incrementInFlightRequest={incrementInFlightRequest}
              decrementInFlightRequest={decrementInFlightRequest}
              observerLastElementInList={observerLastElementInList}
              isLastAdded={index === dates.length - 1}
              handleUpdateDates={intersectHandleUpdateDates}
              setIsLoading={setIsLoading}
              loadPreviousTranslation={loadPreviousTranslation}
              searchPrevTranslations={searchPrevTranslations}
              handleIncrementHiddenDates={handleIncrementHiddenDates}
              handleDecrementHiddenDates={handleDecrementHiddenDates}
            />
          );
        })}
      </Segment.Group>
      {!!header && !allDatesAreHidden && <Divider fitted hidden />}
    </>
  );
};
