import React from "react";
import { Checkbox, Radio, Table } from "semantic-ui-react";

import {
  FooterMatrixType,
  FooterRowCellsUpdateType,
} from "./ChannelLanguageSettingsFooter";

type Props = {
  channelFooterRow: FooterMatrixType;
  disabled: boolean;
  updateFooterCells?: ({
    channel_ids,
    checked,
    radio_button,
    row_name,
  }: FooterRowCellsUpdateType) => void;
};
export const CustomerChannelLanguageFooterRow: React.FC<Props> = ({
  channelFooterRow,
  disabled,
  updateFooterCells,
}) => {
  const FooterRowCell = channelFooterRow.radio_button ? Radio : Checkbox;
  return (
    <>
      {channelFooterRow.channel_details.map((chanelFooterCell) => {
        return (
          <Table.HeaderCell
            collapsing
            textAlign="center"
            data-testid={"footer-checkbox-cell"}
            key={`${channelFooterRow.name}_${chanelFooterCell.channel_id}`}
          >
            <FooterRowCell
              data-radio-button={channelFooterRow.radio_button}
              data-channel-id={chanelFooterCell.channel_id}
              checked={chanelFooterCell.selected}
              disabled={disabled}
              onChange={(e, { checked }): void => {
                updateFooterCells?.({
                  channel_ids: [chanelFooterCell.channel_id],
                  checked,
                  radio_button: channelFooterRow.radio_button,
                  row_name: channelFooterRow.name,
                });
              }}
            />
          </Table.HeaderCell>
        );
      })}
    </>
  );
};
