import { ProductText } from "./ProductText";
import { defaultDict } from "../utils/defaultdict";

const spansVocabRegexp = /<span.*?>.{1,}?<\/span>/gi;
const spanTagsRegexp = /<[/]?span(.*?)>/gi;

export function stripSpans(text: string): string {
  return text.replace(spanTagsRegexp, "");
}

export function collectSpanTags(text: string): Record<string, string> {
  const spans = text.match(spansVocabRegexp) || [];
  const spanTags: Record<string, string> = {};

  for (const span of spans) {
    const vocab = span.replace(spanTagsRegexp, "");
    spanTags[vocab] = span;
  }

  return spanTags;
}

export function applySpanTags(
  text: string,
  spanTags: Record<string, string>
): string {
  // Get vocabulary and sort it descending by string length.
  // It prevents from replacing substrings of a longer strings
  // and thus changing the order of the tags from the original text.
  const vocabKeys = Object.keys(spanTags).sort((a, b) => b.length - a.length);
  const tempMapping: any = {};
  const isNumeric = (s: string): boolean => !isNaN(+s);

  // Prevent from overwriting nested words by setting temporary tags.
  for (let i = 0; i < vocabKeys.length; i++) {
    const vocab = vocabKeys[i];
    // Numeric vocabulary messes up this functionality. It is safer to omit these spans.
    if (isNumeric(vocab)) {
      continue;
    }

    // Escape regex special characters
    const escapedVocab = vocab.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

    const tempTag = "<@" + i + "@>";
    text = text.replace(
      new RegExp("(^|[^A-Za-z0-9])(" + escapedVocab + ")($|[^A-Za-z0-9])", "g"),
      "$1" + tempTag + "$3"
    );
    tempMapping[tempTag] = spanTags[vocab];
  }

  // Replace temporary tags with real span values
  const tempKeys = Object.keys(tempMapping);
  for (const tempKey of tempKeys) {
    text = text.replace(new RegExp(tempKey, "g"), tempMapping[tempKey]);
  }

  // If texts starts or ends with a whitespace, then remove it
  if (text.startsWith(" ")) {
    text = text.slice(1, text.length);
  }
  if (text.endsWith(" ")) {
    text = text.slice(0, text.length - 1);
  }
  return text;
}

export type TextsByLanguage = Record<string, ProductText[]>;

export function groupTextsByLanguages(
  productTexts: ProductText[]
): TextsByLanguage {
  return productTexts.reduce(
    (result, productText) => {
      if (productText.id) {
        result[productText.languageCode].push(productText);
      }
      return result;
    },
    defaultDict<ProductText[]>(() => [])
  );
}
