import React, { Component } from "react";
import { getFluentInputSuggestions } from "../api/vocabularyApi";

export default class FluentInput extends Component {
  mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      words: [],
      errorWords: [],
      currentSpanIndex: 0,

      activeSuggestion: 0,
      suggestions: [],
      filteredSuggestions: [],
      showSuggestions: true,
      loading: false,
    };
  }
  componentDidMount() {
    const { initialValue } = this.props;
    this.setState({
      words: initialValue,
      currentSpanIndex: initialValue.length,
    });
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  // handle suggestion list selection
  handleWordSelection = (e) => {
    // get selected suggestion and text
    const targetSpan = e.target;
    const text = targetSpan.textContent.trim();

    const { onDataChanged } = this.props;
    const { words, errorWords, currentSpanIndex } = this.state;
    const tempWords = [...words];
    const currentSpan = this.fluentInput.getElementsByClassName(
      `span${currentSpanIndex}`
    )[0];

    // add selected suggestion text to word list
    tempWords.splice(currentSpanIndex, 0, text);

    // clean editing span
    currentSpan.innerHTML = "";
    // update words list and hide suggestion list
    this.setState({
      words: tempWords,
      activeSuggestion: 0,
      suggestions: [],
      filteredSuggestions: [],
      showSuggestions: false,
      currentSpanIndex: currentSpanIndex + 1,
    });
    onDataChanged(this, { words: tempWords, errorWords });
    /*
     * select new span
     * setTimeout for words.length - 1 index issue
     */
    setTimeout(() => {
      if (!this.mounted) {
        return;
      }
      this.fluentInput
        .getElementsByClassName(`span${currentSpanIndex + 1}`)[0]
        .focus();
    }, 10);
  };

  // Change cursor by mouse click
  _onMouseDown = (e) => {
    const { onDataChanged } = this.props;
    const {
      words,
      errorWords,
      currentSpanIndex,
      filteredSuggestions,
    } = this.state;
    // get editing span and text
    const currentSpan = this.fluentInput.getElementsByClassName(
      `span${currentSpanIndex}`
    )[0];
    const text = currentSpan.textContent.trim();

    const selectedSpan = e.target;
    const selectedSpanIndex = parseInt(
      selectedSpan.getAttribute("spanindex"),
      10
    );
    if (selectedSpanIndex === -1) {
      return;
    }
    /*
     * if text is empty, just move cursor
     * if text is not empty, add text to words list
     */
    if (text !== "") {
      const tempWords = [...words];
      const tempErrorWords = [...errorWords];

      // add text to words list
      tempWords.splice(currentSpanIndex, 0, text);

      // check if added text is error text
      if (
        filteredSuggestions.length === 0 ||
        !filteredSuggestions.includes(text)
      ) {
        if (!tempErrorWords.includes(text)) {
          tempErrorWords.push(text);
        }
      }

      // clean span and update words state
      currentSpan.innerHTML = "";
      this.setState({
        words: tempWords,
        errorWords: tempErrorWords,
        activeSuggestion: 0,
        showSuggestions: false,
      });
      onDataChanged(this, {
        words: tempWords,
        errorWords: tempErrorWords,
      });

      // if new selected span index is greater than editing span, move cursor to +1 index span
      if (currentSpanIndex < selectedSpanIndex) {
        setTimeout(() => {
          if (!this.mounted) {
            return;
          }
          this.fluentInput
            .getElementsByClassName(`span${selectedSpanIndex + 1}`)[0]
            .focus();
          this.setState({ currentSpanIndex: selectedSpanIndex + 1 });
        }, 10);
      }
    }
    this.setState({ currentSpanIndex: selectedSpanIndex });
  };
  // handle text change
  _onChange = (e) => {
    // get editing span and text, spanIndex
    const targetSpan = e.target;
    const text = targetSpan.textContent.trim();
    const currentSpanIndex = parseInt(targetSpan.getAttribute("spanindex"), 10);

    // if text is not empty, get suggestion list from server
    if (text !== "") {
      const { words } = this.state;
      const tempWords = [...words];
      tempWords.splice(currentSpanIndex, 0, text);

      if (text.length === 1) {
        this.setState({ loading: true });

        let queryParams = "";
        tempWords.forEach((word, index) => {
          queryParams = `${queryParams}${index === 0 ? "?" : "&"}q=${word}`;
        });
        queryParams = `${queryParams}&cursor=${currentSpanIndex}`;

        getFluentInputSuggestions(
          queryParams,
          this.props.token,
          this.props.textualAppName
        ).then((response) => {
          if (!this.mounted) {
            return;
          }
          if (response.data) {
            this.setState({
              activeSuggestion: 0,
              suggestions: response.data,
              filteredSuggestions: response.data,
              showSuggestions: true,
              currentSpanIndex,
              loading: false,
            });
          }
        });
      } else {
        const { suggestions } = this.state;
        // filter suggestion list based on text
        const filteredSuggestions = suggestions
          ? suggestions.filter(
              (suggestion) =>
                suggestion.toLowerCase().indexOf(text.toLowerCase()) === 0
            )
          : [];

        this.setState({
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          currentSpanIndex,
        });
      }
    } else {
      this.setState({
        suggestions: [],
        filteredSuggestions: [],
        showSuggestions: false,
      });
    }
  };
  // handle key press event
  _onKeyDown = (e) => {
    if (!this.mounted) {
      return;
    }
    // get editing span, text, index
    const targetSpan = e.target;
    const text = targetSpan.textContent.trim();
    const spanIndex = parseInt(targetSpan.getAttribute("spanindex"), 10);

    const { onDataChanged } = this.props;
    const {
      words,
      errorWords,
      activeSuggestion,
      filteredSuggestions,
      loading,
    } = this.state;

    const tempWords = [...words];
    const tempErrorWords = [...errorWords];

    if (loading) {
      e.preventDefault();
      return;
    }

    switch (e.keyCode) {
      // if click space || enter, check text and add to words list
      case 32: // space
      case 13: // enter
        e.preventDefault();
        if (text !== "") {
          // if filteredSuggestions exist, select current active suggestion and add to words list
          if (filteredSuggestions.length > 0) {
            tempWords.splice(
              spanIndex,
              0,
              filteredSuggestions[activeSuggestion]
            );
          } else {
            // if not exist, add text to words list as well as errorWords list
            tempWords.splice(spanIndex, 0, text);
            if (!tempErrorWords.includes(text)) {
              tempErrorWords.push(text);
            }
          }

          // clean editing span and update words & errorWords list
          e.target.innerHTML = "";
          this.setState({
            words: tempWords,
            errorWords: tempErrorWords,
            activeSuggestion: 0,
            showSuggestions: false,
            currentSpanIndex: spanIndex + 1,
          });
          onDataChanged(this, {
            words: tempWords,
            errorWords: tempErrorWords,
          });

          /*
           * select new span to edit
           * setTimeout for words.length - 1 index issue
           */
          setTimeout(() => {
            if (!this.mounted) {
              return;
            }
            this.fluentInput
              .getElementsByClassName(`span${spanIndex + 1}`)[0]
              .focus();
          }, 10);
        }
        break;
      case 37: // arrowLeft : if not editing text, move cursor to prev span and update current span index
        if (text.length === 0 && spanIndex > 0) {
          this.fluentInput
            .getElementsByClassName(`span${spanIndex - 1}`)[0]
            ?.focus();
          this.setState({ currentSpanIndex: spanIndex - 1 });
        }
        break;
      case 39: // arrowRight : if not editing text, move cursor to next span and update current span index
        if (text.length === 0 && spanIndex < words.length) {
          this.fluentInput
            .getElementsByClassName(`span${spanIndex + 1}`)[0]
            .focus();
          this.setState({ currentSpanIndex: spanIndex + 1 });
        }
        break;
      case 38: // arrowUp : update suggestion list selection index
        if (activeSuggestion === 0) {
          return;
        }
        this.setState({ activeSuggestion: activeSuggestion - 1 });
        break;
      case 40: // arrowDown : update suggestion list selection index
        if (activeSuggestion - 1 === filteredSuggestions.length) {
          return;
        }
        this.setState({ activeSuggestion: activeSuggestion + 1 });
        break;
      case 8: // backspace : if not editing text, remove previous span
        if (text === "" && spanIndex > 0) {
          tempWords.splice(spanIndex - 1, 1);
          // if word not repeated and exist in error words, remove from errorWords list
          if (!tempWords.includes(text)) {
            const errorWordIndex = tempErrorWords.indexOf(text);
            if (errorWordIndex >= 0) {
              tempErrorWords.splice(errorWordIndex, 1);
            }
          }
          // update words / errorWords
          this.setState({
            words: tempWords,
            errorWords: tempErrorWords,
            currentSpanIndex: spanIndex - 1,
          });
          onDataChanged(this, {
            words: tempWords,
            errorWords: tempErrorWords,
          });

          // change cursor to prev span and timeout for last span issue
          setTimeout(() => {
            if (!this.mounted) {
              return;
            }
            this.fluentInput
              .getElementsByClassName(`span${spanIndex - 1}`)[0]
              .focus();
          }, 10);
        } else if (text.length === 1) {
          // hide suggestion list
          this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
          });
        }
        break;
      default:
        break;
    }
  };

  renderFluentInput() {
    const { words, errorWords, currentSpanIndex } = this.state;

    const jsx = [];
    words.forEach((word, spanIndex) => {
      const className = errorWords.includes(word) ? "error" : "complete";
      jsx.push({ className: "", word: "", spanIndex });
      jsx.push({ className, word, spanIndex: -1 });
    });

    return (
      <div
        data-testid="fluent-input"
        className="input"
        ref={(ref) => {
          this.fluentInput = ref;
        }}
        onKeyDown={this._onKeyDown}
        onInput={this._onChange}
        onMouseDown={this._onMouseDown}
      >
        {jsx.map(({ className, word, spanIndex }, index) => (
          <span
            ref={(ref) => {
              if (currentSpanIndex === spanIndex && ref) {
                this.spanLeftRef = ref;
              }
            }}
            key={index}
            contentEditable={word === ""}
            className={`${className} span${spanIndex}`}
            suppressContentEditableWarning={true}
            // eslint-disable-next-line react/no-unknown-property
            spanindex={spanIndex}
          >
            {word}
          </span>
        ))}
        <span
          ref={(ref) => {
            if (currentSpanIndex === words.length && ref) {
              this.spanLeftRef = ref;
            }
          }}
          contentEditable
          className={`lastSpan span${words.length}`}
          // eslint-disable-next-line react/no-unknown-property
          spanindex={words.length}
        />
      </div>
    );
  }
  renderSuggestions() {
    const {
      words,
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      currentSpanIndex,
    } = this.state;

    if (showSuggestions && filteredSuggestions.length > 0) {
      const options = filteredSuggestions.map((suggestion, index) => {
        let className;
        if (index === activeSuggestion) {
          className = "suggestion-active";
        }
        return (
          <li
            key={index}
            className={className}
            onClick={this.handleWordSelection}
          >
            {suggestion}
          </li>
        );
      });
      let selectionPos = 0;
      if (this.spanLeftRef) {
        const wordWidth =
          currentSpanIndex === words.length
            ? 20
            : this.spanLeftRef.getBoundingClientRect().width;
        selectionPos =
          this.spanLeftRef &&
          this.spanLeftRef.getBoundingClientRect().left +
            wordWidth -
            this.fluentInput.getBoundingClientRect().left -
            10;
      }
      return (
        <ul data-testid="suggestions" style={{ marginLeft: selectionPos }}>
          {options}
        </ul>
      );
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="fluent-input">
        {this.renderFluentInput()}
        {this.renderSuggestions()}
        {loading && (
          <div className="spinner">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
