import React from "react";
import { TagField } from "./TagField";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import { VocabularyTreeData } from "../../utils/tagutils";
import { FieldRecommendation } from "./ProductRecommendations";
import { useGetCustomerQuery } from "../../api/customerApi";

export type FieldType =
  | "color"
  | "designed_in"
  | "made_in"
  | "material"
  | "occasion"
  | "phrase"
  | "position"
  | "properties"
  | "fixed_phrases";

export interface FieldInfo {
  label: string;
  fieldName: FieldType;
  category: string;
  type: string;
}

const FIELDS: FieldInfo[] = [
  {
    label: "Properties",
    fieldName: "properties",
    category: "quality",
    type: "property",
  },
  {
    label: "Material",
    fieldName: "material",
    // FIXME: We should replace this with material/material
    category: "material",
    type: "any",
  },
  {
    label: "Color",
    fieldName: "color",
    category: "quality",
    type: "color",
  },
  {
    label: "Occasion",
    fieldName: "occasion",
    category: "feature",
    type: "perfect_for",
  },
  {
    label: "Made In",
    fieldName: "made_in",
    category: "feature",
    type: "manufactured_in",
  },
  {
    label: "Designed In",
    fieldName: "designed_in",
    category: "feature",
    type: "designed_in",
  },
  {
    label: "Position",
    fieldName: "position",
    category: "phrase",
    type: "position",
  },
  {
    label: "Phrase",
    fieldName: "phrase",
    category: "phrase",
    type: "phrase",
  },
  {
    label: "Fixed phrases",
    fieldName: "fixed_phrases",
    category: "phrase",
    type: "sentence",
  },
];

const clearHeadline = (headline: string): string => {
  switch (headline) {
    // Stand alone phrases were renamed to "fixed phrases"
    case "Stand alone phrases": {
      return "Fixed phrases";
    }
    default:
      return headline;
  }
};

function groupTagsbyHeadline(
  tags: VocabularyTreeData[]
): Record<string, VocabularyTreeData[]> {
  const headlineTags: Record<string, VocabularyTreeData[]> = {};
  tags.map((tag) => {
    if (tag.headline) {
      let { headline } = tag;
      headline = clearHeadline(headline);
      let values: VocabularyTreeData[];
      if (Object.prototype.hasOwnProperty.call(headlineTags, headline)) {
        values = headlineTags[headline];
      } else {
        values = [];
      }
      values.push(tag);
      headlineTags[headline] = values;
    }
  });
  return headlineTags;
}

type Props = {
  details: ProductDetailInfo;
  onTagsAction: onTagsActionCallback;
  selected: SidebarItem;
  fieldRecommendations: Record<string, FieldRecommendation[]>;
};

export const TagFields: React.FC<Props> = ({
  details,
  fieldRecommendations,
  onTagsAction,
  selected,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <div data-testid={"loading"}>Loading...</div>;
  }
  const rows = [];
  const headlineTags = groupTagsbyHeadline(selected.tags || []);
  for (const [i, field] of FIELDS.entries()) {
    const { label, fieldName } = field;
    if (fieldName === "fixed_phrases" && !customer.config.show_fixed_phrases) {
      continue;
    }
    rows.push(
      <div
        key={i}
        className={`row row-${fieldName}`}
        data-testid={"tag-fields"}
      >
        <TagField
          fieldRecommendations={fieldRecommendations[label] || []}
          details={details}
          field={field}
          onTagsAction={onTagsAction}
          selected={selected}
          tags={headlineTags[label] || []}
        />
      </div>
    );
  }
  return <>{rows}</>;
};
