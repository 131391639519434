import React from "react";
import { ProductTagAction } from "../../api/action";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { EditSidebarItem } from "./EditSidebarItem";
import { FieldRecommendations } from "./FieldRecommendations";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { AddSubpartButton } from "./AddSubpartButton";
import { maybeStripSearchVolume } from "./edittabutils";
import { SidebarItem } from "./SidebarItem";
import { ProductRecommendations } from "./ProductRecommendations";
import styled from "styled-components";

export type onSelectedCallback = (selectedSubpart: SidebarItem) => void;

type Props = {
  details: ProductDetailInfo;
  onTagsAction: onTagsActionCallback;
  onSelected: onSelectedCallback;
  recommendations: ProductRecommendations;
  selected: SidebarItem;
};

const RecommendationLabel = styled.div`
  & {
    color: $color_gray;
    display: block;
    font-size: 12pt;
    text-align: center;
    text-transform: uppercase;
  }
`;

/**
 * Shows a list of attributes
 * - main category
 * - subparts, with submenus, see {@link EditSidebarItem}
 * - a button to add a subpart, see {@link AddSubpartButton}
 * - recommendations, see {@link FieldRecommendations}
 * @param props
 * @constructor
 */
export const EditSidebar: React.FC<Props> = ({
  details,
  onSelected,
  onTagsAction,
  recommendations,
  selected,
}) => {
  const { subtreeRecommendations, subpartRecommendations } = recommendations;
  const rootItem = details.getMainCategorySidebarItem();
  const kindProps: Record<string, string> = {};
  let aClasses = undefined;
  if (rootItem.id === selected.id) {
    aClasses = "active";
  }
  if (rootItem.vocabularyIds.length) {
    kindProps["data-vocab-id"] = rootItem.vocabularyIds[0];
  }
  return (
    <>
      <div className={"kind"} {...kindProps}>
        <a className={aClasses} onClick={(): void => onSelected(rootItem)}>
          {maybeStripSearchVolume(rootItem.name) || "product"}
        </a>
      </div>
      <div className={"separator"} />
      <EditSidebarItem
        depth={2}
        details={details}
        item={rootItem}
        onSelected={onSelected}
        onTagsAction={onTagsAction}
        selected={selected}
      />
      <AddSubpartButton
        details={details}
        item={null}
        onTagsAction={onTagsAction}
        toplevel={true}
      />
      <div className={"separator"} />
      <RecommendationLabel>RECOMMENDED</RecommendationLabel>
      <FieldRecommendations
        recommendations={subpartRecommendations}
        onClick={(recommendation): void => {
          onTagsAction({
            action: ProductTagAction.ADD_SUBPART,
            parent: null,
            kind: recommendation.vocabulary_id,
            child: null,
          });
        }}
      />
      {subtreeRecommendations && (
        <>
          <div className="separator" />
          <FieldRecommendations
            recommendations={subtreeRecommendations.filter(
              (r) => "tags" in r && r.tags.length > 0
            )}
            onClick={(recommendation): void => {
              onTagsAction({
                action: ProductTagAction.ADD_SUBPART,
                parent: null,
                kind: recommendation.vocabulary_id,
                child: recommendation.tags[0].vocabulary_id,
              });
            }}
            subtree={true}
          />
        </>
      )}
    </>
  );
};
