import React from "react";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { BalloonDiv } from "../../components/BalloonDiv";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { ProductTagAction } from "../../api/action";
import { SidebarItem } from "./SidebarItem";

interface Props {
  details: ProductDetailInfo;
  selected: SidebarItem;
  onTagsAction: onTagsActionCallback;
}

interface State {
  number: string;
  quantity: number | "";
  numericValue: boolean;
}

/**
 * Component to display number properties for the main category
 * Allows you to specify Plural/Singlar/Mass and a specific amount
 * of a vocabulary.
 */
export const NumbersField: React.FunctionComponent<Props> = ({
  details,
  onTagsAction,
  selected,
}) => {
  const { kindTag } = selected;
  let number = "";
  let quantity: number | "" = "";
  let numericValue = false;
  function parseSelected(): void {
    if (kindTag && kindTag.tags) {
      for (const tag of kindTag.tags) {
        if (
          typeof tag != "string" &&
          tag.category == "kind" &&
          (tag.type == "number" || tag.type == "numeric_number")
        ) {
          const { value } = tag;
          if (typeof value == "string") {
            number = value;
          } else if (typeof value == "number") {
            quantity = value;
            number = "";
          }
          numericValue = tag.type == "numeric_number";
        }
      }
    }
  }
  function updateTags(state: State): void {
    const { number, quantity, numericValue } = state;
    onTagsAction({
      action: ProductTagAction.ADD_NUMBER,
      parent: selected.isSubpart ? selected.id : null,
      number,
      quantity,
      numeric_value: numericValue,
    });
  }

  parseSelected();
  return (
    <>
      <div className={"product-type-row"}>
        <div className={"product-type-number"}>
          <BalloonDiv
            className={"label"}
            value={details.microcopies["product_type_number"]}
          >
            <label htmlFor={"id_product_type_number"}>Grammatical #</label>
          </BalloonDiv>
          <select
            name={"product_type_number"}
            id={"id_product_type_number"}
            disabled={!!quantity}
            value={quantity ? "" : number}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
              const number = event.target.value;
              updateTags({ number, quantity, numericValue });
            }}
          >
            <option value={""}>—</option>
            <option value={"sg"}>Singular</option>
            <option value={"mass"}>Singular, no article (a/an)</option>
            <option value={"pl"}>Plural</option>
          </select>
        </div>
        <div className={"product-type-number"}>
          <BalloonDiv
            className={"label"}
            value={details.microcopies["product_number"]}
          >
            <label htmlFor={"id_product_number"}>Quantity</label>
          </BalloonDiv>
          <input
            type={"number"}
            name={"product_number"}
            min={1}
            id={"id_product_number"}
            value={quantity}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const quantity = parseInt(event.target.value, 10);
              updateTags({ number, quantity, numericValue });
            }}
          />
        </div>
        <div className="product-type-checkbox">
          <input
            type="checkbox"
            name="product_numeric_number"
            id="id_product_numeric_number"
            disabled={!quantity}
            checked={numericValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const numericValue = event.target.checked;
              updateTags({ number, quantity, numericValue });
            }}
          />
          <div className="label">
            <BalloonDiv
              className={"label"}
              value={details.microcopies["product_numeric_number"]}
            >
              <label htmlFor="id_product_numeric_number">Numeric values</label>
            </BalloonDiv>
          </div>
        </div>
      </div>
    </>
  );
};
