import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Button } from "semantic-ui-react";
import { ChannelEditForm } from "./ChannelEditForm";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { updateChannelConfig } from "../../api/channelConfigApi";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";
import { IndexedObject } from "./compareConfigs";
import { NoWithButton } from "./ChannelEditTabHelperText";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import { store } from "../../utils/store";
import { ChannelEditTabHelperText } from "./ChannelEditTabHelperText";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  channelId: string;
};
export const ChannelEditModal: React.FC<Props> = ({
  open,
  setOpen,
  channelId,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();

  const token = useSelector((state: RootState) => state.auth.token);
  const [changedConfig, setChangedConfig] = useState<IndexedObject>();
  const [channelConfig, setChannelConfig] = useState<IndexedObject>({});
  const [channelName, setChannelName] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [helperModalOpen, setHelperModalOpen] = useState(false);
  const mounted = useRef(true);

  const handleSave = async (): Promise<void> => {
    setIsModalLoading(true);
    const { data } = await updateChannelConfig(token, channelId, changedConfig);
    setIsModalLoading(false);
    let djangoMessage: { appearance: NotificationAppearance; content: string };
    if (data.success) {
      djangoMessage = {
        appearance: NotificationAppearance.SUCCESS,
        content: "Global channel settings saved",
      };
      setOpen(false);
      setChangedConfig(data.channel_config);
    } else {
      djangoMessage = {
        appearance: NotificationAppearance.ERROR,
        content: "Something went wrong updating channel settings",
      };
    }

    store.dispatch(setDjangoToastOpen(djangoMessage));
  };
  const onClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    mounted.current = true;
    if (channelId && !isLoading) {
      const channel = customer.getChannelById(Number(channelId));
      let parsedChannelConfig = channel.config;
      if (typeof channel.config === "string") {
        parsedChannelConfig = JSON.parse(channel.config);
      }
      if (mounted.current) {
        setChannelName(channel.display_name);
        setChannelConfig(parsedChannelConfig as IndexedObject);
      }
    }
    return (): void => {
      mounted.current = false;
    };
  }, [channelId, isLoading]);

  if (isLoading) {
    return (
      <Modal open={open}>
        <Spinner></Spinner>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={(): void => onClose()}
      data-testid="channel-edit-modal"
    >
      <Modal.Header>Channel: {channelName}</Modal.Header>
      <Modal.Content scrolling>
        <p>
          This will be saved on a <b>global</b> level.
        </p>
        {"customer_settings" in channelConfig &&
        Object.keys(channelConfig).length ? (
          <>
            <NoWithButton
              data-testid="help-text-modal-button"
              content={"Help texts"}
              floated="right"
              size="tiny"
              onClick={(): void => setHelperModalOpen(true)}
            />
            <ChannelEditForm
              channelConfig={
                changedConfig?.customer_settings ||
                channelConfig.customer_settings
              }
              setChangedConfig={setChangedConfig}
              channelId={Number(channelId)}
            />
          </>
        ) : (
          <h3 data-testid="found-no-settings">
            Found no settings for this channel.
          </h3>
        )}
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        <Button
          data-testid="channel-modal-close"
          loading={isModalLoading}
          className={"pbutton pbutton-inverse"}
          content={"Close"}
          onClick={(): void => onClose()}
        />

        <Button
          data-testid="channel-modal-save"
          loading={isModalLoading}
          content={"Save"}
          className={"pbutton pbutton-primary"}
          onClick={(): Promise<void> => handleSave()}
        />
      </Modal.Actions>
      <Modal
        data-testid="help-text-modal"
        closeIcon
        size="small"
        onClose={(): void => setHelperModalOpen(false)}
        open={helperModalOpen}
      >
        <Modal.Header>Help texts</Modal.Header>
        <Modal.Content scrolling data-testid="help-text-modal-body">
          <ChannelEditTabHelperText showButton={false} width={"100%"} />
        </Modal.Content>
        <Modal.Actions className="modal-actions">
          <Button
            data-testid="help-text-modal-close-button"
            className={"pbutton pbutton-inverse"}
            content={"Close"}
            onClick={(): void => setHelperModalOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    </Modal>
  );
};
