import React, { useEffect, useRef } from "react";
import { CustomerChannel } from "../customers/Customer";
import {
  CustomerLanguages,
  setCustomQueryInitial,
} from "../api/customQuerySlice";
import { useDispatch } from "react-redux";
import { CustomQueryChannelLanguage } from "./CustomQueryChannelLanguage";
import { CustomQueryInputInterface } from "./CustomQueryInputInterface";
import { CustomQuerySampleConditions } from "./CustomQuerySampleConditions";

type Props = {
  customerChannels: CustomerChannel[];
  customerLanguages: CustomerLanguages[];
  preserveParams: any;
  query: string;
};

export const CustomQuery: React.FC<Props> = ({
  customerChannels,
  customerLanguages,
  preserveParams,
  query,
}) => {
  const mounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      dispatch(
        setCustomQueryInitial({
          customerChannels,
          customerLanguages,
          preserveParams,
          query,
        })
      );
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className={"custom-query"} data-testid={"custom-query"}>
      <h1>Custom query</h1>
      <CustomQueryChannelLanguage />
      <CustomQueryInputInterface />
      <CustomQuerySampleConditions />
    </div>
  );
};
