import React from "react";
import { Label, SemanticSIZES, SemanticCOLORS, Icon } from "semantic-ui-react";
import { BalloonDiv } from "../../components/BalloonDiv";

type LabelProps = {
  as?: string;
  color?: SemanticCOLORS;
  onClick?: () => Promise<void> | void;
  onRemove?: () => Promise<void> | void;
  size: SemanticSIZES;
};

type Props = {
  content: string;
  colorPrimary?: boolean;
  disabled?: boolean;
  description: string;
  labelProps: LabelProps;
  isSelected?: boolean;
  pos?: string;
  skipPredefinedClasses?: boolean;
  classNames?: string;
};

export const TemplateLabelChip: React.FC<Props> = ({
  content,
  colorPrimary,
  disabled,
  description,
  labelProps,
  isSelected,
  pos,
  skipPredefinedClasses,
  classNames,
}) => {
  const blueVariant = isSelected || colorPrimary || false;
  let classes = `${blueVariant ? "color-primary" : ""} ${
    disabled ? "disabled" : ""
  }`;
  if (!skipPredefinedClasses) {
    classes += " no-line-height";
  }
  if (labelProps.onClick) {
    classes += " clickable";
  }
  if (classNames) {
    classes += ` ${classNames}`;
  }

  if (description) {
    return (
      <BalloonDiv
        className=""
        value={description}
        pos={pos ?? "up-right"}
        as="span"
      >
        <Label
          className={classes}
          content={content}
          data-testid="row-label"
          icon={isSelected && <Icon name="checkmark" size="small" />}
          {...labelProps}
        />
      </BalloonDiv>
    );
  }
  return (
    <Label
      className={classes}
      content={content}
      data-testid="row-label"
      icon={isSelected && <Icon name="checkmark" size="small" />}
      {...labelProps}
    />
  );
};
