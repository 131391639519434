import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { ProductListAction } from "./ProductList";
import { Language } from "../../customers/customerlanguages";

const StyledMenu = styled(Menu)`
  float: right;
  border: 1px solid grey !important;
  margin-right: 2px !important;
  height: 40px !important;
`;

const StyledDropdown = styled(Dropdown)`
  font-weight: 500 !important;
  font-size: 15px !important;
`;

type Props = {
  originalLanguage: string;
  languages: Language[];
  onAction: (action: ProductListAction, value: string) => void;
};

export const OriginalTextLanguageSelectorField: React.FC<Props> = ({
  originalLanguage,
  languages,
  onAction,
}) => {
  return (
    <StyledMenu compact size="mini">
      <StyledDropdown
        simple
        item
        text="Original Text Language"
        data-testid={"originalTextLanguage"}
        options={languages.map((language: Language) => {
          return {
            key: language.code,
            text: language.name,
            value: language.code,
          };
        })}
        value={originalLanguage}
        onChange={(
          e: React.SyntheticEvent<HTMLElement>,
          { value }: DropdownProps
        ): void => {
          onAction(ProductListAction.ChangeOriginalLanguage, value as string);
        }}
      />
    </StyledMenu>
  );
};
