import React from "react";
import { SectionAction, SectionActionRequest } from "../../api/sectionActions";
import { PrefillFromCategoryModalToolbar } from "./templates/PrefillFromCategoryModalToolbar";
import { ProductId } from "../product";
import { useGetCustomerQuery } from "../../api/customerApi";

type Props = {
  onTemplateAction: (request: SectionActionRequest) => Promise<void>;
  productId: ProductId;
};

export const Toolbar: React.FC<Props> = ({ onTemplateAction, productId }) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }

  const getCategoryModal = (): JSX.Element => {
    return <PrefillFromCategoryModalToolbar productId={productId} />;
  };

  return (
    <div className="actions">
      <div className="menu">
        <div className="title">
          <span data-testid={"more-actions"}>More actions</span>
          <span className="hover-text">More actions</span>
        </div>
        <div className="options">
          <a className="pbutton pbutton-inverse">New template</a>
          {customer.config.show_category_page_form && (
            <>
              <a
                data-testid="template-action-save-category"
                className="pbutton pbutton-inverse save-template-category"
                onClick={(): void => {
                  onTemplateAction({
                    action: SectionAction.TEMPLATE_SAVE_CATEGORY,
                  });
                }}
              >
                Save as category ...
              </a>
              {getCategoryModal()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
