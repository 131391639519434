import React, { Dispatch, SetStateAction } from "react";
import {
  Dropdown,
  DropdownItemProps,
  Form,
  Label,
  LabelProps,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";

type Props = {
  dropdownOptions: DropdownItemProps[];
  selectedPromptsId: number[];
  setSelectedPromptsId: Dispatch<SetStateAction<number[]>>;
};

export const PromptTemplateField: React.FC<Props> = ({
  dropdownOptions,
  selectedPromptsId,
  setSelectedPromptsId,
}) => (
  <Form.Field>
    <label>Select Prompts</label>
    <Text color="grey" className="descriptive-helper-text">
      Select from the dropdown below which prompts you wish to group together.
    </Text>
    <Dropdown
      data-testid="copy-assistant-template-form-add-templates-dropdown"
      search={(options, value): DropdownItemProps[] => {
        if (!value) return options;
        return options.filter(
          ({ key }) => key.toLowerCase().search(value.toLowerCase()) != -1
        );
      }}
      placeholder="Prompts in group..."
      fluid
      multiple
      selection
      value={selectedPromptsId}
      onChange={(_, { value }): void => {
        setSelectedPromptsId(value as number[]);
      }}
      options={dropdownOptions}
      renderLabel={(
        item: DropdownItemProps,
        _: number,
        defaultLabelProps: LabelProps
      ): React.ReactNode => (
        <Label {...defaultLabelProps} content={item.text} />
      )}
    />
  </Form.Field>
);
