import React, { useMemo } from "react";
import ManageGrid from "../../../components/manage/ManageGrid";
import { Text } from "../../../components/Text";
import { Button, Icon } from "../../../components/tailwind";
import { MachineTranslationConfigGroupForm } from "./MachineTranslationConfigGroupForm";
import { useGetMachineTranslationConfigsQuery } from "../../../api/machineTranslationConfigSlice";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import { MachineTranslationConfigList } from "./MachineTranslationConfigList";
import { useGetPrompts } from "../../../products/copy-assistant/customHooks";

export const MachineTranslationConfigGroup: React.FC = () => {
  const {
    data: configGroups = [],
    isLoading: isFetchingGroups,
  } = useGetMachineTranslationConfigsQuery(undefined);

  const {
    isFetching: isFetchingPrompts,
    data: { prompts = [] },
  } = useGetPrompts({
    onlyTranslationPrompts: true,
  });

  const {
    setParam,
    removeParam,
    urlState: { id, copy, create },
  } = useUrlState<{
    id: number;
    copy: string;
    create: string;
  }>();

  const selectedConfigGroup = useMemo(() => {
    if (isFetchingGroups || !!create) return;
    return configGroups.find((group) => group.id === id);
  }, [id, configGroups, isFetchingGroups, create]);

  const defaultIsSelected = useMemo(() => {
    if (copy) return false;
    return selectedConfigGroup?.is_default;
  }, [copy, selectedConfigGroup]);

  const showForm = selectedConfigGroup || !!create;

  const headerInfo = (): React.ReactFragment => {
    if (!showForm) return "";

    if (create)
      return (
        <h4 data-testid="machine-translation-config-group-header-info-create">
          New Settings Group
        </h4>
      );

    if (copy)
      return (
        <h4 data-testid="machine-translation-config-group-header-info-copy">
          Copy from: {selectedConfigGroup?.name} ({id})
        </h4>
      );

    return (
      <h4 data-testid="machine-translation-config-group-header-info-update">
        Update: {selectedConfigGroup?.name} ({id})
      </h4>
    );
  };

  const resetAllParams = (): void => {
    removeParam("copy");
    removeParam("id");
    removeParam("create");
  };

  const onStartCreate = (): void => {
    setParam("create", "true");
    removeParam("copy");
    removeParam("id");
  };

  const onStartUpdate = (id: number): void => {
    setParam("id", id);
    removeParam("copy");
    removeParam("create");
  };

  const onStartCopy = (id: number): void => {
    setParam("copy", "true");
    setParam("id", id);
    removeParam("create");
  };
  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Text as="h4" compact inline>
                Settings Groups
              </Text>
            }
            right={
              <Button
                data-testid="machine-translation-config-group-create-new-small"
                variant="primary-alt"
                icon
                compact
                data-balloon="Create new Settings Group"
                data-balloon-pos="left"
                onClick={onStartCreate}
              >
                <Icon name="add" className="tw-text-lg tw-leading-[1.25]" />
              </Button>
            }
          />
          <ManageGrid.Header.RightColumn>
            {headerInfo()}
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <MachineTranslationConfigList
              selectedId={id}
              configGroups={configGroups}
              onSelect={onStartUpdate}
              onCopy={onStartCopy}
              isLoading={isFetchingGroups}
              isCopying={!!copy}
            />
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {!isFetchingGroups && (
              <>
                {showForm ? (
                  <MachineTranslationConfigGroupForm
                    key={id}
                    selectedConfigGroup={selectedConfigGroup}
                    isCopying={!!copy}
                    isDefaultConfig={defaultIsSelected}
                    onSelect={onStartUpdate}
                    resetAllParams={resetAllParams}
                    translationPrompts={prompts}
                    isFetchingPrompts={isFetchingPrompts}
                  />
                ) : (
                  <ManageGrid.Body.EmptyForm>
                    {!!configGroups.length && (
                      <>
                        <Text as="h2" compact>
                          {" "}
                          Select Settings Group from the list
                        </Text>
                        <Text as="h3" color="grey" compact>
                          Or
                        </Text>
                      </>
                    )}
                    <Button
                      variant="primary"
                      size="lg"
                      icon
                      onClick={onStartCreate}
                      data-testid="machine-translation-config-group-create-new-big"
                    >
                      <Icon name="add" />
                      Create new Settings Group
                    </Button>
                  </ManageGrid.Body.EmptyForm>
                )}
              </>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
    </div>
  );
};
