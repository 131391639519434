import React from "react";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { Product } from "../product";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { TranslationsModalChannelProductTextItems } from "./TranslationsModalChannelProductTextItems";
import { Flag, Language } from "../../customers/customerlanguages";

type Props = {
  language: Language;
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
  product: Product;
  texts: ProductText[];
};

export const TranslationsModalLanguageTexts: React.FC<Props> = ({
  language,
  onRequestProductTextAction,
  product,
  texts,
}) => (
  <div data-testid={"translations-modal-language-texts"}>
    <h3>
      {language.name} <Flag content={language.flag} />
    </h3>
    <TranslationsModalChannelProductTextItems
      onRequestProductTextAction={onRequestProductTextAction}
      product={product}
      texts={texts}
    />
  </div>
);
