import { DirectionProperty } from "csstype";
import { html } from "js-beautify";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, TextArea } from "semantic-ui-react";

import { setEnglishLanguageCustomer } from "../../api/requestWordModalSlice";
import {
  getMachineTranslatedText,
  newRequestWordInfer,
  newRequestWordSchema,
} from "../../api/vocabularyApi";
import { LanguageCode } from "../../customers/customerlanguages";
import { NOOP } from "../../index";
import { LexiconEditorComp } from "../LexiconEditor";
import { stripSpans } from "../../producttext/producttextutils";
import { GridVertical } from "./RequestWordModal";
import { RequestWordModalTranslationContainer } from "./RequestWordModalTranslationContainer";
import { Spinner } from "../../utils/Spinner";
import { RootState } from "../../utils/store";
import { VocabularyTreeData } from "../../utils/tagutils";
import { VocabularyLookupComp } from "../VocabularyLookup";
import { AllowTagType } from "./RequestWordModal";
import { Text } from "../../components/Text";

const helpTexts: { [gfcat: string]: { [type: string]: string } } = {
  Kind: {
    default: "Has singular and plural forms",
    pluralnoun: "Doesn’t have a singular form",
    massnoun: "Doesn’t have a plural form",
  },
  Adjective: {
    default: "Inflects normally",
    invariable: "Doesn’t inflect for gender, number etc.",
    compound: "Doesn’t have comparative or superlative forms",
  },
};

function getHelpText(gf_category: string, type: string): string | undefined {
  return (helpTexts[gf_category] ?? {})[type];
}

type Props = {
  allowTags: AllowTagType;
  alwaysTranslate?: boolean;
  customerLanguage: LanguageCode;
  description: string;
  englishTranslation: string;
  lexiconEditorDirection: DirectionProperty;
  onDescriptionInputChange: (value: string) => void;
  onEnglishTranslationInputChange: (value: string) => void;
  onHypernymChange: (hypernymData: VocabularyTreeData) => void;
  onLexiconDataChange: (value: any) => void;
  onShouldPopulateChange: (value: boolean) => void;
  populateTranslations: boolean;
  providedNewWord: string;
  providedWordType: string;
  shouldPopulate: boolean;
  showHypernym: boolean;
  showMachineTranslate?: boolean;
  textualAppName: string;
  useLexiconEditor: boolean;
};

export const RequestWordModalWordDetails: React.FC<Props> = ({
  allowTags,
  customerLanguage,
  description,
  englishTranslation,
  lexiconEditorDirection,
  onDescriptionInputChange,
  onEnglishTranslationInputChange,
  onHypernymChange,
  onLexiconDataChange,
  onShouldPopulateChange,
  populateTranslations,
  providedNewWord,
  providedWordType,
  shouldPopulate,
  showHypernym,
  showMachineTranslate,
  textualAppName,
  useLexiconEditor,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const [newWord, setNewWord] = useState("");
  const [lexiconEditorSchema, setLexiconEditorSchema] = useState(null);
  const [lexiconEditorUiSchema, setLexiconEditorUiSchema] = useState(null);
  const [lexiconEditorData, setLexiconEditorData] = useState(null);
  const [suggestedEnglishText, setSuggestedEnglishText] = useState("");
  const [wordType, setWordType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    (async (): Promise<void> => {
      mounted.current = true;
      if (mounted.current) {
        changeWordType(providedWordType, providedNewWord);

        if (customerLanguage) {
          await setDefaultCustomerLanguage();
        }
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    changeWordType(providedWordType, providedNewWord);
  }, [providedWordType, providedNewWord]);

  const setDefaultCustomerLanguage = async (): Promise<void> => {
    const englishLanguageCustomer =
      customerLanguage == "en_US" || customerLanguage == "en_GB";
    dispatch(setEnglishLanguageCustomer(englishLanguageCustomer));
    if (
      !englishLanguageCustomer &&
      !englishTranslation.length &&
      !suggestedEnglishText.length
    ) {
      await setMachineTranslateText(customerLanguage, providedNewWord);
    }
  };

  const setMachineTranslateText = async (
    customerLanguage: string,
    text: string
  ): Promise<void> => {
    const translatedText = await getMachineTranslatedText({
      sourceLanguage: customerLanguage,
      targetLanguage: "en_US",
      text,
      context: "request-word-english-translation",
      token,
    });

    if (translatedText) {
      const suggestedEnglishText = html(stripSpans(translatedText));
      setSuggestedEnglishText(suggestedEnglishText);
    }
  };

  const changeWordType = (
    requestWordType: string,
    requestNewWord: string
  ): void => {
    if (!mounted.current) {
      return;
    }
    const wordTypeValue = requestWordType || wordType;
    const newWordValue = requestNewWord || newWord;

    if (
      wordTypeValue &&
      newWordValue &&
      (wordTypeValue !== wordType || newWordValue !== newWord)
    ) {
      const [tagCategory, tagType] = wordTypeValue.split("/");
      if (tagCategory && tagType) {
        setWordType(wordTypeValue);
        setNewWord(newWordValue);
        if (useLexiconEditor) {
          setShowLoader(true);
          setLexiconEditorData(null);
          setLexiconEditorSchema(null);
          setLexiconEditorUiSchema({});

          const disallowKind = allowTags === AllowTagType.NON_KIND_MATERIAL;
          const allowMaterialKindTagsOnly =
            allowTags === AllowTagType.KIND_MATERIAL;

          newRequestWordSchema(
            tagCategory,
            tagType,
            customerLanguage,
            token,
            textualAppName,
            disallowKind,
            allowMaterialKindTagsOnly
          )
            .then((response) => {
              if (!mounted.current) {
                return;
              }
              setShowLoader(false);

              // Modify lexicon schema before updating, inserting help texts
              const lexiconSchema = response.data["schema"];
              for (const dep of lexiconSchema.dependencies.gf_category.oneOf) {
                const type = dep.properties.forms.properties.type;
                const enums = type.enum;
                type.oneOf = enums.map((v: string) => {
                  const helpText = getHelpText(
                    response.data["gf_category"] as string,
                    v
                  );
                  const title = helpText ? `${v}: ${helpText}` : v;
                  return { const: v, title };
                });
                type.enum = null;
              }
              setLexiconEditorSchema(lexiconSchema);

              const uiSchema: any = {
                gf_category: { "ui:classNames": "hidden" },
                forms: {
                  construction: { "ui:classNames": "hidden" },
                },
              };
              if (
                response.data["gf_category"] !== "Kind" &&
                response.data["gf_category"] !== "Adjective"
              ) {
                uiSchema["forms"]["type"] = { "ui:classNames": "hidden" };
              }
              if (response.data["gf_category"] === "Description") {
                uiSchema["forms"]["lemma"] = { "ui:widget": "textarea" };
              }
              setLexiconEditorUiSchema(uiSchema);
            })
            .finally(() => {
              if (mounted.current) setShowLoader(false);
            });

          newRequestWordInfer(
            tagCategory,
            tagType,
            customerLanguage,
            requestNewWord,
            token,
            textualAppName
          ).then((response) => {
            if (!mounted.current) {
              return;
            }
            setLexiconEditorData(response.data);
          });
        }
      }
    }
  };

  return (
    <GridVertical>
      {showLoader && <Spinner />}
      {useLexiconEditor && lexiconEditorSchema && lexiconEditorData && (
        <LexiconEditorComp
          direction={lexiconEditorDirection}
          initialData={lexiconEditorData}
          jsonSchema={lexiconEditorSchema}
          languageCode={customerLanguage}
          onDataChanged={(c, data): void => onLexiconDataChange(data)}
          onLoaded={NOOP}
          uiSchema={lexiconEditorUiSchema}
        />
      )}
      <Form>
        <TextArea
          data-testid={"requestwordmodal-description"}
          placeholder='Description for helping translators, e.g.: "This word can be used to describe the way a material feels when you touch it."'
          value={description}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void =>
            onDescriptionInputChange(event.target.value)
          }
        />
      </Form>
      <RequestWordModalTranslationContainer
        englishTranslation={englishTranslation}
        onEnglishTranslationInputChange={onEnglishTranslationInputChange}
        onShouldPopulateChange={onShouldPopulateChange}
        populateTranslations={populateTranslations}
        providedWordType={providedWordType}
        shouldPopulate={shouldPopulate}
        showMachineTranslate={showMachineTranslate}
        suggestedEnglishText={suggestedEnglishText}
      />
      {showHypernym && providedWordType === "identifier/kind" && (
        <>
          <div>
            Add the parent category of the item.
            <Text color="grey" size="small" compact>
              For example, “dress” is the parent category of “wrap dress”. {}
              This is used when referring to a product.
            </Text>
          </div>
          <VocabularyLookupComp
            customerLanguage={customerLanguage}
            initialData={null}
            onLoaded={(): void => null}
            onDataChanged={(component: React.Component, data: any): void => {
              onHypernymChange && onHypernymChange(data);
            }}
            tagCategory="identifier"
            tagType="kind"
            textualAppName={textualAppName}
          />
        </>
      )}
    </GridVertical>
  );
};
