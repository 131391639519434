import $ from "jquery";

let promiseReject;

const renderPreview = function ($preview, data) {
  $preview.removeClass("loading");
  $preview.empty();

  // if (data.test_phrases) {
  //   $preview.append(
  //     '<div>' +
  //       '<div class="label">' + 'Generated phrases' + '</div>' +
  //       '<div class="text">' + data.test_phrases.join('<br>') + '</div>' +
  //     '</div>'
  //   );
  // }
  data.items.forEach(function (item) {
    $preview.append(
      '<div class="' +
        (item.error ? "error" : "") +
        '">' +
        '<div class="label">' +
        item.label +
        "</div>" +
        '<div class="text">' +
        item.text +
        "</div>" +
        "</div>"
    );
  });
};

const genericError = function ($preview) {
  $preview.removeClass("loading");
  $preview.append(
    '<div class="error">' +
      '<div class="label">' +
      "Error" +
      "</div>" +
      '<div class="text">' +
      "Unable to generate preview." +
      "</div>" +
      "</div>"
  );
};

const updatePreview = function (
  $preview,
  vocabularyId,
  language,
  lexiconData,
  paradigm
) {
  $preview.addClass("loading");
  $preview.text("");

  // Needed for when apps.translation.preview throws uncatchable error
  window.onerror = function () {
    genericError($preview);
  };

  if (promiseReject) promiseReject("cancel"); // cancel any ongoing request
  const data = {
    vocabulary_id: vocabularyId,
    trans_language: language,
    lexicon_data: JSON.stringify(lexiconData),
    paradigm: paradigm,
  };
  // eslint-disable-next-line no-undef
  new Promise(function (resolve, reject) {
    promiseReject = reject;
    $.ajax({
      type: "POST",
      url: "/api/_internal/preview/",
      data: data,
      dataType: "json",
      success: function (data) {
        resolve(data);
      },
    });
  })
    .then(function (data) {
      renderPreview($preview, data);
    })
    .catch(function (reason) {
      if (reason === "cancel") {
        // a newer request is replacing this one
      } else {
        // assume request failed
        genericError($preview);
      }
    });
};

window.lexiconEditorOnDataChanged = function (element, component, data) {
  const $textarea = $(element);
  const $lexiconItem = $textarea.closest(".lexicon-item");
  const $preview = $lexiconItem.find(".preview");

  const language = $preview.data("language");
  const vocabularyId = $preview.data("vocabulary-id");
  const paradigm = $preview.data("paradigm");

  let _timeout = $textarea.data("timeout");
  let _oldValue = $textarea.data("value") + "";
  let _newValue = $textarea.val() + "";

  _oldValue = _oldValue || "";
  _newValue = _newValue || "";

  if (_newValue.trim() !== _oldValue.trim()) {
    clearTimeout(_timeout);
    _timeout = setTimeout(function () {
      if ($lexiconItem.is(".touched")) {
        updatePreview($preview, vocabularyId, language, data, paradigm);
      }
    }, 1000);

    $textarea.data("timeout", _timeout);
  }
};

const touchLexiconItem = function () {
  const $lexiconItem = $(this);
  if (!$lexiconItem.hasClass("touched")) {
    $lexiconItem.addClass("touched");
  }
};

$(document).on("click", ".lexicon-item", touchLexiconItem);
$(document).on("keydown", ".lexicon-item", touchLexiconItem);
