import { TemplateItem } from "../vocabulary/template";
import api from "./api";
import { SortOption } from "./vocabularyApi";

const BASE_URL = "/api/_internal/vocabulary/templates/";
type getTemplateQueryParams = {
  fetch_lexicons?: boolean; // Include related template lexicons
  fetch_fields?: boolean; // Include related template fields
  all_template_labels?: boolean; // Include inactive and active template labels, if not provided/is false it will only include active template labels
};
export const getTemplate = async (
  token: string,
  id: number,
  params?: getTemplatesQueryParams
): Promise<TemplateItem> => {
  const response = await api.get<TemplateItem>(BASE_URL + `${id}`, {
    headers: { token },
    params,
  });

  return response.data;
};

type getTemplatesQueryParams = getTemplateQueryParams & {
  order?: SortOption;
};

export const getTemplates = async (
  token: string,
  params?: getTemplatesQueryParams
): Promise<TemplateItem[]> => {
  const response = await api.get<TemplateItem[]>(BASE_URL, {
    headers: { token },
    params,
  });

  return response.data;
};

export const updateTemplate = async (
  token: string,
  template: TemplateItem
): Promise<unknown> => {
  const response = await api.put(BASE_URL + template.id, template, {
    headers: { token },
  });
  return response.data;
};

export const createTemplate = async (
  token: string,
  template: Omit<TemplateItem, "id">
): Promise<unknown> => {
  const response = await api.post(BASE_URL, template, {
    headers: { token },
  });
  return response.data;
};

export const deleteTemplate = async (
  token: string,
  id: number
): Promise<unknown> => {
  const response = await api.delete(BASE_URL + id, {
    headers: { token },
  });
  return response.data;
};
