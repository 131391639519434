import React from "react";
import uniqBy from "lodash/uniqBy";
import difference from "lodash/difference";
import { Component } from "react";
import { Dropdown, Grid, Label } from "semantic-ui-react";
import { Option } from "./MultipleSelectorField";
import { TemplateLabel } from "../vocabulary/template";
import { ConfirmationModal } from "../ui/ConfirmationModal";
import { Text } from "../components/Text";
import { createHref } from "../utils/hrefUtils";
import { getCustomer } from "../api/customerApi";
import { connect, ConnectedProps } from "react-redux";
import { Customer } from "../customers/Customer";
import { RootState, store } from "../utils/store";

type Choice = {
  default: boolean;
  id: number;
  label: string;
  name: string;
  value: string;
};

type Props = {
  allowAdditions: boolean;
  availableChoices: Choice[];
  componentId: number;
  disabled: boolean;
  initialData: TemplateLabel[];
  labelMaxLength: number;
  onLoaded: (component: TemplateMultipleLabelSelectorField) => void;
  onDataChanged: (
    component: TemplateMultipleLabelSelectorField,
    data: (TemplateLabel | Choice)[]
  ) => void;
};

const mapStateToProps = (
  state: RootState
): {
  token: string | null;
  customer: Customer | null;
} => {
  return {
    token: state.auth.token,
    /** Current customer */
    customer: getCustomer(store.getState()),
  };
};

const connector = connect(mapStateToProps, null);

type TemplateMultipleLabelSelectorFieldProps = ConnectedProps<
  typeof connector
> &
  Props;

type State = {
  currentDefaultLabels: (TemplateLabel | Choice)[];
  currentOptionalLabels: (TemplateLabel | Choice)[];
  displayConfirmationModal: boolean;
  newLabels: Choice[];
  options: Option[];
};

export default class TemplateMultipleLabelSelectorField extends Component<
  TemplateMultipleLabelSelectorFieldProps,
  State
> {
  constructor(props: TemplateMultipleLabelSelectorFieldProps) {
    super(props);
    this.state = {
      currentDefaultLabels: [],
      currentOptionalLabels: [],
      displayConfirmationModal: false,
      newLabels: [],
      options: [],
    };
  }

  componentDidMount(): void {
    const { initialData, onLoaded, availableChoices } = this.props;

    onLoaded(this);
    const { defaultLabels, optionalLabels } = this.sortLabels(initialData);
    const choices = uniqBy(availableChoices, "value") || initialData;
    const options =
      choices &&
      choices.length > 0 &&
      choices.map(({ name }, index) => {
        return { key: index, text: name, value: name };
      });
    this.setState({
      currentDefaultLabels: defaultLabels,
      currentOptionalLabels: optionalLabels,
      newLabels: [],
      options: options || [],
    });
  }

  openConfirmModal = (): void => {
    this.setState({ displayConfirmationModal: true });
  };

  closeConfirmModal = (): void => {
    this.setState({ displayConfirmationModal: false });
  };

  sortLabels = (
    labels: (TemplateLabel | Choice)[]
  ): {
    defaultLabels: (TemplateLabel | Choice)[];
    optionalLabels: (TemplateLabel | Choice)[];
  } => {
    const sortedLabels = labels.sort((a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    const defaultLabels = sortedLabels.filter(
      (label: TemplateLabel | Choice) => label.default
    );
    const optionalLabels = sortedLabels.filter(
      (label: TemplateLabel | Choice) => !label.default
    );
    return { defaultLabels, optionalLabels };
  };

  handleLabelChange = (value: string[], isDefault: boolean): void => {
    const { currentDefaultLabels, currentOptionalLabels } = this.state;
    const { labelMaxLength, availableChoices } = this.props;

    const additionLabelListName = isDefault
      ? currentOptionalLabels.map(({ name }) => name)
      : currentDefaultLabels.map(({ name }) => name);

    const labelLength =
      currentDefaultLabels.length + currentOptionalLabels.length;
    const newValues: string[] = value.filter(
      (v: string) => v.length <= labelMaxLength
    );

    const allValues = [...additionLabelListName, ...newValues];
    const newLabels = uniqBy(availableChoices, "value").filter(({ value }) => {
      return allValues.includes(value);
    });

    newLabels.forEach((label) => {
      if (
        additionLabelListName.includes(label.value) &&
        !newValues.includes(label.value)
      ) {
        label.default = !isDefault;
      } else {
        label.default = isDefault;
      }
    });
    this.setState({ newLabels }, () => {
      if (labelLength > newLabels.length) {
        this.openConfirmModal();
      } else {
        this.changeLabel(value[value.length - 1]);
      }
    });
  };

  changeLabel = (addedValue: string): void => {
    const { onDataChanged } = this.props;
    const { options, newLabels } = this.state;
    const { defaultLabels, optionalLabels } = this.sortLabels(newLabels);

    if (newLabels.length > 0) {
      const matchedIndex = options.findIndex(
        (option) => option.value === addedValue
      );

      if (matchedIndex === -1 || options.length === 0) {
        options.push({
          key: newLabels.length - 1,
          text: addedValue,
          value: addedValue,
        });
      }
    }
    this.setState(
      {
        currentDefaultLabels: defaultLabels,
        currentOptionalLabels: optionalLabels,
        options: options,
      },
      () => {
        onDataChanged(this, newLabels);
        this.closeConfirmModal();
      }
    );
  };

  handleOnConfirm = (confirm: boolean, labelToDelete: string): void => {
    if (confirm) {
      this.changeLabel(labelToDelete);
    } else {
      this.closeConfirmModal();
    }
  };

  render(): React.ReactElement {
    const { componentId, disabled, customer } = this.props;
    const {
      currentDefaultLabels,
      currentOptionalLabels,
      displayConfirmationModal,
      newLabels,
      options,
    } = this.state;
    const newLabelsName = newLabels.map(({ name }) => name);
    const allCurrentLabelsName = [
      ...currentDefaultLabels.map(({ name }) => name),
      ...currentOptionalLabels.map(({ name }) => name),
    ];
    const labelToDelete = difference(allCurrentLabelsName, newLabelsName)[0];
    return (
      <>
        <h2>Template settings</h2>
        <Text color="grey" className="descriptive-helper-text">
          These settings affect how and where a template is used during text
          generation.
          <br />
          <br />
          The settings which are usable with this template are shown below. All
          template settings can be toggled{" "}
          <Label
            as="span"
            content="on"
            className="color-primary"
            size="tiny"
          />{" "}
          or <Label as="span" content="off" size="tiny" /> individually each
          time the template is used on a product.
          <br />
          <br />
          To modify the available template settings, Go to{" "}
          <a
            href={createHref("templateLabels", customer)}
            data-subpage=""
            target=""
          >
            Manage Template Settings...
          </a>
          .
        </Text>
        <Grid>
          <div>
            <h4>Default template settings</h4>
            <Text color="grey" className="descriptive-helper-text">
              These settings will be{" "}
              <Label
                as="span"
                content="on"
                className="color-primary"
                size="tiny"
              />{" "}
              by default when this template is added to product.
            </Text>
          </div>
          <Dropdown
            closeOnBlur={true}
            closeOnChange
            disabled={disabled}
            fluid
            id={componentId}
            multiple
            options={options}
            onChange={(e, { value }): void =>
              this.handleLabelChange(value as string[], true)
            }
            placeholder="Type to search"
            renderLabel={(
              { text },
              _,
              defaultLabelProps
            ): React.ReactElement => (
              <Label
                className="font-size-small color-primary"
                content={text}
                {...defaultLabelProps}
              />
            )}
            search
            selection
            selectOnBlur={false}
            value={currentDefaultLabels?.map(({ name }) => name)}
          />
          <div>
            <h4>Optional template settings</h4>
            <Text color="grey" className="descriptive-helper-text">
              These settings will be usable but{" "}
              <Label as="span" content="off" size="tiny" /> by default when this
              template is added to product.
            </Text>
          </div>
          <Dropdown
            closeOnBlur={true}
            closeOnChange
            disabled={disabled}
            fluid
            id={componentId}
            multiple
            options={options}
            onChange={(e, { value }): void =>
              this.handleLabelChange(value as string[], false)
            }
            placeholder="Type to search"
            renderLabel={(
              { text },
              _,
              defaultLabelProps
            ): React.ReactElement => (
              <Label
                content={text}
                className="font-size-small"
                {...defaultLabelProps}
              />
            )}
            search
            selection
            selectOnBlur={false}
            value={currentOptionalLabels?.map(({ name }) => name)}
          />
          <ConfirmationModal
            headerDescription={"Remove the label " + labelToDelete + "?"}
            contentDescription={
              "Removing the label might affect how this template is displayed. Do " +
              "you still want to remove it?"
            }
            open={displayConfirmationModal}
            onConfirm={(confirm: boolean): void =>
              this.handleOnConfirm(confirm, labelToDelete)
            }
          />
        </Grid>
      </>
    );
  }
}

export const TemplateMultipleLabelSelectorFieldComp = connector(
  TemplateMultipleLabelSelectorField
);
