import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Tab } from "semantic-ui-react";
import styled from "styled-components";
import { ChannelLanguagePair } from "./ProductListURLConfig";
import { ProductListAction } from "./ProductList";
import { TabWrapper } from "../../utils/TabWrapper";
import {
  MatrixRowType,
  CustomerChannelLanguageMatrix,
} from "../../customers/CustomerChannelLanguageMatrix";
import { Text } from "../../components/Text";

const StyledModal = styled(Modal)`
  && {
    width: auto;
    top: 50px;
  }
`;

const StyledModalContent = styled(Modal.Content)`
  && {
    min-width: 785px;
    min-height: 400px;
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAction: (
    action: ProductListAction,
    value?: {
      [key in
        | "channelLanguagePairs"
        | "referenceChannelLanguagePairs"]: ChannelLanguagePair[];
    }
  ) => void;
  selectedPairs: ChannelLanguagePair[];
  selectedReferencePairs: ChannelLanguagePair[];
};

export const ChannelLanguageSelectorModal: React.FC<Props> = ({
  open,
  setOpen,
  onAction,
  selectedPairs,
  selectedReferencePairs,
}) => {
  const [selectedPairsModal, setSelectedPairsModal] = useState<
    ChannelLanguagePair[]
  >(null);
  const [
    selectedReferencePairsPairsModal,
    setSelectedReferencePairsModal,
  ] = useState<ChannelLanguagePair[]>(null);

  useEffect(() => {
    setSelectedPairsModal(selectedPairs);
    setSelectedReferencePairsModal(selectedReferencePairs);
  }, [open]);

  const onChangeSelectedPairsModal = (payload: MatrixRowType[]): void => {
    setSelectedPairsModal(getPairs(payload));
  };

  const onChangeSelectedReferencePairsPairsModal = (
    payload: MatrixRowType[]
  ): void => {
    setSelectedReferencePairsModal(getPairs(payload));
  };

  const getPairs = (pairList: MatrixRowType[]): ChannelLanguagePair[] => {
    const channelPairs: ChannelLanguagePair[] = [];
    pairList.forEach((languagePair) => {
      languagePair.channel_details.forEach((channelDetail) => {
        if (channelDetail.selected) {
          channelPairs.push({
            channel_id: channelDetail.channel_id,
            language_code: languagePair.language_code,
          });
        }
      });
    });
    return channelPairs;
  };

  const panes = [
    {
      menuItem: "Text column(s)",
      render: TabWrapper(
        <StyledDiv>
          <CustomerChannelLanguageMatrix
            fetchedChannelLanguageMatrix={{
              channel_language_matrix: selectedPairsModal?.map((pair) => ({
                channel_id: pair.channel_id,
                language_code: pair.language_code,
                default: true,
              })),
            }}
            onChangeChannelLanguageMatrixRef={onChangeSelectedPairsModal}
          />
        </StyledDiv>
      ),
    },
    {
      menuItem: "Reference column(s)",
      render: TabWrapper(
        <StyledDiv>
          <Container text>
            <Text color="grey" size="small">
              A reference column can be used for proofreading when you need to
              see another language as a reference. Note that any filters will
              not apply to a reference column.
            </Text>
          </Container>
          <CustomerChannelLanguageMatrix
            fetchedChannelLanguageMatrix={{
              channel_language_matrix: selectedReferencePairsPairsModal?.map(
                (pair) => ({
                  channel_id: pair.channel_id,
                  language_code: pair.language_code,
                  default: true,
                })
              ),
            }}
            onChangeChannelLanguageMatrixRef={
              onChangeSelectedReferencePairsPairsModal
            }
          />
        </StyledDiv>
      ),
    },
  ];
  return (
    <StyledModal
      onOpen={(): void => setOpen(true)}
      onClose={(): void => {
        setOpen(false);
      }}
      open={open}
      data-testid={"channel-language-selector-modal"}
    >
      <Modal.Header>Select column(s)</Modal.Header>
      <StyledModalContent>
        <Tab panes={panes} />
      </StyledModalContent>
      <Modal.Actions>
        <Button
          data-testid={"channel-language-selector-modal-close-button"}
          type="button"
          size="small"
          basic
          color="red"
          onClick={(): void => {
            setOpen(false);
          }}
        >
          Close
        </Button>
        <Button
          size="small"
          color="red"
          data-testid={"submitButton"}
          disabled={selectedPairs.length === 0}
          onClick={(): void => {
            onAction(ProductListAction.UpdateChannelLanguagePair, {
              channelLanguagePairs: selectedPairsModal,
              referenceChannelLanguagePairs: selectedReferencePairsPairsModal,
            });
            setOpen(false);
          }}
          type="button"
        >
          Update view
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
