import { ViewId, ViewSet, ViewSetId } from "./ViewSetsFilterContainer";
import React, { useEffect, useRef } from "react";
import { ViewSetsFilterSaveButtonDropdown } from "./ViewSetsFilterSaveButtonDropdown";
import { ViewSetsCreateNewFilterDropdown } from "./ViewSetsCreateNewFilterDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { CustomQueryHint } from "../CustomQueryHint";

type Props = {
  activeViewId: number;
  haveActiveViewFiltersChanged: boolean;
  onActiveViewChange?: () => void;
  onViewUpdate?: (viewId: ViewId) => Promise<void>;
  onViewSetCreate?: (display_name: string) => void;
  onViewCreate?: (display_name: string, viewSetId: ViewSetId) => void;
  viewSets?: ViewSet[];
};

export const ViewSetsFilterSaveClearButtons: React.FC<Props> = ({
  activeViewId,
  haveActiveViewFiltersChanged,
  onActiveViewChange,
  onViewUpdate,
  onViewSetCreate,
  onViewCreate,
  viewSets,
}) => {
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (haveActiveViewFiltersChanged && mounted.current) {
    return (
      <ViewSetsFilterSaveButtonDropdown
        onActiveViewChange={onActiveViewChange}
        onViewCreate={onViewCreate}
        onViewUpdate={(): Promise<void> => onViewUpdate?.(activeViewId)}
        onViewSetCreate={onViewSetCreate}
        viewSets={viewSets}
      />
    );
  }

  return (
    <>
      {customQuery?.query && <CustomQueryHint />}
      <ViewSetsCreateNewFilterDropdown
        onViewSetCreate={onViewSetCreate}
        onViewCreate={onViewCreate}
        viewSets={viewSets}
      />
    </>
  );
};
