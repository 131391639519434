import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { Input, Checkbox, Select, DropdownItemProps } from "semantic-ui-react";
import Form, { IChangeEvent, WidgetProps } from "react-jsonschema-form";
import styled from "styled-components";
import { JSONSchema6, JSONSchema6TypeName } from "json-schema";
import { IndexedObject } from "./compareConfigs";

export type SelectValue = {
  value: string | number | boolean;
  selected?: boolean;
  text: string | number;
};
interface WidgetPropsSelectValue extends WidgetProps {
  value: SelectValue[];
}
const FormNoBorders = styled(Form)`
  fieldset {
    border: none;
    position: relative;
    legend {
      font-weight: 700;
    }
  }
  .field-description {
    display: none;
  }
  .form-group {
    fieldset {
      .form-group:not(.field-object) {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 100px auto;
        gap: 30px;
        align-items: center;
        margin-bottom: 10px;
        label {
          font-size: small;
          margin: 0;
        }
        div > h6 {
          margin: 2px 0 0 0;
        }
      }
    }
  }
`;

const customWidgetTextInput = ({
  value,
  onChange,
  schema,
  id,
}: WidgetProps): React.ReactElement => (
  <div>
    <Input
      data-testid={`test_id_${id}`}
      id={id}
      value={value}
      onChange={(e, { value }): void => onChange(value)}
      fluid
    />
    <h6 className="ui text grey" data-testid={`description_test_id_${id}`}>
      {schema.description}
    </h6>
  </div>
);
const customWidgetSelect = ({
  value,
  onChange,
  schema,
  id,
}: WidgetPropsSelectValue): React.ReactElement => {
  const { description } = schema;
  const semanticOptions: DropdownItemProps[] = [];
  value.forEach(({ value, text }): number =>
    semanticOptions.push({
      value: value,
      key: value,
      text: text,
    })
  );

  return (
    <div>
      <Select
        id={id}
        data-testid={`test_id_${id}`}
        value={value.find((v) => v.selected).value}
        options={semanticOptions}
        onChange={(e, { value, options }): void => {
          const valuesWithSelected: SelectValue[] = [];
          options.forEach((option) => {
            if (option.value === value) {
              valuesWithSelected.push({
                value: option.value,
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                text: option.text.toString(),
                selected: true,
              });
            } else {
              valuesWithSelected.push({
                value: option.value,
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                text: option.text.toString(),
              });
            }
          });
          onChange(valuesWithSelected);
        }}
        fluid
      />
      <h6 className="ui text grey" data-testid={`description_test_id_${id}`}>
        {description}
      </h6>
    </div>
  );
};
const customWidgetCheckBox = ({
  value,
  label,
  onChange,
  schema,
  id,
}: WidgetProps): React.ReactElement => (
  <>
    <label htmlFor={id}>{label}</label>
    <div>
      <Checkbox
        data-testid={`test_id_${id}`}
        checked={value}
        id={id}
        onChange={(e, { checked }): void => onChange(checked)}
      />
      <h6 className="ui text grey" data-testid={`description_test_id_${id}`}>
        {schema.description}
      </h6>
    </div>
  </>
);

const customUiSchema = {
  string: {
    "ui:widget": customWidgetTextInput,
  },
  boolean: {
    "ui:widget": customWidgetCheckBox,
  },
  array: {
    "ui:widget": customWidgetSelect,
  },
};

const widgets = {
  TextWidget: customWidgetTextInput,
  CheckboxWidget: customWidgetCheckBox,
  SelectWidget: customWidgetSelect,
};

const buildArraySchema = (
  array: SelectValue[],
  helperObject: IndexedObject
): JSONSchema6 => {
  const schema: JSONSchema6 = {
    title: helperObject?.title,
    description: helperObject?.description,
    type: "array",
    items: {
      type: "string",
      enum: array,
    },
    uniqueItems: true,
  };

  return schema;
};

/**
 * buildSchema iterates through an object to create a jsonschema object by using
 * the type of the values in the keys to determent what input should be created.
 * It can iterate down objects within objects to create nested jsonschema object by calling it self.
 *
 * @param object the object to iterate and build schema from
 * @param helperObjects array of objects that contains custom title and custom description
 *                      (optional) defaults to key name if not added
 * @param helperObject one of the object from the array of helper objects
 *                     (needs to be able to pass when iterating)
 * @returns a jsonschema object for react-jsonschema-form to build the form
 */
const buildSchema = (
  object: IndexedObject,
  helperObjects?: IndexedObject[],
  helperObject?: IndexedObject
): JSONSchema6 => {
  const schema: JSONSchema6 = {
    type: "object",
    title: helperObject?.title,
    description: helperObject?.description,
    properties: {},
  };

  Object.keys(object).forEach((key) => {
    if (key === "helper_objects") return;

    const helperObject = helperObjects?.find(({ for_key }) => for_key === key);

    if (Array.isArray(object[key])) {
      schema.properties[key] = buildArraySchema(object[key], helperObject);
    } else {
      schema.properties[key] = {
        title: helperObject?.title,
        description: helperObject?.description,
        type: typeof object[key] as JSONSchema6TypeName,
      };

      if (typeof object[key] === "object") {
        schema.properties[key] = buildSchema(
          object[key],
          helperObjects,
          helperObject
        );
      }
    }
  });

  return schema;
};

type Props = {
  channelConfig: { [index: string]: any } | undefined;
  channelId: number;
  setChangedConfig: Dispatch<SetStateAction<IndexedObject>>;
  setShouldAutoSave?: Dispatch<SetStateAction<boolean>>;
};

export const ChannelEditForm: React.FC<Props> = ({
  channelConfig,
  channelId,
  setChangedConfig,
  setShouldAutoSave,
}) => {
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState<JSONSchema6>({
    type: "object",
    properties: {},
  });
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setSchema(buildSchema(channelConfig, channelConfig.helper_objects));
      setFormData(channelConfig);
    }
    return (): void => {
      mounted.current = false;
    };
  }, [channelConfig]);

  const onChange = (e: IChangeEvent): void => {
    if (!mounted.current) return;
    setFormData(e.formData);
    setChangedConfig(e.formData);
    if (setShouldAutoSave) setShouldAutoSave(true);
  };

  const customUiSchemaAndUniqueRootId = {
    "ui:rootFieldId": `for_channel_${channelId}`,
    ...customUiSchema,
  };
  return (
    <FormNoBorders
      schema={schema}
      formData={formData}
      uiSchema={customUiSchemaAndUniqueRootId}
      widgets={widgets}
      onChange={onChange}
      onSubmit={null}
    />
  );
};
