import React, { useEffect, useState } from "react";
import { ProductId } from "../product";
import { CopyData, CopyMode } from "./types";
import { Button, Form, Modal, Popup } from "semantic-ui-react";
import { copyProductTags } from "../../api/action";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";

type Props = {
  label: string;
  productId: ProductId;
  childClose: () => void;
  open: boolean;
  copyMode: CopyMode;
};
export const CopyToFromModal: React.FC<Props> = ({
  label,
  productId,
  childClose,
  open,
  copyMode,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();
  const [skuEan, setSkuEan] = useState("SKU");
  const [mergeOverwrite, setMergeOverwrite] = useState("overwrite");
  const [productToCopyFrom, setProductToCopyFrom] = useState("");
  const [copyBrand, setCopyBrand] = useState(true);
  const [copyName, setCopyName] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [copyData, setCopyData] = useState<CopyData | null>(null);
  const [copyLabels, setCopyLabels] = useState(true);
  const [copyChannelsConfig, setCopyChannelsConfig] = useState(false);

  const DjangoToastHandler = (message: string): void => {
    let djangoMessage: { appearance: NotificationAppearance; content: string };
    if (message.includes("not found") || message.includes("provide")) {
      djangoMessage = {
        appearance: NotificationAppearance.ERROR,
        content: message,
      };
    } else {
      djangoMessage = {
        appearance: NotificationAppearance.SUCCESS,
        content: message,
      };
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    store.dispatch(setDjangoToastOpen(djangoMessage));
  };

  const onCopy = (): void => {
    if (!productToCopyFrom)
      return DjangoToastHandler("Please provide SKU/EAN code");

    let productEan: string | undefined;
    let productSku: string | undefined;

    if (skuEan === "SKU") {
      productSku = productToCopyFrom;
    } else {
      productEan = productToCopyFrom;
    }

    setCopyData({
      productId: productId,
      productSKU: productSku,
      productEAN: productEan,
      copyMode: copyMode,
      shouldMergeData: mergeOverwrite === "merge",
      copyBrand: copyBrand,
      copyName: copyName,
      copyLabels: copyLabels,
      copyChannelsConfig: copyChannelsConfig,
    });
  };

  useEffect(() => {
    let mounted = true;
    if (copyData) {
      (async (): Promise<void> => {
        setIsLoading(true);
        const request = await copyProductTags(copyData, token).finally(() => {
          if (mounted) setIsLoading(false);
        });
        const { message } = request;
        if (mounted) {
          DjangoToastHandler(message);
        }
      })();
    }
    return (): void => {
      mounted = false;
    };
  }, [copyData]);

  if (isCustomerLoading) {
    return (
      <Modal open={open} data-testid={"copy-modal-loading"}>
        <Modal.Header>
          <Spinner />
        </Modal.Header>
      </Modal>
    );
  }

  return (
    <Modal
      onClose={isLoading ? null : childClose}
      open={open}
      data-testid={"copy-modal"}
    >
      <Modal.Header>{label}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group inline>
            <Form.Checkbox
              radio
              label={"SKU"}
              name={"SKUEAN"}
              value={"SKU"}
              checked={skuEan === "SKU"}
              onChange={(e, { value }): void => setSkuEan(value as string)}
            />

            <Form.Checkbox
              radio
              label={"EAN"}
              name={"SKUEAN"}
              value={"EAN"}
              checked={skuEan === "EAN"}
              onChange={(e, { value }): void => setSkuEan(value as string)}
            />
          </Form.Group>
          <Form.Input
            data-testid={"SKU-EAN-input"}
            placeholder={"Type SKU/EAN code.."}
            size={"small"}
            value={productToCopyFrom}
            onChange={(e, { value }): void => setProductToCopyFrom(value)}
          />
        </Form>
      </Modal.Content>

      <Modal.Content>
        <Form>
          <Form.Group inline>
            <Form.Checkbox
              radio
              label={"Overwrite"}
              name={"mergeOverwrite"}
              value={"overwrite"}
              checked={mergeOverwrite === "overwrite"}
              onChange={(e, { value }): void =>
                setMergeOverwrite(value as string)
              }
            />

            <Form.Checkbox
              radio
              label={"Merge"}
              name={"mergeOverwrite"}
              value={"merge"}
              checked={mergeOverwrite === "merge"}
              onChange={(e, { value }): void =>
                setMergeOverwrite(value as string)
              }
            />
          </Form.Group>
          <Form.Checkbox
            label={"Copy name"}
            checked={copyName}
            onChange={(): void => setCopyName(!copyName)}
            value={"copy-name"}
          />
          <Form.Checkbox
            label={"Copy brand"}
            checked={copyBrand}
            onChange={(): void => setCopyBrand(!copyBrand)}
            value={"copy-brand"}
          />
          <Form.Checkbox
            label={"Copy labels"}
            checked={copyLabels}
            onChange={(): void => setCopyLabels(!copyLabels)}
            value={"copy-labels"}
          />
          {customer.config.product_detail_show_product_channel_config && (
            <Popup
              content="This will always overwrite"
              size="tiny"
              trigger={
                <Form.Checkbox
                  label={"Copy channels settings"}
                  checked={copyChannelsConfig}
                  onChange={(): void =>
                    setCopyChannelsConfig(!copyChannelsConfig)
                  }
                  value={"copy-channels-settings"}
                />
              }
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={isLoading}
          data-testid={"copy-modal-close-action"}
          className={"pbutton pbutton-inverse"}
          content={"Close"}
          onClick={childClose}
        />

        <Button
          loading={isLoading}
          data-testid={"copy-modal-copy-action"}
          content={"Copy"}
          className={"pbutton pbutton-primary"}
          onClick={onCopy}
        />
      </Modal.Actions>
    </Modal>
  );
};
