import { MachineTranslationConfigGroup } from "../machine-translation/manage/MachineTranslationConfigGroup/types";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const machineTranslationConfigApi = createApi({
  reducerPath: "machineTranslationConfigApi",
  tagTypes: ["machineTranslationConfig"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/mt/",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    getMachineTranslationConfigs: build.query<
      MachineTranslationConfigGroup[],
      never
    >({
      query: () => "config",
      providesTags: (result) => {
        if (!result) {
          return [{ type: "machineTranslationConfig", id: "LIST" }];
        }
        return [
          ...result.map(({ id }) => ({
            type: "machineTranslationConfig" as const,
            id,
          })),
          { type: "machineTranslationConfig", id: "LIST" },
        ];
      },
    }),
    createMachineTranslationConfig: build.mutation<
      MachineTranslationConfigGroup,
      Omit<MachineTranslationConfigGroup, "id">
    >({
      query: (configGroup) => ({
        url: "config/",
        method: "POST",
        body: configGroup,
      }),
      invalidatesTags: [{ type: "machineTranslationConfig", id: "LIST" }],
    }),
    updateMachineTranslationConfig: build.mutation<
      MachineTranslationConfigGroup,
      MachineTranslationConfigGroup
    >({
      query: ({ id, ...rest }) => ({
        url: `config/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "machineTranslationConfig", id },
      ],
    }),
    deleteMachineTranslationConfig: build.mutation<unknown, number>({
      query: (id) => ({
        url: `config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "machineTranslationConfig", id },
      ],
    }),
  }),
});

export const {
  useGetMachineTranslationConfigsQuery,
  useCreateMachineTranslationConfigMutation,
  useUpdateMachineTranslationConfigMutation,
  useDeleteMachineTranslationConfigMutation,
} = machineTranslationConfigApi;
