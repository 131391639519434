import { ProductId } from "../products/product";
import api from "./api";
import { TreeData, VocabularyTreeData } from "../utils/tagutils";
import { NotificationType } from "../utils/TabNotificationPopup";

// When adding/removing/changing section action please update backend api as every action value is used in endpoint url
export enum SectionAction {
  ITEM_ADD_SETTINGS_NUMBER = "item_add_settings_number",
  ITEM_REMOVE = "item_remove",
  ITEM_REORDER = "item_reorder",
  TEMPLATE_ADD = "template_add",
  TEMPLATE_FIELD_REMOVE = "template_field_remove",
  TEMPLATE_FIELD_SET = "template_field_set",
  VOCABULARY_ADD = "vocabulary_add",
  VOCABULARY_REORDER = "vocabulary_reorder",
  // TODO add api endpoints as these actions are not yet supported/used
  TEMPLATE_SAVE_CATEGORY = "template_save_category",
  TEMPLATE_PREFILL_CATEGORY = "template_prefill_category",
}

export type ItemRemoveRequest = {
  action: SectionAction.ITEM_REMOVE;
  product?: ProductId;
  selected_tag_id?: string;
  tag_id: string;
};

export type ItemReorderRequest = {
  action: SectionAction.ITEM_REORDER;
  tag_id: string;
  section_name: string;
  index: number;
  product?: ProductId;
  selected_tag_id?: string;
};

export type ItemAddSettingsNumberRequest = {
  action: SectionAction.ITEM_ADD_SETTINGS_NUMBER;
  tag_id: string;
  product?: ProductId;
  selected_tag_id?: string;
  subpart_tag_id?: string;
  settings_number_data: {
    number: string;
    type: string;
    value: string;
  };
};

export type TemplateAddRequest = {
  action: SectionAction.TEMPLATE_ADD;
  section_name?: string;
  index: number;
  template: string;
  product?: ProductId;
  selected_tag_id?: string;
};

export type TemplateSaveCategoryRequest = {
  action: SectionAction.TEMPLATE_SAVE_CATEGORY;
  product?: ProductId;
  selected_tag_id?: string;
};

export type TemplatePrefillCategoryRequest = {
  action: SectionAction.TEMPLATE_PREFILL_CATEGORY;
  product?: ProductId;
  selected_tag_id?: string;
};

export type TemplateFieldRemoveRequest = {
  action: SectionAction.TEMPLATE_FIELD_REMOVE;
  tag_id: string;
  field: string;
  product?: ProductId;
  selected_tag_id?: string;
};

export type TemplateFieldSetRequest = {
  action: SectionAction.TEMPLATE_FIELD_SET;
  tag_id: string;
  field: string;
  // FIXME: Generic depending on field type
  tag_value?:
    | { value: string; type: string; category: string }[]
    | string
    | TreeData[];
  text_value?: string;
  product?: ProductId;
  selected_tag_id?: string;
};

export type VocabularyAddRequest = {
  action: SectionAction.VOCABULARY_ADD;
  section_name?: string;
  index: number;
  tag_data: VocabularyTreeData;
  product?: ProductId;
  selected_tag_id?: string;
};

export type VocabularyReorderRequest = {
  action: SectionAction.VOCABULARY_REORDER;
  section_name: string;
  index: number;
  source_tag_id: string;
  target_tag_id: string;
  product?: ProductId;
  selected_tag_id?: string;
};

export type SectionActionRequest =
  | VocabularyAddRequest
  | VocabularyReorderRequest
  | TemplateAddRequest
  | TemplateSaveCategoryRequest
  | TemplatePrefillCategoryRequest
  | TemplateFieldSetRequest
  | TemplateFieldRemoveRequest
  | ItemAddSettingsNumberRequest
  | ItemReorderRequest
  | ItemRemoveRequest;

export enum TemplateFieldType {
  ADVANCED = "A",
  BOOLEAN = "B",
  BULLET_LIST = "P",
  FLOAT = "F",
  FREE_TEXT = "R",
  INTEGER = "I",
  STRING = "S",
  TAG = "T",
  TAG_TREE = "L",
  TEXT = "X",
}

export type TemplateFieldInfoBase = {
  key: string;
  type: TemplateFieldType;
  tag_category: string;
  tag_type: string;
  many: boolean;
  value: any;
};

export type TemplateFieldInfoAdvanced = TemplateFieldInfoBase & {
  key: TemplateFieldType.ADVANCED;
  value: any;
};

export type TemplateFieldInfoBoolean = TemplateFieldInfoBase & {
  key: TemplateFieldType.BOOLEAN;
  value: boolean;
};

export type TemplateFieldInfoBulletList = TemplateFieldInfoBase & {
  key: TemplateFieldType.BULLET_LIST;
  value: boolean;
};

export type TemplateFieldInfoNumber = TemplateFieldInfoBase & {
  key: TemplateFieldType.FLOAT | TemplateFieldType.INTEGER;
  value: number;
};

export type TemplateFieldInfoString = TemplateFieldInfoBase & {
  key: TemplateFieldType.STRING;
  value: string;
};

export type TemplateFieldInfoTag = TemplateFieldInfoBase & {
  key: TemplateFieldType.TAG;
  value: { type: string; category: string; value: string };
};

export type TemplateFieldInfoTagTree = TemplateFieldInfoBase & {
  key: TemplateFieldType.TAG_TREE;
  value: any;
};

export type TemplateFieldInfoText = TemplateFieldInfoBase & {
  key: TemplateFieldType.TEXT;
  value: string;
};

export type TemplateFieldInfo =
  | TemplateFieldInfoAdvanced
  | TemplateFieldInfoBoolean
  | TemplateFieldInfoBulletList
  | TemplateFieldInfoNumber
  | TemplateFieldInfoTag
  | TemplateFieldInfoTagTree
  | TemplateFieldInfoText
  | TemplateFieldInfoString;

export type FormRenderFieldInstruction = {
  type: "field";
  field: TemplateFieldInfo;
};

export type FormRenderDataInstruction = {
  type: "data";
  value: string;
};

export type FormRenderInstruction =
  | FormRenderFieldInstruction
  | FormRenderDataInstruction;

export enum SectionItemType {
  TEMPLATE = "template",
  VOCABULARY = "vocabulary",
  FIXED_SENTENCE = "fixed_sentence",
}

type BaseSectionItem = {
  type: SectionItemType;
};

export type TemplateSectionItem = BaseSectionItem & {
  type: SectionItemType.TEMPLATE;
  data: TemplateFormInfo;
};

export type VocabularySectionItem = BaseSectionItem & {
  type: SectionItemType.VOCABULARY;
  data: VocabularyTreeData[];
};

export type SectionItem = TemplateSectionItem | VocabularySectionItem;

export type SectionDetails = {
  // Internal identifier
  id: number;

  // Internal name of the section
  // e.g. 'headline', this will be references by product tags
  name: string;

  // Name of the section
  // e.g. 'Headline'
  display_name: string;

  // div class name, uses name if set
  // e.g. 'long_description
  div_class: string;

  // render as html
  include_in_new_products: boolean;

  // render as html
  annotate_with_spans: boolean;

  // render as bullet list
  format_as_bullet: boolean;

  // included by default
  default: boolean;

  // List of labels attached to a section, these can be user customized
  labels: string[];

  items: SectionItem[];
};

export type TemplateFormInfo = {
  id: string;
  template_id: string;
  section: string;
  name: string;
  display_name: string;
  fields: Record<string, TemplateFieldInfo>;
  instructions: FormRenderInstruction[];
};

type FetchSectionsRequest = {
  token: string;
  productId: ProductId;
};

export async function fetchSections({
  token,
  productId,
}: FetchSectionsRequest): Promise<SectionDetails[]> {
  const { data } = await api.get(
    `/api/_internal/vocabulary/${productId}/section_fetch_many/`,
    {
      headers: { token },
    }
  );
  return data.sections;
}

export interface ProductSectionActionResult {
  message?: NotificationType;
}

export async function productSectionAction({
  token,
  productId,
  request,
  highlightedTagId,
}: {
  token: string;
  productId: ProductId;
  request: SectionActionRequest;
  highlightedTagId?: string | null;
}): Promise<ProductSectionActionResult> {
  request.product = productId;
  request.selected_tag_id = highlightedTagId;
  const response = await api.post(
    `/api/_internal/vocabulary/section_action/${request.action}/`,
    request,
    {
      headers: { token: token },
    }
  );
  return response.data;
}

// FIXME Legacy code used in ProductSectionsTab.tsx, should be deleted after releasing EnrichTab.tsx
/* istanbul ignore next */
export type TemplateFormResponse = {
  forms: TemplateFormInfo[];
};

/* istanbul ignore next */
export type TemplateSectionWithForms = SectionDetails & {
  forms: TemplateFormInfo[];
};

// Fetch all template forms that are used by a product
// This is a bit expensive as it also parses all the jinja templates
// and builds up form render instructions.
/* istanbul ignore next */
export async function fetchTemplateForms(
  token: string,
  productId: ProductId
): Promise<TemplateFormResponse> {
  const response = await api.get(
    `/api/_internal/product/${productId}/template_form/`,
    {
      headers: { token },
    }
  );
  return response.data;
}

// section name -> section + tags
export type SectionsWithTags = Record<
  string,
  SectionDetails & { tag_ids: string[] }
>;

export type TemplateSectionTagsResponse = {
  tagSections: SectionsWithTags;
};

/* istanbul ignore next */
export async function fetchTemplateSectionTags(
  token: string,
  productId: ProductId
): Promise<TemplateSectionTagsResponse> {
  const { data } = await api.get(
    `/api/_internal/product/${productId}/template_section_tags/`,
    {
      headers: { token },
    }
  );
  return {
    tagSections: data.tag_sections,
  };
}
