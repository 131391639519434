import React from "react";
import styled from "styled-components";

const BackgroundLockDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 8;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
`;

const InnerContentDiv = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding-left: 20%;
`;

type Props = {
  innerContent: JSX.Element;
};
export const LockScreen: React.FC<Props> = ({ innerContent }) => {
  return (
    <BackgroundLockDiv data-testid={"lock-screen"}>
      <InnerContentDiv>{innerContent}</InnerContentDiv>
    </BackgroundLockDiv>
  );
};
