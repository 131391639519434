import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";

class User {
  id: number;
  email: string;
  full_name: string;
  is_staff: boolean;
}

class Credential {
  id: number;
  name: string;
  role: string | null;

  constructor(data: { id: number; name: string; role: string | null }) {
    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
  }

  get role_is_superuser(): boolean {
    return this.role === null || this.role === CredentialRole.Superuser;
  }

  get role_is_copywriter(): boolean {
    return (
      this.role === null ||
      this.role === CredentialRole.Superuser ||
      this.role === CredentialRole.Copywriter
    );
  }

  get role_is_external(): boolean {
    return (
      this.role === null ||
      this.role === CredentialRole.Superuser ||
      this.role === CredentialRole.Copywriter ||
      this.role === CredentialRole.External
    );
  }
}

enum CredentialRole {
  Superuser = "S",
  Copywriter = "C",
  External = "E",
}

export class Auth {
  data: {
    user: User;
    credential: Credential | null;
  };
  constructor(data: {
    user: User;
    credential: { id: number; name: string; role: string | null };
  }) {
    this.data = {
      user: data.user,
      credential: data.credential ? new Credential(data.credential) : null,
    };
  }

  get user(): User {
    return this.data.user;
  }

  get credential(): Credential | null {
    return this.data.credential;
  }
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/auth",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    getAuth: build.query<Auth, void>({
      query: () => `details/`,
      transformResponse: (response) => new Auth(response as any),
    }),
  }),
});

export const { endpoints, useGetAuthQuery } = authApi;
