import React, { useEffect, useState } from "react";
import Styled from "styled-components";

import { ProductFilterOrderByDate } from "./ProductFilterOrderByDate";
import { ProductFilterAddFilter } from "./ProductFilterAddFilter";
import { ProductFilterChoice } from "./ProductFilterChoice";
import { ProductFilterTemplate } from "./ProductFilterTemplate";
import { ProductFilterVocabulary } from "./ProductFilterVocabulary";
import { ProductListAction } from "../ProductList";
import { FullTag } from "../../../utils/tagutils";
import { Grid, Popup } from "semantic-ui-react";
import { ProductFilterMultipleChoice } from "./ProductFilterMultipleChoice";
import { getAllCustomerBrands, getLabelOptions } from "../../../api/action";
import {
  DateCategory,
  Filterkeys,
  ParentChildStatus,
  ProductStatusFilter,
  TextStatus,
  TranslationStatus,
  ItemsWithOperator,
  AndOrOperator,
} from "../ProductListTypes";
import { CustomQueryType } from "../../../api/productListSlice";
import { popupDelay } from "../../../customers/gpt/types";

const MainContainer = Styled.div`
    & {
      .disabled {
        div {
          pointer-events: none;
          opacity: 0.7;
        }
    }
  }
`;

interface Filters {
  brands: boolean;
  [key: string]: boolean;
  parentChild: boolean;
  template: boolean;
  vocabulary: boolean;
}

type Props = {
  brands: string[];
  dateCategory: DateCategory;
  dateEnd: Date | null;
  dateStart: Date | null;
  labelStatus: ItemsWithOperator;
  onAction: (action: ProductListAction, value?: unknown) => void;
  parentChildStatus: ParentChildStatus;
  productStatus: ProductStatusFilter;
  templateStatus: string | null;
  textStatus: TextStatus;
  textualAppName: string;
  translationStatus: TranslationStatus;
  vocabularyStatus: FullTag | null;
  query: CustomQueryType | null;
};

export const ProductFilterTopBar: React.FC<Props> = ({
  brands,
  dateCategory,
  dateEnd,
  dateStart,
  labelStatus,
  onAction,
  parentChildStatus,
  productStatus,
  templateStatus,
  textStatus,
  textualAppName,
  translationStatus,
  vocabularyStatus,
  query,
}): React.ReactElement => {
  const [filters, setFilters] = useState<Filters>({
    brands: false,
    parentChild: false,
    template: false,
    vocabulary: false,
  });

  let className = "enabled";
  if (query?.query) {
    className = "disabled";
  }

  const toggleFilter = (
    filterKey: Filterkeys,
    filterValue: boolean,
    action?: ProductListAction,
    value?: string | string[] | {}
  ): void => {
    setFilters((prev) => ({ ...prev, [filterKey]: filterValue }));
    if (action && value !== undefined) onAction(action, value);
  };

  useEffect(() => {
    toggleFilter(Filterkeys.Brands, !!brands?.length);
  }, [brands]);

  useEffect(() => {
    toggleFilter(Filterkeys.Template, !!templateStatus);
  }, [templateStatus]);

  useEffect(() => {
    toggleFilter(
      Filterkeys.ParentChild,
      parentChildStatus != ParentChildStatus.All
    );
  }, [parentChildStatus]);

  useEffect(() => {
    toggleFilter(Filterkeys.Vocabulary, !!vocabularyStatus);
  }, [vocabularyStatus]);

  return (
    <div>
      <MainContainer>
        <ProductFilterOrderByDate
          dateCategory={dateCategory}
          dateEnd={dateEnd}
          dateStart={dateStart}
          onAction={onAction}
        />
        <Popup
          content="A custom query is active"
          size="tiny"
          disabled={!query?.query}
          position="top center"
          mouseEnterDelay={popupDelay}
          trigger={
            <div
              className={className}
              data-testid="product-filter-top-bar-filter-wrapper"
            >
              <Grid data-testid={"static-filter-container"}>
                <ProductFilterChoice
                  action={ProductListAction.ChangeProductStatusFilter}
                  defaultValue={productStatus}
                  label={"Product status"}
                  onAction={onAction}
                  options={[
                    {
                      key: 1,
                      text: "Show all",
                      value: ProductStatusFilter.All,
                    },
                    {
                      key: 2,
                      text: "Importing",
                      value: ProductStatusFilter.Importing,
                    },
                    {
                      key: 3,
                      text: "In progress",
                      value: ProductStatusFilter.Progress,
                    },
                    { key: 4, text: "Ready", value: ProductStatusFilter.Ready },
                  ]}
                />
                <ProductFilterChoice
                  action={ProductListAction.ChangeTextStatus}
                  defaultValue={textStatus}
                  label={"Text status"}
                  onAction={onAction}
                  options={[
                    { key: 1, text: "Show all", value: TextStatus.All },
                    {
                      key: 2,
                      text: "Needs review",
                      value: TextStatus.InReview,
                    },
                    { key: 3, text: "Approved", value: TextStatus.Approved },
                    {
                      key: 4,
                      text: "Waiting to be published",
                      value: TextStatus.WaitingToBePublished,
                    },
                    { key: 5, text: "Published", value: TextStatus.Published },
                  ]}
                />
                <ProductFilterChoice
                  action={ProductListAction.ChangeTranslationStatus}
                  defaultValue={translationStatus}
                  label={"Translation status"}
                  onAction={onAction}
                  options={[
                    { key: 1, text: "Show all", value: TranslationStatus.All },
                    {
                      key: 2,
                      text: "Missing translations",
                      value: TranslationStatus.Missing,
                    },
                    {
                      key: 3,
                      text: "Complete translations",
                      value: TranslationStatus.Complete,
                    },
                  ]}
                />
                <ProductFilterAddFilter
                  filters={filters}
                  toggleFilter={toggleFilter}
                />
              </Grid>
              <Grid data-testid={"optional-filter-container"}>
                <ProductFilterMultipleChoice
                  action={ProductListAction.ChangeLabel}
                  data-testid="product-filter-labels"
                  filterKey={Filterkeys.Labels}
                  initialData={labelStatus.items}
                  label="Labels"
                  onAction={(action, value) => {
                    onAction(action, {
                      items: value,
                      operator: labelStatus.operator,
                    });
                  }}
                  onFetchOptions={getLabelOptions}
                  toggleFilter={toggleFilter}
                  removable={false}
                  showOperator={true}
                  onChangeOperator={(operator: AndOrOperator) => {
                    onAction(ProductListAction.ChangeLabel, {
                      items: labelStatus.items,
                      operator: operator,
                    });
                  }}
                  operator={labelStatus.operator}
                />
                {filters.parentChild && (
                  <ProductFilterChoice
                    action={ProductListAction.ChangeParentChildStatus}
                    defaultValue={parentChildStatus}
                    toggleFilter={toggleFilter}
                    label={"Parent-child relations"}
                    onAction={onAction}
                    options={[
                      {
                        key: 1,
                        text: "Show all",
                        value: ParentChildStatus.All,
                      },
                      {
                        key: 2,
                        text: "Parents",
                        value: ParentChildStatus.Parents,
                      },
                      {
                        key: 3,
                        text: "Children",
                        value: ParentChildStatus.Children,
                      },
                    ]}
                  />
                )}
                {filters.template && (
                  <ProductFilterTemplate
                    action={ProductListAction.ChangeTemplate}
                    data-testid={"product-filter-template"}
                    filterKey={Filterkeys.Template}
                    toggleFilter={toggleFilter}
                    initialData={templateStatus}
                    onAction={onAction}
                  />
                )}
                {filters.vocabulary && (
                  <ProductFilterVocabulary
                    data-testid={"product-filter-vocabulary"}
                    filterKey={Filterkeys.Vocabulary}
                    toggleFilter={toggleFilter}
                    initialData={vocabularyStatus}
                    onAction={onAction}
                    textualAppName={textualAppName}
                  />
                )}
                {filters.brands && (
                  <ProductFilterMultipleChoice
                    action={ProductListAction.ChangeBrands}
                    data-testid={"product-filter-brands"}
                    filterKey={Filterkeys.Brands}
                    initialData={brands}
                    label={"Brands"}
                    onAction={onAction}
                    onFetchOptions={getAllCustomerBrands}
                    toggleFilter={toggleFilter}
                  />
                )}
              </Grid>
            </div>
          }
        />
      </MainContainer>
    </div>
  );
};
