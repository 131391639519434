import React from "react";

type Props = {
  currentLangstringData: { [key: string]: string };
  exactTemplateVariable: string;
  onChange?: (data: { [key: string]: string }) => void;
};

export const EditTemplateInlineSingleVariable: React.FC<Props> = ({
  currentLangstringData,
  exactTemplateVariable,
  onChange,
}) => {
  return (
    <textarea
      className="tw-px-2 tw-pt-1"
      data-testid={"edit-template-inline-single-variable-textarea"}
      onChange={({ target }): void => {
        onChange?.({
          ...currentLangstringData,
          [exactTemplateVariable]: target.value,
        });
      }}
      value={currentLangstringData[exactTemplateVariable]}
    />
  );
};
