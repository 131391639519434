import React, { useEffect, useRef, useState } from "react";
import { ProductId } from "../product";
import { useGetCustomerQuery } from "../../api/customerApi";
import {
  fetchProductDetail,
  fetchProductRecommendations,
  ProductTagAction,
  ProductTagRequest,
  productTagsAction,
} from "../../api/action";
import { refreshSelected, SidebarItem } from "./SidebarItem";
import { ProductDetailInfo } from "./ProductDetailInfo";
import {
  emptyProductRecommendations,
  ProductRecommendations,
} from "./ProductRecommendations";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { queuePreviewTextGeneration } from "../../utils/GlobalFunctions";
import { Spinner } from "../../utils/Spinner";
import { EditSidebar } from "./EditSidebar";
import { EditFields } from "./EditFields";

export type onTagsActionCallback = (request: ProductTagRequest) => any;

type Props = {
  productId: ProductId;
  textualAppName: string;
  selected: SidebarItem;
};

/**
 * Edit tab in Product Details.
 * Contains a sidebar with attributes, see {@link EditSidebar}
 * Contains a list of fields in the right hand side, see {@link EditFields}
 * Contains a list of fields in the right hand side
 * It's stateful and stores:
 * - customer: customer info, only config part is used
 * - details: a wrapper around all data needed on the tab
 * - selected: the currently selected sidebar item
 * - quickSearchMessage: result from a quick search action
 * -  It proxies all product tags updates via onTagsAction
 */

export const ProductDetailEditTab: React.FC<Props> = ({
  productId,
  textualAppName,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const [details, setDetails] = useState<ProductDetailInfo | null>(null);
  const [recommendations, setRecommendations] = useState<
    ProductRecommendations
  >(emptyProductRecommendations());
  const [
    currentlySelected,
    setCurrentlySelected,
  ] = useState<SidebarItem | null>(null);
  const [quickSearchMessage, setQuickSearchMessage] = useState<string | null>(
    null
  );
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && customer) {
      fetchProductDetail(token, customer, productId).then((details) => {
        updateDetails(details);
      });
    }
    return (): void => {
      mounted.current = false;
    };
  }, [customer]);

  useEffect(() => {
    if (currentlySelected) {
      fetchRecommendations();
    }
  }, [currentlySelected]);

  const fetchRecommendations = (): void => {
    let selectedVocabId: number | null = null;
    if (currentlySelected?.kindTag) {
      selectedVocabId = currentlySelected.kindTag.vocabulary_id;
      fetchProductRecommendations({
        token,
        productId,
        vocabId: selectedVocabId,
      }).then(setRecommendations);
    }
  };

  const updateDetails = (
    details: ProductDetailInfo,
    quickSearchMessage: string | null = null,
    newSelectedId: string | null = null
  ): void => {
    let newlySelected;
    if (currentlySelected) {
      newlySelected =
        refreshSelected(
          details.getMainCategorySidebarItem(),
          newSelectedId || currentlySelected.id
        ) || details.getMainCategorySidebarItem();
    }
    if (!currentlySelected) {
      newlySelected = details.getMainCategorySidebarItem();
    }

    details.textualAppName = textualAppName;

    setDetails(details);
    setCurrentlySelected(newlySelected);
    setQuickSearchMessage(quickSearchMessage);
  };

  const onTagsAction = (request: ProductTagRequest): void => {
    if (customer === null || details === null || details.productId == null) {
      return;
    }
    productTagsAction(token, customer, details.productId, request).then(
      (response) => {
        queuePreviewTextGeneration();
        let newSelected = null;
        const { details, message, result } = response;
        if (request.action == ProductTagAction.ADD_SUBPART && result !== null) {
          newSelected = result.id;
        }
        updateDetails(details, message, newSelected);
      }
    );
  };

  if (isLoading || details === null || currentlySelected == null) {
    return <Spinner />;
  }

  return (
    <div className={"edit-fields"}>
      <div className={"left"}>
        <EditSidebar
          details={details}
          onSelected={(menuItem): void => {
            setCurrentlySelected(menuItem);
          }}
          onTagsAction={(request): void => onTagsAction(request)}
          recommendations={recommendations}
          selected={currentlySelected}
        />
      </div>
      <div className={"right"}>
        <EditFields
          details={details}
          onTagsAction={(request): void => onTagsAction(request)}
          quickSearchMessage={quickSearchMessage}
          recommendations={recommendations}
          selected={currentlySelected}
        />
      </div>
    </div>
  );
};
