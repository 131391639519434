export enum ColumnValue {
  SKU = "sku",
  EAN = "EAN",
  OriginalText = "original_text",
  TextStatuses = "text_statuses",
  GenerateText = "generated_text",
  Product = "product",
  ProductType = "product_type",
  Status = "status",
  Labels = "labels",
  Parent = "parent",
  Created = "created",
  Updated = "updated",
  GroupChildren = "group_children",
}

export const ColumnLabel: Record<string, string> = {
  [ColumnValue.SKU]: "SKU",
  [ColumnValue.EAN]: "EAN",
  [ColumnValue.OriginalText]: "Original Text",
  [ColumnValue.TextStatuses]: "Text Statuses",
  [ColumnValue.GenerateText]: "Generated Text",
  [ColumnValue.Product]: "Product",
  [ColumnValue.ProductType]: "Namespace",
  [ColumnValue.Status]: "Status",
  [ColumnValue.Labels]: "Labels",
  [ColumnValue.Parent]: "Parent",
  [ColumnValue.Created]: "Created at",
  [ColumnValue.Updated]: "Updated at",
  [ColumnValue.GroupChildren]: "Group children with parent",
};
export const defaultColumns = [
  ColumnValue.SKU,
  ColumnValue.GenerateText,
  ColumnValue.Status,
  ColumnValue.Labels,
  ColumnValue.Parent,
  ColumnValue.Created,
];
