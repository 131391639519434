import React from "react";
import { List } from "semantic-ui-react";
import {
  SectionAction,
  SectionActionRequest,
  SectionItemType,
} from "../../api/sectionActions";
import { SectionSearchItem } from "./ProductDetailEnrichTab";
import styled from "styled-components";

const StyledListItem = styled(List.Item)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .content {
      padding: 0;
    }
    .material-icons {
      padding-right: 15px;
    }
  }
`;

const SearchItemIcons: Record<string, string> = {
  [SectionItemType.FIXED_SENTENCE]: "notes",
  [SectionItemType.TEMPLATE]: "description",
  [SectionItemType.VOCABULARY]: "chat_bubble_outline",
};

type Props = {
  onSectionItemAction?: (request: SectionActionRequest) => void;
  sectionSearchItem: SectionSearchItem;
  showIcons: boolean;
};

export const EnrichTabSearchResultItem: React.FC<Props> = ({
  onSectionItemAction,
  sectionSearchItem,
  showIcons,
}) => {
  let actionRequest: SectionActionRequest;
  let description: string;
  switch (sectionSearchItem.type) {
    case SectionItemType.TEMPLATE:
      actionRequest = {
        action: SectionAction.TEMPLATE_ADD,
        index: 0,
        template: sectionSearchItem.data.name,
      };
      description = sectionSearchItem.data.display_name;
      break;
    case SectionItemType.VOCABULARY:
    case SectionItemType.FIXED_SENTENCE:
      actionRequest = {
        action: SectionAction.VOCABULARY_ADD,
        index: 0,
        tag_data: sectionSearchItem.data,
      };
      description = sectionSearchItem.data.master_display_name;
      break;
    default:
      throw new Error("Unhandled action case");
  }

  return (
    <StyledListItem
      key={sectionSearchItem.data.id}
      data-testid={"search-result-item"}
      onClick={(): void => {
        onSectionItemAction?.(actionRequest);
      }}
    >
      {showIcons && (
        <i className="material-icons">
          {SearchItemIcons[sectionSearchItem.type]}
        </i>
      )}
      <List.Content description={description} />
    </StyledListItem>
  );
};
