import { ChannelLanguagePairData, ProductId } from "../products/product";
import { LanguageCode } from "../customers/customerlanguages";
import {
  Customer,
  CustomerChannelLanguagePairData,
} from "../customers/Customer";
import { TextGeneratorError } from "../utils/types";

export type ProductTextRefKey = string;

export class ProductTextRef {
  productId: ProductId;
  channelId: number;
  languageCode: LanguageCode;
  constructor(
    productId: ProductId,
    channelId: number,
    languageCode: LanguageCode
  ) {
    this.productId = productId;
    this.channelId = channelId;
    this.languageCode = languageCode;
  }

  public static fromKey(key: ProductTextRefKey): ProductTextRef {
    const parts = key.split("_");
    if (parts.length != 4) {
      throw new Error(`Invalid product key: ${key}`);
    }
    const [productId, channelId, string] = parts;
    return new ProductTextRef(
      productId,
      parseInt(channelId, 10),
      string as LanguageCode
    );
  }

  public static refKey(
    productId: ProductId,
    channelId: number,
    languageCode: LanguageCode
  ): string {
    return `${productId}_${channelId}_${languageCode}`;
  }

  get key(): string {
    return ProductTextRef.refKey(
      this.productId,
      this.channelId,
      this.languageCode
    );
  }

  apiParam(): {
    product_id: ProductId;
    channel_id: number;
    language_code: LanguageCode;
  } {
    const { productId, channelId, languageCode } = this;
    return {
      product_id: productId,
      channel_id: channelId,
      language_code: languageCode,
    };
  }

  markedForPublish(
    customer: Customer,
    pairs: ChannelLanguagePairData[]
  ): boolean {
    if (pairs.length > 0) {
      // Determine if a product text is marked for publish based on selected channel-language pairs
      const isEqual = (pair: ChannelLanguagePairData): boolean =>
        pair.language_code == this.languageCode &&
        pair.channel_id == this.channelId;
      return pairs.find(isEqual) != undefined;
    } else {
      // Determine if it is marked for publish based on customer default channel-language pairs
      const isEqual = (pair: CustomerChannelLanguagePairData): boolean =>
        pair.language_code == this.languageCode &&
        pair.channel_id == this.channelId &&
        pair.default;
      return customer.channel_language_pairs.find(isEqual) != undefined;
    }
  }
}

export class ProductText {
  _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get ref(): ProductTextRef {
    return new ProductTextRef(
      this.productId,
      this.customerChannelId,
      this.languageCode
    );
  }

  get approved(): string | null {
    return this._data.approved;
  }

  get customerChannelId(): number {
    // TODO doublecheck if the backend change is not required
    return this._data.customer_channel
      ? this._data.customer_channel.id
      : this._data.customer_channel_id;
  }

  get comment(): string | null {
    return this._data.comment;
  }

  get data(): string | null {
    return this._data.data;
  }

  get editedData(): string | null {
    return this._data.edited_data;
  }

  get id(): number {
    return this._data.id;
  }

  get isEdited(): boolean {
    return this.editedData != null;
  }

  get languageCode(): LanguageCode {
    return this._data.language_code;
  }

  get productId(): ProductId {
    return this._data.product_id;
  }

  get published(): string | null {
    return this._data.published;
  }

  get firstPublished(): string | null {
    return this._data.first_published;
  }

  get text(): string | null {
    return this.editedData || this.data;
  }

  get missingTranslation(): boolean {
    return this._data.missing_translation;
  }

  get failed(): boolean {
    return this._data.failed;
  }

  get markForPublish(): boolean {
    return !!this._data.mark_for_publish;
  }

  get needsReview(): boolean {
    return !!this._data.needs_review;
  }

  get waitingToBePublished(): boolean {
    return this._data.waiting_to_be_published;
  }

  shouldRegenerate(): boolean {
    if (this.markForPublish) {
      return false;
    } else if (this.editedData) {
      return false;
    } else if (this.approved) {
      return false;
    } else if (this.published) {
      return false;
    }
    return true;
  }
  get error(): TextGeneratorError | null {
    return this._data.error;
  }
}
