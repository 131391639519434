import React, { useEffect, useState } from "react";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { ProductTextViewerComp } from "../../producttext/ProductTextViewer";
import { Product } from "../product";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import styled from "styled-components";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";

export const ChannelNameAndContentDiv = styled.div`
  padding: 10px;
  vertical-align: top;
  height: 100%;
`;

export const ChannelName = styled.div`
  font-weight: 600;
  color: #21284c;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

export const TextBox = styled.div`
  padding: 12px 20px 12px 15px;
  background: #f4f5f9;
  padding: 20px;
  min-height: 135px;
  text-align: initial;
  position: relative;
}
`;

type Props = {
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
  product: Product;
  productTextRef: ProductTextRef;
  text: ProductText;
};

export const TranslationsModalChannelProductTextItem: React.FC<Props> = ({
  onRequestProductTextAction,
  product,
  productTextRef,
  text,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const [channel, setChannel] = useState("");

  useEffect(() => {
    if (!isLoading) {
      setChannel(
        customer.channels.find(
          (channel) => channel.id === text.customerChannelId
        )?.display_name
      );
    }
  }, [customer, text, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!channel) {
    return <></>;
  }

  return (
    <ChannelNameAndContentDiv
      data-testid={"translations-modal-channel-product-text-item"}
    >
      <ChannelName>{channel}</ChannelName>
      <TextBox>
        <ProductTextViewerComp
          key={text.id}
          markedForPublish={productTextRef.markedForPublish(
            customer,
            product.channel_language_pairs
          )}
          onRequestProductTextAction={onRequestProductTextAction}
          productTextRef={productTextRef}
          productText={text}
        />
      </TextBox>
    </ChannelNameAndContentDiv>
  );
};
