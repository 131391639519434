import {
  FullTag,
  isKind,
  isMaterial,
  isSubpart,
  formatTag,
  VocabularyTreeData,
} from "../../utils/tagutils";

export interface SidebarItem {
  id: string | null;
  name: string;
  childItems: SidebarItem[];
  kindTag: VocabularyTreeData | null;
  tags: VocabularyTreeData[];
  vocabularyIds: string[];
  isSubpart: boolean;
}

// The currently selected sidebar item is stored as a separate state,
// this function is used to refresh it from an updated tags list.
export function buildSidebarItems(
  tags: FullTag[],
  subpart: boolean
): SidebarItem[] {
  return tags
    .filter((tag) => isSubpart(tag))
    .map((tag) => {
      let kind = null;
      let kindTag = null;
      let material = null;
      const subparts = [];
      const childItems = [];
      const vocabularyIds = [];
      const { tags, id } = tag;
      if (tags) {
        for (const subtag of tags) {
          if (isKind(subtag) && kind == null) {
            kindTag = subtag;
            kind = formatTag(kindTag, {
              includeSubTagNames: true,
            });
          }
          if (isMaterial(subtag)) {
            material = formatTag(subtag, {
              includeSubTagNames: true,
            });
          }
          if (isSubpart(subtag)) {
            subparts.push(subtag);
          }
          if (subtag.vocabulary_id) {
            vocabularyIds.push(subtag.vocabulary_id.toString());
          }
        }
        if (subparts.length) {
          childItems.push(...buildSidebarItems(subparts, true));
        }
      }
      return {
        childItems,
        id,
        kindTag,
        name: kind || material,
        tags,
        vocabularyIds,
        isSubpart: subpart,
      };
    });
}

export function refreshSelected(
  root: SidebarItem,
  searchingFor: string
): SidebarItem | null {
  const find = (
    item: SidebarItem,
    searchingFor: string
  ): SidebarItem | null => {
    if (item.id === searchingFor) {
      return item;
    }
    for (const child of item.childItems) {
      const found = find(child, searchingFor);
      if (found !== null) {
        return found;
      }
    }
    return null;
  };
  return find(root, searchingFor);
}
