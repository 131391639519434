export const getTagCatType = (isExist: string, defaultValue: string): string =>
  isExist ? isExist : defaultValue;

export const getURL = (textualAppName: string): string =>
  textualAppName ? `/app/${textualAppName}/1/` : "/app/djangowebapptest/1/";

export type FieldOption = {
  label: string;
  value: string;
};
