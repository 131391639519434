import React from "react";
import { Grid, Dropdown } from "semantic-ui-react";
import { addBrand } from "../api/action";

const getOptions = (data, textField, valueField) => {
  return data.map((item) => ({
    key: item.id,
    text: item[textField],
    value: item[valueField],
  }));
};

export default class BrandSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandValue: "",
      dropdownOptions: [],
    };
  }

  componentDidMount() {
    const { initialData, availableChoices, onLoaded } = this.props;
    onLoaded(this);

    let dropdownOptions = [];
    if (availableChoices && availableChoices.length > 0) {
      dropdownOptions = getOptions(availableChoices, "name", "id");
      this.setState({
        brandValue: initialData ? initialData.id : "",
        dropdownOptions,
      });
    } else if (initialData && initialData.id) {
      dropdownOptions = [
        {
          key: initialData.id,
          text: initialData.name,
          value: initialData.id,
        },
      ];
    }
    this.setState({
      brandValue: initialData ? initialData.id : "",
      dropdownOptions,
    });
  }

  addBrandAfterApiCall = (data) => {
    const { dropdownOptions } = this.state;
    const { onDataChanged } = this.props;
    if (data && data.id) {
      dropdownOptions.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
      this.setState({
        brandValue: data.id,
        dropdownOptions,
      });
      onDataChanged(this, data);
    }
  };

  addExistingBrand = (brandIndex) => {
    const { dropdownOptions } = this.state;
    const { onDataChanged } = this.props;
    const selectedBrand = {
      id: dropdownOptions[brandIndex].value,
      name: dropdownOptions[brandIndex].text,
    };
    this.setState({
      brandValue: dropdownOptions[brandIndex].value,
      dropdownOptions: dropdownOptions,
    });
    onDataChanged(this, selectedBrand);
  };

  handleBrandChange = (e, { value }) => {
    const { token, textualAppName, onDataChanged } = this.props;
    if (value) {
      const addBrandPostData = { name: value };
      const { dropdownOptions } = this.state;
      let brandIndex = -1;
      if (dropdownOptions.length > 0) {
        brandIndex = dropdownOptions.findIndex(
          (brand) => brand.value === value
        );
      }
      if (brandIndex === -1) {
        addBrand(addBrandPostData, token, textualAppName).then((response) => {
          this.addBrandAfterApiCall(response.data);
        });
      } else {
        this.addExistingBrand(brandIndex);
      }
    } else {
      this.setState({ brandValue: "" });
      onDataChanged(this, {});
    }
  };

  render() {
    const { componentId } = this.props;
    const { dropdownOptions, brandValue } = this.state;
    return (
      <Grid>
        <Dropdown
          clearable
          id={componentId}
          options={dropdownOptions || []}
          placeholder="Choose brand"
          search
          selection
          fluid
          allowAdditions
          value={brandValue}
          onChange={this.handleBrandChange}
          selectOnBlur={false}
          closeOnBlur={true}
          closeOnChange
        />
      </Grid>
    );
  }
}
