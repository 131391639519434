import React, { useState } from "react";
import {
  ExplainTemplateField,
  ExplainTemplateFieldKeyType,
  TagCategoryTypeMap,
  TagCategoryTypeMapKeyType,
  TemplateField,
  TemplateFieldTypeMap,
  TemplateFieldTypeMapKeyType,
} from "../template";
import { Button, Dropdown } from "../../components/tailwind";
import { Option } from "../../components/tailwind/dropdown/Dropdown";

const hiddenTemplateFieldTypes = [
  "Advanced",
  "Bullet List",
  "Text",
  "Free Text",
];

const advancedTemplateFieldTypes = ["Boolean", "Float", "Integer"];
const FORBIDDEN_KEYS = ["category"];

export const isKeyForbidden = (key: string): boolean => {
  return FORBIDDEN_KEYS.includes(key.toLowerCase());
};

type Props = {
  index?: number;
  variable?: TemplateField;
  onUpdateVariable: (
    index: number,
    param: {
      type: TemplateFieldTypeMapKeyType;
      key: string;
      tag?: TagCategoryTypeMapKeyType;
    }
  ) => void;
  onRemoveVariable?: (index: number) => void;
};

export const getTemplatefieldTypeOptions = (): Option<
  TemplateFieldTypeMapKeyType
>[] => {
  const options: Option<TemplateFieldTypeMapKeyType>[] = [];
  const advancedOptions: Option<TemplateFieldTypeMapKeyType>[] = [
    {
      key: "adv-header",
      value: "" as TemplateFieldTypeMapKeyType,
      text: "Advanced",
      type: "header",
    },
  ];
  Object.entries(TemplateFieldTypeMap)
    .filter(
      (entries) => !hiddenTemplateFieldTypes.includes(entries[1] as string)
    )
    .forEach(([key, value]) => {
      const option = {
        key: key,
        value: key as TemplateFieldTypeMapKeyType,
        text: value,
        caption: ExplainTemplateField?.[key as ExplainTemplateFieldKeyType],
      };
      if (advancedTemplateFieldTypes.includes(value as string)) {
        advancedOptions.push(option);
      } else {
        options.push(option);
      }
    });
  return [...options, ...advancedOptions];
};
export const TemplateBuilderManageVariableInput: React.FC<Props> = ({
  index = -1,
  variable,
  onUpdateVariable,
  onRemoveVariable,
}) => {
  const [variableKey, setVariableKey] = useState(variable?.key || "");
  const [variableType, setVariableType] = useState(variable?.type);
  const [selectedTag, setSelectedTag] = useState(variable?.tag);
  const [edit, setEdit] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const showTagDropdown = TemplateFieldTypeMap?.[variableType] === "Tag";

  const validateInput = (input: string): void => {
    setValidationError(null);
    if (isKeyForbidden(input)) {
      setValidationError("Variable name blocked.");
      return;
    }
    if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/.test(input)) {
      setValidationError("Invalid variable name.");
    }
  };

  if (!edit && variable) {
    return (
      <div className="tw-grid tw-grid-cols-[repeat(2,_1fr)_200px] tw-gap-4">
        <p className="tw-border-b-[1px]">{variableKey}</p>
        <p className="tw-border-b-[1px]">
          {TemplateFieldTypeMap?.[variableType]}{" "}
          {selectedTag && showTagDropdown && (
            <>
              -{" "}
              <span className="tw-text-sm tw-text-gray-600">
                {TagCategoryTypeMap?.[selectedTag]}
              </span>
            </>
          )}
        </p>

        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <Button
            data-testid={`template-builder-manage-variable-${index}-edit-action`}
            content="Edit"
            variant="primary"
            size="sm"
            compact
            onClick={(): void => setEdit(true)}
          />
          <Button
            data-testid={`template-builder-manage-variable-${index}-remove-action`}
            content="Remove"
            variant="secondary-alt"
            size="sm"
            compact
            disabled={!variableKey || !variableType}
            onClick={(): void => {
              onRemoveVariable?.(index);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="tw-grid tw-grid-cols-[repeat(2,_1fr)_200px] tw-gap-x-4">
      <input
        data-testid={`template-builder-manage-variable-${index}-name-input`}
        className="tw-form-input tw-block tw-rounded-md tw-border-0 tw-p-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset"
        placeholder="Name your variable..."
        value={variableKey}
        onChange={({ target }): void => {
          validateInput(target.value);
          setVariableKey(target.value);
        }}
      />

      <div className="tw-flex tw-gap-2">
        <div className="tw-flex-1">
          <Dropdown<TemplateFieldTypeMapKeyType>
            testIdBase={`template-builder-manage-variable-${index}-type-dropdown`}
            options={getTemplatefieldTypeOptions()}
            placeholder="Select Type"
            value={variableType}
            onChange={(value): void => {
              setVariableType(value as TemplateFieldTypeMapKeyType);
            }}
            clearable
          />
        </div>
        {showTagDropdown && (
          <div className="tw-flex-1">
            <Dropdown<TagCategoryTypeMapKeyType>
              testIdBase={`template-builder-manage-variable-${index}-tag-dropdown`}
              options={Object.entries(TagCategoryTypeMap).map(
                ([key, value]) => ({
                  key: key,
                  value: key as TagCategoryTypeMapKeyType,
                  text: value,
                })
              )}
              placeholder="Select Tag"
              value={selectedTag}
              onChange={(value): void => {
                setSelectedTag(value as TagCategoryTypeMapKeyType);
              }}
              clearable
            />
          </div>
        )}
      </div>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        <Button
          className={!variable ? "tw-col-span-2" : ""}
          data-testid={`template-builder-manage-variable-${index}-save-action`}
          variant="primary"
          size="sm"
          compact
          disabled={!variableKey || !variableType || !!validationError}
          onClick={(): void => {
            setEdit(false);
            onUpdateVariable(index, {
              key: variableKey,
              type: variableType,
              tag: showTagDropdown ? selectedTag : undefined,
            });
            if (!variable) {
              setVariableKey("");
              setVariableType(undefined);
              setSelectedTag(undefined);
            }
          }}
          content={variable ? "Update" : "Create"}
        />
        {variable && (
          <Button
            data-testid={`template-builder-manage-variable-${index}-cancel-edit-action`}
            variant="secondary-alt"
            size="sm"
            content="Cancel"
            onClick={(): void => setEdit(false)}
          />
        )}
      </div>
      {validationError && (
        <p className="tw-text-red-500 tw-row-span-3 tw-text-sm">
          {validationError}
        </p>
      )}
    </div>
  );
};
