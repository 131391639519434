import React, { useContext, useState } from "react";
import { StyledCardContent } from "./GeneratedSingleResult";
import { Text } from "../../../components/Text";
import { Button, Icon, Loader, Popup } from "semantic-ui-react";
import { generatedSingleResultTestIds } from "../testUtils/testIdsSelectors";
import { fixLongName } from "../utils";
import { popupDelay } from "../../../customers/gpt/types";
import { GptGenerationTaskQueue } from "../../../api/gptApi";
import { CopyAssistantContext } from "../CopyAssistantContext/CopyAssistantContext";

export const STREAMING_UNKNOWN_SECTION_NAME_HEADER = "Generating...";

type Props = {
  sectionName: string;
  increment: () => void;
  decrement: () => void;
  total: number;
  current: number;
  disablePagination: boolean;
  testIds: ReturnType<typeof generatedSingleResultTestIds>;
  connectedChannelNames?: string[];
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  selectedSentenceIsStreaming: boolean;
};

export const GeneratedSingleResultHeader: React.FC<Props> = ({
  sectionName,
  increment,
  decrement,
  total,
  current,
  disablePagination,
  testIds,
  connectedChannelNames,
  gptGenerationTaskQueue,
  selectedSentenceIsStreaming,
}) => {
  const {
    sentences: { promptLoadingIds },
  } = useContext(CopyAssistantContext);

  const showCancelButton =
    selectedSentenceIsStreaming && promptLoadingIds.length > 0;

  const [cancelBtnLoading, setCancelBtnLoading] = useState(false);
  return (
    <StyledCardContent>
      <Popup
        disabled={!connectedChannelNames?.length}
        size="small"
        wide
        content={`Section connected to ${connectedChannelNames?.join(", ")}`}
        mouseEnterDelay={popupDelay}
        trigger={
          <div>
            <Text
              compact
              color={selectedSentenceIsStreaming ? "grey" : undefined}
            >
              {selectedSentenceIsStreaming && !cancelBtnLoading && (
                <>
                  <Loader as="span" active inline size="tiny" />{" "}
                </>
              )}
              {sectionName}{" "}
            </Text>

            {!!connectedChannelNames?.length && (
              <Text size="mini" compact color="grey">
                Connected to: {fixLongName(connectedChannelNames.join(", "))}
              </Text>
            )}
          </div>
        }
      />
      <div className="card-extra-center">
        {total > 1 && (
          <Text
            size="small"
            color="grey"
            compact
            inline
            testId={testIds.amountOfVariants}
          >
            <Icon
              data-testid={testIds.buttons.pickVariantLeft}
              name="angle left"
              link={!disablePagination}
              onClick={(): void =>
                !disablePagination ? decrement() : undefined
              }
            />
            {current} / {total}
            <Icon
              data-testid={testIds.buttons.pickVariantRight}
              name="angle right"
              link={!disablePagination}
              onClick={(): void =>
                !disablePagination ? increment() : undefined
              }
            />
          </Text>
        )}
      </div>
      {showCancelButton && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            data-testid={testIds.buttons.cancelStream}
            content={cancelBtnLoading ? "Canceling..." : "Cancel"}
            color="red"
            basic
            size="tiny"
            compact
            onClick={(): void => {
              if (cancelBtnLoading) return;
              setCancelBtnLoading(true);
              gptGenerationTaskQueue.cancelMany(promptLoadingIds);
            }}
          />
        </div>
      )}
    </StyledCardContent>
  );
};
