import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";

import { setRemindEnglish } from "../../api/requestWordModalSlice";
import { RootState } from "../../utils/store";

type Props = {
  alwaysTranslate?: boolean;
  isShowingSimilarVocabularies: boolean;
  englishTranslation: string;
  onClose?: () => void;
  onBack?: () => void;
  showNextButton: boolean;
  showBackButton: boolean;
  handleClickNextButton?: () => void;
  similarVocabulariesButtonState?: {
    show: boolean;
    content: string;
    handler: (show: boolean) => void;
  };
};

export const RequestWordModalButtons: React.FC<Props> = ({
  alwaysTranslate,
  isShowingSimilarVocabularies,
  englishTranslation,
  onClose,
  onBack,
  showNextButton,
  showBackButton,
  handleClickNextButton,
  similarVocabulariesButtonState,
}) => {
  const dispatch = useDispatch();
  const englishLanguageCustomer = useSelector(
    (state: RootState) => state.requestWordModal.englishLanguageCustomer
  );

  return (
    <>
      {similarVocabulariesButtonState &&
        similarVocabulariesButtonState.show && (
          <Button
            color="red"
            floated="left"
            onClick={(): void => similarVocabulariesButtonState.handler(true)}
            content={similarVocabulariesButtonState.content}
          />
        )}
      <Button
        data-testid={"requestwordmodal-button-close"}
        name="cancel"
        content="Cancel"
        onClick={(): void => onClose?.()}
      />
      {showBackButton && (
        <Button
          data-testid={"requestwordmodal-button-back"}
          name="back"
          content="Back"
          onClick={(): void => onBack?.()}
        />
      )}
      {showNextButton && (
        <Button
          color="red"
          content={
            isShowingSimilarVocabularies
              ? "Request new word..."
              : alwaysTranslate
              ? "Submit"
              : "Next"
          }
          data-testid={"requestwordmodal-button-submit"}
          name="submit"
          onClick={(): void => {
            if (
              englishTranslation ||
              englishLanguageCustomer ||
              isShowingSimilarVocabularies
            ) {
              handleClickNextButton?.();
            } else {
              dispatch(setRemindEnglish(true));
            }
          }}
        />
      )}
    </>
  );
};
