import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Form, Input, TextArea, Message } from "semantic-ui-react";

import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../api/djangoToastSlice";
import {
  createPromptSuggestion,
  updatePromptSuggestion,
  deletePromptSuggestion,
} from "../../api/gptApi";
import {
  LanguageCode,
  SUICustomerLanguageDropdown,
} from "../customerlanguages";
import { Button } from "../../components/tailwind/button/Button";
import { Text } from "../../components/Text";
import { TESTID_BASE } from "./PromptSuggestions";
import { PromptSuggestion } from "./types";
import { RootState, store } from "../../utils/store";

type Props = {
  selectedSuggestion: PromptSuggestion | undefined;
  setSelectedSuggestion: (suggestion: PromptSuggestion) => void;
  refreshSuggestions: (unselect: boolean) => Promise<void>;
};

export const PromptSuggestionUpdateOrCreate: React.FC<Props> = ({
  selectedSuggestion,
  setSelectedSuggestion,
  refreshSuggestions,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [description, setDescription] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [textSnippet, setTextSnippet] = useState("");
  const [language, setLanguage] = useState<LanguageCode>();

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) return;
    if (selectedSuggestion) {
      setDescription(selectedSuggestion.description);
      setPlaceholder(selectedSuggestion.placeholder);
      setTextSnippet(selectedSuggestion.text_snippet);
      setLanguage(selectedSuggestion.language);
    } else {
      setDescription("");
      setPlaceholder("");
      setTextSnippet("");
      setLanguage(null);
    }
  }, [selectedSuggestion]);

  const placeholderError = useMemo((): string | null => {
    if (placeholder && placeholder.match(/[^A-Za-z0-9\-_]/)) {
      return "Placeholder contains invalid characters.";
    }
  }, [placeholder]);

  const disableSave = useMemo((): boolean => {
    return (
      !description || Boolean(placeholderError) || !textSnippet || !language
    );
  }, [description, placeholder, textSnippet, language]);

  const onSave = async (): Promise<void> => {
    try {
      if (!selectedSuggestion?.id) {
        const suggestion = await createPromptSuggestion(token, {
          description,
          placeholder,
          text_snippet: textSnippet,
          language,
        });
        if (mounted.current) setSelectedSuggestion(suggestion);
      } else {
        const suggestion = await updatePromptSuggestion(token, {
          id: selectedSuggestion.id,
          placeholder,
          description,
          text_snippet: textSnippet,
          language,
        });
        if (mounted.current) setSelectedSuggestion(suggestion);
      }
      store.dispatch(
        setDjangoToastOpen({
          content: "Prompt Snippet saved",
          appearance: NotificationAppearance.SUCCESS,
        })
      );
      if (mounted.current) await refreshSuggestions(false);
    } catch (err) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Failed to save Prompt Snippet",
          appearance: NotificationAppearance.ERROR,
        })
      );
    }
  };

  const onRemove = async (): Promise<void> => {
    try {
      await deletePromptSuggestion(token, selectedSuggestion.id);
      store.dispatch(
        setDjangoToastOpen({
          content: "Prompt Snippet deleted",
          appearance: NotificationAppearance.INFO,
        })
      );
      if (mounted.current) await refreshSuggestions(true);
    } catch (err) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Failed to delete Prompt Snippet",
          appearance: NotificationAppearance.ERROR,
        })
      );
    }
  };

  return (
    <>
      <Form as="div" data-testid={`${TESTID_BASE}-form`}>
        <Form.Field>
          <label>Name</label>
          <Text color="grey" size="small" compact lessMargin>
            A short description of the Prompt Snippet.
          </Text>
          <Input
            data-testid={`${TESTID_BASE}-form-description`}
            placeholder="Description"
            value={description || ""}
            onChange={(e, { value }): void => setDescription(value)}
          />
        </Form.Field>

        <Form.Field error={Boolean(placeholderError)}>
          <label>Placeholder (Optional)</label>
          <Text color="grey" size="small" compact lessMargin>
            Using a placeholder, the latest version of the instruction (below)
            will be dynamically inserted into the prompt instruction at
            generation time.
          </Text>
          <Input
            data-testid={`${TESTID_BASE}-form-placeholder`}
            placeholder=""
            value={placeholder || ""}
            onChange={(e, { value }): void => setPlaceholder(value)}
            className="!tw-w-auto"
          />
          {placeholderError && (
            <Message
              negative
              size="tiny"
              data-testid={`${TESTID_BASE}-form-placeholder-error`}
            >
              {placeholderError}
            </Message>
          )}
        </Form.Field>

        <Form.Field>
          <label>Snippet</label>
          <Text color="grey" size="small" compact lessMargin>
            The text to be added to the prompt&apos;s instruction when this
            Prompt Snippet is used.
          </Text>
          <TextArea
            data-testid={`${TESTID_BASE}-form-text-snippet`}
            value={textSnippet || ""}
            onChange={(e, { value }): void => setTextSnippet(value as string)}
          />
        </Form.Field>

        <Form.Field>
          <label>Language</label>
          <Text color="grey" size="small" compact lessMargin>
            The language this Prompt Snippet is written in.
          </Text>
          <SUICustomerLanguageDropdown
            data-testid={`${TESTID_BASE}-form-language`}
            onChange={(e, { value }): void => {
              setLanguage(value as LanguageCode);
            }}
            placeholder="Language"
            selection
            value={language}
            className="!tw-w-auto"
          />
        </Form.Field>

        <Form.Field>
          {!!selectedSuggestion?.id && (
            // TODO: Add confirm dialog before deletion
            <Button
              data-testid={`${TESTID_BASE}-form-remove-button`}
              variant="primary-alt"
              onClick={(): Promise<void> => onRemove()}
              className="tw-float-right"
            >
              Remove
            </Button>
          )}
          <Button
            variant="primary"
            data-testid={`${TESTID_BASE}-form-save-button`}
            onClick={(): Promise<void> => onSave()}
            disabled={disableSave}
          >
            {selectedSuggestion?.id ? "Save" : "Create"}
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};
