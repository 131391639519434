import { LanguageCode } from "../customers/customerlanguages";
import { ProductId } from "../products/product";
import api from "./api";

// This might be a good candidate for a class in the frontend
export type TextBlock = {
  id: string;
  customer_id: number;
  product_id: ProductId;
  section_id: number | null;
  text: string;
  language: LanguageCode;
  prompt_id: number | null;
  translated_from: string | null; // Will be set if translated from another text block (not necessarily machine translated)
  active: boolean;
  created: string;
};

export type CreateTextBlock = {
  product: ProductId;
  section: number | null;
  text: string;
  language: LanguageCode;
  prompt_id: number | null;
  translated_from: string | null; // Will be set if translated from another text block (not necessarily machine translated)
  active: boolean;
};

export const TEXT_BLOCK_BASE_URL = "/api/_internal/planner/text-block/";
export async function getTextBlocks(
  token: string,
  productId: string
): Promise<TextBlock[]> {
  const response = await api.get(TEXT_BLOCK_BASE_URL, {
    params: {
      product_id: productId,
    },
    headers: { token },
  });
  return response.data;
}

export async function getTextBlock(
  token: string,
  textBlockId: string
): Promise<TextBlock> {
  const response = await api.get(TEXT_BLOCK_BASE_URL + textBlockId, {
    headers: { token },
  });
  return response.data;
}

export async function deleteTextBlock(
  token: string,
  id: string,
  deleteTranslatedFrom: boolean = false
): Promise<unknown> {
  const response = await api.delete(TEXT_BLOCK_BASE_URL + id, {
    params: {
      delete_translated_from: deleteTranslatedFrom,
    },
    headers: { token },
  });
  return response;
}

export async function createTextBlock(
  token: string,
  textBlock: CreateTextBlock
): Promise<TextBlock> {
  const response = await api.post(TEXT_BLOCK_BASE_URL, textBlock, {
    headers: { token },
  });
  return response.data;
}

export async function updateTextBlock(
  token: string,
  textBlock: Partial<TextBlock>,
  textBlockId: string
): Promise<TextBlock> {
  const response = await api.put(TEXT_BLOCK_BASE_URL + textBlockId, textBlock, {
    headers: { token },
  });
  return response.data;
}

export async function setActiveTextBlock(
  token: string,
  textBlockId: string
): Promise<unknown> {
  const response = await api.post(
    TEXT_BLOCK_BASE_URL + "set-active",
    { text_block_id: textBlockId },
    {
      headers: { token },
    }
  );
  return response;
}

export type TextBlockCountResponse = {
  [index: number]: { total: number; empty: number };
};
export async function countTextBlocks(
  token: string,
  productIds: ProductId[],
  sectionIds: number[]
): Promise<TextBlockCountResponse> {
  const response = await api.post<TextBlockCountResponse>(
    TEXT_BLOCK_BASE_URL + "count",
    {
      product_ids: productIds,
      section_ids: sectionIds,
    },
    { headers: { token } }
  );
  return response.data;
}
