import React from "react";
import { Icon, List, Popup } from "semantic-ui-react";
import styled from "styled-components";

const StyledPopup = styled(Popup)`
  && {
    .content {
      padding: 2px 0px;
      font-size: small;
    }
  }
`;

type ContentItem = {
  header: string;
  description: string;
};

type Props = {
  title: string;
  content?: ContentItem[];
};

export const ProductTextActivityTableHeaderCell: React.FC<Props> = ({
  title,
  content,
}) => (
  <th>
    {title}
    {content && (
      <StyledPopup
        basic
        flowing
        hoverable
        position="bottom center"
        trigger={
          <Icon
            className="!tw-ml-2"
            name="info circle"
            color="grey"
            inverted
            data-testid={`${title}-popup-icon`}
          />
        }
      >
        <Popup.Content>
          <List data-testid={`${title}-content`}>
            {content.map((item) => (
              <List.Item key={item.header}>
                <List.Content>
                  <List.Header>{item.header}</List.Header>
                  <List.Description>{item.description}</List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Popup.Content>
      </StyledPopup>
    )}
  </th>
);
