import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  Button,
  Popup,
  Accordion,
  Icon,
  Modal,
  Divider,
} from "semantic-ui-react";
import styled from "styled-components";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { PreviewAddedSentences } from "../PreviewAddedSentences";
import { Sentence } from "../types";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { ProductId } from "../../product";
import { LanguageCode } from "../../../customers/customerlanguages";
import { GeneratedResultOnlyTextareaPlaceholder } from "./GeneratedResults";
import { Text } from "../../../components/Text";
import { GptGenerationTaskQueue, updatePrompt } from "../../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { generatedSingleResultTestIds } from "../testUtils/testIdsSelectors";
import { LangStringObject } from "../../../vocabulary/LangString";
import { getCustomerEnglishLanguage } from "../utils";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { GeneratedSingleResultHeader } from "./GeneratedSingleResultHeader";

export const StyledCardContent = styled(Card.Content)`
  &&&& {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 33%;
    > .card-extra-center {
      justify-self: center;
      align-self: center;
    }
  }
`;

type Props = {
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  sectionName: string;
  sentences: Sentence[];
  productId: ProductId;
  handleRemoveText: (sentence: Sentence) => Promise<void>;
  setActiveSection: (sentence: Sentence) => void;
  reFetchPrompts: () => Promise<void>;
};
export const GeneratedSingleResult: React.FC<Props> = ({
  gptGenerationTaskQueue,
  sectionName,
  sentences,
  productId,
  handleRemoveText,
  setActiveSection,
  reFetchPrompts,
}) => {
  const mounted = useRef(false);
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [showDetails, setShowDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const {
    prompts: { list },
    sentences: { autoTranslateList },
  } = useContext(CopyAssistantContext);

  const sentence = useMemo(() => sentences[selectedIndex], [
    selectedIndex,
    sentences,
  ]);

  const streamingSentence = useMemo(
    () => sentences.find((sentence) => sentence?.isStreaming),
    [sentences]
  );

  const showingSentence = streamingSentence || sentence;

  const shouldAutoTranslate = useMemo(
    (): boolean => !!autoTranslateList.find(({ id }) => id === sentence?.id),
    [sentence, autoTranslateList]
  );

  const [mtConfigGroupId, setMTConfigGroupId] = useState<number>();
  useEffect((): void => {
    const maybeId = autoTranslateList.find(({ id }) => id === sentence?.id)
      ?.mtConfigGroupId;
    if (maybeId) setMTConfigGroupId(maybeId);
  }, [sentence, autoTranslateList]);

  const doneWithAutoTranslate = (): void => {
    if (!shouldAutoTranslate) return;
    dispatch({
      type: CopyAssistantActionType.REMOVE_SENTENCE_AUTO_TRANSLATE_LIST,
      payload: {
        id: sentence.id,
      },
    });
  };

  const [haveTriedToFindActive, setHaveTriedToFindActive] = useState(false);
  useEffect(() => {
    if (sentences.length === 0) {
      return;
    }
    if (!haveTriedToFindActive) {
      sentences.forEach((s, index) => {
        if (s?.sectionInfo?.active) {
          setSelectedIndex(index);
          return;
        }
      });
      setHaveTriedToFindActive(true);
    } else {
      setSelectedIndex(sentences.length - 1);
    }
  }, [sentences.length]);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  const allSentencesLoading = useMemo(
    () => sentences.every((s) => s.isLoading) && sentences.length > 1,
    [sentences]
  );

  const someSentenceIsLoading = useMemo(
    () => sentences.some((s) => s.isLoading),
    [sentences]
  );

  const someSentenceIsStreaming = useMemo(
    () => sentences.some((s) => s.isStreaming),
    [sentences]
  );

  const loadingReason = useMemo(() => {
    const reason = sentences.find((s) => s.isLoading)?.loadingReason;
    if (!reason) return "Generating...";
    return reason + "...";
  }, [someSentenceIsLoading, allSentencesLoading]);

  const loadingSentencesPromptIds = useMemo(
    () =>
      sentences
        .filter((s) => s.isLoading)
        .map((s) => s.generationInfo.promptId),
    [someSentenceIsLoading]
  );

  const onIncrementSelectedIndex = (): void => {
    if (selectedIndex === sentences.length - 1) {
      return;
    }
    setSelectedIndex(selectedIndex + 1);
  };
  const onDecrementSelectedIndex = (): void => {
    if (selectedIndex === 0) {
      return;
    }
    setSelectedIndex(selectedIndex - 1);
  };
  const prompt = useMemo(
    () => list.find((p) => p.id === sentence?.generationInfo.promptId),
    [list, sentence]
  );

  const isActiveSection = useMemo(() => {
    if (!sentence?.sectionInfo) {
      return null;
    }
    return sentence?.sectionInfo?.active;
  }, [sentence, sentence?.sectionInfo]);

  const testIds = useMemo(() => generatedSingleResultTestIds(prompt?.name), [
    prompt,
  ]);
  const selectedVariantTestId = useMemo(
    () => testIds.selectedVariant(selectedIndex),
    [selectedIndex, testIds]
  );

  const showUpdateButton = useMemo(
    () =>
      prompt &&
      sentence?.generationInfo?.promptId &&
      sentence?.generationInfo?.instruction &&
      sentence?.generationInfo?.instruction !== prompt?.instruction,
    [prompt, sentence]
  );

  const onUpdatePrompt = async (): Promise<void> => {
    const newPrompt = {
      ...prompt,
      instruction: sentence?.generationInfo.instruction,
    };
    await updatePrompt(token, newPrompt);
    await reFetchPrompts();
    setOpen(false);
  };

  const setLangstringValues = (langStringValues: LangStringObject): void => {
    Object.entries(langStringValues).forEach(([key, value]) => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_SENTENCE,
        payload: {
          id: sentence.id,
          toUpdate: { value: { [key as LanguageCode]: value } },
        },
      });
    });
  };

  const customerEnLanguage = getCustomerEnglishLanguage(customer);

  return (
    <>
      <Card fluid data-testid={testIds.card}>
        <GeneratedSingleResultHeader
          sectionName={sectionName}
          increment={onIncrementSelectedIndex}
          decrement={onDecrementSelectedIndex}
          total={sentences.length}
          current={selectedIndex + 1}
          disablePagination={someSentenceIsLoading || someSentenceIsStreaming}
          testIds={testIds}
          connectedChannelNames={sentence?.sectionInfo?.connectedChannels.map(
            ({ name }) => name
          )}
          gptGenerationTaskQueue={gptGenerationTaskQueue}
          selectedSentenceIsStreaming={sentence?.isStreaming}
        />
        <StyledCardContent style={{ paddingBlock: "10px" }}>
          <div>
            <Popup
              content="Remove text"
              size="small"
              trigger={
                <Button
                  data-testid={testIds.buttons.remove}
                  disabled={someSentenceIsStreaming}
                  icon="trash alternate outline"
                  compact
                  basic
                  floated="left"
                  size="mini"
                  onClick={(): void => {
                    setSelectedIndex(selectedIndex - 1);
                    handleRemoveText(sentence);
                  }}
                />
              }
            />
          </div>
          <div className="card-extra-center"></div>
          <div>
            {!isActiveSection &&
              isActiveSection !== null &&
              !someSentenceIsStreaming && (
                <Button.Group size="mini" icon compact floated="right">
                  <Popup
                    content="Set this text to be active"
                    size="small"
                    trigger={
                      <Button
                        data-testid={testIds.buttons.setActive}
                        content="Activate text"
                        color="red"
                        onClick={(): void => setActiveSection(sentence)}
                      />
                    }
                  />
                </Button.Group>
              )}
            {isActiveSection && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <Text
                  color="grey"
                  size="small"
                  inline
                  testId={testIds.isActive}
                >
                  Currently active text
                </Text>
              </div>
            )}
          </div>
        </StyledCardContent>
        <Card.Content>
          <Card.Meta>
            <Text size="small" testId={testIds.displayName}>
              <b>Prompt: </b> {prompt?.name ?? "Unknown"}
            </Text>
            <Accordion>
              <Accordion.Title
                data-testid={selectedVariantTestId.accordion.title}
                style={{ display: "inline" }}
                active={showDetails}
                index={0}
                onClick={(): void => setShowDetails(!showDetails)}
              >
                <Text size="small" color="grey" inline>
                  <Icon name="dropdown" /> Details
                </Text>
              </Accordion.Title>
              <Accordion.Content
                active={showDetails}
                data-testid={selectedVariantTestId.accordion.content}
              >
                {showUpdateButton && (
                  <Button
                    data-testid={selectedVariantTestId.accordion.update}
                    attached="left"
                    content="Update prompt"
                    compact
                    size="mini"
                    basic
                    color="red"
                    floated="right"
                    onClick={(): void => setOpen(true)}
                  />
                )}
                <Text
                  size="small"
                  compact
                  testId={selectedVariantTestId.accordion.usedInstruction}
                >
                  <b>Instruction: </b>
                  {sentence?.generationInfo.instruction ??
                    prompt?.instruction ??
                    "Unknown"}
                </Text>

                <Text
                  size="small"
                  compact
                  testId={selectedVariantTestId.accordion.usedData}
                >
                  <b>Data: </b>
                  {sentence?.generationInfo.inputText}
                </Text>
              </Accordion.Content>
            </Accordion>
          </Card.Meta>
          <Card.Description>
            {(someSentenceIsLoading && !someSentenceIsStreaming) ||
            !showingSentence ? (
              <GeneratedResultOnlyTextareaPlaceholder
                gptGenerationTaskQueue={gptGenerationTaskQueue}
                promptLoadingIds={loadingSentencesPromptIds}
                loadingText={loadingReason}
              />
            ) : (
              <PreviewAddedSentences
                connectedChannels={
                  showingSentence.sectionInfo?.connectedChannels || []
                }
                key={showingSentence.id}
                productId={productId}
                sectionId={sentence?.sectionInfo?.documentSectionId}
                sectionName={sentence?.sectionInfo?.documentSectionName}
                tagId={showingSentence.templateInfo?.tagId}
                templateId={showingSentence.templateInfo?.templateId}
                textBlockId={showingSentence.sectionInfo?.textBlockId}
                langStringValues={showingSentence.value}
                setLangstringValues={setLangstringValues}
                language={showingSentence.language ?? customerEnLanguage.code}
                shouldAutoTranslate={shouldAutoTranslate}
                mtConfigGroupId={mtConfigGroupId}
                onDoneTranslating={doneWithAutoTranslate}
                isStreaming={showingSentence.isStreaming}
                onChangeCallback={(): void => {
                  dispatch({
                    type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
                    payload: true,
                  });
                }}
              />
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <Modal open={open} size="small" onClose={(): void => setOpen(false)}>
        <Modal.Header data-testid={testIds.modal.header}>
          Are you sure you want to change the instruction?
        </Modal.Header>
        <Modal.Content>
          <Text compact>From:</Text>
          <Text color="grey" compact testId={testIds.modal.content.form}>
            {prompt?.instruction}
          </Text>
          <Divider />
          <Text compact>To:</Text>
          <Text color="grey" compact testId={testIds.modal.content.to}>
            {sentence?.generationInfo?.instruction}
          </Text>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-testid={testIds.modal.buttons.cancel}
            content="Cancel"
            basic
            color="red"
            onClick={(): void => setOpen(false)}
          />
          <Button
            data-testid={testIds.modal.buttons.update}
            content="Update prompt"
            color="red"
            onClick={async (): Promise<void> => await onUpdatePrompt()}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
