import React, { useState, useMemo } from "react";
import {
  useGetProductQuery,
  useSetProductStatusMutation,
} from "../../api/productDetailSlice";
import { ProductId, ProductStatus } from "../product";
import { productDetailsRefreshCurrentTab } from "../../legacy/t.product-detail";
import { queuePreviewTextGeneration } from "../../utils/GlobalFunctions";
import { UserActionContext } from "../publish/actionTypes";
import { ConfirmationModal } from "../../ui/ConfirmationModal";
import { store } from "../../utils/store";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../api/djangoToastSlice";
import { Icon } from "semantic-ui-react";

type Props = {
  productId: ProductId;
};

export const RefreshFromImportedData: React.FC<Props> = ({ productId }) => {
  const [open, setOpen] = useState(false);
  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );
  const [
    updateProductStatus,
    { isLoading: isProductUpdating },
  ] = useSetProductStatusMutation();

  useMemo(() => {
    if (!isProductUpdating && !isProductLoading) {
      productDetailsRefreshCurrentTab();
      queuePreviewTextGeneration();
    }
  }, [isProductUpdating]);

  const refreshFromImportedDataAction = (): void => {
    updateProductStatus({
      productId,
      status: ProductStatus.IN_PROGRESS,
      userActionContext: UserActionContext.PRODUCT_REFRESH_FROM_IMPORTED_DATA,
    })
      .unwrap()
      .then(() => {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.SUCCESS,
            content: "Product successfully refreshed",
          })
        );
      })
      // This should be removed with TEX-4900
      .catch(({ data }) => {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.ERROR,
            content: data.message ?? "An unexpected error occurred",
          })
        );
      });
  };

  const onConfirm = (refresh: boolean): void => {
    if (refresh) {
      refreshFromImportedDataAction();
    }
    setOpen(false);
  };
  if (
    !product ||
    isProductLoading ||
    product?.status == ProductStatus.IMPORTING
  ) {
    return <></>;
  }

  return (
    <>
      <a
        className="item"
        href="#"
        onClick={(): void => setOpen(!open)}
        data-testid="action-refresh-from-imported-data"
      >
        <Icon name="redo alternate" />
        <span>Refresh from imported data</span>
      </a>
      <ConfirmationModal
        headerDescription="This will remove all manually added data."
        contentDescription={"Do you wish to continue?"}
        open={open}
        onConfirm={onConfirm}
      />
    </>
  );
};
