import React from "react";
import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";
import { Text } from "../../../components/Text";

type Props = {
  dropdownOptionsWithHeaders: { [key: string]: DropdownItemProps[] };
  label: string;
  placeholder: string;
  testId: string;
  value: number | string;
  setValue: (value: number | string, key: string) => void;
  selectedText: string;
  linkUrl?: string;
  linkText?: string;
  clearable?: boolean;
  caption: string;
};
export const PromptSelectFieldWithHeaders: React.FC<Props> = ({
  dropdownOptionsWithHeaders,
  label,
  placeholder,
  testId,
  value,
  setValue,
  selectedText,
  linkUrl,
  linkText,
  clearable,
  caption,
}) => {
  return (
    <Form.Field>
      <label {...(!!linkUrl && { style: { display: "flex" } })}>
        {label} {!clearable && "*"}
        {linkUrl && (
          <a href={linkUrl} data-subpage="" style={{ marginLeft: "auto" }}>
            {linkText || `Manage ${label}`}
          </a>
        )}
      </label>
      <Text color="grey" className="descriptive-helper-text">
        {caption}
      </Text>
      <Dropdown
        className="selection"
        data-testid={testId}
        fluid
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        text={selectedText || placeholder}
      >
        <Dropdown.Menu>
          {Object.keys(dropdownOptionsWithHeaders).map((key) => (
            <React.Fragment key={key}>
              <Dropdown.Header>{key}</Dropdown.Header>
              {dropdownOptionsWithHeaders[key].map((option) => (
                <Dropdown.Item
                  data-testid={`${testId}-option-${key}-${option.value}`}
                  selected={option.value === value}
                  key={option.value as string | number}
                  text={option.text}
                  value={option.value}
                  onClick={(): void =>
                    setValue(option.value as number | string, key)
                  }
                />
              ))}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Field>
  );
};
