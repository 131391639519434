import React from "react";
import { Placeholder, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { IllustrationKeys } from "./types";

const StyledPlaceholder = styled(Placeholder)`
  &&&&& {
    animation: none;
    background-image: linear-gradient(
      138deg,
      rgba(218, 218, 218, 1) 0%,
      rgba(218, 218, 218, 1) 100%
    );
    &.header {
      margin-top: 0.5rem;
    }
    &.as-ul,
    &.as-ol {
      margin-left: 1rem;
      overflow: visible;
      .line:not(:last-child) {
        &:before {
          top: 45%;
          scale: 1.5;
          height: 20px;
          left: -1rem;
        }
    }
    &.as-ul {
      .line:not(:last-child) {
        &:before {
          content: "•";
        }
      }
    }
    &.as-ol {
    counter-reset: count;
      .line:not(:last-child) {
        &:before {
          content: counter(count) ".";
          counter-increment: count;
          scale: 0.8;
          top: 50%;
        }
      }
    }
  }
`;

export const illustrations = {
  heading: (
    <StyledPlaceholder className="header">
      <Placeholder.Header>
        <Placeholder.Line />
      </Placeholder.Header>
    </StyledPlaceholder>
  ),
  body: (
    <StyledPlaceholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </StyledPlaceholder>
  ),
  bullets: (
    <StyledPlaceholder className="as-ul">
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </StyledPlaceholder>
  ),
};

const StyledSegmentGroup = styled.div`
  &&&&&&&&& {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .ui.segment {
      margin: 0;
      transition: all 0.2s ease-in-out;
      min-width: 150px;
      &:hover {
        scale: 1.05;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
`;

type Props = {
  selected: IllustrationKeys | undefined;
  setSelected: (key: IllustrationKeys) => void;
};

export const SelectIllustrations: React.FC<Props> = ({
  selected = "body",
  setSelected,
}) => {
  return (
    <StyledSegmentGroup data-testid="select-illustration">
      {Object.entries(illustrations).map(([key, value]) => (
        <Segment
          data-testid={`select-illustration-${key}`}
          key={key}
          color={selected === key ? "red" : undefined}
          raised={selected === key}
          onClick={(): void => setSelected(key as IllustrationKeys)}
        >
          {value}
        </Segment>
      ))}
    </StyledSegmentGroup>
  );
};
