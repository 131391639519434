import React from "react";
import {
  Icon,
  Popup,
  SemanticCOLORS,
  SemanticICONS,
  SemanticSIZES,
} from "semantic-ui-react";
import { ProductText } from "./ProductText";
import styled from "styled-components";

const DefaultIcon = styled(Icon)``;

const FFtextStatusIconsCoveredIcon = styled(Icon)`
 &&&& {
  font-size 0.65em
 }
`;

const buildIcon = (
  name: SemanticICONS,
  color: SemanticCOLORS,
  isCoveredIcon = false
): React.ReactElement<Icon> => {
  const props: {
    name: SemanticICONS;
    color: SemanticCOLORS;
    inverted?: boolean;
    circular?: boolean;
    corner?: boolean;
    size?: SemanticSIZES;
  } = { name: name, color: color };

  let ElementType = DefaultIcon;
  if (isCoveredIcon) {
    props.corner = true;
    props.size = "small";
    ElementType = FFtextStatusIconsCoveredIcon;
  }

  return (
    <ElementType data-testid={"product-text-status-icon-helper"} {...props} />
  );
};

export const buildIconWithTooltip = (
  tooltip: string,
  dataTextStatus: string,
  icon: React.ReactElement<Icon>,
  coveredIcon?: React.ReactElement<Icon>
): React.ReactElement => (
  <Popup
    trigger={
      <Icon.Group
        style={{ float: "right" }}
        data-text-status={dataTextStatus}
        data-testid={"single-icon"}
      >
        {icon}
        {coveredIcon}
      </Icon.Group>
    }
    content={tooltip}
    size="mini"
    inverted
  />
);

export const approvedIcon = (): React.ReactElement =>
  buildIcon("check", "green");

export const approvedIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has been approved.",
    "approved",
    approvedIcon()
  );

export const approvedPublishedBeforeIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has been published before and has been approved now.",
    "previously-published-approved-now",
    approvedIcon(),
    buildIcon("paper plane", "blue", true)
  );

export const missingTranslationIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text contains vocabulary that is pending translation.",
    "missing-translation",
    buildIcon("flag", "red")
  );

export const needsReviewIcon = (): React.ReactElement =>
  buildIcon("eye slash", "orange");

export const needsReviewIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has not been reviewed.",
    "needs-reviewed",
    needsReviewIcon()
  );

export const needsReviewPublishedBeforeIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has been published before and needs to be reviewed.",
    "previously-published",
    needsReviewIcon(),
    buildIcon("paper plane", "blue", true)
  );

export const notMarkedForPublishIconWithTooltip = (): React.ReactElement => {
  const buildIconProps: { name: SemanticICONS; color: SemanticCOLORS } = {
    name: "ban",
    color: "grey",
  };
  return buildIconWithTooltip(
    "This text has not been selected for publishing.",
    "not-selected-for-publishing",
    buildIcon(buildIconProps.name, buildIconProps.color)
  );
};

export const waitingToBePublishedIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text is waiting to be published.",
    "waiting-to-be-published",
    buildIcon("hourglass", "yellow")
  );
export const publishedIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has been published.",
    "published",
    buildIcon("paper plane", "blue")
  );

export const commentIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has a comment.",
    "comment",
    buildIcon("comment", "blue")
  );

export const editedIconWithTooltip = (): React.ReactElement =>
  buildIconWithTooltip(
    "This text has been manually edited.",
    "edit",
    buildIcon("edit", "blue")
  );

export function getProductTextStatusIcon(
  productText: ProductText
): React.ReactElement {
  const {
    needsReview,
    approved,
    waitingToBePublished,
    firstPublished,
    published,
  } = productText;
  const getApprovedIcon = (): React.ReactElement => {
    if (firstPublished) {
      return approvedPublishedBeforeIconWithTooltip();
    }
    return approvedIconWithTooltip();
  };

  const getNeedsReviewIcon = (): React.ReactElement => {
    if (firstPublished) {
      return needsReviewPublishedBeforeIconWithTooltip();
    }
    return needsReviewIconWithTooltip();
  };
  if (waitingToBePublished) {
    return waitingToBePublishedIconWithTooltip();
  } else if (published) {
    return publishedIconWithTooltip();
  } else if (approved) {
    return getApprovedIcon();
  } else if (needsReview) {
    return getNeedsReviewIcon();
  } else {
    return null;
  }
}
