import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dimmer } from "semantic-ui-react";

import { useGetAuthQuery } from "../api/authApi";
import { useGetCustomerQuery } from "../api/customerApi";
import { requestProofreadingDocuments } from "../api/requestTranslationApi";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import {
  Flag,
  LanguageCode,
  renderLanguageFromCode,
  SUICustomerLanguageDropdown,
} from "../customers/customerlanguages";
import { RootState } from "../utils/store";
import { Spinner } from "../utils/Spinner";

export const ProofreadingDocumentsRequest: React.FC = () => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<
    LanguageCode
  >();
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>(
    []
  );

  const [isRequesting, setIsRequesting] = useState(false);
  const [jobIds, setJobIds] = useState<{ [lang: string]: string }>({});
  const [email, setEmail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");

  const allLanguagesHasBeenSelected =
    selectedLanguages.length === customer?.languages.length - 1;

  useEffect(() => {
    if (selectedLanguages.includes(selectedSourceLanguage)) {
      setSelectedLanguages(
        [...selectedLanguages].filter((code) => code !== selectedSourceLanguage)
      );
    }
  }, [selectedSourceLanguage]);

  const handleSelectLanguage = (code: LanguageCode): void => {
    if (code === selectedSourceLanguage) {
      return;
    }
    if (selectedLanguages.includes(code)) {
      setSelectedLanguages([...selectedLanguages].filter((c) => c !== code));
      return;
    }
    const copy = [...selectedLanguages];
    copy.push(code);
    setSelectedLanguages(copy);
  };

  const handleSelectAll = (): void => {
    if (allLanguagesHasBeenSelected) {
      setSelectedLanguages([]);
      return;
    }
    const languageCodesToSelect = customer.languages
      .filter(({ code }) => code !== selectedSourceLanguage)
      .map(({ code }) => code);
    setSelectedLanguages(languageCodesToSelect);
  };

  const handleRequestProofreading = async (): Promise<void> => {
    setIsRequesting(true);
    try {
      const results = await Promise.all(
        selectedLanguages.map((language) => {
          return requestProofreadingDocuments(
            token,
            email,
            selectedSourceLanguage,
            [language],
            isUrgent,
            additionalInstructions
          ).then((jobId) => {
            return { language, jobId };
          });
        })
      );
      setJobIds((prevJobIds) => {
        const newJobIds = { ...prevJobIds };
        results.forEach(({ language, jobId }) => {
          newJobIds[language] = jobId;
        });
        return newJobIds;
      });
    } finally {
      setIsRequesting(false);
    }
  };

  const resetAllStates = (): void => {
    setJobIds({});
    setIsUrgent(false);
    setSelectedSourceLanguage(null);
    setSelectedLanguages([]);
    setAdditionalInstructions("");
    setIsRequesting(false);
  };

  useEffect(() => {
    if (isLoading || !customer) return;
    setSelectedSourceLanguage(customer.config.tag_input_language);
  }, [customer, isLoading]);

  useEffect(() => {
    if (isAuthLoading || !auth) return;
    setEmail(auth.user.email);
  }, [auth, isAuthLoading]);

  const isFormValid = useMemo((): boolean => {
    if (!selectedSourceLanguage) return false;
    if (additionalInstructions.length < 1) return false;
    if (selectedLanguages.length < 1) return false;
    return true;
  }, [additionalInstructions, selectedSourceLanguage, selectedLanguages]);

  return (
    <>
      <div className="tw-grid tw-grid-cols-2 tw-gap-8">
        <div className="txu-form tw-flex tw-flex-col tw-gap-4">
          <div>
            <label>Source Language</label>
            <SUICustomerLanguageDropdown
              loading={isLoading}
              clearable
              data-testid="proofreading-documents-request-select-source-language-dropdown"
              fluid
              placeholder="Select source language"
              selection
              value={selectedSourceLanguage}
              onChange={(e, { value }): void =>
                setSelectedSourceLanguage(value as LanguageCode)
              }
            />
          </div>
          <div>
            <label>Instructions</label>
            <textarea
              className="!tw-h-32"
              data-testid="proofreading-documents-request-additional-instructions"
              placeholder="Indicate which Documents should be proofread, by specifying e.g.
• Document labels
• Filter
• Date range"
              value={additionalInstructions}
              onChange={(e): void => setAdditionalInstructions(e.target.value)}
            />
          </div>
          <div>
            <label>Email address(es) for returning the delivery *</label>
            <small>
              Separate multiple email addresses with commas <code>(,)</code>
            </small>
            <input
              data-testid="proofreading-documents-request-email"
              type="email"
              placeholder="Email..."
              value={email}
              onChange={(e): void => setEmail(e.target.value)}
              className="tw-w-full"
            />
          </div>
          <div>
            <input
              type="checkbox"
              id="proofread-request-urgent"
              data-testid="proofread-request-urgent"
              checked={isUrgent}
              onChange={(e): void => setIsUrgent(e.target.checked)}
            />
            <label htmlFor="proofread-request-urgent">
              {customer?.config.translation_request_urgent_message}
            </label>
          </div>
        </div>
        <div>
          <table className="txu-table txu-compact txu-clickable-rows tw-mb-4">
            <thead>
              <tr>
                <th>Language</th>
                <th>Proofreading</th>
              </tr>
            </thead>
            <tbody>
              {customer?.languages.map((language) => (
                <tr
                  data-testid={`proofreading-documents-request-table-row-${language.code}`}
                  key={language.code}
                  onClick={(): void => handleSelectLanguage(language.code)}
                >
                  <td>
                    <Flag content={language.flag} /> {language.short_name}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="txu-input"
                      data-testid={`proofreading-documents-request-table-row-${language.code}-checkbox`}
                      checked={selectedLanguages.includes(language.code)}
                      disabled={language.code === selectedSourceLanguage}
                      onChange={(): void => handleSelectLanguage(language.code)}
                    />
                  </td>
                </tr>
              ))}
              <tr onClick={(): void => handleSelectAll()}>
                <td>Select All</td>
                <td>
                  <input
                    type="checkbox"
                    className="txu-input"
                    data-testid="proofreading-documents-request-table-row-select-all-checkbox"
                    checked={allLanguagesHasBeenSelected}
                    onChange={(): void => handleSelectAll()}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            data-testid="proofreading-documents-request-send-request-button"
            variant="primary"
            content="Send request"
            style={{ float: "right" }}
            onClick={handleRequestProofreading}
            disabled={!isFormValid}
          />
        </div>
      </div>

      <Dimmer active={isRequesting || !isEmpty(jobIds)} page>
        {isRequesting ? (
          <Spinner
            inverted
            content="Sending for Proofreading..."
            data-testid="proofreading-documents-request-loading"
            size="medium"
          />
        ) : (
          <>
            <Icon name="check" />
            <Text size="large">Sent for Proofreading!</Text>
            <Text size="small">
              You will receive an email at <b>{email}</b> when the proofreading
              is complete.
            </Text>
            <ul data-testid="proofreading-documents-request-job-ids">
              {Object.entries(jobIds).map(([languageCode, jobId]) => {
                return (
                  <li key={languageCode} className="tw-my-2">
                    <strong>
                      {renderLanguageFromCode(
                        languageCode,
                        customer?.languages ?? []
                      )}
                    </strong>
                    : {jobId}
                  </li>
                );
              })}
            </ul>
            <Text size="small">
              Save the job ID(s) for use in correspondance with us.
              <br />
              Any related questions can be sent to{" "}
              <a
                href="mailto:translations@textual.se"
                style={{ color: "white" }}
              >
                translations@textual.se
              </a>
            </Text>
            <hr className="tw-my-4 tw-border-gray-600" />
            <Button
              data-testid="proofreading-documents-request-done-button"
              content="Done"
              variant="primary"
              onClick={resetAllStates}
            />
          </>
        )}
      </Dimmer>
    </>
  );
};
