import React, { useEffect, useRef, useState } from "react";

import { ProductListAction } from "../ProductList";
import { getTemplateOptions } from "../../../api/action";
import { ProductFilterSearch, Operation } from "./ProductFilterSearch";
import { FieldOption } from "../../../utils/ApiUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";

type Props = {
  action: ProductListAction;
  filterKey: string;
  toggleFilter: (
    filterKey: string,
    filterValue: boolean,
    action: ProductListAction,
    value: string | string[] | {}
  ) => void;
  initialData: string | null;
  onAction: (action: ProductListAction, value: string) => void;
};
export const ProductFilterTemplate: React.FC<Props> = ({
  action,
  filterKey,
  toggleFilter,
  initialData,
  onAction,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [templates, setTemplates] = useState<FieldOption[] | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const labelNode = <>{selected[0] ? selected : "None"}</>;
  const mounted = useRef(true);

  useEffect(() => {
    (async (): Promise<void> => {
      mounted.current = true;
      const fieldOptions = await getTemplateOptions(token);
      if (mounted.current) {
        return setTemplates(fieldOptions);
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (templates && initialData && mounted.current)
      setSelected([
        templates.filter(({ value }) => value === initialData)[0].label,
      ]);
  }, [templates, initialData]);

  return (
    <ProductFilterSearch
      activeDataTestId={"search-dropdown-template-active"}
      DropdownDataTestId={"search-dropdown-template"}
      label={"Template"}
      dropdownLabel={<span>{labelNode}</span>}
      onClose={(): void =>
        toggleFilter(filterKey, false, ProductListAction.ChangeTemplate, "")
      }
      onClick={(value: string, operation: Operation, label: string): void => {
        if (!mounted.current) {
          return;
        }
        switch (operation) {
          case Operation.Add:
            setSelected([label]);
            onAction(action, value);
            break;
          case Operation.Delete:
            setSelected([]);
            onAction(action, "");
            break;
        }
      }}
      options={templates}
      selected={selected}
    />
  );
};
