import { UseGenericFetch, useGenericFetch } from "../../../api/customFetchHook";
import {
  getDocumentSections,
  getDocumentStructures,
} from "../../../api/documentStructureApi";
import { DocumentStructure, Section } from "../types";

export function useGetDocumentStructures(): UseGenericFetch<
  DocumentStructure[]
> {
  return useGenericFetch<DocumentStructure[]>([], getDocumentStructures);
}

export function useGetDocumentSections(): UseGenericFetch<Section[]> {
  return useGenericFetch<Section[]>([], getDocumentSections);
}
