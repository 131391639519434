import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { ProductId } from "./product";
import styled from "styled-components";
import { TranslationsModalStateHandler } from "./translations-modal/TranslationsModalStateHandler";

export const StyledButton = styled(Button)`
  &&& {
    background: transparent;
    border: solid 1px #d74c50;
    color: #d74c50;
    display: block;
    width: 100%;
  }
`;

type Props = {
  productId: ProductId;
};

export const SelectTranslationsButton: React.FC<Props> = ({ productId }) => {
  // This value keeps the information if the modal was already mounted and rendered for the first time.
  // This is done in such way to not send unnecessary requests for modal data if the user
  // does not click on the "Select translations" button at all.
  // Once this value is set to `true` it remains the same until the view is changed / the page is reloaded.
  const [initialized, setInitialized] = useState(false);

  const [open, onOpen] = useState(false);
  return (
    <>
      <StyledButton
        data-testid="select-translations-button"
        onClick={(): void => {
          onOpen(true);
          setInitialized(true);
        }}
      >
        Select translations
      </StyledButton>
      {initialized && (
        <TranslationsModalStateHandler
          open={open}
          productId={productId}
          onOpen={onOpen}
        />
      )}
    </>
  );
};
