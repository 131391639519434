import { Menu, Dropdown } from "semantic-ui-react";
import styled from "styled-components";

/**
 * Styles a Semantic UIs Menu.Item.
 *
 * It overwrites the background color to be red and the color to be white and some margins and border styles.
 * It also has a specific styling when .disabled class is present
 */
export const RedBackgroundMenuItem = styled(Menu.Item)`
  &&&&&& {
    margin-block: 8px;
    border-radius: 0.3rem;
    margin-left: 6px;
    cursor: pointer;
    background-color: #db2828 !important;
    color: white !important;
    &.active,
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.45;
    }
    transition: opacity 150ms ease;
  }
`;

/**
 * Styles a Semantic UIs Dropdown with prop item={true} that is intended to be used inside a Menu.
 *
 * It overwrites the background color to be red and the color to be white and some margins and border styles.
 * It also has a specific styling when .disabled class is present
 */
export const RedBackgroundMenuItemDropdown = styled(Dropdown)`
  &&&&&& {
    margin-block: 8px;
    border-radius: 0.3rem;
    margin-left: 6px;
    background-color: #db2828 !important;
    color: white !important;
    &.active,
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.45;
    }
    transition: opacity 150ms ease;
  }
`;
