import $ from "jquery";

// This hack is used to set url params when adding new view to viewset.
// This is a temporary solution and should be removed after refactoring Viewsets to React.
$(document).on("click", ".add-view-submit-btn", function () {
  const addViewForm = $(this).closest("form");
  addViewForm.attr(
    "action",
    addViewForm.attr("action") + window.location.search
  );
});
