import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from "react";
import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import { Accordion, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { debounce } from "../../utils/debounce";
import { CustomerChannel } from "../../customers/Customer";
import {
  changeObjectValues,
  checkDiff,
  createShortConfig,
  IndexedObject,
} from "./compareConfigs";
import {
  ProductChannelConfig,
  createProductChannelConfig,
  deleteProductChannelConfig,
} from "../../api/channelConfigApi";
import { BalloonDiv } from "../../components/BalloonDiv";
import { ChannelEditForm } from "./ChannelEditForm";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import { store } from "../../utils/store";
import { createIcons, IconsObject } from "./createIcons";
import { ChannelEditTabHelperText } from "./ChannelEditTabHelperText";
import { NoWithButton } from "./ChannelEditTabHelperText";
const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  .icon-container {
    margin-left: auto;
    display: flex;
    h5 {
      margin: 0;
      margin-right: 10px;
    }
  }
  .channel-edit-main-content {
    flex-grow: 1;
  }
  .channel-edit-revert-helper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
`;

type Props = {
  channel: CustomerChannel;
  isActiveChannel: boolean;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  ProductChannelConfig: ProductChannelConfig | undefined;
  productId: string;
  token: string;
};

export const ChannelEditTabBody: React.FC<Props> = ({
  channel,
  isActiveChannel,
  activeIndex,
  setActiveIndex,
  ProductChannelConfig,
  productId,
  token,
}) => {
  const [channelProductConfig, setChannelProductConfig] = useState<
    IndexedObject
  >();
  const [originalConfig, setOriginalConfig] = useState<IndexedObject>();
  const [loadProductConfig, setLoadProductConfig] = useState(true);
  const [diffTree, setDiffTree] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldAutoSave, setShouldAutoSave] = useState(false);
  const [icons, setIcons] = useState<IconsObject[]>([]);
  const loadConfig = (): IndexedObject => {
    if (loadProductConfig) {
      return channelProductConfig;
    }
    return originalConfig;
  };

  const createChannelProductConfig = (
    channelProductConfigObject: IndexedObject
  ): void => {
    if (!originalConfig) {
      return;
    }
    setLoadProductConfig(true);
    const diff = checkDiff(originalConfig, channelProductConfigObject);

    const changedObjectValues = cloneDeep(originalConfig);
    diff.forEach((d) => {
      changeObjectValues(changedObjectValues, d.key, d.changedValue, [
        ...d.parentKeys,
      ]);
    });

    setDiffTree(diff);
    setChannelProductConfig(changedObjectValues);
  };

  const onRevert = async (skipToastMessage?: boolean): Promise<void> => {
    createChannelProductConfig(ProductChannelConfig);
    const response = await deleteProductChannelConfig(
      token,
      productId,
      channel.id
    );
    window.queuePreviewTextGeneration();
    if (!skipToastMessage) {
      if (response.status === 200) {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.SUCCESS,
            content: `Reverted changes back to global settings for channel "${channel.display_name}"`,
          })
        );
      } else {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.ERROR,
            content: `Something went wrong trying to revert changes in channel "${channel.display_name}"`,
            additionalContent: "Please wait a few seconds and try again",
          })
        );
      }
    }
    setIsLoading(false);
  };

  const onSave = useCallback(
    debounce(async (diff: IndexedObject[]) => {
      if (!diff.length) {
        onRevert(true);
        return;
      }
      setIsLoading(true);
      const shortConfig: IndexedObject = {};
      diff.forEach((d) => {
        createShortConfig(
          shortConfig,
          [...d.parentKeys],
          d.key,
          d.changedValue
        );
      });

      const { data } = await createProductChannelConfig(
        token,
        productId,
        channel.id,
        shortConfig
      );

      createChannelProductConfig(data.config_overwrite);
      window.queuePreviewTextGeneration();
      setIsLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    let parsedChannel = channel.config;
    if (typeof channel.config === "string") {
      parsedChannel = JSON.parse(channel.config);
    }
    const customerSettings = (parsedChannel as IndexedObject).customer_settings;

    if (keys(customerSettings).length) {
      setOriginalConfig(customerSettings);
    }
  }, [channel]);

  useEffect(() => {
    const diff = checkDiff(originalConfig, channelProductConfig);
    setDiffTree(diff);
  }, [channelProductConfig]);

  useEffect(() => {
    setDiffTree([]);
    if (ProductChannelConfig) {
      setLoadProductConfig(true);
      createChannelProductConfig(ProductChannelConfig.config_overwrite);
    } else {
      setLoadProductConfig(false);
    }
  }, [ProductChannelConfig]);

  useEffect(() => {
    if (channelProductConfig && shouldAutoSave) {
      (async (): Promise<void> => await onSave(diffTree))();
    }
  }, [diffTree, onSave]);

  useEffect(() => {
    setIcons(createIcons(isActiveChannel));
  }, [isActiveChannel]);
  return (
    <React.Fragment key={channel.id}>
      <Accordion.Title
        data-testid={`product-channel-config-accordion-header-${channel.id}`}
        index={channel.id}
        active={activeIndex === channel.id}
        onClick={(e, { index }): void =>
          index === activeIndex
            ? setActiveIndex(0)
            : setActiveIndex(index as number)
        }
      >
        <FlexContainer>
          <Icon name="dropdown" />
          {channel.display_name}
          <div className="icon-container">
            {!!diffTree.length && (
              <h5
                className="ui text grey"
                data-testid={`product-channel-config-accordion-customized-${channel.id}`}
              >
                Customized
              </h5>
            )}
            {icons.map(({ key, value, iconName }) => (
              <BalloonDiv
                key={key}
                as="span"
                className=""
                value={value}
                pos={"left"}
              >
                <Icon
                  name={iconName}
                  data-testid={`product-channel-config-accordion-icon-${iconName.replace(
                    " ",
                    "-"
                  )}`}
                />
              </BalloonDiv>
            ))}
          </div>
        </FlexContainer>
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex === channel.id}
        data-testid={`product-channel-config-accordion-body-${channel.id}`}
      >
        {channelProductConfig || originalConfig ? (
          <FlexContainer>
            <div className="channel-edit-main-content">
              <ChannelEditForm
                channelConfig={loadConfig()}
                channelId={channel.id}
                setChangedConfig={setChannelProductConfig}
                setShouldAutoSave={setShouldAutoSave}
              />
            </div>
            <div className="channel-edit-revert-helper">
              <BalloonDiv
                value="Revert settings back to global settings"
                key={"revert-btn"}
                as="span"
                className=""
              >
                <NoWithButton
                  data-testid={`product-channel-config-accordion-revert-${channel.id}`}
                  disabled={!diffTree.length}
                  loading={isLoading}
                  type="button"
                  onClick={(): Promise<void> => onRevert()}
                  size="small"
                >
                  Revert
                </NoWithButton>
              </BalloonDiv>
              <ChannelEditTabHelperText showButton={true} width={"600px"} />
            </div>
          </FlexContainer>
        ) : (
          <h5 data-testid={`found-no-settings-${channel.id}`}>
            Found no settings for this channel.
          </h5>
        )}
      </Accordion.Content>
    </React.Fragment>
  );
};
