import React from "react";

import { endpoints } from "../../api/customerApi";
import { connect, ConnectedProps } from "react-redux";

/* 
This component used to use a custom version of TinyMCE from https://gitlab.com/textual-ai/tinymce.
TinyMCE was completely removed as a dependency in TEX-5095, along with a lot of custom code in this component. If this ever needs to be revived, look first at these MRs:
https://gitlab.com/textual-ai/textual-app/-/merge_requests/4461
https://gitlab.com/textual-ai/textual-app/-/merge_requests/4452
*/
export type AutocompleterContents = {
  value: string;
  text: string;
  icon?: string;
};

const mapDispatch = {
  getCustomer: endpoints.getCustomer.initiate,
};

const connector = connect(null, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  productId: string;
  initial?: string | null;
  templateFieldName?: string | null;
  templateTagId?: string | null;
  token: string;
};

export class FreeTextEditorComp extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    initial: null,
    templateFieldName: null,
    templateTagId: null,
  };

  public render = (): React.ReactElement => (
    <textarea readOnly placeholder="Under maintenance" />
  );
}

export const FreeTextEditor = connector(FreeTextEditorComp);
