import React from "react";
import { Button } from "semantic-ui-react";

export const EnrichTabSearchActions: React.FC = () => (
  <>
    <h4>Actions</h4>
    <Button.Group basic vertical>
      <Button>Extract Tags</Button>
      <Button>Add as name</Button>
      <Button>Request as new vocabulary or sentence</Button>
    </Button.Group>
  </>
);
