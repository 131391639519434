import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import {
  Overlay,
  StyledDropdownBase,
  ViewSetsCreateNewFilterDropdown,
} from "./ViewSetsCreateNewFilterDropdown";
import { ViewSet, ViewSetId } from "./ViewSetsFilterContainer";
import { CustomQueryHint } from "../CustomQueryHint";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";

const CentralizedGreyDiv = styled.div`
  align-self: center;
  color: dimgrey;
  margin-right: 20px;
`;

type Props = {
  onActiveViewChange?: () => void;
  onViewCreate: (display_name: string, viewSetId: ViewSetId) => void;
  onViewUpdate?: () => void;
  onViewSetCreate: (display_name: string) => void;
  viewSets: ViewSet[];
};

export const ViewSetsFilterSaveButtonDropdown: React.FC<Props> = ({
  onActiveViewChange,
  onViewCreate,
  onViewUpdate,
  onViewSetCreate,
  viewSets,
}) => {
  const [open, setOpen] = useState(false);
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );
  return (
    <>
      <Overlay
        onClick={(): void => setOpen(false)}
        className={open ? "show" : "hide"}
      />
      <CentralizedGreyDiv>Edited</CentralizedGreyDiv>
      {customQuery?.query && <CustomQueryHint />}
      <StyledDropdownBase
        basic
        button
        className={"modify-filter tiny"}
        data-testid={"product-filter-save-dropdown-button"}
        text={"Save"}
        open={open}
        onClick={(): void => setOpen(!open)}
        style={{ zIndex: 3 }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            data-testid={"product-filter-save-dropdown-item"}
            text={"Save"}
            onClick={(): void => onViewUpdate?.()}
          />
          <ViewSetsCreateNewFilterDropdown
            data-testid={"nested-view-sets-create-new-filter-dropdown"}
            nestedDropdown={true}
            onViewSetCreate={onViewSetCreate}
            onViewCreate={onViewCreate}
            viewSets={viewSets}
          />
          <Dropdown.Item
            data-testid={"product-filter-discard-dropdown-item"}
            text={"Discard"}
            onClick={(): void => onActiveViewChange?.()}
            key={"Discard"}
          />
        </Dropdown.Menu>
      </StyledDropdownBase>
    </>
  );
};
