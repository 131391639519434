import React from "react";
import { uuidv4 } from "../../utils/uuidUtils";
import { Headings } from "../Text";
import {
  BackgroundAnimation,
  HeaderSkeleton,
  ParagraphSkeleton,
} from "./styles";

interface CommonProps {
  className?: string;
}

export const EmptySkeleton: React.FC<CommonProps> = ({ className }) => (
  <BackgroundAnimation className={className} width="100%"></BackgroundAnimation>
);

interface TextsProps extends CommonProps {
  as?: Headings;
  content: string;
  classNameChild?: string;
}
export const TextHeaderSkeleton: React.FC<TextsProps> = ({
  as,
  content,
  className,
  classNameChild,
}) => (
  <BackgroundAnimation className={className}>
    <HeaderSkeleton as={as} className={classNameChild}>
      {content}
    </HeaderSkeleton>
  </BackgroundAnimation>
);

export type TextParagraphSkeletonProps = Omit<TextsProps, "as">;
export const TextParagraphSkeleton: React.FC<TextParagraphSkeletonProps> = ({
  content,
  className,
  classNameChild,
}) => (
  <BackgroundAnimation className={className}>
    <ParagraphSkeleton className={classNameChild}>{content}</ParagraphSkeleton>
  </BackgroundAnimation>
);

interface TextParagraphSkeletonCollectionProps {
  texts: TextParagraphSkeletonProps[];
}

export const TextParagraphSkeletonCollection: React.FC<TextParagraphSkeletonCollectionProps> = ({
  texts,
}) => (
  <>
    {texts.map(({ ...props }) => (
      <TextParagraphSkeleton {...props} key={uuidv4()} />
    ))}
  </>
);
