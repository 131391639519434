import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ProductFilterSearch, Operation } from "./ProductFilterSearch";
import { ProductListAction } from "../ProductList";
import { AndOrOperator } from "../ProductListTypes";
import { FieldOption } from "../../../utils/ApiUtils";
import { RootState } from "../../../utils/store";

type Props = {
  action: ProductListAction;
  filterKey: string;
  initialData: string[];
  label: string;
  onAction?: (action: ProductListAction, value: string[]) => void;
  onFetchOptions: (token: string) => Promise<FieldOption[]>;
  toggleFilter: (
    filterKey: string,
    filterValue: boolean,
    action: ProductListAction,
    value: string | string[] | {}
  ) => void;
  removable?: boolean;
  showOperator?: boolean;
  operator?: AndOrOperator;
  onChangeOperator?: (operator: AndOrOperator) => void;
};

export const ProductFilterMultipleChoice: React.FC<Props> = ({
  action,
  filterKey,
  initialData,
  label,
  onAction,
  onFetchOptions,
  toggleFilter,
  removable,
  showOperator = false,
  operator,
  onChangeOperator,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [options, setOptions] = useState<FieldOption[] | null>(null);
  const mounted = useRef(true);
  const labelNode = (): string => {
    const initialLabels = initialData;
    if (!initialLabels.length) {
      return "None";
    } else if (initialLabels[2]) {
      return `${initialLabels.length} selected`;
    } else {
      return initialLabels.join(", ");
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      mounted.current = true;
      const fieldOptions = await onFetchOptions(token);
      if (mounted.current) {
        setOptions(fieldOptions);
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const handleItemClick = (value: string, operation: Operation): void => {
    switch (operation) {
      case Operation.Add:
        if (!initialData.includes(value)) {
          onAction(action, [...initialData, value]);
        }
        break;
      case Operation.Delete:
        {
          onAction(
            action,
            initialData.filter((el) => el != value)
          );
        }
        break;
    }
  };

  return (
    <ProductFilterSearch
      activeDataTestId="search-dropdown-label-active-item"
      DropdownDataTestId="search-dropdown-label"
      label={label}
      dropdownLabel={<span>{labelNode()} </span>}
      onClose={(): void => toggleFilter(filterKey, false, action, [])}
      onClick={handleItemClick}
      options={options}
      selected={initialData}
      removable={removable}
      showOperator={showOperator}
      onChangeOperator={onChangeOperator}
      operator={operator}
    />
  );
};
