import React from "react";
import { Dot } from "pure-react-carousel";
import { Button, Container, Image } from "semantic-ui-react";
import styled from "styled-components";
import { ProductImage } from "../products/ProductImage";

type Props = {
  images: ProductImage[];
  size?: string;
};

const CarouselContainer = styled(Container)`
  margin-top: 16px;
`;

const CarouselButtonGroup = styled(Button.Group)`
  * {
    border: none;
  }
`;

const CarouselImageSelector: React.FC<Props> = ({ images, size }) => (
  <CarouselContainer textAlign="center">
    <CarouselButtonGroup size={size}>
      {images.map((image, key) => (
        <Image
          as={Dot}
          key={image.imageUrl}
          slide={key}
          src={image.imageUrl}
          size={"tiny"}
        />
      ))}
    </CarouselButtonGroup>
  </CarouselContainer>
);

export default CarouselImageSelector;
