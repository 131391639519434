import React, { useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";
import { Button, Modal } from "semantic-ui-react";

import { useGetCustomerQuery } from "../../../api/customerApi";

import { loadPartial, replaceContent } from "../../../utils/partialUtils";
import { quietSubmit } from "../../../legacy/t.product-detail";
import {
  SIMPLE_ACTION_PREFIX,
  bulkActionsWithNoSettings,
  findFriendlyName,
} from "./PipelineForm";
import { BulkAction } from "../../../ui/BulkActionsDropdown";
import { UniqueIdentifier } from "./types";
import api from "../../../api/api";

type Props = {
  show: boolean;
  onHide: () => void;
  action: BulkAction;
  stateData: { simplified?: boolean };
  uniqueIdentifier: string;
  index: number;
  pipelineId: number;
  setStepAsComplete: (uniqueIdentifier: UniqueIdentifier) => void;
  stepIsComplete: boolean;
  updateDescription: (wait?: number) => void;
};

export const PipelineStepSettings: React.FC<Props> = ({
  show,
  onHide,
  action,
  stateData,
  uniqueIdentifier,
  index,
  pipelineId,
  setStepAsComplete,
  stepIsComplete,
  updateDescription,
}) => {
  const formRef = useRef<HTMLFormElement>();
  const [stepHasMultipleSteps, setStepHasMultipleSteps] = useState(false);

  const { data: customer, isLoading } = useGetCustomerQuery();

  const formBodyUrl = useMemo(() => {
    if (!customer) return "";
    const simplified =
      action.includes(SIMPLE_ACTION_PREFIX) || stateData?.simplified;
    let url = `/d/${
      customer.slug
    }/products/bulk-actions/perform/${action.replace(
      SIMPLE_ACTION_PREFIX,
      ""
    )}?pipeline_id=${pipelineId}&pipeline_step=${index}`;
    if (simplified) {
      url += "&simplified=true";
    }
    return url;
  }, [customer, index, action]);

  const getFormInputs = (): MutationObserver => {
    loadPartial($(`#${uniqueIdentifier}`));
    const observer = new MutationObserver((_, obs) => {
      const isDone = formRef.current.classList.contains("done");
      if (isDone) {
        setStepHasMultipleSteps(
          !!formRef.current.querySelector("input[name='has_next']")
        );
        if (bulkActionsWithNoSettings.includes(action)) {
          handleFormSubmit(new FormData(), true);
        }
        obs.disconnect();
      }
    });
    const config = { attributes: true };

    observer.observe(formRef.current, config);
    return observer;
  };

  const handleFormSubmit = (formData?: FormData, autoHide?: boolean): void => {
    if (stepHasMultipleSteps) {
      // get HTML for next step in the form
      api
        .post(formBodyUrl, formData, {
          headers: { "x-requested-with": "XMLHttpRequest" }, // This header signals the backend to not send back a full page (related to the Partial system)
        })
        .then(({ data }) => {
          formRef.current.classList.remove("done");
          formRef.current.textContent = "";
          replaceContent($(`#${uniqueIdentifier}`), data.content);
          (window as any).rebindAll();
          setStepHasMultipleSteps(
            !!formRef.current.querySelector("input[name='has_next']")
          );
        });
    } else {
      quietSubmit($(`#${uniqueIdentifier}`), true);
      setStepAsComplete(uniqueIdentifier);
      if (stepIsComplete) {
        updateDescription(700);
      }
      if (autoHide) {
        onHide();
      }
    }
  };
  useEffect(() => {
    if (!formRef.current || !show || isLoading) return;
    const observer = getFormInputs();
    return () => {
      observer.disconnect();
    };
  }, [show, isLoading]);

  if (isLoading) return <></>;

  return (
    <Modal
      size="fullscreen"
      className="pipeline-partial-bulk-action"
      open={show}
      onClose={(): void => onHide()}
    >
      <Modal.Header>{findFriendlyName(action, stateData)}</Modal.Header>
      <Modal.Content scrolling>
        {!!formBodyUrl && (
          <div className="container full-width no-margin">
            <div className="section">
              <form
                data-testid={`manage-pipelines-action-step-form-${action}`}
                action={formBodyUrl}
                method="post"
                onSubmit={(e: any): void => {
                  e.preventDefault();
                  handleFormSubmit(new FormData(e.target), true);
                }}
                ref={formRef}
                className="partial manage-pipeline-action-step-form"
                data-url={formBodyUrl}
                id={uniqueIdentifier}
              ></form>
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        <Button
          data-testid={`manage-pipelines-action-step-form-${action}-close-modal`}
          content="Close"
          size="small"
          basic
          onClick={onHide}
        />
        <Button
          data-testid={`pipeline-action-step-settings-submit-${action}`}
          color="red"
          content={stepHasMultipleSteps ? "Next" : "Save"}
          size="small"
          type="submit"
          form={uniqueIdentifier}
        />
      </Modal.Actions>
    </Modal>
  );
};
