import { LanguageCode } from "../../../customers/customerlanguages";

export const MachineTranslationService = {
  DEEPL: "DeepL",
  GOOGLE: "Google Translate",
  GPT: "GPT",
  MODERNMT: "ModernMT",
} as const;

export const MachineTranslationFormality = {
  prefer_less: "Less formal",
  prefer_more: "More formal",
} as const;

export type MachineTranslationServiceType = keyof typeof MachineTranslationService;
export type MachineTranslationFormalityType = keyof typeof MachineTranslationFormality;

export type MachineTranslationConfig = {
  language: LanguageCode;
  machine_translation_service: MachineTranslationServiceType;
  machine_translation_prompt?: number;
  machine_translation_formality?: MachineTranslationFormalityType;
};

export type MachineTranslationConfigWithSelection = MachineTranslationConfig & {
  isSelected: boolean;
};

export type MachineTranslationConfigGroup = {
  id: number;
  name: string;
  is_default: boolean;
  configs: MachineTranslationConfig[];
};
