import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";

export type UseGenericFetch<T> = {
  data: T;
  isFetching: boolean;
  reFetch: (args?: unknown) => Promise<void>;
};

/**
 * A generic Fetch hook
 * This function can be used if we want to use a react like hook without the need of saving it to redux
 * @param initialData What state we want the data state to be in before the fetch request is complete
 * @param fn A async function
 * @param args The arguments to the function (Exclude the token argument)
 * @returns the result of the async function and a loading state and a reFetch function
 */
export function useGenericFetch<T>(
  initialData: unknown,
  fn: (token: string, args: unknown) => Promise<T>,
  args?: unknown
): UseGenericFetch<T> {
  const token = useSelector((state: RootState) => state.auth.token);
  const [data, setData] = useState<T>(initialData as T);
  const [isFetching, setIsFetching] = useState(true);
  const mounted = useRef(false);

  const fetchData = async (freshArgs?: unknown): Promise<void> => {
    const useArgs = freshArgs || args;
    setIsFetching(true);
    await fn(token, useArgs)
      .then((response) => {
        if (mounted.current) setData(response);
      })
      .finally(() => {
        if (mounted.current) {
          setIsFetching(false);
        }
      });
  };

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (token) {
      (async (): Promise<void> => {
        await fetchData();
      })();
    }
  }, [token]);
  return { data, isFetching, reFetch: fetchData };
}
