import React, { useLayoutEffect, useRef } from "react";
import { Button, Form, Ref, Segment, TextArea } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { FurtherInstructionsGpt3 } from "../types";
import { preventOverflowOnTextarea } from "../utils";
type Props = {
  furtherInstructions: FurtherInstructionsGpt3[];
  updateFurtherInstruction: (
    index: number,
    value: string,
    key: keyof FurtherInstructionsGpt3
  ) => void;
  addFurtherInstruction: () => void;
  removeFurtherInstruction: () => void;
};
export const PromptFurtherInstructionsField: React.FC<Props> = ({
  furtherInstructions,
  updateFurtherInstruction,
  addFurtherInstruction,
  removeFurtherInstruction,
}) => {
  const textareaRefs = useRef<HTMLTextAreaElement[]>([]);
  useLayoutEffect(() => {
    textareaRefs.current.forEach((textarea) => {
      preventOverflowOnTextarea(textarea);
    });
  });

  return (
    <Form.Field data-testid="copy-assistant-template-form-prompts-wrapper">
      <label>Prompts</label>
      <Text color="grey" className="descriptive-helper-text">
        If you provide some examples of what input you will provide and what
        output you desire, it will increase the likelihood of getting the texts
        you want. The more examples the better, but generally 3 is a good sweet
        spot.
      </Text>
      {furtherInstructions.map((prompt, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Form.Field key={index} as={Segment}>
          <Form.Field>
            <label>Input {index + 1}</label>
            <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
              <TextArea
                data-testid={`copy-assistant-prompt-textarea-input-${
                  index + 1
                }`}
                style={{ whiteSpace: "pre-wrap" }}
                placeholder={`Example input ${index + 1}`}
                value={prompt.input}
                onChange={(_, { value }): void => {
                  updateFurtherInstruction(index, value as string, "input");
                }}
              />
            </Ref>
          </Form.Field>
          <Form.Field>
            <label>Output {index + 1}</label>
            <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
              <TextArea
                data-testid={`copy-assistant-prompt-textarea-output-${
                  index + 1
                }`}
                placeholder={`Example output ${index + 1}`}
                value={prompt.output}
                onChange={(_, { value }): void => {
                  updateFurtherInstruction(index, value as string, "output");
                }}
              />
            </Ref>
          </Form.Field>
        </Form.Field>
      ))}
      <Button
        data-testid="copy-assistant-add-prompt"
        color="red"
        basic
        size="mini"
        icon="add"
        onClick={(): void => addFurtherInstruction()}
        disabled={furtherInstructions.length >= 6}
      />

      <Button
        data-testid="copy-assistant-remove-prompt"
        color="red"
        basic
        size="mini"
        icon="minus"
        onClick={(): void => removeFurtherInstruction()}
        disabled={furtherInstructions.length <= 1}
      />
    </Form.Field>
  );
};
