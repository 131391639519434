import React, { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "../utils/localStorageHook";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { fetchAlerts } from "../api/action";
import { Alert, AlertContentType } from "./Alert";
import { useGetCustomerQuery } from "../api/customerApi";

const alertName = "alert_banner";

const ALERT_INTERVAL_TIME = 5; // in minutes

export type AlertMessageType = {
  alert_type: AlertContentType;
  id: number;
  message: string;
  seen?: boolean;
};

type AlertLocalStorageType = {
  alerts: AlertMessageType[];
  timestamp: number;
};

export const Alerts: React.FC = () => {
  const [alertConfig, setAlertConfig] = useLocalStorage<AlertLocalStorageType>(
    alertName
  );
  const { data: customer, isLoading } = useGetCustomerQuery();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | number>(null);
  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(true);

  const getStoredAlerts = (): AlertMessageType[] => {
    if (alertConfig?.alerts) {
      return [...alertConfig.alerts];
    }
    return [];
  };

  const updatedAlerts = (
    alertsToUpdate: AlertMessageType[]
  ): AlertMessageType[] => {
    // update current alerts stored in localStorage
    const alerts = getStoredAlerts();
    for (const alertToUpdate of alertsToUpdate) {
      const existedAlert = alerts.filter(
        (currentAlert) => currentAlert.id == alertToUpdate.id
      );
      if (!existedAlert.length) {
        alerts.push({ ...alertToUpdate, seen: false });
      }
    }

    // remove outdated alerts from localStorage to release memory
    return alerts.filter((alert) => {
      const checkIfAlertExists = alertsToUpdate.find(
        (aa) => aa.id === alert.id
      );
      if (checkIfAlertExists) return alert;
    });
  };

  const setCurrentAlertConfig = async (): Promise<void> => {
    const dateNow = new Date();
    await fetchAlerts(token).then((alerts) => {
      setAlertConfig({
        timestamp: dateNow.setMinutes(
          dateNow.getMinutes() + ALERT_INTERVAL_TIME
        ),
        alerts: updatedAlerts(alerts),
      });
    });
  };

  const getAlerts = (): void => {
    if (
      !alertConfig?.timestamp || // not stored yet in localStorage
      Date.now() > alertConfig.timestamp // alerts should be refreshed
    ) {
      setCurrentAlertConfig();
    }
  };

  const setIntervalGetAlerts = (): void => {
    if (intervalId) {
      clearInterval(intervalId as NodeJS.Timeout);
    }
    const currentInterval = setInterval(
      getAlerts,
      ALERT_INTERVAL_TIME * 60 * 1000
    );
    setIntervalId(currentInterval);
  };

  const clearIntervalGetAlerts = (): void => {
    if (intervalId) {
      clearInterval(intervalId as NodeJS.Timeout);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && customer) {
      getAlerts();
      setIntervalGetAlerts();
    }

    return (): void => {
      clearIntervalGetAlerts();
      mounted.current = false;
    };
  }, [customer]);

  if (isLoading) return <></>;

  const onDismiss = (id: number): void => {
    const alerts = getStoredAlerts();
    for (const currentAlert of alerts) {
      if (currentAlert.id === id) {
        currentAlert.seen = true;
      }
    }
    setAlertConfig({ ...alertConfig, alerts });
    setIntervalGetAlerts();
  };
  return (
    <div data-testid={"alert-banner"}>
      {getStoredAlerts()
        .filter((alert) => !alert.seen)
        .map((alertProps) => (
          <Alert key={alertProps.id} {...alertProps} onDismiss={onDismiss} />
        ))}
    </div>
  );
};
