import { Dispatch, useEffect, useState } from "react";
/*
Custom React hook which is working similar to useState hook
*/
export const useLocalStorage = <LS>(
  key: string,
  defaultValue: LS = null
): [LS | undefined, Dispatch<LS | undefined>] => {
  const [value, setValue] = useState(() => {
    try {
      const saved = localStorage.getItem(key);
      if (saved !== null) {
        return JSON.parse(saved);
      }
      return defaultValue;
    } catch {
      return defaultValue;
    }
  });
  useEffect(() => {
    const rawValue = JSON.stringify(value);
    localStorage.setItem(key, rawValue);
  }, [key, value]);

  return [value, setValue];
};
