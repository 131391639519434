import React from "react";
import { FreeTextExtractField } from "./FreeTextExtractField";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { SEORecommendations } from "./SEORecommendations";
import { MainCategoryField } from "./MainCategoryField";
import { QuickSearchField } from "./QuickSearchField";
import { NumbersField } from "./NumbersField";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import { TagFields } from "./TagFields";
import { ProductRecommendations } from "./ProductRecommendations";
import { UntranslatedNameField } from "./UntranslatedNameField";
import { getCustomerSettings } from "../../customers/customersettings";
import { useGetCustomerQuery } from "../../api/customerApi";

interface Props {
  details: ProductDetailInfo | null;
  onTagsAction: onTagsActionCallback;
  recommendations: ProductRecommendations;
  quickSearchMessage: string;
  selected: SidebarItem | null;
}

/**
 * All fields that are editable, the right hand side of the tab
 * @param props
 * @constructor
 */
export const EditFields: React.FC<Props> = ({
  details,
  onTagsAction,
  quickSearchMessage,
  recommendations,
  selected,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (details === null || selected === null || isLoading) {
    return <>Loading...</>;
  }
  const customerSettings = getCustomerSettings(customer);
  const { fieldRecommendations, seoRecommendations } = recommendations;

  return (
    <>
      <FreeTextExtractField
        details={details}
        onTagsAction={onTagsAction}
        selectedName={selected.name}
      />
      <form className={"edit-fields-form"} data-testid={"edit-fields-form"}>
        <MainCategoryField
          customerSettings={customerSettings}
          details={details}
          onTagsAction={onTagsAction}
          selected={selected}
        />
        <NumbersField
          details={details}
          onTagsAction={onTagsAction}
          selected={selected}
        />
        <SEORecommendations
          details={details}
          onTagsAction={onTagsAction}
          seoRecommendations={seoRecommendations}
        />
        <hr />
        <QuickSearchField
          customerSettings={customerSettings}
          details={details}
          onTagsAction={onTagsAction}
          quickSearchMessage={quickSearchMessage}
          selected={selected}
        />
        <hr />
        {selected.isSubpart && customerSettings.showUntranslatedName && (
          <>
            <UntranslatedNameField
              details={details}
              onTagsAction={onTagsAction}
              selected={selected}
            />
            <hr />
          </>
        )}
        <TagFields
          details={details}
          fieldRecommendations={fieldRecommendations}
          onTagsAction={onTagsAction}
          selected={selected}
        />
      </form>
    </>
  );
};
