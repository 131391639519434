import $ from "jquery";

// Handle setting up main default channel in manage -> radio button
$(document).on("click", ".main_default_channel", function () {
  if ($(this).is(":checked")) {
    const allCheckboxes = $(".main_default_channel");
    allCheckboxes.prop("checked", false);
    $(this).prop("checked", true);
  } else {
    return false;
  }
});

$(document).on("click", ".invoice-single-accordion > .title", function () {
  const title = $(this);
  const content = $(title).next("div.content");
  if (title.hasClass("active")) {
    title.removeClass("active");
    content.removeClass("active");
  } else {
    title.addClass("active");
    content.addClass("active");
  }
});
