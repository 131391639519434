import React from "react";
import { Message } from "semantic-ui-react";
import { NotificationAppearance } from "../api/djangoToastSlice";

export enum NotificationType {
  AddTagTopLevelWarning = "AddTagTopLevelWarning",
  EnrichTabReplaceBrandNameWarning = "EnrichTabReplaceBrandNameWarning",
  GPTAddSentencesError = "GPTAddSentencesError",
  GPTAddSentencesSuccess = "GPTAddSentencesSuccess",
  GPTGenerationEventError = "GPTGenerationEventError",
}

type NotificationContent = {
  appearance: NotificationAppearance;
  content: string;
  header: string;
  positive: boolean;
};

export const NotificationsContent: Record<
  NotificationType,
  NotificationContent
> = {
  [NotificationType.AddTagTopLevelWarning]: {
    appearance: NotificationAppearance.WARNING,
    content:
      "This is not possible, please mark an attribute to be given a child",
    header: "Adding top level tag warning",
    positive: false,
  },
  [NotificationType.EnrichTabReplaceBrandNameWarning]: {
    appearance: NotificationAppearance.WARNING,
    content: "",
    header: "Name/brand is empty, nothing is added",
    positive: false,
  },
  [NotificationType.GPTAddSentencesError]: {
    appearance: NotificationAppearance.ERROR,
    content: "Adding the sentence to the product failed. Please try again.",
    header: "Error",
    positive: false,
  },
  [NotificationType.GPTAddSentencesSuccess]: {
    appearance: NotificationAppearance.SUCCESS,
    content: "",
    header: "The sentence has been added to the product.",
    positive: true,
  },
  [NotificationType.GPTGenerationEventError]: {
    appearance: NotificationAppearance.ERROR,
    content: "Please try again.",
    header: "Text generation error",
    positive: false,
  },
};

type Props = {
  notificationType: NotificationType | null;
  showNotification: boolean;
};

export const TabNotificationPopup: React.FC<Props> = ({
  notificationType,
  showNotification,
}) =>
  notificationType && (
    <Message
      hidden={!showNotification}
      negative={!NotificationsContent[notificationType].positive}
      positive={NotificationsContent[notificationType].positive}
      size={"mini"}
    >
      <Message.Header>
        {NotificationsContent[notificationType].header}
      </Message.Header>
      <p>{NotificationsContent[notificationType].content}</p>
    </Message>
  );
