import React from "react";
import { Container, Tab } from "semantic-ui-react";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import {
  translationPromptsPane,
  myPromptsPanes,
  CustomPane,
} from "./ManagePromptTabPanes";

export const ActiveTab = {
  SINGLE: "prompts",
  GROUPED: "groups",
  SYSTEM: "system-prompts",
} as const;

type ActiveTabType = typeof ActiveTab;

export type ActiveTabValue = ActiveTabType[keyof ActiveTabType];

type Props = {
  viewTranslation?: any | null;
};

export const ManagePromptTabs: React.FC<Props> = ({ viewTranslation }) => {
  const {
    setParam,
    urlState: { tab },
  } = useUrlState<{
    tab: ActiveTabValue;
  }>({
    tab: ActiveTab.SINGLE,
  });

  let buildPanes = myPromptsPanes;
  if (viewTranslation) {
    buildPanes = translationPromptsPane;
  }

  const panes = buildPanes();

  const activeIndex = panes.findIndex(({ tabKey }) => tabKey === tab) ?? 0;

  return (
    <>
      <Container style={{ margin: "20px 0" }}>
        <Tab
          panes={panes}
          renderActiveOnly
          activeIndex={activeIndex}
          onTabChange={(
            e,
            { activeIndex, panes }: { activeIndex: number; panes: CustomPane[] }
          ): void => {
            setParam("tab", panes[activeIndex].tabKey);
          }}
        />
      </Container>
    </>
  );
};
