import {
  GenerateRequestItem,
  MappingsDownloadResult,
  MappingsDownloadTaskResult,
  ProductTextTaskResults,
  PublishResult,
  TaskRequest,
  TaskResult,
  TaskResultType,
} from "./types";
// Task dispatcher
import {
  fetchTaskResults2,
  productTextQueueGeneration,
  RegenerateConfig,
} from "../api/action";
import { ProductText, ProductTextRef } from "../producttext/ProductText";
import { ChannelLanguagePairData, ProductId } from "../products/product";
import { publishProductTexts } from "../products/publish/publishActions";
import {
  OverwriteHeader,
  UserActionContext,
} from "../products/publish/actionTypes";
import { requestMappingsDownloadFile } from "../api/extractApi";
import { ProductPublishTaskResult } from "./TaskDispatcher";
import { debounce } from "./debounce";

/* global Promise */

/*
 * First part is that someone requests a new text to be generated
 * This will return a promise that resolves when we have a result
 *
 * A generation request contains is:
 *   product_id
 *   channel_id
 *   language_code
 *
 * Result is product text object
 */
export class TextGenerationRequest extends TaskRequest<ProductText> {
  resultType = TaskResultType.PRODUCT_TEXT;
  productTextRef: ProductTextRef;
  userActionContext: UserActionContext;
  regenerateConfig: RegenerateConfig | null;

  /*
   * product
   * language
   * channelId
   */
  constructor(
    productTextRef: ProductTextRef,
    regenerateConfig: RegenerateConfig | null,
    userActionContext: UserActionContext
  ) {
    super();
    this.productTextRef = productTextRef;
    this.regenerateConfig = regenerateConfig;
    this.userActionContext = userActionContext;
  }

  taskFinished(results: ProductTextTaskResults): void {
    if (results.errors.length > 0) {
      this.reject?.(results.errors[0]);
    } else {
      this.resolve?.(new ProductText(results.product_text));
    }
  }

  getApiParams(): GenerateRequestItem {
    const apiParams: GenerateRequestItem = {
      user_action_context: this.userActionContext,
      ...this.productTextRef.apiParam(),
    };
    if (this.regenerateConfig) {
      apiParams.regenerate_config = {
        regenerate: this.regenerateConfig.regenerate,
        regenerate_approved_texts: this.regenerateConfig
          .regenerateApprovedTexts,
        regenerate_published_texts: this.regenerateConfig
          .regeneratePublishedTexts,
        regenerate_edited_texts: this.regenerateConfig.regenerateEditedTexts,
      };
    }
    return apiParams;
  }
}

export class PublishRequest extends TaskRequest<PublishResult> {
  resultType = TaskResultType.PUBLISH;
  productId: ProductId;
  userActionContext: UserActionContext;
  overwriteHeader: OverwriteHeader;
  channelLanguagePairs: ChannelLanguagePairData[] | null;

  constructor(
    productId: ProductId,
    userActionContext: UserActionContext,
    overwriteHeader: OverwriteHeader,
    channelLanguagePairs: ChannelLanguagePairData[] | null
  ) {
    super();
    this.productId = productId;
    this.userActionContext = userActionContext;
    this.overwriteHeader = overwriteHeader;
    this.channelLanguagePairs = channelLanguagePairs;
  }

  taskFinished(results: ProductPublishTaskResult): void {
    const publishResults: PublishResult = {
      message: results?.message ?? "Product successfully published",
      productTexts: results?.product_texts?.map((textData: any) => {
        // FIXME 2021-08-12 Texts returned from the backend have no product_id and published assigned
        textData.product_id = this.productId;
        return new ProductText(textData);
      }),
    };
    this.resolve?.(publishResults);
  }

  getApiParams(): null {
    return null;
  }
}

export class MappingsDownloadRequest extends TaskRequest<
  MappingsDownloadResult
> {
  resultType = TaskResultType.MAPPINGS_DOWNLOAD;
  keyFilter: string | null;
  mappedFilter: boolean | null;
  namespaceFilter: string | null;
  tagsFilter: string | null;
  valueFilter: string | null;

  constructor(
    keyFilter: string | null,
    mappedFilter: boolean | null,
    namespaceFilter: string | null,
    tagsFilter: string | null,
    valueFilter: string | null
  ) {
    super();
    this.keyFilter = keyFilter;
    this.mappedFilter = mappedFilter;
    this.namespaceFilter = namespaceFilter;
    this.tagsFilter = tagsFilter;
    this.valueFilter = valueFilter;
  }

  taskFinished(results: MappingsDownloadTaskResult): void {
    const mappingsDownloadResult: MappingsDownloadResult = {
      presigned_url: results.presigned_url,
    };
    this.resolve(mappingsDownloadResult);
  }

  getApiParams(): null {
    return null;
  }
}

/*
 * This is a helper to fetch task results asynchronously
 *
 * There are two main responsibilities:
 * - submit tasks
 * - polling for results
 *
 * both of them are debounced, meaning that we'll wait a little bit
 * before sending the requests to be able to group them together.
 *
 * An important part of the design is to avoid blocking
 * both the browser and the webserver, the actually processing
 * happens in celery. That means we have to poll for the results.
 */

export class TaskDispatcher2 {
  private readonly token: string;
  private readonly _eventuallyQueueRequests: () => void;
  private readonly _eventuallyPollResults: () => void;
  _outgoingRequests: TaskRequest<unknown>[] = [];
  private _incomingRequestsByTaskId: Record<string, TaskRequest<unknown>> = {};
  private _retries = 0;
  /*
   * This is how often we should poll task results from the server.
   * May need some tweaking for different customers as the amount of tags and templates
   * on a product will determine how long time it takes to complete a task
   */
  private POLL_RESULT_FREQUENCY_IN_MS = 1000;

  /*
   * This is how often we should submit incoming requests, the idea is that we should
   * submit most of the requests made for the same list together.
   */
  private QUEUE_FREQUENCY_IN_MS = 1000;

  constructor(token: string, pollFrequency = 1000, queueFrequency = 1000) {
    this.token = token;
    this.POLL_RESULT_FREQUENCY_IN_MS = pollFrequency;
    this.QUEUE_FREQUENCY_IN_MS = queueFrequency;
    this._eventuallyQueueRequests = debounce(
      this._processQueuedRequests.bind(this),
      this.QUEUE_FREQUENCY_IN_MS
    );
    this._eventuallyPollResults = debounce(
      this._pollResults.bind(this),
      this.POLL_RESULT_FREQUENCY_IN_MS
    );
  }

  queue<T>(request: TaskRequest<T>): Promise<T> {
    this._outgoingRequests.push(request);
    this._eventuallyQueueRequests();
    this._retries = 0;
    return request.promise;
  }

  clear(): void {
    this._outgoingRequests = [];
    this._incomingRequestsByTaskId = {};
    this._retries = 0;
  }

  /*
   * We have now waited a little bit and we are ready to submit the
   * requests that are sitting in the _pendingRequests queue
   */
  _processQueuedRequests(): void {
    /*
     * Submit a task job,
     * This will return pretty quickly as it's not doing the actual generation work yet
     */
    const requests = this._outgoingRequests.splice(0, 5);
    if (requests.length === 0) {
      return;
    }
    const textGenerationRequests: TextGenerationRequest[] = [];
    const publishGenerationRequests: PublishRequest[] = [];
    const mappingsDownloadRequests: MappingsDownloadRequest[] = [];

    for (const request of requests) {
      switch (request.resultType) {
        case TaskResultType.PUBLISH:
          publishGenerationRequests.push(request as PublishRequest);
          break;
        case TaskResultType.PRODUCT_TEXT:
          textGenerationRequests.push(request as TextGenerationRequest);
          break;
        case TaskResultType.MAPPINGS_DOWNLOAD:
          mappingsDownloadRequests.push(request as MappingsDownloadRequest);
          break;
        default:
          throw new Error(`Not implemented: ${request.resultType}`);
      }
    }

    const nMappingsDownloadRequests = mappingsDownloadRequests.length;
    if (nMappingsDownloadRequests > 1) {
      throw new Error(`Multiple download requests not supported`);
    } else if (nMappingsDownloadRequests == 1) {
      const request = mappingsDownloadRequests[0];
      requestMappingsDownloadFile({
        key: request.keyFilter,
        mapped: request.mappedFilter,
        namespace: request.namespaceFilter,
        tags: request.tagsFilter,
        token: this.token,
        value: request.valueFilter,
      }).then((taskId) => {
        this._incomingRequestsByTaskId[taskId] = request;
        this._eventuallyPollResults();
      });
    }

    if (textGenerationRequests.length > 0) {
      productTextQueueGeneration(this.token, textGenerationRequests).then(
        (taskIds) => {
          textGenerationRequests.map((request, idx) => {
            this._incomingRequestsByTaskId[taskIds[idx]] = request;
          });
          this._eventuallyPollResults();
        }
      );
    }
    // TODO handle case for multiple publish requests
    const nPublishRequests = publishGenerationRequests.length;
    if (nPublishRequests > 1) {
      throw new Error(`Multiple publish requests not supported`);
    } else if (nPublishRequests == 1) {
      const request = publishGenerationRequests[0];
      publishProductTexts(
        this.token,
        request.userActionContext,
        request.productId,
        request.overwriteHeader,
        request.channelLanguagePairs
      ).then((response) => {
        const taskId = response.task_id;
        this._incomingRequestsByTaskId[taskId] = request;
        this._eventuallyPollResults();
      });
    }
    if (this._outgoingRequests.length > 0) {
      this._eventuallyQueueRequests();
    }
  }

  _incomingPending(): boolean {
    return Object.keys(this._incomingRequestsByTaskId).length > 0;
  }

  _processTaskResult(taskId: string, taskResult: TaskResult): void {
    const request = this._incomingRequestsByTaskId[taskId];
    switch (taskResult.status) {
      case "SUCCESS":
        request.taskFinished(taskResult.results);
        break;
      case "FAILURE":
        request.reject?.(taskResult.results?.error || "Task failed.");
        break;
      default:
        return;
    }
    delete this._incomingRequestsByTaskId[taskId];
  }

  /*
   * Everything is queued up,
   * We're waiting for the text generation to be complete.
   */
  _pollResults(): boolean {
    if (!this._incomingPending()) {
      return false;
    }
    const taskIds = Object.keys(this._incomingRequestsByTaskId);
    fetchTaskResults2(this.token, taskIds).then((taskResults) => {
      for (const taskId of Object.keys(taskResults)) {
        const taskResult: TaskResult = taskResults[taskId];
        this._processTaskResult(taskId, taskResult);
      }
      if (this._incomingPending()) {
        this._eventuallyPollResults();
      }
      this._retries = 0;
    });
    if (this._retries > 30) {
      return false;
    }
    // FIXME: Exponentially wait longer times before next attempt
    this._retries += 1;
  }
}
