import React from "react";
import { Text } from "../Text";

type Props = {
  hasData: boolean;
  noDataMessage: string;
  headers: string[];
  body: { id: number; elements: JSX.Element }[];
  additionButton: JSX.Element;
  showAdditionButtonWithNoData?: boolean;
};
/**
 * @description
 *  This component is used to display a table with data or a message if there is no data.
 *  Should be used in the Manage view when building a table in react.
 * @example
 * <ManageTable
 *  hasData={true}
 *  noDataMessage="No data found"
 *  headers={["Header 1", "Header 2"]}
 *  body={[
 *    {
 *      id: 1,
 *      elements: (
 *        <>
 *          <td>Cell 1</td>
 *          <td>Cell 2</td>
 *        </>
 *      ),
 *    },
 *  ]}
 *  additionButton={<Button color="red" basic size="small">Add Data</Button>}
 *  }
 *  showAdditionButtonWithNoData
 * />
 * @param hasData - If there is data to display.
 * @param noDataMessage - The message to display if there is no data.
 * @param headers - The headers to display in the table.
 * @param body - The body to display in the table.
 * @param additionButton - The button to display at the bottom of the table.
 * @param showAdditionButtonWithNoData - If the addition button should be displayed when there is no data.
 */
export const ManageTable: React.FC<Props> = ({
  hasData,
  noDataMessage,
  headers,
  body,
  additionButton,
  showAdditionButtonWithNoData,
}) => {
  return (
    <div>
      {hasData ? (
        <table className="txu-table txu-compact txu-center-all">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map(({ id, elements }) => (
              <tr key={id}>{elements}</tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={headers.length} className="!tw-text-right">
                {additionButton}
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <div className="no-data-found">
          <Text as="h3">{noDataMessage}</Text>
          {showAdditionButtonWithNoData && additionButton}
        </div>
      )}
    </div>
  );
};
