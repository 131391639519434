import React from "react";
import { useGetCustomerQuery } from "../api/customerApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import {
  CustomQueryRenderTable,
  DefaultHeadingH5,
  DefaultParagraph,
} from "./CustomQueryRenderTable";

export const CustomQueryLanguagesSection: React.FC = () => {
  const customerLanguages = useSelector(
    (state: RootState) => state.customQuery.customerLanguages
  );

  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }

  const tableContent = [
    { as: DefaultHeadingH5, column1: "Language", column2: "ISO code" },
    ...customerLanguages?.map(({ language }) => {
      return {
        as: DefaultParagraph,
        column1: customer.languages?.filter(
          (customerLanguage) => customerLanguage.code === language
        )[0].name,
        column2: language,
      };
    }),
  ];

  return (
    <CustomQueryRenderTable
      description={"List of languages used by this customer"}
      header={"Customer languages"}
      name={"languages"}
      tableContent={tableContent}
    />
  );
};
