import React, { useEffect, useState } from "react";
import { Tab, Table } from "semantic-ui-react";
import { useSelector } from "react-redux";

import { useGetCustomerQuery } from "../../api/customerApi";
import { getTextBlocks, TextBlock } from "../../api/textBlockApi";
import { Customer } from "../../customers/Customer";
import { Flag, LanguageCode } from "../../customers/customerlanguages";
import { RootState } from "../../utils/store";

export function showLanguage(
  customer: Customer,
  languageCode: LanguageCode
): React.ReactNode {
  if (!customer) return "";
  const language = customer.languages.find(({ code }) => code === languageCode);
  if (!language) return "";
  return (
    <>
      <Flag content={language.flag} /> {language.name}
    </>
  );
}

type Props = {
  productId: string;
};

export const TabTextBlocks: React.FC<Props> = ({ productId }) => {
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [textBlocks, setTextBlocks] = useState([]);

  useEffect(() => {
    let mounted = true;
    getTextBlocks(token, productId).then((data) => {
      if (mounted) setTextBlocks(data);
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Tab.Pane className="tab-text-blocks">
      <Table
        className="text-blocks"
        data-testid="table-text-blocks"
        compact
        size="small"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Text</Table.HeaderCell>
            <Table.HeaderCell>Language</Table.HeaderCell>
            <Table.HeaderCell>Section ID</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {textBlocks?.map((block: TextBlock) => (
            <Table.Row
              key={`${block.id}`}
              disabled={!block.active}
              data-testid="table-text-blocks-row"
            >
              <Table.Cell>{block.text}</Table.Cell>
              <Table.Cell>{showLanguage(customer, block.language)}</Table.Cell>
              <Table.Cell>{block.section_id}</Table.Cell>
              <Table.Cell>{block.active ? "Active" : "Inactive"}</Table.Cell>
              <Table.Cell>{block.created}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};
