import { ProductDetailInfo } from "./ProductDetailInfo";
import React from "react";
import { BalloonDiv } from "../../components/BalloonDiv";
import { VocabularyLookupNewComp } from "../../vocabulary/VocabularyLookupNew";
import { ProductTagAction } from "../../api/action";
import { NOOP } from "../../index";
import { CustomerSettings } from "../../customers/customersettings";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import { FullTag } from "../../utils/tagutils";
import { uuidv4 } from "../../utils/uuidUtils";

interface Props {
  customerSettings: CustomerSettings;
  details: ProductDetailInfo;
  onTagsAction?: onTagsActionCallback;
  selected: SidebarItem;
}

/**
 * Main category field
 * @param props
 * @constructor
 */
export const MainCategoryField: React.FC<Props> = ({
  customerSettings,
  details,
  onTagsAction,
  selected,
}) => {
  let productTypeMicrocopy = "main_category";
  let placeholderTypeMicrocopy = "searchbox_main_category";
  let label = "Main Category";
  if (selected.isSubpart) {
    productTypeMicrocopy = "attribute_category";
    placeholderTypeMicrocopy = "searchbox_attribute_category";
    label = "Attribute";
  }
  const parent = selected.isSubpart ? selected.id : null;
  const addTag = (value: FullTag): void => {
    value.id = uuidv4();
    onTagsAction?.({
      action: ProductTagAction.ADD,
      parent,
      value,
    });
  };
  const removeTag = (): void => {
    onTagsAction?.({
      action: ProductTagAction.REMOVE,
      value: [selected.id],
    });
  };
  return (
    <div className={"product-type-row"}>
      <div className={"product-type"}>
        <BalloonDiv
          className={"label"}
          value={details.microcopies[productTypeMicrocopy]}
        >
          <label htmlFor={"id_product_type"}>{label}</label>
        </BalloonDiv>
        <VocabularyLookupNewComp
          {...customerSettings}
          componentId={details.createComponentId()}
          disableAutoAdd={false}
          isMultiple={false}
          showVocabType={false}
          language={details.getLanguage()}
          limit={20}
          onVocabularyAdded={addTag}
          onVocabularyRemoved={removeTag}
          onVocabularyCleared={removeTag}
          onLoaded={NOOP}
          placeholder={details.microcopies[placeholderTypeMicrocopy]}
          selectedValue={selected.name}
          showTagTypeHints={false}
          tagCategory={"identifier"}
          tagType={"kind"}
          textualAppName={details.textualAppName}
          vocabularyRequestProductDescription={""}
          vocabularyRequestProductId={details.productId}
        />
      </div>
    </div>
  );
};
