import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Product, ProductId, ProductStatus } from "../products/product";
import { prepareCustomHeaders } from "../utils/baseQueryHeaders";
import { UserActionContext } from "../products/publish/actionTypes";
import { RegenerateConfig, SwitchProductStatusRequestData } from "./action";

type ProductStatusBodyType = {
  productId?: ProductId;
  status: ProductStatus;
  userActionContext: UserActionContext;
  regenerateConfig?: RegenerateConfig;
};

const prepareProductStatusUpdateBody = ({
  status,
  userActionContext,
  regenerateConfig,
}: ProductStatusBodyType): SwitchProductStatusRequestData => {
  // null is not allowed in typescript enums
  if (status == ProductStatus.IN_PROGRESS) {
    status = null;
  }
  const postData: SwitchProductStatusRequestData = {
    status: status,
    user_action_context: userActionContext,
  };
  if (regenerateConfig) {
    postData.regenerate_config = {
      regenerate: regenerateConfig.regenerate,
      regenerate_approved_texts: regenerateConfig.regenerateApprovedTexts,
      regenerate_published_texts: regenerateConfig.regeneratePublishedTexts,
      regenerate_edited_texts: regenerateConfig.regenerateEditedTexts,
    };
  }
  return postData;
};

export const productDetailApi = createApi({
  reducerPath: "productDetailApi",
  tagTypes: ["Product"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/_internal/product",
    prepareHeaders: prepareCustomHeaders,
  }),
  endpoints: (build) => ({
    getProduct: build.query<Product, ProductId>({
      query: (productId) => `/${productId}/list_one`,
      providesTags: (result, error, productId) => [
        { type: "Product", id: productId },
      ],
      transformResponse: (response: Product) => response,
    }),
    setProductStatus: build.mutation<Product, ProductStatusBodyType>({
      query: ({ productId, ...body }: ProductStatusBodyType) => ({
        url: `/${productId}/status`,
        method: "POST",
        body: prepareProductStatusUpdateBody(body),
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: "Product", id: productId },
      ],
    }),
  }),
});

export const {
  useGetProductQuery,
  useSetProductStatusMutation,
} = productDetailApi;
