import api from "./api";
import { ProductId } from "../products/product";
import { TemplateLabel } from "../vocabulary/template";
import { AxiosResponse } from "axios";

const BASE_URL = "/api/_internal/product-template-vocabulary-labels";

type GetAllLabelResponse = {
  activeLabels: TemplateLabel[];
  labels: TemplateLabel[];
};
type GetAllLabelData = {
  active_labels: TemplateLabel[];
  labels: TemplateLabel[];
};
export async function getAllLabelsForTemplate(
  productId: ProductId,
  templateId: number,
  templateTagId: string,
  token: string
): Promise<GetAllLabelResponse> {
  const url = BASE_URL + "/get-all";
  const params = {
    template_id: templateId,
    product_id: productId,
    template_tag_id: templateTagId,
  };
  const response = await api.get<GetAllLabelData>(url, {
    headers: { token: token },
    params,
  });
  const { active_labels, labels } = response.data;

  return {
    activeLabels: active_labels,
    labels,
  };
}

export async function updateActiveLabels(
  labelId: number,
  productId: ProductId,
  templateId: number,
  templateTagId: string,
  token: string
): Promise<AxiosResponse> {
  const url = BASE_URL + "/update";
  const data = {
    label_id: labelId,
    product_id: productId,
    template_id: templateId,
    template_tag_id: templateTagId,
  };

  return api.post(url, data, {
    headers: { token: token },
  });
}

export const BASE_MANAGE_URL = "/api/_internal/vocabulary/template-label/";

export async function getTemplateLabels(
  token: string
): Promise<TemplateLabel[]> {
  const response = await api.get<TemplateLabel[]>(BASE_MANAGE_URL, {
    headers: { token: token },
  });

  return response.data;
}

export async function createTemplateLabel(
  token: string,
  name: string,
  description: string
): Promise<TemplateLabel> {
  const response = await api.post<TemplateLabel>(
    BASE_MANAGE_URL,
    { name, description },
    { headers: { token: token } }
  );
  return response.data;
}

export async function updateTemplateLabel(
  token: string,
  id: number,
  name?: string,
  description?: string
): Promise<TemplateLabel> {
  const response = await api.put<TemplateLabel>(
    BASE_MANAGE_URL + id,
    { name, description },
    { headers: { token: token } }
  );
  return response.data;
}

export async function deleteTemplateLabel(
  token: string,
  id: number
): Promise<unknown> {
  return api.delete(BASE_MANAGE_URL + id, { headers: { token: token } });
}
