import styled from "styled-components";
import { Text } from "../Text";

type Props = {
  width?: string;
};

export const ANIMATION_DURATION = "3s";

const hideTexts = `
text-indent: 100%;
white-space: nowrap;
overflow: hidden;
`;

export const FixOverflow = styled.div`
  overflow: clip;
  overflow-clip-margin: content-box;
  padding-right: 10px !important;
`;

export const BackgroundAnimation = styled.div<Props>`
  &&& {
    width: ${({ width }): string => width || "max-content"};
    animation: animate-background ${ANIMATION_DURATION} linear infinite;
    border-radius: 4px;
    background-size: 300% 300%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0.05) 51%,
      rgba(0, 0, 0, 0) 70%
    );

    @keyframes animate-background {
      0% {
        background-position: 300% 0;
      }
      100% {
        background-position: -200% 0;
      }
    }
  }
`;

export const HeaderSkeleton = styled(Text)`
  margin-bottom: 5px !important;
  width: max-content;

  ${hideTexts}
`;
export const ParagraphSkeleton = styled(Text)`
  width: max-content;
  margin-top: 3px !important;

  ${hideTexts}
`;
