import React, { useEffect, useRef, useState } from "react";
import { getProductParentAndChildrenIds, Product, ProductId } from "../product";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";
import {
  ProductText,
  ProductTextRef,
  ProductTextRefKey,
} from "../../producttext/ProductText";
import {
  ChannelLanguagePair,
  ProductListParams,
  URLConfigParser,
} from "./ProductListURLConfig";
import { Column } from "./columns/Column";
import {
  DateCategory,
  PageSizeStatus,
  ParentChildStatus,
  ProductStatusFilter,
  TextStatus,
  TranslationStatus,
  ItemsWithOperator,
  emptyStringItems,
} from "./ProductListTypes";
import { ColumnValue, defaultColumns } from "./columns/Columns";
import { FullTag } from "../../utils/tagutils";
import { useDispatch, useSelector } from "react-redux";
import {
  setProducts,
  setCustomQuery,
  setPaginationDetails,
} from "../../api/productListSlice";
import { Customer } from "../../customers/Customer";
import { ProductSearchAction, ProductSearchResult } from "../../api/types";
import {
  fetchProductCount,
  getProductTexts,
  productSearch,
  productTextAction,
  selectionBucketAction,
} from "../../api/action";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { RootState, store } from "../../utils/store";
import {
  ProductListSelectionBanner,
  SelectionBannerAction,
} from "./selection-banner/ProductListSelectionBanner";
import {
  TaskDispatcher2,
  TextGenerationRequest,
} from "../../utils/TaskDispatcher2";
import { OverwriteHeader, UserActionContext } from "../publish/actionTypes";
import { createProductReferenceColumn } from "./columns/ProductListReferenceColumn";
import { ProductListSelectColumn } from "./columns/ProductListSelectColumn";
import { ProductListSKUColumn } from "./columns/ProductListSKUColumn";
import { ProductListTextStatusesColumn } from "./columns/ProductListTextStatusesColumn";
import { ProductListEANColumn } from "./columns/ProductListEANColumn";
import { ProductListProductTypeColumn } from "./columns/ProductListProductTypeColumn";
import { ProductListOriginalTextColumn } from "./columns/ProductListOriginalTextColumn";
import { ProductListImageColumn } from "./columns/ProductListImageColumn";
import { ProductListStatusColumn } from "./columns/ProductListStatusColumn";
import { createProductTextColumn } from "./columns/ProductListTextColumn";
import { ProductListLabelColumn } from "./columns/ProductListLabelColumn";
import { ProductListParentColumn } from "./columns/ProductListParentColumn";
import { ProductListCreatedColumn } from "./columns/ProductListCreatedColumn";
import { ProductListUpdatedColumn } from "./columns/ProductListUpdatedColumn";
import { ProductListLinksColumn } from "./columns/ProductListLinksColumn";
import { LanguageCode } from "../../customers/customerlanguages";
import { ProductListSearchbar } from "./ProductListSearchbar";
import { ViewSetsFilterContainer } from "./viewsets/ViewSetsFilterContainer";
import { ProductFilterTopBar } from "./top-bar/ProductFilterTopBar";
import { OriginalTextLanguageSelectorField } from "./OriginalTextLanguageSelectorField";
import { ObjectTable, ProductListRenderContext } from "./ObjectTable";
import { TaskDispatcher } from "../../utils/TaskDispatcher";
import { setCustomQueryContent } from "./CustomQueryHint";
import { ChannelLanguageSelectorModal } from "./ChannelLanguageSelectorModal";
import { FavoriteViews } from "./viewsets/FavoriteViews";

export enum SelectionAction {
  ADD = "add",
  REMOVE = "remove",
  CLEAR = "clear",
}

export const generateBucketName = (): string => {
  return [...Array(10)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
};

export enum ProductListAction {
  ChangeBrands = "brands",
  ChangeColumns = "columns_change",
  ChangeChannelLanguagePair = "channel_language_change",
  ChangeDateCategory = "date_category",
  ChangeDateEnd = "date_end",
  ChangeDateStart = "date_start",
  ChangeGroupChildren = "group_children",
  ChangeLabel = "label",
  ChangeOriginalLanguage = "original_language",
  ChangePageSize = "pagination_size",
  ChangeParentChildStatus = "parent_child",
  ChangeProductStatusFilter = "status",
  ChangeReferenceChannelLanguagePair = "reference_channel_language_change",
  ChangeSearchQuery = "search",
  ChangeTemplate = "template",
  ChangeTextStatus = "text_status",
  ChangeTranslationStatus = "translation_status",
  ChangeVocabulary = "vocabulary",
  UpdateColumns = "columns_update",
  UpdateChannelLanguagePair = "channel_language_update",
  UpdateSearchBar = "searchbar_update",
  UseViewSet = "use_viewset",
  Reset = "reset",
}

export type ProductTextsMapping = Record<ProductTextRefKey, ProductText>;

type Props = {
  /** Name of the currently active view or "" if empty */
  activeViewName?: string;
  /** Current selection bucket */
  bucket?: string;
  /** custom query, or "" if not provided **/
  query?: string;
  /** internal application name, used by legacy backend api endpoints */
  textualAppName: string;
};

export const ProductList: React.FC<Props> = ({
  bucket,
  query,
  textualAppName,
}) => {
  const urlConfig = new URLConfigParser();

  /** `true` if all products are selected */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allSelected, setAllSelected] = useState(false);

  /** The current brand filters */
  const [brands, setBrands] = useState<string[]>(urlConfig.getBrands());

  /** toggle ChannelLanguageSelectorModal */
  const [
    channelLanguageSelectorOpen,
    setChannelLanguageSelectorOpen,
  ] = useState(false);

  /** all available columns */
  const [columns, setColumns] = useState<Column[]>([]);

  /** current page number */
  const [currentPage, setCurrentPage] = useState<number>(urlConfig.getPage());

  /** Date category filter */
  const [dateCategory, setDateCategory] = useState<DateCategory>(
    urlConfig.getDateCategory()
  );

  /** end date filter or `null` if no date has been selected */
  const [dateEnd, setDateEnd] = useState<Date | null>(urlConfig.getDateEnd());

  /** start date filter or `null` if no date has been selected */
  const [dateStart, setDateStart] = useState<Date | null>(
    urlConfig.getDateStart()
  );

  /** `true` If children of the same parent should grouped together */
  const [groupChildren, setGroupChildren] = useState<boolean>(
    urlConfig.getGroupChildren()
  );

  /** The current label filters */
  const [label, setLabel] = useState<ItemsWithOperator>(urlConfig.getLabel());

  /** The original language columns */
  const [originalLanguage, setOriginalLanguage] = useState<string>(
    urlConfig.getOriginalTextLanguage()
  );

  /** The currently displayed product texts */
  const [productTexts, setProductTexts] = useState<ProductTextsMapping>({});

  /** `false` If fetching Product Texts has been finished */
  const [loadingProductTexts, setLoadingProductTexts] = useState(false);

  /** The current product search filter */
  const [productStatusFilter, setProductStatusFilter] = useState<
    ProductStatusFilter
  >(urlConfig.getProductStatusFilter());

  /** The current parent/child status filter */
  const [parentChildStatus, setParentChildStatus] = useState<ParentChildStatus>(
    urlConfig.getParentChildStatus()
  );

  /** The current page size, how many products that are visible on each page */
  const [pageSize, setPageSize] = useState<PageSizeStatus>(
    urlConfig.getPageSize()
  );

  /**
   * The list of products that are displayed in the list
   * - `null` means that the search has not been finished yet
   * - `[]` means that the search has been finished, but it returned no results
   */
  const products = useSelector(
    (state: RootState) => state.productList.products
  );

  /** The current search query, this is what the user typed in the input field */
  const [searchQuery, setSearchQuery] = useState<string>(urlConfig.getSearch());

  /**
   * error: if the search api throws an error this will be set to true
   * isNotDefaultFilters: a check to display Clear button on failed search if filters are active
   * onClear: Uses the function in ViewSetsFilterContainer.tsx
   */
  const [errorSearchQuery, setErrorSearchQuery] = useState(false);

  /** The currently disabled columns */
  let currentlySelectedColumns = defaultColumns;
  const getVisibleColumns = urlConfig.getVisibleColumns();
  if (getVisibleColumns.length > 0) {
    currentlySelectedColumns = getVisibleColumns;
  }
  const [selectedColumns, setSelectedColumns] = useState<ColumnValue[]>(
    currentlySelectedColumns
  );

  /** The currently selected channel language pairs */
  const [
    selectedChannelLanguagePairs,
    setSelectedChannelLanguagePairs,
  ] = useState<ChannelLanguagePair[]>(urlConfig.channel_language_pairs);

  /** All currently selected products */
  const [selectedProducts, setSelectedProducts] = useState<Set<ProductId>>(
    new Set()
  );

  /** The currently selected reference channel language pairs */
  const [
    selectedReferenceChannelLanguagePairs,
    setSelectedReferenceChannelLanguagePairs,
  ] = useState<ChannelLanguagePair[]>([]);

  /** The current template filter, or `null` if not filtering by template */
  const [template, setTemplate] = useState<string | null>(
    urlConfig.getTemplate()
  );

  /** The current text status filter */
  const [textStatus, setTextStatus] = useState<TextStatus>(
    urlConfig.getTextStatus()
  );

  /** Total number of products loaded or `null` if search has not finished */
  const [totalCount, setTotalCount] = useState<number | null>(null);

  /** The current translation status filter */
  const [translationStatus, setTranslationStatus] = useState<TranslationStatus>(
    urlConfig.getTranslationStatus()
  );

  /**
   * The current vocabulary filter or `null`, if not filterering by vocabularies
   */
  const [vocabulary, setVocabulary] = useState<FullTag | null>(
    urlConfig.getVocabulary()
  );

  const mounted = useRef(true);

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);

  urlConfig.setBucket(bucket || generateBucketName());
  urlConfig.refresh();

  const taskDispatcher = new TaskDispatcher(token);
  const taskDispatcher2 = new TaskDispatcher2(token);
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && customer) {
      updateSelectionBucket(SelectionAction.CLEAR);
      fetchColumns();
      dispatch(setCustomQuery(setCustomQueryContent({ customer, query })));
    }
    return (): void => {
      mounted.current = false;
    };
  }, [customer]);

  const fetchProductText = async (
    customer: Customer,
    searchResult: ProductSearchResult,
    token: string
  ): Promise<void> => {
    setLoadingProductTexts(true);
    const channelLanguagePairs = [
      ...urlConfig.channel_language_pairs,
      ...urlConfig.getReferenceLanguageChannelPairs(),
    ];

    const fetchAllLanguagePairs = selectedColumns.includes(
      ColumnValue.TextStatuses
    );

    const newlyFetchedProductTexts = await getProductTexts({
      token,
      productIds: getProductParentAndChildrenIds(searchResult.results),
      // If we are showing the texts statuses column, we want all channel language pairs,
      // not just the ones that we have visible text columns for.
      channelLanguagePairs: fetchAllLanguagePairs ? null : channelLanguagePairs,
    });
    if (!mounted.current) {
      return;
    }
    updateProductTexts(newlyFetchedProductTexts);
    if (customer == null) {
      return;
    }
    for (const product of searchResult.results) {
      for (const {
        channel_id: channelId,
        language_code: language,
      } of channelLanguagePairs) {
        const productTextRef = new ProductTextRef(
          product.id,
          channelId,
          language
        );
        const productText = productTexts[productTextRef.key];
        if (productText) {
          if (!productText.shouldRegenerate()) {
            continue;
          }
        }
        onRequestProductTextAction(ProductTextAction.GENERATE, productTextRef);
      }
    }
    setLoadingProductTexts(false);
  };

  const search = async (): Promise<void> => {
    // FIXME: Should probably prefetch to make it faster to navigate to the next pages
    const searchParam = urlConfig.getSearchParams();
    dispatch(
      setPaginationDetails({
        searchParams: null,
        productCount: null,
      })
    );

    dispatch(setProducts(null));
    setErrorSearchQuery(false);

    const searchResult = await productSearch(
      token,
      searchParam,
      ProductSearchAction.SEARCH
    );
    if (searchResult.error) {
      setErrorSearchQuery(true);
      return;
    }
    if (!mounted.current) {
      return;
    }

    let { results: productList } = searchResult;

    productList = productList.map(
      (product: Product): Product =>
        ({
          ...product,
          selected: selectedProducts.has(product.id),
        } as Product)
    );
    dispatch(setProducts(productList));

    const searchParams = urlConfig.getSearchParams();
    const totalCount = await fetchProductCount({
      token,
      searchParams,
    });
    dispatch(
      setPaginationDetails({
        searchParams: searchParams,
        productCount: totalCount,
      })
    );

    const allSelected = selectedProducts.size === totalCount;
    setTotalCount(totalCount);
    setAllSelected(allSelected);
    setLoadingProductTexts(true);
    await fetchProductText(customer, searchResult, token);
  };

  const fetchColumns = (): void => {
    if (!mounted.current) {
      return;
    }
    const columns = getColumns();
    const selectedReferenceChannelLanguagePairs = urlConfig.getReferenceLanguageChannelPairs();
    setColumns(columns);
    setSelectedReferenceChannelLanguagePairs(
      selectedReferenceChannelLanguagePairs
    );
    search();
  };

  const updateSelectionBucket = (
    action: SelectionAction,
    data: ProductId[] = []
  ): Promise<ProductId[]> => {
    const { bucket } = urlConfig.getSearchParams();
    return selectionBucketAction(token, bucket, action, data);
  };

  const onPageChange = (currentPage: number): void => {
    taskDispatcher.clear();
    taskDispatcher2.clear();
    setCurrentPage(currentPage);
    setProductTexts({});
    urlConfig.setPage(currentPage);
    urlConfig.refresh();
    search();
  };

  const onSelectRowChange = (selected: boolean, productID: ProductId): void => {
    // A checkbox was toggled, update the project state for the selected product
    if (!selected) {
      setAllSelected(false);
    }
    setSelectedProducts((prevState) => {
      const currentSelectedProduct = new Set(prevState);
      if (selected) {
        currentSelectedProduct.add(productID);
      } else {
        currentSelectedProduct.delete(productID);
      }
      return currentSelectedProduct;
    });

    updateSelectionBucket(
      selected ? SelectionAction.ADD : SelectionAction.REMOVE,
      [productID]
    );
  };

  const onSelectAllOnCurrentPage = (allRowSelected: boolean): void => {
    const productIds = products.map((product: Product) => product.id);
    let newSelectedProducts;
    if (allRowSelected) {
      newSelectedProducts = new Set([...selectedProducts, ...productIds]);
      updateSelectionBucket(SelectionAction.ADD, productIds);
    } else {
      newSelectedProducts = new Set(selectedProducts);
      for (const productId of productIds) {
        newSelectedProducts.delete(productId);
      }
      updateSelectionBucket(SelectionAction.REMOVE, productIds);
    }
    setSelectedProducts(newSelectedProducts);
    setAllSelected(allRowSelected);
  };

  const onObjectSelectionAction = (action: SelectionBannerAction): void => {
    /*
     * Select all was selected,
     * It has a special meaning, if selected -> run bulk actions on _all_ products,
     * not just the ones in the current view.
     */

    if (action == SelectionBannerAction.APPROVE) {
      onApproveSelected();
    } else if (action == SelectionBannerAction.SELECT_EVERYTHING) {
      const searchParam = urlConfig.getSearchParams();
      productSearch(token, searchParam, ProductSearchAction.SELECT).then(
        (searchResult) => {
          if (!mounted.current) {
            return;
          }
          setAllSelected(true);
          setSelectedProducts(new Set(searchResult));
        }
      );
    } else if (action == SelectionBannerAction.CLEAR) {
      updateSelectionBucket(SelectionAction.CLEAR).then((): void => {
        setAllSelected(false);
        setSelectedProducts(new Set());
      });
    }
  };

  const updateProductTexts = (
    currentProductTexts: ProductText[]
  ): ProductText[] => {
    const productList = store.getState().productList.products;
    setProductTexts((prevState) => {
      const prevProductTexts = { ...prevState } as ProductTextsMapping;
      const productIds: ProductId[] = getProductParentAndChildrenIds(
        productList
      );
      for (const productText of currentProductTexts) {
        // The user might have switched pages and a result may come from
        // a previous page, so verify that the product text has a product before
        // adding it to the list of texts
        if (productIds.includes(productText.productId)) {
          prevProductTexts[productText.ref.key] = productText;
        }
      }
      return prevProductTexts;
    });
    return currentProductTexts;
  };

  const onRequestProductTextAction = (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: null
  ): Promise<ProductText> => {
    switch (action) {
      case ProductTextAction.GENERATE:
      case ProductTextAction.REGENERATE: {
        let regenerateConfig = null;
        if (action === ProductTextAction.REGENERATE) {
          regenerateConfig = {
            regenerate: true,
            regenerateApprovedTexts: true,
            regenerateEditedTexts: true,
            regeneratePublishedTexts: true,
          };
        }
        return taskDispatcher2
          .queue(
            new TextGenerationRequest(
              productTextRef,
              regenerateConfig,
              UserActionContext.PRODUCT_LIST_TEXT_COLUMN_TOOLBAR
            )
          )
          .then((productText: ProductText) => {
            return updateProductTexts([productText])[0];
          });
      }
      case ProductTextAction.PUBLISH: {
        const publishDispatcher = taskDispatcher.publishProduct(
          productText.productId,
          UserActionContext.PRODUCT_LIST_TEXT_COLUMN_TOOLBAR,
          OverwriteHeader.NOT_SET,
          [
            {
              language_code: productText.languageCode,
              channel_id: productText.customerChannelId,
            },
          ]
        );
        return publishDispatcher
          .then(() => {
            // Publishing does not return the refresh product text, so do it manually.
            return getProductTexts({
              token: token,
              productIds: [productText.productId],
              channelLanguagePairs: [
                {
                  channel_id: productText.customerChannelId,
                  language_code: productText.languageCode,
                },
              ],
            });
          })
          .then((productTexts) => {
            return updateProductTexts(productTexts)[0];
          });
      }
      case ProductTextAction.APPROVE:
      case ProductTextAction.CLOSE_ACTIVITY_MODAL:
      case ProductTextAction.CLOSE_COMMENT_EDITOR:
      case ProductTextAction.CLOSE_TEXT_EDITOR:
      case ProductTextAction.COMMENT:
      case ProductTextAction.EDIT:
      case ProductTextAction.OPEN_ACTIVITY_MODAL:
      case ProductTextAction.OPEN_COMMENT_EDITOR:
      case ProductTextAction.OPEN_TEXT_EDITOR:
      case ProductTextAction.REVERT:
      case ProductTextAction.SAVE_AND_CLOSE_COMMENT_EDITOR:
      case ProductTextAction.SAVE_AND_CLOSE_TEXT_EDITOR:
      case ProductTextAction.UNAPPROVE: {
        return productTextAction(
          token,
          UserActionContext.PRODUCT_LIST_TEXT_COLUMN_TOOLBAR,
          [productText.id],
          action,
          data
        ).then((productTexts: ProductText[]) => {
          if (!mounted.current) {
            return;
          }
          return updateProductTexts(productTexts)[0];
        });
      }
      default: {
        const exhaustiveCheck: never = action;
        throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
      }
    }
  };

  const showTextColumnsFunc = (visibleColumns: ColumnValue[]): boolean => {
    return (
      visibleColumns.length == 0 ||
      visibleColumns.includes(ColumnValue.GenerateText)
    );
  };

  const onProductListTextStatusesCellFlagToggled = (open: boolean): void => {
    setChannelLanguageSelectorOpen(open);
  };

  const onLinkOverlaySetProducts = (products: Product[]): void => {
    dispatch(setProducts(products));
  };

  const onSelectLabel = (label: string): void => {
    const l: ItemsWithOperator = {
      items: [label],
      operator: "AND",
    };
    urlConfig.setLabel(l);
    setLabel(l);
    refreshList();
  };

  const getReferenceColumns = (
    customer: Customer,
    showTextColumns: boolean
  ): Column[] => {
    const referenceLanguageChannelPairs = urlConfig.getReferenceLanguageChannelPairs();
    return referenceLanguageChannelPairs.map(
      ({ channel_id, language_code }) => {
        return createProductReferenceColumn(
          customer,
          channel_id,
          language_code,
          showTextColumns
        );
      }
    );
  };

  // FIXME: Move product list state & product texts to redux
  const context: ProductListRenderContext = {
    loadingProductTexts,
    onLinkOverlaySetProducts,
    onProductListTextStatusesCellFlagToggled,
    onRequestProductTextAction,
    onSelectAllOnCurrentPage,
    onSelectRowChange,
    onSelectLabel,
    products,
    productTexts,
    selectedProducts,
    urlConfig,
    onObjectSelectionAction,
    totalProducts: totalCount,
    pageSize,
  };

  const getColumns = (): Column[] => {
    const visibleColumns = urlConfig.getVisibleColumns();
    const showTextColumns = showTextColumnsFunc(visibleColumns);
    const columns = [
      ProductListSelectColumn,
      ProductListSKUColumn,
      ProductListTextStatusesColumn,
      ProductListEANColumn,
      ProductListProductTypeColumn,
      ProductListOriginalTextColumn,
      ProductListImageColumn,
      ProductListStatusColumn,
      // Dynamic text reference columns depending on channel configuration
      ...getReferenceColumns(customer, showTextColumns),
      // Dynamic text columns depending on channel configuration
      ...urlConfig.channel_language_pairs.map(
        ({ channel_id, language_code }) => {
          return createProductTextColumn(
            customer,
            channel_id,
            language_code,
            showTextColumns
          );
        }
      ),
      ProductListLabelColumn,
      ProductListParentColumn,
      ProductListCreatedColumn,
      ProductListUpdatedColumn,
      ProductListLinksColumn,
    ];

    // Fetch column names from URL and mark them as visible
    return columns.filter((column: Column): boolean => {
      // visible columns can't be turned off
      if ("visible" in column && column.visible) {
        return true;
        // If there are no columns, check if it should be visible by default
      } else if (!visibleColumns.length && "default" in column) {
        return column.default;
      }
      // Otherwise, honor the user-setting
      return visibleColumns.includes(column.name as ColumnValue);
    });
  };

  const onApproveSelected = (): void => {
    const productTextIds: number[] = [];
    const channelNames = new Set<string>();
    if (products !== null) {
      products
        .filter((p: Product) => selectedProducts.has(p.id))
        .map((p: Product) =>
          urlConfig.channel_language_pairs.map((pair: ChannelLanguagePair) => {
            const refKey = ProductTextRef.refKey(
              p.id,
              pair.channel_id,
              pair.language_code
            );
            const productText = productTexts[refKey];
            if (productText) {
              const channelObject = customer.getChannelById(pair.channel_id);
              const languageObject = customer.getLanguageByCode(
                pair.language_code
              );
              channelNames.add(
                `${channelObject.display_name} ${languageObject.short_name}`
              );
              productTextIds.push(productText.id);
            }
          })
        );
    }
    const params = new URLSearchParams();
    params.set("text_ids", productTextIds.map((i) => i.toString()).join(","));
    Array.from(channelNames).map((channelName) =>
      params.append("channel_name", channelName)
    );
    const redirectUrl = window.location.href;
    params.set("return_url", redirectUrl);
    const url = `bulk-actions/perform/ApproveTextAction?${params.toString()}`;
    if (url.length > 4000) {
      alert("ERROR: Too many product texts selected.");
    } else {
      window.location.href = url;
    }
  };

  const setProductListParams = (
    customer: Customer,
    params: ProductListParams
  ): void => {
    const {
      brands: brandParam,
      columns,
      date_category,
      date_start,
      date_end,
      group_children,
      label: labelParam,
      mode,
      original_language,
      pagination_size,
      parent_child,
      page,
      query: queryParam,
      reference,
      search,
      status,
      template,
      text_status,
      translation_status,
      view,
      viewset,
      vocabulary: vocabularyParam,
    } = params;
    let label = emptyStringItems;
    if (labelParam) {
      if (typeof labelParam === "string") {
        // old format (JSON encoded list): "[\"label one\"]"
        label = {
          items: JSON.parse(labelParam),
          operator: "AND",
        };
      } else if (labelParam.items) {
        // new format (JS object ItemsWithOperator)
        label = labelParam;
      }
    }

    const customQuery = queryParam || null;
    const selectedReferenceChannelLanguagePairs = (reference || []).map(
      (refCol) => {
        const [language, channel] = refCol.split("-");
        return {
          language_code: language,
          channel_id: parseInt(channel),
        } as ChannelLanguagePair;
      }
    );
    const selectedChannelLanguagePairs = urlConfig.parseQueryChannelLanguagePairs(
      params
    );
    if (selectedChannelLanguagePairs.length === 0) {
      selectedChannelLanguagePairs.push({
        language_code: customer.config.tag_input_language,
        channel_id: customer.data.default_channel,
      });
    }
    const selectedColumns = columns || defaultColumns;
    const vocabulary = vocabularyParam ? JSON.parse(vocabularyParam) : null;
    urlConfig.resetParams(selectedChannelLanguagePairs[0]);
    const brands = brandParam || [];
    if (brands.length) {
      urlConfig.setBrands(brands);
    }
    if (date_category) {
      urlConfig.setDateCategory(date_category as DateCategory);
    }
    if (date_end) {
      urlConfig.setDateEnd(new Date(date_end));
    }
    if (date_start) {
      urlConfig.setDateStart(new Date(date_start));
    }
    if (group_children) {
      urlConfig.setGroupChildren(group_children);
    }
    if (label.items.length) {
      urlConfig.setLabel(label);
    }
    if (mode) {
      urlConfig.setMode(mode);
    }
    if (original_language) {
      urlConfig.setOriginalTextLanguage(original_language as LanguageCode);
    }
    if (page) {
      urlConfig.setPage(parseInt(page));
    }
    if (pagination_size) {
      urlConfig.setPageSize(pagination_size.toString() as PageSizeStatus);
    }
    if (parent_child) {
      urlConfig.setParentChildStatus(parent_child as ParentChildStatus);
    }
    if (customQuery) {
      urlConfig.setQuery(customQuery);
    }
    if (selectedReferenceChannelLanguagePairs) {
      urlConfig.setReferenceLanguageChannelPairs(
        selectedReferenceChannelLanguagePairs
      );
    }
    if (search) {
      urlConfig.setSearch(search);
    }
    if (selectedColumns) {
      urlConfig.setColumns(selectedColumns);
    }
    if (selectedChannelLanguagePairs) {
      urlConfig.setChannelLanguagePairs(selectedChannelLanguagePairs);
    }
    if (status) {
      urlConfig.setProductStatusFilter(status as ProductStatusFilter);
    }
    if (template) {
      urlConfig.setTemplate(template);
    }
    if (text_status) {
      urlConfig.setTextStatus(text_status as TextStatus);
    }
    if (translation_status) {
      urlConfig.setTranslationStatus(translation_status as TranslationStatus);
    }
    if (view) {
      urlConfig.setView(view);
    }
    if (viewset) {
      urlConfig.setViewset(viewset);
    }
    if (vocabulary) {
      urlConfig.setVocabulary(vocabulary);
    }
    const dateCategory = date_category || "created";
    const dateStart = date_start ? new Date(date_start) : null;
    const dateEnd = date_end ? new Date(date_end) : null;
    const groupChildren = group_children || false;
    const originalLanguage = (original_language as LanguageCode) || "en_US";
    const productStatusFilter =
      (status as ProductStatusFilter) || ProductStatusFilter.All;
    const searchQuery = search || "";
    const textStatus = (text_status as TextStatus) || TextStatus.All;
    const translationStatus =
      (translation_status as TranslationStatus) || TranslationStatus.All;
    const parentChildStatus =
      (parent_child as ParentChildStatus) || ParentChildStatus.All;
    const pageSize = pagination_size
      ? (pagination_size.toString() as PageSizeStatus)
      : PageSizeStatus.Five;
    setBrands(brands);
    setDateCategory(dateCategory);
    setDateStart(dateStart);
    setDateEnd(dateEnd);
    setGroupChildren(groupChildren);
    setLabel(label);
    setOriginalLanguage(originalLanguage);
    setPageSize(pageSize);
    setParentChildStatus(parentChildStatus);
    setProductStatusFilter(productStatusFilter);
    setSearchQuery(searchQuery);
    setSelectedChannelLanguagePairs(selectedChannelLanguagePairs);
    setSelectedColumns(selectedColumns);
    setSelectedReferenceChannelLanguagePairs(
      selectedReferenceChannelLanguagePairs
    );
    setTemplate(template);
    setTextStatus(textStatus);
    setTranslationStatus(translationStatus);
    setVocabulary(vocabulary);

    refreshList();
  };

  const clearSearchParams = (): void => {
    const defaultChannelPair: ChannelLanguagePair = {
      language_code: customer.config.tag_input_language,
      channel_id: customer.data.default_channel,
    };
    urlConfig.resetParams(defaultChannelPair);

    setSearchQuery("");
    setBrands([]);
    setDateCategory("created");
    setDateStart(null);
    setDateEnd(null);
    setGroupChildren(false);
    setSelectedColumns(defaultColumns);
    setSelectedChannelLanguagePairs([defaultChannelPair]);
    setOriginalLanguage("en_US");
    setProductStatusFilter(ProductStatusFilter.All);
    dispatch(setCustomQuery(null));
    setTextStatus(TextStatus.All);
    setTranslationStatus(TranslationStatus.All);
    setParentChildStatus(ParentChildStatus.All);
    setPageSize(PageSizeStatus.Five);
    setVocabulary(null);
    setTemplate("");
    setLabel(emptyStringItems);
  };

  // FIXME avoid unknown and use discriminated unions
  const onProductListAction = (
    action: ProductListAction,
    value?: unknown
  ): void => {
    if (!mounted.current) {
      return;
    }
    switch (action) {
      case ProductListAction.ChangeBrands:
        setBrands(value as string[]);
        urlConfig.setBrands(value as string[]);
        refreshList();
        break;
      case ProductListAction.ChangeColumns:
        setSelectedColumns(value as ColumnValue[]);
        break;
      case ProductListAction.ChangeChannelLanguagePair:
        setSelectedChannelLanguagePairs(value as ChannelLanguagePair[]);
        break;
      case ProductListAction.ChangeDateCategory:
        setDateCategory(value as DateCategory);
        urlConfig.setDateCategory(value as DateCategory);
        refreshList();
        break;
      case ProductListAction.ChangeDateStart:
        setDateStart(value as Date | null);
        urlConfig.setDateStart(value as Date | null);
        refreshList();
        break;
      case ProductListAction.ChangeDateEnd:
        setDateEnd(value as Date | null);
        urlConfig.setDateEnd(value as Date | null);
        refreshList();
        break;
      case ProductListAction.ChangeGroupChildren:
        setGroupChildren(value as boolean);
        break;
      case ProductListAction.ChangeLabel:
        setLabel(value as ItemsWithOperator);
        urlConfig.setLabel(value as ItemsWithOperator);
        refreshList();
        break;
      case ProductListAction.ChangeOriginalLanguage:
        setOriginalLanguage(value as LanguageCode);
        urlConfig.setOriginalTextLanguage(value as LanguageCode);
        refreshList();
        break;
      case ProductListAction.ChangePageSize:
        setPageSize(value as PageSizeStatus);
        urlConfig.setPageSize(value as PageSizeStatus);
        refreshList();
        break;
      case ProductListAction.ChangeParentChildStatus:
        setParentChildStatus(value as ParentChildStatus);
        urlConfig.setParentChildStatus(value as ParentChildStatus);
        refreshList();
        break;
      case ProductListAction.ChangeProductStatusFilter:
        setProductStatusFilter(value as ProductStatusFilter);
        urlConfig.setProductStatusFilter(value as ProductStatusFilter);
        refreshList();
        break;
      case ProductListAction.ChangeReferenceChannelLanguagePair:
        setSelectedReferenceChannelLanguagePairs(
          value as ChannelLanguagePair[]
        );
        break;
      case ProductListAction.ChangeSearchQuery:
        setSearchQuery(value as string);
        break;
      case ProductListAction.ChangeTemplate:
        setTemplate(value as string);
        urlConfig.setTemplate(value as string);
        refreshList();
        break;
      case ProductListAction.ChangeTextStatus:
        setTextStatus(value as TextStatus);
        urlConfig.setTextStatus(value as TextStatus);
        refreshList();
        break;
      case ProductListAction.ChangeTranslationStatus:
        setTranslationStatus(value as TranslationStatus);
        urlConfig.setTranslationStatus(value as TranslationStatus);
        refreshList();
        break;
      case ProductListAction.ChangeVocabulary:
        setVocabulary(value as FullTag | null);
        urlConfig.setVocabulary(value);
        refreshList();
        break;
      case ProductListAction.UpdateChannelLanguagePair:
        // eslint-disable-next-line no-case-declarations
        const {
          channelLanguagePairs,
          referenceChannelLanguagePairs,
        } = value as {
          channelLanguagePairs: ChannelLanguagePair[];
          referenceChannelLanguagePairs: ChannelLanguagePair[];
        };
        setSelectedChannelLanguagePairs(channelLanguagePairs);
        setSelectedReferenceChannelLanguagePairs(referenceChannelLanguagePairs);
        urlConfig.setChannelLanguagePairs(channelLanguagePairs);
        urlConfig.setReferenceLanguageChannelPairs(
          referenceChannelLanguagePairs
        );
        refreshList();
        break;
      case ProductListAction.UpdateColumns:
        urlConfig.setColumns(selectedColumns);
        urlConfig.setGroupChildren(groupChildren);
        refreshList();
        break;
      case ProductListAction.UpdateSearchBar:
        setSearchQuery(value as string);
        urlConfig.setSearch(value as string);
        refreshList();
        break;
      case ProductListAction.UseViewSet:
        setProductListParams(customer, value as ProductListParams);
        break;
      case ProductListAction.Reset:
        clearSearchParams();
        refreshList();
        break;
    }
  };

  const refreshList = (): void => {
    updateSelectionBucket(SelectionAction.CLEAR);
    taskDispatcher.clear();
    taskDispatcher2.clear();
    urlConfig.setPage(1);
    urlConfig.refresh();
    const columns = getColumns();
    setColumns(columns);
    setCurrentPage(1);
    setSelectedProducts(new Set());
    setAllSelected(false);
    search();
    dispatch(
      setCustomQuery(
        setCustomQueryContent({ customer, query: customQuery.query })
      )
    );
  };

  const onProductEditClick = (): void => {
    if (customer && selectedProducts.size) {
      const bucketName = urlConfig.getBucket();
      const firstProductId = selectedProducts.values().next().value;
      window.location.href = `/d/${customer.slug}/product/${firstProductId}?_bucket=${bucketName}`;
    } else return;
  };

  const visibleColumns = urlConfig.getVisibleColumns();
  const showOriginalTextLanguageSelector = columns.find(
    (column) => column.name === "original_text"
  );
  const showTextColumns = showTextColumnsFunc(visibleColumns);
  const showViewsetTabs = customer?.config.show_viewset_tabs;

  if (isCustomerLoading) {
    return (
      <div style={{ marginTop: "2em" }}>
        <Spinner size="medium" />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="upper">
        <h1>
          <i className="material-icons">description</i>
          <span>Documents</span>
        </h1>
      </div>
      <div className="lower">
        <div className="left">
          <div className="top">
            <div>
              <ProductListSearchbar
                onAction={onProductListAction}
                searchQuery={searchQuery}
                currentParameters={urlConfig.getViewParams()}
              />
              {showViewsetTabs && (
                <ViewSetsFilterContainer
                  currentParameters={urlConfig.getViewParams()}
                  onProductListAction={onProductListAction}
                  viewId={urlConfig.getView()}
                />
              )}

              <ProductFilterTopBar
                brands={brands}
                dateCategory={dateCategory}
                dateEnd={dateEnd}
                dateStart={dateStart}
                labelStatus={label}
                onAction={onProductListAction}
                parentChildStatus={parentChildStatus}
                productStatus={productStatusFilter}
                query={customQuery}
                textStatus={textStatus}
                templateStatus={template}
                textualAppName={textualAppName}
                translationStatus={translationStatus}
                vocabularyStatus={vocabulary}
              />

              <FavoriteViews
                viewId={urlConfig.getView()}
                onProductListAction={onProductListAction}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="list done">
              {showOriginalTextLanguageSelector && (
                <OriginalTextLanguageSelectorField
                  onAction={onProductListAction}
                  originalLanguage={originalLanguage}
                  languages={customer.data.languages}
                />
              )}
              <ProductListSelectionBanner
                bulkActionProps={{
                  onApproveSelected: (): void =>
                    onObjectSelectionAction(SelectionBannerAction.APPROVE),
                  onProductEditClick,
                  disabled: !selectedProducts.size,
                }}
                paginatorProps={{
                  currentPage,
                  currentParameters: urlConfig.getViewParams(),
                  errorSearchQuery,
                  onPageChange,
                  paginationSize: urlConfig.searchParams.pagination_size,
                }}
                columnSelectorMenuItemProps={{
                  groupChildren,
                  selectedColumns,
                  showTextColumns,
                  openChannelLanguageSelectorModal: setChannelLanguageSelectorOpen,
                }}
                rowSelectorMenuItemProps={{
                  pageSize,
                }}
                productListLoading={
                  !(customer && products !== null && products.length > 0)
                }
                onProductListAction={onProductListAction}
              />
              {customer && products !== null && products.length > 0 && (
                <ObjectTable
                  columns={columns}
                  objects={products}
                  context={context}
                  className={"product-list"}
                />
              )}
              {products?.length > 0 && (
                <ProductListSelectionBanner
                  paginatorProps={{
                    currentPage,
                    currentParameters: urlConfig.getViewParams(),
                    errorSearchQuery,
                    onPageChange,
                    paginationSize: urlConfig.searchParams.pagination_size,
                  }}
                  onProductListAction={onProductListAction}
                  asFooter
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showTextColumns && (
        <ChannelLanguageSelectorModal
          open={channelLanguageSelectorOpen}
          setOpen={setChannelLanguageSelectorOpen}
          onAction={onProductListAction}
          selectedPairs={selectedChannelLanguagePairs}
          selectedReferencePairs={selectedReferenceChannelLanguagePairs}
        />
      )}
    </div>
  );
};
