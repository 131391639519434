export class ProductImage {
  _data: any;

  constructor(data: any) {
    this._data = data;
  }

  get imageUrl(): string {
    return this._data.image_url;
  }
}
