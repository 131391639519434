import React, { useCallback, useRef } from "react";
import { Button, List, Popup } from "semantic-ui-react";
import { DocumentStructure } from "../types";
import { popupDelay } from "../../../customers/gpt/types";
import { useGetCustomerQuery } from "../../../api/customerApi";

type Props = {
  documentStructures: DocumentStructure[];
  selectDocumentStructure: (document: DocumentStructure) => void;
  selectedDocumentStructure: DocumentStructure | null;
  isCopying: boolean;
  setIsCopying: (isCopying: boolean) => void;
};

export const DocumentStructuresList: React.FC<Props> = ({
  documentStructures,
  selectDocumentStructure,
  selectedDocumentStructure,
  isCopying,
  setIsCopying,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();

  const documentStructuresHasConnectedChannel = useCallback(
    (id: number) => {
      if (!customer) return false;
      const channel = customer.channels.find(
        (channel) => channel.document_structure_id === id
      );
      return !!channel;
    },
    [customer]
  );

  const clickedCopy = useRef(false);
  return (
    <List divided relaxed="very" selection>
      {documentStructures.map((structure) => (
        <List.Item
          data-testid={`document-structure-list-item-${structure.name}`}
          key={structure.id}
          active={selectedDocumentStructure?.id === structure.id}
          onClick={(): void => {
            if (isCopying && !clickedCopy.current) {
              setIsCopying(false);
            }
            clickedCopy.current = false;
            selectDocumentStructure(structure);
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <List.Content style={{ paddingLeft: "0.5em" }}>
            <List.Header as="a" className="tw-no-underline">
              {structure.name}
            </List.Header>
          </List.Content>
          <List.Content
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {!documentStructuresHasConnectedChannel(structure.id) &&
              !isLoading && (
                <Popup
                  wide="very"
                  basic
                  position="top left"
                  size="tiny"
                  content={`This Structure has no connected Channel and will be ignored.`}
                  mouseEnterDelay={popupDelay}
                  trigger={
                    <List.Icon
                      data-testid={`document-structure-list-item-${structure.name}-no-connected-channel`}
                      name="warning sign"
                      color="yellow"
                      style={{ marginBottom: "2px" }}
                    />
                  }
                />
              )}
            {structure?.locked && (
              <Popup
                size="small"
                content="This Document Structure is reserved by the system and will have a few settings disabled."
                wide
                trigger={
                  <List.Icon name="lock" style={{ marginBottom: "2px" }} />
                }
              />
            )}
            <Popup
              size="tiny"
              content="Copy document"
              position="bottom right"
              mouseEnterDelay={popupDelay}
              trigger={
                <Button
                  data-testid={`document-structure-list-item-${structure.name}-copy`}
                  color="red"
                  basic
                  compact
                  size="mini"
                  icon="copy"
                  onClick={(): void => {
                    clickedCopy.current = true;
                    setIsCopying(true);
                  }}
                />
              }
            />
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};
