import api from "./api";
import { IndexedObject } from "../products/channel-tab/compareConfigs";
import { AxiosResponse } from "axios";
export type ProductChannelConfig = {
  product_id: string;
  channel_id: number;
  config_overwrite: IndexedObject;
};

type UpdateChannelConfigResponse = {
  data: {
    success: number;
    channel_config: IndexedObject;
  };
};
export async function updateChannelConfig(
  token: string,
  channelId: string,
  newConfig: IndexedObject
): Promise<UpdateChannelConfigResponse> {
  const response = await api.post(
    "/api/_internal/customer/channel/change-config",
    { channel_id: channelId, new_config: newConfig },
    {
      headers: { token: token },
    }
  );

  return response;
}

type GetProductChannelConfigResponse = {
  data: {
    config_overwrite: ProductChannelConfig[];
    product_channel_ids: number[];
  };
};
const productChannelConfigApiUrl = "/api/_internal/product-channel-config";
export async function getProductChannelConfig(
  token: string,
  productId: string,
  channelsId: number[]
): Promise<GetProductChannelConfigResponse> {
  const urlParams = `product_id=${productId}&channels_id=${channelsId}`;

  const response = await api.get(
    `${productChannelConfigApiUrl}/get-all?${urlParams}`,
    {
      headers: { token: token },
    }
  );

  return response;
}
type CreateProductChannelConfigResponse = {
  data: ProductChannelConfig;
};
export async function createProductChannelConfig(
  token: string,
  productId: string,
  channelId: number,
  config: IndexedObject
): Promise<CreateProductChannelConfigResponse> {
  const response = await api.post(
    `${productChannelConfigApiUrl}/create`,
    { product_id: productId, channel_id: channelId, config },
    {
      headers: { token: token },
    }
  );

  return response;
}

export async function deleteProductChannelConfig(
  token: string,
  productId: string,
  channelId: number
): Promise<AxiosResponse> {
  const response = await api.post(
    `${productChannelConfigApiUrl}/delete`,
    { product_id: productId, channel_id: channelId },
    {
      headers: { token: token },
    }
  );

  return response;
}
