import React from "react";

type Props = {
  input: string;
  updateInput: (value: string) => void;
  placeholder: string;
};
export const AdvancedEditor: React.FC<Props> = ({
  input,
  updateInput,
  placeholder,
}) => {
  return (
    <textarea
      className="tw-form-textarea tw-block tw-w-96 tw-rounded-md tw-border-0 tw-p-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset"
      onChange={({ target }): void => {
        updateInput(target.value);
      }}
      value={input}
      placeholder={placeholder}
    />
  );
};
