import React, { useState } from "react";
import { Button, Dimmer, Icon, Loader, Popup } from "semantic-ui-react";
import ManageGrid from "../../../components/manage/ManageGrid";
import { popupDelay } from "../types";
import { Text } from "../../../components/Text";
import { useGetOpenAIAssistants } from "../../../products/copy-assistant/customHooks";
import { ManageOpenAIAssistantsList } from "./ManageOpenAIAssistantsList";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import { OpenAIAssistant } from "../../../products/copy-assistant/types";
import { useSelector } from "react-redux";
import {
  createOpenAIAssistant,
  deleteOpenAIAssistant,
  updateOpenAIAssistant,
} from "../../../api/gptApi";
import { RootState, store } from "../../../utils/store";
import { ManageOpenAIAssistantForm } from "./ManageOpenAIAssistantsForm";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";

const CREATE_ASSISTANT_ID = 0;

export const ManageOpenAIAssistants: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: assistants, reFetch, isFetching } = useGetOpenAIAssistants();
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const {
    setParam,
    removeParam,
    urlState: { assistant_id },
  } = useUrlState<{ assistant_id: number }>();

  const selectAssistant = (assistantId: number): void => {
    setParam("assistant_id", assistantId);
    setShowForm(true);
  };

  const createNewAssistant = (): void => {
    removeParam("assistant_id");
    setShowForm(true);
  };

  const viewingAssistant = !!assistant_id;

  const selectedAssistant: OpenAIAssistant = assistants?.find(
    (assistant) => assistant.id === assistant_id
  ) || {
    id: CREATE_ASSISTANT_ID,
    name: "",
    instructions: "",
  };

  const showDjangoToast = (
    message: string,
    appearance: NotificationAppearance
  ): void => {
    store.dispatch(
      setDjangoToastOpen({
        content: message,
        appearance: appearance,
      })
    );
  };

  const createAssistant = async (
    assistant: Omit<OpenAIAssistant, "id">,
    file?: File
  ): Promise<void> => {
    setIsLoading(true);
    const createdAssistant = await createOpenAIAssistant(
      token,
      assistant.name,
      assistant.instructions,
      file
    ).finally(async () => {
      await reFetch();
      setIsLoading(false);
    });
    showDjangoToast(
      `Created assistant: ${createdAssistant.name}`,
      NotificationAppearance.SUCCESS
    );
    selectAssistant(createdAssistant.id);
  };

  const updateAssistant = async (
    assistant: OpenAIAssistant,
    file?: File
  ): Promise<void> => {
    setIsLoading(true);
    const updatedAssistant = await updateOpenAIAssistant(
      token,
      assistant.id,
      assistant.name,
      assistant.instructions,
      file
    ).finally(async () => {
      await reFetch();
      setIsLoading(false);
    });
    showDjangoToast(
      `Updated assistant: ${updatedAssistant.name}`,
      NotificationAppearance.SUCCESS
    );
    selectAssistant(updatedAssistant.id);
  };

  const deleteAssistant = async (assistantId: number): Promise<void> => {
    setIsLoading(true);
    await deleteOpenAIAssistant(token, assistantId).finally(async () => {
      await reFetch();
      setIsLoading(false);
    });
    showDjangoToast(
      `Deleted assistant: ${selectedAssistant.name}`,
      NotificationAppearance.WARNING
    );
    removeParam("assistant_id");
    setShowForm(false);
  };
  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                content="An Assistant is similar to a GPT Model, but includes more context about how text should be generated."
                wide="very"
                size="tiny"
                mouseEnterDelay={popupDelay}
                trigger={
                  <span>
                    <Text as="h4" compact inline>
                      Assistants
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              />
            }
            right={
              <Popup
                content="Create a new Assistant."
                wide="very"
                size="tiny"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Button
                    data-testid="create-openai-assistant-button"
                    size="tiny"
                    color="red"
                    icon="plus"
                    compact
                    basic
                    onClick={(): void => createNewAssistant()}
                  />
                }
              />
            }
          />
          <ManageGrid.Header.RightColumn>
            {showForm && (
              <Text as="h4" compact inline>
                {selectedAssistant.name
                  ? `Viewing Assistant: ${selectedAssistant.name}`
                  : "New Assistant"}
              </Text>
            )}
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <ManageOpenAIAssistantsList
              assistants={assistants}
              selectedAssistantId={assistant_id}
              selectAssistant={selectAssistant}
            />
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {showForm ? (
              <ManageOpenAIAssistantForm
                selectedAssistant={selectedAssistant}
                createAssistant={createAssistant}
                updateAssistant={updateAssistant}
                deleteAssistant={deleteAssistant}
                viewingAssistant={viewingAssistant}
              />
            ) : (
              <ManageGrid.Body.EmptyForm>
                {!!assistants?.length && (
                  <>
                    <Text as="h2" compact>
                      Select an Assistant from the list
                    </Text>
                    <Text as="h3" color="grey" compact>
                      Or
                    </Text>
                  </>
                )}
                <Button
                  content="Create new Assistant"
                  icon="plus"
                  color="red"
                  size="large"
                  onClick={(): void => createNewAssistant()}
                />
              </ManageGrid.Body.EmptyForm>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>

      <Dimmer active={isFetching || isLoading} inverted page>
        <Loader data-testid="assistant-page-loader" />
      </Dimmer>
    </div>
  );
};
