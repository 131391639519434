import React from "react";
import { Dropdown } from "semantic-ui-react";

import VocabularyLookupNew, {
  VocabularyLookupNewComp,
} from "../../../vocabulary/VocabularyLookupNew";
import { FullTag, formatTag } from "../../../utils/tagutils";
import { ProductListAction } from "../ProductList";
import { generateId } from "../../../utils/uuidUtils";
import { StyledDivider } from "./ProductFilterAddFilter";
import {
  FlexCenter,
  FontSizeParagraph,
  FontSizeHoverEffectIcon,
} from "./ProductFilterChoice";
import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";

type Props = {
  filterKey: string;
  toggleFilter: (
    filterKey: string,
    filterValue: boolean,
    action: ProductListAction,
    value: string | string[] | {}
  ) => void;
  initialData: FullTag | null;
  onAction: (action: ProductListAction, value: FullTag | {}) => void;
  textualAppName: string;
};
export const ProductFilterVocabulary: React.FC<Props> = ({
  filterKey,
  toggleFilter,
  initialData,
  onAction,
  textualAppName,
}) => {
  const displayName = formatTag(initialData, {
    includeSubTagNames: true,
  });
  return (
    <FlexCenter>
      <StyledDivider className={"divider"} />
      <FontSizeParagraph>Vocabulary:</FontSizeParagraph>
      <StyledDropdownBase
        className={"tiny"}
        basic
        button
        data-testid={"search-dropdown-vocabulary"}
        defaultOpen={!initialData}
        trigger={<span>{displayName ?? "None"}</span>}
        closeOnChange={false}
      >
        <Dropdown.Menu
          onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
          style={{ minWidth: "300px", overflow: "inherit" }}
        >
          <VocabularyLookupNewComp
            alwaysTranslate={false}
            componentId={generateId()}
            disableAutoAdd={false}
            focusInput
            includeSeo={false}
            isMultiple={false}
            language={"en_US"}
            lexiconEditorDirection={"ltr"}
            limit={10}
            onDataChanged={(
              e: VocabularyLookupNew,
              value: FullTag | {}
            ): void => {
              onAction(
                ProductListAction.ChangeVocabulary,
                value as FullTag | null
              );
            }}
            onLoaded={(): void => {
              return;
            }}
            placeholder={"Type to search"}
            selectedTags={[initialData]}
            selectedValue={displayName || ""}
            showMachineTranslate={false}
            showHypernym={false}
            showTagTypeHints={false}
            textualAppName={textualAppName}
            useLexiconEditor={false}
            vocabularyRequestAllowed={false}
            vocabularyRequestProductDescription={""}
            vocabularyRequestProductId={""}
          />
        </Dropdown.Menu>
      </StyledDropdownBase>
      <FontSizeHoverEffectIcon
        className={"times circle link icon"}
        data-testid={"close-btn"}
        onClick={(): void => {
          toggleFilter(
            filterKey,
            false,
            ProductListAction.ChangeVocabulary,
            ""
          );
        }}
        size={"small"}
      />
    </FlexCenter>
  );
};
