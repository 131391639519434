import React from "react";
import { ProductText } from "./ProductText";
import { Spinner } from "../utils/Spinner";
import { ProductTextHTMLRenderer } from "./ProductTextHTMLRenderer";
import { ProductTextErrorViewer } from "./ProductTextErrorViewer";
import {
  getProductTextStatusIcon,
  missingTranslationIconWithTooltip,
  notMarkedForPublishIconWithTooltip,
} from "./ProductTextStatusIconHelper";
import { ProductTextCommentViewer } from "./ProductTextCommentViewer";

type Props = {
  markedForPublish: boolean;
  productText: ProductText;
};

export const ProductTextReferenceViewer: React.FC<Props> = ({
  markedForPublish,
  productText,
}) => {
  if (!markedForPublish) {
    return (
      <div data-testid={"not-marked-for-publish-icon-with-tooltip"}>
        {notMarkedForPublishIconWithTooltip()}
      </div>
    );
  }
  if (!productText) {
    return <Spinner data-testid={"spinner"} />;
  }
  if (productText.error) {
    return (
      <>
        {missingTranslationIconWithTooltip()}
        <ProductTextErrorViewer error={productText.error} />
      </>
    );
  }
  return (
    <>
      {getProductTextStatusIcon(productText)}
      {productText.missingTranslation || productText.failed
        ? missingTranslationIconWithTooltip()
        : null}
      <ProductTextHTMLRenderer
        isEdited={false}
        productText={productText}
        data-testid={"product-text-html-renderer"}
      />
      <ProductTextCommentViewer comment={productText.comment} />
    </>
  );
};
