import { Customer } from "../customers/Customer";

function areWeTestingWithJest(): boolean {
  return process.env.JEST_WORKER_ID !== undefined;
}

export function getFeatureFlag(
  customer: Customer | null,
  flagName: string,
  warnFunc: (message: string) => void = undefined
): boolean {
  if (warnFunc == undefined) {
    warnFunc = console.warn;
  }

  const customerData = customer?.data;
  if (!customerData) {
    if (!areWeTestingWithJest) {
      warnFunc(
        "Missing customer data - application can't evaluate feature flag"
      );
    }
    return false;
  }

  const features = customerData?.features;
  if (!features) {
    if (!areWeTestingWithJest) {
      warnFunc(
        "Customer data doesn't have a list of features - application can't evaluate feature flag"
      );
    }
    return false;
  }

  const featureFlag = features[flagName];
  if (featureFlag === undefined) {
    warnFunc(`Feature flag ${flagName} doesn't exist`);
    return false;
  }
  return featureFlag;
}
