import { ViewId, ViewSet, ViewSetId } from "./ViewSetsFilterContainer";
import React from "react";
import Styled from "styled-components";
import { ViewSetsFilterSaveClearButtons } from "./ViewSetsFilterSaveClearButtons";

const FlexDiv = Styled.div`
  display: flex;
`;

export const HorizontalDivider = Styled.div`
  position: absolute;
  height: 1px;
  top: 50%;
  left: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.2);
`;

const RelativeDiv = Styled.div`
  position: relative;
  width: 100px;
`;

type Props = {
  activeViewId?: number;
  haveActiveViewFiltersChanged?: boolean;
  onActiveViewChange?: () => void;
  onViewUpdate?: (viewId: ViewId) => Promise<void>;
  onViewSetCreate?: (displayName: string) => void;
  onViewCreate?: (displayName: string, viewSetId: ViewSetId) => void;
  viewSets?: ViewSet[];
};

export const ViewSetsFilterButtonsContainer: React.FC<Props> = ({
  activeViewId,
  haveActiveViewFiltersChanged,
  onActiveViewChange,
  onViewUpdate,
  onViewSetCreate,
  onViewCreate,
  viewSets,
}) => (
  <FlexDiv data-testid={"view-sets-filter-buttons-container"}>
    <RelativeDiv>
      <HorizontalDivider />
    </RelativeDiv>
    <ViewSetsFilterSaveClearButtons
      activeViewId={activeViewId}
      haveActiveViewFiltersChanged={haveActiveViewFiltersChanged}
      onActiveViewChange={onActiveViewChange}
      onViewUpdate={onViewUpdate}
      onViewSetCreate={onViewSetCreate}
      onViewCreate={onViewCreate}
      viewSets={viewSets}
    />
  </FlexDiv>
);
