import React, { useState, useLayoutEffect, useRef } from "react";
import { useHandleClickOutside } from "../../../utils/reactCustomHooks";
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
} from "@floating-ui/react-dom";

type ContextPosition = {
  top: number;
  left: number;
};
type Props = {
  isOpen: boolean;
  position: ContextPosition;
  onSelectAll?: () => void;
  onCopy?: () => void;
  onCut?: () => void;
  onPaste?: () => void;
  getRef?: (ref: HTMLDivElement) => void;
};
/**
 * ContextMenu Component
 *
 * This component renders a customizable context menu that can be positioned
 * anywhere on the screen. It supports native copy, cut, and paste operations,
 * as well as custom child components.
 *
 * @component
 * @param isOpen - Determines whether the context menu is open
 * @param position - The position of the context menu on the screen
 * @param onSelectAll - Optional callback function for select all operation
 * @param onCopy - Optional callback function for copy operation
 * @param onCut - Optional callback function for cut operation
 * @param onPaste - Optional callback function for paste operation
 * @param children - Optional child components to render in the menu
 *
 * @returns The rendered ContextMenu component or null if not visible
 */

export const ContextMenu: React.FC<Props> = ({
  isOpen,
  position,
  onCopy,
  onCut,
  onPaste,
  onSelectAll,
  getRef,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const showNativeButtons = !!onCopy || !!onCut || !!onPaste || !!onSelectAll;
  const menuRef = useRef<HTMLDivElement>(null);

  const { x, y, refs, update } = useFloating({
    placement: "bottom-start",
    middleware: [
      offset(5),
      flip({
        fallbackPlacements: ["top-start", "left-start", "right-start"],
      }),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  useLayoutEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      const menuOffset = 5;
      const leftWithOffset = position.left + menuOffset;
      const topWithOffset = position.top + menuOffset;
      // Create a fake reference element to get the correct position.
      // To the right and down of the actual position.
      refs.setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: leftWithOffset,
            y: topWithOffset,
            top: topWithOffset,
            right: leftWithOffset,
            bottom: topWithOffset,
            left: leftWithOffset,
          };
        },
      });
      requestAnimationFrame(() => {
        if (menuRef.current) {
          menuRef.current.style.opacity = "1";
          menuRef.current.style.transform = "scale(1)";
        }
        update();
      });
    } else {
      if (menuRef.current) {
        menuRef.current.style.opacity = "0";
        menuRef.current.style.transform = "scale(0.95)";
      }
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, position, refs, update]);

  if (!isVisible) return null;

  return (
    <div
      ref={(node): void => {
        getRef?.(node);
        menuRef.current = node;
        refs.setFloating(node);
      }}
      className={`tw-absolute tw-z-[999] tw-flex tw-min-w-max tw-scale-95 tw-flex-col tw-gap-1 tw-rounded-md tw-bg-white  tw-text-sm tw-text-gray-700 tw-opacity-0 tw-outline tw-outline-1 tw-outline-gray-200 tw-drop-shadow-md tw-transition-[opacity,transform] tw-duration-300 ${
        showNativeButtons ? "tw-pt-1" : "tw-py-1"
      }`}
      style={{
        top: y ?? 0,
        left: x ?? 0,
      }}
    >
      {children}
      {showNativeButtons && (
        <ul className="txu-simple-menu tw-bg-gray-50 tw-px-2 tw-py-1 tw-text-gray-600 tw-shadow-inner">
          {onSelectAll && (
            <li className="txu-simple-menu-item" onClick={onSelectAll}>
              Select All
            </li>
          )}
          {onCopy && (
            <li className="txu-simple-menu-item" onClick={onCopy}>
              Copy
            </li>
          )}
          {onCut && (
            <li className="txu-simple-menu-item" onClick={onCut}>
              Cut
            </li>
          )}
          {onPaste && (
            <li className="txu-simple-menu-item" onClick={onPaste}>
              Paste
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

ContextMenu.displayName = "ContextMenu";

/**
 * A custom hook for managing a context menu.
 *
 * @param focusRef - Optional ref to the element that should receive focus when the menu closes.
 * @param excludeCloseRefs - Optional array of refs to elements that should not close the menu when clicked.
 *
 * @returns An object containing:
 *   - `isOpen`: boolean indicating if the menu is open
 *   - `setIsOpen`: function to set the open state of the menu
 *   - `position`: object with top and left coordinates of the menu
 *   - `setPosition`: function to set the position of the menu
 *   - `onContextMenu`: event handler for the context menu event
 *   - `ContextMenu`: React component for rendering the context menu
 *   - `freeze`: function to freeze the menu (prevent opening/closing)
 *   - `unFreeze`: function to unfreeze the menu
 *   - `frozen`: boolean indicating if the menu is frozen
 */
export const useContextMenu = (
  focusRef?: React.RefObject<HTMLElement>,
  ...excludeCloseRefs: React.RefObject<HTMLElement>[]
): {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  position: ContextPosition;
  setPosition: (position: ContextPosition) => void;
  onContextMenu: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  ContextMenu: React.FC<Omit<Props, "isOpen" | "position" | "getRef">>;
  freeze: () => void;
  unFreeze: () => void;
  frozen: boolean;
} => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isFrozen, setIsFrozen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<ContextPosition>({
    top: 0,
    left: 0,
  });

  const onContextMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    if (!isFrozen) {
      const left = event.clientX;
      const top = event.clientY;
      setPosition({ left, top });
      setIsOpen(true);
    }
    event.preventDefault();
  };

  const handleSetIsOpen = (open: boolean): void => {
    if (isFrozen) {
      return;
    }
    setIsOpen(open);
    if (!open && focusRef?.current) {
      focusRef.current.focus?.();
    }
  };

  useHandleClickOutside(
    () => handleSetIsOpen(false),
    [isFrozen],
    menuRef,
    focusRef,
    ...excludeCloseRefs
  );

  ContextMenu.defaultProps = {
    isOpen,
    position,
    getRef: (node: HTMLDivElement): void => {
      menuRef.current = node;
    },
  };
  return {
    isOpen,
    setIsOpen: handleSetIsOpen,
    position,
    setPosition,
    onContextMenu,
    ContextMenu,
    freeze: () => setIsFrozen(true),
    unFreeze: () => setIsFrozen(false),
    frozen: isFrozen,
  };
};
