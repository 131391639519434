import React from "react";
import { HTML } from "../../components/HTML";
import {
  FormRenderInstruction,
  SectionAction,
  TemplateFieldInfoBoolean,
  TemplateFieldInfoNumber,
  TemplateFieldInfoString,
  TemplateFieldInfoTag,
  TemplateFieldInfoText,
  TemplateFieldType,
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import { InputFormField } from "./InputFormField";
import { TextAreaFormField } from "./TextAreaFormField";
import { TagFormField } from "./TagFormField";
import TagTreeButton, {
  TagTreeButtonComp,
} from "../../vocabulary/TagTreeButton";
import { AdvancedTemplateContainer } from "../../vocabulary/AdvancedTemplateContainer";
import { TreeData } from "../../utils/tagutils";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import { capitalize } from "../../utils/stringUtils";
import { useGetCustomerQuery } from "../../api/customerApi";

function placeholder(input: string): string {
  // Underscore as spaces
  return capitalize(input).replace("_", " ");
}

type Props = {
  details: ProductDetailInfo;
  form: TemplateFormInfo;
  instruction: FormRenderInstruction;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  textualAppName: string;
};

export const TemplateFormField: React.FC<Props> = ({
  form,
  instruction,
  onSectionItemAction,
  textualAppName,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }
  let inner = null;
  if (instruction.type == "data") {
    return <HTML text={instruction.value} />;
  }
  const { field } = instruction;
  switch (field.type) {
    case TemplateFieldType.BOOLEAN:
      inner = (
        <InputFormField<TemplateFieldInfoBoolean>
          field={field as TemplateFieldInfoBoolean}
          type={"checkbox"}
          form={form}
          onSectionItemAction={onSectionItemAction}
          placeholder={placeholder(field.key)}
        />
      );
      break;
    // FIXME: BULLETLIST
    case TemplateFieldType.INTEGER:
    case TemplateFieldType.FLOAT:
      inner = (
        <InputFormField<TemplateFieldInfoNumber>
          field={field as TemplateFieldInfoNumber}
          type={"number"}
          form={form}
          onSectionItemAction={onSectionItemAction}
          placeholder={placeholder(field.key)}
        />
      );
      break;
    case TemplateFieldType.STRING:
      inner = (
        <InputFormField<TemplateFieldInfoString>
          field={field as TemplateFieldInfoString}
          type={"text"}
          form={form}
          onSectionItemAction={onSectionItemAction}
          placeholder={placeholder(field.key)}
        />
      );
      break;
    case TemplateFieldType.FREE_TEXT:
    case TemplateFieldType.TEXT:
      inner = (
        <TextAreaFormField
          field={field as TemplateFieldInfoText}
          form={form}
          onSectionItemAction={onSectionItemAction}
          placeholder={placeholder(field.key)}
        />
      );
      break;
    case TemplateFieldType.TAG:
      inner = (
        <TagFormField
          field={field as TemplateFieldInfoTag}
          form={form}
          onSectionItemAction={onSectionItemAction}
          placeholder={placeholder(field.key)}
          textualAppName={textualAppName}
        />
      );
      break;
    case TemplateFieldType.TAG_TREE:
      inner = (
        <div className={"tag-tree-button-wrapper"}>
          <TagTreeButtonComp
            disableDrag
            textualAppName={textualAppName}
            initialData={field.value}
            limit={20}
            language={customer.config.tag_input_language}
            includeSeo={customer.config.show_seo_scores}
            vocabularyRequestAllowed={customer.config.show_request_words}
            mappingMode={false}
            useLexiconEditor={customer.config.use_lexicon_editor}
            showHypernym={customer.config.request_word_show_hypernym}
            alwaysTranslate={customer.config.always_translate}
            showMachineTranslate={
              customer.config.show_machine_translate_in_request_word
            }
            onDataChanged={(component: TagTreeButton, treeData: any): void => {
              const value = treeData.treeData;
              onSectionItemAction({
                action: SectionAction.TEMPLATE_FIELD_SET,
                tag_id: form.id,
                field: field.key,
                tag_value: value,
              });
            }}
          />
        </div>
      );
      break;
    case TemplateFieldType.ADVANCED:
      inner = (
        <AdvancedTemplateContainer
          textualAppName={textualAppName}
          initialData={[field.value] || []}
          limit={20}
          onDataChanged={(
            component: typeof AdvancedTemplateContainer,
            treeData: TreeData[]
          ): void => {
            onSectionItemAction({
              action: SectionAction.TEMPLATE_FIELD_SET,
              tag_id: form.id,
              field: field.key,
              tag_value: treeData,
            });
          }}
        />
      );
      break;
    default:
      throw new Error(`Not implemented: ${field.type.toString()}`);
  }
  const classNames = ["template-field", `template-${field.key}`, "default"];
  return <span className={classNames.join(" ")}>{inner}</span>;
};
