export const BASE_SELECTOR = "[txu-handler]";

const ANCHOR_TAG_SELECTOR = `a${BASE_SELECTOR}`;
export const setLoading = (element: Element): void => {
  element.classList.add("txu-loading");
};

export const loadAnchorTagHandlers = (element: Element | Document): void => {
  const anchors = element.querySelectorAll(ANCHOR_TAG_SELECTOR);
  anchors.forEach((anchor) => {
    anchor.addEventListener("click", () => {
      setLoading(anchor);
    });
  });
};
