import React, { useEffect, useRef, useState } from "react";
import { ConfirmationModal } from "../../ui/ConfirmationModal";
import { useGetCustomerQuery } from "../../api/customerApi";
import { removeChannelLanguageSettings } from "../../api/manageApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { Button } from "semantic-ui-react";
import { ChannelId } from "../../customers/Customer";
import { Spinner } from "../../utils/Spinner";

type Props = {
  channelId: ChannelId;
};
export const ChannelRemoveButton: React.FC<Props> = ({ channelId }) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [open, setOpen] = useState(false);
  const [channelName, setChannelName] = useState<string>(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (channelId && customer) {
      const channel = customer.getChannelById(Number(channelId));
      if (mounted.current) {
        setChannelName(channel.display_name);
      }
    }
    return (): void => {
      mounted.current = false;
    };
  }, [channelId, customer]);

  if (isLoading) {
    return <Spinner />;
  }

  const onConfirm = (confirm: boolean): void => {
    if (confirm) {
      removeChannelLanguageSettings({
        token,
        channelId,
      }).then(() => {
        window.location.reload();
      });
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        basic
        color={"red"}
        content={"Remove"}
        data-testid={"channel-remove-button"}
        disabled={customer.default_channel === channelId}
        onClick={(): void => setOpen(!open)}
        size={"small"}
      />
      <ConfirmationModal
        open={open}
        headerDescription={`Remove channel ${channelName}?`}
        onConfirm={onConfirm}
      />
    </>
  );
};
