import React from "react";
import {
  SectionAction,
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import { TemplateForm } from "./TemplateForm";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";
import { BalloonDiv } from "../../components/BalloonDiv";
import { openInOverlay } from "../../utils/GlobalFunctions";
import { useGetCustomerQuery } from "../../api/customerApi";

type Props = {
  details: ProductDetailInfo | null;
  form: TemplateFormInfo;
  dragHandleProps: DraggableProvidedDragHandleProps;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  textualAppName: string;
};

export const TemplateRow: React.FC<Props> = ({
  details,
  form,
  dragHandleProps,
  onSectionItemAction,
  textualAppName,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <>
      <div className={"template-hover"}>
        <p className={"template-name"}>{form.display_name}</p>
        <div className={"delete-template-form actions"}>
          <BalloonDiv
            as={"a"}
            data-testid={`open-in-overlay-${form.id}`}
            className={"details-button"}
            value={details.microcopies["Details"]}
            pos={"down"}
            onClick={(): void => {
              openInOverlay(`/d/${customer.slug}/template/${form.template_id}`);
            }}
          >
            <i className={"edit icon"} />
          </BalloonDiv>
          <BalloonDiv
            as={"button"}
            data-testid={`remove-button-${form.id}`}
            type={"submit"}
            className={"remove-button"}
            value={details.microcopies["Remove"] || "Remove"}
            pos={"down"}
            onClick={(): void => {
              onSectionItemAction({
                action: SectionAction.ITEM_REMOVE,
                tag_id: form.id,
              });
            }}
          >
            <i className={"close icon"} />
          </BalloonDiv>
        </div>
      </div>
      <div {...dragHandleProps}>
        <i className={"material-icons"}>drag_indicator</i>
      </div>
      <div className={"partial"}>
        <div className={"edit-template-form"}>
          <div className={"container"}>
            <TemplateForm
              form={form}
              details={details}
              onSectionItemAction={onSectionItemAction}
              textualAppName={textualAppName}
            />
          </div>
        </div>
      </div>
    </>
  );
};
