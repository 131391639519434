import {
  ChannelLanguagePair,
  ChannelLanguagePairData,
  ProductId,
} from "../product";
import api from "../../api/api";
import {
  OverwriteHeader,
  ProductProcessResponse,
  PublishProductRequest,
  UserActionContext,
} from "./actionTypes";
import { RegenerateConfig } from "../../api/action";

export async function publishProductTexts(
  token: string,
  userActionContext: UserActionContext,
  productId: ProductId,
  overwriteHeader: OverwriteHeader,
  channelLanguagePairs: ChannelLanguagePairData[] | null
): Promise<ProductProcessResponse> {
  const data: PublishProductRequest = {
    user_action_context: userActionContext,
  };

  if (overwriteHeader === OverwriteHeader.DO_OVERWRITE) {
    data.overwrite = 1;
  }
  if (channelLanguagePairs && channelLanguagePairs.length) {
    data.channel_language_pairs = channelLanguagePairs;
  }

  const response = await api.post(
    `/api/_internal/product/${productId}/publish/`,
    data,
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export async function generateProductTexts(
  token: string,
  userActionContext: UserActionContext,
  productId: ProductId,
  regenerateConfig?: RegenerateConfig,
  channelLanguagePairs?: ChannelLanguagePair[]
): Promise<ProductProcessResponse> {
  const config = regenerateConfig
    ? {
        regenerate: regenerateConfig.regenerate,
        regenerate_approved_texts: regenerateConfig.regenerateApprovedTexts,
        regenerate_published_texts: regenerateConfig.regeneratePublishedTexts,
        regenerate_edited_texts: regenerateConfig.regenerateEditedTexts,
      }
    : null;
  const requestData = {
    user_action_context: userActionContext,
    regenerate_config: config,
    channel_language_pairs: channelLanguagePairs,
  };

  const { data } = await api.post(
    `/api/_internal/product/${productId}/generate/`,
    requestData,
    {
      headers: { token: token },
    }
  );
  return data;
}
