import { LanguageCode } from "../customerlanguages";

export const popupDelay = 300;

export enum ConditionOperator {
  OR = "Or",
  AND = "And",
}

export enum ReorderType {
  UP = "up",
  DOWN = "down",
}

export enum PromptSelectionStringMatchType {
  CONTAINS = "contains",
  EQUALS = "equals",
  EXISTS = "exists",
}

export type PromptSelectionCondition = {
  key: string;
  value: string;
  string_match_type: PromptSelectionStringMatchType;
};

export type PromptSelectionRule = {
  id: number;
  name: string;
  prompt_group_id: number | null;
  prompt_id: number | null;
  condition_operator: ConditionOperator;
  conditions: PromptSelectionCondition[];
};

export type PromptSelectionConditionWithId = PromptSelectionCondition & {
  id: string;
};

export type PromptSuggestion = {
  id: number;
  description: string;
  placeholder?: string;
  text_snippet: string;
  language: LanguageCode;
};
