import React from "react";
import { ProductText, ProductTextRef } from "../producttext/ProductText";
import { Product } from "./product";
import { Channel } from "./product-list/ChannelLanguageSelectorMatrix";
import styled from "styled-components";
import { ProductTextStatusAndFlag } from "./ProductTextStatusAndFlag";
import { Language } from "../customers/customerlanguages";
import { useGetCustomerQuery } from "../api/customerApi";
import { Spinner } from "../utils/Spinner";

const Header = styled.div`
  & {
    margin-bottom: 15px;
  }
`;

const Title = styled.div`
  & {
    margin-bottom: 5px;
  }
`;

const Flags = styled.div`
  & {
    display: flex;
    flex-wrap: wrap;
  }
`;

type Props = {
  channel: Channel;
  onFlagToggle?: (open: boolean) => void;
  product: Product;
  productTexts: ProductText[];
};

export const ProductTextsChannelRow: React.FC<Props> = ({
  channel,
  onFlagToggle,
  product,
  productTexts,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (!product || isLoading) {
    return <Spinner />;
  }
  const relatedProductText = (
    channelId: number,
    languageCode: string
  ): ProductText | null => {
    return productTexts.find((text) => {
      return (
        text.languageCode == languageCode && text.customerChannelId == channelId
      );
    });
  };

  const getMarkedForPublish = (language: Language): boolean => {
    const productTextRef = new ProductTextRef(
      product.id,
      channel.id,
      language.code
    );
    return productTextRef.markedForPublish(
      customer,
      product.channel_language_pairs
    );
  };

  return (
    <Header key={channel.id} data-testid={"product-texts-channel-row"}>
      <Title>{channel.display_name}</Title>
      <Flags>
        {customer.languages.map((language) => (
          <ProductTextStatusAndFlag
            key={language.code}
            onFlagToggle={onFlagToggle}
            markedForPublish={getMarkedForPublish(language)}
            language={language}
            productText={relatedProductText(channel.id, language.code)}
          />
        ))}
      </Flags>
    </Header>
  );
};
