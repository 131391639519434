import React, { useState } from "react";
import { ChannelEditModal } from "./ChannelEditModal";
import { Button } from "semantic-ui-react";

type Props = {
  channelId: string;
};
export const ChannelEditButton: React.FC<Props> = ({ channelId }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        basic
        color={"red"}
        content={"Settings"}
        data-testid="channel-edit-button"
        onClick={(): void => setOpen(!open)}
        size={"small"}
      />
      <ChannelEditModal open={open} setOpen={setOpen} channelId={channelId} />
    </>
  );
};
