import React from "react";
import { Modal } from "semantic-ui-react";
import { Product } from "../product";
import { RegenerateAction, RegenerateConfig } from "../../api/action";
import { ProductTextCountData } from "./RegenerateTextsModalStateHandler";
import { RegenerateTextsModalForm } from "./RegenerateTextsModalForm";
import { ConfirmActionButtons } from "./ConfirmActionButtons";
import { ProductListTextStatusesCell } from "../product-list/ProductListTextStatusesCell";
import { ProductText } from "../../producttext/ProductText";
import styled from "styled-components";

const Flags = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
    justify-content: flex-start;
    > div {
      padding: 0 10px;
      cursor: grab;
    }
  }
`;

type Props = {
  askForOverwrite: boolean;
  cancelMessage: string;
  confirmMessage: string;
  displayApprovedCheckbox: boolean;
  displayEditedCheckbox: boolean;
  displayFlagStatus?: boolean;
  displayPublishedCheckbox: boolean;
  onCancel?: () => void;
  onConfirm?: (regenerateConfig: RegenerateConfig) => void;
  onOverwrite: (overwrite: boolean) => void;
  onRegenerateActionChange?: (action: RegenerateAction, value: boolean) => void;
  open: boolean;
  product: Product;
  productTexts: ProductText[];
  regenerateConfig: RegenerateConfig;
  textsCountData: ProductTextCountData;
};

export const RegenerateTextsModal: React.FC<Props> = ({
  askForOverwrite,
  cancelMessage,
  confirmMessage,
  displayApprovedCheckbox,
  displayEditedCheckbox,
  displayFlagStatus,
  displayPublishedCheckbox,
  onCancel,
  onConfirm,
  onOverwrite,
  onRegenerateActionChange,
  open,
  product,
  productTexts,
  regenerateConfig,
  textsCountData,
}) => (
  <Modal open={open} data-testid="regenerate-texts-modal">
    <Modal.Header>
      <strong>{product.external_id || product.id}</strong>
    </Modal.Header>
    <Modal.Content>
      <Flags data-testid="flag-overview">
        {displayFlagStatus && (
          <ProductListTextStatusesCell
            product={product}
            productTexts={productTexts}
          />
        )}
      </Flags>
      <RegenerateTextsModalForm
        askForOverwrite={askForOverwrite}
        displayApprovedCheckbox={displayApprovedCheckbox}
        displayEditedCheckbox={displayEditedCheckbox}
        displayPublishedCheckbox={displayPublishedCheckbox}
        onChangeOverwrite={onOverwrite}
        onRegenerateActionChange={onRegenerateActionChange}
        product={product}
        regenerateConfig={regenerateConfig}
        textsCountData={textsCountData}
      />
    </Modal.Content>
    <Modal.Actions>
      <ConfirmActionButtons
        cancelMessage={cancelMessage}
        confirmMessage={confirmMessage}
        onCancel={onCancel}
        onConfirm={(): void => onConfirm(regenerateConfig)}
      />
    </Modal.Actions>
  </Modal>
);
