import React from "react";
import { Product } from "../../product";
import {
  ProductText,
  ProductTextRef,
  ProductTextRefKey,
} from "../../../producttext/ProductText";
import { ColumnLabel, ColumnValue } from "./Columns";
import { Spinner } from "../../../utils/Spinner";
import { ProductListTextStatusesCell } from "../ProductListTextStatusesCell";
import { Column } from "./Column";
import { ProductListRenderContext } from "../ObjectTable";

export const ProductListTextStatusesColumn = {
  name: ColumnValue.TextStatuses,
  label: ColumnLabel[ColumnValue.TextStatuses],
  className: "text-column",
  // eslint-disable-next-line react/display-name
  renderCell: (
    product: Product,
    column: Column,
    context: ProductListRenderContext
  ): JSX.Element => {
    const {
      loadingProductTexts,
      productTexts,
      onProductListTextStatusesCellFlagToggled,
    } = context;
    if (loadingProductTexts) {
      return <Spinner />;
    }
    const productTextStatuses: ProductText[] = Object.keys(productTexts)
      .filter(
        (productTextKey: ProductTextRefKey) =>
          ProductTextRef.fromKey(productTextKey).productId == product.id
      )
      .map((key) => productTexts[key]);
    return (
      <ProductListTextStatusesCell
        onFlagToggle={onProductListTextStatusesCellFlagToggled}
        product={product}
        productTexts={productTextStatuses}
      />
    );
  },
  width: 3,
  default: false,
};
