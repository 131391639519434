import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import compact from "lodash/compact";
import styled from "styled-components";
import { SectionCard } from "./SectionCard";
import { Text } from "../../../components/Text";
import { IllustrationKeys } from "../../../planner/document-structure/types";
import { Button, Dimmer, Divider, Loader } from "semantic-ui-react";
import { useGetPrompts } from "../../copy-assistant/customHooks";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { useGetDocumentStructures } from "../../../planner/document-structure/manage/customhooks";
import { selectionBucketAction } from "../../../api/action";
import { ProductId } from "../../product";
import { SelectionAction } from "../../product-list/ProductList";
import { URLConfigParser } from "../../product-list/ProductListURLConfig";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import {
  TextBlockCountResponse,
  countTextBlocks,
} from "../../../api/textBlockApi";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";

const FlexArrangement = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-block: 1.5rem;
`;

enum userFriendlyGenerationSettingKeys {
  fallbackPrompt = "Fallback Prompt",
  translate = "Translate to all languages",
}

export type GenerationSetting = {
  sectionName: string;
  sectionId: number;
  fallbackPrompt: {
    id: number;
    name: React.ReactNode;
  };
  translate: boolean;
};

type PythonFriendlyGenerationSetting = {
  section_id: number;
  fallback_prompt_id: number;
  translate: boolean;
};

export type GenerateSectionInfo = {
  name: string;
  id: number;
  illustration_type: IllustrationKeys;
};

export type GenerateChannelInfo = {
  channelName: string;
  sections: GenerateSectionInfo[];
};

export type ValueOfGenerationSettings = GenerationSetting[keyof GenerationSetting];
// disableBucketCheck is only in place to make testing easier
export const GenerateAITexts: React.FC<{ disableBucketCheck?: boolean }> = ({
  disableBucketCheck,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    data: { prompts },
    isFetching: isFetchingPrompts,
  } = useGetPrompts();

  const {
    data: structures,
    isFetching: isFetchingStructures,
  } = useGetDocumentStructures();

  const {
    data: customer,
    isLoading: isFetchingCustomer,
  } = useGetCustomerQuery();

  const baseHref = useMemo(() => {
    if (!customer) return "/d/_/manage/";
    return `/d/${customer.slug}/manage/`;
  }, [customer]);

  const [inBulkActionContext, setInBulkActionContext] = useState<boolean>(true);

  const [activeProductIds, setActiveProductIds] = useState<ProductId[]>([]);

  const [textBlockCountsMap, setTextBlockCountsMap] = useState<
    TextBlockCountResponse
  >({});

  const [generationSettings, setGenerationSettings] = useState<
    GenerationSetting[]
  >([]);

  const isLoading =
    isFetchingPrompts || isFetchingCustomer || isFetchingStructures;

  const cleanChannelInfo = useMemo<GenerateChannelInfo[]>(() => {
    if (!structures || !customer) return [];
    const connectedChannels = customer.channels.filter(
      ({ document_structure_id }) => !!document_structure_id
    );
    const useableStructures = structures.filter(({ id }) =>
      connectedChannels.some(
        ({ document_structure_id }) => document_structure_id === id
      )
    );
    const generateChannelInfo: GenerateChannelInfo[] = [];
    useableStructures.forEach((structure) => {
      const channel = connectedChannels.find(
        ({ document_structure_id }) => document_structure_id === structure.id
      );
      if (!channel) return;
      const sections = structure.sections
        .filter(({ template_label_ids }) => !template_label_ids)
        .map((section) => {
          return {
            name: section.name,
            id: section.id,
            illustration_type: section.illustration_type,
          };
        });
      generateChannelInfo.push({
        channelName: channel.display_name,
        sections,
      });
    });
    return generateChannelInfo;
  }, [structures, customer]);

  const uniqueSectionInfo = useMemo<GenerateSectionInfo[]>(() => {
    const sections: GenerateSectionInfo[] = [];
    cleanChannelInfo.forEach((channel) => {
      channel.sections.forEach((section) => {
        if (!sections.some(({ id }) => id === section.id)) {
          sections.push(section);
        }
      });
    });
    return sections;
  }, [cleanChannelInfo]);

  const isAllSectionsActive = useMemo(
    () => generationSettings.length === uniqueSectionInfo.length,
    [generationSettings, uniqueSectionInfo]
  );

  const isAllSectionsForChannelActive = useCallback(
    (channelName: string): boolean => {
      const sections = cleanChannelInfo.flatMap((channel) => {
        if (channel.channelName === channelName) return channel.sections;
      });
      return compact(sections).every((section) =>
        generationSettings.some((setting) => setting.sectionId === section.id)
      );
    },
    [generationSettings, cleanChannelInfo]
  );

  const getCountTextBlockForSection = useCallback(
    (sectionId: number): { total: number; empty: number } =>
      textBlockCountsMap[sectionId] || {
        total: null,
        empty: null,
      },
    [textBlockCountsMap]
  );

  const getActiveProductIds = (
    action: SelectionAction
  ): Promise<ProductId[]> => {
    const urlConfig = new URLConfigParser();
    const { bucket } = urlConfig.getSearchParams();
    if (!bucket && !disableBucketCheck) {
      setInBulkActionContext(false);
      return Promise.resolve([]);
    }
    return selectionBucketAction(token, bucket, action, []);
  };

  useEffect(() => {
    let mounted = true;
    if (token) {
      getActiveProductIds(SelectionAction.ADD).then((productIds) => {
        if (mounted) setActiveProductIds(productIds);
      });
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  useEffect(() => {
    let mounted = true;
    if (!activeProductIds.length) return;
    if (token && inBulkActionContext) {
      const sectionIds = uniqueSectionInfo.map(({ id }) => id);
      countTextBlocks(token, activeProductIds, sectionIds).then((counts) => {
        if (mounted) setTextBlockCountsMap(counts);
      });
    }
    return () => {
      mounted = false;
    };
  }, [token, activeProductIds, uniqueSectionInfo]);

  const findSharedSectionAndAlert = (
    channelName: string,
    sectionId: number,
    sectionName: string,
    changedSetting?: string
  ): void => {
    const otherChannels = cleanChannelInfo.filter(
      (channel) => channel.channelName !== channelName
    );

    const channelsWithSharedSections = otherChannels
      .filter((channel) =>
        channel.sections.some((section) => section.id === sectionId)
      )
      .map((channel) => channel.channelName);

    if (channelsWithSharedSections.length) {
      let content = `Section ${sectionName} has changed in other Channels`;

      if (changedSetting) {
        content = `Setting ${changedSetting} in Section ${sectionName} has changed in other Channels`;
      }

      setTimeout(() => {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.WARNING,
            content: content,
            additionalContent: `Affected Channels: ${channelsWithSharedSections.join(
              ", "
            )}`,
          })
        );
      }, 300);
    }
  };

  const handleAddGenerationSetting = (
    sectionId: number,
    channelName: string
  ): void => {
    const section = uniqueSectionInfo.find(
      (section) => section.id === sectionId
    );
    if (!section) return;
    findSharedSectionAndAlert(channelName, sectionId, section.name);
    const newGenerationSettings = [
      ...generationSettings,
      {
        sectionName: section.name,
        sectionId: section.id,
        fallbackPrompt: { id: undefined, name: "" },
        translate: false,
      },
    ];
    setGenerationSettings(newGenerationSettings);
    setGenerationSettingInput(newGenerationSettings);
  };

  const handleRemoveGenerationSetting = (
    sectionId: number,
    channelName: string
  ): void => {
    const removedSection = uniqueSectionInfo.find(
      (section) => section.id === sectionId
    );
    if (removedSection) {
      findSharedSectionAndAlert(channelName, sectionId, removedSection.name);
    }
    const newGenerationSettings = generationSettings.filter(
      (setting) => setting.sectionId !== sectionId
    );
    setGenerationSettings(newGenerationSettings);
    setGenerationSettingInput(newGenerationSettings);
  };
  const handleUpdateGenerationSettings = (
    key: keyof GenerationSetting,
    value: ValueOfGenerationSettings,
    sectionId: number,
    channelName: string
  ): void => {
    const section = uniqueSectionInfo.find(
      (section) => section.id === sectionId
    );
    if (section) {
      const changed =
        userFriendlyGenerationSettingKeys[
          key as keyof typeof userFriendlyGenerationSettingKeys
        ];
      findSharedSectionAndAlert(channelName, sectionId, section.name, changed);
    }
    const newGenerationSettings = generationSettings.map((setting) => {
      if (setting.sectionId === sectionId) {
        return {
          ...setting,
          [key]: value,
        };
      }
      return setting;
    });
    setGenerationSettings(newGenerationSettings);
    setGenerationSettingInput(newGenerationSettings);
  };

  const isActiveSection = (sectionId: number): boolean => {
    return generationSettings.some(
      (setting) => setting.sectionId === sectionId
    );
  };

  const getInput = (): Element =>
    wrapperRef.current.parentElement.previousElementSibling;

  const setAllSectionsActive = (): void => {
    const newGenerationSettings = uniqueSectionInfo.map((section) => {
      return (
        generationSettings.find(
          (setting) => setting.sectionId === section.id
        ) || {
          sectionName: section.name,
          sectionId: section.id,
          fallbackPrompt: { id: undefined, name: "" },
          translate: false,
        }
      );
    });
    setGenerationSettings(newGenerationSettings);
    setGenerationSettingInput(newGenerationSettings);
  };
  const setAllSectionsInChannelActive = (channelName: string): void => {
    const copy = [...generationSettings];
    const sections = cleanChannelInfo.flatMap((channel) => {
      if (channel.channelName === channelName) return channel.sections;
    });

    compact(sections).forEach((section) => {
      if (!copy.some((setting) => setting.sectionId === section.id)) {
        findSharedSectionAndAlert(channelName, section.id, section.name);
        copy.push({
          sectionName: section.name,
          sectionId: section.id,
          fallbackPrompt: { id: undefined, name: "" },
          translate: false,
        });
      }
    });
    setGenerationSettings(copy);
    setGenerationSettingInput(copy);
  };
  const getGenerationSettingInput = (): void => {
    // When we are looking at this view from an action, Load in the saved state from the hidden input.
    const input = getInput();
    if (!input) {
      throw new Error("Could not find input[name='generation_settings']");
    }
    const settings: PythonFriendlyGenerationSetting[] = JSON.parse(
      input.getAttribute("value") || "[]"
    );
    const builtSettings: GenerationSetting[] = settings.map((setting) => ({
      sectionName:
        uniqueSectionInfo.find((section) => section.id === setting.section_id)
          ?.name || "",
      sectionId: setting.section_id,
      fallbackPrompt: {
        id: setting.fallback_prompt_id,
        name: prompts.find((prompt) => prompt.id === setting.fallback_prompt_id)
          ?.name,
      },
      translate: setting.translate,
    }));
    setGenerationSettings(builtSettings);
  };

  const setGenerationSettingInput = (settings: GenerationSetting[]): void => {
    // Load the settings into the hidden input for the backend to parse and read once the form is submitted.
    const input = getInput();
    const pythonFriendlyGenerationSettings: PythonFriendlyGenerationSetting[] = settings.map(
      (setting) => ({
        section_id: setting.sectionId,
        fallback_prompt_id: setting.fallbackPrompt.id || undefined,
        translate: setting.translate,
      })
    );
    if (!input) {
      throw new Error("Could not find input[name='generation_settings']");
    }
    input.setAttribute(
      "value",
      JSON.stringify(pythonFriendlyGenerationSettings)
    );
  };

  useEffect(() => {
    if (inBulkActionContext) return;
    if (prompts && uniqueSectionInfo && wrapperRef.current) {
      getGenerationSettingInput();
    }
  }, [inBulkActionContext, prompts, uniqueSectionInfo, wrapperRef]);

  return (
    <div style={{ padding: "2rem", paddingBottom: "3.5rem" }} ref={wrapperRef}>
      {cleanChannelInfo.length <= 0 ? (
        <Text as="h3" textAlignment="center">
          Seems like you don&apos;t have any Sections to generate from yet, go
          to{" "}
          <a href={`${baseHref}planner/document-structures`} data-subpage>
            Manage Sections...
          </a>{" "}
          to setup your Sections.
        </Text>
      ) : (
        <>
          {/* Clicking here will trigger the form submit */}
          {inBulkActionContext && (
            <Button
              data-testid="generate-ai-texts-submit-top"
              color="red"
              content="Generate"
              disabled={!generationSettings.length}
              floated="right"
            />
          )}
          <Text as="h2">Select Sections to generate</Text>
          <a href={`${baseHref}planner/document-structures`} data-subpage>
            Manage Sections...
          </a>
          <Text as="h5">
            Products that do not already have texts in a Section will use{" "}
            <a href={`${baseHref}gpt/prompt-selection-rules`} data-subpage>
              Prompt selection rules...
            </a>
          </Text>
          <Button
            content="Select all Sections"
            data-testid="generate-ai-texts-select-all-sections"
            disabled={isAllSectionsActive}
            color="red"
            basic
            compact
            size="tiny"
            onClick={(e): void => {
              e.preventDefault();
              setAllSectionsActive();
            }}
          />

          {cleanChannelInfo.map(({ channelName, sections }) => (
            <React.Fragment key={channelName}>
              <Divider />
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Text as="h3" compact>
                  {channelName}
                </Text>
                <Button
                  content="Select All"
                  basic
                  color="red"
                  compact
                  size="tiny"
                  onClick={(e): void => {
                    e.preventDefault();
                    setAllSectionsInChannelActive(channelName);
                  }}
                  disabled={isAllSectionsForChannelActive(channelName)}
                />
              </div>
              <FlexArrangement>
                {sections.map((section) => (
                  <SectionCard
                    key={section.id}
                    section={section}
                    prompts={prompts}
                    textBlockCount={getCountTextBlockForSection(section.id)}
                    translate={
                      generationSettings.find(
                        (setting) => setting.sectionName === section.name
                      )?.translate
                    }
                    selectedPromptId={
                      generationSettings.find(
                        (setting) => setting.sectionName === section.name
                      )?.fallbackPrompt?.id
                    }
                    inBulkActionContext={inBulkActionContext}
                    active={isActiveSection(section.id)}
                    handleUpdateGenerationSettings={(
                      key: keyof GenerationSetting,
                      value: ValueOfGenerationSettings,
                      sectionId: number
                    ): void =>
                      handleUpdateGenerationSettings(
                        key,
                        value,
                        sectionId,
                        channelName
                      )
                    }
                    handleAddGenerationSetting={(sectionId: number): void =>
                      handleAddGenerationSetting(sectionId, channelName)
                    }
                    handleRemoveGenerationSetting={(sectionId: number): void =>
                      handleRemoveGenerationSetting(sectionId, channelName)
                    }
                  />
                ))}
              </FlexArrangement>
            </React.Fragment>
          ))}
          <Divider />
          {/* Clicking here will trigger the form submit */}
          {inBulkActionContext && (
            <Button
              data-testid="generate-ai-texts-submit-bottom"
              color="red"
              content="Generate"
              disabled={!generationSettings.length}
              floated="right"
            />
          )}
        </>
      )}
      <Dimmer page active={isLoading} data-testid="generate-ai-texts-loading">
        <Loader active />
      </Dimmer>
    </div>
  );
};
