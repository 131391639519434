import React from "react";
import { NoCodeNode } from "./useNoCodeParser";
import { HTMLHiddenChar } from "./HTMLHiddenChar";

export const HTMLTextNode: React.FC<{
  node: NoCodeNode;
  onDragOver: (event: React.DragEvent<HTMLElement>) => void;
}> = ({ node, onDragOver }) => {
  if (node?.isBreakElement) {
    return (
      <React.Fragment>
        <HTMLHiddenChar />
        <span
          data-break-element
          data-node-id={node.id}
          data-node-type={node.type}
          data-node-value={node.value}
          contentEditable={false}
          data-disable-blur-event
          className="tw-transition-all focus:tw-outline-none"
          onDragOver={onDragOver}
        >
          {node.displayValue}
          <br id="inserted-break" />
        </span>
        <HTMLHiddenChar />
      </React.Fragment>
    );
  }
  return (
    <span
      data-node-id={node.id}
      data-node-type={node.type}
      data-node-value={node.value}
      data-disable-blur-event
      className="tw-transition-all focus:tw-outline-none"
      onDragOver={onDragOver}
    >
      {node.displayValue}
    </span>
  );
};
