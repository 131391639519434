import React, { useCallback, useState } from "react";
import {
  SectionAction,
  TemplateFieldInfoText,
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import { debounce } from "../../utils/debounce";

type Props = {
  field: TemplateFieldInfoText;
  form: TemplateFormInfo;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  placeholder: string;
  cols?: number;
  rows?: number;
};

export const TextAreaFormField: React.FC<Props> = ({
  cols = 40,
  field,
  form,
  placeholder,
  rows = 10,
  onSectionItemAction,
}) => {
  const [text, setText] = useState(field.value || "");
  const [isSaving, setIsSaving] = useState(false);
  const debounceCallback = useCallback(
    debounce((value: string): void => {
      setIsSaving(true);
      onSectionItemAction({
        action: SectionAction.TEMPLATE_FIELD_SET,
        tag_id: form.id,
        field: field.key,
        text_value: value,
      }).then(() => {
        setIsSaving(false);
      });
    }, 1000),
    []
  );

  const onInputChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const value = e.currentTarget.value;
    setText(value);
    debounceCallback(value);
  };

  return (
    <textarea
      value={text}
      cols={cols}
      rows={rows}
      placeholder={placeholder}
      onChange={onInputChangeHandler}
      disabled={isSaving}
    />
  );
};
