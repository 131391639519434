import React from "react";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { AddSubpartButton } from "./AddSubpartButton";
import { ProductTagAction } from "../../api/action";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { maybeStripSearchVolume } from "./edittabutils";
import { SidebarItem } from "./SidebarItem";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { onSelectedCallback } from "./EditSidebar";

interface Props {
  depth?: number;
  details: ProductDetailInfo;
  item: SidebarItem;
  onSelected: onSelectedCallback;
  onTagsAction: onTagsActionCallback;
  selected: SidebarItem;
}

export function showSubmenu(item: SidebarItem, selected: SidebarItem): boolean {
  if (!item.childItems) {
    return false;
  }
  if (item.id === selected.id) {
    return true;
  }

  return !!item.childItems.find((child) => showSubmenu(child, selected));
}

export const dragAction = (
  result: DropResult,
  item: SidebarItem,
  onTagsAction: onTagsActionCallback
): void => {
  const { source, destination } = result;
  // Dropping to nowhere, e.g. not permitted
  if (!destination) {
    return;
  }
  const { droppableId: destId, index: destIndex } = destination;
  const { droppableId: sourceId, index: sourceIndex } = source;
  // You can only drop within the same subpart
  if (destId != sourceId) {
    return;
  }
  // Change order of subparts, splice is used to remove and add an element to the appropriate place in the array
  const draggedItem = item.childItems[sourceIndex];
  item.childItems.splice(sourceIndex, 1);
  item.childItems.splice(destIndex, 0, draggedItem);
  // Tell the backend
  onTagsAction({
    action: ProductTagAction.REORDER,
    parent: item.isSubpart ? item.id : null,
    value: item.childItems.map((item) => item.id),
  });
};

/**
 * Shows a sidebar item that can be edited
 * Sometimes includes button to add a subpart, see {@link AddSubpartButton}
 * Includes a button to delete the current subpart
 * @param props
 * @constructor
 */
export const EditSidebarItem: React.FC<Props> = ({
  details,
  depth = 0,
  item,
  onSelected,
  onTagsAction,
  selected,
}) => {
  if (!item.id) {
    return null; // Return null if there's no item.id
  }

  return (
    <DragDropContext
      onDragEnd={(result: DropResult): void =>
        dragAction(result, item, onTagsAction)
      }
    >
      <Droppable droppableId={item.id}>
        {(provided): React.ReactElement => (
          <ul
            className="subparts"
            data-sortable="react"
            ref={provided.innerRef}
          >
            {item.childItems.map((item, index) => {
              let liClassNames = `depth-${depth}`;
              let linkClassNames = "link";
              const isLinkSelected = item.id === selected.id;
              const isItemSelected =
                isLinkSelected ||
                item.childItems.find((child) => child.id == selected.id);
              if (isItemSelected) {
                liClassNames += " active";
              }
              if (isLinkSelected) {
                linkClassNames += " active";
              }
              let submenu: React.ReactElement | null = null;
              if (showSubmenu(item, selected)) {
                submenu = (
                  <>
                    <EditSidebarItem
                      details={details}
                      item={item}
                      onSelected={onSelected}
                      onTagsAction={onTagsAction}
                      selected={selected}
                      depth={depth + 1}
                    />
                    {depth < 4 && (
                      <AddSubpartButton
                        details={details}
                        item={item}
                        onTagsAction={onTagsAction}
                        toplevel={false}
                      />
                    )}
                  </>
                );
              }
              return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided): React.ReactElement => (
                    <li
                      key={item.id}
                      className={liClassNames}
                      data-sortable-id={item.id}
                      data-vocab-id={
                        item.vocabularyIds && item.vocabularyIds.join(" ")
                      }
                    >
                      <div
                        className="subpart"
                        ref={provided.innerRef.bind(provided)}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <a
                          className={linkClassNames}
                          onClick={(): void => onSelected(item)}
                        >
                          {maybeStripSearchVolume(item.name) || "new attribute"}
                        </a>
                        <form
                          className="delete"
                          onSubmit={(event: React.FormEvent): void => {
                            event.preventDefault();
                            event.stopPropagation();
                            onTagsAction({
                              action: ProductTagAction.REMOVE,
                              value: [item.id],
                            });
                          }}
                        >
                          <input type="hidden" name="delete" value={item.id} />
                          <button className="delete-button" type="submit">
                            <i className="close icon"></i>
                          </button>
                        </form>
                      </div>
                      {submenu}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
