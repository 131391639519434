import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductListParams } from "../products/product-list/ProductListURLConfig";
import { Product } from "../products/product";

/**
 * The list of products that are displayed in the list
 * - `null` means that the search has not been finished yet
 * - `[]` means that the search has been finished, but it returned no results
 */
type CurrentProductType = Product[] | null;

export type CustomQueryType = {
  query?: string;
  queryHref?: string;
  deleteHref?: string;
};

export type HistoryStateDataType = {
  oldUrl: string | null;
  oldTitle: string | null;
};

export type PaginationDetailsType = {
  searchParams: ProductListParams;
  productCount: number;
};

type initialState = {
  products: CurrentProductType;
  customQuery: CustomQueryType;
  historyStateData: HistoryStateDataType;
  paginationDetails: PaginationDetailsType;
};

const productListSlice = createSlice({
  name: "productList",
  initialState: {
    products: null,
    customQuery: null,
    historyStateData: null,
    paginationDetails: {
      searchParams: null,
      productCount: null,
    },
  } as initialState,
  reducers: {
    setProducts: (
      state,
      { payload: products }: PayloadAction<CurrentProductType>
    ) => {
      state.products = products;
    },
    setCustomQuery: (
      state,
      { payload: customQuery }: PayloadAction<CustomQueryType>
    ) => {
      state.customQuery = customQuery;
    },
    setHistoryStateData: (
      state,
      { payload: historyStateData }: PayloadAction<HistoryStateDataType>
    ) => {
      state.historyStateData = historyStateData;
    },
    setPaginationDetails: (
      state,
      { payload: paginationDetails }: PayloadAction<PaginationDetailsType>
    ) => {
      state.paginationDetails = paginationDetails;
    },
  },
});

export const {
  setProducts,
  setCustomQuery,
  setHistoryStateData,
  setPaginationDetails,
} = productListSlice.actions;

export default productListSlice.reducer;
