import React from "react";
import { ProductId } from "../../product";
import {
  productDetailsRefreshCurrentTab,
  reloadPreview,
} from "../../../legacy/t.product-detail";
import { PrefillFromCategoryModal } from "./PrefillFromCategoryModal";
import { prefillFromTemplateCategory } from "../../../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";

export type PrefillCategory = {
  id: string;
  name: string;
};

type Props = {
  productId: ProductId;
};

export const PrefillFromCategoryModalToolbar: React.FC<Props> = ({
  productId,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const onPrefillCategory = (item: PrefillCategory): void => {
    prefillFromTemplateCategory({
      token: token,
      categoryId: item.id,
      productId: productId,
    }).then(() => {
      productDetailsRefreshCurrentTab();
      reloadPreview();
    });
  };
  return (
    <PrefillFromCategoryModal
      name={"Prefill from category"}
      onSelectItem={onPrefillCategory}
    />
  );
};
