import React from "react";
import { FilteredSearchModal } from "./FilteredSearchModal";
import {
  getPrefillProductCategories,
  PrefillProductCategoriesResponse,
  SortOption,
} from "../../../api/vocabularyApi";
import { PrefillCategory } from "./PrefillFromCategoryModalToolbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";

type Props = {
  name: string;
  onSelectItem: (item: PrefillCategory) => void;
  spaciousLabel?: boolean;
};

export const PrefillFromCategoryModal: React.FC<Props> = ({
  name,
  onSelectItem,
  spaciousLabel,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <FilteredSearchModal<PrefillCategory>
      description={"Prefill templates from a saved category"}
      name={name}
      onFetchItems={(): Promise<PrefillProductCategoriesResponse[]> =>
        getPrefillProductCategories(token)
      }
      onRenderRow={(category): JSX.Element => (
        <div
          className={"filtered-search-modal-category"}
          data-testid={"filtered-search-modal-category"}
        >
          {category.name}
        </div>
      )}
      onSelectItem={onSelectItem}
      onSortChange={(
        order: SortOption
      ): Promise<PrefillProductCategoriesResponse[]> =>
        getPrefillProductCategories(token, order)
      }
      spaciousLabel={spaciousLabel}
      title={"Prefill from category"}
    />
  );
};
