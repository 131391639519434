import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import { ProductId } from "../product";
import { Spinner } from "../../utils/Spinner";
import { useGetCustomerQuery } from "../../api/customerApi";
import {
  useGetPrompts,
  useGetPromptGroups,
  useGetTextBlocks,
  useGetProductSpecificOverwrites,
} from "./customHooks";
import { SelectedPrompt } from "./Selected/SelectedPrompt";
import { GeneratedResults } from "./GeneratedResults/GeneratedResults";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
  copyAssistantReducer,
  initialCopyAssistantState,
} from "./CopyAssistantContext/CopyAssistantContext";
import { CopyAssistantActionType } from "./CopyAssistantContext/types";
import { SelectedPromptGroup } from "./Selected/SelectedPromptGroup";
import { useGetFieldsets } from "../manage/fieldsets/customhooks";
import { ToolbarTop } from "./Toolbar/ToolbarTop";
import {
  useGetDocumentSections,
  useGetDocumentStructures,
} from "../../planner/document-structure/manage/customhooks";
import { Sentence } from "./types";
import { generateId } from "../../utils/uuidUtils";
import { findConnectedChannelsForSection, sortTextBlocks } from "./utils";
import { useUrlState } from "../../utils/react-custom-hooks/urlStateHandler";
import {
  GptGenerationTaskQueue,
  createProductSpecificOverwrite,
} from "../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";

interface Props {
  productId: ProductId;
}

export const ProductDetailCopyAssistant: React.FC<Props> = ({ productId }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const mounted = useRef(false);
  const [state, dispatch] = useReducer(copyAssistantReducer, {
    ...initialCopyAssistantState,
    productId,
  });
  const { data: customer, isLoading } = useGetCustomerQuery();

  const { setParam, removeParam, urlState } = useUrlState<{
    prompt_id: number;
    group_id: number;
  }>();

  const {
    data: { fieldsets, metadata },
    isFetching: isFetchingFieldsets,
  } = useGetFieldsets({ productId });

  const {
    data: sections,
    isFetching: isFetchingSections,
  } = useGetDocumentSections();

  const {
    data: structures,
    isFetching: isFetchingStructures,
  } = useGetDocumentStructures();

  const {
    data: textBlocks,
    isFetching: isFetchingTextBlocks,
  } = useGetTextBlocks(productId);

  const {
    data: productSpecificOverwrites,
    isFetching: isFetchingProductSpecificOverwrite,
  } = useGetProductSpecificOverwrites(productId);

  const {
    data: {
      prompts,
      selected_prompt,
      selected_prompt_group,
      use_channel_as_default_fallback,
    },
    isFetching: isFetchingPrompts,
    reFetch: reFetchPrompts,
  } = useGetPrompts({ productId: productId });

  const {
    data: groups,
    isFetching: isFetchingGroups,
    reFetch: reFetchGroups,
  } = useGetPromptGroups();

  const gptGenerationTaskQueue = useMemo(() => {
    if (token) return new GptGenerationTaskQueue(token);
  }, [token]);

  const promptAndGroupSelectionFromProductSpecificOverwrites = useMemo(() => {
    if (productSpecificOverwrites.length)
      return {
        promptId: productSpecificOverwrites[0].prompt_id,
        groupId: productSpecificOverwrites[0].group_id,
      };

    return {
      promptId: undefined,
      groupId: undefined,
    };
  }, [productSpecificOverwrites]);
  const [hasCreatedKnownSentences, setHasCreatedKnownSentences] = useState(
    false
  );

  const createKnownSentences = (): void => {
    const sentences: Sentence[] = [];
    const mainTextBlocks = sortTextBlocks(
      textBlocks.filter((block) => !block.translated_from),
      groups,
      state.groups.selected
    );
    const allTranslatedTextBlocks = textBlocks.filter(
      (block) => block.translated_from
    );
    // We need to reverse the list to set the correct ordering in the context for the sections
    // the last added section to the ordering is the one that will be displayed first in the list
    mainTextBlocks.reverse().forEach((textBlock) => {
      const langStringValues = {
        [textBlock.language]: textBlock.text,
      };
      const currentTranslatedTextBlocks = allTranslatedTextBlocks.filter(
        (translatedTextBlock) =>
          translatedTextBlock.translated_from === textBlock.id
      );
      currentTranslatedTextBlocks.forEach((translatedTextBlock) => {
        langStringValues[translatedTextBlock.language] =
          translatedTextBlock.text;
      });
      const prompt = prompts.find(
        (prompt) => prompt.id === textBlock.prompt_id
      );
      const sentence: Sentence = {
        id: generateId(),
        value: langStringValues,
        prompt_id: prompt?.id,
        language: prompt?.language || textBlock?.language,
        generationInfo: {
          promptId: prompt?.id,
          instruction: prompt?.instruction,
        },
        sectionInfo: {
          documentSectionId: textBlock.section_id,
          documentSectionName: sections.find(
            (section) => section.id === textBlock.section_id
          )?.name,
          textBlockId: textBlock.id,
          langStringValues: langStringValues,
          connectedChannels: findConnectedChannelsForSection(
            textBlock.section_id,
            customer,
            structures
          ),
          active: textBlock.active,
        },
      };
      if (sentence.sectionInfo.documentSectionName) {
        dispatch({
          type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
          payload: sentence.sectionInfo.documentSectionName,
        });
      }
      sentences.push(sentence);
    });
    dispatch({
      type: CopyAssistantActionType.ADD_SENTENCES,
      payload: sentences,
    });
    setHasCreatedKnownSentences(true);
  };

  useEffect(() => {
    if (
      !isFetchingSections &&
      !isFetchingTextBlocks &&
      !isFetchingPrompts &&
      !isFetchingStructures &&
      !isLoading &&
      !hasCreatedKnownSentences
    ) {
      createKnownSentences();
    }
  }, [
    isFetchingSections,
    isFetchingTextBlocks,
    isFetchingPrompts,
    isFetchingStructures,
    isLoading,
  ]);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING,
      payload: isFetchingProductSpecificOverwrite,
    });
    if (!isFetchingProductSpecificOverwrite && mounted.current) {
      dispatch({
        type: CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES,
        payload: productSpecificOverwrites,
      });
    }
  }, [isFetchingProductSpecificOverwrite]);

  useEffect(() => {
    if (!isFetchingFieldsets && mounted.current) {
      dispatch({
        type: CopyAssistantActionType.SET_FIELDSETS,
        payload: {
          fieldsets,
          metadata,
        },
      });
    }
  }, [isFetchingFieldsets]);

  const saveLastUsedPromptOrGroup = async (
    promptId: number,
    groupId?: number
  ): Promise<void> => {
    await createProductSpecificOverwrite(token, {
      product_id: productId,
      prompt_id: promptId,
      group_id: groupId || null,
    });
  };

  useEffect(() => {
    if (
      !state.prompts.selected &&
      !state.groups.selected &&
      isFetchingProductSpecificOverwrite
    )
      return;

    if (state.prompts.selected && !state.groups.selected) {
      setParam("prompt_id", state.prompts.selected.id);
      removeParam("group_id");

      saveLastUsedPromptOrGroup(state.prompts.selected.id);
    } else if (state.groups.selected && !state.prompts.selected) {
      setParam("group_id", state.groups.selected.id);
      removeParam("prompt_id");
      saveLastUsedPromptOrGroup(
        state.groups.selected.prompt_ids[0], // a prompt needs to be present when saving.
        state.groups.selected.id // Passing in the group id here makes next time user visits this product this group will be selected
      );
    }
  }, [
    state.prompts.selected,
    state.groups.selected,
    isFetchingProductSpecificOverwrite,
  ]);

  useEffect(() => {
    if (
      !isFetchingPrompts &&
      !isFetchingGroups &&
      !isFetchingProductSpecificOverwrite &&
      mounted.current
    ) {
      let selectedPromptId =
        promptAndGroupSelectionFromProductSpecificOverwrites.promptId;
      let selectedGroupId =
        promptAndGroupSelectionFromProductSpecificOverwrites.groupId;
      if (!selectedPromptId && !selectedGroupId) {
        selectedPromptId = urlState.prompt_id;
        selectedGroupId = urlState.group_id;
      }
      let selectedPrompt = selected_prompt;
      let selectedGroup = selected_prompt_group;
      if (selectedGroupId) {
        const foundGroupFromUrl = groups.find(
          (group) => group.id === selectedGroupId
        );
        if (!foundGroupFromUrl) {
          removeParam("group_id");
        } else {
          selectedGroup = foundGroupFromUrl;
          selectedPrompt = null;
        }
      } else if (selectedPromptId) {
        const foundPromptFromUrl = prompts.find(
          (prompt) => prompt.id === selectedPromptId
        );
        if (!foundPromptFromUrl) {
          removeParam("prompt_id");
        } else {
          selectedPrompt = foundPromptFromUrl;
          selectedGroup = null;
        }
      }

      dispatch({
        type: CopyAssistantActionType.SET_PROMPT_INIT_STATE,
        payload: {
          isLoading: false,
          list: prompts,
          selected: selectedPrompt,
          selectedGroup: selectedGroup,
        },
      });
    }
  }, [isFetchingPrompts, isFetchingGroups, isFetchingProductSpecificOverwrite]);

  useEffect(() => {
    if (!isFetchingGroups && mounted.current) {
      dispatch({
        type: CopyAssistantActionType.SET_GROUP_INIT_STATE,
        payload: {
          isLoading: false,
          list: groups,
        },
      });
    }
  }, [isFetchingGroups]);

  useEffect(() => {
    if (state.prompts.selected || state.groups.selected)
      dispatch({
        type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
        payload: true,
      });
  }, [state.prompts.selected, state.groups.selected]);

  const onRefreshPromptList = async (): Promise<void> => {
    dispatch({
      type: CopyAssistantActionType.SET_PROMPT_LOADING,
      payload: true,
    });
    await reFetchPrompts();
    await reFetchGroups();
  };
  const onRefreshGroupList = async (): Promise<void> => {
    dispatch({
      type: CopyAssistantActionType.SET_GROUP_LOADING,
      payload: true,
    });

    await reFetchGroups();
    await reFetchPrompts();
  };
  if (isLoading || !token) {
    return <Spinner />;
  }

  return (
    <CopyAssistantContext.Provider value={state}>
      <CopyAssistantDispatchContext.Provider value={dispatch}>
        <ToolbarTop
          refreshPromptList={onRefreshPromptList}
          refetchGroups={onRefreshGroupList}
          productId={productId}
        />
        <Grid divided="vertically">
          <Grid.Row columns={2} divided>
            <Grid.Column>
              <SelectedPrompt
                productId={productId}
                refreshPrompts={onRefreshPromptList}
                useChannelAsDefaultFallback={use_channel_as_default_fallback}
                gptGenerationTaskQueue={gptGenerationTaskQueue}
              />
              <SelectedPromptGroup
                reFetchPrompts={onRefreshPromptList}
                useChannelAsDefaultFallback={use_channel_as_default_fallback}
                gptGenerationTaskQueue={gptGenerationTaskQueue}
              />
            </Grid.Column>
            <Grid.Column>
              <GeneratedResults
                reFetchPrompts={onRefreshPromptList}
                gptGenerationTaskQueue={gptGenerationTaskQueue}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CopyAssistantDispatchContext.Provider>
    </CopyAssistantContext.Provider>
  );
};
