import { UseGenericFetch, useGenericFetch } from "../../../api/customFetchHook";
import {
  FieldsetKeyMapping,
  getFieldsetKeyMappings,
  getFieldsets,
} from "../../../api/fieldsetApi";
import { ProductId } from "../../product";
import { AppliedField, GetFieldsetsResponse } from "./types";

export function useGetFieldsets(query: {
  productId: ProductId;
  includeKnownKeys?: boolean;
}): UseGenericFetch<GetFieldsetsResponse<AppliedField>>;
export function useGetFieldsets(query: {
  productId?: ProductId;
  includeKnownKeys: boolean;
}): UseGenericFetch<GetFieldsetsResponse>;
export function useGetFieldsets(): UseGenericFetch<GetFieldsetsResponse>;
export function useGetFieldsets(query?: {
  productId?: ProductId;
  includeKnownKeys?: boolean;
}): UseGenericFetch<GetFieldsetsResponse> {
  return useGenericFetch<GetFieldsetsResponse>(
    { fieldsets: [], metadata: {}, known_keys: [] },
    getFieldsets,
    query
  );
}

export function useGetFieldsetKeyMappings(
  key?: string
): UseGenericFetch<FieldsetKeyMapping> {
  return useGenericFetch<FieldsetKeyMapping>({}, getFieldsetKeyMappings, key);
}
