import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uuidv4 } from "../utils/uuidUtils";

const TOAST_MAX_MESSAGES = 5;

export enum NotificationAppearance {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
}
export interface DjangoToastData {
  content: string;
  additionalContent?: string;
  appearance: NotificationAppearance;
  uuid?: string;
  timeoutId?: NodeJS.Timeout;
}

type InitialState = DjangoToastData;

const checkAndRemoveDuplicates = (
  content: string,
  state: InitialState[]
): void => {
  const index = state.findIndex((data) => data.content === content);
  if (index != -1) {
    if (state[index].timeoutId) {
      clearTimeout(state[index].timeoutId);
    }
    state.splice(index, 1);
  }
};

const checkLengthAndRemoveOldestToast = (state: InitialState[]): void => {
  if (state.length >= TOAST_MAX_MESSAGES) {
    if (state[0].timeoutId) {
      clearTimeout(state[0].timeoutId);
    }
    state.splice(0, 1);
  }
};
const djangoToastSlice = createSlice({
  name: "djangoToast",
  initialState: [] as InitialState[],
  reducers: {
    setDjangoToastOpen: (
      state,
      {
        payload: { content, appearance, additionalContent },
      }: PayloadAction<DjangoToastData>
    ) => {
      // If the user trigger the same message again we can remove the old one
      checkAndRemoveDuplicates(content, state);

      // remove oldest message if we already have n messages
      checkLengthAndRemoveOldestToast(state);

      state.push({
        additionalContent: additionalContent ?? "",
        content,
        appearance,
        uuid: uuidv4(),
        timeoutId: undefined,
      });
    },
    setDjangoToastTimeoutId: (state, { payload: { timeoutArray } }) => {
      timeoutArray.forEach(
        ({
          componentId,
          timeoutId,
        }: {
          timeoutId: NodeJS.Timeout;
          componentId: string;
        }) => {
          const index = state.findIndex((data) => data.uuid === componentId);
          // if we find same component id in state we add the timeout id
          if (index != -1 && !state[index].timeoutId) {
            state[index] = { ...state[index], timeoutId };
          } else {
            // as we can get in to some strange race conditions or
            // that setDjangoToastTimeoutId gets fired multiple times
            // we make sure we do not have redundant timeouts
            clearTimeout(timeoutId);
          }
        }
      );
    },
    setDjangoToastClose: (state, { payload: { toastToClose } }) => {
      const index = state.findIndex(({ uuid }) => uuid === toastToClose);
      if (index != -1) {
        // remove the timeout every time
        // but this makes sure that if the user closes a message before the timeout is triggered we remove the timeout
        clearTimeout(state[index].timeoutId);
      }
      state.splice(index, 1);
    },
  },
});

export const {
  setDjangoToastOpen,
  setDjangoToastClose,
  setDjangoToastTimeoutId,
} = djangoToastSlice.actions;

export default djangoToastSlice.reducer;
