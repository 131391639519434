import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownProps, Grid } from "semantic-ui-react";
import { getPrefillProductCategories } from "../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

type Props = {
  selectedOptionItem: (selectedOptionItem: DropdownItemProps) => void;
  newOptionValue?: (newOptionValue: string) => void;
};

type Suggestion = {
  key: number;
  text: string;
  value: number;
};

export const ProductCategorySelector: React.FC<Props> = ({
  selectedOptionItem,
  newOptionValue,
}) => {
  const [productCategorySuggestions, setProductCategorySuggestions] = useState<
    Suggestion[]
  >([]);
  const mounted = useRef(true);
  const token = useSelector((state: RootState) => state.auth.token);
  const [value, setValue] = useState<number>(null);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      (async (): Promise<void> => {
        const items = await getPrefillProductCategories(token, "display_name");
        setProductCategorySuggestions(
          items.map((item) => {
            return {
              key: Number(item.id),
              text: item.name,
              value: Number(item.id),
            };
          })
        );
      })();
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);
  const handleProductCategoryChange = (
    event: React.SyntheticEvent,
    data: DropdownProps
  ): void => {
    if (typeof data.value === "number") {
      // When value is a number it means that option is selected.
      setValue(data.value);
      const selectedOption = data.options.filter((option) => {
        return option.key === data.value;
      });
      selectedOptionItem(selectedOption);
    } else if (typeof data.value === "string" && data.value.length === 0) {
      // When value is an empty string it means that field is cleared.
      setValue(null);
      selectedOptionItem(null);
    }
  };

  const onAddItem = (event: React.KeyboardEvent, data: DropdownProps): void => {
    const keyValue = data.options.length + 1;
    const newOption = {
      key: keyValue,
      text: data.value.toString(),
      value: keyValue,
    };
    setValue(keyValue);
    setProductCategorySuggestions([...productCategorySuggestions, newOption]);
    selectedOptionItem(newOption);
    newOptionValue(newOption.text);
  };

  return (
    <Grid>
      <Dropdown
        allowAdditions
        clearable
        fluid
        data-testid="save-as-category-selector"
        onAddItem={onAddItem}
        onChange={handleProductCategoryChange}
        options={productCategorySuggestions}
        search
        selection
        value={value}
      />
    </Grid>
  );
};
