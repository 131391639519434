import React, { Dispatch, SetStateAction } from "react";
import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";
import { Text } from "../../../components/Text";

type Props = {
  dropdownOptions: DropdownItemProps[];
  selectedTags: string[];
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
  handleAddTag: (tag: string) => void;
  placeholder?: string;
  helpText?: string;
};

export const PromptTagsField: React.FC<Props> = ({
  dropdownOptions,
  selectedTags,
  setSelectedTags,
  handleAddTag,
  placeholder,
  helpText,
}) => (
  <Form.Field>
    <label>Labels</label>
    <Text color="grey" className="descriptive-helper-text">
      {helpText || "Labels are used to identify prompts"}
    </Text>
    <Dropdown
      data-testid="copy-assistant-template-form-add-tags-dropdown"
      search
      placeholder={placeholder || "Labels"}
      fluid
      multiple
      selection
      allowAdditions
      value={selectedTags}
      onChange={(_, { value }): void => {
        setSelectedTags(value as string[]);
      }}
      onAddItem={(_, { value }): void => {
        handleAddTag(value as string);
      }}
      options={dropdownOptions}
    />
  </Form.Field>
);
