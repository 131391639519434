import React from "react";
import { StyledViewSetButton } from "./viewsets/ViewSetsFilterContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { Customer } from "../../customers/Customer";
import { CustomQueryType } from "../../api/productListSlice";
import { CustomQueryHintDropdown } from "./CustomQueryHintDropdown";

export const setCustomQueryContent = ({
  customer,
  query,
}: {
  customer: Customer;
  query: string;
}): CustomQueryType => {
  const params = new URLSearchParams(window.location.search);
  const baseURL = `/d/${customer.slug}/products`;
  params.delete("view");
  params.delete("viewset");
  const queryHref = `${baseURL}/query?${params}`;
  params.delete("query");
  const deleteHref = `${baseURL}?${params}`;
  return { deleteHref, query, queryHref };
};

export const CustomQueryHint: React.FC = () => {
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );
  if (customQuery?.query) {
    return <CustomQueryHintDropdown />;
  } else {
    return (
      <StyledViewSetButton
        data-testid={"custom-query-hint-button"}
        size={"tiny"}
        basic
        href={customQuery?.queryHref}
        icon="bolt"
        content="Custom query"
      />
    );
  }
};
