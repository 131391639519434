import React, { useEffect, useRef, useState } from "react";
import {
  getTagTypeValue,
  TagOption,
  TreeData,
  VocabularyTreeData,
} from "../utils/tagutils";

import { useGetCustomerQuery } from "../api/customerApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../utils/store";
import { AxiosHeaders, AxiosResponse } from "axios";
import styled from "styled-components";
import { getDisplayText } from "../utils/data";
import { CATEGORIES, TYPES } from "../utils/consts";
import { newRequestWord } from "../api/vocabularyApi";
import { ProductTagsEditor } from "./ProductTagsEditor";
import {
  AllowTagType,
  RequestWordModal,
  RequestWordTranslateType,
  RequestWordTranslateTypeToastMessage,
  WordType,
} from "./RequestWordModal/RequestWordModal";
import { Icon } from "semantic-ui-react";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../api/djangoToastSlice";

const StyledWarning = styled.p`
  margin: 20px 0 !important;
  padding: 0 !important;
  width: max-content;
  ::before,
  ::after {
    line-height: 20px;
    text-align: start;
  }
`;

export interface RequestWord {
  callback: (response: AxiosResponse, selectType: string) => void | null;
  hypernymData: any | null;
  lexiconData: any | null;
  newWord: string;
  openRequestModal: boolean;
  selectType: string;
}

interface RequestModelData {
  allowMaterialKindTagsOnly?: boolean;
  disallowKind?: boolean;
  hypernymData?: any;
  lexiconData?: any;
  modalEnglishInputValue: string;
  modalInputValue: string;
  newRequestWord: RequestWord;
  requestWord: RequestWord;
  shouldPopulate: boolean;
  wordType: string;
}

const newRequestWordInit: RequestWord = {
  callback: null,
  hypernymData: null,
  lexiconData: null,
  newWord: "",
  openRequestModal: false,
  selectType: "",
};

type Props = {
  allowMaterialKindTagsOnly?: boolean;
  initialData: TreeData[];
  limit: number;
  onDataChanged: (
    container: typeof AdvancedTemplateContainer,
    data: TreeData[],
    delayedRefresh: boolean
  ) => void;
  textualAppName: string;
  vocabularyRequestProductDescription?: string;
  vocabularyRequestProductId?: string;
};

export const AdvancedTemplateContainer: React.FC<Props> = ({
  allowMaterialKindTagsOnly,
  initialData,
  limit,
  onDataChanged,
  textualAppName,
  vocabularyRequestProductDescription,
  vocabularyRequestProductId,
}) => {
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [requestModalData, setRequestModalData] = useState<RequestModelData>({
    disallowKind: false,
    wordType: "",
    modalInputValue: "",
    modalEnglishInputValue: "",
    newRequestWord: {
      ...newRequestWordInit,
    },
    requestWord: {
      ...newRequestWordInit,
    },
    shouldPopulate: false,
  });
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (initialData.length) {
        if (initialData[0] && initialData[0].children) {
          initialData[0].children.forEach((child) => {
            if (child.allow_children) {
              addSubpartTagsOptions(child);
              if (child.children.length > 0) {
                child.children.forEach((grandChild) => {
                  if (grandChild.allow_children) {
                    addSubpartTagsOptions(grandChild);
                  }
                });
              }
            }
          });
        }
        setTreeData(initialData);
      }
    }
    return (): void => {
      mounted.current = false;
    };
  }, []);

  /**
   * Add tag options to node.
   */
  const addSubpartTagsOptions = (node: TreeData): void => {
    node = onEditorMapEnumNumberNames(node);
    node.subpartOptions = [
      {
        value: node.value,
        text: getDisplayText(node),
      },
    ];

    if (node.children.length > 0) {
      const tagOptions: TagOption[] = [];
      const tags: string[] = [];
      node.children.forEach((child) => {
        if (!child.allow_children) {
          tagOptions.push({
            value: child.value,
            text: getDisplayText(child),
          });
          tags.push(child.value);

          if (node.selectedTagSchema) {
            node.selectedTagSchema.push(child);
          } else {
            node.selectedTagSchema = [{ ...child }];
          }
        }
      });
      if (tagOptions.length > 0) {
        node.tagOptions = tagOptions;
        node.tags = tags;
      }
    }
  };

  /*
   * TREE MANAGEMENT
   */

  /**
   * Adds new child node to the root of the tree.
   *
   * @param {string} tagType - on of TYPES from consts.js
   * @param {boolean} allowChildren - whether to allow adding children to new node
   * @param {string} value - tag value
   * @param {boolean} triggerDataChanged - whether to reload component
   */
  const addChildNodeToTree = (
    tagType: string,
    allowChildren = true,
    value = "",
    triggerDataChanged = false
  ): void => {
    const node: VocabularyTreeData = {
      allow_children: allowChildren,
      category: CATEGORIES.IDENTIFIER,
      children: [],
      helper_display_name: "",
      id: "",
      master_display_name: "",
      seo_scores_volume: "",
      setting_schema: null,
      settings: {},
      type: tagType,
      value: value,
      vocab_type: "",
    };
    const newTreeData = [...treeData];
    newTreeData[0].children = newTreeData[0].children.concat(node);
    if (triggerDataChanged) {
      onDataChanged(this, newTreeData, false);
    }
    setTreeData(newTreeData);
  };

  const onEditorBrandSelected = (): void => {
    addChildNodeToTree("brand", false, ":brand:", true);
  };

  const onEditorChildAdded = (): void => {
    addChildNodeToTree(TYPES.KIND, true, "", false);
  };

  const onEditorDataChanged = (
    currentTreeData: TreeData[],
    delayedRefresh: boolean
  ): void => {
    onDataChanged(this, currentTreeData, delayedRefresh);
    setTreeData(currentTreeData);
  };

  const onEditorHandleRequestWord = (
    value: string,
    selectType: string,
    callback: (response: AxiosResponse, selectType: string) => void
  ): void => {
    const { requestWord } = requestModalData;

    requestWord.callback = callback;
    requestWord.newWord = value;
    requestWord.openRequestModal = true;
    requestWord.selectType = selectType;

    const wordType = "";

    let { disallowKind } = requestModalData;
    if (selectType === "productTagSelect") {
      disallowKind = true;
    }

    setRequestModalState({
      disallowKind,
      isOpenOption: false,
      requestCallback: callback,
      requestSelectType: selectType,
      requestWord,
      wordType,
    });
  };

  /**
   * Provide enum names for each node (sg == singular, etc).
   */
  const onEditorMapEnumNumberNames = (
    node: VocabularyTreeData
  ): VocabularyTreeData => {
    if (
      node.setting_schema &&
      node.setting_schema.properties &&
      node.setting_schema.properties.number &&
      node.setting_schema.properties.number.enum
    ) {
      node.setting_schema.properties.number.enumNames = node.setting_schema.properties.number.enum.map(
        (value: string) => getTagTypeValue(value)
      );
    }
    return node;
  };

  const onEditorNameSelected = (): void => {
    addChildNodeToTree("name", false, ":name:", true);
  };

  /*
   * END OF TREE MANAGEMENT
   */

  /*
   * REQUEST MODAL
   */

  const setRequestModalState = (newState: any): void => {
    setRequestModalData({ ...requestModalData, ...newState });
  };

  const onLexiconDataChange = (lexiconData: any): void => {
    setRequestModalState({ lexiconData: lexiconData });
  };

  const onHypernymChange = (hypernymData: any): void => {
    setRequestModalState({ hypernymData: hypernymData });
  };

  const onShouldPopulateChange = (value: boolean): void => {
    setRequestModalState({ shouldPopulate: value });
  };

  const requestNewWord = (translateType: RequestWordTranslateType): void => {
    requestWordAndAddInTag(translateType);
  };

  const addNewRequestWord = (response: AxiosResponse): void => {
    const { requestWord } = requestModalData;
    requestWord.callback?.(response, requestWord.selectType);
  };

  const requestWordAndAddInTag = (
    translateType: RequestWordTranslateType
  ): void => {
    const {
      hypernymData,
      lexiconData,
      modalEnglishInputValue,
      modalInputValue,
      requestWord,
      shouldPopulate,
      wordType,
    } = requestModalData;

    const [wordTagCategory, wordTagType] = wordType.split("/");

    let newWord;
    if (lexiconData) {
      newWord = {
        word: lexiconData.form_data.forms.lemma || requestWord.newWord,
        lexiconData: lexiconData,
      };
    } else {
      newWord = requestWord.newWord;
    }
    const alwaysTranslate = customer?.config.always_translate;
    const language = customer?.config.tag_input_language;
    const shouldTranslate =
      alwaysTranslate || translateType === RequestWordTranslateType.TRANSLATE;
    const shouldMachineTranslate =
      !alwaysTranslate &&
      translateType === RequestWordTranslateType.MACHINE_TRANSLATE;
    newRequestWord(
      newWord,
      modalEnglishInputValue,
      wordTagCategory,
      wordTagType,
      shouldTranslate,
      shouldMachineTranslate,
      shouldPopulate,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      modalInputValue,
      language,
      token,
      textualAppName,
      (hypernymData || {}).vocabulary_id
    ).then((response: AxiosResponse) => {
      addNewRequestWord(response);
      store.dispatch(
        setDjangoToastOpen({
          content: RequestWordTranslateTypeToastMessage[translateType],
          appearance: NotificationAppearance.SUCCESS,
        })
      );
    });
    closeRequestModal();
  };

  const closeRequestModal = (): void => {
    const { requestWord } = requestModalData;
    setRequestModalState({
      modalEnglishInputValue: "",
      modalInputValue: "",
      requestWord: {
        ...requestWord,
        openRequestModal: false,
        newWord: "",
      },
      shouldPopulate: false,
      wordType: "",
    });
  };

  const onWordTypeChange = (data: WordType | null): void => {
    setRequestModalData({ ...requestModalData, wordType: data?.value ?? "" });
  };

  const setUsedInTagTreeEditor = (): boolean => {
    const { requestWord } = requestModalData;
    return requestWord.selectType !== "productCategorySelect";
  };

  const onAddCopiedVocabulary = (data: VocabularyTreeData): void => {
    const mockAxiosResponse: AxiosResponse<VocabularyTreeData> = {
      data: data,
      status: 200,
      statusText: "",
      headers: {},
      config: { headers: new AxiosHeaders() },
    };
    addNewRequestWord(mockAxiosResponse);
  };

  const {
    disallowKind,
    modalEnglishInputValue,
    modalInputValue,
    requestWord,
    shouldPopulate,
    wordType,
  } = requestModalData;

  const getAllowTags = (): AllowTagType => {
    if (disallowKind) return AllowTagType.NON_KIND_MATERIAL;
    if (allowMaterialKindTagsOnly) return AllowTagType.KIND_MATERIAL;
    return AllowTagType.ALL;
  };

  const usedInTagTreeEditor = setUsedInTagTreeEditor();
  const isOpenRequestModal = requestWord.openRequestModal;
  let displayHelperText = true;

  return (
    <>
      <ProductTagsEditor
        advancedTemplateSearch={true}
        handleRequestWord={onEditorHandleRequestWord}
        limit={limit}
        mapEnumNumberNames={onEditorMapEnumNumberNames}
        onBrandSelected={onEditorBrandSelected}
        onChildAdded={onEditorChildAdded}
        onDataChanged={onEditorDataChanged}
        onNameSelected={onEditorNameSelected}
        parentId={parseInt(treeData[0]?.id)}
        productId={vocabularyRequestProductId}
        textualAppName={textualAppName}
        treeData={treeData}
      />
      {isOpenRequestModal && requestWord.newWord && (
        <RequestWordModal
          allowTags={getAllowTags()}
          alwaysTranslate={customer?.config.always_translate}
          attributeErrorSiteDistinction={true}
          closeModal={closeRequestModal}
          customerLanguage={customer?.config.tag_input_language}
          description={modalInputValue}
          englishTranslation={modalEnglishInputValue}
          isOpenModal={isOpenRequestModal}
          isUsedInTagTreeEditor={usedInTagTreeEditor}
          lexiconEditorDirection={customer?.config.tag_input_language_direction}
          onDescriptionInputChange={(value): void => {
            setRequestModalState({ modalInputValue: value });
          }}
          onEnglishTranslationInputChange={(value): void => {
            setRequestModalState({ modalEnglishInputValue: value });
          }}
          onHypernymChange={onHypernymChange}
          onLexiconDataChange={onLexiconDataChange}
          onShouldPopulateChange={onShouldPopulateChange}
          onWordTypeChange={onWordTypeChange}
          populateTranslations={true}
          providedNewWord={requestWord.newWord}
          providedWordType={wordType}
          shouldPopulate={shouldPopulate}
          showHypernym={customer?.config.request_word_show_hypernym}
          showTranslate={customer?.isPayingCustomer}
          showMachineTranslate={
            customer?.config.show_machine_translate_in_request_word
          }
          submitAction={requestNewWord}
          textualAppName={textualAppName}
          useLexiconEditor={customer?.config.use_lexicon_editor}
          onAddCopiedVocabulary={onAddCopiedVocabulary}
        />
      )}

      <StyledWarning
        className="info"
        data-balloon-break=""
        data-balloon-pos="up"
        data-balloon={
          "Use Tab and Shift+Tab to switch between fields.\nUse Shift+Enter to add a sub attribute.\nUse Shift+Backspace to remove a sub attribute."
        }
      >
        <Icon name="question circle" className={"info"} />
        Keyboard shortcuts
      </StyledWarning>
      {treeData[0]?.children?.map(({ master_display_name, children }) => {
        if (
          displayHelperText &&
          (!master_display_name ||
            children?.some(({ master_display_name }) => !master_display_name))
        ) {
          displayHelperText = false;
          return (
            <StyledWarning key={"warning"} className={"info"}>
              <Icon name="warning circle" />
              Please fill in or remove the empty attribute to generate the text.
            </StyledWarning>
          );
        }
      })}
    </>
  );
};
