import React from "react";
import { List } from "semantic-ui-react";

type sampleCondition = {
  additionalCode?: string;
  additionalText?: string;
  code: string;
  text: string;
};

export const customQuerySampleConditions: sampleCondition[] = [
  {
    code: "status is importing",
    text: "Products that are being imported",
  },
  {
    code: 'status is "in progress"',
    text: "Products in progress",
  },
  {
    code: "status is ready",
    text: "Products ready",
  },
  {
    code: 'created after "2 weeks ago"',
    text: "Products created in the last 2 weeks",
  },
  {
    code: 'updated after "2019-09-02" and updated before "2019-09-10"',
    text: "Products updated between the 2nd and the 10th of September",
  },
  {
    code: "tags has dress and status is ready",
    text: "Dresses that are ready",
  },
  {
    code: "tags has dress and tags has black",
    text: "Black dresses",
  },
  {
    code: "tags.ids has 51",
    text: "Products that use the vocabulary with id 51",
  },
  {
    code: 'external_id is "1234"',
    text: "SKU is 1234",
  },
  {
    code: "external_id in [1234, 5678]",
    text: "SKU is either 1234 or 5678",
    additionalText:
      "If the SKU is ONLY number you can leave out the citation marks.",
  },
  {
    code: "parent_id is set",
    text: "Product has a parent",
  },
  {
    code: 'brand in ["Textual", "Test Brand"]',
    text: "Brand is either Textual or Test Brand",
  },
  {
    code: 'templates has "Template 1"',
    text: 'Products that use the template with name "Template 1"',
  },
  {
    code: 'status is "in progress" and original_text_en_us has "NA-KD"',
    text:
      'Products in progress whose original text in English (US) contains "NA-KD"',
  },
  {
    code: 'product_labels has "test"',
    text: 'Products with the label "test"',
  },
  {
    code: 'product_labels hasnot "white"',
    text: 'Products without the label "white"',
  },
  {
    code: "product_labels hasnot 'test-batch' and status is ready",
    text: "Products without the label test-batch that are ready",
  },
  {
    code: "texts.nl_NL.failed is true and texts.nl_NL.approved is unset",
    text:
      "Products with Dutch texts successfully generated but pending approval",
  },
  {
    additionalCode: "texts.en_US.NNN.created is set",
    code: "NNN = text pattern ID",
    text: "Product texts generated in English in a specific text pattern",
  },
  {
    code: "texts.en_US.created is unset",
    text: "Products that have NOT been generated in English",
  },
  {
    code: "texts.en_US.failed is true",
    text: "Products that failed generation in English",
  },
  {
    code: "texts.en_US.needs_review is true",
    text: "Product texts generated in English (US) that needs review",
  },
  {
    code: "texts.en_US.missing_translation is true",
    text:
      "Product texts generated in English (US) that has missing translation",
  },
  {
    code: "texts.en_US.123.published is set",
    text: "Products that are published in English (US) on channel 123",
  },
  {
    code: 'texts.en_US.123.text has "test"',
    text: 'Products whose text in English (US) on channel 123 contains "test"',
  },
  {
    code: "texts.en_US.123.marked_for_publish is true",
    text: "Products that are marked for publish in English (US) on channel 123",
  },
  {
    code: "texts.en_US.123.marked_for_publish is false",
    text:
      "Products that are not marked for publish in English (US) on channel 123",
  },
  {
    code: "texts.en_US.123.waiting_to_be_published is true",
    text:
      "Products texts that are waiting to be published in English (US) on channel 123",
  },
  {
    code: "texts.en_US.123.is_edited is true",
    text:
      "Products that text is manually edited by user in English (US) on channel 123",
  },
  {
    code: "texts.en_US.123.is_edited is false",
    text:
      "Products that text is NOT manually edited by user in English (US) on channel 123",
  },
  {
    code:
      "texts.en_US.123.first_published is set and texts.en_US.123.published is unset",
    text:
      "Products that a text has been previously published in English (US) on channel 123 and later unpublished",
  },
];

export const CustomQuerySampleConditions: React.FC = () => {
  const renderListItem = (sample: sampleCondition): JSX.Element => (
    <List.Item
      key={sample.code}
      className={"samples"}
      data-testid={"sample-conditions-item"}
    >
      <List.Content>
        <List.Header>{sample.text}</List.Header>
        <List.Description>
          {sample.additionalText}
          <code>{sample.code}</code>
          {sample?.additionalCode && <code>{sample.additionalCode}</code>}
        </List.Description>
      </List.Content>
    </List.Item>
  );
  return (
    <div className={"sample-conditions"} data-testid={"sample-conditions"}>
      <h2>Sample conditions</h2>
      <List bulleted>
        {customQuerySampleConditions.map((sample) => renderListItem(sample))}
      </List>
    </div>
  );
};
