import { FullTag } from "../../utils/tagutils";

export interface SEORecommendation {
  competition: string;
  id: number;
  name: string;
  search_volume: number;
  vocabulary_id: number;
}

export interface FieldRecommendation {
  // ie. 213
  count: number;
  // ie. Properties
  headline?: string;
  // ie. belt tie
  helper_display_name?: string;
  // ie. knytskärp
  master_display_name: string;
  // ie. belt_tie_4231
  value: string;
  // ie. kind
  vocab_type?: string;
  // ie. 4231
  vocabulary_id: number;
  // ie. 4700
  id?: number;
  // ie. 4700
  seo_scores_volume?: string;
  tags: FullTag[];
}

export interface ProductRecommendations {
  fieldRecommendations: Record<string, FieldRecommendation[]>;
  seoRecommendations: SEORecommendation[];
  subtreeRecommendations: FieldRecommendation[];
  subpartRecommendations: FieldRecommendation[];
}

export const emptyProductRecommendations = (): ProductRecommendations => ({
  fieldRecommendations: {},
  subpartRecommendations: [],
  subtreeRecommendations: [],
  seoRecommendations: [],
});
