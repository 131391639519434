import { TextParagraphSkeletonProps } from "./CommonSkeletons";

export function randomNumber(max: number): number {
  return Math.floor(Math.random() * max);
}

export const texts = ["a".repeat(31), "a".repeat(28), "a".repeat(24)]; // These are used to give the loading animation a representative height and length of a paragraph
export const textsLength = texts.length;

export const headers = ["a".repeat(11), "a".repeat(20), "a".repeat(7)]; // These are used to give the loading animation a representative height and length of a header
export const headersLength = headers.length;

export const channels = ["a".repeat(7), "a".repeat(3), "a".repeat(5)]; // These are used to give the loading animation a representative height and length of a channel name
export const channelLength = channels.length;

export function buildParagraph(max: number): TextParagraphSkeletonProps[] {
  const amount = randomNumber(max) || 1; // randomNumber can return 0
  const paragraphs: TextParagraphSkeletonProps[] = [];
  Array.from(Array(amount).keys()).forEach(() => {
    paragraphs.push({ content: texts[randomNumber(textsLength)] });
  });
  return paragraphs;
}
