import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Prompt, PromptFormMode, SentenceSectionInfo } from "../types";
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Icon,
  Loader,
  Modal,
  Popup,
  Ref,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import { useGetCustomerQuery } from "../../../api/customerApi";
import {
  buildSentence,
  findLanguage,
  getCustomerEnglishLanguage,
  getSectionContext,
  preventOverflowOnTextarea,
  updateSentenceValueFactory,
} from "../utils";
import { Text } from "../../../components/Text";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
import {
  createProductSpecificOverwrite,
  getModels,
  getPromptInputText,
  gptAddSentences,
  GptGenerationTaskQueue,
  updatePrompt,
} from "../../../api/gptApi";
import { ProductId } from "../../product";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import { PromptSuggestionsComponent } from "../PromptSuggestionsComponent";
import { PromptForm } from "../PromptForm";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";

import { selectedPromptTestIds } from "../testUtils/testIdsSelectors";
import { FieldsetSelection } from "./FieldsetSelection";
import { debounce } from "../../../utils/debounce";
import { ImageUrlInput } from "./ImageUrlInput";
import { useGetDocumentStructures } from "../../../planner/document-structure/manage/customhooks";
import { reloadPreview } from "../../../legacy/t.product-detail";
import { FlexDivTwoItems } from "./SelectedPromptGroupPromptItem";
import { GroupedGenerationButtons, RevertButton } from "./Components/Buttons";
import { getFeatureFlag } from "../../../utils/featureFlags";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import { LangStringObject } from "../../../vocabulary/LangString";

export const explainInstruction = {
  header: "The instruction tells the model how to generate text",
  content:
    "Example: Write a category page text for fashion with focus on getting the best possible SEO. Repeat the category 10-14 times in the text. The text should be around 300 words.",
};
export const explainInputText = {
  header: "The prompt data tells the model what to write about",
  content:
    "Mostly these are fetched from the product data but you can change it here to tailor to your generation needs.",
};

export const exampleInstruction = "Write a product description";

export const examplePromptData = "Blue jeans";
type Props = {
  productId: ProductId;
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  refreshPrompts: () => Promise<void>;
  useChannelAsDefaultFallback: boolean;
};
export const SelectedPrompt: React.FC<Props> = ({
  productId,
  gptGenerationTaskQueue,
  refreshPrompts,
  useChannelAsDefaultFallback,
}) => {
  const mounted = useRef(false);
  const {
    prompts: {
      selected: selectedPrompt,
      availableTags: { customerOwned },
    },
    groups: { selected: selectedGroup },
    sentences: { list: allSentences },
    inputData: { fetchNew: fetchNewInputData },
    productSpecificOverwrites: {
      list: allProductSpecificOverwrites,
      isLoading: productSpecificOverwriteLoading,
    },
  } = useContext(CopyAssistantContext);

  const dispatch = useContext(CopyAssistantDispatchContext);
  const { data: customer } = useGetCustomerQuery();
  const { data: structures } = useGetDocumentStructures();
  const token = useSelector((state: RootState) => state.auth.token);
  const [alteredInstruction, setAlteredInstruction] = useState<string>();
  const [alteredInputText, setAlteredInputText] = useState<string>();
  const [inputText, setInputText] = useState<string>();
  const [fieldsetText, setFieldsetText] = useState<string>();
  const [fetchingInputText, setFetchingInputText] = useState(false);

  const [imageUrl, setImageUrl] = useState<string>("");
  const [showImageUrlInput, setShowImageUrlInput] = useState<boolean>(false);

  const [formMode, setFormMode] = useState<PromptFormMode>(
    PromptFormMode.UPDATE
  );
  const [modalOpen, setModalOpen] = useState(false);
  const language = findLanguage(selectedPrompt, customer);
  const visible = useMemo(() => !selectedGroup, [selectedGroup]);

  const generateButtonsIsLoading = useMemo(() => {
    return allSentences.some(
      (sentence) => sentence.isLoading || sentence.isStreaming
    );
  }, [allSentences]);

  const productSpecificOverwrite = useMemo(() => {
    return allProductSpecificOverwrites.find(
      ({ prompt_id }) => prompt_id === selectedPrompt?.id
    );
  }, [allProductSpecificOverwrites, selectedPrompt]);

  // This run only once on initial render of the component
  const gptModels = useMemo(async () => {
    try {
      const models = await getModels(token);
      return models;
    } catch {
      return [];
    }
  }, [token]);

  useEffect(() => {
    let canceled = false;
    (async (): Promise<void> => {
      if (!selectedPrompt) setShowImageUrlInput(false);
      const awaitedGptModels = await gptModels;
      if (canceled) return;
      if (!awaitedGptModels.length) setShowImageUrlInput(false);
      const model = awaitedGptModels.find(
        (model) => model.model_name === selectedPrompt?.model_name
      );
      setShowImageUrlInput(model?.supports_images ?? false);
    })();

    return (): void => {
      canceled = true;
    };
  }, [selectedPrompt, gptModels]);

  const viewChannelTextarea = useMemo(() => {
    let result = useChannelAsDefaultFallback;
    if (selectedPrompt?.channel_id) {
      result = true;
    }
    if (selectedPrompt?.fieldset_id) {
      result = false;
    }
    if (!result) {
      setFetchingInputText(false);
    }
    return result;
  }, [useChannelAsDefaultFallback, selectedPrompt]);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (productSpecificOverwrite) {
      setAlteredInputText(productSpecificOverwrite?.input_data);
      setImageUrl(productSpecificOverwrite?.image_url || "");
    }
  }, [productSpecificOverwrite]);

  useEffect(() => {
    let canceled = false;
    if (fetchNewInputData && visible && viewChannelTextarea) {
      (async (): Promise<void> => {
        setInputText(undefined);
        setFetchingInputText(true);
        const { text } = await getPromptInputText(
          token,
          productId,
          selectedPrompt?.id
        );
        if (mounted.current && !canceled) {
          setInputText(text);
          setFetchingInputText(false);
          dispatch({
            type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
            payload: false,
          });
        }
      })();
    }
    return (): void => {
      canceled = true;
    };
  }, [selectedPrompt, fetchNewInputData, viewChannelTextarea]);

  useEffect(() => {
    if (selectedPrompt) {
      setAlteredInstruction(undefined);
    }
    if (!selectedPrompt) {
      if (!alteredInstruction) {
        setAlteredInstruction(exampleInstruction);
      }
    }
  }, [selectedPrompt]);

  useEffect(() => {
    if (!fetchNewInputData) {
      if (!selectedPrompt && !alteredInputText) {
        setInputText(examplePromptData);
      }
    }
  }, [fetchNewInputData, inputText]);

  const textareaRefs = useRef<HTMLTextAreaElement[]>([]);
  useLayoutEffect(() => {
    textareaRefs.current.forEach((textarea) => {
      preventOverflowOnTextarea(textarea);
    });
  });

  const saveManualInputData = useCallback(
    debounce(async (inputData: string): Promise<void> => {
      await createProductSpecificOverwrite(token, {
        product_id: productId,
        input_data: inputData,
        prompt_id: selectedPrompt.id,
      }).then((overwrite) => {
        if (mounted.current) {
          setAlteredInputText(inputData);
          dispatch({
            type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
            payload: overwrite,
          });
        }
      });
    }, 500),
    [selectedPrompt]
  );

  const saveManualImageUrl = useCallback(
    debounce(async (imageUrl: string): Promise<void> => {
      await createProductSpecificOverwrite(token, {
        product_id: productId,
        image_url: imageUrl,
        prompt_id: selectedPrompt.id,
      }).then((overwrite) => {
        if (mounted.current)
          dispatch({
            type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
            payload: overwrite,
          });
      });
    }, 500),
    [selectedPrompt]
  );

  const onAddSuggestion = (text: string): void => {
    const instruction = alteredInstruction ?? selectedPrompt?.instruction;
    let suggestion = text;
    if (instruction) {
      suggestion = `\n${text}`;
    }
    setAlteredInstruction((instruction ?? "") + suggestion);
  };

  const handleInstructionRevert = (): void => {
    setAlteredInstruction(undefined);
  };
  const handleInstructionChange = (value: string): void => {
    if (value === selectedPrompt?.instruction) {
      handleInstructionRevert();
    } else {
      setAlteredInstruction(value);
    }
  };
  const handleInputTextRevert = (): void => {
    createProductSpecificOverwrite(token, {
      product_id: productId,
      input_data: "",
      prompt_id: selectedPrompt.id,
    }).then((overwrite) => {
      if (mounted.current)
        dispatch({
          type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
          payload: overwrite,
        });
    });
    if (mounted.current) setAlteredInputText(undefined);
  };
  const handleInputTextChange = (value: string): void => {
    if (value === inputText) {
      handleInputTextRevert();
    } else {
      setAlteredInputText(value);
      saveManualInputData(value);
    }
  };

  const handleShowForm = (mode: PromptFormMode): void => {
    setFormMode(mode);
    setModalOpen(true);
  };

  const onRefreshPrompts = async (): Promise<void> => {
    dispatch({
      type: CopyAssistantActionType.SET_PROMPT_LOADING,
      payload: true,
    });
    await refreshPrompts();
    dispatch({
      type: CopyAssistantActionType.SET_PROMPT_LOADING,
      payload: false,
    });
  };

  const disabledGenerateButton = useMemo((): boolean => {
    if (
      (!selectedPrompt && fieldsetText) ||
      (!selectedPrompt?.channel_id && fieldsetText)
    ) {
      return false;
    }
    if (inputText && alteredInputText === "") {
      return true;
    }

    if (selectedPrompt) {
      return (!inputText && !alteredInputText) || alteredInstruction === "";
    }

    return !alteredInstruction || (!inputText && !alteredInputText);
  }, [
    selectedPrompt,
    fieldsetText,
    inputText,
    alteredInputText,
    alteredInstruction,
  ]);

  const setLoadingState = (loading: boolean, id?: string): void => {
    if (id) {
      dispatch({
        type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
        payload: { id, isLoading: loading },
      });
    }
    dispatch({
      type: CopyAssistantActionType.SET_SENTENCE_LOADING,
      payload: { loading, promptIds: [selectedPrompt?.id] },
    });
  };

  const handleGenerateAndAddToProduct = async (
    preloadedSectionInfo?: SentenceSectionInfo,
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    const textToUse = viewChannelTextarea
      ? alteredInputText ?? inputText
      : fieldsetText;
    const newSentence = buildSentence(
      [],
      {
        inputText: textToUse,
        instruction: alteredInstruction,
        promptId: selectedPrompt?.id,
      },
      customer,
      structures,
      language.code
    );
    newSentence.isLoading = true;
    if (preloadedSectionInfo) {
      newSentence.sectionInfo = preloadedSectionInfo;
    }

    dispatch({
      type: CopyAssistantActionType.ADD_SENTENCE,
      payload: newSentence,
    });

    const updateSentenceCallback = (value: string): void => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_SENTENCE,
        payload: {
          id: newSentence.id,
          toUpdate: { value: { [language.code]: value }, isStreaming: true },
        },
      });
    };

    const { awaitableTimeouts, updateResult } = updateSentenceValueFactory(
      language.code,
      updateSentenceCallback
    );

    const {
      generated_texts: langStringValues,
      seconds_spent_generating,
    } = await gptGenerationTaskQueue
      .generateOne(
        productId,
        textToUse,
        selectedPrompt?.id,
        alteredInstruction,
        imageUrl,
        getFeatureFlag(customer, "enable_copy_assistant_stream_gpt_generation"),
        updateResult
      )
      .catch(() => {
        dispatch({
          type: CopyAssistantActionType.REMOVE_SENTENCE,
          payload: newSentence.id,
        });
        return {
          generated_texts: [] as LangStringObject[],
          seconds_spent_generating: 0,
        };
      });

    await Promise.all(awaitableTimeouts);

    if (!langStringValues.length) {
      dispatch({
        type: CopyAssistantActionType.REMOVE_SENTENCE,
        payload: newSentence.id,
      });
      return;
    }

    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.INFO,
        content: `Generation completed in ${seconds_spent_generating} seconds`,
      })
    );
    const data = await gptAddSentences(
      productId,
      langStringValues[0],
      token,
      selectedPrompt?.id
    );
    const sectionContext = getSectionContext(data, customer, structures);

    dispatch({
      type: CopyAssistantActionType.UPDATE_SENTENCE,
      payload: {
        id: newSentence.id,
        toUpdate: {
          ...sectionContext,
          isStreaming: false,
          isLoading: false,
          value: langStringValues[0],
        },
      },
    });
    if (sectionContext.sectionInfo) {
      dispatch({
        type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
        payload: sectionContext.sectionInfo.documentSectionName,
      });
    }

    dispatch({
      type: CopyAssistantActionType.SET_SECTION_ACTIVE,
      payload: { id: newSentence.id },
    });

    if (autoTranslateAfterGeneration) {
      dispatch({
        type: CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST,
        payload: {
          id: newSentence.id,
          mtConfigGroupId,
        },
      });
    }
  };
  const onGenerate = async (
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    const foundSentence = allSentences.find(
      (sentence) =>
        sentence?.sectionInfo?.documentSectionId ===
        selectedPrompt?.document_section_id
    );
    setLoadingState(true, foundSentence?.id);
    let preloadSectionInfo: SentenceSectionInfo;
    if (foundSentence?.sectionInfo) {
      preloadSectionInfo = foundSentence.sectionInfo;
      dispatch({
        type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
        payload: foundSentence.sectionInfo.documentSectionName,
      });
    }
    await handleGenerateAndAddToProduct(
      preloadSectionInfo,
      autoTranslateAfterGeneration,
      mtConfigGroupId
    ).finally(() => {
      setLoadingState(false, foundSentence?.id);
      if (!autoTranslateAfterGeneration) {
        reloadPreview(
          foundSentence?.sectionInfo?.connectedChannels.map(({ id }) => id),
          selectedPrompt?.language || getCustomerEnglishLanguage(customer)?.code
        );
      }
      dispatch({
        type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
        payload: true,
      });
    });
  };

  const collectFieldsetString = (str: string): void => {
    setFieldsetText(str);
  };

  const selectPrompt = (prompt: Prompt): void => {
    if (mounted.current)
      dispatch({
        type: CopyAssistantActionType.SELECT_PROMPT,
        payload: prompt,
      });
  };

  const handleUpdatePrompt = async (): Promise<void> => {
    await updatePrompt(token, {
      ...selectedPrompt,
      instruction: alteredInstruction,
    }).finally(async () => {
      await onRefreshPrompts();
    });
  };

  if (!visible) {
    return <></>;
  }
  return (
    <>
      <Form as="div" size="tiny">
        <Form.Field>
          {!selectedPrompt && (
            <Popup
              flowing
              size="small"
              content="Save the prompt to be able to reuse it at any time."
              trigger={
                <Button
                  data-testid={selectedPromptTestIds.buttons.saveNew}
                  color="red"
                  disabled={!alteredInstruction}
                  size="mini"
                  floated="right"
                  basic
                  compact
                  content="Save prompt"
                  onClick={(): void => handleShowForm(PromptFormMode.CREATE)}
                />
              }
            />
          )}

          {selectedPrompt?.tags.map((tag) => (
            <TemplateLabelChip
              data-testid={selectedPromptTestIds.tag(tag)}
              classNames="fitted-template-label"
              skipPredefinedClasses
              key={tag}
              content={tag}
              description={""}
              labelProps={{
                as: "span",
                size: "tiny",
              }}
            />
          ))}
        </Form.Field>
        <Form.Field>
          <FlexDivTwoItems>
            <span>
              <Popup
                wide="very"
                header={explainInstruction.header}
                content={explainInstruction.content}
                trigger={
                  <label
                    style={{ display: "inline-block" }}
                    data-testid={selectedPromptTestIds.instruction.label}
                  >
                    <Icon name="question circle" /> Instruction:
                  </label>
                }
                size="tiny"
                hoverable
              />{" "}
              <Text
                color="grey"
                className="descriptive-helper-text"
                compact
                inline
              >
                how to write
              </Text>
            </span>
            {selectedPrompt && (
              <span>
                {alteredInstruction && selectedPrompt?.customer_id && (
                  <Popup
                    content="Save changed instruction to Prompt"
                    wide
                    size="small"
                    trigger={
                      <Button
                        content="Save changes"
                        onClick={handleUpdatePrompt}
                        size="mini"
                        basic
                        color="red"
                        compact
                      />
                    }
                  />
                )}
                {alteredInstruction !== undefined && (
                  <RevertButton
                    data-testid={selectedPromptTestIds.instruction.revert}
                    onRevert={handleInstructionRevert}
                  />
                )}
              </span>
            )}
          </FlexDivTwoItems>
          <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
            <TextArea
              data-testid={selectedPromptTestIds.instruction.textarea}
              value={alteredInstruction ?? selectedPrompt?.instruction}
              onChange={(
                _: React.FormEvent<HTMLTextAreaElement>,
                { value }: TextAreaProps
              ): void => handleInstructionChange(value as string)}
            />
          </Ref>
          <PromptSuggestionsComponent
            onAddSuggestion={onAddSuggestion}
            language={language?.code}
          />
        </Form.Field>
        <div>
          <Form.Field>
            <FlexDivTwoItems>
              <span>
                <Popup
                  header={explainInputText.header}
                  content={explainInputText.content}
                  trigger={
                    <label
                      style={{ display: "inline-block" }}
                      data-testid={selectedPromptTestIds.data.label}
                    >
                      <Icon name="question circle" /> Data:
                    </label>
                  }
                  size="tiny"
                  wide="very"
                  hoverable
                />{" "}
                <Text
                  color="grey"
                  className="descriptive-helper-text"
                  compact
                  inline
                >
                  what to write about
                </Text>
              </span>
              {(selectedPrompt || productSpecificOverwrite?.input_data) &&
                alteredInputText !== undefined && (
                  <RevertButton
                    onRevert={handleInputTextRevert}
                    data-testid={selectedPromptTestIds.data.revert}
                  />
                )}
            </FlexDivTwoItems>
            {viewChannelTextarea ? (
              <Ref innerRef={(el): number => textareaRefs.current.push(el)}>
                <TextArea
                  data-testid={selectedPromptTestIds.data.textarea}
                  value={alteredInputText ?? inputText}
                  onChange={(
                    _: React.FormEvent<HTMLTextAreaElement>,
                    { value }: TextAreaProps
                  ): void => handleInputTextChange(value as string)}
                />
              </Ref>
            ) : (
              <FieldsetSelection
                key={selectedPrompt?.fieldset_id}
                alteredInputText={alteredInputText}
                activeFieldsetId={selectedPrompt?.fieldset_id}
                collectString={collectFieldsetString}
                saveManualInputData={saveManualInputData}
                language={selectedPrompt?.language}
              />
            )}
            <Dimmer
              active={fetchingInputText || productSpecificOverwriteLoading}
              inverted
              data-testid={selectedPromptTestIds.data.dimmer}
            >
              <Loader
                active={fetchingInputText || productSpecificOverwriteLoading}
                size="tiny"
                data-testid={selectedPromptTestIds.data.loading}
              >
                Collecting Data...
              </Loader>
            </Dimmer>
          </Form.Field>
          {showImageUrlInput && !productSpecificOverwriteLoading && (
            <ImageUrlInput
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              productId={productId}
              saveManualImageUrl={saveManualImageUrl}
              skipProductImage={!selectedPrompt?.include_documents_image}
            />
          )}
          <Divider />
          <div className="tw-sticky tw-bottom-2 tw-z-50 tw-flex tw-justify-center tw-gap-2">
            <GroupedGenerationButtons
              variant="primary"
              loading={generateButtonsIsLoading}
              disabled={disabledGenerateButton}
              onGenerate={onGenerate}
              generateTestId={selectedPromptTestIds.buttons.generate}
              generateAndTranslateTestId={
                selectedPromptTestIds.buttons.generateAndTranslate
              }
            />
          </div>
        </div>
      </Form>
      <Modal open={modalOpen} onClose={(): void => setModalOpen(false)}>
        <PromptForm
          mode={formMode}
          setOpenModal={setModalOpen}
          refreshPromptList={onRefreshPrompts}
          prompt={selectedPrompt ?? { instruction: alteredInstruction }}
          availableTags={customerOwned}
          selectPrompt={selectPrompt}
        />
      </Modal>
    </>
  );
};
