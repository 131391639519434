import { FullTag } from "../../utils/tagutils";
import { ColumnValue } from "./columns/Columns";
import { LanguageCode } from "../../customers/customerlanguages";
import { ChannelId } from "../../customers/Customer";
import {
  DateCategory,
  PageSizeStatus,
  ParentChildStatus,
  ProductStatusFilter,
  TextStatus,
  TranslationStatus,
  ItemsWithOperator,
} from "./ProductListTypes";

// This type corresponds to `EncodedChannelLanguageDict` in backend
export interface ChannelLanguagePair {
  channel_id: ChannelId;
  language_code: LanguageCode;
}

export type ProductListParams = {
  action?: string;
  columns?: ColumnValue[];
  brands?: string[];
  bucket: string;
  date_category?: "created" | "published";
  date_start?: string;
  date_end?: string;
  filter_selection_bucket?: string;
  group_children?: boolean;
  label?: ItemsWithOperator;
  channel_language_pairs: ChannelLanguagePair[];
  mode?: string;
  original_language?: string;
  page?: string;
  pagination_size?: number;
  parent_child?: string;
  reference?: string[];
  query?: string;
  search?: string;
  status?: string;
  template?: string;
  text_status?: string;
  translation_status?: string;
  view?: number;
  viewset?: number;
  vocabulary?: string;
};

/*
 * This parses configuration from the URL which is used to render the
 * product page
 */
export class URLConfigParser {
  urlParams: URLSearchParams;
  params: { [key: string]: any } = {};
  channel_language_pairs: ChannelLanguagePair[];
  searchParams: ProductListParams;

  constructor() {
    this.loadFromLocation();
  }

  // Get all parameters that will be sent to a product search
  getSearchParams(): ProductListParams {
    const {
      brands,
      bucket,
      date_category,
      date_start,
      date_end,
      filter_selection_bucket,
      group_children,
      label,
      label_op,
      original_language,
      pagination_size,
      parent_child,
      page,
      query,
      search,
      status,
      template,
      text_status,
      translation_status,
      vocabulary,
    } = this.params;
    const params: ProductListParams = {
      bucket,
      channel_language_pairs: this.channel_language_pairs,
      pagination_size:
        parseInt(pagination_size) || parseInt(PageSizeStatus.Five),
    };
    if (brands) {
      params["brands"] = brands;
    }
    if (date_category) {
      params["date_category"] = date_category;
    }
    if (date_start) {
      params["date_start"] = date_start;
    }
    if (date_end) {
      params["date_end"] = date_end;
    }
    if (filter_selection_bucket) {
      params["filter_selection_bucket"] = filter_selection_bucket;
    }
    if (group_children) {
      params["group_children"] = group_children === "on";
    }
    if (label) {
      params["label"] = {
        items: JSON.parse(label),
        operator: label_op,
      };
    }
    if (original_language) {
      params["original_language"] = original_language;
    }
    if (parent_child) {
      params["parent_child"] = parent_child;
    }
    if (page) {
      params["page"] = page;
    }
    if (query) {
      params["query"] = query;
    }
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (template) {
      params["template"] = template;
    }
    if (text_status) {
      params["text_status"] = text_status;
    }
    if (translation_status) {
      params["translation_status"] = translation_status;
    }
    if (vocabulary) {
      params["vocabulary"] = vocabulary;
    }
    return params;
  }

  getViewParams(): ProductListParams {
    const params = this.getSearchParams();
    const { columns, mode, reference } = this.params;
    if (columns) {
      params["columns"] = columns;
    }
    if (reference) {
      params["reference"] = reference;
    }
    if (mode) {
      params["mode"] = mode;
    }
    return params;
  }

  resetParams(channelLanguagePair: ChannelLanguagePair): void {
    const { bucket } = this.searchParams;
    this.urlParams = new URLSearchParams();
    this.urlParams.set("bucket", bucket);
    this.urlParams.set("language", channelLanguagePair.language_code);
    this.urlParams.set("channel", `${channelLanguagePair.channel_id}`);
  }

  /*
   * This fetches the selected text columns from the URL
   * first one is language/channel params
   * second one is language_2/channel_2 params
   * and so on
   */
  parseQueryChannelLanguagePairs(
    params: Record<string, any>
  ): ChannelLanguagePair[] {
    let channelString = params["channel"];
    let language = params["language"];
    let channel;
    const pairs = [];
    // FIXME: Make sure the backend does not send 'None'
    if (
      channelString !== undefined &&
      channelString !== "None" &&
      channelString !== "" &&
      language !== undefined &&
      language !== "None" &&
      language !== ""
    ) {
      channel = parseInt(channelString);
      if (isNaN(channel)) {
        throw Error(
          'Failed to parse "channel" parameter, must be an int, ' +
            `got: "${params["channel"]}".`
        );
      }
      pairs.push({ channel_id: channel, language_code: params["language"] });
    }
    let i = 1;
    while (i++) {
      const channelParam = `channel_${i}`;
      channelString = params[channelParam];
      language = params[`language_${i}`];
      if (channelString === undefined || language === undefined) {
        break;
      }
      channel = parseInt(channelString);
      if (isNaN(channel)) {
        throw Error(
          `Failed to parse "${channelParam}" parameter, must be an int, ` +
            `got: "${channelString}".`
        );
      }
      pairs.push({ channel_id: channel, language_code: language });
    }
    return pairs;
  }

  setChannelLanguagePairs(channelLanguagePairs: ChannelLanguagePair[]): void {
    this.urlParams.set("channel", `${channelLanguagePairs[0].channel_id}`);
    this.urlParams.set("language", channelLanguagePairs[0].language_code);
    for (let i = 1; i < channelLanguagePairs.length; i++) {
      this.urlParams.set(
        `channel_${i + 1}`,
        `${channelLanguagePairs[i].channel_id}`
      );
      this.urlParams.set(
        `language_${i + 1}`,
        `${channelLanguagePairs[i].language_code}`
      );
    }
    let j = channelLanguagePairs.length;
    while (j++) {
      const channelParam = this.urlParams.get(`channel_${j}`);
      const languageParam = this.urlParams.get(`language_${j}`);
      if (channelParam === null && languageParam === null) {
        break;
      }
      if (channelParam) {
        this.urlParams.delete(`channel_${j}`);
      }
      if (languageParam) {
        this.urlParams.delete(`language_${j}`);
      }
    }
  }

  getProductStatusFilter(): ProductStatusFilter {
    return (this.urlParams.get("status") ||
      ProductStatusFilter.All) as ProductStatusFilter;
  }

  setProductStatusFilter(value: ProductStatusFilter): void {
    this.urlParams.set("status", value);
  }

  getTextStatus(): TextStatus {
    return (this.urlParams.get("text_status") || TextStatus.All) as TextStatus;
  }

  setTextStatus(value: TextStatus): void {
    this.urlParams.set("text_status", value);
  }

  getTranslationStatus(): TranslationStatus {
    return (this.urlParams.get("translation_status") ||
      TranslationStatus.All) as TranslationStatus;
  }

  setTranslationStatus(value: TranslationStatus): void {
    this.urlParams.set("translation_status", value);
  }

  getParentChildStatus(): ParentChildStatus {
    return (this.urlParams.get("parent_child") ||
      ParentChildStatus.All) as ParentChildStatus;
  }

  setParentChildStatus(value: ParentChildStatus): void {
    this.urlParams.set("parent_child", value);
  }

  getPageSize(): PageSizeStatus {
    return (this.urlParams.get("pagination_size") ||
      PageSizeStatus.Five) as PageSizeStatus;
  }

  setPageSize(value: PageSizeStatus): void {
    this.urlParams.set("pagination_size", value);
  }

  getVocabulary(): FullTag | null {
    return JSON.parse(this.urlParams.get("vocabulary"));
  }

  setVocabulary(value: FullTag | {}): void {
    if (Object.keys(value).length === 0) {
      this.urlParams.delete("vocabulary");
    } else {
      this.urlParams.set("vocabulary", JSON.stringify(value));
    }
  }

  getTemplate(): string | null {
    return this.urlParams.get("template");
  }

  setTemplate(value: string): void {
    this.urlParams.set("template", value);
  }

  getLabel(): ItemsWithOperator {
    return {
      items: JSON.parse(this.urlParams.get("label") || null) || [],
      operator: this.urlParams.get("label_op") === "OR" ? "OR" : "AND",
    };
  }

  setLabel(value: ItemsWithOperator): void {
    this.urlParams.set(
      "label",
      value.items.length > 0 ? JSON.stringify(value.items) : ""
    );
    this.urlParams.set("label_op", value.operator);
  }

  getBrands(): string[] {
    return this.urlParams.getAll("brands");
  }

  setBrands(brands: string[]): void {
    this.urlParams.delete("brands");
    brands.map((brand: string): void => this.urlParams.append("brands", brand));
  }

  getOriginalTextLanguage(): LanguageCode {
    return (this.urlParams.get("original_language") as LanguageCode) || "en_US";
  }

  setOriginalTextLanguage(languageCode: LanguageCode): void {
    this.urlParams.set("original_language", languageCode);
  }

  getSearch(): string {
    return this.urlParams.get("search") || "";
  }

  setSearch(search: string): void {
    this.urlParams.set("search", search);
  }

  getDateCategory(): DateCategory {
    const param = this.urlParams.get("date_category");
    return (param || "created") as DateCategory;
  }

  setDateCategory(dateCategory: DateCategory): void {
    this.urlParams.set("date_category", dateCategory);
  }

  getDateStart(): Date | null {
    const value = this.urlParams.get("date_start");
    if (value) {
      return new Date(value);
    }
    return null;
  }

  setDateStart(date: Date | null): void {
    let value = "";
    if (date) {
      value = date.toISOString().substring(0, 10);
    }
    this.urlParams.set("date_start", value);
  }

  getDateEnd(): Date | null {
    const value = this.urlParams.get("date_end");
    if (value) {
      return new Date(value);
    }
    return null;
  }

  setDateEnd(date: Date | null): void {
    let value = "";
    if (date) {
      value = date.toISOString().substring(0, 10);
    }
    this.urlParams.set("date_end", value);
  }

  getGroupChildren(): boolean {
    return this.urlParams.get("group_children") === "on";
  }

  setGroupChildren(value: boolean): void {
    this.urlParams.set("group_children", value ? "on" : "off");
  }

  // Get visible columns
  getVisibleColumns(): ColumnValue[] {
    return this.urlParams.getAll("columns") as ColumnValue[];
  }

  setColumns(columns: ColumnValue[]): void {
    this.urlParams.delete("columns");
    columns.map((column: ColumnValue): void =>
      this.urlParams.append("columns", column as string)
    );
  }

  getReferenceLanguageChannelPairs(): ChannelLanguagePair[] {
    const referencePairs = this.urlParams.getAll("reference");
    return referencePairs.map((reference) => {
      const [language, channel] = reference.split("-");
      return {
        language_code: language,
        channel_id: parseInt(channel),
      } as ChannelLanguagePair;
    });
  }

  setReferenceLanguageChannelPairs(
    referencePairs: ChannelLanguagePair[]
  ): void {
    this.urlParams.delete("reference");
    referencePairs.map((reference): void =>
      this.urlParams.append(
        "reference",
        `${reference.language_code}-${reference.channel_id}`
      )
    );
  }

  getPage(): number {
    const page = this.urlParams.get("page");
    if (page == null) {
      return 1;
    }
    return parseInt(page);
  }

  setPage(page: number): void {
    this.urlParams.set("page", page.toString());
  }

  setMode(mode: string): void {
    this.urlParams.set("mode", mode);
  }

  getBucket(): string | null {
    return this.urlParams.get("bucket");
  }

  setBucket(bucket: string): void {
    this.urlParams.set("bucket", bucket);
  }

  setQuery(query: string): void {
    this.urlParams.set("query", query);
  }

  getView(): number | null {
    const view = this.urlParams.get("view");
    if (view == null) {
      return null;
    }
    return parseInt(view);
  }

  setView(viewId: number): void {
    this.urlParams.set("view", `${viewId}`);
  }

  setViewset(viewSetId: number): void {
    this.urlParams.set("viewset", `${viewSetId}`);
  }

  loadFromLocation(): void {
    this.urlParams = new URLSearchParams(location.search.substring(1));
    this.params = {};
    const multipleValueParams = ["columns", "reference", "brands"];
    this.urlParams.forEach((value, key) => {
      if (value === "null") {
        return;
      }
      if (multipleValueParams.includes(key)) {
        if (!(key in this.params)) {
          this.params[key] = [];
        }
        this.params[key].push(value);
      } else {
        this.params[key] = value;
      }
    });
    this.channel_language_pairs = this.parseQueryChannelLanguagePairs(
      this.params
    );
    this.searchParams = this.getSearchParams();
  }

  refresh(): void {
    const newUrl =
      location.href.split("?")[0] + "?" + this.urlParams.toString();
    history.pushState(null, null, newUrl);
    this.loadFromLocation();
  }
}
