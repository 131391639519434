import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import compact from "lodash/compact";
import { Dropdown, Label } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import { LabelProps } from "semantic-ui-react/dist/commonjs/elements/Label/Label";

export default class IndexedDropdown extends Dropdown {
  /**
   * Default Dropdown is creating duplicated keys for the same values,
   * this one never creates duplicated keys.
   */
  handleLabelClick: (
    event: React.MouseEvent<HTMLElement>,
    data: LabelProps
  ) => void;
  handleLabelRemove: (
    event: React.MouseEvent<HTMLElement>,
    data: LabelProps
  ) => void;

  renderLabels = (): any[] => {
    const { multiple, renderLabel } = this.props;
    const { selectedLabel, value } = this.state;
    if (!multiple || isEmpty(value)) {
      return;
    }
    const selectedItems = map(value, (this as any).getItemByValue);

    return map(
      compact(selectedItems),
      (item: DropdownItemProps, index: number) => {
        const defaultProps = {
          active: item.value === selectedLabel,
          as: "a",
          key: `${item.key}-${item.value}`, // use different key for each label
          onClick: this.handleLabelClick,
          onRemove: this.handleLabelRemove,
          value: item.value,
        };

        return (Label as any).create(renderLabel(item, index, defaultProps), {
          defaultProps,
        });
      }
    );
  };
}
