import React, {
  Dispatch,
  useEffect,
  useState,
  SetStateAction,
  useMemo,
  useRef,
} from "react";
import {
  PromptGroup,
  PromptFormMode,
  Prompt,
  PROMPT_WITH_NO_TAG_KEY,
} from "./types";
import {
  Modal,
  Form,
  DropdownItemProps,
  Divider,
  Button,
} from "semantic-ui-react";
import {
  PromptInputField,
  PromptOrderTemplateField,
  PromptTagsField,
  PromptTemplateField,
} from "./PromptFormInputs/InputFields";
import { getSelectedPromptLanguage, getCustomerEnglishLanguage } from "./utils";
import { useGetCustomerQuery } from "../../api/customerApi";
import {
  createPromptGroup,
  deletePromptGroup,
  updatePromptGroup,
} from "../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { Flag } from "../../customers/customerlanguages";

type Props = {
  prompts: Prompt[];
  mode: PromptFormMode;
  isLoading: boolean;
  selectedPromptGroup?: PromptGroup;
  availableTags: string[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  reFetchGroups: () => Promise<void>;
  selectGroup?: (group: PromptGroup) => void;
};

export const PromptGroupForm: React.FC<Props> = ({
  prompts,
  mode,
  isLoading,
  selectedPromptGroup,
  availableTags,
  setOpenModal,
  reFetchGroups,
  selectGroup,
}) => {
  const mounted = useRef(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading: fetchingCustomer } = useGetCustomerQuery();
  const [groupName, setGroupName] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedPromptsId, setSelectedPromptsId] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const [allTags, setAllTags] = useState<DropdownItemProps[]>(
    (
      availableTags.filter((tag) => tag !== PROMPT_WITH_NO_TAG_KEY) || []
    ).map((tag) => ({ text: tag, value: tag, key: tag }))
  );
  const findLanguageFlag = (prompt: Prompt): string => {
    if (customer && prompt.language) {
      return getSelectedPromptLanguage(customer, prompt).flag;
    } else if (customer && !prompt.language) {
      return getCustomerEnglishLanguage(customer).flag;
    }
    return "";
  };
  const promptsDropdownOptions = useMemo(() => {
    if (!prompts || fetchingCustomer) return [];
    return prompts
      .filter(({ customer_id }) => !!customer_id)
      .map((prompt) => ({
        text: (
          <>
            <Flag content={findLanguageFlag(prompt)} /> {prompt.name}
          </>
        ),
        value: prompt.id,
        key: prompt.name,
      }));
  }, [prompts, fetchingCustomer]);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedPromptGroup && mounted.current) {
      if (mode === PromptFormMode.COPY) {
        setGroupName(`${selectedPromptGroup.name}-copy`);
      } else {
        setGroupName(selectedPromptGroup.name);
      }
      setSelectedTags(selectedPromptGroup?.tags || []);
      setSelectedPromptsId(selectedPromptGroup.prompt_ids);
    }
  }, [selectedPromptGroup]);

  const handleAddTag = (tag: string): void => {
    const selectedTagsCopy = new Set([...selectedTags]);
    const allTagsCopy = new Set([...allTags]);
    selectedTagsCopy.add(tag);
    allTagsCopy.add({ text: tag, value: tag, key: tag });
    setSelectedTags(Array.from(selectedTagsCopy));
    setAllTags(Array.from(allTagsCopy));
  };

  const collectData = (): Omit<PromptGroup, "id"> => {
    return {
      name: groupName,
      prompt_ids: selectedPromptsId,
      tags: selectedTags,
    };
  };

  const createGroup = async (): Promise<PromptGroup> => {
    return await createPromptGroup(token, collectData());
  };

  const updateGroup = async (): Promise<PromptGroup> => {
    const data = collectData() as PromptGroup;
    data.id = selectedPromptGroup.id;
    return await updatePromptGroup(token, data);
  };

  const deleteGroup = async (): Promise<void> => {
    selectGroup?.(undefined);
    setLoading(true);
    await deletePromptGroup(token, selectedPromptGroup.id);
    setLoading(false);
    setOpenModal(false);
    await reFetchGroups();
  };

  const onSave = async (): Promise<void> => {
    setLoading(true);
    let newGroup: PromptGroup;
    if (mode === PromptFormMode.UPDATE) {
      newGroup = await updateGroup();
    } else {
      newGroup = await createGroup();
    }
    if (newGroup) {
      selectGroup?.(newGroup);
    }
    setLoading(false);
    setOpenModal(false);
    await reFetchGroups();
  };

  const disableSave = (): boolean => !groupName || selectedPromptsId.length < 1;

  return (
    <>
      <Modal.Header
        content={
          mode === PromptFormMode.COPY
            ? `Copying from Prompt Group "${selectedPromptGroup?.name}"`
            : `${
                mode === PromptFormMode.UPDATE ? "Update" : "Create"
              } Prompt Group`
        }
      />
      <Modal.Content>
        <Form
          size="small"
          as="div"
          data-testid="copy-assistant-template-group-form"
          loading={isLoading || loading}
        >
          <PromptInputField
            testId="copy-assistant-template-group-form-name-input"
            label={"Name"}
            placeholder={"Name of prompt group..."}
            value={groupName}
            setValue={setGroupName}
          />
          <Divider />
          <PromptTemplateField
            dropdownOptions={promptsDropdownOptions}
            selectedPromptsId={selectedPromptsId}
            setSelectedPromptsId={setSelectedPromptsId}
          />

          {selectedPromptsId.length > 1 && (
            <>
              <Divider />
              <PromptOrderTemplateField
                prompts={prompts}
                selectedPromptsId={selectedPromptsId}
                setSelectedPromptsId={setSelectedPromptsId}
              />
            </>
          )}
          <Divider />
          <PromptTagsField
            dropdownOptions={allTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            handleAddTag={handleAddTag}
            placeholder="Labels on prompt group..."
            helpText="Labels are used to identify prompt groups (optional)."
          />
        </Form>
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        {mode === PromptFormMode.UPDATE && (
          <Button
            data-testid="copy-assistant-template-group-form-delete-template"
            floated="left"
            content="Delete group"
            size="small"
            basic
            onClick={async (): Promise<void> => await deleteGroup()}
          />
        )}
        <Button
          color="red"
          basic
          size="small"
          onClick={(): void => setOpenModal(false)}
          data-testid="close-copy-assistant-template-group-form"
          loading={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="red"
          size="small"
          data-testid="save-copy-assistant-template-group-form"
          loading={isLoading}
          disabled={disableSave()}
          onClick={async (): Promise<void> => await onSave()}
        >
          {mode === PromptFormMode.COPY ? "Copy" : "Save"}
        </Button>
      </Modal.Actions>
    </>
  );
};
