import React, { useLayoutEffect, useRef } from "react";
import {
  Checkbox,
  Grid,
  InputOnChangeData,
  Ref,
  TextArea,
} from "semantic-ui-react";
import styled from "styled-components";
import { SelectedTextType } from "./ProductTextModalEditor";
import { preventOverflowOnTextarea } from "../products/copy-assistant/utils";

const StyledTextArea = styled(TextArea)`
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  height: 100%;
  padding: 0.5em;
`;

type Props = {
  onChange: (textType: SelectedTextType, value: string) => void;
  onSelect: (textType: SelectedTextType) => void;
  selected: boolean;
  selectedTextType: SelectedTextType;
  showSelector?: boolean;
  text: string;
  title: string;
};

export const ProductTextModalEditBox: React.FC<Props> = ({
  onChange,
  onSelect,
  selected,
  selectedTextType,
  showSelector,
  text,
  title,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>();

  useLayoutEffect(() => {
    if (textareaRef.current) {
      preventOverflowOnTextarea(textareaRef.current);
    }
  }, [textareaRef, text]);
  return (
    <Grid.Column data-testid={"product-text-modal-edit-box"}>
      <h3 data-tesid={"product-text-modal-edit-box-title"}>{title}</h3>
      <Ref
        innerRef={(el): void => {
          if (el?.ref) textareaRef.current = el.ref.current;
        }}
      >
        <StyledTextArea
          data-action={"edit-product-text"}
          data-testid={`edit-product-text-${selectedTextType}`}
          onChange={(
            e: React.ChangeEvent<TextArea>,
            { value }: InputOnChangeData
          ): void => onChange(selectedTextType, value)}
          value={text || ""}
        />
      </Ref>
      {showSelector && (
        <Checkbox
          data-testid={"product-text-modal-edit-box-select"}
          radio
          label={"Select this text to be saved"}
          name={"selectedText"}
          value={selectedTextType}
          checked={selected}
          onChange={(): void => onSelect(selectedTextType)}
        />
      )}
    </Grid.Column>
  );
};
ProductTextModalEditBox.defaultProps = { showSelector: true };
