import { uuidv4 } from "../../../utils/uuidUtils";
import { TemplateField } from "../../template";

const HTML_ELEMENT_START = "<";
const HTML_ELEMENT_END = ">";
const VARIABLE_START = "{";
const VARIABLE_END = "}";
const MODIFIER_START = "|";

export type NoCodeNode = {
  id: string;
  type: "text" | "variable";
  value: string;
  displayValue: string;
  start: number;
  end: number;
  length: number;
  modifiers?: string[];
  tagType?: TemplateField["tag"];
  variableType?: TemplateField["type"];
  isBreakElement?: boolean;
};

export const useNoCodeParser = (
  text: string,
  availableVariables: TemplateField[]
): NoCodeNode[] => {
  const nodes: NoCodeNode[] = [];

  const sanitizeValue = (
    value: string,
    modifiersToHide: string = ""
  ): string => {
    return value
      .replaceAll(VARIABLE_START, "")
      .replaceAll(VARIABLE_END, "")
      .replace(modifiersToHide, "");
  };

  const validateNodes = (nodes: NoCodeNode[]): void => {
    let currentStart = 0;
    let calculatedLength = 0;

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.start !== currentStart) {
        console.error("Node start is not equal to current start", {
          node,
          expectedStart: currentStart,
          index: i,
        });
        throw Error("All nodes are not in sequence");
      }
      currentStart = node.end + 1;
      calculatedLength += node.length;
    }

    if (calculatedLength !== text.length) {
      console.error("Calculated length is not equal to text length", {
        calculatedLength,
        expectedLength: text.length,
      });
      throw Error("Calculated length is not equal to text length");
    }
  };
  const validateVariable = (
    variableCandidate: string,
    modifiersToHide: string = ""
  ): {
    valid: boolean;
    tagType: TemplateField["tag"];
    variableType: TemplateField["type"];
  } => {
    const hasStart = variableCandidate.startsWith(
      `${VARIABLE_START}${VARIABLE_START}`
    );
    const hasEnd = variableCandidate.endsWith(`${VARIABLE_END}${VARIABLE_END}`);
    const sanitizedValue = sanitizeValue(variableCandidate, modifiersToHide);

    const variable = availableVariables.find(
      (variable) => variable.key === sanitizedValue.trim()
    );

    return {
      valid: hasStart && hasEnd && !!variable,
      tagType: variable?.tag,
      variableType: variable?.type,
    };
  };

  const invalidateNumberOfStartsAndEnds = (
    ends: number,
    starts: number,
    nextChar: string
  ): boolean => {
    const nextCharIsEndChar = nextChar === VARIABLE_END;
    const invalidStart = (starts < 2 || starts > 2) && ends === 2;
    const toManyEnds = ends === 2 && starts === 2 && nextCharIsEndChar;
    const toFewEnds = ends === 1 && starts === 2 && !nextCharIsEndChar;

    return invalidStart || toManyEnds || toFewEnds;
  };

  const parseVariableModifier = (
    text: string,
    index: number
  ): {
    list: string[];
    newIndex: number;
    raw: string;
    invalidModifiers: boolean;
  } => {
    const list: string[] = [];
    let current = "";
    let raw = "|";
    for (let i = index; i < text.length; i++) {
      const char = text[i];

      if (char === VARIABLE_END) {
        raw += current;
        list.push(current);
        current = "";
        return {
          list,
          newIndex: i - 1,
          raw,
          invalidModifiers: list.some((item) => !item.trim()),
        };
      }

      if (char === MODIFIER_START) {
        list.push(current);
        raw += current + char;
        if (!current.trim()) {
          return {
            list,
            newIndex: i,
            raw,
            invalidModifiers: true,
          };
        }
        current = "";
        continue;
      }

      current += char;
    }
    return {
      list,
      newIndex: text.length,
      raw,
      invalidModifiers: true,
    };
  };

  const parseVariable = (
    text: string,
    index: number
  ): {
    newIndex: number;
    continueValue: string | undefined;
    invalidVariable: boolean;
  } => {
    let variableValue = "";
    let foundEnds = 0;
    let foundStarts = 0;
    let rawModifiers = "";
    let modifiers: string[] = [];
    for (let i = index; i < text.length; i++) {
      const innerChar = text[i];

      if (innerChar === VARIABLE_START) {
        foundStarts++;
      }
      if (innerChar === VARIABLE_END) {
        foundEnds++;
      }
      if (innerChar === MODIFIER_START) {
        const { list, newIndex, raw, invalidModifiers } = parseVariableModifier(
          text,
          i + 1
        );
        i = newIndex;
        variableValue += raw;
        rawModifiers = raw;
        modifiers = list;

        if (invalidModifiers) {
          return {
            newIndex: i,
            continueValue: variableValue,
            invalidVariable: true,
          };
        }
        continue;
      }
      variableValue += innerChar;
      if (
        invalidateNumberOfStartsAndEnds(foundEnds, foundStarts, text[i + 1])
      ) {
        return {
          newIndex: i,
          continueValue: variableValue,
          invalidVariable: true,
        };
      }
      if (foundEnds === 2 && foundStarts === 2) {
        const { valid, tagType, variableType } = validateVariable(
          variableValue,
          rawModifiers
        );
        if (!valid) {
          return {
            newIndex: i,
            continueValue: variableValue,
            invalidVariable: true,
          };
        }
        nodes.push({
          id: uuidv4(),
          type: "variable",
          value: variableValue,
          start: index,
          end: i,
          length: variableValue.length,
          displayValue: sanitizeValue(variableValue, rawModifiers),
          modifiers,
          tagType,
          variableType,
        });
        return {
          newIndex: i,
          continueValue: undefined,
          invalidVariable: false,
        };
      }
    }
    return {
      newIndex: text.length,
      continueValue: variableValue,
      invalidVariable: true,
    };
  };

  const findBreakTag = (text: string, index: number): number => {
    let value = "";
    const breakTag = "br";
    const wrapperElement = document.createElement("div");
    for (let i = index; i < text.length; i++) {
      const innerChar = text[i];
      value += innerChar;
      const textNode = document.createTextNode(value);
      wrapperElement.innerHTML = textNode.wholeText;
      const tagName = wrapperElement.firstChild?.nodeName.toLowerCase();
      if (tagName !== "#text" && !!tagName) {
        nodes.push({
          id: uuidv4(),
          type: "text",
          value,
          start: index,
          end: i,
          length: value.length,
          displayValue: value,
          isBreakElement: tagName === breakTag,
        });
        return i;
      }
      if (
        text[i + 1] === HTML_ELEMENT_START ||
        innerChar === HTML_ELEMENT_END ||
        text[i + 1] === VARIABLE_START
      ) {
        nodes.push({
          id: uuidv4(),
          type: "text",
          value,
          start: index,
          end: i,
          length: value.length,
          displayValue: value,
        });
        return i;
      }
    }
    nodes.push({
      id: uuidv4(),
      type: "text",
      value,
      start: index,
      end: text.length - 1,
      length: value.length,
      displayValue: value,
    });
    return text.length;
  };

  let value: string = "";
  let start = 0;
  let maybeHtmlElement = false;
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    let maybeVariable = char === VARIABLE_START;
    maybeHtmlElement = char === HTML_ELEMENT_START;
    if (maybeHtmlElement) {
      if (value.length > 0) {
        nodes.push({
          id: uuidv4(),
          type: "text",
          value,
          start: start,
          end: i - 1,
          length: value.length,
          displayValue: value,
        });
      }
      value = "";
      const newIndex = findBreakTag(text, i);
      i = newIndex;
    }
    if (maybeVariable) {
      if (value.length > 0) {
        nodes.push({
          id: uuidv4(),
          type: "text",
          value,
          start: start,
          end: i - 1,
          length: value.length,
          displayValue: value,
        });
      }
      value = "";

      const { newIndex, continueValue, invalidVariable } = parseVariable(
        text,
        i
      );

      if (invalidVariable) {
        value += continueValue;
        start = i;
      }
      i = newIndex;
    }
    if (!maybeVariable && !maybeHtmlElement) {
      if (value.length === 0) {
        start = i;
      }
      value += char;
      if (/\s/.test(char) && value.trim().length) {
        nodes.push({
          id: uuidv4(),
          type: "text",
          value,
          start: start,
          end: i,
          length: value.length,
          displayValue: value,
        });
        value = "";
      }
    }
    maybeVariable = false;
    maybeHtmlElement = false;
  }

  if (value.length > 0) {
    nodes.push({
      id: uuidv4(),
      type: "text",
      value,
      start: start,
      end: text.length - 1,
      length: value.length,
      displayValue: value,
    });
  }
  validateNodes(nodes);
  return nodes;
};
