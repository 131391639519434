import { ColumnLabel, ColumnValue } from "./Columns";
import { Product, productSKU } from "../../product";
import { Column } from "./Column";
import { ProductListRenderContext } from "../ObjectTable";

export const ProductListSKUColumn = {
  name: ColumnValue.SKU,
  label: ColumnLabel[ColumnValue.SKU],
  default: true,
  renderCell: (
    p: Product,
    column: Column,
    context: ProductListRenderContext
  ): string => {
    const markChildren =
      context.urlConfig.searchParams.group_children && p.parent_id;
    let sku = productSKU(p);
    if (markChildren) {
      sku = "* " + sku;
    }
    return sku;
  },
};
