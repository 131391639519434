import React, { Dispatch, SetStateAction } from "react";
import {
  DropdownItemProps,
  Dropdown,
  Segment,
  Divider,
} from "semantic-ui-react";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
import { Prompt } from "../types";
import { AdvanceFilter, AdvancedFilterItemsWrapper } from "./AdvanceFilter";
import { Text } from "../../../components/Text";

const DISPLAY_NAME_MENU_HEADER = {
  customerPrompts: "My prompts",
  systemPrompts: "System prompts",
  promptGroups: "Prompt groups",
} as const;

type Props = {
  // Prompt state
  selectedPrompt: Prompt;
  handleSelectPrompt: (id: number) => void;
  tagMapping: { id: number; tags: string[] }[];
  // Dropdown Menu state
  promptOptions: {
    customerPrompts: DropdownItemProps[];
    promptGroups?: DropdownItemProps[];
    systemPrompts: DropdownItemProps[];
  };
  open: boolean;
  noPromptsShowing: boolean;
  // Advanced Filter states
  search: string;
  setSearch: (query: string) => void;
  hideAdvanceFilterButton?: boolean;
  availableTags: string[];
  selectedTags: string[];
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
  // Additional generation settings
  generationSettings?: JSX.Element;
  // Misc
  testIds?: {
    filterTags: string;
  };
};

export const StatelessSelectPromptDropdownMenu: React.FC<Props> = ({
  selectedPrompt,
  handleSelectPrompt,
  tagMapping,
  promptOptions,
  search,
  setSearch,
  noPromptsShowing,
  hideAdvanceFilterButton,
  availableTags,
  selectedTags,
  setSelectedTags,
  generationSettings,
  testIds,
  open,
}) => {
  return (
    <Dropdown.Menu
      data-testid="Stateless-select-prompt-dropdown-menu"
      onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
      open={open}
    >
      <AdvanceFilter
        search={search}
        setSearch={setSearch}
        placeholder="Search prompt"
        hideButton={hideAdvanceFilterButton}
      >
        <Segment size="tiny" compact>
          <Text as="h4" compact color="black">
            Filter labels
          </Text>
          <Divider />
          <AdvancedFilterItemsWrapper data-testid={testIds?.filterTags}>
            {availableTags.map((tag) => (
              <TemplateLabelChip
                classNames="fitted-template-label"
                skipPredefinedClasses
                key={tag}
                content={tag}
                description={""}
                isSelected={selectedTags.includes(tag)}
                labelProps={{
                  as: "span",
                  size: "mini",
                  onClick: (): void => {
                    if (selectedTags.includes(tag)) {
                      setSelectedTags((prev) => prev.filter((t) => t !== tag));
                    } else {
                      setSelectedTags((prev) => [...prev, tag]);
                    }
                  },
                }}
              />
            ))}
          </AdvancedFilterItemsWrapper>
        </Segment>
      </AdvanceFilter>
      {!!generationSettings && generationSettings}

      <Dropdown.Menu scrolling>
        {noPromptsShowing && (
          <Dropdown.Item disabled>No prompts found</Dropdown.Item>
        )}
        {Object.keys(promptOptions).map(
          (key: keyof typeof DISPLAY_NAME_MENU_HEADER) => {
            if (promptOptions[key].length <= 0) return null;
            return (
              <React.Fragment key={key}>
                <Dropdown.Header>
                  {DISPLAY_NAME_MENU_HEADER[key]}
                </Dropdown.Header>

                {promptOptions[key].map((option, index) => (
                  <Dropdown.Item
                    data-testid={`stateless-select-prompt-dropdown-menu-item-${key}-${index}`}
                    selected={option.key === selectedPrompt?.id}
                    key={option.key}
                    value={option.value}
                    onClick={(): void =>
                      handleSelectPrompt(option.key as number)
                    }
                  >
                    {option.text}
                    <div>
                      {tagMapping
                        .find(({ id }) => option.value === id)
                        ?.tags.map((tag) => (
                          <TemplateLabelChip
                            classNames="fitted-template-label"
                            skipPredefinedClasses
                            key={tag}
                            content={tag}
                            description={""}
                            labelProps={{
                              as: "span",
                              size: "mini",
                            }}
                          />
                        ))}
                    </div>
                  </Dropdown.Item>
                ))}
              </React.Fragment>
            );
          }
        )}
      </Dropdown.Menu>
    </Dropdown.Menu>
  );
};
