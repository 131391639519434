import React, {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { Grid, Ref } from "semantic-ui-react";
import { TemplatesTabLabelSelector } from "../../vocabulary/templatesTabLabelSelector/TemplatesTabLabelSelector";
import styled from "styled-components";
import { LangString, LangStringObject } from "../../vocabulary/LangString";
import { gptChangeTagValue } from "../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { debounce } from "../../utils/debounce";
import { LanguageCode } from "../../customers/customerlanguages";
import { reloadPreview } from "../../legacy/t.product-detail";

const GridRowLessPadding = styled(Grid.Row)`
  &&& {
    padding: 0.5rem 0 0 0;
  }
`;
type Props = {
  connectedChannels: { name: string; id: number }[];
  productId: string;
  sectionId: number;
  sectionName: string;
  tagId?: string;
  templateId?: number;
  textBlockId?: string;
  langStringValues: LangStringObject;
  setLangstringValues?: (langStringValues: LangStringObject) => void;
  setScrollRef?: (ref: MutableRefObject<HTMLDivElement>) => void;
  language?: LanguageCode;
  shouldAutoTranslate?: boolean;
  mtConfigGroupId?: number;
  onDoneTranslating?: () => void;
  isStreaming?: boolean;
  onChangeCallback?: () => void;
};
export const PreviewAddedSentences: React.FC<Props> = ({
  connectedChannels,
  productId,
  sectionId,
  sectionName,
  tagId,
  templateId,
  textBlockId,
  langStringValues,
  setLangstringValues,
  setScrollRef,
  language,
  shouldAutoTranslate,
  mtConfigGroupId,
  onDoneTranslating,
  isStreaming,
  onChangeCallback,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const ref = useRef();

  useLayoutEffect(() => {
    if (ref.current) {
      setScrollRef?.(ref);
    }
  }, [ref]);

  const onDataChanged = useCallback(
    debounce((container: typeof LangString, data: LangStringObject) => {
      setLangstringValues?.(data);
      gptChangeTagValue({
        productId,
        tagId,
        value: data,
        templateId,
        textBlockId,
        token,
      }).finally(() => {
        reloadPreview(connectedChannels.map(({ id }) => id));
        onChangeCallback?.();
      });
    }, 500),
    []
  );
  return (
    <Ref innerRef={ref}>
      <>
        <Grid
          padded="horizontally"
          data-testid={`preview-added-sentence-${tagId ?? textBlockId}`}
        >
          <GridRowLessPadding>
            <Grid.Column>
              <LangString
                initialData={langStringValues}
                onDataChanged={onDataChanged}
                isMinified
                displayLanguage={language}
                shouldAutoTranslate={shouldAutoTranslate}
                mtConfigGroupId={mtConfigGroupId}
                onDoneTranslating={onDoneTranslating}
                streamedPreviewString={
                  isStreaming && (langStringValues?.[language] || "...")
                }
                disableAllButtons={isStreaming}
                isInsideCopyAssistantContext
                productId={productId}
                sectionId={sectionId}
                sectionName={sectionName}
              />
            </Grid.Column>
          </GridRowLessPadding>
          {tagId && (
            <GridRowLessPadding>
              <Grid.Column>
                <TemplatesTabLabelSelector
                  productId={productId}
                  templateId={`${templateId}`}
                  templateTagId={tagId}
                  disableWidth
                />
              </Grid.Column>
            </GridRowLessPadding>
          )}
        </Grid>
      </>
    </Ref>
  );
};
