import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { List, Popup, Ref } from "semantic-ui-react";
import { Prompt, PromptGroup } from "../../products/copy-assistant/types";
import { PromptSelectionRule } from "./types";
import { promptSelectionRulesTestSelectors } from "./testUtils/testSelectors";

export const ScrollableDiv = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;
const {
  list: listTestId,
  listItem: ListItemTestId,
  listItemWarningIcon: ListItemWarningTestId,
} = promptSelectionRulesTestSelectors.promptSelectionRulesList;

type Props = {
  rules: PromptSelectionRule[];
  prompts: Prompt[];
  promptGroups: PromptGroup[];
  selectedRule: PromptSelectionRule;
  setSelectedRule: Dispatch<SetStateAction<PromptSelectionRule | undefined>>;
};
export const PromptSelectionRulesList: React.FC<Props> = ({
  rules,
  prompts,
  promptGroups,
  selectedRule,
  setSelectedRule,
}) => {
  return (
    <Droppable
      droppableId="droppable-1"
      renderClone={(provided, snapshot, rubric): any => {
        const rule = rules[rubric.source.index];
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <List.Item>
              <List.Content>
                <List.Header as="a" className="tw-no-underline">
                  {rule.name}
                </List.Header>
              </List.Content>
            </List.Item>
          </div>
        );
      }}
    >
      {(provided): React.ReactElement => (
        <Ref innerRef={provided.innerRef}>
          <List
            as={ScrollableDiv}
            divided
            ordered
            relaxed="very"
            selection
            data-testid={listTestId}
          >
            {rules.map((rule, index) => {
              const hasValidPromptId = prompts.find(
                ({ id }) => id === rule.prompt_id
              );
              const hasValidPromptGroupId = promptGroups.find(
                ({ id }) => id === rule.prompt_group_id
              );
              return (
                <Draggable
                  draggableId={`${rule.id}`}
                  index={index}
                  key={rule.id}
                >
                  {(provided): React.ReactElement => (
                    <Ref innerRef={provided.innerRef}>
                      <List.Item
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        data-testid={ListItemTestId(rule.name)}
                        active={selectedRule?.id === rule.id}
                        onClick={(): void =>
                          setSelectedRule(
                            rules.find(({ id }) => id === rule.id)
                          )
                        }
                      >
                        {!hasValidPromptId && !hasValidPromptGroupId && (
                          <Popup
                            wide
                            basic
                            position="top right"
                            size="small"
                            content={`"${rule.name}" has an invalid prompt selected and will be ignored.`}
                            trigger={
                              <List.Icon
                                name="warning sign"
                                color="yellow"
                                data-testid={ListItemWarningTestId(rule.name)}
                                style={{ float: "right" }}
                              />
                            }
                          />
                        )}
                        <List.Content style={{ paddingLeft: "0.5em" }}>
                          <List.Header as="a" className="tw-no-underline">
                            {rule.name}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    </Ref>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        </Ref>
      )}
    </Droppable>
  );
};
