import $ from "jquery";

window.vocabularyLookupOnDataChanged = function (
  element,
  component,
  data
): void {
  // detect changes in component
  // suggested vocab can be only provided using search input OR radio inputs
  const form = $(element).closest("form");
  const inputElements = $(form).find("input[type=radio][name=replace_with]");
  if ($.isEmptyObject(data)) {
    $(inputElements).each((index, element) => {
      $(element).prop("disabled", false);
    });
  } else {
    $(form).find("#replace_with_unset").prop("checked", true);
    $(inputElements).each((index, element) => {
      $(element).prop("disabled", true);
    });
  }
};
