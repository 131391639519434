import React, { useMemo, useState } from "react";
import { HTML } from "../components/HTML";
import { openInOverlay } from "../utils/GlobalFunctions";
import styled from "styled-components";
import { useGetCustomerQuery } from "../api/customerApi";
import { ProductText } from "./ProductText";
import { getLangStringTemplate } from "../api/action";
import { useSelector } from "react-redux";
import { RootState, store } from "../utils/store";
import { OnActionCallback } from "./ProductTextViewer";
import { Spinner } from "../utils/Spinner";
import { EditTemplateInline } from "../products/product-list/EditTemplateInline";
import { TextBlock, getTextBlock } from "../api/textBlockApi";
import { EditTextBlockInline } from "../products/product-list/EditTextBlockInline";
import { openTemplateBuilderModal } from "../utils/renderutils";
import $ from "jquery";

type Props = {
  isEdited: boolean;
  onAction?: OnActionCallback;
  productText: ProductText;
};

type LangstringTemplateDetails = {
  exactTemplateVariable: string;
  langstingData: { [key: string]: string };
  templateId: string;
  tagId: string;
  templateVocabContent: string;
  text: string;
};

export interface SpanData {
  templateId?: string;
  vocabId?: string;
  tagId?: string;
  textBlockId?: string;
  documentSectionId?: string;
  textualSection?: string;
}

export const TemplateVocabHTML = styled(HTML)`
  font-size: x-small;
  min-height: 30px;
  word-break: break-word;
  //Make template and vocabulary links look like normal links
  span:hover[data-template-id],
  span:hover[data-vocab-id],
  span:hover[data-text-block-id] {
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export async function openVocabOverlay(
  customerSlug: string,
  target: HTMLElement,
  editTemplateInline?: ({
    clickedText,
    templateId,
    tagId,
  }: {
    clickedText: string;
    templateId: string;
    tagId: string;
  }) => Promise<boolean>,
  showTextBlock?: (
    textBlockId: string,
    sectionSlug?: string,
    sectionId?: number
  ) => void
): Promise<void> {
  let { vocabId, templateId, tagId, textBlockId } = target.dataset as SpanData;
  // This check is used when a langstring has HTML elements in the text and one of those is clicked.
  if (!templateId && !vocabId && !tagId && !textBlockId) {
    let parent = target.closest("[data-template-id]");
    if (parent) {
      templateId = parent.getAttribute("data-template-id");
      vocabId = parent.getAttribute("data-vocab-id");
      tagId = parent.getAttribute("data-tag-id");
    } else {
      parent = target.closest("[data-text-block-id]");
      if (parent) {
        textBlockId = parent.getAttribute("data-text-block-id");
      }
    }
  }
  if (textBlockId) {
    const sectionDiv = (target.closest(
      "[data-textual-section]"
    ) as unknown) as HTMLElement;
    let sectionSlug = "";
    let sectionId: number;
    if (sectionDiv) {
      sectionSlug = (sectionDiv.dataset as SpanData)?.textualSection || "";
      try {
        sectionId = parseInt(
          (sectionDiv.dataset as SpanData)?.documentSectionId
        );
      } catch {
        console.warn(
          `unable to parse sectionId to number ${
            (sectionDiv.dataset as SpanData)?.documentSectionId
          }.\n will use ${sectionSlug || "No"} Header`
        );
      }
    }
    showTextBlock?.(textBlockId, sectionSlug, sectionId);
    return;
  }
  let path;
  if (vocabId) {
    path = `/d/${customerSlug}/vocabulary/${vocabId}`;
  } else if (templateId) {
    if (editTemplateInline) {
      // check if template is LangString
      const inlineTemplate = await editTemplateInline({
        clickedText: target.innerText,
        templateId,
        tagId,
      });

      if (inlineTemplate) {
        return;
      }
    }
    const token = store.getState().auth.token;
    $.ajax({
      method: "POST",
      url: "/api/_internal/vocabulary/build_template_edit_url/",
      data: {
        template_id: templateId,
      },
      headers: { token },
      dataType: "json",
      success: function (data) {
        if (data.is_data_template) {
          openInOverlay(data.url);
        } else {
          openTemplateBuilderModal(Number(templateId));
        }
      },
    });
    return;
  }
  if (path) {
    openInOverlay(path);
  }
}

export const ProductTextHTMLRenderer: React.FC<Props> = ({
  isEdited,
  onAction,
  productText,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [langstringTemplateDetails, setLangstringTemplateDetails] = useState<
    LangstringTemplateDetails
  >(null);

  const [fetchingTextBlock, setFetchingTextBlock] = useState(false);
  const [textBlock, setTextBlock] = useState<TextBlock>();
  const [sectionSlug, setSectionSlug] = useState("");
  const [sectionId, setSectionId] = useState<number>();

  const currentText = useMemo(() => {
    let text = productText?.text || "-";

    // Create a span with color set to red if the vocab/template is not yet translated
    const missingTranslationRegExMatch = /\[[^\]]+\]/g;
    const missingTranslations = text.match(missingTranslationRegExMatch);
    if (missingTranslations) {
      [...new Set(missingTranslations)].forEach((t) => {
        text = text.replaceAll(
          t,
          `<span class="ui text red text-missing-translation">${t}</span>`
        );
      });
    }
    return text;
  }, [productText]);

  if (isLoading) {
    return <Spinner />;
  }

  const editTemplateInline = async ({
    clickedText,
    templateId,
    tagId,
  }: {
    clickedText: string;
    templateId: string;
    tagId: string;
  }): Promise<boolean> => {
    const {
      langsting_dict,
      template_vocab_content,
      exact_variable,
    } = await getLangStringTemplate({
      languageCode: productText.languageCode,
      productId: productText.productId,
      templateId,
      tagId,
      text: clickedText,
      token,
    });

    if (Object.keys(langsting_dict).length) {
      setLangstringTemplateDetails({
        templateId,
        tagId,
        text: clickedText,
        langstingData: langsting_dict,
        exactTemplateVariable: exact_variable,
        templateVocabContent: template_vocab_content,
      });
      return true;
    } else {
      setLangstringTemplateDetails(null);
      return false;
    }
  };

  const showTextBlock = async (
    textBlockId: string,
    sectionSlug?: string,
    sectionId?: number
  ): Promise<void> => {
    setFetchingTextBlock(true);
    try {
      const fetchedTextBlock: TextBlock = await getTextBlock(
        token,
        textBlockId
      );

      setTextBlock(fetchedTextBlock);
      setSectionSlug(sectionSlug);
      setSectionId(sectionId);
    } finally {
      setFetchingTextBlock(false);
    }
  };

  return (
    <>
      <TemplateVocabHTML
        className={"generated-text" + (isEdited ? "is-edited" : "")}
        data-testid={"product-text-html-renderer"}
        onClick={async (e: React.MouseEvent): Promise<void> => {
          await openVocabOverlay(
            customer.slug,
            e.target as HTMLElement,
            editTemplateInline,
            showTextBlock
          );
        }}
        text={currentText}
      />
      {langstringTemplateDetails &&
      Object.keys(langstringTemplateDetails).length ? (
        <EditTemplateInline
          {...langstringTemplateDetails}
          onAction={onAction}
          onClose={(): void => {
            setLangstringTemplateDetails(null);
          }}
          productText={productText}
        />
      ) : null}
      {(fetchingTextBlock || !!textBlock) && (
        <EditTextBlockInline
          onClose={(): void => {
            setTextBlock(undefined);
          }}
          textBlock={textBlock}
          loading={fetchingTextBlock}
          onAction={onAction}
          sectionSlug={sectionSlug}
          sectionId={sectionId}
        />
      )}
    </>
  );
};
