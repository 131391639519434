import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { Language } from "../customers/customerlanguages";

const Flag = styled.div<{ markedForPublish: boolean }>`
  & {
    position: relative;
    cursor: pointer;
  }
  &:before {
    ${({ markedForPublish }): FlattenSimpleInterpolation =>
      markedForPublish
        ? null
        : css`
            content: attr(data-flag);
            color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-shadow: 0px 0px rgba(0, 0, 0, 0.4);
          `};
  }
`;

type Props = {
  language: Language;
  markedForPublish: boolean;
  onToggle?: (open: boolean) => void;
  disabled?: boolean;
};

export const ProductTextsLanguageFlag: React.FC<Props> = ({
  markedForPublish,
  language,
  onToggle,
  disabled,
}) => (
  <Flag
    data-flag={language.flag}
    onClick={(): void => {
      onToggle?.(true);
    }}
    markedForPublish={disabled ? !disabled : markedForPublish}
  >
    {language.flag}
  </Flag>
);
