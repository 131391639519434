import React from "react";
import styled from "styled-components";
import uniqBy from "lodash/uniqBy";
import { TemplateLabel } from "../template";
import { TemplateLabelChip } from "./TemplateLabelChip";

export const sortByNameAndDefault = (
  a: TemplateLabel,
  b: TemplateLabel
): number => {
  const aName = a.name.toLowerCase(),
    bName = b.name.toLowerCase();
  if (a.default && !b.default) {
    return -2;
  }
  if (a.default && b.default) {
    if (aName < bName) {
      return -2;
    }
    if (aName > bName) {
      return 2;
    }
  }
  if (!a.default && b.default) {
    return 2;
  }
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

export const FlexDiv = styled.div<{ disableWidth?: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: wrap;
  gap: 2px;
  justify-content: flex-end;
  width: ${({ disableWidth }): string => (disableWidth ? "auto" : "12em")};
`;

type Props = {
  options: TemplateLabel[];
  selectedLabels: TemplateLabel[];
  templateTagId: string;
  updateActiveLabels: (id: number) => Promise<void>;
  disableWidth?: boolean;
};
export const LabelSelectorAllVisibleVariant: React.FC<Props> = ({
  options,
  selectedLabels,
  templateTagId,
  updateActiveLabels,
  disableWidth,
}) => {
  const allLabels = [...selectedLabels, ...options].sort(sortByNameAndDefault);
  return (
    <FlexDiv disableWidth={disableWidth}>
      {uniqBy(allLabels, "id").map(({ id, name, description }) => {
        const isSelectedLabel = selectedLabels.some((label) => label.id === id);
        return (
          <TemplateLabelChip
            content={name}
            description={description}
            isSelected={isSelectedLabel}
            key={`${id}-${templateTagId}`}
            labelProps={{
              as: "a",
              onClick: async (): Promise<void> => {
                await updateActiveLabels(id);
              },
              size: "mini",
            }}
          />
        );
      })}
    </FlexDiv>
  );
};
