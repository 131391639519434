import { BalloonDiv } from "../../components/BalloonDiv";
import React, { useEffect, useRef, useState } from "react";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { ProductTagAction } from "../../api/action";
import { AnnotationComp } from "../../vocabulary/annotation";
import { NOOP } from "../../index";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import {
  extractAnalyzeText,
  ExtractAnalyzeTextResponse,
} from "../../api/extractApi";
import { useGetCustomerQuery } from "../../api/customerApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";

type Props = {
  details: ProductDetailInfo;
  onTagsAction?: onTagsActionCallback;
  selectedName?: string | null;
};

/**
 * Interface for extracting text (NLP) and adding them as tags.
 *
 * This works in the following way:
 * 1) Type the text you want to extract
 * 2) Click on the 'Analyze text' button
 * 3) Look at the parsed text, potentially removing unwanted words
 * 4) Click on 'Add' button to add the tags to the product
 * 5) Tags are added to the product
 * @param props
 * @constructor
 */
export const FreeTextExtractField: React.FC<Props> = ({
  details,
  onTagsAction,
  selectedName,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const { data: customer, isLoading } = useGetCustomerQuery();

  const [text, setText] = useState("");
  const [analyzedText, setAnalyzedText] = useState("");
  const [response, setResponse] = useState<ExtractAnalyzeTextResponse | null>(
    null
  );
  const mounted = useRef(true);

  useEffect(() => {
    ((): void => {
      mounted.current = true;
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  if (isLoading || !customer?.config.product_detail_show_freetext_tag_extract) {
    return null;
  }
  const language = customer.config.tag_input_language;

  // Parse the text and return a parsed version we can later display in
  // the Annotation component
  function analyzeText(): void {
    setResponse(null);
    extractAnalyzeText(token, language, text).then((response) => {
      if (mounted.current) {
        setResponse(response);
        setAnalyzedText(text);
      }
    });
  }

  // Add the extracted/parsed content to the product
  function handleExtractedTags(
    action: ProductTagAction.ADD_EXTRACT | ProductTagAction.REPLACE_EXTRACT
  ): void {
    if (!mounted.current || response === null) {
      return;
    }
    onTagsAction?.({
      action,
      text: analyzedText,
      value: response.spans,
    }).then(() => {
      if (mounted.current) {
        setAnalyzedText("");
        setText("");
        setResponse(null);
      }
    });
  }

  let addTags = null;
  if (response) {
    let label = "Add";
    let replaceBtn = null;
    if (selectedName !== null) {
      label = "Add as attribute";
      replaceBtn = (
        <div className="field grow">
          <button
            className={"pbutton pbutton-margin"}
            id="extract-replace-dependency-submit"
            onClick={(event: React.FormEvent): boolean => {
              event.preventDefault();
              event.stopPropagation();
              handleExtractedTags(ProductTagAction.REPLACE_EXTRACT);
              return false;
            }}
            data-test={"tag-extract-replace-btn"}
          >
            Replace [{selectedName}]
          </button>
        </div>
      );
    }

    addTags = (
      <form className={"dependency-editor"}>
        <div className="field grow">
          <AnnotationComp
            alwaysTranslate={false}
            entityTypes={response.entity_types}
            entityTypesRequestConfig={response.tag_request_configs}
            language={language}
            onDataChanged={NOOP}
            onLoaded={NOOP}
            showHypernym={true}
            showMachineTranslate={false}
            spans={response.spans}
            text={analyzedText}
            textualAppName={details.textualAppName}
            useLexiconEditor={true}
            vocabularyRequestProductId={details.productId}
            vocabularyRequestProductDescription={analyzedText}
            wordBoundaries={response.word_boundaries}
          />
        </div>
        <div className="field grow">
          <button
            className={"pbutton"}
            id="extract-add-dependency-submit"
            onClick={(event: React.FormEvent): boolean => {
              event.preventDefault();
              event.stopPropagation();
              handleExtractedTags(ProductTagAction.ADD_EXTRACT);
              return false;
            }}
            data-test={"tag-extract-add-btn"}
          >
            {label}
          </button>
        </div>
        {replaceBtn}
      </form>
    );
  }
  return (
    <>
      <div className="fluent-input-row" data-testid={"free-text-extract-field"}>
        <form
          className={"text-search"}
          onSubmit={(event: React.FormEvent): boolean => {
            if (!mounted.current) {
              return;
            }
            event.preventDefault();
            event.stopPropagation();
            analyzeText();
            return false;
          }}
        >
          <div className="field grow">
            <BalloonDiv
              className={"label"}
              value={details.microcopies["text_tag_extract"]}
            >
              <label htmlFor="id_extract_add_textbox">
                Free text tag extract (Beta)
              </label>
            </BalloonDiv>
            <input
              id="id_extract_add_textbox"
              type="text"
              name="text"
              value={text}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setText(event.target.value)
              }
              placeholder="black buttons in wood at the back..."
            />
          </div>
          <div className="field grow">
            <button
              className={"pbutton pbutton-margin"}
              type="submit"
              data-test={"tag-extract-analyze-btn"}
              data-testid={"tag-extract-analyze-btn"}
            >
              Analyze text
            </button>
          </div>
        </form>
        {addTags}
      </div>
      <hr />
    </>
  );
};
