import api from "./api";
import { FullTag } from "../utils/tagutils";
import { TaskId } from "./types";

export interface ExtractAnalyzeTextResponse {
  entity_types: any[];
  tags: FullTag[];
  tag_request_configs: any[];
  word_boundaries: any[];
  spans: any[];
}

export async function extractAnalyzeText(
  token: string,
  language: string,
  text: string
): Promise<ExtractAnalyzeTextResponse> {
  const response = await api.post(
    `/api/_internal/extract/analyze_text`,
    { language, text },
    { headers: { token: token } }
  );
  return response.data;
}

export async function requestMappingsDownloadFile(params: {
  key: string | null;
  mapped: boolean | null;
  namespace: string | null;
  tags: string | null;
  token: string;
  value: string | null;
}): Promise<TaskId> {
  const { token, ...data } = params;
  const response = await api.post(
    `/api/_internal/extract/mappings_download_request`,
    data,
    { headers: { token } }
  );
  return response.data.task_id;
}
