export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
): ((...args: Parameters<T>) => ReturnType<T>) => {
  let timeoutId: any = 0;
  return (...args: Parameters<T>): ReturnType<T> => {
    // When running the tests, dispatch immediately to avoid having to mock timers
    if (typeof jest === "object") {
      return callback(...args);
    }
    let result: any;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};
