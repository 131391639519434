import React, { useMemo, useState } from "react";
import {
  FieldsetKeyMapping,
  createFieldsetKeyMapping,
  deleteFieldsetKeyMapping,
  updateFieldsetKeyMapping,
} from "../../../api/fieldsetApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  List,
  Popup,
} from "semantic-ui-react";
import { popupDelay } from "../../../customers/gpt/types";
import { Text } from "../../../components/Text";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../../api/djangoToastSlice";
import { BooleanFilterType } from "./Fieldsets";
import styled from "styled-components";

const InlineListContent = styled(List.Content)`
  display: grid;
  align-items: center;
  grid-template-columns: 500px 1fr;
`;

const filterOnMappings = (
  keys: string[],
  filterMappings: BooleanFilterType,
  keyMappings: FieldsetKeyMapping
): string[] => {
  switch (filterMappings) {
    case "true":
      return keys.filter((key) => !!keyMappings[key]);
    case "false":
      return keys.filter((key) => !keyMappings[key]);
    default:
      return keys;
  }
};

const filterOnSearch = (keys: string[], search: string): string[] => {
  if (!search) return keys;
  return keys.filter(
    (key) => key.toLowerCase().search(search.toLowerCase()) != -1
  );
};

type Props = {
  keyMappings: FieldsetKeyMapping;
  knownKeys: string[];
  loading: boolean;
  reFetch: () => Promise<void>;
  search: string;
  setSearch: (search?: string) => void;
  filterMappings: BooleanFilterType;
  setFilterMappings: (filter?: BooleanFilterType) => void;
};

export const FieldsetMappingsForm: React.FC<Props> = ({
  keyMappings,
  knownKeys,
  loading,
  reFetch,
  search,
  setSearch,
  filterMappings,
  setFilterMappings,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [editingKey, setEditingKey] = useState<string>();

  const handleUpdateKeyMapping = async (
    key: string,
    displayKey: string
  ): Promise<void> => {
    await updateFieldsetKeyMapping(token, key, displayKey).finally(() =>
      reFetch()
    );
    store.dispatch(
      setDjangoToastOpen({
        content: "Input Schema Key Mapping Updated",
        appearance: NotificationAppearance.SUCCESS,
        additionalContent: `Key: ${key} will now use ${displayKey}`,
      })
    );
    setEditingKey(undefined);
  };

  const handleCreateKeyMapping = async (
    key: string,
    displayKey: string
  ): Promise<void> => {
    await createFieldsetKeyMapping(token, key, displayKey).finally(() =>
      reFetch()
    );
    store.dispatch(
      setDjangoToastOpen({
        content: "Input Schema Key Mapping Created",
        appearance: NotificationAppearance.SUCCESS,
        additionalContent: `Key: ${key} will now use ${displayKey}`,
      })
    );
    setEditingKey(undefined);
  };

  const handleDeleteKeyMapping = async (key: string): Promise<void> => {
    await deleteFieldsetKeyMapping(token, key).finally(() => reFetch());
    store.dispatch(
      setDjangoToastOpen({
        content: "Input Schema Key Mapping Deleted",
        appearance: NotificationAppearance.WARNING,
        additionalContent: `Key: ${key} is now unmapped`,
      })
    );
    setEditingKey(undefined);
  };

  const filterKnownKeys = useMemo(() => {
    return filterOnSearch(
      filterOnMappings(knownKeys, filterMappings, keyMappings),
      search
    );
  }, [filterMappings, search, knownKeys, keyMappings]);

  const resetFilters = (): void => {
    setSearch();
    setFilterMappings();
  };

  const handleEditKeyMapping = (key: string): void => {
    if (editingKey === key) {
      setEditingKey(undefined);
      return;
    }
    setEditingKey(key);
  };

  return (
    <Form as="div" size="small" data-testid="fieldset-mappings-form">
      {/* Filters */}
      <Form.Field>
        <Form.Field>
          <Input
            data-testid="fieldset-mappings-form-search-keys"
            icon="search"
            placeholder="Search fields"
            iconPosition="left"
            value={search || ""}
            onChange={(_, { value }): void => setSearch(value)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            data-testid="fieldset-mappings-form-show-all-mapped"
            label="Show all modified fields"
            checked={filterMappings === "true"}
            onChange={(): void => setFilterMappings("true")}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            data-testid="fieldset-mappings-form-show-all-none-mapped"
            label="Hide all modified fields"
            checked={filterMappings === "false"}
            onChange={(): void => setFilterMappings("false")}
          />
        </Form.Field>
        <Button
          data-testid="fieldset-mappings-form-reset-filters"
          content="Reset filters"
          disabled={!(search ?? filterMappings)}
          onClick={resetFilters}
          color="red"
          compact
        />
      </Form.Field>
      <Divider />
      <Form.Field
        as={List}
        divided
        relaxed="very"
        verticalAlign="middle"
        selection
      >
        {filterKnownKeys.length ? (
          <>
            {filterKnownKeys.map((key) => (
              <List.Item
                data-testid={`fieldset-mappings-form-list-item-wrapper-${key}`}
                key={key}
                active={editingKey === key}
                onClick={(): void => {
                  if (editingKey !== key) handleEditKeyMapping(key);
                }}
              >
                <List.Content floated="right">
                  <Popup
                    size="small"
                    wide="very"
                    position="top right"
                    content={
                      editingKey === key
                        ? "Close edit"
                        : `Edit name for field: ${key}`
                    }
                    mouseEnterDelay={popupDelay}
                    trigger={
                      <Button
                        data-testid={`fieldset-mappings-form-list-item-${key}-edit`}
                        color="red"
                        icon={editingKey === key ? "close" : "pencil"}
                        size="small"
                        compact
                        basic
                        onClick={(): void => handleEditKeyMapping(key)}
                      />
                    }
                  />
                </List.Content>
                <InlineListContent>
                  <div>
                    {key}{" "}
                    {!!keyMappings[key] && (
                      <Popup
                        position="right center"
                        content="Display field"
                        size="small"
                        mouseEnterDelay={popupDelay}
                        trigger={
                          <span
                            data-testid={`fieldset-mappings-form-list-item-${key}-mapped-value`}
                          >
                            <Text inline compact>
                              <Icon name="long arrow alternate right" />{" "}
                              {keyMappings[key]}
                            </Text>
                          </span>
                        }
                      />
                    )}
                  </div>
                  <Form
                    data-testid={`fieldset-mappings-form-list-item-${key}-form`}
                    size="small"
                    style={{
                      flex: 1,
                      margin: 0,
                      visibility: editingKey === key ? "visible" : "hidden",
                    }}
                    onSubmit={(e: any): void => {
                      e.preventDefault();
                      const displayKey = e.target.displayKey.value;
                      if (!displayKey) {
                        store.dispatch(
                          setDjangoToastOpen({
                            content: "Please provide Display Field",
                            appearance: NotificationAppearance.ERROR,
                          })
                        );
                        return;
                      }
                      if (keyMappings[key]) {
                        handleUpdateKeyMapping(key, displayKey);
                      } else {
                        handleCreateKeyMapping(key, displayKey);
                      }
                    }}
                  >
                    <Form.Group
                      inline
                      style={{
                        margin: 0,
                      }}
                    >
                      <Form.Field width="8">
                        <input
                          name="displayKey"
                          data-testid={`fieldset-mappings-form-list-item-${key}-set-display-key`}
                          placeholder="name your display field..."
                          defaultValue={keyMappings[key] || ""}
                        />
                        <Button
                          type="submit"
                          data-testid={`fieldset-mappings-form-list-item-${key}-action-button`}
                          content={keyMappings[key] ? "Update" : "Create"}
                          size="small"
                          color="red"
                          compact
                        />
                      </Form.Field>
                      <Form.Field style={{ marginLeft: "50px" }}>
                        {keyMappings[key] && (
                          <Button
                            type="submit"
                            name="delete"
                            data-testid={`fieldset-mappings-form-list-item-${key}-delete-button`}
                            basic
                            content="Delete"
                            size="small"
                            compact
                            floated="right"
                            onClick={(e): Promise<void> => {
                              e.preventDefault();
                              return handleDeleteKeyMapping(key);
                            }}
                          />
                        )}
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </InlineListContent>
              </List.Item>
            ))}
          </>
        ) : (
          <List.Item disabled data-testid="fieldset-mappings-form-no-result">
            <List.Content>No fields found</List.Content>
          </List.Item>
        )}
      </Form.Field>
      <Dimmer active={loading} page>
        <Header as="h4" icon inverted>
          <Icon
            name="circle notch"
            loading
            data-testid="fieldset-mappings-form-loading"
          />
        </Header>
      </Dimmer>
    </Form>
  );
};
