import React, { useEffect, useState } from "react";
import { Dropdown, Input, DropdownProps } from "semantic-ui-react";
import Styled from "styled-components";

import { ProductFilterSearchSelectedItems } from "./ProductFilterSearchSelectedItems";
import { ProductFilterSearchItems } from "./ProductFilterSearchItems";
import { StyledDivider } from "./ProductFilterAddFilter";
import {
  FlexCenter,
  FontSizeParagraph,
  FontSizeHoverEffectIcon,
} from "./ProductFilterChoice";
import { AndOrOperator } from "../ProductListTypes";
import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";
import { FieldOption } from "../../../utils/ApiUtils";
import { onKeyUp } from "../../../utils/semanticInputUtils";

const StyledDropdownMenu = Styled(Dropdown.Menu)`
  &&&& {
    max-height: initial;
    min-width: max-content;
  }
`;

export enum Operation {
  Add = "ADD",
  Delete = "DELETE",
}

type Props = {
  activeDataTestId: string;
  DropdownDataTestId: string;
  dropdownLabel: React.ReactElement;
  onClose: () => void;
  label: string;
  onClick: (value: string, operation: Operation, label?: string) => void;
  options: FieldOption[];
  selected: string[];
  removable?: boolean;
  showOperator?: boolean;
  onChangeOperator?: (operator: AndOrOperator) => void;
  operator?: AndOrOperator;
};
export const ProductFilterSearch: React.FC<Props> = ({
  activeDataTestId,
  onClose,
  DropdownDataTestId,
  dropdownLabel,
  label,
  onClick,
  options,
  selected,
  removable = true,
  showOperator = false,
  onChangeOperator,
  operator = false,
}) => {
  const [searchResult, setSearchResult] = useState<FieldOption[]>([]);

  const handleChange = (
    e: React.SyntheticEvent,
    { value }: DropdownProps
  ): void =>
    setSearchResult(
      options.filter(
        ({ label }) =>
          label.toLowerCase().search((value as string).toLowerCase()) != -1
      )
    );

  useEffect(() => {
    setSearchResult(options);
  }, [options]);

  return (
    <FlexCenter data-testid="product-filter-search">
      <StyledDivider className="divider" />
      <FontSizeParagraph data-testid="product-filter-search-label">
        {label}:
      </FontSizeParagraph>
      <StyledDropdownBase
        className="tiny"
        basic
        button
        data-testid={DropdownDataTestId}
        trigger={dropdownLabel}
        closeOnChange={false}
      >
        <StyledDropdownMenu>
          {selected.length > 0 && (
            <Dropdown.Header className="tw-flex tw-gap-2 tw-items-start">
              <div className="tw-w-min tw-flex-grow">
                <ProductFilterSearchSelectedItems
                  activeDataTestId={activeDataTestId}
                  onClick={onClick}
                  selected={selected}
                />
              </div>
              {showOperator && selected.length > 1 && (
                <div className="tw-flex tw-flex-col tw-gap-2 tw-ms-1">
                  <label className="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer">
                    <input
                      type="radio"
                      checked={operator === "AND"}
                      onChange={(event) => {
                        if (onChangeOperator && event.target.checked) {
                          onChangeOperator("AND");
                        }
                      }}
                    />
                    Match all
                  </label>

                  <label className="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer">
                    <input
                      type="radio"
                      checked={operator === "OR"}
                      onChange={(event) => {
                        if (onChangeOperator && event.target.checked) {
                          onChangeOperator("OR");
                        }
                      }}
                    />
                    Match any
                  </label>
                </div>
              )}
            </Dropdown.Header>
          )}

          <Dropdown.Menu scrolling>
            <Input
              className="search"
              data-testid="product-filter-search-input"
              icon="search"
              iconPosition="left"
              onChange={handleChange}
              onClick={(event: Event): void => {
                event.stopPropagation();
              }}
              placeholder="Type to search"
              onKeyDown={onKeyUp}
            />
            <ProductFilterSearchItems
              onClick={onClick}
              searchResult={searchResult}
              selected={selected}
            />
          </Dropdown.Menu>
        </StyledDropdownMenu>
      </StyledDropdownBase>
      {removable && (
        <FontSizeHoverEffectIcon
          className="times circle link icon"
          data-testid={"close-btn-" + label.toLowerCase()}
          onClick={onClose}
          size="small"
        />
      )}
    </FlexCenter>
  );
};
