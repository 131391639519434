import React from "react";
import { ChannelLanguagePair, Product } from "../product";
import {
  getProductTexts,
  RegenerateAction,
  RegenerateConfig,
} from "../../api/action";
import { RegenerateTextsModal } from "./RegenerateTextsModal";
import { ProductText } from "../../producttext/ProductText";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../utils/store";

export type ProductTextCountData = {
  approved: number;
  needsReview: number;
  published: number;
  edited: number;
  total: number;
};

export type Props = {
  askForOverwrite: boolean;
  cancelMessage: string;
  channelLanguagePairs?: ChannelLanguagePair[];
  confirmMessage: string;
  displayFlagStatus?: boolean;
  forceDisplayModalContent?: boolean;
  onCancel: () => void;
  onConfirm?: (regenerateConfig: RegenerateConfig) => void;
  onOverwrite: (overwrite: boolean) => void;
  open: boolean;
  product: Product;
};

type State = {
  displayApprovedCheckbox: boolean;
  displayEditedCheckbox: boolean;
  displayPublishedCheckbox: boolean;
  displayModalContent: boolean;
  regenerateApproved: boolean;
  regenerateEdited: boolean;
  regeneratePublished: boolean;
  productTexts: ProductText[];
  textsCountData: ProductTextCountData;
};

const mapStateToProps = (state: RootState): { token: string | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
});

const connector = connect(mapStateToProps, null);

type RegenerateTextsModalStateHandlerProps = ConnectedProps<typeof connector> &
  Props;

export class RegenerateTextsModalStateHandler extends React.Component<
  RegenerateTextsModalStateHandlerProps,
  State
> {
  private mounted = false;

  state: State = {
    displayApprovedCheckbox: false,
    displayEditedCheckbox: false,
    displayPublishedCheckbox: false,
    displayModalContent: false,
    regenerateApproved: false,
    regenerateEdited: false,
    regeneratePublished: false,
    productTexts: [],
    textsCountData: {
      approved: 0,
      needsReview: 0,
      published: 0,
      edited: 0,
      total: 0,
    },
  };
  static defaultProps: Partial<RegenerateTextsModalStateHandlerProps> = {
    forceDisplayModalContent: false,
  };

  componentDidMount(): void {
    this.setModalDisplay();
    this.mounted = true;
  }

  componentDidUpdate(prevProps: Props): void {
    const { channelLanguagePairs, open } = this.props;
    const {
      channelLanguagePairs: prevChannelLanguagePairs,
      open: prevOpen,
    } = prevProps;
    if (
      (open && !prevOpen) ||
      channelLanguagePairs !== prevChannelLanguagePairs
    ) {
      this.setModalDisplay();
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  setModalDisplay = async (): Promise<void> => {
    const { forceDisplayModalContent, onConfirm, open } = this.props;
    const productTexts = await this.getProductTexts();
    const textsCountData = this.getProductTextsCountData(productTexts);
    const displayApprovedCheckbox = textsCountData.approved > 0;
    const displayEditedCheckbox = textsCountData.edited > 0;
    const displayPublishedCheckbox = textsCountData.published > 0;
    const displayModalContent =
      forceDisplayModalContent ||
      displayApprovedCheckbox ||
      displayPublishedCheckbox ||
      displayEditedCheckbox;
    this.setState(
      {
        displayApprovedCheckbox,
        displayEditedCheckbox,
        displayPublishedCheckbox,
        displayModalContent,
        productTexts,
        textsCountData,
      },
      () => {
        if (open && !displayModalContent) {
          onConfirm?.(this.getRegenerateConfig());
        }
      }
    );
  };

  getProductTexts = async (): Promise<ProductText[]> => {
    const { channelLanguagePairs, product, token } = this.props;
    return await getProductTexts({
      token,
      productIds: [product.id],
      channelLanguagePairs,
    });
  };

  getProductTextsCountData = (
    productTexts: ProductText[]
  ): ProductTextCountData => {
    const result: ProductTextCountData = {
      approved: 0,
      needsReview: 0,
      published: 0,
      edited: 0,
      total: productTexts.length,
    };
    for (const text of productTexts) {
      if (text.isEdited) {
        result.edited += 1;
      } else if (text.published) {
        result.published += 1;
      } else if (text.approved) {
        result.approved += 1;
      } else if (text.needsReview) {
        result.needsReview += 1;
      }
    }
    return result;
  };

  getRegenerateConfig = (): RegenerateConfig => {
    const {
      regenerateApproved,
      regenerateEdited,
      regeneratePublished,
    } = this.state;
    return {
      regenerate: true,
      regenerateApprovedTexts: regenerateApproved,
      regenerateEditedTexts: regenerateEdited,
      regeneratePublishedTexts: regeneratePublished,
    };
  };

  onRegenerateActionChange = (
    action: RegenerateAction,
    value: boolean
  ): void => {
    switch (action) {
      case RegenerateAction.REGENERATE_APPROVED:
        this.setState({ regenerateApproved: value });
        break;
      case RegenerateAction.REGENERATE_EDITED:
        this.setState({ regenerateEdited: value });
        break;
      case RegenerateAction.REGENERATE_PUBLISHED:
        this.setState({ regeneratePublished: value });
        break;
      default:
        throw new Error(`Unknown regenerate action: ${action}`);
    }
  };

  render(): React.ReactElement | null {
    const {
      askForOverwrite,
      cancelMessage,
      confirmMessage,
      displayFlagStatus,
      onCancel,
      onConfirm,
      onOverwrite,
      open,
      product,
    } = this.props;
    const {
      displayApprovedCheckbox,
      displayEditedCheckbox,
      displayModalContent,
      displayPublishedCheckbox,
      productTexts,
      textsCountData,
    } = this.state;

    if (!displayModalContent) {
      return null;
    }

    return (
      <RegenerateTextsModal
        askForOverwrite={askForOverwrite}
        cancelMessage={cancelMessage}
        confirmMessage={confirmMessage}
        displayApprovedCheckbox={displayApprovedCheckbox}
        displayEditedCheckbox={displayEditedCheckbox}
        displayFlagStatus={displayFlagStatus}
        displayPublishedCheckbox={displayPublishedCheckbox}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onOverwrite={onOverwrite}
        onRegenerateActionChange={this.onRegenerateActionChange}
        open={open}
        product={product}
        productTexts={productTexts}
        regenerateConfig={this.getRegenerateConfig()}
        textsCountData={textsCountData}
      />
    );
  }
}

export const RegenerateTextsModalStateHandlerComp = connector(
  RegenerateTextsModalStateHandler
);
