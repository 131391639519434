import { ProductSpecificOverwrite } from "../../../api/gptApi";
import { AppliedField, Fieldset, Metadata } from "../../manage/fieldsets/types";
import { ProductId } from "../../product";
import { Prompt, PromptGroup, Sentence } from "../types";

// CONTEXT TYPES
type CopyAssistantContextCommonState = {
  isLoading: boolean;
};

export type CopyAssistantContextPromptState = CopyAssistantContextCommonState & {
  list: Prompt[];
  selected: Prompt | null;
  availableTags: { customerOwned: string[]; systemOwned: string[] };
  customerOwned: Prompt[];
  systemOwned: Prompt[];
};

export type CopyAssistantContextGroupState = CopyAssistantContextCommonState & {
  list: PromptGroup[];
  selected: PromptGroup | null;
  availableTags: string[];
};

export type CopyAssistantContextSentenceState = CopyAssistantContextCommonState & {
  promptLoadingIds: number[];
  list: Sentence[];
  autoTranslateList: { id: string; mtConfigGroupId?: number }[];
  sectionNamesOrderedByLastUsed: string[];
};

export type CopyAssistantContextInputDataState = {
  fetchNew: boolean;
};
export type CopyAssistantContextFieldsetsState = CopyAssistantContextCommonState & {
  list: Fieldset<AppliedField>[];
  availableMetadata: Metadata;
};

export type CopyAssistantContextProductSpecificOverwriteState = CopyAssistantContextCommonState & {
  list: ProductSpecificOverwrite[];
};

export type CopyAssistantContextState = {
  productId: ProductId | null;
  globalIsLoading: boolean;
  prompts: CopyAssistantContextPromptState;
  groups: CopyAssistantContextGroupState;
  sentences: CopyAssistantContextSentenceState;
  inputData: CopyAssistantContextInputDataState;
  fieldsets: CopyAssistantContextFieldsetsState;
  productSpecificOverwrites: CopyAssistantContextProductSpecificOverwriteState;
};

// DISPATCH ACTION TYPES

export enum CopyAssistantActionType {
  SELECT_PROMPT = "SELECT_PROMPT",
  SET_PROMPT_INIT_STATE = "SET_PROMPT_INIT_STATE",
  SET_PROMPT_LOADING = "SET_PROMPT_LOADING",
  SELECT_GROUP = "SELECT_GROUP",
  SET_GROUP_INIT_STATE = "SET_GROUP_INIT_STATE",
  SET_GROUP_LOADING = "SET_GROUP_LOADING",
  REMOVE_SENTENCE = "REMOVE_SENTENCE",
  ADD_SENTENCE = "ADD_SENTENCE",
  ADD_SENTENCES = "ADD_SENTENCES",
  ADD_SENTENCE_AUTO_TRANSLATE_LIST = "ADD_SENTENCE_AUTO_TRANSLATE_LIST",
  REMOVE_SENTENCE_AUTO_TRANSLATE_LIST = "REMOVE_SENTENCE_AUTO_TRANSLATE_LIST",
  ADD_ITEM_ORDER_SENTENCE_LIST = "ADD_ORDER_SENTENCE_LIST",
  REMOVE_ITEM_ORDER_SENTENCE_LIST = "REMOVE_ITEM_ORDER_SENTENCE_LIST",
  UPDATE_SENTENCE = "UPDATE_SENTENCE",
  SET_SENTENCE_LOADING = "SET_SENTENCE_LOADING",
  SET_SPECIFIC_SENTENCE_LOADING = "SET_SPECIFIC_SENTENCE_LOADING",
  RESET_ALL_SENTENCES_LOADING_STATES = "RESET_ALL_SENTENCES_LOADING_STATES",
  SET_SECTION_ACTIVE = "SET_SECTION_ACTIVE",
  FETCH_NEW_PROMPT_INPUT = "FETCH_NEW_PROMPT_INPUT",
  SET_INPUT_DATA = "SET_INPUT_DATA",
  SET_GROUPS_INPUT_DATA = "SET_GROUPS_INPUT_DATA",
  SET_REPLACEABLE_TEXTS = "SET_REPLACEABLE_TEXTS",
  SET_REPLACEABLE_TEXTS_LOADING = "SET_REPLACEABLE_TEXTS_LOADING",
  SET_FIELDSETS = "SET_FIELDSETS",
  SET_FIELDSETS_LOADING = "SET_FIELDSETS_LOADING",
  SET_PRODUCT_SPECIFIC_OVERWRITES = "SET_PRODUCT_SPECIFIC_OVERWRITES",
  UPDATE_PRODUCT_SPECIFIC_OVERWRITE = "UPDATE_PRODUCT_SPECIFIC_OVERWRITE",
  SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING = "SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING",
}

export const modules = {
  GROUP: [
    CopyAssistantActionType.SELECT_GROUP,
    CopyAssistantActionType.SET_GROUP_INIT_STATE,
    CopyAssistantActionType.SET_GROUP_LOADING,
  ],
  PROMPT: [
    CopyAssistantActionType.SELECT_PROMPT,
    CopyAssistantActionType.SET_PROMPT_INIT_STATE,
    CopyAssistantActionType.SET_PROMPT_LOADING,
  ],
  SENTENCES: [
    CopyAssistantActionType.REMOVE_SENTENCE,
    CopyAssistantActionType.ADD_SENTENCE,
    CopyAssistantActionType.ADD_SENTENCES,
    CopyAssistantActionType.UPDATE_SENTENCE,
    CopyAssistantActionType.SET_SENTENCE_LOADING,
    CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
    CopyAssistantActionType.RESET_ALL_SENTENCES_LOADING_STATES,
    CopyAssistantActionType.SET_SECTION_ACTIVE,
    CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST,
    CopyAssistantActionType.REMOVE_SENTENCE_AUTO_TRANSLATE_LIST,
    CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
    CopyAssistantActionType.REMOVE_ITEM_ORDER_SENTENCE_LIST,
  ],
  INPUT_DATA: [
    CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
    CopyAssistantActionType.SET_INPUT_DATA,
    CopyAssistantActionType.SET_GROUPS_INPUT_DATA,
  ],
  FIELDSETS: [
    CopyAssistantActionType.SET_FIELDSETS,
    CopyAssistantActionType.SET_FIELDSETS_LOADING,
  ],
  PRODUCT_SPECIFIC_OVERWRITE: [
    CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES,
    CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
    CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING,
  ],
};

// GROUP ACTIONS TYPES
type CopyAssistantInitialGroupStateAction = {
  type: CopyAssistantActionType.SET_GROUP_INIT_STATE;
  payload: Omit<CopyAssistantContextGroupState, "selected" | "availableTags">;
};

type CopyAssistantSelectGroupAction = {
  type: CopyAssistantActionType.SELECT_GROUP;
  payload: PromptGroup;
};

// PROMPT ACTIONS TYPES
export type CopyAssistantInitialPromptStatePayload = Pick<
  CopyAssistantContextPromptState,
  "list" | "selected" | "isLoading"
> & { selectedGroup: PromptGroup | null };

type CopyAssistantInitialPromptStateAction = {
  type: CopyAssistantActionType.SET_PROMPT_INIT_STATE;
  payload: CopyAssistantInitialPromptStatePayload;
};

type CopyAssistantLoadingAction = {
  type:
    | CopyAssistantActionType.SET_PROMPT_LOADING
    | CopyAssistantActionType.SET_GROUP_LOADING
    | CopyAssistantActionType.SET_REPLACEABLE_TEXTS_LOADING;
  payload: boolean;
};

type CopyAssistantSelectPromptAction = {
  type: CopyAssistantActionType.SELECT_PROMPT;
  payload: Prompt;
};

type CopyAssistantSentencesLoadingAction = {
  type: CopyAssistantActionType.SET_SENTENCE_LOADING;
  payload: { loading: boolean; promptIds: number[] };
};

type CopyAssistantResetSentencesLoadingAction = {
  type: CopyAssistantActionType.RESET_ALL_SENTENCES_LOADING_STATES;
};

type CopyAssistantSetSpecificSentenceLoadingAction = {
  type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING;
  payload: { id: string; isLoading: boolean; reason?: string };
};
type CopyAssistantRemoveSentenceAction = {
  type: CopyAssistantActionType.REMOVE_SENTENCE;
  payload: string; // sentenceId
};

type CopyAssistantAddSentenceAction = {
  type: CopyAssistantActionType.ADD_SENTENCE;
  payload: Omit<Sentence, "isLoading">;
};
type CopyAssistantAddSentencesAction = {
  type: CopyAssistantActionType.ADD_SENTENCES;
  payload: Omit<Sentence, "isLoading">[];
};

type CopyAssistantUpdateSentenceAction = {
  type: CopyAssistantActionType.UPDATE_SENTENCE;
  payload: { id: string; toUpdate: Partial<Sentence> };
};

type CopyAssistantSetSentenceAutoTranslate = {
  type:
    | CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST
    | CopyAssistantActionType.REMOVE_SENTENCE_AUTO_TRANSLATE_LIST;
  payload: {
    id: string; // sentenceId
    mtConfigGroupId?: number; // ignored for REMOVE
  };
};

type CopyAssistantSetSentenceListOrder = {
  type:
    | CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST
    | CopyAssistantActionType.REMOVE_ITEM_ORDER_SENTENCE_LIST;
  payload: string; // section.name
};

type CopyAssistantSetSectionActiveAction = {
  type: CopyAssistantActionType.SET_SECTION_ACTIVE;
  payload: { id: string };
};

type CopyAssistantFetchNewPromptInputAction = {
  type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT;
  payload: boolean;
};

// Fieldsets actions
type CopyAssistantSetFieldsetsInitAction = {
  type: CopyAssistantActionType.SET_FIELDSETS;
  payload: { fieldsets: Fieldset<AppliedField>[]; metadata: Metadata };
};

type CopyAssistantSetFieldsetsLoading = {
  type: CopyAssistantActionType.SET_FIELDSETS_LOADING;
  payload: boolean;
};

// Product specific overwrites actions
type CopyAssistantSetProductSpecificOverwritesAction = {
  type: CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES;
  payload: ProductSpecificOverwrite[];
};
type CopyAssistantSetProductSpecificOverwriteAction = {
  type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE;
  payload: ProductSpecificOverwrite;
};
type CopyAssistantSetProductSpecificOverwriteLoadingAction = {
  type: CopyAssistantActionType.SET_PRODUCT_SPECIFIC_OVERWRITES_LOADING;
  payload: boolean;
};

type CopyAssistantFieldsetsActions =
  | CopyAssistantSetFieldsetsInitAction
  | CopyAssistantSetFieldsetsLoading;

type CopyAssistantSentencesActions =
  | CopyAssistantRemoveSentenceAction
  | CopyAssistantAddSentenceAction
  | CopyAssistantAddSentencesAction
  | CopyAssistantUpdateSentenceAction
  | CopyAssistantSetSpecificSentenceLoadingAction
  | CopyAssistantResetSentencesLoadingAction
  | CopyAssistantSetSectionActiveAction
  | CopyAssistantSentencesLoadingAction
  | CopyAssistantSetSentenceAutoTranslate
  | CopyAssistantSetSentenceListOrder;

type CopyAssistantPromptActions =
  | CopyAssistantInitialPromptStateAction
  | CopyAssistantSelectPromptAction
  | CopyAssistantLoadingAction;

type CopyAssistantGroupActions =
  | CopyAssistantInitialGroupStateAction
  | CopyAssistantSelectGroupAction;

type CopyAssistantInputDataActions = CopyAssistantFetchNewPromptInputAction;

type CopyAssistantProductSpecificOverwritesActions =
  | CopyAssistantSetProductSpecificOverwriteAction
  | CopyAssistantSetProductSpecificOverwritesAction
  | CopyAssistantSetProductSpecificOverwriteLoadingAction;

export type CopyAssistantActions =
  | CopyAssistantGroupActions
  | CopyAssistantPromptActions
  | CopyAssistantSentencesActions
  | CopyAssistantInputDataActions
  | CopyAssistantFieldsetsActions
  | CopyAssistantProductSpecificOverwritesActions;
