import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import {
  CustomQueryRenderTable,
  DefaultHeadingH5,
  DefaultParagraph,
} from "./CustomQueryRenderTable";

export const CustomQueryChannelSection: React.FC = () => {
  const customerChannels = useSelector(
    (state: RootState) => state.customQuery.customerChannels
  );

  const tableContent = [
    { as: DefaultHeadingH5, column1: "Name", column2: "ID" },
    ...customerChannels
      .filter((channel) => channel?.visible)
      .map(({ display_name, id }) => {
        return {
          as: DefaultParagraph,
          column1: display_name,
          column2: id,
        };
      }),
  ];

  return (
    <CustomQueryRenderTable
      description={"Note that IDs are customer-specific"}
      header={"Customer channel IDs"}
      name={"channels"}
      tableContent={tableContent}
    />
  );
};
