import * as React from "react";
import Styled from "styled-components";
import { ProductListParams } from "../ProductListURLConfig";
import { ProductListAction } from "../ProductList";
import { CustomerId } from "../../../customers/Customer";
import { ViewSetsFilterButtonsContainer } from "./ViewSetsFilterButtonsContainer";
import { FlexCenter, FontSizeParagraph } from "../top-bar/ProductFilterChoice";
import { buttonTextColor } from "./ViewSetsCreateNewFilterDropdown";
import { ViewSetsGroupDropdownSearch } from "./ViewSetsGroupDropdownSearch";
import { Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { useEffect, useMemo, useState } from "react";
import { CustomQueryHint } from "../CustomQueryHint";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import {
  useGetViewSetsQuery,
  useCreateViewSetMutation,
  useCreateViewMutation,
  useUpdateViewMutation,
} from "../../../api/viewSetApi";
import { hasActiveFilters } from "../utils";

export const StyledViewSetButton = Styled(Button)`
  &&& {
    color: ${buttonTextColor}
    margin-bottom: 0;
    margin-left: 4px;
  }
  &:hover,
  &:focus {
    border: none !important;
    opacity: 1 !important;
  }
`;

const StyledH4 = Styled.h4`
  margin: 4px 3px 4px 0;
  font-size: initial;
`;

export type ViewSetId = number;
export type ViewId = number;

export enum ViewAction {
  CREATE = "create",
  UPDATE = "update",
}

export type View = {
  display_name: string;
  id: ViewId;
  parameters: ProductListParams;
  product_count: number | null;
  position: number;
  pviewset: number;
  favorite?: boolean;
};

export type ViewSet = {
  customer: CustomerId;
  display_name: string;
  id: ViewSetId;
  position: number;
  views: View[];
};

type Props = {
  currentParameters: ProductListParams;
  onProductListAction: (
    action: ProductListAction,
    value: ProductListParams
  ) => void;
  viewId: ViewId;
};

export const ViewSetsFilterContainer: React.FC<Props> = ({
  currentParameters,
  onProductListAction,
  viewId,
}) => {
  const [updateView] = useUpdateViewMutation();
  const [createView] = useCreateViewMutation();
  const [createViewSet] = useCreateViewSetMutation();
  const { data: viewSets, isLoading: fetching } = useGetViewSetsQuery();
  const { data: customer, isLoading } = useGetCustomerQuery();
  const [activeView, setActiveView] = useState<View | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedView, setSelectedView] = useState<View | null>(null);
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );

  useEffect(() => {
    /* When no filters are selected there's only 4 keys in currentParameters
     * This is to enable other components to deselect viewsets.
     * ObjectPagination needs to be able to reset viewsets if the ProductList fails */
    if (Object.keys(currentParameters).length === 4) {
      setActiveView(null);
      setSelectedView(null);
      setActiveIndex(0);
    }
  }, [currentParameters]);

  useEffect(() => {
    if (fetching) return;
    let currentActiveView: View = activeView;
    let currentActiveIndex: number = activeIndex;
    for (const viewSet of viewSets) {
      if (viewId) {
        currentActiveView = viewSet.views.find((view) => view.id === viewId);
      }
      if (currentActiveView) {
        currentActiveIndex = viewSets.find(
          (viewSet) => viewSet.id === currentActiveView.pviewset
        ).id;
        break;
      }
    }
    setActiveIndex(currentActiveIndex);
    setActiveView(currentActiveView);
    setSelectedView(currentActiveView);
  }, [fetching, viewSets, viewId]);

  const onActiveViewChange = (view?: View): void => {
    onProductListAction(
      ProductListAction.UseViewSet,
      view?.parameters || selectedView.parameters
    );
    setActiveView(view || selectedView);
  };

  const onSelectedViewChange = (view: View): void => {
    setSelectedView(view);
    onProductListAction(ProductListAction.UseViewSet, view.parameters);
    setActiveView(view);
  };

  const toggleDjangoToast = (content: string): void => {
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: content,
      })
    );
  };

  const onViewSetCreate = async (displayName: string): Promise<void> => {
    await createViewSet({ display_name: displayName });
    toggleDjangoToast("Filter group created successfully");
  };

  const onViewCreate = async (
    displayName: string,
    viewSetId: ViewSetId
  ): Promise<void> => {
    const newView = await createView({
      display_name: displayName,
      parameters: currentParameters,
      viewset: viewSetId,
    }).unwrap();
    onActiveViewChange(newView);
    setSelectedView(newView);
    setActiveIndex(viewSetId);
    toggleDjangoToast("Filter created successfully");
  };

  const onViewUpdate = async (viewId: ViewId): Promise<void> => {
    const updatedView = await updateView({
      view: viewId,
      parameters: currentParameters,
    }).unwrap();
    onActiveViewChange(updatedView);
    setSelectedView(updatedView);
    toggleDjangoToast("View updated successfully");
  };

  const defaultFilterChanged = useMemo(() => {
    if (!customer) return false;
    return hasActiveFilters(customer, currentParameters);
  }, [currentParameters, customer]);

  const activeViewFiltersChanged = useMemo(() => {
    const parameters = selectedView?.parameters || null;
    if (!parameters || !customer) return false;
    return hasActiveFilters(customer, currentParameters, parameters);
  }, [currentParameters, selectedView, customer]);

  const updateActiveIndex = (index: number): void => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  let label = "Default";
  if (activeView != null) {
    const activeViewSet = viewSets.find(
      (viewSet: ViewSet) => viewSet.id === activeView.pviewset
    );
    label = `${activeViewSet.display_name} : ${activeView.display_name}`;
  }

  if (!viewSets || isLoading) {
    return (
      <>
        <FlexCenter>
          <StyledH4>Filter: </StyledH4>
          <FontSizeParagraph>Loading...</FontSizeParagraph>
        </FlexCenter>
      </>
    );
  }
  return (
    <>
      <FlexCenter data-testid="view-sets-filter-container">
        <StyledH4>Filter: </StyledH4>
        <ViewSetsGroupDropdownSearch
          activeIndex={activeIndex}
          label={label}
          onSelectedViewChange={onSelectedViewChange}
          updateActiveIndex={updateActiveIndex}
          viewSets={viewSets}
        />
        {!customQuery?.query && <CustomQueryHint />}
        {defaultFilterChanged && (
          <ViewSetsFilterButtonsContainer
            viewSets={viewSets}
            activeViewId={activeView?.id}
            haveActiveViewFiltersChanged={activeViewFiltersChanged}
            onActiveViewChange={onActiveViewChange}
            onViewUpdate={onViewUpdate}
            onViewSetCreate={onViewSetCreate}
            onViewCreate={onViewCreate}
          />
        )}
      </FlexCenter>
    </>
  );
};
