import React from "react";
import { ColumnLabel, ColumnValue } from "./Columns";
import { Product } from "../../product";
import { ProductListImage } from "../ProductListImage";

export const ProductListImageColumn = {
  name: ColumnValue.Product,
  label: ColumnLabel[ColumnValue.Product],
  className: "text-column",
  width: 3,
  // eslint-disable-next-line react/display-name
  renderCell: (p: Product): JSX.Element => <ProductListImage product={p} />,
};
