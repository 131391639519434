import $ from "jquery";
import { customerDetails } from "../api/action";

export const loadExternalScript = (
  url: string,
  callback: () => void = null
): void => {
  const t = document.getElementsByTagName("script")[0];
  if (t !== undefined) {
    const e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = url;
    e.charset = "UTF-8";
    t.parentNode.insertBefore(e, t);
    e.onload = callback;
  }
};

function submitClosestForm(element: HTMLElement): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    form.submit();
  });
}

window.addEventListener("load", () => {
  // base.html
  require("../legacy/t.base");
  const token = document.documentElement.dataset.customerToken;
  customerDetails(token).then((customer) => {
    if (customer?.config?.show_jira_service_desk) {
      loadExternalScript(
        "https://textual-ai.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-lxsann/b/23/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=126ccf3e"
      );
    }
  });
  if (!document.body) {
    return;
  }
  const { classList } = document.body;
  // template_builder/layout.html
  if (classList.contains("template-builder")) {
    require("../legacy/t.template-builder");
  }
  // product/layout.html
  if (document.querySelector(".product-detail")) {
    require("../legacy/t.product-detail");
  }
  // vocabulary/language_edit.html
  if (document.querySelector(".language-edit")) {
    require("../legacy/t.language-edit");
  }
  // admin/base_site.html
  if (document.querySelector("[data-admin-utc-offset]")) {
    require("../legacy/t.admin");
    // admin/category_filter_dropdown.html
    if (document.querySelector(".custom-category-admin-filter")) {
      require("../utils/categoryFilterAdmin");
    }
  }
  // manage.html
  if (classList.contains("account")) {
    require("../legacy/t.account");
  }
  // manage.html
  if (classList.contains("manage")) {
    require("../legacy/t.manage");
  }
  // product_list.html
  if (classList.contains("product-list")) {
    require("../legacy/t.product-list");
  }
  // product_list/bulk_actions/base.html
  if (classList.contains("product-bulk-action")) {
    require("../legacy/t.product-bulk-action");
  }
  // vocabulary_list.html
  if (classList.contains("vocabulary-list")) {
    window.vocabularyLookupOnDataChanged = submitClosestForm;

    $("select#id_word_category").change(function (e) {
      const select = e.target as HTMLSelectElement;
      const selector = `[value="${select.value}"]`;
      window.location.href = $(this).find(selector)[0].dataset.path;
    });

    $("select#id_language").change(function (e) {
      const select = e.target as HTMLSelectElement;
      const selector = `[value="${select.value}"]`;
      window.location.href = $(this).find(selector)[0].dataset.path;
    });
  }
  // _vocabulary_request_item.html
  if (classList.contains("translation-requests")) {
    require("../legacy/t.translate-requests");
  }
  // wizard/base.html
  if (classList.contains("wizard")) {
    require("../legacy/t.wizard");
  }
  // mappings/layout.html
  // existing_data.html
  if (classList.contains("wizard-mappings")) {
    require("../legacy/t.wizard-mappings");
  }
  // lexicon_layout.html
  if (classList.contains("translation-lexicons")) {
    require("../legacy/t.translation");
  }
});
