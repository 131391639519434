import * as React from "react";
import { Accordion, Button, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";

import { View, ViewId } from "./ViewSetsFilterContainer";
import { buttonTextColor } from "./ViewSetsCreateNewFilterDropdown";
import { SemanticPopupWithReactRef } from "../../../ui/semantic-ui-overwrites";

export const DropdownBaseLikeButton = styled(Button)<{
  $excludeColor: boolean;
}>`
  &&&& {
    position: relative;
    ${({ $excludeColor }): string =>
      !$excludeColor && `color: ${buttonTextColor};`}
  }
  &&&.basic,
  &&&.basic:hover,
  &&&.basic:active,
  &&&.basic:focus {
    box-shadow: none !important;
    opacity: 1 !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
`;

const DropdownItemLessPadding = styled(Dropdown.Item)`
  &&&& {
    min-width: max-content;
    padding-bottom: 0 !important;
    padding-top: 0.2em !important;
    &:hover {
      background-color: white !important;
    }

    .title {
      width: max-content;
    }
  }
`;
type ViewDropdownItem = {
  onSelectedViewChange: (view: View) => void;
  closeByChild: () => void;
  onFavoriteView: ({
    id,
    set_favorite,
  }: {
    id: ViewId;
    set_favorite: boolean;
  }) => void;
  views: View[];
};
export const buildViewsDropdownItem = ({
  views,
  onSelectedViewChange,
  closeByChild,
  onFavoriteView,
}: ViewDropdownItem): JSX.Element => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {views.map((view: View) => (
      <Button.Group key={view.id} size="mini" compact>
        <DropdownBaseLikeButton
          style={{
            flex: "unset",
            textAlign: "left",
            width: "100%",
          }}
          basic
          content={view.display_name}
          onClick={(): void => {
            onSelectedViewChange(view);
            closeByChild();
          }}
        />
        <SemanticPopupWithReactRef
          popupProps={{
            content: view.favorite
              ? "Remove from favorites"
              : "Add to favorites",
            size: "tiny",
            position: "top right",
          }}
        >
          <DropdownBaseLikeButton
            data-testid={`view-dropdown-item-favorite-button-${view.id}`}
            $excludeColor
            icon={view.favorite ? "star" : "star outline"}
            compact
            basic
            color={view.favorite ? "yellow" : undefined}
            style={{ flex: "unset" }}
            onClick={(): void =>
              onFavoriteView({ id: view.id, set_favorite: !view.favorite })
            }
          />
        </SemanticPopupWithReactRef>
      </Button.Group>
    ))}
  </div>
);
type Props = {
  activeIndex: number;
  closeByChild: () => void;
  index: number;
  title: string;
  onSelectedViewChange: (activeView: View) => void;
  updateActiveIndex: (index: number) => void;
  onFavoriteView: ({
    id,
    set_favorite,
  }: {
    id: ViewId;
    set_favorite: boolean;
  }) => void;
  viewSets: View[];
};

export const ViewSetsGroupDropdownItem: React.FC<Props> = ({
  activeIndex,
  closeByChild,
  index,
  title,
  onSelectedViewChange,
  updateActiveIndex,
  onFavoriteView,
  viewSets,
}) => {
  return (
    <>
      <DropdownItemLessPadding>
        <Accordion.Title
          active={activeIndex === index}
          content={title}
          data-testid={`accordian-title-${title}`}
          icon={
            <Icon
              name="dropdown"
              style={{ margin: "0 0.5em 0 0", float: "left" }}
            />
          }
          index={index}
          onClick={(): void => updateActiveIndex(index)}
        />

        <Accordion.Content
          active={activeIndex === index}
          content={buildViewsDropdownItem({
            views: viewSets,
            onSelectedViewChange,
            onFavoriteView,
            closeByChild,
          })}
        />
      </DropdownItemLessPadding>
    </>
  );
};
