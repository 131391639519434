import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";

import { useGetCustomerQuery } from "../../api/customerApi";
import { getPromptSuggestions } from "../../api/gptApi";
import { Icon } from "../../components/tailwind";
import { LanguageCode } from "../../customers/customerlanguages";
import { PromptSuggestion } from "../../customers/gpt/types";
import { createHref } from "../../utils/hrefUtils";
import { RootState } from "../../utils/store";

type Props = {
  onAddSuggestion: (text: string) => void;
  language: LanguageCode;
};
export const PromptSuggestionsComponent: React.FC<Props> = ({
  onAddSuggestion,
  language,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer } = useGetCustomerQuery();
  const mounted = useRef(false);
  const [promptSuggestions, setPromptSuggestions] = useState<
    PromptSuggestion[]
  >([]);
  const [languageState, setLanguage] = useState<LanguageCode>();

  const staticSuggestions = useMemo(
    () => promptSuggestions.filter((suggestion) => !suggestion.placeholder),
    [promptSuggestions]
  );

  const dynamicSuggestions = useMemo(
    () => promptSuggestions.filter((suggestion) => !!suggestion.placeholder),
    [promptSuggestions]
  );

  const addSuggestion = (suggestion: PromptSuggestion): void => {
    if (suggestion.placeholder) {
      // Dynamic prompt suggestion
      onAddSuggestion(`{{ ${suggestion.placeholder} }}`);
    } else {
      onAddSuggestion(suggestion.text_snippet);
    }
  };

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  useEffect(() => {
    mounted.current = true;
    if (token && languageState) {
      (async (): Promise<void> => {
        const suggestions = await getPromptSuggestions(
          token,
          languageState,
          true // include global suggestions
        );
        if (mounted.current) {
          setPromptSuggestions(suggestions);
        }
      })();
    }
    return (): void => {
      mounted.current = false;
    };
  }, [token, languageState]);

  return (
    <div
      className="recommend-box tw-mb-3 tw-mt-2"
      data-testid="copy-assistant-template-form-instruction-recommendation-chip-wrapper"
    >
      <a
        className="tw-float-end tw-my-1 tw-text-textual-blue tw-no-underline"
        data-subpage=""
        target=""
        href={createHref("promptSuggestions", customer)}
      >
        Edit Prompt Snippets...
      </a>

      {staticSuggestions.length > 0 && (
        <div className="tw-mb-1">
          <label
            className={`tw-text-gray-500 tx-text-xs ${
              staticSuggestions.length > 3 ? "tw-block tw-mb-1" : "tw-me-1"
            }`}
          >
            Static text snippets:
          </label>
          {staticSuggestions.map((suggestion) => (
            <a
              data-testid="copy-assistant-template-form-instruction-recommendation-chip"
              key={suggestion.id}
              className="recommend !tw-inline-flex tw-items-center tw-gap-1"
              onClick={(): void => addSuggestion(suggestion)}
              data-balloon={suggestion.text_snippet}
              data-balloon-pos="down"
              data-balloon-length={
                suggestion.text_snippet.length > 50 ? "xlarge" : ""
              }
            >
              {suggestion.description}
            </a>
          ))}
        </div>
      )}

      {dynamicSuggestions.length > 0 && (
        <div className="tw-mb-1">
          <label
            className={`tw-text-gray-500 tx-text-xs ${
              dynamicSuggestions.length > 3 ? "tw-block tw-mb-1" : "tw-me-1"
            }`}
          >
            Dynamic snippets:
          </label>
          {dynamicSuggestions.map((suggestion) => (
            <a
              data-testid="copy-assistant-template-form-instruction-recommendation-chip"
              key={suggestion.id}
              className="recommend !tw-inline-flex tw-items-center tw-gap-1"
              onClick={(): void => addSuggestion(suggestion)}
              data-balloon={suggestion.text_snippet}
              data-balloon-pos="down"
              data-balloon-length={
                suggestion.text_snippet.length > 50 ? "xlarge" : ""
              }
            >
              {suggestion.description}
              <Icon name="auto_awesome" className="tw-text-xs" />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
