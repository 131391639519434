import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { StyledDropdownBase } from "./viewsets/ViewSetsCreateNewFilterDropdown";
import { default as DropdownItem } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

export const StyledDivider = styled.div`
  position: absolute;
  width: 1px;
  height: 50%;
  top: 25%;
  background: rgba(0, 0, 0, 0.1);
  left: -5%;
`;

const DropdownLinkItem = styled(DropdownItem)`
  background-color: white;
  border: none;
  color: black;
  cursor: pointer;
  display: inline;
  font-size: 9pt;
  font-weight: 500;
  text-decoration: none;
`;

export const CustomQueryHintDropdown: React.FC = () => {
  const customQuery = useSelector(
    (state: RootState) => state.productList.customQuery
  );
  const dropdownContent: { name: string; href: string }[] = [
    { name: "Modify", href: customQuery.queryHref },
    { name: "Delete", href: customQuery.deleteHref },
  ];
  return (
    <StyledDropdownBase
      basic
      button
      icon={null}
      className={"tiny"}
      data-testid={"custom-query-hint-dropdown"}
      trigger={
        <span>
          <b
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon className={"bolt"} size={"small"} />
            Modify query
          </b>
        </span>
      }
    >
      <Dropdown.Menu>
        {dropdownContent.map(({ name, href }) => (
          <DropdownLinkItem
            data-testid={`dropdownlinkitem-${name.toLowerCase()}`}
            text={name}
            key={name}
            href={href}
          />
        ))}
      </Dropdown.Menu>
    </StyledDropdownBase>
  );
};
