import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import styled from "styled-components";
import { StyledButton } from "../SelectTranslationsButton";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  && > button {
    margin-left: auto;
  }
  .message-body {
    background: white;
  }
`;

export const NoWithButton = styled(StyledButton)`
  &&& {
    width: unset;
  }
`;

type Props = { showButton: boolean; width: string };
export const ChannelEditTabHelperText: React.FC<Props> = ({
  showButton,
  width,
}) => {
  const [hidden, setHidden] = useState(false);
  return (
    <FlexContainer style={{ maxWidth: width }}>
      {showButton && (
        <NoWithButton
          onClick={(): void => setHidden(!hidden)}
          compact
          size="tiny"
        >
          {hidden ? "Show help text" : "Hide help text"}
        </NoWithButton>
      )}
      <Message size="tiny" hidden={hidden} className="message-body">
        <Message.Header>Attributes (Subparts):</Message.Header>
        <Message.Content>
          How many (sub)attributes the sentence will include.
          <br />
          <em>
            Red dress with long sleeves and a zip closure at the back
          </em>{" "}
          (2).
          <br />
          <em>Red dress with long sleeves </em> (1).{" "}
          <em>It has a zip closure at the back.</em> (1)
        </Message.Content>
      </Message>
      <Message size="tiny" hidden={hidden} className="message-body">
        <Message.Header>Sentence Type:</Message.Header>
        <Message.Content>
          What level of verbosity the sentence should have, 1 being the least
          verbose.
          <br />
          1: <em>Red, long sleeves and zip closure at the back.</em>
          <br />
          2: <em>Red dress with long sleeves and a zip closure at the back.</em>
          <br />
          3:{" "}
          <em>
            The dress is red and has long sleeves and a zip closure at the back.
          </em>
        </Message.Content>
      </Message>
      <Message size="tiny" hidden={hidden} className="message-body">
        <Message.Header>Quantifier:</Message.Header>
        <Message.Content>
          How the noun of the sentence should be quantified. <br />A quantifier
          is a word that usually goes before a noun to express the quantity of
          the object. <em>This dress, the dress, a dress</em> etc.
        </Message.Content>
      </Message>
      <Message size="tiny" hidden={hidden} className="message-body">
        <Message.Header>Referring expression:</Message.Header>
        <Message.Content>
          If the sentence should refer back to the main attribute.
          <br />
          <em>It has long sleeves and a zipper at the back.</em>
          <br />
          Usually used in the second or third sentence to make a text sound less
          repetitive.
        </Message.Content>
      </Message>
    </FlexContainer>
  );
};
