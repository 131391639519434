import React from "react";
import {
  SectionAction,
  TemplateFieldInfoTag,
  TemplateFormInfo,
  SectionActionRequest,
} from "../../api/sectionActions";
import { VocabularyLookupNewComp } from "../../vocabulary/VocabularyLookupNew";
import { uuidv4 } from "../../utils/uuidUtils";
import { formatTag, VocabularyTreeData } from "../../utils/tagutils";
import { useGetCustomerQuery } from "../../api/customerApi";

type Props = {
  field: TemplateFieldInfoTag;
  form: TemplateFormInfo;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  placeholder: string;
  textualAppName: string;
};

export const TagFormField: React.FC<Props> = ({
  field,
  form,
  onSectionItemAction,
  placeholder,
  textualAppName,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <>Loading...</>;
  }
  const { key, tag_category: tagCategory, tag_type: tagType, value } = field;
  let selectedValue;
  if (value?.length) {
    selectedValue = formatTag(value[0], {
      includeSubTagNames: true,
      showTagTypeHints: true,
    });
  } else {
    selectedValue = "";
  }
  return (
    <div className={"vocabulary-lookup-wrapper"} data-testid={"tag-form-field"}>
      <VocabularyLookupNewComp
        alwaysTranslate={customer.config.always_translate}
        showMachineTranslate={
          customer.config.show_machine_translate_in_request_word
        }
        disableAutoAdd={false}
        isMultiple={field.many}
        language={customer.config.tag_input_language}
        limit={25}
        onVocabularyAdded={(value: VocabularyTreeData): void => {
          value.id = uuidv4();
          onSectionItemAction({
            action: SectionAction.TEMPLATE_FIELD_SET,
            tag_id: form.id,
            field: key,
            tag_value: [
              {
                category: value.category,
                type: value.type,
                value: value.value,
              },
            ],
          });
        }}
        onVocabularyCleared={(): void => {
          onSectionItemAction({
            action: SectionAction.TEMPLATE_FIELD_REMOVE,
            tag_id: form.id,
            field: key,
          });
        }}
        placeholder={placeholder}
        selectedValue={selectedValue}
        selectedTags={value || []}
        showTagTypeHints={true}
        tagCategory={tagCategory}
        tagType={tagType}
        textualAppName={textualAppName}
      />
    </div>
  );
};
