import { loadAnchorTagHandlers } from "./anchor-tag";
import { loadToastMessageHandlers } from "./toast-message";
import { loadModalHandlers } from "./modal";
import { loadCheckboxTagHandlers } from "./checkbox-tag";
import { loadButtonTagHandlers } from "./button-tag";

export default function init(element: Element | Document = document): void {
  loadAnchorTagHandlers(element);
  loadToastMessageHandlers(element);
  loadModalHandlers(element);
  loadCheckboxTagHandlers(element);
  loadButtonTagHandlers(element);
}
