import React from "react";
import { StyledButton } from "../../products/SelectTranslationsButton";
import { uuidv4 } from "../../utils/uuidUtils";
import {
  EmptySkeleton,
  TextHeaderSkeleton,
  TextParagraphSkeletonCollection,
} from "./CommonSkeletons";
import { FixOverflow } from "./styles";
import {
  channels,
  randomNumber,
  channelLength,
  headers,
  headersLength,
  buildParagraph,
} from "./utils";

const MAX_AMOUNT_OF_PARAGRAPHS = 3;

export const ProductDetailsTextPreviewSkeleton: React.FC = () => {
  return (
    <div className="preview-inner">
      <StyledButton disabled content="Select translations" />
      <div className="text-patterns">
        <EmptySkeleton className="languages" />
      </div>
      <div className="texts-grid single-column">
        <div className="text animate">
          <div className="bottom"></div>
        </div>
      </div>
    </div>
  );
};

export const ProductDetailsTextPreviewItemSkeleton: React.FC = () => {
  return (
    <div className="text-item vocabulary-change-enabled" key={uuidv4()}>
      <div className="channel">
        <TextHeaderSkeleton
          content={channels[randomNumber(channelLength)]}
          className="name"
        />
        <FixOverflow className="text" dir="ltr">
          <TextHeaderSkeleton
            content={headers[randomNumber(headersLength)]}
            as="h1"
          />
          <TextParagraphSkeletonCollection
            texts={buildParagraph(MAX_AMOUNT_OF_PARAGRAPHS)}
          />
        </FixOverflow>
        <div className="word-count"></div>
      </div>
    </div>
  );
};
