import React, { useLayoutEffect, useRef, useState } from "react";
import {
  CustomerChannelLanguageMatrix,
  MatrixRowType,
} from "../../../../customers/CustomerChannelLanguageMatrix";
import { ChannelLanguageMatrixItems } from "../../../../api/manageApi";
import { LanguageCode } from "../../../../customers/customerlanguages";
import { ChannelId } from "../../../../customers/Customer";
import { Text } from "../../../../components/Text";
import { Divider } from "semantic-ui-react";

type InputValueListType = [ChannelId, LanguageCode][];

type Props = {
  inputId: string;
};
export const GenerateRuleBasedTextsChannelLanguagePairSelector: React.FC<Props> = ({
  inputId,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const [
    selectedChannelLanguagePairs,
    setSelectedChannelLanguagePairs,
  ] = useState<ChannelLanguageMatrixItems[]>([]);

  const onChange = (payload: MatrixRowType[]): void => {
    const newSelectedChannelLanguagePairs: ChannelLanguageMatrixItems[] = [];
    payload.forEach(({ language_code, channel_details }) => {
      const hasSelectedChannels = channel_details.some(
        ({ selected }) => selected
      );
      if (hasSelectedChannels) {
        channel_details.forEach(({ channel_id, selected }) => {
          if (selected) {
            newSelectedChannelLanguagePairs.push({
              language_code,
              channel_id,
              default: true,
            });
          }
        });
      }
    });
    setSelectedChannelLanguagePairs(newSelectedChannelLanguagePairs);
    updateInputValue(newSelectedChannelLanguagePairs);
  };

  useLayoutEffect(() => {
    const input = document.getElementById(inputId);
    if (!input) {
      throw Error(`Cant find input with ID ${inputId}`);
    }
    inputRef.current = input as HTMLInputElement;

    const value = inputRef.current.value;
    if (value) {
      const parsedValue = JSON.parse(value) as InputValueListType;
      parsedValue.forEach(([channelId, languageCode]) => {
        setSelectedChannelLanguagePairs((prev) => [
          ...prev,
          {
            channel_id: channelId,
            language_code: languageCode,
            default: true,
          },
        ]);
      });
    }
  }, []);

  function updateInputValue(
    channelLanguagePairs: ChannelLanguageMatrixItems[]
  ): void {
    const parsedChannelLanguagePairs: InputValueListType = [];

    channelLanguagePairs.forEach(({ channel_id, language_code }) => {
      parsedChannelLanguagePairs.push([channel_id, language_code]);
    });
    inputRef.current.setAttribute(
      "value",
      JSON.stringify(parsedChannelLanguagePairs)
    );
  }
  return (
    <>
      <Text as="h4">Select Channels & Languages to Generate</Text>
      <CustomerChannelLanguageMatrix
        fetchedChannelLanguageMatrix={{
          channel_language_matrix: selectedChannelLanguagePairs,
        }}
        onChangeChannelLanguageMatrixRef={onChange}
      />
      <Divider />
    </>
  );
};
