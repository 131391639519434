import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Loader,
  Placeholder,
  Transition,
} from "semantic-ui-react";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import styled from "styled-components";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { gptRemoveSentence, GptGenerationTaskQueue } from "../../../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { Sentence } from "../types";
import { GeneratedSingleResult } from "./GeneratedSingleResult";
import { generatedResultsTestIds } from "../testUtils/testIdsSelectors";
import { deleteTextBlock, setActiveTextBlock } from "../../../api/textBlockApi";
import { reloadPreview } from "../../../legacy/t.product-detail";
import range from "lodash/range";
import { uuidv4 } from "../../../utils/uuidUtils";
import { STREAMING_UNKNOWN_SECTION_NAME_HEADER } from "./GeneratedSingleResultHeader";

const PlaceholderImageLessHight = styled(Placeholder.Image)`
  &&&&& {
    padding-top: 30%;
  }
`;

export const PlaceholderThinkLine = styled(Placeholder)`
  &&&&& {
    display: flex;
  }
`;

const loadingSentences = {
  first: "This seems to be taking some time...",
  second: "Still waiting...",
  third: "Sorry for the long wait...",
};

type PlaceholderProps = {
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  promptLoadingIds: number[];
  loadingText?: string;
};

export const GeneratedResultPlaceholder: React.FC<PlaceholderProps> = ({
  gptGenerationTaskQueue,
  promptLoadingIds,
  loadingText = "Generating...",
}) => {
  const [hasLoadedLongTime, setHasLoadedLongTime] = useState(false);
  const [loadingSentence, setLoadingSentence] = useState(
    loadingSentences.first
  );
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    const intervals = [
      setInterval(() => {
        if (mounted.current) setHasLoadedLongTime(true);
      }, 40 * 1000),
      setInterval(() => {
        if (mounted.current) setLoadingSentence(loadingSentences.second);
      }, 60 * 1000),
      setInterval(() => {
        if (mounted.current) setLoadingSentence(loadingSentences.third);
      }, 100 * 1000),
    ];
    return (): void => {
      intervals.forEach((interval) => clearInterval(interval));
      mounted.current = false;
    };
  }, []);
  return (
    <Transition animation="fade down" transitionOnMount duration={700}>
      <Dimmer.Dimmable dimmed blurring as={Card} raised fluid>
        <Dimmer active inverted>
          <Loader size="small">
            {hasLoadedLongTime ? loadingSentence : loadingText}
            {promptLoadingIds.length > 0 && loadingText.includes("Generating") && (
              <>
                <Divider hidden />
                <Button
                  content="Cancel"
                  basic
                  color="red"
                  compact
                  onClick={(): void => {
                    gptGenerationTaskQueue.cancelMany(promptLoadingIds);
                  }}
                />
              </>
            )}
          </Loader>
        </Dimmer>
        <Card.Content
          extra
          data-testid={
            generatedResultsTestIds.loadingComponents.fullCardLoading
          }
        >
          <PlaceholderThinkLine fluid>
            <Placeholder.Line />
          </PlaceholderThinkLine>
        </Card.Content>
        <Card.Content>
          <Placeholder fluid>
            <PlaceholderImageLessHight rectangular />
          </Placeholder>
        </Card.Content>
      </Dimmer.Dimmable>
    </Transition>
  );
};
export const GeneratedResultOnlyTextareaPlaceholder: React.FC<PlaceholderProps> = ({
  gptGenerationTaskQueue,
  promptLoadingIds,
  loadingText = "Generating...",
}) => {
  const [hasLoadedLongTime, setHasLoadedLongTime] = useState(false);
  const [loadingSentence, setLoadingSentence] = useState(
    loadingSentences.first
  );
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    const intervals = [
      setInterval(() => {
        if (mounted.current) setHasLoadedLongTime(true);
      }, 40 * 1000),
      setInterval(() => {
        if (mounted.current) setLoadingSentence(loadingSentences.second);
      }, 60 * 1000),
      setInterval(() => {
        if (mounted.current) setLoadingSentence(loadingSentences.third);
      }, 100 * 1000),
    ];
    return (): void => {
      intervals.forEach((interval) => clearInterval(interval));
      mounted.current = false;
    };
  }, []);
  return (
    <Dimmer.Dimmable dimmed blurring as={Placeholder} fluid>
      <Dimmer active inverted>
        <Loader size="small">
          {hasLoadedLongTime ? loadingSentence : loadingText}
          {promptLoadingIds.length > 0 && loadingText.includes("Generating") && (
            <>
              <Divider hidden />
              <Button
                content="Cancel"
                basic
                color="red"
                compact
                onClick={(): void => {
                  gptGenerationTaskQueue.cancelMany(promptLoadingIds);
                }}
              />
            </>
          )}
        </Loader>
      </Dimmer>
      <PlaceholderImageLessHight
        rectangular
        data-testid={
          generatedResultsTestIds.loadingComponents.partialCardLoading
        }
      />
    </Dimmer.Dimmable>
  );
};
type Props = {
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  reFetchPrompts: () => Promise<void>;
};
export const GeneratedResults: React.FC<Props> = ({
  gptGenerationTaskQueue,
  reFetchPrompts,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    sentences: { list, promptLoadingIds, sectionNamesOrderedByLastUsed },
    productId,
  } = useContext(CopyAssistantContext);
  const dispatch = useContext(CopyAssistantDispatchContext);

  const amountOfNewSentencesLoading = useMemo(() => {
    return list.filter(
      (sentence) =>
        sentence.isLoading && !sentence.sectionInfo && !sentence.isStreaming
    ).length;
  }, [list, promptLoadingIds]);

  const orderSentenceListBySectionName = useMemo(() => {
    const obj: { [key: string]: Sentence[] } = {};
    const otherStreamingSentences = list.filter(
      (sentence) => !sentence?.sectionInfo && sentence.isStreaming
    );

    if (otherStreamingSentences.length) {
      obj[STREAMING_UNKNOWN_SECTION_NAME_HEADER] = otherStreamingSentences;
    }

    sectionNamesOrderedByLastUsed.forEach((sectionName) => {
      obj[sectionName] = list.filter(
        (sentence) =>
          sentence.sectionInfo &&
          sentence.sectionInfo?.documentSectionName === sectionName
      );
    });
    const others = list.filter(
      (sentence) =>
        !sentence.sectionInfo && !sentence.isLoading && !sentence.isStreaming
    );
    if (others.length) {
      obj["Others"] = others;
    }
    return obj;
  }, [list, sectionNamesOrderedByLastUsed]);
  const setActiveSection = (sentence: Sentence): void => {
    dispatch({
      type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
      payload: {
        id: sentence.id,
        isLoading: true,
        reason: "Switching active text",
      },
    });
    setActiveTextBlock(token, sentence.sectionInfo.textBlockId)
      .then(() => {
        dispatch({
          type: CopyAssistantActionType.SET_SECTION_ACTIVE,
          payload: {
            id: sentence.id,
          },
        });
        reloadPreview(
          sentence.sectionInfo?.connectedChannels.map(({ id }) => id)
        );
      })
      .finally(() => {
        dispatch({
          type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
          payload: { id: sentence.id, isLoading: false },
        });
        dispatch({
          type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
          payload: true,
        });
      });
  };

  const removeText = (sentence: Sentence): Promise<unknown> => {
    if (sentence.templateInfo?.tagId) {
      return gptRemoveSentence(productId, sentence.templateInfo.tagId, token);
    }
    if (sentence.sectionInfo?.textBlockId) {
      return deleteTextBlock(token, sentence.sectionInfo.textBlockId, true);
    }
    return Promise.reject();
  };

  const handleRemoveText = async (sentence: Sentence): Promise<void> => {
    if (sentence.templateInfo || sentence.sectionInfo?.textBlockId) {
      dispatch({
        type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
        payload: {
          id: sentence.id,
          isLoading: true,
          reason: "Removing",
        },
      });

      await removeText(sentence)
        .then(() => {
          dispatch({
            type: CopyAssistantActionType.REMOVE_SENTENCE,
            payload: sentence.id,
          });

          if (sentence.sectionInfo) {
            const amountOfSentencesLeftInSection =
              orderSentenceListBySectionName[
                sentence.sectionInfo.documentSectionName
              ]?.length || 0;
            if (amountOfSentencesLeftInSection <= 1) {
              dispatch({
                type: CopyAssistantActionType.REMOVE_ITEM_ORDER_SENTENCE_LIST,
                payload: sentence.sectionInfo.documentSectionName,
              });
            }
          }
        })
        .catch(() => {
          dispatch({
            type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
            payload: { id: sentence.id, isLoading: false },
          });
        })
        .finally(() => {
          reloadPreview(
            sentence.sectionInfo?.connectedChannels.map(({ id }) => id)
          );
          dispatch({
            type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
            payload: true,
          });
        });
      return;
    }
    dispatch({
      type: CopyAssistantActionType.REMOVE_SENTENCE,
      payload: sentence.id,
    });
  };

  return (
    <Card.Group data-testid={generatedResultsTestIds.cardGroup}>
      {range(amountOfNewSentencesLoading).map(() => {
        return (
          <GeneratedResultPlaceholder
            key={uuidv4()}
            gptGenerationTaskQueue={gptGenerationTaskQueue}
            promptLoadingIds={promptLoadingIds}
          />
        );
      })}
      {Object.entries(orderSentenceListBySectionName).map(
        ([sectionName, sentences]) => (
          <GeneratedSingleResult
            key={sectionName}
            sectionName={sectionName}
            gptGenerationTaskQueue={gptGenerationTaskQueue}
            sentences={sentences}
            productId={productId}
            handleRemoveText={handleRemoveText}
            setActiveSection={setActiveSection}
            reFetchPrompts={reFetchPrompts}
          />
        )
      )}
    </Card.Group>
  );
};
