import React, { useState } from "react";
import { Button, Input, Rail } from "semantic-ui-react";
import styled from "styled-components";
import { toolbarTopTestIds } from "../testUtils/testIdsSelectors";

export const AdvancedFilterItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;

export const AdvanceFilterWrapper = styled.div`
  display: flex;
  padding: 10px;
  gap: 3px;
  > .ui.input {
    flex: 1;
  }
  > .ui.button {
    align-self: stretch;
    margin: 0;
  }
`;

type Props = {
  search: string;
  setSearch: (value: string) => void;
  placeholder: string;
  hideButton?: boolean;
};

export const AdvanceFilter: React.FC<Props> = ({
  search,
  setSearch,
  children,
  placeholder,
  hideButton,
}) => {
  const { advanceFilter } = toolbarTopTestIds;
  const [open, setOpen] = useState(false);
  return (
    <>
      <AdvanceFilterWrapper>
        <Input
          autoFocus
          data-testid={advanceFilter.searchInput}
          size="small"
          icon="search"
          iconPosition="left"
          placeholder={placeholder}
          value={search}
          onChange={(e, { value }): void => setSearch(value)}
          onKeyDown={(e: React.KeyboardEvent): void => {
            // This is a ugly hack. But it's needed to allow the user to type a space in the search bar.
            if (e.key === " ") {
              setSearch(search + " ");
            }
          }}
        />
        {children && !hideButton && (
          <Button
            icon="filter"
            onClick={(): void => setOpen(!open)}
            basic
            data-testid={advanceFilter.showRail}
          />
        )}
      </AdvanceFilterWrapper>
      {open && children && !hideButton && (
        <Rail
          position="right"
          attached
          style={{ width: "300px" }}
          data-testid={advanceFilter.rail}
        >
          {children}
        </Rail>
      )}
    </>
  );
};
