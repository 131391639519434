import React, { useEffect, useMemo, useRef, useState } from "react";
import isNil from "lodash/isNil";
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  updateTemplate,
} from "../../api/templateVocabularyApi";
import { getTemplateLabels } from "../../api/productTemplateVocabularyLabelsApi";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../api/djangoToastSlice";
import { Button, Modal } from "../../components/tailwind";
import { Tab } from "../../components/Tab";
import { TemplateBuilderSettingsTab } from "./TemplateBuilderSettingsTab";
import { TemplateBuilderContentTab } from "./TemplateBuilderContentTab";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Language, LanguageCode } from "../../customers/customerlanguages";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import {
  TagCategoryTypeMapKeyType,
  TemplateField,
  TemplateFieldTypeMapKeyType,
  TemplateItem,
  TemplateLabel,
  TemplateLexicon,
} from "../template";
import { TemplateBuilderModalHeader } from "./TemplateBuilderModalHeader";

import { TemplateBuilderManageVariableTab } from "./TemplateBuilderManageVariableTab";

const NON_BREAKING_SPACE = "\xA0"; // HTML: &nbsp;

const removeSpecialChars = (value: string): string => {
  if (!value) return value;
  return value.replaceAll(NON_BREAKING_SPACE, " ");
};

export type TemplateLexiconWithUncommittedTranslationChanges = TemplateLexicon & {
  notCommitted?: boolean;
  canWithdrawWhenCommitted?: boolean;
};

export type OnUpdateLexiconParams = {
  value?: string;
  inManualTranslation?: boolean;
  translatedByMachineTranslate?: boolean;
};

type Props = {
  templateVocabId?: number;
};

export const TemplateBuilderModal: React.FC<Props> = ({ templateVocabId }) => {
  const isCreating = !templateVocabId;

  const {
    data: customer,
    isLoading: isFetchingCustomer,
  } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const dialogRef = useRef<HTMLDialogElement>();

  const [isFetchingData, setIsFetchingData] = useState(!!templateVocabId);
  const [isFetchingTemplateLabels, setIsFetchingTemplateLabels] = useState(
    false
  );
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [variables, setVariables] = useState<TemplateField[]>([]);
  const [lexicons, setLexicons] = useState<
    {
      [key in LanguageCode]?: TemplateLexiconWithUncommittedTranslationChanges;
    }
  >({});

  const [annotateContent, setAnnotateContent] = useState(false);

  const [activeTemplateLabels, setActiveTemplateLabels] = useState<number[]>(
    []
  );
  const [inactiveTemplateLabels, setInactiveTemplateLabels] = useState<
    number[]
  >([]);

  const [availableTemplateLabels, setAvailableTemplateLabels] = useState<
    TemplateLabel[]
  >([]);

  useEffect(() => {
    let mounted = true;
    if (!token) return;
    (async (): Promise<void> => {
      setIsFetchingTemplateLabels(true);
      const templateLabels = await getTemplateLabels(token).finally(() => {
        if (mounted) setIsFetchingTemplateLabels(false);
      });
      if (mounted) setAvailableTemplateLabels(templateLabels);
    })();
    return () => {
      mounted = false;
    };
  }, [token]);

  const languages = useMemo<Language[]>(() => {
    if (!customer) return [];
    return customer.languages;
  }, [customer]);

  const staticLanguage = useMemo<Language>(() => {
    if (!customer) return;

    return customer.languages.find(
      ({ code }) => code === customer.config.tag_input_language
    );
  }, [customer]);

  const enableSaveButton = useMemo(() => {
    const hasLexiconsWithValues = Object.values(lexicons)
      .map(({ template }) => !!template)
      .find((hasContent) => hasContent);
    return !!displayName && hasLexiconsWithValues;
  }, [displayName, lexicons]);

  useEffect(() => {
    if (!dialogRef.current || dialogRef.current?.open) return;
    dialogRef.current.showModal();
  }, [dialogRef]);

  useEffect(() => {
    let mounted = true;
    if (!token || !templateVocabId) return;
    (async (): Promise<void> => {
      setIsFetchingData(true);
      const template = await getTemplate(token, templateVocabId, {
        fetch_fields: true,
        fetch_lexicons: true,
        all_template_labels: true,
      }).finally(() => {
        if (mounted) setIsFetchingData(false);
      });

      if (mounted) {
        extractFetchedTemplateData(template);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [token, templateVocabId]);

  const extractFetchedTemplateData = (template: TemplateItem): void => {
    setDisplayName(template?.display_name || "");
    setDescription(template?.description || "");

    template?.template_lexicons.forEach((lexicon) => {
      setLexicons((prev) => ({
        ...prev,
        [lexicon.language_code]: {
          ...lexicon,
          canWithdrawWhenCommitted: lexicon?.in_manual_translation,
        },
      }));
    });
    setVariables(template?.template_fields || []);

    const activeTemplateLabels: number[] = [];
    const inactiveTemplateLabels: number[] = [];

    template?.labels.forEach((label) => {
      if (label?.default) {
        activeTemplateLabels.push(label.label_id);
      } else {
        inactiveTemplateLabels.push(label.label_id);
      }
    });
    setActiveTemplateLabels(activeTemplateLabels);
    setInactiveTemplateLabels(inactiveTemplateLabels);

    setAnnotateContent(template?.annotate_content || false);
  };

  const onUpdateLexicon = (
    languageCode: LanguageCode,
    {
      value,
      inManualTranslation,
      translatedByMachineTranslate,
    }: OnUpdateLexiconParams
  ): void => {
    const newManualTranslationStatus = isNil(inManualTranslation)
      ? lexicons[languageCode]?.in_manual_translation || false
      : inManualTranslation;

    const newLexicon: TemplateLexiconWithUncommittedTranslationChanges = {
      language_code: languageCode,
      template: isNil(value) ? lexicons[languageCode]?.template : value,
      id: lexicons[languageCode]?.id,
      in_manual_translation: newManualTranslationStatus,
      translated_by_machine_translate: translatedByMachineTranslate || false,
      notCommitted: true,
      canWithdrawWhenCommitted:
        lexicons[languageCode]?.canWithdrawWhenCommitted || false,
      template_variants: lexicons[languageCode]?.template_variants || [],
    };
    setLexicons((prev) => ({ ...prev, [languageCode]: newLexicon }));
  };

  const onUpdateLexiconVariant = (
    languageCode: LanguageCode,
    value: string,
    index: number,
    removeVariant?: boolean
  ): void => {
    let lexicon = { ...lexicons }?.[languageCode];
    if (!lexicon) {
      lexicon = {
        language_code: languageCode,
        template: "",
        in_manual_translation: false,
        translated_by_machine_translate: false,
        notCommitted: true,
        canWithdrawWhenCommitted: false,
        template_variants: [],
      };
    }
    const variants = [...(lexicon?.template_variants || [])];
    if (removeVariant) {
      variants.splice(index, 1);
    } else {
      if (index <= 0) {
        variants[0] = value;
      } else {
        try {
          variants[index] = value;
        } catch {
          store.dispatch(
            setDjangoToastOpen({
              appearance: NotificationAppearance.ERROR,
              content: `Was not able to update variant at index ${index}`,
            })
          );
        }
      }
    }
    lexicon.template_variants = variants;
    setLexicons((prev) => ({ ...prev, [languageCode]: lexicon }));
  };

  const onCreateVariable = (variable: TemplateField): void => {
    setVariables((prev) => [...prev, variable]);
  };

  const handleCreateTemplate = async (
    template: Omit<TemplateItem, "id">
  ): Promise<void> => {
    setIsFetchingData(true);
    await createTemplate(token, template)
      .then(() => {
        dialogRef.current?.close();
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.SUCCESS,
            content: `Template Created`,
            additionalContent: template.display_name,
          })
        );
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };
  const handleUpdateTemplate = async (
    template: TemplateItem
  ): Promise<void> => {
    setIsFetchingData(true);
    await updateTemplate(token, template)
      .then(() => {
        dialogRef.current?.close();
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.SUCCESS,
            content: `Template Updated`,
            additionalContent: template.display_name,
          })
        );
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };
  const handleCommitTemplate = (): void => {
    const templateLabels: TemplateLabel[] = availableTemplateLabels
      .filter(({ id }) =>
        [...activeTemplateLabels, ...inactiveTemplateLabels].includes(id)
      )
      .map((label) => ({
        default: activeTemplateLabels.includes(label.id),
        ...label,
      }));

    const templateLexicons = Object.values(lexicons);
    templateLexicons.forEach((templateLexicon) => {
      templateLexicon.template = removeSpecialChars(templateLexicon.template);
      templateLexicon.template_variants.forEach((variant, index) => {
        templateLexicon.template_variants[index] = removeSpecialChars(variant);
      });
    });

    const template: Omit<TemplateItem, "id"> = {
      display_name: displayName,
      description: description,
      template_lexicons: templateLexicons,
      template_fields: variables,
      labels: templateLabels,
      annotate_content: annotateContent,
    };
    if (isCreating) {
      handleCreateTemplate(template);
      return;
    }
    handleUpdateTemplate({ ...template, id: templateVocabId.toString() });
  };

  const handleDeleteTemplate = async (): Promise<void> => {
    setIsFetchingData(true);
    await deleteTemplate(token, templateVocabId)
      .then(() => {
        dialogRef.current.close();
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.SUCCESS,
            content: "Template Deleted",
            additionalContent: displayName ? displayName : "",
          })
        );
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const findAndReplaceVariableReference = (
    variable: TemplateField,
    newReference: string
  ): {
    languagesChangedContent: LanguageCode[];
    languagesWithdrawnFromManualTranslations: LanguageCode[];
  } => {
    const replace = newReference ? `{{ ${newReference} }}` : "";
    const languagesWithdrawnFromManualTranslations: LanguageCode[] = [];
    const changedLexicons: typeof lexicons = {};
    const re = new RegExp(String.raw`\{\{\s*${variable.key}\s*\}\}`, "g");
    Object.entries(lexicons).map(([languageCode, lexicon]) => {
      if (lexicon?.template && lexicon.template.search(re) >= 0) {
        const newTemplate = lexicon.template.replaceAll(re, replace);
        const newInManualTranslation = newTemplate
          ? lexicon.in_manual_translation
          : false;
        if (
          newInManualTranslation !== lexicon.in_manual_translation &&
          !newInManualTranslation
        ) {
          languagesWithdrawnFromManualTranslations.push(
            languageCode as LanguageCode
          );
        }
        changedLexicons[languageCode as LanguageCode] = {
          ...lexicon,
          in_manual_translation: newInManualTranslation,
          template: newTemplate,
        };
      }
    });
    if (Object.keys(changedLexicons).length) {
      setLexicons((prev) => ({ ...prev, ...changedLexicons }));
    }
    return {
      languagesChangedContent: Object.keys(changedLexicons) as LanguageCode[],
      languagesWithdrawnFromManualTranslations,
    };
  };

  const onUpdateVariable = (
    index: number,
    param: {
      type: TemplateFieldTypeMapKeyType;
      key: string;
      tag?: TagCategoryTypeMapKeyType;
    }
  ): void => {
    const variable = variables?.[index];

    if (!variable) {
      setVariables((prev) => [...prev, param]);
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.SUCCESS,
          content: "Variable Created",
          additionalContent:
            "This Variable can now be managed under the 'Content' Tab.",
        })
      );
      return;
    }

    let madeChangesToLexicons = false;
    if (param.key != variable.key) {
      const { languagesChangedContent } = findAndReplaceVariableReference(
        variable,
        param.key
      );
      madeChangesToLexicons = !!languagesChangedContent.length;
    }

    const newVariable = { ...variable, ...param };
    const variablesCopy = [...variables];
    variablesCopy[index] = newVariable;
    setVariables(variablesCopy);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Variable Updated",
        additionalContent: madeChangesToLexicons
          ? "Reference to the Variable in templates has changed in the 'Content' Tab."
          : "",
      })
    );
  };

  const onRemoveVariable = (index: number): void => {
    const variableToRemove = variables?.[index];
    if (!variableToRemove) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: `Was not able to find a Variable at index: ${index}`,
        })
      );
      return;
    }

    const {
      languagesChangedContent,
      languagesWithdrawnFromManualTranslations,
    } = findAndReplaceVariableReference(variableToRemove, "");
    const newVariables = [...variables];
    newVariables.splice(index, 1);
    setVariables(newVariables);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Variable Removed",
        additionalContent: languagesChangedContent.length
          ? "The reference to the Variable in templates was also removed in the 'Content' Tab."
          : "",
      })
    );
    if (languagesWithdrawnFromManualTranslations.length) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: `${languagesWithdrawnFromManualTranslations.length} template(s) were withdrawn from Manual Translation`,
          additionalContent:
            "Removing the Variable resulted in these templates not having any content left. Please review the changes in the 'Content' Tab.",
        })
      );
    }
  };
  const panes = [
    {
      menuItem: {
        key: "setting",
        heading: "Settings",
      },
      content: (
        <TemplateBuilderSettingsTab
          displayName={displayName}
          setDisplayName={setDisplayName}
          description={description}
          setDescription={setDescription}
          activeTemplateLabels={activeTemplateLabels}
          setActiveTemplateLabels={setActiveTemplateLabels}
          inactiveTemplateLabels={inactiveTemplateLabels}
          setInactiveTemplateLabels={setInactiveTemplateLabels}
          availableTemplateLabels={availableTemplateLabels}
          isFetchingTemplateLabels={isFetchingTemplateLabels}
          annotateContent={annotateContent}
          setAnnotateContent={setAnnotateContent}
        />
      ),
    },
    {
      menuItem: {
        key: "content",
        heading: "Content",
      },
      content: (
        <TemplateBuilderContentTab
          languages={languages}
          initialStaticLanguage={staticLanguage}
          lexicons={lexicons}
          onUpdateLexicon={onUpdateLexicon}
          onUpdateLexiconVariant={onUpdateLexiconVariant}
          variables={variables}
          onCreateVariable={onCreateVariable}
        />
      ),
    },
    {
      menuItem: {
        key: "variables",
        heading: "Variables",
      },
      content: (
        <TemplateBuilderManageVariableTab
          variables={variables}
          onUpdateVariable={onUpdateVariable}
          onRemoveVariable={onRemoveVariable}
        />
      ),
    },
  ];
  return (
    <Modal
      dialogRef={dialogRef}
      isLoading={isFetchingData || isFetchingCustomer}
      size="lg"
    >
      <Modal.Header divide>
        <TemplateBuilderModalHeader
          templateName={displayName}
          templateVocabId={templateVocabId}
          languages={languages}
          lexicons={lexicons}
          languagesInManualTranslation={Object.values(lexicons)
            .filter(
              ({ in_manual_translation, notCommitted }) =>
                in_manual_translation && !notCommitted
            )
            .map(({ language_code }) => language_code)}
        />
      </Modal.Header>
      <Modal.Body minHeight={670}>
        <Tab panes={panes} stateHandler="react" />
      </Modal.Body>
      <Modal.Actions>
        {!isCreating && (
          <Button
            className="tw-mr-auto"
            size="sm"
            onClick={(): void => {
              handleDeleteTemplate();
            }}
            data-testid="template-modal-builder-delete-template-action"
          >
            Delete Template
          </Button>
        )}
        <Button
          data-testid="template-modal-builder-close-modal"
          size="sm"
          onClick={(): void => {
            dialogRef.current?.close();
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid="template-modal-builder-save-template-action"
          size="sm"
          variant="primary"
          onClick={(): void => {
            handleCommitTemplate();
          }}
          disabled={!enableSaveButton}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
