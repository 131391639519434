import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Popup, Ref, TextArea } from "semantic-ui-react";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { getFieldset } from "../../../api/fieldsetApi";
import { Text } from "../../../components/Text";
import { LanguageCode } from "../../../customers/customerlanguages";
import { createHref } from "../../../utils/hrefUtils";
import { RootState } from "../../../utils/store";
import { AppliedField, Fieldset } from "../../manage/fieldsets/types";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { fixLongName, preventOverflowOnTextarea } from "../utils";
import { FlexDivTwoItems } from "./SelectedPromptGroupPromptItem";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";

type Props = {
  alteredInputText?: string;
  activeFieldsetId?: number;
  collectString?: (str: string) => void;
  saveManualInputData?: (str: string) => void;
  language?: LanguageCode;
};
export const FieldsetSelection: React.FC<Props> = ({
  alteredInputText = "",
  activeFieldsetId,
  collectString,
  saveManualInputData,
  language,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer } = useGetCustomerQuery();
  const mounted = useRef(false);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const {
    productId,
    fieldsets: { availableMetadata, list: fieldsets },
    inputData: { fetchNew: reloadSections },
  } = useContext(CopyAssistantContext);
  const [isReApplyingFieldset, setIsReApplyingFieldset] = useState(false);
  const [selectedFieldset, setSelectedFieldset] = useState<
    Fieldset<AppliedField>
  >();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [textareaValue, setTextareaValue] = useState("");

  const [textareaPlaceholder, setTextareaPlaceholder] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>();

  const fieldsetName = useMemo(() => {
    return selectedFieldset?.name || "Default";
  }, [activeFieldsetId, selectedFieldset]);

  useLayoutEffect(() => {
    preventOverflowOnTextarea(textareaRef.current);
  }, [textareaValue]);

  const invalidValue = (value: string): boolean => !value;

  const parseValue = (value: any): string => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return JSON.stringify(value, null, 1);
    }
    return value?.toString();
  };
  const baseString = useMemo(() => {
    let str = "";

    selectedKeys.forEach((key) => {
      const valueFromSection =
        selectedFieldset?.fields.find(
          (field) => field.origin === "section" && field.key === key
        )?.value || "";
      const value = valueFromSection || availableMetadata[key];
      if (invalidValue(value)) return;

      if (valueFromSection) {
        str += `${parseValue(value)}\n`;
      } else {
        str += `${key}: ${parseValue(value)}\n`;
      }
    });

    if (!str && selectedFieldset?.includes_sections) {
      setTextareaPlaceholder(
        () => "No text in any Section connected to this Input Schema."
      );
    } else if (!str) {
      setTextareaPlaceholder(
        () => "No metadata matched with the Input Schema."
      );
    }

    return str;
  }, [selectedKeys, availableMetadata, selectedFieldset]);

  useEffect(() => {
    setTextareaValue(alteredInputText);
    if (!alteredInputText) {
      setTextareaValue(baseString);
    }
  }, [selectedKeys, alteredInputText]);

  useEffect(() => {
    collectString?.(textareaValue);
  }, [textareaValue]);

  useEffect(() => {
    if (selectedFieldset && !selectedFieldset.use_as_default) {
      setSelectedKeys(selectedFieldset.fields.map(({ key }) => key));
    } else {
      const defaultFieldset = selectedFieldset?.use_as_default;
      if (defaultFieldset) {
        if (selectedFieldset.fields.length < 1) {
          setSelectedKeys(Object.keys(availableMetadata));
        } else {
          setSelectedKeys(selectedFieldset.fields.map(({ key }) => key));
        }
      } else {
        setSelectedKeys([...Object.keys(availableMetadata)]);
      }
    }
  }, [activeFieldsetId, selectedFieldset, availableMetadata]);

  useEffect(() => {
    if (reloadSections) {
      dispatch({
        type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
        payload: false,
      });
    }
    if (activeFieldsetId && fieldsets.length) {
      const selected = fieldsets.find(({ id }) => id === activeFieldsetId);

      if (!selected?.includes_sections && selected) {
        setSelectedFieldset(selected);
      } else if (selected?.includes_sections) {
        (async (): Promise<void> => {
          const oldSelectedKeys = [...selectedKeys];
          setSelectedKeys([]);
          setIsReApplyingFieldset(true);
          setTextareaPlaceholder("");
          const { fieldset } = await getFieldset(token, activeFieldsetId, {
            productId,
            languageCode: language,
          })
            .catch(() => {
              if (mounted.current) setSelectedKeys(oldSelectedKeys);
              return { fieldset: selected };
            })
            .finally(() => {
              if (mounted.current) setIsReApplyingFieldset(false);
            });
          if (mounted.current) {
            setSelectedFieldset(fieldset);
          }
        })();
      }
    }
  }, [activeFieldsetId, fieldsets, language, reloadSections]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      <Dimmer.Dimmable dimmed={isReApplyingFieldset}>
        <Ref innerRef={textareaRef}>
          <TextArea
            data-testid="fieldset-textarea"
            value={textareaValue}
            placeholder={textareaPlaceholder}
            onChange={(e, { value }): void => {
              saveManualInputData?.(value as string);
              setTextareaValue(value as string);
            }}
          />
        </Ref>
        <Dimmer active={isReApplyingFieldset} inverted>
          <Loader active={isReApplyingFieldset} size="tiny">
            Collecting Data...
          </Loader>
        </Dimmer>
      </Dimmer.Dimmable>
      <FlexDivTwoItems $marginTop>
        <Text compact size="small" color="grey">
          Data fetched from Input Schema: <b>{fixLongName(fieldsetName)}</b>
        </Text>
        {Object.keys(availableMetadata) && (
          <Popup
            content="Manage your Input Schemas."
            size="tiny"
            wide="very"
            trigger={
              <a
                className="ui text compact small tw-text-textual-blue tw-underline"
                data-subpage=""
                target=""
                href={`${createHref("fieldsets", customer)}?fieldset_id=${
                  activeFieldsetId || 0
                }`}
                data-testid="fieldset-available-data-button"
              >
                Select what data to use...
              </a>
            }
          />
        )}
      </FlexDivTwoItems>
    </>
  );
};
