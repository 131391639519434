import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import styled from "styled-components";

import { WordType } from "./RequestWordModal";
import { AllowTagType } from "./RequestWordModal";

export const disallowedKindMaterialOptions = [
  "identifier/kind",
  "material/other",
];
export const disallowedFixedPhraseOptions = ["phrase/sentence"];

export const dropdownOptions: DropdownOption[] = [
  {
    key: 1,
    text: "Noun",
    value: "identifier/kind",
  },
  {
    key: 2,
    text: "Property",
    value: "quality/property",
  },
  {
    key: 3,
    text: "Phrase",
    value: "phrase/phrase",
  },
  {
    key: 4,
    text: "Fixed phrase",
    value: "phrase/sentence",
  },
  {
    key: 5,
    text: "Color",
    value: "quality/color",
  },
  {
    key: 6,
    text: "Material",
    value: "material/other",
  },
  {
    key: 7,
    text: "Occasion",
    value: "feature/perfect_for",
  },
  {
    key: 8,
    text: "Position",
    value: "phrase/position",
  },
];

const StyledDropdownItem = styled.span`
  & {
    display: block;
    width: 100%;
  }
`;

interface DropdownOption {
  key: number;
  text: string;
  value: string;
  content?: React.ReactElement;
  disabled?: boolean;
}

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

type Props = {
  allowTags: AllowTagType;
  attributeErrorSiteDistinction?: boolean;
  isUsedInTagTreeEditor: boolean;
  onWordTypeChange: (data: WordType | null) => void;
  providedWordType: string;
};

export const RequestWordModalWordType: React.FC<Props> = ({
  allowTags,
  attributeErrorSiteDistinction,
  onWordTypeChange,
  providedWordType,
}) => {
  const getDisabledOption = ({
    option,
    popupContent = "This word type cannot be added here.",
  }: {
    option: DropdownOption;
    popupContent?: string;
  }): DropdownOption => {
    return {
      ...option,
      disabled: true,
      content: (
        <Popup
          basic
          content={popupContent}
          size="tiny"
          trigger={
            <StyledDropdownItem className="text">
              {option.text}
            </StyledDropdownItem>
          }
        />
      ),
    };
  };

  const getDropdownOptions = (): DropdownOption[] => {
    return dropdownOptions.map((option) => {
      if (
        allowTags === AllowTagType.KIND_MATERIAL &&
        !disallowedKindMaterialOptions.includes(option.value)
      ) {
        if (attributeErrorSiteDistinction) {
          return getDisabledOption({
            option,
            popupContent:
              "This word type cannot be added here. It can be added from the right-most attribute field.",
          });
        } else {
          return getDisabledOption({
            option,
          });
        }
      }
      if (
        allowTags === AllowTagType.NON_KIND_MATERIAL &&
        disallowedKindMaterialOptions.includes(option.value)
      ) {
        return getDisabledOption({
          option,
          popupContent:
            "This word type cannot be added here. It can be added from the left-most attribute field.",
        });
      }
      if (
        allowTags === AllowTagType.NON_FIXED_PHRASES &&
        disallowedFixedPhraseOptions.includes(option.value)
      ) {
        return getDisabledOption({
          option,
        });
      }
      return option;
    });
  };

  return (
    <Grid>
      <div>Please select word type</div>
      <Dropdown
        clearable
        closeOnChange
        closeOnBlur={true}
        data-testid="request-word-type-dropdown"
        fluid
        name="wordType"
        onChange={(event, { value }): void => {
          const wordTypeName = dropdownOptions.find(
            (dropdownOption) => dropdownOption.value == (value as string)
          )?.text;
          onWordTypeChange({
            name: wordTypeName,
            value: value as string,
          });
        }}
        options={getDropdownOptions()}
        placeholder="Choose word type"
        search
        selection
        selectOnBlur={false}
        value={providedWordType}
      />
    </Grid>
  );
};
