import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Divider,
  Form,
  Icon,
  Input,
  Message,
  Popup,
  TextArea,
} from "semantic-ui-react";

import {
  deleteOpenAIAssistantFile,
  getOpenAIAssistantFiles,
} from "../../../api/gptApi";
import { StyledReactFileDrop as StyledFileDrop } from "../../../components/FileDrop";
import { Text } from "../../../components/Text";
import {
  OpenAIAssistant,
  OpenAIAssistantFile,
} from "../../../products/copy-assistant/types";
import { fixLongName } from "../../../products/copy-assistant/utils";
import { popupDelay } from "../types";
import { RootState } from "../../../utils/store";

type Props = {
  selectedAssistant: OpenAIAssistant;
  createAssistant: (
    assistant: Omit<OpenAIAssistant, "id">,
    file?: File
  ) => Promise<void>;
  updateAssistant: (assistant: OpenAIAssistant, file?: File) => Promise<void>;
  deleteAssistant: (assistantId: number) => Promise<void>;
  viewingAssistant: boolean;
};

const ALLOWED_EXTENSIONS = ["docx", "html", "htm", "md", "pdf", "txt"];

export const ManageOpenAIAssistantForm: React.FC<Props> = ({
  selectedAssistant,
  createAssistant,
  updateAssistant,
  deleteAssistant,
  viewingAssistant,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [instructions, setInstructions] = useState("");
  const [file, setFile] = useState<File | undefined>();

  const [existingFiles, setExistingFiles] = useState<OpenAIAssistantFile[]>([]);
  const [fetchingExistingFiles, setFetchingExistingFiles] = useState(false);
  const [deletingFile, setDeletingFile] = useState("");

  const existingFilesShouldBeDisplayed =
    selectedAssistant?.has_files &&
    !fetchingExistingFiles &&
    !!existingFiles.length;

  useEffect(() => {
    setExistingFiles([]);
    if (selectedAssistant?.has_files && token) {
      setFetchingExistingFiles(true);
      getOpenAIAssistantFiles(token, selectedAssistant.id)
        .then((files) => setExistingFiles(files))
        .finally(() => setFetchingExistingFiles(false));
    }
  }, [selectedAssistant, token]);

  useEffect(() => {
    if (selectedAssistant) {
      setName(selectedAssistant.name);
      setInstructions(selectedAssistant.instructions);
    }
  }, [selectedAssistant]);

  const isValidFileExtension = useMemo((): boolean => {
    if (!file) {
      return true;
    }
    const extension = file.name.split(".").pop();

    return ALLOWED_EXTENSIONS.includes(extension.toLowerCase());
  }, [file]);

  const deleteFile = async (fileId: string): Promise<void> => {
    setDeletingFile(fileId);
    await deleteOpenAIAssistantFile(token, selectedAssistant.id, fileId)
      .then(() => {
        setExistingFiles(existingFiles.filter(({ id }) => id !== fileId));
      })
      .finally(() => {
        setDeletingFile("");
      });
  };

  return (
    <Form as="div" size="small">
      <Message
        warning
        visible
        size="small"
        icon="exclamation"
        header="This feature is in Beta stage and might not work as intended."
        content="Please report any issues to Textual support at support@textual.ai."
      />
      <Form.Field>
        <label htmlFor="assistant-name-input">Name *</label>
        <Input
          data-testid="assistant-form-name-input"
          autoFocus
          id="assistant-name-input"
          value={name}
          placeholder="Assistant name"
          onChange={(_, { value }): void => setName(value)}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="assistant-instructions-input">Instructions *</label>
        <Text compact color="grey">
          The instructions will tell the assistant how to write the text or how
          the assistant should behave.
        </Text>
        <TextArea
          data-testid="assistant-form-instructions-input"
          id="assistant-instructions-input"
          placeholder="You are a translator who translates from one language to another"
          value={instructions}
          onChange={(_, { value }): void => setInstructions(value as string)}
        />
      </Form.Field>
      <>
        <Divider />
        {existingFilesShouldBeDisplayed && (
          <>
            <Text>
              <b>Files attached to the assistant</b>
            </Text>
            <Card.Group>
              {existingFiles.map((file) => (
                <Card style={{ cursor: "initial" }} key={file.id}>
                  <Card.Content>
                    <Popup
                      content={file.name}
                      size="small"
                      wide="very"
                      mouseEnterDelay={popupDelay}
                      trigger={
                        <Card.Description>
                          {fixLongName(file.name, 30)}
                        </Card.Description>
                      }
                    />
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      loading={deletingFile === file.id}
                      basic
                      size="tiny"
                      compact
                      floated="right"
                      onClick={(): Promise<void> => deleteFile(file.id)}
                    >
                      Remove file
                    </Button>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
            <Divider />
          </>
        )}
        <Form.Field error={!isValidFileExtension}>
          <label>File (Optional)</label>
          <Text color="grey">
            The assistant will read from the uploaded file to create more
            tailored texts to specific needs. (Like a memory)
          </Text>
          <StyledFileDrop
            onDrop={(files): void => {
              if (files.length > 0) {
                setTimeout(() => {
                  setFile(files.item(0));
                }, 200);
              } else {
                setFile(null);
              }
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ visibility: file ? "visible" : "hidden" }}>
                <Text
                  testId="assistant-form-file-name"
                  as="h3"
                  color={isValidFileExtension ? undefined : "red"}
                  textAlignment="center"
                >
                  <Icon
                    name={isValidFileExtension ? "check" : "warning sign"}
                    color={isValidFileExtension ? "green" : "red"}
                  />{" "}
                  {file?.name}
                </Text>
                <Message
                  size="small"
                  data-testid="assistant-form-file-extension-error"
                  error
                  visible={!isValidFileExtension}
                  header={`Invalid file type: "${file?.name.split(".").pop()}"`}
                  content={`Please upload a file with one of the following extensions: ${ALLOWED_EXTENSIONS.join(
                    ", "
                  )}`}
                />
              </span>
              <Text as="h4">Drag your file to upload here.</Text>
              <Text>
                Supported file types: PDF (.pdf), Word (.docx), Plain text
                (.txt)
              </Text>
              <Text color="grey" compact>
                Also supported: HTML (.html/.htm), Markdown (.md)
              </Text>
              <Divider />
              <Button
                color={"red"}
                content={"Select file to upload"}
                size="small"
                onClick={(): void => {
                  fileInputRef.current.click();
                }}
              />
              <div style={{ visibility: file ? "visible" : "hidden" }}>
                <Divider />
                <Button
                  content={"Remove uploaded file"}
                  data-testid="assistant-form-remove-file-button"
                  basic
                  size="small"
                  onClick={(): void => {
                    setFile(null);
                  }}
                />
              </div>
            </div>
          </StyledFileDrop>

          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            data-testid="assistant-form-file-input"
            id="assistant-file-input"
            onChange={({ target }): void => {
              const file = target.files?.[0];
              if (!file) {
                return;
              }
              setFile(file);
            }}
          />
        </Form.Field>
      </>

      <Divider />

      <Button
        data-testid="assistant-form-create-button"
        disabled={!name || !instructions || !isValidFileExtension}
        color="red"
        content={viewingAssistant ? "Update" : "Create"}
        onClick={async (): Promise<void> => {
          if (viewingAssistant) {
            await updateAssistant(
              {
                id: selectedAssistant.id,
                name,
                instructions,
              },
              file
            );
            return;
          }
          await createAssistant(
            {
              name,
              instructions,
            },
            file
          );
        }}
      />
      {viewingAssistant && (
        <Button
          data-testid="assistant-form-delete-button"
          content="Delete"
          basic
          floated="right"
          size="small"
          compact
          onClick={(): Promise<void> => deleteAssistant(selectedAssistant.id)}
        />
      )}
    </Form>
  );
};
