import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Popup } from "semantic-ui-react";
import styled from "styled-components";

import { useGetCustomerQuery } from "../../api/customerApi";
import { getPromptSuggestions } from "../../api/gptApi";
import ManageGrid from "../../components/manage/ManageGrid";
import { Button, Icon } from "../../components/tailwind";
import { Text } from "../../components/Text";
import { renderLanguageFromCode } from "../customerlanguages";
import { PromptSuggestionUpdateOrCreate } from "./PromptSuggestionUpdateOrCreate";
import { PromptSuggestion, popupDelay } from "./types";
import { RootState } from "../../utils/store";

export const ScrollableDiv = styled.div`
  max-height: calc(100vh - 150px);
  overflow-y: auto;
`;

export const TESTID_BASE = "prompt-suggestions";

export const PromptSuggestions: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [suggestions, setSuggestions] = useState<PromptSuggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    PromptSuggestion
  >();
  const { data: customer } = useGetCustomerQuery();

  const [isLoading, setIsLoading] = useState(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getPromptSuggestions(token)
        .then(setSuggestions)
        .finally(() => setIsLoading(false));
    }
  }, [token]);

  const refreshSuggestions = async (unselect: boolean): Promise<void> => {
    setIsLoading(true);
    if (unselect) {
      setShowCreateNew(false);
      setSelectedSuggestion(undefined);
    }
    await getPromptSuggestions(token)
      .then((suggestions) => {
        if (mounted.current) setSuggestions(suggestions);
      })
      .finally(() => {
        if (mounted.current) setIsLoading(false);
      });
  };

  const onCreateNew = (): void => {
    setShowCreateNew(true);
    setSelectedSuggestion(undefined);
  };

  const onCopy = (suggestion: PromptSuggestion): void => {
    const copy = { ...suggestion };
    copy.id = undefined;
    copy.description += " (Copy)";
    if (copy.placeholder) copy.placeholder += "-copy";
    setSelectedSuggestion(copy);
  };

  if (isLoading) {
    return (
      <Dimmer active data-testid={`${TESTID_BASE}-dimmer`}>
        <Loader />
      </Dimmer>
    );
  }
  return (
    <div className="manage-content">
      <ManageGrid testId={`${TESTID_BASE}-container`}>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                wide
                mouseEnterDelay={popupDelay}
                trigger={
                  <span data-testid={`${TESTID_BASE}-left-heading`}>
                    <Text
                      as="h4"
                      compact
                      className="tw-flex tw-gap-2 tw-items-center"
                    >
                      Prompt Snippets
                      <Icon name="help" className="tw-text-xl" />
                    </Text>
                  </span>
                }
              >
                <Popup.Content>
                  <Text compact>
                    A Prompt Snippet is an instruction that can be inserted into
                    a prompt before generation. You can add them to a prompt in
                    the Copy Assistant view, below the Instruction.
                  </Text>
                  <Text compact>
                    Prompt Snippets can also be <b>dynamic</b>, meaning that the
                    inserted text is updated automatically every time the prompt
                    is run.
                  </Text>
                </Popup.Content>
              </Popup>
            }
            right={
              <Button
                data-testid={`${TESTID_BASE}-create-button`}
                variant="primary-alt"
                icon
                compact
                data-balloon="Create new Prompt Snippet"
                data-balloon-pos="left"
                onClick={onCreateNew}
              >
                <Icon name="add" className="tw-text-lg tw-leading-[1.25]" />
              </Button>
            }
          />
          <ManageGrid.Header.RightColumn>
            {(showCreateNew || selectedSuggestion) && (
              <span data-testid={`${TESTID_BASE}-right-heading`}>
                <Text
                  as="h4"
                  compact
                  className="tw-flex tw-gap-2 tw-items-center"
                >
                  {selectedSuggestion?.id ? (
                    <>Updating: {selectedSuggestion.description}</>
                  ) : (
                    <>Create new</>
                  )}
                </Text>
              </span>
            )}
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <ul
              className="tw-flex tw-flex-col tw-gap-2 tw-divide-gray-200 tw-overflow-auto"
              style={{ maxHeight: "calc(100vh - 150px)" }}
              data-testid={`${TESTID_BASE}-list`}
            >
              {suggestions.map((suggestion) => {
                return (
                  <li
                    aria-selected={
                      selectedSuggestion?.id === suggestion.id
                        ? "true"
                        : "false"
                    }
                    key={suggestion.id}
                    className="tw-flex tw-gap-2 tw-cursor-pointer tw-rounded-lg tw-px-3 tw-py-3 tw-font-semibold tw-text-blue-500 tw-transition-all hover:tw-bg-gray-100 aria-selected:tw-bg-gray-100 aria-selected:tw-font-bold"
                    onClick={(): void => setSelectedSuggestion(suggestion)}
                  >
                    <div>
                      {renderLanguageFromCode(
                        suggestion.language,
                        customer?.languages ?? [],
                        true,
                        false
                      )}
                    </div>
                    <div>{suggestion.description}</div>
                    <span
                      data-balloon="Copy"
                      data-balloon-pos="left"
                      className="tw-ml-auto"
                    >
                      <Button
                        compact
                        variant="primary-alt"
                        icon
                        size="sm"
                        onClick={(event): void => {
                          onCopy(suggestion);
                          event.stopPropagation();
                        }}
                      >
                        <Icon name="content_copy" />
                      </Button>
                    </span>
                  </li>
                );
              })}
            </ul>
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {showCreateNew || selectedSuggestion ? (
              <PromptSuggestionUpdateOrCreate
                selectedSuggestion={selectedSuggestion}
                setSelectedSuggestion={setSelectedSuggestion}
                refreshSuggestions={refreshSuggestions}
              />
            ) : (
              <ManageGrid.Body.EmptyForm>
                {!!suggestions?.length && (
                  <>
                    <Text as="h2" compact>
                      Select a Prompt Snippet from the list
                    </Text>
                    <Text as="h3" color="grey" compact>
                      Or
                    </Text>
                  </>
                )}
                <Button variant="primary" size="lg" icon onClick={onCreateNew}>
                  <Icon name="add" />
                  Create new Prompt Snippet
                </Button>
              </ManageGrid.Body.EmptyForm>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
    </div>
  );
};
