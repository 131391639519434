import React from "react";
import { Grid, Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class SingleSelectorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      options: [],
    };
  }

  componentDidMount() {
    const { initialData, onLoaded, availableChoices } = this.props;
    onLoaded(this);
    const choices = availableChoices || initialData;
    const options =
      choices &&
      choices.length > 0 &&
      choices.map((item, index) => {
        return { key: index, text: item.label, value: item.value };
      });
    this.setState({
      value: initialData,
      options: options || [],
    });
  }

  componentDidUpdate(prevProps) {
    const { initialData } = this.props;
    if (prevProps.initialData !== initialData) {
      this.setState({
        value: initialData,
      });
    }
  }

  handleChangeLabel = (e, { value }) => {
    const { onDataChanged } = this.props;
    const { options } = this.state;
    if (value.length > 0) {
      const matchedIndex = options.findIndex(
        (option) => option.value === value
      );
      if (matchedIndex === -1 || options.length === 0) {
        options.push({
          key: value.length - 1,
          text: value,
          value: value,
        });
      }
    }
    this.setState({ value: value, options });
    onDataChanged(this, value);
  };

  render() {
    const { componentId, disabled, allowAdditions } = this.props;
    const { options, value } = this.state;
    return (
      <Grid>
        <Dropdown
          disabled={disabled}
          multiple={false}
          id={componentId}
          options={options}
          placeholder="Type to search"
          search
          selection
          fluid
          allowAdditions={allowAdditions}
          value={value}
          onChange={this.handleChangeLabel}
          clearable
          selectOnBlur={false}
          closeOnBlur={true}
          closeOnChange
        />
      </Grid>
    );
  }
}

SingleSelectorField.defaultProps = {
  // Initial data of the component
  initialData: "",
  // Whether the component is disabled for user or not
  disabled: false,
  // Determines if user can add own item to the dropdown
  allowAdditions: false,
};

SingleSelectorField.propTypes = {
  initialData: PropTypes.string,
  token: PropTypes.string,
  componentId: PropTypes.string,
  onLoaded: PropTypes.func,
  onDataChanged: PropTypes.func,
  disabled: PropTypes.bool,
  allowAdditions: PropTypes.bool,
};
