import { ProductText } from "../producttext/ProductText";
import { ProductId } from "../products/product";
import { ProductPublishTaskResult } from "./TaskDispatcher";
import { RegenerateConfigRequest } from "../api/action";
import { UserActionContext } from "../products/publish/actionTypes";
import { LanguageCode } from "../customers/customerlanguages";

export enum TaskResultType {
  PRODUCT_TEXT = "PRODUCT_TEXT",
  PUBLISH = "PUBLISH",
  MAPPINGS_DOWNLOAD = "MAPPINGS_DOWNLOAD",
}

export enum CeleryTaskStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export type BaseTaskResult = {
  type: TaskResultType;
};
export type ProductTextTaskResults = BaseTaskResult & {
  type: TaskResultType.PRODUCT_TEXT;
  errors: any[];
  product_text: ProductText | null;
};
export type MappingsDownloadTaskResult = BaseTaskResult & {
  presigned_url: string | null;
};

export type FailureTaskResult = {
  error: string;
};
export type TaskResultPending = {
  status: CeleryTaskStatus.PENDING;
  results: null;
};
export type TaskResultSuccess = {
  status: CeleryTaskStatus.SUCCESS;
  results:
    | ProductTextTaskResults
    | ProductPublishTaskResult
    | MappingsDownloadTaskResult;
};
export type TaskResultFailure = {
  status: CeleryTaskStatus.FAILURE;
  results: FailureTaskResult;
};
export type TaskResult =
  | TaskResultSuccess
  | TaskResultPending
  | TaskResultFailure;

export interface GenerateRequestItem {
  channel_id: number;
  language_code: LanguageCode;
  product_id: ProductId;
  user_action_context: UserActionContext;
  regenerate_config?: RegenerateConfigRequest;
}

export type PublishResult = {
  message: string;
  productTexts: ProductText[] | null | undefined;
};

export type MappingsDownloadResult = {
  presigned_url: string;
};

export abstract class TaskRequest<T> {
  resultType: TaskResultType;
  resolve?: (status: T) => void;
  reject?: (error: string) => void;
  promise: Promise<T>;
  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  abstract taskFinished(taskResult: BaseTaskResult): void;
}

export interface TextGeneratorError {
  message: string;
  level: string;
}
