import { SelectValue } from "./ChannelEditForm";

export type ChangedValue = string | number | boolean | SelectValue[];
export type IndexedObject = { [index: string]: any };

const checkDiffArray = (
  original: SelectValue[],
  changed: SelectValue[],
  key: string,
  parentKeys: string[] = []
): IndexedObject => {
  const changes = [];
  const originalIndex = original.findIndex(({ selected }) => selected);
  const changedIndex = changed.findIndex(({ selected }) => selected);

  if (originalIndex !== changedIndex) {
    changes.push({
      originalValue: original,
      changedValue: changed,
      key,
      parentKeys,
    });
  }
  return changes;
};

export const checkDiff = (
  original: IndexedObject,
  changed: IndexedObject | undefined,
  parentKeys: string[] = []
): IndexedObject[] => {
  const changes: IndexedObject[] = [];

  if (!changed) return [];

  Object.keys(original).forEach((key) => {
    if (!(key in changed)) {
      return;
    }
    if (original[key] !== changed[key]) {
      if (Array.isArray(original[key])) {
        const changesInArray = checkDiffArray(
          original[key],
          changed[key],
          key,
          parentKeys
        );
        if (changesInArray) changes.push(changesInArray);
        return;
      }
      if (typeof original[key] === "object") {
        changes.push(
          checkDiff(original[key], changed[key], [...parentKeys, key])
        );
        return;
      } else {
        changes.push({
          originalValue: original[key],
          changedValue: changed[key],
          key,
          parentKeys,
        });
      }
    }
  });

  return changes.reduce((acc, val) => acc.concat(val), []);
};

export const changeObjectValues = (
  object: IndexedObject,
  keyToLookFor: string,
  changedValue: ChangedValue,
  parentKeys: string[] = null
): IndexedObject => {
  if (parentKeys?.length) {
    parentKeys.forEach((pk) => {
      parentKeys.splice(0, 1);
      changeObjectValues(object[pk], keyToLookFor, changedValue, parentKeys);
    });
  } else {
    object[keyToLookFor] = changedValue;
  }

  return object;
};

export const createShortConfig = (
  object: IndexedObject,
  parentKeys: string[],
  key: string,
  changedValue: ChangedValue
): IndexedObject => {
  const shortConfig: IndexedObject = object;
  if (!parentKeys.length) object[key] = changedValue;
  parentKeys.forEach((k: string) => {
    parentKeys.splice(0, 1);
    if (!(k in object)) {
      shortConfig[k] = {};
    }

    createShortConfig(shortConfig[k], parentKeys, key, changedValue);
  });
  return shortConfig;
};
