import React from "react";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

import { RequestWordModalAutomaticEnglishTranslationSuggestion } from "./RequestWordModalAutomaticEnglishTranslationSuggestion";
import { RootState } from "../../utils/store";

type Props = {
  englishTranslation: string;
  onEnglishTranslationInputChange: (value: string) => void;
  suggestedEnglishText: string;
};

export const RequestWordModalAutomaticEnglishTranslation: React.FC<Props> = ({
  englishTranslation,
  onEnglishTranslationInputChange,
  suggestedEnglishText,
}) => {
  const englishLanguageCustomer = useSelector(
    (state: RootState) => state.requestWordModal.englishLanguageCustomer
  );
  const remindEnglish = useSelector(
    (state: RootState) => state.requestWordModal.remindEnglish
  );

  return (
    <div data-testid={"request-word-modal-automatic-english-translation"}>
      {!englishLanguageCustomer && (
        <RequestWordModalAutomaticEnglishTranslationSuggestion
          englishTranslation={englishTranslation}
          onEnglishTranslationInputChange={onEnglishTranslationInputChange}
          suggestedEnglishText={suggestedEnglishText}
        />
      )}
      {remindEnglish && (
        <Message
          content="We need this to provide you with accurate translations."
          header="Please provide an English translation"
          warning
          data-testid="no-english-example-message"
        />
      )}
    </div>
  );
};
