import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { fetchVocabularyProductCountResponse } from "../api/action";
import { fetchVocabularyProductCount } from "../api/action";
import { Spinner } from "../utils/Spinner";

type Props = {
  vocabularyId: number;
};

export const VocabularyProductCountButton: React.FC<Props> = ({
  vocabularyId,
}) => {
  const [loading, setLoading] = useState(false);
  const [productCount, setProductCount] = useState<number>(null);
  const [productUrl, setProductUrl] = useState<string>(null);
  const [showButton, setShowButton] = useState(true);

  const token = useSelector((state: RootState) => state.auth.token);

  const productCountMessage = (productCount: number): JSX.Element => {
    if (productCount) {
      return (
        <p>
          This vocabulary item is used in{" "}
          <a href={productUrl} rel="noreferrer" target="_blank">
            {productCount} products
          </a>
        </p>
      );
    } else {
      return <p>This vocabulary item is not used by any products</p>;
    }
  };

  const handleOnClick = (): void => {
    setLoading(true);
    fetchVocabularyProductCount({ token, vocabularyId }).then(
      (result: fetchVocabularyProductCountResponse) => {
        setProductCount(result.product_count);
        setProductUrl(result.products_url);
        setLoading(false);
        setShowButton(false);
      }
    );
  };

  if (loading) {
    return <Spinner align="left" />;
  }
  if (showButton) {
    return (
      <Button
        basic
        content={<b>Show Product Count</b>}
        loading={loading}
        onClick={(): void => handleOnClick()}
        size="tiny"
        type="button"
      />
    );
  } else {
    return productCountMessage(productCount);
  }
};
