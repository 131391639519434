import React from "react";
import { AlertMessageType } from "./Alerts";
import { HTML } from "../components/HTML";

// consistent with app/alerts/models.py:Alert.ALERT_TYPES
export enum AlertContentType {
  CRITICAL = "critical",
  INFO = "info",
  WARNING = "warning",
}

type AlertStylingDetails = {
  color: string;
  icon: string;
};

export const AlertBoxStyling: Record<AlertContentType, AlertStylingDetails> = {
  [AlertContentType.CRITICAL]: {
    color: "txu-red",
    icon: "error",
  },
  [AlertContentType.INFO]: {
    color: "txu-blue",
    icon: "info",
  },
  [AlertContentType.WARNING]: {
    color: "txu-yellow",
    icon: "warning",
  },
};

type Props = { onDismiss?: (id: number) => void } & AlertMessageType;

export const Alert: React.FC<Props> = ({
  id,
  message,
  alert_type,
  onDismiss,
}) => {
  const { color, icon } = AlertBoxStyling[alert_type];
  return (
    <div className={`txu-alert ${color}`}>
      <i className="material-icons">{icon}</i>
      <HTML text={message} />
      <i
        className="material-icons txu-close"
        data-balloon="Close"
        data-balloon-pos="down-right"
        onClick={(): void => onDismiss?.(id)}
      >
        close
      </i>
    </div>
  );
};
