import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button, Icon, List, Popup } from "semantic-ui-react";
import { Fieldset } from "./types";
import { popupDelay } from "../../../customers/gpt/types";

const OFFSET = 180;
type Props = {
  defaultFieldset: Fieldset;
  fieldsets: Fieldset[];
  selectedFieldset: Fieldset | undefined;
  setSelectedFieldset: (fieldset: Fieldset) => void;
  setIsCopying: Dispatch<SetStateAction<boolean>>;
  isCopying: boolean;
};

export const ListFieldsets: React.FC<Props> = ({
  defaultFieldset,
  fieldsets,
  selectedFieldset,
  setSelectedFieldset,
  setIsCopying,
  isCopying,
}) => {
  const clickedCopy = useRef(false);
  const [height, setHeight] = useState<number>(0);
  const handleWindowResize = useCallback(() => {
    const windowHeight = document.documentElement.clientHeight;
    const windowScrollHeight = document.documentElement.scrollHeight;
    const scrollOffset = windowScrollHeight - windowHeight;
    setHeight(windowHeight - scrollOffset - OFFSET);
  }, []);
  useLayoutEffect(() => {
    if (height === 0) {
      handleWindowResize();
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <List
      selection
      divided
      verticalAlign="middle"
      data-testid={"list-fieldsets"}
      style={{
        height: `${height}px`,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {[defaultFieldset, ...fieldsets].map((fieldset) => (
        <List.Item
          key={fieldset.id}
          active={fieldset.id === selectedFieldset?.id}
          data-testid={`select-fieldset-update-${fieldset.name}`}
          onClick={(): void => {
            if (isCopying && !clickedCopy.current) {
              setIsCopying(false);
            }
            clickedCopy.current = false;
            setSelectedFieldset(fieldset);
          }}
          relaxed="very"
          style={{ display: "flex", alignItems: "center" }}
        >
          <List.Content style={{ paddingLeft: "0.5em" }}>
            <List.Header as="a" className="tw-no-underline">
              {fieldset.name}
            </List.Header>
          </List.Content>
          <List.Content
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {fieldset.use_as_default && (
              <Popup
                content="Default Input schema"
                size="tiny"
                position="bottom right"
                trigger={
                  <Icon
                    style={{ marginBottom: "0.2em" }}
                    name="check"
                    data-testid={"default-fieldset"}
                    color="blue"
                  />
                }
              />
            )}
            <Popup
              size="tiny"
              content="Copy group"
              position="bottom right"
              mouseEnterDelay={popupDelay}
              trigger={
                <Button
                  data-testid={`select-fieldset-copy-${fieldset.name}`}
                  color="red"
                  basic
                  compact
                  size="mini"
                  icon="copy"
                  onClick={(): void => {
                    clickedCopy.current = true;
                    setIsCopying(true);
                  }}
                />
              }
            />
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};
