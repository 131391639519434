import { LanguageCode } from "../customers/customerlanguages";
import {
  CopyAssistantLangStringTemplate,
  CreatePrompt,
  GptModel,
  OpenAIAssistant,
  OpenAIAssistantFile,
  Prompt,
  PromptGroup,
} from "../products/copy-assistant/types";
import { ProductId } from "../products/product";
import { LangStringObject } from "../vocabulary/LangString";
import api from "./api";
import {
  PromptSelectionRule,
  PromptSelectionCondition,
  ConditionOperator,
  PromptSuggestion,
} from "../customers/gpt/types";
import { TaskQueue, TaskId } from "./taskQueueApi";

export type GetModelsResponse = GptModel[];

export const MODEL_BASE_URL = "/api/_internal/gpt/model/";

export async function getModels(token: string): Promise<GetModelsResponse> {
  const response = await api.get<GetModelsResponse>(MODEL_BASE_URL, {
    params: {},
    headers: { token },
  });
  return response.data;
}

export const ASSISTANT_BASE_URL = "/api/_internal/gpt/assistant/";

export async function getOpenAIAssistants(
  token: string
): Promise<OpenAIAssistant[]> {
  const response = await api.get<OpenAIAssistant[]>(ASSISTANT_BASE_URL, {
    headers: { token },
  });
  return response.data;
}

export async function getOpenAIAssistant(
  token: string,
  id: number
): Promise<OpenAIAssistant> {
  const response = await api.get<OpenAIAssistant>(ASSISTANT_BASE_URL + id, {
    headers: { token },
  });
  return response.data;
}

export async function createOpenAIAssistant(
  token: string,
  name: string,
  instructions: string,
  file?: File
): Promise<OpenAIAssistant> {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  formData.append("name", name);
  formData.append("instructions", instructions);

  const response = await api.post<OpenAIAssistant>(
    ASSISTANT_BASE_URL,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    }
  );
  return response.data;
}

export async function updateOpenAIAssistant(
  token: string,
  id: number,
  name: string,
  instructions: string,
  file?: File
): Promise<OpenAIAssistant> {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  formData.append("name", name);
  formData.append("instructions", instructions);

  const response = await api.put<OpenAIAssistant>(
    ASSISTANT_BASE_URL + id,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    }
  );
  return response.data;
}
export async function deleteOpenAIAssistant(
  token: string,
  id: number
): Promise<unknown> {
  const response = await api.delete<unknown>(ASSISTANT_BASE_URL + id, {
    headers: { token },
  });
  return response.data;
}

export const ASSISTANT_FILE_BASE_URL = "/api/_internal/gpt/assistant/file/";

export async function getOpenAIAssistantFiles(
  token: string,
  id: number
): Promise<OpenAIAssistantFile[]> {
  const response = await api.get<OpenAIAssistantFile[]>(
    ASSISTANT_FILE_BASE_URL + id,
    { headers: { token } }
  );
  return response.data;
}

export async function deleteOpenAIAssistantFile(
  token: string,
  assistantId: number,
  fileId: string
): Promise<unknown> {
  const response = await api.delete<unknown>(
    `${ASSISTANT_FILE_BASE_URL}${assistantId}/${fileId}`,
    { headers: { token } }
  );
  return response.data;
}

export type GetPromptsResponse = {
  prompts: Prompt[];
  selected_prompt: Prompt | null;
  selected_prompt_group: PromptGroup | null;
  use_channel_as_default_fallback?: boolean;
};

export const PROMPT_BASE_URL = "/api/_internal/gpt/prompt/";

type GetPromptParams = {
  product_id?: ProductId;
  only_translation_prompts?: boolean;
};

type GetPromptArgs = {
  productId?: ProductId;
  onlyTranslationPrompts?: boolean;
};
export async function getPrompts(
  token: string,
  { productId, onlyTranslationPrompts }: GetPromptArgs = {}
): Promise<GetPromptsResponse> {
  const params: GetPromptParams = {};
  if (productId) {
    params["product_id"] = productId;
  }

  if (onlyTranslationPrompts) {
    params["only_translation_prompts"] = true;
  }
  const response = await api.get<GetPromptsResponse>(PROMPT_BASE_URL, {
    params: params,
    headers: { token },
  });
  return response.data;
}

export async function createPrompt(
  token: string,
  prompt: CreatePrompt,
  createSystemPrompt?: boolean
): Promise<Prompt> {
  const response = await api.post<Prompt>(
    PROMPT_BASE_URL,
    { ...prompt, create_system_prompt: createSystemPrompt },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updatePrompt(
  token: string,
  prompt: Prompt,
  updateSystemPrompt?: boolean
): Promise<Prompt> {
  const response = await api.put<Prompt>(
    `${PROMPT_BASE_URL}${prompt.id}`,
    { ...prompt, update_system_prompt: updateSystemPrompt },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function deletePrompt(
  token: string,
  id: number,
  deleteSystemPrompt?: boolean
): Promise<unknown> {
  const response = await api.delete<unknown>(
    `${PROMPT_BASE_URL}${id}?delete_system_prompt=${
      deleteSystemPrompt || false
    }`,
    {
      headers: { token },
    }
  );
  return response.data;
}

export const PROMPT_GROUP_BASE_URL = "/api/_internal/gpt/prompt-group/";

export async function getPromptGroups(token: string): Promise<PromptGroup[]> {
  const response = await api.get<PromptGroup[]>(PROMPT_GROUP_BASE_URL, {
    headers: { token },
  });
  return response.data;
}

export async function createPromptGroup(
  token: string,
  group: Omit<PromptGroup, "id">
): Promise<PromptGroup> {
  const response = await api.post<PromptGroup>(
    PROMPT_GROUP_BASE_URL,
    { ...group },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updatePromptGroup(
  token: string,
  group: PromptGroup
): Promise<PromptGroup> {
  const response = await api.put<PromptGroup>(
    PROMPT_GROUP_BASE_URL + `${group.id}`,
    {
      name: group.name,
      prompt_ids: group.prompt_ids,
      tags: group.tags,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function deletePromptGroup(
  token: string,
  id: number
): Promise<unknown> {
  const response = await api.delete<unknown>(`${PROMPT_GROUP_BASE_URL}${id}`, {
    headers: { token },
  });
  return response;
}

export const PROMPT_SUGGESTIONS_BASE_URL =
  "/api/_internal/gpt/prompt-suggestions";

export async function getPromptSuggestions(
  token: string,
  language?: LanguageCode,
  includeGlobal: boolean = false
): Promise<PromptSuggestion[]> {
  const response = await api.get<{ suggestions: PromptSuggestion[] }>(
    PROMPT_SUGGESTIONS_BASE_URL,
    {
      params: { language, include_global: includeGlobal },
      headers: { token: token },
    }
  );
  return response.data.suggestions;
}

export async function createPromptSuggestion(
  token: string,
  promptSuggestion: Omit<PromptSuggestion, "id">
): Promise<PromptSuggestion> {
  const data = promptSuggestion;
  const response = await api.post<PromptSuggestion>(
    PROMPT_SUGGESTIONS_BASE_URL + "/create",
    data,
    { headers: { token } }
  );
  return response.data;
}

export async function updatePromptSuggestion(
  token: string,
  promptSuggestion: PromptSuggestion
): Promise<PromptSuggestion> {
  const data = promptSuggestion;
  const response = await api.put<PromptSuggestion>(
    PROMPT_SUGGESTIONS_BASE_URL + "/update",
    data,
    { headers: { token } }
  );
  return response.data;
}

export async function deletePromptSuggestion(
  token: string,
  id: number
): Promise<unknown> {
  const data = {
    id,
  };
  const response = await api.post<unknown>(
    PROMPT_SUGGESTIONS_BASE_URL + "/delete",
    data,
    { headers: { token } }
  );
  return response;
}

export const PROMPT_SELECTION_RULE_BASE_URL =
  "/api/_internal/gpt/prompt-selection-rules";

export async function getPromptSelectionRules(
  token: string
): Promise<PromptSelectionRule[]> {
  const response = await api.get<PromptSelectionRule[]>(
    PROMPT_SELECTION_RULE_BASE_URL + "/get",
    { headers: { token } }
  );
  return response.data;
}

export async function createPromptSelectionRules(
  token: string,
  name: string,
  promptId: number | null,
  promptGroupId: number | null,
  conditions: PromptSelectionCondition[],
  conditionOperator: ConditionOperator
): Promise<PromptSelectionRule> {
  const data = {
    name,
    prompt_id: promptId,
    prompt_group_id: promptGroupId,
    conditions,
    condition_operator: conditionOperator,
  };
  const response = await api.post<PromptSelectionRule>(
    PROMPT_SELECTION_RULE_BASE_URL + "/create",
    data,
    { headers: { token } }
  );
  return response.data;
}

export async function updatePromptSelectionRules(
  token: string,
  name: string,
  promptId: number | null,
  promptGroupId: number | null,
  conditions: PromptSelectionCondition[],
  conditionOperator: ConditionOperator,
  id: number
): Promise<PromptSelectionRule> {
  const data = {
    name,
    prompt_id: promptId,
    prompt_group_id: promptGroupId,
    conditions,
    condition_operator: conditionOperator,
    id,
  };
  const response = await api.put<PromptSelectionRule>(
    PROMPT_SELECTION_RULE_BASE_URL + "/update",
    data,
    { headers: { token } }
  );
  return response.data;
}

export async function deletePromptSelectionRules(
  token: string,
  id: number
): Promise<unknown> {
  const data = {
    id,
  };
  const response = await api.post<unknown>(
    PROMPT_SELECTION_RULE_BASE_URL + "/delete",
    data,
    { headers: { token } }
  );
  return response;
}

export async function reorderPromptSelectionRules(
  token: string,
  id: number,
  order: number
): Promise<unknown> {
  const data = {
    id,
    order,
  };
  const response = await api.post<unknown>(
    PROMPT_SELECTION_RULE_BASE_URL + "/reorder",
    data,
    { headers: { token } }
  );
  return response;
}

export async function searchKnownKeysPromptSelectionRules(
  token: string,
  searchString: string
): Promise<string[]> {
  const response = await api.get<string[]>(
    PROMPT_SELECTION_RULE_BASE_URL + `/search-known-keys/?s=${searchString}`,
    { headers: { token } }
  );
  return response.data;
}

export async function addNewKnownKeyPromptSelectionRules(
  token: string,
  key: string
): Promise<unknown> {
  const data = {
    key,
  };
  const response = await api.post<unknown>(
    PROMPT_SELECTION_RULE_BASE_URL + "/add-new-known-key",
    data,
    { headers: { token } }
  );
  return response;
}

export const GPT_GENERATE_BASE_URL = "/api/_internal/gpt/generate/";

export async function gptGenerateOne(
  token: string,
  productId: ProductId,
  inputData: string,
  promptId?: number,
  alteredInstruction?: string
): Promise<LangStringObject[]> {
  const response = await api.post<LangStringObject[]>(
    GPT_GENERATE_BASE_URL + "one",
    {
      product_id: productId,
      prompt_id: promptId,
      instruction: alteredInstruction,
      input_data: inputData,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}
type GenerateManySentencesResponse = {
  sentences_batch: GenerateOneResponse;
  prompt_id: number;
  language: LanguageCode;
  error: string | null;
};
export async function gptGenerateMany(
  token: string,
  productId: ProductId,
  groupId: number,
  alteredInstructions: { [key: number]: string },
  InputsData: { [key: number]: string }
): Promise<GenerateManySentencesResponse[]> {
  const response = await api.post<GenerateManySentencesResponse[]>(
    GPT_GENERATE_BASE_URL + "many",
    {
      product_id: productId,
      group_id: groupId,
      instructions: alteredInstructions,
      inputs_data: InputsData,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function getCustomerLangStringTemplates(
  token: string
): Promise<CopyAssistantLangStringTemplate[]> {
  const response = await api.get<CopyAssistantLangStringTemplate[]>(
    "/api/_internal/gpt/get-lang-string-templates",
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function getPromptInputText(
  token: string,
  productId: ProductId,
  promptId?: number
): Promise<{ text: string }> {
  const response = await api.get<{
    text: string;
  }>(`${PROMPT_BASE_URL}input-text`, {
    headers: { token },
    params: {
      product_id: productId,
      prompt_id: promptId,
    },
  });
  return response.data;
}

type AddSentenceToProductRequest = {
  product: ProductId;
  value: LangStringObject;
  prompt_id: number;
  replaceable_tag_id?: string;
};
export type AddSentenceToProductResponse = {
  added_tag_id: string;
  lang_string_values: LangStringObject;
  template_display_name: string;
  template_id: number;
};

export type AddSentenceToProductTextBlockResponse = {
  text_block_id: string;
  document_section_id: number;
  document_section_name: string;
  lang_string_values: LangStringObject;
};

export async function gptAddSentences(
  productId: ProductId,
  value: LangStringObject,
  token: string,
  promptId: number,
  replaceableTagId?: string
): Promise<
  AddSentenceToProductResponse | AddSentenceToProductTextBlockResponse
> {
  const data: AddSentenceToProductRequest = {
    product: productId,
    value,
    prompt_id: promptId,
    replaceable_tag_id: replaceableTagId,
  };
  const response = await api.post(`/api/_internal/gpt/add-sentences/`, data, {
    headers: { token: token },
  });
  return response.data;
}

type RemoveSentenceToProductRequest = {
  product: ProductId;
  tag_id: string;
};

export async function gptRemoveSentence(
  productId: ProductId,
  tagId: string,
  token: string
): Promise<unknown> {
  // If it is successful it does not return anything but the status, if it fails the error handler catches it
  const data: RemoveSentenceToProductRequest = {
    product: productId,
    tag_id: tagId,
  };
  const response = await api.post(`/api/_internal/gpt/remove-sentence/`, data, {
    headers: { token: token },
  });
  return response;
}

export type GPTTemplate = {
  id: number;
  name: string;
  displayName: string;
};

type ChangeTagValueParams = {
  productId: string;
  templateId: number;
  textBlockId: string;
  tagId: string;
  value: LangStringObject;
  token: string;
};
export async function gptChangeTagValue({
  productId,
  tagId,
  value,
  templateId,
  textBlockId,
  token,
}: ChangeTagValueParams): Promise<unknown> {
  const data = {
    product: productId,
    template_id: templateId,
    tag_id: tagId,
    text_block_id: textBlockId,
    value,
  };
  return api.post("/api/_internal/gpt/change-tag-value", data, {
    headers: { token: token },
  });
}

export type ProductSpecificOverwrite = {
  product_id: ProductId;
  input_data: string;
  image_url: string;
  prompt_id: number;
  group_id: number | undefined;
};

export const PRODUCT_SPECIFIC_OVERWRITE_BASE_URL =
  "/api/_internal/gpt/product-specific-overwrites/";

export async function getProductSpecificOverwrites(
  token: string,
  productId: ProductId
): Promise<ProductSpecificOverwrite[]> {
  const response = await api.get<ProductSpecificOverwrite[]>(
    PRODUCT_SPECIFIC_OVERWRITE_BASE_URL + productId,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function getAllProductSpecificOverwrites(
  token: string
): Promise<ProductSpecificOverwrite[]> {
  const response = await api.get<ProductSpecificOverwrite[]>(
    PRODUCT_SPECIFIC_OVERWRITE_BASE_URL,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function createProductSpecificOverwrite(
  token: string,
  overwrite: Partial<ProductSpecificOverwrite> & { prompt_id: number }
): Promise<ProductSpecificOverwrite> {
  const response = await api.post<ProductSpecificOverwrite>(
    PRODUCT_SPECIFIC_OVERWRITE_BASE_URL,
    overwrite,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function updateProductSpecificOverwrite(
  token: string,
  productId: ProductId,
  overwrite: Partial<ProductSpecificOverwrite> & { prompt_id: number }
): Promise<ProductSpecificOverwrite> {
  const response = await api.put<ProductSpecificOverwrite>(
    PRODUCT_SPECIFIC_OVERWRITE_BASE_URL + productId,
    overwrite,
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function deleteProductSpecificOverwrite(
  token: string,
  productId: ProductId,
  promptId?: number
): Promise<unknown> {
  let url = PRODUCT_SPECIFIC_OVERWRITE_BASE_URL + productId;
  if (promptId) {
    url += "/" + promptId;
  }
  const response = await api.delete<unknown>(url, {
    headers: { token },
  });
  return response.data;
}

export async function generateQuickAction(
  token: string,
  id: number,
  translate: boolean,
  selectionBucketName: string,
  returnUrl: string
): Promise<string> {
  const response = await api.post<string>(
    GPT_GENERATE_BASE_URL + "async/generate-quick-action",
    {
      prompt_or_group_id: id,
      translate,
      selection_bucket_name: selectionBucketName,
      return_url: returnUrl,
    },
    {
      headers: { token },
    }
  );

  return response.data;
}

type GenerateOneResponse = {
  generated_texts: LangStringObject[];
  seconds_spent_generating: number;
};

export class GptGenerationTaskQueue extends TaskQueue<GenerateOneResponse> {
  mapPromptIdToTaskId: { [promptId: number | undefined]: TaskId } = {};
  constructor(token: string, pollInterval?: number) {
    super(token, pollInterval, "Generation Cancelled.");
  }

  promptIsGenerating(promptId: number | undefined): boolean {
    return this.mapPromptIdToTaskId[promptId] !== undefined;
  }

  private getTaskIdForPrompt(promptId: number | undefined): string | undefined {
    return this.mapPromptIdToTaskId[promptId];
  }

  async cancel(promptId: number): Promise<unknown> {
    const taskId = this.getTaskIdForPrompt(promptId);
    if (!taskId) {
      console.warn("Task id not found");
      return;
    }
    try {
      await super.cancelTask(taskId);
      delete this.mapPromptIdToTaskId[promptId];
    } catch {
      console.warn("Error cancelling task");
    }
    return;
  }

  cancelMany(promptIds: number[]): Promise<unknown> {
    const cancelling = promptIds.map((promptId) => this.cancel(promptId));
    return Promise.all(cancelling);
  }

  async generateOne(
    productId: ProductId,
    inputData: string,
    promptId?: number,
    alteredInstruction?: string,
    imageUrl?: string,
    stream?: boolean,
    updateResult?: (
      result: { generated_texts: LangStringObject[]; streaming: boolean } | null
    ) => void
  ): Promise<GenerateOneResponse> {
    if (this.promptIsGenerating(promptId)) {
      console.warn(
        "Prompt is already generating, Please wait for it to finish"
      );
      return;
    }
    const response = await api.post<TaskId>(
      GPT_GENERATE_BASE_URL + "async/one",
      {
        product_id: productId,
        prompt_id: promptId,
        instruction: alteredInstruction,
        input_data: inputData,
        image_url: imageUrl,
        stream,
      },
      {
        headers: { token: this.token },
      }
    );
    const taskId = response.data;
    this.mapPromptIdToTaskId[promptId] = taskId;
    this.addTaskId(taskId, "GPT Generation");
    const result = await this.waitUntilTaskIsDone(taskId, updateResult).finally(
      () => {
        delete this.mapPromptIdToTaskId[promptId];
      }
    );

    return result;
  }

  async generateMany(
    productId: ProductId,
    promptIds: number[],
    promptLanguages: { [key: number]: LanguageCode },
    alteredInstructions: { [key: number]: string },
    InputsData: { [key: number]: string },
    imageUrls?: { [key: number]: string }
  ): Promise<GenerateManySentencesResponse[]> {
    const response = promptIds.map((promptId) => {
      return this.generateOne(
        productId,
        InputsData[promptId],
        promptId,
        alteredInstructions[promptId],
        imageUrls[promptId]
      ).then((result) => {
        return {
          sentences_batch: result,
          prompt_id: promptId,
          language: promptLanguages[promptId],
          error: null,
        };
      });
    });

    return await Promise.all(response);
  }

  async generateRewrite(
    rewritePromptId: number,
    inputData: string,
    languageCode: LanguageCode,
    keepHtml?: boolean
  ): Promise<GenerateOneResponse> {
    if (this.promptIsGenerating(rewritePromptId)) {
      console.warn(
        "Prompt is already generating, Please wait for it to finish"
      );
      return;
    }
    const response = await api.post<TaskId>(
      GPT_GENERATE_BASE_URL + "async/rewrite",
      {
        rewrite_prompt_id: rewritePromptId,
        input_data: inputData,
        language_code: languageCode,
        keep_html: keepHtml,
      },
      {
        headers: { token: this.token },
      }
    );
    const taskId = response.data;
    this.mapPromptIdToTaskId[rewritePromptId] = taskId;
    this.addTaskId(taskId, "GPT Rewrite Generation");
    const result = await this.waitUntilTaskIsDone(taskId).finally(() => {
      delete this.mapPromptIdToTaskId[rewritePromptId];
    });

    return result;
  }
}
