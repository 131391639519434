import React from "react";
import { OpenAIAssistant } from "../../../products/copy-assistant/types";
import { List } from "semantic-ui-react";

type Props = {
  assistants: OpenAIAssistant[];
  selectedAssistantId?: number;
  selectAssistant: (assistantId?: number) => void;
};

export const ManageOpenAIAssistantsList: React.FC<Props> = ({
  assistants,
  selectedAssistantId,
  selectAssistant,
}) => (
  <List divided relaxed="very" selection>
    {assistants.map((assistant) => (
      <List.Item
        data-testid={`list-item-assistant-${assistant.name}`}
        key={assistant.id}
        active={assistant.id === selectedAssistantId}
        onClick={(): void => selectAssistant(assistant.id)}
      >
        <List.Header as="a" className="tw-no-underline">
          {assistant.name}
        </List.Header>
      </List.Item>
    ))}
  </List>
);
