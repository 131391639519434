import * as React from "react";
import { DropdownProps } from "semantic-ui-react";
import { SyntheticEvent } from "react";

import { ProductListAction } from "../ProductList";
import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";
import { DateCategory } from "../ProductListTypes";

type Props = {
  dateCategory: DateCategory;
  onAction?: (action: ProductListAction, value: DateCategory) => void;
};
export const ProductFilterOrderByDateCategory: React.FC<Props> = ({
  dateCategory,
  onAction,
}) => (
  <StyledDropdownBase
    className={"tiny"}
    basic
    button
    data-testid={"by-date"}
    onChange={(e: SyntheticEvent, { value }: DropdownProps): void =>
      onAction?.(ProductListAction.ChangeDateCategory, value as DateCategory)
    }
    options={[
      { key: 1, text: "Document creation date", value: "created" },
      { key: 2, text: "Latest publication date", value: "published" },
    ]}
    value={dateCategory}
  />
);
