import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import Styled from "styled-components";

import { StyledDropdownBase } from "../viewsets/ViewSetsCreateNewFilterDropdown";
import { Filterkeys } from "../ProductListTypes";

export const StyledDivider = Styled.div`
  position: absolute;
  width: 1px;
  height: 50%;
  top: 25%;
  background: rgba(0, 0, 0, 0.1);
  left: -5%;
`;

const Divider = Styled(StyledDivider)`
  left: -3%;
`;

const checkboxContent = (): checkboxContent[] => [
  {
    filterKey: Filterkeys.Brands,
    text: "Brands",
  },
  {
    filterKey: Filterkeys.ParentChild,
    text: "Parent Child",
  },
  {
    filterKey: Filterkeys.Template,
    text: "Templates",
  },
  {
    filterKey: Filterkeys.Vocabulary,
    text: "Vocabulary",
  },
];

interface checkboxContent {
  filterKey: Filterkeys;
  text: string;
}

type Props = {
  toggleFilter: (filterKey: Filterkeys, filterValue: boolean) => void;
  filters: { [key: string]: boolean };
};

export const ProductFilterAddFilter: React.FC<Props> = ({
  toggleFilter,
  filters,
}) => (
  <div style={{ position: "relative", alignSelf: "center" }}>
    <StyledDropdownBase
      basic
      button
      icon={null}
      className={"tiny"}
      data-testid={"product-filter-add-filter"}
      trigger={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon className={"plus"} size={"small"} />
          More
        </span>
      }
    >
      <Dropdown.Menu>
        {checkboxContent().map(
          ({ filterKey, text }) =>
            !filters[filterKey] && (
              <Dropdown.Item
                data-testid={filterKey}
                text={text}
                onClick={(): void => toggleFilter(filterKey, true)}
                key={text}
              />
            )
        )}
      </Dropdown.Menu>
    </StyledDropdownBase>
    <Divider />
  </div>
);
