import React from "react";
import { Dropdown } from "semantic-ui-react";

import { Operation } from "./ProductFilterSearch";
import { FieldOption } from "../../../utils/ApiUtils";

type Props = {
  onClick: (value: string, operation: Operation, label: string) => void;
  searchResult: FieldOption[];
  selected: string[];
};

export const ProductFilterSearchItems: React.FC<Props> = ({
  onClick,
  searchResult,
  selected,
}) => (
  <>
    {searchResult?.map(({ label, value }) => (
      <Dropdown.Item
        data-testid={"product-filter-search-items"}
        disabled={selected.includes(value)}
        key={value}
        onClick={(e): void => {
          onClick(value, Operation.Add, label);
          e.stopPropagation();
        }}
        text={label}
        value={value}
      />
    ))}
  </>
);
