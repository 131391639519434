import React from "react";
import {
  TagCategoryTypeMapKeyType,
  TemplateField,
  TemplateFieldTypeMapKeyType,
} from "../template";
import { TemplateBuilderManageVariableInput } from "./TemplateBuilderManageVariableInput";

type Props = {
  variables: TemplateField[];
  onUpdateVariable: (
    index: number,
    param: {
      type: TemplateFieldTypeMapKeyType;
      key: string;
      tag?: TagCategoryTypeMapKeyType;
    }
  ) => void;
  onRemoveVariable: (index: number) => void;
};

export const TemplateBuilderManageVariableTab: React.FC<Props> = ({
  variables,
  onUpdateVariable,
  onRemoveVariable,
}) => {
  return (
    <>
      <div className="tw-mb-4 tw-grid tw-grid-cols-[repeat(2,_1fr)_200px] tw-gap-4 tw-border-b-2">
        <p>Name:</p>
        <p className="tw-flex-1">Type:</p>
        <div></div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-6">
        {variables.map((variable, index) => (
          <TemplateBuilderManageVariableInput
            key={`${variable.key}-${variable.type}`}
            variable={variable}
            index={index}
            onUpdateVariable={onUpdateVariable}
            onRemoveVariable={onRemoveVariable}
          />
        ))}
        <TemplateBuilderManageVariableInput
          onUpdateVariable={onUpdateVariable}
        />
      </div>
    </>
  );
};
