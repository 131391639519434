import React from "react";

import { Button, Modal } from "semantic-ui-react";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmAction: () => void;
  header: string;
  content: React.ReactElement;
};
export const ConfirmModal: React.FC<Props> = ({
  open,
  setOpen,
  confirmAction,
  header,
  content,
}) => {
  return (
    <Modal open={open} size="small" data-testid="section-modal-confirm">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions className="modal-actions">
        <Button
          data-testid="section-modal-close-confirm"
          content="Cancel"
          size="tiny"
          basic
          onClick={(): void => setOpen(false)}
        />
        <Button
          data-testid="section-modal-confirm-action"
          content="Confirm"
          size="tiny"
          color="red"
          onClick={(): void => {
            confirmAction();
            setOpen(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
