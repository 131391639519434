import { LanguageCode } from "../../customers/customerlanguages";
import { LangStringObject } from "../../vocabulary/LangString";
export type GptModelConfig = { [key: string]: any };

export interface GptModel {
  display_name: string; // ChatGPT
  provider: string; // OpenAI
  model_name: string; // gpt-3.5-turbo
  supports_images: boolean; // If we should display the image url input
  config?: GptModelConfig;
}

export type OpenAIAssistant = {
  id: number;
  name: string;
  instructions: string;
  has_files?: boolean;
};
export type OpenAIAssistantFile = {
  name: string;
  id: string;
};

export enum SliderPopupTexts {
  MAX_TOKENS = "The maximum number of tokens returned in generated texts. A token corresponds to roughly 4 characters.",
  TEMPERATURE = "Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 for ones with a well-defined answer.",
  PRESENCE_PENALTY = "Number between -2.0 and 2.0. Increasing the model's likelihood to talk about new topics.",
  FREQUENCY_PENALTY = "Number between -2.0 and 2.0. Decreasing the model's likelihood to repeat the same line verbatim.",
}

export const PROMPT_WITH_NO_TAG_KEY = "no tags";

/**
 * Structure example:
 * {
 *  "gpt-4o": {
 *    "max_tokens": 1024
 *  },
 *  "gpt-3.5-turbo": {
 *    "max_tokens": 512
 *  }
 * }
 */
export type PromptConfigOverwrite = {
  [key: string]: {
    [key: string]: any;
  };
};

export type CopyAssistantLangStringTemplateLabels = {
  name: string;
  description: string;
  label_id: number;
  default: boolean;
};

export type CopyAssistantLangStringTemplate = {
  template_id: number;
  name: string;
  labels: CopyAssistantLangStringTemplateLabels[];
};

export enum PromptFormMode {
  UPDATE = "update",
  CREATE = "create",
  COPY = "copy",
}

export type PromptGroup = {
  name: string;
  prompt_ids: number[];
  tags?: string[];
  id: number;
};

export interface FurtherInstructionsGpt3 {
  input: string;
  output: string;
}

export type Prompt = {
  id: number;
  customer_id: number | null;
  name: string;
  language: LanguageCode | null;
  keep_html: boolean | null;
  model_name: string;
  instruction: string;
  further_instructions: string[] | FurtherInstructionsGpt3[];
  config_overwrite: PromptConfigOverwrite;
  template_vocabulary_id: number | null;
  template_labels: number[];
  tags: string[];
  channel_id: number | null;
  fieldset_id?: number | null;
  document_section_id: number | null;
  assistant_id?: number | null;
  is_translation?: boolean;
  include_documents_image?: boolean;
};

export type CreatePrompt = Omit<Prompt, "id" | "customer_id">;
export interface ObjectOf<T> {
  [key: string]: T;
}

export interface HasTags {
  tags?: string[];
}

export type SentenceGenerationInfo = {
  promptId?: number;
  instruction?: string;
  inputText?: string;
  groupId?: number;
};

export type SentenceTemplateInfo = {
  tagId: string;
  langStringValues: LangStringObject;
  templateDisplayName: string;
  templateId: number;
};

export type SentenceSectionInfo = {
  documentSectionId: number;
  documentSectionName: string;
  textBlockId?: string;
  langStringValues?: LangStringObject;
  connectedChannels?: { name: string; id: number }[];
  active?: boolean;
};
export type Sentence = {
  id: string;
  value: LangStringObject;
  language?: LanguageCode;
  sentenceGroupId?: string;
  isLoading?: boolean;
  isStreaming?: boolean;
  loadingReason?: string;
  generationInfo?: SentenceGenerationInfo;
  templateInfo?: SentenceTemplateInfo;
  sectionInfo?: SentenceSectionInfo;
  prompt_id?: number;
};

export type RewritePrompt = {
  id: number;
  name: string;
  customer_id: number | null;
  model_name: string;
  instruction: string;
  order: number;
  params: PromptConfigOverwrite;
};

export type CreateRewritePrompt = Omit<RewritePrompt, "id" | "customer_id">;
