import React, { useMemo, useState } from "react";
import { Button, Icon, Modal, Popup } from "semantic-ui-react";
import {
  GptModel,
  Prompt,
  PromptFormMode,
} from "../../../products/copy-assistant/types";
import {
  getSelectedPromptLanguage,
  getCustomerEnglishLanguage,
  groupPromptsByTags,
} from "../../../products/copy-assistant/utils";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { Text } from "../../../components/Text";
import { PromptForm } from "../../../products/copy-assistant/PromptForm";
import { TranslationPromptForm } from "../../../products/copy-assistant/TranslationPromptForm";
import { ManageTable } from "../../../components/manage/ManageTable";
import { useGetModels } from "../../../products/copy-assistant/customHooks";

type Props = {
  prompts: Prompt[];
  reFetchPrompts: () => Promise<void>;
  system?: boolean;
  isTranslation?: boolean;
  userIsStaff?: boolean;
};
export const ManagePromptList: React.FC<Props> = ({
  prompts,
  reFetchPrompts,
  system,
  isTranslation,
  userIsStaff,
}) => {
  const { data: customer } = useGetCustomerQuery();
  const { data: models } = useGetModels();

  const [selectedPrompt, setSelectedPrompt] = useState<Prompt>();
  const [promptFormMode, setPromptFormMode] = useState<PromptFormMode>(
    system ? PromptFormMode.COPY : PromptFormMode.CREATE
  );

  const [openModal, setOpenModal] = useState(false);

  const customerPrompts = useMemo(() => {
    return prompts.filter(({ customer_id }) => customer_id);
  }, [prompts]);

  const systemPrompts = useMemo(() => {
    return prompts.filter(({ customer_id }) => !customer_id);
  }, [prompts]);

  const usePrompts = useMemo(() => {
    return system ? systemPrompts : customerPrompts;
  }, [system, systemPrompts, customerPrompts]);

  const promptsOrderedByTags = useMemo(() => {
    if (!usePrompts) return {};
    const copy = [...usePrompts];
    const ordered = groupPromptsByTags(copy);
    return ordered;
  }, [usePrompts]);

  const availableTags = useMemo(() => Object.keys(promptsOrderedByTags), [
    promptsOrderedByTags,
  ]);

  const findModel = (model_name: string): GptModel | undefined => {
    return models.find((m) => m.model_name === model_name);
  };

  const findLanguageFlag = (prompt: Prompt): string => {
    if (customer && prompt.language) {
      return getSelectedPromptLanguage(customer, prompt).flag;
    } else if (customer && !prompt.language) {
      return getCustomerEnglishLanguage(customer).flag;
    }
    return "";
  };

  const refreshPromptList = async (): Promise<void> => {
    setSelectedPrompt(undefined);
    await reFetchPrompts();
    setOpenModal(false);
  };

  let Form: typeof PromptForm | typeof TranslationPromptForm = PromptForm;

  if (isTranslation) {
    Form = TranslationPromptForm;
  }

  const tableHeaders = ["Prompt", "Model", "Language", "Keep HTML", "Actions"];
  const tableBody = (): { id: number; elements: JSX.Element }[] =>
    usePrompts.map((prompt) => ({
      id: prompt.id,
      elements: (
        <>
          {prompt.name.length >= 70 ? (
            <Popup
              mouseEnterDelay={200}
              flowing
              content={prompt.name}
              size="tiny"
              trigger={
                <td>
                  <Text compact>{prompt.name.substring(0, 40)}...</Text>
                </td>
              }
            />
          ) : (
            <td>
              <Text compact>{prompt.name}</Text>
            </td>
          )}
          <td>
            <Text compact>
              {findModel(prompt.model_name)?.display_name ?? prompt.model_name}
            </Text>
          </td>
          <td>
            <Text compact>{findLanguageFlag(prompt)}</Text>
          </td>
          <td>
            <Icon
              name={prompt.keep_html ? "circle" : "circle outline"}
              color={prompt.keep_html ? "green" : "black"}
            />
          </td>
          <td>
            <Button.Group size="mini">
              <Popup
                content="Copy Prompt"
                size="small"
                trigger={
                  <Button
                    basic
                    size="mini"
                    color="red"
                    icon="copy"
                    content="Copy"
                    onClick={(): void => {
                      setSelectedPrompt(prompt);
                      setPromptFormMode(PromptFormMode.COPY);
                      setOpenModal(true);
                    }}
                  />
                }
              />
              {(!system || userIsStaff) && (
                <Popup
                  content="Prompt settings"
                  size="small"
                  trigger={
                    <Button
                      basic
                      size="mini"
                      color="red"
                      icon="setting"
                      content="Edit"
                      onClick={(): void => {
                        setSelectedPrompt(prompt);
                        setPromptFormMode(PromptFormMode.UPDATE);
                        setOpenModal(true);
                      }}
                    />
                  }
                />
              )}
            </Button.Group>
          </td>
        </>
      ),
    }));

  return (
    <>
      <ManageTable
        hasData={system ? !!systemPrompts.length : !!customerPrompts.length}
        noDataMessage={
          isTranslation ? "No Translation Prompts Found" : "No Prompts found"
        }
        headers={tableHeaders}
        body={tableBody()}
        additionButton={
          (!system || userIsStaff) && (
            <Button
              color="red"
              basic
              size="small"
              onClick={(): void => {
                setOpenModal(true);
                setPromptFormMode(PromptFormMode.CREATE);
              }}
            >
              {isTranslation ? "Add Translation Prompt" : "Add Prompt"}
            </Button>
          )
        }
        showAdditionButtonWithNoData
      />

      <Modal open={openModal} onClose={(): void => setOpenModal(false)}>
        {[PromptFormMode.UPDATE, PromptFormMode.COPY].includes(
          promptFormMode
        ) ? (
          <Form
            mode={promptFormMode}
            prompt={selectedPrompt}
            availableTags={availableTags}
            setOpenModal={setOpenModal}
            refreshPromptList={refreshPromptList}
            userIsStaff={userIsStaff}
          />
        ) : (
          <Form
            mode={PromptFormMode.CREATE}
            availableTags={availableTags}
            setOpenModal={setOpenModal}
            refreshPromptList={refreshPromptList}
            userIsStaff={userIsStaff}
          />
        )}
      </Modal>
    </>
  );
};
