import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "semantic-ui-react";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";
import { CustomerChannel } from "../../customers/Customer";
import {
  getProductChannelConfig,
  ProductChannelConfig,
} from "../../api/channelConfigApi";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { ChannelEditTabBody } from "./ChannelEditTabBody";
import { Text } from "../../components/Text";

type Props = {
  productId: string;
};

export const ChannelEditTab: React.FC<Props> = ({ productId }) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);
  const [activeIndex, setActiveIndex] = useState(0);
  const [productChannelIds, setProductChannelIds] = useState<number[]>([]);
  const [productChannelsConfig, setProductChannelsConfig] = useState<
    ProductChannelConfig[]
  >([]);
  const [channels, setChannels] = useState<CustomerChannel[]>([]);
  const mounted = useRef(true);

  useEffect(() => {
    if (!isLoading) {
      const customerChannels = customer.channels.sort((a, b) =>
        a.display_name.localeCompare(b.display_name)
      );

      setChannels(customerChannels);
    }
  }, [isLoading]);

  useEffect(() => {
    if (channels.length) {
      mounted.current = true;
      (async (): Promise<void> => {
        const channelsId: number[] = [];
        channels.forEach(({ id }) => channelsId.push(id));
        const { data } = await getProductChannelConfig(
          token,
          productId,
          channelsId
        );
        if (mounted.current) {
          setProductChannelIds(data.product_channel_ids);
          setProductChannelsConfig(data.config_overwrite);
        }
      })();
    }
    return (): void => {
      mounted.current = false;
    };
  }, [channels]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <h3>Overwrite global channel settings for this product</h3>
      <Text color="grey">
        Channels may have settings which affect how the final output text looks.
        <br />
        Global channel settings affect all products, and can be changed from the
        <em> Manage</em> section. <br />
        These settings can also be overridden for an individual product, using
        the form below.
      </Text>
      <Accordion styled fluid data-testid="product-channels-accordion">
        {channels.map((channel) => (
          <ChannelEditTabBody
            key={channel.id}
            channel={channel}
            isActiveChannel={
              !!productChannelIds.find((id) => id === channel.id)
            }
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            ProductChannelConfig={productChannelsConfig.find(
              ({ channel_id }) => channel_id === channel.id
            )}
            productId={productId}
            token={token}
          />
        ))}
      </Accordion>
    </>
  );
};
