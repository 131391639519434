import React, { useEffect, useRef, useState } from "react";
import { TextArea, Form } from "semantic-ui-react";
import { ProductTextToolbarButton } from "./ProductTextToolbar";
import { OnActionCallback } from "./ProductTextViewer";
import { TextAreaProps } from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import { ProductTextAction } from "./ProductTextAction";

import styled from "styled-components";

const ProductActionsWrapper = styled.div`
  && {
    display: flex;
    height: 25px;
    width: max-content;
  }
`;
type Props = {
  onAction?: OnActionCallback;
  initial: string;
};

export const ProductTextCommentEditor: React.FC<Props> = ({
  onAction,
  initial,
}) => {
  const textArea = useRef(null);
  const [comment, setComment] = useState(initial || "");
  useEffect(() => textArea.current.focus());
  return (
    <div>
      <Form size={"small"}>
        <TextArea
          ref={textArea}
          onChange={(e: React.FormEvent, data: TextAreaProps): void =>
            setComment(data.value.toString())
          }
          placeholder="Write a comment"
          value={comment}
        />
      </Form>
      <ProductActionsWrapper>
        <ProductTextToolbarButton
          onClick={(): void =>
            onAction(ProductTextAction.SAVE_AND_CLOSE_COMMENT_EDITOR, comment)
          }
          icon="save"
          label="Save"
        />
        <ProductTextToolbarButton
          onClick={(): void => onAction(ProductTextAction.CLOSE_COMMENT_EDITOR)}
          icon="close"
          label="Cancel"
        />
      </ProductActionsWrapper>
    </div>
  );
};
