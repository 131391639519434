import React, { useState, useMemo, useEffect } from "react";

import { useGetMachineTranslationConfigsQuery } from "../api/machineTranslationConfigSlice";
import { Button, Dropdown, Icon } from "./tailwind";
import { Props as ButtonProps } from "./tailwind/button/Button";

type Props = Omit<ButtonProps, "onClick"> & {
  content?: React.ReactNode | never;
  initialConfigGroupId?: number;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    configGroupId?: number
  ) => void | Promise<void>;
  "data-testid"?: string;
};

export const TranslateButton: React.FC<Props> = ({
  content,
  initialConfigGroupId,
  onClick,
  children,
  "data-testid": dataTestId,
  ...buttonProps
}) => {
  const {
    data: configGroups = [],
    isLoading: isFetchingGroups,
  } = useGetMachineTranslationConfigsQuery(undefined);

  const [selectedConfigGroupId, setSelectedConfigGroupId] = useState<number>(
    initialConfigGroupId
  );

  useEffect(() => {
    if (initialConfigGroupId) return;
    const defaultGroup = configGroups.find((group) => group.is_default);
    if (defaultGroup) setSelectedConfigGroupId(defaultGroup.id);
  }, [configGroups]);

  const selectedConfigGroup = useMemo(() => {
    if (!selectedConfigGroupId) return null;
    return configGroups.find((group) => group.id === selectedConfigGroupId);
  }, [selectedConfigGroupId, configGroups]);

  const testId = useMemo(() => dataTestId || "translate-button", [dataTestId]);

  if (!isFetchingGroups && configGroups.length < 2) {
    return (
      <Button
        {...buttonProps}
        data-testid={testId}
        onClick={(event): void => {
          onClick(event, selectedConfigGroupId);
        }}
      >
        {content || children}
      </Button>
    );
  }
  return (
    <>
      <span
        data-testid={testId}
        className={`txu-button-group ${
          isFetchingGroups ? "txu-loading" : ""
        } tw-inline-flex tw-w-max tw-items-stretch`}
      >
        <Button
          {...buttonProps}
          variant={buttonProps?.variant ?? "secondary-alt"}
          className="tw-rounded-none tw-rounded-s !tw-border-e-0"
          onClick={(event): void => {
            onClick(event, selectedConfigGroupId);
          }}
        >
          {content || children}
          {selectedConfigGroup && (
            <div className="tw-mx-auto tw-max-w-[8em] tw-truncate tw-text-sm tw-opacity-50">
              {selectedConfigGroup.name}
            </div>
          )}
        </Button>

        <Dropdown
          control="button"
          options={[
            {
              value: 0,
              key: "header-1",
              text: "Translation Settings Groups",
              type: "header",
            },
            ...configGroups.map((group) => ({
              value: group.id,
              key: group.id.toString(),
              text: group.name,
            })),
          ]}
          value={selectedConfigGroupId}
          onChange={(value): void => {
            if (value == selectedConfigGroupId) {
              setSelectedConfigGroupId(undefined);
            } else {
              setSelectedConfigGroupId(value as number);
            }
          }}
          content={<Icon name="expand_more" />}
          buttonProps={{
            ...buttonProps,
            className: "tw-rounded-none tw-rounded-e txu-compact tw-h-full",
            icon: true,
            variant: buttonProps?.variant ?? "secondary-alt",
          }}
          size={buttonProps.size}
        />
      </span>
    </>
  );
};
