import $ from "jquery";
import { loadPartial } from "../utils/partialUtils";
const TAG_ENUM_VALUE = "T";
const TAG_LIST_ENUM_VALUE = "L";
const BULLETLIST_ENUM_VALUE = "P";
const LANG_STRING_VALUE = "N";

const QUIET_SUBMIT_EVENT = "quiet-submit:finished";

const saveButton = $("#template-save-button");

const quietSubmit = function (form, callback = null) {
  saveButton.attr("disabled", true);
  saveButton.addClass("loading");

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: form.serializeArray(),
    success: function () {
      saveButton.removeAttr("disabled");
      saveButton.removeClass("loading");
      $(form).trigger(QUIET_SUBMIT_EVENT);
      if (callback != null) {
        callback();
      }
    },
  });
};

// Run callback when value changed with a wait of 300ms
const onTextChange = function (selector, callback) {
  $(document).on("keydown", selector, function () {
    const input = $(this);
    input.data("value", input.val());
  });

  $(document).on("keyup", selector, function () {
    const input = $(this);

    let _timeout = input.data("timeout");
    let _oldValue = input.data("value") + "";
    let _newValue = input.val() + "";

    _oldValue = _oldValue || "";
    _newValue = _newValue || "";

    if (_newValue.trim() !== _oldValue.trim()) {
      clearTimeout(_timeout);
      _timeout = setTimeout(function () {
        callback(input);
      }, 300);

      input.data("timeout", _timeout);
    }
  });
};

// Quiet submit the translation fields
onTextChange(
  "[data-auto-quiet] textarea, [data-auto-quiet] .field > input",
  function (input) {
    const form = $(input).closest("form");
    setTimeout(function () {
      quietSubmit(form);
    });
  }
);

$(document).on("focusin", "[data-focusout-auto-quiet] textarea", function () {
  saveButton.attr("disabled", true);
});

$(document).on("focusout", "[data-focusout-auto-quiet] textarea", function () {
  const form = $(this).closest("form");
  setTimeout(function () {
    quietSubmit(form);
  });
});

$(document).on(
  "change",
  "[data-auto-quiet] select, [data-auto-quiet] .field > input",
  function () {
    const form = $(this).closest("form");
    setTimeout(function () {
      quietSubmit(form);
    });
  }
);

// Show the tag type dropdown when we are editing a `tag`-field
$(document).on("change", "[id=id_type]", function () {
  const el = $(this);
  const form = $(this).closest("form");
  const tagField = form.find(".field.tag");
  const tagInput = tagField.find("[id=id_tag]");
  const sampleField = el.closest("form").find(".field.sample");

  if (el.val() === TAG_ENUM_VALUE) {
    tagField.show();
    tagInput.attr("required", "");
    tagInput.trigger("change");
  } else if (
    el.val() === TAG_LIST_ENUM_VALUE ||
    el.val() === BULLETLIST_ENUM_VALUE
  ) {
    tagField.hide();
    tagInput.removeAttr("required");
    sampleField.html(form.find("template[id=sample_tag_list]").html());
    window.rebindAll();
  } else if (el.val() === LANG_STRING_VALUE) {
    tagField.hide();
    tagInput.removeAttr("required");
    sampleField.html(form.find("template[id=lang_string]").html());
    window.rebindAll();
  } else {
    tagField.hide();
    tagInput.removeAttr("required");
    sampleField.html(form.find("template[id=sample_value]").html());
  }
});

$(document).on("change", "[id=id_tag]", function () {
  const el = $(this);
  const form = $(this).closest("form");
  const sampleField = form.find(".field.sample");
  const [tagCategory, tagType] = el.val().split("/");
  if (tagCategory && tagType) {
    sampleField
      .html(form.find("template[id=sample_vocabulary]").html())
      .find("input")
      .attr("data-tag-type", tagType)
      .attr("data-tag-category", tagCategory);

    sampleField.removeClass("bound");
    sampleField.prev(".vocabulary-lookup-wrapper").remove();
    window.rebindAll();
  } else {
    sampleField.html("");
  }
});

// Trigger the change event on page load
$(function () {
  $("[id=id_type]").trigger("change");
});

// Show the add field form on link click
$(document).on("click", ".add-field-link", function (event) {
  event.preventDefault();
  $(this).hide();
  $(".new-field-form").show().find("[id=id_key]").focus();
});

// Reset the add field form
$(document).on("click", ".new-field-form .cancel-button", function (event) {
  event.preventDefault();
  $(".new-field-form").hide();
  $(".add-field-link").show();
});

$(document).on(QUIET_SUBMIT_EVENT, function (event) {
  const form = $(event.target);
  form.closest("div").find(".preview form").submit();
});

$(document).on("click", ".test-button", function () {
  const el = $(".VariantsField textarea");
  el[0].val = "testtest";
  el.trigger("change");
});

// Show Helper text
$(document).on("click", ".template-syntax-help", function (event) {
  event.preventDefault();
  $(".helper-text-template").toggle();
});

$(document).on("click", "#template_toggle_machine_translate_all", function () {
  const form = $("#template_toggle_machine_translate_all").closest("form");
  setTimeout(function () {
    const partial = $("#partial-lexicons");
    quietSubmit(form, () => loadPartial(partial, true));
  });
});
