import React, { Dispatch, SetStateAction } from "react";
import { ProofreadingInfo } from "./PreviouslyMachineTranslatedTextRailList";
import { LanguageCode } from "../customers/customerlanguages";
import { Popup, Divider } from "semantic-ui-react";
import { popupDelay } from "../customers/gpt/types";
import { Text } from "../components/Text";
import { Button } from "../components/tailwind";

type Props = {
  language: LanguageCode;
  proofreadingInfo: ProofreadingInfo;
  viewingProofreading: LanguageCode[];
  setViewingProofreading: Dispatch<SetStateAction<LanguageCode[]>>;
};
export const ProofreadStatusButton: React.FC<Props> = ({
  language,
  proofreadingInfo,
  viewingProofreading,
  setViewingProofreading,
}) => (
  <Popup
    size="small"
    wide
    mouseEnterDelay={popupDelay}
    hoverable
    trigger={
      <Button
        {...(!proofreadingInfo.done && {
          style: { cursor: "default" },
        })}
        data-testid={`proofread-status-button-${language}`}
        content={
          proofreadingInfo.done
            ? viewingProofreading.includes(language)
              ? "View original text"
              : "View proofread text"
            : "Pending proofreading"
        }
        onClick={(): void => {
          if (!proofreadingInfo.done) return;
          setViewingProofreading((prev) => {
            const index = prev.indexOf(language);
            if (index > -1) {
              const newArr = [...prev];
              newArr.splice(index, 1);
              return newArr;
            }
            return [...prev, language];
          });
        }}
        variant={proofreadingInfo.done ? "primary" : "secondary-alt"}
        compact
        size="sm"
        color={proofreadingInfo.done ? "red" : undefined}
      />
    }
  >
    <Popup.Content>
      <Text size="small" compact>
        <b>Status:</b> {proofreadingInfo.done ? "Done" : "Pending"}
      </Text>
      <Divider fitted />
      <Text size="small" compact>
        <b>Job ID:</b> {proofreadingInfo.job_id}
      </Text>
    </Popup.Content>
  </Popup>
);
