import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { Text } from "../components/Text";
import { useGetAuthQuery } from "../api/authApi";
import { fixLongName } from "../products/copy-assistant/utils";
import { requestProofreading } from "../api/requestTranslationApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  LanguageCode,
  renderLanguageFromCode,
} from "../customers/customerlanguages";
import { Button } from "../components/tailwind";
import { Props as ButtonProps } from "../components/tailwind/button/Button";

type Props = {
  requestText?: string;
  requestFileName?: string;
  requestPathToFile?: string;
  requestLanguage: LanguageCode;
  originalText?: string;
  originalFileName?: string;
  originalPathToFile?: string;
  originalLanguage: LanguageCode;
  buttonProps?: Partial<ButtonProps>;
  onSendToProofreadingCallback?: () => void;
};

export const ProofreadRequestModal: React.FC<Props> = ({
  requestText,
  requestFileName,
  requestPathToFile,
  requestLanguage,
  originalText,
  originalFileName,
  originalPathToFile,
  originalLanguage,
  buttonProps,
  onSendToProofreadingCallback,
}) => {
  const { data: customer } = useGetCustomerQuery();
  if (requestText && requestPathToFile) {
    throw new Error(
      "You must provide either a requestText or a requestPathToFile, not both."
    );
  }

  const itemToProofread = requestText || requestPathToFile;
  const isProofreadingFile = !!requestPathToFile && !requestText;
  const canProofread = !!itemToProofread;

  const builtButtonProps: ButtonProps & { "data-testid": string } = {
    content: "Send for proofreading...",
    variant: "primary",
    size: "sm",
    "data-testid": "proofread-request-button",
    compact: true,
    ...buttonProps,
  };
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");

  const [jobId, setJobId] = useState<string | null>(null);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    if (isAuthLoading || !auth) return;
    setEmail(auth.user.email);
  }, [auth, isAuthLoading, open]);

  const handleRequestProofreading = async (): Promise<void> => {
    setIsRequesting(true);
    const response = await requestProofreading(
      token,
      email,
      originalText,
      originalPathToFile,
      originalLanguage || "",
      requestText,
      requestPathToFile,
      [requestLanguage],
      isUrgent,
      additionalInstructions
    ).finally(() => {
      setIsRequesting(false);
      onSendToProofreadingCallback?.();
    });
    setJobId(response);
  };

  function renderLanguage(languageCode: string): string {
    return (
      renderLanguageFromCode(languageCode, customer?.languages ?? []) ?? ""
    );
  }

  if (!canProofread) {
    return <Button {...builtButtonProps} disabled />;
  }

  return (
    <Modal
      data-testid={`proofread-request-modal-${open ? "open" : "closed"}`}
      size="small"
      open={open}
      onClose={(): void => setOpen(false)}
      trigger={
        <Button
          {...builtButtonProps}
          disabled={isAuthLoading}
          loading={isAuthLoading}
          onClick={(): void => setOpen(true)}
        />
      }
    >
      <Modal.Header>Send to proofreading</Modal.Header>
      <Modal.Content>
        <Dimmer.Dimmable dimmed={isRequesting}>
          <Segment>
            <Grid columns={2} stackable>
              <Divider vertical>
                <Icon name="arrow right" />
              </Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Text
                    as="h5"
                    compact
                    testId="proofread-request-original-language"
                  >
                    {renderLanguage(originalLanguage) ??
                      "Unknown source language"}
                  </Text>
                  <Text
                    color="grey"
                    size="small"
                    testId="proofread-request-original"
                    className="tw-whitespace-pre-wrap"
                  >
                    {isProofreadingFile
                      ? originalFileName || "Unknown source file"
                      : fixLongName(originalText, 200)}
                  </Text>
                </Grid.Column>
                <Grid.Column>
                  <Text
                    as="h5"
                    compact
                    testId="proofread-request-request-language"
                  >
                    {renderLanguage(requestLanguage)}
                  </Text>
                  <Text
                    color="grey"
                    size="small"
                    testId="proofread-request-request"
                    className="tw-whitespace-pre-wrap"
                  >
                    {isProofreadingFile
                      ? requestFileName || "Unknown target file"
                      : fixLongName(requestText, 200)}
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Divider hidden />
          <Form size="small">
            <Form.Field>
              <label>Email address(es) for returning the delivery *</label>
              <small>
                Separate multiple email addresses with commas <code>(,)</code>
              </small>
              <Input
                data-testid="proofread-request-email"
                type="email"
                placeholder="Email..."
                value={email}
                onChange={(e, { value }): void => setEmail(value)}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                data-testid="proofread-request-urgent"
                checked={isUrgent}
                label={customer?.config.translation_request_urgent_message}
                onChange={(e, { checked }): void => setIsUrgent(checked)}
              />
            </Form.Field>
            <Form.Field>
              <label>Additional instructions</label>
              <TextArea
                data-testid="proofread-request-additional-instructions"
                value={additionalInstructions}
                onChange={(e, { value }): void =>
                  setAdditionalInstructions(value as string)
                }
              />
            </Form.Field>
          </Form>
          <Dimmer active={isRequesting || !!jobId}>
            {jobId ? (
              <>
                <Icon name="check" />
                <Text size="large">Sent for proofreading!</Text>
                <Text size="small">
                  You will receive an email at <b>{email}</b> when the
                  proofreading is done.
                </Text>
                <Text testId="proofread-request-job-id">
                  Job ID: <b>{jobId}</b>
                </Text>
                <Text size="small">
                  Save the job ID if you want to ask for status/cancel the
                  proofreading job.
                  <br />
                  All proofreading related questions can be sent to{" "}
                  <a
                    href="mailto:translations@textual.se"
                    style={{ color: "white" }}
                  >
                    translations@textual.se
                  </a>
                </Text>
                <Divider />
                <Button
                  data-testid="proofread-request-done-button"
                  content="Done"
                  color="red"
                  onClick={(): void => setOpen(false)}
                />
              </>
            ) : (
              <>
                <Icon
                  name="spinner"
                  loading
                  data-testid="proofread-request-loading"
                />
                <Text size="large" color="white">
                  Sending for proofreading...
                </Text>
              </>
            )}
          </Dimmer>
        </Dimmer.Dimmable>
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="proofread-request-cancel-button"
          size="sm"
          className="tw-float-left"
          content="Cancel"
          onClick={(): void => setOpen(false)}
        />
        <Button
          data-testid="proofread-request-send-button"
          content="Send for proofreading"
          disabled={!email || isRequesting || !!jobId}
          variant="primary"
          onClick={handleRequestProofreading}
        />
      </Modal.Actions>
    </Modal>
  );
};
