import React, { useMemo } from "react";
import { Badge, Dropdown, Toggle } from "../../components/tailwind";
import { TemplateLabel } from "../template";
import { createHref } from "../../utils/hrefUtils";
import { useGetCustomerQuery } from "../../api/customerApi";
type Props = {
  displayName: string;
  setDisplayName: (newName: string) => void;
  description: string;
  setDescription: (newDescription: string) => void;
  activeTemplateLabels: number[];
  setActiveTemplateLabels: (templateLabels: number[]) => void;
  inactiveTemplateLabels: number[];
  setInactiveTemplateLabels: (templateLabels: number[]) => void;
  isFetchingTemplateLabels: boolean;
  availableTemplateLabels: TemplateLabel[];
  annotateContent: boolean;
  setAnnotateContent: (annotate: boolean) => void;
};
export const TemplateBuilderSettingsTab: React.FC<Props> = ({
  displayName,
  setDisplayName,
  description,
  setDescription,
  activeTemplateLabels,
  setActiveTemplateLabels,
  inactiveTemplateLabels,
  setInactiveTemplateLabels,
  availableTemplateLabels,
  isFetchingTemplateLabels,
  annotateContent,
  setAnnotateContent,
}) => {
  const { data: customer } = useGetCustomerQuery();
  const [active, inactive] = useMemo(() => {
    if (!availableTemplateLabels.length) return [[], []];
    const active = availableTemplateLabels
      .filter(({ id }) => !inactiveTemplateLabels.includes(id))
      .map((label) => ({
        key: label.id.toString(),
        value: label.id,
        text: label.name,
      }));
    const inactive = availableTemplateLabels
      .filter(({ id }) => !activeTemplateLabels.includes(id))
      .map((label) => ({
        key: label.id.toString(),
        value: label.id,
        text: label.name,
      }));
    return [active, inactive];
  }, [availableTemplateLabels, activeTemplateLabels, inactiveTemplateLabels]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <label htmlFor="template-name-input">Name *</label>
        <input
          value={displayName}
          onChange={({ target }): void => setDisplayName(target.value)}
          className="tw-form-input tw-block tw-rounded-md tw-border-0 tw-p-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset"
          id="template-name-input"
          placeholder="Name your Template..."
          data-testid="template-builder-settings-display-name-textarea"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-items-center tw-gap-2">
          <label htmlFor="template-description-input">Description</label>
          <small className="tw-text-sm tw-text-gray-500">
            Provide additional context about what this Template does
          </small>
        </div>
        <textarea
          value={description}
          onChange={({ target }): void => setDescription(target.value)}
          className="tw-form-textarea tw-block tw-w-96 tw-rounded-md tw-border-0 tw-p-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset"
          id="template-description-input"
          placeholder="Describe how the Template will be used..."
          data-testid="template-builder-settings-description-textarea"
        />
      </div>
      <div>
        <div>
          <h4 className="tw-ml-0">Template Settings</h4>{" "}
          <p className="tw-float-end tw-text-sm tw-text-gray-500">
            To modify the available template settings, Go to{" "}
            <a
              href={createHref("templateLabels", customer)}
              className="tw-text-blue-700"
            >
              Manage Template Settings...
            </a>
          </p>
          <p className="tw-ml-0 tw-text-sm tw-text-gray-500">
            Control where and how this Template is presented in the text
          </p>
        </div>
        <div className="tw-border-l-[1px] tw-pl-4 tw-pt-2">
          <div className="tw-mb-6">
            <div className="tw-flex tw-items-center tw-gap-2">
              <p className="tw-mx-0">Default Settings</p>
              <p className="tw-m-0 tw-mt-1 tw-text-sm tw-text-gray-500">
                These settings will be <Badge active content="on" /> by default
                when this Template is added to a product.
              </p>
            </div>
            <Dropdown
              testIdBase="template-builder-settings-active-labels"
              disabled={isFetchingTemplateLabels}
              options={active}
              value={activeTemplateLabels}
              onChange={setActiveTemplateLabels}
              placeholder="Type to search"
              clearable
              size="sm"
              control="multi-search"
              badgeProps={{ clearable: true, active: true }}
            />
          </div>
          <div>
            <div className="tw-flex tw-items-center tw-gap-2">
              <p className="tw-mx-0">Optional Settings</p>
              <p className="tw-m-0 tw-mt-1 tw-text-sm tw-text-gray-500">
                These settings will be <Badge content="off" /> by default when
                this Template is added to a product.
              </p>
            </div>
            <Dropdown
              testIdBase="template-builder-settings-inactive-labels"
              disabled={isFetchingTemplateLabels}
              options={inactive}
              value={inactiveTemplateLabels}
              onChange={setInactiveTemplateLabels}
              placeholder="Type to search"
              clearable
              size="sm"
              control="multi-search"
              badgeProps={{ clearable: true }}
            />
          </div>
        </div>
      </div>
      <div className="tw-ml-auto tw-mt-2">
        <Toggle
          label="Annotate Content"
          checked={annotateContent}
          setChecked={setAnnotateContent}
        />
      </div>
    </div>
  );
};
