import React from "react";
import { TranslationsModalLanguageTexts } from "./TranslationsModalLanguageTexts";
import { groupTextsByLanguages } from "../../producttext/producttextutils";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { Product } from "../product";
import { useGetCustomerQuery } from "../../api/customerApi";
import { Spinner } from "../../utils/Spinner";

type Props = {
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
  product: Product;
  productTexts: ProductText[];
};

export const TranslationsModalProductTexts: React.FC<Props> = ({
  onRequestProductTextAction,
  product,
  productTexts,
}) => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {Object.entries(groupTextsByLanguages(productTexts)).map(
        ([languageCode, texts]) => {
          const language = customer.languages.find(
            (language) => language.code === languageCode
          );
          if (!language) {
            return <React.Fragment key={`not-active-lang-${languageCode}`} />;
          }
          return (
            <React.Fragment key={languageCode}>
              <hr />
              <TranslationsModalLanguageTexts
                key={languageCode}
                language={language}
                onRequestProductTextAction={onRequestProductTextAction}
                product={product}
                texts={texts}
              />
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
