import React, { useState } from "react";
import { Language, LanguageCode } from "../../customers/customerlanguages";
import { AdvancedEditor, useNoCodeEditor } from "./no-code-editor";
import { TemplateField } from "../template";
import { TemplateBuilderContentVariableHandler } from "./TemplateBuilderContentVariableHandler";
import { Button, Icon } from "../../components/tailwind";

type Props = {
  variant: string;
  index: number;
  language: Language;
  onUpdateLexiconVariant: (
    languageCode: LanguageCode,
    value: string,
    index: number,
    removeVariant?: boolean
  ) => void;
  variables: TemplateField[];
  onCreateVariable: (variable: TemplateField) => void;
};

export const TemplateBuilderContentLanguageInputVariant: React.FC<Props> = ({
  variant,
  index,
  language,
  onUpdateLexiconVariant,
  variables,
  onCreateVariable,
}) => {
  const [viewVariables, setViewVariables] = useState(false);
  const [viewAdvanced, setViewAdvanced] = useState(false);
  const {
    onAddVariable,
    dragHandlers,
    errorParsingNodes,
    NoCodeEditor,
  } = useNoCodeEditor({
    input: variant,
    updateInput: (input: string): void => {
      onUpdateLexiconVariant(language.code, input, index);
    },
    availableVariables: variables,
    placeholder: `template variant content (${index + 1}) for ${
      language?.short_name
    }...`,
    dependencies: [viewAdvanced, variables],
  });

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <Button
        className="tw-mr-auto"
        icon
        variant="secondary-alt"
        size="sm"
        compact
        borderless
        disabled={!language}
        onClick={(): void => setViewVariables((prev) => !prev)}
      >
        Variables{" "}
        <Icon name={viewVariables ? "expand_more" : "navigate_next"} />
      </Button>

      <TemplateBuilderContentVariableHandler
        open={viewVariables}
        variables={variables}
        onCreateVariable={onCreateVariable}
        onAddVariable={onAddVariable}
        dragHandlers={dragHandlers}
        disabledDrag={!!errorParsingNodes || viewAdvanced}
      />
      {errorParsingNodes || viewAdvanced ? (
        <AdvancedEditor
          input={variant}
          updateInput={(value: string) =>
            onUpdateLexiconVariant(language.code, value, index)
          }
          placeholder={`template variant content (${index + 1}) for ${
            language?.short_name
          }...`}
        />
      ) : (
        <NoCodeEditor />
      )}
      <div className="tw-flex">
        <Icon
          data-balloon="Remove variant"
          data-balloon-pos="up-left"
          onClick={(): void => {
            onUpdateLexiconVariant(language.code, "", index, true);
          }}
          name="close"
          className="tw-cursor-pointer tw-leading-[1] tw-text-lg"
        />
        <Icon
          data-balloon="Switch between Simple/Advance Editor"
          data-balloon-pos="up-right"
          onClick={(): void => {
            setViewAdvanced((prev) => !prev);
          }}
          name="code"
          className={`tw-cursor-pointer tw-leading-[1] tw-text-lg tw-ml-auto ${
            viewAdvanced && "tw-text-primary"
          }`}
        />
      </div>
    </div>
  );
};
