import React, { KeyboardEvent } from "react";
import { HandleRequestWordHandler, TreeData } from "../utils/tagutils";
import { ProductId } from "../products/product";
import Styled from "styled-components";
import { Button } from "semantic-ui-react";
import { ProductTagsType } from "./ProductTagsType";
import { ProductTagsSortableTreeComp } from "./ProductTagsSortableTree";

const ButtonsWithSpacing = Styled(Button)`
  &&& {
    margin-bottom: 3px;
    border: solid 1px #d74c50;
    color: #d74c50 !important;
    box-shadow: none !important;
    font-weight: 500;
    &:hover {
      color: #d74c50 !important;
      box-shadow: none !important;
      opacity: 0.8;
    }
  }
`;

type Props = {
  advancedTemplateSearch?: boolean;
  handleRequestWord: HandleRequestWordHandler;
  limit: number;
  mapEnumNumberNames: (node: TreeData) => TreeData;
  onBrandSelected: () => void;
  onChildAdded: () => void;
  onDataChanged: (data: TreeData[], delayedRefresh: boolean) => void;
  onNameSelected: () => void;
  parentId?: number;
  productId?: ProductId;
  textualAppName: string;
  treeData: TreeData[];
};

export const ProductTagsEditor: React.FC<Props> = ({
  advancedTemplateSearch,
  handleRequestWord,
  limit,
  mapEnumNumberNames,
  onBrandSelected,
  onChildAdded,
  onDataChanged,
  onNameSelected,
  parentId,
  productId,
  textualAppName,
  treeData,
}) => {
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.shiftKey && e.key === "Enter") {
      onChildAdded();
    }
  };
  const onAttributeChanged = (subparts: { treeData: TreeData[] }): void => {
    // Filter children that's not type==kind or material
    const tags =
      treeData[0] &&
      treeData[0].children &&
      treeData[0].children.filter((child) => {
        return !child.allow_children;
      });

    const newTree = [...treeData];

    newTree[0].children = [...subparts.treeData, ...tags];

    onDataChanged(newTree, false);
  };

  const attributeTreeData =
    treeData[0] && treeData[0].children
      ? treeData[0].children.filter((child) => {
          return child.allow_children;
        })
      : [];

  if (isNaN(parentId)) {
    parentId = null;
  }

  const disableBrandNameButtons = !(treeData[0] && treeData[0].value);

  return (
    <div className="tags_column treeViewBox">
      <div className="sortable_tree_h" style={{ visibility: "visible" }}>
        <div className="flex-row">
          <div className="custom_label m_top_10">Main attribute</div>

          <>
            <ButtonsWithSpacing
              disabled={disableBrandNameButtons}
              onClick={(): void => onBrandSelected()}
              basic
              size={"mini"}
              float={"left"}
              compact
            >
              Add brand
            </ButtonsWithSpacing>
            <ButtonsWithSpacing
              disabled={disableBrandNameButtons}
              onClick={(): void => onNameSelected()}
              basic
              size={"mini"}
              float={"left"}
              compact
            >
              Add name
            </ButtonsWithSpacing>
          </>
        </div>
        <ProductTagsType
          onKeyDown={onKeyDown}
          treeData={treeData}
          onProductDataChanged={(
            treeData: TreeData[],
            delayedRefresh
          ): void => {
            onDataChanged(treeData, delayedRefresh);
          }}
          textualAppName={textualAppName}
          limit={limit}
          handleRequestWord={handleRequestWord}
          mapEnumNumberNames={mapEnumNumberNames}
          productId={productId}
          advancedTemplateSearch={advancedTemplateSearch}
        />
        <div
          className="custom-coverbox"
          style={{ height: "auto !important", width: "100%" }}
        >
          <div className="custom_label m_top_10">
            Sub attributes
            <i
              tabIndex={0}
              className={"add circle icon"}
              style={{ marginTop: "18px", cursor: "pointer" }}
              color="primary"
              title="Add Child"
              onClick={(): void => onChildAdded()}
              onKeyDown={(e: KeyboardEvent<Element>): void =>
                e.key === "Enter" && onChildAdded()
              }
            />
          </div>
          <ProductTagsSortableTreeComp
            treeData={attributeTreeData}
            textualAppName={textualAppName}
            limit={limit}
            handleRequestWord={handleRequestWord}
            onAttributeChanged={(subparts: { treeData: TreeData[] }): void =>
              onAttributeChanged(subparts)
            }
            productId={productId}
            parentId={parentId}
          />
        </div>
      </div>
    </div>
  );
};
