import React from "react";

export const ProductDetailTabsSkeleton: React.FC = () => {
  return (
    <div className="edit tw-grid tw-h-96 tw-w-full tw-grid-rows-[30px_1fr] tw-rounded-md">
      <div className="tw-flex tw-h-full tw-w-full tw-gap-1 tw-px-4">
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
        <div className="tw-my-2 tw-flex-1 tw-animate-pulse tw-rounded-md tw-bg-gray-300/40"></div>
      </div>
      <div className="tw-h-full tw-w-full tw-animate-pulse tw-rounded-md tw-bg-white"></div>
    </div>
  );
};
