import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ManageGrid from "../../../components/manage/ManageGrid";
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  List,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { popupDelay } from "../../../customers/gpt/types";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../utils/store";
import {
  createTemplateLabel,
  deleteTemplateLabel,
  getTemplateLabels,
  updateTemplateLabel,
} from "../../../api/productTemplateVocabularyLabelsApi";
import { useUrlState } from "../../../utils/react-custom-hooks/urlStateHandler";
import { TemplateLabel } from "../../template";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import { UseGenericFetch, useGenericFetch } from "../../../api/customFetchHook";

function useGetTemplateLabels(): UseGenericFetch<TemplateLabel[]> {
  return useGenericFetch<TemplateLabel[]>([], getTemplateLabels);
}

const OFFSET = 180;

export const ManageTemplateLabels: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { reFetch, data: templateLabels, isFetching } = useGetTemplateLabels();
  const {
    setParam,
    removeParam,
    urlState: { id, q },
  } = useUrlState<{
    id: number;
    q: string;
  }>();

  const selectedTemplateLabel = templateLabels.find(
    (templateLabel) => templateLabel.id === id
  );

  const filteredTemplateLabels = useMemo<TemplateLabel[]>(() => {
    if (!q) {
      return templateLabels;
    }
    return templateLabels.filter(
      ({ name }) => name.toLowerCase().search(q.toLowerCase()) != -1
    );
  }, [q, templateLabels]);

  const isCreatingNew = !selectedTemplateLabel && typeof id === "number";

  const formIsShowing = isCreatingNew || id;

  const handleSelectTemplateLabel = (id: number): void => {
    setParam("id", id);
  };

  const handleSearchTemplateLabel = (search?: string): void => {
    if (!search) {
      removeParam("q");
      return;
    }
    setParam("q", search);
  };

  const handleSubmitForm = (e: any): void => {
    e.preventDefault();
    const templateLabelName = e.target.templateLabelName.value;
    const templateLabelDescription = e.target.templateLabelDescription.value;
    if (!templateLabelName) {
      store.dispatch(
        setDjangoToastOpen({
          content: "Please provide Template Setting Name",
          appearance: NotificationAppearance.ERROR,
        })
      );
      return;
    }
    sendRequest(
      templateLabelName,
      templateLabelDescription,
      selectedTemplateLabel?.id
    )
      .then(({ id }) => handleSelectTemplateLabel(id))
      .finally(() => reFetch());
  };

  const sendRequest = async (
    name: string,
    description: string,
    id?: number
  ): Promise<TemplateLabel> => {
    if (!id) {
      return createTemplateLabel(token, name, description);
    }
    return updateTemplateLabel(token, id, name, description);
  };

  // RESIZE LIST
  const [height, setHeight] = useState<number>(0);
  const handleWindowResize = useCallback(() => {
    const windowHeight = document.documentElement.clientHeight;
    const windowScrollHeight = document.documentElement.scrollHeight;
    const scrollOffset = windowScrollHeight - windowHeight;
    setHeight(windowHeight - scrollOffset - OFFSET);
  }, []);
  useLayoutEffect(() => {
    if (height === 0) {
      handleWindowResize();
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <div className="manage-content">
      <ManageGrid>
        <ManageGrid.Header>
          <ManageGrid.Header.LeftColumn
            left={
              <Popup
                wide
                mouseEnterDelay={popupDelay}
                content="Template Settings are used to control where a template's content should be placed in the generated text."
                trigger={
                  <span>
                    <Text as="h4" compact inline width="max">
                      Template Settings
                    </Text>{" "}
                    <Icon name="question circle" />
                  </span>
                }
              />
            }
            right={
              <Popup
                size="tiny"
                content="Create Template Setting"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Button
                    data-testid="manage-template-labels-create-new-small-button"
                    size="tiny"
                    color="red"
                    icon="plus"
                    compact
                    basic
                    onClick={(): void => handleSelectTemplateLabel(0)}
                  />
                }
              />
            }
          />
          <ManageGrid.Header.RightColumn>
            {formIsShowing && (
              <Text compact as="h3" inline>
                {isCreatingNew ? (
                  <>Creating Template Setting</>
                ) : (
                  <span>
                    Updating Template Setting: {selectedTemplateLabel?.name}
                  </span>
                )}
              </Text>
            )}
          </ManageGrid.Header.RightColumn>
        </ManageGrid.Header>
        <ManageGrid.Body>
          <ManageGrid.Body.List>
            <Input
              data-testid="manage-template-labels-search"
              placeholder="Search"
              icon="search"
              iconPosition="left"
              value={q || ""}
              onChange={(_, { value }): void =>
                handleSearchTemplateLabel(value)
              }
              style={{ flexGrow: "0" }}
            />
            <List
              selection
              divided
              verticalAlign="middle"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: `${height}px`,
              }}
            >
              {filteredTemplateLabels.map((templateLabel) => (
                <List.Item
                  data-testid={`manage-template-labels-list-item-${templateLabel.id}`}
                  key={templateLabel.id}
                  onClick={(): void =>
                    handleSelectTemplateLabel(templateLabel.id)
                  }
                  active={templateLabel.id === id}
                  relaxed="very"
                >
                  <List.Header as="a" className="tw-no-underline">
                    {templateLabel.name}
                  </List.Header>
                </List.Item>
              ))}
            </List>
          </ManageGrid.Body.List>
          <ManageGrid.Body.Form>
            {!formIsShowing ? (
              <ManageGrid.Body.EmptyForm>
                {!!templateLabels?.length && (
                  <>
                    <Text as="h2" compact>
                      Select a Template Setting from the list
                    </Text>
                    <Text as="h3" color="grey" compact>
                      Or
                    </Text>
                  </>
                )}
                <Button
                  data-testid="manage-template-labels-create-new-big-button"
                  content="Create new Template Setting"
                  icon="plus"
                  color="red"
                  size="large"
                  onClick={(): void => handleSelectTemplateLabel(0)}
                />
              </ManageGrid.Body.EmptyForm>
            ) : (
              <Form
                data-testid="manage-template-labels-form"
                size="small"
                onSubmit={handleSubmitForm}
                key={selectedTemplateLabel?.id}
              >
                <Form.Field>
                  <label
                    htmlFor="manage-template-labels-name"
                    style={{ padding: 0 }}
                  >
                    Template Setting Name *
                  </label>
                  <Input
                    data-testid="manage-template-labels-name-input"
                    name="templateLabelName"
                    id="manage-template-labels-name"
                    autoFocus
                    placeholder="Header"
                    tabIndex={1}
                    defaultValue={selectedTemplateLabel?.name}
                  />
                </Form.Field>
                <Form.Field>
                  <Divider />
                  <label
                    htmlFor="manage-template-labels-description"
                    style={{ padding: 0 }}
                  >
                    Description (Optional)
                  </label>
                  <Text color="grey" lessMargin>
                    Describe where the Template Setting will place the Templates
                    text content in the generated text if active
                  </Text>
                  <TextArea
                    data-testid="manage-template-labels-description-input"
                    name="templateLabelDescription"
                    id="manage-template-labels-description"
                    tabIndex={2}
                    placeholder="When active on a Template the text content of that Template will be placed in the 'Header' Section."
                    defaultValue={selectedTemplateLabel?.description}
                  />
                </Form.Field>
                {!isCreatingNew &&
                  (selectedTemplateLabel?.used_in_templates ? (
                    <Text>
                      Template Setting &quot;{selectedTemplateLabel?.name}&quot;
                      is currently used in{" "}
                      {selectedTemplateLabel?.used_in_templates} template(s).
                    </Text>
                  ) : (
                    <Text>
                      Template Setting &quot;{selectedTemplateLabel?.name}&quot;
                      is not used in any templates yet.
                    </Text>
                  ))}
                <Button
                  content={selectedTemplateLabel ? "Update" : "Create"}
                  color="red"
                />
                {!!selectedTemplateLabel && (
                  <Button
                    data-testid="manage-template-labels-delete-button"
                    floated="right"
                    content="Delete"
                    basic
                    compact
                    disabled={isFetching}
                    onClick={(e): void => {
                      e.preventDefault();
                      deleteTemplateLabel(
                        token,
                        selectedTemplateLabel.id
                      ).finally(() => reFetch());
                    }}
                  />
                )}
              </Form>
            )}
          </ManageGrid.Body.Form>
        </ManageGrid.Body>
      </ManageGrid>
      <Dimmer
        active={isFetching}
        page
        data-testid="manage-template-labels-loading"
      >
        <Header as="h4" icon inverted>
          <Icon name="circle notch" loading />
        </Header>
      </Dimmer>
    </div>
  );
};
