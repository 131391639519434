import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import {
  SemanticICONS,
  SemanticCOLORS,
  Popup,
  Icon,
  Input,
  Message,
  Form,
} from "semantic-ui-react";
import { selectedPromptTestIds } from "../testUtils/testIdsSelectors";
import { Text } from "../../../components/Text";
import { getProductImages } from "../../../api/action";
import { ProductId } from "../../product";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { validateImageUrl } from "../utils";
import { CopyButton } from "./Components/Buttons";

const explainImage = {
  header: "The image will be analyzed by the model to generate text from",
  content:
    "Example: A picture of a blue jeans. The model will generate text based on the image.",
};

type Props = {
  imageUrl: string;
  setImageUrl: (value: string) => void;
  productId?: ProductId;
  saveManualImageUrl?: (value: string) => void;
  handleCopyImageUrl?: (value: string) => void;
  skipProductImage?: boolean;
};
export const ImageUrlInput: React.FC<Props> = ({
  imageUrl,
  setImageUrl,
  productId,
  saveManualImageUrl,
  handleCopyImageUrl,
  skipProductImage,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [validImageUrl, setValidImageUrl] = useState<boolean>(true);
  const [isValidatingImageUrl, setIsValidatingImageUrl] = useState<boolean>(
    false
  );
  const [hasBeenValidated, setHasBeenValidated] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState<boolean>(false);

  const validateImageUrlDebounced = useCallback(
    debounce(async (url: string): Promise<void> => {
      if (!url) {
        saveManualImageUrl?.(url);
        setValidImageUrl(true);
        setHasBeenValidated(false);
        setIsValidatingImageUrl(false);
        return;
      }
      const valid = await validateImageUrl(url);
      if (valid) {
        saveManualImageUrl?.(url);
      }
      setValidImageUrl(valid);
      setHasBeenValidated(true);
      setIsValidatingImageUrl(false);
    }, 1000),
    [saveManualImageUrl]
  );
  const getImageUrlIcon = ():
    | SemanticICONS
    | { name: SemanticICONS; color?: SemanticCOLORS; loading?: boolean } => {
    if (isValidatingImageUrl) return { name: "circle notch", loading: true };
    if (!hasBeenValidated || !imageUrl) return undefined;
    if (validImageUrl) return { name: "check", color: "green" };
    return "warning sign";
  };

  useEffect(() => {
    let canceled = false;
    if (!token || !productId || imageUrl || skipProductImage) return;
    setIsValidatingImageUrl(true);
    setDisableInput(true);
    getProductImages(token, productId)
      .then((images) => {
        if (canceled) return;
        if (images.length > 0) {
          setImageUrl(images[0].imageUrl);
          validateImageUrlDebounced(images[0].imageUrl);
        } else {
          setIsValidatingImageUrl(false);
        }
        setDisableInput(false);
      })
      .catch(() => {
        if (canceled) return;
        setIsValidatingImageUrl(false);
        setDisableInput(false);
      });
    return (): void => {
      canceled = true;
    };
  }, [token, productId, skipProductImage]);

  return (
    <Form.Field error={!validImageUrl && !!imageUrl}>
      <Popup
        header={explainImage.header}
        content={explainImage.content}
        trigger={
          <label
            style={{ display: "inline-block" }}
            data-testid={selectedPromptTestIds.image.label}
          >
            <Icon name="question circle" /> Image:
          </label>
        }
        size="tiny"
        wide="very"
        hoverable
      />{" "}
      {imageUrl && handleCopyImageUrl && (
        <CopyButton
          onCopy={(): void => handleCopyImageUrl(imageUrl)}
          floated="right"
        />
      )}
      <Text color="grey" className="descriptive-helper-text" compact>
        This image will be analyzed by the model
      </Text>
      <Input
        data-testid={selectedPromptTestIds.image.input}
        disabled={disableInput}
        icon={getImageUrlIcon()}
        value={imageUrl}
        onChange={(_, { value }): void => {
          setImageUrl(value);
          setIsValidatingImageUrl(true);
          validateImageUrlDebounced(value);
        }}
        placeholder="https://example.com/blue-jeans.jpg"
      />
      <Message
        data-testid={selectedPromptTestIds.image.error}
        visible={!validImageUrl && !!imageUrl}
        error
        size="tiny"
        header="Invalid image URL"
        content="We couldn't find an image at that URL. Validate that the URL is correct and that the image is publicly accessible."
      />
    </Form.Field>
  );
};
