import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Divider,
  DropdownItemProps,
  Form,
  Icon,
  Modal,
} from "semantic-ui-react";

import {
  PromptInputField,
  PromptSelectField,
  PromptTextareaField,
} from "./PromptFormInputs/InputFields";
import { CreatePrompt, Prompt, PromptFormMode } from "./types";

import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { useGetCustomerQuery } from "../../api/customerApi";
import { LanguageCode } from "../../customers/customerlanguages";
import { getCustomerEnglishLanguage } from "./utils";
import { useGetModels, useGetOpenAIAssistants } from "./customHooks";
import { createPrompt, updatePrompt, deletePrompt } from "../../api/gptApi";
import { Text } from "../../components/Text";
import { createHref } from "../../utils/hrefUtils";
import { PromptSelectFieldWithHeaders } from "./PromptFormInputs/PromptSelectFieldWithHeaders";

type Props = {
  mode: PromptFormMode;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  refreshPromptList: () => Promise<void>;
  selectPrompt?: (prompt: Prompt) => void;
  prompt?: Partial<Prompt>;
  userIsStaff?: boolean;
};

export const TranslationPromptForm: React.FC<Props> = ({
  mode,
  setOpenModal,
  refreshPromptList,
  selectPrompt,
  prompt,
  userIsStaff,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const { data: models, isFetching: isFetchingModels } = useGetModels();

  const {
    data: assistants,
    isFetching: isFetchingAssistants,
  } = useGetOpenAIAssistants();

  const [createSystemPrompt, setCreateSystemPrompt] = useState(false);

  useEffect(() => {
    if (
      userIsStaff &&
      Object.keys(prompt || {}).includes("customer_id") &&
      !prompt?.customer_id
    ) {
      setCreateSystemPrompt(true);
    }
  }, [prompt, userIsStaff]);

  // LANGUAGE SELECTION
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
    prompt?.language
  );

  const customerModelOptions = useMemo(() => {
    if (!models) return [];
    return models.map(({ display_name, model_name, supports_images }) => ({
      value: model_name,
      text: display_name,
      key: model_name,
      description: supports_images ? "Supports image processing" : undefined,
    }));
  }, [models]);

  const customerLanguageOptions = useMemo(() => {
    if (customer) {
      if (!selectedLanguage) {
        const language =
          getCustomerEnglishLanguage(customer)?.code ||
          customer.config.tag_input_language;
        setSelectedLanguage(language);
      }
      return customer.languages.map(({ code, short_name }) => ({
        value: code,
        text: short_name,
        key: code,
      }));
    }
    return [];
  }, [customer]);

  const assistantOptions = useMemo(() => {
    if (isFetchingAssistants) return [];

    return assistants.map(({ id, name }) => ({
      value: id,
      text: name,
      key: id,
    }));
  }, [isFetchingAssistants, assistants]);

  const [loadingForm, setLoadingForm] = useState(false);

  // NAME
  const [promptName, setPromptName] = useState(prompt?.name || "");

  // INSTRUCTION
  const [instructionState, setInstruction] = useState(
    prompt?.instruction || ""
  );

  // MODEL
  const DEFAULT_MODEL_NAME = "gpt-4";

  const [selectedModelName, setSelectedModelName] = useState(
    prompt?.assistant_id ? undefined : prompt?.model_name ?? DEFAULT_MODEL_NAME
  );

  // ASSISTANT
  const [selectedAssistant, setSelectedAssistant] = useState<number>(
    prompt?.assistant_id
  );

  useEffect(() => {
    if (mode === PromptFormMode.COPY) {
      setPromptName(`${promptName ?? prompt?.name}-copy`);
    }
  }, [mode]);

  const isSaveEnable = (): boolean => {
    return !(promptName && instructionState);
  };

  const collectPromptData = (): Partial<CreatePrompt> => {
    const newPrompt: Partial<CreatePrompt> = {
      instruction: instructionState,
      name: promptName,
      keep_html: true,
      model_name: selectedModelName,
      language: selectedLanguage,
      assistant_id: selectedAssistant,
      is_translation: true,
      config_overwrite: {},
    };

    return newPrompt;
  };
  const onCreatePrompt = async (): Promise<Prompt> => {
    const newPrompt = (collectPromptData() as unknown) as CreatePrompt;
    return await createPrompt(
      token,
      newPrompt,
      createSystemPrompt && userIsStaff
    );
  };

  const onUpdatePrompt = async (): Promise<Prompt> => {
    const newPrompt = (collectPromptData() as unknown) as Prompt;
    newPrompt.id = prompt.id;
    return updatePrompt(token, newPrompt, createSystemPrompt && userIsStaff);
  };
  const onDeletePrompt = async (): Promise<void> => {
    setLoadingForm(true);
    await deletePrompt(token, prompt.id, createSystemPrompt && userIsStaff);
    setLoadingForm(false);
    setOpenModal(false);
    await refreshPromptList(); // causes component to unmount
  };
  const onSave = async (): Promise<void> => {
    setLoadingForm(true);
    let newPrompt: Prompt;
    if (mode === PromptFormMode.UPDATE) {
      newPrompt = await onUpdatePrompt();
    } else {
      newPrompt = await onCreatePrompt();
    }
    if (newPrompt) {
      selectPrompt?.(newPrompt);
    }
    setLoadingForm(false);
    setOpenModal(false);
    await refreshPromptList(); // causes component to unmount
  };

  const collectModelsAndAssistantOptions = (): {
    [key: string]: DropdownItemProps[];
  } => {
    return {
      Models: customerModelOptions,
      "Assistants (Beta)": assistantOptions,
    };
  };

  const handleSelectFromDropdownWithHeader = (
    value: number | string,
    key: string
  ): void => {
    if (key === "Models") {
      setSelectedModelName(value as string);
      setSelectedAssistant(undefined);
    } else if (key === "Assistants (Beta)") {
      setSelectedAssistant(value as number);
      setSelectedModelName(undefined);
    }
  };

  const findSelectedText = (): string => {
    if (selectedModelName) {
      return customerModelOptions.find(
        ({ value }) => value === selectedModelName
      )?.text;
    } else if (selectedAssistant) {
      return assistantOptions.find(({ value }) => value === selectedAssistant)
        ?.text;
    }
    return undefined;
  };

  return (
    <>
      <Modal.Header
        content={
          mode === PromptFormMode.COPY
            ? `Copying from Translation Prompt "${prompt?.name}"`
            : `${
                mode === PromptFormMode.UPDATE ? "Update" : "Create"
              } Translation Prompt`
        }
      />
      <Modal.Content>
        <Form
          as="div"
          size="small"
          data-testid="copy-assistant-template-form"
          loading={
            loadingForm || isLoading || isFetchingAssistants || isFetchingModels
          }
        >
          {userIsStaff && (
            <Form.Field>
              <Text size="small" color="grey">
                This setting is never shown to users that are not staff
              </Text>
              <Checkbox
                disabled={mode === PromptFormMode.UPDATE}
                label="System Prompt"
                checked={createSystemPrompt}
                onChange={(_, { checked }): void =>
                  setCreateSystemPrompt(checked)
                }
              />
              {mode === PromptFormMode.UPDATE && (
                <Text size="small" color="grey">
                  <Icon name="warning circle" color="red" />
                  This Prompt already exists and can&apos;t be changed between
                  System Prompt and Customer Prompt. If you wish to convert this
                  Prompt to the other type consider copying it instead.
                  <br /> The checkbox shows if it&apos;s a System Prompt or not.
                  <br />
                  If the checkbox is checked when it&apos;s not supposed to
                  please inform any developer about this.
                </Text>
              )}
              <Divider />
            </Form.Field>
          )}
          <PromptInputField
            testId="copy-assistant-template-form-name-input"
            label={"Name"}
            placeholder={"Name your prompt..."}
            value={promptName}
            setValue={setPromptName}
          />
          <Divider />
          <PromptSelectField
            caption="The target language the generated text should be in."
            dropdownOptions={customerLanguageOptions}
            label={"Language"}
            placeholder={"Select what language to use"}
            testId="copy-assistant-template-form-select-language"
            value={selectedLanguage}
            setValue={setSelectedLanguage}
          />
          <Divider />
          <PromptTextareaField
            testId="copy-assistant-template-form-instruction-input"
            caption="The instruction to tell the Model how to translate the text."
            label={"Instruction"}
            placeholder={`Translate the following text to ${
              customerLanguageOptions.find(
                ({ value }) => value === selectedLanguage
              )?.text || "target language"
            }...`}
            value={instructionState}
            setValue={setInstruction}
          />
          <Text color="grey" compact size="small">
            <Icon name="info circle" color="black" />
            We recommend starting the instruction with &quot;Translate the
            following text to{" "}
            {customerLanguageOptions.find(
              ({ value }) => value === selectedLanguage
            )?.text || "target language"}
            &quot;
          </Text>

          <Divider />

          <Divider />
          {assistantOptions.length > 0 ? (
            <PromptSelectFieldWithHeaders
              dropdownOptionsWithHeaders={collectModelsAndAssistantOptions()}
              caption="Choose AI model or Assistant (Beta). Note that different models vary in quality, speed, and cost."
              label="Model or Assistant"
              placeholder="Select AI Model or Assistant"
              testId="copy-assistant-template-form-select-gpt-model-or-assistant"
              value={selectedModelName || selectedAssistant}
              selectedText={findSelectedText()}
              setValue={handleSelectFromDropdownWithHeader}
              linkUrl={createHref("assistants", customer)}
              linkText="Manage Assistants…"
            />
          ) : (
            <PromptSelectField
              caption="Choose AI model. Note that different models vary in quality, speed, and cost."
              dropdownOptions={customerModelOptions}
              label={"Model"}
              placeholder={"Select AI Model"}
              testId="copy-assistant-template-form-select-gpt-model"
              value={selectedModelName}
              setValue={setSelectedModelName}
            />
          )}

          <Divider />
        </Form>
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        {mode === PromptFormMode.UPDATE && (
          <Button
            data-testid="copy-assistant-template-form-delete-template"
            floated="left"
            onClick={async (): Promise<void> => await onDeletePrompt()}
            content="Delete prompt"
            size="small"
            basic
          />
        )}
        <Button
          color="red"
          basic
          size="small"
          onClick={(): void => setOpenModal(false)}
          data-testid="close-copy-assistant-template-form"
          loading={loadingForm || isLoading}
        >
          Cancel
        </Button>
        <Button
          color="red"
          size="small"
          disabled={isSaveEnable()}
          onClick={async (): Promise<void> => await onSave()}
          data-testid="save-copy-assistant-template-form"
          loading={loadingForm || isLoading}
        >
          {mode === PromptFormMode.COPY ? "Copy" : "Save"}
        </Button>
      </Modal.Actions>
    </>
  );
};
