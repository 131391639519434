import {
  useFloating,
  autoUpdate,
  autoPlacement,
  offset,
} from "@floating-ui/react";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHandleClickOutside } from "../../../utils/reactCustomHooks";
import { NoCodeNode } from "./useNoCodeParser";
import {
  getTogglableModifiersInitState,
  translateModifierState,
  friendlyTogglableModifiers,
  disableModifierState,
  shortFriendlyTogglableModifiers,
} from "./utils";
import { Button, Icon } from "../../../components/tailwind";
type Props = {
  node: NoCodeNode;
  onDragEnd: (event: React.DragEvent<HTMLButtonElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLButtonElement>) => void;
  onDragStart: (event: React.DragEvent<HTMLButtonElement>) => void;
  updateModifiers: (add: string[], remove: string[], nodeId: string) => void;
};

export const HTMLVariableNode: React.FC<Props> = ({
  node,
  onDragEnd,
  onDragOver,
  onDragStart,
  updateModifiers,
}) => {
  const disableDropdown = disableModifierState(node?.variableType);
  const [togglableModifiersState, setTogglableModifiersState] = useState<
    ReturnType<typeof getTogglableModifiersInitState>
  >(getTogglableModifiersInitState(node.modifiers, node?.tagType));

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const noModifiersSelected = (): boolean => {
    return Object.entries(togglableModifiersState)
      .filter(([key]) => key !== "tag_tokind")
      .every((entry) => !entry[1]);
  };

  const handleUpdateModifiersState = (key?: string): void => {
    setTogglableModifiersState((prev) => {
      const newState = { ...prev };
      if (!key) {
        Object.keys(newState).forEach((k) => {
          if (k !== "tag_tokind") {
            newState[k] = false;
          }
        });
        return newState;
      }
      if (newState?.[key] === undefined) {
        return prev;
      }
      newState[key] = true;
      Object.keys(newState).forEach((k) => {
        if (k !== "tag_tokind" && k !== key) {
          newState[k] = false;
        }
      });
      return newState;
    });
  };
  const handleOpenState = (open: boolean): void => {
    if (disableDropdown) return;
    setIsOpen(open);
    if (!open) {
      buttonRef.current?.classList.remove(
        "!tw-ring-2",
        "!tw-ring-gray-400/70",
        "!tw-ring-offset-1"
      );
    } else {
      buttonRef.current?.classList.add(
        "!tw-ring-2",
        "!tw-ring-gray-400/70",
        "!tw-ring-offset-1"
      );
    }
  };
  useHandleClickOutside(
    () => {
      if (disableDropdown) return;
      const initState = getTogglableModifiersInitState(
        node.modifiers,
        node?.tagType
      );
      const nothingChanged = Object.entries(togglableModifiersState).every(
        ([key, value]) => {
          return initState?.[key] == value;
        }
      );
      if (nothingChanged) {
        handleOpenState(false);
      } else {
        const { add, remove } = translateModifierState(togglableModifiersState);
        updateModifiers(add, remove, node.id);
      }
    },
    [isOpen, togglableModifiersState, disableDropdown],
    buttonRef,
    dropdownRef
  );
  const {
    refs: dropdownRefs,
    floatingStyles: dropdownFloatingStyles,
  } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    middleware: [
      autoPlacement({
        allowedPlacements: ["top-start", "bottom-start"],
      }),
      offset(4),
    ],
  });
  const {
    refs: statusIconRefs,
    floatingStyles: statusIconFloatingStyles,
  } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "absolute",
    placement: "top-end",
    middleware: [offset({ mainAxis: -6, crossAxis: -4 })],
  });
  const [isDragging, setIsDragging] = useState(false);

  const portalDestination =
    document.querySelector("dialog[open]") || document.body;
  return (
    <>
      <Button
        icon
        innerRef={(ref) => {
          dropdownRefs.setReference(ref);
          statusIconRefs.setReference(ref);
          buttonRef.current = ref;
        }}
        data-disable-blur-event
        data-node-id={node.id}
        data-node-type={node.type}
        data-node-value={node.value}
        contentEditable={false}
        onMouseEnter={(event): void => {
          (event.target as HTMLButtonElement).setAttribute("draggable", "true");
        }}
        onMouseLeave={(event): void => {
          (event.target as HTMLButtonElement).removeAttribute("draggable");
        }}
        onDragEnd={(event): void => {
          setIsDragging(false);
          onDragEnd(event);
        }}
        onDragOver={onDragOver}
        onDragStart={(event): void => {
          onDragStart(event);
          setIsDragging(true);
        }}
        compact="very"
        className={`tw-mx-1 tw-caret-transparent focus:tw-bg-secondary focus:tw-text-white focus:tw-ring-2 focus:tw-ring-gray-400/70 focus:tw-ring-offset-1 tw-align-middle ${
          isDragging ? "!tw-opacity-50" : ""
        }`}
        onClick={(): void => {
          handleOpenState(!isOpen);
        }}
      >
        <Icon
          name="drag_indicator"
          className="tw-pointer-events-none !tw-text-lg"
        />

        {node.displayValue}

        <Icon
          name="expand_more"
          className="tw-pointer-events-none !tw-text-lg"
        />
      </Button>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            data-disable-blur-event
            onInput={(event): void => {
              event.stopPropagation();
            }}
            ref={(ref) => {
              dropdownRefs.setFloating(ref);
              dropdownRef.current = ref;
            }}
            style={dropdownFloatingStyles}
            className="tw-z-50 tw-flex tw-max-h-96 tw-flex-col tw-overflow-y-auto tw-overscroll-contain tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-shadow"
          >
            <div
              role="option"
              className="tw-cursor-pointer tw-bg-inherit tw-px-3 tw-py-2 tw-transition-colors"
            >
              <label className="tw-inline-flex tw-cursor-pointer tw-gap-2">
                <input
                  data-disable-blur-event
                  className="txu-input"
                  type="radio"
                  checked={noModifiersSelected()}
                  onChange={(): void => {
                    handleUpdateModifiersState();
                  }}
                />
                No format
              </label>
            </div>
            {Object.entries(friendlyTogglableModifiers).map(([key, value]) => (
              <div
                key={key}
                role="option"
                className="tw-cursor-pointer tw-bg-inherit tw-px-3 tw-py-2 tw-transition-colors"
              >
                <label className="tw-inline-flex tw-cursor-pointer tw-gap-2">
                  <input
                    data-disable-blur-event
                    name={key}
                    className="txu-input"
                    type="radio"
                    checked={togglableModifiersState?.[key] || false}
                    onChange={(): void => {
                      handleUpdateModifiersState(key);
                    }}
                  />
                  {value}
                </label>
              </div>
            ))}
            {togglableModifiersState?.["tag_tokind"] !== undefined && (
              <div
                role="option"
                className="tw-cursor-pointer tw-border-t-[1px] tw-border-gray-300 tw-bg-inherit tw-px-3 tw-py-2 tw-transition-colors"
              >
                <label className="tw-inline-flex tw-cursor-pointer tw-gap-2">
                  <input
                    data-disable-blur-event
                    name="tag_tokind"
                    className="txu-input"
                    type="checkbox"
                    checked={togglableModifiersState?.["tag_tokind"] || false}
                    onChange={(): void => {
                      setTogglableModifiersState((prev) => {
                        const old = prev?.["tag_tokind"];
                        if (old !== undefined) {
                          return {
                            ...prev,
                            ["tag_tokind"]: !old,
                          };
                        }
                        return prev;
                      });
                    }}
                  />
                  Material as Kind
                </label>
              </div>
            )}
          </div>,
          portalDestination
        )}
      {!isOpen &&
        !disableDropdown &&
        ReactDOM.createPortal(
          <div
            ref={statusIconRefs.setFloating}
            className="tw-z-[5] tw-flex"
            style={statusIconFloatingStyles}
          >
            {Object.entries(friendlyTogglableModifiers).map(([key, value]) => {
              const isChecked = togglableModifiersState?.[key];
              if (!isChecked) return <></>;

              return (
                <div
                  className="tw-cursor-default tw-rounded tw-border tw-border-secondary tw-bg-gray-100 tw-p-1 tw-text-sm tw-leading-[0.4]"
                  key={key}
                  data-balloon={`${value}`}
                  data-balloon-pos="up-left"
                >
                  {shortFriendlyTogglableModifiers?.[key] || "unknown"}
                </div>
              );
            })}
          </div>,
          portalDestination
        )}
    </>
  );
};
