import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { fetchDataTagTab } from "../../api/action";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";

type Props = {
  productId: string;
};

export const TabDataTree: React.FC<Props> = ({ productId }) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const [tagsTree, setTagsTree] = useState("");

  useEffect(() => {
    fetchDataTagTab({ token, productId }).then((data) => {
      setTagsTree(data.tagsTree);
    });
  }, []);

  return (
    <Tab.Pane className={"data-tab-tree"}>
      <div className={"tags"} data-testid={"tab-data-tree"}>
        {tagsTree?.split("\n").map((tag: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`${tag}-${index}`} data-testid={"tab-data-tree-tag"}>
            {tag}
            <br />
          </span>
        ))}
      </div>
    </Tab.Pane>
  );
};
