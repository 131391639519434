import React from "react";
import { Divider, Icon, Popup } from "semantic-ui-react";
import Styled from "styled-components";

const FlexDiv = Styled.div`
& {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: small;
  }
}
`;

type Props = {
  comment: string | null;
};

export const ProductTextCommentViewer: React.FC<Props> = ({ comment }) => {
  if (!comment) {
    return null;
  }
  if (comment.length > 99) {
    const commentArray = comment.split(/\r?\n/).filter((comment) => comment);
    return (
      <>
        <Divider />
        <Popup
          trigger={
            <FlexDiv data-testid={"long-comment-wrapper"}>
              <Icon name="comment" color="blue" size="small" />
              <p
                data-testid={"long-comment-truncate"}
                className={"ui text grey"}
              >{`${comment.substring(0, 40)}...`}</p>
            </FlexDiv>
          }
          size={"small"}
          basic
          hoverable
          wide={"very"}
        >
          {commentArray.map((comment) => (
            <p key={comment}>{comment}</p>
          ))}
        </Popup>
      </>
    );
  } else {
    return (
      <>
        <Divider />
        <FlexDiv data-testid={"short-comment-wrapper"}>
          <Icon name="comment" color="blue" size="small" />
          <p data-testid={"short-comment"} className={"ui text grey"}>
            {comment}
          </p>
        </FlexDiv>
      </>
    );
  }
};
