import $ from "jquery";
import { loadPartial } from "../utils/partialUtils";
import { debounce } from "../utils/debounce";
import { ChannelId } from "../customers/Customer";
import { LanguageCode } from "../customers/customerlanguages";
import { renderPageDimmer } from "../utils/renderutils";

const EVENT = {
  SUBMIT_LOADING: "partial:loading",
  SUBMIT_DONE: "partial:loaded",
  CLICK_DONE: "partial:clicked",
};

const partials = {
  form: $("#form"),
  edit: $("#edit"),
  preview: $("#preview"),
  previewItem: (channelId: ChannelId): JQuery<HTMLElement> =>
    $(`[data-preview-item=channel-${channelId}]`),
  allPreviewItems: (): JQuery<HTMLElement> => $("[data-preview-item]"),
  originalText: $("#original-text"),
};

const topActions = $("#actions");

/**
 *
 * @param channelIds Include if only trigger reload for specific channels (All channels are reloaded if excluded)
 * @param language Include if the preview should display a different language (If a language change is done all channels are reloaded)
 * @returns void
 */
export const reloadPreview = (
  channelIds?: ChannelId[],
  language?: LanguageCode
): void => {
  const debounceTime = document.getElementById("debounce_time")?.textContent;
  // If in manual mode we submit only the preview items
  // This will make the individual channels to display info about he texts state and prompt the user to manually update them.
  const isManualMode = partials.preview
    .find("form")
    .attr("action")
    ?.includes("mode=manual");
  if (isManualMode) {
    if (channelIds) {
      channelIds.forEach((id) => {
        const previewItem = partials.previewItem(id);
        previewItem.closest("form.preview-item").trigger("submit");
      });
    } else {
      partials.allPreviewItems().each((_, item) => {
        $(item).closest("form.preview-item").trigger("submit");
      });
    }
    return;
  }

  let willStayInSameLanguage = true;
  if (language) {
    const previewLanguageSelector = $("#preview-language-selector");
    const prevSelected = previewLanguageSelector.find("option[selected]").val();
    willStayInSameLanguage = prevSelected == language;

    if (!willStayInSameLanguage) {
      const languageOption = previewLanguageSelector.find(
        `option[value=${language}]`
      );
      languageOption.attr("selected", "");
    }
  }

  // Submit preview form to show how product changes would affect generated text.
  // This function is triggered by changing edit tab fields or adding templates.
  if (debounceTime) {
    debounce(() => {
      if (!partials.preview.hasClass("loading")) {
        if (channelIds && willStayInSameLanguage) {
          channelIds.forEach((id) => {
            const previewItem = partials.previewItem(id);
            previewItem.closest("form.preview-item").trigger("submit");
          });
        } else {
          partials.preview.find("form").trigger("submit");
        }
      }
    }, parseInt(debounceTime))();
  }
};

export const quietSubmit = (
  form: JQuery<HTMLElement>,
  skipReloadPreview?: boolean
): void => {
  topActions.addClass("loading");
  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: form.serializeArray(),
    success: function () {
      if (!skipReloadPreview) {
        reloadPreview();
      }
      topActions.removeClass("loading");
    },
  });
};

// TODO: Might be OK to remove. But needs to be replaced with reloadPreview where it's called.
// form.text-pattern does not exist on product detail page (there is a form.text-patterns).
// This works because the ajax call in quietSubmit allows url, type and data to be falsy values. In other words sending in a empty Jquery object too quietSubmit will always make the request a success. And a side effect of that is reloading the preview
window.queuePreviewTextGeneration = (): void =>
  quietSubmit($("form.text-pattern"));

window.textPatternOnDataChanged = function (element): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    form.submit();
  });
};

window.templateSelectorOnDataChanged = function (element): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    form.submit();
  });
};

window.tagTreeEditorOnDataChanged = function (element): void {
  if ($(element).closest(".vision-annotations").length > 0) {
    return;
  }

  const doSubmit = function (): void {
    const form = $(element).closest("form");

    // for fields in template editor
    if (form.closest(".edit-templates").length) {
      setTimeout(() => form.submit());
    }
    setTimeout(() => quietSubmit(form));
  };

  const $annotationLock = $(".annotation-lock");

  if ($annotationLock.length > 0) {
    const productId = $annotationLock.data("product-id");
    $.ajax({
      type: "POST",
      url: "/api/_internal/product/set_annotation_lock",
      data: { product_id: productId, locked: true },
      success: function () {
        $annotationLock.show();
        doSubmit();
      },
    });
  } else {
    doSubmit();
  }
};

window.productTagsEditorOnDataChanged = function (
  element,
  delayedRefresh
): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    if (delayedRefresh) {
      quietSubmit(form);
      setTimeout(function () {
        productDetailsRefreshCurrentTab();
      }, 1000);
    } else {
      const skipSubmit = form.hasClass("manage-pipeline-action-step-form");
      if (!skipSubmit) {
        form.submit();
      }
    }
  });
};

window.vocabularyLookupOnDataChanged = function (element, c, d): void {
  const el = $(element);
  const form = el.closest("form");

  d = !d ? [] : Array.isArray(d) ? d : [d];

  const vocabIds = d.map((tag) => tag["id"]);
  el.closest("[data-vocab-id]").data("vocab-id", vocabIds.join(" "));

  // for vocabulary lookup fields in template editor
  if (form.closest(".edit-templates").length) {
    setTimeout(function () {
      form.submit();
    });
  }
  // material tab
  if (form.closest(".edit-template-form").length) {
    setTimeout(function () {
      form.submit();
    });
  }
  // color in edit product color tab
  if (form.closest(".edit-child-colors").length) {
    setTimeout(function () {
      quietSubmit(form);
    });
  }
};

window.brandSelectorOnDataChanged = function (element, c, d): void {
  const form = $(element).closest("form");
  const newBrand = d["name"] + "";
  $(element)
    .closest("[data-tag-value]")
    .data("tag-value", newBrand.replace(/\s/g, "-"));

  setTimeout(function () {
    quietSubmit(form);
  });
};

window.multipleSelectorOnDataChanged = function (element): void {
  if ($(element).parent().hasClass("no-auto-submit")) return; // TEX-5905

  const form = $(element).closest("form");

  setTimeout(function () {
    quietSubmit(form);
  });
};

window.templateMultipleLabelSelectorOnDataChanged = function (element): void {
  const form = $(element).closest("form");
  setTimeout(function () {
    quietSubmit(form);
  });
};

window.submitAnnotations = function (element): void {
  const form = $(element).closest("form");
  topActions.addClass("loading");
  let _timeout = $(element).data("timeout");
  clearTimeout(_timeout);
  _timeout = setTimeout(function () {
    $.ajax({
      url: form.attr("action"),
      type: form.attr("method"),
      data: form.serializeArray(),
      success: function () {
        const extractTagTree = $("#edit_interactive_extract_tag_tree");
        let _innerTimeout = extractTagTree.data("timeout");
        clearTimeout(_innerTimeout);
        _innerTimeout = setTimeout(function () {
          loadPartial(extractTagTree, true);
          reloadPreview();
          topActions.removeClass("loading");
        }, 500);
        extractTagTree.data("timeout", _innerTimeout);
      },
    });
  }, 200);
  $(element).data("timeout", _timeout);
};

$(document).on("click", "#annotation-submit-button", function () {
  window.submitAnnotations($(this));
});

const connectForm = (partial: JQuery<HTMLElement>): void => {
  partial.on(
    "keydown",
    "form .field > input, form .field > textarea",
    function () {
      const input = $(this);
      input.data("value", input.val());
    }
  );

  partial.on(
    "keyup",
    "form .field > input, form .field > textarea",
    function () {
      const input = $(this);
      const form = input.closest("form");

      let _timeout = input.data("timeout");
      let _oldValue = input.data("value") + "";
      let _newValue = input.val() + "";

      _oldValue = _oldValue || "";
      _newValue = _newValue || "";

      if (_newValue.trim() !== _oldValue.trim()) {
        clearTimeout(_timeout);
        _timeout = setTimeout(function () {
          input
            .closest("[data-tag-value]")
            .data("tag-value", _newValue.replace(/\s/g, "-"));

          topActions.addClass("loading");
          form.find(".error-messages").empty();
          form.find("input").css("border", "solid 1px rgba(0, 0, 0, 0.2)");
          $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: form.serializeArray(),
            success: function (data) {
              Object.entries(data.errors).map(([field, errors]) => {
                form
                  .find(`[name="${field}"]`)
                  .css("border", "solid 1px rgba(244,66,54,1)");
                const errorsField = form.find(`#${field}_errors`);
                (errors as string[]).map((error) => {
                  errorsField.append(`<li>${error}</li>`);
                });
              });
              if (!data.errors) {
                reloadPreview();
              }
              topActions.removeClass("loading");
            },
          });
        }, 300);

        input.data("timeout", _timeout);
      }
    }
  );
};

connectForm(partials.form);

$(document).on("click", ".annotation-lock button", function () {
  const $button = $(this);
  const $annotationLock = $button.closest(".annotation-lock");
  const productId = $annotationLock.data("product-id");

  $.ajax({
    type: "POST",
    url: "/api/_internal/product/set_annotation_lock",
    data: { product_id: productId, locked: false },
    success: function () {
      $annotationLock.hide();
    },
  });
});

// Auto submit form on number-change
$(document).on("change", ".original-text-form select", function () {
  const form = $(this).closest("form");
  $(this).attr("name", "change_language");
  setTimeout(function () {
    quietSubmit($("#original-text-form-id"));
    form.submit();
  });
});

$(document).on("click", ".additional-information-product-details", function () {
  const icon = $(this).find("i");
  const accordion = $(this).closest("div.accordion");
  const content = $(accordion).find("div.content");
  if (icon.hasClass("active")) {
    icon.removeClass("down active").addClass("right");
    content.removeClass("active");
  } else {
    icon.removeClass("right").addClass("down active");
    content.addClass("active");
  }
});
// Auto submit translations form on language change
// $(document).on('change', '.translations input[type="checkbox"]', function (event) {
//   var form = $(this).closest('form');
//   form.submit();
// });

(function (): void {
  const selector = [
    '.edit-fields-form input[name="subpart_name"]',
    ".original-text-form input",
    ".original-text-form textarea",
    ".extract-form input",
    ".extract-form textarea",
    ".edit-template-form .template-field > input",
    ".edit-template-form .template-field > textarea",
  ].join(", ");

  // Auto submit form on name-change
  $(document).on("keydown", selector, function () {
    const input = $(this);
    input.data("value", input.val());
  });

  // Auto submit form on checkbox change
  $(document).on(
    "change",
    '.edit-template-form .template-field > input[type="checkbox"]',
    function () {
      const input = $(this)[0];
      $(input).data("value", input.checked);
      const form = $(input).closest("form");
      quietSubmit(form);
    }
  );

  // Auto submit on subpart name change
  $(document).on("keyup", selector, function () {
    const debounceTime = 600;
    const input = $(this);
    const form = input.closest("form");
    let _timeout = input.data("timeout");
    let _oldValue = input.data("value") + "";
    let _newValue = input.val() + "";

    _oldValue = _oldValue || "";
    _newValue = _newValue || "";

    if (_newValue.trim() !== _oldValue.trim()) {
      clearTimeout(_timeout);
      _timeout = setTimeout(function () {
        input
          .closest("[data-tag-value]")
          .data("tag-value", _newValue.replace(/\s/g, "-"));
        quietSubmit(form);
      }, debounceTime);

      input.data("timeout", _timeout);
    }
  });
})();

$(document).on(
  `${EVENT.SUBMIT_DONE} ${EVENT.CLICK_DONE}`,
  "#edit",
  function () {
    reloadPreview();
  }
);

$(document).on(EVENT.SUBMIT_DONE, "#form", function () {
  reloadPreview();
  loadPartial(partials.edit, true);
});

$(document).on(
  {
    mouseenter: function () {
      const dataLabel = $("#frequency-data-label");
      dataLabel.css("opacity", 1);
      dataLabel.text("Frequency: " + $(this).data("frequency"));
    },
    mouseleave: function () {
      $("#frequency-data-label").css("opacity", 0);
    },
  },
  "mark[data-frequency]"
);

// Highlight related `data-vocab-id` elements on hover
$(document).on(
  {
    mouseenter: function () {
      const el = $(this);
      const vocabIds = String(el.data("vocab-id")).split(" ");
      const tagValues = String(el.data("tag-value")).split(" ");
      const templateIds = String(el.data("template-id")).split(" ");
      el.addClass("highlight");
      vocabIds.forEach((id) => {
        $(`[data-vocab-id~="${id}"]`).addClass("highlight");
      });
      tagValues.forEach((value) => {
        $(`[data-tag-value~="${value}"]`).addClass("highlight");
      });
      templateIds.forEach((value) => {
        $(`[data-template-id~="${value}"]`).addClass("highlight");
      });
    },
    mouseleave: function () {
      $("[data-vocab-id], [data-template-id]").removeClass("highlight");
    },
  },
  "[data-vocab-id], [data-template-id]"
);

$(document).on(
  {
    mouseenter: function () {
      $(".texts-grid").addClass("fade");
    },
    mouseleave: function () {
      $(".texts-grid").removeClass("fade");
    },
  },
  ".edit-fields-form [data-vocab-id] .vocabulary-lookup .tag-label, " +
    ".edit-fields-form span [data-vocab-id], " +
    ".edit-fields .left [data-vocab-id], " +
    "#form [data-tag-value] .text, " +
    "#form [data-tag-value] input#id_name"
);
// '.edit-fields-form [data-vocab-id] .VocabularyLookup #react-async-select div > div:first-child > div, ' +
// '.edit-fields-form [data-vocab-id] .subpart, ' +
// '.edit-fields-form span [data-vocab-id], ' +
// '.edit-fields .left [data-vocab-id], ' +
// '#form [data-tag-value] .text, ' +
// '#form [data-tag-value] input#id_name'
$(document).on("focus", '[data-js-id="fluent-write-form"] input', function () {
  $(this).closest(".fluent-row").removeClass("verify").addClass("write");
});

$(document).on(EVENT.SUBMIT_DONE, ".edit-fluent", function () {
  $(this)
    .find('[data-js-id="fluent-verify-form"] button[type="submit"]')
    .focus();
});

$(document).on("change", ".change-product-category-form select", function (e) {
  if (confirm("This will delete your currently inserted tags. Continue?")) {
    $(this).closest("form").submit();
  } else {
    const previousId = $(this).closest("form").data("current-category-id");
    $(this).val(previousId);
    e.preventDefault();
  }
});

// Handle select all languages in translations modal
$(document).on("click", "#language-select-all", function () {
  $(this)
    .closest(".channel-language-form")
    .find("input[type=checkbox]")
    .prop("checked", true);
});

// Handle deselect all languages in translations modal
$(document).on("click", "#language-deselect-all", function () {
  $(this)
    .closest(".channel-language-form")
    .find("input[type=checkbox]")
    .prop("checked", false);
});

// Handle tabs in product detail view (image, original texts)
let currentActiveTabIndex = 0;
$(document).on("click", ".tab-switcher", function () {
  $(".tab-switcher").removeClass("active");
  $(this).addClass("active");
  const tabClicked = $(this).data("tab-index");
  if (tabClicked !== currentActiveTabIndex) {
    $(".tab-container").each(function () {
      if ($(this).data("tab-index") === tabClicked) {
        $(".tab-container").hide();
        $(this).show();
        currentActiveTabIndex = $(this).data("tab-index");
      }
    });
  }
});

// Use this function to refresh current tab in product detail view
export const productDetailsRefreshCurrentTab = (): void => {
  const partial = $("#edit");
  if (partial) {
    partial.data("url", partial.find(".tab.active").attr("href"));
    loadPartial(partial, true);
  }
};

export const productDetailsRefreshProductDescription = (): void => {
  const partial = $("#form.product-description-form");
  if (partial) {
    partial.data("url");
    loadPartial(partial, true);
  }
};

// Calculate text preview height to fill up reminder of visible viewport height
function resizePreview(): void {
  // This makes sure all generated text will be able to be viewed in any viewport height
  // Add height when rendered
  const viewportHeight = window.innerHeight;
  const previewInner = $(".preview-inner");
  const subHeight = 175;
  previewInner.css("height", `${viewportHeight - subHeight}px`);
}

const resizePreviewObserver = new MutationObserver(resizePreview);
partials.preview.each(function () {
  resizePreviewObserver.observe(this, {
    attributes: true,
    childList: true,
    characterData: true,
  });
});

function resizeAllTagTrees(): void {
  const els = $(".field-type-tag_tree");

  els.each(function (this): void {
    const tagtree = $(this).find(".rendered-sortable-tree");
    const container = $(this).closest(".container");
    tagtree.css("width", container.innerWidth() - 50);
  });
}

const resizeAllTagTreesObserver = new MutationObserver(resizeAllTagTrees);
const tagTrees = $(".field-type-tag_tree");
tagTrees.each(function () {
  resizeAllTagTreesObserver.observe(this, {
    attributes: true,
    childList: true,
    characterData: true,
  });
});

const partialObserver = new MutationObserver(() => {
  const partialItems = $("form.partial.preview-item");
  partialItems.each(function () {
    loadPartial($(this));
  });
});
partials.preview.each(function () {
  partialObserver.observe(this, {
    childList: true,
  });
});

window.onresize = function (): void {
  resizePreview();
  resizeAllTagTrees();
};
$(document).on("click", "#submit-duplicate-form", function (): void {
  const duplicateForm = $("form#duplicate-form");
  $(".partial").each((_, el) => {
    loadPartial($(el), true, true);
  });
  renderPageDimmer({
    header: "Duplicating Document",
    loading: true,
  });
  duplicateForm.trigger("submit");
});
