import React, { FormEvent, useState } from "react";
import {
  Icon,
  Checkbox,
  CheckboxProps,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { ColumnValue, ColumnLabel } from "../columns/Columns";
import { ProductListAction } from "../ProductList";
import { DropdownHideDisplayText } from "./ProductListSelectionBanner";
import { ChannelLanguagePair } from "../ProductListURLConfig";
import { SemanticPopupWithReactRef } from "../../../ui/semantic-ui-overwrites";

export type Props = {
  groupChildren: boolean;
  selectedColumns: ColumnValue[];
  onProductListAction: (
    action: ProductListAction,
    value?:
      | ColumnValue[]
      | boolean
      | {
          [key in
            | "channelLanguagePairs"
            | "referenceChannelLanguagePairs"]: ChannelLanguagePair[];
        }
  ) => void;
  showTextColumns: boolean;
  openChannelLanguageSelectorModal: (open: boolean) => void;
};
export const ColumnSelectorMenuItem: React.FC<Props> = ({
  groupChildren,
  selectedColumns,
  onProductListAction,
  showTextColumns,
  openChannelLanguageSelectorModal,
}) => {
  const [open, setOpen] = useState(false);
  const [checkboxClicked, setCheckBoxClicked] = useState(false);
  const onCheckboxChange = (
    checked: boolean,
    columnValue: ColumnValue
  ): void => {
    if (checked) {
      selectedColumns = [...selectedColumns, columnValue];
    } else {
      selectedColumns = selectedColumns.filter(
        (value: ColumnValue): boolean => value != columnValue
      );
    }
    onProductListAction(ProductListAction.ChangeColumns, selectedColumns);
    setCheckBoxClicked(true);
  };
  const renderDropdownItem = (value: ColumnValue): React.ReactElement => (
    <Dropdown.Item
      onClick={(): void =>
        onCheckboxChange(!selectedColumns.includes(value), value)
      }
    >
      <Checkbox
        style={{ fontSize: "unset" }}
        checked={selectedColumns.includes(value)}
        label={ColumnLabel[value]}
        value={value}
        data-testid={value}
      />
    </Dropdown.Item>
  );

  const handleCommitColumnUpdates = (): void => {
    if (checkboxClicked) {
      onProductListAction(ProductListAction.UpdateColumns);
      setCheckBoxClicked(false);
    }
    setOpen(false);
  };
  return (
    <SemanticPopupWithReactRef
      popupProps={{
        content: "Show/Hide columns",
        disabled: open,
        position: "top right",
      }}
    >
      <DropdownHideDisplayText
        data-testid="column-selector-menu-item"
        upward={false}
        floating
        open={open}
        onBlur={handleCommitColumnUpdates}
        onClick={(e: React.SyntheticEvent): void => {
          if (!open) {
            setOpen(true);
            return;
          }
          const dropdownTriggerRoles = ["listbox-icon", "listbox"];
          const clickedElement = e?.nativeEvent?.target as HTMLElement;
          if (dropdownTriggerRoles.includes(clickedElement?.role)) {
            handleCommitColumnUpdates();
          }
        }}
        item
        icon={
          <>
            <Icon name="columns" size="large" role="listbox-icon" />
            <Icon name="dropdown" fitted role="listbox-icon" />
          </>
        }
      >
        <Dropdown.Menu direction="left">
          <Dropdown.Header content="Select columns" />
          {showTextColumns && (
            <Dropdown.Item>
              <Button
                size="tiny"
                compact
                fluid
                onClick={(): void => {
                  openChannelLanguageSelectorModal(true);
                }}
              >
                Setup text column(s)...
              </Button>
            </Dropdown.Item>
          )}
          {renderDropdownItem(ColumnValue.SKU)}
          {renderDropdownItem(ColumnValue.EAN)}
          {renderDropdownItem(ColumnValue.ProductType)}
          {renderDropdownItem(ColumnValue.OriginalText)}
          {renderDropdownItem(ColumnValue.TextStatuses)}
          {renderDropdownItem(ColumnValue.Product)}
          {renderDropdownItem(ColumnValue.Status)}
          {renderDropdownItem(ColumnValue.GenerateText)}
          {renderDropdownItem(ColumnValue.Labels)}
          {renderDropdownItem(ColumnValue.Parent)}
          {renderDropdownItem(ColumnValue.Created)}
          {renderDropdownItem(ColumnValue.Updated)}
          <Dropdown.Divider />
          <Dropdown.Item>
            <Checkbox
              style={{ fontSize: "unset" }}
              checked={groupChildren}
              label={ColumnLabel[ColumnValue.GroupChildren]}
              onChange={(
                event: FormEvent<HTMLInputElement>,
                { checked }: CheckboxProps
              ): void => {
                event.preventDefault();
                onProductListAction(
                  ProductListAction.ChangeGroupChildren,
                  checked
                );
                setCheckBoxClicked(true);
              }}
              value={ColumnValue.GroupChildren}
            />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item style={{ marginBlock: "0.7rem" }}>
            <Button
              data-testid="column-selector-menu-item-apply-changes"
              fluid
              content="Apply changes"
              basic
              onClick={handleCommitColumnUpdates}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </DropdownHideDisplayText>
    </SemanticPopupWithReactRef>
  );
};
