import React from "react";
import {
  Button,
  Icon,
  ToggleCheckbox,
  ToggleLabel,
} from "../../components/tailwind";
import {
  OnUpdateLexiconParams,
  TemplateLexiconWithUncommittedTranslationChanges,
} from "./TemplateBuilderModal";
import { Language, LanguageCode } from "../../customers/customerlanguages";

type Props = {
  isStatic?: boolean;
  lexicon: TemplateLexiconWithUncommittedTranslationChanges;
  language: Language;
  onUpdateLexicon: (
    languageCode: LanguageCode,
    params: OnUpdateLexiconParams
  ) => void;
  onCopyToAllLexicons: (languageCode: LanguageCode, value: string) => void;
  onSendAllLexiconsToManualTranslation?: (languageCode: LanguageCode) => void;
  onTranslate: () => void;
  isTranslating?: boolean;
  canTranslate?: boolean;
};

export const TemplateBuilderContentLanguageInputToolbar: React.FC<Props> = ({
  isStatic,
  lexicon,
  language,
  onUpdateLexicon,
  onCopyToAllLexicons,
  onSendAllLexiconsToManualTranslation,
  onTranslate,
  isTranslating,
  canTranslate,
}) => {
  if (isStatic) {
    return (
      <div className="-tw-mt-[47px]">
        <p className="tw-mx-0 tw-text-sm tw-text-gray-500">
          These actions are applied to all languages except{" "}
          {language?.short_name}
        </p>
        <div className="tw-grid tw-auto-rows-min tw-grid-cols-[auto_1fr] tw-items-center tw-gap-x-3 tw-gap-y-2">
          <Button
            variant="primary"
            size="sm"
            compact
            disabled={!language || !lexicon?.template}
            onClick={(): void =>
              onCopyToAllLexicons(language?.code, lexicon?.template)
            }
            data-testid="template-builder-content-copy-all"
          >
            <Icon name="content_copy" />
          </Button>
          <p className="tw-m-0 tw-text-sm">Copy this text to all languages</p>
          <Button
            variant="primary"
            icon
            size="sm"
            compact
            disabled={!language || !lexicon?.template || !canTranslate}
            loading={isTranslating}
            onClick={onTranslate}
            data-testid="template-builder-content-machine-translate-all"
          >
            <Icon name="translate" />
          </Button>
          <p className="tw-m-0 tw-text-sm">Machine Translate all</p>
          <Button
            variant="primary"
            icon
            size="sm"
            compact
            onClick={(): void =>
              onSendAllLexiconsToManualTranslation?.(language?.code)
            }
            data-testid="template-builder-content-manual-translate-all"
          >
            <Icon name="supervised_user_circle" />
          </Button>
          <div>
            <p className="tw-m-0 tw-text-sm">Send all for Manual Translation</p>
            <p className="tw-m-0 -tw-mb-7 tw-text-xs tw-text-gray-500">
              Templates will be sent for Manual Translation when pressing
              &quot;Save&quot;
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tw-grid tw-auto-rows-min tw-grid-cols-[auto_1fr] tw-items-center tw-gap-x-3 tw-gap-y-2">
      <Button
        className="tw-place-self-center"
        variant="primary-alt"
        icon
        size="sm"
        compact
        disabled={
          !language ||
          !canTranslate ||
          (lexicon?.in_manual_translation &&
            (!lexicon?.notCommitted || lexicon?.canWithdrawWhenCommitted))
        }
        loading={isTranslating}
        onClick={onTranslate}
        data-testid={`template-builder-content-toolbar-${language?.code}-machine-translate-button`}
      >
        <Icon name="translate" />
      </Button>
      <p className="tw-m-0 tw-text-sm">Translate using Machine Translation</p>
      {language?.translate && (
        <>
          <ToggleCheckbox
            data-testid={`template-builder-content-toolbar-${language?.code}-manual-translation-checkbox`}
            checkboxId={`checkbox-toggle-${language?.code}`}
            disabled={!language || !!lexicon?.translator_approved}
            checked={lexicon?.in_manual_translation || false}
            setChecked={(checked): void =>
              onUpdateLexicon(language?.code, {
                inManualTranslation: checked,
              })
            }
          />
          <ToggleLabel
            disabled={!language || !!lexicon?.translator_approved}
            checked={lexicon?.in_manual_translation || false}
            label="Send for Manual Translation"
            checkboxId={`checkbox-toggle-${language?.code}`}
          />
        </>
      )}

      {lexicon?.in_manual_translation &&
        lexicon?.notCommitted &&
        !lexicon?.canWithdrawWhenCommitted && (
          <p
            className="tw-col-span-2 tw-m-0 tw-text-sm"
            data-testid={`template-builder-content-toolbar-${language?.code}-action-needed-send-for-manual-translation-info`}
          >
            When pressing Save, this language will be <b>sent</b> for manual
            translation
          </p>
        )}
      {!lexicon?.in_manual_translation &&
        lexicon?.notCommitted &&
        lexicon?.canWithdrawWhenCommitted && (
          <p
            className="tw-col-span-2 tw-m-0 tw-text-sm"
            data-testid={`template-builder-content-toolbar-${language?.code}-action-needed-withdraw-from-manual-translation-info`}
          >
            When pressing Save, this language will be <b>withdrawn</b> from
            manual translation
          </p>
        )}
    </div>
  );
};
