import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import {
  DoneTranslateTextResponse,
  MachineTranslateTaskQueue,
} from "../../api/machineTranslationApi";
import { Language, LanguageCode } from "../../customers/customerlanguages";
import { TemplateField } from "../template";
import {
  OnUpdateLexiconParams,
  TemplateLexiconWithUncommittedTranslationChanges,
} from "./TemplateBuilderModal";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import { TemplateBuilderContentLanguageInput } from "./TemplateBuilderContentLanguageInput";

type Props = {
  languages: Language[];
  initialStaticLanguage: Language;
  lexicons: {
    [key in LanguageCode]?: TemplateLexiconWithUncommittedTranslationChanges;
  };
  onUpdateLexicon: (
    languageCode: LanguageCode,
    params: OnUpdateLexiconParams
  ) => void;
  onUpdateLexiconVariant: (
    languageCode: LanguageCode,
    value: string,
    index: number,
    removeVariant?: boolean
  ) => void;
  variables: TemplateField[];
  onCreateVariable: (variable: TemplateField) => void;
};

export const TemplateBuilderContentTab: React.FC<Props> = ({
  languages,
  initialStaticLanguage,
  lexicons,
  onUpdateLexicon,
  onUpdateLexiconVariant,
  variables,
  onCreateVariable,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [selectedStaticLanguage, setSelectedStaticLanguage] = useState(
    initialStaticLanguage
  );
  const [viewMode, setViewMode] = useState<"preview" | "all" | null>("all");
  const [selectedPreviewLanguage, setSelectedPreviewLanguage] = useState<
    Language
  >(null);
  const languagesWithoutStaticLanguage = useMemo(
    () => languages.filter(({ code }) => code !== selectedStaticLanguage.code),
    [selectedStaticLanguage]
  );

  const [translatingLanguages, setTranslatingLanguages] = useState<
    LanguageCode[]
  >([]);

  const machineTranslateTaskQueue = useMemo(() => {
    if (token)
      return new MachineTranslateTaskQueue<DoneTranslateTextResponse>(
        token,
        2000
      );
  }, [token]);

  const canTranslate = useMemo(
    () =>
      !!selectedStaticLanguage &&
      !!lexicons[selectedStaticLanguage.code]?.template,
    [lexicons, selectedStaticLanguage]
  );

  const selectableStaticLanguages = useMemo(() => {
    return languages.filter(
      (language) => !lexicons[language.code]?.in_manual_translation
    );
  }, [languages, lexicons]);

  useEffect(() => {
    if (!selectableStaticLanguages.length) return;

    if (lexicons[selectedStaticLanguage?.code]?.in_manual_translation) {
      setSelectedStaticLanguage(selectableStaticLanguages[0]);
    }
  }, [selectedStaticLanguage, lexicons, selectableStaticLanguages]);

  const onViewAll = (): void => {
    if (viewMode == "all") {
      setViewMode(null);
      return;
    }
    setViewMode("all");
  };
  const onViewPreview = (): void => {
    if (viewMode == "preview") {
      setViewMode(null);
      return;
    }
    setViewMode("preview");
  };

  const onCopyToAllLexicons = (
    languageCode: LanguageCode,
    value: string
  ): void => {
    const lockedLanguageCodes = Object.values(lexicons)
      .filter(
        ({ in_manual_translation, notCommitted }) =>
          in_manual_translation && !notCommitted
      )
      .map(({ language_code }) => language_code);
    languages
      .filter(
        ({ code }) => ![languageCode, ...lockedLanguageCodes].includes(code)
      )
      .forEach(({ code }) => {
        onUpdateLexicon(code, { value });
      });
  };

  const onTranslate = async (
    targetLanguageCodes: LanguageCode[],
    action?: "single" | "all"
  ): Promise<void> => {
    const lockedLanguageCodes = Object.values(lexicons)
      .filter(
        ({ in_manual_translation, notCommitted }) =>
          in_manual_translation && !notCommitted
      )
      .map(({ language_code }) => language_code);

    const allowedLanguages = targetLanguageCodes.filter(
      (code) => !lockedLanguageCodes.includes(code)
    );
    if (!allowedLanguages.length) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: "Selected Languages cannot be translated.",
          additionalContent:
            "This is most likely because all of the selected Languages have been sent for Manual Translation",
        })
      );
      return;
    }

    setTranslatingLanguages((prev) => [...prev, ...allowedLanguages]);
    if (action == "all") {
      setTranslatingLanguages((prev) => [
        ...prev,
        selectedStaticLanguage?.code,
      ]);
    }
    await machineTranslateTaskQueue
      .translateText(
        lexicons[selectedStaticLanguage?.code]?.template,
        "",
        selectedStaticLanguage.code,
        allowedLanguages
      )
      .catch(() => {
        setTranslatingLanguages([]);
      });

    const translationJobs = allowedLanguages.map((code) => {
      return machineTranslateTaskQueue
        .waitUntilTranslateLanguageIsDone(code)
        .then((result) => {
          onUpdateLexicon(code, {
            value: result.translated_text,
            translatedByMachineTranslate: true,
          });
        })
        .finally(() => {
          setTranslatingLanguages((prev) =>
            prev.filter((tCode) => code !== tCode)
          );
        });
    });

    let additionalContent = `Translated ${allowedLanguages.length} Language(s)`;

    if (lockedLanguageCodes.length) {
      additionalContent += `, Skipped ${lockedLanguageCodes.length} Language(s) as these are in Manual Translation`;
    }

    await Promise.all(translationJobs).finally(() => {
      if (action == "all") {
        setTranslatingLanguages((prev) =>
          prev.filter((tCode) => selectedStaticLanguage?.code !== tCode)
        );
      }
    });
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Translation Complete",
        additionalContent: additionalContent,
      })
    );
  };

  const onSendAllLexiconsToManualTranslation = (
    languageCode: LanguageCode
  ): void => {
    const languageCodesToCheck = languages
      .filter(({ translate, code }) => translate && code !== languageCode)
      .map(({ code }) => code);
    const lexiconsToCheck = Object.values(
      lexicons
    ).filter(({ language_code }) =>
      languageCodesToCheck.includes(language_code)
    );
    lexiconsToCheck.forEach((lexicon) => {
      if (!lexicon?.in_manual_translation) {
        onUpdateLexicon(lexicon.language_code, { inManualTranslation: true });
      }
    });
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content:
          "All languages marked for manual translation and will be sent when saved.",
      })
    );
  };

  return (
    <div>
      <TemplateBuilderContentLanguageInput
        isStatic
        language={selectedStaticLanguage}
        selectLanguage={setSelectedStaticLanguage}
        onViewAll={onViewAll}
        onViewPreview={onViewPreview}
        viewMode={viewMode}
        lexicon={lexicons[selectedStaticLanguage.code]}
        onUpdateLexicon={onUpdateLexicon}
        onUpdateLexiconVariant={onUpdateLexiconVariant}
        variables={variables}
        onCreateVariable={onCreateVariable}
        onCopyToAllLexicons={onCopyToAllLexicons}
        languageOptions={selectableStaticLanguages}
        onTranslate={onTranslate}
        canTranslate={canTranslate}
        isTranslating={translatingLanguages.includes(
          selectedStaticLanguage?.code
        )}
        onSendAllLexiconsToManualTranslation={
          onSendAllLexiconsToManualTranslation
        }
      />
      {viewMode !== null && <div className="tw-my-6 tw-border-b-2"></div>}
      {viewMode === "all" && (
        <div
          className="tw-ml-6 tw-flex tw-flex-col tw-gap-6"
          data-testid="template-builder-content-view-all"
        >
          {languagesWithoutStaticLanguage.map((language) => (
            <TemplateBuilderContentLanguageInput
              language={language}
              key={language.code}
              lexicon={lexicons[language.code]}
              onUpdateLexicon={onUpdateLexicon}
              onUpdateLexiconVariant={onUpdateLexiconVariant}
              variables={variables}
              onCreateVariable={onCreateVariable}
              onCopyToAllLexicons={onCopyToAllLexicons}
              onTranslate={onTranslate}
              canTranslate={canTranslate}
              isTranslating={translatingLanguages.includes(language.code)}
            />
          ))}
        </div>
      )}
      {viewMode === "preview" && (
        <div
          className="tw-ml-6"
          data-testid="template-builder-content-view-preview"
        >
          <TemplateBuilderContentLanguageInput
            language={selectedPreviewLanguage}
            asPreview
            selectLanguage={setSelectedPreviewLanguage}
            languageOptions={languagesWithoutStaticLanguage}
            lexicon={
              selectedPreviewLanguage
                ? lexicons[selectedPreviewLanguage.code]
                : undefined
            }
            onUpdateLexicon={onUpdateLexicon}
            onUpdateLexiconVariant={onUpdateLexiconVariant}
            variables={variables}
            onCreateVariable={onCreateVariable}
            onCopyToAllLexicons={onCopyToAllLexicons}
            onTranslate={onTranslate}
            canTranslate={canTranslate}
            isTranslating={translatingLanguages.includes(
              selectedPreviewLanguage?.code
            )}
          />
        </div>
      )}
    </div>
  );
};
