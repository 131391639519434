import React from "react";
import kebabCase from "lodash/kebabCase";
import {
  SemanticSIZES,
  SemanticCOLORS,
  SemanticFLOATS,
  SemanticTEXTALIGNMENTS,
} from "semantic-ui-react";
export type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type Paragraph = "p";
type Others = "code" | "pre" | "blockquote";
export type HTMLTextElements = Paragraph | Others | Headings;

type TextDecoration =
  | "overline"
  | "line-through"
  | "underline"
  | "overline-underline";

type SemanticColorsExtended = SemanticCOLORS | "white";
interface ColorClasses {
  color?: SemanticColorsExtended;
  primary?: boolean;
  secondary?: boolean;
}
interface Classes extends ColorClasses {
  className?: string; // Additional classes
  compact?: boolean; // Removes all margin
  lessMargin?: boolean; // Reduces margin
  disabled?: boolean; // Adds opacity: 0.5 and line-through
  inline?: boolean; // displays inline-block
  floated?: SemanticFLOATS; // Makes element float
  size?: Exclude<SemanticSIZES, "massive">; // changes font-size
  textAlignment?: SemanticTEXTALIGNMENTS;
  textDecoration?: TextDecoration;
  width?: "max" | "min"; // max-content or min-content
}

interface InitProps extends Classes {
  as?: HTMLTextElements | "a";
  content?: string;
  href?: string;
  testId?: string;
  id?: string;
  style?: React.CSSProperties;
}

// When this component is called and as="a" the href prop is mandatory.
export interface PropsWhenAsIsA extends InitProps {
  as: "a";
  href: string;
}
// When this component is called and as!="a" the href prop can't be specified.
export interface Props extends InitProps {
  as?: HTMLTextElements;
  href?: never;
}

/**
 * if primary is specified it will render the element with primary color
 *
 * else if secondary is specified it will render the element with secondary color
 *
 * else it will render the element with a semantic color
 */
const buildColorClass = ({
  color,
  primary,
  secondary,
}: ColorClasses): string => {
  let colorClass: string = color;
  if (primary) {
    colorClass = "primary";
  } else if (secondary) {
    colorClass = "secondary";
  }

  return colorClass;
};

/**
 * It builds a string containing all the active classes
 */
const buildClasses = (params: Classes): string => {
  const classList = ["ui", "text"];
  const { color, primary, secondary, floated, textAlignment, ...rest } = params;
  const colorClass = buildColorClass({ color, primary, secondary });

  if (colorClass) {
    classList.push(colorClass);
  }

  if (floated) {
    classList.push(`${floated}-floated`);
  }

  if (textAlignment) {
    classList.push(`${textAlignment}-text-align`);
  }

  let key: keyof typeof rest;
  for (key in rest) {
    if (typeof rest[key] !== "boolean") {
      classList.push(rest[key] as string);
    } else {
      classList.push(kebabCase(key));
    }
  }

  return classList.join(" ");
};

/**
 * Semantic like Text component.
 *
 * To use in django templates:
 * to initiate add class="ui text" to a text element
 *
 * Then you can add additional classes for stylings as:
 * "medium" (size), "red" (color), "left-floated" (floating) and so on
 *
 * To see all the classes look in file text.scss
 */
export const Text: React.FC<Props | PropsWhenAsIsA> = ({
  as = "p",
  content,
  children,
  href,
  testId,
  id,
  style,
  ...classes
}) => {
  const As = (as as unknown) as React.JSXElementConstructor<
    React.PropsWithChildren<InitProps>
  >;

  return (
    <As
      className={buildClasses(classes)}
      {...{ href, "data-testid": testId, id, style }}
    >
      {children || content}
    </As>
  );
};
