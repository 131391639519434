import {
  renderReduxElement,
  renderReduxElementProvidedProps,
  openTemplateBuilderModal,
} from "../utils/renderutils";
import { html_beautify } from "js-beautify";
import Sortable from "sortablejs";
import { uuidv4 } from "../utils/uuidUtils";
import ReactDOM from "react-dom";
import { ProductSectionsTabComp } from "../products/sections-tab/ProductSectionsTab";
import { productDetailsRefreshCurrentTab } from "./t.product-detail";
import {
  CLASS,
  handle,
  loadPartial,
  replaceContent,
  showMessage,
} from "../utils/partialUtils";
import { NotificationAppearance } from "../api/djangoToastSlice";
import { store } from "../utils/store";
import { setHistoryStateData } from "../api/productListSlice";
import { CustomQuery } from "../custom-query/CustomQuery";

/* global require */
const $ = require("jquery");

const { glue } = window;

const AUTO = 600;

$(document).ajaxError(function (event, jqxhr) {
  let msg = "An unexpected error occurred";
  let level;
  if (jqxhr.status < 500) {
    level = NotificationAppearance.WARNING;
  } else {
    level = NotificationAppearance.ERROR;
  }
  showMessage(level, msg);
  console.error(jqxhr);
});

const all = function () {
  $(".partial").each(function () {
    const partial = $(this);
    if (partial.is(":empty")) {
      loadPartial(partial);
    }
  });
  $(".partial[data-timeout]:not(.bound)")
    .addClass("bound")
    .each(function () {
      const partial = $(this);
      const timeout = partial.data("timeout");
      setTimeout(function () {
        loadPartial(partial);
      }, timeout);
    });

  $("button.uuid-gen:not(.bound)")
    .addClass("bound")
    .each(function () {
      const $button = $(this);
      const $input = $button
        .closest("form")
        .find('input[name="' + $button.data("input-name") + '"]');
      $button.click(function () {
        $input.val(uuidv4());
        $input.change();
      });
    });

  $(".wizard-dialog:not(.bound)")
    .addClass("bound")
    .each(function () {
      const dialog = $(this).find(".dialog");
      dialog.css({ top: dialog.position().top + $(window).scrollTop() });
    });

  $(".choices:not(.bound)")
    .addClass("bound")
    .each(function () {
      const $this = $(this);
      const toggle = $this.find(".toggle");
      const input = $this.find('input[type="checkbox"]');

      window.input = input;

      const selectAll = toggle.find("button.all");
      const selectNone = toggle.find("button.none");

      selectAll.click(function () {
        input.prop("checked", true);
      });

      selectNone.click(function () {
        input.prop("checked", false);
      });
    });

  /*************
   * Components *
   *************/

  function setModalVisibility(selector, visible) {
    const options = {};
    if (visible) {
      options["opacity"] = "1";
      options["pointer-events"] = "auto";
      options["visibility"] = "visible";
    } else {
      options["opacity"] = "";
      options["pointer-events"] = "";
      options["visibility"] = "hidden";
    }
    const modal = $(selector);
    modal.find(".modal").css(options);
    modal.find(".modal-overlay").css(options);
  }

  $("button.copy-product-tags")
    .off()
    .click(function (e) {
      const element = $(this);
      const selector = "#sku-ean-modal";
      const modal = $(selector);
      e.preventDefault();
      modal.find("input#product-id").text(element.data("product-id"));
      modal.find("input#copy-mode").text(element.data("copy-mode"));
      setModalVisibility(selector, true);
      return false;
    });
  // Edit 'Generated Text' modal.
  function saveGeneratedText(e) {
    const modal = e.data.modal;
    const page_num_object = document.getElementById("pagination_value");
    let page_num = null;
    if (page_num_object) {
      page_num = page_num_object.value;
    }
    $.ajax({
      type: "POST",
      url: e.data.saveUrl,
      data: {
        edit: 1,
        edited_data: modal.find("#editor").val(),
        page: page_num,
      },
      success: function () {
        setModalVisibility("#edit-text-modal", false);
        // FIXME: This should use a partial reload, since it may take
        // a long time to reload the page.
        // Note: that when this is called, the text might not have been
        // generated, so it'd have to poll an API/get a notification when
        // it has been generated

        // Note, this can also be called from product-list and from
        // translation modal inside product details
        window.location.reload();
      },
      error: function () {
        modal.find("#error").text("Failed to save text");
      },
    });
    return false;
  }

  $(".edit-text-modal").on("click", function (e) {
    function populateTextModal(anchor, modal) {
      modal.find("#product").text(anchor.data("product"));
      modal.find("#language").text(anchor.data("language"));
      modal.find("#channel").text(anchor.data("channel"));

      const editor = modal.find("#editor");
      const preview = modal.find("#preview");
      editor.bind("input propertychange", function () {
        preview.html($(this).val());
      });
      const generatedText = anchor
        .data("generated-text")
        .replace(/<\/?span[^>]*>/g, "");
      editor.text(html_beautify(generatedText));
      preview.html(editor.val());
      modal.find("button.save").on(
        "click",
        {
          modal: modal,
          saveUrl: anchor.data("save-url"),
        },
        saveGeneratedText
      );
    }

    const anchor = $(this);

    const target = "#edit-text-modal";
    const modal = $(target).find(".modal-content");
    e.preventDefault();
    populateTextModal(anchor, modal);
    setModalVisibility(target, true);
  });

  $("#edit-text-modal button.close, #edit-text-modal .modal-overlay").click(
    function () {
      setModalVisibility("#edit-text-modal", false);
      $("#edit-text-modal button.save").off("click", saveGeneratedText);
    }
  );

  /* Publish overwrite modal

    If CustomerConfig has a value of `ask_for_overwrite_on_publish` set to true,
    then user should see a modal after pressing publish. To make it work template needs
    to include a `publish-overwrite-modal.html` file and the button responsible for
    submitting a "publish" form has to have a `publish-overwrite` class.
  */
  $(
    "#publish-overwrite-modal button.cancel, #publish-overwrite-modal .modal-overlay"
  ).click(function () {
    setModalVisibility("#publish-overwrite-modal", false);
  });

  $("#publish-overwrite-modal button.yes")
    .off()
    .click(function (e) {
      const selector = "#publish-overwrite-modal";
      const modal = $(selector);
      e.preventDefault();
      setModalVisibility(selector, false);
      const form = $("." + modal.data("form"));
      const input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "overwrite")
        .val(1);
      form.append(input);
      form.submit();
      return false;
    });

  $("#publish-overwrite-modal button.no")
    .off()
    .click(function (e) {
      const selector = "#publish-overwrite-modal";
      const modal = $(selector);
      e.preventDefault();
      setModalVisibility(selector, false);
      const form = $("." + modal.data("form"));
      const input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "overwrite")
        .val(0);
      form.append(input);
      form.submit();
      return false;
    });

  $("button.publish-overwrite")
    .off()
    .click(function (e) {
      const selector = "#publish-overwrite-modal";
      const modal = $(selector);
      const form = $(this).closest("form");
      const uniqueId = "publish" + new Date().getTime();
      form.addClass(uniqueId);
      modal.data("form", uniqueId);
      e.preventDefault();
      setModalVisibility(selector, true);
      return false;
    });

  $(document).on(
    "click",
    ".temp-adj-vocabulary .template-selector button",
    function () {
      $(".left .mask").css({ visibility: "visible" });
    }
  );

  $(document).on("click", ".left .mask", function () {
    $(".left .mask").css({ visibility: "hidden" });
  });

  // Works for any element, just add class "copy-clipboard" and data-value with value you want to copy to clipboard
  $(".copy-clipboard").click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    const element = $(this)[0];
    const value = element.dataset.value;
    window.navigator.clipboard.writeText(value).then(
      function () {
        element.dataset.balloon = "Copied!";
      },
      function () {
        element.dataset.balloon = "Copy failed!";
      }
    );
  });
  // Fallback when navigator is unavailable.
  $(".copy-clipboard").each(function () {
    const element = $(this)[0];
    if (
      window.navigator === undefined ||
      window.navigator.clipboard === undefined
    ) {
      element.dataset.balloon = "Click to copy unavailable";
      $(element).children(".copy-clipboard-fallback")[0].innerHTML =
        element.dataset.value;
    }
  });

  $('[data-t-component="custom-query"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const div = $(this)[0];
      const jsx = renderReduxElementProvidedProps(CustomQuery, {
        customerChannels: JSON.parse(div.dataset.customerChannels),
        customerLanguages: JSON.parse(div.dataset.customerLanguages),
        preserveParams: JSON.parse(div.dataset.preserveParams),
        query: div.dataset.query,
        token: div.dataset.token,
      });
      ReactDOM.render(jsx, div);
    });

  $('[data-t-component="variants"]:not(.bound)').each(function () {
    const element = $(this)[0];
    window.glue.variantsField.forTextarea(element, {});
    $(this).addClass("bound");
  });

  $('[data-t-component="template-variants"]:not(.bound)').each(function () {
    const element = $(this)[0];
    const props = element.dataset.props;
    window.glue.templateVariantsField.forTextarea(element, {
      props: props ? JSON.parse(element.dataset.props) : null,
    });
    $(this).addClass("bound");
  });

  $('[data-t-component="dropdown"]:not(.bound)').each(function () {
    const element = $(this)[0];
    window.glue.dropdown.forInput(element, {});
    $(this).addClass("bound");
  });

  $('[data-t-component="template-selector"]:not(.bound)').each(function () {
    const element = $(this)[0];
    window.glue.templateSelector.forInput(element, {
      onDataChanged: function (data) {
        const callback = window.templateSelectorOnDataChanged;
        if (callback) {
          callback(element, data);
        }
      },
    });
    $(this).addClass("bound");
  });

  $('[data-t-component="vocabulary-lookup"]:not(.bound)').each(function () {
    const element = $(this)[0];
    if (window.glue) {
      window.glue.vocabularyLookupNew.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.vocabularyLookupOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
      $(this).addClass("bound");
    }
  });

  $('[data-t-component="multiple-selector"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      window.glue.multipleSelector.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.multipleSelectorOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="template-multiple-label-selector"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      window.glue.templateMultipleLabelSelector.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.templateMultipleLabelSelectorOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="single-selector"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      window.glue.singleSelector.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.singleSelectorOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="product-tags-editor"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.productTagsEditor.forTextarea(element, {
        onDataChanged: function (component, data, delayedRefresh) {
          const callback = window.productTagsEditorOnDataChanged;
          if (callback) callback(element, delayedRefresh, component, data);
        },
      });
    });

  $('[data-t-component="tag-tree-editor"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      setTimeout(function () {
        glue.tagTreeEditor.forTextarea(element, {
          onDataChanged: function (component, data) {
            const callback = window.tagTreeEditorOnDataChanged;
            if (callback) callback(element, component, data);
          },
        });
      });
    });

  $('[data-t-component="tag-tree-button"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      setTimeout(function () {
        glue.tagTreeButton.forTextarea(element, {
          onDataChanged: function (component, data) {
            const callback = window.tagTreeEditorOnDataChanged;
            if (callback) callback(element, component, data);
          },
        });
      });
    });

  $('[data-t-component="lang-string"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      setTimeout(function () {
        glue.langString.forTextarea(element, {
          onDataChanged: function (component, data) {
            const callback = window.langStringOnDataChanged;
            if (callback) callback(element, component, data);
          },
        });
      });
    });

  $('[data-t-component="lexicon-editor"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.lexiconEditor.forTextarea(element, {
        onDataChanged: function (component, data) {
          const callback = window.lexiconEditorOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="fluent-input"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.fluentInput.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.fluentInputOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="brand-selector"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.brandSelector.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.brandSelectorOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="text-pattern-selector"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.textPatternSelector.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.textPatternOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="annotation"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.annotation.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.annotationOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="predefined-title"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const element = $(this)[0];
      glue.predefinedTitle.forInput(element, {
        onDataChanged: function (component, data) {
          const callback = window.predefinedTitleOnDataChanged;
          if (callback) callback(element, component, data);
        },
      });
    });

  $('[data-t-component="product-sections-tab"]:not(.bound)')
    .addClass("bound")
    .each(function () {
      const div = $(this)[0];
      const jsx = renderReduxElementProvidedProps(ProductSectionsTabComp, {
        token: div.dataset.token,
        productId: div.dataset.productId,
        textualAppName: div.dataset.textualAppName,
      });
      ReactDOM.render(jsx, div.parentNode);
      // div.parentNode.removeChild(div);
    });

  $("[data-react-component]:not(.bound)")
    .each(function () {
      const element = $(this)[0];
      ReactDOM.render(renderReduxElement(element.dataset), element);
    })
    .addClass("bound");

  $('[data-t-component="prefill-from-category-button"]:not(.bound)').each(
    function () {
      const element = $(this)[0];
      const props = element.dataset.props;
      window.glue.prefillFromCategory.forTextarea(element, {
        props: props ? JSON.parse(element.dataset.props) : null,
      });
      $(this).addClass("bound");
    }
  );

  /************
   * Sortable *
   ***********/

  $(function () {
    $("[data-sortable]:not(.bound)").each(function () {
      /*
       * If there is a `data-sortable-group` attribute set
       * we will assume that there are several lists where you
       * want to sort the elements, otherwise only one.
       *
       * if `isMultiple == false` the request will be a list
       * otherwise a json object with the `data-sortable-name` of
       * the list as key.
       */
      const el = $(this);
      const groupName = el.data("sortable-group");
      const hasHandle = el.find("[data-sortable-handle]").length;
      const isMultiple = Boolean(groupName);
      Sortable.create(el[0], {
        group: groupName,
        handle: hasHandle ? "[data-sortable-handle]" : null,
        onEnd: function (evt) {
          const list = $(evt.to);
          const lists = isMultiple
            ? $(`[data-sortable-group="${groupName}"]`)
            : list;
          const data = isMultiple ? {} : [];
          lists.children().each(function () {
            const item = $(this);
            const id = item.data("sortable-id");
            if (isMultiple) {
              const parentGroup = $(this)
                .closest("[data-sortable-name]")
                .data("sortable-name");
              if (id && parentGroup) {
                if (!(parentGroup in data)) {
                  data[parentGroup] = [];
                }
                data[parentGroup].push(id);
              }
            } else {
              data.push(id);
            }
          });
          if (el.data("sortable") !== "react") {
            $("<form>", {
              method: "POST",
              action: list.data("sortable"),
            })
              .append(
                $("<input>", {
                  name: "order",
                  value: JSON.stringify(data),
                  type: "hidden",
                })
              )
              .appendTo(list)
              .submit();
          }
        },
      });
      el.addClass("bound");
    });
  });
};
window.rebindAll = all;

function changeNoteTab(tab, target) {
  $(".note-tab").removeClass("active");
  $(".note-tab-target").hide();

  tab.addClass("active");
  target.show();
}

const openSubpage = function (openerElement, url, callback = null) {
  const iframe = document.createElement("iframe");
  iframe.onload = function () {
    $(iframe).contents().find("html").addClass("html-subpage");
    $(iframe).contents().find("body").addClass("embed");
  };
  iframe.src = url;
  iframe.className = "subpage-iframe";
  // Add autologin parameter to iframe url for inriver
  if (document.body.classList.contains("inriver")) {
    const urlParams = new URLSearchParams(window.location.search);
    const autoLogin = urlParams.get("autologin");
    const jwt = urlParams.get("jwt");
    if (autoLogin && !iframe.src.includes("autologin")) {
      iframe.src += iframe.src.includes("?")
        ? `&autologin=${autoLogin}`
        : `?autologin=${autoLogin}`;
    } else if (jwt && !iframe.src.includes("jwt")) {
      iframe.src += iframe.src.includes("?") ? `&jwt=${jwt}` : `?jwt=${jwt}`;
    }
  }
  const html = $("html");

  const backButton = $(
    '<div class="subpage-back">' +
      "<button>" +
      '<i class="material-icons">arrow_back</i>' +
      "</button>" +
      "</div>"
  );
  const mask = $('<div class="subpage-mask"></div>');

  if (window.document.body) {
    window.document.body.appendChild(iframe);
    $(window.document.body).append(backButton);
    $(window.document.body).append(mask);
  }

  html.addClass(CLASS.NOSCROLL);
  const state = {
    oldUrl: window.location.href,
    oldTitle: document.title,
  };
  history.pushState(state, null, url);
  store.dispatch(setHistoryStateData(state));

  const goBackFunc = function () {
    if (callback) callback();
    if (openerElement !== null && !openerElement.is("[data-ignore-partial]")) {
      const partial = openerElement.closest(".partial");
      if (openerElement.is("[data-stay-on-tab]")) {
        // Adds a possibility to stay on the same tab in product detail view
        partial.data("url", $(".tab.active").attr("href"));
      }
      loadPartial(partial, true);
    }

    $(iframe).addClass("out");
    html.removeClass(CLASS.NOSCROLL);
    const { oldTitle, oldUrl } = store.getState().productList.historyStateData;
    history.pushState(null, oldTitle, oldUrl);
    setTimeout(function () {
      if (window.document.body) {
        window.document.body.removeChild(iframe);
      }
      backButton.remove();
      mask.remove();
      productDetailsRefreshCurrentTab();
    }, 500);
  };

  backButton.click(goBackFunc);
  mask.click(goBackFunc);

  window.CURRENT_SUBPAGE_GO_BACK = goBackFunc;
};
window.openSubpage = openSubpage;

window.PARTIAL = {
  all: all,
  forceLoad: (selector) => loadPartial($(selector), true),
};

function setupGlobalEventHandlers() {
  $(window).on("hashchange", function () {
    const hash = window.location.hash;
    if (hash.includes("#!")) return;

    const scroll = $(hash).first();
    const scrollTop = (scroll.offset() || {}).top - 140;

    $("html,body").scrollTop(scrollTop);
  });
  $(window).scroll(function () {
    const _top = $(window).scrollTop();
    const _bottom = _top + $(window).height();

    $("a[data-auto]").each(function () {
      const a = $(this);
      if (a.offset().top < _bottom + AUTO) {
        if (!a.hasClass(CLASS.LOADING)) {
          a.addClass(CLASS.LOADING);
          a.click();
        }
      }
    });
  });
  $(document).on("keydown", ".partial form input[data-auto]", function () {
    const $input = $(this);
    $input.data("value", $input.val());
  });
  $(document).on("keyup", ".partial form input[data-auto]", function () {
    const $input = $(this);
    const $form = $input.closest("form");

    let _timeout = $input.data("timeout");
    let _oldValue = $input.data("value");
    let _newValue = $input.val();

    _oldValue = _oldValue || "";
    _newValue = _newValue || "";

    if (_newValue.trim() !== _oldValue.trim()) {
      clearTimeout(_timeout);
      _timeout = setTimeout(function () {
        $form.submit();
      }, 300);

      $input.data("timeout", _timeout);
    }
  });
  $(document).on("click", ".alert-box .close", function (event) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }
    $(this).closest(".alert-box").remove();
  });
  $(document).on("click", '.collapsible > button[type="button"]', function (
    event
  ) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }
    const $button = $(this);
    const $collapsible = $button.closest(".collapsible");

    if ($collapsible.is(".collapsed")) {
      $collapsible.removeClass("collapsed");
      $collapsible.addClass("expanded");
      return;
    }

    if ($collapsible.is(".expanded")) {
      $collapsible.removeClass("expanded");
      $collapsible.addClass("collapsed");
    }
  });
  $(document).on("click", ".note-tab", function () {
    const tab = $(this);
    const tabTarget = tab.attr("data-note-tab-target");
    const target = $(`div[data-note-target="${tabTarget}"]`);
    if (tabTarget === "preview") {
      $.ajax({
        type: "POST",
        url: target.attr("data-url"),
        data: {
          text: $("textarea").val(),
        },
        success: function (data) {
          target.html(data.text);
          changeNoteTab(tab, target);
        },
      });
    } else {
      changeNoteTab(tab, target);
    }
  });
  $(document).on(
    "click",
    ".vocabulary-change-enabled [data-vocab-id]",
    function (event) {
      if (event.button !== 0) {
        // Wasn't left click
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      const $this = $(this);
      const vocabId = $this.data("vocab-id");
      const tagId = $this.data("tag-id") || null;
      const productIdTag = $this.closest("[data-product-id]");
      const productId =
        (productIdTag && productIdTag.data("product-id")) || null;
      const token = store.getState().auth.token;
      $.ajax({
        method: "POST",
        url: "/api/_internal/vocabulary/build_vocabulary_edit_url/",
        data: {
          vocabulary_id: vocabId,
          tag_id: tagId,
          product_id: productId,
        },
        headers: { token },
        dataType: "json",
        success: function (data) {
          openSubpage($this, data.url);
        },
      });
    }
  );
  $(document).on(
    "click",
    ".vocabulary-change-enabled [data-template-id]",
    function (event) {
      if (event.button !== 0) {
        // Wasn't left click
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      const $this = $(this);
      const templateId = $this.data("template-id");
      const token = store.getState().auth.token;
      $.ajax({
        method: "POST",
        url: "/api/_internal/vocabulary/build_template_edit_url/",
        data: {
          template_id: templateId,
        },
        headers: { token },
        dataType: "json",
        success: function (data) {
          if (data.is_data_template) {
            openSubpage($this, data.url);
          } else {
            openTemplateBuilderModal(templateId);
          }
        },
      });
    }
  );
  $(document).on("click", "a", function () {
    const link = $(this);
    const target = link.attr("target");
    if (!target || target.length === 0) {
      link.removeClass(CLASS.TIMEOUT);
      link.addClass(CLASS.LOADING);

      setTimeout(function () {
        link.removeClass(CLASS.LOADING);
        link.addClass(CLASS.TIMEOUT);
      }, 1000);
    }
  });
  $(document).on("click", "a[data-subpage]", function (event) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const $a = $(this);
    const subpageUrl = $a.attr("href");
    if ($a.is("[data-quiet-submit]")) {
      const $form = $a.closest("form");
      $.ajax({
        url: $form.attr("action"),
        type: $form.attr("method").toUpperCase(),
        data: $form.serializeArray(),
        error: function () {},
        success: function () {
          openSubpage($a, subpageUrl);
        },
      });
    } else {
      openSubpage($a, subpageUrl);
    }
  });
  $(document).on("click", 'a[target="disable"]', function (event) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }
    event.stopPropagation();
    event.preventDefault();
  });
  $(document).on("click", 'a[target="share"]', function (event) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    const a = $(this);
    const href = a.attr("href");
    const target = a.attr("target");

    window.open(
      href,
      target,
      "width=320,height=480,menubar=no,status=no",
      true
    );
  });
  $(document).on("click", "button[data-iframe-component]", function (event) {
    if (event.button !== 0) {
      // Wasn't left click
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const $button = $(this);
    const component = $button.data("iframe-component");
    const appUrl = $button.data("app-url");
    const token = $button.data("token");

    const iframe = document.createElement("iframe");
    const html = $("html");

    if (component === "editMapping") {
      const mappingId = $button.data("mapping-id");
      iframe.src = appUrl + "?token=" + token + "&mapping_id=" + mappingId;
      if (window.document.body) {
        window.document.body.appendChild(iframe);
      }
      html.addClass(CLASS.NOSCROLL);

      iframe.contentWindow.onCloseMapping = function () {
        if (window.document.body) {
          window.document.body.removeChild(iframe);
        }
        html.removeClass(CLASS.NOSCROLL);
      };

      iframe.contentWindow.onSaveMapping = function () {
        if (window.document.body) {
          window.document.body.removeChild(iframe);
        }
        html.removeClass(CLASS.NOSCROLL);

        const partial = $button.closest(".partial");
        loadPartial(partial, true);
      };
    }

    if (component === "editProduct") {
      const productId = $button.data("product-id");
      iframe.src = appUrl + "?token=" + token + "&id=" + productId;
      if (window.document.body) {
        window.document.body.appendChild(iframe);
      }
      html.addClass(CLASS.NOSCROLL);

      iframe.contentWindow.onProductSaveAndGenerate = function () {
        if (window.document.body) {
          window.document.body.removeChild(iframe);
        }
        html.removeClass(CLASS.NOSCROLL);

        const partial = $button.closest(".partial");
        loadPartial(partial, true);
      };
    }
  });
  $(document).on("click", "form[data-submit-on-click]", function () {
    $(this).closest("form").submit();
  });
  $(document).on("submit", "form.processor", function () {
    let product_namespace = $("select[name=product_namespace]").val();
    if (product_namespace === undefined) {
      product_namespace = new URLSearchParams(location.search.slice(1)).get(
        "_namespace"
      );
    }
    $(this).append(
      `<input type="hidden" name="product_namespace" value="${product_namespace}"/>`
    );
  });
  $(document).on(
    "change",
    "form[data-auto] input:not(.txu-dropdown-control), form[data-auto] select",
    function () {
      $(this).closest("form").submit();
    }
  );
  $(document).on("submit", "form", function (event) {
    const form = $(this);
    let button = form.find("button:focus");
    if (button.length === 0) {
      button = $(event.explicitOriginalTarget);
    }
    if (button.length === 0) {
      button = $(this).find('[type="submit"]').first();
    }
    button.removeClass(CLASS.TIMEOUT);
    button.addClass(CLASS.LOADING);

    let timeout = button.data("timeout");
    timeout = timeout ? parseInt(timeout) : null;
    timeout = timeout || 1000;

    setTimeout(function () {
      button.removeClass(CLASS.LOADING);
      button.addClass(CLASS.TIMEOUT);
    }, timeout);
  });
  $(document).on(
    "click",
    ".partial:not(.partial-unbound) a:not([target])",
    function (event) {
      if (event.button !== 0) {
        // Wasn't left click
        return;
      }

      const a = $(this);
      const target = a.data("target");
      const href = a.attr("href");
      let partial;
      if (target) {
        const $target = a.closest(target);
        if ($target.length > 0) {
          partial = $target.filter(".partial");
        } else {
          return;
        }
      } else {
        partial = a.closest(".partial");
      }

      if (!href) {
        return;
      }

      if (a.closest("[data-skip]").length > 0) {
        return;
      }

      if (!partial.length) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const loadWithNoSpinner =
        partial.attr("data-no-loading-spinner") !== undefined;

      if (loadWithNoSpinner) {
        partial.addClass(CLASS.LOADING_NO_SPINNER);
      } else {
        partial.addClass(CLASS.LOADING);
      }
      $.ajax({
        url: a.attr("href"),
        error: function () {
          showMessage(
            NotificationAppearance.ERROR,
            "An unexpected error occurred."
          );
          partial.removeClass(CLASS.LOADING);
          partial.removeClass(CLASS.LOADING_NO_SPINNER);
        },
        success: function (data) {
          if (!data.content) {
            partial.remove();
          }

          handle(data);

          partial = replaceContent(partial, data.content);

          if (a.is("[data-trigger-click]")) {
            partial.trigger("partial:clicked");
          }
          if (partial?.hasClass(CLASS.LOADING_NO_SPINNER)) {
            partial.removeClass(CLASS.LOADING_NO_SPINNER);
          }
          all();
        },
      });
    }
  );
  $(document).on(
    "submit",
    ".partial:not(.partial-unbound) form:not([target])",
    function (event) {
      const form = $(this);

      let button = form.find("button:focus");
      if (button.length === 0) {
        const $target = $(event.explicitOriginalTarget);
        if ($target.is('button,input[type="submit"]')) {
          button = $target;
        }
      }

      if (button.is("[target]")) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const target = form.data("target");
      let partial;
      if (target) {
        partial = form.closest(target).filter(".partial");

        if (!partial.length) {
          partial = form.closest(target).find(".partial");
        }
        if (!partial.length) {
          partial = $(target);
        }
      } else {
        partial = form.closest(".partial");
      }

      const method = form.attr("method").toUpperCase();

      let processData;
      let contentType;
      let data;

      if (method === "GET") {
        processData = undefined;
        contentType = undefined;
        data = form.serializeArray();

        if (button) {
          const name = button.attr("name");
          const value = button.attr("value");

          if (name && value) {
            data.push({
              name: name,
              value: value,
            });
          }
        }
      } else {
        processData = false;
        contentType = false;
        data = new FormData();

        form.serializeArray().forEach(function (item) {
          data.append(item.name, item.value);
        });

        form.find('input[type="file"]').each(function () {
          const $input = $(this);
          const file = $input[0].files[0];
          if (file) {
            data.append($input.attr("name"), file);
          }
        });

        if (button) {
          const name = button.attr("name");
          const value = button.attr("value");

          if (name && value) {
            data.append(name, value);
          }
        }
      }

      const loadWithNoSpinner =
        partial.attr("data-no-loading-spinner") !== undefined;

      if (loadWithNoSpinner) {
        partial.addClass(CLASS.LOADING_NO_SPINNER);
      } else {
        partial.addClass(CLASS.LOADING);
      }
      $.ajax({
        url: form.attr("action"),
        type: method,
        data: data,
        processData: processData,
        contentType: contentType,
        error: function () {
          showMessage(
            NotificationAppearance.ERROR,
            "An unexpected error occurred."
          );
          partial.removeClass(CLASS.LOADING);
          partial.removeClass(CLASS.LOADING_NO_SPINNER);
        },
        success: function (data) {
          handle(data);

          partial = replaceContent(partial, data.content);

          if (method === "POST") {
            const RELOAD_NONE = "none";

            const reload =
              partial.data("reload") ||
              partial.closest(".partial[data-reload]").data("reload");

            const call =
              partial.data("call") ||
              partial.closest(".partial[data-call]").data("call");

            if (call) {
              window[call](data);
            }

            if (reload && reload !== RELOAD_NONE) {
              $(reload).each(function () {
                loadPartial($(this), true);
              });
            }
          }

          partial.trigger("partial:loaded");
          partial.removeClass(CLASS.LOADING_NO_SPINNER);
          all();
        },
      });
    }
  );
  $(".js-toggle-columns-select").click(function () {
    // Show dropdown and wait for click outside element
    // When clicked outside element, stop waiting and hide dropdown

    const csc = document.getElementsByClassName("columns-select-content")[0];
    const close = function (e) {
      if (csc && !csc.contains(e.target)) {
        document.removeEventListener("mouseup", close);
        csc.style.display = "none";
      }
    };

    if ((csc && csc.style.display === "") || csc.style.display === "none") {
      csc.style.display = "block";
      document.addEventListener("mouseup", close);
    }
  });
  $("#id_pagination_size").change(function () {
    document.getElementById("filter-form").submit();
  });

  const refreshFileInput = function () {
    const $input = $(this);
    const drop = $input.closest(".drop");
    const value = $input.val();
    if (value) {
      drop.addClass(CLASS.SELECTED);
      drop.find(".text > .selected").text($input[0].files[0].name);
    } else {
      drop.removeClass(CLASS.SELECTED);
    }
  };
  $(document).on("change", '.drop input[type="file"]', refreshFileInput);
  $(document).find('.drop input[type="file"]').each(refreshFileInput);
}

setupGlobalEventHandlers();

// scroll to top of the page
$(function () {
  setTimeout(function () {
    const scroll = $("[data-scroll]").first();
    const scrollTop = (scroll.offset() || {}).top - 80;

    $("html,body").scrollTop(scrollTop);
  });
});

(function () {
  const $customerSelect = $(".customer-select");
  const $liNew = $customerSelect.find("li.new");
  const $input = $customerSelect.find('input[type="text"]');

  function filterLinks() {
    const term = $input.val() || "";
    $customerSelect.find("li").each(function () {
      const $li = $(this);
      if (!term) {
        $li.show();
      } else {
        if (!$li.text().toLowerCase().includes(term.toLowerCase())) {
          $li.hide();
        } else {
          $li.show();
        }
      }
    });

    $liNew.find("span").text(term);
    $liNew.find('input[name="product_label"]').val(term);
    $liNew.toggle(term.length > 0);
  }

  $input.keyup(filterLinks);
  filterLinks();

  $input.keypress(function (e) {
    // On enter
    if (e.which === 13) {
      $customerSelect
        .find("li")
        .filter(function () {
          return $(this).css("display") !== "none";
        })
        .find("a")
        .first()
        .click();
    }
  });
})();

// Show a tooltip on all img tags with class `js-show-tooltip`
(function () {
  const Controls = {
    init: function () {
      $(document).on(
        "mouseover",
        ".js-show-tooltip",
        Controls.mouseOverListener
      );
      $(document).on("mouseout", ".js-show-tooltip", Controls.mouseOutListener);
    },

    mouseOverListener: function () {
      Controls.displayTooltip(this);
    },

    mouseOutListener: function () {
      Controls.hideTooltip(this);
    },

    displayTooltip: function (imgLink) {
      const tooltip = document.createElement("div");
      const fullImg = document.createElement("img");

      fullImg.src = imgLink.src;
      tooltip.appendChild(fullImg);
      tooltip.className = "js-img-tooltip";

      tooltip.style.top = "40px";
      tooltip.style.left = "40px";

      imgLink._tooltip = tooltip;
      Controls._tooltip = tooltip;
      imgLink.parentNode.appendChild(tooltip);
    },

    hideTooltip: function (imgLink) {
      imgLink.parentNode.removeChild(imgLink._tooltip);
      imgLink._tooltip = null;
      Controls._tooltip = null;
    },
  };

  $(function () {
    Controls.init();
  });
})();

// listen if backend creates a message and add it in redux to use same
// system as frontend for consistency
const setDjangoToastInReduxStore = () => {
  const alertBox = $(".alert-box-container");
  const alertBoxChildren = alertBox.children();
  if (alertBoxChildren.length) {
    alertBoxChildren.each((i) => {
      const child = $(alertBoxChildren[i]);
      showMessage(child.attr("data-appearance"), child.attr("data-content"));
      child.remove();
    });
  }
};
// when form refresh the page
$(setDjangoToastInReduxStore());

// when form do not refresh the page
const alertBoxContainers = $(".alert-box-container");
const setDjangoToastInReduxStoreObserver = new MutationObserver(
  setDjangoToastInReduxStore
);
alertBoxContainers.each(function () {
  setDjangoToastInReduxStoreObserver.observe(this, {
    attributes: true,
    childList: true,
    characterData: true,
  });
});

window.addEventListener("opentemplatebuilder", ({ detail }) => {
  openTemplateBuilderModal(detail);
});

// Bind everything when page loaded
$(all);
