import React from "react";
import { JinjaGenerator } from "./JinjaGenerator";
import { TemplateItem } from "./template";
import { generateId } from "../utils/uuidUtils";
import styled from "styled-components";
import { getTemplateLexiconShortcut } from "../api/vocabularyApi";
import { TemplateSelector } from "./TemplateSelector";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../utils/store";
import { TemplateLabelsCollection } from "../components/TemplateLabelsCollection";
const StyledDiv = styled.div`
  margin: 10px 0;
  width: 100%;
`;

const MODE_RAW = 1;

type Item = {
  value: string;
  id: string;
  mode: number;
};

export enum TemplateLexiconShortcutTypes {
  TEMPLATE = "template",
  MAPPING = "mapping",
  DATA = "data",
  CONDITIONAL = "conditional",
}

type Props = {
  hasShortcuts: boolean;
  initialData: any[];
  onDataChanged: (field: TemplateVariantsField, data: string[]) => void;
  templateVocabId: string;
};

type State = {
  items: Item[];
  lastItem: null; // FIXME: Always null. Not sure if used in component
  selectionStart: number | null;
  selectionEnd: number | null;
  showConditionalCreator: boolean;
  addedConditional: boolean;
};

const mapStateToProps = (state: RootState): { token: string | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
});

const connector = connect(mapStateToProps, null);

type TemplateVariantsFieldProps = ConnectedProps<typeof connector> & Props;

export class TemplateVariantsField extends React.Component<
  TemplateVariantsFieldProps,
  State
> {
  private mounted = false;
  constructor(props: TemplateVariantsFieldProps) {
    super(props);

    const items = props.initialData.map((value) => {
      return {
        id: generateId(),
        value: value,
        mode: MODE_RAW,
      };
    });

    this.state = {
      items: items,
      lastItem: null,
      selectionStart: null,
      selectionEnd: null,
      showConditionalCreator: false,
      addedConditional: false,
    };
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  callOnDataChanged(): void {
    const { onDataChanged } = this.props;
    const { items } = this.state;
    const data = items.map((item) => item.value);
    onDataChanged(this, data);
  }

  onAddClick(): void {
    const { items } = this.state;
    items.push({
      id: generateId(),
      value: "",
      mode: MODE_RAW,
    });

    this.setState({ items });
    this.callOnDataChanged();
  }

  onInputChange(item: Item, value: string): void {
    item.value = value;
    const { items } = this.state;
    this.setState({ items });
    this.callOnDataChanged();
  }

  onInputChangeQuill(item: Item): (x: string) => void {
    return (newValue): void => {
      item.value = newValue;

      const { items } = this.state;
      this.setState({ items });
      this.callOnDataChanged();
    };
  }

  onRemoveClick(item: Item): () => void {
    return (): void => {
      const { items } = this.state;
      const index = items.indexOf(item);
      items.splice(index, 1);

      this.setState({ items });
      this.callOnDataChanged();
    };
  }

  onCopyClick(item: Item): () => void {
    return (): void => {
      const { items } = this.state;
      const index = items.indexOf(item);
      items.splice(index, 0, {
        id: generateId(),
        value: item.value,
        mode: item.mode,
      });

      this.setState({ items });
      this.callOnDataChanged();
    };
  }

  onBlur(): React.FocusEventHandler<HTMLTextAreaElement> {
    return (e): void => {
      this.setState({
        selectionStart: e.target.selectionStart,
        selectionEnd: e.target.selectionEnd,
      });
    };
  }

  insertTextInItem(item: Item, text: string): void {
    const { selectionStart, items, selectionEnd } = this.state;
    const start = selectionStart === null ? item.value.length : selectionStart;
    const end = selectionEnd === null ? item.value.length : selectionEnd;
    item.value = item.value.slice(0, start) + text + item.value.slice(end);
    this.setState({
      items,
      selectionStart: null,
      selectionEnd: null,
    });
    this.callOnDataChanged();
  }

  onAddTemplateClick(item: Item): (value: TemplateItem) => void {
    return (value): void => {
      this.insertTextInItem(item, value.jinja);
    };
  }

  onRenderRow = (template: TemplateItem): JSX.Element => (
    <>
      <p className="template-display-name">{template.display_name}</p>
      {template && (
        <TemplateLabelsCollection colorPrimary labels={template.labels} />
      )}
    </>
  );

  renderShortcuts(item: Item): React.ReactElement {
    const { showConditionalCreator } = this.state;
    const getShortcuts = (): JSX.Element[] => {
      const { hasShortcuts, templateVocabId, token } = this.props;
      const enumMap = Object.keys(TemplateLexiconShortcutTypes) as Array<
        keyof typeof TemplateLexiconShortcutTypes
      >;
      return (
        hasShortcuts &&
        enumMap
          .filter(
            (enumName) =>
              TemplateLexiconShortcutTypes[enumName] !==
              TemplateLexiconShortcutTypes.CONDITIONAL
          )
          .map((enumName) => {
            const shortcutValue = TemplateLexiconShortcutTypes[enumName];
            const name = `Add ${shortcutValue}`;
            return (
              <TemplateSelector
                key={enumName}
                name={name}
                onFetchItems={(): Promise<TemplateItem[]> =>
                  getTemplateLexiconShortcut({
                    token: token,
                    templateVocabId: templateVocabId,
                    shortcutType: shortcutValue,
                  })
                }
                onRenderRow={this.onRenderRow}
                onSubmit={this.onAddTemplateClick(item)}
                title={name}
              />
            );
          })
      );
    };

    return (
      <div key={item.value} className="shortcuts">
        {getShortcuts()}
        <button
          type="button"
          key="conditional_creator"
          className="pbutton pbutton-primary pbutton-inverse pbutton-small"
          onClick={(): void =>
            this.setState({
              showConditionalCreator: !showConditionalCreator,
            })
          }
        >
          Create conditional
        </button>
      </div>
    );
  }

  renderTextEditor = (item: Item): JSX.Element => {
    return (
      <textarea
        onBlur={this.onBlur()}
        onChange={(e): void => this.onInputChange(item, e.target.value)}
        value={item.value}
      />
    );
  };

  render(): React.ReactElement {
    const { items, showConditionalCreator } = this.state;
    const { templateVocabId } = this.props;
    return (
      <div className="TemplateVariantsField">
        {items.map((item) => (
          <div key={item.id} className="row">
            <StyledDiv>
              {this.renderShortcuts(item)}
              {this.renderTextEditor(item)}
              <button
                type="button"
                onClick={this.onRemoveClick(item)}
                className="remove"
              >
                <i className="material-icons">remove_circle_outline</i>
                <span>Remove</span>
              </button>

              <button
                type="button"
                onClick={this.onCopyClick(item)}
                className="copy"
              >
                <i className="material-icons">filter_none</i>
                <span>Copy</span>
              </button>
            </StyledDiv>
            {showConditionalCreator ? (
              <div className="jinja-generator">
                <JinjaGenerator
                  onGenerate={(data): void => {
                    this.insertTextInItem(item, data);
                    this.setState({
                      showConditionalCreator: false,
                      addedConditional: true,
                    });
                  }}
                  onRenderRow={this.onRenderRow}
                  templateVocabId={templateVocabId}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
        <div className="actions">
          <button
            type="button"
            onClick={(): void => this.onAddClick()}
            className="add"
          >
            <i className="material-icons">add_circle_outline</i>
            <span>Add</span>
          </button>
        </div>
      </div>
    );
  }
}

export const TemplateVariantsFieldComp = connector(TemplateVariantsField);
