import React, { useEffect, useRef } from "react";
import Downshift, { StateChangeOptions } from "downshift";
import styled from "styled-components";
import { TemplateItem, TemplateLabel } from "./template";
import { SortOption } from "../api/vocabularyApi";
import { List } from "semantic-ui-react";

type Props<T> = {
  description?: string;
  onCancel: () => void;
  onFetchItems: () => Promise<T[]>;
  onRenderRow: (item: T) => JSX.Element;
  onSortChange?: (order: SortOption) => Promise<T[]>;
  onSubmit: (item: T) => void;
  spaciousLabel?: boolean;
  title: string;
};

const Sort = styled.span`
  float: right;
`;

const StyledList = styled(List)`
  margin-left: 0;
`;

const StyledLi = styled(List.Item)<{ spaciousLabel: boolean }>`
  padding: 10px 0 10px 5px !important;
  margin-right: 20px;
  border-bottom: 1px solid #e8e8e8;
  &:last-of-type {
    border-bottom: none;
  }

  ${({ spaciousLabel }): string =>
    spaciousLabel &&
    `
    padding: 10px 5px !important;
  `}
`;

export type FilteredSearchResultBoxType = {
  id: string;
  labels?: TemplateLabel[];
};

const SortButton = styled.small<{ underline: boolean }>`
  cursor: pointer;
  margin: auto 0 auto 12px;

  ${({ underline }): string =>
    underline &&
    `
    text-decoration: underline;
  `}
`;

const SortOptionLabels: Record<SortOption, string> = {
  recently_used: "most recent",
  display_name: "A-Z",
};

export const FilteredSearchResultBox = <T extends FilteredSearchResultBoxType>({
  description,
  onCancel,
  onFetchItems,
  onRenderRow,
  onSortChange,
  onSubmit,
  spaciousLabel = false,
  title,
}: Props<T>): React.ReactElement => {
  const [items, setItems] = React.useState<T[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [sortBy, setSortBy] = React.useState("recently_used");
  const inputRef = React.useRef(null);
  const mounted = useRef(true);

  useEffect(() => {
    (async (): Promise<void> => {
      const items = await onFetchItems();
      if (mounted.current) {
        setItems(items);
        inputRef.current.focus();
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const onSortChangeCallback = (order: SortOption): void => {
    onSortChange?.(order).then(setItems);
    setSortBy(order);
  };

  const itemFilter = (inputValue: string) => (item: {
    [x: string]: any; // string | string[] | TemplateLabel[]
  }): boolean => {
    // Get all fields values of provided object excluding id and labels
    const fieldsValues = Object.keys(item)
      .filter(
        (itemKey) =>
          itemKey != "id" &&
          itemKey != "labels" &&
          itemKey != "template_lexicons" &&
          itemKey != "template_fields" &&
          itemKey != "annotate_content" &&
          item[itemKey]
      )
      .map((valName) => item[valName]);
    return (
      !inputValue ||
      fieldsValues.some((fieldValue: string) =>
        fieldValue.toLowerCase().includes(inputValue)
      ) ||
      item.labels?.some((label: TemplateLabel) =>
        label.name.toLowerCase().includes(inputValue)
      )
    );
  };

  const sortButtons = Object.entries(SortOptionLabels).map(
    ([key, value]: [SortOption, string]) => (
      <SortButton
        underline={sortBy === key}
        key={key}
        onClick={(): void => onSortChangeCallback(key)}
      >
        {value}
      </SortButton>
    )
  );

  return (
    <Downshift
      inputValue={inputValue || ""}
      isOpen={true}
      onChange={(item: T): void => {
        if (item) {
          onSubmit(item);
          setInputValue("");
        }
      }}
      onInputValueChange={setInputValue}
      onOuterClick={(): void => onCancel()}
      onStateChange={(changes: StateChangeOptions<TemplateItem>): void => {
        if (changes.type == Downshift.stateChangeTypes.keyDownEscape) {
          onCancel();
        }
      }}
      itemToString={(item): string => (item ? item.value : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }): React.ReactElement => (
        <div
          className="template-autocomplete"
          data-testid="filtered-search-result-box"
        >
          <h4>
            <span>{title}</span>
            {onSortChange && (
              <Sort>
                Sort by:
                {sortButtons}
              </Sort>
            )}
          </h4>
          {description && <p className="ui text grey">{description}</p>}
          <input
            data-testid={"filtered-search-result-box-input"}
            {...getInputProps({
              ref: inputRef,
              placeholder: "Filter",
            })}
          />
          <StyledList as={"ul"} {...getMenuProps()}>
            {isOpen
              ? items
                  ?.filter(itemFilter(inputValue && inputValue.toLowerCase()))
                  .map((item, index) => (
                    <StyledLi
                      as={"li"}
                      {...getItemProps({
                        key: item.id,
                        index,
                        item,
                        className: highlightedIndex === index ? "selected" : "",
                      })}
                      key={item.id}
                      spaciousLabel={spaciousLabel}
                      data-testid={`filtered-search-result-${item.id}`}
                    >
                      {onRenderRow(item)}
                    </StyledLi>
                  ))
              : null}
          </StyledList>
        </div>
      )}
    </Downshift>
  );
};
