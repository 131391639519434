import React from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
export type SEOLanguage = {
  text: string;
  value: string;
};

interface Props extends DropdownProps {
  switchLanguage: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void;
  activeLanguage: string;
  languages: SEOLanguage[];
}

export const SEOLanguageSelector: React.FC<Props> = ({
  switchLanguage,
  activeLanguage,
  languages,
}) => (
  <Dropdown
    data-testid={"seo-language-selector-dropdown"}
    options={languages}
    value={activeLanguage}
    onChange={switchLanguage}
    selection
    compact
    selectOnBlur={false}
    closeOnBlur={true}
    closeOnChange
  />
);
