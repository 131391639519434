import React from "react";
import { Grid } from "semantic-ui-react";

// Header custom components
const HeaderLeftColumn: React.FC<{
  left: React.ReactElement;
  right: React.ReactElement;
}> = ({ left, right }) => {
  return (
    <Grid.Column width={3}>
      <Grid padded={"vertically"}>
        <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column width={14} style={{ padding: 0 }}>
            {left}
          </Grid.Column>
          <Grid.Column width={2} textAlign="right" style={{ padding: 0 }}>
            {right}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Column>
  );
};

const HeaderRightColumn: React.FC = ({ children }) => {
  return (
    <Grid.Column width={13}>
      <Grid padded={"vertically"}>
        <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column style={{ padding: 0 }}>{children}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Column>
  );
};

type HeaderGridType = React.FC & {
  LeftColumn: React.FC<{
    left: React.ReactElement;
    right: React.ReactElement;
  }>;
  RightColumn: React.FC;
};

const Header: HeaderGridType = ({ children }) => {
  return <Grid.Row>{children}</Grid.Row>;
};

Header.LeftColumn = HeaderLeftColumn;
Header.RightColumn = HeaderRightColumn;

// End Header custom components

// Body custom components

type BodyGridType = React.FC & {
  List: React.FC;
  Form: React.FC;
  EmptyForm: React.FC;
};

const BodyLeft: React.FC = ({ children }) => {
  return <Grid.Column width={3}>{children}</Grid.Column>;
};
const BodyRight: React.FC = ({ children }) => {
  return <Grid.Column width={13}>{children}</Grid.Column>;
};
const Body: BodyGridType = ({ children }) => {
  return <Grid.Row>{children}</Grid.Row>;
};

const EmptyBodyRight: React.FC = ({ children }) => {
  return (
    <Grid.Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {children}
    </Grid.Row>
  );
};

Body.List = BodyLeft;
Body.Form = BodyRight;
Body.EmptyForm = EmptyBodyRight;

// End Body custom components

// Main layout component
type ManageLayoutGridType = React.FC<{ testId?: string }> & {
  Header: HeaderGridType;
  Body: BodyGridType;
};

/**
 *
 * @example
 * <ManageGrid>
 *
 *  <ManageGrid.Header>
 *
 *   <ManageGrid.Header.LeftColumn
 *    left={<TitleAndExplainWithPopup />}
 *    right={<AddNewEntity />}
 *    />
 *
 *    <ManageGrid.Header.RightColumn>
 *      <MoreExplainTextOrFormState />
 *    </ManageGrid.Header.RightColumn>
 *
 *   </ManageGrid.Header>
 *
 *  <ManageGrid.Body>
 *
 *    <ManageGrid.Body.List>
 *      <ListEntities />
 *    </ManageGrid.Body.List>
 *
 *   <ManageGrid.Body.Form>
 *      <InputFormForSelectedEntity />
 *   </ManageGrid.Body.Form>
 *
 *  </ManageGrid.Body>
 * </ManageGrid>
 *
 */
const ManageGrid: ManageLayoutGridType = ({
  children,
  testId = "manage-grid",
}) => {
  return (
    <Grid
      stretched
      celled
      style={{ background: "white" }}
      className="manage-content-grid"
      data-testid={testId}
    >
      {children}
    </Grid>
  );
};

ManageGrid.Header = Header;
ManageGrid.Body = Body;

export default ManageGrid;
