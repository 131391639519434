import React, { Dispatch, SetStateAction } from "react";
import { Form, Input } from "semantic-ui-react";
import { Text } from "../../../components/Text";
type Props = {
  testId?: string;
  caption?: string;
  label: string;
  placeholder: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  clearable?: boolean;
};
export const PromptInputField: React.FC<Props> = ({
  testId,
  caption,
  label,
  placeholder,
  value,
  setValue,
  clearable,
}) => (
  <Form.Field>
    <label>
      {label} {!clearable && "*"}
    </label>
    {caption && (
      <Text color="grey" className="descriptive-helper-text">
        {caption}
      </Text>
    )}
    <Input
      data-testid={testId}
      placeholder={placeholder}
      value={value}
      onChange={({ target }): void => setValue(target.value)}
    />
  </Form.Field>
);
