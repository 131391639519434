import React from "react";
import { Button, Modal } from "semantic-ui-react";

type Props = {
  content: string;
  onClose: () => void;
  open: boolean;
};

export const SimpleModal: React.FC<Props> = ({ content, onClose, open }) => (
  <Modal open={open} size={"small"}>
    <Modal.Content>
      <p>{content}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>Close</Button>
    </Modal.Actions>
  </Modal>
);
