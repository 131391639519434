import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "semantic-ui-react";
import { ChannelLanguagePair, ProductId } from "../product";
import {
  TranslationModalFormData,
  TranslationsModal,
  StyledModal,
} from "./TranslationsModal";
import { CustomerChannel } from "../../customers/Customer";
import {
  getProductTexts,
  productTextAction,
  RegenerateConfig,
  updateProductChannelLanguagePairs,
} from "../../api/action";
import { mapTranslationModalFormData } from "./translationsmodalutils";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import {
  PublishRequest,
  TaskDispatcher2,
  TextGenerationRequest,
} from "../../utils/TaskDispatcher2";
import { OverwriteHeader, UserActionContext } from "../publish/actionTypes";
import { Spinner } from "../../utils/Spinner";
import { ProductTextsMapping } from "../product-list/ProductList";
import { Language } from "../../customers/customerlanguages";
import { RegenerateTextsModalStateHandlerComp } from "../regenerate-texts-modal/RegenerateTextsModalStateHandler";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import { useGetCustomerQuery } from "../../api/customerApi";
import { getFeatureFlag } from "../../utils/featureFlags";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../api/djangoToastSlice";
import {
  productDetailApi,
  useGetProductQuery,
} from "../../api/productDetailSlice";

export type CustomerChannelLanguagePair = {
  customerChannel: CustomerChannel;
  customerLanguage: Language;
};

type Props = {
  onOpen?: (v: boolean) => void;
  open: boolean;
  productId: ProductId;
};

export const TranslationsModalStateHandler: React.FC<Props> = ({
  onOpen,
  open,
  productId,
}) => {
  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );
  const token = useSelector((state: RootState) => state.auth.token);
  const taskDispatcher = new TaskDispatcher2(token);
  const [
    isSaveSettingsButtonClicked,
    setIsSaveSettingsButtonClicked,
  ] = useState(false);
  const [isTranslateButtonClicked, setIsTranslateButtonClicked] = useState(
    false
  );
  const [productTextsMapping, setProductTextsMapping] = useState<
    ProductTextsMapping
  >({});
  const [refetchProductTexts, setRefetchProductTexts] = useState(false);
  const [regenerateModalOpened, setRegenerateModalOpened] = useState(false);

  const [
    channelLanguageCheckboxDisabled,
    setChannelLanguageCheckboxDisabled,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mounted = useRef(true);

  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();

  const channelLanguagePairs = useMemo(() => {
    if (!product || !customer) return [];

    let channelLanguagePairs = product.channel_language_pairs;
    if (!channelLanguagePairs.length)
      channelLanguagePairs = customer.channel_language_pairs
        .filter((clpData) => clpData.default)
        .map(
          (clpData) =>
            new ChannelLanguagePair(clpData.language_code, clpData.channel_id)
        );

    return channelLanguagePairs;
  }, [product, customer]);

  const translationModalFormData = useMemo<TranslationModalFormData[]>(() => {
    if (!channelLanguagePairs) return [];
    return mapTranslationModalFormData(channelLanguagePairs);
  }, [channelLanguagePairs]);

  const fetchProductText = async (): Promise<void> => {
    const currentProductTexts = await getProductTexts({
      token,
      productIds: [productId],
      channelLanguagePairs,
    });

    updateProductTexts(currentProductTexts);
  };

  const getSelectedProductTextRefs = (): ProductTextRef[] => {
    return getSelectedChannelLanguagePairs().map((pair) =>
      pair.createProductTextRef(productId)
    );
  };

  const getSelectedChannelLanguagePairs = (): ChannelLanguagePair[] => {
    const pairs: ChannelLanguagePair[] = [];
    translationModalFormData.forEach((formData) => {
      formData.selectedLanguageCodes.forEach((languageCode) => {
        formData.selectedCustomerChannelIds.forEach((channelId) => {
          pairs.push(new ChannelLanguagePair(languageCode, channelId));
        });
      });
    });
    return pairs;
  };

  const fetchProductTexts = async (
    regenerateConfig: RegenerateConfig
  ): Promise<void> => {
    const productTextRefs = getSelectedProductTextRefs();

    const promises = productTextRefs.map((productTextRef) => {
      return taskDispatcher
        .queue(
          new TextGenerationRequest(
            productTextRef,
            regenerateConfig,
            UserActionContext.TRANSLATIONS_MODAL_TEXT_TOOLBAR
          )
        )
        .then((productText: ProductText) => {
          updateProductTexts([productText]);
          setIsSaveSettingsButtonClicked(false);
          setIsTranslateButtonClicked(false);
        });
    });
    setIsLoading(true);
    await Promise.all(promises).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!product) return;
    (async (): Promise<void> => {
      mounted.current = true;
      if (mounted.current && customer) {
        setIsLoading(true);
        setChannelLanguageCheckboxDisabled(true);

        await fetchProductText()
          .then(() => {
            if (
              getFeatureFlag(
                customer,
                "product_list_automatically_regenerate_needs_regeneration"
              )
            ) {
              setRefetchProductTexts(true);
            }
          })
          .finally(() => {
            setIsLoading(false);
            setChannelLanguageCheckboxDisabled(false);
          });
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, [product, isProductLoading]);

  useEffect(() => {
    if (refetchProductTexts) {
      fetchProductTexts({ regenerate: false });
      setRefetchProductTexts(false);
    }
  }, [refetchProductTexts]);

  const onGenerateTexts = (regenerateConfig: RegenerateConfig): void => {
    setIsTranslateButtonClicked(true);
    setProductTextsMapping({});
    setRegenerateModalOpened(false);
    saveChannelLanguagePairs();
    fetchProductTexts(regenerateConfig);
  };

  const onRequestProductTextAction = async (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: null
  ): Promise<ProductText | null> => {
    setIsLoading(true);

    const channelLanguagePairs = [
      {
        channel_id: productText.customerChannelId,
        language_code: productText.languageCode,
      },
    ];
    let updatedProductText: ProductText | undefined;
    switch (action) {
      case ProductTextAction.GENERATE:
      case ProductTextAction.REGENERATE: {
        let regenerateConfig = null;
        if (action === ProductTextAction.REGENERATE) {
          regenerateConfig = {
            regenerate: true,
            regenerateApprovedTexts: true,
            regenerateEditedTexts: true,
            regeneratePublishedTexts: true,
          };
        }
        updatedProductText = await taskDispatcher.queue(
          new TextGenerationRequest(
            productTextRef,
            regenerateConfig,
            UserActionContext.TRANSLATIONS_MODAL_TEXT_TOOLBAR
          )
        );
        break;
      }
      case ProductTextAction.PUBLISH: {
        const publishResult = await taskDispatcher.queue(
          new PublishRequest(
            productText.productId,
            UserActionContext.TRANSLATIONS_MODAL_TEXT_TOOLBAR,
            OverwriteHeader.NOT_SET,
            channelLanguagePairs
          )
        );
        updatedProductText = publishResult.productTexts?.[0];
        break;
      }
      case ProductTextAction.APPROVE:
      case ProductTextAction.CLOSE_ACTIVITY_MODAL:
      case ProductTextAction.CLOSE_COMMENT_EDITOR:
      case ProductTextAction.CLOSE_TEXT_EDITOR:
      case ProductTextAction.COMMENT:
      case ProductTextAction.EDIT:
      case ProductTextAction.OPEN_ACTIVITY_MODAL:
      case ProductTextAction.OPEN_COMMENT_EDITOR:
      case ProductTextAction.OPEN_TEXT_EDITOR:
      case ProductTextAction.REVERT:
      case ProductTextAction.SAVE_AND_CLOSE_COMMENT_EDITOR:
      case ProductTextAction.SAVE_AND_CLOSE_TEXT_EDITOR:
      case ProductTextAction.UNAPPROVE: {
        updatedProductText = (
          await productTextAction(
            token,
            UserActionContext.TRANSLATIONS_MODAL_TEXT_TOOLBAR,
            [productText.id],
            action,
            data
          )
        )?.[0];
        break;
      }
      default: {
        const exhaustiveCheck: never = action;
        throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
      }
    }
    setIsLoading(false);
    if (!updatedProductText) {
      // FIXME: There's a backend bug that prevents the productText from being returned
      //        successfully, if that happens we'll refetch the texts again, since we know
      //        the product & channel language pairs.
      updatedProductText = (
        await getProductTexts({
          token,
          channelLanguagePairs,
          productIds: [productText.productId],
        })
      )?.[0];
    }
    if (updatedProductText) {
      return updateProductTexts([updatedProductText])?.[0];
    }

    return null;
  };

  const saveChannelLanguagePairs = (
    pairs: ChannelLanguagePair[] = null
  ): void => {
    let channelLanguagePairs;
    if (pairs === null) {
      channelLanguagePairs = getSelectedChannelLanguagePairs();
    } else {
      channelLanguagePairs = pairs;
    }

    updateProductChannelLanguagePairs({
      channelLanguagePairs,
      productId,
      token,
    })
      .then((success) => {
        if (success) {
          setProductTextsMapping({});
          // Reload product and texts when the settings are saved
          fetchProductText().then(() => {
            setRefetchProductTexts(true);
            displayNotification(
              "The settings have been saved",
              NotificationAppearance.SUCCESS
            );
          });
        } else {
          displayNotification(
            "Failed to save settings",
            NotificationAppearance.ERROR
          );
        }
      })
      .finally(() => {
        store.dispatch(
          productDetailApi.util.invalidateTags([
            { type: "Product", id: productId },
          ])
        );
      });
  };

  const onUpdateChannelLanguagePairs = (
    pairs: ChannelLanguagePair[] = null
  ): void => {
    setIsSaveSettingsButtonClicked(true);
    saveChannelLanguagePairs(pairs);
  };

  const updateProductTexts = (
    providedProductTexts: ProductText[]
  ): ProductText[] => {
    setProductTextsMapping((prevState) => {
      const prevStateCopy = { ...prevState };
      for (const productText of providedProductTexts) {
        prevStateCopy[productText.ref.key] = productText;
      }
      return prevStateCopy;
    });

    return providedProductTexts;
  };

  const displayNotification = (
    message: string,
    appearance: NotificationAppearance
  ): void => {
    store.dispatch(
      setDjangoToastOpen({
        appearance,
        content: message,
      })
    );
  };

  const setRegenerateModalDisplay = (value: boolean): void =>
    setRegenerateModalOpened(value);

  if (isCustomerLoading || isProductLoading) {
    return (
      <StyledModal open={open} onClose={(): void => onOpen?.(false)}>
        <Modal.Content>
          <Spinner />
        </Modal.Content>
      </StyledModal>
    );
  }

  return (
    <>
      <TranslationsModal
        channelLanguageCheckboxDisabled={channelLanguageCheckboxDisabled}
        isSaveSettingsButtonClicked={isSaveSettingsButtonClicked}
        isTranslateButtonClicked={isTranslateButtonClicked}
        onClose={(): void => onOpen(false)}
        onButtonTranslateClicked={(): void => setRegenerateModalDisplay(true)}
        onRequestProductTextAction={onRequestProductTextAction}
        onButtonSaveSettingsClicked={(
          pairs: ChannelLanguagePair[] = null
        ): void => onUpdateChannelLanguagePairs(pairs)}
        opened={open}
        product={product}
        productTexts={Object.values(productTextsMapping)}
        translationModalFormData={translationModalFormData}
        isLoading={isLoading}
      />
      <RegenerateTextsModalStateHandlerComp
        cancelMessage={"Close"}
        channelLanguagePairs={getSelectedChannelLanguagePairs()}
        confirmMessage={"Translate"}
        onCancel={(): void => setRegenerateModalDisplay(false)}
        onConfirm={onGenerateTexts}
        open={regenerateModalOpened}
        product={product}
      />
    </>
  );
};
