import React, { useMemo } from "react";
import {
  Flag,
  Language,
  LanguageCode,
} from "../../customers/customerlanguages";
import { TemplateLexicon } from "../template";
import { Icon } from "../../components/tailwind";
type Props = {
  templateName?: string;
  templateVocabId?: number;
  languages: Language[];
  lexicons: { [key in LanguageCode]?: TemplateLexicon };
  languagesInManualTranslation: LanguageCode[];
};
export const TemplateBuilderModalHeader: React.FC<Props> = ({
  templateName,
  templateVocabId,
  languages,
  lexicons,
  languagesInManualTranslation,
}) => {
  const inManualTranslation = useMemo(() => {
    return languages.filter(({ code }) =>
      languagesInManualTranslation.includes(code)
    );
  }, [languages, languagesInManualTranslation]);

  const languagesWithContent = useMemo(() => {
    const languageCodes = Object.values(lexicons)
      .filter((lexicon) => !!lexicon.template)
      .map(({ language_code }) => language_code);

    return languages.filter(
      ({ code }) =>
        languageCodes.includes(code) &&
        !languagesInManualTranslation.includes(code)
    );
  }, [lexicons, languagesInManualTranslation]);

  const languagesWithoutContent = useMemo(() => {
    const emptyLanguageCodes = Object.values(lexicons)
      .filter((lexicon) => !lexicon.template)
      .map(({ language_code }) => language_code);
    const hasContentLanguageCodes = languagesWithContent.map(
      ({ code }) => code
    );
    return languages.filter(
      ({ code }) =>
        (emptyLanguageCodes.includes(code) ||
          !hasContentLanguageCodes.includes(code)) &&
        !languagesInManualTranslation.includes(code)
    );
  }, [lexicons, languagesWithContent, languagesInManualTranslation]);
  return (
    <div className="tw-flex">
      <div>
        <h3>Template Builder</h3>
        <h6
          className="tw-text-gray-600"
          data-testid="template-builder-modal-header-mode-info"
        >
          {templateName && templateVocabId ? (
            <>
              Editing: {templateName}{" "}
              {templateVocabId ? `(${templateVocabId})` : ""}
            </>
          ) : (
            <>Creating {templateName ? templateName : "new"} </>
          )}
        </h6>
      </div>
      <div className="tw-ml-auto">
        <h6 className="tw-ml-0 tw-text-right tw-text-gray-600">Summary</h6>
        <div className="tw-grid tw-grid-cols-[repeat(3,_auto)] tw-divide-x tw-rounded-md tw-border-[1px]">
          <div
            className="tw-flex tw-cursor-default tw-flex-wrap tw-items-center [&:hover>span]:tw-ml-0 [&:hover>span]:tw-bg-white"
            data-balloon={
              languagesWithContent.length
                ? `${languagesWithContent.length} language(s) ready to use.`
                : "No language ready to use."
            }
            data-balloon-pos="down-right"
            data-testid="template-builder-modal-header-has-content-summary"
          >
            <Icon
              name="check"
              className="tw-mx-2 tw-text-xl tw-leading-[1] tw-text-green-600"
            />
            {languagesWithContent.map((language, index) => (
              <Flag
                content={language.flag}
                key={language.code}
                className={`tw-rounded-full tw-border-4 tw-border-white tw-bg-gray-100 tw-w-[30px] tw-h-[30px] tw-pl-[2px] tw-pt-[1px] ${
                  index > 0 ? "-tw-ml-4" : ""
                }`}
              />
            ))}
            {!languagesWithContent.length && (
              <p className="tw-my-0 tw-text-gray-200">&#45;</p>
            )}
          </div>

          <div
            className="tw-flex tw-cursor-default tw-flex-wrap tw-items-center [&:hover>span]:tw-ml-0 [&:hover>span]:tw-bg-white"
            data-balloon={
              inManualTranslation.length
                ? `${inManualTranslation.length} language(s) have been sent for Manual Translation.`
                : "No languages pending translation."
            }
            data-balloon-pos="down-right"
            data-testid="template-builder-modal-header-in-manual-translation-summary"
          >
            <Icon
              name="hourglass_empty"
              className="tw-mx-2 tw-text-xl tw-leading-[1] tw-text-orange-600"
            />
            {inManualTranslation.map((language, index) => (
              <Flag
                content={language.flag}
                key={language.code}
                className={`tw-rounded-full tw-border-4 tw-border-white tw-bg-gray-100 tw-w-[30px] tw-h-[30px] tw-pl-[2px] tw-pt-[1px] ${
                  index > 0 ? "-tw-ml-4" : ""
                }`}
              />
            ))}
            {!inManualTranslation.length && (
              <p className="tw-my-0 tw-text-gray-200">&#45;</p>
            )}
          </div>

          <div
            className="tw-flex tw-cursor-default tw-flex-wrap tw-items-center [&:hover>span]:tw-ml-0 [&:hover>span]:tw-bg-white"
            data-balloon={
              languagesWithoutContent.length
                ? `${languagesWithoutContent.length} language(s) have no content.`
                : "All languages have content or is waiting for translation."
            }
            data-balloon-pos="down-right"
            data-testid="template-builder-modal-header-no-content-summary"
          >
            <Icon
              name="more_horiz"
              className="tw-mx-2 tw-text-xl tw-leading-[1] tw-text-gray-600"
            />
            {languagesWithoutContent.map((language, index) => (
              <Flag
                content={language.flag}
                key={language.code}
                className={`tw-rounded-full tw-border-4 tw-border-white tw-bg-gray-100 tw-w-[30px] tw-h-[30px] tw-pl-[2px] tw-pt-[1px] ${
                  index > 0 ? "-tw-ml-4" : ""
                }`}
              />
            ))}
            {!languagesWithoutContent.length && (
              <p className="tw-my-0 tw-text-gray-200">&#45;</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
