import React from "react";
import { Table } from "semantic-ui-react";

import styled from "styled-components";
import { CustomerChannel } from "./Customer";
import { Language } from "./customerlanguages";

import { MatrixCellsUpdateType } from "./CustomerChannelLanguageMatrix";

const StyledHeaderNameCell = styled(Table.HeaderCell)`
  cursor: pointer !important;
`;

type Props = {
  customerChannels: CustomerChannel[];
  customerLanguages: Language[];
  updateChannelLanguageCells?: ({
    channel_ids,
    checked,
    language_codes,
  }: MatrixCellsUpdateType) => void;
  ShouldCheckOrNot?: (channelId: number) => boolean;
};

export const CustomerChannelLanguageMatrixTableHeader: React.FC<Props> = ({
  customerChannels,
  customerLanguages,
  updateChannelLanguageCells,
  ShouldCheckOrNot,
}) => (
  <Table.Header data-testid={"customer-channel-language-matrix-table-header"}>
    <Table.Row>
      <Table.HeaderCell />
      {customerChannels.map((channel: CustomerChannel) => (
        <StyledHeaderNameCell
          key={channel.id}
          collapsing
          textAlign="center"
          data-testid={"header-cell"}
          onClick={(): void =>
            updateChannelLanguageCells?.({
              channel_ids: [channel.id],
              checked: ShouldCheckOrNot?.(channel.id) ?? true,
              language_codes: customerLanguages.map(
                (language) => language.code
              ),
            })
          }
        >
          {channel.display_name}
        </StyledHeaderNameCell>
      ))}
    </Table.Row>
  </Table.Header>
);
