import React from "react";
import styled from "styled-components";
import { TextGeneratorError } from "../utils/types";

const ErrorDiv = styled.div<{ level: string }>`
  ${(props): string => {
    switch (props.level) {
      case "info":
        return "color: grey;";
      case "warning":
        return "background-color: #fcfcd8;";
      default:
        return "background-color: #fccac7;";
    }
  }}
  font-size: x-small;
  min-height: 32px;
`;

type Props = {
  error: TextGeneratorError;
};

export const ProductTextErrorViewer: React.FC<Props> = ({ error }) => {
  return (
    <ErrorDiv data-testid={"product-text-error-viewer"} level={error.level}>
      {error.message}
    </ErrorDiv>
  );
};
