import React, { useState } from "react";
import { LanguageCode } from "../customers/customerlanguages";
import { useGetRewritePromptsQuery } from "../api/rewritePromptSlice";
import { Icon } from "./tailwind";
import { GptGenerationTaskQueue } from "../api/gptApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";

type Props = {
  getIsGenerating: (isGenerating: boolean) => void;
  onGenerationCompleteCallback: (newText: string) => void;
  input: string;
  languageCode: LanguageCode;
};
export const AIRewriteContextMenuList: React.FC<Props> = ({
  getIsGenerating,
  onGenerationCompleteCallback,
  input,
  languageCode,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const {
    data: rewritePrompts = [],
    isLoading: isFetchingRewritePrompts,
  } = useGetRewritePromptsQuery(undefined);
  const [generatingId, setGeneratingId] = useState<number | null>(null);
  const onGenerate = async (rewritePromptId: number): Promise<void> => {
    setGeneratingId(rewritePromptId);
    getIsGenerating?.(true);
    const taskQueue = new GptGenerationTaskQueue(token);
    const { generated_texts } = await taskQueue
      .generateRewrite(rewritePromptId, input, languageCode, true)
      .finally(() => {
        setGeneratingId(null);
        getIsGenerating?.(false);
      });
    onGenerationCompleteCallback?.(generated_texts[0][languageCode]);
  };
  return (
    <>
      <p className="!tw-m-0 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-px-2 tw-py-1 tw-font-bold tw-text-gray-400">
        <Icon
          name="auto_fix_high"
          className="tw-text-lg tw-leading-[1] tw-text-primary"
        />{" "}
        Rewrite with AI
      </p>
      <div className="tw-w-full tw-border-b-[1px]"></div>
      <ul className="txu-simple-menu tw-max-w-[200px] tw-px-2">
        {isFetchingRewritePrompts && (
          <>
            <li
              data-testid="loading-placeholder"
              className="txu-simple-menu-item tw-mb-1 tw-h-4 tw-w-full tw-animate-pulse tw-bg-gray-200 tw-px-2"
            ></li>
            <li
              data-testid="loading-placeholder"
              className="txu-simple-menu-item tw-mb-1 tw-h-4 tw-w-full tw-animate-pulse tw-bg-gray-200 tw-px-2"
            ></li>
            <li
              data-testid="loading-placeholder"
              className="txu-simple-menu-item tw-mb-1 tw-h-4 tw-w-full tw-animate-pulse tw-bg-gray-200 tw-px-2"
            ></li>
            <li
              data-testid="loading-placeholder"
              className="txu-simple-menu-item tw-mb-1 tw-h-4 tw-w-full tw-animate-pulse tw-bg-gray-200 tw-px-2"
            ></li>
            <li
              data-testid="loading-placeholder"
              className="txu-simple-menu-item tw-h-4 tw-w-full tw-animate-pulse tw-bg-gray-200 tw-px-2"
            ></li>
          </>
        )}
        {rewritePrompts.map((prompt) => (
          <li
            key={prompt.id}
            className={`txu-simple-menu-item tw-flex tw-items-center ${
              generatingId !== null
                ? "tw-cursor-none tw-pointer-events-none"
                : ""
            }`}
            onClick={(): Promise<void> => {
              if (generatingId !== null) return;
              return onGenerate(prompt.id);
            }}
          >
            <span className="tw-truncate">{prompt.name}</span>
            {generatingId === prompt.id && (
              <span
                className="tw-ml-auto tw-inline-block tw-h-4 tw-w-4 tw-animate-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-gray-300 tw-border-t-gray-600"
                data-testid="ai-rewrite-context-menu-loading-icon"
              ></span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
