import { ChannelLanguagePairData } from "../product";

export type ProductProcessResponse = {
  success: boolean;
  message: string;
  task_id: string;
};

export type PublishProductRequest = {
  channel_language_pairs?: ChannelLanguagePairData[];
  user_action_context: UserActionContext;
  overwrite?: number;
};

// keep in sync with app/products/constants.py::UserActionContext
export enum UserActionContext {
  CHANGE_PRODUCT_STATUS = "change-product-status",
  PRODUCT_DETAILS_PUBLISH = "product-details-publish",
  PRODUCT_LIST_TEXT_COLUMN_TOOLBAR = "product-list-text-column-toolbar",
  PRODUCT_STATUS_BUTTON = "product-status-button",
  TRANSLATIONS_MODAL_SAVE_SETTINGS = "translations-modal-save-settings",
  TRANSLATIONS_MODAL_TEXT_TOOLBAR = "translations-modal-text-toolbar",
  PRODUCT_REFRESH_FROM_IMPORTED_DATA = "product-refresh-from-imported-data",
  // This is _only_ used for old publish logs where we don't know where it was published
  UNKNOWN = "unknown",
}

export enum OverwriteHeader {
  NOT_SET = "not-set",
  DO_OVERWRITE = "overwrite",
  DONT_OVERWRITE = "not-overwrite",
}
