let categoryLabels: string[] = [];

type queryParam = { [key: string]: string };

type WindowLocation = {
  url: string;
};

function updateWindowLocation({ url }: WindowLocation): void {
  window.location.assign(url);
}

const parseURL = (location: string): queryParam => {
  const urlSearchParams = new URLSearchParams(location);
  const categoryParams: queryParam = {};
  urlSearchParams.forEach((value, key) => {
    categoryParams[key] = value;
  });
  return categoryParams;
};

const createURLFromParams = (
  location: string,
  categoryParams: queryParam
): string => {
  const newCategoryParams = Object.entries(categoryParams).map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  );
  return location + `?${newCategoryParams.join("&")}`;
};

const handleCategories = (reset: boolean, categoryParams: queryParam): void => {
  if (reset) {
    delete categoryParams["category"];
  } else {
    categoryParams["category"] = categoryLabels.join(",");
  }
};

const goFromSelect = (
  reset = false,
  callback: {
    (reset: boolean, categoryParams: queryParam): void;
  }
): void => {
  const categoryParams = parseURL(window.location.search);
  callback(reset, categoryParams);
  const urlParams = createURLFromParams(
    window.location.pathname,
    categoryParams
  );
  updateWindowLocation({ url: urlParams });
};

document.querySelectorAll("button").forEach((item) =>
  item.addEventListener("click", () => {
    if (item.classList.contains("reset")) goFromSelect(true, handleCategories);
    else goFromSelect(false, handleCategories);
  })
);

const onDeleteCategoryClicked = (element: Element): void => {
  const optionId = element.getAttribute("data-id");
  categoryLabels = categoryLabels.filter((item) => item !== optionId);
  element.parentElement.remove();
};

const setDeleteOption = (): void => {
  document
    .querySelectorAll(".delete-option")
    .forEach((item) =>
      item.addEventListener("click", () => onDeleteCategoryClicked(item))
    );
};

const refreshCategoryLabels = (): void => {
  const categoryParam = parseURL(window.location.search)["category"];
  if (categoryParam) {
    categoryLabels = categoryParam.split(",");
  }
};

const addCategoryLabel = (
  value: string,
  text: string,
  callback: {
    (): void;
  }
): void => {
  const categoryContainer = document.createElement("div");
  categoryContainer.classList.add("selected");
  categoryContainer.innerHTML = `<div data-id="${value}" class="option">${text}</div>
        <div data-id=${value} class="delete-option">(delete)</div>`;
  document
    .getElementById("templatevocabulary-category-filter")
    .appendChild(categoryContainer);
  callback();
};

const onCustomCategorySelectChange = (): void => {
  const selectField = document.getElementById(
    "custom-category-select"
  ) as HTMLSelectElement;
  const opt = selectField.options[selectField.selectedIndex];
  categoryLabels.push(opt.value);
  addCategoryLabel(opt.value, opt.getAttribute("name"), setDeleteOption);
};

document
  .getElementById("custom-category-select")
  .addEventListener("change", () => onCustomCategorySelectChange());

refreshCategoryLabels();
setDeleteOption();
