import React from "react";
import { GenerationSetting } from "./GenerateAITexts";
import { Loader } from "semantic-ui-react";

type Props = {
  generationSetting: GenerationSetting;
  total: number;
  empty: number;
};
export const GenerationSummaryPopup: React.FC<Props> = ({
  generationSetting,
  total,
  empty,
}) => {
  return (
    <div data-testid="generation-summary-popup">
      {total === null && empty === null && <Loader />}
      <ul>
        {!!empty && (
          <>
            <li>{empty} product(s) will use Prompt Selection rules.</li>
            <ul>
              {generationSetting.fallbackPrompt?.id ? (
                <li>
                  If no rule matches the fallback Prompt &quot;
                  {generationSetting.fallbackPrompt?.name || "Unknown Prompt"}
                  &quot; will be used.
                </li>
              ) : (
                <li>
                  If no rule matches it will skip generation for this Section.
                  (No fallback Prompt selected)
                </li>
              )}
            </ul>
          </>
        )}
        {!!total && (
          <li>
            {!empty ? "All " : null}
            {total} text(s) will use previously used Prompt.
          </li>
        )}
        <li>
          {generationSetting.translate
            ? "Will translate generation result to all languages."
            : "Will not translate generation result to all languages."}
        </li>
      </ul>
    </div>
  );
};
