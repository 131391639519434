import api from "./api";
import { showMessage } from "../utils/partialUtils";
import { NotificationAppearance } from "./djangoToastSlice";

/**
 * Implementation:
 * the class should hold the task ids and have a method to get the status of a task
 * The extended classes should hold methods to create the task ids
 * The task status endpoint should be a generic endpoint that returns the status of any task.
 * But the extended class should type the return value of the status endpoint.
 * The result key should be typed by the extended class as it can differ to what is expected.
 */

export const TASK_BASE_URL = "/api/_internal/base/task/";
/**
 * The task id will be a UUID generated by the backend.
 */
export type TaskId = string;

type TaskError = {
  reason: string;
  message: string;
};

export const taskState = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  REVOKED: "REVOKED",
} as const;

export type TaskStatus<T = unknown> = {
  state: typeof taskState[keyof typeof taskState];
  result: T;
  error: TaskError;
};

type TaskInfo = {
  created: Date;
  caller?: string;
};
type TaskMap = { [key: TaskId]: TaskInfo };

/**
 * base class to handle tasks and get their status in the frontend.
 * This class is not meant to be used directly, but rather extended by other classes.
 * It implements a way of storing the task ids, canceling tasks and getting task status.
 * The extended classes should implement a way of creating the task.
 */
export class TaskQueue<T = unknown> {
  tasks: TaskMap = {};
  token: string;
  pollInterval: number = 2000; // ms
  cancelMessage: string;

  constructor(
    token: string,
    pollInterval?: number,
    cancelMessage: string = "The task was cancelled."
  ) {
    this.token = token;
    this.cancelMessage = cancelMessage;
    if (pollInterval) {
      this.pollInterval = pollInterval;
    }
  }

  getTaskIds(): string[] {
    return Object.keys(this.tasks);
  }

  taskIdExists(taskId: string): boolean {
    return !!this.tasks[taskId];
  }
  // Caller might be useful for debugging. But is unused ATM.
  addTaskId(taskId: string, caller?: string): void {
    if (this.taskIdExists(taskId)) {
      console.error(`Task with id ${taskId} already exists (${caller})`);
      return;
    }
    this.tasks[taskId] = {
      created: new Date(),
      caller,
    };
  }

  removeTaskId(taskId: string): void {
    if (!this.taskIdExists(taskId)) {
      console.error(`Task with id ${taskId} doesn't exist`);
      return;
    }

    delete this.tasks[taskId];
  }

  private async getTaskStatus(taskId: string): Promise<TaskStatus<T>> {
    const response = await api.get(`${TASK_BASE_URL}status/${taskId}`, {
      headers: { token: this.token },
    });
    return response.data;
  }

  /**
   *
   * @param taskId The id of the task to wait for
   * @param updateResult a callback function to be passed in if the task has steps in it.
   * @returns The result of the task
   */
  async waitUntilTaskIsDone(
    taskId: string,
    updateResult?: (result: unknown) => void
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        if (!this.taskIdExists(taskId)) {
          // We try to return early and cancel the interval if the task id doesn't exist in the taskIds array, But due to timing issues it might be able to hit the endpoint before this if statement.
          // The backend handles a REVOKED status as well.
          clearInterval(interval);
          console.warn(`Task with id ${taskId} was cancelled`);
          showMessage(NotificationAppearance.WARNING, this.cancelMessage);
          reject("Cancelled");
          return;
        }
        try {
          const task = await this.getTaskStatus(taskId);
          updateResult?.(task.result);

          if (task.state === taskState.SUCCESS) {
            this.removeTaskId(taskId);
            clearInterval(interval);

            resolve(task.result);
          } else if (
            task.state === taskState.FAILURE ||
            task.state === taskState.REVOKED
          ) {
            // This should never get hit as the catch block above should catch all errors.
            // A Failed task will send a 500 http status code, which will be caught by the catch block.
            // A revoked task will send a 410 http status code, which will be caught by the catch block.
            // This is here just in case due to hard to control the timing correctly.
            this.removeTaskId(taskId);
            clearInterval(interval);
            reject();
          }
        } catch (error) {
          this.removeTaskId(taskId);
          clearInterval(interval);
          reject(error);
          return { state: taskState.FAILURE };
        }
      }, this.pollInterval);
    });
  }

  async cancelTask(taskId: string): Promise<unknown> {
    try {
      await api.get(`${TASK_BASE_URL}cancel/${taskId}`, {
        headers: { token: this.token },
      });
      this.removeTaskId(taskId);
    } catch (error) {
      console.error(error);
    }
    return;
  }
}
