import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Form, Modal } from "semantic-ui-react";

import { updateFindAndReplaces } from "../api/action";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../api/djangoToastSlice";
import { LanguageCode } from "../customers/customerlanguages";
import { createHref } from "../utils/hrefUtils";
import { RootState, store } from "../utils/store";
import { FindAndReplaceFormInput } from "./FindAndReplaceFormInput";

export type FindAndReplaceInputData = {
  language: LanguageCode;
  replace: string;
  find: string;
  pos: number;
  is_regex?: boolean; // currently unused but the API supports it.
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const FindAndReplaceModal: React.FC<Props> = ({ open, setOpen }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer, isLoading } = useGetCustomerQuery();

  const [inputs, setInputs] = useState<number[]>([0]);

  const [inputsData, setInputsData] = useState<FindAndReplaceInputData[]>([]);

  const handleRemoveInput = (pos: number): void => {
    setInputs((prev) => [...prev.filter((v) => v !== pos)]);
    setInputsData((prev) => [...prev.filter((data) => data.pos !== pos)]);
  };

  const collectData = (inputData: FindAndReplaceInputData): void => {
    setInputsData([
      ...inputsData.filter(({ pos }) => pos !== inputData.pos),
      inputData,
    ]);
  };

  const removeInputData = (pos: number): void => {
    setInputsData((prev) => [...prev.filter((data) => data.pos !== pos)]);
  };

  const handleUpdateFindAndReplace = async (): Promise<void> => {
    await updateFindAndReplaces(token, inputsData);
    setInputs([0]);
    setInputsData([]);
    setOpen(false);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Word Replacement added",
      })
    );
  };
  return (
    <Modal
      data-testid={`find-and-replace-modal-${
        open && !isLoading ? "open" : "closed"
      }`}
      open={open}
      onClose={(): void => setOpen(false)}
    >
      <Modal.Header>Add Word Replacements</Modal.Header>
      <Modal.Content>
        <Form as="div" size="tiny">
          {inputs.map((pos) => (
            <React.Fragment key={pos}>
              <FindAndReplaceFormInput
                pos={pos}
                handleRemoveInput={handleRemoveInput}
                isRemovable={inputs.length > 1}
                collectData={collectData}
                removeInputData={removeInputData}
                initialState={inputsData.find((data) => data.pos === pos)}
                prevSelectedLanguage={
                  inputsData.length
                    ? inputsData[inputsData.length - 1]?.language
                    : undefined
                }
              />
              {inputs.length > 1 && <Divider />}
            </React.Fragment>
          ))}

          <a href={createHref("FindAndReplace", customer)} data-subpage="">
            View All Word Replacements...
          </a>
          <Button
            data-testid="find-and-replace-modal-add-input"
            floated="right"
            content="Add"
            icon="plus"
            compact
            size="tiny"
            color="red"
            onClick={(): void =>
              setInputs((prev) => [...prev, prev[prev.length - 1] + 1])
            }
          />
          <Divider hidden />
          <Divider hidden />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          data-testid="find-and-replace-modal-cancel-button"
          basic
          size="small"
          floated="left"
          content="Cancel"
          onClick={(): void => setOpen(false)}
        />
        <Button
          disabled={inputsData.length < 1}
          data-testid="find-and-replace-modal-save-button"
          content="Save"
          color="red"
          onClick={handleUpdateFindAndReplace}
        />
      </Modal.Actions>
    </Modal>
  );
};
