import React from "react";
type ModalHeaderProps = {
  divide?: boolean;
};
const ModalHeader: React.FC<ModalHeaderProps> = ({ children, divide }) => (
  <section className={`txu-header${divide ? " txu-divide" : ""}`}>
    {children}
  </section>
);
type ModalBodyProps = {
  minHeight?: number; // px
};
const ModalBody: React.FC<ModalBodyProps> = ({ children, minHeight }) => (
  <section
    className="txu-body"
    {...(minHeight && { style: { minHeight: `${minHeight}px` } })}
  >
    {children}
  </section>
);

const ModalActions: React.FC = ({ children }) => (
  <section className="txu-actions">{children}</section>
);

type Props = {
  dialogRef: React.Ref<HTMLDialogElement>;
  isLoading?: boolean;
  size?: "sm" | "lg" | "full";
};

type ModalType = React.FC<Props> & {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBodyProps>;
  Actions: React.FC;
};
const Modal: ModalType = ({ children, dialogRef, isLoading, size }) => {
  let classNames = "txu-modal";
  if (size) {
    classNames += ` txu-${size}`;
  }
  if (isLoading) {
    classNames += " txu-loading";
  }
  return (
    <dialog ref={dialogRef} className={classNames}>
      <div className="txu-content">{children}</div>
    </dialog>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Actions = ModalActions;

export { Modal };
