import React, { useEffect, useMemo, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button } from "../../../components/tailwind";
type Props = {
  inputIdSelector: string;
};

export const AddDataActionForm: React.FC<Props> = ({ inputIdSelector }) => {
  const [mode, setMode] = useState<"simple" | "raw">("simple");

  const textareaControlInputRef = useRef<HTMLTextAreaElement>();
  const [rawMetadata, setRawMetadata] = useState<string>("{}");
  const simpleModeIsBroken = useRef(false);

  useEffect(() => {
    const textareaControlInput = document.getElementById(
      inputIdSelector
    ) as HTMLTextAreaElement;
    if (!textareaControlInput) {
      throw new Error("Can't find the textarea control");
    }
    textareaControlInputRef.current = textareaControlInput;
    setRawMetadata(textareaControlInput.value);
  }, [inputIdSelector]);

  const parsedMetadata = useMemo(() => {
    try {
      simpleModeIsBroken.current = false;
      return JSON.parse(rawMetadata);
    } catch (e) {
      console.error(e);
      setMode("raw");
      simpleModeIsBroken.current = true;
      return {};
    }
  }, [rawMetadata]);

  const switchMode = (): void => {
    switch (mode) {
      case "raw": {
        if (simpleModeIsBroken.current) break;
        setMode("simple");
        break;
      }
      case "simple":
        setMode("raw");
        break;
    }
  };

  const onChangeRaw = (value: string): void => {
    textareaControlInputRef.current.value = value;
    setRawMetadata(value);
  };

  const onAddSimple = (): void => {
    const copyMetadata = cloneDeep(parsedMetadata);
    const metadataLength = Object.keys(copyMetadata).length;
    copyMetadata[`key_${metadataLength}`] = `value_${metadataLength}`;

    const newRawMetadata = JSON.stringify(copyMetadata);
    setRawMetadata(newRawMetadata);
    textareaControlInputRef.current.value = newRawMetadata;
  };

  const onRemoveSimple = (key: string): void => {
    const copyMetadata = cloneDeep(parsedMetadata);
    delete copyMetadata[key];
    const newRawMetadata = JSON.stringify(copyMetadata);
    setRawMetadata(newRawMetadata);
    textareaControlInputRef.current.value = newRawMetadata;
  };

  const onChangeSimple = (
    key: string,
    value: string | number | string[],
    pos: number
  ): void => {
    const copyMetadata = cloneDeep(parsedMetadata);
    const newMetadata = Object.entries(copyMetadata).reduce(
      (prev, [k, v], index) => {
        if (index === pos) {
          return { ...prev, [key]: value };
        }
        return { ...prev, [k]: v };
      },
      {}
    );
    const newRawMetadata = JSON.stringify(newMetadata);
    setRawMetadata(newRawMetadata);
    textareaControlInputRef.current.value = newRawMetadata;
  };

  if (mode === "simple") {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Button
          content="Switch to Raw Mode"
          variant="primary"
          size="sm"
          compact
          className="tw-ml-auto"
          onClick={switchMode}
          type="button"
        />
        <div className="tw-grid tw-grid-cols-[1fr_1fr_auto] tw-gap-2">
          <label>Key:</label>
          <label>Value:</label>
          <div></div>
          {Object.entries(parsedMetadata).map(([key, value], pos) => {
            let stringifiedValue = value as string | number | string[];
            if (typeof value == "object") {
              stringifiedValue = JSON.stringify(value);
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={pos}>
                <input
                  value={key}
                  className="tw-form-input tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pr-8 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 disabled:tw-text-gray-400 disabled:tw-shadow-none disabled:tw-ring-gray-50"
                  onChange={({ target }): void =>
                    onChangeSimple(target.value, stringifiedValue, pos)
                  }
                />
                <input
                  value={stringifiedValue}
                  className="tw-form-input tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pr-8 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 disabled:tw-text-gray-400 disabled:tw-shadow-none disabled:tw-ring-gray-50"
                  onChange={({ target }): void =>
                    onChangeSimple(key, target.value, pos)
                  }
                />
                <Button
                  icon
                  size="sm"
                  variant="primary-alt"
                  type="button"
                  data-testid={`add-data-action-form-remove-row-${pos}`}
                  onClick={(): void => onRemoveSimple(key)}
                >
                  <i className="material-icons">close</i>
                </Button>
              </React.Fragment>
            );
          })}
        </div>
        <Button
          icon
          variant="primary"
          className="tw-ml-auto"
          onClick={onAddSimple}
          type="button"
          size="sm"
        >
          <i className="material-icons">add</i>
        </Button>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <Button
        content="Switch to Simple Mode"
        variant="primary"
        size="sm"
        compact
        className="tw-ml-auto"
        onClick={switchMode}
        type="button"
        disabled={simpleModeIsBroken.current}
      />
      {simpleModeIsBroken.current && (
        <p className="tw-m-0 tw-text-gray-600">
          The JSON object seems not to be formatted correctly.
        </p>
      )}
      <textarea
        data-testid="add-data-action-form-raw-input"
        value={rawMetadata}
        onChange={(e): void => onChangeRaw(e.target.value)}
      />
    </div>
  );
};
