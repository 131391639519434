import React from "react";
import { Section } from "./Section";
import {
  SectionAction,
  SectionActionRequest,
  SectionDetails,
} from "../../api/sectionActions";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { ProductDetailInfo } from "../edit-tab/ProductDetailInfo";

type Props = {
  activeSections: Set<number>;
  details: ProductDetailInfo | null;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  sections: SectionDetails[];
  textualAppName: string;
  toggleSectionVisibility: (sectionId: number) => void;
};

export const Sections: React.FC<Props> = ({
  activeSections,
  details,
  onSectionItemAction,
  sections,
  textualAppName,
  toggleSectionVisibility,
}) => {
  const onDragEnd = (result: DropResult): void => {
    const { destination, draggableId } = result;
    // Dropping to nowhere, e.g. not permitted
    if (!destination) {
      return;
    }
    const { droppableId: destId, index: destIndex } = destination;
    onSectionItemAction({
      action: SectionAction.ITEM_REORDER,
      tag_id: draggableId,
      section_name: destId,
      index: destIndex,
    });
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {sections.map((section) => (
        <Section
          key={section.name}
          active={activeSections.has(section.id)}
          details={details}
          section={section}
          onSectionItemAction={onSectionItemAction}
          textualAppName={textualAppName}
          toggleSectionVisibility={toggleSectionVisibility}
        />
      ))}
    </DragDropContext>
  );
};
