import React from "react";
import { Droppable, Draggable, DraggingStyle } from "react-beautiful-dnd";
import { Segment, Ref, List, Button } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { findConnectedChannelsForSection } from "../../copy-assistant/utils";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { DocumentStructure } from "../../../planner/document-structure/types";

export type FieldsetFormListItem = {
  name: string;
  id?: number;
  origin: "data" | "section";
};

type Props = {
  testIdPrefix: string;
  listItems: FieldsetFormListItem[];
  listItemAction: (item: FieldsetFormListItem) => void;
  listItemIcon: string;
  listHight: number;
  droppableId: string;
  disabledDrop?: boolean;
  structures?: DocumentStructure[];
};
export const FieldsetFormList: React.FC<Props> = ({
  testIdPrefix,
  listItems,
  listItemAction,
  listItemIcon,
  listHight,
  droppableId,
  disabledDrop,
  structures,
}) => {
  const { data: customer } = useGetCustomerQuery();

  const createDisplayName = (item: FieldsetFormListItem): React.ReactNode => {
    if (item.origin === "section") {
      let additionalInfo = "(Section)";
      if (item?.id && customer && structures) {
        const channelDisplayNames = findConnectedChannelsForSection(
          item.id,
          customer,
          structures
        )
          .map(({ name }) => name)
          .join(", ");

        if (channelDisplayNames) {
          additionalInfo = `(Section connected to ${channelDisplayNames})`;
        }
      }
      return (
        <>
          <b>{item.name}</b>{" "}
          <Text size="tiny" color="grey" inline compact>
            {additionalInfo}
          </Text>
        </>
      );
    }
    return item.name;
  };
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={disabledDrop}
      renderClone={(provided, snapshot, rubric): any => {
        const item = listItems[rubric.source.index];
        provided.draggableProps.style = {
          ...provided.draggableProps.style,
          width: 460,
        } as DraggingStyle & {
          width: number;
        };
        return (
          <Ref innerRef={provided.innerRef}>
            <Segment
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              compact
            >
              {item?.name}
            </Segment>
          </Ref>
        );
      }}
    >
      {(provided): React.ReactElement => (
        <Ref innerRef={provided.innerRef}>
          <List
            animated
            data-testid={`${testIdPrefix}-list`}
            size="small"
            style={{
              userSelect: "none",
              height: `${listHight}px`,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            divided
            relaxed
            verticalAlign="middle"
          >
            {listItems.map((item, index) => (
              <Draggable
                draggableId={`${droppableId}-${item.name}`}
                index={index}
                key={item?.id || item.name}
              >
                {(provided): React.ReactElement => {
                  const style = {
                    ...provided.draggableProps.style,
                    cursor: "pointer",
                  };
                  return (
                    <Ref innerRef={provided.innerRef}>
                      <List.Item
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                        data-testid={`${testIdPrefix}-list-item-${item.name}`}
                        onClick={(): void => listItemAction(item)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Text
                            size="tiny"
                            color="grey"
                            compact
                            width="min"
                            inline
                          >
                            <i
                              className="material-icons"
                              style={{
                                opacity: 0.5,
                                marginTop: "0.2em",
                                cursor: "grab",
                              }}
                            >
                              drag_indicator
                            </i>
                          </Text>
                          <List.Content style={{ flex: 1 }}>
                            {createDisplayName(item)}
                          </List.Content>
                          <Button
                            icon={listItemIcon}
                            color="red"
                            basic
                            size="tiny"
                            compact
                            data-testid={`${testIdPrefix}-list-item-action-${item.name}`}
                            onClick={(): void => listItemAction(item)}
                          />
                        </div>
                      </List.Item>
                    </Ref>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        </Ref>
      )}
    </Droppable>
  );
};
