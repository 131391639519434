import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ViewSetsGroupDropdownItem,
  buildViewsDropdownItem,
} from "./ViewSetsGroupDropdownItem";
import { Accordion, Dropdown, DropdownProps, Input } from "semantic-ui-react";
import { View, ViewSet } from "./ViewSetsFilterContainer";
import { onKeyUp } from "../../../utils/semanticInputUtils";
import { StyledDropdownBase } from "./ViewSetsCreateNewFilterDropdown";
import { useFavoriteViewMutation } from "../../../api/viewSetApi";

type Props = {
  activeIndex: number;
  label: string;
  onSelectedViewChange: (selectedView: View) => void;
  updateActiveIndex: (index: number) => void;
  viewSets: ViewSet[];
};
export const ViewSetsGroupDropdownSearch: React.FC<Props> = ({
  activeIndex,
  label,
  onSelectedViewChange,
  updateActiveIndex,
  viewSets,
}) => {
  const [favoriteView] = useFavoriteViewMutation();
  const [searchResult, setSearchResult] = useState<ViewSet[] | []>([]);
  const [open, setOpen] = useState(false);
  const mounted = useRef(true);

  const favoriteViews = useMemo(() => {
    if (!viewSets) return [];
    return viewSets
      .flatMap(({ views }) => views.filter(({ favorite }) => favorite))
      .sort((a, b) => {
        if (a.display_name < b.display_name) {
          return -1;
        }
        if (a.display_name > b.display_name) {
          return 1;
        }
        return 0;
      });
  }, [viewSets]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setSearchResult(viewSets);
    }
    return (): void => {
      mounted.current = false;
    };
  }, [viewSets]);

  return (
    <Accordion
      as={StyledDropdownBase}
      basic
      button
      className={"tiny"}
      closeOnChange={true}
      onBlur={(): void => setOpen(false)}
      onClick={(): void => setOpen(!open)}
      open={open}
      text={label}
      scrolling
      data-testid={"filter-group-dropdown"}
    >
      <Dropdown.Menu
        onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
      >
        <Input
          icon="search"
          iconPosition="left"
          onChange={(
            e: React.SyntheticEvent,
            { value }: DropdownProps
          ): void => {
            setSearchResult(
              viewSets.filter(
                ({ display_name }) =>
                  display_name
                    .toLowerCase()
                    .search((value as string).toLowerCase()) != -1
              )
            );
          }}
          placeholder={"Search Group"}
          onKeyUp={onKeyUp}
        />

        {!!favoriteViews.length && (
          <>
            <Dropdown.Header>Favorite</Dropdown.Header>
            {buildViewsDropdownItem({
              views: favoriteViews,
              onSelectedViewChange,
              closeByChild: (): void => setOpen(false),
              onFavoriteView: favoriteView,
            })}
          </>
        )}
        <Dropdown.Header>Filters</Dropdown.Header>
        {searchResult.map(({ display_name, id, views }) => (
          <ViewSetsGroupDropdownItem
            activeIndex={activeIndex}
            closeByChild={(): void => setOpen(false)}
            index={id}
            onSelectedViewChange={onSelectedViewChange}
            key={id}
            title={display_name}
            updateActiveIndex={updateActiveIndex}
            viewSets={views}
            onFavoriteView={favoriteView}
          />
        ))}
      </Dropdown.Menu>
    </Accordion>
  );
};
