export type DateCategory = "created" | "published";

export enum Filterkeys {
  Brands = "brands",
  Labels = "labels",
  ParentChild = "parentChild",
  Template = "template",
  Vocabulary = "vocabulary",
}

export enum PageSizeStatus {
  One = "1",
  Five = "5",
  Ten = "10",
  TwentyFive = "25",
  Fifty = "50",
}

export enum ParentChildStatus {
  All = "all",
  Children = "children",
  Parents = "parents",
}

export enum ProductStatusFilter {
  All = "all",
  Importing = "importing",
  Progress = "progress",
  Ready = "ready",
}

export enum TextStatus {
  All = "text_all",
  Approved = "text_approved",
  InReview = "text_review",
  Published = "text_published",
  WaitingToBePublished = "text_waiting_to_be_published",
}

export enum TranslationStatus {
  All = "translation_all",
  Complete = "translation_complete",
  Missing = "translation_missing",
}

export type AndOrOperator = "AND" | "OR";

export type ItemsWithOperator<TItem = string, TOperator = AndOrOperator> = {
  items: TItem[];
  operator: TOperator;
};

export const emptyStringItems: ItemsWithOperator = {
  items: [],
  operator: "AND",
};
