import React from "react";
import { html } from "js-beautify";
import { Button, Grid, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { HTML } from "../components/HTML";
import { ProductText } from "./ProductText";
import { OnActionCallback } from "./ProductTextViewer";
import { ProductTextAction } from "./ProductTextAction";
import { stripSpans, collectSpanTags, applySpanTags } from "./producttextutils";
import { ProductTextDetailsRail } from "./ProductTextDetailsRail";
import { getProductTexts } from "../api/action";
import { getMachineTranslatedText } from "../api/vocabularyApi";
import { ProductTextModalEditBox } from "./ProductTextModalEditBox";
import { getCustomer } from "../api/customerApi";
import { RootState, store } from "../utils/store";
import { Customer } from "../customers/Customer";
import { connect, ConnectedProps } from "react-redux";

const StyledModal = styled(Modal)`
  && {
    width: 95% !important;

    & .scrolling.content {
      max-height: calc(95vh - 10em) !important;
      height: calc(95vh - 10em);
    }
    & .description {
      height: 100%;
    }
  }
`;

const StyledPreview = styled(HTML)`
  font-size: small;
`;

export enum SelectedTextType {
  Textual = "textual",
  MachineTranslate = "machine",
}

const mapStateToProps = (
  state: RootState
): { token: string | null; customer: Customer } => ({
  token: state.auth.token,
  customer: getCustomer(store.getState()),
});

const connector = connect(mapStateToProps, null);

type Props = {
  onAction: OnActionCallback;
  open: boolean;
  productText: ProductText;
};

type ProductTextModalEditorProps = ConnectedProps<typeof connector> & Props;

type State = {
  originalText: string | null;
  generatedText: string | null;
  machineTranslatedText: string | null;
  selectedText: SelectedTextType;
  isMachineTranslating: boolean;
};

/*
 * Modal that allows user to edit the product text.
 *
 * By default it hides all Textual-related <span> tags just if the text was not annotated.
 * It makes text more readable for user, and prevents from need to disable annotation in text
 * patterns.
 *
 * Once edited text fragment is no longer wrapped up in a <span> tag.
 */
export class ProductTextModalEditor extends React.Component<
  ProductTextModalEditorProps,
  State
> {
  private mounted = false;
  private showMachineTranslate = false;
  private vocabDictionary: Record<string, string> = {};
  private originalTextLanguage: string | null = null;
  private generatedTextLanguage: string | null = null;

  state: State = {
    originalText: null,
    generatedText: null,
    machineTranslatedText: null,
    selectedText: SelectedTextType.Textual,
    isMachineTranslating: false,
  };

  componentDidMount(): void {
    this.mounted = true;
    const { customer, productText } = this.props;
    this.originalTextLanguage = customer.config?.tag_input_language || "en_US";
    this.generatedTextLanguage = productText.languageCode;
    this.showMachineTranslate =
      customer?.config.show_machine_translate_button_in_manual_edit &&
      this.originalTextLanguage !== this.generatedTextLanguage;
    this.vocabDictionary = collectSpanTags(productText.text || "");
    this.setState({ generatedText: html(stripSpans(productText.text || "")) });
  }

  async componentDidUpdate(): Promise<void> {
    const { open } = this.props;
    const { originalText } = this.state;
    if (open && originalText === null) {
      await this.fetchOriginalText();
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  async fetchOriginalText(): Promise<void> {
    const { productText, token } = this.props;
    const originalTextList = await getProductTexts({
      token: token,
      productIds: [productText.productId],
      channelLanguagePairs: [
        {
          channel_id: productText.customerChannelId,
          language_code: this.originalTextLanguage,
        },
      ],
    });
    if (!this.mounted) {
      return;
    }
    const originalText = originalTextList?.[0];

    if (originalText) {
      this.setState({ originalText: html(originalText.text) });
    }
  }

  async fetchMachineTranslateText(): Promise<void> {
    const { token } = this.props;
    const { originalText } = this.state;
    this.setState({ isMachineTranslating: true });
    const translatedText = await getMachineTranslatedText({
      sourceLanguage: this.originalTextLanguage,
      targetLanguage: this.generatedTextLanguage,
      text: originalText,
      context: "manual-edit-product-text-translate",
      token: token,
    }).finally(() => {
      if (this.mounted) this.setState({ isMachineTranslating: false });
    });
    if (!this.mounted) {
      return;
    }
    if (translatedText) {
      this.setState({
        machineTranslatedText: html(translatedText),
      });
    }
  }

  onTextChange = (textType: SelectedTextType, value: string): void => {
    if (!this.mounted) {
      return;
    }
    switch (textType) {
      case SelectedTextType.Textual:
        this.setState({ generatedText: value });
        break;
      case SelectedTextType.MachineTranslate:
        this.setState({ machineTranslatedText: value });
        break;
      default:
        throw new Error();
    }
  };

  onTextSelect = (textType: SelectedTextType): void => {
    if (!this.mounted) {
      return;
    }
    this.setState({ selectedText: textType });
  };

  onSaveChanges = (): void => {
    const { onAction } = this.props;
    const { selectedText, generatedText, machineTranslatedText } = this.state;
    let text;
    switch (selectedText) {
      case SelectedTextType.Textual:
        text = applySpanTags(generatedText, this.vocabDictionary);
        break;
      case SelectedTextType.MachineTranslate:
        text = machineTranslatedText;
        break;
      default:
        throw new Error();
    }
    onAction(ProductTextAction.SAVE_AND_CLOSE_TEXT_EDITOR, text);
  };

  render(): React.ReactElement {
    const { customer, open, onAction, productText } = this.props;
    const {
      originalText,
      generatedText,
      machineTranslatedText,
      selectedText,
      isMachineTranslating,
    } = this.state;

    return (
      <StyledModal open={open} data-testid={"product-text-modal-editor"}>
        <Modal.Header>
          Editing Product Text
          <ProductTextDetailsRail
            channel={customer.getChannelById(productText.customerChannelId)}
            productText={productText}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <h3>Original text</h3>
                  <StyledPreview
                    text={originalText}
                    data-testid={"original-text"}
                  />
                </Grid.Column>
                <ProductTextModalEditBox
                  onChange={this.onTextChange}
                  onSelect={this.onTextSelect}
                  selected={selectedText === SelectedTextType.Textual}
                  selectedTextType={SelectedTextType.Textual}
                  showSelector={this.showMachineTranslate}
                  text={generatedText}
                  title={"Generated text"}
                />
                {this.showMachineTranslate && (
                  <>
                    {!machineTranslatedText ? (
                      <Grid.Column textAlign="center" verticalAlign="middle">
                        <Button
                          data-testid="product-text-modal-editor-machine-translate-text"
                          disabled={!originalText}
                          content="Request Machine Translated version"
                          onClick={(): void => {
                            if (!isMachineTranslating)
                              this.fetchMachineTranslateText();
                          }}
                          color="red"
                          loading={isMachineTranslating}
                        />
                      </Grid.Column>
                    ) : (
                      <ProductTextModalEditBox
                        data-testid={"machine-translated-text"}
                        onChange={this.onTextChange}
                        onSelect={this.onTextSelect}
                        selected={
                          selectedText === SelectedTextType.MachineTranslate
                        }
                        selectedTextType={SelectedTextType.MachineTranslate}
                        text={machineTranslatedText}
                        title={"Machine Translation"}
                      />
                    )}
                  </>
                )}
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-testid={"close-button"}
            onClick={(): void => onAction(ProductTextAction.CLOSE_TEXT_EDITOR)}
            color="red"
            basic
          >
            Close
          </Button>
          <Button
            content="Save Changes"
            data-testid={"save-changes-button"}
            onClick={this.onSaveChanges}
            color="red"
          />
        </Modal.Actions>
      </StyledModal>
    );
  }
}

export const ProductTextModalEditorComp = connector(ProductTextModalEditor);
