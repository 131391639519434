import $ from "jquery";
import { store } from "../utils/store";

$(document).on("click", "button.googletranslate", function () {
  const $button = $(this);

  const languageSource = $button.data("language-source");
  const languageTarget = $button.data("language-target");
  const translateText = $button.data("translate-text");
  const token = store.getState().auth.token;

  $button.addClass("loading");

  $.ajax({
    method: "POST",
    url: "/api/_internal/mt/translate/text_sync",
    data: {
      source_text: translateText,
      source_language: languageSource,
      target_language: languageTarget,
    },
    headers: { token },
    dataType: "json",
    success: function (data) {
      $button.removeClass("loading");
      const translatedText = data.translated_text;

      const lexiconNameInput = $('input[name="lexicon_name"]');
      if (lexiconNameInput.length) {
        lexiconNameInput.val(translatedText);
      }
      const rootFormsLemmaInput = $("#root_forms_lemma");
      if (rootFormsLemmaInput.length) {
        rootFormsLemmaInput.val(translatedText);
      }
    },
  });
});
