import { formatTag } from "./tagutils";

const getGeneratedText = (data) => (data.text ? data.text : "");
const getDisplayText = (data) => {
  if (data.master_display_name) {
    return formatTag(data, {
      includeSubTagNames: true,
      showTagTypeHints: true,
    });
  }
  return data.value;
};
const getValueWithTagId = (data) => {
  return data.tag_id ? `${data.value}-${data.tag_id}` : data.value;
};

export { getDisplayText, getGeneratedText, getValueWithTagId };
