import { Channel } from "../products/product-list/ChannelLanguageSelectorMatrix";
import { Language, LanguageCode } from "./customerlanguages";

export type CustomerId = string;
export type ChannelId = number;

export type CustomerChannel = {
  id: ChannelId;
  display_name: string;
  document_structure_id?: number | null;
  visible?: boolean;
  locked?: boolean;
  config?: string | { [index: string]: any };
};

export interface CustomerChannelLanguagePairData {
  channel_id: ChannelId;
  language_code: LanguageCode;
  default: boolean;
}

export interface TemplateLabel {
  id: number;
  name: string;
}

export class Customer {
  data: any;
  constructor(data: any) {
    this.data = data;
  }

  get default_channel(): number {
    return this.data.default_channel;
  }

  get slug(): string {
    return this.data.slug;
  }

  get config(): any {
    return this.data.config;
  }

  get channels(): CustomerChannel[] {
    return this.data.channels;
  }

  get channel_language_pairs(): CustomerChannelLanguagePairData[] {
    return this.data.channel_language_pairs;
  }

  get languages(): Language[] {
    return this.data.languages.sort((a: any, b: any) =>
      a.short_name > b.short_name ? 1 : -1
    );
  }

  get template_labels(): TemplateLabel[] {
    return this.data.template_labels;
  }

  get isPayingCustomer(): boolean {
    return this.data.trans_prio === 10;
  }

  getChannelById(channelId: ChannelId): CustomerChannel | null {
    return (
      this.channels.find(
        (channel: CustomerChannel) => channel.id === channelId
      ) ?? null
    );
  }

  getVisibleChannels(): CustomerChannel[] {
    return this.channels.filter((channel: Channel) => channel.visible == true);
  }

  getLanguageByCode(languageCode: LanguageCode): Language | null {
    return (
      this.languages.find(
        (language: Language) => language.code === languageCode
      ) ?? null
    );
  }
}
