export const isClipboardAvailable = (): boolean => {
  return !!navigator.clipboard;
};

export const requestClipboardPermission = async (): Promise<boolean> => {
  if (!isClipboardAvailable()) return false;

  try {
    const result = await navigator.permissions.query({
      name: "clipboard-write" as PermissionName,
    });
    if (result.state !== "granted") {
      console.info("Clipboard permission not granted");
      console.info("Clipboard permission:", result.state);
    }
    return result.state === "granted";
  } catch (error) {
    console.log("Error checking clipboard permission:", error);
    return false;
  }
};

export const copyToClipboard = async (
  text: string,
  ...conditions: boolean[]
): Promise<void> => {
  if (!conditions.every((condition) => condition)) return;
  console.log("Clipboard permission not granted");
  if (!(await requestClipboardPermission())) {
    console.log("Clipboard permission not granted");
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    return;
  }
};

export const cutToClipboard = async (
  textToCopy: string,
  textToCut: string,
  start: number,
  end: number,
  ...conditions: boolean[]
): Promise<string | null> => {
  if (!conditions.every((condition) => condition)) return textToCut;

  if (!(await requestClipboardPermission())) {
    return textToCopy;
  }

  try {
    await navigator.clipboard.writeText(textToCopy);
    return textToCut.substring(0, start) + textToCut.substring(end);
  } catch {
    return textToCopy;
  }
};

export const pasteFromClipboard = async (
  callback: (pastedText: string) => void,
  ...conditions: boolean[]
): Promise<void> => {
  if (!conditions.every((condition) => condition)) return;

  if (!isClipboardAvailable()) {
    console.info("Clipboard API not available");
    return;
  }

  try {
    const clipboardPermission = await navigator.permissions.query({
      name: "clipboard-read" as PermissionName,
    });
    if (
      clipboardPermission.state === "granted" ||
      clipboardPermission.state === "prompt"
    ) {
      const pastedText = await navigator.clipboard.readText();
      callback(pastedText);
    } else {
      console.info("Clipboard read permission not granted");
    }
  } catch (error) {
    console.info("Failed to paste text:", error);
  }
};

/**
 * Selects all text in the given input element.
 */
export const selectAllText = (
  inputElement: HTMLTextAreaElement | HTMLInputElement
): void => {
  inputElement.select();
  inputElement.setSelectionRange(0, inputElement.value.length);
};
