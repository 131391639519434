// Backend action constants
export enum ProductTextAction {
  APPROVE = "approve",
  GENERATE = "generate",
  REGENERATE = "regenerate",
  COMMENT = "comment",
  EDIT = "edit",
  PUBLISH = "publish",
  REVERT = "revert",
  UNAPPROVE = "unapprove",
  // Frontend constants, these are UI actions
  OPEN_TEXT_EDITOR = "open-text-editor",
  OPEN_ACTIVITY_MODAL = "open-activity-modal",
  OPEN_COMMENT_EDITOR = "open-comment-editor",
  SAVE_AND_CLOSE_COMMENT_EDITOR = "save-and-close-comment-editor",
  SAVE_AND_CLOSE_TEXT_EDITOR = "save-and-close-text-editor",
  CLOSE_ACTIVITY_MODAL = "close-activity-modal",
  CLOSE_COMMENT_EDITOR = "close-comment-editor",
  CLOSE_TEXT_EDITOR = "close-text-editor",
}
