import React from "react";
import Styled from "styled-components";
import { FontSizeHoverEffectIcon } from "./ProductFilterChoice";
import { Operation } from "./ProductFilterSearch";

const StyledSpan = Styled.span`
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 2px;
    margin-left: 2px;
    padding: 4px;
    &:hover {
      cursor: pointer;
      .close {
        transform: scale(1.4);
      }
    }
    text-transform: none;
    font-size: 0.9rem;
    font-weight: 500;
`;

const FlexContainer = Styled.div`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

type Props = {
  activeDataTestId: string;
  onClick: (element: string, operation: Operation, label?: string) => void;
  selected: string[];
};
export const ProductFilterSearchSelectedItems: React.FC<Props> = ({
  activeDataTestId,
  onClick,
  selected,
}) => (
  <FlexContainer>
    {selected?.map((element) => (
      <StyledSpan
        data-testid={`${activeDataTestId}-${element}`}
        key={element}
        onClick={(e): void => {
          onClick(element, Operation.Delete, "");
          e.stopPropagation();
        }}
      >
        <FontSizeHoverEffectIcon className={"close link icon"} size={"small"} />
        {element}
      </StyledSpan>
    ))}
  </FlexContainer>
);
