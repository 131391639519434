import React, { useEffect, useRef, useState } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { RegenerateConfig } from "../../api/action";
import { Spinner } from "../../utils/Spinner";
import { UserActionContext } from "../publish/actionTypes";
import {
  productDetailsRefreshCurrentTab,
  productDetailsRefreshProductDescription,
} from "../../legacy/t.product-detail";
import {
  useGetProductQuery,
  useSetProductStatusMutation,
} from "../../api/productDetailSlice";
import {
  getProductStatusLabel,
  Product,
  ProductId,
  ProductStatus,
  ProductStatusLabel,
} from "../product";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

type Props = {
  productId: ProductId;
};

export const ProductStatusButton: React.FC<Props> = ({ productId }) => {
  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );
  const [
    updateProductStatus,
    { isLoading: isProductUpdating, data: productUpdatedData },
  ] = useSetProductStatusMutation();
  const [status, setStatus] = useState<ProductStatus>(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && product) {
      setStatus(product.status as ProductStatus);
    }
  }, [product]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && status !== null) {
      statusChange({
        toStatus: status,
        regenerateConfig: {
          regenerate: status === ProductStatus.READY,
        },
      });
    }
  }, [status]);

  useEffect(() => {
    mounted.current = true;
    // should be ProductStatus.IN_PROGRESS, but "null" is not allowed in typescript enums
    if (
      mounted.current &&
      !isProductUpdating &&
      productUpdatedData?.status == null
    ) {
      // after auto-refresh on product, refresh other sections.
      productDetailsRefreshCurrentTab();
    }
  }, [isProductUpdating]);

  useEffect(() => {
    if (
      product &&
      product.status !== ProductStatus.IMPORTING &&
      !isProductUpdating
    ) {
      productDetailsRefreshProductDescription();
    }
  }, [isProductUpdating, status, product]);

  if (!product || isProductLoading) {
    return <Spinner />;
  }

  const statusChange = async ({
    toStatus,
    regenerateConfig,
  }: {
    toStatus: ProductStatus;
    regenerateConfig?: RegenerateConfig;
  }): Promise<
    { data: Product } | { error: FetchBaseQueryError | SerializedError }
  > =>
    updateProductStatus({
      productId,
      status: toStatus,
      userActionContext: UserActionContext.PRODUCT_STATUS_BUTTON,
      regenerateConfig,
    });

  return (
    <Checkbox
      slider
      data-testid={"product-status-button"}
      checked={getProductStatusLabel(status) == ProductStatusLabel.READY}
      label={"Ready"}
      onClick={(
        e: React.FormEvent<HTMLInputElement>,
        { checked }: CheckboxProps
      ): void => {
        setStatus(checked ? ProductStatus.READY : ProductStatus.IN_PROGRESS);
      }}
    />
  );
};
