import axios, { AxiosError, AxiosResponse } from "axios";
import { showMessage } from "../utils/partialUtils";
import { NotificationAppearance } from "./djangoToastSlice";

/*
 * Generic error handler for all errors
 */
export class APIError extends Error {
  response: AxiosResponse;

  constructor(message: string, response: AxiosResponse) {
    super(message);
    this.response = response;
    let msg;
    let level;
    if (response.status >= 200 && response.status < 300) {
      msg = this.responseMessage;
      level = NotificationAppearance.SUCCESS;
    } else if (response.status >= 400 && response.status < 500) {
      msg = this.responseMessage;
      level = NotificationAppearance.WARNING;
    } else {
      msg = response.data.message ?? "An unexpected error occurred";
      level = NotificationAppearance.ERROR;
    }
    showMessage(level, msg);
  }

  get responseMessage(): string {
    const { data } = this.response;
    if (data?.message) {
      return data.message;
    } else if (data?.detail) {
      return data.detail; // { detail: "You do not have permission to perform this action." }
    } else {
      if (typeof data === "string") {
        return data.toString();
      } else {
        return JSON.stringify(data);
      }
    }
  }
}

function errorHandler(error: AxiosError): Promise<APIError> {
  const { config, response } = error;
  const { data, method, url } = config;
  return Promise.reject(
    new APIError(
      `ERROR in backend callback:
          responseURL=${url} 
          method=${method} 
          data=${data} 
          message='${error.message}' 
          status=${response?.statusText} (${response?.status})
          errors=${JSON.stringify(response?.data ?? "").slice(0, 500)}`,
      response
    )
  );
}

const api = axios.create();

api.interceptors.response.use(null, errorHandler);

export default api;
