import { DirectionProperty } from "csstype";
import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

import { LanguageCode } from "../../customers/customerlanguages";
import { RequestWordModalButtons } from "./RequestWordModalButtons";
import { RequestWordModalSamples } from "./RequestWordModalSamples";
import { RequestWordModalWordDetails } from "./RequestWordModalWordDetails";
import { dropdownOptions } from "./RequestWordModalWordType";
import { VocabularyTreeData } from "../../utils/tagutils";
import {
  SimilarVocabulary,
  copyVocabularyToCustomer,
  getSimilarVocabularies,
} from "../../api/vocabularyApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import { RequestWordModalSimilarVocabularies } from "./RequestWordModalSimilarVocabularies";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../api/djangoToastSlice";
import { RequestWordModalPickTranslationType } from "./RequestWordModalPickTranslationType";

export enum RequestWordTranslateType {
  MACHINE_TRANSLATE = "machine_translate",
  TRANSLATE = "translate",
  CUSTOMER_TRANSLATE = "customer_translate",
}

export const RequestWordTranslateTypeToastMessage = {
  [RequestWordTranslateType.MACHINE_TRANSLATE]:
    "Vocabulary has been machine translated and is ready to be used.",
  [RequestWordTranslateType.TRANSLATE]:
    "Vocabulary has been added and sent to Textual for translation.",
  [RequestWordTranslateType.CUSTOMER_TRANSLATE]:
    "Vocabulary has been added and is waiting for your translation.",
} as const;

export enum AllowTagType {
  ALL = "all",
  KIND_MATERIAL = "kind-material",
  NON_FIXED_PHRASES = "non-fixed-phrases",
  NON_KIND_MATERIAL = "non-kind-material",
}

export interface WordType {
  name: string; // "Property"
  value: string; // "quality/property"
}

export const GridVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const GridHorizontal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

interface Props {
  allowTags?: AllowTagType;
  alwaysTranslate?: boolean;
  attributeErrorSiteDistinction?: boolean;
  closeModal: () => void;
  customerLanguage: LanguageCode;
  description: string;
  englishTranslation: string;
  isOpenModal: boolean;
  isUsedInTagTreeEditor?: boolean;
  lexiconEditorDirection: DirectionProperty;
  onDescriptionInputChange: (value: string) => void;
  onEnglishTranslationInputChange: (value: string) => void;
  onHypernymChange: (hypernymData: VocabularyTreeData) => void;
  onLexiconDataChange: (value: any) => void;
  onShouldPopulateChange: (value: boolean) => void;
  onWordTypeChange?: (data: WordType | null) => void;
  populateTranslations: boolean;
  providedNewWord: string;
  providedWordType: string;
  shouldPopulate: boolean;
  showHypernym: boolean;
  showTranslate?: boolean;
  showMachineTranslate?: boolean;
  submitAction: (translateType: RequestWordTranslateType) => void;
  textualAppName: string;
  useLexiconEditor: boolean;
  onAddCopiedVocabulary?: (data: VocabularyTreeData) => void;
}

export const RequestWordModal: React.FC<Props> = ({
  allowTags = AllowTagType.ALL,
  alwaysTranslate,
  attributeErrorSiteDistinction,
  closeModal,
  customerLanguage,
  description,
  englishTranslation,
  isOpenModal,
  isUsedInTagTreeEditor,
  lexiconEditorDirection,
  onDescriptionInputChange,
  onEnglishTranslationInputChange,
  onHypernymChange,
  onLexiconDataChange,
  onShouldPopulateChange,
  onWordTypeChange,
  populateTranslations,
  providedNewWord,
  providedWordType,
  shouldPopulate,
  showHypernym,
  showTranslate = true,
  showMachineTranslate,
  submitAction,
  textualAppName,
  useLexiconEditor,
  onAddCopiedVocabulary,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [wordTypeName, setWordTypeName] = useState(
    dropdownOptions.find((item) => item.value === providedWordType)?.text
  );

  const [pickTranslationOption, setPickTranslationOption] = useState(false);

  const [similarVocabularies, setSimilarVocabularies] = useState<{
    exact: SimilarVocabulary[];
    contains: SimilarVocabulary[];
  }>({ exact: [], contains: [] });

  const hideSimilarVocabulariesButton = ![
    ...similarVocabularies.exact,
    ...similarVocabularies.contains,
  ].length;
  const [
    fetchingSimilarVocabularies,
    setFetchingSimilarVocabularies,
  ] = useState(false);
  const [showSimilarVocabularies, setShowSimilarVocabularies] = useState(false);

  const handleAddVocabulary = async (
    id: number,
    requestTranslation: boolean
  ): Promise<void> => {
    const vocabularyTreeData = await copyVocabularyToCustomer(
      token,
      id,
      requestTranslation
    );
    onAddCopiedVocabulary?.(vocabularyTreeData);
    store.dispatch(
      setDjangoToastOpen({
        appearance: NotificationAppearance.SUCCESS,
        content: "Word added to your vocabulary",
      })
    );
    closeModal();
  };

  const handleFetchSimilarVocabularies = async (
    mounted: boolean,
    contains: boolean = false,
    excludeVocabularyIds: number[] = [],
    limit: number = 10
  ): Promise<SimilarVocabulary[]> => {
    const key = contains ? "contains" : "exact";
    const [wordTagCategory, wordTagType] = providedWordType.split("/");
    const similarVocabularies = await getSimilarVocabularies(
      token,
      providedNewWord,
      wordTagType,
      wordTagCategory,
      limit,
      contains,
      excludeVocabularyIds
    ).finally(() => {
      if (mounted) setFetchingSimilarVocabularies(false);
    });

    if (mounted) {
      setSimilarVocabularies((prev) => ({
        ...prev,
        [key]: similarVocabularies,
      }));
    }

    return similarVocabularies;
  };

  useEffect(() => {
    let mounted = true;

    (async (): Promise<void> => {
      if (token && providedWordType) {
        setFetchingSimilarVocabularies(true);
        const similarVocabularies = await handleFetchSimilarVocabularies(
          mounted
        );
        let similarVocabulariesContains: SimilarVocabulary[] = [];
        if (similarVocabularies.length < 10) {
          const excludeIds = similarVocabularies.map(
            ({ vocabulary_id }) => vocabulary_id
          );
          similarVocabulariesContains = await handleFetchSimilarVocabularies(
            mounted,
            true,
            excludeIds,
            10 - similarVocabularies.length
          );
        }
        if (
          !similarVocabularies.length &&
          !similarVocabulariesContains.length
        ) {
          setShowSimilarVocabularies(false);
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, [token, providedWordType]);

  useEffect(() => {
    if (fetchingSimilarVocabularies && !showSimilarVocabularies) {
      setShowSimilarVocabularies(true);
    }
  }, [showSimilarVocabularies, fetchingSimilarVocabularies]);

  const handleClickNextButton = (): void => {
    if (showSimilarVocabularies) {
      setShowSimilarVocabularies(false);
      return;
    }
    if (!alwaysTranslate) {
      setPickTranslationOption(true);
      return;
    }
    // Skip picking translation type and just send it to the dashboard
    submitAction(RequestWordTranslateType.TRANSLATE);
  };
  return (
    <div data-testid={"requestwordmodal"}>
      <Modal open={isOpenModal} onClose={(): void => closeModal()}>
        <Modal.Header className="ui" as="h4">
          {wordTypeName && (
            <>
              Request new {wordTypeName}: {providedNewWord}
            </>
          )}
          {!wordTypeName && <>Request new word: {providedNewWord}</>}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {showSimilarVocabularies ? (
              <RequestWordModalSimilarVocabularies
                similarVocabularies={similarVocabularies}
                loading={fetchingSimilarVocabularies}
                handleAddVocabulary={handleAddVocabulary}
              />
            ) : (
              <>
                {!providedWordType && (
                  <RequestWordModalSamples
                    allowTags={allowTags}
                    attributeErrorSiteDistinction={
                      attributeErrorSiteDistinction
                    }
                    customerLanguage={customerLanguage}
                    isUsedInTagTreeEditor={isUsedInTagTreeEditor}
                    onSelectWordType={(data: WordType): void => {
                      setWordTypeName(data.name);
                      onWordTypeChange(data);
                    }}
                    providedNewWord={providedNewWord}
                  />
                )}
                {providedWordType && (
                  <>
                    {!pickTranslationOption ? (
                      <RequestWordModalWordDetails
                        allowTags={allowTags}
                        alwaysTranslate={alwaysTranslate}
                        customerLanguage={customerLanguage}
                        description={description}
                        englishTranslation={englishTranslation}
                        lexiconEditorDirection={lexiconEditorDirection}
                        onDescriptionInputChange={onDescriptionInputChange}
                        onEnglishTranslationInputChange={
                          onEnglishTranslationInputChange
                        }
                        onHypernymChange={onHypernymChange}
                        onLexiconDataChange={onLexiconDataChange}
                        onShouldPopulateChange={onShouldPopulateChange}
                        populateTranslations={populateTranslations}
                        providedNewWord={providedNewWord}
                        providedWordType={providedWordType}
                        shouldPopulate={shouldPopulate}
                        showHypernym={showHypernym}
                        showMachineTranslate={showMachineTranslate}
                        textualAppName={textualAppName}
                        useLexiconEditor={useLexiconEditor}
                      />
                    ) : (
                      <RequestWordModalPickTranslationType
                        submitAction={submitAction}
                        showTranslate={showTranslate}
                        showMachineTranslate={showMachineTranslate}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <RequestWordModalButtons
            alwaysTranslate={alwaysTranslate}
            isShowingSimilarVocabularies={showSimilarVocabularies}
            englishTranslation={englishTranslation}
            onClose={closeModal}
            onBack={(): void => {
              if (!pickTranslationOption) {
                setShowSimilarVocabularies(false);
                onWordTypeChange(null);
              } else {
                setPickTranslationOption(false);
              }
            }}
            showBackButton={!!providedWordType}
            showNextButton={!pickTranslationOption}
            handleClickNextButton={handleClickNextButton}
            similarVocabulariesButtonState={{
              show:
                !!providedWordType &&
                !showSimilarVocabularies &&
                !hideSimilarVocabulariesButton &&
                !pickTranslationOption,
              content: fetchingSimilarVocabularies
                ? "Finding similar known word(s)..."
                : "View similar known word(s)",
              handler: (show): void => {
                setShowSimilarVocabularies(show);
              },
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
