import { NotificationAppearance } from "../api/djangoToastSlice";
import { showMessage } from "../utils/partialUtils";

const BASE_SELECTOR = "[txu-toast]";

const CLICK_TRIGGER_SELECTOR = `${BASE_SELECTOR} [txu-toast-trigger="click"], ${BASE_SELECTOR}`;

const getToastMessage = (element: Element): string => {
  return element.getAttribute("txu-toast-message") || "";
};

const openToastOnClick = (element: Element | Document): void => {
  const toasts = element.querySelectorAll(CLICK_TRIGGER_SELECTOR);
  if (toasts) {
    toasts.forEach((toast) => {
      const message = getToastMessage(toast);
      if (!message) {
        return;
      }
      toast.addEventListener("click", () => {
        showMessage(NotificationAppearance.INFO, message);
      });
    });
  }
};

export const loadToastMessageHandlers = (element: Element | Document): void => {
  const toast = element.querySelector(BASE_SELECTOR);
  if (toast) {
    openToastOnClick(element);
  }
};
