export const BASE_SELECTOR = "[txu-handler]";

const BUTTON_TAG_SELECTOR = `button${BASE_SELECTOR}`;
export const setLoading = (element: Element): void => {
  element.classList.add("txu-loading");
};

export const loadButtonTagHandlers = (element: Element | Document): void => {
  const buttons = element.querySelectorAll(BUTTON_TAG_SELECTOR);
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      setLoading(button);
    });
  });
};
