import React from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 240px;
`;

type Props = {
  cancelMessage: string;
  confirmMessage: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmActionButtons: React.FC<Props> = ({
  cancelMessage,
  confirmMessage,
  onCancel,
  onConfirm,
}) => (
  <>
    <StyledButton
      type="button"
      className="pbutton pbutton-inverse"
      onClick={onCancel}
    >
      {cancelMessage}
    </StyledButton>
    <StyledButton
      type="button"
      className="pbutton pbutton-primary"
      onClick={onConfirm}
    >
      {confirmMessage}
    </StyledButton>
  </>
);
