import React, { useContext, useMemo, useState } from "react";
import { FittedDropdownAsMenuItem } from "./ToolbarTop";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import {
  Divider,
  Dropdown,
  DropdownItemProps,
  Segment,
} from "semantic-ui-react";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
import { AdvanceFilter, AdvancedFilterItemsWrapper } from "./AdvanceFilter";
import { Text } from "../../../components/Text";
import { PromptGroup } from "../types";
import { toolbarTopTestIds } from "../testUtils/testIdsSelectors";
import { fixLongName } from "../utils";

export const SelectPromptGroupDropdown: React.FC = () => {
  const { selectPromptGroup } = toolbarTopTestIds;
  const {
    groups: {
      list: groups,
      selected: selectedGroup,
      isLoading: fetchingGroups,
      availableTags,
    },
  } = useContext(CopyAssistantContext);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [filterTags, setFilterTags] = useState<string[]>([]);

  const filterGroupList = (list: PromptGroup[]): DropdownItemProps[] => {
    let searchResult = list.filter(
      ({ name }) => name.toLowerCase().search(search.toLowerCase()) != -1
    );

    filterTags.forEach((tag) => {
      searchResult = searchResult.filter((group) => group.tags.includes(tag));
    });
    return searchResult.map((group) => ({
      key: group.id,
      value: group.id,
      text: group.name,
    }));
  };

  const groupOptions = useMemo(() => filterGroupList(groups), [
    groups,
    search,
    filterTags,
  ]);

  const filteredAvailableTags = useMemo(
    () => availableTags.filter((tag) => tag !== "no tags"),
    [availableTags]
  );

  const handleSelectGroup = (id: number): void => {
    const group = groups.find((group) => group.id === id);
    if (group) {
      setSearch("");
      setOpen(false);
      dispatch({
        payload: group,
        type: CopyAssistantActionType.SELECT_GROUP,
      });
    }
  };

  const hideButton = filteredAvailableTags.length <= 0;

  return (
    <FittedDropdownAsMenuItem
      data-testid={selectPromptGroup.dropdown}
      floating
      upward={false}
      loading={fetchingGroups}
      disabled={fetchingGroups}
      value={selectedGroup?.id}
      text={selectedGroup ? fixLongName(selectedGroup.name) : "Select Group"}
      onClick={(): void => setOpen(!open)}
      onBlur={(): void => setOpen(false)}
      open={open}
    >
      <Dropdown.Menu
        onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
      >
        <AdvanceFilter
          search={search}
          setSearch={setSearch}
          placeholder="Search prompt group"
          hideButton={hideButton}
        >
          <Segment size="tiny" compact>
            <Text as="h4" compact>
              Filter labels
            </Text>
            <Divider />
            <AdvancedFilterItemsWrapper
              data-testid={selectPromptGroup.filterTags}
            >
              {filteredAvailableTags.map((tag) => (
                <TemplateLabelChip
                  classNames="fitted-template-label"
                  skipPredefinedClasses
                  key={tag}
                  content={tag}
                  description={""}
                  isSelected={filterTags.includes(tag)}
                  labelProps={{
                    as: "span",
                    size: "mini",
                    onClick: (): void => {
                      if (filterTags.includes(tag)) {
                        setFilterTags((prev) => prev.filter((t) => t !== tag));
                      } else {
                        setFilterTags((prev) => [...prev, tag]);
                      }
                    },
                  }}
                />
              ))}
            </AdvancedFilterItemsWrapper>
          </Segment>
        </AdvanceFilter>
        <Dropdown.Menu scrolling>
          {groupOptions.length === 0 && (
            <Dropdown.Item disabled>No groups found</Dropdown.Item>
          )}
          {groupOptions.map((option) => (
            <Dropdown.Item
              selected={option.value === selectedGroup?.id}
              key={option.key}
              value={option.value}
              onClick={(): void => handleSelectGroup(option.value as number)}
            >
              {fixLongName(option.text as string)}
              <div>
                {groups
                  .find((group) => option.value === group.id)
                  ?.tags.map((tag) => (
                    <TemplateLabelChip
                      classNames="fitted-template-label"
                      skipPredefinedClasses
                      key={tag}
                      content={tag}
                      description={""}
                      labelProps={{
                        as: "span",
                        size: "mini",
                      }}
                    />
                  ))}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </FittedDropdownAsMenuItem>
  );
};
