import React, { useEffect, useRef, useState } from "react";
import { ProductId } from "./product";
import { Button, Input } from "semantic-ui-react";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { getProduct, updateProductParent } from "../api/action";
import { BalloonDiv } from "../components/BalloonDiv";
import Styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../api/djangoToastSlice";

const StyledDiv = Styled.div`
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    > span {
      margin: 6px 10px;
    
      > label {
        font-size: 9pt;
        color: #666666;
        text-transform: uppercase;
      }
    }
  }
`;

const StyledInput = Styled(Input)`
  {
    width: 100%;
    flex-wrap: wrap;
  }
`;

type Props = {
  customerSlug: string;
  productId: ProductId;
};

export const ProductDetailEditParentButton: React.FC<Props> = ({
  customerSlug,
  productId,
}) => {
  // Store the external id of the parent product
  const [currentParentEntity, setCurrentParentEntity] = useState("");
  // Store the value entered by the user
  const [parentEntity, setParentEntity] = useState("");
  // Current product
  const [product, setProduct] = useState(null);
  const mounted = useRef(true);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    (async (): Promise<void> => {
      mounted.current = true;
      if (mounted.current) {
        const product = await getProduct(token, productId);
        const entity = product?.parent_external_id || product?.parent_id || "";
        setCurrentParentEntity(entity);
        setParentEntity(entity);
        setProduct(product);
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const onProductParentChange = async (value: string): Promise<void> => {
    if (!mounted.current) {
      return;
    }
    const response = await updateProductParent({
      parentProductEntity: value,
      productId: product.id,
      token,
    });
    const djangoMessage = {
      appearance: NotificationAppearance.SUCCESS,
      content: "",
    };
    if (response.result) {
      setProduct(response.product);
      setCurrentParentEntity(value);
      setParentEntity(value);
    } else {
      djangoMessage.appearance = NotificationAppearance.ERROR;
    }
    djangoMessage.content = response.message;
    store.dispatch(setDjangoToastOpen(djangoMessage));
  };

  // FIXME use microcopies for BaloonDiv value when available globally
  return (
    <StyledDiv data-testid={"product-detail-edit-parent-button"}>
      <BalloonDiv
        as={"span"}
        className={"label"}
        pos={"right"}
        value={"Parent of the current product"}
      >
        <label>Parent product</label>
      </BalloonDiv>
      <StyledInput
        fluid
        action
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          data: InputOnChangeData
        ): void => setParentEntity(data.value)}
        value={parentEntity || ""}
        placeholder="Enter parent SKU"
      >
        {currentParentEntity && (
          <BalloonDiv
            as={"span"}
            className={"label"}
            pos={"right"}
            value={"Go to parent product"}
          >
            <Button
              data-testid={"product-detail-edited-parent-button-go-to-parent"}
              icon="external"
              as={"a"}
              href={`/d/${customerSlug}/product/${product?.parent_id}?autologin=${token}`}
              data-subpage={"true"}
              target={"true"}
            />
          </BalloonDiv>
        )}
        <input />
        <Button
          as={"a"}
          content={currentParentEntity ? "Change parent" : "Add parent"}
          data-testid={
            "product-detail-edited-parent-button-parent-change-button"
          }
          disabled={parentEntity == currentParentEntity}
          onClick={(): Promise<void> => {
            if (!mounted.current) {
              return;
            }
            return onProductParentChange(parentEntity);
          }}
        />
        <Button
          as={"a"}
          content={"Remove"}
          data-testid={
            "product-detail-edited-parent-button-parent-remove-button"
          }
          disabled={!currentParentEntity}
          onClick={(): Promise<void> => {
            if (!mounted.current) {
              return;
            }
            return onProductParentChange("");
          }}
        />
      </StyledInput>
    </StyledDiv>
  );
};
