import React, { useRef } from "react";
import { List, Popup, Button } from "semantic-ui-react";
import { popupDelay } from "../../../customers/gpt/types";
import { Pipeline } from "./types";

type Props = {
  pipelines: Pipeline[];
  selectedPipeline?: Pipeline;
  handleSelectPipeline: (id: number, isCopying?: boolean) => void;
  handleCopyPipeline: (data: Pipeline) => void;
};
export const ListPipelines: React.FC<Props> = ({
  pipelines,
  selectedPipeline,
  handleSelectPipeline,
  handleCopyPipeline,
}) => {
  const copyClicked = useRef(false);
  return (
    <List selection divided verticalAlign="middle">
      {pipelines
        .filter(({ name }) => !!name)
        .map((pipeline) => (
          <List.Item
            data-testid={`manage-pipelines-list-item-${pipeline.id}`}
            key={pipeline.id}
            active={pipeline.id === selectedPipeline?.id}
            onClick={(): void => {
              if (copyClicked.current) {
                copyClicked.current = false;
                return;
              }
              handleSelectPipeline(pipeline.id);
            }}
            relaxed="very"
            style={{ display: "flex", alignItems: "center" }}
          >
            <List.Content style={{ paddingLeft: "0.5em" }}>
              <List.Header as="a" className="tw-no-underline">
                {pipeline.name} ({pipeline.id})
              </List.Header>
            </List.Content>
            <List.Content
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Popup
                size="tiny"
                content="Copy pipeline"
                position="bottom right"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Button
                    data-testid={`manage-pipelines-list-item-${pipeline.id}-copy`}
                    color="red"
                    basic
                    compact
                    size="mini"
                    icon="copy"
                    onClick={(): void => {
                      copyClicked.current = true;
                      handleCopyPipeline(pipeline);
                    }}
                  />
                }
              />
            </List.Content>
          </List.Item>
        ))}
    </List>
  );
};
