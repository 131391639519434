import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ChannelLanguagePair,
  ChannelLanguagePairData,
  ProductId,
} from "../product";
import { UntranslatedTextsModal } from "./UntranslatedTextsModal";
import { getProductTexts, RegenerateConfig } from "../../api/action";
import { getCustomerSettings } from "../../customers/customersettings";
import { TaskDispatcher } from "../../utils/TaskDispatcher";
import {
  TaskDispatcher2,
  TextGenerationRequest,
} from "../../utils/TaskDispatcher2";
import { OverwriteHeader, UserActionContext } from "./actionTypes";
import { useGetCustomerQuery } from "../../api/customerApi";
import { useSelector } from "react-redux";
import { RootState, store } from "../../utils/store";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../../api/djangoToastSlice";
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Modal,
  Popup,
  Transition,
} from "semantic-ui-react";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { Language, LanguageCode } from "../../customers/customerlanguages";
import { CustomerChannel } from "../../customers/Customer";
import {
  ChannelName,
  ChannelNameAndContentDiv,
} from "../translations-modal/TranslationsModalChannelProductTextItem";

import { ProductTextCountData } from "../regenerate-texts-modal/RegenerateTextsModalStateHandler";
import styled from "styled-components";
import { Text } from "../../components/Text";
import { formatDate } from "../../utils/dateUtils";
import { ProductTextsLanguageFlag } from "../ProductTextsLanguageFlag";
import {
  editedIconWithTooltip,
  getProductTextStatusIcon,
} from "../../producttext/ProductTextStatusIconHelper";
import {
  productDetailApi,
  useGetProductQuery,
} from "../../api/productDetailSlice";

import { compareStrings } from "../copy-assistant/utils";

const FlexButton = styled(Button)`
  && {
    display: inline-flex;
    padding-inline: 10px;
    align-items: center;
    gap: 15px;
    & > .flag-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      & > [data-flag] {
        transform: scale(1.5);
      }
    }
  }
`;

enum RegenerateConfigCheckBoxType {
  APPROVED = "approved",
  EDITED = "edited",
  PUBLISHED = "published",
}

const warnRegenerateConfigCheckBox: {
  [key in RegenerateConfigCheckBoxType]?: string;
} = {
  [RegenerateConfigCheckBoxType.EDITED]:
    "Re-generating manually edited texts will update the text to latest version but overwrite the changes made manually",
};

type Props = {
  productId: ProductId;
  disabled?: "true";
};

const filterOnSuccessAndFailedPairs = (
  productTexts: ProductText[],
  originalChannelLanguagePairs: ChannelLanguagePair[]
): { success: ChannelLanguagePair[]; failed: ChannelLanguagePair[] } => {
  const success = productTexts.map(
    (pt) => new ChannelLanguagePair(pt.languageCode, pt.customerChannelId)
  );
  const failed = originalChannelLanguagePairs.filter((clp) => {
    return !success.some((clpSuccess) => {
      return (
        clpSuccess.channel_id === clp.channel_id &&
        clpSuccess.language_code === clp.language_code
      );
    });
  });
  return { success, failed };
};

const tryToExtractErrorMessage = (errorString: string): string => {
  try {
    const json = JSON.parse(errorString);
    const message = json?.error?.message;
    return message || errorString;
  } catch (e) {
    return errorString;
  }
};

export const PublishButton: React.FC<Props> = ({ productId, disabled }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: product, isLoading: isProductLoading } = useGetProductQuery(
    productId
  );
  const { data: customer, isLoading } = useGetCustomerQuery();

  const [lastPublicationDate, setLastPublicationDate] = useState<Date>();
  // Modals props
  const [
    untranslatedTextsModalOpened,
    setUntranslatedTextsModalOpened,
  ] = useState(false);

  const [showDimmer, setShowDimmer] = useState(false);
  const [continueAnimationStep, setContinueAnimationStep] = useState(false);
  const [transition, setTransition] = useState(false);
  const [showStatusIcon, setShowStatusIcon] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [
    checkingMissingTranslations,
    setCheckingMissingTranslations,
  ] = useState(false);
  const [refetchProductTexts, setRefetchProductTexts] = useState(false);
  const [overwriteHeader, setOverwriteHeader] = useState<OverwriteHeader>(
    OverwriteHeader.NOT_SET
  );
  const [regenerateTextsModalOpened, setRegenerateTextsModalOpened] = useState(
    false
  );
  const [translatedTexts, setTranslatedTexts] = useState([]);
  const [untranslatedTexts, setUntranslatedTexts] = useState([]);

  const [regenerateConfig, setRegenerateConfig] = useState<RegenerateConfig>({
    regenerate: true,
    regenerateApprovedTexts: true,
    regenerateEditedTexts: false,
    regeneratePublishedTexts: true,
  });

  // Customer configuration
  const [settings, setSettings] = useState(null);

  const [
    publishChannelLanguagePairs,
    setPublishChannelLanguagePairs,
  ] = useState<{ pair: ChannelLanguagePair; selected: boolean }[]>([]);

  const visibleChannels = useMemo<CustomerChannel[]>(() => {
    if (!publishChannelLanguagePairs) return [];

    const visibleChannels: CustomerChannel[] = [];
    publishChannelLanguagePairs.forEach(({ pair }) => {
      const channel = customer
        .getVisibleChannels()
        .find((channel) => channel.id === pair.channel_id);
      if (channel && !visibleChannels.includes(channel)) {
        visibleChannels.push(channel);
      }
    });
    return visibleChannels;
  }, [publishChannelLanguagePairs]);

  const [
    checkingVisibleProductTexts,
    setCheckingVisibleProductTexts,
  ] = useState(false);
  const [visibleProductTexts, setVisibleProductTexts] = useState<ProductText[]>(
    []
  );

  const cleanProductChannelLanguagePairData = (): ChannelLanguagePairData[] => {
    const visibleChannels = customer.getVisibleChannels();
    const result: ChannelLanguagePairData[] = [];
    for (const pair of product.channel_language_pairs) {
      const isVisibleChannel = visibleChannels.find(
        (channel) => channel.id === pair.channel_id
      );
      const isActiveLanguage = customer.languages.find(
        (language) => language.code === pair.language_code
      );

      if (isVisibleChannel && isActiveLanguage) {
        result.push(pair);
      }
    }
    return result;
  };

  const cleanProductsChannelLanguagePairs = (): ChannelLanguagePair[] =>
    cleanProductChannelLanguagePairData().map(
      (pair) => new ChannelLanguagePair(pair.language_code, pair.channel_id)
    );

  const regenerateConfigCheckBoxes = useMemo(() => {
    if (!visibleProductTexts) return null;
    const result: ProductTextCountData = {
      approved: 0,
      needsReview: 0,
      published: 0,
      edited: 0,
      total: visibleProductTexts.length,
    };
    for (const text of visibleProductTexts) {
      if (text.isEdited) {
        result.edited += 1;
      } else if (text.published) {
        setLastPublicationDate((prev) => {
          const date = new Date(text.published);
          if (!prev || date > prev) return date;
          return prev;
        });
        result.published += 1;
      } else if (text.approved) {
        result.approved += 1;
      } else if (text.needsReview) {
        result.needsReview += 1;
      }
    }
    const checkBoxes: {
      type: RegenerateConfigCheckBoxType;
      key: keyof RegenerateConfig;
      count: number;
    }[] = [];
    if (result.edited > 0) {
      checkBoxes.push({
        type: RegenerateConfigCheckBoxType.EDITED,
        key: "regenerateEditedTexts",
        count: result.edited,
      });
    }
    return checkBoxes;
  }, [visibleProductTexts]);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      mounted.current &&
      token &&
      product &&
      publishChannelLanguagePairs.length &&
      (!visibleProductTexts.length || refetchProductTexts)
    ) {
      (async (): Promise<void> => {
        const channelLanguagePairs = publishChannelLanguagePairs.map(
          ({ pair }) => pair
        );
        setCheckingVisibleProductTexts(true);
        setVisibleProductTexts(
          await getProductTexts({
            token,
            productIds: [product.id],
            channelLanguagePairs,
            skipCreateExcludeList: true,
          })
        );
        setCheckingVisibleProductTexts(false);
        setRefetchProductTexts(false);
      })();
    }
  }, [product, token, publishChannelLanguagePairs, refetchProductTexts]);

  useEffect(() => {
    if (mounted.current && customer) {
      setSettings(getCustomerSettings(customer));
    }
  }, [customer]);

  const getMarkedForPublish = (
    language: Language,
    channel: CustomerChannel
  ): boolean => {
    const productTextRef = new ProductTextRef(
      product.id,
      channel.id,
      language.code
    );
    return productTextRef.markedForPublish(
      customer,
      cleanProductChannelLanguagePairData()
    );
  };

  const findActiveChannelLanguagePairs = (): void => {
    let active: ChannelLanguagePair[] = [];
    if (!product.channel_language_pairs.length) {
      customer.getVisibleChannels().forEach((channel) => {
        customer.languages.forEach((language) => {
          if (getMarkedForPublish(language, channel)) {
            active.push(new ChannelLanguagePair(language.code, channel.id));
          }
        });
      });
    } else {
      active = cleanProductsChannelLanguagePairs();
    }
    setPublishChannelLanguagePairs(
      active.map((pair) => ({ pair, selected: true }))
    );
  };

  useEffect(() => {
    if (product && customer) {
      findActiveChannelLanguagePairs();
    }
  }, [product, customer]);

  const collectChannelLanguagePairs = (): ChannelLanguagePair[] =>
    publishChannelLanguagePairs
      .filter(({ selected }) => selected)
      .map(({ pair }) => pair);

  const displayErrorNotification = (message: string): void => {
    const errorMessage = tryToExtractErrorMessage(message);
    store.dispatch(
      setDjangoToastOpen({
        content: "<strong>Error:</strong> " + errorMessage,
        appearance: NotificationAppearance.ERROR,
      })
    );
  };

  const publish = (channelLanguagePairs?: ChannelLanguagePair[]): void => {
    const dispatcher = new TaskDispatcher(token);
    setUntranslatedTextsModalOpened(false);
    setShowDimmer(true);

    if (continueAnimationStep || !settings?.generateOnPublish) {
      setTransition(true);
    }
    const channelLanguagePairsToPublish =
      channelLanguagePairs ?? collectChannelLanguagePairs();
    dispatcher
      .publishProduct(
        productId,
        UserActionContext.PRODUCT_DETAILS_PUBLISH,
        overwriteHeader,
        channelLanguagePairsToPublish
      )
      .then((response) => {
        const status = response.status;

        if (status !== "SUCCESS") {
          setHasError(true);
          displayErrorNotification(response.message);
        }
      })
      .catch((error) => {
        setHasError(true);
        displayErrorNotification(error);
      })
      .finally(() => {
        setTransition(false);
        store.dispatch(
          productDetailApi.util.invalidateTags([
            { type: "Product", id: productId },
          ])
        );
      });
  };

  const notifyAboutFailedPairs = (failedPairs: ChannelLanguagePair[]): void => {
    const failedMap: { [key: string]: string[] } = {}; // key: channel name, value: array: language short name

    failedPairs.forEach(({ channel_id, language_code }) => {
      const channel = customer.getChannelById(channel_id);
      const language = customer.getLanguageByCode(language_code);
      const channelName = channel?.display_name || channel_id.toString();
      const languageName = language?.short_name || language_code;
      if (!failedMap[channelName]) {
        failedMap[channelName] = [languageName];
      } else {
        failedMap[channelName].push(languageName);
      }
    });
    Object.entries(failedMap).forEach(([channelName, languages]) => {
      if (languages.length > 1) {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.WARNING,
            content: `Unable to generate texts for <strong>${languages.length}</strong> Languages in Channel <strong>${channelName}</strong>`,
            additionalContent:
              "Skipping publish for these Channel and Language Pairs.",
          })
        );
        return;
      }
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: `Unable to generate text for Language <strong>${languages[0]}</strong> in Channel <strong>${channelName}</strong>.`,
          additionalContent:
            "Skipping publish for this Channel and Language Pair.",
        })
      );
    });
  };

  const onGenerate = async (): Promise<void> => {
    setRegenerateTextsModalOpened(false);
    setCheckingMissingTranslations(true);
    setShowDimmer(true);
    const channelLanguagePairs = collectChannelLanguagePairs();
    const taskDispatcher = new TaskDispatcher2(token);

    const regenerateRequests = channelLanguagePairs.map(async (clp) => {
      const request = new TextGenerationRequest(
        clp.createProductTextRef(product.id),
        regenerateConfig,
        UserActionContext.PRODUCT_DETAILS_PUBLISH
      );
      return taskDispatcher.queue(request);
    });
    const settledProductTexts = await Promise.allSettled(regenerateRequests);

    const productTexts = settledProductTexts
      .filter((promise) => promise.status === "fulfilled")
      .map(({ value }: PromiseFulfilledResult<ProductText>) => value);

    if (!productTexts.length) {
      setCheckingMissingTranslations(false);
      setHasError(true);
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: "No channel was able to generate any texts.",
        })
      );
      return;
    }

    const {
      success: successfulPairs,
      failed: failedPairs,
    } = filterOnSuccessAndFailedPairs(productTexts, channelLanguagePairs);

    notifyAboutFailedPairs(failedPairs);

    setCheckingMissingTranslations(false);
    const translatedTexts = productTexts.filter(
      (text) => !text.missingTranslation
    );
    const untranslatedTexts = productTexts.filter(
      (text) => text.missingTranslation
    );

    if (untranslatedTexts.length > 0) {
      setShowDimmer(false);
      setContinueAnimationStep(true);
      setCheckingMissingTranslations(false);
      setTranslatedTexts(translatedTexts);
      setUntranslatedTexts(untranslatedTexts);
      setUntranslatedTextsModalOpened(true);
    } else {
      publish(successfulPairs);
    }
    // Set last published date to today
    setLastPublicationDate(new Date());
  };

  const onPublish = (): void => {
    if (settings?.generateOnPublish) {
      setRegenerateTextsModalOpened(true);
    } else {
      // Just publish texts
      publish();
    }
  };

  const handleChooseChannelLanguagePairs = (
    channelId: number,
    languageCode: LanguageCode
  ): void => {
    const newPublishChannelLanguagePairs = publishChannelLanguagePairs.map(
      ({ pair, selected }) => {
        if (
          pair.channel_id === channelId &&
          pair.language_code === languageCode
        )
          return { pair, selected: !selected };
        return { pair, selected };
      }
    );
    setPublishChannelLanguagePairs(newPublishChannelLanguagePairs);
  };

  const handleSelectAllInChannel = (channelId: number): void => {
    const newPublishChannelLanguagePairs = publishChannelLanguagePairs.map(
      ({ pair, selected }) => {
        if (pair.channel_id === channelId) return { pair, selected: true };
        return { pair, selected };
      }
    );
    setPublishChannelLanguagePairs(newPublishChannelLanguagePairs);
  };

  const allSelectedInChannel = (channelId: number): boolean => {
    const channelLanguagePairs = publishChannelLanguagePairs.filter(
      ({ pair }) => pair.channel_id === channelId
    );
    return channelLanguagePairs.every(({ selected }) => selected);
  };

  const handleDeselectAllInChannel = (channelId: number): void => {
    const newPublishChannelLanguagePairs = publishChannelLanguagePairs.map(
      ({ pair, selected }) => {
        if (pair.channel_id === channelId) return { pair, selected: false };
        return { pair, selected };
      }
    );
    setPublishChannelLanguagePairs(newPublishChannelLanguagePairs);
  };

  const canPublish = (): boolean => {
    return (
      publishChannelLanguagePairs.filter(({ selected }) => selected).length > 0
    );
  };

  const onOverwrite = (overwrite: boolean): void => {
    if (overwrite) {
      setOverwriteHeader(OverwriteHeader.DO_OVERWRITE);
    } else {
      setOverwriteHeader(OverwriteHeader.DONT_OVERWRITE);
    }
  };

  const loadingButton =
    isProductLoading ||
    isLoading ||
    !settings ||
    !publishChannelLanguagePairs.length;

  return (
    <>
      <Popup
        size="small"
        wide
        disabled={!disabled}
        position="bottom right"
        trigger={
          <span style={{ position: "relative", display: "block" }}>
            <Button
              basic
              className="custom-dropdown-like-button"
              size="tiny"
              icon={<Icon name="paper plane" color="blue" />}
              data-testid={"publish-button"}
              onClick={(): void => onPublish()}
              content={<b>Publish</b>}
              loading={loadingButton}
              disabled={loadingButton || !!disabled}
            />
            {!!lastPublicationDate && (
              <span
                style={{
                  position: "absolute",
                  bottom: "-15px",
                  left: "-20px",
                  width: "max-content",
                }}
              >
                <Text color="grey" size="tiny" compact>
                  Last publish {formatDate(lastPublicationDate, true)}
                </Text>
              </span>
            )}
          </span>
        }
      >
        <Popup.Content>
          No publish settings are configured. Check{" "}
          <strong>Publish Settings</strong> in the Manage view.
        </Popup.Content>
      </Popup>

      <Modal
        onMount={(): void => {
          setRefetchProductTexts(true);
        }}
        open={regenerateTextsModalOpened}
        onClose={(): void => setRegenerateTextsModalOpened(false)}
      >
        <Modal.Header>Select what languages to publish</Modal.Header>
        <Modal.Content>
          {visibleChannels.map((channel) => {
            const productTexts = visibleProductTexts.filter((text) => {
              return text.customerChannelId === channel.id;
            });
            const channelsLanguages = publishChannelLanguagePairs
              .filter(({ pair }) => pair.channel_id === channel.id)
              .sort((a, b) => {
                const code1 = a.pair.language_code;
                const code2 = b.pair.language_code;
                const name1 =
                  customer.getLanguageByCode(code1)?.short_name || code1;
                const name2 =
                  customer.getLanguageByCode(code2)?.short_name || code2;
                return compareStrings(name1, name2);
              });
            const allSelected = allSelectedInChannel(channel.id);
            return (
              <ChannelNameAndContentDiv key={channel.id}>
                <ChannelName>
                  {channel.display_name}{" "}
                  <Button
                    size="mini"
                    content={allSelected ? "Deselect all" : "Select all"}
                    color="red"
                    basic
                    compact
                    onClick={(): void =>
                      allSelected
                        ? handleDeselectAllInChannel(channel.id)
                        : handleSelectAllInChannel(channel.id)
                    }
                  />
                </ChannelName>
                <Divider hidden />
                {channelsLanguages.map(({ pair, selected }) => {
                  const language = customer.getLanguageByCode(
                    pair.language_code
                  );
                  const productText = productTexts.find(
                    (text) =>
                      text.languageCode === pair.language_code &&
                      text.customerChannelId === pair.channel_id
                  );
                  if (!productText && !checkingVisibleProductTexts) {
                    return null;
                  }

                  return (
                    <FlexButton
                      key={`${pair.channel_id}-${pair.language_code}`}
                      size="small"
                      basic
                      onClick={(): void => {
                        handleChooseChannelLanguagePairs(
                          pair.channel_id,
                          pair.language_code
                        );
                      }}
                      loading={checkingVisibleProductTexts}
                    >
                      {productText && (
                        <>
                          <Checkbox checked={selected} />
                          <div className="flag-wrapper">
                            <ProductTextsLanguageFlag
                              key={`${pair.channel_id}-${pair.language_code}-${productText?.id}}`}
                              language={language}
                              markedForPublish
                              disabled={!selected}
                            />
                            <div>
                              {productText?.isEdited && editedIconWithTooltip()}
                              {getProductTextStatusIcon(productText)}
                            </div>
                          </div>
                        </>
                      )}
                    </FlexButton>
                  );
                })}
                <Divider />
              </ChannelNameAndContentDiv>
            );
          })}

          {regenerateConfigCheckBoxes.map(({ type, key, count }) => (
            <Form.Field key={type}>
              <Checkbox
                checked={regenerateConfig[key]}
                label={
                  <label>
                    Also re-generate{" "}
                    <strong>
                      {count} {type} texts
                    </strong>
                  </label>
                }
                onChange={(): void => {
                  setRegenerateConfig({
                    ...regenerateConfig,
                    [key]: !regenerateConfig[key],
                  });
                }}
              />
              {!!warnRegenerateConfigCheckBox?.[type] && (
                <Text inline compact size="small" color="grey">
                  <Icon name="warning sign" color="yellow" />{" "}
                  {warnRegenerateConfigCheckBox[type]}
                </Text>
              )}
            </Form.Field>
          ))}
          {settings?.askForOverwrite && (
            <Form.Field>
              <Checkbox
                label={"Overwrite already published texts"}
                onChange={(
                  event: React.FormEvent<HTMLInputElement>,
                  { checked }
                ): void => onOverwrite(checked)}
              />
            </Form.Field>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            color="red"
            basic
            onClick={(): void => setRegenerateTextsModalOpened(false)}
          />
          <Button
            content="Publish"
            color="red"
            disabled={!canPublish()}
            onClick={(): Promise<void> => onGenerate()}
          />
        </Modal.Actions>
      </Modal>
      <UntranslatedTextsModal
        translatedTexts={translatedTexts}
        untranslatedTexts={untranslatedTexts}
        onClose={(): void => setUntranslatedTextsModalOpened(false)}
        onPublish={publish}
        open={untranslatedTextsModalOpened}
      />
      <Dimmer active={showDimmer} page>
        <Header as="h2" icon inverted>
          <Transition
            animation="fade"
            onHide={(): void => {
              if (!untranslatedTextsModalOpened) {
                !hasError ? setTransition(true) : setShowStatusIcon(true);
              }
            }}
            visible={checkingMissingTranslations}
            duration={{ hide: 1000, show: 200 }}
            reactKey="checking-missing-translations"
          >
            <span>
              <Icon name="circle notch" loading />
            </span>
          </Transition>
          <Transition
            onHide={(): void => setShowStatusIcon(true)}
            transitionOnMount
            animation="fade"
            duration={{ hide: 1000, show: 1000 }}
            visible={transition && !hasError}
            reactKey={transition ? "transition" : "no-transition"}
          >
            <span>
              <Icon name="paper plane" color="blue" />
              The product {product?.external_id} is being published...
            </span>
          </Transition>
          <Transition
            reactKey={
              showStatusIcon ? "show-status-icon" : "no-show-status-icon"
            }
            onHide={(): void => {
              setShowDimmer(false);
              setHasError(false);
            }}
            onShow={(): void => {
              setTimeout(() => {
                setShowStatusIcon(false);
              }, 500);
            }}
            animation="fade"
            duration={{ hide: 200, show: 1000 }}
            visible={showStatusIcon}
          >
            <span>
              <Icon
                {...(hasError
                  ? { name: "close", color: "red" }
                  : { name: "checkmark", color: "green" })}
              />
              {hasError ? "Error publishing product" : "Product published"}
            </span>
          </Transition>
        </Header>
      </Dimmer>
    </>
  );
};
