import React from "react";
import { Grid, Dropdown } from "semantic-ui-react";
export interface Option {
  key: number;
  text: string;
  value: string;
}
export interface Choice {
  label: string;
  value: string;
}

type Props = {
  allowAdditions: boolean;
  availableChoices: Choice[];
  disabled: boolean;
  componentId?: string | number;
  initialData: string[];
  onDataChanged: (component: MultipleSelectorField, data: string[]) => void;
  onLoaded?: (component: MultipleSelectorField) => void;
};

type State = {
  currentValues: string[];
  options: Option[];
};

export default class MultipleSelectorField extends React.Component<
  Props,
  State
> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentValues: [],
      options: [],
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    const { initialData, onLoaded, availableChoices } = this.props;
    onLoaded?.(this);

    const choices =
      availableChoices ||
      initialData.map((item) => {
        return { label: item, value: item };
      });
    const options =
      choices &&
      choices.length > 0 &&
      choices.map(({ label, value }, index) => {
        return { key: index, text: label, value: value };
      });
    this.setState({
      currentValues: initialData,
      options: options || [],
    });
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: Props): void {
    const { initialData } = this.props;
    if (prevProps.initialData !== initialData) {
      this.setState({
        currentValues: initialData,
      });
    }
  }

  handleLabelChange = (
    e: React.SyntheticEvent,
    { value: newValues }: any
  ): void => {
    if (!this.mounted) {
      return;
    }
    const { onDataChanged } = this.props;
    const { options } = this.state;
    if (newValues.length > 0) {
      const addedValue = newValues[newValues.length - 1];
      const matchedIndex = options.findIndex(
        (option) => option.value === addedValue
      );
      if (matchedIndex === -1 || options.length === 0) {
        options.push({
          key: newValues.length - 1,
          text: addedValue,
          value: addedValue,
        });
      }
    }

    this.setState(
      {
        currentValues: newValues,
        options: options,
      },
      () => {
        onDataChanged(this, newValues);
      }
    );
  };

  render(): React.ReactElement {
    const { componentId, disabled } = this.props;
    const { options, currentValues } = this.state;
    return (
      <Grid>
        <Dropdown
          data-testid="multiple-selector-field-dropdown"
          disabled={disabled}
          multiple
          id={componentId}
          options={options}
          placeholder="Type to search"
          search
          selection
          fluid
          allowAdditions
          value={currentValues}
          onChange={this.handleLabelChange}
          clearable
          selectOnBlur={false}
          closeOnBlur={true}
          closeOnChange
        />
      </Grid>
    );
  }
}
