import React, { useRef } from "react";
import { Dimmer, Header, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { ChannelId } from "../../customers/Customer";
import { ChannelLanguagePair, Product } from "../product";
import { ProductText, ProductTextRef } from "../../producttext/ProductText";
import { ProductTextAction } from "../../producttext/ProductTextAction";
import { TranslationsModalProductTexts } from "./TranslationsModalProductTexts";
import { LanguageCode } from "../../customers/customerlanguages";
import { TranslationsModalTextPatternsAndLanguages } from "./TranslationsModalTextPatternsAndLanguages";
import { MatrixRowType } from "../../customers/CustomerChannelLanguageMatrix";
import { Button } from "../../components/tailwind";

export const StyledModal = styled(Modal)`
  width: 1166px !important;
`;

const StyledH2 = styled.h2`
  margin-top: 0;
  text-align: center;
`;

export type TranslationModalFormData = {
  key: number;
  selectedCustomerChannelIds: ChannelId[];
  selectedLanguageCodes: LanguageCode[];
};

type Props = {
  channelLanguageCheckboxDisabled: boolean;
  isSaveSettingsButtonClicked: boolean;
  isTranslateButtonClicked: boolean;
  onButtonSaveSettingsClicked: (pairs?: ChannelLanguagePair[]) => void;
  onButtonTranslateClicked: () => void;
  onClose: () => void;
  onRequestProductTextAction: (
    action: ProductTextAction,
    productTextRef: ProductTextRef,
    productText?: ProductText,
    data?: string
  ) => Promise<ProductText>;
  opened: boolean;
  product: Product;
  productTexts: ProductText[];
  translationModalFormData: TranslationModalFormData[];
  isLoading?: boolean;
};

export const TranslationsModal: React.FC<Props> = ({
  channelLanguageCheckboxDisabled,
  isSaveSettingsButtonClicked,
  isTranslateButtonClicked,
  onButtonSaveSettingsClicked,
  onButtonTranslateClicked,
  onClose,
  onRequestProductTextAction,
  opened,
  product,
  productTexts,
  translationModalFormData,
  isLoading,
}) => {
  const getSelectedChannelLanguagePairs = (): ChannelLanguagePair[] => {
    const pairs: ChannelLanguagePair[] = [];
    channelLanguageMatrixRef.current.forEach((channelLanguage) => {
      channelLanguage.channel_details.forEach((channelDetails) => {
        if (channelDetails.selected) {
          pairs.push(
            new ChannelLanguagePair(
              channelLanguage.language_code,
              channelDetails.channel_id
            )
          );
        }
      });
    });
    return pairs;
  };

  const channelLanguageMatrixRef = useRef<MatrixRowType[]>([]);

  const onChangeChannelLanguageMatrixRef = (payload: MatrixRowType[]): void => {
    channelLanguageMatrixRef.current = payload;
  };
  return (
    <Dimmer.Dimmable dimmed={isLoading} as={StyledModal} open={opened}>
      <Modal.Header>Translations</Modal.Header>
      <Modal.Content scrolling>
        <StyledH2>Select language(s) per text pattern</StyledH2>
        <TranslationsModalTextPatternsAndLanguages
          channelLanguageCheckboxDisabled={channelLanguageCheckboxDisabled}
          translationModalFormData={translationModalFormData}
          onChangeChannelLanguageMatrixRef={onChangeChannelLanguageMatrixRef}
        />
        <TranslationsModalProductTexts
          onRequestProductTextAction={onRequestProductTextAction}
          product={product}
          productTexts={productTexts}
        />
      </Modal.Content>
      <Dimmer active={isLoading} inverted>
        <Header>
          <Loader active={isLoading} />
        </Header>
      </Dimmer>
      <Modal.Actions className="tw-flex tw-justify-end tw-gap-2">
        <Button
          variant="secondary-alt"
          content="Close"
          onClick={onClose}
          className="tw-mr-8"
        />
        <Button
          variant="primary"
          data-testid="translate"
          disabled={
            isSaveSettingsButtonClicked ||
            isTranslateButtonClicked ||
            translationModalFormData[0]?.selectedCustomerChannelIds.length ===
              0 ||
            translationModalFormData[0]?.selectedLanguageCodes.length === 0
          }
          onClick={onButtonTranslateClicked}
          loading={isTranslateButtonClicked}
        >
          Translate
        </Button>
        <Button
          variant="primary"
          data-testid="save-settings"
          disabled={isSaveSettingsButtonClicked || isTranslateButtonClicked}
          onClick={(): void =>
            onButtonSaveSettingsClicked(getSelectedChannelLanguagePairs())
          }
          loading={isSaveSettingsButtonClicked}
        >
          Save setting for this product
        </Button>
      </Modal.Actions>
    </Dimmer.Dimmable>
  );
};
