import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { List, Popup } from "semantic-ui-react";
import styled from "styled-components";

import {
  generateRequestWordSamples,
  GenerateRequestWordSamplesResponseItem,
} from "../../api/vocabularyApi";
import { HTML } from "../../components/HTML";
import { LanguageCode } from "../../customers/customerlanguages";
import { Spinner } from "../../utils/Spinner";
import { RootState } from "../../utils/store";
import { WordType } from "./RequestWordModal";
import {
  RequestWordModalWordType,
  disallowedKindMaterialOptions,
  disallowedFixedPhraseOptions,
} from "./RequestWordModalWordType";
import { AllowTagType } from "./RequestWordModal";

const shouldBeDisabled = (type: string, allowTags: AllowTagType): boolean => {
  const isKindMaterialAndNotInDisallowedArray =
    allowTags === AllowTagType.KIND_MATERIAL &&
    !disallowedKindMaterialOptions.includes(type);

  const IsNotKindMaterialAndIsInDisallowedArray =
    allowTags === AllowTagType.NON_KIND_MATERIAL &&
    disallowedKindMaterialOptions.includes(type);

  const IsNotFixedPhrasesAndInDisallowedArray =
    allowTags === AllowTagType.NON_FIXED_PHRASES &&
    disallowedFixedPhraseOptions.includes(type);

  return (
    isKindMaterialAndNotInDisallowedArray ||
    IsNotKindMaterialAndIsInDisallowedArray ||
    IsNotFixedPhrasesAndInDisallowedArray
  );
};
const HelpText = styled.p`
  & {
    margin-top: 0;
  }
`;
const ListLabel = styled.div`
  & {
    width: 9em;
    display: table-cell;
    color: black;
    font-weight: 600;
    padding: 0.2em 0;
  }
`;

type Props = {
  allowTags: AllowTagType;
  attributeErrorSiteDistinction?: boolean;
  customerLanguage: LanguageCode;
  isUsedInTagTreeEditor: boolean;
  onSelectWordType: (data: WordType | null) => void;
  providedNewWord: string;
};

export const RequestWordModalSamples: React.FC<Props> = ({
  allowTags,
  attributeErrorSiteDistinction,
  customerLanguage,
  onSelectWordType,
  isUsedInTagTreeEditor,
  providedNewWord,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [samples, setSamples] = useState(null);
  const [fallbackDropdown, setFallbackDropdown] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    setFallbackDropdown(false);
    (async (): Promise<void> => {
      mounted.current = true;
      const samples = await generateSamples();
      if (mounted.current) {
        setSamples(samples);
      }
    })();
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const generateSamples = async (): Promise<
    GenerateRequestWordSamplesResponseItem[]
  > => {
    try {
      const samplesData = await generateRequestWordSamples({
        base_form: providedNewWord,
        languageCode: customerLanguage,
        token,
      });
      return samplesData;
    } catch (err) {
      setFallbackDropdown(true);
    }
  };

  const renderSample = (
    item: GenerateRequestWordSamplesResponseItem
  ): React.ReactElement => {
    if (shouldBeDisabled(item.type, allowTags)) {
      return (
        <Popup
          basic
          content="This word type cannot be added here."
          size="tiny"
          trigger={
            <List.Item key={item.name} style={{ opacity: 0.5 }}>
              <ListLabel>{item.name}</ListLabel>
              <List.Content style={{ display: "table-cell" }}>
                <List.Description>
                  <HTML
                    text={item.sample.replace(
                      `<span class="notranslate" data-tag-value="${providedNewWord}">${providedNewWord}</span>`,
                      `<span class="ui text red">${providedNewWord}</span>`
                    )}
                  />
                </List.Description>
              </List.Content>
            </List.Item>
          }
        />
      );
    }
    return (
      <List.Item
        key={item.name}
        onClick={(): void =>
          onSelectWordType({
            name: item.name,
            value: item.type,
          })
        }
      >
        <ListLabel>{item.name}</ListLabel>
        <List.Content style={{ display: "table-cell" }}>
          <List.Description>
            <HTML
              text={item.sample.replace(
                `<span class="notranslate" data-tag-value="${providedNewWord}">${providedNewWord}</span>`,
                `<span class="ui text red">${providedNewWord}</span>`
              )}
            />
          </List.Description>
        </List.Content>
      </List.Item>
    );
  };

  if (fallbackDropdown) {
    return (
      <div>
        <HelpText>
          Please choose the type for your new word by selecting from the
          dropdown below
        </HelpText>
        <RequestWordModalWordType
          allowTags={allowTags}
          attributeErrorSiteDistinction={attributeErrorSiteDistinction}
          isUsedInTagTreeEditor={isUsedInTagTreeEditor}
          onWordTypeChange={onSelectWordType}
          providedWordType={""}
        />
      </div>
    );
  }
  if (samples)
    return (
      <div>
        <HelpText>
          Choose the type for your new word by selecting the sentence below that
          fits best:
        </HelpText>
        <List selection divided data-testid={"sample-sentences-list"}>
          {samples.map((item: GenerateRequestWordSamplesResponseItem) =>
            renderSample(item)
          )}
        </List>
      </div>
    );
  else return <Spinner />;
};
