import React from "react";
import { SectionActionRequest, SectionDetails } from "../../api/sectionActions";
import { Draggable } from "react-beautiful-dnd";
import { VocabularyTreeData } from "../../utils/tagutils";
import styled from "styled-components";
import { TagTreeEditorComp } from "../../vocabulary/TagTreeEditor";

// custom styling for html elements generated by SortableTree
const VocabularySectionItemDiv = styled.div`
  & .rst__tree {
    flex-grow: 1;
  }
  & .rstcustom__node {
    white-space: inherit;
  }
  & .rstcustom__nodeContent {
    width: 90%;
  }
  & .rstcustom__row {
    padding-left: 30px !important;
    white-space: inherit;
  }
  & .rstcustom__rowContents {
    width: 100%;
  }
`;
const StyledDiv = styled.div`
  & {
    padding-top: 26px;
  }
`;

type Props = {
  data: VocabularyTreeData[];
  index: number;
  onSectionItemAction: (request: SectionActionRequest) => Promise<void>;
  section: SectionDetails;
};

// FIXME add full tests
/* istanbul ignore next */
export const VocabularySectionItem: React.FC<Props> = ({
  data,
  index,
  onSectionItemAction,
}) => {
  const draggableId = data[0]._id;
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided): React.ReactElement => (
        <VocabularySectionItemDiv
          className="vocabulary-item"
          ref={provided.innerRef.bind(provided)}
          {...provided.draggableProps}
        >
          <StyledDiv key={index} {...provided.dragHandleProps}>
            <i className={"material-icons"}>drag_indicator</i>
          </StyledDiv>
          <TagTreeEditorComp
            initialData={data}
            disableDrag
            expandedWhenEmpty={true}
            limit={10}
            manageWithClick={true}
            onSectionItemAction={onSectionItemAction}
            small={true}
          />
        </VocabularySectionItemDiv>
      )}
    </Draggable>
  );
};
