import React, { useEffect, useState } from "react";
import {
  LanguageCode,
  SUICustomerLanguageDropdown,
} from "../customers/customerlanguages";
import { Form, Icon, Popup } from "semantic-ui-react";
import { FindAndReplaceInputData } from "./FindAndReplaceModal";
import { popupDelay } from "../customers/gpt/types";
type Props = {
  pos: number;
  handleRemoveInput: (pos: number) => void;
  isRemovable: boolean;
  collectData: (inputData: FindAndReplaceInputData) => void;
  removeInputData: (pos: number) => void;
  initialState?: FindAndReplaceInputData;
  prevSelectedLanguage?: LanguageCode;
};
export const FindAndReplaceFormInput: React.FC<Props> = ({
  pos,
  handleRemoveInput,
  isRemovable,
  collectData,
  removeInputData,
  initialState,
  prevSelectedLanguage,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
    initialState?.language || prevSelectedLanguage
  );
  const [replace, setReplace] = useState(initialState?.replace || "");
  const [find, setFind] = useState(initialState?.find || "");

  useEffect(() => {
    if (selectedLanguage && replace && find)
      collectData({ language: selectedLanguage, replace, find, pos });
    else removeInputData(pos);
  }, [selectedLanguage, replace, find]);

  return (
    <Form.Group widths="equal">
      <Form.Input
        label="Find:"
        placeholder="e.g, Couch"
        value={find}
        data-testid={`find-and-replace-input-find-${pos}`}
        onChange={(_, { value }): void => setFind(value)}
      />
      <Form.Input
        label="Replace:"
        placeholder="e.g, Sofa"
        value={replace}
        data-testid={`find-and-replace-input-replace-${pos}`}
        onChange={(_, { value }): void => setReplace(value)}
      />
      <Form.Field>
        <label>For language:</label>
        <SUICustomerLanguageDropdown
          data-testid={`find-and-replace-input-language-selector-${pos}`}
          fluid
          onChange={(_, { value }): void =>
            setSelectedLanguage(value as LanguageCode)
          }
          placeholder="Select language"
          selection
          value={selectedLanguage}
        />
      </Form.Field>
      {isRemovable && (
        <Popup
          size="tiny"
          content="Remove Replacement"
          mouseEnterDelay={popupDelay}
          position="top right"
          basic
          trigger={
            <Icon
              data-testid={`find-and-replace-input-${pos}-remove`}
              name="close"
              link
              onClick={(): void => handleRemoveInput(pos)}
            />
          }
        />
      )}
    </Form.Group>
  );
};
