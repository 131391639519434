import React, { useMemo, useRef, useState } from "react";
import {
  Segment,
  Divider,
  Dropdown,
  Checkbox,
  Form,
  Popup,
  Button,
  Icon,
  Loader,
} from "semantic-ui-react";
import { Text } from "../../../components/Text";
import styled from "styled-components";
import { illustrations } from "../../../planner/document-structure/illustrations";
import {
  GenerateSectionInfo,
  GenerationSetting,
  ValueOfGenerationSettings,
} from "./GenerateAITexts";
import { GenerationSummaryPopup } from "./GenerationSummaryPopup";
import { popupDelay } from "../../../customers/gpt/types";
import { findLanguage, fixLongName } from "../../copy-assistant/utils";
import { Prompt } from "../../copy-assistant/types";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { Flag } from "../../../customers/customerlanguages";

const StyledSegment = styled(Segment)`
  && {
    position: relative;
    margin: 0;
    width: 500px;
    transition: all 0.3s ease-in-out;
    &.inactive:hover {
      transform: scale(1.04, 1.02);
      z-index: 1;
      cursor: pointer;
    }
  }
`;

const IllustrationWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 15px;
  width: 100px;
  scale: 0.7;
  &.bullets {
    top: 40px;
  }
  &.body {
    top: 40px;
  }
`;

const AbsoluteButtonMiddle = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CardHeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  section: GenerateSectionInfo;
  active: boolean;
  selectedPromptId: number | undefined;
  textBlockCount: { total: number; empty: number };
  prompts: Prompt[];
  translate: boolean;
  inBulkActionContext: boolean;
  handleUpdateGenerationSettings: (
    key: keyof GenerationSetting,
    value: ValueOfGenerationSettings,
    sectionId: number
  ) => void;
  handleAddGenerationSetting: (sectionId: number) => void;
  handleRemoveGenerationSetting: (sectionId: number) => void;
};
export const SectionCard: React.FC<Props> = ({
  section,
  active,
  selectedPromptId,
  textBlockCount: { total, empty },
  prompts,
  translate,
  inBulkActionContext,
  handleUpdateGenerationSettings,
  handleAddGenerationSetting,
  handleRemoveGenerationSetting,
}) => {
  const [enablePopup, setEnablePopup] = useState<boolean>(false);

  const { data: customer } = useGetCustomerQuery();

  const contextRef = useRef(null);

  const relatedPromptOptions = useMemo(() => {
    if (!customer || !prompts) return [];
    return prompts
      .filter((prompt) => prompt.document_section_id === section.id)
      .map((prompt) => ({
        key: prompt.id,
        value: prompt.id,
        text: (
          <>
            <Flag content={findLanguage(prompt, customer)?.flag} />{" "}
            {prompt.name}
          </>
        ),
      }));
  }, [prompts, section, customer]);

  const customerPromptOptions = useMemo(() => {
    if (!customer || !prompts) return [];
    return prompts
      .filter(
        (prompt) =>
          !relatedPromptOptions.some((option) => option.value === prompt.id)
      )
      .filter((prompt) => prompt.customer_id)
      .map((prompt) => ({
        key: prompt.id,
        value: prompt.id,
        text: (
          <>
            <Flag content={findLanguage(prompt, customer)?.flag} />{" "}
            {prompt.name}
          </>
        ),
      }));
  }, [prompts, relatedPromptOptions, customer]);

  const systemPromptOptions = useMemo(() => {
    if (!customer || !prompts) return [];
    return prompts
      .filter(
        (prompt) =>
          !relatedPromptOptions.some((option) => option.value === prompt.id)
      )
      .filter((prompt) => !prompt.customer_id)
      .map((prompt) => ({
        key: prompt.id,
        value: prompt.id,
        text: (
          <>
            <Flag content={findLanguage(prompt, customer)?.flag} />{" "}
            {prompt.name}
          </>
        ),
      }));
  }, [prompts, relatedPromptOptions, customer]);

  const fullName = useMemo(() => {
    if (!selectedPromptId || !prompts.length) return undefined;
    const prompt = prompts.find((prompt) => prompt.id === selectedPromptId);
    if (!prompt) return undefined;
    return (
      <>
        <Flag content={findLanguage(prompt, customer)?.flag} />
        {prompt.name}
      </>
    );
  }, [selectedPromptId, prompts, customer]);

  const shortName = useMemo(() => {
    if (!selectedPromptId || !prompts.length) return undefined;
    setEnablePopup(false);
    const prompt = prompts.find((prompt) => prompt.id === selectedPromptId);
    if (!prompt) return undefined;

    if (prompt.name.length > 50) {
      setEnablePopup(true);
    }
    return `${findLanguage(prompt, customer)?.flag} ${fixLongName(
      prompt.name
    )}`;
  }, [selectedPromptId, prompts, customer]);

  const visibility = useMemo(() => {
    return active ? "visible" : "hidden";
  }, [active]);

  const handleSelectFallbackPrompt = (
    id?: number,
    name?: React.ReactNode
  ): void => {
    handleUpdateGenerationSettings(
      "fallbackPrompt",
      {
        id: id,
        name: name,
      },
      section.id
    );
  };

  return (
    <div ref={contextRef}>
      <StyledSegment
        data-testid={`section-card-section-${visibility}-${section.name}`}
        compact
        raised={!active}
        color={active ? "red" : undefined}
        className={active ? "active" : "inactive"}
        onClick={(): void => !active && handleAddGenerationSetting(section.id)}
      >
        <CardHeaderStyle>
          <Popup
            wide
            mouseEnterDelay={popupDelay}
            hoverable
            flowing
            size="small"
            context={contextRef}
            disabled={!active || !inBulkActionContext}
            position="top center"
            trigger={
              <div data-testid={`section-card-hover-more-info-${section.name}`}>
                {active && inBulkActionContext && <Icon name="info circle" />}
                <Text as="h5" compact inline>
                  {section.name}
                </Text>
              </div>
            }
          >
            <GenerationSummaryPopup
              generationSetting={{
                sectionName: section.name,
                sectionId: section.id,
                fallbackPrompt: {
                  id: selectedPromptId || undefined,
                  name: fullName,
                },
                translate,
              }}
              total={total}
              empty={empty}
            />
          </Popup>
          {inBulkActionContext && (
            <Text
              compact
              color="grey"
              size="small"
              testId={`section-card-existing-texts-${section.name}`}
            >
              Existing texts:{" "}
              {total === null ? (
                <Loader active as="span" inline size="tiny" />
              ) : (
                total
              )}
            </Text>
          )}
        </CardHeaderStyle>
        <Divider />
        <IllustrationWrapper className={section.illustration_type}>
          {illustrations[section.illustration_type]}
        </IllustrationWrapper>
        <Form as="div" size="tiny" style={{ visibility: visibility }}>
          <Button
            data-testid={`section-card-deselect-${section.name}`}
            content="Deselect"
            color="red"
            basic
            compact
            size="tiny"
            onClick={(e): void => {
              e.preventDefault();
              handleRemoveGenerationSetting(section.id);
            }}
          />
          <Text size="small">Generation settings:</Text>
          <div
            style={{
              border: "1px solid rgba(34, 36, 38, 0.15)",
              borderRadius: "0.2em",
              padding: "10px",
            }}
          >
            <Popup
              disabled={!enablePopup}
              content={fullName}
              size="small"
              wide="very"
              trigger={
                <Form.Field>
                  <Text size="small" compact>
                    Fallback Prompt:
                  </Text>
                  <Dropdown
                    data-testid={`section-card-fallback-prompt-dropdown-${section.name}`}
                    clearable={!!selectedPromptId}
                    className="selection"
                    value={selectedPromptId}
                    text={shortName || "Select Prompt"}
                    compact
                    placeholder={"Select Prompt"}
                    onChange={(): void => {
                      // This deselects the selected prompt
                      handleSelectFallbackPrompt(undefined, undefined);
                    }}
                  >
                    <Dropdown.Menu>
                      {relatedPromptOptions.length > 0 && (
                        <>
                          <Dropdown.Header content="Prompts related to Section" />
                          <Dropdown.Divider />
                          {relatedPromptOptions.map((option) => (
                            <Dropdown.Item
                              selected={option.value === selectedPromptId}
                              key={option.key}
                              {...option}
                              onClick={(): void =>
                                handleSelectFallbackPrompt(
                                  option.value,
                                  option.text
                                )
                              }
                            />
                          ))}
                        </>
                      )}
                      {customerPromptOptions.length > 0 && (
                        <>
                          <Dropdown.Header content="My Prompts" />
                          <Dropdown.Divider />
                          {customerPromptOptions.map((option) => (
                            <Dropdown.Item
                              selected={option.value === selectedPromptId}
                              key={option.key}
                              {...option}
                              onClick={(): void =>
                                handleSelectFallbackPrompt(
                                  option.value,
                                  option.text
                                )
                              }
                            />
                          ))}
                        </>
                      )}
                      {systemPromptOptions.length > 0 && (
                        <>
                          <Dropdown.Header content="System Prompts" />
                          <Dropdown.Divider />
                          {systemPromptOptions.map((option) => (
                            <Dropdown.Item
                              selected={option.value === selectedPromptId}
                              key={option.key}
                              {...option}
                              onClick={(): void =>
                                handleSelectFallbackPrompt(
                                  option.value,
                                  option.text
                                )
                              }
                            />
                          ))}
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Field>
              }
            />
            <Divider hidden />
            <Form.Field>
              <Checkbox
                data-testid={`section-card-translate-${section.name}`}
                label="Translate to all Languages"
                style={{ fontSize: "small" }}
                checked={translate}
                onChange={(e, { checked }): void =>
                  handleUpdateGenerationSettings(
                    "translate",
                    checked,
                    section.id
                  )
                }
              />
            </Form.Field>
          </div>
          {inBulkActionContext && (
            <Text
              size="small"
              color="grey"
              testId={`section-card-section-empty-${section.name}`}
            >
              Section empty on{" "}
              {empty === null ? (
                <Loader active as="span" inline size="tiny" />
              ) : (
                empty
              )}{" "}
              products
            </Text>
          )}
        </Form>
        {!active && (
          <AbsoluteButtonMiddle>
            <Button
              fluid
              content="Select for generation"
              color="red"
              basic
              size="small"
            />
          </AbsoluteButtonMiddle>
        )}
      </StyledSegment>
    </div>
  );
};
