import { BalloonDiv } from "../../components/BalloonDiv";
import React, { useEffect, useState } from "react";
import { ProductDetailInfo } from "./ProductDetailInfo";
import { ProductTagAction } from "../../api/action";
import { onTagsActionCallback } from "./ProductDetailEditTab";
import { SidebarItem } from "./SidebarItem";
import styled from "styled-components";

interface Props {
  details: ProductDetailInfo;
  onTagsAction: onTagsActionCallback;
  selected: SidebarItem;
}

const StyledInput = styled.input`
  padding: 11px 10px;
  width: 100%;
  font-size: 11pt;
  display: block;
`;

const InputContainer = styled.div`
  width: 100%;
  flex: 1 1 300px;
  padding: 0 10px;
  margin: 10px 0;
`;

export const UntranslatedNameField: React.FC<Props> = ({
  details,
  onTagsAction,
  selected,
}) => {
  const tags = selected.tags || [];
  const nameTag = tags.find(
    (tag) => tag.category === "identifier" && tag.type === "name"
  );
  const nameValue = nameTag?.value || "";

  const [name, setName] = useState(nameValue);
  useEffect(() => {
    setName(nameValue);
  }, [selected]);

  function updateTag(name: string): void {
    onTagsAction({
      action: ProductTagAction.ADD_UNTRANSLATED_NAME,
      parent: selected.id,
      value: name,
    });
  }

  return (
    <InputContainer>
      <BalloonDiv
        className={"label"}
        value={details.microcopies["text_untranslated_name"]}
      >
        <label htmlFor="id_untranslated_name">Untranslated name</label>
      </BalloonDiv>
      <StyledInput
        id="id_untranslated_name"
        type="name"
        name="name"
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
          setName(event.target.value)
        }
        onBlur={(): void => updateTag(name)}
      />
    </InputContainer>
  );
};
