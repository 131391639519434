// Request Text Generation jobs
import { fetchTaskResults, productTextAction } from "../api/action";
import { ProductText } from "../producttext/ProductText";
import { ProductTextAction } from "../producttext/ProductTextAction";
import { ChannelLanguagePairData, ProductId } from "../products/product";
import { publishProductTexts } from "../products/publish/publishActions";
import {
  OverwriteHeader,
  UserActionContext,
} from "../products/publish/actionTypes";

import { TaskResult, TaskResultType } from "./types";
import { debounce } from "./debounce";

/*
 * This is how often we should poll text generation results from the server.
 * May need some tweaking for different customers as the amount of tags and templates
 * on a product will determine how long time it takes to generate a text
 */
const POLL_RESULT_FREQUENCY_IN_MS = 1000;

/*
 * This is how often we should submit incoming requests, the idea is that we should
 * submit most of the requests made for the same list together.
 */
const QUEUE_FREQUENCY_IN_MS = 1000;

/*
 * This is a helper to fetch text generation asynchronously
 *
 * There are two main responsibilities:
 * - requesting text generation
 * - polling for results
 *
 * both of them are debounced, meaning that we'll wait a little bit
 * before sending the requests to be able to group them together.
 *
 * An important part of the design is to avoid blocking
 * both the browser and the webserver, the actually processing
 * happens in celery. That means we have to poll for the results.
 */

export type ProductPublishTaskResult = {
  type: TaskResultType.PUBLISH;
  message: string;
  product_texts: ProductText[] | null;
};

export type PublishStatus = {
  message: string;
  status: string;
  productTexts?: ProductText[];
};

abstract class AsyncRequest {
  resultType: TaskResultType;
  promise: Promise<unknown>;
  resolve?: (status: unknown) => void;
  reject?: (error: string) => void;
}

export class PublishRequest extends AsyncRequest {
  resultType = TaskResultType.PUBLISH;
  productId: ProductId;
  userActionContext: UserActionContext;
  overwriteHeader: OverwriteHeader;
  channelLanguagePairs: ChannelLanguagePairData[] | null;

  promise: Promise<PublishStatus>;
  resolve: (status: PublishStatus) => void;
  reject: (error: string) => void;

  constructor(
    productId: ProductId,
    userActionContext: UserActionContext,
    overwriteHeader: OverwriteHeader,
    channelLanguagePairs: ChannelLanguagePairData[] | null
  ) {
    super();
    this.productId = productId;
    this.userActionContext = userActionContext;
    this.overwriteHeader = overwriteHeader;
    this.channelLanguagePairs = channelLanguagePairs;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  getApiParams(): null {
    return null;
  }
}

export class TaskDispatcher {
  private readonly token: string;
  private readonly _eventuallyQueueRequests: () => void;
  private readonly _eventuallyPollResults: () => void;
  _outgoingRequests: AsyncRequest[] = [];
  private _incomingRequestsByTaskId: Record<string, AsyncRequest> = {};
  private _retries = 0;

  constructor(token: string) {
    this.token = token;
    this._eventuallyQueueRequests = debounce(
      this._queueRequests.bind(this),
      QUEUE_FREQUENCY_IN_MS
    );
    this._eventuallyPollResults = debounce(
      this._pollResults.bind(this),
      POLL_RESULT_FREQUENCY_IN_MS
    );
  }

  publishProduct(
    productId: ProductId,
    userActionContext: UserActionContext,
    overwriteHeader: OverwriteHeader,
    channelLanguagePairs: ChannelLanguagePairData[] | null
  ): Promise<PublishStatus> {
    const request = new PublishRequest(
      productId,
      userActionContext,
      overwriteHeader,
      channelLanguagePairs
    );
    this._outgoingRequests.push(request);
    this._eventuallyQueueRequests();
    this._retries = 0;
    return request.promise;
  }

  // This is mainly kept here so we don't have to pass around a token everywhere.
  productTextAction(
    actionContext: UserActionContext,
    productTextIds: number[],
    action: ProductTextAction,
    data: string = null
  ): Promise<ProductText[]> {
    return productTextAction(
      this.token,
      actionContext,
      productTextIds,
      action,
      data
    );
  }

  clear(): void {
    this._outgoingRequests = [];
    this._incomingRequestsByTaskId = {};
    this._retries = 0;
  }

  /*
   * We have now waited a little bit and we are ready to submit the
   * requests that are sitting in the _pendingRequests queue
   */
  _queueRequests(): void {
    /*
     * Submit a text generation job,
     * This will return pretty quickly as it's not doing the actual generation work yet
     * console.debug("queueTextGeneration", generationRequests);
     */

    const requests = this._outgoingRequests.splice(0, 5);
    if (requests.length === 0) {
      return;
    }
    const publishGenerationRequests: PublishRequest[] = [];

    for (const request of requests) {
      if (request.resultType == TaskResultType.PUBLISH) {
        publishGenerationRequests.push(request as PublishRequest);
      }
    }
    // TODO handle case for multiple publish requests
    if (publishGenerationRequests.length === 1) {
      const request = publishGenerationRequests[0];
      publishProductTexts(
        this.token,
        request.userActionContext,
        request.productId,
        request.overwriteHeader,
        request.channelLanguagePairs
      ).then((response) => {
        const taskId = response.task_id;
        this._incomingRequestsByTaskId[taskId] = request;
        this._eventuallyPollResults();
      });
    }
    if (this._outgoingRequests.length > 0) {
      this._eventuallyQueueRequests();
    }
  }

  _incomingPending(): boolean {
    return Object.keys(this._incomingRequestsByTaskId).length > 0;
  }

  _processTaskResult(taskId: string, taskResult: TaskResult): void {
    const request = this._incomingRequestsByTaskId[taskId];
    if (taskResult.status === "SUCCESS") {
      const { results } = taskResult as { results: ProductPublishTaskResult };
      switch (request.resultType) {
        case TaskResultType.PUBLISH: {
          const publishResults: PublishStatus = {
            message: results?.message ?? "Product published successfully.",
            status: taskResult.status,
          };
          if (results?.product_texts) {
            publishResults.productTexts = results.product_texts.map(
              (textData: any) => new ProductText(textData)
            );
          }
          request.resolve?.(publishResults);
          break;
        }
      }
    } else if (taskResult.status === "FAILURE") {
      const { results } = taskResult;
      request.reject?.(results?.error || "Task failed.");
    }

    if (
      taskResult.status !== "PENDING" &&
      taskId in this._incomingRequestsByTaskId
    ) {
      delete this._incomingRequestsByTaskId[taskId];
    }
  }

  /*
   * Everything is queued up,
   * We're waiting for the text generation to be complete.
   */
  _pollResults(): boolean {
    if (!this._incomingPending()) {
      return false;
    }
    const taskIds = Object.keys(this._incomingRequestsByTaskId);
    fetchTaskResults(this.token, taskIds).then((taskResults) => {
      for (const taskId of Object.keys(taskResults)) {
        const taskResult: TaskResult = taskResults[taskId];
        this._processTaskResult(taskId, taskResult);
      }
      if (this._incomingPending()) {
        this._eventuallyPollResults();
      }
      this._retries = 0;
    });
    if (this._retries > 30) {
      return false;
    }
    // FIXME: Exponentially wait longer times before next attempt
    this._retries += 1;
  }
}
