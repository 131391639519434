import React, { FormEvent, useRef } from "react";
import { Button, Modal } from "semantic-ui-react";
import { VocabularyTreeData } from "../utils/tagutils";
import {
  ProductSettingSchemaField,
  ProductSettingSchemaFieldDataType,
} from "./ProductSettingSchemaField";

type Props = {
  classNames?: string;
  closeSettingModal: () => void;
  children?: any;
  currentNode: VocabularyTreeData;
  description?: string;
  errors?: string[];
  handleFormJsonSchemaSubmit: (
    data:
      | React.MutableRefObject<ProductSettingSchemaFieldDataType>
      | FormEvent<Element>
  ) => void;
  help?: string;
  id?: string;
  isOpenSettingsModal: boolean;
  label?: string;
  required?: boolean;
};

export const SettingsModal: React.FC<Props> = ({
  handleFormJsonSchemaSubmit,
  closeSettingModal,
  currentNode,
  isOpenSettingsModal,
}) => {
  const schemaRef = useRef(null);

  const modalInnerContent = (): JSX.Element => {
    return (
      <>
        <label>Number</label>
        <ProductSettingSchemaField
          node={currentNode}
          schemaRef={schemaRef}
          tagTreeComponent={true}
        />
        <div
          className="pd-10-top"
          data-testid={"settings-modal-action-buttons"}
        >
          <Button
            data-testid={"settings-modal-action-submit"}
            name="submit"
            onClick={(): void => {
              handleFormJsonSchemaSubmit(schemaRef.current.getSchemaState());
            }}
          >
            Submit
          </Button>
          <Button
            data-testid={"settings-modal-action-cancel"}
            onClick={(): void => closeSettingModal()}
            name="cancel"
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <div data-testid={"settings-modal"}>
      <Modal
        open={isOpenSettingsModal}
        onClose={(): void => closeSettingModal()}
      >
        <Modal.Header>Settings</Modal.Header>
        <Modal.Content image>
          <Modal.Description>{modalInnerContent()}</Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};
